<ons-toolbar>
  <div class="left ">
    <ons-back-button #onsBackButton></ons-back-button>
  </div>
  <div class="center">{{ getViewStatus() }}</div>
</ons-toolbar>

<div class="content">
  <div [ngBusy]="busy"></div>

<ng-container *ngIf="getViewDeliveryResult() !== ''"><div class="content--message">{{ getViewDeliveryResult() }}​</div></ng-container>
  <p class="content--title content--title--bg">予約内容</p>

  <div class="condition">
    <dl class="condition-item__text">
      <dt>日　時：</dt>
      <dd>{{ reservation.o.schd_time | chatDate: "YYYY/M/D HH:mm" }} - {{ reservation.d.schd_time | chatDate: "HH:mm"  }}
          ({{ moment.duration(moment(reservation.d.schd_time).startOf('minutes').diff(moment(reservation.o.schd_time).startOf('minutes'))).format('h[時間]m[分]') }})
      </dd>
    </dl>
    <dl class="condition-item__text">
      <dt>出発地：</dt>
      <dd>{{ reservation.o.name }}</dd>
    </dl>
    <dl class="condition-item__text">
      <dt>目的地：</dt>
      <dd>{{ reservation.d.name }}</dd>
    </dl>
    <dl class="condition-item__text">
      <dt>利用者：</dt>
      <dd>{{ showPassengerNames(reservation) }}</dd>
    </dl><!--
    <dl class="condition-item__text">
      <dt>荷　物：</dt>
      <dd *ngIf="reservation.baggages == 0">手持ち以外の荷物なし</dd>
      <dd *ngIf="reservation.baggages > 0">{{reservation.baggages | number:'.0-0'}}個</dd>
    </dl>-->
    <dl class="condition-item__text">
      <dt>乗　換：</dt>
      <dd>{{ reservation.trans_count - 1 }}回</dd>
    </dl>
    <ons-list>
      <ons-list-item id="history-detail_001" modifier="chevron longdivider" class="accountMenu" tappable (click)="pushPlan()">
        <p class="list_text">移動プランの詳細を確認する</p>
      </ons-list-item>
    </ons-list>
  </div>

<!-------------
  Template
-------------->

<!-- OD -->
<ng-template #sectionPoint let-sectionPoint="sectionPoint" let-odText="odText">
  <div class="section-point__time">{{ sectionPoint.schd_time | chatDate: "HH:mm" }}{{ odText }}</div>
  <a *ngIf="sectionPoint.location; else sectionPointNoLink"
      [href]="'https://www.google.com/maps/search/?api=1&query=' + sectionPoint.location.lat + ',' + sectionPoint.location.lng + '&z=20'"
      class="list-item__link" target="_blank">
    <div>{{ sectionPoint.name }}</div>
  </a>
  <ng-template #sectionPointNoLink>
    <div>{{ sectionPoint.name }}</div>
  </ng-template>
</ng-template>

<!-- 区間 -->
<ng-template #section let-section="section" let-carNo="carNo">
  <div class="section">
    <div class="section-item__data">
      <ng-container *ngTemplateOutlet="sectionPoint; context: { sectionPoint: section.o, odText: '発' }"></ng-container>
      <div class="arrow">→</div>
      <ng-container *ngTemplateOutlet="sectionPoint; context: { sectionPoint: section.d, odText: '着' }"></ng-container>
    </div>
  </div>
</ng-template>

<!-- 同乗者 -->
<ng-template #passenger let-passenger="passenger" let-canceled="canceled">
  <div class="passenger">
    <div class="passenger-item__name">{{ passenger.user.name }} 様</div>
    <div class="passenger-item__amount">
      <ons-row>
        <ons-col class="passenger-item__ticket-name">{{ passenger.ticket_name }}</ons-col>
        <ons-col class="amount">￥{{ passenger.purchase_amount | number:'.0-0' }}</ons-col>
        <ons-col class="amount--child">{{ getAmoutProperty(passenger.amount_property, canceled) }}</ons-col>
      </ons-row>
    </div>
  </div>
</ng-template>

<!-- 合計料金 -->
<ng-template #total let-total="total">
  <div class="total">
    <div class="total-item__amount">お支払い料金(合計)：
      <span class="amount">￥{{ total.price | number:'.0-0' }}</span>
    </div>
    <div *ngIf="total.price > 0">請求先：{{ total.payer_name }} 様</div>
  </div>
</ng-template>

<!-------------
  Body
-------------->

<ons-list *ngIf="customerBill.total" class="reservation-list">
  <div class="list-item__caption">
    配車予約一覧とお支払い料金
    <ng-container *ngIf="customerBill.families?.length > 0">(ファミリー)</ng-container>
  </div>

  <!-- ログインユーザが乗車 -->
  <ons-list-item *ngFor="let ticket of customerBill?.user?.tickets; index as count" class="passenger-line">
    <!-- O → D -->
    <ng-container *ngFor="let sec of ticket.sections">
      <ng-container *ngTemplateOutlet="section; context: { section: sec }"></ng-container>
    </ng-container>

    <!-- ログインユーザ -->
    <ng-container *ngTemplateOutlet="passenger; context: { passenger: ticket.ticket, canceled: customerBill.user.canceled }"></ng-container>

    <!-- ファミリー -->
    <ng-container *ngFor="let family of customerBill.families">
      <ng-container *ngTemplateOutlet="passenger; context: { passenger: family.tickets[count].ticket, canceled: family.canceled }"></ng-container>
    </ng-container>
  </ons-list-item>

  <!-- ログインユーザの乗車なし、ファミリーの乗車あり -->
  <ng-container *ngIf="customerBill.user === undefined">
    <ons-list-item *ngFor="let ticket of customerBill?.families[0]?.tickets; index as count" class="passenger-line">
      <!-- O → D -->
      <ng-container *ngFor="let sec of ticket.sections">
        <ng-container *ngTemplateOutlet="section; context: { section: sec }"></ng-container>
      </ng-container>

      <!-- ファミリー -->
      <ng-container *ngFor="let family of customerBill?.families">
        <ng-container *ngTemplateOutlet="passenger; context: { passenger: family.tickets[count].ticket, canceled: family.canceled }"></ng-container>
      </ng-container>
    </ons-list-item>
  </ng-container>

  <!-- 合計料金 -->
  <ons-list-item>
    <ng-container *ngTemplateOutlet="total; context: {total: customerBill.total}"></ng-container>
  </ons-list-item>
</ons-list>

<!-- 予約のキャンセル -->
<ng-container [ngSwitch]="reservation.status">
  <ng-container *ngSwitchCase="['reserved', 'working'].includes(reservation.status) ? reservation.status : !reservation.status">
    <!--【UI改善対応】テキストからボタンに変更-->
    <!--<div class="attention">-->
    <!--  配車予約をキャンセルする場合は<ons-button id="history-detail_002" modifier="quiet" (click)="pushCancel()"><span class="button-text">こちら</span></ons-button>-->
    <!--</div>-->
    <div class="button-wrap">
      <ons-button id="history-detail_002" (click)="pushCancel()" modifier="cta">予約のキャンセル</ons-button>
    </div>
  </ng-container>
</ng-container>
</div>
