//=============================================================================================
// インポート
//=============================================================================================
import { Component, Inject, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { OnsNavigator } from 'ngx-onsenui';
import { Subscription } from 'rxjs';
import * as moment from 'moment-timezone';

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { UserWebApiService, USER_WEB_API_CARD_BRAND_MAP, USER_WEB_API_PAYMENT_METHOD_MAP } from '../../../http-services/user-web-api.service';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';

// component
import { PaymentMethod } from '../payment-method';
import { PaymentMethodAddComponent } from '../payment-method-add/payment-method-add.component';
import { PaymentMethodCreditDetaileComponent } from '../payment-method-credit-detaile/payment-method-credit-detaile.component';
import { PaymentMethodPostComponent } from '../payment-method-post/payment-method-post.component';

// interface
import { Card, Settings, UserInfo } from '../../../interfaces/response';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 支払い方法一覧画面。
 *
 * @export
 * @class PaymentMethodListComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component
({
  selector: 'ons-page[payment-method-list]',
  templateUrl: './payment-method-list.component.html',
  styleUrls: ['./payment-method-list.component.css']
})
export class PaymentMethodListComponent implements OnInit, OnDestroy 
{

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * 通信用subscription
   *
   * @type {Subscription}
   * @memberof PaymentMethodListComponent
   */
  busy: Subscription;

  /**
   * ユーザ情報
   *
   * @type {UserInfo}
   * @memberof PaymentMethodListComponent
   */
  userInfo: UserInfo;

  /**
   * 支払い方法
   * 各登録情報、登録数
   *
   * @type {PaymentMethod.PaymentRegistration}
   * @memberof PaymentMethodListComponent
   */
  paymentRegistration: PaymentMethod.PaymentRegistration = 
  {
    count: 0, 
    // slip: false, 
    // yucho: false, 
    // jabank: false, 
    // bank77: false,
    // cards: []
  };

  /**
   * smartgotoとして設定可能な支払い方法
   * NOTE: 
   *  サーバーから自治体として設定可能支払い方法は取得可能であり、そのデータをもとに登録状況を取得することはできるが、
   *  自治体として設定可能な支払い方法が削除された時、削除した支払い方法&登録済み支払い方法がある場合、
   *  削除する口がなくなるためsmartgotoとして登録される可能性がある支払い方法を全て持っておく必要がある。
   *
   * @type {string[]}
   * @memberof PaymentMethodListComponent
   */
  readonly PAYMENT_MEHTOD: string[] = ["card", "slip", "yucho", "jabank", "bank77"];

  /**
   * 住民ユーザーかどうか
   *
   * @memberof PaymentMethodListComponent
   */
  isCitizen = false;

  /**
   * 支払い方法表示有無
   *
   * @memberof PaymentMethodListComponent
   */
  isDisplayPaymentIcon: {[method: string]: boolean} = {};

  /**
   * 自治体として設定可能な支払方法
   *
   * @type {string[]}
   * @memberof PaymentMethodListComponent
   */
  paymentMethods: string[] = [];

  /**
   * 登録情報がない場合のメッセージ(HTMLにバインディング)
   *
   * @type {string}
   * @memberof PaymentMethodListComponent
   */
  paymentMethodUnregisteredMessage: string;

  /**
   * 支払い方法表示文字
   *
   * @memberof PaymentMethodListComponent
   */
  readonly VIEW_TEXT = {
    METHOD: PaymentMethod.METHOD_STR
  };

  /**
   * 自治体ごとの設定情報
   *
   * @type {Settings}
   * @memberof PaymentMethodListComponent
   */
  setting: Settings = this.municipalityWebApiServ.getClientSettings();

  /**
   * 設定可能な支払い方法が存在するかどうか
   *
   * @type {boolean}
   * @memberof PaymentMethodListComponent
   */
  isExistConfigurablePayment: boolean = false;

  /**
   * 登録済みクレジットカード
   *
   * @type {Card[]}
   * @memberof PaymentMethodListComponent
   */
  creditCard: Card[];

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of PaymentMethodListComponent.
   * @param {Map<string, Map<string, string>>} cardBrandMap
   * @param {OnsNavigator} _navigator
   * @param {UserWebApiService} userWebApiService
   * @param {HttpErrorResponseParserService} httpErrorResponseParserService
   * @param {ApplicationMessageService} appMsg
   * @param {ChangeDetectorRef} changeDetectorRef
   * @memberof PaymentMethodListComponent
   */
  constructor
  (
    @Inject(USER_WEB_API_CARD_BRAND_MAP) public readonly cardBrandMap: Map<string, Map<string, string>>,
    @Inject(USER_WEB_API_PAYMENT_METHOD_MAP) public readonly paymentMethodMap: Map<string, Map<string, string>>,
    private _navigator: OnsNavigator,
    private appMsg: ApplicationMessageService, 
    private httpErrorResponseParserService: HttpErrorResponseParserService,
    private userWebApiService: UserWebApiService,
    public changeDetectorRef: ChangeDetectorRef,
    private municipalityWebApiServ: MunicipalityWebApiService,
    private msg: MESSAGE,
  ) { }

  /**
   * 初期化処理。
   *
   * @memberof PaymentMethodListComponent
   */
  ngOnInit(): void {
    this.refresh();
  }

  /**
   * 破棄処理。
   *
   * @memberof PaymentMethodListComponent
   */
  ngOnDestroy(): void {
    this.busy?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 「クレジットカード」選択時の処理。
   *
   * @param {number} card_seq
   * @memberof PaymentMethodListComponent
   */
  selectCredit(card_seq: number): void {
    const paymentMethodDetaileCreditCard = this.paymentRegistration.card.find(e => e.card_seq === card_seq);

    // クレジットカード詳細画面へ遷移
    this._navigator.element.pushPage(PaymentMethodCreditDetaileComponent, {
      data: [paymentMethodDetaileCreditCard, this.paymentRegistration]
    });
  }

  /**
   * クレジットカード以外選択時の処理。
   *
   * @memberof PaymentMethodListComponent
   */
  selectMethod(method: UserInfo["payment"]["method"]): void {
    let arg = [{ view: 'detail', method: method }, this.paymentRegistration];

    // 各支払い方法詳細画面へ遷移
    this._navigator.element.pushPage(PaymentMethodPostComponent, { data: arg });
  }

  /**
   * 「支払い方法を追加」ボタン押下時の処理。
   *
   * @memberof PaymentMethodListComponent
   */
  addPaymentMethod(): void {
    // このユーザーで設定可能な支払い方法が一つも存在しない場合、ダイアログを出し遷移しないように
    if (this.isExistConfigurablePayment === false) {
      this.appMsg.viewDialogMessage(this.msg.CLIENT.PAYMENT.ERR_NOT_EXIST_APPENDABLE_PAYMENT.message());
      return;
    }

    this._navigator.element.pushPage(PaymentMethodAddComponent, {
      data: {paymentRegistration: this.paymentRegistration, paymentMethods: this.paymentMethods}
    });
  }

//=============================================================================================
// メソッド
//=============================================================================================

  // /**
  //  * 登録済みの支払い方法数を設定する。
  //  *
  //  * @private
  //  * @memberof PaymentMethodListComponent
  //  */
  // private setPaymentCount(): void {

  //   let count: number = 0;
  //   // 支払い方法の登録数計算
  //   Object.values(this.PAYMENT_MEHTOD).forEach(method => {
  //   // 1件も登録されていない場合のメッセージ
  //   this.paymentMethodUnregisteredMessage = this.paymentRegistration.count === 0 ? this.msg.CLIENT.PAYMENT.NOT_PAYMENT.message() : '';

  //   });
  //   count += this.paymentRegistration.cards.length;

  //   this.paymentRegistration.count = count;

  //   // 1件も登録されていない場合のメッセージ
  //   this.paymentMethodUnregisteredMessage = this.paymentRegistration.count === 0 ? this.msg.CLIENT.PAYMENT.NOT_PAYMENT.message() : '';


  //   // // 支払い方法の登録数
  //   // this.paymentRegistration.count = (this.paymentRegistration.slip ? 1 : 0) 
  //   //                                   + this.paymentRegistration.cards.length 
  //   //                                   + (this.paymentRegistration.yucho ? 1 : 0) 
  //   //                                   + (this.paymentRegistration.jabank ? 1 : 0)
  //   //                                   + (this.paymentRegistration.bank77 ? 1 : 0);
    
  // }

  /**
   * 自治体が登録可能な支払い方法から、
   * ユーザーが設定可能な支払方法を取得。
   *
   * @private
   * @memberof PaymentMethodListComponent
   */
  private setRegisterablePayment(): void {
    /**
     * 設定可能な支払い方法か
     * 
     * @returns boolean
     */
    const checkPaymentMethods = (payment: string[]): string[] => {
      // 住民ユーザーの場合、すべての支払い方法が利用可能
      if (this.isCitizen === true) return payment;
      // 観光ユーザーの場合、カード支払いのみ可能
      if (payment.includes('card') === true) return ['card'];
      // 観光ユーザーかつ、自治体としてカード支払い不可の場合、設定可能支払い方法なし
      else return [];
    }

    this.paymentMethods = checkPaymentMethods(this.setting.payment_methods);

    this.paymentMethods.forEach((item) => {
      this.isDisplayPaymentIcon[item] = true;
    });

    // 設定可能な支払い方法が存在するかどうか
    this.isExistConfigurablePayment = this.paymentMethods.length !== 0;
  }

  /**
   * ユーザーごとの支払い方法登録状況をセット。
   *
   * @private
   * @param {Card[]} creditCard サーバーから取得したクレジットカード配列
   * @memberof PaymentMethodListComponent
   */
  private setPaymentMethod(): void {

    const thisYearMonth = Number(moment().format('YYMM'));
    const nearExpiredYearMonth = Number(moment().add(1, 'M').format('YYMM'));
    const toYearMonth = (expire: string): number => Number(`${expire.slice(0, 2)}${expire.slice(2)}`)

    // 登録済み支払い方法数
    let count: number = 0;

    // 支払い方法、登録状況を整理、データセット
    Object.values(this.PAYMENT_MEHTOD).forEach(method => {

      if (method !== 'card') {
        this.paymentRegistration[method] = this.userInfo.payment[method]?.use ? true : false;
        count += this.paymentRegistration[method] ? 1 : 0;
      }
      else if (method === 'card' && this.creditCard) {
        this.paymentRegistration[method] = this.creditCard.map((e: Card): PaymentMethod.PaymentCard => {
          let obj: any = Object.keys(e).reduce((object, key) => {
            object[key] = e[key];
            return object;
          }, {});
    
          const expireYearMonth = toYearMonth(e.expire);
    
          obj['is_default'] = this.userInfo.payment.method === 'CARD' && this.userInfo.payment.card_seq === e.card_seq;
          obj['is_expired'] = expireYearMonth < thisYearMonth;
          obj['is_near_expired'] = !obj['is_expired'] && expireYearMonth <= nearExpiredYearMonth;
    
          return obj as PaymentMethod.PaymentCard;
        });
        count += this.paymentRegistration.card.length;
      }
    });
    if (this.creditCard) {
      // 初期表示メッセージ
      const initMessage: string = (() => {
        const defaultCard = this.paymentRegistration.card.find(e => e.is_default);

        // 有効期限間近
        if (defaultCard?.is_near_expired) return this.msg.CLIENT.PAYMENT.NEAR_EXPIRED.message();
        // 有効期限切れ
        else if(defaultCard?.is_expired)  return this.msg.CLIENT.PAYMENT.EXPIRED.message();
        else return null;
      })();

      // 期限間近、期限切れがあるならアラート
      if (initMessage) this.appMsg.viewDialogMessage(initMessage);
    }

    // 支払い方法登録数セット
    this.paymentRegistration.count = count;
    // 1件も登録されていない場合のメッセージを設定
    this.paymentMethodUnregisteredMessage = this.paymentRegistration.count === 0 ? this.msg.CLIENT.PAYMENT.NOT_PAYMENT.message() : '';
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * 支払い方法の最新化を行う。
   *
   * @private
   * @memberof PaymentMethodListComponent
   */
  private refresh(): void {
    this.busy = this.userWebApiService.getLatestUserInfo().subscribe({
      next: user => {
        this.userInfo = user.body;
        this.isCitizen = this.userInfo.user_type === 'citizen';

        /**
         * 支払い状況を設定
         */
        const setPayment = () => {
          // 登録状況を取得し、セット
          this.setPaymentMethod();
          // 設定可能かを取得し、セット
          this.setRegisterablePayment();
          
          // 更新完了後、ビューの再描画
          this.changeDetectorRef.detectChanges();
        }

        // クレジットカード情報を取得
        this.busy = this.userWebApiService.allCards<Card[]>().subscribe({
          next: response => {
            this.creditCard = response.body;
            setPayment();
          },
          error: this.httpErrorResponseParserService.doParse((_err, errContent) => {
            this.httpErrorResponseParserService.viewErrDialog(errContent);

            setPayment();
          }),
        });
      },
      error: this.httpErrorResponseParserService.doParse((_err, errContent) => {
        this.httpErrorResponseParserService.viewErrDialog(errContent);
      })
    });
  }
}