import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DatePickerService {

  /**
   * カレンダーの表示位置を設定
   */
  positionDatePicker() {
    // カレンダーの「日付を選択」ボタンを取得
    const calendarButton = document.querySelector('.fc-selectDate-button') as HTMLElement;
    const triggerRect = calendarButton.getBoundingClientRect();
    
    // 日付ピッカーのオーバーレイ要素を取得
    const datePickerOverlay = document.querySelector('.mat-datepicker-content') as HTMLElement;
  
    if (datePickerOverlay) {
      datePickerOverlay.style.position = 'fixed';  // 固定位置に設定
  
      const viewportHeight = window.innerHeight;
      const viewportWidth = window.innerWidth;
      const datePickerHeight = datePickerOverlay.offsetHeight;
      const datePickerWidth = datePickerOverlay.offsetWidth;
  
      // 初期位置をボタンの上方に設定
      let top = triggerRect.top - datePickerHeight - 20;
      let left = triggerRect.right - datePickerWidth;
  
      // 画面の下にはみ出していないか確認し、必要ならばボタンの下に移動
      if (top < 0) {
        top = triggerRect.bottom + 20;
      }
  
      // 画面の左にはみ出していないか確認し、必要ならばボタンの左に移動
      if (left < 0) {
        left = triggerRect.left;
      }
  
      // 画面の右にはみ出していないか確認し、必要ならば画面の右端に移動
      if (left + datePickerWidth > viewportWidth) {
        left = viewportWidth - datePickerWidth - 10;
      }
  
      // 計算された位置を適用
      datePickerOverlay.style.top = `${top}px`;
      datePickerOverlay.style.left = `${left}px`;
  
      // 下にはみ出ている場合は上に移動
      const bottomOverflow = top + datePickerHeight - viewportHeight;
      if (bottomOverflow > 0) {
        top -= bottomOverflow + 10; // 10px余裕を持たせる
        datePickerOverlay.style.top = `${top}px`;
      }
    }
  }
}
