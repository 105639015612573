//=============================================================================================
// インポート
//=============================================================================================
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { HTML5_FMT } from 'moment';
import { concat, Observable, Subscription } from 'rxjs';
import * as CONST from '../constants/constant';
import * as moment from 'moment';

// service
import { ApplicationMessageService } from './application-message.service';
import { UserWebApiService } from '../http-services/user-web-api.service';
import { SpotGeolocationService } from '../http-services/spot-geolocation.service';
import { MunicipalityWebApiService } from '../http-services/municipality-web-api.service';

// interface
import { ExUser, Relation, UserInfo } from '../interfaces/response';

// other
import { UserReservation } from '../components/reservation/user-reservation/user-reservation';
import { MESSAGE } from '../constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

  /**
   * 〈出発地/目的地〉の種別を表すコード。
   *
   */
  export const targetKind: 'o' | 'd' | 'order' = undefined;

/**
 * 配車割り当て管理サービス。
 *
 * @export
 * @class SerachConditionService
 */
@Injectable({
  providedIn: 'root'
})
export class AllocationConditionService implements OnDestroy{//} implements UserReservation.SearchCondition {

//=============================================================================================
// クラス変数
//=============================================================================================

  /**
   * 出発地
   *
   * @type {UserReservation.SearchConditionSpot}
   * @memberof AllocationConditionService
   */
  d: UserReservation.SearchConditionSpot = {};

  /**
   * 目的地
   *
   * @type {UserReservation.SearchConditionSpot}
   * @memberof AllocationConditionService
   */
  o: UserReservation.SearchConditionSpot = {};

  /**
   * お届け先
   *
   * @type {UserReservation.SearchConditionSpot}
   * @memberof AllocationConditionService
   */
  order: UserReservation.SearchConditionSpot = {};

  /**
   * 検索条件（積載荷物）
   *
   * @type {UserReservation.SearchConditionBaggage}
   * @memberof AllocationConditionService
   */
  readonly baggage: UserReservation.SearchConditionBaggage;
  
  /**
   * 検索条件（出発時刻/到着時刻）
   *
   * @type {UserReservation.SearchConditionSchedule}
   * @memberof AllocationConditionService
   */
  readonly schedule: UserReservation.SearchConditionSchedule;

  /**
   * （出発時刻/到着時刻）に関する値とラベル
   *
   * @memberof UserReservationComponent
   */
   readonly searchConditionScheduleOdOptions: UserReservation.SearchConditionSchedule["odOptions"] = {
    o: {
      value: 0,
      label: '出発'
    },
    d: {
      value: 1,
      label: '到着'
    }
  };

  /**
   * 検索条件（同乗者）
   *
   * @private
   * @type {UserReservation.SearchConditionPassengers}
   * @memberof AllocationConditionService
   */
  private passengers: UserReservation.SearchConditionPassengers;

  /**
   * 時刻ユーティリティ（メソッド群）
   *
   * @type {UserReservation.SearchConditionScheduleDateTimeService}
   * @memberof AllocationConditionService
   */
  readonly scheduleUtil: UserReservation.SearchConditionScheduleDateTimeService;

  /**
   * 選択したスポットユーティリティ（メソッド群）
   *
   * @type {UserReservation.SearchConditionSpotService}
   * @memberof AllocationConditionService
   */
  readonly spotUtil: UserReservation.SearchConditionSpotService;

  /**
   * 配車プラン検索が可能かどうか（必要な条件がすべて設定されているか）
   *
   * @private
   * @type {boolean}
   * @memberof AllocationConditionService
   */
  private canSearch: boolean = false;

  /**
   * OnDestroy時に破棄するSubscriptionオブジェクト
   *
   * @memberof ExpComponent
   */
  subscription = new Subscription();

  /**
   * 予約可能日数
   *
   * @type {number}
   * @memberof UserReservationComponent
   */
  reservableDays: number;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of OrderRequestService.
   * @memberof OrderRequestService
   */
  constructor(
    private appMsgServ: ApplicationMessageService, 
    private userServ: UserWebApiService, 
    private spotGeoServ: SpotGeolocationService,
    private municipalityWebApiServ: MunicipalityWebApiService,
    private msg: MESSAGE,
  ) {

    const settingsChanged = this.municipalityWebApiServ.settingsChanged.subscribe({
      next: setting => {
        if (setting == null) return;
        this.reservableDays = setting.dispatch.limit;
      }
    });
    this.subscription.add(settingsChanged);

    /**************************
     * interface初期化
     **************************/

    // this
    const that = this;

    // 荷物
    this.baggage = { checked: false };

    // 時刻
    this.schedule = {
      useCurrentTime: true, 
      dateTime: moment().format(HTML5_FMT.DATETIME_LOCAL), 
      od: 'o', 
      dir: 1, 
      odOptions: this.searchConditionScheduleOdOptions
    };

    // 時刻（Util）
    this.scheduleUtil = {
      asMoment() {
        return moment(this.schedule.dateTime, HTML5_FMT.DATETIME_LOCAL);
      },
      verifyRange() {
        const now = moment().startOf('minutes');
        const scheduleDateTime = moment(this.schedule.dateTime, HTML5_FMT.DATETIME_LOCAL);

        if (!scheduleDateTime.isValid()) {
          return undefined;
        }

        if (scheduleDateTime.isBefore(now, 'minutes')) {
          return -1;
        }

        if (moment(scheduleDateTime).startOf('days').isAfter(moment(now).add(this.reservableDays, 'days').startOf('days'), 'days')) {
          return 1;
        }

        return 0;
      }
    };

    // スポット（Util）
    this.spotUtil = {
      getFormalNameOrAddress(spot) {
        return spot.favorite?.name 
                || spot.place?.name
                || spot.history?.name
                || spot.placeResult && (spot.placeResult?.name || that.spotGeoServ.formatGeocoderResultAddress(spot.placeResult) || `${spot.placeResult.geometry.location.lat()}, ${spot.placeResult.geometry.location.lng()}`)
                || (spot.geocoderResult && that.spotGeoServ.formatGeocoderResultAddress(spot.geocoderResult))
                || (spot.geocoderResult?.geometry.location ? `${spot.geocoderResult.geometry.location.lat()}, ${spot.geocoderResult.geometry.location.lng()}` : "");
      },
      getGeometryLocation(spot) {
        return spot.placeResult?.geometry?.location
                || spot.arbitraryLocation
                || spot.geocoderResult?.geometry.location;
      },
      getLocation(spot) {
        return spot.favorite?.location
                || spot.place?.location
                || spot.history?.location
                || that.spotUtil.getGeometryLocation(spot);
      },
      getNameOrAddress(spot) {
        return spot.favorite?.name
                || that.spotUtil.getFormalNameOrAddress(spot);
      },
      getTitle(spot) {
        return !!spot.useCurrentPosition ? '現在地' : that.spotUtil.getNameOrAddress(spot);
      },
      isSelected(spot) {
        return !!spot.favorite
                || !!spot.place
                || !!spot.history
                || !!spot.placeResult
                || !!spot.geocoderResult
                || !!spot.useCurrentPosition
                || !!spot.registerAddress;
      }
    };
  }

  /**
   * 廃棄処理
   *
   * @memberof AllocationConditionService
   */
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * 同乗者コレクションを取得する。
   *
   * @readonly
   * @type {UserReservation.SearchConditionPassengers}
   * @memberof AllocationConditionService
   */
  //  get getPassengers(): UserReservation.SearchConditionPassengers {

  //   return this.passengers;
  // }

  /**
   * 同乗者（ゲスト）を追加する。
   *
   * @param {ExUser} exUser
   * @memberof AllocationConditionService
   */
  // addGuestPassenger(exUser: ExUser): void {

  //   // 搭乗済みか
  //   if (this.existsPassenger(exUser.user_id)) {
  //     throw new Error();
  //   }

  //   // 認証の有効期限を取得
  //   const expirationTime = this.getGuestExpirationTime();

  //   if (this.config.guest.slideEachExpirations) {
  //     this.passengers.guests.forEach(e => {
  //       e.expirationTime = expirationTime;
  //     });
  //   }

  //   this.passengers.guests.push({
  //     user: {
  //       user_id: exUser.user_id,
  //       name: exUser.profile.name,
  //       icon: exUser.profile.icon
  //     },
  //     checked: true,
  //     expirationTime: expirationTime
  //   });

  //   this.updateCanSearchValue();
  // }

  /**
   * ゲスト搭乗者を初期化する。
   *
   * @memberof SearchConditionImpl
   */
  // deleteGuestPassenger(): void 
  // {
  //   this.passengers.guests = [];
  // }

  /**
   * 入力された検索条件の妥当性を検証する。
   *
   * @return {*} 
   * @memberof AllocationConditionService
   */
  assertInputModel(): Observable<Error[]> {
    
    /**
     * 選択している場所の位置情報を返すObservable
     *
     * @param {UserReservation.SearchConditionSpot} spot
     * @return {*}  {(Observable<google.maps.LatLng | google.maps.LatLngLiteral>)}
     */
    const spotLocationObservable = (spot: UserReservation.SearchConditionSpot): Observable<google.maps.LatLng | google.maps.LatLngLiteral> => {

      return new Observable<google.maps.LatLng | google.maps.LatLngLiteral>(subscriber => {
        if (!spot.useCurrentPosition) {
          const location = this.spotUtil.getLocation(spot);

          if (location) {
            subscriber.next(location);
          }

          subscriber.complete();
        } 
        else {
          // getCurrentPosition の呼び出しは一回で済ませたいとも考えたが、そもそも〈出発地〉と〈目的地〉の両方に『現在地』を指定するようなイレギュラーな操作のために無駄なコードを書くのもナンセンス。
          this.spotGeoServ.getCurrentPosition().subscribe({
            next: result => {
              const location = result.position && this.spotGeoServ.createLatLng(result.position.coords);

              if (location) {
                new google.maps.Geocoder().geocode({ location }, (results, status) => {
                  // HACK: 『破壊的な検証』は避けたいところであるが……。
                  spot.geocoderResult = (status === google.maps.GeocoderStatus.OK) && results[0];

                  subscriber.next(spot.geocoderResult?.geometry.location);
                  subscriber.complete();
                });
              } 
              else {
                subscriber.next();
                subscriber.complete();
              }
            }
          });
        }
      })
    };

    /**
     * 選択したスポットのエラーを返す
     *
     * @param {('o' | 'd')} od
     * @return {*}  {Observable<Error>}
     */
    const spotErrorObservable = (od: 'o' | 'd'): Observable<Error> => {

      return new Observable<Error>(subscriber => {
        spotLocationObservable(this[od]).subscribe({
          next: location => {
            if (location) {
              if (!this.spotGeoServ.maxValidBounds.contains(location)) {
                // SmartGOTO範囲外
                subscriber.next(new Error(this.msg.CLIENT.DISPATCH.E_VALID_BOUNDS.message(this.getTargetPlaceKindName(od))));
              }
            } 
            else {
              // locationがない
              subscriber.next(new Error(this.msg.CLIENT.COMMON.E_UNEXPECTED.message()));
            }
          },
          complete: () => {
            subscriber.complete();
          }
        });
      });
    };

    const scheduleErrorObservable = new Observable<Error>(subscriber => {

      const now = moment().startOf('minutes');

      // HACK: 『破壊的な検証』は避けたいところであるが……。
      if (this.schedule.useCurrentTime) {
        this.schedule.dateTime = now.format(HTML5_FMT.DATETIME_LOCAL);
      }

      switch (this.scheduleUtil.verifyRange()) {
        case -1:
          subscriber.next(new Error(this.msg.CLIENT.DISPATCH.E_VERIFY_PAST.message()));

          break;
        case 1:
          subscriber.next(new Error(this.msg.CLIENT.DISPATCH.E_VERIFY_FUTURE.message((this.reservableDays + 1).toString())));

          break;
        case 0:
          break;
        default:
          // HACK: 発着時刻が無効な場合は [検索] ボタンが押せないようになっているため、発着時刻の有効性についてはあらかじめ検証済みであるものとする。
          break;
      }

      subscriber.complete();
    });

    return new Observable<Error[]>(subscriber => {
      let errors: Error[] = [];

      concat(
        spotErrorObservable('o'),
        spotErrorObservable('d'),
        scheduleErrorObservable
      ).subscribe({
        next: error => {
          errors.push(error);
        },
        complete: () => {
          subscriber.next(errors);
          subscriber.complete();
        }
      });
    });
  }

  /**
   * ゲストが搭乗済みか判定する。
   *
   * @param {number} user_id
   * @return {*}  {number} 
   *              指定IDがログインユーザ：1
   *              指定IDがファミリメンバー：2
   *              指定IDがゲスト：3
   *              含まれない：0
   * @memberof SearchConditionImpl
   */
  existsPassenger(user_id: string): number {

    const includesUserId = (source: UserReservation.SearchConditionPassengersElement[]) => source
      .map(e => e.user.user_id)
      .includes(user_id);

    if (this.passengers.self.user.user_id === user_id) return 1;
    else if (includesUserId(this.passengers.families)) return 2;
    else if (includesUserId(this.passengers.guests)) return 3;
    else ;

    return 0;
  }

  /**
   * 乗車済みユーザの姓名を取得する。
   *
   * @param {UserReservation.SearchConditionPassengersElement} passenger
   * @return {*}  {string}
   * @memberof AllocationConditionService
   */
  formatPassengerName(passenger: UserReservation.SearchConditionPassengersElement): string {

    return passenger.user.name.family_name + passenger.user.name.given_name;
  }

  /**
   * 乗車済みのユーザ数を取得する。
   *
   * @return {*}  {number}
   * @memberof AllocationConditionService
   */
  getPassengersCount(): number {

    return this.getPassengersUsers().length;
  }

  /**
   * 乗車済みのユーザのuser_id配列を取得する。
   *
   * @return {*}  {number[]}
   * @memberof AllocationConditionService
   */
  getPassengersUserIds(): string[] {

    return this.getPassengersUsers().map(e => e.user?.user_id);
  }

  /**
   * 乗車済みユーザ情報を取得する。
   *
   * @return {*}  {UserReservation.SearchConditionPassengersElement[]}
   * @memberof AllocationConditionService
   */
  getPassengersUsers(): UserReservation.SearchConditionPassengersElement[] {
    
    const checkedUsers = (source: UserReservation.SearchConditionPassengersElement[]) => source.filter(e => e.checked);

    return [
      ...checkedUsers([this.passengers?.self || {
        user: undefined,
        checked: false
      }]),
      ...checkedUsers(this.passengers?.families || []),
      ...checkedUsers(this.passengers?.guests || [])
    ];
  }
  
  /**
   * 設定対象種別を取得する。
   *
   * @param {('o' | 'd' | 'order')} kind
   * @return {*}  {string}
   * @memberof AllocationConditionService
   */
  getTargetPlaceKindName(kind: typeof targetKind): string {

    return { o: '出発地', d: '目的地', order: 'お届け先' }[kind];
  }

  /**
   * 同乗者の氏名を取得する。
   *
   * @param {number} user_id
   * @return {*}  {string}
   * @memberof AllocationConditionService
   */
  getPassengerName(user_id: string): string {

    const getName = (passenger: UserReservation.SearchConditionPassengersElement) => {
      return passenger.user.name.family_name + ' ' + passenger.user.name.given_name;
    }

    // ファミリー
    const family = this.passengers.families.find(p => p.user.user_id === user_id);
    if (family) return getName(family);

    // ゲスト
    const guest = this.passengers.guests.find(p => p.user.user_id === user_id);
    if (guest) return getName(guest);

    // 予約者
    if (this.passengers.self.user.user_id === user_id) return getName(this.passengers.self);
  }

  /**
   * 乗車ユーザの初期化を行う。
   *
   * @param {UserInfo} [user]
   * @param {ExUser[]} [families]
   * @memberof AllocationConditionService
   */
  initPassengers(user?: UserInfo, families?: ExUser[]): void {
    
    this.passengers = {
      self: {
        user: user && {
          user_id: user.user_id,
          name: user.profile.name,
          icon: user.profile.icon
        },
        checked: this.passengers?.self.checked || true
      }, 
      families: families?.map<UserReservation.SearchConditionPassengersElement>(u => ({
        user: {
          user_id: u.user_id,
          name: u.profile.name,
          icon: u.profile.icon
        },
        checked: false
      })) || [], 
      guests: []
    };
  }

  /**
   * ファミリーの初期化を行う。
   *
   * @param {Relation[]} [families]
   * @memberof AllocationConditionService
   */
  initFamilies(families?: Relation[]): void {

    let familiesInfo: UserReservation.SearchConditionPassengersElement[] = [];

    if (families && families[0] && families[0].role == "child") {
      for (let i = 0; i < families.length; i++) {
        if (families[i].status != "waiting") {
          const family: UserReservation.SearchConditionPassengersElement = {
            user: {
              user_id: families[i].user_id, 
              name: families[i].name, 
              icon: ""
            },
            checked: this.passengers?.families.find(u => u.user.user_id == families[i].user_id)?.checked || false
          }
          familiesInfo.push(family);
        }
      }
    }
    this.passengers.families = familiesInfo;
  }
  
  /**
   * ゲストの乗車有効期限を更新する。
   *
   * @param {UserReservation.SearchConditionPassengersElement} guest
   * @return {*}  {void}
   * @memberof AllocationConditionService
   */
  // reenableGuestPassenger(guest: UserReservation.SearchConditionPassengersElement): void {

  //   if (!this.config.guest.reenableExpiration) {
  //     return;
  //   }

  //   if (guest.checked) {
  //     const expirationTime = this.getGuestExpirationTime();

  //     if (this.config.guest.slideEachExpirations) {
  //       this.passengers.guests.forEach(e => {
  //         e.expirationTime = expirationTime;
  //       });
  //     }
  //     else {
  //       guest.expirationTime = expirationTime;
  //     }
  //   }

  //   this.updateCanSearchValue();
  // }

  /**
   * スポット情報を設定する。
   *
   * @param {typeof targetKind} kind
   * @param {UserReservation.SearchConditionSpot} spot
   * @memberof AllocationConditionService
   */
  setSpot(kind: typeof targetKind, spot: UserReservation.SearchConditionSpot): void {

    const current = { ... this[kind] };

    this[kind] = {
      ...spot,
      freeText: spot.freeText || current.freeText
    };

    if (kind !== 'order') this.updateCanSearchValue();
  }

  /**
   * 出発地と目的地を入れ替える。
   *
   * @memberof AllocationConditionService
   */
  swapSpots(): void {

    const temp = this.o;

    this.o = this.d;
    this.d = temp;

    this.updateCanSearchValue();
  }

  /**
   * 配車プラン検索可否を判断（更新）する。
   *
   * @memberof AllocationConditionService
   */
  updateCanSearchValue(): void {

    this.canSearch = this.spotUtil.isSelected(this.o)
      && this.spotUtil.isSelected(this.d)
      && (this.schedule.useCurrentTime || (this.scheduleUtil.verifyRange() === 0))
      && (this.getPassengersCount() > 0);
  }

//=============================================================================================
// privateメソッド
//=============================================================================================

  /**
   * ゲスト認証の有効時間（秒）を取得する。
   *
   * @private
   * @return {*}  {number}
   * @memberof AllocationConditionService
   */
  // private getGuestExpirationTime(): number {

  //   if (this.config.guest.validTime) {
  //     return moment().add(this.config.guest.validTime, 'seconds').valueOf();
  //   }

  //   return null;
  // };
}

//=============================================================================================
// 外部公開定義
//=============================================================================================

