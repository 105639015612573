import { HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HTML5_FMT } from 'moment';
import * as moment from 'moment-timezone';
import { OnsNavigator } from 'ngx-onsenui';
import * as ons from 'onsenui';
import { concat, Observable, Subscription } from 'rxjs';
import * as CONST from '../../../constants/constant';

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { HttpCustomErrorContent, HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { PageKey, PagerService } from '../../../lib-services/pager.service';
import { FamilyWebApiService } from '../../../http-services/family-web-api.service';
import { MenuService } from '../../../lib-services/menu.service';
import { UserWebApiService } from '../../../http-services/user-web-api.service';
import { FavoriteWebApiSchemas, FavoriteWebApiService } from '../../../http-services/favorite-web-api.service';
import { SpotGeolocationService } from '../../../http-services/spot-geolocation.service';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';

// component
import { UseListComponent } from '../../service/use-list/use-list.component';
import { TabbarComponent } from '../../tabbar/tabbar.component';
import { ServiceComponent } from '../../service/service.component';
import { SigninComponent } from '../../signin/signin.component';
import { BaggageComponent } from '../_baggage/baggage.component';
import { RideCountComponent } from '../ride-count/ride-count.component';
import { SearchMethodListComponent } from '../search-method-list/search-method-list.component';
import { SearchResultListComponent } from '../search-result-list/search-result-list.component';

// interface
import { UserReservation } from './user-reservation';
import { UserInfo, ExUser, Relation } from '../../../interfaces/response';
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';
import { MESSAGE } from 'src/app/constants/message';


/**
 * 各種条件選択画面。
 *
 * @export
 * @class UserReservationComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ons-page[user-reservation]',
  templateUrl: './user-reservation.component.html',
  styleUrls: ['./user-reservation.component.css']
})
export class UserReservationComponent implements OnInit, AfterViewInit, OnDestroy 
{

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * 〈出発時刻/到着時刻〉に関する値とラベル。
   *
   * @memberof UserReservationComponent
   */
  readonly searchConditionScheduleOdOptions = {
    o: {
      value: 0,
      label: '出発'
    },
    d: {
      value: 1,
      label: '到着'
    }
  };

  /**
   * エラーメッセージが格納される `Map` オブジェクトのキー (発着時刻に関連するもの)。
   *
   * @memberof UserReservationComponent
   */
  readonly SCHEDULE_DATE_TIME_ERROR_MESSAGE_KEY = "SCHEDULE_DATE_TIME_ERROR";

  /**
   * 移動プランの検索条件。
   *
   * @type {UserReservation.SearchCondition}
   * @memberof UserReservationComponent
   */
  searchCondition: UserReservation.SearchCondition;

  /**
   * エラーメッセージが格納される `Map` オブジェクト。
   *
   * @memberof UserReservationComponent
   */
  validationErrorMessages = new Map<string, string>();

  /**
   * 非同期処理の実行状態を表す。
   *
   * @type {Subscription}
   * @memberof UserReservationComponent
   */
  busyPosition: Subscription;

  /**
   * 非同期処理の実行状態を表す。
   *
   * @type {Subscription}
   * @memberof UserReservationComponent
   */
  busyUser: Subscription;

  /**
   * 非同期処理の実行状態を表す。
   *
   * @type {Subscription}
   * @memberof UserReservationComponent
   */
  busyFamily: Subscription;

  /**
   * 非同期処理の実行状態を表す。
   *
   * @type {Subscription}
   * @memberof UserReservationComponent
   */
  busyFavorite: Subscription;

  /**
   * 非同期処理の実行状態を表す。
   *
   * @type {Subscription}
   * @memberof UserReservationComponent
   */
  busyAssert: Subscription;

  /**
   * このコンポーネントのページ番号
   *
   * @private
   * @type {number}
   * @memberof UserReservationComponent
   */
  private currentPageIndex: number;

  /**
   * HTTPエラーが生じたかどうか
   *
   * @private
   * @type {boolean}
   * @memberof UserReservationComponent
   */
  private httpErrorHandled: boolean = false;

  private onUserInfoChanged: Subscription;
  private onFamilyInfoChanged: Subscription;
  private onFavoriteChanged: Subscription;

  beforeUserStatus: string = undefined;

  /**
   * assetsファイルへのパス(定数)
   *
   * @memberof UserReservationComponent
   */
  readonly ASSETS = {
    DEPARTURE: CommonFunctionModule.getAssetsUrl('/image/common/04-Departure.png'),
    DESTINATION: CommonFunctionModule.getAssetsUrl('/image/common/05-Destination.png'),
    CHANGE: CommonFunctionModule.getAssetsUrl('/image/common/16-Change.png'),
    CLOCK: CommonFunctionModule.getAssetsUrl('/image/common/17-Clock.png'),
    NUMBER: CommonFunctionModule.getAssetsUrl('/image/common/18-Number.png'),
    BAGGAGE: CommonFunctionModule.getAssetsUrl('/image/common/19-Baggage.png')
  } as const;

  /**
   * OnDestroy時に破棄するSubscriptionオブジェクト
   *
   * @memberof ExpComponent
   */
  subscription = new Subscription();

  /**
   * 予約可能日数
   *
   * @type {number}
   * @memberof UserReservationComponent
   */
  reservableDays: number;

  /**
   * 現在時刻、時刻選択要素
   * ons-segment
   *
   * @type {*}
   * @memberof UserReservationComponent
   */
  @ViewChild('useCurrentTimeSegment') userCurrentTimeSeg: any;

  /**
   * 出発、到着要素
   * ons-segment
   *
   * @type {*}
   * @memberof UserReservationComponent
   */
  @ViewChild('OdSegment') odSeg: any;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of UserReservationComponent.
   * @param {UserReservation.Config} config 『おでかけ予約』の構成設定。
   * @param {OnsNavigator} _navigator ページスタックの管理とナビゲーション機能を提供するコンポーネント。
   * @param {UseListComponent} useListComp
   * @param {ApplicationMessageService} appMsg
   * @param {UserWebApiService} userWebApiService Web API (ユーザー関連) の呼び出しを簡略化するためのユーティリティ。
   * @param {FavoriteWebApiService} favoriteWebApiService Web API (マイスポット関連) の呼び出しを簡略化するためのユーティリティ。
   * @param {SpotGeolocationService} spotGeolocationService デバイスやスポットの位置情報の扱いを簡略化するためのユーティリティ。
   * @param {HttpErrorResponseParserService} httpErrorResponseParserService `HttpErrorResponse` オブジェクトを SmartGOTO の形式にカスタマイズするためのユーティリティ。
   * @memberof UserReservationComponent
   */
  constructor(
    private _navigator: OnsNavigator,
    private useListComp: UseListComponent, 
    private tabbarComp: TabbarComponent, 
    private appMsg: ApplicationMessageService, 
    private msg: MESSAGE,
    private userWebApiService: UserWebApiService,
    private familyWebApiService: FamilyWebApiService,
    private favoriteWebApiService: FavoriteWebApiService,
    private spotGeolocationService: SpotGeolocationService,
    private httpErrorResponseParserService: HttpErrorResponseParserService, 
    private pagerServ: PagerService,
    private municipalityWebApiServ: MunicipalityWebApiService,
  ) {
    const settingsChanged = this.municipalityWebApiServ.settingsChanged.subscribe({
      next: setting => {
        if (setting == null) return;
        this.reservableDays = setting.dispatch.limit;
      }
    });
    this.subscription.add(settingsChanged);
  }

  /**
   * コンポーネントが初期化される際に呼び出されるメソッド。
   *
   * @return {*}  {void}
   * @memberof UserReservationComponent
   */
  ngOnInit(): void 
  {
    this.currentPageIndex = this._navigator.element.pages.length - 1;

    class SearchConditionImpl implements UserReservation.SearchCondition {
      //=========================================
      // プロパティ定義
      //=========================================
      d: UserReservation.SearchConditionSpot = {};
      o: UserReservation.SearchConditionSpot = {};

      readonly baggage: UserReservation.SearchConditionBaggage;
      readonly schedule: UserReservation.SearchConditionSchedule;
      readonly scheduleDateTimeService: UserReservation.SearchConditionScheduleDateTimeService;
      readonly spotService: UserReservation.SearchConditionSpotService;
      private _canSearch: boolean = false;
      private _passengers: UserReservation.SearchConditionPassengers;

      constructor(
        private component: UserReservationComponent,
      ) {
        const that = this;

        // 初期化処理
        this.baggage = {
          checked: false
        };

        this.schedule = {
          useCurrentTime: true,
          dateTime: moment().format(HTML5_FMT.DATETIME_LOCAL),
          od: 'o',
          dir: 1,
          odOptions: this.component.searchConditionScheduleOdOptions
        };

        this.scheduleDateTimeService = {
          asMoment() {
            return moment(that.schedule.dateTime, HTML5_FMT.DATETIME_LOCAL);
          },
          verifyRange() {
            const now = moment().startOf('minutes');
            const scheduleDateTime = moment(that.schedule.dateTime, HTML5_FMT.DATETIME_LOCAL);

            if (!scheduleDateTime.isValid()) {
              return undefined;
            }

            if (scheduleDateTime.isBefore(now, 'minutes')) {
              return -1;
            }

            if (moment(scheduleDateTime).startOf('days').isAfter(moment(now).add(that.component.reservableDays, 'days').startOf('days'), 'days')) {
              return 1;
            }

            return 0;
          }
        };

        this.spotService = {
          getFormalNameOrAddress(spot) {
            return spot.favorite?.name 
              || spot.place?.name
              || spot.history?.name
              || spot.placeResult && (spot.placeResult?.name || that.component.spotGeolocationService.formatGeocoderResultAddress(spot.placeResult) || `${spot.placeResult.geometry.location.lat()}, ${spot.placeResult.geometry.location.lng()}`)
              || (spot.geocoderResult && that.component.spotGeolocationService.formatGeocoderResultAddress(spot.geocoderResult))
              || (spot.geocoderResult?.geometry.location ? `${spot.geocoderResult.geometry.location.lat()}, ${spot.geocoderResult.geometry.location.lng()}` : "");
          },
          getGeometryLocation(spot) {
            return spot.placeResult?.geometry?.location
              || spot.arbitraryLocation
              || spot.geocoderResult?.geometry.location;
          },
          getLocation(spot) {
            return spot.favorite?.location
              || spot.place?.location
              || spot.history?.location
              || that.spotService.getGeometryLocation(spot);
          },
          getNameOrAddress(spot) {
            return spot.favorite?.name
              || that.spotService.getFormalNameOrAddress(spot);
          },
          getTitle(spot) {
            return !!spot.useCurrentPosition ? '現在地' : that.spotService.getNameOrAddress(spot);
          },
          isSelected(spot) {
            return !!spot.favorite
              || !!spot.place
              || !!spot.history
              || !!spot.placeResult
              || !!spot.geocoderResult
              || !!spot.useCurrentPosition;
          }
        };
      }

      //=========================================
      // 関数定義
      //=========================================

      get canSearch() {
        return this._canSearch;
      }

      get passengers() {
        return this._passengers;
      }

      // addGuestPassenger(exUser: ExUser) {
      //   if (this.existsPassenger(exUser.user_id)) {
      //     // ゲストとして追加済みなので、エラー
      //     throw new Error();
      //   }

      //   /** ゲストとして有効な制限時刻 */
      //   const expirationTime = this.getGuestExpirationTime();

      //   if (this.component.config.guest.slideEachExpirations) {
      //     // 他のゲストの制限時刻も更新
      //     this.passengers.guests.forEach(e => {
      //       e.expirationTime = expirationTime;
      //     });
      //   }

      //   this.passengers.guests.push({
      //     user: {
      //       user_id: exUser.user_id,
      //       name: exUser.profile.name,
      //       icon: exUser.profile.icon
      //     },
      //     checked: true,
      //     expirationTime: expirationTime
      //   });

      //   this.updateCanSearchValue();
      // }

      /**
       * ゲスト搭乗者を初期化する。
       *
       * @memberof SearchConditionImpl
       */
      // deleteGuestPassenger(): void {
      //   this.passengers.guests = [];
      // }

      /**
       *
       *
       * @return {*} 
       * @memberof SearchConditionImpl
       */
      assertInputModel() {
        /** スポットの座標を取得するObservableを返す */
        const spotLocationObservable = (spot: UserReservation.SearchConditionSpot) => {
          return new Observable<google.maps.LatLng | google.maps.LatLngLiteral>(subscriber => {
            if (!spot.useCurrentPosition) {
              const location = this.spotService.getLocation(spot);

              if (location) {
                subscriber.next(location);
              }

              subscriber.complete();
            } 
            else {
              // getCurrentPosition の呼び出しは一回で済ませたいとも考えたが、そもそも〈出発地〉と〈目的地〉の両方に『現在地』を指定するようなイレギュラーな操作のために無駄なコードを書くのもナンセンス。
              this.component.spotGeolocationService.getCurrentPosition().subscribe({
                next: result => {
                  const location = result.position && this.component.spotGeolocationService.createLatLng(result.position.coords);

                  if (location) {
                    new google.maps.Geocoder().geocode({location}, (results, status) => {
                      // spotの座標や住所を記録
                      spot.geocoderResult = (status === google.maps.GeocoderStatus.OK) && results[0];

                      subscriber.next(spot.geocoderResult?.geometry.location);
                      subscriber.complete();
                    });
                  } 
                  else {
                    subscriber.next();
                    subscriber.complete();
                  }
                }
              });
            }
          })
        };

        /** スポットの座標を取得時のエラーを流すObservableを返す */
        const spotErrorObservable = (od: 'o' | 'd') => {
          return new Observable<Error>(subscriber => {
            spotLocationObservable(this[od]).subscribe({
              next: location => {
                if (location) {
                  if (!this.component.spotGeolocationService.maxValidBounds.contains(location)) {
                    // 運用していない座標が返却された場合
                    subscriber.next(new Error(this.component.msg.CLIENT.DISPATCH.E_VALID_BOUNDS.message(this.getTargetPlaceKindName(od))))
                  }
                } 
                else {
                  // 座標がない場合
                  subscriber.next(new Error(this.component.msg.CLIENT.COMMON.E_UNEXPECTED.message()));
                }
              },
              complete: () => {
                subscriber.complete();
              }
            });
          });
        };

        /** 設定時刻のエラーを返すObservable */
        const scheduleErrorObservable = new Observable<Error>(subscriber => {
          /** 現在時刻(但し、秒数は00) */
          const now = moment().startOf('minutes');

          if (this.schedule.useCurrentTime) {
            // 現在時刻
            this.schedule.dateTime = now.format(HTML5_FMT.DATETIME_LOCAL);
          }

          switch (this.scheduleDateTimeService.verifyRange()) {
            case -1:
              // 過去の時刻が指定されている
              subscriber.next(new Error(this.component.msg.CLIENT.DISPATCH.E_VERIFY_PAST.message()));

              break;
            case 1:
              // 予約できないほど先の時刻が指定されている
              subscriber.next(new Error(this.component.msg.CLIENT.DISPATCH.E_VERIFY_FUTURE.message((this.component.reservableDays + 1).toString())));

              break;
            case 0:
              break;
            default:
              // note: 発着時刻が無効な場合は [検索] ボタンが押せないようになっているため、発着時刻の有効性についてはあらかじめ検証済みであるものとする。
              break;
          }

          subscriber.complete();
        });

        return new Observable<Error[]>(subscriber => {
          let errors: Error[] = [];

          concat(
            spotErrorObservable('o'),
            spotErrorObservable('d'),
            scheduleErrorObservable
          ).subscribe({
            next: error => {
              errors.push(error);
            },
            complete: () => {
              subscriber.next(errors);
              subscriber.complete();
            }
          });
        });
      }

      /**
       * ゲストが登場済みか判定する。
       *
       * @param {number} user_id
       * @return {*}  {number} 
       *              指定IDがログインユーザ：1
       *              指定IDがファミリメンバー：2
       *              指定IDがゲスト：3
       *              含まれない：0
       * @memberof SearchConditionImpl
       */
      existsPassenger(user_id: string) :number {  // ゲストがすでに追加されているかの判定用メソッド

        const includesUserId = (source: UserReservation.SearchConditionPassengersElement[]) => source
          .map(e => e.user.user_id)
          .includes(user_id);

        if (this.passengers.self.user.user_id === user_id) return 1;
        else if (includesUserId(this.passengers.families)) return 2;
        else if (includesUserId(this.passengers.guests)) return 3;
        else ;

        return 0;
      }

      /**
       *乗客の姓名を返す
       *
       * @param {UserReservation.SearchConditionPassengersElement} passenger
       * @return {*} "苗字""名前"
       * @memberof SearchConditionImpl
       */
      formatPassengerName(passenger: UserReservation.SearchConditionPassengersElement): string {
        return passenger.user.name.family_name + passenger.user.name.given_name;
      }

      /**
       * 乗車ユーザーの人数を返す
       *
       * @return {*}  {number}
       * @memberof SearchConditionImpl
       */
      getPassengersCount(): number {
        return this.getPassengersUsers().length;
      }

      /**
       * 乗車ユーザーのuser_id配列を返す
       *
       * @return {*}  {number[]}
       * @memberof SearchConditionImpl
       */
      getPassengersUserIds(): string[] {
        return this.getPassengersUsers().map(e => e.user?.user_id);
      }

      /**
       * 乗車ユーザーの情報を返す
       *
       * @return {*}  {UserReservation.SearchConditionPassengersElement[]}
       * @memberof SearchConditionImpl
       */
      getPassengersUsers(): UserReservation.SearchConditionPassengersElement[] {
        /** 乗車予定のユーザを返す */
        const checkedUsers = (source: UserReservation.SearchConditionPassengersElement[]) => source.filter(e => e.checked);

        return [
          ...checkedUsers([this.passengers?.self || {
            user: undefined,
            checked: false
          }]),
          ...checkedUsers(this.passengers?.families || []),
          ...checkedUsers(this.passengers?.guests || [])
        ];
      }

      getTargetPlaceKindName(od: 'o' | 'd'): string {
        return { o: '出発地', d: '目的地' }[od];
      }

      getPassengerName(user_id: string): string {
        let strName: string = '';

        this._passengers.families.forEach(element => {
          if (element.user.user_id==user_id) {
            strName = element.user.name.family_name + ' ' + element.user.name.given_name;
          }
        });
        this._passengers.guests.forEach(element => {
          if (element.user.user_id==user_id) {
            strName = element.user.name.family_name + ' ' + element.user.name.given_name;
          }
        });
        if (this.passengers.self.user.user_id == user_id) {
          strName = this.passengers.self.user.name.family_name + ' ' + this.passengers.self.user.name.given_name;
        }

      return strName;
      }

      initPassengers(user?: UserInfo, families?: ExUser[]) { // TODO: ファミリーinitを消したい
        this._passengers = {
          self: {
            user: user && {
              user_id: user.user_id,
              name: user.profile.name,
              icon: user.profile.icon
            },
            checked: this.passengers?.self.checked || true
          },
          families: families?.map<UserReservation.SearchConditionPassengersElement>(u => ({
            user: {
              user_id: u.user_id,
              name: u.profile.name,
              icon: u.profile.icon
            },
            checked: false
          })) || [],
          guests: []
        };
      }

      initFamilies(families?: Relation[]) {
        let familiesInfo: UserReservation.SearchConditionPassengersElement[] =[];
        if (families && families[0] && families[0].role == "child") {
          for (let i = 0; i < families.length; i++) {
            if (families[i].status != "waiting") {
              let family: UserReservation.SearchConditionPassengersElement = {
                user: {
                  user_id: families[i].user_id,
                  name: families[i].name,
                  icon: ""
                },
                checked: this.passengers?.families.find(u => u.user.user_id == families[i].user_id)?.checked || false
              }
              familiesInfo.push(family);
            }
          }
        }
        this._passengers.families = familiesInfo;
      }

      // reenableGuestPassenger(guest: UserReservation.SearchConditionPassengersElement) {
      //   if (!this.component.config.guest.reenableExpiration) {
      //     // 有効期限更新不可
      //     return;
      //   }

      //   if (guest.checked) {
      //     /** 更新されたゲスト有効期限 */
      //     const expirationTime = this.getGuestExpirationTime();

      //     if (this.component.config.guest.slideEachExpirations) {
      //       this.passengers.guests.forEach(e => {
      //         e.expirationTime = expirationTime;
      //       });
      //     } 
      //     else {
      //       guest.expirationTime = expirationTime;
      //     }
      //   }

      //   this.updateCanSearchValue();
      // }

      returnHere() {
        this.component.returnHere();
      }

      setSpot(od: 'o' | 'd', spot: UserReservation.SearchConditionSpot) {
        const current = { ... this[od] };

        this[od] = {
          ...spot,
          freeText: spot.freeText || current.freeText
        };

        this.updateCanSearchValue();
      }

      swapSpots() {
        const temp = this.o;

        this.o = this.d;
        this.d = temp;

        this.updateCanSearchValue();
      }

      updateCanSearchValue() {
        this._canSearch = this.spotService.isSelected(this.o)
          && this.spotService.isSelected(this.d)
          && (this.schedule.useCurrentTime || (this.scheduleDateTimeService.verifyRange() === 0))
          && (this.getPassengersCount() > 0);
      }

      // validateGuestPassengers(time: number, postAction: () => void)
      // {
      //   const expired = this.passengers.guests.filter(e => e.expirationTime && (e.expirationTime < time));
      //   const expiredUserIds = expired.map(e => e.user.user_id);
      //   const valid = this.passengers.guests.filter(e => !expiredUserIds.includes(e.user.user_id));

      //   this.passengers.guests = [...valid];

      //   if (expired.length > 0) 
      //   {
      //     this.component.appMsg.viewClientMsg(this.component.appMsg.CLIENT_CODE.RESERVATION.EXPIRED_GUEST).then(() =>
      //     {
      //       this.component._navigator.element.resetToPage(ServiceComponent);
      //     });

      //     return;
      //   }

      //   postAction();
      // }

      /**
       * 現時刻からゲストとして有効な制限時刻を返す
       *
       * @private
       * @return {*}  {number}
       * @memberof SearchConditionImpl
       */
      // private getGuestExpirationTime(): number {
      //   if (this.component.config.guest.validTime) {
      //     return moment().add(this.component.config.guest.validTime, 'seconds').valueOf();
      //   }

      //   return null;
      // };
    }

    this.searchCondition = new SearchConditionImpl(this);

    this.onUserInfoChanged = this.userWebApiService.userInfoChanged.subscribe(() => {
      this.busyUser = this.getCurrentUserInfo().subscribe({
        next: a => {

          // console.log("前回値：", this.beforeUserStatus, "今回値：", a.user?.status);

          this.searchCondition.initPassengers(a.user);

          // ログイン→未ログイン（ログアウト）
          // user.status: signed > undefined, registed > undefined
          if (!a.user) {
            // 利用するタブから起動しているサインイン画面が残っている場合は処理しない
            if (this.pagerServ.getUserAppNavigator === false) {

              for (const spot of [this.searchCondition.o, this.searchCondition.d]) {
                delete spot.favorite;
                delete spot.history;
              }
              delete this.searchCondition;
              this.searchCondition = new SearchConditionImpl(this);
              this.searchCondition.initPassengers();

              this.returnHere();

              // 下部タブバーを再表示
              this.tabbarComp.setTabbarVisibility(true);
            }

            // 前回値を保存
            this.beforeUserStatus = a.user?.status;
            return;
          }

          // 乗客情報のファミリー部分を初期化  NOTE: 操作代行アプリの場合、規約未合意のファミリーメンバーは含まれない
          this.searchCondition.initFamilies(this.familyWebApiService.getFamilyInfo()); // TODO: initPassengers()の中のファミリー部分を消した後は不要
          this.searchCondition.updateCanSearchValue();

          // 2022/10/26 wada
          // 「利用する」タブでユーザ情報が変更された場合のみ遷移処理を実施。
          // 想定しているシチュエーション：
          // 未ログイン状態で「利用する」タブの各機能を使おうとしてログインを求められ実施した場合。
          // 　→本対応時点で、「利用する」タブを操作しているときにユーザ情報が変更される場合がほかにないため。
          // 　 今後ユーザ情報が変更される場合が生じた際には別途対応が必要。
          // 　 また、未ログイン→ログイン後の遷移は「利用する」タブ内の全タブが対象となるため、
          // 　 処理自体別の機能としてまとめておくほうが良い（当初実装されていたのがおでかけタブのみだったためここで実装されている）
          // 
          // 以下、説明
          // ユーザ情報の変更検知はログイン/ログアウト時だけなく、プロフィール、支払い方法、通知方法などに遷移した場合にも生じる。
          // そのため、従来のままでは配車の予約確認画面まで行った状態で支払い方法を変更しにいくと、「利用する」タブがすべて初期化されてしまっていた。
          // ユーザ情報変更時と現在表示している画面の組み合わせによって遷移処理を切り分ける。
          // console.log("ユーザ情報変更を検知。操作中のタブ番号（利用する：0からの連番）: ", this.tabbarComp.getActiveTab());

          // 未ログイン→ログイン
          // user.status: undefined > signed, undefined > registed
          if (this.beforeUserStatus === undefined) {
            // メインタブ：「利用する」操作中
            if (this.tabbarComp.getActiveTab() === this.tabbarComp.tabComponent.reservation) {
              // サブタブ：「おでかけ」操作中
              // if (this.useListComp.getActiveTab() === this.useListComp.useListComponent.reservation) this.returnHere();
              // // 上記以外のサブタブ操作中
              // else this._navigator.element.popPage();
            }
            // 上記以外のメインタブ操作中
            else {
              // サブタブ：「おでかけ」操作中
              if (this.useListComp.getActiveTabTag() === CONST.Common.SERVICE_TAG.RESERVATION) this.returnHere();
              // 上記以外のサブタブ操作中
              else ;
            }
          }
          // ログイン状態でユーザ情報が変更
          // user.status: signed > registed, registed > registed
          else ;
          
          // 前回値を保存
          this.beforeUserStatus = a.user?.status;
        },
        error: this.httpErrorResponseParserService.doParse((error, customErrorContent) => {
          this.httpErrorHandler(error, customErrorContent);
        })
      });
    });

    //ファミリー情報が更新された時に選択可能な乗客の変更
    this.onFamilyInfoChanged = this.familyWebApiService.familyInfoChenged.subscribe(
      response => {
        this.searchCondition.initFamilies(response);
        this.searchCondition.updateCanSearchValue();
      }
    );

    // マイスポット情報が更新された際に「マイスポットから検索」の値を変更
    this.onFavoriteChanged = this.favoriteWebApiService.favoriteChanged.subscribe({
      next: event => {
        /** [出発地,目的地]のうち、更新されたマイスポットを指定しているもの */
        const spots = [this.searchCondition.o, this.searchCondition.d].filter(spot => spot.favorite?.favorite_id === event.favoriteId);

        if (spots.length == 0) {
          return;
        }

        switch (event.type) {
          case "updated": {
            // マイスポット更新
            this.busyFavorite = this.favoriteWebApiService.findFavorite<FavoriteWebApiSchemas.ResponseSchemas.Favorite>(event.favoriteId).subscribe({
              next: response => {
                const favorite = response.body;

                spots.forEach(spot => {
                  spot.favorite = favorite;
                });

                this.searchCondition.updateCanSearchValue();
                this.searchCondition.returnHere();
              },
              // ログイン済みのユーザーでなければ〈お気に入り〉の変更は発生しえないので、ここのエラー処理では 401 かどうかの判定は行わない。
              error: this.httpErrorResponseParserService.doParse((error, customErrorContent) => {
                this.httpErrorHandler(error, customErrorContent);
              })
            });

            break;
          }
          case "deleted": {
            // マイスポット削除
            spots.forEach(spot => {
              delete spot.favorite;
            });

            this.searchCondition.updateCanSearchValue();
            this.searchCondition.returnHere();

            break;
          }
          default:
            break;
        }
      }
    });

    this.busyPosition = this.spotGeolocationService.getCurrentPosition().subscribe({
      next: result => {
        if (result.position && this.spotGeolocationService.maxValidBounds.contains(this.spotGeolocationService.createLatLng(result.position.coords))) {
          this.searchCondition.setSpot('o', { useCurrentPosition: true });
        }
      }
    });

    /**
     * 検索条件を初期化
     */
    const refreshUserSelect = () => {
      
      // 「現在時刻」に設定
      this.userCurrentTimeSeg.nativeElement.setActiveButton(0);
      this.toggleScheduleUseCurrentTime(0);

      // 「出発」に設定
      this.odSeg.nativeElement.setActiveButton(0);
      this.toggleScheduleOd(0);

      // 検索条件削除
      delete this.searchCondition;

      // 検索条件初期化
      this.searchCondition = new SearchConditionImpl(this);

      // ログインユーザー設定
      this.searchCondition.initPassengers(this.userWebApiService.getUserInfo());

      // 乗客情報のファミリー部分を初期化  NOTE: 操作代行アプリの場合、規約未合意のファミリーメンバーは含まれない
      this.searchCondition.initFamilies(this.familyWebApiService.getFamilyInfo()); // TODO: initPassengers()の中のファミリー部分を消した後は不要
      this.searchCondition.updateCanSearchValue();
    }

    // ページスタックが削除されて、この画面に戻ってきたことを監視
    const pagerSubject = this.pagerServ.subject.subscribe({
      next: (key: number) => {
        if (key == PageKey.UserReservationComponent) refreshUserSelect();
      },
    });
    this.subscription.add(pagerSubject);
  }

  /**
   * 
   *
   * @memberof UserReservationComponent
   */
  ngAfterViewInit(): void {
    // 配車サービストップ画面に戻る為のページ情報保存
    this.pagerServ.setReturnPage({ index: this._navigator.element.pages.length - 1, key: PageKey.UserReservationComponent });
  }

  /**
   * コンポーネントが破棄される際に呼び出されるメソッド。
   *
   * @memberof UserReservationComponent
   */
  ngOnDestroy(): void {
    [this.busyPosition, this.busyUser, this.busyFamily, this.busyFavorite, this.busyAssert, this.onUserInfoChanged, this.onFamilyInfoChanged, this.onFavoriteChanged, this.subscription].forEach(s => {
      s?.unsubscribe();
    });
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 〈出発地/目的地〉を指定する。
   *
   * @param {('o' | 'd')} od 〈出発地/目的地〉の種別を表すコード。
   * @memberof UserReservationComponent
   */
  selectSpot(od: 'o' | 'd'): void {
    this._navigator.element.pushPage(SearchMethodListComponent, { data: { condition: this.searchCondition, kind: od }});
  }

  /**
   * セグメント (現在時刻/時刻選択) を切り替える。
   *
   * @param {number} index アクティブになったボタン (〈現在時刻〉、あるいは〈時刻選択〉) のインデックス。
   * @memberof UserReservationComponent
   */
  toggleScheduleUseCurrentTime(index: number): void {
    const useCurrentTime = [true];

    this.searchCondition.schedule.useCurrentTime = useCurrentTime[index];
  }

  /**
   * セグメント (出発/到着) を切り替える。
   *
   * @param {number} index アクティブになったボタン (〈出発〉、あるいは〈到着〉) のインデックス。
   * @memberof UserReservationComponent
   */
  toggleScheduleOd(index: number): void {
    const od: ('o' | 'd')[] = ['o', 'd'];

    this.searchCondition.schedule.od = od[index];

    if (this.searchCondition.schedule.od=='o') {
      this.searchCondition.schedule.dir = 1;
    }
    else if (this.searchCondition.schedule.od=='d') {
      this.searchCondition.schedule.dir = -1;
    }
  }

  /**
   * 〈荷物積載情報〉を指定する。
   *
   * @memberof UserReservationComponent
   */
  pushBaggage() {
    this._navigator.element.pushPage(BaggageComponent, {
      animation: 'lift',
      data: [this.searchCondition]
    });
  }

  /**
   * 〈乗車人数〉を指定する。
   *
   * @memberof UserReservationComponent
   */
  pushRide() {
    this._navigator.element.pushPage(RideCountComponent, {
      animation: 'lift',
      data: [this.searchCondition]
    });
  }

  /**
   * 移動プランを検索する。
   *
   * @memberof UserReservationComponent
   */
  search(): void {
    if (this.userWebApiService.isLoggedIn()) {
      if (this.userWebApiService.getUserInfo().status === "registed") {
        // ファミリー管理者から制限されている場合は遷移不可
        this.busyFamily = this.familyWebApiService.getFamily().subscribe();
        if (this.familyWebApiService.isLoginUserUtilization()) {
          this.appMsg.viewDialogMessage(this.msg.CLIENT.FAMILY.RESTRICTED_UTILIZATION.message());
          return;
        }
      
        // 入力物のエラーチェック
        this.busyAssert = this.searchCondition.assertInputModel().subscribe({
          next: errors => {
            if (errors.length > 0) {
              ons.notification.alert({
                messageHTML: [this.msg.CLIENT.DISPATCH.INVALID_CONDITION.message(), '<ul style="text-align:left;">', ...errors.map(e => `<li>${e.message}</li>`), '</ul>'].join(''),
                title: null
              });
            } 
            else {
              // チェックOK
              this._navigator.element.pushPage(SearchResultListComponent, {
                data: [this.searchCondition]
              });
            }
          }
        });
      }
    } 
    else {
      // 未ログインの場合は、ログインまたはアカウント作成を促すメッセージを表示し、サインイン画面へ遷移
      this.appMsg.viewDialogMessage(this.msg.CLIENT.COMMON.E_UNLOGIN_USE_FUNCTION.message(), () => {
        this.pagerServ.setUserAppNavigator = true;
        this.pagerServ.getAppNavigator.element.pushPage(SigninComponent);
      });
    }
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * 入力中の日時の妥当性を検証する。
   *
   * @memberof UserReservationComponent
   */
  assertScheduleDateTime(): void {
    this.validationErrorMessages.delete(this.SCHEDULE_DATE_TIME_ERROR_MESSAGE_KEY);

    if (!this.searchCondition.schedule.useCurrentTime) {
      switch (this.searchCondition.scheduleDateTimeService.verifyRange()) {
        case -1:
          // 過去の日時を指定
          this.validationErrorMessages.set(this.SCHEDULE_DATE_TIME_ERROR_MESSAGE_KEY, this.msg.CLIENT.DISPATCH.E_VERIFY_PAST.message());

          break;
        case 1:
          // 1週間以上先の日時を指定
          this.validationErrorMessages.set(
            this.SCHEDULE_DATE_TIME_ERROR_MESSAGE_KEY, 
            this.msg.CLIENT.DISPATCH.E_VERIFY_FUTURE.message((this.reservableDays + 1).toString())
          );

          break;
        case 0:
          // OK
          break;
        default:
          break;
      }
    }

    this.searchCondition.updateCanSearchValue();
  }

  /**
   * このコンポーネントのページへ戻る
   *
   * @private
   * @memberof UserReservationComponent
   */
  private returnHere(): void {
    const lastIndex = this._navigator.element.pages.length - 1;

    if (this.currentPageIndex < lastIndex) {
      this._navigator.element.removePage(this.currentPageIndex + 1).then(() => {
        setTimeout(() => {
          this.returnHere();
        }, 0);
      });
    }
  }
  
  /**
   * ログインしている場合、UserInfoを流す(必要？)
   *
   * @memberof UserReservationComponent
   */
  private getCurrentUserInfo(): Observable<{user?: UserInfo}> {
    return new Observable(subscriber => {
      if (!this.userWebApiService.isLoggedIn()) {
        subscriber.next({});
        subscriber.complete();
      } 
      else {
        const user = this.userWebApiService.getUserInfo();
        subscriber.next({user: user});
        subscriber.complete()
      }
    });
  }

  private httpErrorHandler(_error: HttpErrorResponse, customErrorContent: HttpCustomErrorContent) {
    if (this.httpErrorHandled) 
    {
      return;
    }

    this.httpErrorHandled = true;

    this.appMsg.viewDialogMessage(customErrorContent.smartGotoErrMessage || this.msg.CLIENT.COMMON.E_UNEXPECTED.message(), () => 
    {
      this._navigator.element.resetToPage(ServiceComponent);
    });
  }
}
