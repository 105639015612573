<ons-toolbar class="toolbar">
  <div class="left">
    <ons-back-button></ons-back-button>
  </div>
  <div class="center">住所の編集</div>
</ons-toolbar>
<div class="content">
  <div [ngBusy]="m_Busy"></div>

  <form id="mySpotForm" #formCheck="ngForm" class="formCheck">
    <div class="address-list">
      <p class="address-list__title">
        住所<span class="required-mark">※</span>
      </p>
      <dl class="address-list__item">
        <dt class="address-title">郵便番号</dt>
        <dd class="address-data">
          <ons-input id="proxy-account-profile-edit_001" inputmode="numeric" [(ngModel)]="m_Relation.address.zip" name="zip" #zipModel="ngModel"
              (ngModelChange)="zipEmptyMessageClear()" pattern="^\d{7}$" maxlength="7" required
              autocomplete="postal-code" placeholder="1234567" class="input--short zip"></ons-input>
          <ons-button id="proxy-account-profile-edit_002" [disabled]="zipModel.errors" (click)="addressAutocomplete()" class="address-button">住所自動入力</ons-button>
          <div class="valid-msg" *ngIf="zipModel.errors && (zipModel.dirty || zipModel.touched)">
            <span *ngIf="zipModel.errors.required">必須です。</span>
            <span *ngIf="zipModel.errors.pattern">半角数字７桁で入力ください。</span>
            <span *ngIf="zipModel.errors.maxlength">最大７文字です。</span>
          </div>
          <div class="valid-msg" *ngIf="m_ZipEmptyMessage.length > 0"><span>{{ m_ZipEmptyMessage }}</span></div>
        </dd>
      </dl>
      <dl class="address-list__item">
        <dt class="address-title">都道府県</dt>
        <dd class="address-data">
          <ons-input id="proxy-account-profile-edit_003" [(ngModel)]="m_Relation.address.prefecture" name="prefecture" #prefectureModel="ngModel"
              autocomplete="address-level1" placeholder="都道府県" required></ons-input>
          <div class="valid-msg" *ngIf="prefectureModel.errors && (prefectureModel.dirty || prefectureModel.touched)">
            <span *ngIf="prefectureModel.errors.required">必須です。</span>
          </div>
        </dd>
      </dl>
      <dl class="address-list__item">
        <dt class="address-title">市区町村</dt>
        <dd class="address-data">
          <ons-input id="proxy-account-profile-edit_004" [(ngModel)]="m_Relation.address.city" name="city" #cityModel="ngModel" autocomplete="address-level2"
              placeholder="市区町村" required></ons-input>
          <div class="valid-msg" *ngIf="cityModel.errors && (cityModel.dirty || cityModel.touched)">
            <span *ngIf="cityModel.errors.required">必須です。</span>
          </div>
        </dd>
      </dl>
      <dl class="address-list__item">
        <dt class="address-title">町域</dt>
        <dd class="address-data">
          <ons-input id="proxy-account-profile-edit_005" [(ngModel)]="m_Relation.address.address1" name="address1" #address1Model="ngModel" autocomplete="address-level3"
              placeholder="町域" required></ons-input>
          <div class="valid-msg" *ngIf="address1Model.errors && (address1Model.dirty || address1Model.touched)">
            <span *ngIf="address1Model.errors.required">必須です。</span>
          </div>
        </dd>
      </dl>
      <dl class="address-list__item">
        <dt class="address-title">番地以降</dt>
        <dd class="address-data">
          <ons-input id="proxy-account-profile-edit_006" [(ngModel)]="m_Relation.address.address2" name="address2" #address2Model="ngModel"
              placeholder="番地以降" required></ons-input>
          <div class="valid-msg" *ngIf="address2Model.errors && (address2Model.dirty || address2Model.touched || m_bAddressAutoCompleted)">
            <span *ngIf="address2Model.errors.required">必須です。</span>
          </div>
        </dd>
      </dl>
      <dl class="address-list__item">
        <dt class="address-title">建物名・<br />部屋番号</dt>
        <dd class="address-data">
          <ons-input id="proxy-account-profile-edit_007" [(ngModel)]="m_Relation.address.address3" name="address3" placeholder="建物名・部屋番号"></ons-input>
        </dd>
      </dl>
    </div>
    <div class="button-wrap">
      <ons-button modifier="large--cta" (click)="pushLocationEdit()" [attr.disabled]="formCheck.invalid ? '' : null">地図で場所を確認</ons-button>
    </div>
  </form>
</div>
