//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { OnsNavigator, Params } from 'ngx-onsenui';
import * as moment from 'moment-timezone';

//service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { DispatchWebApiService } from '../../../http-services/dispatch-web-api.service';
import { FavoriteWebApiSchemas, FavoriteWebApiService } from '../../../http-services/favorite-web-api.service';
import { SpotGeolocationService } from '../../../http-services/spot-geolocation.service';
import { UserWebApiService } from '../../../http-services/user-web-api.service';
import { OrderRequestService } from '../../../lib-services/order-request.service';
import { AllocationConditionService, targetKind } from '../../../lib-services/allocation-condition-service';
import { CommonFunctionModule } from '../../../lib-modules/common-function.module';

// component
import { PlaceMapComponent } from '../place-map/place-map.component';
import { UserReservation } from '../user-reservation/user-reservation';

// interface
import { PlaceHistory } from '../../../interfaces/response';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 検索方法指定画面。
 *
 * @export
 * @class SearchMethodListComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ons-page[search-method-list]',
  templateUrl: './search-method-list.component.html',
  styleUrls: ['./search-method-list.component.css']
})
export class SearchMethodListComponent implements OnInit, OnDestroy {

//=============================================================================================
// クラス変数
//=============================================================================================

  /**
   * 日付と時刻を扱うためのユーティリティ (Moment オブジェクト)。
   *
   * @memberof SearchMethodListComponent
   */
  readonly moment = moment;

  /**
   * 移動プランの検索条件。
   *
   * @type {UserReservation.SearchCondition}
   * @memberof SearchMethodListComponent
   */
  searchCondition: UserReservation.SearchCondition;

  /**
   * 〈出発地/目的地〉の種別を表すコード。
   *
   * @type {('o' | 'd')}
   * @memberof SearchMethodListComponent
   */
  targetPlaceKind: typeof targetKind;

  /**
   * 〈出発地/目的地〉の種別を表すコードを表示用の文字列に変換したもの。
   *
   * @type {string}
   * @memberof SearchMethodListComponent
   */
  targetPlaceKindName: string;

  /**
   * 『マイスポット』のリスト。
   *
   * @type {FavoriteWebApiSchemas.ResponseSchemas.Favorite[]}
   * @memberof SearchMethodListComponent
   */
  mySpots: FavoriteWebApiSchemas.ResponseSchemas.Favorite[] = [];

  /**
   * 『利用履歴』のリスト。
   *
   * @type {PlaceHistory[]}
   * @memberof SearchMethodListComponent
   */
  searchHistory: PlaceHistory[] = [];

  /**
   * 非同期処理の実行状態を表す。
   *
   * @type {Subscription}
   * @memberof SearchMethodListComponent
   */
  busyPosition: Subscription;

  /**
   * 非同期処理の実行状態を表す。
   *
   * @type {Subscription}
   * @memberof SearchMethodListComponent
   */
  busyMySpots: Subscription;

  /**
   * 非同期処理の実行状態を表す。
   *
   * @type {Subscription}
   * @memberof SearchMethodListComponent
   */
  busySearchHistory: Subscription;

  /**
   * 登録住所の表示有無
   *
   * @type {boolean}
   * @memberof SearchMethodListComponent
   */
  viewRegistAddress: boolean = true;

  private mySpotsSubject = new Subject<FavoriteWebApiSchemas.ResponseSchemas.Favorite[]>();

  private onUserInfoChanged: Subscription;
  private onFavoriteLoaded: Subscription;
  private onFavoriteChanged: Subscription;

  /**
   * HTTPエラーが既に発生しているかどうか
   *
   * @private
   * @memberof SearchMethodListComponent
   */
  private httpErrorHandled = false;

  /**
   * HTTP通信エラー時の処理
   *
   * @private
   * @memberof SearchMethodListComponent
   */
  private httpErrorHandler = this.errResServ.doParse((_error, errContent) => {
    // 既にHTTPエラーが発生して、このメソッドを呼んでいる場合は処理を抜ける
    if (this.httpErrorHandled) {
      return;
    }

    this.httpErrorHandled = true;

    this.errResServ.viewErrDialog(errContent).then(() => {
      this.searchCondition.returnHere();
    });
  });

  /**
   * assetsファイルへのパス(定数)
   *
   * @memberof SearchMethodListComponent
   */
  readonly ASSETS = {
    MARKER: CommonFunctionModule.getAssetsUrl('/image/common/52-Marker_A2.png'),
    NUMBER: CommonFunctionModule.getAssetsUrl('/image/common/18-Number.png'),
    MAP_POINT: CommonFunctionModule.getAssetsUrl('/image/common/50-MapPoint.png'),
    INACTIVE: CommonFunctionModule.getAssetsUrl('/image/common/46-PinC_Inactive2.png'),
  } as const;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of SearchMethodListComponent.
   * @param {OnsNavigator} _navigator
   * @param {UserWebApiService} userServ
   * @param {FavoriteWebApiService} favoriteServ
   * @param {DispatchWebApiService} dispatchServ
   * @param {SearchWebApiService} searchServ
   * @param {SpotGeolocationService} spotGeolocationServ
   * @param {ApplicationMessageService} appMsgServ
   * @param {HttpErrorResponseParserService} errResServ
   * @param {OrderRequestService} orderServ
   * @param {AllocationConditionService} allocationServ
   * @param {CommonFunctionModule} commonFunction
   * @param {Params} params
   * @memberof SearchMethodListComponent
   */
  constructor(
    private _navigator: OnsNavigator,
    private userServ: UserWebApiService,
    private favoriteServ: FavoriteWebApiService,
    private dispatchServ: DispatchWebApiService,
    private spotGeolocationServ: SpotGeolocationService,
    private appMsgServ: ApplicationMessageService, 
    private errResServ: HttpErrorResponseParserService,
    private orderServ: OrderRequestService, 
    private allocationServ: AllocationConditionService, 
    private commonFunction: CommonFunctionModule, 
    private params: Params
  ) { }

  /**
   * コンポーネントが初期化される際に呼び出されるメソッド。
   *
   * @memberof SearchMethodListComponent
   */
  ngOnInit(): void {
    
    this.searchCondition = this.params.data.condition;
    this.targetPlaceKind = this.params.data.kind;
    this.targetPlaceKindName = this.allocationServ.getTargetPlaceKindName(this.targetPlaceKind);

    // 買い物時に使用。観光目的ユーザの登録住所が設定されているか
    if (this.userServ.isLoggedIn() && this.userServ.getUserInfo().user_type === 'tourist') {
      if (false === this.commonFunction.checkAddressValidity(this.userServ.getUserInfo().profile.address))
        this.viewRegistAddress = false;
    }

    // マイスポット読み込み時
    this.onFavoriteLoaded = this.mySpotsSubject.subscribe({
      next: mySpots => {
        // マイスポットを初期化
        this.mySpots.length = 0;

        mySpots.forEach(mySpot => {
          // 位置情報のあるものだけを追加
          if (mySpot.location != null)
          {
            this.mySpots.push(mySpot);
          }
        });

        // 並び替え
        this.mySpots.sort((a, b) => a.index - b.index);
      }
    });

    /**
     *マイスポットを取り直す
     *
     */
    const loadAllFavorites = () => {
      if (!this.userServ.isLoggedIn()) {
        // 未ログインの場合
        this.mySpotsSubject.next([]);
      } else {
        this.busyMySpots = this.favoriteServ.allFavorites<FavoriteWebApiSchemas.ResponseSchemas.Favorite>().subscribe({
          next: response => {
            this.mySpotsSubject.next(response.body);
          },
          error: this.httpErrorHandler
        });
      }
    };

    /**
     *利用履歴を取り直す
     *
     */
    const loadSearchHistory = () => {
      if (!this.userServ.isLoggedIn()) {
        // 未ログイン
        this.searchHistory = [];
      } 
      else {
        this.busySearchHistory = this.dispatchServ.userReservations<PlaceHistory[]>({
          status: 'done',
          sort: (() => {
            let sort = {};

            sort[`${this.targetPlaceKind}.schd_time`] = -1;

            return sort;
          })(),
            limit: 10, // TODO: 件数は environment に定義する方向で。
            place_history: this.targetPlaceKind as 'o' | 'd'
          })
          .subscribe({
            next: response => {
              this.searchHistory = response.body;
            },
            error: this.httpErrorHandler
          });
      }
    }

    // ユーザ情報変更時
    this.onUserInfoChanged = this.userServ.userInfoChanged.subscribe({
      next: _ => {
        loadAllFavorites();
        loadSearchHistory();
      }
    });

    // マイスポット変更時
    this.onFavoriteChanged = this.favoriteServ.favoriteChanged.subscribe({
      next: _ => {
        loadAllFavorites();
      }
    });
  }

  /**
   * コンポーネントが破棄される際に呼び出されるメソッド。
   *
   * @memberof SearchMethodListComponent
   */
  ngOnDestroy(): void {

    [this.busyPosition, this.busyMySpots, this.busySearchHistory, this.onUserInfoChanged, this.onFavoriteLoaded, this.onFavoriteChanged].forEach(s => {
      s?.unsubscribe();
    });
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 『現在地』を〈出発地/目的地〉として設定する。
   *    ※おでかけのみ。
   *
   * @memberof SearchMethodListComponent
   */
  selectCurrentPosition(): void {

    this.busyPosition = this.spotGeolocationServ.getCurrentPosition().subscribe({
      next: result => {
        if (!result.position) {
          // TODO: 文言。
          this.appMsgServ.viewDialogMessage("現在地を取得できませんでした。");
        }
        else {
          // note: 現在地のセットはsearchCondition.assertInputModel()にて行っている
          this.searchCondition.setSpot(this.targetPlaceKind, { useCurrentPosition: true });
          this.searchCondition.returnHere();
        }
      }
    });
  }

  /**
   * 『登録住所』を〈お届け先〉として設定する。
   *    ※買い物のみ
   *
   * @memberof SearchMethodListComponent
   */
  selectRegistedAddress(): void {

    // 登録住所をお届け先に設定
    this.orderServ.setDeliveryPlace({
      name: this.commonFunction.conversionAddress(this.userServ.getUserInfo().profile.address, true), 
      address: this.commonFunction.conversionAddress(this.userServ.getUserInfo().profile.address), 
      location: this.userServ.getUserInfo().profile.address.location
    });

    // お届け先のスポット情報を設定
    this.allocationServ.setSpot(this.targetPlaceKind, { registerAddress: true });

    // ページバックし、配達プランを取得（お届け先画面の場合）
    this.params.data.callMethod();
    this._navigator.element.popPage();
  }

  /**
   * 〈出発地/目的地/お届け先>を地図から指定する。
   *
   * @memberof SearchMethodListComponent
   */
  selectByMap(): void {

    this._navigator.element.pushPage(PlaceMapComponent, {
      data: { 
        condition: this.searchCondition, 
        kind: this.targetPlaceKind, 
        callMethod: this.params.data.callMethod
      }
    });
  }

  /**
   * 『マイスポット』を〈出発地/目的地/お届け先>として設定する。
   *
   * @param {FavoriteWebApiSchemas.ResponseSchemas.Favorite} favorite 設定する『マイスポット』。
   * @memberof SearchMethodListComponent
   */
  selectMySpot(favorite: FavoriteWebApiSchemas.ResponseSchemas.Favorite): void {

    /**
     *  マイスポットの緯度経度から位置情報を取得
     */
    const geocodeObservable = new Observable<google.maps.GeocoderResult>(subscriber => {
      if (!favorite.location) {
        subscriber.next();
        subscriber.complete();
      }
      else {
        new google.maps.Geocoder().geocode({ location: favorite.location }, (results, status) => {
          subscriber.next((status === google.maps.GeocoderStatus.OK) && results[0]);
          subscriber.complete();
        });
      }
    });

    /**
     * マイスポットを出発地/目的地に設定：おでかけ
     *
     * @param {UserReservation.SearchConditionSpot} spot
     */
    const reservProc = (spot: UserReservation.SearchConditionSpot) => {
      // 出発地/目的地のスポット情報を設定
      this.searchCondition.setSpot(this.targetPlaceKind, spot);

      // ページバック
      this.searchCondition.returnHere();
    }

    /**
     * マイスポットをお届け先に設定：買い物
     *
     * @param {UserReservation.SearchConditionSpot} spot
     */
    const orderProc = (spot: UserReservation.SearchConditionSpot) => {
      // カートサービスにお届け先を設定
      this.orderServ.setDeliveryPlace({
        name: favorite.name, 
        favorite_id: favorite.favorite_id, 
        location: { lat: favorite.location.lat, lng: favorite.location.lng }
      });

      // お届け先のスポット情報を設定
      this.allocationServ.setSpot(this.targetPlaceKind, spot);

      // 呼び出し元のメソッド
      this.params.data.callMethod();

      // ページバック
      this._navigator.element.popPage();
    }

    this.busyMySpots = geocodeObservable.subscribe({
      next: geocoderResult => {
        // 住所が取得できたら
        if (geocoderResult) {
          if (this.targetPlaceKind !== 'order') reservProc({ favorite, geocoderResult });
          else orderProc({ favorite, geocoderResult });
        }
        else {
          this.appMsgServ.viewDialogMessage("住所の取得に失敗しました。", () => {
            if (this.targetPlaceKind !== 'order') this.searchCondition.returnHere();
            else this._navigator.element.popPage();
          });
        }
      }
    });
  }
}