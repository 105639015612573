//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit } from '@angular/core';
import { Params } from 'ngx-onsenui';

// service
import { DispatchWebApiService } from '../../../http-services/dispatch-web-api.service';

// component
import { UserReservation } from '../user-reservation/user-reservation';

// interface
import { common } from '../../../interfaces/common';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 乗車料金画面。
 *
 * @export
 * @class PassengerFareComponent
 * @implements {OnInit}
 * @author （株）オブジェクトデータ wada
 */
@Component({
  selector: 'ons-page[passenger-fare]',
  templateUrl: './passenger-fare.component.html',
  styleUrls: ['./passenger-fare.component.css']
})
export class PassengerFareComponent implements OnInit 
{

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * 移動プランの検索条件。
   *
   * @type {UserReservation.SearchCondition}
   * @memberof PassengerFareComponent
   */
  searchCondition: UserReservation.SearchCondition;
  
  /**
   * 利用者ごとの料金、利用チケット情報
   *
   * @type {common.BillPassenger}
   * @memberof PassengerFareComponent
   */
  customerBill: common.BillPassenger[] = [];

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of PassengerFareComponent.
   * @param {Params} params 遷移元のページから渡されるパラメーター。
   * @param {DispatchWebApiService} dispatchWebApiService
   * @memberof PassengerFareComponent
   */
  constructor(
    private params: Params, 
    private dispatchWebApiService: DispatchWebApiService
  ) { }

  /**
   * コンポーネントが初期化される際に呼び出されるメソッド。
   *
   * @memberof PassengerFareComponent
   */
  ngOnInit(): void 
  {
    let bill: common.customerBill;
    [this.searchCondition, bill] = this.params.data;

    if (bill.user) this.customerBill.push(bill.user);
    bill.families.forEach((f) => this.customerBill.push(f));
    // bill.guests.forEach((g) => this.customerBill.push(g));
  }

  //=============================================================================================
  // イベントハンドラ
  //=============================================================================================

  /**
   * 料金種別（表示名）を取得する。
   *
   * @param {string} property
   * @return {*}  {string}
   * @memberof PassengerFareComponent
   */
  getAmoutProperty(property: string): string 
  {
    return this.dispatchWebApiService.getAmountPropertyString(property);
  }
}