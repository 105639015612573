//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Params, OnsNavigator } from 'ngx-onsenui';
import * as ons from 'onsenui';

import { ApplicationMessageService } from '../../lib-services/application-message.service'
import { UserWebApiService } from '../../http-services/user-web-api.service';
import { HttpErrorResponseParserService } from '../../lib-services/http-error-response-parser.service';
import { ChangePasswordCompleteComponent } from './change-password-complete/change-password-complete.component';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * パスワード再設定画面。
 *
 * @export
 * @class ChangePasswordComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @author （株）オブジェクトデータ wada
 */
@Component({
  selector: 'ons-page[change-password]',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit, OnDestroy 
{

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * ユーザーID（メールアドレス）
   *
   * @type {string}
   * @memberof ChangePasswordComponent
   */
  m_strUserName: string = null;

  /**
   * パスワード
   *
   * @type {string}
   * @memberof ChangePasswordComponent
   */
  m_strPassword: string = null;
  
  /**
   * パスワード（確認用）
   *
   * @type {string}
   * @memberof ChangePasswordComponent
   */
  m_strPasswordConfirm: string = null;

  /**
   * 通常遷移/メールリンク遷移の判定
   *
   * @type {boolean}
   * @memberof ChangePasswordComponent
   */
  m_bMailTransition: boolean = false;
  
  /**
   * Observable戻り値のSubscriptionオブジェクト
   *
   * @type {Subscription}
   * @memberof ChangePasswordComponent
   */
   m_Busy: Subscription;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of ChangePasswordComponent.
   * @param {OnsNavigator} _navigator
   * @param {UserWebApiService} userWebApiService
   * @param {HttpErrorResponseParserService} httpErrorResponseParserService
   * @param {Params} params
   * @memberof ChangePasswordComponent
   */
  constructor(
    private _navigator: OnsNavigator,
    private msg: MESSAGE,
    private appMsg: ApplicationMessageService, 
    private userWebApiService: UserWebApiService,
    private httpErrorResponseParserService: HttpErrorResponseParserService,
    private params: Params
  ) { }

  /**
   * 初期化処理。
   *
   * @memberof ChangePasswordComponent
   */
  ngOnInit(): void 
  {
    // 通常遷移かメールリンクからの遷移かを判定
    if (!this.params?.data?.token ?? false ) this.m_bMailTransition = false;
    else this.m_bMailTransition = true;
  }

  /**
   * 廃棄処理。
   *
   * @memberof ChangePasswordComponent
   */
  ngOnDestroy(): void 
  {
    this.m_Busy?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * リンク送信ボタンタップ時のイベントハンドラ。
   *    パスワード再設定メール送信。
   *
   * @memberof ChangePasswordComponent
   */
  sendLink(): void
  {
    // メールアドレスに該当するユーザに再設定メールを送信
    this.m_Busy = this.userWebApiService.sendChangePasswordMail(this.m_strUserName).subscribe(
      {
        // 該当ユーザが存在する場合、メール送信結果へ遷移
        next: () => this._navigator.element.pushPage(ChangePasswordCompleteComponent, { data: { mailAddress: this.m_strUserName }}),
        error: this.httpErrorResponseParserService.doParse((_error, customErrorContent) => 
        {
          let msg = this.msg.CLIENT.CHANGE_PASSWORD.E_NOT_USER.message();
          
          if (undefined == customErrorContent.statusCode) {
            msg = this.msg.CLIENT.COMMON.E_UNEXPECTED.message();
            customErrorContent.smartGotoErrMessage = this.msg.CLIENT.COMMON.E_UNEXPECTED.message();
          }
          else if (404 != customErrorContent.statusCode) this.msg.CLIENT.COMMON.E_UNEXPECTED.message();

          this.appMsg.viewDialogErrorMessage(msg, customErrorContent.smartGotoErrMessage);
        })
      }
    );
  }

  /**
   * パスワードを再設定ボタンタップ時のイベントハンドラ。
   *    パスワード再設定処理実行。
   *
   * @return {*}  {void}
   * @memberof ChangePasswordComponent
   */
  changePassword(): void
  {
    // パスワード一致チェック
    if (this.m_strPassword !== this.m_strPasswordConfirm)
    {
      this.appMsg.viewDialogMessage(this.msg.CLIENT.COMMON.E_PASSWORD_DIFFERENT.message());
      return;
    }

    // パスワード変更処理
    this.m_Busy = this.userWebApiService.changePassword(this.params.data.token, this.m_strPassword).subscribe(
      {
        // 変更完了後、再設定完了へ遷移
        next: () => 
        {
          // 履歴を上書き
          history.replaceState(null, null, window.location.href.replace(window.location.search, ""));
          this._navigator.element.pushPage(ChangePasswordCompleteComponent);
        },
        error: this.httpErrorResponseParserService.doParse((_error, customErrorContent) => 
        {
          if (404 != customErrorContent.statusCode) 
          {
            let msg = this.msg.CLIENT.COMMON.E_UNEXPECTED.message();
            if (undefined == customErrorContent.statusCode) customErrorContent.smartGotoErrMessage = msg;
            ons.notification.alert(msg + "<br><br>詳細：<br>" + customErrorContent.smartGotoErrMessage, { title: null });
          }
          else 
            ons.notification.alert(customErrorContent.smartGotoErrMessage, { title: null });
          // console.log(_error);
        })
      }
    );
  }
}
