//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { OnsNavigator, Params } from 'ngx-onsenui';
import * as CONST from '../../../constants/constant';

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { UserWebApiService } from '../../../http-services/user-web-api.service';
import { ShoppingWebApiService } from "../../../http-services/shopping-web-api.service";
import { OrderRequestService } from "../../../lib-services/order-request.service";
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';

// module
import { CommonFunctionModule } from '../../../lib-modules/common-function.module';

// component
import { SearchMethodListComponent } from '../../reservation/search-method-list/search-method-list.component';
import { ShoppingDatermineComponent } from '../shopping-datermine/shopping-datermine.component';

// interface
import { common } from '../../../interfaces/common';
import { request } from '../../../interfaces/request';
import { DeliveryPlan, UserInfo } from '../../../interfaces/response';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * お届け先設定画面。
 *
 * @export
 * @class ShoppingAddressComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ons-page[shopping-address]',
  templateUrl: './shopping-address.component.html',
  styleUrls: ['./shopping-address.component.css']
})
export class ShoppingAddressComponent implements OnInit, OnDestroy {

//=============================================================================================
// メンバ変数
//=============================================================================================

  /**
   * 通信用Subscription
   *
   * @type {Subscription}
   * @memberof NewsArticleListComponent
   */
  busy: Subscription;

  /**
   * ユーザが選択した配送プラン
   *
   * @type {common.DeliveryPlan[]}
   * @memberof ShoppingAddressComponent
   */
  deliveryPlanList: common.DeliveryPlan[];

  /**
   * 配達日
   *
   * @type {common.selectBox[]}
   * @memberof ShoppingAddressComponent
   */
  selectDate: common.selectBox[] = [];

  /**
   * 配達時間帯
   *
   * @type {object}
   * @memberof ShoppingAddressComponent
   */
  selectTime: object = {};

  /**
   * 日付/時間帯の選択内容
   *
   * @memberof ShoppingAddressComponent
   */
  selected: {

    /**
     * 日付
     *
     * @type {string}
     */
    date: string;
    
    /**
     * 時間帯
     *
     * @type {string}
     */
    time: string;

    /**
     * 配送料
     *
     * @type {number}
     */
    amount: number;
  }

  /**
   * 配送料
   *
   * @type {number}
   * @memberof ShoppingAddressComponent
   */
  charges: number;

  /**
   * ページインデックス
   *
   * @private
   * @type {number}
   * @memberof ShoppingAddressComponent
   */
  private currentPageIndex: number;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of ShoppingAddressComponent.
   * @param {ApplicationMessageService} appMsgServ
   * @param {HttpErrorResponseParserService} errResServ
   * @param {CommonFunctionModule} commonFunction
   * @param {UserWebApiService} userServ
   * @param {ShoppingWebApiService} shoppingServ
   * @param {OrderRequestService} orderServ
   * @param {OnsNavigator} navigator
   * @param {Params} params
   * @memberof ShoppingAddressComponent
   */
  constructor(
    private appMsgServ: ApplicationMessageService, 
    private errResServ: HttpErrorResponseParserService, 
    private commonFunction: CommonFunctionModule, 
    private userServ: UserWebApiService, 
    private shoppingServ: ShoppingWebApiService, 
    private orderServ: OrderRequestService, 
    private navigator: OnsNavigator, 
    private params: Params,
    private municipalityWebApiServ: MunicipalityWebApiService,
    private msg: MESSAGE,
  ) { }

  /**
   * 初期処理。
   *
   * @memberof ShoppingComponent
   */
  ngOnInit(): void {

    // 初期化
    this.init();

    // 現在のページ番号
    this.currentPageIndex = this.navigator.element.pages.length - 1;
    
    if (this.orderServ.deliveryPlace.address || this.orderServ.deliveryPlace.favorite_id || this.orderServ.deliveryPlace.location)
      // 配達プランを取得
      this.getDeliveryPlan();
  }

  /**
   * 破棄処理
   *
   * @memberof ShoppingComponent
   */
  ngOnDestroy(): void {

    this.busy?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 配達日変更時のイベントハンドラ。
   *
   * @memberof ShoppingAddressComponent
   */
  changeDeliveryDate(): void {
    
    // 時間帯、配送料設定
    this.selected.time = this.selectTime[this.selected.date][0].value;
    this.selected.amount = this.selectTime[this.selected.date][0].amount;

    // 配達プランを設定
    this.setDeliveryPlan()
  }

  /**
   * 配達時間変更時のイベントハンドラ。
   *
   * @memberof ShoppingAddressComponent
   */
  changeDeliveryTime(): void {

    // 配送料設定
    this.selected.amount = this.selectTime[this.selected.date].find((time: common.selectBox) => time.value === this.selected.time).amount;
    
    // 配達プランを設定
    this.setDeliveryPlan()
  }

  /**
   * お届け先ボタンのイベントハンドラ。
   *
   * @memberof ShoppingAddressComponent
   */
  onSearchMethod(): void {

    this.navigator.element.pushPage(SearchMethodListComponent, { data: { kind: "order", callMethod: () => this.getDeliveryPlan() }});
  }

  /**
   * 注文確認ボタンのイベントハンドラ。
   *
   * @memberof ShoppingAddressComponent
   */
  onCheckOrder(): void {

    this.navigator.element.pushPage(ShoppingDatermineComponent, { data: { class: this.params.data.class }});
  }

  /**
   * お届け先（表示名）を取得する。
   *
   * @return {*}  {string}
   * @memberof ShoppingAddressComponent
   */
  getDeliveryAddress(): string {

    return this.orderServ.deliveryPlace.name;
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * 初期化を行う。
   *
   * @private
   * @memberof ShoppingAddressComponent
   */
  private init(): void {

    // 日付/時間帯/選択状態
    this.selectDate = [];
    this.selectTime = {};
    this.selected = { date: "", time: "", amount: 0 };
  }

  /**
   * お届け先選択（地図から選択）からのページバックを行う。
   *
   * @private
   * @memberof ShoppingAddressComponent
   */
  private returnHere(): void {
    const lastIndex = this.navigator.element.pages.length - 1;

    if (this.currentPageIndex < lastIndex) {
      this.navigator.element.removePage(this.currentPageIndex + 1).then(() => {
        setTimeout(() => { this.returnHere(); }, 0);
      });
    }
    else {
      this.getDeliveryPlan();
    }
  }

  /**
   * 配達プランを設定する。
   *
   * @private
   * @memberof ShoppingAddressComponent
   */
  private setDeliveryPlan(): void {
    
    this.deliveryPlanList.forEach(p => {
      if (p.plan_id === this.selected.time) this.orderServ.deliveryPlan = p;
    });
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * 配達プランを取得する。
   *
   * @private
   * @memberof ShoppingAddressComponent
   */
  private getDeliveryPlan(): void {

    // 初期化
    this.init();
    
    // 緯度経度が無ければエラー(登録住所を選択時にありうる)
    if (!this.orderServ.deliveryPlace.location) {
      // エラー
      this.appMsgServ.viewDialogMessage(this.msg.CLIENT.SHOPPING.NO_LATLNG.message());
      return;
    }

    this.orderServ.deliveryPlace.google_map_url = this.orderServ.deliveryPlace.location !== undefined ? this.commonFunction.getGoogleMapUrl(this.orderServ.deliveryPlace.location) : this.commonFunction.getGoogleMapUrl(this.orderServ.deliveryPlace.address);

    // クエリ生成
    let reqBody: request.Order = {
      shop_id: this.orderServ.getOrderShop.shop_id, 
      items: [], 
      destination: this.orderServ.deliveryPlace
    };

    // 商品を登録（オプションは現時点ではないため空）
    this.orderServ.getCartItems.forEach(item => {
      reqBody["items"].push({
        order_item_id: 0, 
        user_id: "", 
        order_time: "", 
        menu_id: item.menu.menu_id, 
        name: item.menu.name, 
        count: item.count, 
        options: []
      });
    });

    // 仮注文を行い、注文IDを取得
    this.busy = this.shoppingServ.getOrderFetch(reqBody).subscribe({ 
      next: res => {
        const deliveryPlans: DeliveryPlan[] = res.body;

        // 配達プランがない
        if (deliveryPlans.length === 0) {
          this.appMsgServ.viewDialogMessage(this.msg.CLIENT.SHOPPING.ERR_NO_PLAN.message());
          return;
        }

        // 日付/時間帯の配達リストを生成
        this.deliveryPlanList = this.shoppingServ.getSoppingPlan(deliveryPlans);
        this.charges = this.deliveryPlanList[0].total.price;
        this.orderServ.deliveryPlan = this.deliveryPlanList[0];
        
        // 日付リストを生成
        this.deliveryPlanList.forEach(p => {

          if (undefined === this.selectDate.find(s => s.value === p.shop[0].day)) {
            this.selectDate.push({
              value: p.shop[0].day, 
              label: p.shop[0].day
            });
          }

          p.shop.forEach(s => {
            
            let time: object = {
              value: p.plan_id, 
              label: s.time_zone.from_str + "～" + s.time_zone.to_str, 
              amount: p.total.price
            };

            if (this.selectTime[s.day] === undefined) this.selectTime[s.day] = new Array(time);
            else this.selectTime[s.day].push(time);
          });
        });

        // 日付/時間帯（プランID）リストの初期値を設定
        this.selected = {
          date: this.deliveryPlanList[0].shop[0].day, 
          time: this.deliveryPlanList[0].plan_id, 
          amount: this.deliveryPlanList[0].total.price
        }
      },
      error: this.errResServ.doParse((_err, errContent) => {
        // 緯度経度が無ければエラー(基本サーバー通信前に除外している)
        if (errContent.smartGotoErrCode == this.appMsgServ.SERV_CONST_CODE.COMMON.LOCATION_NOT_EXIST) {
          this.appMsgServ.viewDialogMessage(this.msg.CLIENT.SHOPPING.NO_LATLNG.message());
        }
        else if (errContent.smartGotoErrCode == this.appMsgServ.SERV_CONST_CODE.COMMON.USER_TERM_NEVER_AGREED) {
          // 規約合意していないユーザーとしてメッセージに表示する文字列を取得
          const neverAgreeUser: string = this.municipalityWebApiServ.getTermNeverAgreedUser(errContent);
          // サーバーレスポンスが想定外
          if (neverAgreeUser === "") return this.errResServ.viewErrDialog(errContent);
          // 規約合意していないエラー
          else this.appMsgServ.viewDialogMessage(this.msg.CLIENT.SHOPPING.E_NOT_TERM_AGREE.message(neverAgreeUser));
        }
        else this.errResServ.viewErrDialog(errContent);
      })
    });
  }
}
