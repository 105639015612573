<ons-toolbar>
  <div class="left "><ons-back-button #onsBackButton></ons-back-button></div>
  <div class="center">{{ getViewStatus() }}</div>
</ons-toolbar>

<div [ngBusy]="busy"></div>
<div class="content">
  <ng-container *ngIf="getViewResult() !== ''">
    <div class="content--message">{{ getViewResult() }}</div>
  </ng-container>

  <!-- header -->
  <parts-ons-list [listInfo]="template.header"></parts-ons-list>

  <!-- body -->
  <ng-container *ngFor="let body of template.body1">
    <parts-ons-list [listInfo]="body"></parts-ons-list>
    <div class="text--right" *ngIf="body.id=='amount'"><span class="icon--warning">！</span> 料金についてご不明な点がある場合は店舗へご連絡ください。</div>
  </ng-container>

  <!-- オプション -->
  <ng-container *ngIf="template.optionGroup.length !== 0">
    <ng-container *ngFor="let group of template.optionGroup">
      <ng-container *ngIf="group.options.length !== 0">

        <div class="list-item__caption">
          <span *ngIf="group.options.length === 1" class="exp-option-wrap__item">
            <span>{{group.options[0].name}}</span>
            &nbsp;<button #optionDialog class="info_button" style="border: solid 1px !important;" (click)="onClickOptionInfo(group.options[0])"><img [src]="ASSETS.INFORMATION"></button>
          </span>
          <ng-container *ngIf="group.options.length >= 2">{{group.name}}</ng-container>
        </div>
        
        <ons-list *ngFor="let option of group.options" class="list-item__text">
  
          <ons-list-item modifier="nodivider">
            <div class="option-time">
              <span *ngIf="group.options.length >= 2">
                <span style="overflow-wrap: anywhere;">{{ option.name }}</span>
                &nbsp;<button #optionDialog class="info_button" style="border: solid 1px !important;" (click)="onClickOptionInfo(option)"><img [src]="ASSETS.INFORMATION"></button>
              </span>

              <span style="overflow-wrap: anywhere;" *ngIf="option.user_option === 'yesno'">{{selectedOption[option.sg_option_id].yesno_param.name}}</span>
              <span style="overflow-wrap: anywhere;" *ngIf="option.user_option === 'select'">{{selectedOption[option.sg_option_id].select_param.name}}</span>
              <span *ngIf="option.user_option === 'number'">
                <span>{{selectedOption[option.sg_option_id].number_param.selected}}</span>
                <span style="overflow-wrap: anywhere;">{{option.unit}}</span>
              </span>
              <span style="overflow-wrap: anywhere;" *ngIf="option.user_option === 'comment'">{{selectedOption[option.sg_option_id].comment}}</span>
            </div>
          </ons-list-item>
  
  
          <ons-list-item modifier="nodivider" *ngIf="option.time_rule === 'days'">

            <div style="padding-left: 15px;" class="option-time">
              <span *ngIf="option.time_rule === 'days'" style="overflow-wrap: anywhere;">利用日数</span>
              <span *ngIf="option.time_rule === 'mins'" style="overflow-wrap: anywhere;">利用時間</span>

              <span>
                <span>{{selectedOption[option.sg_option_id].time_param.selected}}</span>
                <span style="overflow-wrap: anywhere;" *ngIf="option.time_rule === 'days'">日</span>
                <span style="overflow-wrap: anywhere;" *ngIf="option.time_rule === 'mins'">分</span>
              </span>
            </div>
          </ons-list-item>
  
        </ons-list>

      </ng-container>
    </ng-container>
  </ng-container>
  <ng-container *ngFor="let body of template.body2">
    <parts-ons-list [listInfo]="body"></parts-ons-list>
  </ng-container>

  <!--【UI改善対応】テキストからボタンに変更-->
  <!--<div *ngIf="isServiceCancel()" class="attention">-->
  <!--  予約内容の変更・キャンセルは<ons-button modifier="quiet" (click)="onTransLink()"><span class="button-text">こちら</span></ons-button>-->
  <!--</div>-->
  <div *ngIf="isServiceCancel()" class="button-wrap">
    <ons-button (click)="onTransLink()" modifier="cta">予約の変更・キャンセル</ons-button>
  </div>
  <span class="br"></span>
</div>
