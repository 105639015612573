<ons-toolbar class="toolbar">
  <div class="left">
    <ons-back-button></ons-back-button>
  </div>
  <div class="center">認証コード入力</div>
</ons-toolbar>
<div class="content">
  <div [ngBusy]="busy"></div>
  <div class="center segment-wrap">
    <div class="segment">
      <ul class="stepbar stepbar__four">
        <li class="stepbar__item">STEP1</li>
        <li class="stepbar__item stepbar__item--current">STEP2</li>
        <li class="stepbar__item">STEP3</li>
        <li class="stepbar__item">STEP4</li>
      </ul>
    </div>
    <div>認証コードを入力してください。</div>
  </div>
  <ons-input id="login_001" [(value)]="username" hidden></ons-input>
  <p class="content--text">メールアドレス（{{username}}）に認証コードを送信しました。</p>
  <p class="content--text--attention">
    ※Yahoo!メールなど一部のメールサービスでは、送信されたメールが迷惑メールに振り分けられる可能性があります。<br />
    メールが届かない場合は、迷惑メールをご確認ください。
  </p>
  <p class="content--text">本人確認のために認証コードを入力してください。<br />※認証コードは30分間有効です。</p>

  <dl class="login__item">
    <dt class="login-title">認証コード：</dt>
    <dd class="login-data">
      <ons-input id="login_002" [(value)]="pincode" inputmode="numeric"></ons-input>
    </dd>
  </dl>
  <div class="button-wrap">
    <ons-button id="login_004" modifier="large--cta" (click)="signup()">次へ</ons-button>
  </div>
</div>
