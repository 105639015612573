<ons-toolbar class="toolbar">
  <div class="left">
    <ons-back-button></ons-back-button>
  </div>
  <div class="right">
    <ons-toolbar-button class="edit-cancel-button" (click)="cancel()">登録中止</ons-toolbar-button>
  </div>
  <div class="center">新規会員登録</div>
</ons-toolbar>
<div class="content">
  <div class="center segment-wrap">
    <div class="segment" *ngIf="viewStep.valueOf() === true">
      <ul class="stepbar stepbar__four">
        <li class="stepbar__item">STEP1</li>
        <li class="stepbar__item">STEP2</li>
        <li class="stepbar__item stepbar__item--current">STEP3</li>
        <li class="stepbar__item">STEP4</li>
      </ul>
    </div>
    <div class="segment" *ngIf="viewStep.valueOf() === false">
      <ul class="stepbar stepbar__two">
        <li class="stepbar__item stepbar__item--current">STEP1</li>
        <li class="stepbar__item">STEP2</li>
      </ul>
    </div>
  </div>

  <div class="content--message">お迎え・配達を迅速に行うために、地図上でご住所の入り口にピンを置いてください。
  </div>

  <div class="button-wrap">
    <ons-button modifier="large--cta" (click)="pushSignupDetermine()" [disabled]="!location">位置情報を確定し次へ</ons-button>
  </div>

  <parts-location-update style="height: 100%;" [address]="userInfo.profile.address" [isGetLocation]="isEdit" (locationEvent)="updateLocation($event)"></parts-location-update>
</div>