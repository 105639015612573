//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { OnsNavigator, Params } from 'ngx-onsenui';
import * as CONST from '../../../constants/constant';

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { OrderRequestService, CartAddedItems, orderRequestResult } from "../../../lib-services/order-request.service";
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';

// component
import { TabbarComponent } from "../../../components/tabbar/tabbar.component";
import { menuProcMode, ShoppingMenuDetailComponent } from '../shopping-menu-detail/shopping-menu-detail.component';
import { ShoppingAddressComponent } from '../shopping-address/shopping-address.component';

// interface
import { Shop } from '../../../interfaces/response';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * カート画面。
 *
 * @export
 * @class ShoppingCartComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ons-page[shopping-cart]',
  templateUrl: './shopping-cart.component.html',
  styleUrls: ['./shopping-cart.component.css']
})
export class ShoppingCartComponent implements OnInit, OnDestroy {

//=============================================================================================
// メンバ変数
//=============================================================================================

  /**
   * 通信用Subscription
   *
   * @type {Subscription}
   * @memberof NewsArticleListComponent
   */
  busy: Subscription;

  /**
   * 注文先店舗
   *
   * @type {Shop}
   * @memberof ShoppingCartComponent
   */
  shop: Shop;

  /**
   * カート内商品一覧
   *
   * @type {CartAddedItems[]}
   * @memberof ShoppingCartComponent
   */
  items: CartAddedItems[] = [];
  
  /**
   * カートに追加することができる最大個数
   *
   * @type {number}
   * @memberof ShoppingCartComponent
   */
  maxOrderItemCount: number;

  /**
   * セレクトボックス用の個数配列
   *
   * @type {number[]}
   * @memberof ShoppingCartComponent
   */
  countArray: number[] = [];

  /**
   * 選択中の個数配列
   *
   * @type {string}
   * @memberof ShoppingCartComponent
   */
  selectedCount: selectedCount[] = [];
  
  /**
   * ons-back-button
   *
   * @private
   * @type {ElementRef}
   * @memberof ShoppingCartComponent
   */
  @ViewChild('onsBackButton') private onsBackButton: ElementRef;

//=============================================================================================
// コンストラクタ
//=============================================================================================

  /**
   * Creates an instance of ShoppingCartComponent.
   * @param {ApplicationMessageService} appMsgServ
   * @param {HttpErrorResponseParserService} errResServ
   * @param {OrderRequestService} orderServ
   * @param {ChangeDetectorRef} cd
   * @param {OnsNavigator} navigator
   * @param {Params} params
   * @memberof ShoppingCartComponent
   */
  constructor(
    private appMsgServ: ApplicationMessageService, 
    private errResServ: HttpErrorResponseParserService, 
    private orderServ: OrderRequestService, 
    private tabComp: TabbarComponent, 
    private cd: ChangeDetectorRef, 
    private navigator: OnsNavigator, 
    private params: Params,
    private municipalityWebApiServ: MunicipalityWebApiService,
    private msg: MESSAGE,
  ) {
    this.maxOrderItemCount = this.municipalityWebApiServ.setting.shopping.max_order_item_count;
  }

  /**
   * 初期処理。
   *
   * @memberof ShoppingComponent
   */
  ngOnInit(): void {

    this.shop = this.orderServ.getOrderShop;
    this.items = this.orderServ.getCartItems;

    // セレクトボックス用、個数配列を作成
    this.createCountArray();

    // セレクトボックス表示用データを作成
    this.createSelectedCount();
  }

  /**
   * afterViewInit
   *
   * @memberof ShoppingCartComponent
   */
  ngAfterViewInit(): void {

    // バックボタン
    this.onsBackButton.nativeElement.onClick = () => {

      // タブバー表示
      this.tabComp.setTabbarVisibility(true);

      // メニュー一覧再描画
      this.navigator.element.popPage({ animation: "lift" }).then(() => this.params.data.redraw());
    };
  }

  /**
   * 破棄処理
   *
   * @memberof ShoppingComponent
   */
  ngOnDestroy(): void {
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 商品の個数変更・削除説明ボタンのイベントハンドラ。
   *
   * @memberof ShoppingCartComponent
   */
  // onDescription(): void {

  //   // 商品の個数変更・削除の説明を表示
  //   this.appMsgServ.viewClientMsg(this.appMsgServ.CLIENT_CODE.SHOPPING.DESCRIPTION);
  // }

  /**
   * メニュー選択時のイベントハンドラ。
   *
   * @param {number} index
   * @memberof ShoppingMenuListComponent
   */
  onItemSelect(index: number): void {

    const procMode: menuProcMode = {
      mode: "update",
      delete_permit: true
    }

    // メニュー詳細へ遷移
    this.navigator.element.pushPage(ShoppingMenuDetailComponent, {
      data: { 
        shop: this.shop,
        menu: this.items[index].menu,
        method: () => this.redraw(),
        detailMode: procMode,
        returnMenuList: () => { this.params.data.returnMenuList() }
      }
    });
  }
  
  /**
   * お届け先入力ボタンのイベントハンドラ。
   *
   * @memberof ShoppingCartComponent
   */
  onAddressInput(): void {

    // お届け先へ遷移
    this.navigator.element.pushPage(ShoppingAddressComponent, { data: { class: this.params.data.class }});
  }

  /**
   * 合計料金を取得する。
   *
   * @return {*}  {number}
   * @memberof ShoppingCartComponent
   */
  getTotalPrice(): number {
    
    return this.orderServ.getTotalPrice();
  }

  /**
   * 対象メニューの個数変更
   *
   * @param {string} menu_id
   * @memberof ShoppingCartComponent
   */
  changeCount(menu_id: string) {

    // 操作対象メニューのインデックスを取得
    const targetSelectedCount: number = this.selectedCount.findIndex(ele => ele.menu_id == menu_id);

    // 合計が10個以内の場合、個数変更を行う
    if (this.checkCount() == true) {
      this.orderServ.changeMenuCount(menu_id, this.selectedCount[targetSelectedCount].count);
    }

    // 合計が10個を超えている場合、操作前の個数に戻す
    else {
      this.appMsgServ.viewDialogMessage(this.msg.CLIENT.SHOPPING.ERR_MAX_COUNT.message(String(this.maxOrderItemCount)), () => {
        this.selectedCount[targetSelectedCount].count = this.orderServ.getItemCount(menu_id);
      });
    }
  }

  /**
   * カートから商品を削除
   *
   * @param {*} event
   * @param {string} menu_id
   * @memberof ShoppingCartComponent
   */
  deleteMenu(event: any, menu_id: string) {

    // イベントの伝播を防止
    event.stopPropagation();

    // カートから商品を削除
    const result = this.orderServ.deleteMenu(menu_id);

    if (result == orderRequestResult.ERR_NOT_EXISTS_MENU_ID) {
      // 想定外
      this.appMsgServ.viewDialogMessage(this.msg.CLIENT.SHOPPING.ERR_DELETE_ITEM.message());
      return;
    }
    else {
      
      // 削除した商品の選択した個数情報を削除
      const newSelectedCount = this.selectedCount.filter(ele=> ele.menu_id != menu_id);
      this.selectedCount = newSelectedCount;

      // 再描画
      this.redraw();

      // カートに商品が存在しない場合
      if (this.orderServ.getCartItemCount == 0) {

        // ユーザ通知の上、メニュー一覧画面に戻る
        this.params.data.returnMenuList();

        return;
      }
    }
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * 画面の再描画を行う。
   *
   * @memberof ShoppingCartComponent
   */
  redraw(): void {

    // カート内情報を初期化
    this.items = this.orderServ.getCartItems;
    
    // セレクトボックスの個数を初期化
    this.createSelectedCount();

    // 再描画
    this.cd.detectChanges();
  }

  /**
   * セレクトボックス用の個数配列を作成
   *
   * @param {CartAddedItems} item
   * @return {*}  {string[]}
   * @memberof ShoppingCartComponent
   */
  createCountArray(): void {

    // 個数配列を作成
    for(let num=1; num<=this.maxOrderItemCount; num++) {
      this.countArray.push(num)
    }
  }

  /**
   * セレクトボックス表示用データを作成
   *
   * @memberof ShoppingCartComponent
   */
  createSelectedCount() {

    this.selectedCount = [];

    // カート内商品情報から、セレクトボックス表示用データを設定
    this.items.forEach((item) => {

      let selectedCount: selectedCount = {
        
        // 選択中の個数
        count: item.count,
        // メニューID
        menu_id: item.menu.menu_id,
      }

      this.selectedCount.push(selectedCount);
    })
  }

  /**
   * 合計個数が最大個数以下であるかチェック
   *
   * @return {*}  {boolean}
   * @memberof ShoppingCartComponent
   */
  checkCount(): boolean {

    // セレクトボックスを変更するとstring型になるため変換
    this.selectedCount.forEach(ele => ele.count = Number(ele.count));

    // カート内の合計個数を計算
    const result: number = this.selectedCount.reduce((totalCount, current) => totalCount + current["count"], 0);

    // 合計個数が最大個数以下であるか
    if (result <= this.maxOrderItemCount) return true;
    else return false;
  }


//=============================================================================================
// サーバ通信
//=============================================================================================

}

/**
 * セレクトボックスの情報
 *
 * @interface selectedCount
 */
interface selectedCount {
  
  /**
   * メニューID
   *
   * @type {string}
   * @memberof selectedCount
   */
  menu_id: string,

  /**
   * 選択中の個数
   *
   * @type {number}
   * @memberof selectedCount
   */
  count: number
}