<ons-toolbar class="toolbar">
  <div class="left">
    <ons-back-button></ons-back-button>
  </div>
  <div class="right">
    <ons-toolbar-button class="edit-cancel-button" (click)="cancel()">登録中止</ons-toolbar-button>
  </div>
  <div class="center">新規会員登録</div>
</ons-toolbar>
<div class="content">
  <div [ngBusy]="busy"></div>
  <div class="center segment-wrap">
    <div class="segment" *ngIf="viewStep.valueOf() === true">
      <ul class="stepbar stepbar__four">
        <li class="stepbar__item">STEP1</li>
        <li class="stepbar__item">STEP2</li>
        <li class="stepbar__item">STEP3</li>
        <li class="stepbar__item stepbar__item--current">STEP4</li>
      </ul>
    </div>
    <div class="segment" *ngIf="viewStep.valueOf() === false">
      <ul class="stepbar stepbar__two">
        <li class="stepbar__item">STEP1</li>
        <li class="stepbar__item stepbar__item--current">STEP2</li>
      </ul>
    </div>
    <div>下記の内容で登録を行います。<br />よろしいですか？</div>
  </div>

  <form id="profileCreateForm" #formCheck="ngForm" class="formCheck">
    <ons-list class="profile-list">
      <ons-list-header modifier="color" class="list-item__title list-item__title-wrap">観光目的の利用</ons-list-header>
      <ons-list-item id="profile-signup-determine_001" modifier="longdivider" class="list-item__text">
        <span>{{ isCitizen ? "いいえ" : "はい"}}</span>
      </ons-list-item>

      <ons-list-header modifier="color" class="list-item__title">氏名</ons-list-header>
      <ons-list-item id="profile-signup-determine_002" modifier="longdivider" class="list-item__text">
        {{ userInfo.profile.name.family_name }}
      </ons-list-item>
      <ons-list-item id="profile-signup-determine_003" modifier="longdivider" class="list-item__text">
        {{ userInfo.profile.name.given_name }}
      </ons-list-item>

      <ons-list-header modifier="color" class="list-item__title">氏名（かな）</ons-list-header>
      <ons-list-item id="profile-signup-determine_004" modifier="longdivider" class="list-item__text">
        {{ userInfo.profile.name.family_name_kana }}
      </ons-list-item>
      <ons-list-item id="profile-signup-determine_005" modifier="longdivider" class="list-item__text">
        {{ userInfo.profile.name.given_name_kana }}
      </ons-list-item>

      <ons-list-header modifier="color" class="list-item__title">生年月日</ons-list-header>
      <ons-list-item id="profile-signup-determine_006" modifier="longdivider" class="list-item__text">
        {{ userInfo.profile.birth.year }}
        <div class="mark--delimiter">/</div>
        {{ userInfo.profile.birth.month }}
        <div class="mark--delimiter">/</div>
        {{ userInfo.profile.birth.day }}
      </ons-list-item>

      <ons-list-header modifier="color" class="list-item__title">性別</ons-list-header>
      <ons-list-item id="profile-signup-determine_007" modifier="longdivider" class="list-item__text">
        {{ selectedSex }}
      </ons-list-item>

      <ons-list-header modifier="color" class="list-item__title">電話番号</ons-list-header>
      <ons-list-item id="profile-signup-determine_008" modifier="longdivider" class="list-item__text">
        {{ userInfo.profile.tel }}
      </ons-list-item>

      <ons-list-header modifier="color" class="list-item__title">メールアドレス</ons-list-header>
      <ons-list-item id="profile-signup-determine_009" modifier="longdivider" class="list-item__text">
        {{ userInfo.profile.mail }}
      </ons-list-item>
    </ons-list>

    <div class="address-list">
      <div class="address-list__title-wrap">
        <p class="address-list__title">住所</p>
        <a class="right address-list__title--link" target="_blank" [attr.href]="googleMapUrl">地図で位置を確認</a>
      </div>
      <dl class="address-list__item">
        <dt class="address-title">郵便番号</dt>
        <dd class="address-data list-item__center">
          {{ userInfo.profile.address.zip }}
        </dd>
      </dl>
      <dl class="address-list__item">
        <dt class="address-title">都道府県</dt>
        <dd class="address-data list-item__center">
          {{ userInfo.profile.address.prefecture }}
        </dd>
      </dl>
      <dl class="address-list__item">
        <dt class="address-title">市区町村</dt>
        <dd class="address-data list-item__center">
          {{ userInfo.profile.address.city }}
        </dd>
      </dl>
      <dl class="address-list__item">
        <dt class="address-title">町域</dt>
        <dd class="address-data list-item__center">
          {{ userInfo.profile.address.address1 }}
        </dd>
      </dl>
      <dl class="address-list__item">
        <dt class="address-title">番地以降</dt>
        <dd class="address-data list-item__center">
          {{ userInfo.profile.address.address2 }}
        </dd>
      </dl>
      <dl class="address-list__item">
        <dt class="address-title">建物名・<br />部屋番号</dt>
        <dd class="address-data list-item__center">
          {{ userInfo.profile.address.address3 }}
        </dd>
      </dl>
    </div>
    <div class="content--text--check-wrap">
      <ons-checkbox id="profile-signup-determine_010" [name]="terms" [attr.value]="terms[0]" [(ngModel)]="checkedTerms" class="left"></ons-checkbox>
      <p class="content--text--small">{{setting.name}}におけるアプリケーションおよびサービスの
        <ons-button id="profile-signup-determine_011" modifier="quiet" (click)="viewUsePolicy()">利用規約</ons-button> と <ons-button id="profile-signup-determine_012" modifier="quiet"
          (click)="viewPrivacyPolicy()">プライバシーポリシー</ons-button> に同意する
      </p>
    </div>
    <div class="button-wrap">
      <ons-button id="profile-signup-determine_013" modifier="large--cta noFixed" (click)="edit()"
        [disabled]="checkedTerms.length!==1">上記の内容で登録</ons-button>
    </div>
  </form>
</div>