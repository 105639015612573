import { ModuleWithProviders, NgModule } from '@angular/core';
import { DispatchWebApiServiceConfig } from '../http-services/dispatch-web-api.service';

/**
 * ユーティリティサービス (予約関連) に依存性を注入するためのインジェクターを構成するモジュール。
 *
 * @export
 * @class DispatchWebApiModule
 */
@NgModule()
export class DispatchWebApiModule {
  /**
   * DI トークンと依存性の関連付けを行う。
   *
   * @static
   * @param {DispatchWebApiServiceConfig} config Web API (予約関連) の構成設定。
   * @return {*}  {ModuleWithProviders<DispatchWebApiModule>} `ModuleWithProviders<DispatchWebApiModule>`
   * @memberof DispatchWebApiModule
   */
  static withConfig(config: DispatchWebApiServiceConfig): ModuleWithProviders<DispatchWebApiModule> {
    return {
      ngModule: DispatchWebApiModule,
      providers: [
        { provide: DispatchWebApiServiceConfig, useValue: config }
      ]
    };
  }
}
