import { Pipe, PipeTransform } from '@angular/core';

/**
 * 文字省略パイプ。
 *
 * @export
 * @class TypeofPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'omission'
})
export class OmissionPipe implements PipeTransform {

  /**
   * 文字列を省略形式に修飾する。
   *
   * @param {string} value
   * @param {number} maxlenght
   * @return {*}  {string}
   * @memberof OmissionPipe
   */
  transform(value: string, maxlenght: number): string {
    
    const trans = value.slice(0, maxlenght);
    return trans === value ? value : trans + "..."; 
  }
}