import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';
import { environment } from 'src/environments/environment';

/**
 * 自治体ごとアセット画像主力コンポーネント
 *
 * @export
 * @class ImageComponent
 * @implements {OnInit}
 * @implements {OnChanges}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'parts-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit, OnChanges, OnDestroy {

  /**
   * ImageURL
   *
   * @type {string}
   * @memberof ImageComponent
   */
  @Input('url') readonly url: string;

  /**
   * サーバーにリクエストするURL
   *
   * @type {string}
   * @memberof ImageComponent
   */
  processedUrl: string;

  /**
   * 自治体に該当URLのアセットが用意されているかどうか
   *
   * @type {boolean}
   * @memberof ImageComponent
   */
  isUrlExisting: boolean = false;

  /**
   * 自治体ごと設定情報のSubscriptionオブジェクト
   *
   * @private
   * @type {Subscription}
   * @memberof ImageComponent
   */
  private _settingChanged: Subscription;

  constructor(
    private _municipalityServ: MunicipalityWebApiService, 
  ) {  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.url.previousValue!=changes.url.currentValue) {
      this._settingChanged?.unsubscribe();  // 前回のsubscriptionを解除
      this._settingChanged = this._municipalityServ.settingsChanged.subscribe({
        next: setting => {
          if (setting) {
            this.processedUrl = `${environment.base_url}/${setting.municipal_id}/assets${this.url}`;  // NOTE: this.urlは"/"から始まる
            this.isUrlExisting = setting.assets?.includes(this.url) ?? false;  // 値を更新
          }
        }
      });
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this._settingChanged?.unsubscribe();
  }

}
