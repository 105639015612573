import { NgModule, ModuleWithProviders } from '@angular/core';
import { HistoryWebApiServiceConfig } from '../http-services/history-web-api.service';

@NgModule()
export class HistoryWebApiModule 
{ 
  static withConfig(config: HistoryWebApiServiceConfig): ModuleWithProviders<HistoryWebApiModule> {
    return {
      ngModule: HistoryWebApiModule,
      providers: [
        { provide: HistoryWebApiServiceConfig, useValue: config }
      ]
    };
  }
}
