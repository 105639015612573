import { Component, OnInit } from '@angular/core';
import { OnsNavigator } from 'ngx-onsenui';

// component
import { TabbarComponent } from '../../tabbar/tabbar.component';

// service
import { PagerService } from '../../../lib-services/pager.service';
import { GoogleTagManagerService } from '../../../lib-services/google-tag-manager.service';

// enum
import { PageKey } from '../../../lib-services/pager.service';

// module
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';


@Component({
  selector: 'ons-page[exp-complete]',
  templateUrl: './exp-complete.component.html',
  styleUrls: ['./exp-complete.component.scss']
})
export class ExpCompleteComponent implements OnInit {

  /**
   * Creates an instance of ExpCompleteComponent.
   * @param {OnsNavigator} navigator
   * @param {PagerService} pagerServ
   * @memberof ExpCompleteComponent
   */
  constructor(
    private navigator: OnsNavigator,
    private pagerServ: PagerService,
    private tabbarComp: TabbarComponent,
    private gtmServ: GoogleTagManagerService,
  ) { }

  ngOnInit(): void {
    this.gtmServ.pushPageview('/exp/reserve_complete/', '予約完了');
    this.gtmServ.pushFbqTrack('Purchase', {value: 0.00, currency: 'JPY'});
  }

  /**
   * ホームに戻るボタンクリック。
   * トップ画面に戻る。
   *
   * @memberof ExpCompleteComponent
   */
  onBackHome(): void {

    // タブバー再表示
    this.tabbarComp.setTabbarVisibility(true);

    // 体験サービストップ画面に遷移
    this.pagerServ.transitionToPage(this.navigator, PageKey.ExpComponent);
  }
}
