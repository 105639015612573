//=============================================================================================
// インポート
//=============================================================================================
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'exp-detail-option-dialog',
  templateUrl: './exp-detail-option-dialog.component.html',
  styleUrls: ['./exp-detail-option-dialog.component.scss']
})
export class ExpDetailOptionDialog {

  constructor(
    public dialogRef: MatDialogRef<ExpDetailOptionDialog>,
    @Inject (MAT_DIALOG_DATA) public data: {
      images: string[];
      optionName: string;
      description: string;
    }
  ) {}
   
  /**
   * 
   *
   * @memberof ExpDetailOptionDescriptionDialog
   */
  onNoClick(): void {
    this.dialogRef.close();
  }
}