import { Component, OnInit } from '@angular/core';
import { OnsNavigator, Params } from 'ngx-onsenui';
import { Subscription } from 'rxjs';

// service
import { UserWebApiService } from 'src/app/http-services/user-web-api.service';
import { FamilyWebApiService } from 'src/app/http-services/family-web-api.service';
import { HttpErrorResponseParserService } from 'src/app/lib-services/http-error-response-parser.service';
import { PageKey, PagerService } from 'src/app/lib-services/pager.service';
import { ApplicationMessageService } from 'src/app/lib-services/application-message.service';

// module
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';

// interface
import { UserInfo } from 'src/app/interfaces/response';
import { request } from 'src/app/interfaces/request';
import { MESSAGE } from 'src/app/constants/message';

@Component({
  selector: 'ons-page[app-location-address-edit]',
  templateUrl: './profile-location-edit.component.html',
  styleUrls: ['./profile-location-edit.component.scss']
})
export class ProfileLocationEditComponent implements OnInit {

  /**
   * 通信監視用, Subscription
   *
   * @type {Subscription}
   * @memberof ProfileLocationEditComponent
   */
  busy: Subscription;

  /**
   * 住所編集画面の入力値
   *
   * @type {UserInfo["profile"]["address"]}
   * @memberof ProfileLocationEditComponent
   */
  address: UserInfo["profile"]["address"];

  /**
   * 地図から選択した緯度経度
   *
   * @type {google.maps.LatLngLiteral}
   * @memberof ProfileLocationEditComponent
   */
  location: google.maps.LatLngLiteral;

  /**
   * 住所編集画面で、住所を編集したかどうか
   * 編集した場合、住所から緯度経度検索を行う
   *
   * @type {boolean}
   * @memberof ProfileLocationEditComponent
   */
  isGetLocation: boolean;

  /**
   * 住所、地点(緯度経度)編集する対象
   *
   * @type {EditMode}
   * @memberof ProfileLocationEditComponent
   */
  mode: EditMode;

  /**
   * リレーションID
   *
   * @type {number}
   * @memberof ProfileLocationEditComponent
   */
  relationId: number;

  /**
   * 実行可能な処理
   *  user: ログインユーザに対する編集
   *  proxy_user: 見守り対象アカウントに対する編集
   *
   * @memberof ProfileLocationEditComponent
   */
  readonly edit_mode = EDIT_MODE;

  /**
   * Creates an instance of ProfileLocationEditComponent.
   * @param {Params} params
   * @param {CommonFunctionModule} commonFunctionMdl
   * @param {UserWebApiService} userWebApiServ
   * @param {HttpErrorResponseParserService} errResServ
   * @param {PagerService} pagerServ
   * @param {OnsNavigator} navigator
   * @param {ApplicationMessageService} appMsg
   * @memberof ProfileLocationEditComponent
   */
  constructor(
    private params: Params,
    private commonFunctionMdl: CommonFunctionModule,
    private userWebApiServ: UserWebApiService,
    private errResServ: HttpErrorResponseParserService,
    private pagerServ: PagerService,
    private navigator: OnsNavigator,
    private appMsg: ApplicationMessageService,
    private familyWebApiServ: FamilyWebApiService,
    private msg: MESSAGE,
  ) { }

  ngOnInit(): void {

    /* 前画面から渡される値 */

    // 住所編集画面の入力値
    this.address = this.commonFunctionMdl.deepcopy(this.params.data.address);

    // 住所編集画面で、住所を編集したかどうか
    this.isGetLocation = this.params.data.addressEditted;

    // 住所、地点(緯度経度)編集する対象
    this.mode = this.params.data.mode;

    // (見守り対象アカウントの住所編集時のみ)リレーションID
    if (this.mode == EDIT_MODE.proxy_user) this.relationId = this.params.data.relation_id
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 選択中の緯度経度の更新
   *
   * @param {google.maps.LatLngLiteral} location
   * @memberof ProfileLocationEditComponent
   */
  updateLocation(location: google.maps.LatLngLiteral) {

    // 住所に該当する地点(緯度経度)が見つからない場合
    if (!location) {
      this.appMsg.viewDialogMessage(this.msg.CLIENT.PROFILE.NOT_EXIST_ADDRESS.message(), () => {
        this.navigator.element.popPage();
      });
      return;
    }

    this.location = this.commonFunctionMdl.deepcopy(location);
  }

//=============================================================================================
// サーバー通信
//=============================================================================================

  /**
   * ログインユーザ、プロフィール更新
   *
   * @memberof ProfileLocationEditComponent
   */
  editProfile() {

    // ユーザ情報
    let profile: UserInfo["profile"] = this.commonFunctionMdl.deepcopy(this.userWebApiServ.getUserInfo().profile);

    // 住所編集画面で入力された値を保存
    profile.address = this.commonFunctionMdl.deepcopy(this.address);

    // 地図から指定した地点情報(緯度経度)を保存
    profile.address.location = this.location;

    // プロフィール更新
    this.busy = this.userWebApiServ.updateProfile({address: profile.address}).subscribe({
      next: () => {
        // 登録完了ダイアログ
        this.appMsg.viewDialogMessage(this.msg.CLIENT.COMMON.COMPLETE_REGIST.message(), () => {
          // プロフィール一覧画面に遷移
          this.pagerServ.transitionToPage(this.navigator, PageKey.ProfileListComponent);
        });
      },
      error: this.errResServ.doParse((_err, errContent) => {
        this.errResServ.viewErrDialog(errContent);
      })
    });
  }

  /**
   * 見守り対象ユーザ、プロフィール更新
   *
   * @memberof ProfileLocationEditComponent
   */
  editproxyAddress(): void {
    
    this.address.location = this.commonFunctionMdl.deepcopy(this.location);

    // request body作成
    const reqBody: request.familyInfoUpdate = {
      relation_id: this.relationId,
      address: this.address
    }

    // ファミリーユーザの情報を更新
    this.busy = this.familyWebApiServ.familyInfoUpdate(reqBody).subscribe({
      next: () => {
        this.appMsg.viewDialogMessage(this.msg.CLIENT.COMMON.COMPLETE_REGIST.message(), ()=> {
          // ファミリー詳細画面に遷移
          this.pagerServ.transitionToPage(this.navigator, PageKey.FamilyDetailComponent);
        });
      },
      error: this.errResServ.doParse((_error, customErrorContent) => {
        // エラー発生時はメッセージ表示
        this.appMsg.viewDialogErrorMessage(this.msg.CLIENT.COMMON.E_UNEXPECTED.message(), customErrorContent.smartGotoErrMessage)
      })
    });
  }
}

/**
 * 住所、地点(緯度経度)編集する対象
 */
export const EDIT_MODE = {

  user: 'USER',

  proxy_user: 'proxy_user'
}

/**
 * 住所、地点(緯度経度)編集する対象
 */
type EditMode = typeof EDIT_MODE[keyof typeof EDIT_MODE];