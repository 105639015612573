<ons-toolbar class="toolbar">
    <div class="left">
        <ons-back-button></ons-back-button>
    </div>
    <div class="center">プロフィールの編集</div>
</ons-toolbar>
<div class="content">
    <div [ngBusy]="busy"></div>
    <form id="mySpotForm" #formCheck="ngForm" class="formCheck">

        <ons-list *ngIf="editKey=='tel'">
            <ons-list-header modifier="color" class="list-item__title">
                電話番号<span class="required-mark">※</span>
            </ons-list-header>
            <ons-list-item modifier="longdivider" class="list-item__text">
                <ons-input id="profile-edit_001" [(ngModel)]="item.profile.tel" name="tel" #telModel="ngModel" pattern="^\d{9,15}$"
                    maxlength="15" autocomplete="tel" inputmode="numeric" required placeholder="08012345678(ハイフンなし)">
                </ons-input>
                <div class="valid-msg" *ngIf="telModel.errors && (telModel.dirty || telModel.touched)">
                    <span *ngIf="telModel.errors.required">必須です。</span>
                    <span *ngIf="telModel.errors.pattern">電話番号が不正です。9～15桁の半角数字を入力してください。</span>
                </div>
            </ons-list-item>
        </ons-list>

        <ons-list *ngIf="editKey=='mail'">
          <ons-list-header modifier="color" class="list-item__title">
            メールアドレス
          </ons-list-header>
          <ons-list-item modifier="longdivider" class="list-item__text">
            <ons-input id="profile-edit_002" [(ngModel)]="item.profile.mail" name="mail" #mailModel="ngModel" [required]="(item.auth === 'local')"
              email autocomplete="email"></ons-input>
            <div class="valid-msg" *ngIf="mailModel.errors && (mailModel.dirty || mailModel.touched)">
              <span *ngIf="mailModel.errors.required">必須です。</span>
              <span *ngIf="mailModel.errors.email">メールアドレス形式が不適切です。</span>
            </div>
          </ons-list-item>
        </ons-list>

        <div *ngIf="editKey=='address'" class="address-list">
            <p class="address-list__title">
                住所<span class="required-mark">※</span>
            </p>
            <dl class="address-list__item">
                <dt class="address-title">郵便番号</dt>
                <dd class="address-data">
                    <ons-input id="profile-edit_003" inputmode="numeric" [(ngModel)]="item.profile.address.zip" name="zip" #zipModel="ngModel"
                        (ngModelChange)="zipEmptyMessageClear()" pattern="^\d{7}$" maxlength="7" required
                        autocomplete="postal-code" placeholder="1234567" class="input--short zip"></ons-input>
                    <ons-button id="profile-edit_004" [disabled]="zipModel.errors" (click)="addressAutocomplete()" class="address-button">
                        住所自動入力</ons-button>
                    <div class="valid-msg" *ngIf="zipModel.errors && (zipModel.dirty || zipModel.touched)">
                        <span *ngIf="zipModel.errors.required">必須です。</span>
                        <span *ngIf="zipModel.errors.pattern">半角数字７桁で入力ください。</span>
                        <span *ngIf="zipModel.errors.maxlength">最大７文字です。</span>
                    </div>
                    <div class="valid-msg" *ngIf="zipEmptyMessage.length > 0"><span>{{zipEmptyMessage}}</span></div>
                </dd>
            </dl>
            <dl class="address-list__item">
                <dt class="address-title">都道府県</dt>
                <dd class="address-data">
                    <ons-input id="profile-edit_005" [(ngModel)]="item.profile.address.prefecture" name="prefecture" #prefectureModel="ngModel"
                        autocomplete="address-level1" placeholder="都道府県" required></ons-input>
                    <div class="valid-msg" *ngIf="prefectureModel.errors && (prefectureModel.dirty || prefectureModel.touched)">
                      <span *ngIf="prefectureModel.errors.required">必須です。</span>
                    </div>
                </dd>
            </dl>
            <dl class="address-list__item">
                <dt class="address-title">市区町村</dt>
                <dd class="address-data">
                    <ons-input id="profile-edit_006" [(ngModel)]="item.profile.address.city" name="city" #cityModel="ngModel" autocomplete="address-level2"
                        placeholder="市区町村" required></ons-input>
                    <div class="valid-msg" *ngIf="cityModel.errors && (cityModel.dirty || cityModel.touched)">
                      <span *ngIf="cityModel.errors.required">必須です。</span>
                    </div>
                </dd>
            </dl>
            <dl class="address-list__item">
                <dt class="address-title">町域</dt>
                <dd class="address-data">
                    <ons-input id="profile-edit_007" [(ngModel)]="item.profile.address.address1" name="address1" #address1Model="ngModel" autocomplete="address-level3"
                        placeholder="町域" required></ons-input>
                    <div class="valid-msg" *ngIf="address1Model.errors && (address1Model.dirty || address1Model.touched)">
                      <span *ngIf="address1Model.errors.required">必須です。</span>
                    </div>
                </dd>
            </dl>
            <dl class="address-list__item">
                <dt class="address-title">番地以降</dt>
                <dd class="address-data">
                    <ons-input id="profile-edit_008" [(ngModel)]="item.profile.address.address2" name="address2" #address2Model="ngModel"
                        placeholder="番地以降" required></ons-input>
                    <div class="valid-msg" *ngIf="address2Model.errors && (address2Model.dirty || address2Model.touched || m_AddressAutoCompleted)">
                      <span *ngIf="address2Model.errors.required">必須です。</span>
                    </div>
                </dd>
            </dl>
            <dl class="address-list__item">
                <dt class="address-title">建物名・<br />部屋番号</dt>
                <dd class="address-data">
                    <ons-input id="profile-edit_009" [(ngModel)]="item.profile.address.address3" name="address3" placeholder="建物名・部屋番号">
                    </ons-input>
                </dd>
            </dl>
        </div>

        <div class="button-wrap">
            <ons-button *ngIf="editKey=='address'" id="profile-edit_010" modifier="large--cta" (click)="pushAddressEdit()" [attr.disabled]="formCheck.invalid ? '' : null">地図で場所を確認
            </ons-button>
            <ons-button *ngIf="editKey!=='address'" id="profile-edit_010" modifier="large--cta" (click)="edit()" [attr.disabled]="formCheck.invalid ? '' : null">登録する
            </ons-button>
        </div>
    </form>
</div>
