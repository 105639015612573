//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Params, OnsNavigator } from 'ngx-onsenui';
import { Subscription } from 'rxjs';

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { FamilyWebApiService } from '../../../http-services/family-web-api.service';

// interface
import { UserInfo } from '../../../interfaces/response';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

@Component({
  selector: 'ons-page[app-proxy-hand-over]',
  templateUrl: './proxy-hand-over.component.html',
  styleUrls: ['./proxy-hand-over.component.css']
})
export class ProxyHandOverComponent implements OnInit, OnDestroy 
{

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * サーバ通信Subscription
   *
   * @type {Subscription}
   * @memberof ProxyHandOverComponent
   */
  m_Busy: Subscription;

  /**
   * 一時パスワード
   *
   * @type {string}
   * @memberof ProxyHandOverComponent
   */
  m_strOnePassword: string = "";

  /**
   * 見守り対象ユーザ情報
   *
   * @type {UserInfo}
   * @memberof ProxyHandOverComponent
   */
  m_ProxyUserInfo: UserInfo;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of ProxyHandOverComponent.
   * @param {OnsNavigator} navigator
   * @param {ApplicationMessageService} appMsg
   * @param {HttpErrorResponseParserService} httpErrorResponseParserService
   * @param {UserWebApiService} userWebApiService
   * @param {FamilyWebApiService} familyWebApiService
   * @param {Params} params
   * @memberof ProxyHandOverComponent
   */
  constructor(
    private navigator: OnsNavigator, 
    private appMsg: ApplicationMessageService, 
    private httpErrorResponseParserService: HttpErrorResponseParserService, 
    private familyWebApiService: FamilyWebApiService, 
    private params: Params,
    private msg: MESSAGE,
  ) { }

  /**
   * 初期化処理。
   *
   * @memberof ProxyHandOverComponent
   */
  ngOnInit(): void 
  {
  }

  /**
   * 破棄処理。
   *
   * @memberof ProxyHandOverComponent
   */
  ngOnDestroy(): void 
  {
    this.m_Busy?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 一時パスワード送信を行う。
   *
   * @memberof ProxyHandOverComponent
   */
  sendPassword(): void 
  {
    this.getProxyInfo(this.m_strOnePassword);
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * 見守り対象ユーザ情報を引き継ぐ。
   *
   * @private
   * @param {string} token
   * @memberof ProxyHandOverComponent
   */
  private getProxyInfo(token: string): void 
  {
    this.m_Busy = this.familyWebApiService.handOverProxy(token).subscribe(
      {
        next: response => 
        {
          this.appMsg.viewDialogMessage(this.msg.CLIENT.FAMILY.HAND_OVER_ACCOUNT.message(), () => 
          {
            // 取得したユーザ情報の反映処理をコール
            this.navigator.element.popPage().then(() => { this.params.data.reflect(response.body); });
          });
        }, 
        error: this.httpErrorResponseParserService.doParse((_error, customErrorContent) => 
        {
          this.appMsg.viewDialogMessage(this.msg.CLIENT.FAMILY.E_INVALID_PASSWORD.message());
        })
      }
    )
  }
}
