import { Component, OnInit } from '@angular/core';
import { OnsNavigator, Params } from 'ngx-onsenui';
import { Subscription } from 'rxjs';
import * as CONST from '../../../constants/constant';

// component
import { ProfileSignupDetermineComponent } from '../profile-signup-determine/profile-signup-determine.component';

// service
import { ApplicationMessageService } from 'src/app/lib-services/application-message.service';
import { UserWebApiService } from '../../../http-services/user-web-api.service';
import { PagerService } from 'src/app/lib-services/pager.service';

// module
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';

// interface
import { UserInfo } from 'src/app/interfaces/response';
import { SigninService } from '../../signin/signin.component';
import { MESSAGE } from 'src/app/constants/message';

@Component({
  selector: 'ons-page[app-profile-signup-location-edit]',
  templateUrl: './profile-signup-location-edit.component.html',
  styleUrls: ['./profile-signup-location-edit.component.scss']
})
export class ProfileSignupLocationEditComponent implements OnInit {
  
  /**
   * 非同期処理監視Subscription
   *
   * @type {Subscription}
   * @memberof ProfileSignupLocationEditComponent
   */
  busy: Subscription;

  /**
   * メールアドレス認証/SNS認証どちらによる登録か
   * trueならメールアドレス認証
   *
   * @type {Boolean}
   * @memberof ProfileSignupLocationEditComponent
   */
  viewStep?: Boolean;

  /**
   * ユーザ情報
   *
   * @type {UserInfo}
   * @memberof ProfileSignupLocationEditComponent
   */
  userInfo: UserInfo;

  /**
   * 地図から選択した緯度経度
   *
   * @type {google.maps.LatLngLiteral}
   * @memberof ProfileSignupLocationEditComponent
   */
  location: google.maps.LatLngLiteral;

  /**
   * (初回ログイン)住所編集をしたかどうか
   *
   * @type {boolean}
   * @memberof ProfileSignupLocationEditComponent
   */
  isEdit: boolean;

  /**
   * Creates an instance of ProfileSignupLocationEditComponent.
   * @param {Params} params
   * @param {OnsNavigator} navigator
   * @param {CommonFunctionModule} commonFunctionMdl
   * @memberof ProfileSignupLocationEditComponent
   */
  constructor(
    private userWebApiService: UserWebApiService, 
    private params: Params,
    private pagerServ: PagerService, 
    private signinServ: SigninService,
    private navigator: OnsNavigator,
    private commonFunctionMdl: CommonFunctionModule,
    private appMsg: ApplicationMessageService,
    private msg: MESSAGE,
  ) { }

  /**
   * 初期処理
   *
   * @memberof ProfileSignupLocationEditComponent
   */
  ngOnInit(): void {

    this.viewStep = this.params.data.viewStep;
    this.userInfo = this.commonFunctionMdl.deepcopy(this.params.data.userInfo);
    this.isEdit = this.params.data.isEdit;
  }

// ================================================================================
// イベントハンドラ
// ================================================================================

  /**
   * 新規会員登録確認画面に遷移
   *
   * @memberof ProfileSignupLocationEditComponent
   */
  pushSignupDetermine(): void {

    // 選択した位置情報(緯度経度)を代入
    this.userInfo.profile.address.location = this.commonFunctionMdl.deepcopy(this.location);

    // 新規会員登録確認画面に遷移
    this.navigator.element.pushPage(ProfileSignupDetermineComponent, { data: {viewStep: this.viewStep, userInfo: this.userInfo, citizen: this.params.data.citizen} });
  }

  /**
   * 選択中の緯度経度の更新
   *
   * @param {google.maps.LatLngLiteral} location
   * @memberof ProfileSignupLocationEditComponent
   */
  updateLocation(location: google.maps.LatLngLiteral): void {

    // 入力した住所に該当する地点(緯度経度)が見つからない場合
    if (!location) {
      this.appMsg.viewDialogMessage(this.msg.CLIENT.PROFILE.NOT_EXIST_ADDRESS.message(), () => {
        this.navigator.element.popPage();
      });
      return;
    }

    this.location = this.commonFunctionMdl.deepcopy(location);
  }

  /**
   * 新規会員登録を中止。
   *
   * @memberof ProfileSignupLocationEditComponent
   */
  cancel(): void {

    this.appMsg.confirmMessage(this.msg.CLIENT.PROFILE.COMFIRM_CANCEL_REGISTRATION.message(), (value) => {
      // Yes回答だったら登録中止
      if (Number(value) === CONST.Common.CONFIRM_YES) {
        this.busy = this.userWebApiService.signinCancel().subscribe({
          next: () => {},
          error: () => { location.reload(); }
        });

        // 利用するタブから遷移している場合はサインイン画面へ戻る
        if (this.pagerServ.getUserAppNavigator) this.signinServ.trans(true);
      }
    });
  }
}
