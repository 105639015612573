import { Pipe, PipeTransform } from '@angular/core';

/**
 * 型を示す文字列を取得するためのパイプ。
 *
 * @export
 * @class TypeofPipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'typeof'
})
export class TypeofPipe implements PipeTransform {
  /**
   * `value` の型を示す文字列を返す。
   *
   * @param {*} value `typeof` 演算子のオペランドとなる値。
   * @return {*}  {*} 型を示す文字列。
   * @memberof TypeofPipe
   */
  transform(value: any): any {
    return typeof value;
  }
}