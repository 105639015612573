<ons-toolbar>
    <div class="center">予約完了</div>
</ons-toolbar>
<div class="content">
    <p class="content--title">予約が確定しました。</p>
    <p class="content--text">利用・履歴のページから<br />予約内容を確認<br />することができます。</p>
    <parts-image class="content--img" url="/image/character/complate.png"></parts-image>
    <div class="button-wrap">
        <ons-button id="user-reservation-complete_001" modifier="large--cta large--cta--1st" (click)="returnHome()">ホームに戻る</ons-button>
        <ons-button id="user-reservation-complete_002" modifier="large--cta" (click)="continueReservation()">続けて予約する</ons-button>
    </div>
</div>