<ons-toolbar>
  <div class="center">注文完了</div>
</ons-toolbar>

<div class="content complete-wrap">
  <p class="content--title complete-wrap__title">注文が完了しました。</p>
  <p class="content--text complete-wrap__text">利用・履歴のページから<br />注文内容を確認<br />することができます。</p>
  <parts-image class="content--img complete-wrap__img" url="/image/character/complate.png"></parts-image>
  <div class="button-wrap">
    <ons-button id="shopping-complete_001" modifier="large--cta" (click)="onBackHome()">ホームへ戻る</ons-button>
  </div>
</div>
