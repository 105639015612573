// ========================================================================================================================
// 各種インポート
// ========================================================================================================================

import { Component, OnInit } from '@angular/core';
import { OnsNavigator } from 'ngx-onsenui';
import { Subscription } from 'rxjs';
import * as ons from 'onsenui';

import { UserWebApiService } from '../../../http-services/user-web-api.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { LoginComponent } from '../login/login.component';
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';
import { MESSAGE } from 'src/app/constants/message';

// ========================================================================================================================
// クラス定義
// ========================================================================================================================

@Component({
  selector: 'ons-page[signup]',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
})
export class SignupComponent implements OnInit {

  // ================================================================================
  // 変数定義
  // ================================================================================

  /**
   * 非同期処理監視Subscription
   *
   * @type {Subscription}
   * @memberof SignupComponent
   */
  busy: Subscription;

  /**
   *　フォームに入力されたユーザー名(メールアドレス)
   *
   * @type {string}
   * @memberof SignupComponent
   */
  public username: string = null;

  /**
   *　フォームに入力されたパスワード
   *
   * @type {string}
   * @memberof SignupComponent
   */
  public password: string = null;

  /**
   *　フォームに入力されたパスワード(確認用)
   *
   * @type {string}
   * @memberof SignupComponent
   */
  public passwordConfirm: string = null;

  /**
   * assetsファイルへのパス(定数)
   *
   * @type {string}
   * @memberof SignupComponent
   */
  ASSETS_MAIL: string = CommonFunctionModule.getAssetsUrl('/image/common/39-Mail.png');
  
  // ================================================================================
  // ライフサイクルメソッド定義
  // ================================================================================

  constructor(
    private _navigator: OnsNavigator,
    private userWebApiService: UserWebApiService,
    private httpErrorResponseParserService: HttpErrorResponseParserService,
    private appMsg: ApplicationMessageService,
    private msg: MESSAGE,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {

    this.busy?.unsubscribe();
  }

  // ================================================================================
  // 関数定義
  // ================================================================================

  /**
   * フォームに入力された内容を元にアカウント登録
   *
   * @return {*}  {void}
   * @memberof SignupComponent
   */
  signupLocal(): void {

    // パスワードとパスワード(確認)の入力が異なっている場合は登録不可
    if (this.password !== this.passwordConfirm) {
      this.appMsg.viewDialogMessage(this.msg.CLIENT.COMMON.E_PASSWORD_DIFFERENT.message());
      return;
    }

    // アカウント登録 ⇒ サーバからメールアドレスへPIN送信
    this.busy = this.userWebApiService.signup(this.username, this.password).subscribe({
      next: () => this._navigator.element.pushPage(LoginComponent, {data: {username: this.username}}),

      error: this.httpErrorResponseParserService.doParse((_error, customErrorContent) => {
        this.appMsg.viewDialogMessage(customErrorContent.smartGotoErrMessage || this.msg.CLIENT.COMMON.E_UNEXPECTED.message());
      })
    });
  }

}
