//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { BehaviorSubject, Subject, Subscription, fromEvent } from 'rxjs';
import { onsPlatform, OnsTabbar } from 'ngx-onsenui';
import * as CONST from "../../../constants/constant";
import { municipalities } from 'src/municipality';

// component
import { UserReservationComponent } from '../../reservation/user-reservation/user-reservation.component';
import { NewsCategoryListComponent } from '../../news/news-category-list/news-category-list.component';
import { ShoppingComponent } from '../../shopping/shopping.component';
import { AppComponent } from 'src/app/app.component';
import { ExpComponent } from '../../exp/exp/exp.component';

// service
import { MenuService } from '../../../lib-services/menu.service';
import { UserWebApiService } from "../../../http-services/user-web-api.service";
import { ExpWebApiService } from 'src/app/http-services/exp-web-api.service';
import { PagerService } from 'src/app/lib-services/pager.service';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';
import { ApplicationMessageService } from 'src/app/lib-services/application-message.service';
import { HttpErrorResponseParserService } from 'src/app/lib-services/http-error-response-parser.service';

import { environment } from 'src/environments/environment';
import { ExpService, Shop } from 'src/app/interfaces/response';
import { HttpParams } from '@angular/common/http';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 利用する内のタブ情報。
 *
 * @export
 * @class UseListComponent
 */
@Component({
  selector: 'ons-page[use-list]',
  templateUrl: './use-list.component.html',
  styleUrls: ['./use-list.component.css']
})

export class UseListComponent implements OnInit, AfterViewInit, OnDestroy {

//=============================================================================================
// メンバ変数
//=============================================================================================

  /**
   * andloidかどうか
   *
   * @memberof UseListComponent
   */
  md = onsPlatform.isAndroid();

  /**
   * メニューを表示するかどうか（そもそもこれが必要かどうか要調査）
   *
   * @type {boolean}
   * @memberof UseListComponent
   */
  useListMenuList: boolean = false;

  /**
   * OnDestroy時に破棄するSubscriptionオブジェクト
   *
   * @memberof UseListComponent
   */
  subscription = new Subscription();

  /**
   * タブラベル
   *
   * @memberof UseListComponent
   */
  readonly SERVICE_TAB_LABEL = {
    RESERVATION: "おでかけ",
    NEWS: "まちニュース",
    SHOPPING: "買い物",
    EXPERIENCE: "観光"
  } as const;

  /**
   * タブ情報
   *
   * @private
   * @memberof UseListComponent
   */
  private readonly tabs = [
    { id: "use-list_001", tag: CONST.Common.SERVICE_TAG.RESERVATION, component: UserReservationComponent, label: this.SERVICE_TAB_LABEL.RESERVATION },
    { id: "use-list_002", tag: CONST.Common.SERVICE_TAG.NEWS, component: NewsCategoryListComponent, label: this.SERVICE_TAB_LABEL.NEWS },
    { id: "use-list_003", tag: CONST.Common.SERVICE_TAG.SHOPPING, component: ShoppingComponent, label: this.SERVICE_TAB_LABEL.SHOPPING },
    { id: "use-list_004", tag: CONST.Common.SERVICE_TAG.EXPERIENCE, component: ExpComponent, label: this.SERVICE_TAB_LABEL.EXPERIENCE },
  ] as const;

  /**
   * 表示するタブ情報
   *
   * @memberof UseListComponent
   */
  displayTabs = [];

  /**
   * タブの遷移履歴
   *
   * @memberof UseListComponent
   */
  transitionTabHistory = {
    before: "",
    active: ""
  }

  /**
   * タブバーコントロール
   *
   * @private
   * @memberof UseListComponent
   */
  @ViewChild(OnsTabbar) private onsTabbar;

  /**
   * エリア名
   *
   * @type {string}
   * @memberof UseListComponent
   */
  areaName: string;

  /**
   *
   *
   * @type {Subscription}
   * @memberof UseListComponent
   */
  tabClickSubscription: Subscription;

  /**
   * タブクリックBehaviorSubject
   *
   * @memberof UseListComponent
   */
  tabClickSubject;

  /**
   * useListタブ変更を監視
   *
   * @type {Subject<UseListTabTag>}
   * @memberof UseListComponent
   */
  tabClicked: Subject<UseListTabTag> = new Subject();

  /**
   * 検索体験サービス一覧画面、体験サービス詳細画面へ遷移促し
   *
   * @type {BehaviorSubject<ExpSubjectParams>}
   * @memberof UseListComponent
   */
  pushExp: BehaviorSubject<ExpSubjectParams> = new BehaviorSubject({type: 0});

  /**
   * まちニュース一覧画面へ遷移促し
   *
   * @type {BehaviorSubject<{freeword?: string}>}
   * type(freeword(検索に使うフリーワード))
   * @memberof AppComponent
   */
  pushNews: BehaviorSubject<{freeword?: string}> = new BehaviorSubject(null);

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of UseListComponent.
   * @param {MenuService} menuService
   * @param {UserWebApiService} userServ
   * @param {AppComponent} appComp
   * @param {PagerService} pagerServ
   * @param {MunicipalityWebApiService} municipalityWebApiServ
   * @memberof UseListComponent
   */
  constructor(
    private menuService: MenuService,
    private userServ: UserWebApiService,
    private appComp: AppComponent,
    private pagerServ: PagerService,
    private municipalityWebApiServ: MunicipalityWebApiService,
    private appMsgServ: ApplicationMessageService,
    private msg: MESSAGE,
    private expWebApiServ: ExpWebApiService,
    private errResServ: HttpErrorResponseParserService,
  ) { }

  /**
   * 初期処理。
   *
   * @memberof UseListComponent
   */
  ngOnInit(): void {
    try {
      // 表示するタブ取得
      municipalities[environment.areacode].service.forEach(settingTab => {
        this.displayTabs.push(this.tabs.find(tab => settingTab == tab.tag));
      });

      // 初期表示タブ、トップコンポーネントの初期表示に必要な処理を起こさせるため
      this.tabClickSubject = new BehaviorSubject<UseListTabTag>(this.displayTabs[0].tag);
    }
    catch {
      // 想定外エラー
      console.error("タブ情報が見つからない");
    }
  }

  /**
   * afterViewInit
   *
   * @memberof UseListComponent
   */
  ngAfterViewInit(): void {
    /**
     * 自治体毎の設定情報取得監視
     * エリア名、表示するタブ
     */
    const settingsChanged = this.municipalityWebApiServ.settingsChanged.subscribe({
      next: setting => {
        if (setting == null) return;
        // エリア名
        setTimeout(() => { this.areaName = setting.name; })
      }
    });
    this.subscription.add(settingsChanged);

    // NOTE: タブクリックイベントをキャッチし、BehaviorSubjectにクリックしたタブを流す。
    //        タブ内コンポーネントで監視(subscribe)し、それぞれの処理を実施
    this.tabClickSubscription = fromEvent(document.querySelectorAll(`[id^='use-list']`), 'click').subscribe((eve) => {
      // アクティブタグを取得
      const tabTag: UseListTabTag = this.displayTabs[this.onsTabbar._elementRef.nativeElement.getActiveTabIndex()].tag;

      this.tabClickSubject.next(tabTag);
    });

    // ユーザ情報がnull（ログアウトした）なら「おでかけ」タブを表示
    const userChanged = this.userServ.userInfoChanged.subscribe({
      next: user => {
        // 利用するタブから起動したサインイン画面が残っているなら処理しない
        if (null === user && this.pagerServ.getUserAppNavigator === false) {
          // 初期ロード(タブが生成されていない)では、処理しない
          if (this.displayTabs.length !== 0) this.firstTabClick();
        }

        // ユーザー情報未取得の場合、処理を抜ける
        if (user === void 0) return;
        // 初期処理用リンク(?page=)があるかつ、規約合意画面を表示しない場合(未ログイン or 規約合意済み)、リンク処理実施
        if (window.location.href.includes('?') && (!this.userServ.isLoggedIn() || user.term_agreed===true)) {
          this.link();
        }
      }
    });

    this.subscription.add(userChanged);


    // useListメニューバー
    const MenuBar = document.querySelector<HTMLElement>('ons-tabbar .tabbar');

    /* (useListメニューバーに対する)ホイールイベントを
          横スクロール(メニューバーのスクロール)に変換 */
    MenuBar.addEventListener("wheel", ele => {
      // 横スクロールが含まれている場合抜ける
      if (ele.deltaX !== 0) return;

      // デフォルト動作(縦スクロール)を停止
      ele.preventDefault();

      // スクロール量分、横スクロール
      MenuBar.scrollBy(ele.deltaY, 0)
    });
  }

  /**
   * 破棄処理。
   *
   * @memberof UseListComponent
   */
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.tabClickSubscription?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * タブのクリック時のイベントハンドラ
   *
   * @param {string} tabId
   * @memberof UseListComponent
   */
  onSetScroll(tabId: string, tabTag: string) {
    // タブの遷移履歴を保存
    this.transitionTabHistory.before = this.transitionTabHistory.active;
    this.transitionTabHistory.active = tabTag;

    // タブの位置調整
    const scrollMenu = document.getElementsByClassName('tabbar')[0];

    //タグの位置
    const selectedTag = document.getElementById(tabId.toString());

    // 選択したタブに合わせてタブのスクロールを調整
    const scrollCenter = scrollMenu.getBoundingClientRect().left + (scrollMenu.getBoundingClientRect().width / 2);
    const tagCenter = 0? null:selectedTag.getBoundingClientRect().left + (selectedTag.getBoundingClientRect().width / 2);
    const tabWidth = scrollMenu.scrollLeft - (scrollCenter-tagCenter);

    scrollMenu.scrollLeft = (tabWidth);
  };

  /**
   * サイドメニューを開く。
   *
   * @memberof UseListComponent
   */
  openMenu(): void {
    this.menuService.open();
  }

  /**
   * 一つ目のタブを選択させる。
   *
   * @memberof UseListComponent
   */
  firstTabClick(): void {
    this.onsTabbar._elementRef.nativeElement.setActiveTab(0);
  }

  /**
   * タブ切り替えと
   * clickした際のイベントを同時に行いたい場合に使用。
   *
   * @param {TabNum} tabNum
   * @memberof UseListComponent
   */
  tabClickEvent(tabTag: string): void {
    let targetIndex: number;

    // setActiveTab関数で使用するindex取得
    this.displayTabs.forEach((tab, index) => {
      if (tab.tag === tabTag) targetIndex = index;
    });

    // アクティブタブを変更
    this.onsTabbar._elementRef.nativeElement.setActiveTab(targetIndex);
    // タブクリック時と同様の処理を起こさせる
    this.tabClickSubject.next(tabTag);
  }

  /**
   * 表示中のタブ番号を取得する。
   *
   * @return {*}  {number}
   * @memberof UseListComponent
   */
  getActiveTabTag(): string {
    const tabIndex: number = this.onsTabbar._elementRef.nativeElement.getActiveTabIndex();
    return this.displayTabs[tabIndex].tag;
  }

  /**
   * メールリンクからの遷移処理。
   *
   * @private
   * @return {*}
   * @memberof AppComponent
   */
  private link(): void {
    const url = window.location.href;

    // パラメータ取得
    const httpParams = new HttpParams({ fromString: url.split('?')[1] });

    // 「page」パラメータによって分岐
    switch(httpParams.get("page")) {

      // 体験サービス モビパからの遷移
      case "exp":
        // URLにフリーワードが含まれている場合
        if (httpParams.has("freeword")) {
          // 検索体験サービス一覧画面を表示
          const fromExternalLink: boolean = true;
          this.viewExpSearch(httpParams.get("freeword"), httpParams.get("date_search_type"), fromExternalLink);
        }
        // URLにショップIDが含まれている場合
        else if (httpParams.has("shop_id")) {
          // 店舗サービスを表示
          this.viewExpShop(httpParams.get("shop_id"));
        }
        // URLにサービスIDが含まれている場合
        else if (httpParams.has("service_id")) {
          // 体験サービス詳細を表示
          this.viewExpServiceDetail(httpParams.get("service_id"));
        }
        // 体験サービストップ画面を表示
        else this.viewUseListTab(CONST.Common.SERVICE_TAG.EXPERIENCE);
        this.linkReset();
        break;

      // まちニュース
      case "news":
        // URLにフリーワードが含まれている場合
        if (httpParams.has("freeword")) {
          // まちニュース一覧画面を表示
          this.viewNewsSearch(httpParams.get("freeword"));
        }
        // まちニューストップ画面を表示
        else this.viewUseListTab(CONST.Common.SERVICE_TAG.NEWS);
        this.linkReset();
        break;

      // 買い物
      case "shopping":
        // 買い物トップ画面を表示
        this.viewUseListTab(CONST.Common.SERVICE_TAG.SHOPPING);
        this.linkReset();
        break;
    }
  }

  /**
   * 初期リンク(?page以降)URL削除
   *
   * @private
   * @memberof UseListComponent
   */
  private linkReset(): void {
    // 初期リンク(?page以降)URL削除
    history.replaceState({}, "", location.href.split('?')[0]);
  }

  /**
   * useListの指定したタブへ遷移
   *
   * @private
   * @param {UseListTabTag} tabNum
   * @memberof AppComponent
   */
  private viewUseListTab(tabTag: UseListTabTag): void {
    // 指定されたタブをclick
    setTimeout(() => this.tabClickEvent(tabTag));

    const checkExp = (tabTag: UseListTabTag) => {
      setTimeout(() => {
        if (this.getActiveTabTag() != tabTag) checkExp(tabTag);
        else this.tabClicked.next(tabTag);
      })
    }
    // 指定したタブがクリックされたことを通知
    checkExp(tabTag);
  }

  /**
   * 検索体験サービス一覧画面を表示する。
   *
   * @private
   * @param {string} freeword
   * @param {string} dateSearchType
   * @param {boolean} fromExternalLink
   * @return {*}
   * @memberof AppComponent
   */
  private viewExpSearch(freeword: string, dateSearchType: string, fromExternalLink: boolean): void {
    // パラメータ異常
    if (!freeword ?? false) {
      this.appMsgServ.viewDialogMessage(this.msg.CLIENT.COMMON.E_INVALID_URL.message());
      return;
    }

    // useListタブが観光に切り替わったことを監視
    const clicked: Subscription = this.tabClicked.subscribe({
      next: (tabTag: UseListTabTag) => {
        // 観光サービスタブ（タブ番号３）
        if (tabTag == CONST.Common.SERVICE_TAG.EXPERIENCE) {
          setTimeout(() => {
            // 検索体験サービス一覧画面へ遷移
            this.pushExp.next({type: 1, freeword: freeword, dateSearchType: dateSearchType, fromExternalLink: fromExternalLink});
            this.tabClicked.complete();
          })
        }
      }
    });
    this.subscription.add(clicked);

    // 体験サービスタブへ遷移
    this.viewUseListTab(CONST.Common.SERVICE_TAG.EXPERIENCE);
  }

  /**
   * 体験サービス詳細を表示する。
   *
   * @private
   * @param {string} serviceId
   * @return {*}
   * @memberof AppComponent
   */
  private viewExpServiceDetail(serviceId: string): void {
    // パラメータ異常
    if (!serviceId ?? false) {
      this.appMsgServ.viewDialogMessage(this.msg.CLIENT.COMMON.E_INVALID_URL.message());
      return;
    }

    // サービスID
    const serviceIdNum = Number(serviceId)

    // 数字に変換できない文字列の場合、処理を抜ける
    if (isNaN(serviceIdNum)) {
      this.appMsgServ.viewDialogMessage(this.msg.CLIENT.COMMON.E_INVALID_URL.message());
      return;
    }

    // サービスGET
    this.appComp.busy = this.expWebApiServ.getService({sg_service_id: serviceIdNum}).subscribe({
      next: res => {
        // サービス
        const service: ExpService = res.body;

        // useListタブが観光に切り替わることを監視
        const clicked: Subscription = this.tabClicked.subscribe({
          next: (tabTag: UseListTabTag) => {
            // 観光サービスタブ（タブ番号３）
            if (tabTag == CONST.Common.SERVICE_TAG.EXPERIENCE) {
              setTimeout(() => {
                if (service.schedule_type === 1) {
                  // 体験サービス詳細画面へ遷移
                  this.pushExp.next({type: 3, service: service});
                  this.tabClicked.complete();
                }
                else if (service.schedule_type === 3) {
                  // 体験サービス詳細画面へ遷移
                  this.pushExp.next({type: 2, service: service});
                  this.tabClicked.complete();
                }
              })
            }
          }
        });
        this.subscription.add(clicked);

        // 体験サービスタブへ遷移
        this.viewUseListTab(CONST.Common.SERVICE_TAG.EXPERIENCE);
      },
      error: this.errResServ.doParse((_err, errContent) => this.errResServ.viewErrDialog(errContent))
    });
  }

  /**
   * まちニュース一覧画面を表示する。
   *
   * @private
   * @param {string} freeword
   * @return {*}
   * @memberof AppComponent
   */
  private viewNewsSearch(freeword: string): void {
    // パラメータ異常
    if (!freeword ?? false) {
      this.appMsgServ.viewDialogMessage(this.msg.CLIENT.COMMON.E_INVALID_URL.message());
      return;
    }

    // useListタブがまちニュースに切り替わったことを監視
    const clicked: Subscription = this.tabClicked.subscribe({
      next: (tabTag: UseListTabTag) => {
        // まちニュースタブ（タブ番号１）
        if (tabTag == CONST.Common.SERVICE_TAG.NEWS) {
          setTimeout(() => {
            // まちニュース一覧画面へ遷移

            // 全角スペースを半角に置換、前後のスペース除去
            let word = freeword.replace(/　/g, " ").trim();

            this.pushNews.next({freeword: word});
            this.tabClicked.complete();
          })
        }
      }
    });
    this.subscription.add(clicked);
    // まちニュースタブへ遷移
    this.viewUseListTab(CONST.Common.SERVICE_TAG.NEWS);
  }

  /**
   * 店舗の観光サービスを表示する。
   *
   * @private
   * @param {string} shopId
   * @return {*}
   * @memberof AppComponent
   */
  private viewExpShop(shopId: string): void {
    // パラメータ異常
    if (!shopId ?? false) {
      this.appMsgServ.viewDialogMessage(this.msg.CLIENT.COMMON.E_INVALID_URL.message());
      return;
    }

    // 店舗ID
    const shopIdNum = Number(shopId)

    // 数字に変換できない文字列の場合、処理を抜ける
    if (isNaN(shopIdNum)) {
      this.appMsgServ.viewDialogMessage(this.msg.CLIENT.COMMON.E_INVALID_URL.message());
      return;
    }
    
    // 店舗情報取得
    this.appComp.busy = this.expWebApiServ.getShop(shopIdNum).subscribe({
      next: res => {
        // ショップ
        const shops: Shop[] = res.body;

        if (shops.length == 0) {
          this.appMsgServ.viewDialogMessage(this.msg.CLIENT.COMMON.E_INVALID_URL.message());
          return;
        }
        
        // useListタブが観光に切り替わることを監視
        const clicked: Subscription = this.tabClicked.subscribe({
          next: (tabTag: UseListTabTag) => {
            if (tabTag == CONST.Common.SERVICE_TAG.EXPERIENCE) {
              // 観光サービスタブ（タブ番号３）
              setTimeout(() => {
                  // 観光サービス一覧画面へ遷移
                  this.pushExp.next({type: 4, shop: shops[0]});
                  this.tabClicked.complete();
              })
            }
          }
        });
        this.subscription.add(clicked);

        // 体験サービスタブへ遷移
        this.viewUseListTab(CONST.Common.SERVICE_TAG.EXPERIENCE);
      },
      error: this.errResServ.doParse((_err, errContent) => this.errResServ.viewErrDialog(errContent))
    });
  }
}
/**
 * タブ,タグ型
 */
export type UseListTabTag = typeof CONST.Common.SERVICE_TAG[keyof typeof CONST.Common.SERVICE_TAG];


/**
 * 観光サービス画面でのユーザ操作をAppComponentに伝えて画面遷移を行うためのパラメータ
 *
 * @export
 * @interface ExpSubjectParams
 */
export interface ExpSubjectParams {
  /**
   * 遷移先コンポーネントの指定
   * (0:遷移なし, 1: 検索体験サービス一覧画面, 2: 体験サービス詳細画面(所要時間固定型), 3: 体験サービス詳細画面(開始・終了時刻自由型)), 4: 体験サービス店舗画面
   *
   * @type {(0|1|2|3|4)}
   * @memberof ExpSubjectParams
   */
  type: (0|1|2|3|4),
  /**
   * 検索に使うフリーワード
   *
   * @type {string}
   * @memberof ExpSubjectParams
   */
  freeword?: string,
  /**
   * 詳細画面で表示するサービス
   *
   * @type {ExpService}
   * @memberof ExpSubjectParams
   */
  service?: ExpService,
  /**
   * 観光サービス一覧で表示する店舗
   *
   * @type {ExpService}
   * @memberof ExpSubjectParams
   */
  shop?: Shop

  /**
   * 日付検索タイプ
   * 
   * @type {string}
   * @memberof ExpSubjectParams
   */
  dateSearchType?: string

  /**
   * 外部リンクからの遷移判定フラグ
   * 
   * @type {boolean}
   * @memberof ExpSubjectParams
   */ 
  fromExternalLink?: boolean
}
