//========================================================================================================================
// 各種インポート(モジュール、コンポーネント、サービス等)
//========================================================================================================================

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OnsNavigator } from 'ngx-onsenui';
import * as ons from 'onsenui';
import { Subscription, forkJoin } from 'rxjs';

import { MyspotEditComponent } from './myspot-edit/myspot-edit.component';

import { FavoriteWebApiService, FavoriteWebApiSchemas } from '../../http-services/favorite-web-api.service';
import { HttpErrorResponseParserService } from '../../lib-services/http-error-response-parser.service';
import { ApplicationMessageService } from '../../lib-services/application-message.service';
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';
import { MESSAGE } from 'src/app/constants/message';

//========================================================================================================================
// メタデータ定義
//========================================================================================================================

@Component
({
  selector: 'ons-page[myspot]',
  templateUrl: './myspot.component.html',
  styleUrls: ['./myspot.component.css']
})

//========================================================================================================================
// クラス定義
//========================================================================================================================

export class MyspotComponent implements OnInit, OnDestroy {
//================================================================================
// 変数定義
//================================================================================

  @ViewChild('onsBackButton') private onsBackButton: ElementRef;

  /**
   * 非同期通信の受信オブジェクト
   *
   * @type {Subscription}
   * @memberof MyspotComponent
   */
  busy: Subscription;

  /**
   * SearchConditionImplクラスをインスタンス化
   *
   * @type {Myspot.SearchCondition}
   * @memberof MyspotComponent
   */
  searchCondition: Myspot.SearchCondition;

  /**
   * サーバーから取得したマイスポット
   *
   * @type {FavoriteWebApiSchemas.ResponseSchemas.Favorite[]}
   * @memberof MyspotComponent
   */
  mySpots: FavoriteWebApiSchemas.ResponseSchemas.Favorite[] = [];

  /**
   * 登録されていないfavorite.typeのマイスポット
   *
   * @type {FavoriteWebApiSchemas.ResponseSchemas.Favorite[]}
   * @memberof MyspotComponent
   */
  mySpotsUndefined: FavoriteWebApiSchemas.ResponseSchemas.Favorite[] = [];

  /**
   * マイスポット並び替え・削除時に一時保存するコピー
   *
   * @type {FavoriteWebApiSchemas.ResponseSchemas.Favorite[]}
   * @memberof MyspotComponent
   */
  mySpots_copy: FavoriteWebApiSchemas.ResponseSchemas.Favorite[] = [];

  /**
   * マイスポット編集中かどうか
   *
   * @type {boolean}
   * @memberof MyspotComponent
   */
  isEditList: boolean = false;
  
  /**
   * 使っていない
   *
   * @type {string}
   * @memberof MyspotComponent
   */
  // target: string = '';

  // 使っていない？
  // private od:('s') = 's';

  /**
   * assetsファイルへのパス(定数)
   *
   * @memberof MyspotComponent
   */
  readonly ASSETS = {
    TRASH: CommonFunctionModule.getAssetsUrl('/image/common/32-Trash.png'),
    INACTIVE: CommonFunctionModule.getAssetsUrl('/image/common/46-PinC_Inactive.png'),
    PLUS: CommonFunctionModule.getAssetsUrl('/image/common/54-Plus_B.png'),
    BAR: this.commonFunctionMdl.getAssetsCssUrl(CommonFunctionModule.getAssetsUrl('/image/common/33-Bar.png'))
  } as const;

// ================================================================================
// ライフサイクルメソッド定義
// ================================================================================

  constructor (
    private _navigator: OnsNavigator,
    private favoriteWebApiService: FavoriteWebApiService,
    private httpErrorResponseParserService: HttpErrorResponseParserService,
    private appMsg: ApplicationMessageService,
    private commonFunctionMdl: CommonFunctionModule,
    private msg: MESSAGE,
  ) { }

  ngOnInit(): void {

    class SearchConditionImpl implements Myspot.SearchCondition {
      spot: Myspot.SearchConditionSpot = {};
      readonly spotService: Myspot.SearchConditionSpotService;
    
      constructor() {
        // SearchConditionImplクラス
        const that = this;
    
        // placeResult : POI / SearchBox
        // mapMouseEvent: 地図上タップの座標
        // geocoderResult : 任意の場所タップ
        // favorite : お気に入り
        this.spotService = {
          getGeometryLocation(spot) {
            return spot.placeResult?.geometry?.location
              || spot.mapMouseEvent?.latLng
              || spot.geocoderResult?.geometry.location;
          },
          getLocation(spot) {
            return spot.favorite?.location
              || that.spotService.getGeometryLocation(spot)?.toJSON();
          },
          getName(spot) {
            return spot.favorite?.name
            || (!!spot.favorite?.favorite_id && `${spot.favorite?.favorite_id}`) // 〈お気に入り〉の名前は運用上必須となっているため、こちらが返されることはないと思うが……。
            || spot.placeResult?.name
            || null;
          },
          getAddress(spot) {
            return spot.placeResult?.formatted_address
              || spot.geocoderResult?.formatted_address
              || null;
          },
          isSelected(spot) {
            return !!spot.favorite?.location
              || !!spot.placeResult
              || !!spot.geocoderResult;
          }
        };
      }
    
      // スポット情報をクラスにセット
      setSpot(spot: Myspot.SearchConditionSpot) {
        // 前回マイスポット一覧画面で選択したスポット情報
        const current = { ... this[0] };

        // スポット情報をクラスに保存
        this[0] = {
          ...spot,
          freeText: spot.freeText || current.freeText
        }
      }
    }
    this.searchCondition = new SearchConditionImpl();

    // ユーザーのマイスポット一覧を取得
    this.busy = this.favoriteWebApiService.allFavorites<FavoriteWebApiSchemas.ResponseSchemas.Favorite>().subscribe({
      // 正常系レスポンスの場合
      next: resFavorites => {
        var favorites = resFavorites.body;

        // 「自宅」マイスポットが無い場合、undefined(未設定)のものとして追加する
        if (!favorites.find(f => f.type == 'home')) {
          this.mySpotsUndefined.push({
            name: "自宅",
            type: 'home'
          })
        }

        this.mySpots = favorites;
      }
    });
  }

  ngOnDestroy(): void {
    this.busy?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * スポット情報をクラスに保存
   * マイスポット編集画面へ遷移
   *
   * @param {FavoriteWebApiSchemas.ResponseSchemas.Favorite} favorite
   * @memberof MyspotComponent
   */
  transitionToEdit(favorite?: FavoriteWebApiSchemas.ResponseSchemas.Favorite): void {
    
    // クラスに、選択したスポット情報を保存
    this.searchCondition.setSpot({favorite});
    //
    this.searchCondition.spot.favorite = favorite;
    // console.log(this.searchCondition);
    // マイスポット編集画面に遷移
    this._navigator.element.pushPage(MyspotEditComponent, {data: this.searchCondition});
  }

  /**
   *編集の終了(元に戻す)
   *
   * @memberof MyspotComponent
   */
  cancel = (): void => this.change(false);

  /**
   *編集の開始
   *
   * @memberof MyspotComponent
   */
  edit = (): void => this.change(true);

  /**
   * 編集モードの切り替え
   *
   * @private
   * @param {boolean} toEdit
   * @memberof MyspotComponent
   */
  private change(toEdit: boolean): void {
    this.isEditList = toEdit;
    // 編集
    if(toEdit) {
      // 編集用配列へ値コピー
      this.mySpots_copy = this.mySpots.map(e => ({ ...e }));
    }
    // 一覧モードの場合、コピーしておいたものに戻す
    else {
      this.mySpots = this.mySpots_copy.map(e => ({ ...e }));
    }
  }

  /**
   *マイスポットの並び替え
   *
   * @param {CdkDragDrop<string[]>} event
   * @return {void} 
   * @memberof MyspotComponent
   */
  drop(event: CdkDragDrop<string[]>) :void {
    if (!this.isEditList) return;
    // マイスポット配列内でインデックスを変更
    moveItemInArray(this.mySpots, event.previousIndex, event.currentIndex);
  }

  /**
   *マイスポットの削除
   *
   * @param {FavoriteWebApiSchemas.ResponseSchemas.Favorite} favorite
   * @return {void}
   * @memberof MyspotComponent
   */
  delete(favorite: FavoriteWebApiSchemas.ResponseSchemas.Favorite): void {
    if (!this.isEditList) return;
    // マイスポット配列から削除対象のスポット情報を削除
    this.mySpots = this.mySpots.filter(e => e.favorite_id != favorite.favorite_id);
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   *マイスポットの削除・並び替え(HTTP通信)
   *
   * @return {void}
   * @memberof MyspotComponent
   */
  register(): void {
    if (!this.isEditList) return;

    // 削除されたfavorite_idを取得
    var deleteFavoriteIds = this.mySpots_copy.map(e => e.favorite_id).filter(copyId => this.mySpots.map(e => e.favorite_id).indexOf(copyId) === -1);
    // マイスポット削除処理を行う。その後、マイスポット編集の結果をサーバーに送る。
    this.busy = forkJoin(
      deleteFavoriteIds.map(
        // 1つずつDELETE /favoriteを実行していく
        id => this.favoriteWebApiService.deleteFavorites(id)
      )).subscribe({
      complete: () => {
        // マイスポットの並び替え
        this.favoriteWebApiService.sortFavorites(this.mySpots.filter(myspot => !!myspot.favorite_id).map(myspot => myspot.favorite_id)).subscribe({
          next: () => {
            this.appMsg.viewDialogMessage(this.msg.CLIENT.MY_SPOT.COMPLETE_EDIT.message(), () => this._navigator.element.replacePage(MyspotComponent));
          },
          error: this.httpErrorResponseParserService.doParse((_error, customErrorContent) => {
            this.appMsg.viewDialogMessage(customErrorContent.smartGotoErrMessage || this.msg.CLIENT.COMMON.E_UNEXPECTED.message());
          })
        });
      }
    });
  }

}

export namespace Myspot {
  export interface SearchConditionSpot {
    
    /**
     * 地点情報取得結果
     *
     * @type {google.maps.places.PlaceResult}
     * @memberof SearchConditionSpot
     */
    placeResult?: google.maps.places.PlaceResult;
    /**
     * マップクリックイベント(スポットマーカークリック以外)
     *
     * @type {google.maps.MouseEvent}
     * @memberof SearchConditionSpot
     */
    mapMouseEvent?: google.maps.MouseEvent;
    /**
     * 地点情報(スポットマーカークリック以外)
     *
     * @type {google.maps.GeocoderResult}
     * @memberof SearchConditionSpot
     */
    geocoderResult?: google.maps.GeocoderResult;
    /**
     * 選択したマイスポット情報
     *
     * @type {FavoriteWebApiSchemas.ResponseSchemas.Favorite}
     * @memberof SearchConditionSpot
     */
    favorite?: FavoriteWebApiSchemas.ResponseSchemas.Favorite;
    /**
     *
     *
     * @type {string}
     * @memberof SearchConditionSpot
     */
    searchText?: string;
    /**
     * マップの中心地点(google mapのオプション)
     *
     * @type {google.maps.LatLngLiteral}
     * @memberof SearchConditionSpot
     */
    mapCenter?: google.maps.LatLngLiteral;
    /**
     * マップのズーム設定(google mapのオプション)
     *
     * @type {number}
     * @memberof SearchConditionSpot
     */
    mapZoom?: number;
    /**
     * 
     *
     * @type {string}
     * @memberof SearchConditionSpot
     */
    freeText?: string;
  }

  /**
   * SearchConditionImplクラス、メソッドインターフェイス
   *
   * @export
   * @interface SearchConditionSpotService
   */
  export interface SearchConditionSpotService {
    /**
     *スポットの緯度経度を返す
     *
     * @param {SearchConditionSpot} spot
     * @return {*}  {google.maps.LatLng}
     * @memberof SearchConditionSpotService
     */
    getGeometryLocation(spot: SearchConditionSpot): google.maps.LatLng;
    /**
     *スポットの緯度経度を返す
     *
     * @param {SearchConditionSpot} spot
     * @return {*}  {google.maps.LatLngLiteral}
     * @memberof SearchConditionSpotService
     */
    getLocation(spot: SearchConditionSpot): google.maps.LatLngLiteral;
    /**
     *スポットの名前を返す
     *
     * @param {SearchConditionSpot} spot
     * @return {*}  {string}
     * @memberof SearchConditionSpotService
     */
    getName(spot: SearchConditionSpot): string;
    /**
     *スポットの住所を返す
     *
     * @param {SearchConditionSpot} spot
     * @return {string}
     * @memberof SearchConditionSpotService
     */
    getAddress(spot: SearchConditionSpot): string;
    /**
     * スポットの存在を返す
     *
     * @param {SearchConditionSpot} spot
     * @return {boolean}
     * @memberof SearchConditionSpotService
     */
    isSelected(spot: SearchConditionSpot): boolean;
  }

  export interface SearchCondition {
    
    /**
     * スポット情報
     *
     * @type {SearchConditionSpot}
     * @memberof SearchCondition
     */
    spot: SearchConditionSpot;

    /**
     * SearchConditionImplクラス、メソッドインターフェイス
     *
     * @type {SearchConditionSpotService}
     * @memberof SearchCondition
     */
    readonly spotService: SearchConditionSpotService;

    /**
     * クラスにスポット情報を保存
     *
     * @param {SearchConditionSpot} spot
     * @memberof SearchCondition
     */
    setSpot(spot: SearchConditionSpot): void;
  }
}
