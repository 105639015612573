//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Params, OnsNavigator } from 'ngx-onsenui';
import * as moment from 'moment';
import * as CONST from '../../../constants/constant';

import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { PurchaseWebApiService } from '../../../http-services/purchase-web-api.service';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';
import { PagerService } from 'src/app/lib-services/pager.service';

import { ExUser, TicketCatalog } from '../../../interfaces/response';
import { PurchaseTicketComponent } from '../purchase-ticket/purchase-ticket.component';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 乗車券新規購入確認画面
 *
 * @export
 * @class PurchaseTicketConfirmComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @author （株）オブジェクトデータ wada
 */
@Component({
  selector: 'ons-page[purchase-ticket-confirm]',
  templateUrl: './purchase-ticket-confirm.component.html',
  styleUrls: ['./purchase-ticket-confirm.component.css']
})
export class PurchaseTicketConfirmComponent implements OnInit, OnDestroy 
{

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * ユーザ情報
   *
   * @type {ExUser}
   * @memberof PurchaseTicketConfirmComponent
   */
  purchaseUser: ExUser;
  
  /**
   * 購入対象チケット情報
   *
   * @type {TicketCatalog}
   * @memberof PurchaseTicketConfirmComponent
   */
  purchaseTargetTicket: TicketCatalog;

  /**
   * 自動更新設定値
   *
   * @type {boolean}
   * @memberof PurchaseTicketConfirmComponent
   */
  m_bContinueStatus: boolean = false;

  /**
   * 乗車券の名称
   *    定額乗車券/数日乗り放題券
   *
   * @type {string}
   * @memberof PurchaseTicketComponent
   */
  ticketTypeName: string = CONST.Purchase.TICKET_TITLE_CITIZEN;

  /**
   * 購読用サブスクリプション
   *
   * @type {Subscription}
   * @memberof PurchaseTicketConfirmComponent
   */
  busy: Subscription;

  /**
   * 日付フォーマット用
   *
   * @memberof PurchaseTicketNewComponent
   */
  moment = moment;

  /**
   * サーバにチケット購入リクエスト実行中かどうか
   *
   * @type {boolean}
   * @memberof PurchaseTicketConfirmComponent
   */
  isRequesting: boolean = false;

  /**
   * エリアを選択する乗車券かどうか
   *
   * @type {boolean}
   * @memberof PurchaseTicketConfirmComponent
   */
  isSelectAreaTicket: boolean;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of PurchaseTicketConfirmComponent.
   * @param {OnsNavigator} _navigator
   * @param {ApplicationMessageService} appMsg
   * @param {PurchaseWebApiService} _purchaseWebApiService
   * @param {HttpErrorResponseParserService} httpErrorResponseParserService
   * @param {Params} _params
   * @memberof PurchaseTicketConfirmComponent
   */
  constructor(
    private appMsg: ApplicationMessageService, 
    private purchaseWebApiService: PurchaseWebApiService,
    private httpErrorResponseParserService: HttpErrorResponseParserService,
    private _params: Params, 
    private navigator: OnsNavigator, 
    private pager: PagerService,
    private municipalityWebApiServ: MunicipalityWebApiService,
    private msg: MESSAGE,
  ) { }

  /**
   * 初期化処理。
   *
   * @memberof PurchaseTicketConfirmComponent
   */
  ngOnInit(): void 
  {
    // パラメータを取得する
    this.purchaseUser = this._params.data.user;
    this.purchaseTargetTicket = this._params.data.ticket;
    this.m_bContinueStatus = this._params.data.continuable;

    // エリアを選択する乗車券かどうか
    this.isSelectAreaTicket = this.purchaseTargetTicket.area && this.purchaseTargetTicket.area.area_ids && this.purchaseTargetTicket.area.area_ids.length !== 0;

    // 乗車券の種別名称を取得
    // this.ticketTypeName = this.purchaseWebApiService.getTicketTypeName(this.purchaseUser.user_type);
  }

  /**
   * 破棄処理。
   *
   * @memberof PurchaseTicketConfirmComponent
   */
  ngOnDestroy(): void 
  {
    // 破棄
    this.busy?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 購入確定処理を行う
   *
   * @memberof PurchaseTicketConfirmComponent
   */
  buyNewTicket(): void {

    // 2重送信対策
    if (this.isRequesting) {
      console.log("2重クリックのためリクエストロック中");
      return;
    }
    this.isRequesting = true;
    
    // パラメータ作成
    const ticketInfo = {
      user_id:            this.purchaseUser.user_id,
      start_date:         this.purchaseTargetTicket.start_date,
      ticket_catalog_id:  this.purchaseTargetTicket.ticket_catalog_id,
      continue:           this.m_bContinueStatus
    };

    // 乗車券購入
    this.busy = this.purchaseWebApiService.buyTicket(ticketInfo).subscribe({
       next: () => {

        this.isRequesting = false;

        this.appMsg.viewDialogMessage(this.msg.CLIENT.PURCHASE.COMPLETE_BUY.message(), () => this.pager.transitionToTopPage(this.navigator, PurchaseTicketComponent));
      },
      error: this.httpErrorResponseParserService.doParse((_err, errContent) => {

        this.isRequesting = false;
        
        // 規約未合意エラー
        if (errContent.smartGotoErrCode == this.appMsg.SERV_CONST_CODE.COMMON.USER_TERM_NEVER_AGREED) {
          // 規約合意していないユーザーとしてメッセージに表示する文字列を取得
          const neverAgreeUser: string = this.municipalityWebApiServ.getTermNeverAgreedUser(errContent);
          // サーバーレスポンスが想定外
          if (neverAgreeUser === "") this.httpErrorResponseParserService.viewErrDialog(errContent);
          // 規約合意していないエラー
          else this.appMsg.viewDialogMessage(this.msg.CLIENT.PURCHASE.E_NOT_TERM_AGREE.message(neverAgreeUser));
        }
        // GMOエラー
        // NOTE: サーバーエラー(オプション含め)から判別することができないため、クライアントメッセージとしている
        else if (errContent.smartGotoErrCode === this.appMsg.SERV_CONST_CODE.PURCHASE.GMO_TRAN_AUTH) {
          this.httpErrorResponseParserService.viewErrDialog(errContent, "BUY_TICKET");
        }
        // 運営債権支払い方法、購入不可エラー
        else if (errContent.smartGotoErrCode === this.appMsg.SERV_CONST_CODE.PURCHASE.METHOD_NO_PERMISSION) {
          this.appMsg.viewDialogMessage(this.msg.CLIENT.PURCHASE.METHOD_NO_PERMISSION_BUY_TICKET.message());
        }
        else this.httpErrorResponseParserService.viewErrDialog(errContent);
      })
    });
  }
}