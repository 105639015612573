//=============================================================================================
// インポート
//=============================================================================================
import { AfterViewInit, Component, ElementRef, Inject, NgZone, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { OnsNavigator ,Params } from 'ngx-onsenui';
import { Observable, Subscription } from 'rxjs';

// service
import { UserWebApiService } from "../../../http-services/user-web-api.service";
import { OrderRequestService } from "../../../lib-services/order-request.service";
import { AllocationConditionService, targetKind } from "../../../lib-services/allocation-condition-service";
import { SpotGeolocationService } from '../../../http-services/spot-geolocation.service';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';
import { DispatchWebApiService } from 'src/app/http-services/dispatch-web-api.service';
import { ApplicationMessageService } from 'src/app/lib-services/application-message.service';

// interface
import { request } from '../../../interfaces/request';

// other
import { UserReservation } from '../user-reservation/user-reservation';
import { PLACE_MAP_BUSSTOP_MARKER_ICON, PLACE_MAP_CURRENT_POSITION_MARKER_ICON, PLACE_MAP_MAP_OPTIONS, PLACE_MAP_MARKER_ICON, PLACE_MAP_SELECTED_MARKER_ICON } from './place-map';
import { SearchMethodListComponent } from '../search-method-list/search-method-list.component';
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';
import * as ons from 'onsenui';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 地図検索画面
 * 遷移元： {@link SearchMethodListComponent}
 *
 * @export
 * @class PlaceMapComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @implements {AfterViewInit}
 */
@Component({
  selector: 'ons-page[place-map]',
  templateUrl: './place-map.component.html',
  styleUrls: ['./place-map.component.css']
})
export class PlaceMapComponent implements OnInit, OnDestroy, AfterViewInit {

//=============================================================================================
// クラス変数
//=============================================================================================

  /**
   * GoogleMap要素
   *
   * @private
   * @type {GoogleMap}
   * @memberof PlaceMapComponent
   */
  @ViewChild(GoogleMap) private googleMap: GoogleMap;
  /**
   * 検索ボックス要素
   *
   * @private
   * @type {ElementRef<HTMLInputElement>}
   * @memberof PlaceMapComponent
   */
  @ViewChild('pacInput') private pacInput: ElementRef<HTMLInputElement>;
  /**
   * 検索ボックスクリアボタン要素
   *
   * @private
   * @type {ElementRef}
   * @memberof PlaceMapComponent
   */
  @ViewChild('clearButton') private clearButton: ElementRef;

  /**
   * 移動プランの検索条件。
   *
   * @type {UserReservation.SearchCondition}
   * @memberof PlaceMapComponent
   */
  searchCondition: UserReservation.SearchCondition;

  /**
   * 出発地/目的地/注文の種別 
   *
   * @memberof PlaceMapComponent
   */
  targetKind: {

    /**
     * 出発地/目的地/注文の種別を表すコード
     *
     * @type {typeof targetKind}
     */
    type: typeof targetKind;

    /**
     * 種別コードの表示用文字列
     *
     * @type {string}
     */
    name: string;
  };

  /**
   * 選択されているスポット。
   *
   * @type {UserReservation.SearchConditionSpot}
   * @memberof PlaceMapComponent
   */
  targetSpot: UserReservation.SearchConditionSpot;

  /**
   * 選択されているスポットの名称、あるいは住所。
   *
   * @type {string}
   * @memberof PlaceMapComponent
   */
  targetSpotName: string = '';

  /**
   * 地図から選択されたスポット。
   *
   * @type {UserReservation.SearchConditionSpot}
   * @memberof PlaceMapComponent
   */
  selectedSpot: UserReservation.SearchConditionSpot;

  /**
   * 地図の中心位置。
   *
   * @type {(google.maps.LatLng | google.maps.LatLngLiteral)}
   * @memberof PlaceMapComponent
   */
  center: google.maps.LatLng | google.maps.LatLngLiteral;

  /**
   * 地図のズームレベル。
   *
   * @type {number}
   * @memberof PlaceMapComponent
   */
  zoom: number;

  /**
   * ユーザーの現在位置。
   *
   * @type {google.maps.LatLng}
   * @memberof PlaceMapComponent
   */
  currentPosition: google.maps.LatLng;

  /**
   * スポットを表すマーカー。
   *
   * @type {SpotMarker[]}
   * @memberof PlaceMapComponent
   */
  markers: SpotMarker[] = [];

  /**
   * 非同期処理の実行状態を表す。
   *
   * @type {Subscription}
   * @memberof PlaceMapComponent
   */
  busy: Subscription;

  /**
   * バス停取得 Promise
   *
   * @type {Promise<any>}
   * @memberof PlaceMapComponent
   */
  busyBusstop: Promise<any>;

  /**
   * GoogleMapのPlacesServiceオブジェクト
   *
   * @private
   * @type {google.maps.places.PlacesService}
   * @memberof PlaceMapComponent
   */
  private placesService: google.maps.places.PlacesService;
  /**
   * GoogleMapのGeocoderオブジェクト
   *
   * @private
   * @memberof PlaceMapComponent
   */
  private geocoder = new google.maps.Geocoder();

  private googleMapOnCenterChanged: Subscription;
  private googleMapOnZoomChanged: Subscription;
  private googleMapOnMapClick: Subscription;

  /**
   * assetsファイルへのパス(定数)
   *
   * @type {string}
   * @memberof PlaceMapComponent
   */
  readonly ASSETS_SPOT_ACTIVE: string = CommonFunctionModule.getAssetsUrl('/image/common/13-Spot_Active.png')

  /**
   * エリアの代表地点
   *
   * @private
   * @type {google.maps.LatLngLiteral}
   * @memberof PlaceMapComponent
   */
  private defaultSpotPosition: google.maps.LatLngLiteral;

  /**
   * 情報ウィンドウ
   *
   * @type {google.maps.InfoWindow}
   * @memberof PlaceMapComponent
   */
  infoWindow: google.maps.InfoWindow;

  /**
   * バス停マーカー
   *
   * @type {google.maps.marker.AdvancedMarkerElement[]}
   * @memberof PlaceMapComponent
   */
  bussstopMarker: google.maps.marker.AdvancedMarkerElement[] = [];

  /**
   * バス停が表示されているかどうか
   *
   * @type {boolean}
   * @memberof PlaceMapComponent
   */
  isVisibleBusstop: boolean = true;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of PlaceMapComponent.
   * @param {google.maps.MapOptions} mapOptions 地図に設定されるプロパティ。
   * @param {google.maps.Icon} currentPositionMarkerIcon 現在位置を表すマーカーのアイコン。
   * @param {google.maps.Icon} markerIcon テキスト検索の結果として地図に表示されるマーカーのアイコン。
   * @param {google.maps.Icon} selectedMarkerIcon 選択されたスポットを表すマーカーのアイコン。
   * @param {google.maps.LatLngLiteral} defaultSpotPosition 位置情報が適切でなかった場合の既定の座標。
   * @param {SpotGeolocationService} spotGeolocationServ デバイスやスポットの位置情報の扱いを簡略化するためのユーティリティ。
   * @param {AllocationConditionService} allocationServ searchConditionクラスのサービス版、買い物機能からはこちらを利用している。
   * @param {OrderRequestService} orderServ
   * @param {Params} params 遷移元のページから渡されるパラメーター。
   * @param {NgZone} ngZone 非同期処理を Angular の Zone の中で実行できるようにするためのサービス。
   * @memberof PlaceMapComponent
   */
  constructor(
    @Inject(PLACE_MAP_MAP_OPTIONS) public mapOptions: google.maps.MapOptions, 
    @Inject(PLACE_MAP_CURRENT_POSITION_MARKER_ICON) private currentPositionMarkerIcon: google.maps.Icon, 
    @Inject(PLACE_MAP_MARKER_ICON) private markerIcon: google.maps.Icon, 
    @Inject(PLACE_MAP_SELECTED_MARKER_ICON) private selectedMarkerIcon: google.maps.Icon,
    @Inject(PLACE_MAP_BUSSTOP_MARKER_ICON) private busstopIcon: google.maps.Icon,
    private userServ: UserWebApiService, 
    private spotGeolocationServ: SpotGeolocationService, 
    private allocationServ: AllocationConditionService, 
    private orderServ: OrderRequestService, 
    private navigator: OnsNavigator, 
    private params: Params, 
    private ngZone: NgZone,
    private municipalityWebApiServ: MunicipalityWebApiService,
    private dispatchWebApiServ: DispatchWebApiService,
    private appMsgServ: ApplicationMessageService,
    private msg: MESSAGE,
  ) {
    this.defaultSpotPosition = this.municipalityWebApiServ.setting.map.representive_loc;
  }

  /**
   * コンポーネントが初期化される際に呼び出されるメソッド。
   *
   * @memberof PlaceMapComponent
   */
  ngOnInit(): void {

    this.searchCondition = this.params.data.condition;
    this.targetKind = {
      type: this.params.data.kind, 
      name: this.allocationServ.getTargetPlaceKindName(this.params.data.kind)
    };



    // this.targetPlaceKindName = this.allocationServ.getTargetPlaceKindName(this.targetPlaceKind);
    if (this.targetKind.type !== 'order') {
      this.targetSpot = this.searchCondition[this.targetKind.type];
      // バス停取得
      this.busyBusstop = this.dispatchWebApiServ.getBusstop().then(busstop => {
        this.setBusstop(busstop);
      });
    }
    else this.targetSpot = this.allocationServ[this.targetKind.type];

    /**
     * お届け先の位置情報を取得する。
     *
     * @return {*}  {google.maps.LatLng}
     */
    const deliverlyPosition = (): google.maps.LatLng => {
      const location = this.userServ.getUserInfo().profile.address.location;
      if (!location?.lat || !location?.lng) return undefined;
      return new google.maps.LatLng(location.lat, location.lng);
    }

    // 地図の中心位置とzoom値を設定
    this.busy = new Observable<{
      /** 地図の中心位置 */
      center?: google.maps.LatLng | google.maps.LatLngLiteral;
      /** 地図のzoom値 */
      zoom?: number;
    }>(subscriber => {
      
      // デバイスの現在位置取得
      this.spotGeolocationServ.getCurrentPosition().subscribe({
        next: currentPos => {

          if (currentPos.position) {
            const marker = this.drawMarker(new google.maps.LatLng(currentPos.position?.coords?.latitude, currentPos.position?.coords?.longitude), this.currentPositionMarkerIcon);
            marker.addListener('click', (event:google.maps.MapMouseEvent) => {this.selectSpecifiedLocation(event)});
          }

          this.currentPosition = currentPos.position && this.spotGeolocationServ.createLatLng(currentPos.position.coords);

          // 必要な情報が選択済みか
          if (this.allocationServ.spotUtil.isSelected(this.targetSpot)) {
            // 出発地/目的地を現在地に設定している
            if (this.targetSpot.useCurrentPosition) {
              if (this.currentPosition && this.spotGeolocationServ.maxValidBounds.contains(this.currentPosition)) {
                let center: google.maps.LatLng | google.maps.LatLngLiteral;

                this.getGeocoderResultInNgZone(this.currentPosition).subscribe({
                  next: geocoderResult => {
                    this.selectSpot({
                      arbitraryLocation: this.currentPosition,
                      geocoderResult
                    });

                    center = this.currentPosition;
                  },
                  complete: () => {
                    subscriber.next({ center });
                    subscriber.complete();
                  }
                });
              }
              else {
                subscriber.next({});
                subscriber.complete();
              }
            }
            // 登録住所を設定している
            else if (this.targetSpot.registerAddress) {
              let center: google.maps.LatLng | google.maps.LatLngLiteral;

              this.getGeocoderResultInNgZone(deliverlyPosition()).subscribe({
                next: geocoderResult => {
                  this.selectSpot({
                    arbitraryLocation: deliverlyPosition(),
                    geocoderResult
                  });

                  center = deliverlyPosition();
                },
                complete: () => {
                  subscriber.next({ center });
                  subscriber.complete();
                }
              });
            }
            else {
              // 設定しているスポットを選択
              this.selectSpot(this.targetSpot);

              subscriber.next({ center: this.allocationServ.spotUtil.getLocation(this.targetSpot) });
              subscriber.complete();
            }
          }
          else {
            subscriber.next((() => {
              if (this.targetSpot.mapCenter) {
                return {
                  center: this.targetSpot.mapCenter,
                  zoom: this.targetSpot.mapZoom
                };
              }
              else if (this.targetKind.type === "order") {
                this.targetSpotName = this.orderServ.deliveryPlace.name;
                return { center: deliverlyPosition() };
              }
              else if (this.currentPosition && this.spotGeolocationServ.maxValidBounds.contains(this.currentPosition)) {
                return { center: this.currentPosition };
              }
              else {
                return {};
              }
            })());

            subscriber.complete();
          }
        }
      });
    }).subscribe({
      next: a => {
        this.center = this.targetSpot.mapCenter = (a.center || this.defaultSpotPosition);
        this.zoom = this.targetSpot.mapZoom = (a.zoom || 16);
      }
    });
  }

  /**
   * コンポーネントのビューが初期化された後に呼び出されるメソッド。
   *
   * @memberof PlaceMapComponent
   */
  ngAfterViewInit(): void {

    // コンストラクト
    this.placesService = new google.maps.places.PlacesService(this.googleMap.googleMap);

    // 検索ボックスの初期値
    this.pacInput.nativeElement.value = this.targetSpot.freeText || '';

    this.googleMapOnCenterChanged = this.googleMap.centerChanged.subscribe(() => {
      this.targetSpot.mapCenter = this.googleMap.getCenter();
    });

    this.googleMapOnZoomChanged = this.googleMap.zoomChanged.subscribe(() => {
      this.targetSpot.mapZoom = this.googleMap.getZoom();
    });

    this.googleMapOnMapClick = this.googleMap.mapClick.subscribe(event => {
      event.stop(); // InfoWindow が表示されるのを防ぐ

      const placeId = (event as google.maps.IconMouseEvent)?.placeId;

      if (placeId) {
        // placeIdを基に詳細情報を取得
        this.placesService.getDetails({
          // TODO: 必要に応じて fields プロパティを指定し、検索結果として不要な項目を除去する。
          placeId: placeId
        }, (result, status) => {
          this.ngZone.run(() => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              // スポットを選択
              this.selectSpot({ placeResult: result });
              // フリーテキストと検索欄に名前を代入
              this.targetSpot.freeText = this.pacInput.nativeElement.value = result.name;
            }
          });
        });
      }
      else {
        // スポットを選択し、フリーテキストと検索欄に名前を代入
        this.selectSpecifiedLocation(event);
      }
    });

    /** 検索ボックス */
    const searchBox = new google.maps.places.SearchBox(this.pacInput.nativeElement, {
      bounds: this.spotGeolocationServ.maxValidBounds
    });

    this.googleMap.controls[google.maps.ControlPosition.TOP_CENTER].push(this.pacInput.nativeElement);
    this.googleMap.controls[google.maps.ControlPosition.TOP_CENTER].push(this.clearButton.nativeElement);

    // 検索欄入力による地図検索
    searchBox.addListener('places_changed', () => {
      this.ngZone.run(() => {
        // 選択場所を初期化
        this.selectedSpot = null;
        this.targetSpot.freeText = this.pacInput.nativeElement.value;

        /** 検索結果 */
        const results = searchBox.getPlaces().filter(place => !!place.geometry).filter(place => this.spotGeolocationServ.maxValidBounds.contains(place.geometry.location));

        if (results.length == 0) {
          this.markers = [];
          this.refreshMarkers();
        }
        else {
          this.markers = results.map<SpotMarker>(result => ({
            spot: {
              placeResult: result,
              searchText: this.targetSpot.freeText
            },
            options: {
              icon: this.markerIcon
            }
          }));
          this.refreshMarkers();

          // 地図の表示範囲を更新
          this.googleMap.fitBounds(results.reduce((bounds, result) => {
            if (result.geometry.viewport) {
              bounds.union(result.geometry.viewport);
            }
            else {
              bounds.extend(result.geometry.location);
            }

            return bounds;
          }, new google.maps.LatLngBounds()));
        }
      });
    });
  }

  /**
   * コンポーネントが破棄される際に呼び出されるメソッド。
   *
   * @memberof PlaceMapComponent
   */
  ngOnDestroy(): void {

    [this.busy, this.googleMapOnCenterChanged, this.googleMapOnZoomChanged, this.googleMapOnMapClick].forEach(s => {
      s?.unsubscribe();
    });
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 指定されたマーカーを選択状態にする。
   *
   * @param {SpotMarker} marker 選択されたマーカー。
   * @memberof PlaceMapComponent
   */
  selectSpotMarker(marker: SpotMarker): void {

    this.selectedSpot = marker.spot;

    this.markers.forEach(m => {
      m.options = {
        icon: this.markerIcon
      };
    });

    marker.options = {
      icon: this.selectedMarkerIcon
    };
    this.refreshMarkers();

    // スポット名称取得
    this.targetSpotName = this.allocationServ.spotUtil.getNameOrAddress(this.selectedSpot);
  }

  /**
   * 地図上の任意の場所を選択した時のイベントハンドラ
   *
   * @param {google.maps.MouseEvent} event 選択された場所の座標を含むオブジェクト。
   * @memberof PlaceMapComponent
   */
  selectSpecifiedLocation(event: google.maps.MapMouseEvent): void {

    this.getGeocoderResultInNgZone(event.latLng).subscribe({
      next: geocoderResult => {
        this.selectSpot({
          arbitraryLocation: event.latLng,
          geocoderResult
        });
        this.targetSpot.freeText = this.pacInput.nativeElement.value = '指定された地点';
      }
    });
  }

  /**
   * スポットの位置を取得する。
   *
   * @param {UserReservation.SearchConditionSpot} spot
   * @return {*}  {(google.maps.LatLng | google.maps.LatLngLiteral)}
   * @memberof PlaceMapComponent
   */
  getLocation(spot: UserReservation.SearchConditionSpot): google.maps.LatLng | google.maps.LatLngLiteral {

    return this.allocationServ.spotUtil.getLocation(spot);
  }

  /**
   * 地図から選択されたスポットを〈出発地/目的地〉として設定する。
   *
   * @memberof PlaceMapComponent
   */
  async decide(): Promise<void> {

    if (this.targetKind.type !== 'order') {
      this.searchCondition.setSpot(this.targetKind.type, this.selectedSpot);
    }
    else {
      this.allocationServ.setSpot(this.targetKind.type, this.selectedSpot);

      // 緯度経度取得
      const location = this.allocationServ.spotUtil.getGeometryLocation(this.selectedSpot);

      // スポットの住所を取得
      const chooseSpotProperties = async (spot: UserReservation.SearchConditionSpot): Promise<request.ReservationPlace> => {
        return this.spotGeolocationServ.getGeocoderPlaceResultAddress(spot);
      };

      // 住所取得
      let address: string = undefined;
      let result: request.ReservationPlace;
      if (this.selectedSpot.placeResult) result = await chooseSpotProperties(this.selectedSpot);
      else if (this.selectedSpot.geocoderResult) address = this.spotGeolocationServ.formatGeocoderResultAddress(this.selectedSpot.geocoderResult);

      // お届け先を設定
      this.orderServ.setDeliveryPlace({
        name: this.allocationServ.spotUtil.getFormalNameOrAddress(this.selectedSpot), 
        address: result?.address ?? address, 
        location: { lat: location.lat(), lng: location.lng() }
      });
    }

    // 配車/買い物ページ（2ページ前）へ戻る
    this.navigator.element.removePage(this.navigator.element.pages.length - 2).then(() => {
      setTimeout(() => { this.navigator.element.popPage().then(() => {
        // 遷移元が買い物の場合、対応処理を実施
        if (this.params.data.callMethod !== undefined) this.params.data.callMethod();
      } ); }, 0);
    });
  }

  /**
   * googleMapズーム変更時イベント
   *
   * @memberof PlaceMapComponent
   */
  onZoomChanged(): void {
    // お届け先設定画面の場合、処理を抜ける
    if (this.targetKind.type === 'order') return;

    const zoom: number = this.googleMap.getZoom();

    if (zoom <= 12) {
      // マーカー非表示
      this.bussstopMarker.forEach(item => (item.content as HTMLImageElement).hidden = true);
      // toastメッセージを表示する。
      if (this.isVisibleBusstop === true) {
        // this.appMsgServ.viewToastMessage(this.appMsgServ.getCLientMsg(this.appMsgServ.CLIENT_CODE.DISPATCH.BUSSTOP_NO_VISIBLE));
        const toast = document.getElementById("toast--bottom");
        if (!toast || (toast && toast.style.display == "none")) {
          ons.notification.toast(this.msg.CLIENT.DISPATCH.BUSSTOP_NO_VISIBLE.message(), {
            force: true, animation: "none", timeout: 4000, class: "toast--bottom"
          });
        }
      }
      this.isVisibleBusstop = false;
    }
    else if (zoom > 12 && this.isVisibleBusstop === false) {
      // 再表示
      this.bussstopMarker.forEach(item => (item.content as HTMLImageElement).hidden = false);
      this.isVisibleBusstop = true;

      const toast = document.querySelectorAll<HTMLElement>(".toast--bottom");
      if (toast.length !== 0) {
        toast.forEach(item => {
          item.style.display = "none";
        })
      }
    }
  }

  /**
   * バス停選択イベント
   * 情報ウィンドウ表示
   *
   * @param {BusstopMarker["busstop"][number]} marker
   * @memberof PlaceMapComponent
   */
  selectBusstop(event, marker: BusstopMarker["busstop"][number]) {

    const text = `<p>${marker.name}</p>`;
    if (this.infoWindow != null && this.infoWindow != void 0) {
      this.infoWindow.close();
      this.infoWindow = void 0;
    }

    // 情報ウィンドウを設定
    this.infoWindow = new google.maps.InfoWindow({ content: text });

    const latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
    this.infoWindow.setPosition(latlng);

    // open
    this.infoWindow.open(this.googleMap.googleMap);

    // バス停を選択
    this.selectSpot({ place: {
      name: marker.name,
      location: {
        lat: marker.position.lat(),
        lng: marker.position.lng()
      }
    } });
    // フリーテキストと検索欄に名前を代入
    this.targetSpot.freeText = this.pacInput.nativeElement.value = marker.name;
  }

  /**
   * マップクリックイベント
   * 情報ウィンドウ削除
   *
   * @memberof PlaceMapComponent
   */
  mapClick() {
    // 情報ウィンドウが開かれているならクローズ
    if (this.infoWindow != null && this.infoWindow != void 0) {
      this.infoWindow.close();
      this.infoWindow = void 0;
    }
  }
  
  /**
   * 入力中の検索テキスト、およびスポットを表すマーカーを消去する。
   *
   * @memberof PlaceMapComponent
   */
  clearFreeText(): void {

    this.targetSpot.freeText = this.pacInput.nativeElement.value = '';
    this.targetSpotName = '';
    this.selectedSpot = null;
    this.markers = [];
    this.refreshMarkers();
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * マップバス停をセット
   *
   * @private
   * @memberof PlaceMapComponent
   */
  private setBusstop(busstop): void {
    // 初期化
    busstop.forEach(item => {
      const position = new google.maps.LatLng(item.geo_location.coordinates[1], item.geo_location.coordinates[0]);
      const marker = this.drawMarker(position, this.busstopIcon);
      this.bussstopMarker.push(marker);

      marker.addListener('click', (event: google.maps.MapMouseEvent) => {
        this.selectBusstop(event, {name: item.name, position: position});
      });
    });
  }

  /**
   * 緯度経度から位置情報を取得
   *
   * @private
   * @param {(google.maps.LatLng | google.maps.LatLngLiteral)} location
   * @return {*}  {Observable<google.maps.GeocoderResult>}
   * @memberof PlaceMapComponent
   */
  private getGeocoderResultInNgZone(location: google.maps.LatLng | google.maps.LatLngLiteral): Observable<google.maps.GeocoderResult> {

    return new Observable<google.maps.GeocoderResult>(subscriber => {
      this.geocoder.geocode({ location }, (results, status) => {
        this.ngZone.run(() => {
          const result = (status === google.maps.GeocoderStatus.OK) && results[0];

          if (result) {
            subscriber.next(result);
          }

          subscriber.complete();
        });
      });
    });
  }

  /**
   * スポットを選択して、マーカーと名前をセット
   *
   * @private
   * @param {UserReservation.SearchConditionSpot} spot
   * @memberof PlaceMapComponent
   */
  private selectSpot(spot: UserReservation.SearchConditionSpot): void {
    this.selectedSpot = { ...spot };  // = spotでいいのでは？
    // マーカーをセット
    setTimeout(() => {
      this.markers = [{
        spot: this.selectedSpot,
        options: {
          icon: this.selectedMarkerIcon
        }
      }];
      this.refreshMarkers();
    });

    this.targetSpotName = this.allocationServ.spotUtil.getNameOrAddress(this.selectedSpot) || "指定された地点";
  }

  /**
   * 描画済みのマーカー
   */
  private drown_markers:google.maps.marker.AdvancedMarkerElement[] = [];

  /**
   * マーカー再描画
   */
  private refreshMarkers():void {
    // 描画済みのものを削除
    this.drown_markers.forEach(drown_marker => {
      drown_marker.map = null;
      drown_marker.remove();
    });
    this.drown_markers = [];

    // マーカーを描画
    for (let marker of this.markers) {
      const position = this.getLocation(marker.spot);
      const drown_marker = this.drawMarker(position, marker.options.icon);
      drown_marker.addListener('click', () => {
        this.selectSpotMarker(marker);
      });

      // 描画済み
      this.drown_markers.push(drown_marker);
    }
  }


  /**
   * マーカー描画
   * @param car_info 
   */
  private drawMarker(position: google.maps.LatLng | google.maps.LatLngLiteral, icon: google.maps.Icon): google.maps.marker.AdvancedMarkerElement {

    const options: google.maps.marker.AdvancedMarkerElementOptions = {
      map: this.googleMap.googleMap,      
      position: position,
      content: CommonFunctionModule.convertIconToContent(icon),
    }
    
    const marker = new google.maps.marker.AdvancedMarkerElement(options);

    return marker;
  }

}

/**
 * スポットを表すマーカー。
 *
 * @interface SpotMarker
 */
interface SpotMarker {
  /**
   * スポット。
   *
   * @type {UserReservation.SearchConditionSpot}
   * @memberof SpotMarker
   */
  spot: UserReservation.SearchConditionSpot;

  /**
   * マーカーのプロパティ。
   *
   * @memberof SpotMarker
   */
  options: {
    icon: google.maps.Icon;
  };
}

/**
 * バス停
 *
 * @interface BusstopMarker
 */
interface BusstopMarker {
  busstop?: {
    position: google.maps.LatLng;
    name: string;
  }[],
}
