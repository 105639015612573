<ons-toolbar>
  <div class="left">
    <ons-back-button #onsBackButton></ons-back-button>
  </div>
  <div class="center toolbar__title-smartgoto-wrap">
    <div class="toolbar__title-smartgoto">Smart GOTO</div>
    <div *ngIf="areaName" class="toolbar__title-smartgoto--area-name">({{areaName}}版)</div>
  </div>
</ons-toolbar>
<div class="content">
  <div [ngBusy]="busy"></div>
  <div class="center segment-wrap">
    <ons-segment class="segment" active-index="2">
      <button disabled>STEP1</button>
      <button disabled>STEP2</button>
      <button>STEP3</button>
    </ons-segment>
    <div class="content--description">ファミリーの本人確認を行ってください。</div>
  </div>
  <div class="content--description content--text--around">
    以下のユーザーをファミリーに招待します。<br>
    よろしいですか？
  </div>
  <ons-list>
    <ons-list-header class="list-item__title">ユーザー情報</ons-list-header>
    <ons-list-item id="family-auth-step3_003" class="list-item__text">
      ID：{{ memberUserId }}
    </ons-list-item>

    <ons-list-item class="list-item_text">
      <div class="content-text-wrap">
        <p>
          招待を行うと、ファミリーにあなたの以下の個人情報を開示します。
        </p>
        <p class="content-text-wrap__padding-top">
          ・氏名
        </p>
        <p class="content-text-wrap__padding-top">
          <input id="invite-check" type="checkbox" #checkbox><label class="content-text-wrap__checkbox" for="invite-check">上記の個人情報開示に同意する</label>
        </p>
      </div>
    </ons-list-item>
  </ons-list>
  <div class="button-wrap button-wrap--flex">
    <ons-button id="family-auth-step3_001" class="button button--large--cta button--cancel" (click)="onClickAbortButton()">中止</ons-button>
    <ons-button id="family-auth-step3_002" class="button button--large--cta" (click)="onClickInviteButton()" [disabled]="!checkbox.checked">招待</ons-button>
  </div>
</div>