//=============================================================================================
// インポート
//=============================================================================================
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { common } from '../interfaces/common';
import { UserInfo } from '../interfaces/response';
import * as moment from 'moment';
import { QrContent, QrType, SmartGotoQr } from '../interfaces/smartgoto-qr';
import { Qr } from '../constants/constant';
import { environment } from 'src/environments/environment';

/**
 * 共通関数モジュール
 *
 * @export
 * @class CommonFunctionModule
 */
@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    SmartGotoQr,
    QrContent,
  ],
})
export class CommonFunctionModule {
  constructor(
    private smartGotoQr: SmartGotoQr,
    private qrContent: QrContent,
  ) {  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * 日付のリストを作成する。
   *    value : yyyy-mm-dd
   *    label : yyyy/nn/dd
   *
   * @param {number} maxLength 最大日数
   * @return {*}  {selectBox[]}
   * @memberof CommonFuncionModule
   */
  createDateList(maxLength: number): selectBox[] {
    
    // 現在日付を取得
    let disp = new Date();
    let list: selectBox[] = [];

    // 最大日数分のリストを作成
    for (let count = 0; count < maxLength; count++) 
    {
      // 「yyyy-mm」形式を作成
      let work = disp.getFullYear() + "-" + (disp.getMonth() + 1).toString().padStart(2, '0') + "-" + disp.getDate().toString().padStart(2, '0');

      // 設定
      list.push( 
        {
          value: work,
          label: work.split('-').join('/')
        }
      );

      // 1日進める
      disp.setDate(disp.getDate() + 1);
    }
    
    return list;
  }

  /**
   * 配列のソート（昇順）を行う。
   *
   * @template T
   * @param {T[]} array
   * @memberof CommonFunctionModule
   */
  sort<T>(array: T[]): void {

    array.sort((a, b) => 
    {
      if (a < b) return -1;
      else if (a > b) return 1;
      else return 0;
    });
  }

  /**
   * 配列のソート（降順）を行う。
   *
   * @template T
   * @param {T[]} array
   * @memberof CommonFunctionModule
   */
  reverse<T>(array: T[]): void {

    array.sort((a, b) => 
    {
      if (a < b) return -1;
      else if (a > b) return 1;
      else return 0;
    });
  }

  /**
   * target日付がsourceStart, sourceEndの間に含まれるか
   *
   * @param {string} sourceStart 比較元の開始日
   * @param {string} sourceEnd 比較元の終了日
   * @param {string} target_start 比較先の開始日
   * @param {string} target_end 比較砂金終了日
   * @return {*}  {boolean} true：targetが合間に含まれる
   *                        false：targetが合間が含まれない
   * @memberof CommonFunctionModule
   */
  compareDateInclude(sourceStart: string, sourceEnd: string, target_start: string, target_end: string): boolean {

    // 所有乗車券：利用開始日
    let start = new Date(sourceStart);

    // 所有乗車券：利用終了日
    let end = new Date(sourceEnd);
    end = new Date(end.getFullYear(), end.getMonth(), end.getDate());

    // 購入乗車券：利用開始日
    let ts = new Date(target_start);

    // 購入乗車券：利用終了日
    let te = new Date(target_end);
    te = new Date(te.getFullYear(), te.getMonth(), te.getDate());

    // 重複チェック
    if (start.getTime() <= ts.getTime() && ts.getTime() <= end.getTime()) return true;
    if (start.getTime() <= te.getTime() && te.getTime() <= end.getTime()) return true;
    if (ts.getTime() <= start.getTime() && end.getTime() <= te.getTime()) return true;

    return false;
  }
   
  /**
   * 日付をyyyymmdd形式の数字に変換する。
   *
   * @private
   * @param {Date} date
   * @return {*}  {number}
   * @memberof CommonFunctionModule
   */
  parseDate(date: Date): number {

    return parseInt('' + date.getFullYear() + ('0' + (date.getMonth() + 1)).slice(-2)+('0' + date.getDate()).slice(-2));
  }
  
  /**
   * 金額表示（￥マーク、カンマ付き）に成形する。
   *
   * @param {number} amount 金額
   * @return {*}  {string} 成形した金額文字列
   * @memberof CommonFunctionModule
   */
  numericalForming(amount: number): string { 

    return this.replaceFullToHalf(new Intl.NumberFormat("ja", { style: "currency", currency: "JPY" }).format(amount ?? 0));
  }

  /**
   * 全角を半角に変換する。
   *
   * @param {string} str
   * @return {*}  {string}
   * @memberof CommonFunctionModule
   */
  replaceFullToHalf(str: string): string {
    
    const regex = /[Ａ-Ｚａ-ｚ０-９！＂＃＄％＆＇（）＊＋，－．／：；＜＝＞？＠［＼］＾＿｀｛｜｝]/g;

    return str.replace(regex, function(s) { return String.fromCharCode(s.charCodeAt(0) - 0xFEE0); });
  }
  
  /**
   * 半角を全角に変換する。
   *
   * @param {string} str
   * @return {*}  {string}
   * @memberof CommonFunctionModule
   */
  replaceHalfToFull(str: string): string {
    
    const regex = /[A-Za-z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|}]/g;

    return str.replace(regex, function(s) { return String.fromCharCode(s.charCodeAt(0) + 0xFEE0); });
  }

  /**
   * 年・月・日の比較を行う。
   *
   * @param {Date} source
   * @param {Date} target
   * @return {*}  {boolean}
   * @memberof CommonFunctionModule
   */
  compareDate(source: Date, target: Date): boolean {

    if (source.getFullYear() === target.getFullYear()) 
    {
      if (source.getMonth() === target.getMonth()) 
      {
        if (source.getDate() === target.getDate()) return true;
      }
    }

    return false;
  }

  /**
   * 住所（県からの完全形）から市以降を取得する。
   *    市がない場合：県からの完全形を返す。
   *
   * @param {common.ImiAddress} imiAddress
   * @return {*}  {string}
   * @memberof CommonFunctionModule
   */
  getAddressAfterCity(imiAddress: common.ImiAddress): string {

    let pos: number = 0;

    // 市が定義されているなら市以降を抽出
    if (imiAddress.city !== undefined) pos = imiAddress.address_org.indexOf(imiAddress.city);

    // 市がないなら県からのフル住所を返す
    else ;

    return imiAddress.address_org.slice(pos === -1 ? 0 : pos)
  }

  /**
   * 住所（県からの完全形）から区以降を取得する。
   *    区がない場合：町以降を取得する。
   *    町もない場合：県からの完全形を返す。
   *
   * @param {common.ImiAddress} imiAddress
   * @return {*}  {string}
   * @memberof CommonFunctionModule
   */
  getAddressAfterWard(imiAddress: common.ImiAddress): string {

    let pos: number = 0;

    // 区が定義されているなら区以降を抽出
    if (imiAddress.ward !== undefined) pos = imiAddress.address_org.indexOf(imiAddress.ward);

    // 区がなく、町があるなら町以降を抽出
    else if (imiAddress.address1 !== undefined) pos = imiAddress.address_org.indexOf(imiAddress.address1);

    // 区も町もないなら県からのフル住所を返す
    else ;

    return imiAddress.address_org.slice(pos === -1 ? 0 : pos)
  }

  /**
   * GoogleMapへのリンクを作成する。
   *
   * @param {(common.Location | string)} [location]
   * @return {*}  {string}
   * @memberof CommonFunctionModule
   */
  getGoogleMapUrl(location?: common.Location | string): string {

    // googleMap遷移用URL
    let googleMapUrl: string;

    if ((<common.Location>location)?.lat !== undefined) googleMapUrl = "https://www.google.com/maps/search/?api=1&query=" + (<common.Location>location).lat + "," + (<common.Location>location).lng + "&z=20";
    else googleMapUrl = "https://www.google.com/maps/search/?api=1&query=" + encodeURI(location as string) + "&z=20";

    return googleMapUrl;
  }

  /**
   * 文字列を日時フォーマットに変換する。
   *
   * @param {string} value
   * @param {string} format
   * @return {*}  {string}
   * @memberof CommonFunctionModule
   */
  transformDateTime(value: string, format: string): string {
    
    return moment(value).format(format);
  }

  /**
   *
   *
   * @param {number} time
   * @return {*}  {string}
   * @memberof CommonFunctionModule
   */
  TimeConvert (minutes: number) : string {
    const min = minutes % 60;
    const hour = Math.floor(minutes / 60);
    console.log(hour > 96 ? `${Math.floor(hour / 24)}日` + (hour % 24 !== 0 ? hour % 24 + '時間' : '') + (min !== 0 ? min + '分' : '') : (hour !== 0 ? hour + '時間' : '') + (min !== 0 ? min + '分' : ''));
    return hour > 96 ? `${Math.floor(hour / 24)}日` + (hour % 24 !== 0 ? hour % 24 + '時間' : '') + (min !== 0 ? min + '分' : '') : (hour !== 0 ? hour + '時間' : '') + (min !== 0 ? min + '分' : '');
  }

  /**
   * 時間(分)を表示形式に変更する。
   *
   * @param {(string | number)} time
   * @return {*}  {string}
   * @memberof CommonFunctionModule
   */
  getDispTime(time: string | number): string {
    let timeNum: number;

    if (typeof time == 'number') timeNum = time;
    if (typeof time == 'string') timeNum = Number(time);

    return (Math.floor(timeNum/60)?`${Math.floor(timeNum/60)}時間`:'') + ((timeNum%60>0)?(timeNum%60>=10?`${timeNum%60}分`: `0${timeNum%60}分`):'');
  }

  /**
   * エスケープ文字に変換
   * XSS対策
   *
   * @param {string} str
   * @return {*} 
   * @memberof CommonFunctionModule
   */
  replaceEscapeSequence(str: string) {
    return str ? str
    .replace(/\&/g, '&amp;')
    .replace(/\</g, '&lt;')
    .replace(/\>/g, '&gt;')
    .replace(/\"/g, '&quot;')
    .replace(/\'/g, '&#x27;') : '';
  }

  /**
   * URL文字列(https...)をタグ付き文字列に変換
   *
   * @param {string} input
   * @return {*}  {string}
   * @memberof CommonFunctionModule
   */
  replaceUrl(input: string): string {
    if (input===void 0 || input===null) return "";
    return input.replace(/https?:\/\/\S*((?=\s+)|$)/g, (text: string) => `<a href='${text}' #link target='_blank'>${text}</a>`);
  }

//=============================================================================================
// SmartGOTO専用
//=============================================================================================

  /**
   * 住所（JSON形式）を文字列に変換する。
   *
   * @param {UserInfo["profile"]["address"]} address
   * @param {boolean} [short=false] true：県名を除外して返却
   * @return {*}  {string}
   * @memberof CommonFunctionModule
   */
  conversionAddress(address: UserInfo["profile"]["address"], short: boolean = false): string {
    
    if (short) return address.city + address.address1 + address.address2 + address.address3;
    return address.prefecture + address.city + address.address1 + address.address2 + address.address3;
  }

  /**
   * 観光目的ユーザの住所が都道府県～番地まで登録されているかチェックする。
   *
   * @param {UserInfo["profile"]["address"]} address
   * @return {*}  {boolean}
   * @memberof CommonFunctionModule
   */
  checkAddressValidity(address: UserInfo["profile"]["address"]): boolean {
    
    if (address.prefecture !== "" 
              && address.city !== "" 
              && address.address1 !== "" 
              && address.address2 !== "") return true;
    else return false;
  }

  /**
   * blob読込→dataURL出力までの一連の流れをPromiseで返す(未動確)
   *
   * @param {Blob} blob
   * @return {*}  {Promise<string>}
   * @memberof CommonFunctionModule
   */
  readAsDataURL(blob: Blob): Promise<string> {
    const fileReader = new FileReader();
    
    return new Promise((res,rej)=>{
      fileReader.addEventListener("load", ({target}) => res(target.result as string));
      fileReader.addEventListener("error", ({target}) => rej(target.error));
      fileReader.readAsDataURL(blob);
    });
  }

  /**
   * 深いコピー
   *
   * @template T srcの型
   * @param {T} src コピー元
   * @return {*}  {T} コピー内容
   * @memberof CommonFunctionModule
   */
  deepcopy<T>(src: T): T {
    return JSON.parse(JSON.stringify(src)) as T;
  }

  /**
   * 文字列をテンプレートリテラルに変換し、変数を代入して返す
   * note: strによるparamsの上書きに注意, 元のparamsを上書きされたくない場合は予めparamsをdeepcopy
   *
   * @param {string} str テンプレートリテラルに変換する文字列
   * @param {Params} params 文字列内の変数オブジェクト
   * @memberof CommonFunctionModule
   */
  evalStr(str: string, params: Params): string {
    try {
      /* 
       * Functionコンストラクタ
       * https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Function/Function 
       */
      return Function(...Object.keys(params), `return \`${str}\`;`)(...Object.values(params));
    }
    catch (err) {
      console.error("evalStrエラー");
      throw err;
    }
  }

  /**
   * 文字列を計算式に変換し、変数を代入して計算結果を返す
   * CAUTION: formulaによるparamsの上書きに注意, 元のparamsを上書きされたくない場合は予めparamsをdeepcopy
   *
   * @param {string} formula
   * @param {Params} params
   * @return {*}  {number}
   * @memberof CommonFunctionModule
   */
  evalNum(formula: string, params: Params): number {
    try {
      /* 
       * Functionコンストラクタ
       * https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Function/Function 
       */
      return Function(...Object.keys(params), `return (${formula})`)(...Object.values(params));
    }
    catch (err) {
      console.error("evalNumエラー");
      throw err;
    }
  }

  /**
   * 文字列を関数に変換し、変数を代入して関数を実行
   * CAUTION: funcによるparamsの上書きに注意, 元のparamsを上書きされたくない場合は予めparamsをdeepcopy
   *
   * @template T 関数の返り値の型
   * @param {string} func 関数に変換する文字列
   * @param {Params} params 文字列内の変数オブジェクト
   * @memberof CommonFunctionModule
   */
  evalFunc<T=any>(func: string, params: Params): T {
    try {
      /* 
       * Functionコンストラクタ
       * https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Function/Function 
       */
      return Function(...Object.keys(params), func)(...Object.values(params));
    }
    catch (err) {
      console.error("evalFuncエラー");
      throw err;
    }
  }

  /**
   * QRから読み取られたコード情報を検証
   *
   * @param {string} qrcode QRコード文字列
   * @return {*}  {("SQRC"|QrType|undefined)}
   * @memberof CommonFunctionModule
   */
   validateQrCode(qrcode: string): "SQRC"|QrType|undefined {
    // SQRC廃止
    // if (Qr.SQRC_PATTERNS.some(p => p.test(qrcode))) {
    //   return "SQRC";  // SQRC
    // }
    let qrObject: SmartGotoQr;
    try {
      qrObject = JSON.parse(qrcode);
    }
    catch (err) {
      return;  // parse不可な文字列
    }
    
    /** qrObjectに含まれるべきプロパティキー配列 */
    const smartGotoQrProperties: (keyof SmartGotoQr)[] = [];
    for (const key in this.smartGotoQr) {
      smartGotoQrProperties.push(key as keyof SmartGotoQr);
    }

    if (smartGotoQrProperties.every(p => qrObject[p]!==void 0)) {
      /** qrObject.contentに含まれるべきプロパティキー配列 */
      const qrContentProperties = (type: keyof QrContent): string[] => {
        const properties: string[] = [];
        for (const key in this.qrContent[type]) {
          properties.push(key);
        }
        return properties;
      }
      // SmartGOTOのQRコード
      if (qrContentProperties(qrObject.type).every(p => qrObject.content[p]!==void 0)/* &&Qr.QR_VER.includes(qrObject.ver) */) {
        return qrObject.type;
      }
      else {
        return; // 必須プロパティが足りていないので、その他
      }
    }
    else {
      return;  // その他
    }
  }

  /**
   * assetフォルダへのURLを取得
   *
   * @static
   * @param {string} fileName
   * @param {boolean} [isAreaCommon=true]
   * @return {*}  {string}
   * @memberof CommonFunctionModule
   */
  static getAssetsUrl(fileName: string, isAreaCommon: boolean = true): string {
    // 全自治体共通 or エリア個別
    const areacode: string = isAreaCommon ? "" : "/" + environment.areacode;

    return environment.base_url + areacode + "/assets" + fileName;
  }

  /**
   * assetフォルダへのURLを取得
   *  cssで使用する形に変換し、取得(url())
   * 
   * @param {string} fileName
   * @param {boolean} [isAreaCommon=true]
   * @return {*}  {string}
   * @memberof CommonFunctionModule
   */
  getAssetsCssUrl(fileName: string): string {
    return "url(" + fileName + ")"; 
  }

  /**
   * Google Maps AdvancedMarker用
   * Icon (urlで画像指定) から Node <img src=...> を作成
   * 
   * @param {google.maps.Icon} icon アイコン
   * @returns {Node}  <img>
   */
  static convertIconToContent(icon: google.maps.Icon): HTMLImageElement {
    const content = document.createElement('img');
    content.setAttribute('src', icon.url);
    if (icon.scaledSize) {
      content.setAttribute('width', ''+icon.scaledSize.width);
      content.setAttribute('height', ''+icon.scaledSize.height);
    }
    return content;
  }

}

//=============================================================================================
// インターフェース定義
//=============================================================================================

/**
 * select-box
 *
 * @export
 * @interface selectBox
 */
interface selectBox {

  /**
  * value
  *
  * @type {(string | number)}
  * @memberof selectBox
  */
  value: string | number;

  /**
  * label
  *
  * @type {(string | number)}
  * @memberof selectBox
  */
  label: string | number;
}

// eval***メソッドで使用
interface Params {
  [param: string]: any;
}