//=============================================================================================
// インポート
//=============================================================================================
import { AfterViewInit, Component,  OnDestroy, OnInit, ElementRef, ViewChild } from '@angular/core';
import { OnsNavigator, Params } from 'ngx-onsenui';
import { Subscription } from 'rxjs';
import * as moment from 'moment-timezone';

// service
import { ShoppingWebApiService } from '../../../../http-services/shopping-web-api.service';
import { CommonFunctionModule } from "../../../../lib-modules/common-function.module";

// component
import { LoginComponent } from '../../../user/login/login.component';
import { OrderCancelComponent } from '../order-cancel/order-cancel.component';

// interface
import { common } from '../../../../interfaces/common';
import { Order } from '../../../../interfaces/response';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 予約情報詳細画面
 *
 * @export
 * @class HistoryOrderDetailComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component
({
  selector: 'ons-page[history-order-detail]',
  templateUrl: './history-order-detail.component.html',
  styleUrls: ['./history-order-detail.component.css']
})

export class HistoryOrderDetailComponent implements OnInit, AfterViewInit, OnDestroy {

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * 「<」ボタン
   *
   * @private
   * @type {ElementRef}
   * @memberof HistoryOrderDetailComponent
   */
  @ViewChild('onsBackButton') private onsBackButton: ElementRef;

  /**
   * 非同期通信の受信オブジェクト
   *
   * @type {Subscription}
   * @memberof HistoryOrderDetailComponent
   */
  busy: Subscription;

  /**
   * 注文情報
   *
   * @type {Order}
   * @memberof HistoryOrderDetailComponent
   */
  order: Order;

  /**
   * 日付操作moment
   *
   * @memberof HistoryOrderDetailComponent
   */
  moment = moment;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of HistoryOrderDetailComponent.
   * @param {ApplicationMessageService} appMsgServ
   * @param {HttpErrorResponseParserService} errResServ
   * @param {ShoppingWebApiService} shoppingServ
   * @param {OnsNavigator} navigator
   * @param {Params} params
   * @memberof HistoryOrderDetailComponent
   */
  constructor
  ( 
    private shoppingServ: ShoppingWebApiService, 
    private commonFuc: CommonFunctionModule, 
    private navigator: OnsNavigator, 
    private params: Params,
  ) { }

  /**
   * 初期化処理。
   *
   * @memberof HistoryOrderDetailComponent
   */
  ngOnInit(): void {

    this.order = this.params.data.order;
  }

  /**
   * HTML上の変数定義
   */
  ngAfterViewInit(): void {

    // バックボタン
    this.onsBackButton.nativeElement.onClick = () => {
      // 履歴再描画メソッド呼び出し
      if (undefined !== this.params.data.reload) this.navigator.element.popPage({ callback: () => { this.params.data.reload(); } });
      // メールからの遷移のため画面を閉じるのみ
      else this.navigator.element.popPage();
    };
  }

  /**
   * 破棄処理
   *
   * @memberof HistoryOrderDetailComponent
   */
  ngOnDestroy(): void {

    this.busy?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 注文のステータスを表示する。
   *
   * @return {*}  {string}
   * @memberof HistoryOrderDetailComponent
   */
  getViewStatus(): string {

    switch (this.order.status) {
      
      case 'INIT':
      case 'READY':
      case 'PICKED':
        return "配達予定";
      case 'DELIVERED':
        return "配達履歴";
      case 'CANCELED_USER':
        return "キャンセル履歴";
      case 'CANCELED_SHOP':
      case 'CANCELED_TRANS':
      case 'CANCELED_OPERATOR':
      case 'CANCELED_SYSTEM':
        return "配達中止履歴";
      default:
        return "ステータスエラー";
    }
  }

  /**
   * 注文結果がある場合、表示する。
   *
   * @return {*}  {string}
   * @memberof HistoryOrderDetailComponent
   */
  getViewDeliveryResult(): string {

    switch (this.order.status) {
      
      case 'DELIVERED':
        return "この注文は" + moment(this.order.d.prod_time).format('YYYY/M/D') + "に配達されました。";
      case 'CANCELED_USER':
        return "この注文は" + moment(this.order.cancel_time).format('YYYY/M/D') + "にキャンセルされました。";
      case 'CANCELED_SHOP':
      case 'CANCELED_TRANS':
      case 'CANCELED_OPERATOR':
      case 'CANCELED_SYSTEM':
        return "この注文は事業者側の都合により、配達中止となりました。";
      default: ;
    }

    return "";
  }

  /**
   * 注文キャンセルが可能かどうか。
   *    集荷前チェックのみ。
   *
   * @return {*}  {boolean}
   * @memberof HistoryOrderDetailComponent
   */
  isOrderCancel(): boolean {

    // 集荷前かつ、キャンセル期限前のみキャンセル可能
    return this.shoppingServ.isOrderCancel(this.order, false);
  }

  /**
   * 配送料の合計金額を取得する。
   *
   * @return {*}  {number}
   * @memberof HistoryOrderDetailComponent
   */
  getOrderCharge(): number {
    
    return this.shoppingServ.getOrderCharge(this.order);
  }

  /**
   * お届け先住所を取得する。
   *
   * @param {common.ImiAddress} imiAddress
   * @return {*}  {string}
   * @memberof HistoryOrderDetailComponent
   */
  getDestinationName(imiAddress: common.ImiAddress): string {

    return this.commonFuc.getAddressAfterCity(imiAddress);
  }

  /**
   *キャンセル画面へ遷移
   *
   * @memberof HistoryOrderDetailComponent
   */
  pushCancel(): void {

    this.navigator.element.pushPage(OrderCancelComponent, { data: { order: this.order, transition: this.params.data.transition }});
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * ページ操作を行う。
   *
   * @param {LoginComponent.Transition} transition
   * @memberof HistoryOrderDetailComponent
   */
  toTransition(transition: LoginComponent.Transition): void {
    switch (transition.method) {
      case 'popPage':
        this.navigator.element.popPage(transition.options);
        break;
      case 'pushPage':
        this.navigator.element.pushPage(transition.page, transition.options);
        break;
      case 'resetToPage':
        this.navigator.element.resetToPage(transition.page, transition.options);
        break;
    }
  }
}