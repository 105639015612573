<ons-toolbar class="toolbar">
  <div class="left">
    <ons-back-button></ons-back-button>
  </div>
  <div class="center">支払い方法</div>
</ons-toolbar>

<div class="content">
  <div [ngBusy]="busy"></div>

  <ons-list class="list-wrap">
    <ons-list-header modifier="color" class="list-item__title">カード番号</ons-list-header>
    <ons-list-item id="payment-method-credit-detaile_001" class="list-item__number">
      <p><span>{{ card.brand }}</span><span>{{ card.card_no }}</span></p>
    </ons-list-item>
    <ons-list-header modifier="color" class="list-item__title">有効期限</ons-list-header>
    <ons-list-item id="payment-method-credit-detaile_002" class="list-item__number">
      <p>{{ toExpireFormat(card.expire) }}</p>
    </ons-list-item>
  </ons-list>

  <div class="checkbox-wrap" *ngIf="isDefaultOrExpiredCard === false">
    <ons-checkbox id="payment-method-credit-detaile_003" input-id="default-flag" [ngModel]="card.is_default" 
                  [attr.disabled]="isDefaultOrExpiredCard ? true : null"
                  (ngModelChange)="changeDefaultPayment()"></ons-checkbox>
    <label [attr.for]="isDefaultOrExpiredCard ? '' : 'default-flag'">デフォルト支払い方法に設定する</label>
  </div>

  <p *ngIf="card.is_expired" class="status--attention">
    有効期限切れカードは支払い方法に設定できません。
  </p>

  <div class="button-wrap">
    <ons-button id="payment-method-credit-detaile_004" class="button button--large--cta" (click)="delete()">お支払情報を削除する</ons-button>
  </div>
</div>