//=============================================================================================
// インポート
//=============================================================================================
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * サイドメニューService
 *
 * @export
 * @class MenuService
 * @author （株）オブジェクトデータ wada
 */
@Injectable({
  providedIn: 'root'
})
export class MenuService 
{

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * subjectインスタンス
   *
   * @memberof MenuService
   */
  subject = new Subject();

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of MenuService.
   * @memberof MenuService
   */
  constructor() { }

//=============================================================================================
// メソッド
//=============================================================================================
  
  /**
   * サイドメニューを登録。
   *
   * @readonly
   * @type {Observable<any>}
   * @memberof MenuService
   */
  get menu$(): Observable<any> 
  {
    return this.subject.asObservable();
  }

  /**
   * サイドメニューを開く。
   *
   * @return {*} 
   * @memberof MenuService
   */
  open(): void 
  {
    this.subject.next();
  }
}
