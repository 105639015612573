import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import * as CONST from '../constants/constant';

import { ApplicationMessageService } from './application-message.service';
import { MunicipalityWebApiService } from '../http-services/municipality-web-api.service';
import { Subscription } from 'rxjs';
import { Settings } from '../interfaces/response';
import { MESSAGE } from '../constants/message';

/**
 * `HttpErrorResponse` オブジェクトを SmartGOTO の形式にカスタマイズするためのユーティリティ。
 *
 * @export
 * @class HttpErrorResponseParserService
 */
@Injectable({
  providedIn: 'root'
})
export class HttpErrorResponseParserService implements OnDestroy {

  /**
   * OnDestroy時に破棄するSubscriptionオブジェクト
   *
   * @memberof HttpErrorResponseParserService
   */
  subscription = new Subscription();

  /**
   * 運営事務所
   *
   * @type {Settings["office"]}
   * @memberof HttpErrorResponseParserService
   */
  office: Settings["office"];

  /**
   * Creates an instance of HttpErrorResponseParserService.
   * @param {LoggingService} loggingService ロギングサービス。
   * @memberof HttpErrorResponseParserService
   */
  constructor(
    private appMsg: ApplicationMessageService,
    private municipalityWebApiServ: MunicipalityWebApiService,
    private msg: MESSAGE,
  ) {
    const settingsChanged = this.municipalityWebApiServ.settingsChanged.subscribe({
      next: setting => {
        if (setting == null) return;
        this.office = setting.office;
      }
    });
    this.subscription.add(settingsChanged);
  }

  /**
   * 廃棄処理
   *
   * @memberof HttpErrorResponseParserService
   */
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  /**
   * `HttpErrorResponse` オブジェクトを SmartGOTO の形式にカスタマイズする。
   *
   * @param {(error: HttpErrorResponse, customErrorContent: HttpCustomErrorContent) => void} action カスタマイズされたエラー情報を受け取るコールバック関数。
   * @return {*}  {(error: HttpErrorResponse) => void} `HttpErrorResponse` オブジェクトを受け取る関数 (`PartialObserver` の `error` プロパティとして指定する)。
   * @memberof HttpErrorResponseParserService
   */
  doParse(action: (error: HttpErrorResponse, customErrorContent: HttpCustomErrorContent) => void): (error: HttpErrorResponse) => void {
    return (error: HttpErrorResponse) => 
    {
      action(error, 
        {
          statusCode: (error.error as HttpCustomErrorContent)?.statusCode, 
          smartGotoErrMessage: (error.error as HttpCustomErrorContent)?.smartGotoErrMessage, 
          smartGotoErrCode: (error.error as HttpCustomErrorContent)?.smartGotoErrCode,
          smartGotoErrOpt: (error.error as HttpCustomErrorContent)?.smartGotoErrOpt
        });
    };
  }

  /**
   * サーバからのエラーコードを基に各種ダイアログを表示する。
   *
   * @param {HttpCustomErrorContent} content サーバからのエラーレスポンス情報
   * @param {number} [clientCode=undefined] クライアントメッセージコード
   * @return {*}  {Promise<HTMLElement>}
   * @memberof HttpErrorResponseParserService
   */
  viewErrDialog(content: HttpCustomErrorContent, useCase: string = undefined): Promise<HTMLElement> {
    // エラーコードがundefinedの場合、想定外エラー
    if (undefined === content.smartGotoErrCode) return this.appMsg.viewDialogMessage(this.msg.CLIENT.COMMON.E_UNEXPECTED.message());

    // エラーコードからメッセージを取得
    let message = this.msg.getServerMessage((content as HttpCustomErrorContent).smartGotoErrCode, (content as HttpCustomErrorContent).smartGotoErrOpt, useCase);

    if (!message || !message((content as HttpCustomErrorContent).smartGotoErrOpt, useCase)) {
      // メッセージがなければサーバーエラーメッセージ、それも無ければ想定外エラーメッセージを返す
      const serverMsg = (content as HttpCustomErrorContent)?.smartGotoErrMessage;
      message = serverMsg ? () => serverMsg : () => this.msg.UNEXPECTED_MSG;
    }

    // メッセージ表示
    switch(content.smartGotoErrCode) {
      // アカウント停止中
      case this.appMsg.SERV_CONST_CODE.COMMON.SUSPENDED_USER: 
      case this.appMsg.SERV_CONST_CODE.DISPATCH.SUSPENDED_USER: 
      case this.appMsg.SERV_CONST_CODE.PURCHASE.SUSPENDED_USER: 
        return this.appMsg.userNotification(this.msg.CLIENT.USER_NOTIFICATION.ACCOUNT_SUSPEND.message(this.office.office_name));
      default:
        return this.appMsg.viewDialogMessage(message((content as HttpCustomErrorContent).smartGotoErrOpt, useCase));
    }
  }
}

/**
 * カスタマイズされたエラー情報。
 *
 * @export
 * @interface HttpCustomErrorContent
 */
export interface HttpCustomErrorContent 
{  
  /**
   * エラーコード。
   *
   * @type {number}
   * @memberof HttpCustomErrorContent
   */
  statusCode?: number;

  /**
   * SmartGotoErrMessage
   *
   * @type {string}
   * @memberof HttpCustomErrorContent
   */
  smartGotoErrMessage?: string;

  /**
   * SmartGotoErrCode
   *
   * @type {string}
   * @memberof HttpCustomErrorContent
   */
  smartGotoErrCode?: string;

  /**
   * タイムアウトエラーが発生したかどうか
   *
   * @type {boolean}
   * @memberof HttpCustomErrorContent
   */
  occurredTimeout?: boolean;

  /**
   * 付加情報
   *
   * @type {object}
   * @memberof HttpCustomErrorContent
   */
  smartGotoErrOpt?: any;
}