<div [ngBusy]="busy"></div>
<!-- 固定ヘッダー -->
<header class="search-area">

  <!-- 検索フォーム -->
  <div class="search-form">
    <mat-icon>search</mat-icon>
    <input matInput #autoCompleteInput id="HistoryInput" type="text" placeholder="スペース区切りで複数検索" 
        [(ngModel)]="freeword" 
        (change)="onSearch(true)" 
        (click)="onSearchFocus()" 
        (blur)="onSearchBlur()" 
        [matAutocomplete]="auto">
    
    <!-- 検索履歴 -->
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSearchHistorySelect()">
      <div class="history__right">
        <button id="shopping_004" class="history__delete" mat-flat-button (click)="onDeleteSearchHistory($event, -1, '', true)">
          すべて削除
        </button>
      </div>
      <mat-option *ngFor="let history of searchHistory; index as i;" [value]="history">
        <div class="history__left">
          <mat-icon class="search-icon">search</mat-icon><span id="shopping-search-history_00{{i+1}}">{{ history }}</span>
        </div>
        <div class="history__right">
          <button id="shopping-search-history-delete_00{{i+1}}" mat-icon-button (click)="onDeleteSearchHistory($event, i, history)">
            <mat-icon class="search-icon">clear</mat-icon>
          </button>
        </div>
      </mat-option>
    </mat-autocomplete>
  </div>
</header>


<!-- 検索フォーム -->
<!-- <div class="search-area">
  <div class="search-form">
    <mat-icon>search</mat-icon>
    <input matInput #autoCompleteInput id="newsHistoryInput" type="text" placeholder="スペース区切りで複数検索" 
          [(ngModel)]="freeword" 
          (change)="onSearch()" 
          (click)="onSearchFocus()" 
          (blur)="onSearchBlur()" 
          >
          [matAutocomplete]="auto"
    
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSearchHistorySelect($event)">
      <div class="history__right">
        <button class="history__delete" mat-flat-button (click)="onDeleteSearchHistory($event, -1, '', true)">
          すべて削除
        </button>
      </div>
      <mat-option *ngFor="let history of searchHistory; index as i;" [value]="history">
        <div class="history__left">
          <mat-icon class="search-icon">search</mat-icon>{{ history }}
        </div>
        <div class="history__right">
          <button mat-icon-button (click)="onDeleteSearchHistory($event, i, history)">
            <mat-icon class="search-icon">clear</mat-icon>
          </button>
        </div>
      </mat-option>
    </mat-autocomplete>
  </div> -->
