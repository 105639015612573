//=============================================================================================
// インポート
//=============================================================================================
import { InjectionToken } from '@angular/core';
import { Moment } from 'moment';
import { Observable } from 'rxjs';

import { FavoriteWebApiSchemas } from '../../../http-services/favorite-web-api.service';

import { UserInfo, UserName, ExUser, PlaceHistory, Relation, Location } from '../../../interfaces/response';

export const USER_RESERVATION_CONFIG = new InjectionToken<UserReservation.Config>('userReservationConfig');  // valueはAppModuleにて設定

//=============================================================================================
// インターフェース定義
//=============================================================================================

export namespace UserReservation {
  /**
   * 『おでかけ予約』の構成設定。
   *
   * @export
   * @interface Config
   */
  export interface Config {
    /**
     * 事前受付期間 (日単位)。
     *
     * @type {number}
     * @memberof Config
     */
    reservableDays: number;

    /**
     * ゲストに関する設定。
     *
     * @type {{
     *       validTime?: number;
     *       slideEachExpirations: boolean;
     *       reenableExpiration: boolean;
     *     }}
     * @memberof Config
     */
    guest: {
      /**
       * 有効時間 (秒単位)。
       *
       * @type {number}
       */
      validTime?: number;

      /**
       * 既存のゲストの有効期限も更新する場合は true。それ以外の場合は false。
       *
       * @type {boolean}
       */
      slideEachExpirations: boolean;

      /**
       * ゲストが再有効化された際に有効期限を更新する場合は true。それ以外の場合は false。
       *
       * @type {boolean}
       */
      reenableExpiration: boolean;
    };
  }

  /**
   * 移動プランの検索条件 (出発地/目的地)。
   *
   * @export
   * @interface SearchConditionSpot
   */
  export interface SearchConditionSpot {
    /**
     * 〈出発地/目的地〉として現在地を採用する場合は true。それ以外の場合は false。
     *
     * @type {boolean}
     * @memberof SearchConditionSpot
     */
    useCurrentPosition?: boolean;

    /**
     * <お届け先>として登録住所を採用する場合は true。それ以外の場合は false。
     *
     * @type {boolean}
     * @memberof SearchConditionSpot
     */
    registerAddress?: boolean;

    /**
     * 地図上から選択された POI に関する情報。
     *
     * @type {google.maps.places.PlaceResult}
     * @memberof SearchConditionSpot
     */
    placeResult?: google.maps.places.PlaceResult;

    /**
     * 地図上から選択された任意の場所の座標。
     *
     * @type {google.maps.LatLng}
     * @memberof SearchConditionSpot
     */
    arbitraryLocation?: google.maps.LatLng;

    /**
     * 住所に関する情報。
     *
     * @type {google.maps.GeocoderResult}
     * @memberof SearchConditionSpot
     */
    geocoderResult?: google.maps.GeocoderResult;

    /**
     * 〈出発地/目的地〉が『マイスポット』から選択された場合に設定される。
     *
     * @type {FavoriteWebApiSchemas.ResponseSchemas.Favorite}
     * @memberof SearchConditionSpot
     */
    favorite?: FavoriteWebApiSchemas.ResponseSchemas.Favorite;

    /**
     * 〈出発地/目的地〉が『利用履歴』から選択された場合に設定される。
     *
     * @type {PlaceHistory}
     * @memberof SearchConditionSpot
     */
    history?: PlaceHistory;

    /**
     * 〈出発地/目的地〉に関する SmartGOTO 固有の情報。
     *
     * @type {Place}
     * @memberof SearchConditionSpot
     */
    place?: Place;

    /**
     * スポットの関連キーワードとしてサーバーに記録されるテキスト。
     *
     * @type {string}
     * @memberof SearchConditionSpot
     */
    searchText?: string;

    /**
     * 地図の中心位置 (再表示用)。
     *
     * @type {(google.maps.LatLng | google.maps.LatLngLiteral)}
     * @memberof SearchConditionSpot
     */
    mapCenter?: google.maps.LatLng | google.maps.LatLngLiteral;

    /**
     * 地図のズームレベル (再表示用)。
     *
     * @type {number}
     * @memberof SearchConditionSpot
     */
    mapZoom?: number;

    /**
     * 検索テキスト (再表示用)。
     *
     * @type {string}
     * @memberof SearchConditionSpot
     */
    freeText?: string;
  }

  /**
   * 選択スポットに関するユーティリティ。
   *
   * @export
   * @interface SearchConditionSpotService
   */
  export interface SearchConditionSpotService {
    /**
     * 選択スポットの名称、あるいは住所を取得する。
     *
     * @param {SearchConditionSpot} spot 選択スポット。
     * @return {string} 選択スポットの名称、あるいは住所。
     * @memberof SearchConditionSpotService
     */
    getFormalNameOrAddress(spot: SearchConditionSpot): string;

    /**
     * 選択スポットの座標を取得する。
     *
     * @param {SearchConditionSpot} spot 選択スポット。
     * @return {google.maps.LatLng} 選択スポットの座標 (地図に関係するもののみ)。
     * @memberof SearchConditionSpotService
     */
    getGeometryLocation(spot: SearchConditionSpot): google.maps.LatLng;

    /**
     * 選択スポットの座標を取得する。
     *
     * @param {SearchConditionSpot} spot 選択スポット。
     * @return {(google.maps.LatLng | google.maps.LatLngLiteral)} 選択スポットの座標。
     * @memberof SearchConditionSpotService
     */
    getLocation(spot: SearchConditionSpot): google.maps.LatLng | google.maps.LatLngLiteral;

    /**
     * マイスポットの名称、選択スポットの名称、あるいは住所を取得する。
     *
     * @param {SearchConditionSpot} spot 選択スポット。
     * @return {string} マイスポットの名称、選択スポットの名称、あるいは住所。
     * @memberof SearchConditionSpotService
     */
    getNameOrAddress(spot: SearchConditionSpot): string;

    /**
     * 〈出発地/目的地〉を表すテキストを取得する。
     *
     * @param {SearchConditionSpot} spot 選択スポット。
     * @return {string} "現在地" (リテラル)、マイスポットの名称、選択スポットの名称、あるいは住所。
     * @memberof SearchConditionSpotService
     */
    getTitle(spot: SearchConditionSpot): string;

    /**
     * 〈出発地/目的地〉が選択済みかどうかを判定する。
     *
     * @param {SearchConditionSpot} spot 選択スポット。
     * @return {boolean} 選択済みの場合は true。それ以外の場合は false。
     * @memberof SearchConditionSpotService
     */
    isSelected(spot: SearchConditionSpot): boolean;
  }

  /**
   * 移動プランの検索条件 (出発時刻/到着時刻)。
   *
   * @export
   * @interface SearchConditionSchedule
   */
  export interface SearchConditionSchedule {
    /**
     * 〈出発時刻/到着時刻〉として現在時刻を採用する場合は true。それ以外の場合は false。
     *
     * @type {boolean}
     * @memberof SearchConditionSchedule
     */
    useCurrentTime: boolean;

    /**
     * 〈出発時刻/到着時刻〉を表す datetime-local 形式の文字列。
     *
     * @type {string}
     * @memberof SearchConditionSchedule
     */
    dateTime: string;

    /**
     * `SearchConditionSchedule.dateTime` が〈出発時刻〉と〈到着時刻〉のどちらを指しているのかを表す。
     *
     * @type {('o' | 'd')}
     * @memberof SearchConditionSchedule
     */
    od: 'o' | 'd';
    
    dir: -1 | 0 | 1;
    
    /**
     * 〈出発時刻/到着時刻〉に関する値とラベル。
     *
     * @type {{
     *       [key: string]: {
     *         value: number;
     *         label: string;
     *       }
     *     }}
     * @memberof SearchConditionSchedule
     */
    odOptions: {
      [key: string]: {
        /**
         * サーバーに送信される〈出発時刻/到着時刻〉を表す値。
         *
         * @type {number}
         */
        value: number;

        /**
         * 画面表示用のラベル。
         *
         * @type {string}
         */
        label: string;
      }
    };
  }

  /**
   * 時刻値に関するユーティリティ。
   *
   * @export
   * @interface SearchConditionScheduleDateTimeService
   */
  export interface SearchConditionScheduleDateTimeService {
    /**
     * 時刻値を Moment オブジェクトに変換する。
     *
     * @return {*}  {Moment} Moment オブジェクト。
     * @memberof SearchConditionScheduleDateTimeService
     */
    asMoment(): Moment;

    /**
     * 時刻値が有効範囲内かどうかを判定する。
     *
     * @return {*}  {number} 範囲外 (過去) の場合は -1、範囲外 (未来) の場合は 1、範囲内の場合は 0、時刻値が無効だった場合は undefined。
     * @memberof SearchConditionScheduleDateTimeService
     */
    verifyRange(): number;
  }

  /**
   * 移動プランの検索条件 (ユーザー)。
   *
   * @export
   * @interface SearchConditionUser
   */
  export interface SearchConditionUser {
    /**
     * ユーザー ID。
     *
     * @type {number}
     * @memberof SearchConditionUser
     */
    user_id: string;

    /**
     * ユーザー名。
     *
     * @type {UserName}
     * @memberof SearchConditionUser
     */
    name: UserName;

    /**
     * プロフィールアイコン。
     *
     * @type {string}
     * @memberof SearchConditionUser
     */
    icon: string;
  }

  /**
   * 移動プランの検索条件 (搭乗者)。
   *
   * @export
   * @interface SearchConditionPassengersElement
   */
  export interface SearchConditionPassengersElement {
    /**
     * 乗車するユーザー。
     *
     * @type {SearchConditionUser}
     * @memberof SearchConditionPassengersElement
     */
    user: SearchConditionUser;

    /**
     * 乗車の対象とする場合は true。それ以外の場合は false。
     *
     * @type {boolean}
     * @memberof SearchConditionPassengersElement
     */
    checked: boolean;

    /**
     * ゲストの乗車有効期限を表すエポック秒。
     *
     * @type {number}
     * @memberof SearchConditionPassengersElement
     */
    expirationTime?: number;
  }

  /**
   * 移動プランの検索条件 (搭乗者のコレクション)。
   *
   * @export
   * @interface SearchConditionPassengers
   */
  export interface SearchConditionPassengers {
    /**
     * 本人。
     *
     * @type {SearchConditionPassengersElement}
     * @memberof SearchConditionPassengers
     */
    self: SearchConditionPassengersElement;

    /**
     * ファミリーのリスト。
     *
     * @type {SearchConditionPassengersElement[]}
     * @memberof SearchConditionPassengers
     */
    families: SearchConditionPassengersElement[];

    /**
     * ゲストのリスト。
     *
     * @type {SearchConditionPassengersElement[]}
     * @memberof SearchConditionPassengers
     */
    guests: SearchConditionPassengersElement[];
  }

  /**
   * 移動プランの検索条件 (荷物積載情報)。
   *
   * @export
   * @interface SearchConditionBaggage
   */
  export interface SearchConditionBaggage {
    /**
     * 手持ち以外の荷物がある場合は true。それ以外の場合は false。
     *
     * @type {boolean}
     * @memberof SearchConditionBaggage
     */
    checked: boolean;

    /**
     * 荷物に関する詳細な情報。
     *
     * @type {*}
     * @memberof SearchConditionBaggage
     */
    detail?: any;
  }

  /**
   * 移動プランの検索条件。
   *
   * @export
   * @interface SearchCondition
   */
  export interface SearchCondition {
    /**
     * 目的地。
     *
     * @type {SearchConditionSpot}
     * @memberof SearchCondition
     */
    d: SearchConditionSpot;

    /**
     * 出発地。
     *
     * @type {SearchConditionSpot}
     * @memberof SearchCondition
     */
    o: SearchConditionSpot;

    /**
     * 荷物積載情報。
     *
     * @type {SearchConditionBaggage}
     * @memberof SearchCondition
     */
    readonly baggage: SearchConditionBaggage;

    /**
     * 検索条件の入力が完了している場合は true。それ以外の場合は false。
     *
     * @type {boolean}
     * @memberof SearchCondition
     */
    readonly canSearch: boolean;

    /**
     * 搭乗者のコレクション。
     *
     * @type {SearchConditionPassengers}
     * @memberof SearchCondition
     */
    readonly passengers: SearchConditionPassengers;

    /**
     * 出発時刻、または到着時刻。
     *
     * @type {SearchConditionSchedule}
     * @memberof SearchCondition
     */
    readonly schedule: SearchConditionSchedule;

    /**
     * 時刻値に関するユーティリティ。
     *
     * @type {SearchConditionScheduleDateTimeService}
     * @memberof SearchCondition
     */
    readonly scheduleDateTimeService: SearchConditionScheduleDateTimeService;

    /**
     * 選択スポットに関するユーティリティ。
     *
     * @type {SearchConditionSpotService}
     * @memberof SearchCondition
     */
    readonly spotService: SearchConditionSpotService;

    /**
     * 指定されたゲストを搭乗者として追加する。
     *
     * @param {ExUser} exUser 追加するゲスト。
     * @memberof SearchCondition
     */
    // addGuestPassenger(exUser: ExUser): void;

    /**
     * ゲスト搭乗者を初期化する。
     *
     * @memberof SearchCondition
     */
    // deleteGuestPassenger(): void;

    /**
     * 入力された検索条件の妥当性を検証する。
     *
     * @return {*}  {Observable<Error[]>} 検証に失敗した項目を表す Error オブジェクトのリスト。
     * @memberof SearchCondition
     */
    assertInputModel(): Observable<Error[]>;

    /**
     * 指定された ID のユーザーが既に搭乗者に含まれているかどうかを判定する。
     *
     * @param {number} user_id ユーザー ID。
     * @return {*}  {number} 
     *              指定IDがログインユーザ：1
     *              指定IDがファミリメンバー：2
     *              指定IDがゲスト：3
     *              含まれない：0
     * @memberof SearchCondition
     */
    existsPassenger(user_id: string): number;

    /**
     * 搭乗者の名前を書式化する。
     *
     * @param {SearchConditionPassengersElement} passenger 書式化の対象となる搭乗者。
     * @return {*}  {string} 書式化された搭乗者の名前。
     * @memberof SearchCondition
     */
    formatPassengerName(passenger: SearchConditionPassengersElement): string;

    /**
     * 搭乗者数を取得する。
     *
     * @return {*}  {number} 乗車対象としてチェックされたユーザーの数。
     * @memberof SearchCondition
     */
    getPassengersCount(): number;

    /**
     * 搭乗するユーザーの ID のリストを取得する。
     *
     * @return {*}  {number[]} 乗車対象としてチェックされたユーザーの ID のリスト。
     * @memberof SearchCondition
     */
    getPassengersUserIds(): string[];

    /**
     * 搭乗者のリストを取得する。
     *
     * @return {*}  {SearchConditionPassengersElement[]} 乗車対象としてチェックされたユーザーのリスト。
     * @memberof SearchCondition
     */
    getPassengersUsers(): SearchConditionPassengersElement[];

    /**
     * 〈出発地/目的地〉の種別を表すコードを表示用の文字列に変換する。
     *
     * @param {('o' | 'd')} kind 〈出発地/目的地〉の種別を表すコード。
     * @return {*}  {string} 表示用の文字列。
     * @memberof SearchCondition
     */
    getTargetPlaceKindName(kind: 'o' | 'd' | 'order'): string;

    /**
     * 搭乗者のコレクションを初期化する。(TODO)ファミリーの部分を消す。
     *
     * @param {UserInfo} [user] 本人。
     * @param {ExUser[]} [families] ファミリーのリスト。
     * @memberof SearchCondition
     */
    initPassengers(user?: UserInfo, families?: ExUser[]): void;

    /**
     *ファミリーの搭乗者コレクションを初期化する。
     *
     * @param {Relation[]} [families]
     * @memberof SearchCondition
     */
    initFamilies(families?: Relation[]):void;

    /**
     * ゲストの乗車有効期限を更新する。
     *
     * @param {SearchConditionPassengersElement} guest
     * @memberof SearchCondition
     */
    // reenableGuestPassenger(guest: SearchConditionPassengersElement): void;

    /**
     * 〈各種条件選択画面〉に戻る。
     *
     * @memberof SearchCondition
     */
    returnHere(): void;

    /**
     * 〈出発地/目的地〉を設定する。
     *
     * @param {('o' | 'd')} od 〈出発地/目的地〉の種別を表すコード。
     * @param {SearchConditionSpot} spot 設定するスポット。
     * @memberof SearchCondition
     */
    setSpot(od: 'o' | 'd' | 'order', spot: SearchConditionSpot): void;

    /**
     * 〈出発地〉と〈目的地〉を入れ替える。
     *
     * @memberof SearchCondition
     */
    swapSpots(): void;

    /**
     * 検索条件の入力完了状態を更新する。
     *
     * @memberof SearchCondition
     */
    updateCanSearchValue(): void;

    /**
     * ゲストの乗車有効期限が切れていないかどうかを検証する。
     *
     * @param {number} time 比較の対象となる時刻 (エポック秒)。
     * @param {() => void} postAction 乗車有効期限内だった場合に実行されるメソッド。
     * @memberof SearchCondition
     */
    // validateGuestPassengers(time: number, postAction: () => void): void;

    /**
     *搭乗者の名前をユーザーIDから取得する
     *
     * @param {number} user_id
     * @return {*}  {string}
     * @memberof SearchCondition
     */
    getPassengerName(user_id: string): string;
  }

  /**
   * スポット
   *
   * @interface Place
   */
  interface Place {
    /**
     * スポットの ID。
     *
     * @type {string}
     * @memberof Place
     */
    place_id?: string;

    /**
     * 場所の名前。
     *
     * @type {string}
     * @memberof Place
     */
    name: string;

    /**
     * 位置。
     *
     * @type {Location}
     * @memberof Place
     */
    location: Location;

    /**
     * 住所。
     *
     * @type {string}
     * @memberof Place
     */
    address?: string;

    /**
     * 電話番号。
     *
     * @type {string}
     * @memberof Place
     */
    tel?: string;

    /**
     * 営業時間。
     *
     * @type {PlaceOpeningHoursEntry[]}
     * @memberof Place
     */
    // opening_hours?: PlaceOpeningHoursEntry[];

    /**
     * エリア (地区)。
     *
     * @type {string}
     * @memberof Place
     */
    area?: string;

    /**
     * カテゴリ。
     *
     * @type {string[]}
     * @memberof Place
     */
    categories?: string[];

    /**
     * アイコン URL。
     *
     * @type {string}
     * @memberof Place
     */
    icon?: string;

    /**
     * 画像 URL。
     *
     * @type {string[]}
     * @memberof Place
     */
    photos?: string[];

    /**
     * 最寄りのバス停の `busstop_id`。
     *
     * @type {number}
     * @memberof Place
     */
    busstop_id?: number;

    /**
     * 種類。
     *
     * @type {string}
     * @memberof Place
     */
    type?: string;
  }
}