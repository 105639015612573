//============= ================================================================================
// インポート
//=============================================================================================
import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Subscription, Observable, fromEvent } from 'rxjs';
import { OnsNavigator, Params } from 'ngx-onsenui';
import Viewer from 'viewerjs';
import * as moment from 'moment-timezone';
import * as CONST from '../../../constants/constant';
import * as uuid from 'uuid';

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { NewsWebApiService } from '../../../http-services/news-web-api.service';
import { HistoryWebApiService } from '../../../http-services/history-web-api.service';
import { UserWebApiService } from '../../../http-services/user-web-api.service';
import { SelectHistoryWebApiService } from '../../../http-services/select-history-web-api.service';

// interface
import { parameter } from '../../../interfaces/parameter';
import { NewsArticle } from '../../../interfaces/response';
import { ArticleTextSelectHistory, ArticleImageSelectHistory } from 'src/app/http-services/select-history-web-api.service';

// module
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 記事一覧。
 *
 * @export
 * @class NewsArticleListComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ons-page[news-article-list]',
  templateUrl: './news-article-list.component.html',
  styleUrls: ['./news-article-list.component.scss']
})
export class NewsArticleListComponent implements OnInit, OnDestroy {

//=============================================================================================
// メンバ変数
//=============================================================================================

  /**
   * 通信用Subscription
   *
   * @type {Subscription}
   * @memberof NewsArticleListComponent
   */
  busy: Subscription;

  /**
   * 記事一覧
   *
   * @type {NewsArticle[]}
   * @memberof NewsArticleListComponent
   */
  articleList: NewsArticle[] = undefined;

  /**
   * 検索ワード
   *
   * @type {string}
   * @memberof NewsArticleListComponent
   */
  freeword: string = "";

  /**
   * 検索履歴
   *
   * @type {string[]}
   * @memberof NewsArticleListComponent
   */
  searchHistory: string[] = [];

  /**
   * 検索履歴のフィルタリング
   *
   * @type {Observable<string[]>}
   * @memberof NewsArticleListComponent
   */
  filteredOptions: Observable<string[]>;

  /**
   * moment
   *
   * @memberof NewsArticleListComponent
   */
  readonly moment = moment;

  /**
   * 検索フォームにフォーカスがある場合履歴を再取得しない
   *
   * @type {boolean}
   * @memberof NewsArticleListComponent
   */
  oneFocus: boolean = false;

  /**
   * 検索履歴パネル
   *
   * @type {MatAutocompleteTrigger}
   * @memberof NewsArticleListComponent
   */
  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger })
  autoComplete: MatAutocompleteTrigger;

  /**
   * 表示中のサムネイルインデックス
   *
   * @type {number}
   * @memberof NewsArticleListComponent
   */
  viewImageIndex: number = 0;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of NewsArticleListComponent.
   * @param {ApplicationMessageService} appMsgServ
   * @param {HttpErrorResponseParserService} errResServ
   * @param {NewsWebApiService} newsServ
   * @param {HistoryWebApiService} historyServ
   * @param {OnsNavigator} navigator
   * @param {ChangeDetectorRef} cd
   * @param {UserWebApiService} userServ
   * @param {SelectHistoryWebApiService} selectHistoryServ
   * @param {Params} params
   * @memberof NewsArticleListComponent
   */
  constructor(
    private appMsgServ: ApplicationMessageService,
    private errResServ: HttpErrorResponseParserService,
    private newsServ: NewsWebApiService,
    private historyServ: HistoryWebApiService,
    private navigator: OnsNavigator,
    private cd: ChangeDetectorRef,
    private userServ: UserWebApiService,
    private selectHistoryServ: SelectHistoryWebApiService,
    private params: Params,
    private commonFuncMdl: CommonFunctionModule,
  ) { }

  /**
   * 初期化処理。
   *
   * @memberof NewsArticleListComponent
   */
  ngOnInit(): void {

    let query: parameter.NewsArticleQuery = this.newsServ.createGetArticleQeuery();

    // 前画面からの初期化
    if (this.params.data.freeword !== "") {
      this.freeword = this.params.data.freeword;
      query.freeword = this.params.data.freeword;
    }
    query.categories = this.params.data.category_id !== undefined ? [this.params.data.category_id] : undefined;

    // 議事一覧取得
    this.getArticles(query, true);
  }

  /**
   * 破棄処理。
   *
   * @memberof NewsArticleListComponent
   */
  ngOnDestroy(): void {

    this.busy?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 無限スクロールのイベントハンドラ。
   *    無限スクロールによって最下層までスクロールしたあとの処理。
   *
   * @memberof NewsArticleListComponent
   */
  onScroll(): void {

    // 表示中の記事のうち、最古の記事IDを保存
    let oldest_article_id: number = undefined;
    if (this.articleList !== undefined) oldest_article_id = this.articleList[this.articleList.length - 1].article_id;

    // 記事取得のためのクエリを作成
    let query = this.newsServ.createGetArticleQeuery(oldest_article_id);
    if (this.freeword !== "") query.freeword = this.freeword;

    // 記事の取得
    this.getArticles(query);
  }

  /**
   * 新規追加、更新ボタン押下時のイベントハンドラ。
   *    新規投稿/編集ダイアログを開く。
   *
   * @param {number} [article_id]
   * @memberof NewsArticleListComponent
   */
  // onOpen(article_id?: number): void {

  //   const dialogRef = this.dialog.open(NewsArticleComponent, {
  //     width: '90vw',
  //     disableClose: true,
  //     panelClass:'icon-outside',
  //     data: { article_id: article_id }
  //   });

  //   // close後のコールバック処理
  //   dialogRef.afterClosed().subscribe(result => {
  //     // 処理後なら一覧を更新
  //     if (true === result) {
  //       setTimeout(() => {
  //         this.articleList = [];
  //         this.getArticles(this.newsServ.createGetArticleQeuery());
  //       }, 1000);
  //     }
  //   });
  // }

  /**
   * 削除ボタン押下時のイベントハンドラ。
   *    記事を削除する。
   *
   * @param {number} article_id
   * @memberof NewsArticleListComponent
   */
  // onDelete(article_id: number): void {

  //   // 確認ダイアログ表示
  //   this.appMsgServ.confirmMessage("削除してよろしいですか？").then(value => {
  //     // delete
  //     if (Number(value) == 1) {
  //       // DBから削除
  //       this.deleteArticles(article_id);

  //       // ローカル上の記事を削除
  //       let index = this.articleList.findIndex(list => list.article_id === article_id);
  //       this.articleList.splice(index, 1);
  //     }
  //   });
  // }

  /**
   * サムネイル画像の省略番号タップ時のイベントハンドラ。
   *
   * @param {NewsArticle} article
   * @memberof NewsArticleListComponent
   */
  onHiddenImageNum(article: NewsArticle): void {

    // 番号配下のサムネイルをタップしたとする
    const img = document.getElementById("maskImage" + article.article_id);
    if (img) img.click();
    else ;
  }

  /**
   * サムネイル画像タップ時のイベントハンドラ。
   *    サムネイルをライトボックス表示する。
   *
   * @param {string[]} photos
   * @param {number} index
   * @memberof NewsArticleListComponent
   */
  onEnlargeImage(article: NewsArticle): void {

    // image取得
    const imgV = document.getElementById(article.article_id.toString());

    // viewer生成
    const viewer = new Viewer(imgV, {
      // タイトル
      title: false,
      // ツールバー
      toolbar: {
        prev: { show: article.photos.length > 1 ? true: false, size: "large" },
        zoomOut: { size: "large" },
        download: { show: true, size: "large", click: () => this.imageDownload(article) },
        zoomIn: { size: "large" },
        next: { show: article.photos.length > 1 ? true: false, size: "large" },
        oneToOne: false,
        reset: false,
        play: false,
        rotateLeft: false,
        rotateRight: false,
        flipHorizontal: false,
        flipVertical: false,
      },
      // 画像描画後のイベント
      viewed: (event: CustomEvent) => {
        this.viewImageIndex = event.detail.index;
        this.sendSelectHistory(article, "image", event.detail.index);
      }
    });
  }

  /**
   * 検索フォームフォーカス時のイベントハンドラ。
   *    検索履歴を表示する。
   *
   * @memberof NewsArticleListComponent
   */
  onSearchFocus(): void {

    // 検索履歴取得、表示
    if (this.oneFocus === false) {
      this.searchHistory = [];
      this.getSearchHistory(CONST.Search.HISTORY);
    }

    // フォーカスがある状態でのclickイベント無効化
    this.oneFocus = true;
  }

  /**
   * 検索フォームからフォーカスが外れた際のイベントハンドラ。
   *
   * @memberof NewsArticleListComponent
   */
  onSearchBlur(): void {

    // clickイベントを有効化
    this.oneFocus = false;
  }

  /**
   * 検索履歴選択時のイベントハンドラ。
   *
   * @param {MatAutocompleteSelectedEvent} event
   * @memberof NewsArticleListComponent
   */
  onSearchHistorySelect(event: MatAutocompleteSelectedEvent): void {

    // 検索履歴パネルを閉じる
    this.autoComplete.closePanel();

    // 選択項目でフリーワード検索
    this.onSearch();
  }

  /**
   * 検索履歴削除ボタン押下時のイベントハンドラ。
   *
   * @param {string} word
   * @param {boolean} [all]
   * @memberof NewsArticleListComponent
   */
  onDeleteSearchHistory(event: any, index: number, word: string, all?: boolean): void {

    // clickイベントの伝播を防止
    event.stopPropagation();
    event.preventDefault();

    const query = { word: word, all: all };
    if (all === true) delete query.word;
    if (all === undefined) delete query.all;

    // 検索履歴削除
    this.deleteSearchHistory(query, index);
  }

  /**
   * フリーワード検索実施時のイベントハンドラ。
   *
   * @memberof NewsArticleListComponent
   */
  onSearch(): void {

    // 全角スペースを半角に置換、前後のスペース除去
    let word = this.freeword.replace(/　/g, " ");
    this.freeword = word.trim();

    if (this.freeword === "") this.freeword = undefined;

    // 取得
    this.getArticles(this.newsServ.createFreewordSearchQuery(this.freeword), true);

    // 検索履歴パネルを閉じる
    this.autoComplete.closePanel();

    // INPUTタグからフォーカスを外す
    document.getElementById("newsHistoryInput").blur();
  }

  /**
   * 店舗名、ハッシュタグ押下時のイベントハンドラ。
   *
   * @param {NewsArticle} key
   * @param {string} target
   * @memberof NewsArticleListComponent
   */
  onSearchKeyword(event: Event, key: string, target: 'shop' | 'tag', value?: string): void {

    // リンク遷移を防止
    event.preventDefault();

    // 検索フォームに設定
    if (target === "shop") this.freeword = value;
    if (target === "tag") this.freeword = key;

    // 取得
    this.getArticles(this.newsServ.createTapSearchQuery(key, target), true);
  }

  /**
   * 続きを読むタップ時のイベントハンドラ。
   *    記事の全文を表示はCSSで実施。
   *
   * @param {NewsArticle} article
   * @memberof NewsArticleListComponent
   */
  onReadArticle(article: NewsArticle): void {
    if (article.unread) {
      this.newsServ.readArticle(article);
    }

    // 記事操作履歴をサービスに追加
    this.sendSelectHistory(article, "text");
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * 記事操作履歴をサービスに追加
   *
   * @param {NewsArticle} article 記事情報
   * @param {("text"|"image")} sendType 記事本文 or 記事画像
   * @param {number} [imageIndex] 記事画像の場合のみ、画像index
   * @memberof NewsArticleListComponent
   */
  private sendSelectHistory(article: NewsArticle, sendType: "text"|"image", imageIndex?: number) {

    // ログインユーザーID
    let loginUserID: string;

    // ログインユーザー情報取得。未ログインであれば、null
    if (this.userServ.isLoggedIn()) {
      loginUserID = this.userServ.getUserInfo().user_id;
    }
    else {
      loginUserID = null;
    }

    // 記事本文の操作履歴送信用データを送信
    if (sendType == "text") {
      const articleTextSelectHistory: ArticleTextSelectHistory = {
        id: uuid.v4(),  // ランダムな文字列
        user_id: loginUserID,
        date: moment().toISOString(),
        service: "news",
        type: "article",
        article: {
          shop_id: Number(article.shop_id),
          article_id: article.article_id,
          type: "text"
        }
      }
      // サービスに追加
      this.selectHistoryServ.addHistory(articleTextSelectHistory);
    }

    // 記事画像の操作履歴送信用データを作成
    else if (sendType == "image") {
      const articleImageSelectHistory: ArticleImageSelectHistory = {
        id: uuid.v4(),  // ランダムな文字列
        user_id: loginUserID,
        date: moment().toISOString(),
        service: "news",
        type: "article",
        article: {
          shop_id: Number(article.shop_id),
          article_id: article.article_id,
          type: "image",
          url: article.photos[imageIndex]
        }
      }
      // サービスに追加
      this.selectHistoryServ.addHistory(articleImageSelectHistory);
    }

    // 想定外
    else {
      // console.log("想定外");
    }
  }

  /**
   * イメージをダウンロードする。
   *
   * @private
   * @param {NewsArticle} article
   * @memberof NewsArticleListComponent
   */
  private imageDownload(article: NewsArticle): void {

    const a = document.createElement('a');
    a.href = article.photos[this.viewImageIndex];
    // a.download = viewer.image.alt;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * 記事を取得する。
   *
   * @memberof NewsArticleListComponent
   */
  private getArticles(req: parameter.NewsArticleQuery, init: boolean = false): void {

    // 取得可能な最大数以上の場合
    if (this.articleList !== undefined && this.articleList.length >= CONST.News.GET_ARTICLE_MAX) return;

    // 無限スクロール以外は初期化
    if (init) this.articleList = [];

    // 記事取得
    this.busy = this.newsServ.getArticles(req).subscribe({
      next: res => {
        // 記事がない場合終了
        if (res.body.length === 0 && req.before_article === undefined) {
          this.articleList = undefined;
          return;
        }

        // ハッシュタグリンクを生成
        res.body.forEach(list => {
          const str = list.text.match(/#[^\s#]+/g);
          // リンク文字列をタグ付きに変換
          list.text = this.commonFuncMdl.replaceUrl(this.commonFuncMdl.replaceEscapeSequence(list.text));
          if (str !== null) str.forEach(s => list.text = list.text.replace(s, `<a class='tagLink' href="#">${s}</a>`));
        });

        // 記事一覧に追加
        if (init) {
          this.articleList = res.body;
        }
        else {
          if (this.articleList === undefined) this.articleList = res.body;
          else this.articleList = this.articleList.concat(res.body);
        }

        // 再描画
        this.cd.detectChanges();

        // ハッシュタグにイベント、スタイルを設定
        const tags = document.querySelectorAll(".tagLink");
        tags.forEach(t => {
          t.addEventListener("click", (event: Event) => {
            // リンク遷移を防止
            event.preventDefault();
            this.onSearchKeyword(event, t.textContent, "tag");
          });
          t.setAttribute("style", "color: var(--color-main); text-decoration: none;");
        });

      },
      error: this.errResServ.doParse((_err, errContent) => { this.errResServ.viewErrDialog(errContent); })
    });
  }

  /**
   * 記事を削除する。
   *
   * @private
   * @param {number} article_id
   * @memberof NewsArticleListComponent
   */
  // private deleteArticles(article_id: number): void {

  //   // 記事削除
  //   this.busy = this.newsServ.deleteArticle(article_id).subscribe({
  //     error: this.errResServ.doParse((_err, errContent) => { this.errResServ.viewErrDialog(errContent); })
  //   });
  // }

  /**
   * 検索履歴を取得する。
   *
   * @private
   * @param {number} limit
   * @memberof NewsArticleListComponent
   */
  private getSearchHistory(limit: number): void {

    this.busy = this.historyServ.getSearchHistory("article", limit).subscribe({
      next: response => { this.searchHistory = response.body; },
      error: this.errResServ.doParse((_err, errContent) => { this.errResServ.viewErrDialog(errContent); })
    });
  }

  /**
   * 検索履歴を削除する。
   *
   * @private
   * @param {{ word?: string, all?: boolean }} query
   * @param {number} index
   * @memberof NewsArticleListComponent
   */
  private deleteSearchHistory(query: { word?: string, all?: boolean }, index: number): void {

    this.busy = this.historyServ.deleteSearchHistory("article", query).subscribe({
      next: () => { index === -1 ?  this.searchHistory = [] : this.searchHistory.splice(index, 1); },
      error: this.errResServ.doParse((_err, errContent) => { this.errResServ.viewErrDialog(errContent); })
    });
  }
}
