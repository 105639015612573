<ons-toolbar>
  <div class="center">登録完了</div>
</ons-toolbar>
<div class="content">
  <p class="content--title content__center">登録が完了しました。</p>
  <parts-image class="content--img complete-wrap__img" url="/image/character/complate.png"></parts-image>
  <p class="content--title">サービスを利用する前に</p>
  <div class="content--text-wrap">
    続けてお支払い方法の登録を行う場合は、以下のリンクより登録をお願いいたします。<br />
      <div class="button-wrap">
        <ons-button id="profile-signup-complete_004" modifier="large--cta noFixed" (click)="pushPaymentMethod()">お支払い方法を登録する</ons-button>
      </div>
    <span class="content--attention">※サービスをご利用いただくためには「支払い方法の登録」が必要となります。</span><br /><br />
    お支払い方法を登録せずにご利用を続ける場合は、下記「ご利用を続ける」ボタンを押してください。
    <br /><br />
    お支払い方法の登録は　「マイページ」タブ→「支払い方法」を選択　よりいつでも登録が可能です。<br />
  </div>
  <div class="button-wrap">
    <ons-button id="profile-signup-complete_003" modifier="large--cta noFixed" (click)="returnHome()">サービス画面に戻る</ons-button>
  </div>
</div>
