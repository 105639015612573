//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit } from '@angular/core';
import { OnsNavigator } from 'ngx-onsenui';

// component
import { ProxyAccountDescriptionComponent } from '../../proxy-account/proxy-account-description/proxy-account-description.component';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * ファミリー説明画面。
 *
 * @export
 * @class FamilyDescriptionComponent
 * @implements {OnInit}
 * @author （株）オブジェクトデータ wada
 */
@Component({
  selector: 'ons-page[family-description]',
  templateUrl: './family-description.component.html',
  styleUrls: ['./family-description.component.css']
})
export class FamilyDescriptionComponent implements OnInit 
{

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of FamilyDescriptionComponent.
   * @param {OnsNavigator} navigator
   * @memberof FamilyDescriptionComponent
   */
  constructor(
    private navigator: OnsNavigator
  ) { }

  /**
   * 初期化処理
   *
   * @memberof FamilyDescriptionComponent
   */
  ngOnInit(): void 
  {
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 見守り対象アカウント説明へ遷移する。
   *
   * @memberof FamilyDescriptionComponent
   */
  viewProxyDescription(): void 
  {
    this.navigator.element.pushPage(ProxyAccountDescriptionComponent);
  }
}
