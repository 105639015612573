<ons-toolbar>
    <div class="left ">
        <ons-back-button #onsBackButton></ons-back-button>
    </div>
    <div class="center">移動プラン詳細</div>
</ons-toolbar>
<div class="plan-wrap">
    <ons-list *ngFor="let section of reservation.sections; index as i; first as isFirst; last as isLast">
        <!--発着時刻、地点名テンプレート-->
        <ng-template #sectionPointDetail let-od="od">
            <ng-container [ngSwitch]="od">
                <ng-container *ngSwitchCase="'o'">
                    <ons-row vertical-align="center">
                        <ons-col width="64px" class="plan-item__section-schd-time">
                            <ons-row>
                                <ons-col>{{moment(section.o.schd_time).format('HH:mm')}}発</ons-col>
                            </ons-row>
                        </ons-col>
                        <ons-col width="16px">
                            <ons-icon icon="fa-circle"></ons-icon>
                        </ons-col>
                        <ons-col class="plan-item__section-name">{{section.o.name}}</ons-col>
                    </ons-row>
                </ng-container>
                <ng-container *ngSwitchCase="'d'">
                    <ons-row vertical-align="center">
                        <ons-col width="64px" class="plan-item__section-schd-time">
                            <ons-row>
                                <ons-col>{{moment(section.d.schd_time).format('HH:mm')}}着</ons-col>
                            </ons-row>
                            <ons-row *ngIf="!isLast">
                                <ons-col>{{moment(reservation.sections[i + 1].o.schd_time).format('HH:mm')}}発
                                </ons-col>
                            </ons-row>
                        </ons-col>
                        <ons-col width="16px">
                            <ons-icon icon="fa-circle"></ons-icon>
                        </ons-col>
                        <ons-col class="plan-item__section-name">{{section.d.name}}</ons-col>
                    </ons-row>
                </ng-container>
            </ng-container>
        </ng-template>
        <!--発着地点の外部リンクテンプレート-->
        <ng-template #sectionPoint let-od="od">
            <a *ngIf="section[od].location; else noLink"
                [href]="'https://www.google.com/maps/search/?api=1&query=' + section[od].location.lat + ',' + section[od].location.lng + '&z=20'"
                class="list-item__link" target="_blank">
                <ng-container *ngTemplateOutlet="sectionPointDetail; context: {od: od}"></ng-container>
            </a>
            <ng-template #noLink>
                <div class="list-item__link">
                    <ng-container *ngTemplateOutlet="sectionPointDetail; context: {od: od}"></ng-container>
                </div>
            </ng-template>
        </ng-template>

        <!--本文-->
        <ons-list-item *ngIf="isFirst" tappable modifier="longdivider chevron noPadding" class="plan-item">
            <ng-container *ngTemplateOutlet="sectionPoint; context: {od: 'o'}"></ng-container>
        </ons-list-item>
        <ons-list-item modifier="longdivider"
            [attr.class]="'plan-item' + ' ' + 'plan-item__trans-' + section.trans">
            <ons-row vertical-align="center">
                <ons-col width="64px" class="plan-item__section-trans">
                    <img [src]="reservationTransMap.get(section.trans).get('icon')" />
                </ons-col>
                <ons-col class="plan-item__text">
                    <div class="plan-item__section-description">
                        <div>
                            <span>{{moment.duration(moment(section.d.schd_time).startOf('minutes').diff(moment(section.o.schd_time).startOf('minutes'))).format('h[時間]m[分]', { minValue: 1 })}}</span>
                            <span *ngIf="(section.distance | typeof) == 'number'">({{section.distance | number:'.0-0'}}m)</span>
                        </div>
                        <div *ngIf="(section.car_no | typeof) == 'number'">車両番号：{{section.car_no}}</div>
                        <div *ngIf="!!section.comment" class="plan-item__section-comment">{{section.comment}}</div>
                    </div>
                </ons-col>
            </ons-row>
        </ons-list-item>
        <ons-list-item tappable modifier="longdivider chevron noPadding" class="plan-item">
            <ng-container *ngTemplateOutlet="sectionPoint; context: {od: 'd'}"></ng-container>
        </ons-list-item>
    </ons-list>
</div>
<div *ngIf="isWalkingSection === true" class="reservation-comment">
    <ons-icon class="reservation-comment-item__icon" icon="fa-exclamation-circle"></ons-icon>
    <p class="reservation-comment-item__text">徒歩移動の時間・距離は、実際の状況を反映していない場合があるのでご注意ください。</p>
</div>