<ons-toolbar class="toolbar">
  <div class="left">
    <ons-back-button></ons-back-button>
  </div>
  <div class="center">支払い方法</div>
</ons-toolbar>
<div class="content">
  <ons-list class="list-wrap">
    <ons-list-header modifier="color" class="list-item__title">支払い方法追加</ons-list-header>
    
    <ng-container *ngIf="isShowPaymentMethod.card === true">
      <ons-list-item id="payment-method-add_001" tappable modifier="chevron" class="list-item__text" (click)="selectCredit()">
        <p>{{ VIEW_TEXT.METHOD.CREDIT }}</p>
      </ons-list-item>
    </ng-container>
    
    <ng-container *ngIf="isShowPaymentMethod.yucho === true">
      <ons-list-item id="payment-method-add_002" tappable modifier="chevron" class="list-item__text" (click)="addMethod('YUCHO')">
        <p>{{ VIEW_TEXT.METHOD.YUCHO }}</p>
      </ons-list-item>
    </ng-container>
    
    <ng-container *ngIf="isShowPaymentMethod.jabank === true">
      <ons-list-item id="payment-method-add_003" tappable modifier="chevron" class="list-item__text" (click)="addMethod('JABANK')">
        <p>{{ VIEW_TEXT.METHOD.JABANK }}</p>
      </ons-list-item>
    </ng-container>
    
    <!-- <ng-container *ngIf="isShowPaymentMethod.slip === true">
      <ons-list-item id="payment-method-add_004" tappable modifier="chevron" class="list-item__text" (click)="addMethod('SLIP')">
        <p>{{ VIEW_TEXT.METHOD.SLIP }}</p>
      </ons-list-item>
    </ng-container> -->

    <ng-container *ngIf="isShowPaymentMethod.bank77 === true">
      <ons-list-item id="payment-method-add_005" tappable modifier="chevron" class="list-item__text" (click)="addMethod('BANK77')">
        <p>{{ VIEW_TEXT.METHOD.BANK77 }}</p>
      </ons-list-item>
    </ng-container>
  </ons-list>
</div>