//=============================================================================================
// インポート
//=============================================================================================
import { Injectable } from '@angular/core';
import { OnsNavigator } from 'ngx-onsenui';
import { Subject } from 'rxjs';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * pagerService
 *
 * @export
 * @class PagerService
 * @author （株）オブジェクトデータ wada
 */
@Injectable({
  providedIn: 'root'
})
export class PagerService {

  /**
   * 戻るページ情報
   *
   * @private
   * @type {ReturnPage[]} {key: number, index: number}
   * @memberof PagerService
   */
  private returnPage: ReturnPage[] = [];

  /**
   * ページスタックが削除されたことを通知するためのSubject
   *
   * @type {Subject<string>}
   * @memberof PagerService
   */
  subject: Subject<number> = new Subject();

  private useAppNavigator: boolean = false;
  private appNavigator: OnsNavigator;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of PagerService.
   * @memberof PagerService
   */
  constructor() { }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * getter
   *
   * @readonly
   * @type {ReturnPage[]} {key: number, index: number}
   * @memberof PagerService
   */
  get getReturnPage(): ReturnPage[] {
    return this.returnPage;
  }

  /**
   * 機能のトップページへ戻る。
   *
   * @param {OnsNavigator} navi
   * @param {*} component
   * @return {*}  {Promise<void>}
   * @memberof PagerService
   */
  async transitionToTopPage(navi: OnsNavigator, component: any): Promise<void> {
    for (let index = 1; index < navi.element.pages.length - 1; index++) {
      await navi.element.pageLoader.unload(navi.element.pages[index]);
    }
    
    // 指定のコンポーネントを最初と最後の間に挿入する
    await navi.element.insertPage(1, component);
    // 最後のコンポーネントをページスタックから取り除く
    await navi.element.popPage();
  }

  /**
   * 戻り先コンポーネントのページスタックindexを設定
   *
   * @param {ReturnPage} returnPage {key: number, index: number}
   * @memberof PagerService
   */
  setReturnPage(returnPage: ReturnPage): void {

    // 同一keyの保存をはじく
    if (this.returnPage.length != 0) {
      if (this.returnPage.some(page => page.key == returnPage.key)) return;
    }

    this.returnPage.push(returnPage);
  }

  /**
   * setReturnPageIndexで設定したindexのページへ戻る。
   *
   * @param {OnsNavigator} navi
   * @param {number} key
   * @memberof PagerService
   */
  transitionToPage(navi: OnsNavigator, key: number): Promise<void> {
    return new Promise(res => {
      // 戻るページ情報
      let returnPage: ReturnPage;

      // 指定したkeyのページ情報
      returnPage = this.returnPage.find(e => e.key == key);

      // ループする回数
      const loop: number = navi.element.pages.length - 1 - returnPage.index;
      // ページスタックを削除した回数
      let count: number = 0;

      // pageStackの指定したindex一つ前まで削除
      for (let index = 1; index <= loop; index++) {
        // 指定したindexのpageを削除
        setTimeout(() => {
          navi.element.removePage(returnPage.index + 1).then(() => {
            count += 1;
            // スタック削除完了
            if (count === loop) res();
          });
        })
      }
      // 戻り先のコンポーネントに、ページスタックを削除することを通知
      this.subject.next(returnPage.key);
    });
  }

  /**
   * delete
   *
   * @param {number} key
   * @memberof PagerService
   */
  deleteReturnPage(key: number): void {

    const index = this.returnPage.findIndex(e => e.key == key);
    if (index > -1) this.returnPage.splice(index, 1);
  }

//=============================================================================================
// appComponent、navigatorに関するメソッド
//=============================================================================================

  /**
   * getter: appNavigator
   *
   * @memberof PagerService
   */
  set setAppNavigator(navi: OnsNavigator) {
    this.appNavigator = navi;
  }

  /**
   * setter: appNavigator
   *
   * @readonly
   * @type {OnsNavigator}
   * @memberof PagerService
   */
  get getAppNavigator(): OnsNavigator {
    return this.appNavigator;
  }

  /**
   * getter: useAppNavigator
   *
   * @memberof PagerService
   */
  set setUserAppNavigator(use: boolean) {
    this.useAppNavigator = use;
  }

  /**
   * setter: useAppNavigator
   *
   * @readonly
   * @type {boolean}
   * @memberof PagerService
   */
  get getUserAppNavigator(): boolean {
    return this.useAppNavigator;
  }
  
  /**
   * appNavigatorの初期化
   *
   * @return {*}  {Promise<void>}
   * @memberof PagerService
   */
  async initAppNavigator(leaveSignin: boolean = false): Promise<void> {

    if (leaveSignin) await this.transitionToPage(this.appNavigator, PageKey.Signin);
    else {
      // フラグオフ
      this.useAppNavigator = false;
      await this.transitionToPage(this.appNavigator, PageKey.AllClear);
    }
  }
}

/**
 * interface
 *
 * @export
 * @interface ReturnPage
 */
export interface ReturnPage {

  /**
   * 戻るページのindex
   *
   * @type {number}
   * @memberof ReturnPage
   */
  index: number;

  /**
   * 戻るページ情報の識別子
   * 　複数navigatorで呼ばれた場合必要な為
   *
   * @type {(string | number)}
   * @memberof ReturnPage
   */
  key: number;
}

/**
 *  戻るページ識別用key
 *    setReturnPageで保存した際に、このkeyに対し戻るページのindexが紐づく
 */
export const PageKey = {
  
  AllClear: -1, 

  // サインイン-------------------------------
  // サインイン
  Signin: 0, 

  // 体験サービス-------------------------------
  // 体験サービストップ画面
  ExpComponent: 1,

  // 体験詳細画面
  ExpDetailComponent: 2, 

  ExpServiceListComponent: 8,
  
  // 予定履歴-------------------------------
  // 予定履歴トップ
  HistoryList: 3, 

  // ファミリー-------------------------------
  // ファミリー一覧画面
  FamilyComponent: 4,

  // ファミリー追加画面
  FamilyAddComponent: 5,

  // ファミリー詳細画面
  FamilyDetailComponent: 6,

  // プロフィール-------------------------------
  // プロフィール一覧画面
  ProfileListComponent: 7,

  // 配車サービス-------------------------------
  // 配車サービストップ画面
  UserReservationComponent: 8
}as const;