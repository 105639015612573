//=============================================================================================
// インポート
//=============================================================================================
import { HttpClient, HttpDownloadProgressEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { HttpCustomErrorContent } from '../lib-services/http-error-response-parser.service';

import { request } from '../interfaces/request';
import { Auth, UserQr } from "../interfaces/response";

//=============================================================================================
// Configクラス定義
//=============================================================================================

/**
 * Web API (顔認証 QR コード関連) の構成設定。
 *
 * @export
 * @class SqrcWebApiServiceConfig
 */
export class SqrcWebApiServiceConfig {
  /**
   * Web API の基底 URL。
   *
   * @type {string}
   * @memberof SqrcWebApiServiceConfig
   */
  baseUrl: string;

  /**
   * エリアコード
   *
   * @type {string}
   * @memberof SqrcWebApiServiceConfig
   */
  areacode: string;

  /**
   * HTTP リクエストと一緒に送信されるオプション。
   *
   * @type {({
   *     headers?: HttpHeaders | {
   *       [header: string]: string | string[];
   *     };
   *   })}
   * @memberof SqrcWebApiServiceConfig
   */
  httpOptions?: {
    /**
     * HTTP ヘッダー。
     *
     * @type {(HttpHeaders | {
     *       [header: string]: string | string[];
     *     })}
     */
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
  };
}

//=============================================================================================
// Serviceクラス定義
//=============================================================================================

/**
 * Web API (顔認証 QR コード関連) の呼び出しを簡略化するためのユーティリティ。
 *
 * @export
 * @class SqrcWebApiService
 */
@Injectable({
  providedIn: 'root'
})
export class SqrcWebApiService {

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * SQRC用サブジェクト
   *
   * @private
   * @memberof SqrcWebApiService
   */
  private sqrcSubject = new Subject();

//=============================================================================================
// コンストラクタ
//=============================================================================================

  /**
   * Creates an instance of SqrcWebApiService.
   * @param {HttpClient} http HTTP リクエストを実行するためのサービス。
   * @param {SqrcWebApiServiceConfig} config Web API (顔認証 QR コード関連) の構成設定。
   * @memberof SqrcWebApiService
   */
  constructor(
    private http: HttpClient,
    private config: SqrcWebApiServiceConfig
  ) { }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * 登録済みの QRコード(json)を取得する。
   *
   * @param {number} [user_id] ユーザー ID (省略時はログインしているユーザーの ID が使用される)。
   * @return {*}  {Observable<HttpResponse<object>>} レスポンスの受信を監視するための `Observable`。
   * @memberof SqrcWebApiService
   */
  getCode(user_id?: string): Observable<HttpResponse<UserQr>> {

    return this.http.get<UserQr>(`${this.config.baseUrl}`, {
      ...this.config.httpOptions,
      observe: 'response',
      params: user_id ? {user_id: `${user_id}`} : null,
      withCredentials: true
    });
  }

  /**
   * QRコードの再発行を行う。
   *
   * @param {number} [user_id]
   * @return {*}  {Observable<HttpResponse<UserQr>>}
   * @memberof SqrcWebApiService
   */
  putCode(user_id?: string): Observable<HttpResponse<UserQr>> {

    return this.http.put<UserQr>(`${this.config.baseUrl}`, {user_id: user_id}, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }

  /**
   * SQRCの変更を監視するための `Observable` を取得する。
   *
   * @readonly
   * @type {Observable<any>}
   * @memberof SqrcWebApiService
   */
  get sqrcChanged(): Observable<any> {
    return this.sqrcSubject.asObservable();
  }

  /**
   * 利用者カード発行依頼を運営に対して送る
   *
   * @param {string} alt_user_id
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof SqrcWebApiService
   */
  requestIssueUserCard(alt_user_id: string): Observable<HttpResponse<any>> {

    return this.http.post<any>(`${this.config.baseUrl}/issue`, {alt_user_id: alt_user_id, areacode: this.config.areacode}, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }
}

//=============================================================================================
// インターフェース定義（本サービスでしか使用しないためここに記載）
//=============================================================================================
export namespace SqrcWebApiSchemas.ResponseSchemas {
  /**
   * カスタマイズされたエラー情報。
   *
   * @export
   * @interface AuthHttpCustomErrorContent
   * @extends {HttpCustomErrorContent}
   */
  export interface AuthHttpCustomErrorContent extends HttpCustomErrorContent {
    /**
     * デコードに成功した画像番号 (成功時: 0～9、失敗時: -1)。
     *
     * @type {number}
     * @memberof AuthHttpCustomErrorContent
     */
    qr_data_number: number;

    /**
     * 顔認証に成功した画像番号 (成功時: 0～9、失敗時: -1)。
     *
     * @type {number}
     * @memberof AuthHttpCustomErrorContent
     */
    face_number: number;
  }
}
