import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { OnsNavigator, Params } from 'ngx-onsenui';
import * as ons from 'onsenui';
import * as CONST from '../../../constants/constant';
import { Observable, Subscription } from 'rxjs';
import { FavoriteWebApiService } from '../../../http-services/favorite-web-api.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { Myspot, MyspotComponent } from '../../myspot/myspot.component';
import { PLACE_MAP_BUSSTOP_MARKER_ICON, PLACE_MAP_CURRENT_POSITION_MARKER_ICON, PLACE_MAP_MAP_OPTIONS, PLACE_MAP_MARKER_ICON, PLACE_MAP_SELECTED_FAVORITE_MARKER_ICON } from '../../reservation/place-map/place-map';
import { SpotGeolocationService } from '../../../http-services/spot-geolocation.service';
import { DispatchWebApiService } from 'src/app/http-services/dispatch-web-api.service';
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';
import { MESSAGE } from 'src/app/constants/message';

@Component({
  selector: 'ons-page[myspot-edit]',
  templateUrl: './myspot-edit.component.html',
  styleUrls: ['./myspot-edit.component.css']
})
export class MyspotEditComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(GoogleMap) private googleMap: GoogleMap;
  @ViewChild('pacInput') private pacInput: ElementRef<HTMLInputElement>;
  @ViewChild('clearButton') private clearButton: ElementRef;
  @ViewChild('searchButton') private searchButton: ElementRef;

  busyCenter: Subscription;
  busyPlace: Subscription;
  busyFavorite: Subscription;

  /**
   * バス停取得 Promise
   *
   * @type {Promise<any>}
   * @memberof MyspotEditComponent
   */
  busyBusstop: Promise<any>;

  /**
   * 
   *
   * @type {Myspot.SearchCondition}
   * @memberof MyspotEditComponent
   */
  searchCondition: Myspot.SearchCondition;

  /**
   * 選択したマイスポットID
   *
   * @type {number}
   * @memberof MyspotEditComponent
   */
  mySpotId: number;

  /**
   * マイスポット名前
   *
   * @memberof MyspotEditComponent
   */
  mySpotName = '';
  
  /**
   * マイスポット住所
   *
   * @memberof MyspotEditComponent
   */
  mySpotAddress = '';

  /**
   *削除ボタンを出すかどうか
   *
   * @type {boolean}
   * @memberof MyspotEditComponent
   */
  isShowDeleteBtn: boolean = false;

  /**
   *選択したマイスポット
   *
   * @type {Myspot.SearchConditionSpot}
   * @memberof MyspotEditComponent
   */
  selectedSpot: Myspot.SearchConditionSpot;

  /**
   * 
   *
   * @memberof MyspotEditComponent
   */
  freeText = '';

  /**
   * マップの中心
   *
   * @type {google.maps.LatLngLiteral}
   * @memberof MyspotEditComponent
   */
  center: google.maps.LatLngLiteral;
  
  /**
   * ズーム設定
   *
   * @memberof MyspotEditComponent
   */
  zoom = 16;
  
  /**
   *マップ上に映すマーカー
   *
   * @type {SpotMarker[]}
   * @memberof MyspotEditComponent
   */
  markers: SpotMarker[] = [];

  /**
   * マイスポットにタイプがあるか　(HTMLで名前編集ができるかの判別に活用)
   *
   * @type {boolean}
   * @memberof MyspotEditComponent
   */
  favorite_type: boolean;

  private placesService: google.maps.places.PlacesService;
  private geocoder = new google.maps.Geocoder();

  private googleMapOnMapClick: Subscription;

  /**
   * assetsファイルへのパス(定数)
   *
   * @type {string}
   * @memberof MyspotEditComponent
   */
  readonly ASSETS_ACTIVE_PINK: string =  CommonFunctionModule.getAssetsUrl('/image/common/46-PinC_Active_Pink.png');

  /**
   * エリアの代表地点
   *
   * @private
   * @type {google.maps.LatLngLiteral}
   * @memberof MyspotEditComponent
   */
  private defaultSpotPosition: google.maps.LatLngLiteral

  /**
   * 情報ウィンドウ
   *
   * @type {google.maps.InfoWindow}
   * @memberof MyspotEditComponent
   */
  infoWindow: google.maps.InfoWindow;

  /**
   * バス停マーカー
   *
   * @type {google.maps.marker.AdvancedMarkerElement[]}
   * @memberof MyspotEditComponent
   */
  bussstopMarker: google.maps.marker.AdvancedMarkerElement[] = [];

  /**
   * バス停が表示されているかどうか
   *
   * @type {boolean}
   * @memberof MyspotEditComponent
   */
  isVisibleBusstop: boolean = true;

  constructor(
    @Inject(PLACE_MAP_MAP_OPTIONS) public mapOptions: google.maps.MapOptions,
    @Inject(PLACE_MAP_CURRENT_POSITION_MARKER_ICON) private currentPositionMarkerIcon: google.maps.Icon,
    @Inject(PLACE_MAP_MARKER_ICON) private markerIcon: google.maps.Icon,
    @Inject(PLACE_MAP_SELECTED_FAVORITE_MARKER_ICON) private selectedMarkerIcon: google.maps.Icon,
    @Inject(PLACE_MAP_BUSSTOP_MARKER_ICON) private busstopIcon: google.maps.Icon,
    private spotGeolocationService: SpotGeolocationService,
    private _navigator: OnsNavigator,
    private favoriteWebApiService: FavoriteWebApiService,
    private httpErrorResponseParserService: HttpErrorResponseParserService,
    private _params: Params,
    private appMsgServ: ApplicationMessageService,
    private municipalityWebApiServ: MunicipalityWebApiService,
    private dispatchWebApiServ: DispatchWebApiService,
    private msg: MESSAGE,
  ) {
    // 代表地点
    this.defaultSpotPosition = this.municipalityWebApiServ.setting.map.representive_loc;
  }

/**
* tokyo2world(日本測地系から世界測地系に変換)
* @param {lon} num - 日本測地系の経度
* @param {lat} num - 日本測地系の緯度
* @return {array} 0：世界測地系の経度 １:世界測地系の緯度
**/
  ngOnInit(): void {
    // searchConditionクラス（マイスポット関連）
    this.searchCondition = this._params.data;
    // 選択したスポット情報
    this.selectedSpot = this.searchCondition[0];
    // 削除ボタンを表示するかどうか
    this.isShowDeleteBtn = this.searchCondition.spotService.isSelected(this.selectedSpot);

    this.busyBusstop = this.dispatchWebApiServ.getBusstop().then(busstop => {
      this.setBusstop(busstop);
    });

    this.busyCenter = new Observable <{
      center: google.maps.LatLngLiteral;
      zoom?: number;
    }>
    (subscriber => {
      // 現在位置を取得
      this.spotGeolocationService.getCurrentPosition().subscribe({
        next: result => {
          // 現在位置
          const currentPosition = result.position != undefined ? this.spotGeolocationService.createLatLng(result.position.coords) : undefined;
          console.log(currentPosition);
          if (currentPosition) {
            // 現在位置のマーカーを描画
            this.drawMarker(currentPosition, this.currentPositionMarkerIcon);
          }

          // スポットの名前を取得
          this.mySpotName = this.searchCondition.spotService.getName(this.selectedSpot);

          // マイスポットに緯度経度情報がある場合
          if (this.searchCondition.spot.favorite?.location) {
            // 
            this.mySpotId = this.selectedSpot.favorite?.favorite_id;
            this.selectSpot(this.selectedSpot, this.selectedSpot.favorite?.place_id);

            subscriber.next({center: this.searchCondition.spotService.getLocation(this.selectedSpot)});
            subscriber.complete();
          } 
          else {
            // センターを指定されている場合
            if (this.selectedSpot.mapCenter) {
              subscriber.next({
                center: this.selectedSpot.mapCenter,
                zoom: this.selectedSpot.mapZoom
              });
            }
            // 指定されていない場合
            else {
              // 現在位置を取得できる場合
              if (currentPosition && this.spotGeolocationService.maxValidBounds.contains(currentPosition)) {
                
                subscriber.next({center: currentPosition.toJSON()})
              }
              // 現在位置を取得できない場合
              else {
                // デフォルト位置(町役場)をセンターに
                subscriber.next({center: this.defaultSpotPosition});
              }
            }
            subscriber.complete();
          }
        }
      });
    }).subscribe({
      next: a => {
        this.center = this.selectedSpot.mapCenter = a.center;
        this.zoom = this.selectedSpot.mapZoom = a.zoom || 16;
      }
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {this.favorite_type = !!this.searchCondition.spot.favorite?.type;});

    // 場所の検索、場所の詳細取得に関するメソッドが含まれているクラス
    this.placesService = new google.maps.places.PlacesService(this.googleMap.googleMap);

    this.pacInput.nativeElement.value = this.freeText || '';

    /** GoogleMap内部の検索ボックス */
    const searchBox = new google.maps.places.SearchBox(this.pacInput.nativeElement, {
      bounds: this.spotGeolocationService.maxValidBounds
    });

    // マップクリック時の処理-------------------------------------------------------------------------
    this.googleMapOnMapClick = this.googleMap.mapClick.subscribe(event => {
      event.stop(); // InfoWindow が表示されるのを防ぐ。ｗ

      // 他ウィンドウを閉じる
      this.closeInfoWindow();

      this.busyPlace = new Observable<{
        spot: Myspot.SearchConditionSpot;
        freeText: string;
      }>
      (subscriber => {
        const placeId = (event as google.maps.IconMouseEvent)?.placeId;

        // GoogleMapの役場やお店などのスポットマーカーをクリックした場合
        if (placeId) {
          this.placesService.getDetails({placeId: placeId, fields: ["name", "formatted_address", "geometry.location", "address_components"]}, 
          (result, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
              subscriber.next({
                spot: { placeResult: result },
                freeText: result.name
              });
            }
            subscriber.complete();
          });
        }
        // スポットマーカー以外の地点をクリックした場合
        else {
          // 住所を地理座標に変換
          this.geocoder.geocode({
            location: event.latLng
          }, 
          (results, status) => {
            if ((status === google.maps.GeocoderStatus.OK) && results[0]) {
              subscriber.next({
                spot: {
                  mapMouseEvent: event,
                  geocoderResult: results[0]
                },
                freeText: '指定された地点'
              });
            }
            subscriber.complete();
          });
        }
      }).subscribe({
        next: a => {
          this.selectSpot(a.spot);
          this.freeText = a.freeText;
          this.pacInput.nativeElement.value = a.freeText;
        },
        complete: () => {
          // console.log("[MapClick] Completed");
        }
      });
    });

    // GoogleMap内部にタグを挿入
    this.googleMap.controls[google.maps.ControlPosition.TOP_CENTER].push(this.pacInput.nativeElement);
    this.googleMap.controls[google.maps.ControlPosition.TOP_CENTER].push(this.clearButton.nativeElement);
    this.googleMap.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(this.searchButton.nativeElement);

    // 検索ボックスにplaces_changedイベント時の処理を与える
    // 　検索ボックスに文字を入力後enter or 検索候補から選択
    searchBox.addListener('places_changed', () => {
      this.selectedSpot = null;
      this.freeText = this.pacInput.nativeElement.value;

      // 検索結果の地点情報を取得
      const results = searchBox.getPlaces().filter(place => !!place.geometry);

      // マーカー設置に必要なデータ型に整形
      this.markers = results.map<SpotMarker>(result => {
        return {
          spot: {
            placeResult: result,
            searchText: this.freeText
          },
          options: {
            icon: this.markerIcon
          }
        }
      });
      this.refreshMarkers();

      // 検索後、地点候補の数によって処理を変える
      switch (results.length) {
        // 候補がない場合
        case 0:
          break;
        // 候補が単一の場合
        case 1:
          // マップのcenterとzoomを調整する
          this.googleMap.panTo(results[0].geometry.location);

          break;
        // 複数の候補がある場合
        default: {
          // 表示領域を定義(領域の左下と右上の座標を保持)するクラス
          const bounds = new google.maps.LatLngBounds();

          results.forEach(result => {
            // 表示領域情報がある場合
            if (result.geometry.viewport) {
              // 地点候補それぞれの表示領域を合成する
              bounds.union(result.geometry.viewport);
            }
            // 表示領域情報がない場合
            else {
              // 位置情報から、その地点を含めるように表示領域情報を変更
              bounds.extend(result.geometry.location);
            }
          })

          // 全ての候補を含めるようにcenterとzoomを調整する
          this.googleMap.fitBounds(bounds);
          break;
        }
      }
    });
  }

  ngOnDestroy(): void {
    [/* this.busyBusstop,  */this.busyCenter, this.busyPlace, this.busyFavorite, this.googleMapOnMapClick].forEach(s => {
      s?.unsubscribe();
    });
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   *マーカークリック(検索による複数候補の1つを選択)
   *
   * @param {SpotMarker} marker
   * @memberof MyspotEditComponent
   */
  selectSpotMarker(marker: SpotMarker): void {

    // 選択したマーカー情報
    this.selectedSpot = marker.spot;

    this.markers.forEach(m => {
      m.options = {
        icon: this.markerIcon
      };
    });
    marker.options = {
      icon: this.selectedMarkerIcon
    };
    this.refreshMarkers();

    // typeが無い場合、登録名を変更
    if (!this.favorite_type) {
      this.mySpotName = this.searchCondition.spotService.getName(this.selectedSpot);
    }
    this.setMySpotAddress(this.selectedSpot);
  }

  /**
   * googleMapズーム変更時イベント
   *
   * @memberof PlaceMapComponent
   */
  onZoomChanged(): void {
    // googlemapが生成されていない場合、処理を抜ける
    if (this.googleMap === void 0) return;
    // バス停がない場合、処理を抜ける
    if (this.bussstopMarker.length === 0) return;

    const zoom: number = this.googleMap.getZoom();

    if (zoom <= 12) {
      // マーカー非表示
      this.bussstopMarker.forEach(item => (item.content as HTMLImageElement).hidden = true);
      // toastメッセージを表示する。
      if (this.isVisibleBusstop === true) {
        // this.appMsgServ.viewToastMessage(this.appMsgServ.getCLientMsg(this.appMsgServ.CLIENT_CODE.DISPATCH.BUSSTOP_NO_VISIBLE));
        const toast = document.getElementById("toast--bottom");
        if (!toast || (toast && toast.style.display == "none")) {
          ons.notification.toast(this.msg.CLIENT.DISPATCH.BUSSTOP_NO_VISIBLE.message(), {
            force: true, animation: "none", timeout: 4000, class: "toast--bottom"
          });
        }
      }
      this.isVisibleBusstop = false;
    }
    else if (zoom > 12 && this.isVisibleBusstop === false) {
      // 再表示
      this.bussstopMarker.forEach(item => (item.content as HTMLImageElement).hidden = false);
      this.isVisibleBusstop = true;

      const toast = document.querySelectorAll<HTMLElement>(".toast--bottom");
      if (toast.length !== 0) {
        toast.forEach(item => {
          item.style.display = "none";
        })
      }
    }
  }

  /**
   *検索ボックスを空にする
   *
   * @memberof MyspotEditComponent
   */
  clearRelatedPlaceMarkers(): void {
    this.freeText = this.pacInput.nativeElement.value = '';
    this.selectedSpot = null;
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   *スポットとマーカーのセット
   *
   * @private
   * @param {Myspot.SearchConditionSpot} spot
   * @param {string} placeId
   * @memberof MyspotEditComponent
   */
  private selectSpot(spot: Myspot.SearchConditionSpot, placeId?: string): void {
    this.selectedSpot = { ...spot };
    setTimeout(() => {
      this.markers = [{
        spot: this.selectedSpot,
        options: {
          icon: this.selectedMarkerIcon
        }
      }];
      this.refreshMarkers();
    });

    // タイプフラグ(自宅など)が存在するスポットの名前は変更されない
    if (!this.favorite_type) {
      this.mySpotName = this.searchCondition.spotService.getName(this.selectedSpot);
    }

    this.setMySpotAddress(this.selectedSpot, placeId);
  }

  /**
   * バス停選択イベント
   * 情報ウィンドウ表示
   *
   * @param {BusstopMarker["busstop"][number]} marker
   * @memberof PlaceMapComponent
   */
  private selectBusstop(event, marker: BusstopMarker["busstop"][number]) {

    const text = `<p>${marker.name}</p>`;
    // 他の情報ウィンドウを閉じる
    this.closeInfoWindow();

    // 情報ウィンドウを設定
    this.infoWindow = new google.maps.InfoWindow({ content: text });

    const latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
    this.infoWindow.setPosition(latlng);

    // open
    this.infoWindow.open(this.googleMap.googleMap);


    this.busyPlace = new Observable<
    {
      spot: Myspot.SearchConditionSpot;
      freeText: string;
    }>
    (subscriber => {
      // 地理座標を住所に変換
      this.geocoder.geocode({
        location: event.latLng
      }, 
      (results, status) => {
        if ((status === google.maps.GeocoderStatus.OK) && results[0]) {
          subscriber.next({
            spot: {
              mapMouseEvent: event,
              geocoderResult: results[0]
            },
            freeText: marker.name
          });
          subscriber.complete();
        }
      });
    }).subscribe({
      next: a => {
        this.selectSpot(a.spot);
        this.mySpotName = marker.name;
        this.freeText = a.freeText;
        this.pacInput.nativeElement.value = a.freeText;
      },
      complete: () => {
      }
    });
  }

  /**
   *
   *
   * @private
   * @param {Myspot.SearchConditionSpot} spot
   * @param {string} [placeId]
   * @return {*}  {void}
   * @memberof MyspotEditComponent
   */
  private setMySpotAddress(spot: Myspot.SearchConditionSpot, placeId?: string): void {

    // 書式化した住所を取得
    const formatAddress = (spot.placeResult?.address_components && this.spotGeolocationService.formatPlaceResultAddress(spot.placeResult)) ??
      (spot.geocoderResult?.address_components && this.spotGeolocationService.formatGeocoderResultAddress(spot.geocoderResult));

    if (formatAddress) {
      this.mySpotAddress = formatAddress;
    } 
    else {
      const geocoderParam = (() => {
        if (placeId) 
          return {placeId: placeId};
        
        // 地点情報が取得できている or マイスポット指定
        const isSearchResultOrFavoriteSpot = spot.placeResult?.geometry || spot.favorite?.location;

        if (isSearchResultOrFavoriteSpot)
          return {location: spot.placeResult?.geometry.location ?? spot.favorite.location};
      })();

      if (!geocoderParam) {
        const location = spot.geocoderResult?.geometry.location;
        // 住所を更新(住所の代わりに緯度経度を代入)
        this.mySpotAddress = location ? `${location.lat()}, ${location.lng()}` : "";
        return;
      };

      // 住所を地理座標に変換
      this.geocoder.geocode(geocoderParam, (results, status) => {
        switch (status) {
          case google.maps.GeocoderStatus.OK: {
            if (results[0].geometry) {
              const location = results[0].geometry.location;
              this.mySpotAddress = this.spotGeolocationService.formatGeocoderResultAddress(results[0]) || `${location.lat()}, ${location.lng()}`;
            }
            else {
              this.mySpotAddress = "";
            }
            break;
          }
          default: {
            this.mySpotAddress = "";
            break;
          }
        }
      });
    }
  }

  /**
   * マイスポット設定ボタンの活性・非活性切り替え
   */
  canPressButton(): boolean {
    return !!this.selectedSpot && !!this.mySpotName;
  }

  /**
   * マップバス停をセット
   *
   * @private
   * @memberof MyspotEditComponent
   */
  private setBusstop(busstop): void {
    // 初期化
    busstop.forEach(item => {
      const position = new google.maps.LatLng(item.geo_location.coordinates[1], item.geo_location.coordinates[0]);
      const marker = this.drawMarker(position, this.busstopIcon);
      this.bussstopMarker.push(marker);

      marker.addListener('click', (event: google.maps.MapMouseEvent) => {
        this.selectBusstop(event, {name: item.name, position: position});
      });
    });
  }

  /**
   * 情報ウィンドウを閉じる
   *
   * @private
   * @memberof MyspotEditComponent
   */
  private closeInfoWindow(): void {
    if (this.infoWindow != null && this.infoWindow != void 0) {
      this.infoWindow.close();
      this.infoWindow = void 0;
    }
  }

//=============================================================================================
// サーバー通信
//=============================================================================================

  /**
   *マイスポットの削除
   *
   * @memberof MyspotEditComponent
   */
  delete(): void {
    // マイスポット削除処理
    const deleteFavorite = () => this.favoriteWebApiService.deleteFavorites(this.mySpotId).subscribe({
      next: () => {
        this.appMsgServ.viewDialogMessage(this.msg.CLIENT.MY_SPOT.COMPLETE_DELETE.message(), () => {
          this._navigator.element.popPage({
            animation: "none",
            callback: () => {
              this._navigator.element.replacePage(MyspotComponent);
            }
          });
        });
      },
      error: this.httpErrorResponseParserService.doParse((_error, customErrorContent) => {
        // TODO: 適切なエラーメッセージを。
        this.appMsgServ.viewDialogMessage(customErrorContent.smartGotoErrMessage || this.msg.CLIENT.COMMON.E_UNEXPECTED.message());
      })
    });
    
    // マイスポット削除の確認ダイアログ
    ons.notification.confirm({
      title: CONST.Common.CONFIRM,
      message: this.msg.CLIENT.MY_SPOT.COMFIRM_DELETE.message(),
      buttonLabels: [CONST.Common.NO, CONST.Common.YES],
      primaryButtonIndex: 0,
      cancelable: true,
      // 確認ダイアログでOKを選択した場合、マイスポット削除処理実施
      callback: (index: number) => {
        if (index==1) {
          deleteFavorite();
        }
      }
    });
  }

  /**
   *マイスポットの更新・登録
   *
   * @memberof MyspotEditComponent
   */
  edit(): void {
    this.busyFavorite = (() => {
      const favorite = {
        favorite_id: this.mySpotId,
        place_id: this.selectedSpot.placeResult?.place_id,
        location: this.searchCondition.spotService.getLocation(this.selectedSpot),
        name: this.mySpotName,
        search_text: this.selectedSpot.searchText,
        type: this.searchCondition.spot.favorite?.type
      };
      // 新規登録と更新の区別(favorite_idがあるかないか)
      if (this.mySpotId) {
        return this.favoriteWebApiService.updateFavorite(favorite.favorite_id, favorite);
      } 
      else {
        return this.favoriteWebApiService.createFavorite(favorite);
      }
    })().subscribe({
      next: () => {
        this.appMsgServ.viewDialogMessage(this.msg.CLIENT.MY_SPOT.COMPLETE_REGIST.message(), () => {
          this._navigator.element.popPage({
            animation: "none",
            callback: () => {
              this._navigator.element.replacePage(MyspotComponent);
            }
          });
        });
      },
      error: this.httpErrorResponseParserService.doParse((_error, customErrorContent) => {
        // TODO: 適切なエラーメッセージを。
        this.appMsgServ.viewDialogMessage(customErrorContent.smartGotoErrMessage || this.msg.CLIENT.COMMON.E_UNEXPECTED.message());
      })
    });
  }

  /**
   * 描画済みのマーカー
   */
  private drown_markers:google.maps.marker.AdvancedMarkerElement[] = [];

  /**
   * マーカー再描画
   */
  private refreshMarkers():void {
    // 描画済みのものを削除
    this.drown_markers.forEach(drown_marker => {
      drown_marker.map = null;
      drown_marker.remove();
    });
    this.drown_markers = [];

    // マーカーを描画
    for (let marker of this.markers) {
      const position = new google.maps.LatLng(this.searchCondition.spotService.getLocation(marker.spot));      
      const drown_marker = this.drawMarker(position, marker.options.icon);
      drown_marker.addListener('click', () => {
        this.selectSpotMarker(marker);
      });

      // 描画済み
      this.drown_markers.push(drown_marker);
    }
  }


  /**
   * マーカー描画
   * @param car_info 
   */
  private drawMarker(position: google.maps.LatLng, icon: google.maps.Icon): google.maps.marker.AdvancedMarkerElement {

    const options: google.maps.marker.AdvancedMarkerElementOptions = {
      map: this.googleMap.googleMap,      
      position: position,
      content: CommonFunctionModule.convertIconToContent(icon),
    }
    
    const marker = new google.maps.marker.AdvancedMarkerElement(options);

    return marker;
  }

}

/**
 * スポットの情報
 *
 * @interface SpotMarker
 */
interface SpotMarker {
  spot: Myspot.SearchConditionSpot;
  options: {
    icon? : google.maps.Icon;
  }
}

/**
 * バス停
 *
 * @interface BusstopMarker
 */
interface BusstopMarker {
  busstop?: {
    position: google.maps.LatLng;
    name: string;
  }[],
}