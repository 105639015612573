<ons-toolbar>
    <div class="left ">
        <ons-back-button></ons-back-button>
    </div>
    <div class="center">移動プラン選択</div>
</ons-toolbar>
<div class="content">
    <div [ngBusy]="busy"></div>
    <div class="condition">
        <dl class="condition-item">
            <dt class="condition-item__title"><img class="spot-icon" [src]="ASSETS.DEPARTURE" />
            </dt>
            <dd class="condition-item__data">{{searchCondition.spotService.getFormalNameOrAddress(searchCondition.o)}}
            </dd>
        </dl>
        <dl class="condition-item">
            <dt class="condition-item__title"><img class="spot-icon"
                    [src]="ASSETS.DESTINATION" /></dt>
            <dd class="condition-item__data">{{searchCondition.spotService.getFormalNameOrAddress(searchCondition.d)}}
            </dd>
        </dl>
        <div class="condition-item">
            <p class="condition-item__text">{{scheduleDateTime.format('M/D')}}
                {{scheduleDateTime.format('HH:mm')}}{{searchCondition.schedule.odOptions[searchCondition.schedule.od].label}}
            </p>
            <p class="condition-item__text">乗車人数{{searchCondition.getPassengersCount()}}人</p>
        </div>
    </div>
    <ons-segment hidden active-index="0">
        <button id="search-result-list_001" (click)="planner.sort('time')">到着が早い</button>
        <button id="search-result-list_002" (click)="planner.sort('fee')">料金が安い</button>
        <button id="search-result-list_003" (click)="planner.sort('transfer')">乗換が少ない</button>
    </ons-segment>
    <ons-list *ngFor="let reservation of planner?.reservations; index as i" (click)="selectPlan(reservation, i)">
        <ons-list-header class="plan__title content--title--bg">プラン{{i + 1}}</ons-list-header>
        <ons-list-item tappable modifier="chevron">
            <p class="plan-text">{{moment(reservation.o.schd_time).format('HH:mm')}} -
                {{moment(reservation.d.schd_time).format('HH:mm')}}
                ({{moment.duration(moment(reservation.d.schd_time).startOf('minutes').diff(moment(reservation.o.schd_time).startOf('minutes'))).format('h[時間]m[分]')}})
            </p>
            <div class="plan-item">
                <ng-container *ngFor="let section of reservation.sections; last as isLast">
                    <div class="plan-item__icon"><img [src]="reservationTransMap.get(section.trans).get('icon')" />
                    </div>
                    <div *ngIf="!isLast" class="plan-item__icon--trans">
                        <ons-icon icon="fa-arrow-right"></ons-icon>
                    </div>
                </ng-container>
            </div>
            <dl class="plan-item">
                <dt class="plan-item__title">乗換</dt>
                <dd class="plan-item__data">{{ reservation.trans_count - 1 }}回</dd>
            </dl>
            <dl class="plan-item">
                <dt class="plan-item__title">合計</dt>
                <dd class="plan-item__data">￥{{ reservation.price | number:'.0-0' }} （{{ reservation.passengers }}人分）</dd>
            </dl>
        </ons-list-item>
    </ons-list>
</div>
