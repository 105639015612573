//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnDestroy, OnInit } from '@angular/core';
import { OnsNavigator, Params } from 'ngx-onsenui';
import { Subscription } from 'rxjs';
import * as CONST from '../../../constants/constant'

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { FamilyWebApiService } from '../../../http-services/family-web-api.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { PageKey, PagerService } from 'src/app/lib-services/pager.service';

// component
import { FamilyLimitComponent } from '../_family-limit/family-limit.component';
import { FamilyDeleteComponent } from '../family-delete/family-delete.component';
import { TabbarComponent } from '../../tabbar/tabbar.component';

// interface
import { Relation } from '../../../interfaces/response';
import { ProxyAccountProfileEditComponent } from '../../proxy-account/proxy-account-profile-edit/proxy-account-profile-edit.component';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * ファミリー詳細画面
 *
 * @export
 * @class FamilyDetailComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @author （株）オブジェクトデータ takimoto yoshida wada
 */
@Component({
  selector: 'ons-page[family-detail]',
  templateUrl: './family-detail.component.html',
  styleUrls: ['./family-detail.component.css']
})
export class FamilyDetailComponent implements OnInit, OnDestroy {
//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * ファミリー詳細のSubscriptionオブジェクト
   *
   * @type {Subscription}
   * @memberof FamilyDetailComponent
   */
  m_Busy: Subscription;

  /**
   * ファミリー情報変更監視subscription
   *
   * @private
   * @type {Subscription}
   * @memberof MyqrComponent
   */
  private onFamilyChanged: Subscription;

  /**
  * ユーザー情報
  *
  * @type {Relation}
  * @memberof FamilyDetailComponent
  */
  m_Relation: Relation;

  /**
   * ログインユーザのファミリーロール
   *
   * @type {string}
   * @memberof FamilyComponent
   */
  m_LoginUserRole: string;

  /**
   * OnDestroy時に破棄するSubscriptionオブジェクト
   *
   * @memberof FamilyDetailComponent
   */
  subscription = new Subscription();

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of FamilyDetailComponent.
   * @param {OnsNavigator} navigator
   * @param {ApplicationMessageService} appMsg
   * @param {HttpErrorResponseParserService} httpErrorResponseParserService
   * @param {Params} params
   * @memberof FamilyDetailComponent
   */
  constructor (
    private navigator: OnsNavigator,
    private appMsg: ApplicationMessageService, 
    private httpErrorResponseParserService: HttpErrorResponseParserService,
    private familyWebApiService: FamilyWebApiService, 
    private params: Params,
    private pagerServ: PagerService,
    private msg: MESSAGE,
  ) {}

  /**
   * 初期化処理
   *
   * @memberof FamilyDetailComponent
   */
  ngOnInit(): void {
    //FamilyComponentからのデータ受け取り
    this.m_Relation = this.params.data.relation;

    // ログインユーザのファミリーロールを取得
    this.m_LoginUserRole = this.familyWebApiService.getLoginUserRole();

    // ファミリー詳細画面を戻り先のページとして登録
    this.pagerServ.setReturnPage({key: PageKey.FamilyDetailComponent, index: this.navigator.element.pages.length - 1})
  }

  /**
   * HTMLのデータ処理
   *
   * @memberof FamilyDetailComponent
   */
  ngAfterViewInit(): void {
    // ファミリー情報の変更を監視
    this.onFamilyChanged = this.familyWebApiService.familyInfoChenged.subscribe({
      next: familyInfo => {
        if (familyInfo) {
          // ファミリー情報を更新
          this.m_Relation = familyInfo.find(f => f.relation_id === this.m_Relation?.relation_id);
        }
      }
    });

    // ページスタックが削除されて、この画面に戻ってくることを監視
    const pagerSubject = this.pagerServ.subject.subscribe({
      next: (key: number) => {
        if (key == PageKey.FamilyDetailComponent) {
          // 最新のファミリー情報を取得
          this.m_Busy = this.familyWebApiService.getFamily().subscribe();
        }
      },
    });

    this.subscription.add(pagerSubject);
  }

  /**
   * 破棄処理
   *
   * @memberof FamilyDetailComponent
   */
  ngOnDestroy(): void {
    // 廃棄
    [this.m_Busy, this.onFamilyChanged, this.subscription].forEach(s => { s?.unsubscribe(); });
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
  * 管理者によるメンバーの利用制限の有無をchangeするためのページに遷移する。
  *
  * @memberof FamilyDetailComponent
  */
  pushLimit(): void {
    this.navigator.element.pushPage(FamilyLimitComponent, { data: { relation: this.m_Relation, class: this.params.data.class }});
  }
  
  /**
   * ファミリーリンク解除へ遷移する。
   *
   * @memberof FamilyDetailComponent
   */
  deleteFamily(): void {
    this.navigator.element.pushPage(FamilyDeleteComponent, { data: { relation: this.m_Relation, class: this.params.data.class, deleteMode: "family" }});
  }

  /**
   * アカウント削除へ遷移する。
   *
   * @memberof FamilyDetailComponent
   */
  deleteAccount(): void {
    this.navigator.element.pushPage(FamilyDeleteComponent, { data: { relation: this.m_Relation, class: this.params.data.class, deleteMode: "account" }});
  }

  /**
   * 住所変更へ遷移する。
   *
   * @memberof FamilyDetailComponent
   */
  pushEdit(): void {
    this.navigator.element.pushPage(ProxyAccountProfileEditComponent, { data: { relation: this.m_Relation, class: this.params.data.class, detailPageIndex: this.navigator.element.pages.length - 1 } });
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * 性別（文字列）を返却する。
   *
   * @param {number} sex
   * @return {*}  {string}
   * @memberof FamilyDetailComponent
   */
  getFamilySexStr(sex: number): string {
    switch (sex){
      case 0: return CONST.Common.SEX_NO_ANSWER;
      case 1: return CONST.Common.SEX_MALE;
      case 2: return CONST.Common.SEX_FEMALE;
      default: return "";
    }
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * ファミリーリレーション解除申請の取り消しを行う。
   *
   * @private
   * @memberof FamilyDeleteComponent
   */
  private rejectDeleteRelation(): void {
    this.familyWebApiService.rejectDeleteRelation(this.m_Relation.relation_id).subscribe({
      next: () => this.appMsg.viewDialogMessage(this.msg.CLIENT.FAMILY.CONFIRM_NG.message(), () => this.params.data.class.getFamily()), 
      error: this.httpErrorResponseParserService.doParse((_error, customErrorContent) => {
        this.appMsg.viewDialogErrorMessage(this.msg.CLIENT.COMMON.E_UNEXPECTED.message(), customErrorContent.smartGotoErrMessage);
      })
    });
  }
}
