//=============================================================================================
// import
//=============================================================================================
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Params, OnsNavigator } from 'ngx-onsenui';
import * as dayjs from 'dayjs';

// service
import { HttpErrorResponseParserService } from '../../../../lib-services/http-error-response-parser.service';
import { CommonFunctionModule } from "../../../../lib-modules/common-function.module";
import { ExpWebApiService, ReservationOption, UserSelectOption } from '../../../../http-services/exp-web-api.service';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';
import { ApplicationMessageService } from 'src/app/lib-services/application-message.service';

// component

// parts
import { ListParts } from "../../../parts/ons-list/ons-list.component";

// interface
import { parameter } from '../../../../interfaces/parameter';
import { ExpAvailable, ExpResourceAvailable, ExpReservation, ExpFetchReservation, SgServiceOption } from '../../../../interfaces/response';
import { HttpResponse } from '@angular/common/http';
import { request } from 'src/app/interfaces/request';
import { ExpChangeDatermineComponent } from '../exp-change-datermine/exp-change-datermine.component';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 観光サービス予約変更画面。
 *
 * @export
 * @class ExpChangeComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ons-page[exp-change]',
  templateUrl: './exp-change.component.html',
  styleUrls: ['./exp-change.component.scss']
})
export class ExpChangeComponent implements OnInit, AfterViewInit, OnDestroy {

  readonly ASSETS = {
    INFORMATION: CommonFunctionModule.getAssetsUrl('/image/button/info.png')
  } as const;

  /**
   * 通信監視用Subscription
   *
   * @type {Subscription}
   * @memberof ExpChangeComponent
   */
  busy: Subscription;

  /**
   * onslist parts
   *
   * @type {{
   *     header: ListParts;
   *     body: ListParts[];
   *   }}
   * @memberof ExpChangeComponent
   */
  template: {

    /**
     * ヘッダー
     *
     * @type {ListParts}
     */
    header: ListParts;

    /**
     * 本文1
     *
     * @type {ListParts[]}
     */
    body1: ListParts[];

    /**
     * 本文2
     *
     * @type {ListParts[]}
     */
    body2: ListParts[];
  };

  orgReservNumber: ListParts["select_box"]["item"][] = [];

  /**
   * 予約可能な最大数
   *
   * @type {number}
   * @memberof ExpChangeComponent
   */
  max_resources: number = 0;

  /**
   * 観光履歴・予定
   *
   * @type {ExpReservation}
   * @memberof ExpCancelComponent
   */
  private reserv: ExpReservation;

  /**
   * 取得した最新の空き時間情報
   *
   * @type {ExpResourceAvailable['available']}
   * @memberof ExpChangeComponent
   */
  targetAvailable: ExpResourceAvailable['available'] = [];

  /**
   * ユーザが選択した料金種別毎の予約数
   *
   * @private
   * @type {{ type: number, value:number }[]}
   * @memberof ExpChangeComponent
   */
  private reserv_number: { type: number, value:number }[] = [];

  /**
   * ユーザが選択したオプション情報
   *
   * @private
   * @type {request.ExpReservation["service_options"]}
   * @memberof ExpChangeComponent
   */
  selectedOption: {[option_id: string]: UserSelectOption} = {};

  /**
   * オプショングループ
   *
   * @type {ExpService["option_groups"]}
   * @memberof ExpDetailComponent
   */
  optionGroups: ExpReservation["service"]["option_groups"] = [];

  /**
   * 体験サービス予約変更
   * 管理クラス
   *
   * @type {ReservationOption}
   * @memberof ExpChangeType1Component
   */
  putReservationOption: ReservationOption;

  /**
   * 最少人数エラーの有無
   *
   * @type {boolean}
   * @memberof ExpChangeComponent
   */
  err_min_number: boolean = false;

  /**
   * 最大人数(capacity)エラーの有無
   * activityサービスのみ
   *
   * @type {boolean}
   * @memberof ExpChangeComponent
   */
  err_max_number: boolean = false;

  /**
   * 遷移ボタンの有効状態
   *
   * @type {boolean}
   * @memberof ExpChangeComponent
   */
  btn_trans_disabled: boolean = true;
  
  /**
   * 次画面への遷移ボタン
   *
   * @type {ElementRef}
   * @memberof ExpDetailComponent
   */
  @ViewChild('btn_trans') btn_trans: ElementRef<HTMLButtonElement>;

  /**
   * オプション、インフォメーションマーク要素
   *
   * @type {QueryList<ElementRef>}
   * @memberof ExpChangeComponent
   */
  @ViewChildren('optionDialog') optionDialog: QueryList<ElementRef>;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of ExpChangeComponent.
   * @param {HttpErrorResponseParserService} errResServ
   * @param {CommonFunctionModule} commonFunc
   * @param {ExpWebApiService} expServ
   * @param {OnsNavigator} navigator
   * @param {Params} params
   * @memberof ExpChangeComponent
   */
  constructor(
    private errResServ: HttpErrorResponseParserService, 
    private commonFunc: CommonFunctionModule, 
    private expServ: ExpWebApiService, 
    private navigator: OnsNavigator, 
    private params: Params,
    private municipalityWebApiServ: MunicipalityWebApiService,
    private appMsgServ: ApplicationMessageService
  ) { }

  /**
   * 初期処理。
   *
   * @memberof ExpChangeComponent
   */
  ngOnInit(): void {

    this.reserv = this.commonFunc.deepcopy(this.params.data.reserv);

    // type='usage'以外除外
    this.reserv.service_options = this.reserv.service_options.filter(option => option.type === "usage");

    this.putReservationOption = new ReservationOption(this.reserv, this.municipalityWebApiServ.setting.exp);
    this.optionGroups = this.putReservationOption.optionGroups;
    this.selectedOption = this.putReservationOption.selectedOption;

    // 料金種別毎の予約数を保存
    this.reserv.numbers.forEach(n => this.reserv_number.push({type: n.type, value: n.value}));

    // 予約の時間で空き情報取得
    // this.getAvaivable();

    // テンプレート情報作成
    this.createTemplateDate();
  }
  
  /**
   *
   *
   * @memberof ExpChangeComponent
   */
  ngAfterViewInit(): void {
    // オプション、インフォメーションマークclick時、二重イベント止める
    this.optionDialog.forEach(item => {
      item.nativeElement.addEventListener('click', (event) => {
        console.log('click');
        event.stopPropagation();
      });
    })
  }

  /**
   * 破棄処理。
   *
   * @memberof ExpChangeComponent
   */
  ngOnDestroy(): void {
    
    this.busy?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * セレクトボックス変更時の値格納処理。
   *
   * @param {*} event
   * @return {*}  {void}
   * @memberof ExpChangeComponent
   */
  selectedEvent(event: any): void {

    try {
      switch (event.id) {
        // 利用数、人数
        case "reserv_number":
          
          const key = Number(event.key);
        
          // リストに変更がないなら終了
          if (this.reserv_number[0].value === key) return;

          // ユーザの選択値を保存
          this.reserv_number[0].value = key;
          break;
        default:
          if (!event.id.indexOf("reserv_number")){

            // 対象の要素番号（２桁（最大９９=length１００）まで対応）を取得
            const typeIndex: number = Number((<string>event.id).slice(this.reserv_number.length >= 10 ? -2 : -1));
          
            const key = Number(event.key);

            // リストに変更がないなら終了
            if (this.reserv_number[typeIndex].value ===  key) return;

            // ユーザの選択値を保存
            this.reserv_number[typeIndex].value = key;
            
            // if (this.reserv.service.capacity !== void 0) {
            //   // 操作した料金種別以外の予約数の上限値を設定
            //   let reserv_numbers = this.template.body1.filter(b => b?.id != undefined && !b?.id.indexOf("reserv_number"));
            //   reserv_numbers.forEach((n, index: number) => {

            //     // 変更したリストは処理しない
            //     if (typeIndex === index) return;

            //     const counts = this.reserv_number.reduce((pre,cur) => pre+cur.value,0);

            //     // ほかのリストを最大予約数から変更したリストの値を上限としたものに変更
            //     n.select_box.item = this.commonFunc.deepcopy(this.orgReservNumber[index].filter(item => (item.key as number + key) <= this.reserv.service.capacity - counts + this.reserv_number[index].value));
            //   });
            // }
          
            // 最少人数チェック
            if (this.reserv.service.service_type === "ACTIVITY") {
              const sumNum = this.reserv_number.map(num => num.value).reduce((a, b) => a + b);
              const index = this.template.body1.findIndex(b => b.id === "reserv_number" + (this.reserv.service.user_params.numbers.length - 1));
              // console.log("index: ", index, "sum: ", sumNum,  "this.template.body1: ", this.template.body1);
  
              if (this.err_min_number = this.expServ.isMinNumberCheck(this.reserv.service.constraints, sumNum)) 
                // todo 表示するメッセージは制約によって切り替わる。現状は配列[0]に最小人数制約が入っている前提
                this.template.body1[index].valid_msg = this.reserv.service.constraints[0].message;
              else this.template.body1[index].valid_msg = "";
            }

            // 遷移ボタン有効/無効設定
            // if (this.reserv_number[0].value !== this.reserv.numbers[0].value) this.btn_trans_disabled = false;
            // else this.btn_trans_disabled = true;
          }
          else return;
      }
    }
    finally {
      // 制約エラーがなく、種別毎の予約数に変更があるなら遷移ボタンを活性化
      // const result = this.reserv_number.filter((n, index: number) => n !== this.reserv.numbers[index].value);
      // this.err_min_number === false && result.length >= 1 ? this.btn_trans_disabled = false : this.btn_trans_disabled = true;
      this.checkCapacity();

      this.switchOverTransitionBtn();
    }
  }

  /**
   * オプション詳細ダイアログ
   *
   * @param {ExpReservation["service"]["option_groups"][number]["options"][number]} option
   * @memberof ExpChangeType1Component
   */
  onClickOptionInfo(option: ExpReservation["service"]["option_groups"][number]["options"][number]): void {
    this.expServ.openOptionDialog(option);
  }

  /**
   * オプション変更(user_option: yesno)
   *
   * @param {ExpReservation["service"]["option_groups"][number]["options"][number]} option
   * @param {(SgServiceOption["yesno_param"]["items"][number] | SgServiceOption["select_param"]["items"][number])} item
   * @memberof ExpDetailComponent
   */
  onChangeOptionYesno(option: ExpReservation["service"]["option_groups"][number]["options"][number], item: SgServiceOption["yesno_param"]["items"][number]): void {
    this.putReservationOption.changeOptionYesno(option, item);
    this.switchOverTransitionBtn();
  }

  /**
   * オプション変更(user_option: select)
   *
   * @param {ExpReservation["service"]["option_groups"][number]["options"][number]} option
   * @param {SgServiceOption["select_param"]["items"][number]} item
   * @memberof ExpDetailComponent
   */
  onChangeOptionSelect(option: ExpReservation["service"]["option_groups"][number]["options"][number], item: SgServiceOption["select_param"]["items"][number]): void {
    this.putReservationOption.changeOptionSelect(option, item);
    this.switchOverTransitionBtn();
  }

  /**
   *
   *
   * @param {ExpReservation["service"]["option_groups"][number]["options"][number]} option
   * @param {number} value
   * @memberof ExpDetailComponent
   */
  onChangeOptionNumber(option: ExpReservation["service"]["option_groups"][number]["options"][number], value: number): void {
    this.putReservationOption.changeOptionNumber(option, value);
    this.switchOverTransitionBtn();
  }

  /**
   * 日数選択型オプション 変更
   *
   * @param {ExpReservation["service"]["option_groups"][number]["options"][number]} option
   * @param {number} item
   * @memberof ExpDetailComponent
   */
  onChangeOptionTime(option: ExpReservation["service"]["option_groups"][number]["options"][number], item: number) {
    this.putReservationOption.changeOptionTime(option, item);
    this.switchOverTransitionBtn();
  }

  /**
   * 確認ボタン押下時のイベントハンドラ。
   *
   * @memberof ExpChangeComponent
   */
  onConfirm(): void {
    // fetch用リクエストボディ作成
    let reqBody: request.ExpReservation = {
      numbers: this.reserv_number,
      as: "user",
      service_options: this.putReservationOption.createFetchOption()
    };

    // fetch
    this.busy = this.expServ.putFetchReservation(this.reserv.sg_reservation_id, reqBody).subscribe({
      next: res => {

        const body: ExpFetchReservation = res.body;
        // 次画面へ
        this.navigator.element.pushPage(ExpChangeDatermineComponent, {
          data: { 
            fetch: body,
            reservation: this.reserv,
            selected: {
              number: this.reserv_number,
              option: this.selectedOption
            },
            transition: this.params.data.transition,
          }
        });
      },
      error: this.errResServ.doParse((_err, errContent) => {
        if (errContent.smartGotoErrCode === this.appMsgServ.SERV_CONST_CODE.SG_OPTION_PRICE.CALC_FAILED) {
          const errOption: {sg_option_id: number, param: "number"|"days"|"mins", table_limit: number} = errContent.smartGotoErrOpt;
          let optionName: string = void 0;
          this.reserv.service.option_groups.some(group => {
            const result = group.options.find(option => option.sg_option_id === errOption.sg_option_id);
            if (result === void 0) return false;
            optionName = result.name;
            return true;
          });
          this.errResServ.viewErrDialog(errContent, optionName);
        }
        else this.errResServ.viewErrDialog(errContent)
      })
    });
    
    // 確認画面へ遷移
    // this.navigator.element.pushPage(ExpChangeDatermineComponent, { data: { reserv: this.reserv, reserv_number: this.reserv_number, transition: this.params.data.transition } });
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * 遷移ボタンチェック
   *
   * @private
   * @return {*}  {void}
   * @memberof ExpChangeType1Component
   */
  private switchOverTransitionBtn(): void {
    const switchBtn = (isDisplay: boolean): void => {
      if (isDisplay === true) this.btn_trans_disabled = false;
      else this.btn_trans_disabled = true;
    }
  
    // 最大、最小人数エラー
    if (this.err_max_number === true || this.err_min_number === true) return switchBtn(false);

    // 制約エラーがなく、変更がない場合遷移ボタンを非活性
    const result = this.reserv_number.filter((n, index: number) => n.value !== this.reserv.numbers[index].value);
    if (result.length === 0 && this.putReservationOption.checkChangeOption() === false) return switchBtn(false);

    const optionCheckResult: boolean = Object.keys(this.selectedOption).some(id => {
      return this.selectedOption[id].time_err.check === true || this.selectedOption[id].err.check === true;
    });
    if (optionCheckResult === true) return switchBtn(false);

    switchBtn(true);
  }

  /**
   * 最大人数チェック
   *
   * @memberof ExpChangeComponent
   */
  private checkCapacity(): void {

    if (this.reserv.service.capacity === void 0) return;

    if (this.reserv.service.service_type === 'ACTIVITY') {
      const count: number = this.reserv_number.map(num => num.value).reduce((a, b) => a + b);

      const index = this.template.body1.findIndex(b => b.id === "reserv_number" + (this.reserv.service.user_params.numbers.length - 1));

      if (count > this.reserv.service.capacity) {
        this.err_max_number = true;

        // todo: 単位
        this.template.body1[index].valid_msg = this.reserv.service.capacity + this.reserv.numbers[0].unit + "以下で予約してください。";
      }
      else {
        this.err_max_number = false;
        this.template.body1[index].valid_msg = "";
      }  
    }
  }

  /**
   * テンプレートに表示する情報を作成する。
   *
   * @private
   * @memberof ExpChangeComponent
   */
  private createTemplateDate(): void {
    
    let currentTotalReservNum: number = 0;

    // 予約しているサービスの最大空き数を算出
    if (this.reserv.service.service_type === "MOBILITY") this.max_resources = this.reserv.service.user_params.numbers[0].select.slice(-1)[0];
    else if (this.reserv.service.service_type === "ACTIVITY") {

      // アクティビティの場合は、自身の予約数を含めずに全体の空き数が取得される
      this.max_resources = this.reserv.service.capacity;

      // 現予約数の合計数を算出
      currentTotalReservNum = this.reserv.numbers.map(n => n.value).reduce((a, b) => a + b);
      // console.log("currentTotalReservNum: ", currentTotalReservNum);
      // this.max_resources += currentTotalReservNum;
    }
    else ;

    // // サービスが保有しているリソース情報
    // const serv_resources = this.reserv.service.user_params.numbers.find(n => n.type === this.reserv.numbers[0].type);

    // 予約数、料金
    let viewPrice: string[] = [];
    const calcePrice = this.calcuPrice();
    
    
    // オプションテンプレート作成

    // 料金種別毎に料金、予約数を作成
    let numbers: ListParts["select_box"]["item"][] = [];
    this.reserv.service.user_params.numbers.forEach((n, index: number) => {

      // 初期化
      numbers.push([]);
      this.orgReservNumber.push([]);
      
      // 予約数のセレクトボックス作成
      n.select.forEach(s => {
        const value = { key: s, name: s.toString() + n.unit };
        // 最大空き数を超えたらなら終了
        if (s > n.to) return;

        // オリジナルに保存
        this.orgReservNumber[index].push(value);

        numbers[index].push(value);

        // if (s <= this.reserv.numbers[index].value + (this.max_resources - currentTotalReservNum)) numbers[index].push(value);
      });
      
      // 料金
      let price: string = "";
      if (this.reserv.numbers.length >= 2) price += n.name + "：";
      price += this.commonFunc.numericalForming(calcePrice[index]) + "/" + n.unit;

      viewPrice.push(price);
    });
    
    this.template = {
      header: {
        mode: "header", 
        header_list: {
          item: [this.reserv.service.title, "提供：" + this.reserv.service.shop.name],
          icon: this.reserv.service.images[0] ?? ""
        }
      }, 
      body1: [{
        displayOrder: 4, 
        header: '利用開始時刻',
        common: {
          item: dayjs(this.reserv.start.schd_time).format("M月D日 H:mm")
        }
      }, {
        displayOrder: 6, 
        header: '利用終了時刻',
        common: {
          item: dayjs(this.reserv.end.schd_time).format("M月D日 H:mm")
        }
      }],
      body2: [{
        id: "cancelRule", 
        header: 'キャンセル料ルール',
        common: { item: this.expServ.getDispCancelRules(this.reserv.service.prices[0].cancel_rules) }
      }, {
        header: '店舗連絡先',
        common: { item: this.reserv.service.shop.tel }
      }]
    };

    // 料金
    viewPrice.forEach((p, index: number) => {
      this.template.body1.push({
        displayOrder: 1, 
        id: "price", 
        common: { item: p } 
      });
      // ヘッダーは先頭のみ
      if (index === 0) {
        this.template.body1[this.template.body1.length - 1].header = "お支払い料金";
      
        // 料金種別が複数 & アクティビティサービスの場合、料金詳細説明(infoボタン)を表示
        if (this.reserv.numbers.length >= 2 && this.reserv.service.service_type === "ACTIVITY") {
          const diff = (new Date(this.reserv.end.schd_time).getTime() - new Date(this.reserv.start.schd_time).getTime()) / (60 * 1000);
          this.template.body1[this.template.body1.length - 1].header_dialog_info = this.expServ.getPriceDescription(this.reserv.service.user_params.numbers, this.reserv.service.prices[0], diff);
        }
      }
    });
    
    if (this.reserv.service.service_type === "MOBILITY") {
      this.template.body1.push({
        displayOrder: 3, 
        header: '貸出場所',
        common: {
          item: this.reserv.start.place.name,
          link: this.commonFunc.getGoogleMapUrl(this.reserv.start.place.location)
        }
      }, 
      {
        displayOrder: 5, 
        header: '返却場所',
        common: {
          item: this.reserv.end.place.name,
          link: this.commonFunc.getGoogleMapUrl(this.reserv.end.place.location)
        }
      },
      {
        displayOrder: 2, 
        id: "reserv_number", 
        mode: numbers[0].length > 1 ? "selectBox" : "common", 
        header: "予約数",
        select_box: {
          item: numbers[0], 
          select_id: "reserv_number", 
          initial_select: this.reserv.numbers[0].value
        }, 
        common: {
          item: numbers[0][0].name ?? "", 
        }
      });
    }
    else if (this.reserv.service.service_type === "ACTIVITY") {
      this.template.body1.push({
        displayOrder: 3, 
        header: '開催場所',
        common: {
          item: this.reserv.start.place.name,
          link: this.commonFunc.getGoogleMapUrl(this.reserv.start.place.location)
        }
      });
      
      numbers.forEach((r, index: number) => {
        this.template.body1.push({
          displayOrder: 2, 
          id: "reserv_number" + index,  
          mode: "selectBox", 
          select_box: {
            item: r, 
            select_id: "reserv_number" + index, 
            initial_select: this.reserv.numbers[index].value
          }
        });
        
        // 料金種別が複数ある場合のみ種別名を表示
        const length = this.reserv.numbers.length;
        if (length >= 2) this.template.body1[this.template.body1.length - 1].select_box.type_name = this.reserv.numbers[index].name;

          // todo　capacityの単位をどうだすか（10「人」の部分）
        if (index === 0) {
          this.template.body1[this.template.body1.length - 1].header = this.reserv.service.capacity ? "予約数" + (length > 1 ? "（最大：" + this.reserv.service.capacity + this.reserv.numbers[index].unit + "）" : "") : "予約数";
        }
      });
    }
    else ;

    // 表示順にソート
    this.template.body1.sort((a, b) => a.displayOrder - b.displayOrder);
  }
  
  /**
   * 最低料金の計算を行う。
   *
   * @private
   * @return {*}  {void}
   * @memberof ExpChangeComponent
   */
   private calcuPrice(): number[] {

    let prices: number[] = [];

    // 利用時間（分）を算出
    const diff = (new Date(this.reserv.end.schd_time).getTime() - new Date(this.reserv.start.schd_time).getTime()) / (60 * 1000);
    
    // 料金を算出
    this.reserv.numbers.forEach(n => {
      prices.push(this.expServ.getPrice({ price_rules: this.reserv.service.prices[0].price_rules, params: { number: 1, util_time: diff }, type: n.type }));
    });

    return prices;
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * 空き情報を取得する。
   *
   * @private
   * @memberof ExpChangeComponent
   */
  // private getAvaivable(): void {

  //   // 利用時間（分）を算出
  //   const diff = (new Date(this.reserv.end.schd_time).getTime() - new Date(this.reserv.start.schd_time).getTime()) / (60 * 1000);
    
  //   // 終了予定時刻をマイナス1秒
  //   let end = new Date(this.reserv.end.schd_time);
  //   end.setSeconds(end.getSeconds() - 1);

  //   // parameter
  //   const param: parameter.ExpAvailable = {
  //     sg_service_id : this.reserv.service.sg_service_id, 
  //     sg_reservation_id: this.reserv.service.service_type === "MOBILITY" ? undefined : this.reserv.sg_reservation_id, 
  //     date_from: this.reserv.start.schd_time, 
  //     date_to: this.reserv.start.schd_time, 
  //     util_time: diff
  //   };

  //   let method: Observable<HttpResponse<ExpResourceAvailable>> = undefined;
  //   if (this.reserv.service.service_type === "MOBILITY") method = this.expServ.getAvailable(param);
  //   else if (this.reserv.service.service_type === "ACTIVITY") method = this.expServ.getResourceAvailable(param);
  //   else ;

  //   // request
  //   this.busy = method.subscribe({ 
  //     next: res => {

  //       // サービス種別毎に空き数を取得
  //       if (this.reserv.service.service_type === "MOBILITY") {
  //         this.targetAvailable = res.body.available;
  //       }
  //       else if (this.reserv.service.service_type === "ACTIVITY") {

  //         const resources = res.body.resources;
  
  //         // リソースの内、空き数が最も多いものを選択
  //         if (resources.length > 1) {
  //           // 初期値として最初の空き数を設定
  //           this.targetAvailable = resources[0].availables;

  //           // const start = performance.now();
  //           // console.log("計測開始(for)");
  //           resources.forEach((r, index: number) => {
  //             if (index === 0) return;
              
  //             r.availables.forEach((a, i: number) => {
  //               if (resources[index - 1].availables[i].available_number < a.available_number) {
  //                 // console.log("より大きい空きリソースに差し替え：", this.targetAvailable[i], a);
  //                 this.targetAvailable[i] = this.commonFunc.deepcopy(a);
  //                 console.log(this.targetAvailable[i]);
  //               }
  //             })
  //           });
  //           // const end = performance.now();
  //           // console.log("計測終了(for):", end - start);
  //         }
  //         else if (resources.length === 1) this.targetAvailable = resources[0].availables;
  //       }
  //       // 上記以外のサービス種別以外は未対応
  //       else ;

  //       console.log(this.targetAvailable);

  //       // todo: 暫定対応
  //       // 現状レスポンスは指定の時刻の範囲ではなく、その日一日分が返る
  //       // サーバ対応までの間、クライアントで予約の日時の空き情報を抽出する
  //       // this.available.available = res.body.available.filter(b => new Date(b.from).getTime() === new Date(this.reserv.start.schd_time).getTime());
  //       // console.log("予約可能な空き情報：", JSON.parse(JSON.stringify(this.available)));
        
  //       // テンプレート情報作成
  //       this.createTemplateDate();
  //     },
  //     error: this.errResServ.doParse((_err, errContent) => this.errResServ.viewErrDialog(errContent))
  //   });
  // }
}
