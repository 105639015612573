import { NgModule, ModuleWithProviders } from '@angular/core';

import { UserAgentServiceConfig } from '../http-services/user-agent.service';

@NgModule()
export class UserAgentModule {
  static withConfig(config: UserAgentServiceConfig): ModuleWithProviders<UserAgentModule> {
    return {
      ngModule: UserAgentModule,
      providers: [
        { provide: UserAgentServiceConfig, useValue: config }
      ]
    };
  }
}
