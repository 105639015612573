//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { OnsNavigator, Params } from 'ngx-onsenui';
import * as moment from 'moment';
import * as CONST from "../../../constants/constant";

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service'
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service'
import { UserWebApiService } from '../../../http-services/user-web-api.service';
import { PurchaseWebApiService } from '../../../http-services/purchase-web-api.service';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';

// component
import { LoginComponent } from '../../user/login/login.component';

// interface
import { common } from 'src/app/interfaces/common';
import { parameter } from '../../../interfaces/parameter';
import { Settings, UserInfo } from '../../../interfaces/response';
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 請求詳細表示
 *
 * @export
 * @class PurchaseDetailsComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @author （株）オブジェクトデータ 和田
 */
@Component({
  selector: 'ons-page[purchase-details]',
  templateUrl: './purchase-details.component.html',
  styleUrls: ['./purchase-details.component.scss']
})
export class PurchaseDetailsComponent implements OnInit, OnDestroy {

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * クライアント設定情報
   *
   * @type {Settings}
   * @memberof PurchaseDetailsComponent
   */
  settings: Settings;

  /**
   * メールパラメータ：遷移情報
   *
   * @type {LoginComponent.Transition}
   * @memberof PurchaseDetailsComponent
   */
  transition: LoginComponent.Transition;
  
  /**
   * メールパラメータ：照会年月
   *
   * @type {string}
   * @memberof PurchaseDetailsComponent
   */
  payment_month: string;

  /**
   * 日付フォーマット用
   *
   * @memberof PurchaseDetailsComponent
   */
  moment = moment;

  readonly ASSETS = {
    INFORMATION: CommonFunctionModule.getAssetsUrl('/image/button/info.png')
  } as const;

  /**
   * 選択した照会年月
   *
   * @type {string}
   * @memberof PurchaseDetailsComponent
   */
  selectedValue: number;

  /**
   * 照会年月プルダウンリスト
   *
   * @type {common.selectBox[]}
   * @memberof PurchaseDetailsComponent
   */
  dateList: common.selectBox[] = [];

  /**
   * 請求明細のSubscriptionオブジェクト
   *
   * @private
   * @type {Subscription}
   * @memberof PurchaseDetailsComponent
   */
  busy: Subscription;

  /**
   * 購入情報
   *
   * @type {Purchase}
   * @memberof PurchaseDetailsComponent
   */
  purchase: {[method: string]: common.DispPurchase};

  /**
   * 表示中の購入情報
   *
   * @type {common.DispPurchase}
   * @memberof PurchaseDetailsComponent
   */
  dispPurchase: common.DispPurchase;
  
  /**
   * 現在の月index
   *
   * @type {number}
   * @memberof PurchaseDetailsComponent
   */
  currentMonthIndex: number;

  /**
   * 選択可能な請求先
   *
   * @type {string[]}
   * @memberof PurchaseDetailsComponent
   */
  paymentMethod: string[] = [];

  /**
   * 表示中の請求先
   *
   * @type {string}
   * @memberof PurchaseDetailsComponent
   */
  dispPaymentMethod: string = "";

  /**
   * 表示中の集計期間
   *
   * @type {{
   *     from: string,
   *     to: string
   *   }}
   * @memberof PurchaseDetailsComponent
   */
  dispPurchasePeriod: {
    from: string,
    to: string
  }

    /**
   * 酒類販売用表示フラグ
   *
   * @type {boolean}
   * @memberof PurchaseDetailsComponent
   */
    dispAlcoholPurchaseNotice: boolean = true;

  /**
   * OnDestroy時に破棄するSubscriptionオブジェクト
   *
   * @memberof PurchaseDetailsComponent
   */
  subscription = new Subscription();

  /**
   * 請求先
   *
   * @memberof PurchaseDetailsComponent
   */
  readonly PAYMENT_NETHOD = {
    CREDIT_CARD: "CARD",
    TRANSFER_FORM: "SLIP",
    YUCHO: "YUCHO",
    JABANK: "JABANK",
    BANK77: "BANK77",
    FAIL: "FAIL", // 運営より請求
    NONE: "NONE" // 元々0円購入物
  } as const;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of PurchaseDetailsComponent.
   * @param {ApplicationMessageService} appMsg
   * @param {HttpErrorResponseParserService} errResServ
   * @param {PurchaseWebApiService} purchaseServ
   * @param {OnsNavigator} _navigator
   * @param {Params} _params
   * @memberof PurchaseDetailsComponent
   */
  constructor(
    private appMsg: ApplicationMessageService, 
    private errResServ: HttpErrorResponseParserService, 
    private userServ: UserWebApiService, 
    private purchaseServ: PurchaseWebApiService, 
    private _navigator: OnsNavigator,
    private _params: Params,
    private municipalityWebApiServ: MunicipalityWebApiService,
    private msg: MESSAGE,
  ) { }

  /**
   * 初期化処理。
   *
   * @return {*}  {void}
   * @memberof PurchaseDetailsComponent
   */
  ngOnInit(): void {

    // これらはメールから飛んできた場合のパラメータ
    // 通常通りこのページに来た場合はundefined
    this.purchase = this._params.data.purchase;
    this.transition = this._params.data.transition;
    this.payment_month = this._params.data.payment_month;

    // メール遷移の場合はデータ取得済みのため終了
    if (this.purchase != undefined && this.transition != undefined && this.payment_month != undefined) {

      const settingsChanged = this.municipalityWebApiServ.settingsChanged.subscribe({
        next: setting => {
          if (setting == null) return;
          this.settings = setting;

          // 照会年月セット
          this.setDateList();

          // 整形した購入物および酒類販売に関する注意事項を表示するための初期処理
          this.init();
        }
      });
      this.subscription.add(settingsChanged);
    }
    // 通常遷移の場合
    else {
      this.getSettings();
    }

    // // 照会年月を作成
    // this.createDateList();

    // // 最新年月を選択
    // this.selectedValue = <string>this.dateList[0].value;

    // // 請求明細を取得
    // this.getPurchaseDetail();

    // カード請求失敗時のメッセージ
    document.getElementById("toastMsgDiv").innerHTML = this.msg.CLIENT.PURCHASE.PAYMENT_FAILED.message();
  }

  /**
   * 破棄処理。
   *
   * @memberof PurchaseDetailsComponent
   */
  ngOnDestroy(): void {
    
    this.busy?.unsubscribe();
    this.subscription?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 「今月」ボタンイベントハンドラ
   *    最新年月の明細を表示する。
   * @memberof PurchaseDetailsComponent
   */
  latestMonthView(): void {

    // 照会年月を作成
    // this.createDateList();

    // 選択中の年月が現在の年月だったら終了
    if (this.selectedValue == this.dateList[this.currentMonthIndex].value) return;

    // 現在の年月を選択
    this.selectedValue = <number>this.dateList[this.currentMonthIndex].value;

    // 請求明細を取得
    this.getPurchaseDetail();
  }

  /**
   * 「請求年月プルダウン」イベントハンドラ
   *    選択した請求年月の明細を表示する。
   * 
   * @memberof PurchaseDetailsComponent
   */
  changePurchase(): void {

    // 請求明細を取得
    this.getPurchaseDetail();
  }

  /**
   * 金額表示（￥マーク、カンマ付き）に成形する。
   *
   * @param {number} amount 金額
   * @return {*}  {string} 成形した金額文字列
   * @memberof PurchaseDetailsComponent
   */
  getDispAmount(amount: number): string { 

    return new Intl.NumberFormat("ja", { style: "currency", currency: "JPY" }).format(amount ?? 0);
  }

  /**
   * 日付を成形する。
   *
   * @param {string} date
   * @return {*}  {string}
   * @memberof PurchaseDetailsComponent
   */
  getDispDate(date: string): string {

    return date === null ? "" : moment(date).format('YYYY年M月D日');
  }

  /**
   * 支払い方法を成形する。
   *    クレジットの場合、マスクしたカード番号を表示する。
   * @return {*}  {string} カード番号またはコメント
   * @memberof PurchaseDetailsComponent
   */
  getDispMethod(method: string): string {

    if (!method) return;

    switch (method) {
      case method.includes(this.PAYMENT_NETHOD.CREDIT_CARD) ? method : "": {
        return this.purchase[method]?.purchase.card_no ?? "";
        // return PAYMENT_SLIP;
      }
      case this.PAYMENT_NETHOD.TRANSFER_FORM: {
        return "振替払込書";
      }
      case this.PAYMENT_NETHOD.YUCHO: {
        return "ゆうちょ口座";
      }
      case this.PAYMENT_NETHOD.BANK77: {
        return "七十七銀行,その他銀行口座(ゆうちょ除く)"
      }
      case this.PAYMENT_NETHOD.JABANK: {
        return "JAバンク"
      }
      case this.PAYMENT_NETHOD.FAIL: {
        return "運営より請求"
      }
      // case PAYMENT_NETHOD.ACCOUNT_TRANSFER: {
      //   return "";
      // }
      default:
        return "";
    }
  }

  /**
   * 請求先を切り替える。
   *
   * @param {string} method
   * @memberof PurchaseDetailsComponent
   */
  changePurchaseMethod(method: string): void {
    // 選択した請求先を表示する請求先としてセット
    this.dispPaymentMethod = method;
    this.setDispPurchase();
  }

  /**
   * 運営より請求の場合に表示するインフォメーションマークをクリック
   *
   * @memberof PurchaseDetailsComponent
   */
  onClickPopUpDialog(): void {
    // 「運営より引き落とし」の説明ダイアログ表示
    this.appMsg.viewDialogMessage(this.msg.CLIENT.PURCHASE.DESCRIPTION_FAIL.message());
  }

//=============================================================================================
// メソッド
//=============================================================================================

  // /**
  //  * メールから請求明細を表示した場合、バックボタンでウィンドウを閉じる。
  //  *
  //  * @memberof PurchaseDetailsComponent
  //  */
  // private backTrans(): void {

  //   if (this.transition != null) {
  //     switch (this.transition.method) {
  //       case 'popPage':
  //         this._navigator.element.popPage(this.transition.options);
  //         break;
  //     }
  //   }
  // }

  /**
   * 表示する情報をセットする。
   *
   * @private
   * @return {*}  {common.DispPurchase}
   * @memberof PurchaseDetailsComponent
   */
  private setDispPurchase(): void {

    // 表示する購入物情報をセット
    this.dispPurchase = this.purchase[this.dispPaymentMethod];
  
    // 期間を取得
    this.dispPurchasePeriod = this.settings.purchase.periods[this.selectedValue].period;
  }

  /**
   * 照会年月を作成
   * 初期表示として、選択される月を選択
   * @private
   * @memberof PurchaseDetailsComponent
   */
  private setDateList(): void {

    // マイページメニューから遷移時
    if (this.payment_month === undefined) {
      // 照会年月を作成
      this.createDateList();

      this.selectedValue = <number>this.dateList[this.currentMonthIndex].value;
    }
    // メール遷移時
    else {
      // 表示する月のindexを取得
      this.selectedValue = this.settings.purchase.periods.findIndex(p => p.month === this.payment_month);
      
      // 表示用に整形(〇〇〇〇年〇〇月)
      const d: Date = new Date(this.payment_month);
      this.payment_month = d.getFullYear() + "年" + (d.getMonth() + 1) + '月';
    }
  }

  /**
   * 照会年月プルダウンのリストを作成する。
   *
   * @memberof PurchaseDetailsComponent
   */
  private createDateList(): void {
    
    // 初期化
    this.dateList = [];
    // リスト作成
    this.settings.purchase.periods.forEach((element, index: number) => {

      const d: Date = new Date(element.month);
      // 設定情報を元に年月プルダウンリストを作成
      this.dateList.push( {
        value: index,
        label: d.getFullYear() + "年" + (d.getMonth() + 1) + '月'
      });

      // 現在の日付含まれている請求期間(period)のindex取得
      if (moment().isBetween(element.period.from, element.period.to, 'second', "[]")) {
        this.currentMonthIndex = index;
      }
    });
  }

  /**
   * 表示年月が有効範囲内かチェックする。
   * note: 取得範囲は過去1年以内
   *
   * @private
   * @return {*}  {boolean}
   * @memberof PurchaseDetailsComponent
   */
  private checkValidRange(): boolean {

    switch (this.purchaseServ.isValidRange(this.settings.purchase.periods[this.selectedValue].month)) {
      case false: {
        // 1年以上前
        this.appMsg.viewDialogMessage(this.msg.CLIENT.PURCHASE.E_DETAIL_RANGE.message(), () => {this.latestMonthView();});
        
        return false;
      }
      case "Invalid Date": {
        this.appMsg.viewDialogMessage(this.msg.CLIENT.PURCHASE.E_INVALID_RANGE.message(), () => this.latestMonthView());
        return false;
      }
      default: ;
    }
    return true;
  }

  /**
   * 請求明細情報を初期化
   *
   * @private
   * @memberof PurchaseDetailsComponent
   */
  private refresh() {
    this.purchase = {};
    this.dispPurchase = null;
    this.dispPaymentMethod = "";
    this.paymentMethod = [];
  }

  /**
   * 整形した購入物および酒類販売に関する注意事項を表示するための初期処理
   *
   * @private
   * @memberof PurchaseDetailsComponent
   */
  private init(): void {
    // 選択可能な請求先取得
    this.paymentMethod = Object.keys(this.purchase);

    const userPayment: UserInfo["payment"] = this.userServ.getUserInfo().payment;
    // デフォルト支払い方法として設定されている請求先名を取得
    const method = userPayment.method === 'CARD' ? userPayment.method + userPayment.card_seq : userPayment.method;

    // デフォルト支払い方法が購入物の中に存在する場合、初期表示とする
    if (this.userServ.getUserInfo().payment.method && this.paymentMethod.includes(method)) {
      this.dispPaymentMethod = method;
    }
    // 存在しない場合、purchase一つ目を初期表示とする
    else this.dispPaymentMethod = this.paymentMethod[0];

    // 表示する請求先の購入物、期間をセット
    this.setDispPurchase();

    // 酒類販売に関する注意事項表示設定
    this.dispAlcoholPurchaseNotice = this.settings.services.includes(CONST.Common.SERVICE_TAG.SHOPPING);
  }

//=============================================================================================
// サーバ処理
//=============================================================================================

  /**
   * クライアントの設定情報を取得後、請求明細表示。
   *
   * @private
   * @memberof PurchaseDetailsComponent
   */
  private getSettings(): void {

    this.busy = this.municipalityWebApiServ.getLatestSettings().subscribe({
      next: res => {
        this.settings = res.body;

        // 照会年月セット
        this.setDateList();

        // 請求明細を取得
        this.getPurchaseDetail();
      }, 
      error: this.errResServ.doParse((_err, errContent) => { this.errResServ.viewErrDialog(errContent); })
    });
  }

  /**
   * 請求明細を取得する。
   *
   * @private
   * @return {*}  {void}
   * @memberof PurchaseDetailsComponent
   */
  private getPurchaseDetail(): void {
    
    if (false === this.checkValidRange()) return;

    // 表示データ初期化
    this.refresh();

    // パラメータ作成
    const params: parameter.PossessionsTicket = {
      im: "payer",
      payment_month: this.settings.purchase.periods[this.selectedValue].month
    }

    // 指定年月の請求額を取得
    this.busy = this.purchaseServ.getPurchaseDetail(params).subscribe({
      next: result => {
        // データを整形
        this.purchase = this.purchaseServ.createPurchaseDetailData(result);

        // 整形データを表示するための初期処理
        this.init();
      },
      error: this.errResServ.doParse((_err, errContent) => this.errResServ.viewErrDialog(errContent))
    });
  }
}