//=============================================================================================
// インポート
//=============================================================================================
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ElementRef, ViewChild } from '@angular/core';
import { OnsNavigator, Params } from 'ngx-onsenui';
import { Subscription } from 'rxjs';
import * as moment from 'moment-timezone';

// service
import { ApplicationMessageService } from '../../../../lib-services/application-message.service';
import { DispatchWebApiService, DISPATCH_WEB_API_RESERVATION_TRANS_MAP } from '../../../../http-services/dispatch-web-api.service';
import { FamilyWebApiService } from '../../../../http-services/family-web-api.service';
import { CommonFunctionModule } from "../../../../lib-modules/common-function.module";

// component
import { ReservationCancelComponent } from '../../dispatch/reservation-cancel/reservation-cancel.component';
import { MovingPlanDetailComponent } from '../../dispatch/moving-plan-detail/moving-plan-detail.component';

// interface
import { Reservation } from '../../../../interfaces/response';
import { common } from '../../../../interfaces/common';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 予約情報詳細画面
 *
 * @export
 * @class HistoryDetailComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ons-page[history-detail]',
  templateUrl: './history-detail.component.html',
  styleUrls: ['./history-detail.component.css']
})

export class HistoryDetailComponent implements OnInit, AfterViewInit, OnDestroy {

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * 「<」ボタン
   *
   * @private
   * @type {ElementRef}
   * @memberof HistoryDetailComponent
   */
  @ViewChild('onsBackButton') private onsBackButton: ElementRef;

  /**
   * 非同期通信の受信オブジェクト
   *
   * @type {Subscription}
   * @memberof HistoryDetailComponent
   */
  busy: Subscription;
  
  /**
   * 予約情報
   *
   * @type {Reservation}
   * @memberof HistoryDetailComponent
   */
  reservation: Reservation;

  /**
   * 日付操作moment
   *
   * @memberof HistoryDetailComponent
   */
  moment = moment;

  /**
   * 利用者ごとの料金、利用チケット情報
   *
   * @type {common.customerBill}
   * @memberof SearchResultDetailComponent
   */
  customerBill: common.customerBill;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of HistoryDetailComponent.
   * @param {Map<string, Map<string, string>>} reservationTransMap
   * @param {ApplicationMessageService} appMsgServ
   * @param {UserWebApiService} userServ
   * @param {FamilyWebApiService} familyServ
   * @param {DispatchWebApiService} dispatchServ
   * @param {OnsNavigator} _navigator
   * @param {Params} _params
   * @memberof HistoryDetailComponent
   */
  constructor(
    @Inject(DISPATCH_WEB_API_RESERVATION_TRANS_MAP) public readonly reservationTransMap: Map<string, Map<string, string>>,
    private appMsgServ: ApplicationMessageService, 
    private familyServ: FamilyWebApiService, 
    private dispatchServ: DispatchWebApiService, 
    private commonFunc: CommonFunctionModule, 
    private _navigator: OnsNavigator,
    private _params: Params,
    private msg: MESSAGE,
  ) { }

  /**
   * 初期化処理。
   *
   * @memberof HistoryDetailComponent
   */
  ngOnInit(): void {

    this.reservation = this._params.data.reservation;
    this.customerBill = this._params.data.customerBill;
  }

  /**
   * HTML上の変数定義
   */
  ngAfterViewInit(): void {

    // バックボタン
    this.onsBackButton.nativeElement.onClick = () => {
      // 履歴再描画メソッド呼び出し
      if (undefined !== this._params.data.reload) this._navigator.element.popPage({ callback: () => { this._params.data.reload(); } });
      // メールからの遷移のため画面を閉じるのみ
      else this._navigator.element.popPage();
    };
  }

  /**
   * 破棄処理
   *
   * @memberof HistoryDetailComponent
   */
  ngOnDestroy(): void {

    this.busy?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 注文のステータスを表示する。
   *
   * @return {*}  {string}
   * @memberof getViewDeliveryResult
   */
  getViewStatus(): string {

    switch (this.reservation.status) {
      
      case 'reserved':
      case 'working':
        return "利用予定";
      case 'done':
      case 'unboarded':
        return "利用履歴";
      case 'canceled':
        return "キャンセル履歴";
      case 'aborted':
        return "運行中止履歴";
      default:
        return "ステータスエラー";
    }
  }

  /**
   * 注文結果がある場合、表示する。
   *
   * @return {*}  {string}
   * @memberof getViewDeliveryResult
   */
  getViewDeliveryResult(): string {

    switch (this.reservation.status) {

      case 'done':
      case 'unboarded':
        return "この予約は" + this.commonFunc.transformDateTime(this.reservation.finish_time, "YYYY/M/D") + "に利用完了しました。";
      case 'canceled':
        return "この予約は" + this.commonFunc.transformDateTime(this.reservation.cancel_time, "YYYY/M/D") + "にキャンセルされました。";
      case 'aborted':
        return "この予約はSmartGOTO側の都合により、運行中止となりました。​";
      default: ;
    }

    return "";
  }
  
  /**
   * 料金種別（文字列）を取得する。
   *
   * @param {string} property
   * @param {boolean} canceld
   * @return {*}  {string}
   * @memberof HistoryDetailComponent
   */
  getAmoutProperty(property: string, canceld: boolean): string {
    
    if (canceld === true) {
      property = 'canceled';

      // 払い戻し料金が0円の場合は表示しない
    //  if (passenger.price === 0)  return "";
    }

    return this.dispatchServ.getAmountPropertyString(property);
  }

  /**
   * キャンセル画面へ遷移する。
   *
   * @memberof HistoryDetailComponent
   */
  pushCancel(): void {

    // ファミリー管理者から制限されている場合は遷移不可
    this.busy = this.familyServ.getFamily().subscribe();
    if (this.familyServ.isLoginUserUtilization()) {
      this.appMsgServ.viewDialogMessage(this.msg.CLIENT.FAMILY.RESTRICTED_UTILIZATION.message());
      return;
    }

    // 現在時刻が有料キャンセル期限以降、またはステータスが利用前ではない場合
    const dNow = new Date();
    const dPaidLimit = new Date(this.reservation.sections.filter(s => s.trans == "smartgoto")[0].o.schd_time);
    if(dPaidLimit <= dNow || this.reservation.status != "reserved") {
      // キャンセル不可
      this.appMsgServ.viewDialogMessage(this.msg.CLIENT.DISPATCH.ELAPSED_RESERV_TIME.message());
      return;
    }
    
    this._navigator.element.pushPage(ReservationCancelComponent, {data: { reservation: this.reservation, transition: this._params.data.transition, customerBill: this.customerBill }});
  }

  /**
   * 移動プラン詳細画面へ遷移する。
   *
   * @memberof HistoryDetailComponent
   */
  pushPlan(): void {

    this._navigator.element.pushPage(MovingPlanDetailComponent,{data: { reservation: this.reservation }});
  }

  /**
   * 同乗者の氏名を表示する/ note: HTML上で使用
   *
   * @param {Reservation} reservation
   * @return {string}
   * @memberof HistoryDetailComponent
   */
  showPassengerNames(reservation: Reservation): string {
    
    return reservation.passenger_names.map(e => `${e.family_name} ${e.given_name}`).join(' 様、') + " 様";
  }

//=============================================================================================
// メソッド
//=============================================================================================

}