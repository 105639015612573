//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnDestroy, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { SwPush } from '@angular/service-worker';
import { Subscription, from } from 'rxjs';
import * as _ from 'lodash';

// service
import { ApplicationMessageService } from '../../lib-services/application-message.service';
import { HttpErrorResponseParserService, HttpCustomErrorContent } from '../../lib-services/http-error-response-parser.service';
import { UserAgentService } from '../../http-services/user-agent.service';
import { UserWebApiService } from '../../http-services/user-web-api.service';

// interface
import { UserInfo } from '../../interfaces/response';

// other
import { environment } from '../../../environments/environment';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 通知方法画面。
 *
 * @export
 * @class NotificationMethodComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ons-page[notification-method]',
  templateUrl: './notification-method.component.html',
  styleUrls: ['./notification-method.component.css']
})
export class NotificationMethodComponent implements OnInit, OnDestroy 
{

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * HTTP通信購読用
   *
   * @type {Subscription}
   * @memberof NotificationMethodComponent
   */
  busy: Subscription;

  /**
   * ラインID
   *
   * @type {string}
   * @memberof NotificationMethodComponent
   */
  lineBusinessId: string;

  /**
   * android携帯か
   *
   * @type {boolean}
   * @memberof NotificationMethodComponent
   */
  isAndroidPhone: boolean;

  /**
   * ユーザ情報
   *
   * @type {UserInfo}
   * @memberof NotificationMethodComponent
   */
  userInfo: UserInfo;

  /**
   * 支払い方法の設定状態
   *
   * @type {NotificationMethodComponent.NotificationMethod}
   * @memberof NotificationMethodComponent
   */
  method: NotificationMethodComponent.NotificationMethod = {
    push: false,
    mail: false,
    line: false
  };

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of NotificationMethodComponent.
   * @param {SwPush} swPush
   * @param {ApplicationMessageService} appMsg
   * @param {UserWebApiService} userWebApiService
   * @param {PushNotificationWebApiService} pushNotificationWebApiService
   * @param {UserAgentService} userAgentService
   * @param {HttpErrorResponseParserService} httpErrorResponseParserService
   * @memberof NotificationMethodComponent
   */
  constructor(
    private swPush: SwPush, 
    private appMsg: ApplicationMessageService, 
    private httpErrorResponseParserService: HttpErrorResponseParserService, 
    private userAgentService: UserAgentService, 
    private userWebApiService: UserWebApiService, 
    private msg: MESSAGE,
  ) { }

  /**
   * 初期化処理。
   *
   * @memberof NotificationMethodComponent
   */
  ngOnInit(): void 
  {
    this.isAndroidPhone = this.userAgentService.isAndroidPhone;
    this.lineBusinessId = environment.sns.line.id;
  }

  /**
   * 描画後の初期化処理。
   *
   * @memberof NotificationMethodComponent
   */
  ngAfterViewInit(): void 
  {
    this.userWebApiService.getLatestUserInfo().subscribe({
      next: userInfo => 
      {
        setTimeout(() => 
        {
          this.userInfo = userInfo.body;

          // 通知方法を特定
          if (this.userInfo?.notify?.method) this.method[this.userInfo.notify.method] = true;

          // 通知方法未設定エラーの表示
          switch (this.userInfo.auth) 
          {
            case 'local': 
            // case 'google': 
            {
              if (false === this.method['mail'] && false === this.method['push']) this.appMsg.viewDialogMessage(this.msg.CLIENT.NOTIFY.E_NOT_NOTIFY.message());
              break;
            }
            // case 'line': 
            // {
            //   if (false === this.method['mail'] && false === this.method['line'] && false === this.method['push']) this.appMsg.viewClientMsg(this.appMsg.CLIENT_CODE.NOTIFY.E_NOT_NOTIFY);

            //   if (true === this.method['line'] && 
            //       !this.userInfo.notify?.line) this.appMsg.viewClientMsg(this.appMsg.CLIENT_CODE.NOTIFY.E_NOT_NOTIFY_LINE);
            //   break;
            // }
            default: 
              break;
          }
        }, 0);
      }
    });
  }

  /**
   * 破棄処理。
   *
   * @memberof NotificationMethodComponent
   */
  ngOnDestroy(): void 
  {
    this.busy?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 通知方法変更ボタン押下時のイベントハンドラ。
   *
   * @param {string} methodName
   * @param {boolean} status
   * @return {*}  {void}
   * @memberof NotificationMethodComponent
   */
  public changeNoticeMethod(methodName: UserInfo["notify"]["method"], status: boolean): void 
  {
    // SW OFF > ON
    if (status) 
    {
      for (const property in this.method) 
      {
        if (property !== methodName) this.method[property] = false;
      }

      // mail通知かつ、メールが未登録
      if (methodName === this.nameof<NotificationMethodComponent.NotificationMethod>('mail') && undefined === this.userInfo.profile.mail) 
      {
        this.appMsg.viewDialogMessage(this.msg.CLIENT.NOTIFY.E_EMPTY_MAIL.message());
        setTimeout(() => this.toggleRefresh(), 300);
        return;
      }

      // push(android)通知
      // if (methodName === this.nameof<NotificationMethodComponent.NotificationMethod>('push')) 
      // {
      //   this.subscribeToNotifications();
      //   return;
      // }

      // 変更処理
      this.updateUserInfo(methodName);
    }
    // SW ON > OFF
    else 
    {
      // SW毎にOFFにはできない（別SW ONによりOFFとなる）
      setTimeout(() => { this.method[methodName] = true; }, 300);
      this.appMsg.viewDialogMessage(this.msg.CLIENT.NOTIFY.E_NOT_SELECTED.message());
    }
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * push通知(android)の設定を行う。
   *
   * @private
   * @memberof NotificationMethodComponent
   */
  // private subscribeToNotifications() 
  // {
  //   const errorAction = this.httpErrorResponseParserService.doParse((_err: HttpErrorResponse, errContent: HttpCustomErrorContent) => 
  //   {
  //     this.httpErrorResponseParserService.viewErrDialog(errContent, this.appMsg.CLIENT_CODE.NOTIFY.E_DEFAULT);
  //     this.toggleRefresh();
  //   });

  //   this.busy = this.pushNotificationWebApiService.key().subscribe({
  //     next: e => 
  //     {
  //       this.busy = from(this.swPush.requestSubscription({ serverPublicKey: e.body.key })).subscribe(
  //         {
  //           next: pushSubscription => 
  //           {
  //             this.busy = this.pushNotificationWebApiService.regist(pushSubscription).subscribe(
  //               {
  //                 next: () => this.updateUserInfo(this.nameof<NotificationMethodComponent.NotificationMethod>('push')),
  //                 error: errorAction
  //               });
  //           },
  //           error: (error: Error) => 
  //           {
  //             this.appMsg.viewClientMsg(error.name === 'NotAllowedError' ? this.appMsg.CLIENT_CODE.NOTIFY.E_NOT_ALLOWED : this.appMsg.CLIENT_CODE.NOTIFY.E_DEFAULT);
  //             this.toggleRefresh();
  //           }
  //         }
  //       );
  //     },
  //     error: errorAction
  //   });
  // }

  /**
   * ユーザの通知方法を更新する。
   *
   * @private
   * @param {UserInfo["notify"]["method"]} methodName
   * @param {boolean} status
   * @memberof NotificationMethodComponent
   */
  private updateUserInfo(methodName: UserInfo["notify"]["method"]) 
  {
    const errorAction = this.httpErrorResponseParserService.doParse((_err: HttpErrorResponse, errContent: HttpCustomErrorContent) => 
    {
      this.httpErrorResponseParserService.viewErrDialog(errContent);
      this.toggleRefresh();
    });

    // 通知方法更新
    this.busy = this.userWebApiService.changeNotify({ method: methodName }).subscribe(
      {
        next: () => 
        {
          // ユーザ情報最新化
          this.busy = this.userWebApiService.getLatestUserInfo().subscribe({
            next: response => 
            {
              this.appMsg.viewDialogMessage(this.msg.CLIENT.NOTIFY.COMPLETE_UPDATE.message());

              this.userInfo = response.body;
              this.toggleRefresh();
            },
            error: errorAction
          });
        },
        error: errorAction
      }
    );
  }

  /**
   * 通知方法名が一致するか。
   *
   * @template T
   * @param {keyof T} name
   */
  private nameof = <T>(name: keyof T) => name;

  /**
   * 通知方法SWを初期化する。
   *
   * @private
   * @memberof NotificationMethodComponent
   */
  private toggleRefresh(): void 
  {
    Object.keys(this.method).forEach(key => this.method[key] = this.userInfo.notify.method === key);
  }
}

export namespace NotificationMethodComponent 
{
  /**
   * 支払い方法種類
   *
   * @export
   * @interface NotificationMeｄthod
   */
  export interface NotificationMethod 
  {
    /**
     * push(android)
     *
     * @type {boolean}
     * @memberof NotificationMeｄthod
     */
    push: boolean;

    /**
     * mail
     *
     * @type {boolean}
     * @memberof NotificationMeｄthod
     */
    mail: boolean;

    /**
     * line
     *
     * @type {boolean}
     * @memberof NotificationMeｄthod
     */
    line: boolean;
  }
}
