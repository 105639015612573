//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { OnsNavigator, Params } from 'ngx-onsenui';
import * as moment from 'moment';
import * as CONST from '../../../constants/constant';
import * as PURCHASE from '../purchase';

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { PurchaseWebApiService } from '../../../http-services/purchase-web-api.service';
import { DispatchWebApiService } from '../../../http-services/dispatch-web-api.service';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';

// module
import { CommonFunctionModule } from "../../../lib-modules/common-function.module";

// component
import { PurchaseTicketConfirmComponent } from '../purchase-ticket-confirm/purchase-ticket-confirm.component';

// interface
import { common } from '../../../interfaces/common';
import { parameter } from '../../../interfaces/parameter';
import { ExUser, TicketCatalog, Reservation } from '../../../interfaces/response';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 乗車券新規購入画面
 *
 * @export
 * @class PurchaseTicketNewComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @author （株）オブジェクトデータ wada
 */
@Component({
  selector: 'ons-page[purchase-ticket-new]',
  templateUrl: './purchase-ticket-new.component.html',
  styleUrls: ['./purchase-ticket-new.component.css']
})
export class PurchaseTicketNewComponent implements OnInit, OnDestroy {

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * 乗車券を購入する利用者情報
   *
   * @type {ExUser}
   * @memberof PurchaseTicketNewComponent
   */
  purchaseUser: ExUser;

  /**
   * 乗車券の名称（ページタイトル用）
   *    定額乗車券/数日乗り放題券
   *
   * @type {string}
   * @memberof PurchaseTicketComponent
   */
  ticketTypeName: string = CONST.Purchase.TICKET_TITLE_CITIZEN;
  
  /**
   * 予約（1回券）情報
   *
   * @type {number[][]}
   * @memberof PurchaseTicketNewComponent
   */
  reservOneTicket: common.ReservTicket[] = [];

  // -----------------------
  // 購入対象乗車券
  // -----------------------

  /**
   * サーバーから取得した購入可能可能な乗車券リスト
   *
   * @type {TicketCatalog[]}
   * @memberof PurchaseTicketNewComponent
   */
  buyableTicketList: TicketCatalog[] = [];

  /**
   * 種類毎の乗車券リスト
   *    添え字：種類、中身：乗車券情報、利用エリア情報
   *
   * @type {object}
   * @memberof PurchaseTicketNewComponent
   */
  ticketByCategory: object = {};

  /**
   * 購入対象のチケットID
   *    バインド時にテンプレートを経由すると
   *    文字列扱いになる
   *
   * @type {string}
   * @memberof PurchaseTicketNewComponent
   */
  purchaseTargetId: string = "0";

  /**
   * 購入対象のチケット情報
   *    purchaseTargetIdを使用して取得する
   *
   * @type {TicketCatalog}
   * @memberof PurchaseTicketNewComponent
   */
  purchaseTargetTicket: TicketCatalog;

  // -----------------------
  // 利用開始日
  // -----------------------

  /**
   * 利用開始日の一覧
   *
   * @type {common.selectBox[]}
   * @memberof PurchaseTicketNewComponent
   */
  startDateList: common.selectBox[] = [];

  /**
   * 選択した利用開始日
   *
   * @type {string}
   * @memberof PurchaseTicketNewComponent
   */
  selectedStartDate: string;
  
  // -----------------------
  // 種類
  // 定額乗車券（小/中・高）、
  // 数日乗り放題券（子供/大人）
  // -----------------------

  /**
   * 乗車券の種類
   *
   * @type {string[]}
   * @memberof PurchaseTicketNewComponent
   */
  category: string[] = [];

  /**
   * 選択中の乗車券の種類
   *
   * @type {string}
   * @memberof PurchaseTicketNewComponent
   */
  selectedTicketType: string;

  /**
   * 住人（観光目的でない）ユーザか
   *
   * @type {boolean}
   * @memberof PurchaseTicketNewComponent
   */
  userCitizen: boolean = true;

  /**
   * 自動更新切り替えスイッチの状態
   *
   * @type {boolean}
   * @memberof PurchaseTicketNewComponent
   */
  continueSwitchState: boolean;

  /**
   * 新規購入可能か否か
   *
   * @type {boolean}
   * @memberof PurchaseTicketComponent
   */
  purchasable: boolean = true;

  /**
   * 利用できない期間があるか
   *
   * @type {boolean}
   * @memberof PurchaseTicketNewComponent
   */
  userPeriod: boolean = false;

  /**
   * 利用期間短縮時にHTMLに表示するメッセージ
   *
   * @type {string}
   * @memberof PurchaseTicketNewComponent
   */
  periodShortMsg: string;
  
  /**
   * 購読用サブスクリプション
   *
   * @type {Subscription}
   * @memberof PurchaseTicketNewComponent
   */
  busy: Subscription;

  /**
   * 日付フォーマット用
   *
   * @memberof PurchaseTicketNewComponent
   */
  moment = moment;
  
  /**
   * 自動更新切り替えスイッチの排他フラグ
   *
   * @type {boolean}
   * @memberof PurchaseTicketConfirmComponent
   */
  exclusion: boolean = false;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of PurchaseTicketNewComponent.
   * @param {OnsNavigator} _navigator
   * @param {Params} _params
   * @param {ApplicationMessageService} appMsgServ
   * @param {CommonFunctionModule} commonFunction
   * @param {PurchaseWebApiService} purchaseServ
   * @param {DispatchWebApiService} dispatchServ
   * @param {HttpErrorResponseParserService} errResServ
   * @memberof PurchaseTicketNewComponent
   */
  constructor(
    private _navigator: OnsNavigator, 
    private _params: Params, 
    private appMsgServ: ApplicationMessageService,  
    private commonFunction: CommonFunctionModule,  
    private purchaseServ: PurchaseWebApiService, 
    private dispatchServ: DispatchWebApiService, 
    private errResServ: HttpErrorResponseParserService,
    private municipalityWebApiServ: MunicipalityWebApiService,
    private msg: MESSAGE,
  ) { }

  /**
   * 初期化処理。
   *
   * @memberof PurchaseTicketNewComponent
   */
  ngOnInit(): void {

    // 初期化
    this.initContorol();
    this.initMessage();
    
    // パラメータ取得
    this.purchaseUser = this._params.data.userProfile;
    this.userCitizen = this.purchaseUser.user_type === "citizen" ? true : false;  // 住人（観光目的でない）ユーザか

    // 利用開始日一覧を作成(今日を含めるため＋１)
    this.startDateList = this.commonFunction.createDateList(this.municipalityWebApiServ.setting.ticket.limit + 1);

    // 利用開始日は本日を選択
    this.selectedStartDate = <string>this.startDateList[0].value;

    // 購入可能な乗車券を取得
    // 利用開始日からの予約（1回券）も取得し、
    // 購入対象乗車券との重複チェックを行う
    // 観光目的でない（住人）ユーザ：有効期間と利用地区の重複チェック
    // 観光目的ユーザ：有効期間の重複チェック
    this.getTicketCatalog();
  }

  /**
   * 破棄処理。
   *
   * @memberof PurchaseTicketNewComponent
   */
  ngOnDestroy(): void {

    // 破棄
    this.busy?.unsubscribe();
  }
  
//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 利用開始日変更時のイベントハンドラ。
   *
   * @memberof PurchaseTicketNewComponent
   */
  changeStartDate(): void {

    // 初期化
    this.initContorol();
    this.initMessage();

    // 利用開始日から使用できる乗車券を取得
    this.getTicketCatalog();
  }
  
  /**
   * プラン変更時のイベントハンドラ。
   *
   * @memberof PurchaseTicketNewComponent
   */
  changePlan(): void {

    // 初期化
    this.initContorol();
    this.initMessage();
    
    // 初期選択のチケットIDを取得
    this.setInitialArea();

    // 購入対象の乗車券情報を設定
    this.setPurchaseTargetTicket();
  }
  
  /**
   * 利用地区変更時のイベントハンドラ。
   *
   * @memberof PurchaseTicketNewComponent
   */
  changeArea(): void {

    // 初期化
    this.initContorol();
    this.initMessage();

    // 購入対象の乗車券情報を設定
    this.setPurchaseTargetTicket();
  }
  
  /**
   * 自動更新設定スイッチ変更時のイベントハンドラ。
   *
   * @param {boolean} status
   * @memberof PurchaseTicketNewComponent
   */
  changeContinuous(): void {

    this.exclusion = true;

    // 初期化
    this.initMessage();

    // OFF → ON
    if (true === this.continueSwitchState) {
      // 自動更新不可乗車券
      // 自動更新後の全日が利用不可、免許返納者乗車券
      if (false === this.purchaseTargetTicket.continuable) {
        let msg: string = "";

        // 免許返納者は自動更新SW非表示のため対応不要
        if (this.purchaseTargetTicket.terms.special_type === 'handicapped') 
          msg = this.msg.CLIENT.PURCHASE.TERM_EXPIRATION.message();
        else {
          // if (new Date(this.purchaseTargetTicket.start_date).getMonth() === 2)
          //   msg = this.appMsgServ.getCLientMsg(this.appMsgServ.CLIENT_CODE.PURCHASE.AFTER_GRADUATION);
          // else 
          msg = this.msg.CLIENT.PURCHASE.CONTINUE_DISABLE.message();
        }

        // 自動更新OFF
        this.appMsgServ.viewDialogMessage(msg, () => setTimeout(() => {
          this.continueSwitchState = false;
          this.exclusion = false;
        }, 0));
  
        return;
      }

      // 自動更新後の乗車券を作成
      const next: TicketCatalog = this.purchaseServ.createNextTicket(this.purchaseTargetTicket) as TicketCatalog;
      
      // 自動更新後の乗車券の購入可否をチェック
      let checkResult = this.purchaseServ.checkInvalidPeriodAndReserv(next, this.reservOneTicket);

      // ユーザ通知メッセージ
      let result = this.getPurchasableMessage(next, checkResult, true);

      const initial = () => {
        // 初期化
        this.initMessage(); 
        this.continueSwitchState = false;
        this.exclusion = false;
      };

      const viewTmpMsg = () => {
        if (result.tempMsg.length > 0) this.userPeriod = true;
        this.periodShortMsg = result.tempMsg.join();
        this.exclusion = false;
      };

      if (false !== result.confirm) {
        // 確認ダイアログ表示
        this.appMsgServ.confirmMessage(result.dlgMsg[0], value => { 
          // キャンセルなら自動更新OFF
          if (Number(value) !== CONST.Common.CONFIRM_YES) initial();
          else {
            if (undefined !== result.dlgMsg[1]) {
              this.appMsgServ.viewDialogMessage(result.dlgMsg[1], () => viewTmpMsg());
            }
            else viewTmpMsg();
          }
        });
      }
      else {
        if (result.dlgMsg.length > 0) {
          this.appMsgServ.viewDialogMessage(result.dlgMsg[0]);
        }
        else viewTmpMsg();
      }
    }
    // ON → OFF 
    else this.exclusion = false; 
  }

  /**
   * 購入へ進むボタン押下時のイベントハンドラ。
   *
   * @memberof PurchaseTicketNewComponent
   */
  moveBuyNewTicket(): void {

    // 乗車券の購入可否をチェック
    const checkResult: number[] = this.purchaseServ.checkInvalidPeriodAndReserv(this.purchaseTargetTicket, this.reservOneTicket);

    // ユーザ通知メッセージ
    let result = this.getPurchasableMessage(this.purchaseTargetTicket, checkResult);

    if (result.tempMsg.length === 0 && result.dlgMsg.length === 0) this.transition();
    else {
      if (true === result.confirm) {
        // 確認ダイアログ表示
        this.appMsgServ.confirmMessage(result.dlgMsg[0], value => { 
          // OKなら確認画面へ
          if (Number(value) === CONST.Common.CONFIRM_YES) {
            if (undefined !== result.dlgMsg[1]) {
              this.appMsgServ.viewDialogMessage(result.dlgMsg[1], () => setTimeout(() => { this.transition(); }, 0));
            }
            else setTimeout(() => { this.transition(); }, 0);
          }
        });
      }
      else {
        this.appMsgServ.viewDialogMessage(result.dlgMsg[0], () => setTimeout(() => { this.transition(); }, 0));
      }
    }
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * 購入可否メッセージを取得する。
   *
   * @private
   * @param {TicketCatalog} target
   * @param {number[]} checkResult
   * @param {boolean} [isContinue=false]
   * @return {*}  {{ tempMsg: string[], dlgMsg: string[], confirm: boolean }}
   * @memberof PurchaseTicketNewComponent
   */
  private getPurchasableMessage(target: TicketCatalog, checkResult: number[], isContinue: boolean = false): { tempMsg: string[], dlgMsg: string[], confirm: boolean } {

    let templatViewMsg: string[] = [];
    let dialogViewMsg: string[] = [];
    let confirm: boolean = false;

    // メッセージコードを取得
    checkResult.forEach(result => {
      let tmpMsg: string = "";
      let dlgMsg: string = "";
      
      switch (result) {
        case PURCHASE.PURCHASE_STATUS.OK: return;
        case PURCHASE.PURCHASE_STATUS.NG: {
          if (this.purchaseTargetTicket.terms.special_type === "handicapped") 
            dlgMsg = this.msg.CLIENT.PURCHASE.TERM_EXPIRATION.message();
          else 
            dlgMsg = this.msg.CLIENT.PURCHASE.CONTINUE_DISABLE.message();
          break;
        }
        case PURCHASE.PURCHASE_STATUS.OK_CAUTION: {
          let from = moment(target.invalid.from).format("M/D");

          true === isContinue ? tmpMsg = this.appMsgServ.viewDialogMessage(this.msg.CLIENT.PURCHASE.CONTINUE_IMPOSSIBLE.message(`${ from }以降`, this.purchaseTargetTicket.name)) 

                              : tmpMsg = this.msg.CLIENT.PURCHASE.IMPOSSIBLE.message(`${ from }以降`, this.purchaseTargetTicket.name);
          true === isContinue ? dlgMsg = this.msg.CLIENT.PURCHASE.CONTINUE_IMPOSSIBLE_CONFIRM.message(`${ from }以降`, this.purchaseTargetTicket.name)

                              : dlgMsg = this.msg.CLIENT.PURCHASE.IMPOSSIBLE_CONFIRM.message(`${ from }以降`, this.purchaseTargetTicket.name);
          
          confirm = true;
          break;
        }
        case PURCHASE.PURCHASE_STATUS.OK_RESERV_CANCEL: {
          true === isContinue ? dlgMsg = this.msg.CLIENT.PURCHASE.CONTINUE_CANCEL_RESERVE.message()

                              : dlgMsg = this.msg.CLIENT.PURCHASE.CANCEL_RESERVE.message();          
          break;
        }
        case PURCHASE.PURCHASE_STATUS.E_UNEXPECTED:
        default:
          return;
      }
      
      if (tmpMsg !== "") templatViewMsg.push(tmpMsg);
      if (dlgMsg !== "") dialogViewMsg.push(dlgMsg);
    });

    return { tempMsg: templatViewMsg, dlgMsg: dialogViewMsg, confirm: confirm };
  }

  /**
   * 購入確認画面編遷移する。
   *
   * @private
   * @memberof PurchaseTicketNewComponent
   */
  private transition(): void {

    // 乗車券購入確認画面へ遷移する
    this._navigator.element.pushPage(PurchaseTicketConfirmComponent,{
      data: { 
        user: this.purchaseUser,
        ticket: this.purchaseTargetTicket,
        continuable: this.continueSwitchState, 
        returnPage: this._params.data.returnPage
      }
    });
  }
  
  /**
   * コントロールの状態を初期化する。
   *
   * @memberof PurchaseTicketNewComponent
   */
  private initContorol(): void {

    // コントロールの状態を初期化
    this.continueSwitchState = false;
    this.purchasable = true;
  }

  /**
   * メッセージを初期化する。
   *
   * @memberof PurchaseTicketNewComponent
   */
  private initMessage(): void {

    this.periodShortMsg = "";
    this.userPeriod = false;
  }
  
  /**
   * バス停一覧のPDFファイルを表示する。
   *
   * @memberof PurchaseTicketOwnedComponent
   */
  viewBusStopMap(): void {

    // 乗降可能なバス停一覧を表示
    // window.open('../../assets/pdf/' + CONST.Purchase.TICKET_BUSSTOP_MAP,'_blank');
    // TODO:結合テスト向けにcoming soon表示に変更
    this.appMsgServ.viewDialogMessage(CONST.Common.COMING_SOON);
  }

  /**
   * 購入可能乗車券取得後、利用エリアの初期選択を行う。
   *    recommend=trueが初期選択エリア。
   *    観光目的でない（住人）ユーザのみ。
   *
   * @memberof PurchaseTicketNewComponent
   */
  setInitialArea(): void {

    try {
      // エリア情報が存在しない場合、初期選択のチケットIDを選択
      if (!this.ticketByCategory[this.selectedTicketType].areas) {
        this.purchaseTargetId = this.ticketByCategory[this.selectedTicketType].ticket.ticket_catalog_id;
        return;
      }

      // recommendがtrueのエリアが存在した場合、recommendエリアの一つ目を初期選択
      const isExistRecommend: boolean = this.ticketByCategory[this.selectedTicketType].areas.some((area) => {
        if (area.recommend === true) {
          this.purchaseTargetId = area.ticket_catalog_id;
          return true;
        }
      });
      // recommendがtrueのエリアが存在しない場合、エリアの一つ目を初期選択
      if (isExistRecommend === false) {
        this.purchaseTargetId = this.ticketByCategory[this.selectedTicketType].areas[0].ticket_catalog_id;
        return;
      }
    }
    catch {
      // 免許返納者は利用地区なし
      this.purchaseTargetId = this.ticketByCategory[this.selectedTicketType].ticket.ticket_catalog_id;
    }
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * 予約一覧を取得する。
   *
   * @private
   * @param {string} start_date
   * @param {string} end_date
   * @memberof PurchaseTicketOwnedComponent
   */
  private getReservations(start_date: string, end_date: string): void {
    
    // パラメータ作成（購入対象乗車券の利用範囲内の予約を取得）
    const param: parameter.UserReservations = {
      passenger_user_id: this.purchaseUser.user_id, 
      begin_date: moment(start_date).format("YYYY-MM-DD"), 
      end_date: moment(end_date).format("YYYY-MM-DD"), 
      status: ['reserved']
    };

    // 予約一覧を取得
    this.busy = this.dispatchServ.userReservations<Reservation[]>(param).subscribe({
      next: (response: { body: Reservation[]; }) => {
        let customerBills: common.customerBill[] = [];
        this.reservOneTicket = [];

        for (let reserv of response.body) {
          let now = new Date();
          let reservStart = new Date(reserv.o.schd_time);

          if (true === this.commonFunction.compareDate(now, reservStart)) {
            if (now.getTime() >= reservStart.getTime()) continue;
          }
          
          customerBills.push(this.dispatchServ.getCustomersBill(reserv));
        }

        // 購入ユーザの予約から1回券を取得
        this.reservOneTicket = this.dispatchServ.getReservByUser(customerBills, this.purchaseUser.user_id, 1);
      }, 
      error: this.errResServ.doParse((_err, errContent) => {
        this.errResServ.viewErrDialog(errContent);
      })
    });
  }

  /**
   * 購入可能なチケットを取得する。
   *
   * @memberof PurchaseTicketNewComponent
   */
  private getTicketCatalog(): void {

    // 利用開始日から使用できる乗車券を取得
    this.busy = this.purchaseServ.getTicketList(this.purchaseUser.user_id, this.selectedStartDate).subscribe({
      next: (response: { body: TicketCatalog[]; }) => {
        // 購入可能乗車券一覧の取得
        this.buyableTicketList = response.body;

        // 購入可能な乗車券がない場合
        if (this.buyableTicketList.length == 0) return this.purchasable = false;

        // 画面表示用の乗車券情報を作成
        this.createPurchaseTicketCitizen();
        // if (this.userCitizen) this.createPurchaseTicketCitizen();
        // else this.createPurchaseTicketTourist();
        
        // 購入対象の乗車券情報を設定
        this.setPurchaseTargetTicket();

        // 自動更新を考慮し利用終了日＋1ヶ月を取得
        // 1回券との重複チェック時は通常乗車券の利用範囲でチェックするため、チェック対象外となる
        let end = new Date(this.purchaseTargetTicket.end_date);
        if (true === this.userCitizen) end.setMonth(end.getMonth() + 1);
        else end.setDate(end.getDate() + 6);  // 初期選択は1日乗車券のため、最大1週間分の予約を取得

        // 自動更新後期間にinvalid/fromがあるなら、再設定
        // 複数の乗車券が購入対象でそれぞれにinvalid/fromがある場合は成立しない
        // 現仕様では学生（中・高）/定額乗車券のみが該当し、初期選択の学生（中・高）のみにinvalid/from
        // が含まれるため成立することに注意
        if (true === this.userCitizen && this.purchaseTargetTicket.next_ticket !== undefined) {
          if (this.purchaseTargetTicket.next_ticket.invalid !== undefined) 
            end = new Date(this.purchaseTargetTicket.next_ticket.invalid.from);
        }
        
        // 購入ユーザの予約（1回券）を取得
        this.getReservations(this.purchaseTargetTicket.start_date, end.toISOString());
      },
      error: this.errResServ.doParse((_err, errContent) => {
        this.appMsgServ.viewDialogMessage(this.msg.CLIENT.PURCHASE.E_GET_TICKET_LIST.message());
        this.purchasable = false;
      })
    });
  }

//=============================================================================================
// 乗車券情報作成
//=============================================================================================

  /**
   * テンプレート表示用の乗車券情報を作成する。
   *    観光目的ユーザでない（住人）向け
   *
   * @memberof PurchaseTicketNewComponent
   */
  private createPurchaseTicketCitizen(): void {

    let price: number = undefined;
    this.ticketByCategory = [];
    
    // 表示用連想配列の生成
    this.buyableTicketList.forEach(t => {
      // 乗車券名を設定
      this.purchaseServ.setTicketTypeName(t, this.purchaseUser.user_type);

      // 最も安い乗車券を初期選択
      if (undefined === price) {
        price = t.price;
        this.selectedTicketType = t.ticket_type_name;
      }
      else {
        if (price > t.price) {
          price = t.price;
          this.selectedTicketType = t.ticket_type_name;
        }
      }

      // エリアを設定
      let area = undefined;
      if (t.terms?.special_type !== 'give_up_license' && t.area && t.area.area_ids && t.area.area_ids.length !== 0) {
        area = { ticket_catalog_id: String(t.ticket_catalog_id), name: t.area.name, recommend: t.recommend };
      }

      // 乗車券名で連想配列が未設定なら新規登録、設定済みならエリア情報のみ追加
      // 例：ticketByCategory["定額乗車券"] = {   // 学生定額乗車券（中・高）、学生定額乗車券（小）
      //        areas: {
      //          ticket_catalog_id: number;
      //          name: string;　例：（大人料金）
      //          recommend: boolean
      //        }[], 
      //        ticket: TicketCatalog;
      //     }
      if (undefined === this.ticketByCategory[t.ticket_type_name]) this.ticketByCategory[t.ticket_type_name] = { ticket: t/* , areas: area !== void 0 ? new Array(area) : void 0 */ };

      // エリア情報が存在する場合追加
      if (area !== void 0) {
        if (undefined === this.ticketByCategory[t.ticket_type_name].areas) this.ticketByCategory[t.ticket_type_name].areas = new Array(area);
        else this.ticketByCategory[t.ticket_type_name].areas.push(area);
      }
    });

    // 初期選択のチケットIDを取得
    this.setInitialArea();

    // 種類一覧を作成
    this.category = Object.keys(this.ticketByCategory);
  }

  /**
   * テンプレート表示用の乗車券情報を作成する。
   *    観光目的ユーザ向け
   *
   * @private
   * @memberof PurchaseTicketNewComponent
   */
  // private createPurchaseTicketTourist(): void {

  //   this.ticketByCategory = [];
    
  //   // 料金の安い順に並び替え
  //   this.purchaseServ.sortTicketPrice(this.buyableTicketList);

  //   // 表示用連想配列の生成
  //   this.buyableTicketList.forEach(t => {
  //       // 乗車券名を設定
  //     this.purchaseServ.setTicketTypeName(t, this.purchaseUser.user_type);

  //     // 乗車券名で連想配列を生成
  //     this.ticketByCategory[t.name] = { ticket: t, areas: undefined };
  //   });

  //   // 種類一覧を作成
  //   this.category = Object.keys(this.ticketByCategory);

  //   // 初期選択のチケットIDを取得
  //   this.purchaseTargetId = this.ticketByCategory[this.category[0]].ticket.ticket_catalog_id;

  //   // 初期選択する乗車券の種類（1日乗車券）
  //   this.selectedTicketType = this.category[0];
  // }

  /**
   * 購入対象の乗車券情報を設定する。
   *
   * @private
   * @memberof PurchaseTicketNewComponent
   */
  private setPurchaseTargetTicket(): void {

    // 購入対象の乗車券情報
    this.purchaseTargetTicket = this.buyableTicketList.find(t => t.ticket_catalog_id === Number(this.purchaseTargetId));
  }
}