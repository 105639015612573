<ons-toolbar>
  <div class="left ">
    <ons-back-button></ons-back-button>
  </div>
  <div class="center">{{ targetKind.name }}の検索</div>
</ons-toolbar>

<div class="content">
  <div [ngBusy]="[busy, busyBusstop]"></div>
  <div class="textbox-wrap">
    <div class="select-spot-text">
      <div class="select-spot-name">
        <img class="select-spot-icon" [src]="ASSETS_SPOT_ACTIVE" alt="" />{{ targetSpotName }}
      </div>
    </div>
    <div class="button-wrap">
      <ons-button id="place-map_001" modifier="large--cta" [disabled]="!selectedSpot || !targetSpotName" (click)="decide()">
        {{ targetKind.name }}に設定
      </ons-button>
    </div>
  </div>

  <input id="place-map_002" #pacInput class="pac-input" type="text" placeholder="検索" />
  <button id="place-map_003" #clearButton class="clear-button" (click)="clearFreeText()">x</button>

  <google-map 
    id="map"
    height="100%"
    width="100%"
    [zoom]="zoom"
    [center]="center"
    [options]="mapOptions"
    (mapClick)="mapClick()"
    (zoomChanged)="onZoomChanged()"
    class="map-height--max"
  >
</google-map>
</div> 