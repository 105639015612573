<!-- 画像が登録されている -->
<ng-container *ngIf="images.length">
  <div class="carousel-area">
    <div class="carousel" id="carouselViewerImages">
      <owl-carousel-o [options]="carouselOwlOptions" #owlCar>
        <ng-container *ngFor="let image of images">
          <ng-template carouselSlide [id]="image">
            <img [style.maxHeight.px]="carouselMaxHeight" [src]="image" (click)="onEnlargeImage()">
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
    <div class="carousel-thumbs">
      <owl-carousel-o [options]="thumbsOwlOptions">
        <ng-container *ngFor="let image of images; let i = index">
          <ng-template carouselSlide [id]="image+i" [width]="80">
            <a (click)="owlCar.to(image); changeCarouselImage(image+i)">
              <img [src]="image"
                  [ngClass]="{'carousel-item--active':selectedCarouselImage === image+i || (i == 0 && !selectedCarouselImage)}">
            </a>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </div>
  </div>
</ng-container>
<!-- 画像が登録されていない -->
<ng-container *ngIf="images.length == 0">
  <div class="image--background image-center">
    <img [src]="noImage">
  </div>
</ng-container>