<ons-toolbar>
  <div class="left">
    <ons-back-button></ons-back-button>
  </div>
  <div class="center">ファミリー機能とは？</div>
</ons-toolbar>
<div class="content">
  <ons-list>
    <ons-list-item modifier="longdivider" style="font-size: 0;">
      <div class="content--list--description">
        ご家族のアカウントと「ファミリー」として繋がる(リンクする)ことで、自身が管理者となり、ご家族のおでかけサービス利用の代行予約やご家族とのおでかけサービスの乗合予約を都度認証なしで行ったり、自身のお支払いでご家族がサービスを利用できるようになります。<br>
        （※本ファミリー機能はおでかけサービスのみで有効であり、その他のサービス（買い物・観光等）では利用できません。）
      </div>
    </ons-list-item>
    <ons-list-item modifier="longdivider" expandable>
      <div class="list-item__title">ファミリー管理者の権限</div>
      <div class="expandable-content content-border--top">
        <br>
        <div class="content-itemization">
          <ol>
            <li><b><span>&#9312;</span> ご家族のアカウントへファミリーリンクの申請が可能</b></li>
          </ol>
        </div>
        <div class="content--description">
          <p>
            管理者が相手のQRコードを読み取り本人確認を行った後、相手にファミリーリンクの申請が送られます。<br>
            相手が申請を承認すると、相手がファミリーとして追加されます。
          </p>
        </div>
        <div class="content-itemization">
          <ol>
            <li><b><span>&#9313;</span> 都度認証不要で、ファミリーメンバーの代理予約やファミリーメンバーとの乗合予約が可能</b></li>
          </ol>
        </div>
        <div class="content--description">
          <p>
            通常、自分以外を含めて配車予約を行う場合は、予約の都度QRコードによる本人確認が必要となりますが、ファミリーメンバーの場合は都度認証不要で予約が可能となります。
          </p>
        </div>
        <div class="content-itemization">
          <ol>
            <li><b><span>&#9314;</span> ファミリーメンバーがサービス予約を行った場合、管理者へ内容が通知される</b></li>
          </ol>
        </div>
        <div class="content--description">
          <p>
            ファミリーメンバーが予約を行った場合やチケットを購入した場合、管理者へ通知されます。<br>
            もし利用を中止させたい場合は、管理者が予約のキャンセルを行うことも可能です。
          </p>
        </div>
        <div class="content-itemization">
          <ol>
            <li><b><span>&#9315;</span> ファミリーメンバーがサービス利用する際の各種チケット支払いは管理者が行う</b></li>
          </ol>
        </div>
        <div class="content--description">
          <p>
            これにより、チケットを購入できないアカウントでもサービスを利用することができます。
          </p>
        </div>
        <!-- <div class="content-itemization">
          <ol>
            <li><b><span>&#9316;</span> ファミリーメンバーのサービス利用制限を行うことができる</b></li>
          </ol>
        </div>
        <div class="content--description">
          <p>
            利用制限がかかったメンバーは、メンバー自身の操作によってサービスの予約や各種チケットの購入を行うことができません。また、管理者以外に代理予約を行ってもらうこともできません。<br>
            利用制限中もログインや無料サービス利用は可能です。<br>
            制限解除は管理者の許可により可能となります。
          </p>
        </div> -->
      </div>
    </ons-list-item>
    <ons-list-item modifier="longdivider" expandable>
      <div class="list-item__title">ファミリーメンバー(被管理者)の権限</div>
      <div class="expandable-content content--list">
        <br>
        <div class="content-itemization">
          <ol>
            <li><b><span>&#9312;</span> 管理者からのファミリーリンク申請を承認することで、自身が管理者のファミリーメンバーとなる</b></li>
          </ol>
        </div>
        <div class="content--description">
          <p>
            管理者に設定できるのは一人のみとなります。
          </p>
        </div>
        <div class="content-itemization">
          <ol>
            <li><b><span>&#9313;</span> 管理者の支払いで、サービス利用を行うことが可能</b></li>
          </ol>
        </div>
        <div class="content--description">
          <p>
            ファミリーメンバーが予約を行った場合、管理者に通知されます。<br>
            管理者が予約キャンセルを行った場合は、ファミリーメンバーはサービス利用することができません。
          </p>
        </div>
        <!-- <div class="content-itemization">
          <ol>
            <li><b><span>&#9314;</span> 管理者によってサービス利用停止になった場合は、SmartGOTOの有料サービスの利用が不可</b></li>
          </ol>
        </div>
        <div class="content--description">
          <p>
            ログインや無料サービスの利用は可能です。<br>
            また、サービス利用停止中でも、管理者からサービスの予約や各種チケットの購入を行ってもらうことは可能です。
          </p>
        </div> -->
      </div>
    </ons-list-item>
    <ons-list-item modifier="longdivider" expandable>
      <div class="list-item__title">ファミリーリンクの作り方</div>
      <div class="expandable-content content--list">
        <br>
        <div class="content-itemization">
          <b>管理者がご家族のアカウントを招待する</b>
        </div>
        <div class="content--description">
          <p>
            管理者が相手のQRコードを読み取り本人確認を行った後、相手にファミリーリンクの申請が送られます。<br>
            相手が申請を承認すると、相手がファミリーとして追加されます。<br>
            管理者に設定できるのは一人のみとなります。
          </p>
        </div>
        <div class="content-itemization">
          <b>見守り対象アカウントを代理作成する</b>
        </div>
        <div class="content--description">
          <p>
            ご自身でアカウントを作成できないご家族に代わり、見守り対象となるアカウントを作成することができます。<br>
            見守り対象アカウントに関する詳しい情報は
            <ons-button id="family-description_001" modifier="quiet" (click)="viewProxyDescription()"><span id="link" class="button-text">こちら</span></ons-button>をご覧ください。
          </p>
        </div>
      </div>
    </ons-list-item>
  </ons-list>
</div>