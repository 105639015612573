//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit } from '@angular/core';
import { Params, OnsNavigator } from 'ngx-onsenui';

import { SigninComponent, SigninService } from '../../signin/signin.component';
import { UserWebApiService } from '../../../http-services/user-web-api.service';
import { PagerService } from '../../../lib-services/pager.service';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * パスワード再設定
 *    メール送信完了/パスワード再設定完了画面
 *
 * @export
 * @class ChangePasswordCompleteComponent
 * @implements {OnInit}
 * @author （株）オブジェクトデータ wada
 */
@Component({
  selector: 'ons-page[change-password-complete]',
  templateUrl: './change-password-complete.component.html',
  styleUrls: ['./change-password-complete.component.css']
})
export class ChangePasswordCompleteComponent implements OnInit {

  //=============================================================================================
  // プロパティ定義
  //=============================================================================================

  /**
   * メールアドレス
   *
   * @type {string}
   * @memberof ChangePasswordComponent
   */
  m_strMailAddress: string = null;

  /**
   * 通常遷移/メールリンク遷移の判定
   *
   * @type {boolean}
   * @memberof ChangePasswordCompleteComponent
   */
  m_bMailTransition:boolean = false;

  //=============================================================================================
  // ライフサイクルメソッド
  //=============================================================================================

  /**
   * Creates an instance of ChangePasswordCompleteComponent.
   * @param {OnsNavigator} _navigator
   * @param {UserWebApiService} userWebApiService
   * @param {PagerService} pagerServ
   * @param {Params} params
   * @memberof ChangePasswordCompleteComponent
   */
  constructor(
    private _navigator: OnsNavigator,
    private userWebApiService: UserWebApiService,
    private pagerServ: PagerService, 
    private signinServ: SigninService,
    private params: Params
    ) { }

  /**
   * 初期化処理
   *
   * @memberof ChangePasswordCompleteComponent
   */
  ngOnInit(): void {

    if (!this.params?.data?.mailAddress ?? false) {
      this.m_bMailTransition = true;
    }
    else {
      this.m_bMailTransition = false;
      this.m_strMailAddress = this.params.data.mailAddress;
    }
  }

  //=============================================================================================
  // イベントハンドラ
  //=============================================================================================

  /**
   * ホームへボタンタップ時のイベントハンドラ。
   *    SmartGOTOトップ画面へ遷移。
   *
   * @memberof ChangePasswordCompleteComponent
   */
  transition(): void {
    
    // pushページをリセット
    if (this.m_bMailTransition) {
      // 履歴を上書き
      let url = window.location.href.replace(window.location.search, "");
      history.replaceState(null, null, url);
      
      // トップページへリダイレクト
      window.open(url, '_self');
    }
    else {
      // 利用するタブから遷移している場合はサインイン画面へ戻る
      if (this.pagerServ.getUserAppNavigator) this.signinServ.trans(true);
      else this._navigator.element.replacePage(SigninComponent);
    }
  }
}
