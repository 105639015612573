// ========================================================================================================================
// 各種インポート
// ========================================================================================================================

import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { OnsNavigator, Params } from 'ngx-onsenui';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import * as CONST from '../../../constants/constant';

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { UserWebApiService } from '../../../http-services/user-web-api.service';
import { PagerService } from 'src/app/lib-services/pager.service';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';

// component
import { ProfileSignupDetermineComponent } from '../profile-signup-determine/profile-signup-determine.component';
import { ProfileSignupLocationEditComponent } from '../profile-signup-location-edit/profile-signup-location-edit.component';

// interface
import { UserInfo } from '../../../interfaces/response';
import { SigninService } from '../../signin/signin.component';

// module
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';
import { MESSAGE } from 'src/app/constants/message';

// ========================================================================================================================
// クラス定義
// ========================================================================================================================

@Component({
  selector: 'ons-page[account-list]',
  templateUrl: './profile-signup.component.html',
  styleUrls: ['./profile-signup.component.css']
})
export class ProfileSignupComponent implements OnInit, OnDestroy {

  // ================================================================================
  // 変数定義
  // ================================================================================

  /**
   * 非同期処理監視Subscription
   *
   * @type {Subscription}
   * @memberof ProfileSignupComponent
   */
  busy: Subscription;

  /**
   * メールアドレス認証/SNS認証どちらによる登録か
   * trueならメールアドレス認証
   *
   * @type {Boolean}
   * @memberof ProfileSignupComponent
   */
  viewStep?: Boolean;

  /**
   *　緯度経度登録画面をスキップする
   *
   * @type {boolean}
   * @memberof ProfileSignupComponent
   */
   isSkipLatLngRegistrationScreen: boolean = false;
  
  /**
   * ユーザー情報
   * 次の確認画面への引継ぎ情報
   * @type {UserInfo}
   * @memberof ProfileSignupComponent
   */
  userInfo: UserInfo;

  /**
   * 住民かどうか
   * falseの場合は観光目的
   * @type {boolean}
   * @memberof ProfileSignupComponent
   */
  citizen: boolean;

  /**
   * 生年
   *
   * @type {number}
   * @memberof ProfileSignupComponent
   */
  userBirthYear: number;

  /**
   * 生月
   *
   * @type {number}
   * @memberof ProfileSignupComponent
   */
  userBirthMonth: number;

  /**
   * 生日
   *
   * @type {number}
   * @memberof ProfileSignupComponent
   */
  userBirthDay: number;

  /**
   * 性別の連想配列
   *
   * @memberof ProfileSignupComponent
   */
  SEX = [
    { value: 0, label: CONST.Common.SEX_NO_ANSWER },
    { value: 1, label: CONST.Common.SEX_MALE },
    { value: 2, label: CONST.Common.SEX_FEMALE }
  ];

  /**
   * 年セレクトリスト(画面要素用)
   *
   * @memberof ProfileSignupComponent
   */
  years = new Array;

  /**
   * 月セレクトリスト(画面要素用)
   *
   * @memberof ProfileSignupComponent
   */
  months = new Array(12).fill(0).map((x, i) => i);

  /**
   * 日セレクトリスト(画面要素用)
   *
   * @memberof ProfileSignupComponent
   */
  days = new Array(31).fill(0).map((x, i) => i + 1);
  
  /**
   * ユーザタイプ切り替え可能フラグ
   *
   * @type {boolean}
   * @memberof ProfileSignupComponent
   */
  canChangeUserType: boolean;

  /**
   * 画面で選択されている生年月日が存在する日付かどうか
   * 例：2/30は実在しない
   * @type {boolean}
   * @memberof ProfileSignupComponent
   */
  isDateValid: boolean = true;

  /**
   * 画面で選択されている生年月日が未来の日付かどうか
   *
   * @type {boolean}
   * @memberof ProfileSignupComponent
   */
  isFutureDate: boolean = false;
  
  /**
   * 画面に表示する住所に関するメッセージ
   *
   * @memberof ProfileSignupComponent
   */
  zipEmptyMessage = "";

  /**
   * 住所自動入力完了フラグ
   *
   * @memberof ProfileSignupComponent
   */
  addressAutoCompleted = false;

  /**
   * GoogleMap Geocoder
   *
   * @private
   * @type {google.maps.Geocoder}
   * @memberof ProfileSignupComponent
   */
  private geocoder: google.maps.Geocoder;

  /**
   * 編集前住所(見守り対象アカウント初回ログイン時のみ)
   * NOTE: 編集した場合編集後住所と比較し、新たにgoogleMapAPIを使用する必要があるのか判断するため
   *
   * @type {UserInfo}
   * @memberof ProfileSignupComponent
   */
  beforeEditUserInfo: UserInfo;

  /**
   * 仮設定用の緯度経度
   * 提供サービスが観光のみの場合に使用
   *
   * @type {google.maps.LatLng}
   * @memberof ProfileSignupComponent
   */
  dummy_latlng: google.maps.LatLng;

  /**
   * 仮設定用の緯度経度
   * 提供サービスが観光のみの場合に使用
   *
   * @type {google.maps.LatLngLiteral}
   * @memberof ProfileSignupComponent
   */
  dummy_location: google.maps.LatLngLiteral;

  // ================================================================================
  // ライフサイクルメソッド定義
  // ================================================================================

  constructor(
    private _navigator: OnsNavigator, 
    private appMsg: ApplicationMessageService, 
    private changeDetectorRef: ChangeDetectorRef, 
    private userWebApiService: UserWebApiService, 
    private params: Params, 
    private pagerServ: PagerService, 
    private signinServ: SigninService,
    private commonFunctionMdl: CommonFunctionModule,
    private municipalityWebApiServ: MunicipalityWebApiService,
    private msg: MESSAGE,
  ) {
    //NOTE: SNS認証がない場合、使われていない為、使うようになった際に見直しが必要
    [this.viewStep, this.userInfo] = this.params.data;

    // 生年月日セレクトリストに1900年から現在までの年を代入
    for(var year = 1900; year <= new Date().getFullYear(); year++) {
      this.years.push(year);
    }
  }

  ngOnInit(): void {

    // メールアドレス認証/SNS認証どちらによる登録かを判定
    // 引数での指定がない場合はページスタック数で判断
    this.viewStep = this.viewStep ?? this._navigator.element.pages.length > 2;

    if(this.userInfo == null) {
      this.userInfo = this.userWebApiService.getUserInfo();
      this.userInfo.profile.sex = this.SEX.find(e => e.value === 0).value;
    }

    this.userBirthYear = this.userInfo.profile.birth.year;
    this.userBirthMonth = this.userInfo.profile.birth.month - 1; // JavaScript の month は 0 ～ 11
    this.userBirthDay = this.userInfo.profile.birth.day;
    // this.citizen = this.userInfo.profile.set_citizen == null ? true : this.userInfo.profile.set_citizen;
    // 提供サービスが観光サービスのみの場合、観光目的の利用の初期表示は「はい」
    this.citizen = !this.municipalityWebApiServ.setting.isProvidedExpServiceOnly;
    this.canChangeUserType = true;

    // 観光目的初期化
    let user_citizen: HTMLInputElement = <HTMLInputElement>document.getElementsByName("user_citizen")[0];
    let user_tourist: HTMLInputElement = <HTMLInputElement>document.getElementsByName("user_tourist")[0];

    user_citizen.checked = this.citizen;
    user_tourist.checked = !this.citizen;
  }

  ngAfterViewInit(): void {

    this.geocoder = new google.maps.Geocoder();
  }
  
  ngOnDestroy(): void {

    this.busy?.unsubscribe();
  }

  // ================================================================================
  // イベントハンドラ
  // ================================================================================

  /**
   * 新規会員登録を中止。
   *
   * @memberof ProfileSignupComponent
   */
  cancel(): void {

    this.appMsg.confirmMessage(this.msg.CLIENT.PROFILE.COMFIRM_CANCEL_REGISTRATION.message(), (value) => {
      // Yes回答だったら登録中止
      if (Number(value) === CONST.Common.CONFIRM_YES) {
        this.busy = this.userWebApiService.signinCancel().subscribe({
          next: () => {},
          error: () => { location.reload(); }
        });

        // 利用するタブから遷移している場合はサインイン画面へ戻る
        if (this.pagerServ.getUserAppNavigator) this.signinServ.trans(true);
      }
    });
  }

  /**
   * 観光目的かどうかのフォーム選択状態をクラス変数に保存。
   *
   * @param {boolean} citizen
   * @memberof ProfileSignupComponent
   */
  toggle(citizen: boolean): void {

    let user_citizen: HTMLInputElement = <HTMLInputElement>document.getElementsByName("user_citizen")[0];
    let user_tourist: HTMLInputElement = <HTMLInputElement>document.getElementsByName("user_tourist")[0];

    this.citizen = citizen;

    user_citizen.checked = citizen;
    user_tourist.checked = !citizen;
  }

  /**
   * 郵便番号を元にGoogleMapから住所を取得しフォームに反映。
   *
   * @memberof ProfileSignupComponent
   */
  addressAutocomplete(): void {

    this.zipEmptyMessageClear();

    this.geocoder.geocode({address: `${ this.userInfo.profile?.address?.zip?? "" }`}, (results, status) => {

      // 住所を取得出来なかった場合、エラーメッセージをフォーム上に表示
      if ((status !== google.maps.GeocoderStatus.OK) || !results[0]) {
        this.zipEmptyMessage = this.msg.CLIENT.PROFILE.INVALID_ZIP.message();
        return;
      }
        
      const addressComponents = results[0].address_components;

      const getLongName = (type: string): string => addressComponents.find(e => e.types.some(x => x === type))?.long_name?? '';

      this.userInfo.profile.address.prefecture = getLongName('administrative_area_level_1');
      this.userInfo.profile.address.city = `${ getLongName('locality') }${ getLongName('sublocality_level_1') }`;
      this.userInfo.profile.address.address1 = `${ getLongName('sublocality_level_2') }${ getLongName('sublocality_level_3') }`;
      this.userInfo.profile.address.address2 = getLongName('sublocality_level_4');
      this.userInfo.profile.address.address3 = getLongName('premise');

      // 住所自動入力完了フラグON
      this.addressAutoCompleted = true;

      this.changeDetectorRef.detectChanges();
    });
  }
  
  /**
   * 入力確認画面へ遷移。
   *
   * @memberof ProfileSignupComponent
   */
  pushSignupLocation(): void {

    // userInfoにフォーム入力内容を代入
    var date = moment({ year: this.userBirthYear, month: this.userBirthMonth, day: this.userBirthDay });
    this.userInfo.profile.birth.year = parseInt(moment(date).format('YYYY'));
    this.userInfo.profile.birth.month = parseInt(moment(date).format('MM'));
    this.userInfo.profile.birth.day = parseInt(moment(date).format('DD'));

    // 住所編集したかどうか
    let isEdit: boolean;

    // 見守り対象アカウント初回ログインの場合
    if (this.beforeEditUserInfo) isEdit = this.userWebApiService.checkChangeAddress(this.beforeEditUserInfo.profile.address, this.userInfo.profile.address);
    else isEdit = true;

    // 遷移先の画面
    let transitionDestination;
    
    // 観光ユーザならスキップ、住民ならスキップせず緯度経度の登録を行う
    this.isSkipLatLngRegistrationScreen = !this.citizen;

    // 緯度経度画面のスキップ処理
    if (this.isSkipLatLngRegistrationScreen) {
      this.setDummyLocation();
      transitionDestination = ProfileSignupDetermineComponent;
    }else{
      transitionDestination = ProfileSignupLocationEditComponent;
    }

    this._navigator.element.pushPage(transitionDestination, { data: {viewStep: this.viewStep, userInfo: this.userInfo, isEdit: isEdit, citizen: this.citizen} });

  }

  /**
   * 仮の緯度経度を設定
   * 観光サービスのみ提供している場合、緯度経度登録画面をスキップするのでここで緯度経度の仮設定を行う
   *
   * @memberof ProfileSignupComponent
   */
  setDummyLocation(): void {
    // 各自治体の役場の緯度経度を仮値として設定
    var dummy_location = this.municipalityWebApiServ.getDummyLocation();
    // 仮の位置情報(緯度経度)を設定
    const latitude = dummy_location.lat;
    const longitude = dummy_location.lng;

    this.dummy_latlng = new google.maps.LatLng(latitude, longitude);

    // LatLngオブジェクトからLatLngLiteralオブジェクトへ変換
    this.dummy_location = {
      lat: this.dummy_latlng.lat(),
      lng: this.dummy_latlng.lng()
    };

    // 仮の位置情報を代入
    this.userInfo.profile.address.location = this.dummy_location;
  }

  // ================================================================================
  // 関数定義イベントハンドラ
  // ================================================================================

  /**
   * 代理アカウントの情報を引き継ぐ
   *
   * @memberof ProfileSignupComponent
   */
  setProxyUserInfo(user: UserInfo): void {

    // 上書き
    this.userInfo = JSON.parse(JSON.stringify(user));
    this.userBirthYear = this.userInfo.profile.birth.year;
    this.userBirthMonth = this.userInfo.profile.birth.month - 1;
    this.userBirthDay = this.userInfo.profile.birth.day;

    // 代理アカウントに観光目的かどうかが設定されている場合
    if (this.userInfo.user_type !== void 0) {
      // user_type切り替え不可(引き継いだユーザーのuser_typeを使用するため)
      this.canChangeUserType = false;
      // 住民 or 観光
      this.citizen = this.userInfo.user_type === 'citizen' ? true : false;

      // 観光目的かどうかの選択状態変更
      this.toggle(this.citizen);
    }

    this.beforeEditUserInfo = this.commonFunctionMdl.deepcopy(user);
  }

  /**
   * 生年月日の妥当性をチェックする。
   *
   * @return {*}  {boolean}
   * @memberof ProfileSignupComponent
   */
  canEdit(): boolean {

    // 存在しない日付かチェックする
    this.isDateValid = moment({ year: this.userBirthYear, month: this.userBirthMonth, day: this.userBirthDay }).isValid();

    // 未来の日付かチェックする
    let birth = new Date(this.userBirthYear, this.userBirthMonth, this.userBirthDay);
    let now = new Date();

    if(now < birth) {
      // 生年月日が現在日付より未来の場合は、フラグON
      this.isFutureDate = true;
    }
    else {
      // 上記以外はフラグOFF
      this.isFutureDate = false;
    }

    return this.isDateValid || this.isFutureDate;
  }
  
  /**
   * 郵便番号エラーメッセージを初期化。
   *
   * @memberof ProfileSignupComponent
   */
  zipEmptyMessageClear(): void {

    this.zipEmptyMessage = "";
  }

}