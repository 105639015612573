<ons-toolbar class="toolbar">
    <div class="left">
        <ons-back-button></ons-back-button>
    </div>
    <div class="center">通知方法</div>
</ons-toolbar>
<div class="content">
    <div [ngBusy]="busy"></div>
    <p class="content--text">予約時のお迎えや新着イベントに<br />関する通知方法を指定できます。
    </p>
    <ons-list *ngIf="userInfo" class="list-wrap">
        <ons-list-header class="content--title list-header--color">通知方法</ons-list-header>
        <!-- <ons-list-item *ngIf="isAndroidPhone" modifier="longdivider noPadding">
            <div class="center">
                ブラウザ通知(androidのみ)
            </div>
            <div class="right">
                <ons-switch [(ngModel)]="method.push" (change)="changeNoticeMethod('push', $event.value)"
                    modifier="mainColor"></ons-switch>
            </div>
        </ons-list-item> -->
        <ons-list-item modifier="longdivider noPadding">
            <div class="center">
                登録アドレスへメール送付
            </div>
            <div class="right">
                <ons-switch [(ngModel)]="method.mail" (change)="changeNoticeMethod('mail', $event.value)"
                    modifier="mainColor"></ons-switch>
            </div>
        </ons-list-item>
        <ons-list-item *ngIf="userInfo.auth === 'line'" modifier="longdivider noPadding noBorderBottom">
            <div class="center">
                LINEアカウントから通知
            </div>
            <div class="right">
                <ons-switch [(ngModel)]="method.line" (change)="changeNoticeMethod('line', $event.value)"
                    modifier="mainColor"></ons-switch>
            </div>
        </ons-list-item>
        <ons-list-item *ngIf="userInfo.auth === 'line' && !userInfo.notify.line" class="list-item--line" modifier="longdivider noPadding">
            <p class="text--line">
                SmartGOTOと友達になって各種各種情報を受け取ろう！
            </p>
            <ons-button modifier="large--cta cta--line">
                <a [href]="'https://line.me/R/ti/p/' + lineBusinessId" target="_blank" rel="noopener"
                    class="list-item__link">LINEでSmartGOTOと友達になる</a>
            </ons-button>
        </ons-list-item>
        <!-- <ons-list-item modifier="longdivider noPadding"> -->
        <!-- <div class="center"> -->
        <!-- Twitterアカウントから通知 -->
        <!-- </div> -->
        <!-- <div class="right"> -->
        <!-- <ons-switch modifier="mainColor"></ons-switch> -->
        <!-- </div> -->
        <!-- </ons-list-item> -->
        <!-- <ons-list-item modifier="longdivider noPadding"> -->
        <!-- <div class="center"> -->
        <!-- Facebookアカウントから通知 -->
        <!-- </div> -->
        <!-- <div class="right"> -->
        <!-- <ons-switch modifier="mainColor"></ons-switch> -->
        <!-- </div> -->
        <!-- </ons-list-item> -->
    </ons-list>

    <!-- <ons-list> -->
    <!-- <ons-list-header class="content--title list-header--color">通知内容</ons-list-header> -->
    <!-- <ons-list-item modifier="longdivider noPadding"> -->
    <!-- <div class="center"> -->
    <!-- ご予約に関する連絡<br /> -->
    <!-- （予約確定、お迎え通知など） -->
    <!-- </div> -->
    <!-- <div class="right"> -->
    <!-- <ons-switch modifier="mainColor"></ons-switch> -->
    <!-- </div> -->
    <!-- </ons-list-item> -->
    <!-- <ons-list-item modifier="longdivider noPadding"> -->
    <!-- <div class="center"> -->
    <!-- お支払いに関する連絡 -->
    <!-- </div> -->
    <!-- <div class="right"> -->
    <!-- <ons-switch modifier="mainColor"></ons-switch> -->
    <!-- </div> -->
    <!-- </ons-list-item> -->
    <!-- <ons-list-item modifier="longdivider noPadding"> -->
    <!-- <div class="center"> -->
    <!-- おすすめ・新着イベント -->
    <!-- </div> -->
    <!-- <div class="right"> -->
    <!-- <ons-switch modifier="mainColor"></ons-switch> -->
    <!-- </div> -->
    <!-- </ons-list-item> -->
    <!-- </ons-list> -->
</div>
