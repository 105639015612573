//=============================================================================================
// インポート
//=============================================================================================
import { Component, Inject, OnInit } from '@angular/core';
import { Reservation, Section } from '../../../../interfaces/response/reservation';
import { Params } from 'ngx-onsenui';
import * as moment from 'moment';

// service
import { UserWebApiService } from '../../../../http-services/user-web-api.service';
import { DISPATCH_WEB_API_RESERVATION_TRANS_MAP } from '../../../../http-services/dispatch-web-api.service';

/**
 * 移動プラン詳細画面。
 *
 * @export
 * @class MovingPlanDetailComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'ons-page[moving-plan-detail]',
  templateUrl: './moving-plan-detail.component.html',
  styleUrls: ['./moving-plan-detail.component.css']
})
export class MovingPlanDetailComponent implements OnInit 
{

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * 予約情報
   *
   * @type {Reservation}
   * @memberof MovingPlanDetailComponent
   */
   reservation: Reservation;

  /**
   * htmlにバインディング用
   *
   * @memberof MovingPlanDetailComponent
   */
  moment = moment;

  /**
   * 徒歩区間の有無
   *
   * @type {boolean}
   * @memberof MovingPlanDetailComponent
   */
  isWalkingSection: boolean = false;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  constructor(
    @Inject(DISPATCH_WEB_API_RESERVATION_TRANS_MAP) public readonly reservationTransMap: Map<string, Map<string, string>>,
    private userWebApiService: UserWebApiService,
    private _params: Params,
  ) 
  {}

  /**
   * 初期化処理
   */
  ngOnInit(): void 
  {
    this.reservation = this._params.data.reservation;
    
    // 徒歩区間有無
    this.reservation.sections.find(s => s.trans === 'walk') !== undefined ? this.isWalkingSection = true : this.isWalkingSection = false;
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * 区間ごとの合計金額を算出する
   *
   * @return {*}  {number}
   * @memberof HistoryDetailComponent
   */
  getTotalPriceSection(section: Section): number 
  {
    let price: number;
 
    price = 0;
 
    if (section.tickets != undefined)
    {
      // 対象区間内の全チケット情報を対象とする
      for (let index = 0; index < section.tickets.length; index++) 
      {
        if (section.tickets[index].purchase_amount != null)
        {
          // 支払金額がある場合、合計金額に加算
          price += section.tickets[index].purchase_amount;
        }
      }
    }
 
    return price;
  }

  /**
   * 区間ごとのログインユーザーの支払合計金額を算出する
   *
   * @return {number}
   * @memberof HistoryDetailComponent
   */
  getTotalPriceSectionByUser(section: Section): number 
  {
    let price: number;

    price = 0;

    if (section.tickets != undefined)
    {
      // 対象区間内の全チケット情報を対象とする
      for (let index = 0; index < section.tickets.length; index++) 
      {
        // ログインユーザーが支払い対象のチケットのみを対象とする
        if (section.tickets[index].payer.user_id != this.userWebApiService.getUserInfo().user_id)
        {
          continue;
        }

        if (section.tickets[index].purchase_amount != null)
        {
          // 支払金額がある場合、合計金額に加算
          price += section.tickets[index].purchase_amount;
        }
      }
    }

    return price;
  }
}
