<ons-toolbar>
  <div class="left ">
    <ons-back-button #onsBackButton></ons-back-button>
  </div>
  <div class="center">注文キャンセル</div>
</ons-toolbar>

<div class="content">
  <div [ngBusy]="busy"></div>

  <!--区間ごと、ユーザーごとの支払小計テンプレート-->
  <ng-template #detail let-item="item">
    <!-- 商品一覧 -->
    <ons-row vertical-align="center" class="list-item__menu" *ngFor="let item of order.items; index as i">
      <ons-col id="order-cancel_001" width="50%">{{ item.menu.name }}
        <!-- オプションを追加する場合はここでitem.optionsを出力 -->
      </ons-col>
      <ons-col id="order-cancel_002" width="10%" class="text--right">{{ item.count }}個</ons-col>
      <ons-col id="order-cancel_003" class="amount">￥{{ item.amount | number:'.0-0' }}</ons-col>
    </ons-row>
    <ons-row vertical-align="center" class="list-item__menu">
      <ons-col width="60%">配送料</ons-col>
      <ons-col id="order-cancel_004" class="amount">￥{{ getOrderCharge() }}</ons-col>
    </ons-row>
  </ng-template>

  <!-- 合計料金 -->
  <ng-template #total let-order="order">
    <div class="total">
      <div class="total-item__amount">払い戻し料金(合計)：
        <span id="order-cancel_004" class="amount">￥{{ order.amount | number:'.0-0' }}</span>
      </div>
      <div id="order-cancel_004" *ngIf="order.amount > 0">払い戻し先：{{ order.payer.name }} 様</div>
    </div>
  </ng-template>

  <!--本文-->
  <ons-list>
    <div class="list-item__caption">キャンセルによる料金払い戻し</div>
    <ons-list-item modifier="nodivider">
      <ng-container *ngTemplateOutlet="detail"></ng-container>
    </ons-list-item>
    <ons-list-item modifier="nodivider">
      <ng-container *ngTemplateOutlet="total; context: { order: order }"></ng-container>
    </ons-list-item>
  </ons-list>

  <div class="list-item__caption">キャンセル期限</div>
  <ons-list>
    <ons-list-item id="deliveryMenu-list_" modifier="longdivider" class="deliveryMenu">
      <p id="order-cancel_004">{{ moment(order.cancelable_time).format('M月D日 HH:mm') }}</p>
    </ons-list-item>
  </ons-list>

  <div class="attention">
    キャンセルポリシーは<ons-button id="deliveryMenu-list_" modifier="quiet" (click)="onCancelPolicy()"><span class="button-text">こちら</span></ons-button>
  </div>

  <ng-container *ngIf="cancellation.order === true">
    <div class="content--text--around">以上の内容でキャンセルを行う場合、確定ボタンを選択してください。</div>
    <div class="button-wrap">
      <ons-button id="order-cancel_005" [disabled]="isRequesting" modifier="large--cta" (click)="onCancelOrder()">キャンセル確定</ons-button>
    </div>
  </ng-container>
</div>
