<ons-toolbar>
  <div class="left">
    <ons-back-button></ons-back-button>
  </div>
  <div class="center">新規会員登録</div>
</ons-toolbar>
<div class="content">
  <div [ngBusy]="m_Busy"></div>
  <div class="content--description">
    管理者に送られた初回ログイン用のワンタイムパスワードを入力してください。<br><br>
    <span>一時パスワードの送信がまだの場合は、管理者のアカウントより初回ログイン許可操作を行ってください。</span>
    <br><br>
    <div class="mail-wrap">
      <ons-input id="proxy-hand-over_001" type="text" modifier="underbar" placeholder="ワンタイムパスワード" [(ngModel)]="m_strOnePassword" required></ons-input>
    </div>
    <div class="button-wrap">
      <ons-button id="proxy-hand-over_002" class="button button--large--cta" (click)="sendPassword()">パスワード送信</ons-button>
    </div>
  </div>
</div>