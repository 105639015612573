<ons-toolbar class="toolbar">
  <div class="left">
      <ons-back-button></ons-back-button>
  </div>
  <div class="center">プロフィールの編集</div>
</ons-toolbar>
<div [ngBusy]="busy"></div>
<div class="content">
  <div class="content--message">お迎え・配達を迅速に行うために、地図上でご住所の入り口にピンを置いてください。
  </div>

  <div class="button-wrap">
    <ons-button *ngIf="mode == edit_mode.user" modifier="large--cta" (click)="editProfile()" [disabled]="!location">位置情報を確定</ons-button>
    <ons-button *ngIf="mode == edit_mode.proxy_user" modifier="large--cta" (click)="editproxyAddress()" [disabled]="!location">位置情報を確定</ons-button>
  </div>
  <parts-location-update style="height: 100%;" [address]="address" [isGetLocation]="isGetLocation" (locationEvent)="updateLocation($event)"></parts-location-update>
</div>