<ons-toolbar>
  <div class="left"><ons-back-button #onsBackButton></ons-back-button></div>
  <div class="center">予約キャンセル</div>
</ons-toolbar>

<div [ngBusy]="busy"></div>
<div class="content">

  <!-- header -->
  <parts-ons-list [listInfo]="template.header"></parts-ons-list>

  <!-- body -->
  <ng-container *ngFor="let body of template.body">
    <parts-ons-list [listInfo]="body"></parts-ons-list>
  </ng-container>

  <div class="button-wrap">
    <ons-button modifier="large--cta" (click)="onCancel()">キャンセルを確定する</ons-button>
  </div>
</div>