/**
 * constant外部定義
 *    動的に値を変更すべき定数を定義
 * 
 * @author （株）オブジェクトデータ wada
 */

import { environment } from '../../environments/environment';

//=============================================================================================
// 共通
//=============================================================================================

export class Common {

  /**
   * タブ情報
   *
   * @static
   * @memberof Common
   */
  public static readonly TAB_INFO = {
    SERVICE: "service",
    HISTORY: "history",
    MYQR: "myqr",
    OPERATION: "operation",
    ACCOUNT: "account"
  };

  /** 名詞一覧 */
  public static readonly YES: string = "はい";
  public static readonly NO: string = "いいえ";
  public static readonly CONFIRM: string = "確認";
  public static readonly COMING_SOON: string = "coming soon...";
  public static readonly SEX_NO_ANSWER: string = "無回答";
  public static readonly SEX_MALE: string = "男性";
  public static readonly SEX_FEMALE: string = "女性";

  /** 確認系ダイアログ */
  public static readonly CONFIRM_YES: 0 = 0;
  public static readonly CONFIRM_NO: 1 = 1;

  /**
   * 日付フォーマット
   *
   * @static
   * @memberof Common
   */
  public static readonly MOMENT_FORMAT = {
    DATE: "YYYY年M月D日",
    TIME: "H:mm",
    DATETIME: "YYYY年M月D日 H:mm",
    TIME_ZERO: "HH:mm",
    DATE_TIME_ZERO: "YYYY年M月D日 HH:mm",
    DATE_MIN: "M/D"
  };

  /**
   * 提供サービス
   *
   * @static
   * @memberof Common
   */
  public static readonly SERVICE_TAG = {
    RESERVATION: "dispatch",
    NEWS: "news",
    SHOPPING: "shopping",
    EXPERIENCE: "exp-service"
  } as const;

  constructor() { };
};

//=============================================================================================
// QR
//=============================================================================================

export class Qr {
  /** SQRCから読み取られる公開部の正規表現 */
  public static readonly SQRC_PATTERNS = [
    /^SG\d{8}CC\d{6}$/,
    /SmartGOTO_\d{5}/
  ] as const;

  /** QRコードの有効バージョン */
  public static readonly QR_VER = [
    "1.0",
  ] as const;
};


//=============================================================================================
// FullCalendar
//=============================================================================================

/**
 * FullCalendar定数
 *
 * @export
 * @class FullCalendar
 */
export class FullCalendar {

  /**
   * dayGridViewにて、選択中の日付を表すマークのclass属性値
   * note: 値は変更する場合はcssファイルの同クラス属性値も変更する必要あり
   *
   * @static
   * @memberof FullCalendar
   */
  public static readonly SELECTED_DATE = 'selected-date';

  /**
   * dayGridViewにて、イベント存在日につける黒点のclass属性値
   * note: 値は変更する場合はcssファイルの同クラス属性値も変更する必要あり
   *
   * @static
   * @memberof FullCalendar
   */
  public static readonly EXIST_ORDER_DATE = 'exist-order-date';
};

//=============================================================================================
// operation
//=============================================================================================

/**
 * 運行状況
 *
 * @export
 * @class Operation
 */
export class Operation {
  /**
   * 更新周期（ミリ秒）
   *
   * @static
   * @type {number}
   * @memberof Operation
   */
  public static readonly UPDATE_CYCLE_TIME: number = 60000;
}

//=============================================================================================
// purchase
//=============================================================================================

/**
 * 乗車券
 *
 * @export
 * @class Purchase
 */
export class Purchase {
  /**
   * 請求明細表示期間（最大何ヶ月分か）
   *
   * @static
   * @type {number}
   * @memberof Purchase
   */
  public static readonly DETAILS_DISPLAY_RANGE: number = 13;

  /**
   * 利用開始日を最大何日間選べるか
   *
   * @static
   * @type {number}
   * @memberof Purchase
   */
  // public static readonly TICKET_VALID_DAY: number = 15;

  /**
   * 観光目的でないユーザ向けタイトル
   *
   * @static
   * @type {string}
   * @memberof Purchase
   */
  public static readonly TICKET_TITLE_CITIZEN: string = "定額乗車券";

  /**
   * 観光目的ユーザ向けタイトル
   *
   * @static
   * @type {string}
   * @memberof Purchase
   */
  public static readonly TICKET_TITLE_TOURIST: string = "数日乗り放題券";

  /**
   * チケット種類の名称
   *
   * @static
   * @memberof Purchase
   */
  public static readonly TICKET_TYPE_NAME = {
    NONE: "1回券",
    CHILD: "学生定額乗車券（小）",
    STUDENT: "学生定額乗車券（中・高）",
    ADULT_SENIOR: "定額乗車券",
    TOURIST_CHILD: "数日乗り放題券（子供）",
    TOURIST_ADULT: "数日乗り放題券（大人）"
  };

  /**
   * 購入可能乗車券の切り替え時の年齢
   *
   * @static
   * @memberof Purchase
   */
  public static readonly TICKET_PLAN_AGE = {
    FREE: 6,
    CHILD: 12,
    STUDENT: 15,
    ADULT: 18,
    SENIOR: 64,
  };

  /**
   * 自動更新切り替えタイムリミット
   *
   * @static
   * @memberof Purchase
   */
  public static readonly TICKET_CONTINUE_LIMIT = 20;
}

//=============================================================================================
// dispatch
//=============================================================================================

/**
 * 予約
 *
 * @export
 * @class Dispatch
 */
export class Dispatch {
  /**
   * キャンセル手数料の割合(%)
   *
   * @static
   * @type {number}
   * @memberof Dispatch
   */
  public static readonly CANCELFEE_PERCENTAGE: number = 10;


  /**
   * 料金種別
   *
   * @static
   * @memberof Dispatch
   */
  public static readonly AMOUNT_PROPERTY = {
    CANCEL: "(キャンセル料金)",
    BABY: "(乳児料金)",
    TODDLER: "(幼児料金)",
    CHILD: "(子供料金)",
    NORMAL: "(大人料金)",
    HANDICAPPED: "(障がい者料金)",
    ASSISTANT: "(介添人料金)",
    PRESCHOOLER: "(未就学児料金)",
    ELEMENTARY_SCHOOL: "(小学生料金)",
    JUNIOR_HIGH_SCHOOL: "(中学生料金)",
  }

  /**
   * 予約不可しきい時刻
   *
   * @static
   * @memberof Purchase
   */
  public static readonly DUPLICATE_RESERBATION_THRESHOLD = 8;
}

/**
 * バス停(配車)
 *
 * @export
 * @class Busstop
 */
export class Busstop {

  /**
   * バス停、更新頻度(30分)
   *
   * @static
   * @type {number}
   * @memberof Busstop
   */
  public static readonly UPDATE_BUSSTOP_TIME: number = 1800000;
}

//=============================================================================================
// search
//=============================================================================================

export class Search {

  /**
   * 検索履歴の取得数
   *
   * @static
   * @memberof Search
   */
  public static readonly HISTORY = 15;
}

//=============================================================================================
// news
//=============================================================================================

export class News {

  /**
   * 一度に取得する記事の数
   *
   * @static
   * @memberof NEWS
   */
  public static readonly GET_ARTICLE_LIMIT = 20;

  /**
   * 取得可能な記事の最大数
   *
   * @static
   * @memberof NEWS
   */
  public static readonly GET_ARTICLE_MAX = 500;
}

//=============================================================================================
// exp
//=============================================================================================

export class Exp {

  /**
   * モビパHP
   *
   * @static
   * @memberof Exp
   */
  public static readonly MOBIPA_HP = {
    url: `${environment.base_url}/${environment.areacode}/mobipa`
  }

  /**
   * サービス種別
   *
   * @static
   * @memberof Exp
   */
  public static readonly SERVICE_TYPE = {
    MOBILITY: "MOBILITY",
    ACTIVITY: "ACTIVITY"
  }
}