<ons-toolbar>
  <div class="left">
    <ons-back-button></ons-back-button>
  </div>
  <div class="center">ファミリー招待</div>
</ons-toolbar>
<div class="content">
  <div [ngBusy]="m_Busy"></div>
  <div class="center segment-wrap">
    <ons-segment class="segment" active-index="0">
      <button>STEP1</button>
      <button disabled>STEP2</button>
      <button disabled>STEP3</button>
    </ons-segment>
    <div class="content--description">
      ファミリーのQRコードを読み取ってください。<br>
      ※招待者が遠方にいるなどの理由により、撮影が行えない方は、
        <ons-button id="family-invite-qr_001" modifier="quiet" (click)="viewOfficeMessage()"><span class="button-text">{{officeName}}</span></ons-button>
        にお問い合わせください。
    </div>
  </div>
  <app-qr-read [filming]="filming" (qr)="readQr($event)"></app-qr-read>
  <!-- <div class="qr-scan-camera-container">
    <video #video class="qr-scan-camera" playsinline></video>
  </div> -->
</div>
<!-- <div style="display:none">
  <canvas #canvas width="640" height="480"></canvas>
</div> -->