//=============================================================================================
// インポート
//=============================================================================================
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

//=============================================================================================
// Configクラス定義
//=============================================================================================

/**
 * HTTP接続情報
 *
 * @export
 * @class OperationWebApiServiceConfig
 * @author （株）オブジェクトデータ wada
 */
export class OperationWebApiServiceConfig {

  /**
   * サーバのベースURL
   *
   * @type {string}
   * @memberof OperationWebApiServiceConfig
   */
  baseUrl: string;

  /**
   * httpOptions
   *
   * @type {({
   *     headers?: HttpHeaders | {
   *       [header: string]: string | string[];
   *     };
   *   })}
   * @memberof OperationWebApiServiceConfig
   */
  httpOptions?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
  };
}

//=============================================================================================
// Serviceクラス定義
//=============================================================================================

/**
 * WebApiサービス
 *
 * @export
 * @class OperationWebApiService
 * @author （株）オブジェクトデータ wada
 */
@Injectable({
  providedIn: 'root'
})
export class OperationWebApiService 
{

//=============================================================================================
// コンストラクタ
//=============================================================================================

  /**
   * Creates an instance of OperationWebApiService.
   * @param {HttpClient} http
   * @param {OperationWebApiServiceConfig} config
   * @memberof OperationWebApiService
   */
  constructor(
    private http: HttpClient,
    private config: OperationWebApiServiceConfig
  ) { }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * 運行管理情報を取得
   *
   * @template T
   * @param {OperationWebApiSchemas.ResponseSchemas.CarInfo} params
   * @return {*}  {Observable<HttpResponse<T>>}
   * @memberof OperationWebApiService
   */
  getOperationCarsInfo<T>(): Observable<HttpResponse<any>> {
    // console.log('[OperationWebApiService][operationCarsInfo] ' + this.config.baseUrl + '/cars');

    return this.http.get<any>(`${this.config.baseUrl}/cars`, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: false
    });
  }
}
