import { NgModule, ModuleWithProviders } from '@angular/core';
import { PurchaseWebApiServiceConfig } from '../http-services/purchase-web-api.service';

@NgModule()
export class PurchaseWebApiModule {
  static withConfig(config: PurchaseWebApiServiceConfig): ModuleWithProviders<PurchaseWebApiModule> {
    return {
      ngModule: PurchaseWebApiModule,
      providers: [
        { provide: PurchaseWebApiServiceConfig, useValue: config }
      ]
    };
  }
 }