<ons-toolbar class="toolbar">
    <div class="left">
        <ons-back-button></ons-back-button>
    </div>
    <div class="center">プロフィール</div>
</ons-toolbar>
<div class="content">
    <ons-list class="profile-list">
        <ons-list-header modifier="color" class="list-item__title">氏名</ons-list-header>
        <ons-list-item id="profile-list_001" modifier="longdivider" class="list-item__text">
            {{userProfile.profile.name.family_name + ' ' + userProfile.profile.name.given_name}}</ons-list-item>


        <ons-list-header modifier="color" class="list-item__title">氏名（かな）</ons-list-header>
        <ons-list-item id="profile-list_002" modifier="longdivider" class="list-item__text">
            {{userProfile.profile.name.family_name_kana + ' ' + userProfile.profile.name.given_name_kana}}
        </ons-list-item>

        <ons-list-header modifier="color" class="list-item__title">生年月日</ons-list-header>
        <ons-list-item id="profile-list_003" modifier="longdivider" class="list-item__text">
            {{userProfile.profile.birth ? userProfile.profile.birth.year + '/' + userProfile.profile.birth.month + '/' + userProfile.profile.birth.day : ''}}
        </ons-list-item>

        <ons-list-header modifier="color" class="list-item__title">性別</ons-list-header>
        <ons-list-item id="profile-list_004" modifier="longdivider" class="list-item__text">{{getSexDisplay(userProfile)}}</ons-list-item>

        <ons-list-header modifier="color" class="list-item__title">観光目的の利用</ons-list-header>
        <ons-list-item id="profile-list_005" modifier="longdivider" class="list-item__text">{{getIsTourist(userProfile)}}</ons-list-item>

        <ons-list-header modifier="color" class="list-item__title">
            <div class="left">電話番号</div>
            <div id="profile-list_006" class="right list-item__link" (click)="edit('tel')">編集する</div>
        </ons-list-header>
        <ons-list-item id="profile-list_007" modifier="longdivider" class="list-item__text">{{userProfile.profile.tel}}</ons-list-item>

        <ons-list-header modifier="color" class="list-item__title">
          <div class="left">メールアドレス</div>
          <div id="profile-list_008" class="right list-item__link" (click)="edit('mail')" *ngIf="userProfile.auth !== 'local'">編集する</div>
        </ons-list-header>
        <ons-list-item id="profile-list_009" modifier="longdivider" class="list-item__text">{{userProfile.profile.mail}}</ons-list-item>

        <ons-list-header modifier="color" class="list-item__title">
            <div class="left">住所</div>
            <div id="profile-list_010" class="right list-item__link" (click)="edit('address')">編集する</div>
        </ons-list-header>
        <ons-list-item id="profile-list_011" modifier="longdivider" class="list-item__text">
            {{userProfile.profile.address.zip}}
            {{userProfile.profile.address.prefecture + userProfile.profile.address.city + userProfile.profile.address.address1 + userProfile.profile.address.address2 + userProfile.profile.address.address3}}
        </ons-list-item>
    </ons-list>
</div>
