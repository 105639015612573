//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { OnsNavigator, Params } from 'ngx-onsenui';
import * as moment from 'moment';

// service
import { ApplicationMessageService } from '../../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../../lib-services/http-error-response-parser.service';
import { FamilyWebApiService } from '../../../../http-services/family-web-api.service';
import { ShoppingWebApiService } from '../../../../http-services/shopping-web-api.service';

// component
import { HistoryListComponent } from '../../history-list/history-list.component';

// interface
import { Order } from '../../../../interfaces/response';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 配車予約キャンセル画面。
 *
 * @export
 * @class OrderCancelComponent
 * @implements {OnInit}
 * @implements {AfterViewInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ons-page[order-cancel]',
  templateUrl: './order-cancel.component.html',
  styleUrls: ['./order-cancel.component.css']
})
export class OrderCancelComponent implements OnInit, OnDestroy {

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * 非同期通信の受信オブジェクト
   *
   * @type {Subscription}
   * @memberof OrderCancelComponent
   */
  busy: Subscription;
  
  /**
   * 注文情報
   *
   * @type {Order}
   * @memberof OrderCancelComponent
   */
  order: Order;

  /**
   * HTML用
   *
   * @memberof OrderCancelComponent
   */
  moment = moment;

  /**
   * キャンセル可否
   *
   * @type {{
   *     family: boolean;
   *     order: boolean;
   *   }}
   * @memberof OrderCancelComponent
   */
  cancellation: {

    /**
     * ファミリーメンバーとして利用制限中か
     *
     * @type {boolean}
     */
    family: boolean;

    /**
     * キャンセル期限を過ぎているか
     *
     * @type {boolean}
     */
    order: boolean;
  };

  /**
   * サーバに注文キャンセルリクエスト実行中かどうか
   *
   * @type {boolean}
   * @memberof OrderCancelComponent
   */
  isRequesting: boolean = false;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of OrderCancelComponent.
   * @param {ApplicationMessageService} appMsgServ
   * @param {HttpErrorResponseParserService} errResServ
   * @param {FamilyWebApiService} familyServ
   * @param {ShoppingWebApiService} shoppingServ
   * @param {OnsNavigator} navigator
   * @param {Params} params
   * @memberof OrderCancelComponent
   */
  constructor(
    private appMsgServ: ApplicationMessageService, 
    private errResServ: HttpErrorResponseParserService, 
    private familyServ: FamilyWebApiService, 
    private shoppingServ: ShoppingWebApiService, 
    private navigator: OnsNavigator, 
    private params: Params,
    private msg: MESSAGE,
  ) {}

  /**
   * 初期化処理。
   *
   * @memberof OrderCancelComponent
   */
  ngOnInit(): void {

    this.order = this.params.data.order;

    // ファミリー制限中か、キャンセル期限を過ぎているか
    this.cancellation = {
      family: this.isFamilyUtilization(), 
      order: this.isOrderCancel()
    };
  }

  /**
   * 破棄処理。
   *
   * @memberof OrderCancelComponent
   */
  ngOnDestroy(): void {

    this.busy?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 配送料の合計金額を取得する。
   *
   * @return {*}  {number}
   * @memberof OrderCancelComponent
   */
  getOrderCharge(): number {
      
    return this.shoppingServ.getOrderCharge(this.order);
  }

  /**
   * セキュリティポリシーを表示する。
   *
   * @memberof OrderCancelComponent
   */
  onCancelPolicy(): void {

    this.appMsgServ.shoppingCancelPolicy();
  }

  /**
   * 注文をキャンセルする。
   *
   * @memberof OrderCancelComponent
   */
  onCancelOrder(): void {

    this.cancelOrder();
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * ファミリーメンバーの場合、制限中かどうか。
   *
   * @private
   * @return {*}  {boolean}
   * @memberof OrderCancelComponent
   */
  private isFamilyUtilization(): boolean {
    
    // ファミリー管理者から制限されている場合はキャンセル不可
    this.busy = this.familyServ.getFamily().subscribe();
    if (this.familyServ.isLoginUserUtilization()) return false;

    return true;
  }

  /**
   * 注文キャンセルが可能かどうか。
   *
   * @return {*}  {boolean}
   * @memberof OrderCancelComponent
   */
  private isOrderCancel(): boolean {

    // 集荷前かつ、キャンセル期限前のみキャンセル可能
    return this.shoppingServ.isOrderCancel(this.order, true)
  }

//=============================================================================================
// サーバ通信
//=============================================================================================
  
  /**
   * 注文キャンセルを実行する。
   *
   * @return {*}  {void}
   * @memberof OrderCancelComponent
   */
  private cancelOrder(): void {

    // ファミリー管理者から制限されている場合はキャンセル不可
    if (this.cancellation.family === false) {
      this.appMsgServ.viewDialogMessage(this.msg.CLIENT.FAMILY.RESTRICTED_UTILIZATION.message());
      return;
    }

    // 2重送信対策
    if (this.isRequesting) {
      // console.log("2重クリックのためリクエストロック中");
      return;
    }
    this.isRequesting = true;

    this.busy = this.shoppingServ.cancelOrder(this.order.order_id).subscribe({ 
      next: () => {
        
        this.isRequesting = false;
        this.appMsgServ.viewDialogMessage(this.msg.CLIENT.SHOPPING.CANCEL_COMPLETE.message(), () => {
          // メール遷移の場合
          if (this.params.data.transition != null) this.params.data.transition();
          else {
            this.navigator.element.resetToPage(HistoryListComponent, {
              animation: 'fade-ios',
              data: { selectedDate: moment(this.order.d.schd_time.from).format('YYYY-MM-DD') }
            })
          }
        });
      },
      // キャンセル期限が過ぎたときのサーバエラーコードを確認して、対応メッセとして登録
      error: this.errResServ.doParse((_err, errContent) => {

        this.isRequesting = false;

        // GMOエラー
        if (errContent.smartGotoErrCode === this.appMsgServ.SERV_CONST_CODE.PURCHASE.GMO_TRAN_VOID) {
          this.errResServ.viewErrDialog(errContent, "CANCEL");
        }
        else this.errResServ.viewErrDialog(errContent);
      })
    });
  }
}