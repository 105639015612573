<ons-toolbar>
  <div class="left"><ons-back-button #onsBackButton></ons-back-button></div>
  <div class="center">予約内容確認</div>
</ons-toolbar>

<div [ngBusy]="busy"></div>
<div class="content">
  
  <ng-container *ngFor="let list of template.header">
    <parts-ons-list [listInfo]="list"></parts-ons-list>
  </ng-container>

  <div class="text--right"><span class="icon--warning">！</span> 料金についてご不明な点がある場合は店舗へご連絡ください。</div>
  
  <ng-container *ngFor="let list of template.body1">
    <parts-ons-list [listInfo]="list"></parts-ons-list>
  </ng-container>

  <!-- オプション -->
  <ng-container *ngIf="template.optionGroup.length !== 0">
    <ng-container *ngFor="let group of template.optionGroup">
      <div class="list-item__caption">
        <span *ngIf="group.options.length === 1" class="exp-option-wrap__item">
          <span>{{group.options[0].name}}</span>
          &nbsp;
          <button #optionDialog class="info_button" style="border: solid 1px !important;" (click)="onClickOptionInfo(group.options[0])">
            <img [src]="ASSETS.INFORMATION">
          </button>
        </span>
        <ng-container *ngIf="group.options.length >= 2">{{group.name}}</ng-container>
      </div>
      <ng-container *ngFor="let option of group.options">
        <ons-list *ngIf="option.isDisplay === true" class="list-item__text">
          <ons-list-item modifier="nodivider">
            <div class="option-time">
              <span *ngIf="group.options.length >= 2" class="exp-option-wrap__item">
                <span style="overflow-wrap: anywhere;">{{ option.name }}</span>
                &nbsp;<button #optionDialog class="info_button" style="border: solid 1px !important;" (click)="onClickOptionInfo(option)"><img [src]="ASSETS.INFORMATION"></button>
              </span>
              <span style="overflow-wrap: anywhere;" *ngIf="option.user_option === 'yesno'">{{expBill.options[option.sg_option_id].yesno_param.name}}</span>
              <span style="overflow-wrap: anywhere;" *ngIf="option.user_option === 'select'">{{expBill.options[option.sg_option_id].select_param.name}}</span>
              <span *ngIf="option.user_option === 'number'">
                <span>{{expBill.options[option.sg_option_id].number_param.selected}}</span>
                <span style="overflow-wrap: anywhere;">{{option.unit}}</span>
              </span>
              <span style="overflow-wrap: anywhere;" *ngIf="option.user_option === 'comment'">{{expBill.options[option.sg_option_id].comment}}</span>
  
            </div>
          </ons-list-item>
  
          <ons-list-item modifier="nodivider" *ngIf="option.time_rule === 'days'">
            <div style="padding-left: 15px;" class="option-time">
              <span *ngIf="option.time_rule === 'days'" style="overflow-wrap: anywhere;">利用日数</span>
              <span *ngIf="option.time_rule === 'mins'" style="overflow-wrap: anywhere;">利用時間</span>
              <span>
                <span>{{expBill.options[option.sg_option_id].time_param.selected}}</span>
                <span style="overflow-wrap: anywhere;" *ngIf="option.time_rule === 'days'">日</span>
                <span style="overflow-wrap: anywhere;" *ngIf="option.time_rule === 'mins'">分</span>
              </span>
            </div>
          </ons-list-item>
        </ons-list>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngFor="let list of template.body2">
    <parts-ons-list [listInfo]="list"></parts-ons-list>
  </ng-container>

  <div class="button-wrap">
    <ons-button modifier="large--cta" (click)="onReserveButton()">
      予約を確定する
    </ons-button>
  </div>

  <!-- 利用規約とキャンセルポリシー -->
  <div class="content-description">
    「確定する」ボタンを押して頂くことで、お客様は本地域におけるアプリケーションおよびサービスの
    <ons-button modifier="quiet" (click)="viewTermsOfService()" class="content-description_button"><span class="button-text">利用規約</span></ons-button>
    、上記<span class="attention">キャンセル料ルール</span>に同意の上、ご予約・ご注文されたことになります。
  </div>

</div>