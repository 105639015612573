//=============================================================================================
// import
//=============================================================================================
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { Params, OnsNavigator } from 'ngx-onsenui';
import * as dayjs from 'dayjs';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import * as ons from 'onsenui';

// service
import { HttpErrorResponseParserService } from '../../../../lib-services/http-error-response-parser.service';
import { CommonFunctionModule } from "../../../../lib-modules/common-function.module";
import { ExpWebApiService, UserSelectOption, ReservationOption } from '../../../../http-services/exp-web-api.service';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';
import { ApplicationMessageService } from 'src/app/lib-services/application-message.service';
import { DatePickerService } from 'src/app/lib-services/date-picker.service';

// component

// parts
import { ListParts } from "../../../parts/ons-list/ons-list.component";

// interface
import { parameter } from '../../../../interfaces/parameter';
import { ExpAvailable, ExpResourceAvailable, ExpReservation, ShopCalendar, ExpFetchReservation, SgServiceOption, Settings } from '../../../../interfaces/response';
import { ExpChangeDatermineComponent } from '../exp-change-datermine/exp-change-datermine.component';
import { HttpResponse } from '@angular/common/http';
import { CalendarOptions, DayCellMountArg, EventClickArg, EventInput, FullCalendarComponent } from '@fullcalendar/angular';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { ExpDetailAvailableDescriptionDialog } from 'src/app/components/exp/exp-detail-type1/exp-detail-type1.component';
import { request } from 'src/app/interfaces/request';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 観光サービス予約変更画面。
 *
 * @export
 * @class ExpChangeType1Component
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ons-page[exp-change-type1]',
  templateUrl: './exp-change-type1.component.html',
  styleUrls: ['./exp-change-type1.component.scss']
})
export class ExpChangeType1Component implements OnInit, AfterViewInit, OnDestroy {

  readonly ASSETS = {
    INFORMATION: CommonFunctionModule.getAssetsUrl('/image/button/info.png')
  } as const;

  /**
   * 通信監視用Subscription
   *
   * @type {Subscription}
   * @memberof ExpChangeType1Component
   */
  busy: Subscription;

  /**
   * onslist parts
   *
   * @type {{
   *     header: ListParts;
   *     body: ListParts[];
   *   }}
   * @memberof ExpChangeType1Component
   */
  template: {

    /**
     * ヘッダー
     *
     * @type {ListParts}
     */
    header?: ListParts;

    /**
     * 本文1
     *
     * @type {ListParts[]}
     */
    body1?: ListParts[];

    /**
     * 本文2
     *
     * @type {ListParts[]}
     */
    body2?: ListParts[];
  };

  orgReservNumber: ListParts["select_box"]["item"][] = [];

  /**
   * 予約可能な最大数
   *
   * @type {number}
   * @memberof ExpChangeType1Component
   */
  max_resources: number = 0;

  /**
   * 観光履歴・予定
   *
   * @type {ExpReservation}
   * @memberof ExpCancelComponent
   */
  reserv: ExpReservation;

  /**
   * 取得した空き情報
   *
   * @type {ExpResourceAvailable['resources']}
   * @memberof ExpChangeType1Component
   */
  resourceAvailable: ExpResourceAvailable['resources'] = [];

  /**
   * 取得した最新の空き時間情報
   *
   * @type {ExpResourceAvailable['available']}
   * @memberof ExpChangeComponent
   */
  // targetAvailable: ExpResourceAvailable['available'] = [];

  /**
   * 表示中リソース空き情報
   *
   * @type {ExpResourceAvailable['resources'][number]}
   * @memberof ExpChangeType1Component
   */
  targetResource: ExpResourceAvailable['resources'][number] = {
    sg_resource_id: null,
    availables: [],
    name: ""
  };

  /**
   * 営業スケジュール
   *
   * @type {ShopCalendar[]}
   * @memberof ExpChangeType1Component
   */
  shopSchedule: ShopCalendar[] = [];

  /**
   * ユーザが選択した料金種別毎の予約数
   *
   * @private
   * @type {{ type: number, value:number }[]}
   * @memberof ExpChangeType1Component
   */
  private reserv_number: { type: number, value:number }[] = [];

  /**
   * ユーザが選択したオプション情報
   *
   * @private
   * @type {request.ExpReservation["service_options"]}
   * @memberof ExpChangeType1Component
   */
  selectedOption: {[option_id: string]: UserSelectOption} = {};

  /**
   * 最少人数エラーの有無
   *
   * @type {boolean}
   * @memberof ExpChangeType1Component
   */
  err_min_number: boolean = false;

  /**
   * 最大人数(capacity)エラーの有無
   * activityサービスのみ
   *
   * @type {boolean}
   * @memberof ExpChangeType1Component
   */
  err_max_number: boolean = false;

  /**
   * 遷移ボタンの有効状態
   *
   * @type {boolean}
   * @memberof ExpChangeType1Component
   */
  btn_trans_disabled: boolean = true;


  /**
   * オプショングループ
   *
   * @type {ExpReservation["service"]["option_groups"]}
   * @memberof ExpDetailComponent
   */
  optionGroups: ExpReservation["service"]["option_groups"] = [];

  /**
   * datePickerの表示範囲
   *  start: 現在日時
   *  end: 現在日時 + 2か月
   *  endTime: end + 7日(終了日時は2か月+7であるため)
   * 
   * @memberof ExpChangeType1Component
   */
  datePickerRange = {
    start: new Date(), 
    end: new Date(),
    endTime: new Date(),
  };

  /**
   * 選択中の日付
   * note: selectedDateのsetter以外での参照は禁止
   *
   * @private
   * @type {string}
   * @memberof ExpChangeType1Component
   */
  private _selectedDate: string;
  
  /**
   * 選択中の日付
   *
   * @type {string}
   * @memberof ExpChangeType1Component
   */
  get selectedDate(): string {
    return this._selectedDate;
  }

  /**
   * 空き日程、表示中の週
   *
   * @type {{start: string, end: string}}
   * @memberof ExpChangeType1Component
   */
  dispAvailableRange: {start: string, end: string};

  /**
   * 体験サービス予約変更
   * 管理クラス
   *
   * @type {ReservationOption}
   * @memberof ExpChangeType1Component
   */
  putReservationOption: ReservationOption;

  /**
   * 利用時間上限(h)
   *
   * @type {Settings["exp"]["max_reserve_hour"]}
   * @memberof ExpChangeType1Component
   */
  maxReserveHour: Settings["exp"]["service"]["max_reserve_hour"];

  /**
   * カレンダーの次週・翌週判定に使用
   *
   * @memberof ExpDetailType1Component
   */
  readonly calendarWeekType = {
    next: "翌週",
    prev: "前週"
  }

  /**
   * カレンダー表示設定
   *
   * @memberof ExpDetailType1Component
   */
  validRange = {
    start: moment().format(moment.HTML5_FMT.DATE),
    end: moment().add(6, 'months').add(1, 'days').format(moment.HTML5_FMT.DATE)
  }

  /**
   * 次画面への遷移ボタン
   *
   * @type {ElementRef}
   * @memberof ExpDetailComponent
   */
  @ViewChild('btn_trans') btn_trans: ElementRef<HTMLButtonElement>;

  /**
   * mat-date-picker
   *
   * @type {MatDatepicker<undefined>}
   * @memberof ExpReserveListComponent
   */
  @ViewChild('matDatePicker') matDatePicker: MatDatepicker<undefined>;

  /**
   * calendar
   *
   * @type {FullCalendarComponent}
   * @memberof ExpDetailComponent
   */
  @ViewChild('calendar') calendar: FullCalendarComponent;

  /**
   * オプション、インフォメーションマーク要素
   *
   * @type {QueryList<ElementRef>}
   * @memberof ExpChangeType1Component
   */
  @ViewChildren('optionDialog') optionDialog: QueryList<ElementRef>;

  /**
   *
   *
   * @type {CalendarOptions}
   * @memberof ExpChangeType1Component
   */
  readonly calendarOptions: CalendarOptions = {
    
    // 種類
    initialView: "todayAsFirstDayOfWeek", 
    plugins: [
      timeGridPlugin,      // Display events on Month view or DayGrid view
      interactionPlugin   // Provides functionality for event drag-n-drop, resizing, dateClick, and selectable actions
    ], 
    views:{
      todayAsFirstDayOfWeek: {
        type: 'timeGridWeek',
        duration: {days: 7}
      }
    },
    validRange: this.validRange,    
    // タイトル
    titleFormat: {
      month: "long", 
      day: "numeric"
    }, 
    // 終日OFF
    allDaySlot: false, 
    // ツールバー 
    headerToolbar: {left: 'today,prev,next', center: 'title', right: 'selectDate'}, 
    // ボタン
    buttonText: { today: "今日" }, 
    // ボタンヒント
    buttonHints: {
      prev: '前の週を表示',
      next: '次の週を表示',
      today: '今日の日付に戻る'
    }, 
    // 言語
    locale: 'ja', 
    // リンクオフ
    navLinks: false,
    // 高さ(height)
    height: 650,

    // scrollTime: '09:00:00',


    // データセル
    dayCellContent: (date: DayCellMountArg) => {
      date.dayNumberText = date.dayNumberText.replace('日', '');  // カレンダーセルの「日」を除去
    }, 
    // データセルクリック　※空き情報はイベントで表示するため処理なし
    dateClick: (date: DateClickArg): void => {
      // this.selectedDate = moment(date.dateStr).format(moment.HTML5_FMT.DATE);  // 選択日の設定
      // this.selectedStartTime = moment(date.dateStr).format(moment.HTML5_FMT.TIME);
      // console.log(this.selectedStartTime);
    }, 
    // 日付ヘッダー
    dayHeaderFormat: function (date) {
      const day = date.date.day;
      const weekNum = date.date.marker.getDay();
      const week = ['(日)', '(月)', '(火)', '(水)', '(木)', '(金)', '(土)'][weekNum];
      return day + '\n' + week;
    }, 
    // カスタムボタン
    customButtons: {
      // date-picker
      selectDate: {
        hint: '日付を選択',
        click: () => {
          this.openDatePicker();
        }
      },
      // prev
      prev: {
        text: '<',
        hint: '前の週を表示',
        click: () => {
          this.setCalendarWeekChange(this.calendarWeekType.prev);
        }
      },
      // next
      next: {
        text: '>',
        hint: '次の週を表示',
        click: () => {
          this.setCalendarWeekChange(this.calendarWeekType.next);
        }
      },
      // today
      today: {
        text: '今日',
        hint: '今日の日付に戻る',
        click: () => {
          const date_today = moment().format(moment.HTML5_FMT.DATE); 
          this.selectedDate = date_today;
        }
      }
    }, 
    // NOTE: slotmaxtime slotmintimeで指定した表示するスクロール領域
    //     の一番上からスクロールする距離を指定。
    //     slotmaxtime slotmintimeを指定した際にscrollToTimeが効かなかった為、初期表示時のoptionで指定。

    // slot
    slotLabelFormat: { hour: 'numeric', minute: 'numeric' }, 
    slotLabelInterval: "00:30", 

    // event
    eventShortHeight: 100, 
    eventMinHeight: 10, 
    displayEventTime: false, 
    eventBackgroundColor: "#ffffff", 
    eventBorderColor: "#000000", 
    eventTextColor: "#000000", 

    // event click
    eventClick: (arg: EventClickArg): void => {

      // // 空き以外は処理しない
      // if (arg.el.textContent !== this.available.OK) return;

      // // 色初期化
      // const eve = document.getElementsByClassName("fc-event");
      // for (let i = 0; i < eve.length; i++) {
      //   if (eve.item(i).textContent === this.available.OK) eve.item(i).setAttribute("style", "background-color: #ffffff; border-color: #000000");
      // }

      // // 利用開始時間、終了時間
      // this.expBill.start_time = arg.event.start.toISOString();
      // const endtime = new Date(arg.event.start);
      // endtime.setMinutes(endtime.getMinutes() + this.expBill.util_time);
      // this.expBill.end_time = endtime.toISOString();

      // // 選択イベントの色変更
      // arg.el.style.backgroundColor = "skyblue";

      // // 遷移ボタン表示切替
      // this.switchOverTransitionBtn();


      this.openAvailableDescriptionDialog();
    },
  };

  //=============================================================================================
  // ライフサイクルメソッド
  //=============================================================================================

  /**
   * Creates an instance of ExpChangeType1Component.
   * @param {HttpErrorResponseParserService} errResServ
   * @param {CommonFunctionModule} commonFunc
   * @param {ExpWebApiService} expServ
   * @param {OnsNavigator} navigator
   * @param {Params} params
   * @memberof ExpChangeType1Component
   */
  constructor(
    private errResServ: HttpErrorResponseParserService, 
    private commonFunc: CommonFunctionModule, 
    private expServ: ExpWebApiService, 
    private navigator: OnsNavigator, 
    private params: Params,
    private dialog: MatDialog,
    private municipalityWebApiServ: MunicipalityWebApiService,
    private appMsgServ: ApplicationMessageService,
    private datePickerService: DatePickerService,
  ) { }

  /**
   * 初期処理。
   *
   * @memberof ExpChangeType1Component
   */
  ngOnInit(): void {

    this.reserv = this.commonFunc.deepcopy(this.params.data.reserv);
    // type='usage'以外除外
    this.reserv.service_options = this.reserv.service_options.filter(option => option.type === "usage");

    this.putReservationOption = new ReservationOption(this.reserv, this.municipalityWebApiServ.setting.exp);
    this.optionGroups = this.putReservationOption.optionGroups;
    this.selectedOption = this.putReservationOption.selectedOption;

    this.maxReserveHour = this.putReservationOption.settingExp.service.max_reserve_hour;

    // 空き日程初期表示は、利用開始日時を含む週
    this.dispAvailableRange = this.getAvailableRange(this.reserv.start.schd_time);

    // カレンダーの最大表示日数（自治体毎の設定値Nヵ月後まで）
    this.datePickerRange.end.setMonth(this.datePickerRange.end.getMonth() + this.municipalityWebApiServ.setting.exp.reservation.max_month);

    // 終了日時として設定可能な日数
    this.datePickerRange.endTime.setMonth(this.datePickerRange.end.getMonth());
    this.datePickerRange.endTime.setDate(this.datePickerRange.endTime.getDate() + this.maxReserveHour / 7);

    // オプションソート
    if (this.reserv.service.option_groups) {
      // オプショングループ順ソート
      this.optionGroups = this.reserv.service.option_groups.sort((a, b) => {
        return a.index - b.index;
      });

      // グループ一つ目からさらにグループ内ソート
      this.optionGroups.forEach(group => {
        group.options.sort((a, b) => {
          return a.index - b.index;
        });
      });
    }

    // 料金種別毎の予約数を保存
    this.reserv.numbers.forEach(n => this.reserv_number.push({type: n.type, value: n.value}));
  }

  /**
   *
   *
   * @memberof ExpChangeType1Component
   */
  ngAfterViewInit(): void {
    // カスタムボタンにカレンダーアイコンを挿入
    this.calendar.getApi().el.querySelector('button.fc-selectDate-button')?.insertAdjacentHTML('beforeend', '<span class="material-icons calendar-icon">calendar_month</span>');

    // 今日ボタンを非活性
    this.calendar.getApi().el.querySelector<HTMLButtonElement>('.fc-today-button').disabled = true;

    // カレンダー スワイプ処理の設定
    var detector = ons.GestureDetector(document.querySelector('#week-calendar'), {swipeVelocityX: 0.1});
    detector.on('swipeleft', ()=> {
      this.setCalendarWeekChange(this.calendarWeekType.next);
    });

    detector.on('swiperight', ()=> {
      this.setCalendarWeekChange(this.calendarWeekType.prev);
    });    

    setTimeout(() => {
      // 予約の時間で空き情報取得
      // this.selectedDate
      const dispStart = new Date(this.calendar.getApi().view.currentStart);

      if (moment(dispStart).isSame(this.dispAvailableRange.start)) this.communicationsCommand(true);
      else this.selectedDate = this.dispAvailableRange.start;
    })

    // オプション、インフォメーションマークclick時、二重イベント止める
    this.optionDialog.forEach(item => {
      item.nativeElement.addEventListener('click', (event) => {
        console.log('click');
        event.stopPropagation();
      });
    })
  }

  /**
   * 日付選択ボタンをクリックしたときのイベントハンドラ
   */
  private openDatePicker() {
    this.matDatePicker.open();

    setTimeout(() => {
      this.datePickerService.positionDatePicker();
    }, 0);
  }

  /**
   * 破棄処理。
   *
   * @memberof ExpChangeType1Component
   */
  ngOnDestroy(): void {
    
    this.busy?.unsubscribe();
  }

  //=============================================================================================
  // イベントハンドラ
  //=============================================================================================


  /**
   * datePickerで日付選択時のイベントハンドラ
   *
   * @param {MatDatepickerInputEvent<Date>} event
   * @memberof ExpChangeType1Component
   */
  onChangeDate(event: MatDatepickerInputEvent<Date>): void {

    const selected = moment(event.value).format(moment.HTML5_FMT.DATE);
    this.selectedDate = selected;  // 選択日を設定
  }

  /**
   * 空き日程 リソース変更
   *
   * @memberof ExpChangeType1Component
   */
  onChangeResource(resource: ExpResourceAvailable['resources'][number]): void {
    // 表示リソース変更
    this.targetResource = resource;
    // イベント、ボタン初期化
    this.calendar.getApi().removeAllEvents();
    // ボタン追加
    this.addCustomEventSource();
  }
  
  /**
   * 空き日程 infoマークclick
   *
   * @memberof ExpChangeType1Component
   */
  onClickAvailableInfo(): void {
    this.openAvailableDescriptionDialog();
  }

  /**
   * オプション詳細ダイアログ
   *
   * @param {ExpReservation["service"]["option_groups"][number]["options"][number]} option
   * @memberof ExpChangeType1Component
   */
  onClickOptionInfo(option: ExpReservation["service"]["option_groups"][number]["options"][number]): void {
    this.expServ.openOptionDialog(option);
  }

  /**
   * オプション変更(user_option: yesno)
   *
   * @param {ExpReservation["service"]["option_groups"][number]["options"][number]} option
   * @param {(SgServiceOption["yesno_param"]["items"][number] | SgServiceOption["select_param"]["items"][number])} item
   * @memberof ExpDetailComponent
   */
  onChangeOptionYesno(option: ExpReservation["service"]["option_groups"][number]["options"][number], item: SgServiceOption["yesno_param"]["items"][number]): void {
    this.putReservationOption.changeOptionYesno(option, item);
    this.switchOverTransitionBtn();
  }

  /**
   * オプション変更(user_option: select)
   *
   * @param {ExpReservation["service"]["option_groups"][number]["options"][number]} option
   * @param {SgServiceOption["select_param"]["items"][number]} item
   * @memberof ExpDetailComponent
   */
  onChangeOptionSelect(option: ExpReservation["service"]["option_groups"][number]["options"][number], item: SgServiceOption["select_param"]["items"][number]): void {
    this.putReservationOption.changeOptionSelect(option, item);
    this.switchOverTransitionBtn();
  }

  /**
   *
   *
   * @param {ExpReservation["service"]["option_groups"][number]["options"][number]} option
   * @param {number} value
   * @memberof ExpDetailComponent
   */
  onChangeOptionNumber(option: ExpReservation["service"]["option_groups"][number]["options"][number], value: number): void {
    this.putReservationOption.changeOptionNumber(option, value);
    this.switchOverTransitionBtn();
  }

  /**
   * 日数選択型オプション 変更
   *
   * @param {ExpReservation["service"]["option_groups"][number]["options"][number]} option
   * @param {number} item
   * @memberof ExpDetailComponent
   */
  onChangeOptionTime(option: ExpReservation["service"]["option_groups"][number]["options"][number], item: number) {
    this.putReservationOption.changeOptionTime(option, item);
    this.switchOverTransitionBtn();
  }

  /**
   * セレクトボックス変更時の値格納処理。
   *
   * @param {*} event
   * @return {*}  {void}
   * @memberof ExpChangeType1Component
   */
  selectedEvent(event: any): void {

    try {
      switch (event.id) {
        // 利用数、人数
        case "reserv_number":
          
          const key = Number(event.key);
        
          // リストに変更がないなら終了
          if (this.reserv_number[0].value === key) return;

          // ユーザの選択値を保存
          this.reserv_number[0].value = key;
          break;
        default:
          // 料金種別が複数ある場合
          if (!event.id.indexOf("reserv_number")){

            // 対象の要素番号（２桁（最大９９=length１００）まで対応）を取得
            const typeIndex: number = Number((<string>event.id).slice(this.reserv_number.length >= 10 ? -2 : -1));
          
            const key = Number(event.key);

            // リストに変更がないなら終了
            if (this.reserv_number[typeIndex].value ===  key) return;

            // ユーザの選択値を保存
            this.reserv_number[typeIndex].value = key;
            
            // if (this.reserv.service.capacity !== void 0) {
            //   // 操作した料金種別以外の予約数の上限値を設定
            //   let reserv_numbers = this.template.body2.filter(b => b?.id != undefined && !b?.id.indexOf("reserv_number"));
            //   reserv_numbers.forEach((n, index: number) => {

            //     // 変更したリストは処理しない
            //     if (typeIndex === index) return;

            //     const counts = this.reserv_number.reduce((pre,cur) => pre+cur.value,0);

            //     // ほかのリストを最大予約数から変更したリストの値を上限としたものに変更
            //     n.select_box.item = this.commonFunc.deepcopy(this.orgReservNumber[index].filter(item => (item.key as number + key) <= this.reserv.service.capacity - counts + this.reserv_number[index].value));
            //   });
            //   console.log(reserv_numbers);
            // }
          
            // 最少人数チェック
            if (this.reserv.service.service_type === "ACTIVITY") {
              const sumNum: number = this.reserv_number.map(num => num.value).reduce((a, b) => a + b);
              const index = this.template.body2.findIndex(b => b.id === "reserv_number" + (this.reserv.service.user_params.numbers.length - 1));
              // console.log("index: ", index, "sum: ", sumNum,  "this.template.body: ", this.template.body);
  
              if (this.err_min_number = this.expServ.isMinNumberCheck(this.reserv.service.constraints, sumNum)) 
                // todo 表示するメッセージは制約によって切り替わる。現状は配列[0]に最小人数制約が入っている前提
                this.template.body2[index].valid_msg = this.reserv.service.constraints[0].message;
              else this.template.body2[index].valid_msg = "";
            }
            // // 遷移ボタン有効/無効設定
            // if (this.reserv_number[0] !== this.reserv.numbers[0].value) this.btn_trans_disabled = false;
            // else this.btn_trans_disabled = true;

          }
          else return;
      }
    }
    finally {
      // 制約エラーがなく、種別毎の予約数に変更があるなら遷移ボタンを活性化
      // const result = this.reserv_number.filter((n, index: number) => n !== this.reserv.numbers[index].value);
      // this.err_min_number === false && result.length >= 1 ? this.btn_trans_disabled = false : this.btn_trans_disabled = true;

      this.checkCapacity();

      this.switchOverTransitionBtn();
    }
  }

  /**
   * 確認ボタン押下時のイベントハンドラ。
   *
   * @memberof ExpChangeType1Component
   */
  onConfirm(): void {
    
    // fetch用リクエストボディ作成
    let reqBody: request.ExpReservation = {
      numbers: this.reserv_number,
      as: "user",
      service_options: this.putReservationOption.createFetchOption()
    };

    // fetch
    this.busy = this.expServ.putFetchReservation(this.reserv.sg_reservation_id, reqBody).subscribe({
      next: res => {
        const body: ExpFetchReservation = res.body;

        // 次画面へ
        this.navigator.element.pushPage(ExpChangeDatermineComponent, {
          data: { 
            fetch: body,
            reservation: this.reserv,
            selected: {
              number: this.reserv_number,
              option: this.selectedOption
            },
            transition: this.params.data.transition,
          }
        });
      },
      error: this.errResServ.doParse((_err, errContent) => {
        if (errContent.smartGotoErrCode === this.appMsgServ.SERV_CONST_CODE.SG_OPTION_PRICE.CALC_FAILED) {
          const errOption: {sg_option_id: number, param: "number"|"days"|"mins", table_limit: number} = errContent.smartGotoErrOpt;
          let optionName: string = void 0;
          this.reserv.service.option_groups.some(group => {
            const result = group.options.find(option => option.sg_option_id === errOption.sg_option_id);
            if (result === void 0) return false;
            optionName = result.name;
            return true;
          });
          this.errResServ.viewErrDialog(errContent, optionName);
        }
        else this.errResServ.viewErrDialog(errContent)
      })
    });
  }

  //=============================================================================================
  // メソッド
  //=============================================================================================

  /**
   * 遷移ボタンチェック
   *
   * @private
   * @return {*}  {void}
   * @memberof ExpChangeType1Component
   */
  private switchOverTransitionBtn(): void {
    const switchBtn = (isDisplay: boolean): void => {
      if (isDisplay === true) this.btn_trans_disabled = false;
      else this.btn_trans_disabled = true;
    }
    // // 遷移ボタン有効/無効設定
    // if (this.reserv_number[0] !== this.reserv.numbers[0].value) this.btn_trans_disabled = false;
    // else this.btn_trans_disabled = true;
  
    // 最大人数エラー
    if (this.err_max_number === true || this.err_min_number === true) return switchBtn(false);

    // 制約エラーがなく、変更がない場合遷移ボタンを非活性
    const result = this.reserv_number.filter((n, index: number) => n.value !== this.reserv.numbers[index].value);
    if (result.length === 0 && this.putReservationOption.checkChangeOption() === false) return switchBtn(false);

    const optionCheckResult: boolean = Object.keys(this.selectedOption).some(id => {
      return this.selectedOption[id].time_err.check === true || this.selectedOption[id].err.check === true;
    });
    if (optionCheckResult === true) return switchBtn(false);

    switchBtn(true);
  }

  /**
   * 最大人数チェック
   *
   * @memberof ExpChangeType1Component
   */
  private checkCapacity(): void {

    if (this.reserv.service.capacity === void 0) return;

    if (this.reserv.service.service_type === 'ACTIVITY') {
      const count: number = this.reserv_number.map(num => num.value).reduce((a, b) => a + b);

      const index = this.template.body2.findIndex(b => b.id === "reserv_number" + (this.reserv.service.user_params.numbers.length - 1));

      if (count > this.reserv.service.capacity) {
        this.err_max_number = true;

        // todo: 単位
        this.template.body2[index].valid_msg = this.reserv.service.capacity + this.reserv.numbers[0].unit + "以下で予約してください。";
      }  
      else this.err_max_number = false;
    }
  }

  /**
   * 指定した日時を含む週を取得
   *
   * @private
   * @memberof ExpChangeType1Component
   */
  private getAvailableRange(time: string): {start: string, end: string} {
    if (!moment(time).isValid()) return;

    return { 
      start: moment(time).startOf('day').toISOString(),
      end: moment(time).add(6, 'days').endOf('day').toISOString()
    }
  }

  /**
   * テンプレートに表示する情報を作成する。
   *
   * @private
   * @memberof ExpChangeType1Component
   */
  private createTemplateDate(): void {
    
    let currentTotalReservNum: number = 0;

    // 予約しているサービスの最大空き数を算出
    if (this.reserv.service.service_type === "MOBILITY") {
      this.max_resources = this.reserv.service.user_params.numbers[0].select.slice(-1)[0];
    }
    else if (this.reserv.service.service_type === "ACTIVITY") {
      this.max_resources = this.reserv.service.capacity;
      // 現予約数の合計数を算出
      currentTotalReservNum = this.reserv.numbers.map(n => n.value).reduce((a, b) => a + b);
    }
    else ;
    
    // 料金種別毎に料金、予約数を作成
    let numbers: ListParts["select_box"]["item"][] = [];
    this.reserv.service.user_params.numbers.forEach((n, index: number) => {

      // 初期化
      numbers.push([]);
      this.orgReservNumber.push([]);
      
      // 予約数のセレクトボックス作成
      n.select.forEach(s => {
        const value = { key: s, name: s.toString() + n.unit };
        // 最大空き数を超えたらなら終了
        if (s > n.to) return;

        // オリジナルに保存
        this.orgReservNumber[index].push(value);
        numbers[index].push(value);
      });
    });
    
    this.template = {
      header: {
        mode: "header", 
        header_list: {
          item: [this.reserv.service.title, "提供：" + this.reserv.service.shop.name],
          icon: this.reserv.service.images[0] ?? ""
        }
      }, 
      body1: [{
        displayOrder: 1, 
        header: '利用開始時刻',
        common: {
          item: dayjs(this.reserv.start.schd_time).format("M月D日 H:mm")
        }
      }, {
        displayOrder: 2, 
        header: '利用終了時刻',
        common: {
          item: dayjs(this.reserv.end.schd_time).format("M月D日 H:mm")
        }
      }],
      body2: []
    };

    // 料金
    // viewPrice.forEach((p, index: number) => {
    //   this.template.body.push({
    //     displayOrder: 1, 
    //     id: "price", 
    //     common: { item: p } 
    //   });
    //   // ヘッダーは先頭のみ
    //   if (index === 0) {
    //     this.template.body[this.template.body.length - 1].header = "お支払い料金";
      
    //     // 料金種別が複数 & アクティビティサービスの場合、料金詳細説明(infoボタン)を表示
    //     if (this.reserv.numbers.length >= 2 && this.reserv.service.service_type === "ACTIVITY") {
    //       const diff = (new Date(this.reserv.end.schd_time).getTime() - new Date(this.reserv.start.schd_time).getTime()) / (60 * 1000);
    //       this.template.body[this.template.body.length - 1].header_dialog_info = this.expServ.getPriceDescription(this.reserv.service.user_params.numbers, this.reserv.service.prices[0], diff);
    //     }
    //   }
    // });
    
    if (this.reserv.service.service_type === "MOBILITY") {
      this.template.body2.push({
        displayOrder: 2, 
        header: '貸出場所',
        common: {
          item: this.reserv.start.place.name,
          link: this.commonFunc.getGoogleMapUrl(this.reserv.start.place.location)
        }
      }, 
      {
        displayOrder: 3, 
        header: '返却場所',
        common: {
          item: this.reserv.end.place.name,
          link: this.commonFunc.getGoogleMapUrl(this.reserv.end.place.location)
        }
      },
      {
        displayOrder: 1, 
        id: "reserv_number", 
        mode: numbers[0].length > 1 ? "selectBox" : "common", 
        header: "予約数",
        select_box: {
          item: numbers[0], 
          select_id: "reserv_number", 
          initial_select: this.reserv.numbers[0].value
        }, 
        common: {
          item: numbers[0][0].name ?? "", 
        }
      });
    }
    else if (this.reserv.service.service_type === "ACTIVITY") {
      this.template.body2.push({
        displayOrder: 2, 
        header: '開催場所',
        common: {
          item: this.reserv.start.place.name,
          link: this.commonFunc.getGoogleMapUrl(this.reserv.start.place.location)
        }
      });
      
      numbers.forEach((r, index: number) => {
        this.template.body2.push({
          displayOrder: 2, 
          id: "reserv_number" + index,  
          mode: "selectBox", 
          select_box: {
            item: r, 
            select_id: "reserv_number" + index, 
            initial_select: this.reserv.numbers[index].value
          }
        });
        
        // 料金種別が複数ある場合のみ種別名を表示
        const length = this.reserv.numbers.length;
        if (length >= 2) this.template.body2[this.template.body2.length - 1].select_box.type_name = this.reserv.numbers[index].name;

          // todo　capacityの単位をどうだすか（10「人」の部分）
        if (index === 0) {
          this.template.body2[this.template.body2.length - 1].header = this.reserv.service.capacity ? "予約数" + (length > 1 ? "（最大：" + this.reserv.service.capacity + this.reserv.numbers[index].unit + "）" : "") : "予約数";
        }
      });
    }
    else ;

    // 表示順にソート
    this.template.body2.sort((a, b) => a.displayOrder - b.displayOrder);
  }
  
  /**
   * 選択中の日付を設定する。
   *
   * @memberof ExpChangeType1Component
   */
  set selectedDate(date: string) {

    if (moment(date).isValid()) {

      // 選択前の日付
      const old_date = this._selectedDate;
      // 選択後の日付
      const formatted_date = moment(date).format(moment.HTML5_FMT.DATE);
      // 選択日を変更
      this._selectedDate = formatted_date;

      // 異なる日付を選択した場合
      if (old_date != formatted_date) {
        // 変更期間取得
        this.dispAvailableRange = this.getAvailableRange(formatted_date);

        this.calendar.getApi().removeAllEvents();
        
        // 週カレンダーの表示範囲を切り替え
        this.calendar.getApi().gotoDate(formatted_date);
        this.calendar.getApi().el.querySelector<HTMLButtonElement>('.fc-today-button').disabled = false;  // 今日ボタンの非活性を無効化

        //  1週間の予約を取得して、カレンダーを更新
        this.communicationsCommand(true);

        // 今日ボタンの非活性処理
        this.setTodayButtonDisabled();
      }
    }
    else {
      console.error(`解釈不能な日付: ${date}`);
    }
  }

  /**
   *
   *
   * @private
   * @memberof ExpChangeType1Component
   */
  private addCustomEventSource(): void {

    // イベントソース
    let events: EventInput[] = [];

    /**
     * カレンダーに表示する空き情報を示した文字列
     * 
     * @memberof ExpChangeType1Component
     */
    const available = {
      OK: "〇", 
      NG: "×" 
    }

    /**
     * イベントソース作成
     *
     * @param {string} status
     * @param {Date} start
     * @param {Date} end
     */
    const createEvents = (status: string, start: Date, end: Date) => {

      const bgColor = status === available.OK ? "white" : "lightgray";
      events.push({ title: status, start: start, end: end, backgroundColor: bgColor });
    };

    this.targetResource.availables.forEach(r => {

      const start = new Date(r.from);
      let end = new Date(r.from);
      end.setMinutes(end.getMinutes() + 30);

      // 選択している予約総数を取得
      const total_reservnum = this.reserv_number.map(num => num.value).reduce((a, b) => a + b);

      // 予約締切を過ぎている場合はNG
      const deadline_prop = this.reserv.service.reserve_deadline;
      if (deadline_prop.acpt_onday===true) {
        /** 締切時刻(これより後ろの時刻開始のサービスしか予約できない) */
        const deadline = moment().add(deadline_prop.acpt_time, 'm');
        if (deadline.isSameOrAfter(start)) {
          createEvents(available.NG, start, end);
          return;
        };
      }
      else if (deadline_prop.acpt_onday===false) {
        const deadline_time = deadline_prop.to.time.match(/(\d+):(\d+)/);
        /** 本日期限 */
        const today_deadline = moment().hour(Number(deadline_time[1])).minute(Number(deadline_time[2])).startOf('minute');
        /** 締切時刻(これより後ろの時刻開始のサービスしか予約できない) */
        let deadline: moment.Moment;
        if (moment().isBefore(today_deadline)) {
          deadline = moment().add(deadline_prop.to.day, 'd').startOf('D');  // N日後からの予約が出来る  (N=deadline_prop.to.day)
        }
        else {
          deadline = moment().add(deadline_prop.to.day+1, 'd').startOf('D');  // N日後の予約期限も過ぎているため、N+1日後からの予約が出来る
        }
        if (deadline.isSameOrAfter(start)) {
          createEvents(available.NG, start, end);
          return;
        }
      }

      // ----------------------------------------------------
      // 空き日程 〇×設定
      //----------------------------------------------------
      const targetHour: ShopCalendar = this.shopSchedule.find(hour => moment(hour.date).isSame(r.from, 'day'));

      let isBusiness: boolean;

      // 営業時間が設定されていない
      if (targetHour === void 0) isBusiness = false;
      else {// 営業時間内かどうかチェック
        isBusiness = targetHour.opening_hours.some(opening => {
          const from = moment(opening.shipping.from, 'HH:mm');
          const to = moment(opening.shipping.to, 'HH:mm');
  
          return moment(targetHour.date).set({'hour': from.hour(), 'minute': from.minute()}).isSameOrBefore(moment(r.from).format('YYYY-MM-DD HH:mm')) 
          && moment(targetHour.date).set({'hour': to.hour(), 'minute': to.minute()}).isSameOrAfter(moment(r.to).format('YYYY-MM-DD HH:mm'));
        });
      }

      /**
       * 〇×設定
       * @param reservable 
       */
      const createSchedule = (reservable: boolean) => {

        // 予約可能（営業時間内＆空きあり）
        if (isBusiness === true && reservable === true) {
          events.push({title: "〇", start: start, end: end, backgroundColor: "white"});
        }
        // 予約不可
        else {
          events.push({title: "×", start: start, end: end, backgroundColor: "lightgray"});
        }
      };

      // モビリティ
      if (this.reserv.service.service_type === 'MOBILITY') {
        // 空きがあるかチェック(空きが0以上)
        const reservable: boolean = r.available_number === 1 ? true : false;
        createSchedule(reservable);
      }
      // アクティビティ
      else if (this.reserv.service.service_type === 'ACTIVITY') {
        // 空きがあるかチェック(選択中利用数が空き数に達しているか)
        const reservable: boolean = r.available_number - total_reservnum >= 0 && r.available_number !== 0 ? true : false;
        createSchedule(reservable);
      }
      else {}
    });

    console.log(events);

    // イベント登録
    this.calendar.getApi().addEventSource(events);
  }

  /**
   * カレンダーの週変更時の設定処理
   *
   * @private
   * @param {string} [weekType] next：翌週 prev：前週
   * @return {*}  {void}
   * @memberof ExpChangeType1Component
   */
  private setCalendarWeekChange(weekType: string){
    
    switch (weekType) {
      case this.calendarWeekType.next:
        this.calendar.getApi().next();
        break;
      case this.calendarWeekType.prev:
        this.calendar.getApi().prev();
        break;
      default:
        break;
    }

    const startDayWeek = this.calendar.getApi().view.activeStart;
    const date = moment(startDayWeek).format(moment.HTML5_FMT.DATE);
    this.selectedDate = date;
  }

  /**
   * 今日ボタン非活性判定処理
   * カレンダー表示の先頭（左列）が現在日である場合、今日ボタンを非活性に設定する
   *
   * @private
   * @return {*}  {void}
   * @memberof ExpChangeType1Component
   */ 
  private setTodayButtonDisabled() {
    const today = moment().format(moment.HTML5_FMT.DATE);
    if (moment(this.calendar.getApi().view.activeStart).format(moment.HTML5_FMT.DATE) == today) {
      this.calendar.getApi().el.querySelector<HTMLButtonElement>('.fc-today-button').disabled = true;
    }
  }

  /**
   * カレンダーの見方 ダイアログを表示
   *
   * @private
   * @return {*}  {void}
   * @memberof ExpChangeType1Component
   */
  private openAvailableDescriptionDialog() {
    this.dialog.open(ExpDetailAvailableDescriptionDialog,{
      data: {displayText : false}
    });
  }

  //=============================================================================================
  // サーバ通信
  //=============================================================================================

  /**
   * 
   *
   * @private
   * @memberof ExpChangeType1Component
   */
  private communicationsCommand(isGetBusiness: boolean): void {
    let proc: Observable<any>[] = [];
    proc.push(this.getAvailable());
    if (isGetBusiness === true) proc.push(this.getShopCalendar());

    this.busy = forkJoin(proc).subscribe({
      next: result => {
        this.resourceAvailable = result[0].body.resources;

        this.shopSchedule = result[1] ? result[1].body: [];

        // 初期値は一つ目のリソース
        this.targetResource = this.resourceAvailable[0];

        this.addCustomEventSource();

        // テンプレート情報作成
        this.createTemplateDate();

        // ----------------------------------------------------
        // スクロール初期表示時刻、設定
        // ----------------------------------------------------

        let minStartHours: string = "";

        this.shopSchedule.forEach((shop) => {
          
          if (shop?.opening_hours?.length > 0) {
            // 開始日時
            let start: string = "";
            shop.opening_hours.forEach((opening_hour) => {
              
              if (start === "") start = opening_hour.shipping.from;
              // 同じ日に複数営業時間が設定されている場合、
              // start：開始時刻内で一番早い時刻, end: 終了時刻内で一番遅い時刻
              else start = start < opening_hour.shipping.from ? start : opening_hour.shipping.from;
            });

            // minStartHours: 週で一番早い営業開始時間
            // minEndHours: 週で一番遅い営業終了時間
            if (minStartHours === "") minStartHours = start;
            else minStartHours = minStartHours < start ? start : minStartHours;
          }
        });
        // 営業時間未設定の場合、8時に設定
        if (minStartHours === "") minStartHours = "8:00";
        setTimeout(() => {
          // スクロール初期表示時刻、設定
          this.calendar.getApi()?.scrollToTime(minStartHours);
        });
      },
      error: this.errResServ.doParse((_err, errContent) => this.errResServ.viewErrDialog(errContent))
    })
    
  }

  /**
   * サービスの空き情報を取得する。
   *
   * @private
   * @return {*}  {Observable<HttpResponse<ExpResourceAvailable>>}
   * @memberof ExpDetailComponent
   */
  private getAvailable(): Observable<HttpResponse<ExpResourceAvailable>> {
    
    const param: parameter.ExpAvailable = {
      sg_service_id : this.reserv.service.sg_service_id,
      sg_reservation_id: this.reserv.sg_reservation_id, 
      date_from: this.dispAvailableRange.start, 
      date_to: this.dispAvailableRange.end, 
      util_time: 30
    };
    
    return this.expServ.getResourceAvailable(param);
  }

  /**
   * 店舗の営業時間を取得する。
   *
   * @private
   * @param {boolean} [update=true]
   * @param {string} [targetDate=""]
   * @return {*}  {Observable<HttpResponse<ShopCalendar[]>>}
   * @memberof ExpChangeType1Component
   */
  private getShopCalendar(): Observable<HttpResponse<ShopCalendar[]>> {

    // サーバ通信
    return this.expServ.getShopCalendar(this.reserv.service.shop.shop_id, this.dispAvailableRange.start, this.dispAvailableRange.end);
  }

}
