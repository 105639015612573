import { Component, OnDestroy, OnInit } from '@angular/core';

import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';
import { UserWebApiService } from 'src/app/http-services/user-web-api.service';
import { ApplicationMessageService } from 'src/app/lib-services/application-message.service';
import { HttpErrorResponseParserService } from 'src/app/lib-services/http-error-response-parser.service';
import * as CONST from "../../../constants/constant";

import { Settings } from 'src/app/interfaces/response';
import { MESSAGE } from 'src/app/constants/message';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ons-page[app-withdrawal]',
  templateUrl: './withdrawal.component.html',
  styleUrls: ['./withdrawal.component.scss']
})
export class WithdrawalComponent implements OnInit, OnDestroy {

  /**
   * Subscriptionオブジェクト
   *
   * @type {Subscription}
   * @memberof WithdrawalComponent
   */
  busy: Subscription;

  /**
   * オフィス情報
   *
   * @type {Settings["office"]}
   * @memberof WithdrawalComponent
   */
  office: Settings["office"];

  /**
   * 同意チェック
   *
   * @type {boolean}
   * @memberof WithdrawalComponent
   */
  checked: boolean = false;

  constructor(
    private municipalityWebApiServ: MunicipalityWebApiService,
    private userWebApiServ: UserWebApiService,
    private appMsgServ: ApplicationMessageService,
    private msg: MESSAGE,
    private errResServ: HttpErrorResponseParserService,
  ) { }

  ngOnInit(): void {
    this.office = this.municipalityWebApiServ.setting.office;
  }

  ngOnDestroy(): void {
    this.busy?.unsubscribe();
  }

  /**
   * アカウント削除ボタンclick
   *
   * @memberof WithdrawalComponent
   */
  pushWithdrawalButton() {
    this.appMsgServ.confirmMessage(this.msg.CLIENT.WITHDRAWAL.CONFIRM.message(), (value) => {
      if (value === CONST.Common.CONFIRM_YES) {
        this.withdrawal();
      }
    });
  }
  //=============================================================================================
  // サーバー通信
  //=============================================================================================

  /**
   * 退会リクエスト
   *
   * @private
   * @memberof WithdrawalComponent
   */
  private withdrawal(): void {
    this.busy = this.userWebApiServ.withdrawal().subscribe({
      next: () => {
        this.appMsgServ.viewDialogMessage(this.msg.CLIENT.WITHDRAWAL.COMPLETE.message(), () => {
          // 退会後、サーバーでログイン情報が削除されるため、以下の関数を呼び出す
          this.userWebApiServ.refreshUserData();
        });
      },
      error: this.errResServ.doParse((_err, errContent) => { this.errResServ.viewErrDialog(errContent); })
    });
  }
}
