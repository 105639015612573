<ons-toolbar modifier="header" class="toolbar">
  <div class="left">
    <ons-back-button></ons-back-button>
  </div>
  <div class="center">メニュー詳細</div>
</ons-toolbar>

<div class="content">
  <!-- 商品名 -->
  <ons-list class="list-item__title list-header__title--sticky shop-info-wrap header">
    {{ selectMenu.menu.name }} ￥{{ selectMenu.menu.price | number:'.0-0' }}
  </ons-list>
  
  <!-- 商品画像 -->
  <div class="swiper-area">
    <!-- 配下の画像をswiperとして表示 -->
    <swiper *ngIf="selectMenu.menu.images?.length > 0; else noPhoto" 
      [slidesPerView]="1" [spaceBetween]="50" [navigation]="true" [pagination]="{ clickable: true }"
      [controller]="{ control: controlledSwiper }" id="{{ selectMenu.menu.menu_id }}">

      <ng-container *ngFor="let image of selectMenu.menu.images">
        <ng-template swiperSlide>
          <div class="image--background">
            <img [src]="image" (click)="onEnlargeImage()">
          </div>
        </ng-template>
      </ng-container>
    </swiper>
  </div>
  
  <ons-list>
    <ons-list-header modifier="color" class="list-item__title">メニュー説明</ons-list-header>
    <ons-list-item modifier="longdivider" class="list-item__text">
      <div id="shopping-menu-detail_004" class="list-item__text--no-wrap" *ngIf="selectMenu.menu.description; else noData">
        <span [innerHTML]="dispDescription"></span>
        <!-- {{ selectMenu.menu.description }} -->
      </div>
    </ons-list-item>

    <ons-list-header modifier="color" class="list-item__title">カテゴリ</ons-list-header>
    <ons-list-item modifier="longdivider" class="list-item__text">
      <ng-container id="shopping-menu-detail_005" *ngIf="selectMenu.menu.categories?.length > 0; else noData">
        {{ displayCategories }}
      </ng-container>
    </ons-list-item>

    <ons-list-header modifier="color" class="list-item__title">アレルギー品目</ons-list-header>
    <ons-list-item modifier="longdivider" class="list-item__text">
      <ng-container id="shopping-menu-detail_006" *ngIf="selectMenu.menu.allergens?.length > 0; else noData">
        {{ displayAllergens }}
      </ng-container>
    </ons-list-item>

    <ons-list-header modifier="color" class="list-item__title">個数</ons-list-header>
    <ons-list-item modifier="nodivider" *ngIf="selectItemsMaxCount != 0; else maxCount">
      <div class="list-item-wrap-center list-item-count-wrap">
        <button mat-icon-button id="shopping-menu-detail_007" class="button--count" (click)="countDown()" [disabled]="isDisabledCountDown">
          <mat-icon>remove_circle_outline</mat-icon>
        </button>
        <div id="shopping-menu-detail_008" class="list-item-count-wrap__number">{{ selectMenu.count }}</div>
        <button mat-icon-button id="shopping-menu-detail_009" class="button--count" (click)="countUp()" [disabled]="isDisabledCountUp">
          <mat-icon>add_circle_outline</mat-icon>
        </button>
      </div>
      <div  *ngIf="selectMenu.count == selectItemsMaxCount" class="list-item-wrap-center list-item-count-msg">
        <p class="valid-msg">
          <span>{{cliantErrMsg.MAX_ORDER_ITEM_COUNT}}</span>
        </p>
      </div>
      <div class="list-item-wrap-center delete-link" *ngIf="menuProcMode.delete_permit">
        <p><span id="shopping-menu-detail_010" class="delete-link__text" (click)="deleteItem()">カートから削除</span></p>
      </div>
    </ons-list-item>
  </ons-list>

  <div class="button-wrap button-sticky">
    <ons-button id="shopping-menu-detail_011" *ngIf="menuProcMode.mode == 'add' && selectItemsMaxCount != 0" modifier="large--cta" (click)="addItem()">
      カートに{{ selectMenu.count }}個追加 ￥{{ totalPrice | number:'.0-0' }}
    </ons-button>
    <!-- <ons-button *ngIf="menuProcMode.mode == 'update'" [disabled]="isDisabledUpdateButton" modifier="large--cta" (click)="updateItem()"> -->
    <ons-button id="shopping-menu-detail_012" *ngIf="menuProcMode.mode == 'update'" modifier="large--cta" (click)="updateItem()">
      カートの更新 ￥{{ totalPrice | number:'.0-0' }}
    </ons-button>
  </div>

  <!-- template -->
  <ng-template #noData><mat-icon>remove</mat-icon></ng-template>
  <ng-template #maxCount>
    <ons-list-item>
      <p class="valid-msg">
        <span class="valid-msg__count" *ngIf="selectItemsMaxCount == 0">{{cliantErrMsg.MAX_ORDER_ITEM_COUNT}}</span>
        <span *ngIf="selectItemsMaxCount == 0">{{cliantErrMsg.ERR_ALREADY_MAX_COUNT}}</span>
      </p>
    </ons-list-item>
  </ng-template>
  <ng-template #noPhoto>
    <div class="image--background image-center">
      <img id="shopping-menu-detail_002" [src]="noImages">
    </div>
  </ng-template>
</div>