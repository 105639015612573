<ons-toolbar>
  <div class="left">
    <ons-back-button></ons-back-button>
  </div>
  <div class="center">注文内容確認</div>
</ons-toolbar>

<div class="content">
  <div [ngBusy]="busy"></div>

  <!-- 店舗名 -->
  <ons-list class="list-item__title shop-info-wrap">
    <ons-list-item>
      <!-- 店舗アイコン/店舗名 -->
      <ons-row vertical-align="center">
        <ons-col width="120px" class="list-icon-col"><span *ngIf="shop.icon !== undefined && shop.icon !== ''; else noIcon"><img class="shop-info-wrap__img" [src]="shop.icon" /></span></ons-col>
        <ons-col id="shopping-determine_001">{{ shop.name }}</ons-col>
      </ons-row>
    </ons-list-item>
  </ons-list>

  <!-- 注文内容 -->
  <div class="list-item__caption">お支払い料金</div>
  <ons-list>
    <ons-list-item id="deliveryMenu-list_" modifier="nodivider" class="deliveryMenu">
      <!-- 商品一覧 -->
      <ons-row vertical-align="center" class="list-item__menu" *ngFor="let item of items; index as i">
        <ons-col width="50%" id="shopping-determine-menu-name_00{{i+1}}">{{ item.menu.name }}</ons-col>
        <ons-col width="10%" id="shopping-determine-menu-count_00{{i+1}}" class="text--right">{{ item.count }}個</ons-col>
        <ons-col class="amount" id="shopping-determine-menu-subtotal_00{{i+1}}">￥{{ (item.menu.price * item.count) | number:'.0-0' }}</ons-col>
      </ons-row>
      <ons-row vertical-align="center" class="list-item__menu">
        <ons-col width="60%">配送料</ons-col>
        <ons-col class="amount" id="shopping-determine_002">￥{{ plan.total.price | number:'.0-0' }}</ons-col>
      </ons-row>
    </ons-list-item>
    <ons-list-item modifier="nodivider" class="deliveryMenu">
      <div class="total">
        <div class="total-item__amount">お支払い料金(合計)：
          <span class="amount" id="shopping-determine_003">￥{{ getTotalPrice() | number:'.0-0' }}</span>
        </div>
        <div id="shopping-determine_004">請求先：{{ plan.payer.name }} 様</div>
      </div>
    </ons-list-item>
  </ons-list>

  <div class="list-item__caption">お届け先</div>
  <ons-list>
    <ons-list-item modifier="longdivider" class="deliveryMenu">
      <p>
        <a class="list-item__link" target="_blank" [href]="place.google_map_url">
          <div id="shopping-determine_005">{{ place.name }}</div>
        </a>
      </p>
    </ons-list-item>
  </ons-list>

  <div class="list-item__caption">配達希望日時</div>
  <ons-list>
    <ons-list-item modifier="longdivider" class="deliveryMenu">
      <p id="shopping-determine_006">{{ plan.shop[0].day }} {{ plan.shop[0].time_zone.from_str }}～{{ plan.shop[0].time_zone.to_str }}</p>
    </ons-list-item>
  </ons-list>

  <div class="list-item__caption">キャンセル期限</div>
  <ons-list>
    <ons-list-item id="deliveryMenu-list_" modifier="longdivider" class="deliveryMenu">
      <p id="shopping-determine_007">{{ conversionTimezone(plan.cancelable_time) }}</p>
    </ons-list-item>
  </ons-list>

<!-- 【UI改善対応】キャンセルポリシーを確定ボタン下に移した
  <div class="text--right">
    キャンセルポリシーは<ons-button id="shopping-determine_008" modifier="quiet" (click)="onCancelPolicy()"><span class="button-text">こちら</span></ons-button>
  </div>
-->

  <div class="button-wrap">
    <ons-button id="shopping-determine_009" [disabled]="isRequesting" modifier="large--cta" (click)="onOrderConfirmation()">注文を確定する</ons-button>
  </div>

  <!-- 利用規約とキャンセルポリシー -->
  <div class="content-description">
    「確定する」ボタンを押して頂くことで、お客様は本地域におけるアプリケーションおよびサービスの
    <ons-button modifier="quiet" (click)="viewTermsOfService()" class="content-description_button"><span class="button-text">利用規約</span></ons-button>
    、
    <ons-button id="shopping-determine_008" modifier="quiet" (click)="onCancelPolicy()" class="content-description_button"><span class="button-text">キャンセルポリシー</span></ons-button>
    に同意の上、ご予約・ご注文されたことになります。
  </div>

  <!-- template -->
  <!-- 店舗アイコンがない場合、汎用アイコンを表示 -->
  <ng-template #noIcon><mat-icon class="shop-info-wrap__mat-icon">store</mat-icon></ng-template>
</div>
