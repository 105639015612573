//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit, OnDestroy } from '@angular/core';
import { OnsNavigator, Params } from 'ngx-onsenui';
import { Subscription } from 'rxjs';
import * as CONST from "../../../constants/constant";

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { FamilyWebApiService } from '../../../http-services/family-web-api.service';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';

// interface
import { Relation, Settings } from '../../../interfaces/response';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * ファミリーリンク削除画面。
 *
 * @export
 * @class FamilyDeleteComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @author （株）オブジェクトデータ wada
 */
@Component({
  selector: 'ons-page[family-delete]',
  templateUrl: './family-delete.component.html',
  styleUrls: ['./family-delete.component.css']
})
export class FamilyDeleteComponent implements OnInit, OnDestroy {

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * ファミリー情報のSubscriptionオブジェクト
   *
   * @type {Subscription}
   * @memberof FamilyComponent
   */
  busy: Subscription;

  /**
   * ファミリー情報
   *
   * @type {Relation}
   * @memberof FamilyDeleteComponent
   */
  relation: Relation;
  
  /**
   * ログインユーザのファミリーロール
   *
   * @type {string}
   * @memberof FamilyComponent
   */
  loginUserRole: string;

  /**
   * 削除モード
   *    family　：ファミリーリンク解除
   *    account ：アカウント削除（代理アカウント機能）
   *
   * @type {('family' | 'account')}
   * @memberof FamilyDeleteComponent
   */
  deleteMode: 'family' | 'account';

  /**
   * 同意チェック
   *
   * @type {boolean}
   * @memberof WithdrawalComponent
   */
  checked: boolean = false;

  /**
   * オフィス情報
   *
   * @type {Settings["office"]}
   * @memberof WithdrawalComponent
   */
  office: Settings["office"];

//=============================================================================================
// ライブサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of FamilyDeleteComponent.
   * @param {OnsNavigator} navigator
   * @param {ApplicationMessageService} appMsgServ
   * @param {HttpErrorResponseParserService} errResServ
   * @param {UserWebApiService} userServ
   * @param {FamilyWebApiService} familyServ
   * @param {DispatchWebApiService} dispatchServ
   * @param {Params} params
   * @memberof FamilyDeleteComponent
   */
  constructor(
    private navigator: OnsNavigator,
    private appMsgServ: ApplicationMessageService, 
    private errResServ: HttpErrorResponseParserService, 
    private municipalityWebApiServ: MunicipalityWebApiService,
    private familyServ: FamilyWebApiService, 
    private params: Params,
    private msg: MESSAGE,
  ) 
  { }

  /**
   * 初期化処理。
   *
   * @memberof FamilyDeleteComponent
   */
  ngOnInit(): void {

    this.relation = this.params.data.relation;
    this.deleteMode = this.params.data.deleteMode;
    this.office = this.municipalityWebApiServ.setting.office;

    // ログインユーザのファミリーロールを取得
    this.loginUserRole = this.familyServ.getLoginUserRole();
  }

  /**
   * 破棄処理。
   *
   * @memberof FamilyDeleteComponent
   */
  ngOnDestroy(): void {

    this.busy?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * ファミリーリンク解除/解除申請却下を行う。
   *    ファミリー管理者機能。
   *
   * @memberof FamilyDeleteComponent
   */
  deleteFamily(approval: boolean): void {

    // 予約などがある場合は解除不可
    if (approval) this.deleteRelation();
    else this.rejectDeleteRelation();
  }

  /**
   * ファミリーリンク解除申請を行う。
   *    ファミリーメンバー機能。
   *
   * @memberof FamilyDeleteComponent
   */
  deleteRequest(): void {

    this.requestDeleteRelation();
  }

  /**
   * 見守り対象アカウント削除を行う。
   *    ファミリー管理者機能。
   *
   * @memberof FamilyDeleteComponent
   */
  deleteAccount(): void {
    
    const name = this.relation.name.family_name + "&ensp;" + this.relation.name.given_name;
    this.appMsgServ.confirmMessage(this.msg.CLIENT.WITHDRAWAL.CONFIRM_ALT.message(name), (value) => {
      if (value === CONST.Common.CONFIRM_YES) {
        this.deleteAltAccount();
      }
    });
  }

//=============================================================================================
// メソッド
//=============================================================================================

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * ファミリーリンクの削除を行う。
   *    ファミリー管理者機能
   *
   * @private
   * @memberof FamilyDeleteComponent
   */
  private deleteRelation(): void {
    
    this.familyServ.deleteRelation(this.relation.relation_id).subscribe({
      next: () => {
        this.appMsgServ.viewDialogMessage(this.msg.CLIENT.FAMILY.DELETE_RELATION.message(), () => this.params.data.class.refresh());
      }, 
      error: this.errResServ.doParse((_err, errContent) => { this.errResServ.viewErrDialog(errContent); })
    });
  }

  /**
   * ファミリーリンク解除申請の拒否を行う。
   *    ファミリー管理者機能
   *
   * @private
   * @memberof FamilyDeleteComponent
   */
  private rejectDeleteRelation(): void {

    this.familyServ.rejectDeleteRelation(this.relation.relation_id).subscribe({
      next: () => {
        this.appMsgServ.viewDialogMessage(this.msg.CLIENT.FAMILY.CONFIRM_NG.message(), () => {
          this.params.data.class.getFamily();
          this.navigator.element.popPage();
        });
      }, 
      error: this.errResServ.doParse((_err, errContent) => { this.errResServ.viewErrDialog(errContent); })
    });
  }
  
  /**
   * ファミリーリンク解除申請を行う。
   *    ファミリーメンバー機能
   *
   * @private
   * @memberof FamilyDeleteComponent
   */
  private requestDeleteRelation(): void {

    this.familyServ.reqDeleteRelation(this.relation.relation_id).subscribe({
      next: () => {
        this.appMsgServ.viewDialogMessage(this.msg.CLIENT.FAMILY.DELETE_RELATION_REQUEST.message(), () => {
          this.params.data.class.getFamily();
          this.navigator.element.popPage();
        });
      }, 
      error: this.errResServ.doParse((_err, errContent) => { this.errResServ.viewErrDialog(errContent); })
    });
  }

  /**
   * 見守りアカウントを削除する。
   *
   * @private
   * @memberof FamilyDeleteComponent
   */
  private deleteAltAccount(): void {

    this.busy = this.familyServ.deleteProxy(this.relation.user_id).subscribe({
      next: () => {
        this.appMsgServ.viewDialogMessage(this.msg.CLIENT.WITHDRAWAL.COMPLETE_ALT.message(), () => this.params.data.class.refresh());
      }, 
      error: this.errResServ.doParse((_err, errContent) => { this.errResServ.viewErrDialog(errContent); })
    });
  }
}
