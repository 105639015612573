import { ModuleWithProviders, NgModule } from '@angular/core';
import { SpotGeolocationServiceConfig } from '../http-services/spot-geolocation.service';

/**
 * ユーティリティサービス (デバイスやスポットの位置情報に関するもの) に依存性を注入するためのインジェクターを構成するモジュール。
 *
 * @export
 * @class SpotGeolocationModule
 */
@NgModule()
export class SpotGeolocationModule {
  /**
   * DI トークンと依存性の関連付けを行う。
   *
   * @static
   * @param {SpotGeolocationServiceConfig} config 構成設定。
   * @return {*}  {ModuleWithProviders<SpotGeolocationModule>} `ModuleWithProviders<SpotGeolocationModule>`
   * @memberof SpotGeolocationModule
   */
  static withConfig(config: SpotGeolocationServiceConfig): ModuleWithProviders<SpotGeolocationModule> {
    return {
      ngModule: SpotGeolocationModule,
      providers: [
        { provide: SpotGeolocationServiceConfig, useValue: config }
      ]
    };
  }
}
