//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit } from '@angular/core';
import { UserAgentService } from '../../../../http-services/user-agent.service';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 推奨環境画面。
 *
 * @export
 * @class RecommendedEnvironmentComponent
 * @implements {OnInit}
 * @author （株）オブジェクトデータ wada
 */
@Component({
  selector: 'ons-page[recommended-environment]',
  templateUrl: './recommended-environment.component.html',
  styleUrls: ['./recommended-environment.component.css']
})
export class RecommendedEnvironmentComponent implements OnInit 
{

//=============================================================================================
// プロパティ定義
//============================================================================================= 

  /**
   * 推奨環境情報
   *
   * @memberof RecommendedEnvironmentComponent
   */
  recommendedEnvironment = this.userAgentService.recommendedEnvironment;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of RecommendedEnvironmentComponent.
   * @param {UserAgentService} userAgentService
   * @memberof RecommendedEnvironmentComponent
   */
  constructor(
    private userAgentService: UserAgentService
  ) { }

  /**
   * 初期化処理。
   *
   * @memberof RecommendedEnvironmentComponent
   */
  ngOnInit(): void 
  {
    // 推奨環境情報を取得する
    // this.recommendedEnvironment = this.userAgentService.recommendedEnvironment;
  }
}
