//=============================================================================================
// インポート
//=============================================================================================
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OnsNavigator, Params } from 'ngx-onsenui';
import { Observable, Subscription } from 'rxjs';

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { FamilyWebApiService } from 'src/app/http-services/family-web-api.service';
import { HttpErrorResponseParserService } from 'src/app/lib-services/http-error-response-parser.service';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';

// component
import { PincodeAuthComponent, QR_MODE } from '../../pincode-auth/pincode-auth.component';

// module
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';

// parts
import { QrObject } from '../../parts/qr-read/qr-read.component';
import { request } from 'src/app/interfaces/request';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 *ファミリー招待画面（STEP1）
 *
 * @export
 * @class FamilyInviteQrComponent
 * @implements {OnInit}
 * @implements {AfterViewInit}
 * @implements {OnDestroy}
 * @implements {FaceAuthStep1Context}
 */
@Component({
  selector: 'ons-page[family-invite-qr]',
  templateUrl: './family-invite-qr.component.html',
  styleUrls: ['./family-invite-qr.component.css']
})
export class FamilyInviteQrComponent implements OnInit, AfterViewInit, OnDestroy {

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * 非同期処理の実行状態を表す。
   *
   * @type {Subscription}
   * @memberof FamilyInviteQrComponent
   */
  m_Busy: Subscription;

  /**
   * 撮影状態
   *
   * @type {boolean}
   * @memberof FamilyInviteQrComponent
   */
  filming: boolean = true;

  /**
   * 運営事務所名
   *
   * @type {string}
   * @memberof FamilyInviteQrComponent
   */
  officeName: string;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of FamilyInviteQrComponent.
   * @param {OnsNavigator} _navigator
   * @param {ApplicationMessageService} appMsgServ
   * @param {CommonFunctionModule} commonFuncMdl
   * @param {FamilyWebApiService} familyServ
   * @param {HttpErrorResponseParserService} errResServ
   * @memberof FamilyInviteQrComponent
   */
  constructor(
    private _navigator: OnsNavigator,
    private appMsgServ: ApplicationMessageService,
    private commonFuncMdl: CommonFunctionModule,
    private familyServ: FamilyWebApiService,
    private errResServ: HttpErrorResponseParserService,
    private municipalityWebApiServ: MunicipalityWebApiService,
    private msg: MESSAGE,
  ) {}
  
  /**
   * コンポーネントが初期化される際に呼び出されるメソッド。
   *
   * @memberof FamilyInviteQrComponent
   */
  ngOnInit(): void {
    this.officeName = this.municipalityWebApiServ.setting.office.office_name;
  }

  /**
   * コンポーネントのビューが初期化された後に呼び出されるメソッド。
   *
   * @memberof FamilyInviteQrComponent
   */
  ngAfterViewInit(): void {
  }

  /**
   * コンポーネントが破棄される際に呼び出されるメソッド。
   *
   * @memberof FamilyInviteQrComponent
   */
  ngOnDestroy(): void {
    this.m_Busy?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * SmartGOTO運営事務所情報を出すメソッド
   *
   * @memberof FamilyInviteQrComponent
   */
  viewOfficeMessage(): void {
    this.appMsgServ.userNotification(this.officeName);
  }

  /**
   * QR読み取り
   *
   * @param {QrObject} qrObject
   * @return {*} 
   * @memberof FamilyInviteQrComponent
   */
  readQr(qrObject: QrObject) {

    // 撮影停止
    this.filming = false;
    // QR種類
    const qrType = this.commonFuncMdl.validateQrCode(qrObject?.code);

    // QRの検証
    if (qrType != 'SQRC' && qrType != 'US') {
      return this.appMsgServ.viewDialogMessage(this.msg.CLIENT.SQRC.E_INVALID_QR.message(), () => {
        // 撮影再開
        this.filming = true;
      });
    }
    // SQRC or QRの場合、PINコードを管理者に送信
    else if (qrType == 'SQRC' || qrType == 'US') {
      // PINコードをログインユーザに送信
      this.authParent(qrObject, qrType);
      return;
    }
    else {} // 想定外
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * 撮影再開
   *
   * @private
   * @memberof FamilyInviteQrComponent
   */
  private restartFilming(): void {
    this.filming = true;
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * ログインユーザに対し、PINコードを送付する。
   *
   * @private
   * @param {request.familyPincodeSend} qrcode
   * @memberof FamilyInviteQrComponent
   */
  private authParent(qrObject: QrObject, qrType: 'US' | 'SQRC'): void {

    // PINコード送付APIのリクエストBody
    let requestBody: request.familyPincodeSend;
    if (qrType == 'US') requestBody = { qr_code: qrObject.code };
    // SQRCの場合、画像が必要
    else if (qrType == 'SQRC') requestBody = { qr_image: qrObject.image, qr_code: qrObject.code };
    else {} // 想定外

    // PINコード送付
    this.m_Busy = this.familyServ.authParent(requestBody).subscribe({
      next: res => {
        // QRを読み取ったユーザID
        const userId = res.body.user_id;
        // 二段階認証方法、連絡先
        const twoFactorMethod = res.body.dst;
        this._navigator.element.pushPage(PincodeAuthComponent, {
          data: {
            mode: QR_MODE.family,
            userId: userId,
            twoFactorMethod: twoFactorMethod,
            restartFilming: () => { this.restartFilming() },
          }
        });
      },
      error: this.errResServ.doParse((_err, errContent) => {
        this.errResServ.viewErrDialog(errContent).then(() => {
          // 撮影再開
          this.filming = true;
        })
      })
    })
  }


}