//=============================================================================================
// インポート
//=============================================================================================
import { Injectable } from '@angular/core';
import { ExpService } from '../interfaces/response';

declare var fbq: any;

//=============================================================================================
// クラス定義
//=============================================================================================

/**
  * Google タグマネージャー サービス。
  *
  * @export
  * @class GoogleTagManagerService
  */
@Injectable({
  providedIn: 'root'
})
export class GoogleTagManagerService {

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * window オブジェクトを取得する。
   *
   * @private
   * @return {*}  {any}
   * @memberof GoogleTagManagerService
   */
  private windowRef(): any {
    return window;
  }

  /**
   * データをプッシュする。
   *
   * @param {object} obj 送信するデータ
   * @memberof GoogleTagManagerService
   */
  pushTag(obj: object) {
    if(obj) this.windowRef().dataLayer?.push(obj);
  }

  /**
   * ページビューをプッシュする。
   *
   * @param {string} [trackPageview] 仮想ページ
   * @param {string} [title] ページタイトル
   * @memberof GoogleTagManagerService
   */
  pushPageview(trackPageview: string, title: string) {
    this.pushTag({
      trackPageview,
      title,
      event: 'loadready'
    });
  }

  /**
   * 観光体験サービス詳細画面のページビューをプッシュする。
   *
   * @param {ExpService} [service] 体験サービス
   * @memberof GoogleTagManagerService
   */
  pushExpDetailPageview(service: ExpService) {

    let serviceType = "";
    if (service.service_type === "ACTIVITY") {
      serviceType = 'activity';
    } else if (service.service_type === "MOBILITY") {
      serviceType = 'mobility';
    }
    const trackPageview = `/exp/${serviceType}/${service.sg_service_id}/`;

    const title = `${service.title} ${service.shop.name}`;

    this.pushPageview(trackPageview, title);
  }

  /**
   * Metaトラックイベントをプッシュする。
   *
   * @param {string} [eventName] イベント名
   * @param {object} param 追加送信するデータ
   * @memberof GoogleTagManagerService
   */
  pushFbqTrack(eventName: string, param?: object) {
    if (typeof fbq !==  "undefined") {
      if (param) {
        fbq('track', eventName, param);
      } else {
        fbq('track', eventName);
      }
    }
  }
}
