//=============================================================================================
// インポート
//=============================================================================================
import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Subscription, Observable } from 'rxjs';
import { OnsNavigator, Params } from 'ngx-onsenui';

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { PagerService } from '../../../lib-services/pager.service';
import { UserWebApiService } from '../../../http-services/user-web-api.service';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';

// component
import { PaymentMethod, DELETE_IMPOSSIBLE } from '../payment-method';
import { PaymentMethodListComponent } from '../payment-method-list/payment-method-list.component';

// interface
import { request } from '../../../interfaces/request';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 郵便局の支払い方法操作画面。
 *
 * @export
 * @class PaymentMethodPostComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ons-page[payment-method-post]',
  templateUrl: './payment-method-post.component.html',
  styleUrls: ['./payment-method-post.component.css']
})
export class PaymentMethodPostComponent implements OnInit, OnDestroy {

//=============================================================================================
// プロパティ定義
//=============================================================================================
  
  /**
   * HTTP通信購読用
   *
   * @type {Subscription}
   * @memberof PaymentMethodPostComponent
   */
  busy: Subscription;

  /**
   * 支払い方法の登録状況
   *
   * @type {PaymentMethod.PaymentRegistration}
   * @memberof PaymentMethodPostComponent
   */
  paymentRegistration: PaymentMethod.PaymentRegistration;

  /**
   * ユーザが選択し、遷移した支払い方法画面情報
   *
   * @type {PaymentMethod.postPayment}
   * @memberof PaymentMethodPostComponent
   */
  postPayment: PaymentMethod.postPayment;

  /**
   * デフォルト支払い方法のチェック状態
   *
   * @type {boolean}
   * @memberof PaymentMethodPostComponent
   */
  defaultFlag: boolean = true;
  
  /**
   * デフォルト支払い方法のチェックボックス表示可否
   *
   * @type {boolean}
   * @memberof PaymentMethodPostComponent
   */
  isViewDefaultPayment: boolean = false;
  
  /**
   * 支払い方法表示文字
   *
   * @memberof PaymentMethodPostComponent
   */
  readonly VIEW_TEXT = {
    METHOD: PaymentMethod.METHOD_STR
  };

  /**
   * 運営事務所
   *
   * @memberof PaymentMethodPostComponent
   */
  office: {

    /**
     * 名前
     *
     * @type {string}
     */
    name: string;

    /**
     * 地点
     *
     * @type {google.maps.LatLngLiteral}
     */
    position: google.maps.LatLngLiteral
  }

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of PaymentMethodPostComponent.
   * @param {OnsNavigator} navigator
   * @param {ApplicationMessageService} appMsg
   * @param {HttpErrorResponseParserService} httpErrorResponseParserService
   * @param {PagerService} pager
   * @param {UserWebApiService} userWebApiService
   * @param {Params} params
   * @param {MunicipalityWebApiService} municipalityWebApiServ
   * @memberof PaymentMethodPostComponent
   */
  constructor
  ( 
    private navigator: OnsNavigator, 
    private appMsg: ApplicationMessageService, 
    private httpErrorResponseParserService: HttpErrorResponseParserService, 
    private pager: PagerService, 
    private userWebApiService: UserWebApiService, 
    private params: Params,
    private municipalityWebApiServ: MunicipalityWebApiService,
    private msg: MESSAGE,
  ) {
    const office = this.municipalityWebApiServ.setting.office;
    
    this.office = {
      position: office.location,
      name: office.office_name
    }
  }

  /**
   * 初期化処理。
   *
   * @memberof PaymentMethodPostComponent
   */
  ngOnInit(): void {
    // パラメータ取得
    [this.postPayment, this.paymentRegistration] = this.params.data;
    
    const user_payment = this.userWebApiService.getUserInfo().payment;

    // 支払い方法画面の状態によってチェックボックスの表示を切り替える
    switch (this.postPayment.view) {
      case 'add':
        this.isViewDefaultPayment = user_payment.method !== 'NONE';
        break;
      case 'detail':
        // 既にデフォルトに設定されている支払い方法は、チェックボックス非表示
        this.isViewDefaultPayment = user_payment.method !== this.postPayment.method;
        // 振替払込書はデフォルトOFF⇒ONには変更不可
        if (this.postPayment.method === 'SLIP') this.isViewDefaultPayment = false;

        this.defaultFlag = !this.isViewDefaultPayment;
        break;
      default:
        this.appMsg.viewDialogMessage(this.msg.CLIENT.COMMON.E_UNEXPECTED.message());
        break;
    }
  }

  /**
   * 破棄処理。
   *
   * @memberof PaymentMethodPostComponent
   */
  ngOnDestroy(): void {
    this.busy?.unsubscribe();
  }

  //=============================================================================================
  // イベントハンドラ
  //=============================================================================================

  /**
   * 支払い方法を削除する。
   *
   * @return {*}  {void}
   * @memberof PaymentMethodPostComponent
   */
  delete(): void {
    // 支払い方法が1件のみの場合、削除不可
    if (this.paymentRegistration.count === 1) {
      this.appMsg.viewDialogMessage(DELETE_IMPOSSIBLE, () => this.pager.transitionToTopPage(this.navigator, PaymentMethodListComponent));
      return;
    }

    let payment: request.Payment = {};
    switch (this.postPayment.method) {
      case 'SLIP': payment = { slip: {use: false} };
        break;
      case 'YUCHO': payment = { yucho: { use: false } };
        break;
      case 'BANK77': payment = { bank77: { use: false } };
        break;
      case 'JABANK': payment = {jabank: {use: false}};
        break;
    }

    // 削除
    this.commit(this.userWebApiService.deletePayment(payment), this.msg.CLIENT.PAYMENT.DELETE.message());
  }

  /**
   * 支払い方法を追加する。
   *
   * @memberof PaymentMethodPostComponent
   */
  add(): void {
    let payment: request.Payment = {};

    switch (this.postPayment.method) {
      case 'YUCHO': payment.yucho = { use: true };
        break;
      case 'BANK77': payment.bank77 = { use: true };
        break;
      case 'JABANK': payment.jabank = { use: true };
        break;
      default: this.appMsg.viewDialogMessage(this.msg.CLIENT.PAYMENT.NOT_APPENDABLE_PAYMENT.message());
    }
    
    // デフォルトチェックの場合、支払い方法に設定
    if (true === this.defaultFlag) payment.method = this.postPayment.method;

    // 追加
    this.commit(this.userWebApiService.addPayment(payment), this.msg.CLIENT.PAYMENT.REGIST.message());
  }

  /**
   * デフォルト支払処理の変更を行う。
   *
   * @memberof PaymentMethodPostComponent
   */
  changeDefaultPayment(): void {
    const payment: request.Payment = {
      method: this.postPayment.method, 
    };

    // 更新
    this.commit(this.userWebApiService.updatePayment(payment), this.msg.CLIENT.PAYMENT.UPDATE_DEFAULT.message());
  }

  /**
   * 支払い方法の追加/変更/削除を行う。
   *
   * @private
   * @param {Observable<HttpResponse<any>>} callMethod
   * @param {number} code
   * @memberof PaymentMethodPostComponent
   */
  private commit(callMethod: Observable<HttpResponse<any>>, message: string): void {
    this.busy = callMethod.subscribe({
      next: _ => {
        this.appMsg.viewDialogMessage(message, _ => this.pager.transitionToTopPage(this.navigator, PaymentMethodListComponent));
      },
      error: this.httpErrorResponseParserService.doParse((_err, errContent) => {
        this.httpErrorResponseParserService.viewErrDialog(errContent).then(_ => this.pager.transitionToTopPage(this.navigator, PaymentMethodListComponent));
      })
    });
  }
}