<ons-toolbar class="toolbar">
    <div class="center">QRコード</div>
</ons-toolbar>
<div [ngBusy]="busy"></div>

<ng-container *ngIf="isLoggedIn; else notLogin" class="content">
  <ons-list>
    <ons-list-header modifier="color" class="list-item__title">利用者</ons-list-header>
    <ons-list-item modifier="longdivider" class="list-item__text">
      <div class="form-select-box">
        <ons-select [(ngModel)]="selectedUserId" (change)="changeCustomer()" required>
          <option *ngFor="let userInfo of userInfos" [value]="userInfo?.value" [selected]="userInfo?.value == selectedUserId">{{ userInfo.label }}</option>
        </ons-select>
      </div>
    </ons-list-item>
  </ons-list>

  <div *ngIf="imgSrc[selectedUserId] !== undefined && imgSrc[selectedUserId] !== 'suspended_user'" class="content">
    <p class="content--text">
        SmartGOTOの各種サービス利用時にQRコードを掲示してください。
    </p>
    <qrcode class="qrcode content--img" [qrdata]="imgSrc[selectedUserId]" [errorCorrectionLevel]="'M'" alt="QRコード取得失敗" [width]="200"></qrcode>
    
    <ons-list>
      <ons-list-item expandable>
        <p class="sqrc-picture-title">紛失または他人に知られてしまった場合</p>
        <div class="sqrc-picture expandable-content">
          <p><span id="myqr_001" (click)="onClickReissueQr()" class="text--link">こちら</span>より、QRコードの再発行を行ってください。</p>
        </div>
      </ons-list-item>
    </ons-list>
    <ons-list *ngIf="isAlt && isDisplayIssueCard">
      <ons-list-item expandable>
        <p class="sqrc-picture-title">利用者カードを発行したい場合</p>
        <div class="sqrc-picture expandable-content">
          <div class="content--text-wrap">
            <p><span id="myqr_002" (click)="onClickIssueUserCard()" class="text--link">こちら</span>より、カードの発行依頼を行ってください。</p>
          </div>
        </div>
      </ons-list-item>
    </ons-list>
  </div>

  <!-- QR未登録 -->
  <!-- <div *ngIf="imgSrc[selectedUserId] === undefined" class="content">
    <p class="content--text">
      QRコードの登録が行われていません。
    </p>
    <p class="content--text content--attention">
      SmartGOTOのサービスを利用する為には、本人確認のQRコードの登録が必要です。
    </p>
    <div class="button-wrap">
      <ons-button id="myqr_003" modifier="large--cta noFixed" (click)="myqrAdd()">QRコードを登録する</ons-button>
    </div>
  </div> -->

  <!-- アカウント停止中 -->
  <div *ngIf="imgSrc[selectedUserId] === 'suspended_user'" class="content">
    <p class="content--text content--attention">
      このユーザーはアカウント停止中のため、QRコードを表示することができません。
    </p>
  </div>
</ng-container>

<!-- サインイン前 -->
<ng-template #notLogin class="content">
  <p class="content--text">
    この機能を利用するためにはSmartGOTOへのログインが必要です。<br />
    マイページタブよりログインまたは新規登録を行ってください。
  </p>
</ng-template>
