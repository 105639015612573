import { NgModule, ModuleWithProviders } from '@angular/core';
import { OperationWebApiServiceConfig } from '../http-services/operation-web-api.service';

@NgModule()
export class OperationWebApiModule {
  static withConfig(config: OperationWebApiServiceConfig): ModuleWithProviders<OperationWebApiModule> {
    return {
      ngModule: OperationWebApiModule,
      providers: [
        { provide: OperationWebApiServiceConfig, useValue: config }
      ]
    };
  }
}