//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { Params, OnsNavigator, ElementRef } from 'ngx-onsenui';
import { Subscription, fromEvent } from 'rxjs';
import * as CONST from '../../../constants/constant';

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { ExpWebApiService } from '../../../http-services/exp-web-api.service';
import { PagerService } from '../../../lib-services/pager.service';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';

// component
import { ExpSearchServiceListComponent } from '../exp-search-service-list/exp-search-service-list.component';
import { ExpShopServiceListComponent } from '../exp-shop-service-list/exp-shop-service-list.component';
import { UseListComponent, UseListTabTag } from '../../service/use-list/use-list.component'
import { AppComponent } from 'src/app/app.component';
import { ExpDetailComponent } from '../exp-detail/exp-detail.component';

// interface
import { ExpService, Settings, Shop } from 'src/app/interfaces/response';

// enum
import { PageKey } from 'src/app/lib-services/pager.service';

// parts
import { ListParts } from '../../parts/ons-list/ons-list.component';
import { SwiperImages, SwiperImagesComponent } from '../../parts/swiper-images/swiper-images.component';
import { SearchFormData } from '../../parts/search-form/search-form.component';
import { ExpDetailType1Component } from '../exp-detail-type1/exp-detail-type1.component';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 観光サービス画面。
 *
 * @export
 * @class ExpComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'ons-page[exp]',
  templateUrl: './exp.component.html',
  styleUrls: ['./exp.component.scss']
})
export class ExpComponent implements OnInit, OnDestroy, AfterViewInit {

  //=============================================================================================
  // ライフサイクルメソッド
  //=============================================================================================

  /**
   * 通信用Subscription
   *
   * @type {Subscription}
   * @memberof ExpComponent
   */
  busy: Subscription;

  /**
   * 観光タブクリック監視Subscription
   *
   * @type {Subscription}
   * @memberof ExpComponent
   */
  expTabClickSubscription: Subscription;

  /**
   * モビパ遷移監視Subscription
   *
   * @type {Subscription}
   * @memberof ExpComponent
   */
  pushExpSubscription: Subscription;

  /**
   * 体験サービス提供中 店舗一覧
   *
   * @type {ExpService}
   * @memberof ExpComponent
   */
  shopList: Shop[] = [];

  /**
   * 検索ワード
   *
   * @type {string}
   * @memberof ExpComponent
   */
  freeword: string;

  /**
   * モビリティサービスを提供している店舗として表示する為の配列
   *
   * @type {SwiperImages}
   * @memberof ExpComponent
   */
  hasMobilityService: SwiperImages = { elements: [] };

  /**
   * アクティビティサービスを提供している店舗として表示する為の配列
   *
   * @type {SwiperImages}
   * @memberof ExpComponent
   */
  hasActivityService: SwiperImages = { elements: [] };

  /**
   * リスト表示用データ(ここではヘッダーのみ)
   *
   * @type {ListParts}
   * @memberof ExpComponent
   */
  listHeader: ListParts[];

  /**
   * 検索履歴最大数
   *
   * @type {number}
   * @memberof ExpComponent
   */
  limit: number = CONST.Search.HISTORY;

  /**
   * 配下のswiper-images.componentすべて
   *
   * @type {QueryList<SwiperImagesComponent>}
   * @memberof ExpComponent
   */
  @ViewChildren(SwiperImagesComponent) swiperImages: QueryList<SwiperImagesComponent>;

  /**
   * エリア名
   *
   * @type {Settings}
   * @memberof ExpComponent
   */
  areaName: Settings["name"];

  /**
   * OnDestroy時に破棄するSubscriptionオブジェクト
   *
   * @memberof ExpComponent
   */
  subscription = new Subscription();

  /**
   * Creates an instance of ExpComponent.
   * @param {ApplicationMessageService} appMsgServ
   * @param {HttpErrorResponseParserService} errResServ
   * @param {ExpWebApiService} expServ
   * @param {OnsNavigator} navigator
   * @param {Params} params
   * @param {UseListComponent} useListComp
   * @param {FamilyWebApiService} familyServ
   * @param {PagerService} pagerServ
   * @memberof ExpComponent
   */
  constructor(
    private errResServ: HttpErrorResponseParserService,
    private expServ: ExpWebApiService,
    private navigator: OnsNavigator,
    private useListComp: UseListComponent,
    private pagerServ: PagerService,
    private appComponent: AppComponent,
    private municipalityWebApiServ: MunicipalityWebApiService,
  ) {
    const settingsChanged = this.municipalityWebApiServ.settingsChanged.subscribe({
      next: setting => {
        if (setting == null) return;
        this.areaName = setting.name;
      }
    });
    this.subscription.add(settingsChanged);
  }
  /**
   * 初期化処理。
   *
   * @memberof ExpComponent
   */
  ngOnInit(): void {

    this.expServ.methodGetShop = () => this.getShopList();

    // タブクリックで、店舗情報を取得
    this.expTabClickSubscription = this.useListComp.tabClickSubject.subscribe({
      next: (tag: UseListTabTag) => {
        if (tag === 'exp-service') {
          if (this.navigator.element.pages.length === 1) {
            // 店舗一覧を取得
            this.getShopList();
          }
        }
      }
    });

    // リストヘッダー表示用データ
    this.listHeader = [
      { header: 'モビリティ店舗', is_main_color_header: true, header_ons: true },
      { header: 'アクティビティ店舗', is_main_color_header: true, header_ons: true }
    ];
  }

  ngAfterViewInit(): void {
    // 観光サービストップ画面に戻る為のページ情報保存
    this.pagerServ.setReturnPage({ index: this.navigator.element.pages.length - 1, key: PageKey.ExpComponent });

    // ページスタックが削除されて、この画面に戻ってきたことを監視
    const pagerSubject = this.pagerServ.subject.subscribe({
      next: (key: number) => {
        if (key == PageKey.ExpComponent) {
          // 店舗情報取得
          this.getShopList();
          // swiperサイズ初期化
          this.swiperInit();
        }
      },
    });
    
    this.subscription.add(pagerSubject);

    // モビパからの遷移処理-----------------------------------------------
    this.pushExpSubscription = this.useListComp.pushExp.subscribe({
      next: value => {
        if (value.type == 0) return;

        // 検索体験サービス一覧画面へ遷移
        if (value.type == 1) {
          this.navigator.element.pushPage(ExpSearchServiceListComponent, {
            animation: 'fade-ios',
            data: {
              freeword: value.freeword,
              dateSearchType: value.dateSearchType,
              fromExternalLink: value.fromExternalLink,
              swiperInit: () => {this.swiperInit()}
            }
          });
        }
        // 体験サービス詳細画面へ遷移
        else if (value.type == 2) {
          this.navigator.element.pushPage(ExpDetailComponent, {
            animation: 'fade-ios',
            data: {
              service: value.service,
              swiperInit: () => {this.swiperInit()},
              isLink: true
            }
          });
        }
        else if (value.type == 3) {
          this.navigator.element.pushPage(ExpDetailType1Component, {
            animation: 'fade-ios',
            data: {
              service: value.service,
              swiperInit: () => {this.swiperInit()},
              isLink: true
            }
          });
        }
        // 店舗の観光サービス一覧画面へ遷移
        else if (value.type == 4) {
          this.navigator.element.pushPage(ExpShopServiceListComponent, {
            animation: 'fade-ios',
            data: {
              shop: value.shop,
              swiperInit: () => {this.swiperInit()},
              isLink: true
            }
          });
        }
      }
    })
    // -------------------------------------
  }

  /**
   * 破棄処理。
   *
   * @memberof ExpComponent
   */
  ngOnDestroy(): void {
    this.expTabClickSubscription?.unsubscribe();
    this.pushExpSubscription?.unsubscribe();
    this.busy?.unsubscribe();
    this.subscription?.unsubscribe();
  }

  //=============================================================================================
  // イベントハンドラ
  //=============================================================================================

  /**
   * モビパリンク/画像タップ時のイベントハンドラ
   *
   * @memberof ExpComponent
   */
  onClickMobipaLink(): void {

    // モビパHPを開く
    window.open(CONST.Exp.MOBIPA_HP.url, '_blank');
  }

  /**
   * 店舗タップ時のイベントハンドラ
   *
   * @param {string} shopId
   * @memberof ExpComponent
   */
  onClickShop(shopId: string): void {

    // 選択した店舗情報
    const targetShop = this.shopList.find(list => list.shop_id == shopId);

    // 店舗管理体験サービス一覧画面へ遷移
    this.navigator.element.pushPage(ExpShopServiceListComponent, {
      data: {
        shop: targetShop,
        swiperInit: () => {this.swiperInit()}
      }
    });
  }

  /**
   * フリーワード検索
   *
   * @param {SearchFormData} searchFormData
   * @memberof ExpComponent
   */
  onSearch(searchFormData: SearchFormData): void {

    // 入力したフリーワード
    this.freeword = searchFormData.freeword;

    // 検索体験サービス一覧画面へ遷移
    this.navigator.element.pushPage(ExpSearchServiceListComponent, {
      data: {
        freeword: this.freeword,
        swiperInit: () => {this.swiperInit()}
      }
    });
  }

  //=============================================================================================
  // メソッド
  //=============================================================================================

  /**
   * トップ画面にons-back-buttonで戻ってきた際に
   *  swiperスライダーの初期化を行う。
   *
   * @memberof ExpComponent
   */
  swiperInit() {
    // 子のswiperをすべて初期化
    this.swiperImages.toArray().forEach(e => {
      e.swiperInit();
    })
  }

  //=============================================================================================
  // サーバ通信
  //=============================================================================================

  /**
   * 体験サービス提供している店舗を取得
   *
   * @memberof ExpComponent
   */
  private getShopList(): void {
    this.hasActivityService.slides_per_view = 3;
    this.hasMobilityService.slides_per_view = 3;

    // 体験サービス提供中店舗一覧の取得
    this.busy = this.expServ.getShopList().subscribe({
      next: res => {
        // 表示用データ初期化
        if (this.hasActivityService) this.hasActivityService.elements = [];
        if (this.hasMobilityService) this.hasMobilityService.elements = [];

        this.shopList = res.body;

        // 提供しているサービスの種類によって表示を変更
        this.shopList.forEach(shop => {
          // モビリティサービスを提供している店舗
          if (shop.exp_services.includes('MOBILITY')) this.hasMobilityService.elements.push({ image: shop.icon, key: shop.shop_id, title: shop.name });
          
          // アクティビティサービスを提供している店舗
          if (shop.exp_services.includes('ACTIVITY')) this.hasActivityService.elements.push({ image: shop.icon, key: shop.shop_id, title: shop.name });
        })
      },
      error: this.errResServ.doParse((_err, errContent) => this.errResServ.viewErrDialog(errContent))
    })
  }
}
