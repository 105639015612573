<ons-toolbar>
    <div class="left" *ngIf="m_bMailTransition == false">
        <ons-back-button></ons-back-button>
    </div>
    <div class="center">パスワード再設定</div>
</ons-toolbar>
<div class="content">
  <div [ngBusy]="m_Busy"></div>
  <div *ngIf="m_bMailTransition == false; else defaultTransition">
    <p class="content--text">ご自身のアカウントに登録されたメールアドレスを入力してください。</p>
    <span class="content--text content--text--note">パスワード再設定用のリンクをお送りします。</span><br>
    <form id="inputMailForm" #formCheck="ngForm" class="formCheck mail-wrap">
      <ons-input id="change-password_001" type="email" modifier="underbar" placeholder="メールアドレス" [(ngModel)]="m_strUserName" name="user_name" #userNameModel="ngModel" required email></ons-input>
      <div class="valid-msg" *ngIf="userNameModel.errors && (userNameModel.dirty || userNameModel.touched)">
          <span *ngIf="userNameModel.errors.required">必須です。</span>
          <span *ngIf="userNameModel.errors.email">メールアドレスが不適切です。</span>
      </div>
      <ons-button id="change-password_002" modifier="large--cta noFixed sns" (click)="sendLink()" [attr.disabled]="!formCheck.invalid ? null : ''">リンク送信</ons-button>
    </form>
  </div>
  <ng-template #defaultTransition>
    <p class="content--text">新しいパスワードを入力し、再設定ボタンを押してください。</p>
    <form id="inputPasswordForm" #formCheckPass="ngForm" class="formCheckPass mail-wrap">
      <ons-input id="change-password_003" type="password" modifier="underbar" placeholder="パスワード" [(ngModel)]="m_strPassword" name="password"
        #passwordModel="ngModel" required minlength="8" maxlength="16" pattern="^(?=.*?[a-zA-Z])(?=.*?\d)[a-zA-Z\d]*$">
      </ons-input>
      <div class="valid-msg" *ngIf="passwordModel.errors && (passwordModel.dirty || passwordModel.touched)">
        <span *ngIf="passwordModel.errors.required">必須です。</span>
        <span *ngIf="passwordModel.errors.minlength">８文字以上必要です。</span>
        <span *ngIf="passwordModel.errors.maxlength">最大１６文字です。</span>
        <span *ngIf="passwordModel.errors.pattern">「半角数字、英字」を各１字以上、「半角英数のみ」で入力してください。</span>
      </div>
      <ons-input id="change-password_004" type="password" modifier="underbar" placeholder="パスワード（確認用）" [(ngModel)]="m_strPasswordConfirm"
        name="passwordConfirm" #passwordConfirmModel="ngModel" required></ons-input>
      <div class="valid-msg"
        *ngIf="passwordConfirmModel.errors && (passwordConfirmModel.dirty || passwordConfirmModel.touched)">
        <span *ngIf="passwordConfirmModel.errors.required">必須です。</span>
      </div>
      <ons-button id="change-password_005" modifier="large--cta noFixed sns" (click)="changePassword()" [attr.disabled]="!formCheckPass.invalid ? null : ''">パスワードを再設定</ons-button>
    </form>
  </ng-template>
</div>