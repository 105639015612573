//=============================================================================================
// Purchase専用定義
//=============================================================================================

/**
 * 乗車券の購入可否結果
 *
 */
export const PURCHASE_STATUS = 
{
  OK: 1,
  NG: 2, 
  OK_CAUTION: 3, 
  OK_RESERV_CANCEL: 4, 
  E_UNEXPECTED: -1
} as const;
