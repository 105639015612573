import Viewer from 'viewerjs';    
  
export class EnlargeImage {
    
    constructor(
      private images: string[]
    ) {}

    viewImageIndex: number;

    // viewer生成
    viewer: Viewer;

    enlargeImage(imageElement: HTMLElement) {
      // image取得
      // imgV: HTMLElement = document.getElementById("images");
      // imageElement
      const options: Viewer.Options = {
        // タイトル
        title: false,
        // ツールバー
        toolbar: {
          prev: { show: this.images.length > 1 ? true: false, size: "large" },
          zoomOut: { size: "large" },
          download: { show: true, size: "large", click: () => this.imageDownload() }, 
          zoomIn: { size: "large" },
          next: { show: this.images.length > 1 ? true: false, size: "large" },
          oneToOne: false,
          reset: false,
          play: false,
          rotateLeft: false,
          rotateRight: false,
          flipHorizontal: false,
          flipVertical: false,
        },
        // 画像描画後のイベント
        viewed: (event: CustomEvent) => {
          this.viewImageIndex = event.detail.index;
        }
      };

      // viewer生成
      this.viewer = new Viewer(imageElement, options);
    }
    
    /**
     * イメージをダウンロードする。
     *
     * @private
     * @memberof viewer
     */
    private imageDownload(): void {
    
      const a = document.createElement('a');
      a.href = this.images[this.viewImageIndex];
      // a.download = viewer.image.alt;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
}
