<ons-toolbar class="toolbar">
    <div class="left">
        <ons-back-button></ons-back-button>
    </div>
    <div class="center">マイスポットの設定</div>
    <div id="myspot-edit_001" *ngIf="isShowDeleteBtn" (click)="delete()" class="right button--text">削除</div>
</ons-toolbar>
<div class="content myspot">
    <div [ngBusy]="[busyCenter, busyPlace, busyFavorite, busyBusstop]"></div>
    <form id="mySpotForm">
        <div class="textbox-wrap">
            <dl class="mySpot__item">
                <dt class="mySpot-title">名称</dt>
                <dd *ngIf="!favorite_type" class="mySpot-data">
                    <ons-input id="myspot-edit_002" [(value)]="mySpotName"></ons-input>
                </dd>
                <dd *ngIf="favorite_type" class="mySpot-data-fixed">
                    {{mySpotName}}
                </dd>
            </dl>
            <dl class="mySpot__item mySpot__item--address">
                <dt class="mySpot-title">住所</dt>
                <dd class="mySpot-data noBg">
                    <img class="mySpot-data-icon" [src]="ASSETS_ACTIVE_PINK" />
                    {{this.mySpotAddress}}
                </dd>
            </dl>
            <ons-input id="myspot-edit_003" hidden [(value)]="mySpotId"></ons-input>
            <div #searchButton class="button-wrap">
                <ons-button id="myspot-edit_004" modifier="large--cta" [disabled]="!canPressButton()" (click)="edit()">マイスポットに設定</ons-button>
            </div>
        </div>
    </form>
    <input id="myspot-edit_005" #pacInput class="pac-input" type="text" placeholder="検索" />
    <button id="myspot-edit_006" #clearButton class="clear-button" (click)="clearRelatedPlaceMarkers()">x</button>
    <google-map class="map-height--max" height="100%" width="100%" [zoom]="zoom" [center]="center" [options]="mapOptions" (zoomChanged)="onZoomChanged()">
    </google-map>
</div>