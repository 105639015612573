//=============================================================================================
// インポート
//=============================================================================================
import { Component, Input, OnInit, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';

import { ApplicationMessageService } from 'src/app/lib-services/application-message.service';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * OnsListParts
 *
 * @export
 * @class OnsListComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'parts-ons-list',
  templateUrl: './ons-list.component.html',
  styleUrls: ['./ons-list.component.scss']
})
export class OnsListComponent implements OnInit {

  readonly ASSETS = {
    INFORMATION: CommonFunctionModule.getAssetsUrl('/image/button/info.png')
  } as const;

  /**
   * リスト用データ
   *
   * @type {ListParts[]}
   * @memberof OnsListComponent
   */
  @Input() 
  get listInfo(): ListParts { return this._listInfo }
  // 親から渡されるlistInfoが変更された場合、set配下の処理が実行される
  set listInfo(listInfo: ListParts) {
    this._listInfo = listInfo || {};
    if (listInfo) {
      // ヘッダーのみの表示
      if (!listInfo.mode && listInfo.header && !listInfo.common) {
        listInfo.mode = 'onlyHeader';
      }
      // modeが渡されなかった場合、common
      if (!listInfo?.mode) {
        listInfo.mode = 'common';
      }

      // 合計料金の計算
      if (this.listInfo.mode == 'paymentAmount' && this.listInfo.payment_amount.total_amount == undefined) {
        // 合計料金の初期値0
        this.listInfo.payment_amount.total_amount = 0;

        this.listInfo.payment_amount.row.forEach(e => {
          this.listInfo.payment_amount.total_amount += e.amount;
        });
      }
    }
  };

  private _listInfo: ListParts;

  /**
   * タップ可能(tappable)リストタップ時のイベント
   *
   * @memberof OnsListComponent
   */
  @Output() partsEvent = new EventEmitter<string | number>();

  /**
   * セレクトボックス変更時のイベント
   *
   * @memberof OnsListComponent
   */
  @Output() partsSelectEvent = new EventEmitter<{ id?: string | number, key: string | number }>();

  /**
   * 合計料金
   *
   * @type {number}
   * @memberof OnsListComponent
   */
  // totalAmount: number = 0;

  /**
   * ヘッダーのみ表示
   *
   * @type {boolean}
   * @memberof OnsListComponent
   */
  onlyHeader: boolean = false;

  /**
   * 選択中のセレクトボックス
   *
   * @type {(number|string)}
   * @memberof OnsListComponent
   */
  // selected: number|string;

  /**
   * リンク付きセレクトボックス 選択中のindex
   *
   * @type {number}
   * @memberof OnsListComponent
   */
  selectedIndex: number = 0;

  /**
   * commonListのタイプ
   *
   * @type {('string' | 'number' | 'customNumber')}
   * @memberof OnsListComponent
   */
  commonType: 'string' | 'number' | 'customNumber';

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of OnsListComponent.
   * @param {ApplicationMessageService} appMsgServ
   * @memberof OnsListComponent
   */
  constructor(
    private appMsgServ: ApplicationMessageService  
  ) { }

  /**
   * 初期処理。
   *
   * @memberof OnsListComponent
   */
  ngOnInit(): void {

    // select box
    if (this.listInfo.mode === "selectBox" && this.listInfo.select_box) {

      // 初期値の指定がある場合
      if (this.listInfo.select_box?.initial_select) {
        this.listInfo.select_box.item.forEach((m, index: number) => {
          if (m.key === this.listInfo.select_box.initial_select) this.selectedIndex = index;
        });
      }
    }

    // 内容がnumberの場合
    if (typeof(this.listInfo.common?.item) == 'number') {
      if (this.listInfo.common.number_option_text) this.commonType = 'customNumber';
      else this.commonType = 'number';
    }
    else {
      this.commonType = 'string';
    }
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * タップ可能なリスト押下時のイベントハンドラ。
   *
   * @param {(string|number)} key
   * @memberof OnsListComponent
   */
  onClickList(key: string|number): void {

    // イベント発火
    this.partsEvent.emit(key);
  }

  /**
   * リンク付きセレクトボックス変更時のイベントハンドラ。
   *
   * @param {(string|number)} key
   * @param {number} index
   * @param {*} event
   * @memberof OnsListComponent
   */
  onChangeLinkSelectBox(key: string|number, index: number, event: any): void {

    this.selectedIndex = index;
    this.partsSelectEvent.emit({ id: event.currentTarget.id, key: key });
  }

  /**
   * ヘッダーInfoボタンClick
   * ダイアログopen
   *
   * @memberof OnsListComponent
   */
  onClickPopUpDialog(): void {
    // ダイアログ
    this.appMsgServ.viewDialogMessage(this.listInfo.header_dialog_info);
  }

  /**
   * リンク付きセレクトボックス、リンクClick
   *
   * @param {*} event
   * @memberof OnsListComponent
   */
  onClickSelectBoxLink(event: any) {
    // リンクを押した際にプルダウンメニューが開かないように
    // 親へのClickの伝播をキャンセル
    event.stopPropagation();
  }
}

//=============================================================================================
// interface
//=============================================================================================

/**
 * リストデータ/行
 *
 * @export
 * @interface ListParts
 */
export interface ListParts {

  /**
   * 表示順（ソートはコンポーネント側で実施）
   *
   * @type {number}
   * @memberof ListParts
   */
  displayOrder?: number;

  /**
   * 項目キー
   *
   * @type {string}
   * @memberof ListParts
   */
  id?: string;

  /**
   * リストのタイプ
   * 入力なしの場合、common
   *
   * @type {('common'|'price'|'tappable')}
   * @memberof ListParts
   */
  mode?: 'common'|'paymentAmount'|'tappable'|'header'|'selectBox'|'onlyHeader';

  /**
   * リスト ヘッダー
   * この行の上に表示するヘッダーテキスト
   *
   * @type {string}
   * @memberof ListParts
   */
  header?: string;

  /**
   * ヘッダーのinfoボタン
   * クリックした時に表示するダイアログ内容 
   *
   * @type {string}
   * @memberof ListParts
   */
  header_dialog_info?: string;

  /**
   * ヘッダーの色がSmartGOTOメインカラーかどうか
   *
   * @type {boolean}
   * @memberof ListParts
   */
  is_main_color_header?: boolean;

  /**
   * onsenuit list-headerを使用するか
   *
   * @type {boolean}
   * @memberof ListParts
   */
  header_ons?: boolean;

  /**
   * commonList用データ
   *
   * @type {CommonList}
   * @memberof ListParts
   */
  common?: CommonList;

  /**
   * PaymentAmountList用データ
   * 支払い料金リスト
   *
   * @type {PaymentAmountList}
   * @memberof ListParts
   */
  payment_amount?: PaymentAmountList;

  /**
   * tappableList用データ
   * タップ可能リスト(4行以上は未対応)
   *
   * @type {TappableList}
   * @memberof ListParts
   */
  tappable?: TappableList;

  /**
   * header_list用データ
   * ヘッダー
   *
   * @type {HeaderList}
   * @memberof ListParts
   */
  header_list?: HeaderList;

  /**
   * select_box用データ
   * セレクトボックスリスト
   *
   * @type {SelectBox}
   * @memberof ListParts
   */
  select_box?: SelectBox;

  /**
   * valid-msg
   * リストの下にエラーメッセージを表示する
   *
   * @type {string}
   * @memberof ListParts
   */
  valid_msg?: string;
}

/**
 * tappableList用データ
 * タップ可能リスト(4行以上は未対応)
 *
 * @interface TappableList
 */
interface TappableList {

  /**
   * 表示するテキスト
   *
   * @type {string[]}
   * @memberof TappableList
   */
  item: TappableListItem[];

  /**
   * リストの識別子
   *
   * @type {(string|number)}
   * @memberof TappableList
   */
  key: string|number;

  /**
   * アイコン
   *
   * @type {string}
   * @memberof TappableList
   */
  icon?: string;
}

/**
 * tappableList
 * 行単位データ
 *
 * @interface TappableListItem
 */
interface TappableListItem {

  /**
   * 表示するテキスト(string)
   *
   * @type {string}
   * @memberof TappableListItem
   */
  text_string?: string;

  /**
   * 表示するテキスト(number)
   *
   * @type {number}
   * @memberof TappableListItem
   */
  text_number?: number;

  /**
   * 表示テキスト(number)の先頭に
   *    必要に応じて追加する文字
   *
   * @type {string}
   * @memberof TappableListItem
   */
  option_text_before?: string;

  /**
   * 表示テキスト(number)の末尾に
   *    必要に応じて追加する文字
   * 
   * @type {string}
   * @memberof TappableListItem
   */
  option_text_after?: string;
}

/**
 * commonList用データ
 *
 * @interface CommonList
 */
interface CommonList {

  /**
   * 表示テキスト
   *
   * @type {(string | number)}
   * @memberof CommonList
   */
  item?: string | number;

  /**
   * number型のリストの後ろに付けるテキスト
   *
   * @type {string}
   * @memberof CommonList
   */
  number_option_text?: string;

  /**
   * リンク
   * 遷移先URL
   *
   * @type {string}
   * @memberof CommonList
   */
  link?: string;
}

/**
 * PaymentAmountList用データ
 * 支払い料金リスト
 *
 * @interface PaymentAmountList
 */
interface PaymentAmountList {

  /**
   * 行単位データ
   *
   * @type {Row[]}
   * @memberof PaymentAmountList
   */
  row: Row[];

  /**
   * 請求先
   *
   * @type {string}
   * @memberof PaymentAmountList
   */
  payer_name: string;

  /**
   * 合計料金
   *
   * @type {number}
   * @memberof PaymentAmountList
   */
  total_amount?: number;
}

/**
 * 行単位データ
 *
 * @interface Row
 */
interface Row {

  /**
   * 購入物テキスト
   *
   * @type {string}
   * @memberof Row
   */
  item: string;
  
  /**
   * オプションなど
   *
   * @type {string}
   * @memberof Row
   */
  type?: string;

  /**
   * 個数
   *
   * @type {number}
   * @memberof Row
   */
  count?: number;

  /**
   * 個数単位
   *
   * @type {string}
   * @memberof Row
   */
  unit?: string;

  /**
   * 料金
   *
   * @type {number}
   * @memberof Row
   */
  amount: number;

  /**
   * 料金　元値
   *
   * @type {number}
   * @memberof Row
   */
  amount_org?: number;
}

/**
 * ヘッダーデータ
 *
 * @interface HeaderList
 */
interface HeaderList {

  /**
   * ヘッダーテキスト
   *
   * @type {string[]}
   * @memberof HeaderList
   */
  item: string[];

  /**
   * アイコン
   *
   * @type {string}
   * @memberof HeaderList
   */
  icon?: string;
}

/**
 * セレクトボックスリスト用データ
 *
 * @interface SelectBox
 */
interface SelectBox {

  /**
   * セレクトボックスアイテム
   *
   * @type {selectBoxItem[]}
   * @memberof SelectBox
   */
  item: selectBoxItem[];

  /**
   * リンク付きセレクトボックスかどうか
   *
   * @type {boolean}
   * @memberof SelectBox
   */
  is_link?: boolean;

  /**
   * 初期選択値（初期値とするitem/key）
   *
   * @type {(string|number)}
   * @memberof SelectBox
   */
  initial_select? : string|number;

  /**
   * セレクトボックスに付与するID
   *
   * @type {(string|number)}
   * @memberof selectBoxItem
   */
  select_id?: string|number;

  /**
   * セレクトボックス
   *    左側に表示される文字
   *
   * @type {string}
   * @memberof SelectBox
   */
  type_name?: string;
}

/**
 * セレクトボックスアイテム
 *
 * @interface selectBoxItem
 */
interface selectBoxItem {

  /**
   * セレクトボックス内 表示名
   *
   * @type {string}
   * @memberof selectBoxItem
   */
  name: string;

  /**
   * セレクトボックス 識別子
   *
   * @type {(string|number)}
   * @memberof selectBoxItem
   */
  key?: string|number;

  /**
   * リンク
   *
   * @type {string}
   * @memberof selectBoxItem
   */
  link?: string;
}