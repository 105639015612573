//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit } from '@angular/core';
import { OnsNavigator, Params } from 'ngx-onsenui';

// service
import { UserWebApiService } from '../../../http-services/user-web-api.service';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';
import { ApplicationMessageService } from 'src/app/lib-services/application-message.service';

// component
import { PaymentMethod } from '../payment-method';
import { PaymentMethodCreditAddComponent } from '../payment-method-credit-add/payment-method-credit-add.component';
import { PaymentMethodPostComponent } from '../payment-method-post/payment-method-post.component';

// interface
import { UserInfo } from '../../../interfaces/response';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 支払い方法の追加画面。
 *
 * @export
 * @class PaymentMethodAddComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'ons-page[payment-method-add]',
  templateUrl: './payment-method-add.component.html',
  styleUrls: ['./payment-method-add.component.css']
})
export class PaymentMethodAddComponent implements OnInit {

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * 支払い方法表示情報
   *
   * @type {{[method: string]: boolean}}
   * @memberof PaymentMethodAddComponent
   */
  isShowPaymentMethod: {[method: string]: boolean} = {};
  
  /**
   * 支払い方法表示文字
   *
   * @memberof PaymentMethodAddComponent
   */
  readonly VIEW_TEXT = {
    METHOD: PaymentMethod.METHOD_STR
  };

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of PaymentMethodAddComponent.
   * @param {OnsNavigator} navigator
   * @param {Params} params
   * @param {UserWebApiService} userWebApiService
   * @memberof PaymentMethodAddComponent
   */
  constructor(
    private navigator: OnsNavigator,
    private params: Params,
    private userWebApiService: UserWebApiService,
    private applicationMessageServ: ApplicationMessageService,
    private msg: MESSAGE,
  ) { }

  /**
   * 初期化処理。
   *
   * @memberof PaymentMethodAddComponent
   */
  ngOnInit(): void {
    
    // 使用可能な支払い方法
    const canUse: string[] = this.params.data.paymentMethods;
    // 既に登録している支払い方法
    const userInfo = this.userWebApiService.getUserInfo();

    // ユーザーが登録していないかつ、使用可能な支払い方法であれば追加可能な支払方法として表示
    canUse.forEach((item) => {
      if (item === 'card') this.isShowPaymentMethod[item] = true;
      else {
        if (item !== 'slip') this.isShowPaymentMethod[item] = true && !userInfo.payment[item]?.use;
      }
    });

    // 追加可能な支払い方法が存在しない場合ダイアログを表示し、支払い方法一覧画面へ
    if (!Object.values(this.isShowPaymentMethod).includes(true)) {
      this.applicationMessageServ.viewDialogMessage(this.msg.CLIENT.PAYMENT.NOT_EXIST_APPENDABLE_PAYMENT.message(), () => {
        this.navigator.element.popPage();
      });
    }
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * クレジットカードを追加する。
   *
   * @memberof PaymentMethodAddComponent
   */
  selectCredit(): void {
    // クレジットカード追加画面へ遷移
    this.navigator.element.pushPage(PaymentMethodCreditAddComponent, { data: {paymentRegistration: this.params.data.paymentRegistration}});
  }

  /**
   * 支払い方法を追加する。
   *    - ゆうちょ口座自動引き落とし
   *    - JAバンク自動引き落とし
   *    - 振替払込書
   *
   * @memberof PaymentMethodAddComponent
   */
  addMethod(method: UserInfo["payment"]["method"]): void {
    let arg = [{ view: 'add', method: method }, this.params.data.paymentRegistration];

    // 各支払い方法追加画面へ遷移
    this.navigator.element.pushPage(PaymentMethodPostComponent, { data: arg });
  }
}