import { Injectable } from '@angular/core';
import { Settings } from '../interfaces/response';
import { Subscription } from 'rxjs';
import { MunicipalityWebApiService } from '../http-services/municipality-web-api.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class MESSAGE {

  /**
   *
   *
   * @private
   * @type {Settings}
   * @memberof MESSAGE
   */
  private setting: Settings;

  /**
   * Subscription停止用
   *
   * @type {Subscription}
   * @memberof MESSAGE
   */
  subscription: Subscription = new Subscription();

  constructor(
    private municipalityServ: MunicipalityWebApiService,
  ) {
    const settingChanged = this.municipalityServ.settingsChanged.subscribe({
      next: setting => {
        this.setting = setting;
      }
    });
    this.subscription.add(settingChanged);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

//=============================================================================================
// メッセージ定数
//=============================================================================================

  readonly FATAL_MSG: string = "<br>一度時間を空けて操作いただくか、それでもエラーが発生する場合は、利用規約に記載の窓口連絡先までお問い合わせください。";

  readonly DISPATCH_ERROR: string = "配車情報を取得することができませんでした。";

  readonly UNEXPECTED_MSG: string = "想定外エラーが起こりました。";

  readonly CONTACT_MSG: string = "<br>詳細については、利用規約に記載の窓口連絡先までお問い合わせください。";

  get OFFICE_INFO(): string {
    // 運営事務所
    const office = this.municipalityServ.setting.office;

    return `
    <!DOCTYPE html>
    <html>
      <head>
        <style>
          pre {
            font-size: 15px;
            width: 100%;
            border-top: solid 1px;
            white-space: pre-wrap;
            font-family: "Helvetica Neue", Helvetica, "Hiragino Sans", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
          }
        </style>
      </head>
      <body>
        <pre>
    
    <b>期限までにキャンセルを行った場合</b>、請求料金を全額払い戻します。
    
    <b>期限を過ぎてキャンセルを行いたい場合</b>、アプリからは操作できません。${office.office_name}までお問合せください。
    また、その場合は料金の払い戻しはありません。
    
    ［電話番号］
    <a href="tel:${ office.tel.replace(/-/g, "") }">${ office.tel }</a>
    
    ［営業時間］
    ${ office.start_time }～${ office.end_time }（${ office.day_text }）</pre>
      </body>
    </html>`;
  }

  /**
   * GMOエラーメッセージ
   *
   * @private
   * @param {('RESERVE'| 'CANCEL' | 'CHANGE' | 'TICKET_CONTINUE' | 'BUY_TICKET')} useCase
   * @param {({retry: boolean, reason: 'card'|'system'})} [errOption]
   * @memberof MESSAGE
   */
  private viewGmoErrorMessage = (useCase: 'RESERVE'| 'CANCEL' | 'CHANGE' | 'TICKET_CONTINUE' | 'BUY_TICKET', errOption?: {retry: boolean, reason: 'card'|'system'}): string  => {

    let message: string = "クレジットカードのエラーにより、";
    switch (useCase) {
      case 'RESERVE':
        message += "予約ができませんでした。";
        break;
      case 'CANCEL':
        message += "予約をキャンセルできませんでした。";
        break;
      case 'CHANGE':
        message += "予約の変更ができませんでした。";
        break;
      case 'TICKET_CONTINUE':
        message += "自動更新設定の切替ができませんでした。";
        break;
      case 'BUY_TICKET':
        message += "乗車券を購入できませんでした。";
      default: ;
    }

    // 想定外
    if (!errOption) message += "<br>決済システムへの通信ができませんでした。" + this.FATAL_MSG + "<br><br>コード: G099";
    else {
      // オーソリエラー
      if (errOption.retry === true && errOption.reason === 'card') message += "<br>カード会社にお問い合わせいただくか、別の支払い方法をご利用ください。<br><br>コード: G001";
      // システムメンテなど
      else if (errOption.retry === true && errOption.reason === 'system') message += "<br>決済システムへの通信ができませんでした。" + this.FATAL_MSG + "<br><br>コード: G002";
      // 限度額エラー、取り扱いできないカードなど
      else if (errOption.retry === false && errOption.reason === 'card') message += "<br>支払い方法に設定しているカードは現在ご利用できません。<br>カード会社にお問い合わせいただくか、それでもエラーが発生する場合は、別の支払い方法をご利用ください。<br><br>コード: G003";
      // 取り扱いできないカードエラー、運営のGMOの契約が変わった場合かバグでしか起こらない
      else if (errOption.retry === false && errOption.reason === 'system') message += "<br>決済システムへの通信ができませんでした。" + this.FATAL_MSG + "<br><br>コード: G004";
      // 想定外
      else message += "<br>決済システムへの通信ができませんでした。" + this.FATAL_MSG + "<br><br>コード: G009";
    }

    // エラーダイアログ表示
    return message;
  }

  readonly CLIENT: MessagePackage = {
    COMMON: {
      E_UNEXPECTED: {message: () => "想定外のエラーが発生しました。<br>一度時間を空けてご利用いただくか、それでもエラーが発生する場合は、利用規約に記載の窓口連絡先までお問い合わせください。"},
      E_UNLOGIN_STATE: {message: () => "この機能を利用するためにはSmartGOTOへのログインが必要です。<br>マイページタブよりログインを行ってください。"},
      E_INVALID_URL: {message: () => "無効なURLのためページを開けません。"},
      E_UNLOGIN_USE_FUNCTION: {message: () => "これ以降の機能を利用する為には、SmartGOTOへのログインが必要です。<br>ログインまたは新規登録を行ってください。"},
      E_PASSWORD_DIFFERENT: {message: () => "パスワードと確認用パスワードが一致しません。"},
      E_INACTIVE_CAMERA: {message: () => "ご利用中端末のカメラを起動できませんでした。<br>端末またはブラウザの設定を確認ください。"},
      COMPLETE_REGIST: {message: () => "登録が完了しました。"},
      SUSPEND_STATUS: {message: () => "アカウント停止中のため利用できません。<br>詳細については、利用規約に記載の窓口連絡先までお問い合わせください。"},
      CANNOT_SIGNIN: {message: () => "<u>◆iPhoneの場合</u><br>デフォルトのブラウザAppをSafariに設定し再度行うか、メールアドレスでの登録をご利用ください。<br><br><u>◆それ以外の場合</u><br>メールアドレスでの登録をご利用ください。"},
      NEW_VERSION_AVAILABLE: {message: () => "SmartGOTO WEBアプリの新しいバージョンが見つかりました。<br>最新版を表示します。"},
    },
    // 運行状況確認機能
    OPERATION: {
      E_GET_CARS: {message: () => "運行中の車両情報が取得できませんでした。"},
    },
    // 通知方法設定機能
    NOTIFY: {
      E_DEFAULT: {message: () => "通知設定を更新できませんでした。<br>時間をおいて再度お試しください。"},
      E_NOT_NOTIFY: {message: () => "通知方法が設定されていません。"},
      E_NOT_NOTIFY_LINE: {message: () => "通知方法が設定されていません。<br>LINEアカウントからの通知を有効にしSmartGOTOのLINEアカウントと友達になるか、それ以外の通知方法を有効にしてください。"},
      E_NOT_ALLOWED: {message: () => "ブラウザ側の通知設定が無効になっているため、通知設定を有効にできませんでした。<br>ブラウザの設定を行ってから再度操作を行ってください。"},
      E_EMPTY_MAIL: {message: () => "メールアドレスが登録されていません。<br>メールアドレスの登録を行ってから再度操作を行ってください。"},
      E_NOT_SELECTED: {message: () => "通知方法を全てOFFにすることはできません。"},
      COMPLETE_UPDATE: {message: () => "更新が完了しました。"},
    },
    // ユーザープロフィール編集機能
    PROFILE: {
      FACE_AUTH_SUCCESS: {message: () => "認証に成功しました。<br>引き続き、SmartGOTOをご利用ください。"},
      INVALID_ZIP: {message: () => "該当する住所が見つかりません。"},
      COMFIRM_CANCEL_REGISTRATION: {message: () => "登録を中止します。よろしいですか？"},
      NOT_REGIST_QR: {message: () => "QRコードが未登録です。<br>QRコードタブより登録してください。"},
      NOT_EXIST_ADDRESS: {message: () => "該当する場所が見つかりません。<br>別の住所を入力し、再度操作を行ってください。"},
    },
    // パスワード変更
    CHANGE_PASSWORD: {
      E_NOT_USER: {message: () => "入力されたメールアドレスに該当するユーザーが存在しません。"},
    },
    // 配車サービス予約機能
    DISPATCH: {
      // 旧RESERVATION
      E_VALID_BOUNDS: {message: (OD?: string) => `${OD}がSmartGOTOの運行区間外です。`},
      E_VERIFY_PAST: {message: () => "発着時刻に過去の時刻が選択されています。"},
      E_VERIFY_FUTURE: {message: (RESERVABLE_DAYS: string) => `${RESERVABLE_DAYS}日後以降の配車予約はできません。`},
      NOT_EXIST_DISPATCH_PLAN: {message: () => "移動プランの候補がありません。<br>検索条件を変えて、再度検索を行ってください。"},
      INVALID_CONDITION: {message: () => "検索条件に無効な条件が含まれています。以下を項目を確認ください。"},
      DEPLICATE_RESERVE_CONFIRM: {message: (DEPLICATE_USER: string) => `${DEPLICATE_USER} の他の予約と時間が重複していますが、それでも予約を行いますか？`},
      DEPLICATE_RESERVE: {message: (DEPLICATE_USER: string) => `${DEPLICATE_USER}の他の予約と時間が重複しているため、予約できません。<br>予約を行いたい場合は、予約を取り消してから再度操作してください。`},

      // 旧DISPATCH
      ELAPSED_RESERV_TIME: {message: () => "乗車予定時刻を過ぎたため、キャンセルできません。"},
      CANCEL_COMPLETE: {message: () => "予約のキャンセルが完了しました。"},
      COST_CAMCELLATION_FEE: {message: () => "キャンセル料が発生します、よろしいですか？"},
      E_NO_RESERVATION: {message: () => "予約詳細がありませんでした。"},
      E_GET_RESERVATION: {message: () => "予約情報が取得できませんでした。"},
      E_OVER_DATE: {message: (NOT_RESERVABLE_DATE: string, RESERVABLE_DATE: string) => `${NOT_RESERVABLE_DATE}日後以降の配車予約はできません。<br>配車予約日は本日から${RESERVABLE_DATE}日後までを設定してください。`},
      E_NOT_TERM_AGREE: {message: (USER_NAME: string) => `${USER_NAME}が規約合意していないため、移動プランの検索ができませんでした。`},
      BUSSTOP_NO_VISIBLE: {message: () => "おでかけサービスの乗降場を確認するためには、ズームインを行って下さい。"},
    },
    // 購入機能
    PURCHASE: {
      E_GET_DETAILS: {message: () => "請求明細が取得できませんでした。"},
      E_DETAIL_RANGE: {message: () => "請求明細確認期間（過去1年）外のため、明細を表示することができません。"},
      E_INVALID_RANGE: {message: () => "無効な照会年月です。<br>マイページタブより請求明細を確認してください。"},
      E_GET_POSSESSIONS: {message: () => "所有乗車券が取得できませんでした。<br>一度時間を空けて操作いただくか、それでもエラーが発生する場合は、利用規約に記載の窓口連絡先までお問い合わせください。"},
      E_GET_TICKET_LIST: {message: () => "購入可能な乗車券が取得できませんでした。" + this.FATAL_MSG},
      E_SET_CONTINUE: {message: () => "自動更新設定の切替ができませんでした。"},
      E_NOT_TARGET_TICKET: {message: () => "該当する乗車券がありません。"},

      PAYMENT_FAILED: {message: () => "ご指定の支払い方法にて請求処理が実施できませんでした。<br>登録住所に振替払込書が送付されますので、そちらから料金をお支払いください。"},
      NOT_TARGET_AGE6: {message: (TICKET_TYPE: string) => `幼児以下のお子様用の${TICKET_TYPE}はありません。未就学児にあたるお子様は、無料での乗車が可能です。`},
      COMPLETE_BUY: {message: () => "乗車券の購入が完了しました。"},
      COMPLETE_AUTOUPDATE: {message: () => "自動継続購入の設定が完了しました。"},
      E_NOT_AGREE_BUY_TICKET: {message: (USER_NAME: string) => `${USER_NAME}は規約合意していないため、定額乗車券を購入することはできません。`},

      TERM_EXPIRATION: {message: () => "障がい者割引の期限切れ後の自動継続購入は行えません。"},
      E_NOT_TERM_AGREE: {message: (USER_NAME: string) => `${USER_NAME}が規約合意していないため、乗車券の購入ができませんでした。`},
      CONTINUE_DISABLE: {message: () => "この乗車券は現在の利用終了日以降、続けて購入することができません。"},

      IMPOSSIBLE: {message: (DATE: string, PLAN_NAME: string) => `指定期間の一部（${DATE}）は${PLAN_NAME}の利用可能期間外となるため使用できません。`},
      IMPOSSIBLE_CONFIRM: {message: (DATE: string, PLAN_NAME: string) => `指定期間の一部（${DATE}）は${PLAN_NAME}の利用可能期間外となるため使用できませんが、それでも購入を行いますか？`},

      CANCEL_RESERVE: {message: () => "購入期間内に1回券での予約が入っています。<br>一度予約をキャンセルしてから購入する乗車券での予約を行った方がお得になります。"},

      CONTINUE_OVER_LIMIT: {message: () => "設定変更期限を過ぎているため、変更できません。"},
      CONTINUE_IMPOSSIBLE: {message: (DATE: string, PLAN_NAME: string) => `更新後期間の一部（${DATE}）は${PLAN_NAME}の利用可能期間外となるため使用できません。`},
      CONTINUE_IMPOSSIBLE_CONFIRM: {message: (DATE: string, PLAN_NAME: string) => `更新後期間の一部（${DATE}）は${PLAN_NAME}の利用可能期間外となるため使用できませんが、それでも自動更新を有効にしますか？`},
      CONTINUE_CANCEL_RESERVE: {message: () => "継続後の期間内に1回券での予約が入っています。<br>一度予約をキャンセルしてから購入する定額乗車券での予約を行った方がお得になります。"},
      CONTINUE_NO_DISCONTINUOUS: {message: () => "継続後の期間内に配車予約が入っているため、自動継続購入を無効にできません。<br>継続後期間の予約を全てキャンセルしてから再度操作を行ってください。"},

      MAIL_NOT_OWNED: {message: () => "所有している乗車券がありません。<br>マイページタブより乗車券を確認してください。"},
      DESCRIPTION_FAIL: {message: () => "ご指定のクレジットカードへの請求がエラーとなったため、運営から別途請求致します。"},
      // 運営債権支払い方法、購入不可エラー
      METHOD_NO_PERMISSION_RESERVE: {message: () => "決済者の支払い方法は、使用が許可されていないため、予約ができませんでした。" + this.CONTACT_MSG},
      METHOD_NO_PERMISSION_BUY_TICKET: {message: () => "決済者の支払い方法は、使用が許可されていないため、乗車券を購入できませんでした。" + this.CONTACT_MSG},
      METHOD_NO_PERMISSION_TICKET_CONTINUE: {message: () => "決済者の支払い方法は、使用が許可されていないため、自動更新設定の切り替えができませんでした。" + this.CONTACT_MSG},
    },
    SQRC: {
      E_INVALID_QR: {message: () => "無効なQRコードです。"},
      E_FAIL_AUTH: {message: () => "認証に失敗しました。"},
      E_GET_QR: {message: () => "QRコードが取得できませんでした。"},
      ISSUE_USER_CARD: {message: (OFFICE_NAME: string) => `カード発行依頼が完了しました。<br><br>${OFFICE_NAME}からの連絡をお待ちください。`},
      REISSUE_QR: {message: () => "再発行が完了しました。"},
      INACTIVE_CAMERA: {message: () => "ご利用中端末のカメラを起動できませんでした。<br>端末またはブラウザの設定を確認ください。"},
    },
    // マイスポット機能
    MY_SPOT: {
      COMPLETE_REGIST: {message: () => "マイスポットの登録が完了しました。"},
      COMPLETE_EDIT: {message: () => "マイスポットの編集が完了しました。"},
      COMPLETE_DELETE: {message: () => "マイスポットを削除しました。"},
      COMFIRM_DELETE: {message: () => "マイスポットから削除しますか？"},

    },
    // 支払い方法設定機能
    PAYMENT: {
      UPDATE_DEFAULT: {message: () => "支払い方法を変更しました。"},
      REGIST: {message: () => "支払い方法の登録が完了しました。"},
      DELETE: {message: () => "支払い方法から削除しました。"},
      NOT_PAYMENT: {message: () => "現在支払い方法は登録されていません。"},
      NEAR_EXPIRED: {message: () => "支払い方法に設定されているクレジットカードの有効期限が間近となっております。<br>お早めに別の支払い方法に変更してください。"},
      EXPIRED: {message: () => "支払い方法に設定されているクレジットカードの有効期限が切れております。<br>お早めに別の支払い方法に変更してください。"},
      NOT_EXIST_APPENDABLE_PAYMENT: {message: () => "追加可能な支払い方法はありません。"},
      ERR_NOT_EXIST_APPENDABLE_PAYMENT: {message: () => "現在設定可能な支払い方法はありません。" + this.CONTACT_MSG},
      NOT_APPENDABLE_PAYMENT: {message: () => "追加可能な支払い方法ではありません。" + this.CONTACT_MSG},

      E_TOKEN_CARD_INFO: {message: () => "入力されたカード情報に誤りがあり、登録できませんでした。"},
      E_TOKEN_API_KEY: {message: () => "クレジットカード決済サービスに接続できず、登録できませんでした。<br>一度時間を空けて操作いただくか、それでもエラーが発生する場合は、利用規約に記載の窓口連絡先までお問い合わせください。"},
      E_TOKEN_GMO: {message: () => "クレジットカード決済サービスの内部エラーにより、登録できませんでした。<br>一度時間を空けて操作いただくか、それでもエラーが発生する場合は、利用規約に記載の窓口連絡先までお問い合わせください。"},
      E_TOKEN_BUSY: {message: () => "現在、クレジットカード決済サービスが混み合っているため、登録できませんでした。<br>一度時間を空けて操作ください。"},
      E_TOKEN_UNEXPECTED: {message: () => "想定外のエラーが発生し、登録できませんでした。<br>一度時間を空けて操作いただくか、それでもエラーが発生する場合は、利用規約に記載の窓口連絡先までお問い合わせください。"},

    },
    // ファミリー機能
    FAMILY: {
      E_GET_FAMILY: {message: () => "ファミリー情報が取得できませんでした。"},
      E_REGISTER_FAMILY: {message: () => "ファミリーへ招待できませんでした。"},
      E_SET_LIMTS: {message: () => "利用制限が変更できませんでした。"},
      E_REMAINING_RESERVE: {message: () => "利用前の予約が残っています。<br>予約を全てキャンセルしてからリンク削除を行ってください。"},
      CONFIRM_OK: {message: (NAME: string) => `「${NAME}」さんがあなたの管理者に設定されました。`},
      CONFIRM_AGAIN: {message: () => "申請を却下します。<br>よろしいですか？"},
      CONFIRM_NG: {message: () => "申請を却下しました。"},
      LIMIT_SET: {message: () => "利用制限が設定されました。"},
      LIMIT_RELEASE: {message: () => "利用制限が解除されました。"},
      DELETE_RELATION: {message: () => "ファミリーリンクが解除されました。"},
      DELETE_RELATION_REQUEST: {message: () => "管理者へファミリーリンク解除を申請しました。"},
      DELETE_RELATION_CONFIRM: {message: () => "現在管理者へリンク解除申請中です。却下しますか？"},

      RESTRICTED_UTILIZATION: {message: () => "管理者によりサービス利用制限中です。<br>管理者が代行するか、管理者に依頼して制限解除を行ってください。"},
      DUPLOCATE_USER: {message: () => "読み取ったユーザーは既にメンバーとして追加、または招待されています。"},
      DUPLOCATE_OWN_USER: {message: () => "読み取ったユーザーはログインユーザーです。"},
      CAUTIONS: {message: () => "※ファミリー機能はおでかけサービスを利用者の家族が利用する場合、利用可能な家族を事前に登録する機能です。<br>登録された情報は、他地域のSmartGOTOの利用を開始する際に引き継がれる情報に含まれるため（利用規約「個人情報の第三者への提供」）、登録する際は、本人の承諾を得たうえでご登録ください。<br>また、ご家族の方がおでかけサービスを使わない限り登録は不要です。"},

      // 見守りアカウント関連
      LOGIN_APPROVAL: {message: () => "設定されている通知先に、初回ログイン用のワンタイムパスワードを送りました。<br><br>本人にワンタイムパスワードを伝えた上、サインイン画面より初回ログイン操作を行ってください。"},
      HAND_OVER_ACCOUNT: {message: () => "アカウントの情報が引き継がれました。"},
      COMPLETE_CREATE: {message: () => "ご家族の登録が完了しました。"},

      PIN_EXPIRED: {message: () => "PINコードの有効期限が切れました。<br>再度QRコード読み取りを行ってください。"},
    },
    // 買い物サービス関連
    SHOPPING: {
      CONFIRM_DIFFERENT_SHOP_ITEM: {message: () => "この店舗のメニューをカートに追加すると、現在のカートにある別の店舗のメニューが削除されます。<br>よろしいですか？"},
      ERR_NO_PLAN: {message: () => "配達プランの候補がありません。<br>お届け先を変更してから、注文操作を行ってください。"},
      ERR_ADD_ITEM: {message: () => "商品を追加することができませんでした。<br>一度時間を空けて操作いただくか、それでもエラーが発生する場合は、利用規約に記載の窓口連絡先までお問い合わせください。"},
      ERR_DELETE_ITEM: {message: () => "商品を削除することができませんでした。<br>一度時間を空けて操作いただくか、それでもエラーが発生する場合は、利用規約に記載の窓口連絡先までお問い合わせください。"},
      ERR_UPDATE_ITEM: {message: () => "カート内情報を更新することができませんでした。<br>一度時間を空けて操作いただくか、それでもエラーが発生する場合は、利用規約に記載の窓口連絡先までお問い合わせください。"},
      CANCEL_COMPLETE: {message: () => "キャンセルが完了しました。"},
      ELAPSED_ORDER_TIME: {message: () => "キャンセル期限を過ぎたため、注文キャンセルできません。"},
      ERR_NOT_TARGET_ORDER: {message: () => "該当する注文情報がありません。"},
      CART_ALL_DELETE: {message: () => "カートからすべての商品が削除されたため、メニュー一覧へ戻ります。"},
      ERR_OUT_RANGE_DELIVERY: {message: () => "お届け先が配達可能エリア外です。"},
      ERR_MAX_COUNT: {message: (MAX_ORDER_ITEM_COUNT: string) => `カートに登録できる商品は${MAX_ORDER_ITEM_COUNT}個までです。`},
      ERR_ALREADY_MAX_COUNT: {message: (MAX_ORDER_ITEM_COUNT: string) => `既にカートに商品が${MAX_ORDER_ITEM_COUNT}個追加されています。`},
      NO_LATLNG: {message: () => "ご住所の位置情報が登録されていません。<br>マイページタブ > プロフィールメニューから住所を更新するか、別のお届け先を指定してください。"},
      E_NOT_TERM_AGREE: {message: (USER_NAME: string) => `${USER_NAME}が規約合意していないため、配送プランの検索ができませんでした。`},

    },
    EXP: {
      COMPLATE_CHANGE: {message: () => "予約の変更が完了しました。"},
      COMPLATE_CANCEL: {message: () => "予約のキャンセルが完了しました。"},
      E_GET_RESERVATION: {message: () => "予約情報が取得できませんでした。"},
      RESERVE_LIMIT_CHANGE: {message: () => "受付締切時間を過ぎたため、予約の変更ができませんでした。"},
      RESERVE_LIMIT_CONFIRM: {message: () => "受付締切時間を過ぎたため、予約ができませんでした。<br>別の時間を選択し、予約操作を行ってください。"},
      E_NOT_TERM_AGREE: {message: (USER_NAME: string) => `${USER_NAME}が規約合意していないため、予約が出来ませんでした。`},

      NOT_EXIST_PLAN_ID_RESERVE: {message: () => "一定時間以上経っているため、予約ができませんでした。再度操作を行ってください。"},
      NOT_EXIST_PLAN_ID_CHANGE: {message: () => "一定時間以上経っているため、予約変更ができませんでした。再度操作を行ってください。"},
      NOT_EXIST_PLAN_ID_CANCEL: {message: () => "一定時間以上経っているため、予約キャンセルができませんでした。再度操作を行ってください。"},

      // 個別対応必要
      OPTION_CALC_FAILED: {message: () => "$OPTION_NAME$の$TYPE$が上限を超えています。$NUMBER$以下を選択してください。"},

      NOT_FREE_RESERVE: {message: () => "合計金額が0円のため、予約をすることはできません。条件を変更してください。"},
      NOT_FREE_RESERVE_CHANGE: {message: () => "合計金額が0円のため、予約変更はできません。条件を変更してください。"},
      OPTION_UNMATCH_REQUEST: {message: () => "予約対象のサービス情報が更新されたため、お手数ですが再度予約操作を行ってください。"},
      NOT_EXIST_SERVICE: {message: () => "ご指定の店舗において提供中のサービスが見つかりませんでした。お手数ながら、他の店舗からお探しください。"},
    },
    PINCODE: {
      UNREGISTERED_TEL: {message: () => "電話番号が登録されていないため変更できません。"},
    },
    LOGIN: {
      PIN_EXPIRED: {message: () => "PINコードの有効期限が切れました。<br>再度サインイン画面からログイン操作を行ってください。"},
    },
    SIGNUP: {
      PIN_EXPIRED: {message: () => "PINコードの有効期限が切れました。<br>再度新規登録画面からPINコード送信を行ってください。"},
    },
    USER_NOTIFICATION: {
      ACCOUNT_SUSPEND: {message: (OFFICE_NAME: string) => `現在このアカウントは、利用を停止されています。<br>利用再開したい場合は、${OFFICE_NAME}までお問い合わせください。`},
      PURCHASE_REFUND: {message: (OFFICE_NAME: string) => `払い戻しは、${OFFICE_NAME}までお問い合せください。`},
    },
    WITHDRAWAL: {
      CONFIRM: {message: () => "アカウント削除手続きを行います。よろしいですか？"},
      COMPLETE: {message: () => "アカウント削除手続きが完了しました。ご利用、ありがとうございました。"},
      CONFIRM_ALT: {message: (NAME: string) => `”${NAME}”様のアカウント削除手続きを行います。よろしいですか？`},
      COMPLETE_ALT: {message: () => "アカウント削除手続きが完了しました。"},
    },
    SEARCH: {
      E_PERIOD_ERROR: {message: () => "7日以上の予約はできません。選択しなおしてください。"},
      E_RETURN_DATE_ERROR: {message: () => "返却日時が出発日時より前に設定されています。選択しなおしてください。"},
      E_DATE_SAME_ERROR: {message: () => "出発日と返却日が同じ日時で設定されています。選択しなおしてください。"}
    }
  };


  /**
   * サーバーサイドエラーメッセージ
   * NOTE: "COMMON.UNAUTH"などの${key}.${sub_key}はサーバーエラーコードに一致する
   *
   * @type {MessagePackage}
   * @memberof MESSAGE
   */
  SERVER: MessagePackage = {
    COMMON: {
      EXCEPTION: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: COMMON.EXCEPTION"},
      ILLEGAL_PARAMETER: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: COMMON.ILLEGAL_PARAMETER"},
      LOCATION_NOT_EXIST: {message: () => "緯度経度が指定されていません。" + this.FATAL_MSG + "<br><br>コード: COMMON.LOCATION_NOT_EXIST"},
      DELETED_USER: {message: (option: {is_login_user: boolean}) => {
        if (option.is_login_user === true) return "ログインユーザーは退会済みです。お手数ですが、ページを更新してから再度操作してください。";
        else return "ユーザー情報が削除されているため、この操作をすることはできません。";
      }},
      LIMIT_RATE: {message: () => "ログイン試行の回数が上限を超えました。しばらくしてからもう一度お試しください。"},
      SUSPENDED_USER: {message: () => "アカウント停止中のため利用できません。" + this.CONTACT_MSG},
      UNAUTH: {message: () => "この機能はログイン済みユーザーのみが利用可能です。会員登録またはログインを行ってください。"},
      FORBIDDEN: {message: () => "権限がありません。"},
      DISTANCE_MATRIX_NOT_FOUND: {message: () => "指定された区間で利用可能なルートが見つかりませんでした。"},
      // TERM
      SHOP_TERM_AGREEMENT_EXPIRED: {message: () => "予約することができませんでした。" + this.FATAL_MSG + "<br><br>コード: COMMON.SHOP_TERM_AGREEMENT_EXPIRED"},
      USER_TERM_NEVER_AGREED: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: COMMON.USER_TERM_<br>NEVER_AGREED"},
      SHOP_TERM_NEVER_AGREED: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: COMMON.SHOP_TERM_<br>NEVER_AGREED"},
      SHOP_CANCELED: {message: (option: {cancel_date: string}) => `この店舗は、${moment(option.cancel_date).format('YYYY年MM月DD日')}以降の予約をすることができません。`},
      END_TIME_OVER_EXPIRY_DATE: {message: (option: {shop_id: string, expiry_date: string}) => `この店舗は、${moment(option.expiry_date).format('YYYY年MM月DD日')}以降の予約をすることができません。`},
    },
    DISPATCH: {
      ABORT_STATUS: {message: () => "既に配車済みです。"},
      CANCEL_CANCELED: {message: () => "既にキャンセル済みです。"},
      CANCEL_NOT_EXIST: {message: () => "既に利用済みです。"},
      CANCEL_DONE: {message: () => "既に利用済みです。"},
      CANCEL_FEE: {message: () => "今キャンセルするとキャンセル料がかかります。"},
      CANCEL_FORBIDDEN: {message: () => "キャンセルすることができませんでした。" + this.FATAL_MSG},
      // CANCEL_NOT_EXIST: {message: () => "既にキャンセルされた予約です。"},
      CANCEL_NOT_EXIST_PASSENGER: {message: () => "既にキャンセル済みです。"},
      CANCEL_TIMELIMIT: {message: () => "キャンセル期限を過ぎたため、キャンセルすることができませんでした。"},
      CANCEL_USER_CANCELED: {message: () => "既にキャンセル済みのユーザーが選択されています。"},
      CC_403: {message: () => "配車プランの候補がありませんでした。"},
      CC_500: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: DISPATCH.CC_500"},
      CC_CONNECTION: {message: () => this.DISPATCH_ERROR + this.FATAL_MSG + "<br><br>コード: DISPATCH.CC_CONNECTION"},
      CC_COUNT_EXCEEDED: {message: () => "これ以上予約はできません。"},
      CC_FAILED_OPERATION: {message: () => "配車プランの候補がありませんでした。" + this.FATAL_MSG + "<br><br>コード:DISPATCH.CC_FAILED_OPERATION"},
      CC_NOT_EXIST: {message: () => "希望日時などを変更した上で、再度お試しください。"},
      CC_OUTOFSERVICE: {message: () => "サービス時間外です。"},
      CC_PARAM: {message: () => this.DISPATCH_ERROR + this.FATAL_MSG + "<br><br>コード: DISPATCH.CC_PARAM"},
      CC_RESPONSE_EMPTY: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: DISPATCH.CC_RESPONSE_EMPTY"},
      CC_TIMELIMIT: {message: () => this.DISPATCH_ERROR + this.FATAL_MSG + "<br><br>コード: DISPATCH.CC_TIMELIMIT"},
      CC_TIMETABLE_EXPIRED: {message: () => "本日分の時刻表は取得できません。" + this.FATAL_MSG + "<br><br>コード: DISPATCH.CC_TIMETABLE_EXPIRED"},
      CC_UNKNOWN: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: DISPATCH.CC_UNKNOWN"},
      CONNOTHER: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: DISPATCH.CONNOTHER"},
      CONNREFUSED: {message: () => this.DISPATCH_ERROR + this.FATAL_MSG + "<br><br>コード: DISPATCH.CONNREFUSED"},
      CONNRESET: {message: () => this.DISPATCH_ERROR + this.FATAL_MSG + "<br><br>コード: DISPATCH.CONNRESET"},
      EXCEPTION: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: DISPATCH.EXCEPTION"},
      NO_CC_USERID_1: {message: () => this.DISPATCH_ERROR + this.FATAL_MSG + "<br><br>コード: DISPATCH.NO_CC_USERID_1"},
      NO_CC_USERID_2: {message: () => this.DISPATCH_ERROR + this.FATAL_MSG + "<br><br>コード: DISPATCH.NO_CC_USERID_2"},
      NO_POINT: {message: () => this.DISPATCH_ERROR + this.FATAL_MSG + "<br><br>コード: DISPATCH.NO_POINT"},
      NO_QRCODE: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: DISPATCH.NO_QRCODE"},
      NOT_EXIST_FAVORITE: {message: () => "お気に入りスポットが未登録です。"},
      // OVER_DATE
      PAST_DATE: {message: () => "配車予約日に過去の日付が使われています。"},
      RESERVE_TIMEOUT: {message: () => "配車プラン検索から一定時間以上経っているため、予約確定できませんでした。"},
      SAME_OD: {message: () => "SmartGOTOの乗車区間はありません。"},
      SUSPENDED_USER: {message: () => "ゲストは利用停止中です。"},
      UNREGISTERED_USER: {message: () => "未登録または退会済みです。"},
      // DISPATCH_RESTRICT_UTILIZATION1
      // DISPATCH_RESTRICT_UTILIZATION2
      NO_PAYMENT: {message: () => "利用者の方の中に、支払い方法の登録が行われていないユーザーがいるため、予約できませんでした。<br>支払い方法を登録してから再度予約を行って下さい。"},
      QR_UNAUTH: {message: () => "ゲスト代理予約の有効期限が切れました。<br>再度ゲストの認証を行ってから、予約操作を行って下さい。"},

    },
    FAVORITE: {
      DUPLICATED_LOCATION1: {message: () => "既に登録されている場所です。"},
      DUPLICATED_LOCATION2: {message: () => "既に登録されている場所です。"},
      DUPLICATED_TYPE: {message: () => "同じ種別のマイスポットが既に登録されています。"},
      NO_LOCATION: {message: () => "場所を特定できません。"},
      NOT_FOUND: {message: () => "登録されていません。" + this.FATAL_MSG + "<br><br>コード: FAVORITE.NOT_FOUND"},
      TOO_MANY: {message: () => "登録できるマイスポット件数の上限を超えています。<br>登録済みのマイスポットを削除してから再度登録して下さい。"},

    },
    // スマホアプリでは起こらない
    // IN_VEHICLE: {
    //   NOT_REQUIRED_INCURRENT_TYPE: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: IN_VEHICLE.NOT_REQUIRED_INCURRENT_TYPE"},
    //   REQUIRED_INCURRENT_TYPE: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: IN_VEHICLE.REQUIRED_INCURRENT_TYPE"},
    //   REQUIRED_NOT_EXIST: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: IN_VEHICLE.REQUIRED_NOT_EXIST"},
    // }
    LOGIN: {
      CP_NOT_EXIST: {message: () => "このメールアドレスでのユーザ登録はありません。"},
      CP_TIME_EXPIRED: {message: () => "パスワード再設定の期限が過ぎました。再度再設定操作を行って下さい。"},
      DUPLICATED: {message: () => "このメールアドレスはすでに登録済です。"},
      FAIL: {message: () => "ログインに失敗しました。再度入力をお願いいたします。"},
      NOT_EXIST: {message: () => "このメールアドレスでのユーザ登録はありません。"},
      PIN_EXPIRED: {message: () => "PINコードの有効期限が切れました。"},
      PIN_PW_UNMATCH: {message: () => "登録時のパスワードと一致しません。"},
      PIN_UNKOWN: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: LOGIN.PIN_UNKOWN"},
      PIN_UNMATCH: {message: () => "PINコードが一致しません。"},
      UNAUTH: {message: () => "認証エラーが起こりました。" + this.FATAL_MSG + "<br><br>コード: LOGIN.UNAUTH"},
      UNKNOWN: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: LOGIN.UNKNOWN"},
      INHIBIT_USER: {message: () => "この端末でログインできるユーザではありません。"},
      UNREGISTERED_USER: {message: () => "ユーザ情報が未登録なのでログインできません。"},
      NOT_FOUND: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: LOGIN.NOT_FOUND"},
      PROXY: {message: () => "操作代行アプリからログインすることはできません。"},
    },
    LOGIN_MODEL: {
      ILLEGAL_UPDATE: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: LOGIN_MODEL.ILLEGAL_UPDATE"},
    },
    PURCHASE: {
      CANCEL_JABANK: {message: () => "請求済みのJAバンク口座引き落としの金額変更はできません。"},
      CANCEL_SLIP: {message: () => "請求済みの振替依頼票の金額変更はできません。"},
      CANCEL_STATUS: {message: () => "キャンセル済みです。"},
      CANCEL_YUCHO: {message: () => "請求済みのゆうちょ口座引き落としの金額変更はできません。"},
      FORBIDDEN: {message: () => "このユーザは購入できません。"},
      GMO_TRAN_REQUEST: {message: () => "カード請求でエラーが発生しました。"},
      ILLEGAL_PARAMETER: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: PURCHASE.ILLEGAL_PARAMETER"},
      ILLEGAL_QUERY: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: PURCHASE.ILLEGAL_QUERY"},
      INHIBIT_CONTINUOUS_TICKET: {message: () => "この乗車券は自動継続できません。"},
      NEXT_TICKET_HAS_RESERVATION: {message: () => "更新後の予約があるので解除できません."},
      NO_START_DATE: {message: () => "購入することができませんでした。"},
      NO_TICKET_CATALOG_ID: {message: () => "購入することができませんでした。"},
      NOT_EXIST_PAYER: {message: () => "支払者が退会済みです。"},
      NOT_EXIST_PURCHASE: {message: () => "購入情報がありません。" + this.FATAL_MSG + "<br><br>コード: PURCHASE.NOT_EXIST_PURCHASE"},
      NOT_TICKET_ID: {message: () => "乗車券が存在しません。" + this.FATAL_MSG + "<br><br>コード: PURCHASE.NOT_TICKET_ID"},
      PAYMENT_NOT_CARD: {message: () => "支払いに使用するカードを登録してください。"},
      PAYMENT_NOT_SUPPORTED: {message: () => "口座支払は未実装です。" + this.FATAL_MSG + "<br><br>コード: PURCHASE.PAYMENT_NOT_SUPPORTED"},
      PAYMENT_REQUIRED: {message: () => "支払方法を登録してください。"},
      PAYMENT_TOURIST_JABANK: {message: () => "JAバンク口座引き落としによる支払いは住民ユーザーのみです。"},
      PAYMENT_TOURIST_SLIP: {message: () => "振替依頼書による支払いは住民ユーザーのみです。"},
      PAYMENT_TOURIST_YUCHO: {message: () => "ゆうちょ口座引き落としによる支払いは住民ユーザーのみです。"},
      POSSESSION_CONTINUOUS_TICKET: {message: () => "同じ利用地区の乗車券を所有しているため更新できませんでした。"},
      PURCHASED: {message: () => "乗車券を既に所有しています。"},
      RESTRICT_UTILIZATION: {message: () => "ファミリー管理者によってサービスの利用制限がかけられています。"},
      SUSPENDED_USER: {message: () => "このユーザは利用停止中です。"},
      PAYER_NOT_EXIST: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: PURCHASE.PAYER_NOT_EXIST"},
      PAYER_SUSPENDED: {message: () => "このユーザーの決済者がアカウント停止中のため、購入することができません。" + this.CONTACT_MSG},
      CARD_EXPIRED: {message: () => "支払い方法に設定されているクレジットカードの有効期限が切れているため、購入することができません。"},
      ILLEGAL_PARAM: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: PURCHASE.ILLEGAL_PARAM"},
      GMO_TRAN_SALES: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: PURCHASE.GMO_TRAN_SALES"},
      
      GMO_TRAN_AUTH: {message: (option: {retry: boolean, reason: 'card'|'system'}, useCase: 'RESERVE'| 'CANCEL' | 'CHANGE' | 'TICKET_CONTINUE' | 'BUY_TICKET') => this.viewGmoErrorMessage(useCase, option)},
      GMO_TRAN_VOID: {message: (option: {retry: boolean, reason: 'card'|'system'}, useCase: 'RESERVE'| 'CANCEL' | 'CHANGE' | 'TICKET_CONTINUE' | 'BUY_TICKET') => this.viewGmoErrorMessage(useCase, option)},
      GMO_TRAN_CHANGE: {message: (option: {retry: boolean, reason: 'card'|'system'}, useCase: 'RESERVE'| 'CANCEL' | 'CHANGE' | 'TICKET_CONTINUE' | 'BUY_TICKET') => this.viewGmoErrorMessage(useCase, option)},
      METHOD_NO_PERMISSION: {message: () => "決済者の支払方法の使用許可がされていません。" + this.CONTACT_MSG}, 
    },
    RELATION: {
      DELETE_DENIED: {message: () => "削除する権限がありません。"},
      DESABLE_DELETE_PROXY: {message: () => "代理アカウントのファミリーリンクは解除できません。"},
      DIFFERENCT_AREA: {message: () => "観光ユーザーと住民ユーザー間ではファミリー登録できません。"},
      DUPLICATED: {message: () => "読み取ったユーザーは既にメンバーとして追加、または招待されています。"},
      ESTABLISHED: {message: () => "すでに承認済みです。"},
      EXIST_FAMILY: {message: () => "このユーザーは既に他のファミリーに所属しています。"},
      EXIST_REQUEST: {message: () => "他の申請を処理してからもう一度お試しください。"},
      ILLEGAL_PARAMETER: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: RELATION.ILLEGAL_PARAMETER"},
      ILLEGAL_TYPE: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: RELATION.ILLEGAL_TYPE"},
      LIMIT_RATE: {message: () => "PINコード認証の回数が上限を超えました。しばらくしてからもう一度お試し下さい。"},
      NO_USER: {message: () => "ユーザが存在しません。" + this.FATAL_MSG + "<br><br>コード: RELATION.NO_USER"},
      NOT_ACCEPTABLE: {message: () => "承認できるリクエストではありません。"},
      NOT_ALT_USER: {message: () => "見守り対象アカウントではありません。"},
      NOT_EXIST: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: RELATION.NOT_EXIST"},
      NOT_EXIST_USER: {message: () => "ユーザが存在しません。"},
      NOT_REQUEST: {message: () => "承認待ちではありません。"},
      SAME_USER: {message: () => "同一ユーザーではファミリーリンクを作れません。"},
      TOKEN_EXPIRED: {message: () => "申請されていないか時間切れです。再度申請してください。"},
      UPDATE_DENIED: {message: () => "変更する権限がありません。"},
      SESSION_NOT_FOUND: {message: () => "ファミリー申請の期限が切れました。<br>再度QRコードを読み込んでから、招待操作を行ってください。"},
      UNAUTH: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: RELATION.UNAUTH"},
      EXIST_RESERVATION: {message: () => "ファミリーメンバーの利用予定をキャンセルしてから解除してください。"},
      NOT_ESTABLISHED: {message: () => "承認待ちです。"},
    },
    SEARCH: {
      ILLEGAL_QUERY: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: SEARCH.ILLEGAL_QUERY"}
    },
    SQRC: {
      NOT_EXIST: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: SQRC.NOT_EXIST"},
      NO_PARAMETER: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: SQRC.NO_PARAMETER"},
      ENCODE_FAILED: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: SQRC.ENCODE_FAILED"},
      UPDATE_LIMIT_RATE: {message: () => "QRコードの再発行回数が上限を超えました。QRコードの再発行は1日に100回までです。"},
      USER_NOT_EXIST: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: SQRC.USER_NOT_EXIST"},
      NOT_ALLOWED_PROXY_RESERVATION: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: SQRC.NOT_ALLOWED_PROXY_RESERVATION"},
      ILLEGAL_PARAMETER: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: SQRC.ILLEGAL_PARAMETER"},
      RESTRICT_UTILIZATION: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: SQRC.RESTRICT_UTILIZATION"},
      DECODE: {message: () => "QRコードの読み取りに失敗しました。<br>再度読み取りを行ってください。"},
      DECODE_REQUEST: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: SQRC.DECODE_REQUEST"},
      ILLEGAL_CODE: {message: () => "QRコードが正しくありません。"},
      ILLEGAL_FACEMARK: {message: () => "QRコード読み取りエラーが起こりました。"},
      NO_JUNPUJI_ID: {message: () => "会員情報の登録が必要です。"},
      NO_PERMISSION: {message: () => "この機能を利用する権限がありません。"},
      TOKEN_REQUEST: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: SQRC.TOKEN_REQUEST"},
    },
    QRCODE: {
      UNMATCH_HASH: {message: () => "無効なQRコードです。"},
      PARSE_ERROR: {message: () => "無効なQRコードです。" + this.FATAL_MSG + "<br><br>コード: QRCODE.PARSE_ERROR"},
      ILLEGAL_FORMAT: {message: () => "無効なQRコードです。"}
    },
    QR: {
      MUNICIPAL_NOT_EXIST: {message: () => "自治体が見つかりません。" + this.FATAL_MSG + "<br><br>コード: QR.MUNICIPAL_NOT_EXIST"},
      ISSUE_ADDRESS_NOT_FOUND: {message: () => "利用者カードの発行依頼を行うことができませんでした。" + this.FATAL_MSG + "<br><br>コード: QR.ISSUE_ADDRESS_NOT_FOUND"},
    },
    TICKET: {
      DELETED: {message: () => "乗車券が削除されています。" + this.FATAL_MSG + "<br><br>コード: TICKET.DELETED"},
      ILLEGAL_TYPE: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: TICKET.ILLEGAL_TYPE"},
      ALREADY_PURCHASED: {message: () => "この乗車券は既に購入されています。" + this.FATAL_MSG + "<br><br>コード: TICKET.ALREADY_PURCHASED"},
    },
    USER: {
      CARD_NOT_EXIST: {message: () => "カードは未登録です。"},
      CARD_UNREGISTERED: {message: () => "カードは未登録です。"},
      ILLEGAL_BIRTH: {message: () => "パラメータ不正(生年月日)"},
      ILLEGAL_QRCODE: {message: () => "QRコードが不正です。"},
      NOT_EXIST: {message: () => "ユーザが存在しません。"},
      PAYMENT_NO_CARD: {message: () => "カードは未登録です。"},
      PAYMENT_NOT_EXIST_CARD: {message: () => "カードは未登録です。"},
      PAYMENT_REQUIRED: {message: () => "お支払い方法は最低でも一つ登録されている必要があるため、削除できませんでした。<br/>別のお支払い方法を登録した上、再度削除を行って下さい。"},
      PAYMENT_TOURIST_JABANK: {message: () => "JAバンク口座引き落としによる支払いは住民のみです。"},
      PAYMENT_TOURIST_METHOD: {message: () => "観光ユーザーの利用はカード支払いのみです。"},
      PAYMENT_TOURIST_SLIP: {message: () => "振替依頼書による支払いは住民ユーザーのみです。"},
      PAYMENT_TOURIST_YUCHO: {message: () => "ゆうちょ口座引き落としによる支払いは住民のみです。"},
      RELATION_NOT_EXIST: {message: () => "見守り対象のファミリーリンクがありません。"},
      NOT_EXIST_WITH_QR: {message: () => "無効なQRコードです。" + this.FATAL_MSG + "<br><br>コード: USER.NOT_EXIST_WITH_QR"},
      NO_METHOD: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: USER.NO_METHOD"},
      QR_VER_CONFLICT: {message: () => "読み取ったQRコードは古いバージョンのため、使用することができません。<br>最新のQRコードを確認し、再度招待操作を行ってください。"},
      NOT_US_QR: {message: () => "無効なQRコードです。"},
      INVALID_ADDRESS: {message: () => "存在しない住所です。"},
      ILLEGAL_PAYMENT_METHOD: {message: () => "この支払い方法は設定可能ではありません。" + this.FATAL_MSG + "<br><br>コード: USER.ILLEGAL_PAYMENT_METHOD"},
      PAYMENT_TOURIST_BANK77: {message: () => "観光ユーザーは七十七銀行を支払い方法として設定することができません。" + this.FATAL_MSG + "<br><br>コード: USER.PAYMENT_TOURIST_BANK77"},
      CARD_GMO_REQUEST: {message: () => "クレジットカード情報を取得することができませんでした。" + this.FATAL_MSG + "<br><br>コード: USER.CARD_GMO_REQUEST"},
    },
    WITHDRAWAL: {
      ALREADY: {message: () => "既に退会済みです。"},
      HAS_RELATION_FAMILY: {message: () => "ファミリーリンクを解除してから実行してください。"},
      HAS_RESERVATION: {message: (opt: {service:  'dispatch' | 'shopping' | 'exp-service'}) => {
        let serviceName: string = "";
        switch(opt.service) {
          case 'dispatch':
            serviceName = '配車サービス';
            break;
          case 'shopping':
            serviceName = '買い物サービス';
            break;
          case 'exp-service':
            serviceName = '体験サービス';
            break;
        }
        return serviceName + "予約をキャンセルしてから実行してください。";
      }},
      HAS_TICKET: {message: () => "利用期限が残っているチケットがあります。"},
      HAS_SCHEDULE: {message: () => "定期配車予約設定を解除してから実行してください。"},
      HAS_CONTINUED_TICKET: {message: () => "定額乗車券の継続購入設定を解除してから実行してください。"},
      HAS_PERIOD_TICKET: {message: () => "利用期間の残っている定額乗車券の払い戻しを行ってから実行してください。"},
      HAS_FAMILY_PURCHASE: {message: () => "ファミリーメンバーのサービス予約のキャンセル、定額券の払い戻しを行ってから実行してください。"},
    },
    CONNECT: {
      PARAMETER: {message: () => "注文情報が見つかりません。" + this.FATAL_MSG + "<br><br>コード: CONNECT.PARAMETER"},
      NO_DISTINATION: {message: () => "配達先が指定されていません。" + this.FATAL_MSG + "<br><br>コード: CONNECT.NO_DISTINATION"},
      RESTRICT_UTILIZATION: {message: () => "管理者によりサービス利用制限中です。<br>管理者が代行するか、管理者に依頼して制限解除を行ってください。"},
      USER_SUSPENDED: {message: () => "アカウント停止中のため利用できません。" + this.CONTACT_MSG},
      PAYER_SUSPENDED: {message: () => "決済者のアカウントが停止中のため利用できません。" + this.CONTACT_MSG},
      NO_PAYMENT: {message: () => "支払い方法が登録されていません。<br>支払い方法の登録を行ってから再度操作を行ってください。"},
      NOT_EXIST_PLAN_ID: {message: () => "配達希望日時指定から一定時間以上経っているため、注文できませんでした。再度カート一覧画面から注文操作を行ってください。"},
      QR_UNAUTH: {message: () => "ファミリー以外、QR認証していないユーザーの利用はできません"},
      PACKAGE_NOT_EXIST: {message: () => "注文情報が見つかりません。" + this.FATAL_MSG + "<br><br>コード: CONNECT.PACKAGE_NOT_EXIST"},
      SECTION_NOT_EXIST: {message: () => "注文情報が見つかりません。" + this.FATAL_MSG + "<br><br>コード: CONNECT.SECTION_NOT_EXIST"},
      CANCEL_AS: {message: () => "注文をキャンセルできませんでした。" + this.FATAL_MSG + "<br><br>コード: CONNECT.CANCEL_AS"},
      CANCEL_USER_FORBIDDEN: {message: () => "ログインユーザー、ファミリーメンバー以外のユーザーの注文はキャンセルできません。" + "<br><br>コード: CONNECT.CANCEL_USER_FORBIDDEN"},
      CANCEL_LIMIT: {message: () => "キャンセル期限を過ぎたため、注文キャンセルできません。"},
      SHOP_FORBIDDEN: {message: () => "ログインユーザーはこの店舗の従業員ではありません。"},
      ORDER_NOT_EXIST: {message: () => "注文情報が見つかりません。" + this.FATAL_MSG + "<br><br>コード: CONNECT.ORDER_NOT_EXIST"},
      CANCEL_DELIVERED: {message: () => "既に配達済みのため、注文キャンセルできません。"},
      ORDER_FORBIDDEN: {message: () => "予約情報が取得できませんでした。"},
    },
    SG: {
      TIME_LIMIT: {message: () => "開始時刻を過ぎたため、予約変更・キャンセルできません。"},
      ALREADY_DONE: {message: () => "既に利用中のため予約変更・キャンセルできません。"},
      // 予約変更
      UPDATE_LIMIT: {message: () => "変更受付期限を過ぎたため、予約変更できません。"},
      UPDATE_AS: {message: () => "予約を変更できませんでした。" + this.FATAL_MSG + "<br><br>コード: SG.UPDATE_AS"},
      // 予約キャンセル
      CANCEL_LIMIT: {message: () => "キャンセル期限を過ぎたため、予約キャンセルできません。"},
      RESERVATION_NOT_FOUND: {message: () => "予約情報が見つかりません。" + this.FATAL_MSG + "<br><br>コード: SG.RESERVATION_NOT_FOUND"},
      ALREADY_CANCELED: {message: () => "既にキャンセルされた予約です。"},
      CANCEL_AS: {message: () =>  "予約をキャンセルできませんでした。" + this.FATAL_MSG + "<br><br>コード: SG.CANCEL_AS"},
      DIFFERENT_CANCEL_RULE: {message: () => "時間経過により、キャンセル料金が変更になりました。再度確認の上、キャンセル手続きを行ってください。"},
      // 
      SHOP_NOT_EXIST: {message: () => "選択した店舗が見つかりません。" + this.FATAL_MSG + "<br><br>コード: SG.CANCEL_AS"},
      RESTRICT_UTILIZATION: {message: () => "管理者によりサービス利用制限中です。<br>管理者が代行するか、管理者に依頼して制限解除を行ってください。"},
      NO_QR: {message: () => "QRコードが未登録です。<br>QRコードタブより登録してください。"},
      PAYER_SUSPENDED: {message: () => "このユーザの決済者がアカウント停止中のため、利用できません。" + this.CONTACT_MSG},
      NO_PAYMENT: {message: () => "支払い方法が登録されていません。<br>支払い方法の登録を行ってから再度操作を行ってください。"},
      ILLEGAL_PARAMETER: {message: () => "予約ができませんでした。" + this.FATAL_MSG + "<br><br>コード: SG.ILLEGAL_PARAMETER"},
      ILLEGAL_QRCODE: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: SG.ILLEGAL_QRCODE"},
      RESOURCE_OCCUPIED: {message: () => "選択した時間に空きがありません。"},
      RESOURCE_NOT_REGISTERED: {message: () => "予約を変更できませんでした。" + this.FATAL_MSG + "<br><br>コード: SG.RESOURCE_NOT_REGISTERED"},
      NOT_DROP_OFF: {message: () => "貸出、返却場所は同じ場所を選択してください。"},
      UNCONFORMITY_RESOURCES: {message: () => "予約を変更できませんでした。" + this.FATAL_MSG + "<br><br>コード: SG.UNCONFORMITY_RESOURCES"},
      NUMBERS_NOT_EXIST: {message: () => "予約変更で利用数を0にすることはできません。"},
      USER_NOT_ALLOWED_CHANGE_RESOURCE: {message: () => "号車変更はできません。"},
      USER_NOT_ALLOWED_CHANGE_TIME: {message: () => "利用時間延長はできません。"},
      USER_NOT_ALLOWED_CHANGE_PLACE: {message: () => "場所変更はできません。"},
      NOT_ALLOWED_SHORTEN_TIME: {message: () => "利用時間短縮はできません。"},
      NUMBER_EMPTY: {message: () => "予約ができませんでした。" + this.FATAL_MSG + "<br><br>コード: SG.NUMBER_EMPTY"},
      NOT_AVAILABLE: {message: () => "選択した時間に空きがありません。"},
      PRICE_NOT_EXIST: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: SG.PRICE_NOT_EXIST"},
      RESERVATION_CHANGE_CONFLICT: {message: () => "予約変更・キャンセルができませんでした。" + this.FATAL_MSG + "<br><br>コード: SG.RESERVATION_CHANGE_CONFLICT"},
      RESERVATION_OVER_RANGE: {message: () => "指定した利用時間が最大利用時間を超えています。" + this.FATAL_MSG},
      NUMBERS_NOT_APPROPRIATE: {message: () => "予約が出来ませんでした。" + this.FATAL_MSG + "<br><br>コード: SG.NUMBERS_NOT_APPROPRIATE"},
      DIFFERENT_PRICE_RULE: {message: () => "時間経過により、料金ルールが変更しました。<br>再度予約操作を行ってください。"},
      SERVICE_NOT_EXIST: {message: () => "未サポートのサービスです。" + this.FATAL_MSG + "<br><br>コード: SG.SERVICE_NOT_EXIST"},
      OVER_DATE: {message: () => "観光サービス予約日は本日から2ヵ月後までを設定してください。"},
      OVER_RESERVATION_EXPANSION: {message: () => "体験サービスは、24時間を超えて延長することはできません。" + this.FATAL_MSG + "<br><br>コード: SG.OVER_RESERVATION_EXPANSION"},
      END_TIME_OVER_EXPIRY_DATE: {message: (option: {shop_id: string, expiry_date: string}) => `この店舗は、${moment(option.expiry_date).format('YYYY年MM月DD日')}以降の予約をすることができません。`},
      OPTION_TIME_OVER_RESERVATION: {message: () => "オプション利用時間がサービス利用時間を超えています。サービス利用時間以下を選択してください。"},
      DIFFERENT_USER_REQUEST: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: SG.DIFFERENT_USER_REQUEST"},
      DIFFERENT_RESERVATION: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: SG.DIFFERENT_RESERVATION"},
      RESERVE_FORBIDDEN: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: SG.RESERVE_FORBIDDEN"},
      NOT_ACCEPTED: {message: () => "操作中のサービスは提供期間外であるため、予約することができません。"},
      DATE_CONTRADICTION: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: SG.DATE_CONTRADICTION"},
      INVALID_PARAMETER: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: SG.INVALID_PARAMETER"},
      RESERVATION_FETCH_CONFLICT: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: SG.RESERVATION_FETCH_CONFLICT"},
      RESERVE_LIMIT: {message: () => "受付締切時間を過ぎました。"},
      CANNOT_FREE_RESERVE: {message: (errOption: "reserve"|"change") => {
        switch(errOption) {
          case "reserve":
            return "合計金額が0円のため、予約をすることはできません。条件を変更してください。";
          case "change":
            return "合計金額が0円のため、予約変更はできません。条件を変更してください。";
          default: 
            return "合計金額が0円のため、この操作はできません。条件を変更してください。";
        }
      }},
    },

    SG_SERVICE: {
      ILLEGAL_RANGE: {message: () => "空き時間の取得ができませんでした。" + this.FATAL_MSG + "<br><br>コード: SG_SERVICE.ILLEGAL_RANGE"},
      OVER_RANGE: {message: () => "空き時間の取得ができませんでした。" + this.FATAL_MSG + "<br><br>コード: SG_SERVICE.OVER_RANGE"},
      NO_UTIL_TIME: {message: () => "空き時間の取得ができませんでした。" + this.FATAL_MSG + "<br><br>コード: SG_SERVICE.NO_UTIL_TIME"},

    },

    SG_OPTION: {
      NOT_EXIST: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: SG_OPTION.NOT_EXIST"},
      PRICE_NOT_EXIST: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: SG_OPTION.PRICE_NOT_EXIST"},
      ILLEGAL_PARAM: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: SG_OPTION.ILLEGAL_PARAM"},
      ILLEGAL_RESERVATION: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: SG_OPTION.ILLEGAL_RESERVATION"},
      PRICE_RULE_UNMATCH: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: SG_OPTION.PRICE_RULE_UNMATCH"},
      UNMATCH_REQUEST: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: SG_OPTION.UNMATCH_REQUEST"},
      UPDATE_LIMIT: {message: () => "オプション変更の期限を過ぎています。"},

    },
    SG_OPTION_PRICE: {
      PARAM_UNDEFINED: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: SG_OPTION_PRICE.PARAM_UNDEFINED"},
      ILLEGAL_PARAM: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: SG_OPTION_PRICE.ILLEGAL_PARAM"},
      CALC_FAILED: {message: (errOpt: {sg_option_id: number, param: "number"|"days"|"mins", table_limit: number}, optionName: string) => {
        const name: string = optionName ? optionName : "オプション";
        let type: string = "";
        let number: string = "";
        switch(errOpt?.param) {
          case "number":
            type = "個数";
            number = errOpt.table_limit + "個";
            break;
          case "days":
            type = "日数";
            number = errOpt.table_limit + "日";
            break;
          case "mins":
            const min = errOpt.table_limit % 60;
            const hour = Math.floor(errOpt.table_limit / 60);
            const limit: string = hour > 96 ? `${Math.floor(hour / 24)}日` + (hour % 24 !== 0 ? hour % 24 + '時間' : '') + (min !== 0 ? min + '分' : '') : (hour !== 0 ? hour + '時間' : '') + (min !== 0 ? min + '分' : '');        

            type = "時間";
            number = limit;
            break;
          default: return this.UNEXPECTED_MSG + this.FATAL_MSG;
        }
        if (type !== "") return name + "の" + type + "が上限を超えています。" + number + "以下を選択してください。";
        else return this.UNEXPECTED_MSG + this.FATAL_MSG;
      }},      
    },
    SG_PRICE: {
      CALC_FAILED: {message: () => "選択可能な最大時間を超えています。選択しなおしてください。"},

    },
    AREA_MATRIX: {
      ILLEGAL_DATA: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: AREA_MATRIX.ILLEGAL_DATA"},
      NOT_EXIT: {message: () => "運行区間はありません。"},
      
    },
    BUSSTOP: {
      DISTANCE_MATRIX: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: BUSSTOP.DISTANCE_MATRIX"},
    },
    NEWS: {
      NO_TEXT: {message: () => "本文がありません。"},
      PHOTOS_TOO_MANY: {message: (_, max_number: number = this.setting?.news.photos.max_number) => `写真が${max_number}枚を超えています。`}, 
      ARTICLE_NOT_EXIST: {message: () => "指定の記事はありません。"},
      CATEGORY_NOT_EXIST: {message: () => "カテゴリが存在しません。"},
      ARTICLE_FORBIDDEN: {message: () => "この記事は変更できません。"},
      CATEGORY_DUPLICATED: {message : () => "同じ名前のカテゴリが既に登録されています。"},
      CATEGORY_INVALID_DATE: {message: () => "有効期限の設定可能範囲外です。"},
      QUERY_UNSUPPORTED: {message: () => "複数カテゴリ検索では記事取得数を制限できません。"},
      QUERY_FORBIDDEN: {message: () => "検索条件が不正です。"},
      FILENAME_UNSUPPORTED: {message: () => "写真の拡張子が不正です。"},
      TEXT_TOO_LONG: {message: () => "本文が文字数制限を超えています。"},
      USER_NOT_EXIST: {message: () => "このユーザの従業員情報がありません。"},
      NO_CATEGORY: {message: () => "カテゴリが選択されていません。"},
      AUTHOR_NEVER_AGREE_TERM: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: NEWS.AUTHOR_NEVER_AGREE_TERM"},

    },
    MENU: {
      EXPIRY_DATE_INVALID: {message: () => "販売終了日が正しくありません。"},
      START_DATE_INVALID: {message: () => "販売開始日が正しくありません。"},
      EXPIRY_DATE_PAST: {message: () => "販売終了日が過去に設定されています。"},
      NO_SHOP_ID: {message: () => "店舗IDが設定されてません。"},
      NO_START_DATE: {message: () => "販売開始日が設定されてません。"},
      NO_CLASS: {message: () => "物販系分類が設定されてません。"},
      NO_PRICE: {message: () => "料金が設定されてません。"},
      NO_NAME: {message: () => "メニュー名が設定されてません。"},
      NOT_FOUND: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: MENU.NOT_FOUND"},
    },

    SHOP: {
      NOT_EXIST: {message: () => "店舗が見つかりません。"},
      NOT_FOUND: {message: () => "店舗が見つかりません。" + this.FATAL_MSG + "<br><br>コード: SHOP.NOT_FOUND"},
    },
    // カード請求エラー
    CARD: {
      NOT_AVAILABLE: {message: () => "このカードは取り扱っていないため、登録できません。"},
      ILLEGAL_PARAMETER: {message: () => "入力内容に誤りがあるため、カードを登録できませんでした。"},
      UNKNOWN: {message: () => "カードを登録できませんでした。"},
    },
    DELIVERY_AREA: {
      NOT_EXIST: {message: () => "配送エリアが見つかりません。" + this.FATAL_MSG + "<br><br>コード: DELIVERY_AREA.NOT_EXIST"},

    },
    TERM: {
      NOT_FOUND: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: TERM.NOT_FOUND"},
    },
    TERM_AGREEMENT: {
      ILLEGAL_QUERY: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: TERM_AGREEMENT.ILLEGAL_QUERY"},
      IS_CITIZEN_NOT_CHANGEABLE: {message: () => this.UNEXPECTED_MSG + this.FATAL_MSG + "<br><br>コード: TERM_AGREEMENT.IS_CITIZEN_NOT_CHANGEABLE"},
    },
    SIGNUP: {
      PROXY: {message: () => "操作代行アプリからアカウント登録することはできません。"},

    },

  }

  /**
   * サーバーエラーコードからメッセージを取得
   *
   * @param {string} [code]
   * @return {*}  {Message["message"]|null}
   * @memberof MESSAGE
   */
  getServerMessage(code: string = "", option: any, useCase: any): (Message["message"] | null) {
    // メッセージが定義されてあればそのメッセージを出力
    // メッセージが無い・そもそもコードが存在しなければ、nullを出力
    if (!code) return null;

    const keys = code.split(".");
    if (keys.length!==2) return null;
    
    return this.SERVER[keys[0]] && this.SERVER[keys[0]][keys[1]]?.message(option, useCase) ? this.SERVER[keys[0]][keys[1]].message : null;
  }
}

/**
 * エラーコードとメッセージの連想配列
 *
 * @interface MessagePackage
 */
interface MessagePackage {
  readonly [key:string]: {
    readonly [sub_key: string]: Message
  };
}

/**
 * エラーコードとメッセージ
 *
 * @interface Message
 */
export interface Message {
  /**
   * エラーコード(サーバーのみ)
   *
   * @type {string}
   * @memberof Message
   */
  code?: string;
  /**
   * メッセージ(ユースケースに応じて出力値を区別)
   *
   * @param usecase ユースケース
   * @return string
   * @memberof Message
   */
  readonly message: (usecase1?: any, usecase2?: any) => string;
}
