<ons-toolbar>
  <div class="left">
    <ons-back-button></ons-back-button>
  </div>
  <div class="center">見守り対象アカウント</div>
</ons-toolbar>
<div class="content">
  <ons-list>
    <ons-list-item modifier="longdivider">
      <div class="content--list--description">
        見守り対象アカウント代理作成機能とは、ファミリー管理者がご家族の代わりにアカウントを作成・管理し、自分ではアカウントを持てないご家族でもおでかけサービスを利用できるようにするための機能です。<br>
        （※本機能で作成されるアカウントで利用できるのはおでかけサービスのみです。
        その他のサービス（買い物・観光等）では利用できません。）
      </div>
    </ons-list-item>
    <ons-list-item modifier="longdivider" expandable>
      <div class="list-item__title">見守り対象アカウント代理作成機能の特徴</div>
      <div class="expandable-content content-border--top">
        <br>
        <div class="content-itemization">
          <ol>
            <li><b><span>&#9312;</span> 管理者がご本人の代わりにアカウントを作成</b></li>
          </ol>
        </div>
        <div class="content--description">
          <p>見守り対象アカウントの作成はファミリーの追加より行うことができます。</p>
        </div>
        <div class="content-itemization">
          <ol>
            <li><b><span>&#9313;</span> 管理者がご本人に代わりにユーザー情報登録を行う</b></li>
          </ol>
        </div>
        <div class="content--description">
          <p>
            見守り対象アカウントのユーザー情報登録は管理者が行ってください。必須項目は「名前 / 生年月日 / 性別」となります。<br>
            アカウントの作成に、メールアドレスなどのログイン情報は必要ありません。<br>
            また、ご本人に代わりに管理者による
            <ons-button id="proxy-account-description_001" modifier="quiet" (click)="viewUsePolicy()"><span id="link" class="button-text">利用規約</span></ons-button>および
            <ons-button id="proxy-account-description_002" modifier="quiet" (click)="viewPrivacyPolicy()"><span id="link" class="button-text">プライバシーポリシー</span></ons-button>への同意が必要になります。
          </p>
        </div>
        <div class="content-itemization">
          <ol>
            <li><b><span>&#9314;</span> 作成されたアカウントは自動的にファミリーリンクが設定される</b></li>
          </ol>
        </div>
        <div class="content--description">
          <p>見守り対象アカウントが代理作成された時点で、管理者のファミリーメンバーに自動追加されます。</p>
        </div>
        <div class="content-itemization">
          <ol>
            <li><b><span>&#9315;</span> サービス予約やチケット購入は全て管理者がご本人の代わりに実施</b></li>
          </ol>
        </div>
        <div class="content--description">
          <p>見守り対象アカウントはログインの為の情報を持っておらずログインできないため、サービスの予約やチケット購入は全て管理者アカウントより実施ください。</p>
        </div>
        <div class="content-itemization">
          <ol>
            <li><b><span>&#9316;</span> サービスに関するご連絡は管理者へ代理通知</b></li>
          </ol>
        </div>
        <div class="content--description">
          <p>サービスに関するご連絡(予約時、配車時、決済時など)は全て管理者に送られます。</p>
        </div>
      </div>
    </ons-list-item>
  </ons-list>
</div>