<div class="swiper-area">
  <!-- 配下の画像をswiperとして表示 -->
  <swiper *ngIf="images?.length > 0; else noPhoto" 
    [slidesPerView]="1" [spaceBetween]="50" [centeredSlides]="true" [navigation]="true" [pagination]="{ clickable: true }"
    [controller]="{ control: controlledSwiper }" id="swiperViewerImages">

    <ng-container *ngFor="let image of images">
      <ng-template swiperSlide>
        <div class="image--background">
          <img [src]="image" (click)="onEnlargeImage()">
        </div>
      </ng-template>
    </ng-container>
  </swiper>
</div>

<!-- template -->
<ng-template #noPhoto>
  <div class="image--background image-center">
    <img [src]="noImage">
  </div>
</ng-template>