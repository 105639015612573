// ========================================================================================================================
// 各種インポート(モジュール、コンポーネント、サービス等)
// ========================================================================================================================
import { Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { OnsNavigator, Params } from 'ngx-onsenui';
import * as moment from 'moment-timezone';

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { PagerService } from '../../../lib-services/pager.service';
import { UserWebApiService } from '../../../http-services/user-web-api.service';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';
import { GoogleTagManagerService } from '../../../lib-services/google-tag-manager.service';

// component
import { PaymentMethod } from '../payment-method';
import { PaymentMethodListComponent } from '../payment-method-list/payment-method-list.component';

// interface
import { request } from '../../../interfaces/request';

// other
import { MESSAGE } from 'src/app/constants/message';

// ========================================================================================================================
// GMO-PGトークン取得関連関数のアンビエント宣言
// ========================================================================================================================

// トークン取得関数(関数名はGMO-PG空の指定なので変更できない)
declare var Multipayment : any;

// トークン取得時のコールバック関数のひな型を追加(Windowオブジェクトに拡張)
// ⇒コールバック関数はグローバルスコープに無いと、CDNから取得したライブラリ側で認識されないため
declare global
{
  interface Window
  {
    // この関数名は変更可能
    execPurchase(Response): void;
  }
}

// ========================================================================================================================
// メタデータ定義
// ========================================================================================================================

/**
 * クレジットカード追加画面。
 *
 * @export
 * @class PaymentMethodCreditAddComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component
({
  selector: 'ons-page[payment-method-credit-add]',
  templateUrl: './payment-method-credit-add.component.html',
  styleUrls: ['./payment-method-credit-add.component.css']
})

// ========================================================================================================================
// クラス定義
// ========================================================================================================================

export class PaymentMethodCreditAddComponent implements OnInit, OnDestroy
{
  // ================================================================================
  // 変数定義
  // ================================================================================

  /**
   * HTTP通信用
   *
   * @type {Subscription}
   * @memberof PaymentMethodCreditAddComponent
   */
  busy: Subscription;

  /**
   * デフォルト支払い方法のチェックボックス表示可否
   *
   * @type {boolean}
   * @memberof PaymentMethodCreditAddComponent
   */
  isDefaultFlagDisabled: boolean;

  /**
   * カード番号一式
   *
   * @type {QueryList<ElementRef>}
   * @memberof PaymentMethodCreditAddComponent
   */
  @ViewChildren('cardNos') cardNos: QueryList<ElementRef>;

  /**
   * 入力フォーム群
   *
   * @type {FormGroup}
   * @memberof PaymentMethodCreditAddComponent
   */
  creditForm: FormGroup;

  /**
   * 今年から7年後までの年数下2桁の配列 ※HTMLで使用
   *
   * @memberof PaymentMethodCreditAddComponent
   */
  expireYears = (function ()
  {
    const thisYear = moment().year();
    return [...Array(8)].map((_, i) => `${thisYear + i}`.slice(-2));
  }());

  /**
   * 1月~12月(2桁表示) ※HTMLで使用
   *
   * @memberof PaymentMethodCreditAddComponent
   */
  expireMonths = [...Array(12)].map((_, i) => (`00${i + 1}`.slice(-2)));

  /**
   * 支払い方法の登録状況
   *
   * @type {PaymentMethod.PaymentRegistration}
   * @memberof PaymentMethodCreditAddComponent
   */
  paymentRegistration: PaymentMethod.PaymentRegistration;

  // ================================================================================
  // ライフサイクルメソッド定義
  // ================================================================================

  /**
   * Creates an instance of PaymentMethodCreditAddComponent.
   * @param {Params} params
   * @param {UserWebApiService} userWebApiService
   * @param {HttpErrorResponseParserService} httpErrorResponseParserService
   * @param {ApplicationMessageService} appMsg
   * @memberof PaymentMethodCreditAddComponent
   */
  constructor
  (
    private appMsg: ApplicationMessageService,
    private httpErrorResponseParserService: HttpErrorResponseParserService,
    private pager: PagerService,
    private userWebApiService: UserWebApiService,
    private navigator: OnsNavigator,
    private params: Params,
    private MunicipalityWebApiServ: MunicipalityWebApiService,
    private msg: MESSAGE,
    private gtmServ: GoogleTagManagerService,
  ) { }

  /**
   * 初期化処理。
   *
   * @memberof PaymentMethodCreditAddComponent
   */
  ngOnInit(): void
  {
    this.paymentRegistration = this.params.data.paymentRegistration;

    this.isDefaultFlagDisabled = this.userWebApiService.getUserInfo().payment.method === 'NONE';

    const now = moment();

    this.creditForm = new FormGroup({
      card_nos: new FormArray([
        new FormControl(''),
        new FormControl(''),
        new FormControl(''),
        new FormControl('')
      ],
      [
        // カスタムvalidator
        this.cardNosRquiredValidator,
        this.cardNosPatternValidator
      ]),
      holder_name: new FormControl('', [
        Validators.required,
        Validators.pattern('^[A-Z]+ [A-Z]+$'),
      ]),
      // 有効期限年月の初期値は今年・今月
      expire_year: new FormControl(`${now.format('YY')}`, [
        Validators.required,
      ]),
      expire_month: new FormControl(`${now.format('MM')}`, [
        Validators.required,
      ]),
      security_code: new FormControl('', [
        Validators.required,
        Validators.pattern('^\\d{1,4}$'),
        Validators.minLength(3),
      ]),
      default_flag: new FormControl(true, [
        Validators.required,
      ]),
    });
  }

  /**
   * 破棄処理。
   *
   * @memberof PaymentMethodCreditAddComponent
   */
  ngOnDestroy(): void
  {
    this.busy?.unsubscribe();
  }

  // ================================================================================
  // 関数定義
  // ================================================================================

  /**
   * セキュリティコードの説明を表示する。
   *
   * @memberof PaymentMethodCreditAddComponent
   */
  cvvInfoAlert(): void
  {
    const message = `<b>▼ セキュリティコードとは</b><br>
    <br>
    クレジットカード決済を利用して購入手続きをする際に、クレジットカード番号とは別に、カード裏面に印字されている末尾3桁（AMEXの場合は表面の4桁）の数字を入力してもらうことで、本人認証を行うサービスです。<br>
    <br>
    ※カード会社により表示箇所および名称が異なります。`;

    this.appMsg.viewDialogMessage(message);
  }

  myTrackBy(index: number, obj: any): any
  {
    return index;
  }

  /**
   * クレジットカード番号のchangeイベントハンドラ
   *
   * @param {number} index
   * @param {*} val
   * @memberof PaymentMethodCreditAddComponent
   */
  changeCardNo(index: number, val: any): void
  {
    const nextIndex = index + 1;
    const isNextInputFocus = val.length === 4 && nextIndex !== (this.creditForm.get('card_nos') as FormArray).controls.length;

    if (isNextInputFocus) {
      // 4桁の入力が行われた時、次のクレジットカード番号のinput要素にforcusする
      this.cardNos.find((_, i) => i === nextIndex).nativeElement._input.focus();
    }
  }

  /**
   * クレジットカードを追加する。
   *
   * @memberof PaymentMethodCreditAddComponent
   */
  add(): void
  {
    const getVal = (name: string) => this.creditForm.get(name).value;

    // ==============================================
    // トークン取得時のコールバック関数定義
    // ⇒GMOのトークン取得後、この関数が呼び出される
    // ==============================================
    window.execPurchase = (Response) =>
    {
      // トークン取得成功
      if (Response.resultCode == "000")
      {
        const Card: request.Card =
        {
          token: Response.tokenObject.token,
          default_flag: getVal('default_flag')
        }

        this.busy = this.userWebApiService.createCard(Card).subscribe
        ({
          next: _ =>
          {
            this.appMsg.viewDialogMessage(this.msg.CLIENT.PAYMENT.REGIST.message(), _ => this.pager.transitionToTopPage(this.navigator, PaymentMethodListComponent));

            this.gtmServ.pushPageview('/payment-method/credit_add_complete/', 'クレジットカード登録完了');
            this.gtmServ.pushFbqTrack('AddPaymentInfo');
          },
          error: this.httpErrorResponseParserService.doParse((_err, errContent) =>
          {
            this.httpErrorResponseParserService.viewErrDialog(errContent);
          })
        });
      }
      // トークン取得失敗(カード情報に誤りあり)
      else if (Response.resultCode == "100" || Response.resultCode == "101" || Response.resultCode == "102" || Response.resultCode == "110" ||
               Response.resultCode == "111" || Response.resultCode == "112" || Response.resultCode == "113" || Response.resultCode == "121" ||
               Response.resultCode == "122" || Response.resultCode == "131" || Response.resultCode == "132")
      {
        this.appMsg.viewDialogMessage(this.msg.CLIENT.PAYMENT.E_TOKEN_CARD_INFO.message());
      }
      // トークン取得失敗(APIキーが存在しないor有効でない)
      else if (Response.resultCode == "551" || Response.resultCode == "552")
      {
        this.appMsg.viewDialogMessage(this.msg.CLIENT.PAYMENT.E_TOKEN_API_KEY.message());
      }
      // トークン取得失敗(ペイメント内部のシステムエラー)
      else if (Response.resultCode == "901")
      {
        this.appMsg.viewDialogMessage(this.msg.CLIENT.PAYMENT.E_TOKEN_GMO.message());
      }
      // トークン取得失敗(処理が混みあっている)
      else if (Response.resultCode == "902")
      {
        this.appMsg.viewDialogMessage(this.msg.CLIENT.PAYMENT.E_TOKEN_BUSY.message());
      }
      // トークン取得失敗(その他＝実装ミス)
      else
      {
        this.appMsg.viewDialogMessage(this.msg.CLIENT.PAYMENT.E_TOKEN_UNEXPECTED.message());
      }
    };

    // ==============================================
    // トークン取得実行
    // ==============================================

    // カード情報
    const CardObject =
    {
      cardno: getVal('card_nos').join(''),
      expire: `${getVal('expire_year')}${getVal('expire_month')}`,
      securitycode: getVal('security_code'),
      holdername: getVal('holder_name')
    }

    // トークン取得のAPIキー
    const strApiKey = this.MunicipalityWebApiServ.setting.token_payment_api_key;

    // 初期化
    Multipayment.init(strApiKey);

    // トークン取得
    Multipayment.getToken(CardObject, window.execPurchase);

  }

  /**
   * 登録済みのカードか判定する。
   *
   * @param {AbstractControl} formArray
   * @return {*}
   * @memberof PaymentMethodCreditAddComponent
   */
  cardNosRquiredValidator(formArray: AbstractControl): any
  {
    const val = (formArray?.value ?? []) as Array<string>;
    return val.some(e => e.length === 0) ? { cardNosRquired: true } : null;
  }

  /**
   * カード番号の体裁をチェックする。
   *
   * @param {AbstractControl} formArray
   * @return {*}
   * @memberof PaymentMethodCreditAddComponent
   */
  cardNosPatternValidator(formArray: AbstractControl): any
  {
    const val = (formArray?.value ?? []) as Array<string>;
    return val.some(e => e.match(/[^0-9]+/)) ? { cardNosPattern: true } : null;
  }
}
