//=============================================================================================
// インポート
//=============================================================================================
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import * as qs from 'qs';
import * as CONST from '../constants/constant';

// service
import { UserWebApiService } from './user-web-api.service';
import { MunicipalityWebApiService } from './municipality-web-api.service';
import { HttpErrorResponseParserService } from '../lib-services/http-error-response-parser.service';

// interface
import { NewsArticle, NewsCategory } from '../interfaces/response/article';
import { parameter } from '../interfaces/parameter';
import { Settings } from '../interfaces/response';
import { OnDestroy } from 'ngx-onsenui';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * ServiceConfig
 *
 * @export
 * @class NewsWebApiServiceConfig
 */
export class NewsWebApiServiceConfig {
  baseUrl: string;
  httpOptions?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
  };
}

/**
 * 記事サービス。
 *
 * @export
 * @class NewsWebApiService
 */
@Injectable({
  providedIn: 'root'
})

export class NewsWebApiService implements OnDestroy {

  // ================================================================================
  // 変数定義
  // ================================================================================

  /**
   * ニュースカテゴリ情報を持つサブジェクト
   *
   * @private
   * @type {BehaviorSubject<NewsCategory[]>}
   * @memberof NewsWebApiService
   */
  private CategoriesSubject:BehaviorSubject<NewsCategory[]> = new BehaviorSubject<NewsCategory[]>(null);

  // ================================================================================
  // 定数定義
  // ================================================================================

  /**
   * 既読ニュースのlocalStorageのkey
   *
   * @private
   * @type {string}
   * @memberof NewsWebApiService
   */
  private readonly STORAGE_KEY: string = 'smart-goto-read-articles';


//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * 自治体ごとの設定情報
   *
   * @type {Settings}
   * @memberof NewsWebApiService
   */
  setting: Settings;

  /**
   * OnDestroy時に破棄するSubscriptionオブジェクト
   *
   * @memberof NewsWebApiService
   */
  subscription = new Subscription();

  /**
   * Creates an instance of NewsWebApiService.
   * @param {HttpErrorResponseParserService} errResServ
   * @param {HttpClient} http
   * @param {UserWebApiService} userServ @param {NewsWebApiServiceConfig} config
   * @memberof NewsWebApiService
   */
  constructor(
    private errResServ: HttpErrorResponseParserService,
    private http: HttpClient,
    private userServ: UserWebApiService,
    private config: NewsWebApiServiceConfig,
    private municipalityWebApiServ: MunicipalityWebApiService,
  ) {
    const settingsChanged = this.municipalityWebApiServ.settingsChanged.subscribe({
      next: setting => {
        if (setting == null) return;
        this.setting = setting;
      }
    });
    this.subscription.add(settingsChanged);
  }
  /**
   * 廃棄処理
   *
   * @memberof NewsWebApiService
   */
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

//=============================================================================================
// メソッド
//=============================================================================================

  //////////////////////////////////////////////
  // クエリ
  //////////////////////////////////////////////

  /**
   * 記事取得クエリを作成する。
   *
   * @param {number} oldest_article_id
   * @param {boolean} [inifinity=false]
   * @return {*}  {parameter.NewsArticleQuery}
   * @memberof NewsWebApiService
   */
  public createGetArticleQeuery(oldest_article_id?: number): parameter.NewsArticleQuery {

    // 最大数かつ、取得済み記事IDより古い記事を対象
    let query: parameter.NewsArticleQuery = {
      limit: CONST.News.GET_ARTICLE_LIMIT,
    };

    // 無限スクロールの場合は取得済み記事より前
    if (undefined !== oldest_article_id ) {
      query.before_article = oldest_article_id;
    }

    return query;
  }

  /**
   * フリーワード検索クエリを作成する。
   *
   * @param {string} freeword
   * @return {*}  {parameter.NewsArticleQuery}
   * @memberof NewsWebApiService
   */
  public createFreewordSearchQuery(freeword: string): parameter.NewsArticleQuery {

    // // 全角スペースを半角に置換、前後のスペース除去
    // let word = freeword.replace(/　/g, " ");
    // word = word.trim();

    // クエリ作成
    let query = this.createGetArticleQeuery();

    // フリーワードをクエリに追加
    // if (freeword.length > 0) {
      query.freeword = freeword;
    // }

    return query;
  }

  /**
   * タップ検索クエリを作成する。
   *
   * @param {string} key
   * @param {string} target
   * @return {*}  {parameter.NewsArticleQuery}
   * @memberof NewsWebApiService
   */
  public createTapSearchQuery(key: string, target: string): parameter.NewsArticleQuery {

    // クエリ生成
    let query = this.createGetArticleQeuery();

    if (target === "shop") {
      // クエリ追加
      query.shop = new Array(key.toString());
    }

    if (target === "tag") {
      // 「#」削除
      key = key.replace(/^#/, "");

      // クエリ追加
      query.tags = new Array(key);
    }

    return query;
  }

  //////////////////////////////////////////////
  // 画像・PDF
  //////////////////////////////////////////////

  /**
   * 読み込んだ画像/PDFをリサイズする。
   *
   * @param {number} width
   * @param {number} height
   * @return {*}  {{ width: number, height: number }}
   * @memberof NewsWebApiService
   */
  public resizeImage(width: number, height: number): { width: number, height: number } {

    const News = this.setting.news;

    // リサイズ設定
    if (width > News.photos.max_width && width >= height) {

      height = Math.floor(News.photos.max_width * height / width);
      width = News.photos.max_width;
    }
    else if (height > News.photos.max_height && height > width) {

      width = Math.floor(News.photos.max_height * width / height);
      height = News.photos.max_height;
    }
    else ;

    return { width, height };
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * ニュース一覧を取得する。
   *
   * @param {NewsArticleQuery} [query]
   * @return {*}  {Observable<HttpResponse<NewsArticle[]>>}
   * @memberof NewsWebApiService
   */
  getArticles(query?: parameter.NewsArticleQuery): Observable<HttpResponse<NewsArticle[]>> {
    // 未読情報を常に取得する
    query.unread = true;

    // TODO query から params: HttpParam を作りたい
    return this.http.get<NewsArticle[]>(`${ this.config.baseUrl }/article?${qs.stringify(query)}`, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    }).pipe(map((response: HttpResponse<NewsArticle[]>) => {
        if (!this.userServ.isLoggedIn()) {
          response.body.forEach(article => {
            if (article.unread && this.existsLocalReadArticle(article)) {
              article.unread = false;
            }
          });
        }
        return response;
      }));
  }

  /**
   * カテゴリ情報を取得する。
   *
   * @return {*}  {Observable<HttpResponse<NewsCategory[]>>}
   * @memberof NewsWebApiService
   */
  getCategories(): Observable<HttpResponse<NewsCategory[]>> {
    if (this.userServ.isLoggedIn()) {
      return this.getCategoriesAPI();
    } else {
      return new Observable(subscriber => {
        this.getArticlesLatest().subscribe((res: HttpResponse<number[]>) => {
          this.clearOldLocalReadArticles(res.body);

          this.getCategoriesAPI().subscribe((response: HttpResponse<NewsCategory[]>) => {
            response.body.forEach(category => {
              if (category.unread_count > 0) {
                category.unread_count -= this.countLocalReadArticles(category);
              }
            });
            subscriber.next(response);
            subscriber.complete();
            this.CategoriesSubject.next(response.body);
          });
        });
      });
    }
  }

  /**
   * 記事を読む（既読にする）。
   *
   * @param {NewsArticle} article
   * @memberof NewsWebApiService
   */
  readArticle(article: NewsArticle) {
    article.unread = false;

    if (!this.userServ.isLoggedIn()) {
      this.addLocalReadArticles(article);
    }

    article.categories.forEach(categoryId => {
      const categoryToUpdate = this.CategoriesSubject.getValue().find(category => category.category_id === categoryId);
      if (categoryToUpdate) {
        categoryToUpdate.unread_count--;
      }
    });
  }

  /**
   * ローカルの全自治体既読記事を削除する。
   *
   * @memberof NewsWebApiService
   */
  clearLocalReadArticles(): void {
    localStorage.removeItem(this.STORAGE_KEY);
  }

  //=============================================================================================
  // private
  //=============================================================================================

  /**
   * 新着ニュース記事ID一覧を取得する。
   *
   * @private
   * @return {*}  {Observable<HttpResponse<string[]>>}
   * @memberof NewsWebApiService
   */
  private getArticlesLatest(): Observable<HttpResponse<number[]>> {
    return this.http.get<number[]>(`${ this.config.baseUrl }/article/latest`, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }

  /**
   * カテゴリ情報を取得する。
   *
   * @return {*}  {Observable<HttpResponse<NewsCategory[]>>}
   * @memberof NewsWebApiService
   */
  private getCategoriesAPI(): Observable<HttpResponse<NewsCategory[]>> {
    // 未読数を常に取得する
    const query: parameter.NewsCategoryQuery = {
      unread_count: true,
    };
    return this.http.get<NewsCategory[]>(`${ this.config.baseUrl }/category?${qs.stringify(query)}`, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    }).pipe(tap((response: HttpResponse<NewsCategory[]>) => {
            this.CategoriesSubject.next(response.body);
      }));
  }

  /**
   * ローカルの全自治体既読記事を取得する。
   *
   * @private
   * @return {*} {any}
   * @memberof NewsWebApiService
   */
  private getAllLocalReadArticles(): any {
    return JSON.parse(localStorage.getItem(this.STORAGE_KEY)) || {};
  };

  /**
   * ローカルの既読記事を取得する。
   *
   * @private
   * @return {*} {NewsArticle[]}
   * @memberof NewsWebApiService
   */
  private fetchLocalReadArticles(): NewsArticle[] {
    const localReadArticles = this.getAllLocalReadArticles();
    return localReadArticles[this.setting.municipal_id] || [];
  };

  /**
   * ローカルの既読記事に記事を追加する。
   *
   * @private
   * @param {NewsArticle} article
   * @memberof NewsWebApiService
   */
  private addLocalReadArticles(article: NewsArticle): void {
    const localReadArticles = this.getAllLocalReadArticles();
    localReadArticles[this.setting.municipal_id] = this.fetchLocalReadArticles().concat(article);
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(localReadArticles));
  };

  /**
   * ローカルの既読記事に記事があるか。
   *
   * @private
   * @param {NewsArticle} article
   * @return {*} {boolean}
   * @memberof NewsWebApiService
   */
  private existsLocalReadArticle(article: NewsArticle): boolean {
    return this.fetchLocalReadArticles().some(readArticle => readArticle.article_id === article.article_id);
  }

  /**
   * ローカルの既読記事数を取得する。
   *
   * @private
   * @param {NewsCategory} category
   * @return {*} {number}
   * @memberof NewsWebApiService
   */
  private countLocalReadArticles(category: NewsCategory): number {
    const articles = this.fetchLocalReadArticles();
    return articles.filter(article => article.categories.includes(category.category_id)).length;
  };

  /**
   * ローカルの既読記事から期間外の記事を削除する。
   *
   * @private
   * @param {number[]} articlesLatest
   * @memberof NewsWebApiService
   */
  private clearOldLocalReadArticles(articlesLatest: number[]): void {
    const localReadArticles = this.getAllLocalReadArticles();
    const localLatestReadArticles = this.fetchLocalReadArticles().filter(article => articlesLatest.includes(article.article_id));
    if (localLatestReadArticles.length > 0) {
      localReadArticles[this.setting.municipal_id] = localLatestReadArticles;
    } else {
      delete localReadArticles[this.setting.municipal_id];
    }
    localStorage.setItem(this.STORAGE_KEY, JSON.stringify(localReadArticles));
  }
}
