//=============================================================================================
// PaymentMethod専用定義
//=============================================================================================
import { InjectionToken } from '@angular/core';

// interface
import { UserInfo, Card } from '../../interfaces/response';

// 支払い方法が1件の場合のメッセージ：TODO：application serviceへ持っていく
export const DELETE_IMPOSSIBLE = "支払い方法は最低でも一つ登録されている必要があるため、削除できませんでした。<br>別の支払い方法を登録した上、再度削除を行って下さい。";

/**
 * 乗車券の購入可否結果
 *
 */
export namespace PaymentMethod 
{
  // 支払い方法表示文字
  export const METHOD_STR = 
  {
    CREDIT: "クレジットカード", 
    YUCHO: "ゆうちょ口座自動引き落とし", 
    JABANK: "JAバンク自動引き落とし", 
    SLIP: "振替払込書", 
    BANK77: "七十七銀行,その他銀行口座(ゆうちょ除く)引き落とし",
    DEFAULT: "（デフォルト）"
  } as const;
  
  /**
   * 支払い方法の登録状況
   *
   * @export
   * @interface PaymentRegistration
   */
  export interface PaymentRegistration 
  {
    /**
     * 支払い方法登録数
     *
     * @type {number}
     * @memberof PaymentRegistration
     */
    count: number;

    /**
     * ゆうちょ口座自動引き落とし登録有無
     *
     * @type {boolean}
     * @memberof PaymentRegistration
     */
    yucho?: boolean;

    /**
     * JAバンク自動引き落とし登録有無
     *
     * @type {boolean}
     * @memberof PaymentRegistration
     */
    jabank?: boolean;

    /**
     * 振替払込所登録有無
     *
     * @type {boolean}
     * @memberof PaymentRegistration
     */
    slip?: boolean;

    /**
     * 七十七銀行登録有無
     *
     * @type {boolean}
     * @memberof PaymentRegistration
     */
    bank77?: boolean;

    /**
     * クレジットカード登録有無
     *
     * @type {PaymentCard[]}
     * @memberof PaymentRegistration
     */
    card?: PaymentCard[];
  }
  
  /**
   * クレジットカードの状況
   *
   * @export
   * @interface PaymentCard
   * @extends {Card}
   */
  export interface PaymentCard extends Card 
  {
    /**
     * デフォルト選択カードか
     *
     * @type {boolean}
     * @memberof PaymentCard
     */
    is_default: boolean;
    
    /**
     * 有効期限切れ間近か
     *
     * @type {boolean}
     * @memberof PaymentCard
     */
    is_expired: boolean;

    /**
     * 有効期限切れ
     *
     * @type {boolean}
     * @memberof PaymentCard
     */
    is_near_expired: boolean;
  }

  /**
   * ユーザが選択し、遷移した支払い方法画面情報
   *
   * @export
   * @interface postPayment
   */
  export interface postPayment
  {
    /**
     * 追加/削除
     *
     * @type {('add' | 'detail')}
     * @memberof postPayment
     */
    view: 'add' | 'detail';
    
    /**
     * 支払い方法
     *
     * @type {UserInfo["payment"]["method"]}
     * @memberof postPayment
     */
    method: UserInfo["payment"]["method"];
  }
}