import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { OnsNavigator, Params } from 'ngx-onsenui';
import { Subscription } from 'rxjs';

import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';
import { HttpErrorResponseParserService } from 'src/app/lib-services/http-error-response-parser.service';
import { UserWebApiService } from 'src/app/http-services/user-web-api.service';

import { Settings, Term } from 'src/app/interfaces/response';
import { FamilyDescriptionComponent } from '../family/family-description/family-description.component';
import { ProxyAccountDescriptionComponent } from '../proxy-account/proxy-account-description/proxy-account-description.component';

@Component({
  selector: 'ons-page[app-term-agree]',
  templateUrl: './term-agree.component.html',
  styleUrls: ['./term-agree.component.scss']
})
export class TermAgreeComponent implements OnInit, OnDestroy {

  /**
   * 非同期処理監視Subscription
   *
   * @type {Subscription}
   * @memberof TermAgreeComponent
   */
  busy: Subscription;

  /**
   * 規約
   *
   * @type {Term}
   * @memberof TermAgreeComponent
   */
  term: Term;

  /**
   * チェック状態
   * 利用規約同意
   *
   * @type {boolean}
   * @memberof TermAgreeComponent
   */
  checkedTerms: boolean = false;

  /**
   * チェック状態
   * ファミリー関連
   *
   * @type {boolean}
   * @memberof TermAgreeComponent
   */
  checkedFamily: boolean = false;

  /**
   * 自治体ごとの設定情報
   *
   * @type {Settings}
   * @memberof TermAgreeComponent
   */
  setting: Settings;

  /**
   * OnDestroy時に破棄するSubscriptionオブジェクト
   *
   * @memberof TermAgreeComponent
   */
  subscription = new Subscription();

  /**
   * 観光ユーザーかどうか
   *
   * @type {boolean}
   * @memberof TermAgreeComponent
   */
  isTourist: boolean = false;

  /**
   * 住民ユーザーかどうか
   *
   * @type {boolean}
   * @memberof TermAgreeComponent
   */
  isCitizen: boolean  = true;

  /**
   * メール遷移かどうか
   *
   * @type {boolean}
   * @memberof TermAgreeComponent
   */
  isMail: boolean  = false;

  constructor(
    private params: Params,
    private navigator: OnsNavigator,
    private municipalityWebApiServ: MunicipalityWebApiService,
    private errResServ: HttpErrorResponseParserService,
    private userWebApiServ: UserWebApiService,
  ) {
    const settingsChanged = this.municipalityWebApiServ.settingsChanged.subscribe({
      next: setting => {
        if (setting == null) return;
        this.setting = setting;
      }
    });
    this.subscription.add(settingsChanged);
  }

  /**
   * 初期処理
   *
   * @memberof TermAgreeComponent
   */
  ngOnInit(): void {
    // メール遷移かどうか
    if (this.params.data?.isMail === true) this.isMail = true;

    // 最新規約を取得
    this.getTerm();
  }

  /**
   * 廃棄処理
   *
   * @memberof TermAgreeComponent
   */
  ngOnDestroy(): void {
    this.busy?.unsubscribe();
    this.subscription?.unsubscribe();
  }

  //=============================================================================================
  // イベントハンドラ
  //=============================================================================================

  /**
   * 利用規約を表示する
   *
   * @memberof TermAgreeComponent
   */
  viewTerm(): void {
    // 利用規約ページへ遷移
    window.open(this.term.url, '_blank');
  }

  /**
   * プライバシーポリシーを表示する
   *
   * @memberof TermAgreeComponent
   */
  viewPrivacyPolicy(): void {
    // プライバシーポリシーのサイトを表示
    window.open(`/${this.municipalityWebApiServ.setting.municipal_id}/assets/pdf/privacy-policy.pdf`,'_blank');
  }

  /**
   * ファミリー説明画面へ遷移
   *
   * @memberof TermAgreeComponent
   */
  viewFamilyDescription(): void {
    this.navigator.element.pushPage(FamilyDescriptionComponent);
  }

  /**
   * 見守り対象アカウント説明画面へ遷移
   *
   * @memberof TermAgreeComponent
   */
  viewProxyDescription(): void {
    this.navigator.element.pushPage(ProxyAccountDescriptionComponent);
  }

  /**
   * 規約に合意するボタンclick
   *
   * @memberof TermAgreeComponent
   */
  onClickAgreeButton(): void {
    this.agreeTerm();
  }

  /**
   * チェックボックス変更
   * 観光目的かどうか
   *
   * @memberof TermAgreeComponent
   */
  /**
   * チェックボックス変更
   * 観光目的かどうか
   * 
   * @param {boolean} isTourist
   * @memberof TermAgreeComponent
   */
  onChangeIsTourist(isTourist: boolean): void {

    // 観光ユーザーを選択
    if (isTourist == true) {
      this.isTourist = true;
      this.isCitizen = false;
    }
    // 住民ユーザーを選択
    else {
      this.isTourist = false;
      this.isCitizen = true;
    }
  }

  //=============================================================================================
  // サーバ通信
  //=============================================================================================

  /**
   * 最新規約を取得する
   *
   * @private
   * @memberof TermAgreeComponent
   */
  private getTerm(): void {
    this.busy = this.municipalityWebApiServ.getTerm().subscribe({
      next: res => {this.term = res.body},
      error: this.errResServ.doParse((_err, errContent) => this.errResServ.viewErrDialog(errContent))
    })
  }

  /**
   * 規約に合意する
   *
   * @private
   * @memberof TermAgreeComponent
   */
  private agreeTerm(): void {
    this.busy = this.municipalityWebApiServ.postTermAgreement({term_id: this.term.term_id, is_citizen: this.isCitizen}).subscribe({
      next: () => {
        // メール遷移の場合、リロードを行う
        if (this.isMail) location.reload();
        else {
          // 規約合意後のユーザー情報取得
          this.userWebApiServ.getLatestUser().subscribe({
            next: () => {
              // 規約合意画面をpop
              this.navigator.element.popPage();
            }
          });
        }
      },
      error: this.errResServ.doParse((_err, errContent) => this.errResServ.viewErrDialog(errContent))      
    })
  }
}
