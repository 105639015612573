import { Injectable, InjectionToken } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { Observable, Subscriber } from 'rxjs';

/**
 * @deprecated
 */
export const GEOLOCATION_DEFAULT_POSITION = new InjectionToken<{
  lat: number;
  lng: number;
}>('geolocationDefaultPosition');

/**
 * @deprecated
 */
export const GEOLOCATION_DEFAULT_MAX_DISTANCE = new InjectionToken<number>('geolocationDefaultMaxDistance');

/**
 * @deprecated
 */
@Injectable({
  providedIn: 'root'
})
export class GeolocationService {
  constructor() { }

  getCurrentPosition(options?: {
    positionOptions?: PositionOptions;
    timerOptions?: {
      delay?: number;
    };
  }): Observable<{
    position?: Position;
    positionError?: PositionError;
  }> {
    return new Observable(subscriber => {
      if (!navigator.geolocation) {
        subscriber.next({});
        subscriber.complete();
      } else {
        const getCurrentPositionInternal = () => navigator.geolocation.getCurrentPosition(
          position => {
            if (!subscriber.closed) {
              subscriber.next({
                position: position
              });
              subscriber.complete();
            }
          },
          positionError => {
            if (!subscriber.closed) {
              subscriber.next({
                positionError: positionError
              });
              subscriber.complete();
            }
          },
          options?.positionOptions
        );

        const intervalId = setInterval(
          () => {
            if (subscriber.closed) {
              clearInterval(intervalId);
            } else {
              getCurrentPositionInternal();
            }
          },
          options?.timerOptions?.delay || 1000
        );

        getCurrentPositionInternal();
      }
    });
  }

}