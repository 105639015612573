import { Component, OnInit } from '@angular/core';
import { AfterViewInit, OnsNavigator } from 'ngx-onsenui';
import * as CONST from '../../../constants/constant'
import { Subscription } from 'rxjs';

// service
import { UserWebApiService } from '../../../http-services/user-web-api.service';
import { PageKey, PagerService } from 'src/app/lib-services/pager.service';
import { HttpErrorResponseParserService } from 'src/app/lib-services/http-error-response-parser.service';

// component
import { ProfileEditComponent } from '../profile-edit/profile-edit.component';

// interface
import { UserInfo } from '../../../interfaces/response';

@Component({
  selector: 'ons-page[profile-list]',
  templateUrl: './profile-list.component.html',
  styleUrls: ['./profile-list.component.css']
})
export class ProfileListComponent implements OnInit, AfterViewInit {

  /**
   *
   *
   * @type {Subscription}
   * @memberof ProfileListComponent
   */
  busy: Subscription;
  
  /**
   * ユーザ情報
   *
   * @type {UserInfo}
   * @memberof ProfileListComponent
   */
  userProfile: UserInfo;

  /**
   * OnDestroy時に破棄するSubscriptionオブジェクト
   *
   * @memberof ProfileListComponent
   */
  subscription = new Subscription();

  constructor(
    private _navigator: OnsNavigator,
    private userWebApiService: UserWebApiService,
    private pagerServ: PagerService,
    private errResServ: HttpErrorResponseParserService,
  ) { }

  ngOnInit(): void {
    this.userProfile = this.userWebApiService.getUserInfo();
    // console.log(JSON.stringify(this.userProfile, null, ' '));
  }

  ngAfterViewInit(): void {
    // 戻り先のページ設定
    this.pagerServ.setReturnPage({key: PageKey.ProfileListComponent, index: this._navigator.element.pages.length - 1});
    
    // ページスタックが削除されて、この画面に戻ってきたことを監視
    const pagerSubject = this.pagerServ.subject.subscribe({
      next: (key: number) => {
        if (key == PageKey.ProfileListComponent) {
          // 最新のユーザ情報を取得
          this.busy = this.userWebApiService.getLatestUser().subscribe({
            next: res => this.userProfile = res.body,
            error: this.errResServ.doParse((_err, errContent) => this.errResServ.viewErrDialog(errContent))
          })
        }
      },
    });

    this.subscription.add(pagerSubject);
  }

  ngOnDestroy(): void {
    this.busy?.unsubscribe();
    this.subscription?.unsubscribe();
  }

  //=============================================================================================
  // イベントハンドラ
  //=============================================================================================

  /**
   * プロフィール編集画面へ遷移。
   *
   * @param {('tel' | 'mail' | 'address')} item
   * @memberof ProfileListComponent
   */
  edit(item: 'tel' | 'mail' | 'address') {
    this._navigator.element.pushPage(ProfileEditComponent, {
      data: { user: this.userProfile, editKey: item }
    }, { animation: 'left' });
  }

  //=============================================================================================
  // メソッド
  //=============================================================================================

  /**
   * 性別(sex)の値を表示用文字列に変換し取得。
   *
   * @param {UserInfo} userInfo
   * @return {*} 
   * @memberof ProfileListComponent
   */
  getSexDisplay(userInfo: UserInfo) {
    switch (userInfo.profile.sex) {
      case ProfileListComponent.Sex.Default:
        return CONST.Common.SEX_NO_ANSWER;
      case ProfileListComponent.Sex.Male:
        return CONST.Common.SEX_MALE;
      case ProfileListComponent.Sex.Female:
        return CONST.Common.SEX_FEMALE;
      // TODO: Exeptionをthrowするかは検討
      default:
        return '';
    }
  }

  getIsTourist(userInfo: UserInfo) {
    return userInfo.user_type === "tourist" ? CONST.Common.YES : CONST.Common.NO;
  }
}

export namespace ProfileListComponent {
  export enum Sex {
    Default,
    Male,
    Female
  }
}
