<ons-toolbar>
  <div class="left">
    <ons-back-button></ons-back-button>
  </div>
  <div class="center">まちニュース</div>
</ons-toolbar>

<div [ngBusy]="busy"></div>
<div class="content" style="overflow: hidden">
  <!-- 無限スクロール領域 -->
  <div
      class="search-results"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="50"
      (scrolled)="onScroll()"
      [scrollWindow]="false"
  >

    <!-- 検索フォーム -->
    <div class="search-area">
      <div class="search-form">
        <mat-icon>search</mat-icon>
        <input matInput #autoCompleteInput id="newsHistoryInput" type="text" placeholder="スペース区切りで複数検索"
              [(ngModel)]="freeword"
              (change)="onSearch()"
              (click)="onSearchFocus()"
              (blur)="onSearchBlur()"
              [matAutocomplete]="auto">

        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSearchHistorySelect($event)">
          <div class="history__right">
            <button class="history__delete" mat-flat-button (click)="onDeleteSearchHistory($event, -1, '', true)">
              すべて削除
            </button>
          </div>
          <mat-option *ngFor="let history of searchHistory; index as i;" [value]="history">
            <div class="history__left">
              <mat-icon class="search-icon">search</mat-icon>{{ history }}
            </div>
            <div class="history__right">
              <button mat-icon-button (click)="onDeleteSearchHistory($event, i, history)">
                <mat-icon class="search-icon">clear</mat-icon>
              </button>
            </div>
          </mat-option>
        </mat-autocomplete>
      </div>
    </div>

    <!-- 記事 -->
    <div *ngFor="let article of articleList; index as num">
      <mat-card class="card-with-label">
        <span *ngIf="article.unread" class="card-label">New</span> <!-- 新着ラベルの条件付き表示 -->
        <mat-card-title>
          <!-- 店舗名 -->
          <div class="text-overflow" style="max-width: 100%;">
            <a href="#" (click)="onSearchKeyword($event, article.shop_id, 'shop', article.shop_name)"  class="shopname"><span>{{ article.shop_name }}</span></a>
          </div>
        </mat-card-title>

        <mat-card-content>
          <!-- 本文 -->
          <div class="grad-wrap">
            <input id="{{num}}" class="grad-trigger" type="checkbox">
            <label class="grad-btn" for="{{num}}" (click)="onReadArticle(article)">続きを読む</label>
            <div class="grad-item">
                <span [innerHTML]="article.text"></span>
            </div>
            <span class="br"></span>
          </div>

          <!-- 画像 -->
          <ng-container *ngIf="article.photos?.length > 0">
            <span class="br"></span>
            <div class="center" id="{{ article.article_id }}">
              <ng-container *ngFor="let photo of article.photos; index as i;">
                <!-- 2枚目までは通常表示 -->
                <img mat-card-md-image *ngIf="i < 2" [src]="photo" (click)="onEnlargeImage(article)">

                <!-- 4枚以上ある場合、3枚目をマスクして表示 -->
                <div class="image--message" *ngIf="article.photos.length >= 4; else allView">
                  <ng-container *ngIf="i === 2">
                    <img mat-card-md-image id="{{ 'maskImage' + article.article_id }}" class="image--mask" [src]="photo" (click)="onEnlargeImage(article)">
                    <button mat-mini-fab color="primary" (click)="onHiddenImageNum(article)"><p>+{{ article.photos.length - 2 }}</p></button>
                  </ng-container>
                  <!-- 4枚目以降は非表示 -->
                  <ng-container *ngIf="i > 2">
                    <img mat-card-md-image [src]="photo" hidden>
                  </ng-container>
                </div>

                <!-- 3枚の場合 -->
                <ng-template #allView>
                  <img mat-card-md-image *ngIf="i === 2" [src]="photo" (click)="onEnlargeImage(article)">
                </ng-template>
              </ng-container>
            </div>
          </ng-container>
        </mat-card-content>
        <mat-card-actions>
          <div *ngIf="article.created_date" class="text-overflow" style="max-width: 100%;">
            <!-- 投稿日時 -->
            <span>{{ article.created_date | chatDate: "DATETIME" }}</span>
          </div>
        </mat-card-actions>
      </mat-card>
      <br>
    </div>
    <ng-container *ngIf="articleList === undefined">
      <div class="text--center" *ngIf="freeword !== ''; else noArticle">
        <p>検索条件にヒットするニュースはありませんでした。</p>
        <parts-image class="complete-wrap__img" url="/image/character/notfound.png"></parts-image>
      </div>
    </ng-container>
  </div>

  <!-- template -->
  <!-- 記事がない -->
  <ng-template #noArticle>
    <div class="text--center" *ngIf="articleList === undefined">
      <p>現在、配信されたニュースはありません。<br>配信までしばらくお待ちください。</p>
      <parts-image class="complete-wrap__img" url="/image/character/out-of-service.png"></parts-image>
    </div>
  </ng-template>
</div>
