import { NgModule, ModuleWithProviders } from '@angular/core';

import { MunicipalityWebApiServiceConfig } from '../http-services/municipality-web-api.service';

@NgModule()
export class MunicipalityWebApiModule {
  static withConfig(config: MunicipalityWebApiServiceConfig): ModuleWithProviders<MunicipalityWebApiModule> {
    return {
      ngModule: MunicipalityWebApiModule,
      providers: [
        { provide: MunicipalityWebApiServiceConfig, useValue: config }
      ]
    };
  }
}
