<ons-toolbar class="toolbar">
  <div class="center">利用規約への同意</div>
</ons-toolbar>

<div class="content">
  <div [ngBusy]="busy"></div>

  <div class="content--description">
    <p class="content--description-text" *ngIf="setting">
      {{setting.name}}向けサービスを利用するためには、自治体向け情報の設定と、{{setting.name}}における利用規約に同意いただく必要があります。
    </p>
  </div>

  <div class="list-item__title" style="padding-left: 10px; padding-bottom: 5px;">自治体向け情報</div>
  <ons-list class="profile-list">
    <ons-list-header modifier="color" class="list-item__title">観光目的の利用</ons-list-header>
    <ons-list-item modifier="longdivider" class="list-item__text">
      <span>
        <ons-checkbox input-id="user_tourist" name="user_tourist" [(ngModel)]="isTourist" (change)="onChangeIsTourist(true)"></ons-checkbox>
        <label for="user_tourist">はい</label>
      </span>
      <span style="padding-left: 10px;">
        <ons-checkbox input-id="user_citizen" name="user_citizen" [(ngModel)]="isCitizen" (change)="onChangeIsTourist(false)"></ons-checkbox>
        <label for="user_citizen">いいえ</label>
      </span>
    </ons-list-item>
  </ons-list>
  <div class="content--text--check-wrap" *ngIf="setting">
    <ons-checkbox [(ngModel)]="checkedTerms" class="left"></ons-checkbox>
    <p class="content--text--small">{{setting.name}}におけるアプリケーションおよびサービスの
      <ons-button modifier="quiet" (click)="viewTerm()">利用規約</ons-button> と <ons-button modifier="quiet"
        (click)="viewPrivacyPolicy()">プライバシーポリシー</ons-button> に同意する
    </p>
  </div>
  <br>
  <ons-list class="profile-list">
    <ons-list-header modifier="color" class="list-item__title" style="text-transform: none">
      SmartGOTOに登録されている個人情報の本地域の運営者への提供
    </ons-list-header>
    <ons-list-item class="list-item_text">
      <div class="content-text-wrap">
        <p class="content-text-wrap__padding-top">
          他地域のSmartGOTO利用時に登録された以下の個人情報は、本地域の運営者へ提供されます。
        </p>
        <p class="content-text-wrap__padding-top">
          ・プロフィールデータ（氏名、生年月日、電話番号、メールアドレス、等）
        </p>
        <p>・ファミリー管理者とファミリーメンバーの紐づけ情報</p>
        <p>・ファミリー機能で登録された見守り対象アカウントに関するデータ（氏名、生年月日、電話番号、メールアドレス、等）</p>
        <p class="content-text-wrap__padding-top">
          ※見守り対象アカウントを登録されている場合は、対象者に本地域に登録される旨を事前に説明し、同意を得たうえでご登録下さい。
        </p>
        <p class="content-text-wrap__padding-top">
          本地域の運営者は、提供される情報を利用規約に記載している利用目的以外で利用することはありません。
        </p>
        <p>（ファミリー機能の説明は<ons-button modifier="quiet" (click)="viewFamilyDescription()">こちら</ons-button>。
          見守り対象アカウントの説明は<ons-button modifier="quiet" (click)="viewProxyDescription()">こちら</ons-button>。）</p>
      </div>
    </ons-list-item>
  </ons-list>
  <div class="content--text--check-wrap">
    <ons-checkbox [(ngModel)]="checkedFamily" class="left"></ons-checkbox>
    <p class="content--text--small">上記を理解したうえで本地域の運営者に情報を提供することに同意します。</p>
  </div>

  <div class="button-wrap">
    <ons-button modifier="large--cta noFixed" (click)="onClickAgreeButton()"
      [disabled]="!checkedTerms || !checkedFamily">上記の内容で登録</ons-button>
  </div>
  
</div>

