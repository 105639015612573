//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit, OnDestroy } from '@angular/core';
import { OnsNavigator, Params } from 'ngx-onsenui';
import { Subscription } from 'rxjs';

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { FamilyWebApiService } from '../../../http-services/family-web-api.service';

// interface
import { request } from '../../../interfaces/request';
import { Relation } from '../../../interfaces/response';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 利用制限画面。
 *
 * @export
 * @class FamilyLimitComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @author （株）オブジェクトデータ takimoto wada
 */
@Component({
  selector: 'ons-page[family-limit]',
  templateUrl: './family-limit.component.html',
  styleUrls: ['./family-limit.component.css']
})
export class FamilyLimitComponent implements OnInit, OnDestroy 
{

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   *ユーザー情報
  *
  * @type {Relation}
  * @memberof FamilyLimitComponent
  */
  m_Relation: Relation;

  /**
   * サーバ通信Subscription
   *
   * @type {Subscription}
   * @memberof FamilyLimitComponent
   */
  m_Busy: Subscription;

  /**
   * ボタン非活性状態
   *
   * @type {boolean}
   * @memberof FamilyLimitComponent
   */
  m_bDisabled: boolean;

  /**
   * サービス利用制限の設定状況
   *
   * @type {boolean}
   * @memberof FamilyLimitComponent
   */
  m_bUtilization: boolean;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of FamilyLimitComponent.
   * @param {OnsNavigator} navigator
   * @param {ApplicationMessageService} appMsg
   * @param {HttpErrorResponseParserService} httpErrorResponseParserService
   * @param {FamilyWebApiService} familyWebApiService
   * @param {Params} params
   * @memberof FamilyLimitComponent
   */
  constructor(
    private navigator: OnsNavigator, 
    private appMsg: ApplicationMessageService, 
    private httpErrorResponseParserService: HttpErrorResponseParserService, 
    private familyWebApiService :FamilyWebApiService, 
    private params: Params,
    private msg: MESSAGE,
  ) { }

  /**
   * 初期化処理。
   *
   * @memberof FamilyLimitComponent
   */
  ngOnInit(): void {
    this.m_Relation = this.params.data.relation;//ユーザー情報を取得

    this.m_bUtilization = this.m_Relation.limits?.utilization ? this.m_Relation.limits.utilization : false;
    this.m_bDisabled = true;
  }

  /**
   * 破棄処理。
   *
   * @memberof FamilyLimitComponent
   */
  ngOnDestroy(): void {
    this.m_Busy?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * サービス利用制限のON/OFFを切り替える。
   *
   * @memberof FamilyLimitComponent
   */
  changeUtilization(): void {
    if (this.m_Relation.limits?.utilization === this.m_bUtilization) this.m_bDisabled = true;
    else this.m_bDisabled = false;
  }

  /**
   * メンバーに対する制限を設定する。
   *
   * @memberof FamilyLimitComponent
   */
  saveUtilization() { //putで制限情報を更新
    // request body作成
    const reqBody: request.familyInfoUpdate = {
      relation_id: this.m_Relation.relation_id, 
      limits: this.m_Relation.limits = { utilization: this.m_bUtilization }
    };

    // サービス利用制限を設定/解除
    this.m_Busy = this.familyWebApiService.familyInfoUpdate(reqBody).subscribe({
      next:() => {
        let message = this.msg.CLIENT.FAMILY.LIMIT_RELEASE.message();
        if (this.m_bUtilization) message = this.msg.CLIENT.FAMILY.LIMIT_SET.message();

        // 制限操作後、前ページへ戻る
        this.appMsg.viewDialogMessage(message, ()=> {
          this.params.data.class.getFamily();
          this.navigator.element.popPage();
        });
      },
      error: this.httpErrorResponseParserService.doParse((_error, customErrorContent) => {
        this.appMsg.viewDialogErrorMessage(this.msg.CLIENT.FAMILY.E_SET_LIMTS.message(), customErrorContent.smartGotoErrMessage)
      })
    })
  } 
}
