//=============================================================================================
// インポート
//=============================================================================================
import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { Subscription, fromEvent, interval } from 'rxjs';
import * as moment from 'moment';
import * as CONST from '../../../constants/constant';
import * as external from './operation-map';

import { ApplicationMessageService } from '../../../lib-services/application-message.service'
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { OperationWebApiService } from '../../../http-services/operation-web-api.service';
import { SpotGeolocationService } from '../../../http-services/spot-geolocation.service';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';

import { CarInfo } from '../../../interfaces/response';

import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 運行状況確認画面
 *
 * @export
 * @class OperationMapComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @author （株）オブジェクトデータ 和田
 */
@Component({
  selector: 'ons-page[operation-map]',
  templateUrl: './operation-map.component.html',
  styleUrls: ['./operation-map.component.css']
})

export class OperationMapComponent implements OnInit, OnDestroy 
{

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * GoogleMapコンポーネント参照変数
   *
   * @private
   * @type {GoogleMap}
   * @memberof OperationMapComponent
   */
  @ViewChild(GoogleMap) private googleMap: GoogleMap;

  /**
   * 運行状況確認更新日時
   *
   * @type {string}
   * @memberof OperationMapComponent
   */
  updateTimestamp: string;

  /**
   * 地図のズーム値
   *
   * @type {number}
   * @memberof OperationMapComponent
   */
  zoom: number = 11;
    
  /**
   * 情報ウィンドウ
   *
   * @type {google.maps.InfoWindow}
   * @memberof OperationMapComponent
   */
  infoWindow: google.maps.InfoWindow;

  /**
   * タイマー稼働状態
   *    値あり　：タイマー起動中
   *    undefine：タイマー停止中
   *
   * @type {Subscription}
   * @memberof OperationMapComponent
   */
  timerStatus: Subscription;

  /**
   * 運行中の車両情報のSubscriptionオブジェクト
   *
   * @type {Subscription}
   * @memberof OperationMapComponent
   */
  m_Busy: Subscription;

  /**
   * エリアの代表地点
   *
   * @type {google.maps.LatLngLiteral}
   * @memberof OperationMapComponent
   */
  defaultPosition: google.maps.LatLngLiteral;

  /**
   * 描画済みの車両マーカー
   */
  private car_markers: google.maps.marker.AdvancedMarkerElement[] = [];

  /**
   * OnDestroy時に破棄するSubscriptionオブジェクト
   *
   * @private
   * @memberof OperationMapComponent
   */
  private subscription = new Subscription();

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of OperationMapComponent.
   * @param {google.maps.Icon} currentPositionMarkerIcon 現在位置アイコン
   * @param {google.maps.LatLngLiteral} defaultPosition 地図の表示位置（初期値は新上五島町）
   * @param {google.maps.MapOptions} mapOptions 地図の表示オプション
   * @param {google.maps.Icon} vehiclePositionMarkerIcon 車両マーカアイコン
   * @param {ApplicationMessageService} appMsg アプリケーションメッセージサービス
   * @param {GeolocationService} geolocationService 現在位置サービス
   * @param {OperationWebApiService} operationWebApiService 運行状況のサーバAPIサービス
   * @memberof OperationMapComponent
   */
  constructor(
    @Inject(external.OPERATION_MAP_CURRENT_POSITION_MARKER_ICON) private currentPositionMarkerIcon: google.maps.Icon,

    @Inject(external.OPERATION_MAP_OPTIONS) public mapOptions: google.maps.MapOptions,
    @Inject(external.OPERATION_MAP_VEHICLE_POSITION_MARKER_ICON) private vehiclePositionMarkerIcon: google.maps.Icon,
    private appMsg: ApplicationMessageService, 
    private httpErrorResponseParserService: HttpErrorResponseParserService,
    private spotGeolocationService: SpotGeolocationService,
    private operationWebApiService: OperationWebApiService,
    private municipalityWebApiServ: MunicipalityWebApiService,
    private msg: MESSAGE,
  ) 
  {
    const settingsChanged = this.municipalityWebApiServ.settingsChanged.subscribe({
      next: setting => {
        if (setting == null) return;
        // 代表地点
        this.defaultPosition = setting.map.representive_loc
      }
    });
    this.subscription.add(settingsChanged);
  }
  
  /**
   * 初期化処理。
   *
   * @memberof OperationMapComponent
   */
  ngOnInit(): void 
  {

    // 現在情報があるなら現在位置を設定
    this.m_Busy = this.spotGeolocationService.getCurrentPosition().subscribe( 
      {
        next: location => 
        {
          if (location.position != undefined) 
          {
            this.drawMarker(new google.maps.LatLng(location.position?.coords?.latitude, location.position?.coords?.longitude), this.currentPositionMarkerIcon);
          }
        }
      });

    // 運行状況タブが選択されたイベントを補足
    fromEvent(document.querySelector('ons-tabbar ons-tab[data-tab="' + CONST.Common.TAB_INFO.OPERATION + '"] button'), 'click')
      .subscribe(() => 
      {
        // タイマー起動中の場合（運行状況タブを開いた状態で選択されたため）は処理を抜ける
        if (this.timerStatus != undefined) return;

        // 車両マーカの表示を登録
        this.updateVehicleInfo();

        // // 1分周期で車両マーカを更新
        const inter = interval(CONST.Operation.UPDATE_CYCLE_TIME);
        this.timerStatus = inter.subscribe(() => this.updateVehicleInfo());
      });
      
    // 運行状況タブ以外を選択した場合、タイマーを消去
    let tabName: string[] = [CONST.Common.TAB_INFO.SERVICE, CONST.Common.TAB_INFO.HISTORY, CONST.Common.TAB_INFO.MYQR, CONST.Common.TAB_INFO.ACCOUNT];
    for (let count = 0; count < tabName.length; count++) 
    {
      fromEvent(document.querySelector('ons-tabbar ons-tab[data-tab="' + tabName[count] + '"] button'), 'click')
        .subscribe(() => 
        {
          if (this.timerStatus != undefined) 
          {
            this.timerStatus.unsubscribe();
            this.timerStatus = undefined;
          }
        });
    }
  }

  /**
   * 破棄処理。
   *
   * @memberof OperationMapComponent
   */
  ngOnDestroy() 
  {
    // タイマー破棄
    if (this.timerStatus != undefined) this.timerStatus.unsubscribe();

    // subscribe停止
    this.m_Busy?.unsubscribe();

    this.subscription?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   *  車両マーカタップ時に情報ウィンドウを表示する。
   *
   * @param {OperationWebApiSchemas.ResponseSchemas.CarInfo} carInfo マーカの車両情報
   * @memberof OperationMapComponent
   */
  selectVehicle(carInfo: CarInfo): void 
  {
    let text = '<p>車両No：' + carInfo.car_no + '<br>';
    if (carInfo?.max_ride_people != undefined) text += '最大乗車人数：' + carInfo.max_ride_people + '名<br>';
    if (carInfo?.current_ride_people != undefined) text += '現在乗車人数：' + carInfo.current_ride_people + '名<br>';
    if (carInfo?.image !== undefined) {
      text += '<img src="' + carInfo.image + '" width="70%" heigth="70%"/></p>';
    }
    
    // 情報ウィンドウが開かれているならクローズ
    if (this.infoWindow != null && this.infoWindow != undefined) 
    {
      this.infoWindow.close();
      this.infoWindow = undefined;
    }

    // 情報ウィンドウを設定
    this.infoWindow = new google.maps.InfoWindow( 
      {
        content: text,
        position: carInfo.status?.gps?.location
      });

    // open
    this.infoWindow.open(this.googleMap.googleMap);
  }

  /**
   * 情報ウィンドウ表示時、地図タップでウィンドウを消去する。
   *
   * @memberof OperationMapComponent
   */
  mapTap(): void 
  {
    // 情報ウィンドウが開かれているならクローズ
    if (this.infoWindow != null && this.infoWindow != undefined) 
    {
      this.infoWindow.close();
      this.infoWindow = undefined;
    }
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * 地図上にSmartGOTOサーバから取得した車両マーカを表示する。
   *
   * @memberof OperationMapComponent
   */
  updateVehicleInfo(): void 
  {
    // 情報ウィンドウが開かれているならクローズ
    if (this.infoWindow != null && this.infoWindow != undefined) 
    {
      this.infoWindow.close();
      this.infoWindow = undefined;
    }

    // 本処理はタブから運行状況画面が開かれた際に実施し、その後周期で実施される。
    // SmartGOTOサーバから、運行中の車両情報一覧を取得
    this.m_Busy = this.operationWebApiService.getOperationCarsInfo<CarInfo>().subscribe({
      next: response => 
      {
        // 描画済みのものをクリア
        for (let marker of this.car_markers) {
          marker.map = null;
          marker.remove();          
        }
        this.car_markers = [];

        // GPS座標があるもののみ
        const car_infos = response.body.filter((car_info:CarInfo) => car_info.status?.gps?.location?.lat);
        for (let car_info of car_infos) {
          this.car_markers.push(this.drawCarMarker(car_info));
        }
      },
      error: this.httpErrorResponseParserService.doParse((_error, customErrorContent) => 
      {
        this.appMsg.viewDialogMessage(this.msg.CLIENT.OPERATION.E_GET_CARS.message());
      })
    });

    // 更新日時作成
    this.updateTimestamp = moment().format("YYYY年M月D日 H時m分");
  }

  /**
   * 車両マーカー描画
   * @param car_info 
   */
  private drawCarMarker(car_info:CarInfo): google.maps.marker.AdvancedMarkerElement {
    const marker = this.drawMarker(new google.maps.LatLng(car_info.status?.gps?.location?.lat, car_info.status?.gps?.location?.lng), this.vehiclePositionMarkerIcon);
   
    marker.addListener('click', (event: google.maps.MapMouseEvent) =>  {
      this.selectVehicle(car_info);
    });

    return marker;
  }

  /**
   * マーカー描画
   * @param car_info 
   */
  private drawMarker(position: google.maps.LatLng, icon: google.maps.Icon): google.maps.marker.AdvancedMarkerElement {

    const options: google.maps.marker.AdvancedMarkerElementOptions = {
      map: this.googleMap.googleMap,      
      position: position,
      content: CommonFunctionModule.convertIconToContent(icon),
    }
    
    const marker = new google.maps.marker.AdvancedMarkerElement(options);

    return marker;
  }

}
