//=============================================================================================
// インポート
//=============================================================================================
import { Component, ViewChild, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { Observable, Subscription, Subject, fromEvent } from 'rxjs';
import { OnsTabbar } from 'ngx-onsenui';
import * as CONST from "../../constants/constant";
import { municipalities } from 'src/municipality';
import { environment } from 'src/environments/environment';

// service
import { UserWebApiService } from '../../http-services/user-web-api.service';
import { SqrcWebApiService } from '../../http-services/sqrc-web-api.service';
import { FamilyWebApiService } from '../../http-services/family-web-api.service';
import { SelectHistoryWebApiService } from '../../http-services/select-history-web-api.service';
import { PagerService, PageKey } from '../../lib-services/pager.service';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';

// component
import { ServiceComponent } from '../service/service.component';
import { HistoryComponent } from '../history/history.component';
import { OperationComponent } from '../operation/operation.component';
import { MyqrComponent } from '../myqr/myqr.component';
import { AccountComponent } from '../../components/account/account.component';

// interface
import { UserInfo, Relation } from '../../interfaces/response';

// module
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';

//=============================================================================================
// クラス定義
//=============================================================================================

@Component({
  selector: 'ons-page[tabbar]',
  templateUrl: './tabbar.component.html',
  styleUrls: ['./tabbar.component.css']
})

export class TabbarComponent implements OnInit, OnDestroy {

//=============================================================================================
// プロパティ定義
//=============================================================================================

  busy: Subscription;
  private onUserInfoChanged: Subscription;
  private onSqrcChanged: Subscription;
  private onFamilyInfoChanged: Subscription;

  tab1 = ServiceComponent;
  tab2 = HistoryComponent;
  tab3 = MyqrComponent;
  tab4 = OperationComponent;
  tab5 = AccountComponent;

  tabComponent = 
  {
    reservation: 0,
    history: 1,
    myqr: 2,
    operation: 3,
    account: 4
  };

  imgSrc: string;

  isLoggedInUser: boolean=true;
  user?: UserInfo;

  /**
   * 利用するタブクリック時、操作履歴送信確認用Subscription
   *
   * @type {Subscription}
   * @memberof TabbarComponent
   */
  serviceTabClickSubscription: Subscription;

  @ViewChild('tabbar') tabbar: ElementRef;
  @ViewChild('historyTab') historyTab: ElementRef;
  @ViewChild('myqrTab') myqrTab: ElementRef;
  @ViewChild('accountTab') accountTab: ElementRef;
  @ViewChild('serviceTab') serviceTab: ElementRef;
  @ViewChild(OnsTabbar) private onsTabbar;

  /**
   * OnDestroy時に破棄するSubscriptionオブジェクト
   *
   * @memberof TabbarComponent
   */
  subscription = new Subscription();

  /**
   * 運行情報タブを表示するかどうか
   *
   * @type {boolean}
   * @memberof TabbarComponent
   */
  isDisplayOperationTab: boolean = true;

  /**
   * assetsファイルへのパス(定数)
   *
   * @memberof TabbarComponent
   */
  readonly ASSETS = {
    ACCOUNT_INACTIVE: this.commonFunctionMdl.getAssetsCssUrl(this.getAccountTabIconURL('/tabbar/03-Account_Inactive.png')),
    ACCOUNT_ACTIVE: this.commonFunctionMdl.getAssetsCssUrl(this.getAccountTabIconURL('/tabbar/03-Account_Active.png')),
  } as const;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of TabbarComponent.
   * @param {SqrcWebApiService} sqrcWebApiService
   * @param {UserWebApiService} userServ
   * @param {FamilyWebApiService} familyServ
   * @param {PagerService} pagerServ
   * @param {SelectHistoryWebApiService} selectHistoryServ
   * @param {CommonFunctionModule} commonFunctionMdl
   * @memberof TabbarComponent
   */
  constructor(
    private userServ: UserWebApiService,
    private familyServ: FamilyWebApiService,
    private pagerServ: PagerService,
    private selectHistoryServ: SelectHistoryWebApiService,
    private commonFunctionMdl: CommonFunctionModule,
    private municipalityWebApiServ: MunicipalityWebApiService,
  ) {
    const settingsChanged = this.municipalityWebApiServ.settingsChanged.subscribe({
      next: setting => {
        if (setting == null) return;
        // お出かけ機能がない場合、運行情報タグ非表示
        this.isDisplayOperationTab = setting.services.includes(CONST.Common.SERVICE_TAG.RESERVATION);
      }
    });
    this.subscription.add(settingsChanged);
  }

  /**
   * 初期処理。
   *
   * @memberof TabbarComponent
   */
  ngOnInit(): void {
    
    //以下マイページアイコンorゲストアイコン表示判断必要用
    // this.userServ.autoLogin().subscribe({
    //   next: () => {
    //     console.log("isLoggedInAuto()", this.userServ.isLoggedIn());
    //     this.isLoggedInUser=true;
    //     const user=this.userServ.getUserInfo();
    //     this.imgSrc=user.profile.icon==''? "../assets/icon/tabbar/03-Account_Active.png":user.profile.icon;
    //   },
    //   error: (error: HttpErrorResponse) => {
    //     this.isLoggedInUser=false;
    //   }
    // });
  }

  /**
   * afterViewInit
   *
   * @memberof TabbarComponent
   */
  ngAfterViewInit(): void {

    const accountObservable = (userInfo: UserInfo, familyRelations:Relation[]) => {
      return new Observable<number>(subscriber => {
        var num = 0;
        if (userInfo) {
          if ((userInfo.notify?.method ?? '') === '') num += 1;
          if (userInfo.notify?.method === 'line' && userInfo.notify?.line === null) num += 1;
          if ((userInfo.payment?.method ?? 'NONE') === 'NONE') num += 1;
        }

        if (familyRelations) {
          for (let relation of familyRelations) {
            if (relation.status == 'request') num += 1;
          }
        }
        subscriber.next(num);
        subscriber.complete();
      })
    }

    // const myqrObservable = (userInfo: UserInfo) => {
    //   return new Observable<number>(subscriber => {
    //     if (this.userServ.isLoggedIn()){
    //       this.sqrcWebApiService.getCode().subscribe({
    //         next: response => {
    //           // QRが登録されている
    //           // QRの文字列がない場合、バッジ有り
    //           subscriber.next(response === null ? 1 : 0);//TODO:判別の必要ある？？
    //         },
    //         error: () => {
    //           // ログインしていて、QRが登録されていない場合、バッジ有り
    //           subscriber.next(1);
    //         },
    //         complete: () => {
    //           subscriber.complete();
    //         }
    //     });}
    //     else {//ログインされていない場合
    //       subscriber.next(0);
    //       subscriber.complete()
    //     }
    //   });
    // };

    // BehaviorSubject
    this.onUserInfoChanged = this.userServ.userInfoChanged.subscribe({
      next: userInfo => {
        accountObservable(userInfo, this.familyServ.getFamilyInfo()).subscribe({
          next: badge => this.accountBadgeSet(badge)
        });
        // myqrObservable(userInfo).subscribe({
        //   next: badge => this.myqrBadgeSet(badge)
        // });
      }
    });

    // Subject
    // this.onSqrcChanged = this.sqrcWebApiService.sqrcChanged.subscribe({
    //   next: () => {
    //     myqrObservable(this.userServ.getUserInfo()).subscribe({
    //       next: badge => this.myqrBadgeSet(badge)
    //     })
    //   }
    // });

    // BehaviorSubject
    this.onFamilyInfoChanged = this.familyServ.familyInfoChenged.subscribe({
      next: familyRelations => {
        accountObservable(this.userServ.getUserInfo(), familyRelations).subscribe({
          next: badge => this.accountBadgeSet(badge)
        });
      }
    });

    // タブクリックで新規情報読み込み
    this.serviceTabClickSubscription = fromEvent(this.serviceTab.nativeElement, 'click').subscribe(() => {

      // const page = this.pagerServ.returnPageInfo;
      const index = this.pagerServ.getReturnPage.find(e => e.key == PageKey.ExpDetailComponent);
      if (index !== undefined) {
        // タブバー非表示
        this.setTabbarVisibility(false);
      }
      // サービスにある操作履歴送信用データの現在時刻と一番古い履歴データを比較し、一定期間経っていたら送信
      this.selectHistoryServ.postStrage(false, false);
    });
  }
  
  /**
   * 破棄処理。
   *
   * @memberof TabbarComponent
   */
   ngOnDestroy() {
    this.onUserInfoChanged?.unsubscribe();
    this.onSqrcChanged?.unsubscribe();
    this.onFamilyInfoChanged?.unsubscribe();
    this.serviceTabClickSubscription?.unsubscribe();
   }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * QRコードタブをタップした時と同様の処理を行う。
   *
   * @memberof TabbarComponent
   */
  myqrTabClick() {

    this.onsTabbar._elementRef.nativeElement.setActiveTab(this.tabComponent.myqr);
  }

  /**
   * マイページタブをタップした時と同様の処理を行う。
   *
   * @memberof TabbarComponent
   */
  accountTabClick() {

    this.onsTabbar._elementRef.nativeElement.setActiveTab(this.tabComponent.account);
  }

  /**
   * 予定・履歴タブをタップした時と同様の処理を行う。
   *
   * @memberof TabbarComponent
   */
  transitionHistoryListTab() {

    this.onsTabbar._elementRef.nativeElement.setActiveTab(this.tabComponent.history);
  }

  /**
   * 予定・履歴タブをタップ。
   *
   * @memberof TabbarComponent
   */
  clickHistoryListTab() {
    
    this.historyTab.nativeElement.click();
  }

  /**
   * QRコードタブにバッジを表示する。
   *
   * @param {number} badge
   * @memberof TabbarComponent
   */
  // myqrBadgeSet(badge: number): void {

  //   this.myqrTab.nativeElement.setAttribute("badge", badge == 0 ? "" : badge);
  // }

  /**
   * マイページタブにバッジを表示する。
   *
   * @param {number} badge
   * @memberof TabbarComponent
   */
  accountBadgeSet(badge: number): void {

    this.accountTab.nativeElement.setAttribute("badge", badge == 0 ? "" : badge);
  }

  /**
   * タブバーの表示/非表示を切り替える。
   *
   * @param {boolean} visible
   * @memberof TabbarComponent
   */
  setTabbarVisibility(visible: boolean): void {
    
    this.onsTabbar._elementRef.nativeElement.setTabbarVisibility(visible);
  }

  /**
   * 表示中のタブ番号を取得する。
   *
   * @return {*}  {number}
   * @memberof TabbarComponent
   */
  getActiveTab(): number {

    return this.onsTabbar._elementRef.nativeElement.getActiveTabIndex();
  }

  /**
   * マイページタブアイコン用URL取得
   *
   * @private
   * @param {string} fileName
   * @return {*}  {string}
   * @memberof TabbarComponent
   */
  private getAccountTabIconURL(fileName: string): string {
    return '../../../assets/' + municipalities[environment.areacode].municipal_id + fileName;
  }
}

/**
 * タブ番号
 */
export const TABBAR_TAB = {
  reservation: 0,
  history: 1,
  myqr: 2,
  operation: 3,
  account: 4
} as const;

/**
 * タブ番号、リテラル型
 */
type TabbarTabNum = typeof TABBAR_TAB[keyof typeof TABBAR_TAB];