<ons-toolbar class="toolbar">
  <div class="left">
    <ons-back-button></ons-back-button>
  </div>
  <div class="center">見守り対象アカウント作成</div>
</ons-toolbar>
<div class="content">
  <div [ngBusy]="m_Busy"></div>
  <div class="center segment-wrap">
    <div class="segment">
      <ul class="stepbar stepbar__three">
        <li class="stepbar__item">STEP1</li>
        <li class="stepbar__item">STEP2</li>
        <li class="stepbar__item stepbar__item--current">STEP3</li>
      </ul>
    </div>
    <div class="content--description">下記の内容でご家族の登録を行います。<br>よろしいですか？</div>
  </div>

  <form id="profileCreateForm" #formCheck="ngForm" class="formCheck">
    <ons-list class="profile-list">

      <ons-list-header modifier="color" class="list-item__title">氏名</ons-list-header>
      <ons-list-item modifier="longdivider" class="list-item__text">
        {{ m_ProxyUser.profile.name.family_name }}
      </ons-list-item>
      <ons-list-item modifier="longdivider" class="list-item__text">
        {{ m_ProxyUser.profile.name.given_name }}
      </ons-list-item>

      <ons-list-header modifier="color" class="list-item__title">氏名（かな）</ons-list-header>
      <ons-list-item modifier="longdivider" class="list-item__text">
        {{ m_ProxyUser.profile.name.family_name_kana }}
      </ons-list-item>
      <ons-list-item modifier="longdivider" class="list-item__text">
        {{ m_ProxyUser.profile.name.given_name_kana }}
      </ons-list-item>

      <ons-list-header modifier="color" class="list-item__title">生年月日</ons-list-header>
      <ons-list-item modifier="longdivider" class="list-item__text">
        {{ m_ProxyUser.profile.birth.year }}
        <div class="mark--delimiter">/</div>
        {{ m_ProxyUser.profile.birth.month }}
        <div class="mark--delimiter">/</div>
        {{ m_ProxyUser.profile.birth.day }}
      </ons-list-item>

      <ons-list-header modifier="color" class="list-item__title">性別</ons-list-header>
      <ons-list-item modifier="longdivider" class="list-item__text">
        {{ m_strSelectedSex }}
      </ons-list-item>

      <ons-list-header modifier="color" class="list-item__title">
        <div class="left">住所</div>
        <div class="right list-item__link">
          <a class="right list-item__link--google" target="_blank" [attr.href]="googleMapUrl">地図で位置を確認</a>
        </div>
      </ons-list-header>
      <ons-list-item modifier="longdivider" class="list-item__text">
        {{m_ProxyUser.profile.address.zip}}
        {{m_ProxyUser.profile.address.prefecture + m_ProxyUser.profile.address.city + m_ProxyUser.profile.address.address1 + m_ProxyUser.profile.address.address2 + m_ProxyUser.profile.address.address3}}
      </ons-list-item>

    </ons-list>

    <div class="content--text--check-wrap">
      <ons-checkbox id="proxy-account-signup-determine_004" [name]="terms" [attr.value]="terms[0]" [(ngModel)]="checkedTerms" class="left"></ons-checkbox>
      <p class="content--text--small">
        ご家族に代わり
        <ons-button id="proxy-account-signup-determine_001" modifier="quiet" (click)="viewUsePolicy()"><span class="button-text">利用規約</span></ons-button> と 
        <ons-button id="proxy-account-signup-determine_002" modifier="quiet" (click)="viewPrivacyPolicy()"><span class="button-text">プライバシーポリシー</span></ons-button> に同意する
      </p>
    </div>
    <div class="button-wrap">
      <ons-button id="proxy-account-signup-determine_003" modifier="large--cta noFixed" (click)="createProxyUser()"
        [disabled]="checkedTerms.length!==1">アカウント作成</ons-button>
    </div>
  </form>
</div>
