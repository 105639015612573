import { NgModule, ModuleWithProviders } from '@angular/core';
import { FamilyWebApiServiceConfig } from '../http-services/family-web-api.service';

@NgModule()
export class FamilyWebApiModule 
{
  static withConfig(config: FamilyWebApiServiceConfig): ModuleWithProviders<FamilyWebApiModule> {
    return {
      ngModule: FamilyWebApiModule,
      providers: [
        { provide: FamilyWebApiServiceConfig, useValue: config }
      ]
    };
  }
 }