<ons-toolbar class="toolbar">
    <div class="center">運行状況</div>
</ons-toolbar>
<div class="content">
    <div [ngBusy]="m_Busy"></div>
    <p class="operation--comment">位置情報の更新は1分毎に行われます。</p>
    <div class="operation--update">最終更新：{{ updateTimestamp }}</div>
    <google-map height="100%" width="100%" [zoom]="zoom" [center]="defaultPosition" [options]="mapOptions" 
        class="map-height--max" (mapClick)="mapTap();">
    </google-map>
</div>