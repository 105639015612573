//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit } from '@angular/core';
import { OnDestroy, OnsNavigator } from 'ngx-onsenui';
import * as moment from 'moment';

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';

// component
import { RecommendedEnvironmentComponent } from './recommended-environment/recommended-environment.component';
import { AllMunicipalitiesComponent } from '../../all-municipalities/all-municipalities.component';

// other
import { environment } from '../../../../environments/environment';
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';
import { Settings } from 'src/app/interfaces/response';
import { Subscription } from 'rxjs';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * ハンバーガーメニュー画面。
 *
 * @export
 * @class SplitterSideMenuComponent
 * @implements {OnInit}
 * @author （株）オブジェクトデータ wada
 */
@Component({
  selector: 'ons-page[splitter-side-menu]',
  templateUrl: './splitter-side-menu.component.html',
  styleUrls: ['./splitter-side-menu.component.css']
})
export class SplitterSideMenuComponent implements OnInit, OnDestroy
{

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * リリース日
   *
   * @type {string}
   * @memberof SplitterSideMenuComponent
   */
  releaseDate: string = "";

  /**
   * エリアごとの説明資料
   *
   * @type {Settings["guide"]}
   * @memberof SplitterSideMenuComponent
   */
  areaDocuments: Settings["guides"];

  /**
   * OnDestroy時に破棄するSubscriptionオブジェクト
   *
   * @memberof SplitterSideMenuComponent
   */
  subscription = new Subscription();

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of SplitterSideMenuComponent.
   * @param {OnsNavigator} _navigator
   * @param {ApplicationMessageService} appMsg
   * @memberof SplitterSideMenuComponent
   */
  constructor(
    private _navigator: OnsNavigator, 
    private appMsg: ApplicationMessageService,
    private municipalityWebApiServ: MunicipalityWebApiService,
  ) {
    const settingsChanged = this.municipalityWebApiServ.settingsChanged.subscribe({
      next: setting => {
        if (setting == null) return;
        this.areaDocuments = setting.guides;
      }
    });
    this.subscription.add(settingsChanged);
  }

  /**
   * 初期化処理。
   *
   * @memberof SplitterSideMenuComponent
   */
  ngOnInit(): void {
    // アプリリリース日付を取得
    this.releaseDate = moment(environment.releaseDate).format("YYYY年MM月DD日");
  }
    
  /**
   * 廃棄処理
   *
   * @memberof SplitterSideMenuComponent
   */
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 最新規約のPDFを表示。
   *
   * @memberof SplitterSideMenuComponent
   */
  pushTerms(): void {
    // 別タブで、最新規約のPDFを表示
    window.open(this.municipalityWebApiServ.setting.term, '_blank');
  }

  /**
   * プライバシーポリシーへ遷移する。
   *
   * @memberof SplitterSideMenuComponent
   */
  pushPrivacyPolicy(): void {
    // プライバシーポリシーのサイトを表示
    window.open(`/${this.municipalityWebApiServ.setting.municipal_id}/assets/pdf/privacy-policy.pdf`,'_blank');

    // TODO:確認メッセージ表示後にページ遷移させたいが、非同期処理となりポップアップブロックされてしまうため、直接遷移とする
  }

  /**
   * 利用自治体変更画面へ遷移
   *
   * @memberof SplitterSideMenuComponent
   */
  pushChangeMunicipality(): void {
    this._navigator.element.pushPage(AllMunicipalitiesComponent, {data: {isSplitter: true}});
  }

  /**
   * 推奨環境へ遷移する。
   *
   * @memberof SplitterSideMenuComponent
   */
  pushRecommendedEnvironment(): void {
    this._navigator.element.pushPage(RecommendedEnvironmentComponent);
  }

  /**
   * エリアごとの説明資料サイトへ遷移
   *
   * @param {string} pdf
   * @memberof SplitterSideMenuComponent
   */
  pushPDF(pdf: string) {
    window.open(pdf);
  }

  // /**
  //  * 買い物サービス説明資料サイトへ遷移
  //  *
  //  * @memberof SplitterSideMenuComponent
  //  */
  // pushShoppingPDF(): void {
  //   window.open(CommonFunctionModule.getAssetsUrl('/pdf/service_shopping.pdf', false));
  // }

}