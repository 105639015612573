<ons-toolbar>
  <div class="center">予約完了</div>
</ons-toolbar>

<div class="content complete-wrap">
  <p class="content--title complete-wrap__title">予約が完了しました。</p>
  <p class="content--text complete-wrap__text">利用・履歴のページから<br />予約内容を確認<br />することができます。</p>
  <parts-image class="content--img complete-wrap__img" url="/image/character/complate.png"></parts-image>

  <div class="button-wrap">
    <ons-button id="exp-complete_001" modifier="large--cta" (click)="onBackHome()">ホームへ戻る</ons-button>
  </div>
</div>
  