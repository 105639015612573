<ons-toolbar>
    <div class="left">
        <ons-back-button></ons-back-button>
    </div>
    <div class="center toolbar__title-smartgoto-wrap">
        <div class="toolbar__title-smartgoto">Smart GOTO</div>
        <div *ngIf="areaName" class="toolbar__title-smartgoto--area-name">({{areaName}}版)</div>
    </div>
</ons-toolbar>
<div [ngBusy]="busy"></div>
<div class="content">
    <div class="text-wrap">
        <p class="text--title">選択中の乗車人数</p>
        <p class="text--data">{{searchCondition.getPassengersCount()}}人</p>
    </div>
    <ons-list class="expandable-content">
        <ons-list-item id="ride-count_001" modifier="longdivider" tappable (click)="searchCondition.passengers.self.checked = !searchCondition.passengers.self.checked">
            <label id="ride-count_002" for="check-self" (click)="$event.stopPropagation()">
                あなた
            </label>
            <div class="right">
                <input id="ride-count_003" type="checkbox" class="checkbox__input" input-id="check-self" [(ngModel)]="searchCondition.passengers.self.checked"
                    (change)="searchCondition.updateCanSearchValue()" (click)="$event.stopPropagation()">
                <div class="checkbox__checkmark"></div>
            </div>
        </ons-list-item>
    </ons-list>
    <ons-list *ngIf="searchCondition.passengers.families.length > 0">
        <ons-list-item id="ride-count_004" expandable modifier="parent">
            <div class="list-item__title">
                <img class="list-icon" [src]="ASSETS_FAMILY" />
                ファミリー（{{searchCondition.passengers.families.length}}人）
            </div>
            <ons-list class="expandable-content">
                <!-- 操作代行アプリの場合、規約未合意のファミリーメンバーは表示されない -->
                <ons-list-item *ngFor="let family of searchCondition.passengers.families; let i = index"
                    modifier="longdivider" tappable (click)="family.checked = !family.checked">
                    <label [attr.for]="'check-family-' + i" (click)="$event.stopPropagation()">
                        {{searchCondition.formatPassengerName(family)}}
                    </label>
                    <div class="right">
                        <input type="checkbox" class="checkbox__input" [attr.input-id]="'check-family-' + i" [(ngModel)]="family.checked"
                            (change)="searchCondition.updateCanSearchValue()" (click)="$event.stopPropagation()">
                        <div class="checkbox__checkmark"></div>
                    </div>
                </ons-list-item>
            </ons-list>
        </ons-list-item>
    </ons-list>
    <!-- <ons-list *ngIf="searchCondition.passengers.self.user">
        <ons-list-item id="ride-count_005" expandable modifier="parent">
            <div class="list-item__title">
                <img class="list-icon" src="../../assets/icon/common/23-Guest.png" />
                ゲスト
            </div>
            <ons-list class="expandable-content">
                <ons-list-item *ngFor="let guest of searchCondition.passengers.guests; let i = index"
                    modifier="longdivider noIcon" tappable (click)="guest.checked = !guest.checked">
                    <label [attr.for]="'check-guest-' + i" (click)="$event.stopPropagation()">
                        {{searchCondition.formatPassengerName(guest)}}
                    </label>
                    <div class="right">
                        <input type="checkbox" class="checkbox__input" [attr.input-id]="'check-guest-' + i" [(ngModel)]="guest.checked"
                            (change)="searchCondition.reenableGuestPassenger(guest)" (click)="$event.stopPropagation()">
                        <div class="checkbox__checkmark"></div>
                    </div>
                </ons-list-item>
                <ons-list-item id="ride-count_006" (click)="faceAuth()" tappable>
                    <div class="list-item__title">
                        <img class="list-icon" src="../../assets/icon/common/24-Plus.png" />
                        追加
                    </div>
                </ons-list-item>
            </ons-list>
        </ons-list-item>
    </ons-list> -->
</div>