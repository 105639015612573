//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { OnsNavigator, Params } from 'ngx-onsenui';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import * as CONST from '../../../constants/constant';

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { UserWebApiService } from '../../../http-services/user-web-api.service';

// component
import { ProxyAccountSignupLocationEditComponent } from '../proxy-account-signup-location-edit/proxy-account-signup-location-edit.component';

// interface
import { ExUser } from '../../../interfaces/response';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 見守り対象アカウントプロフィール入力画面。
 *
 * @export
 * @class ProxyAccountSignupComponent
 * @implements {OnInit}
 * @author （株）オブジェクトデータ wada
 */
@Component({
  selector: 'ons-page[proxy-account-signup]',
  templateUrl: './proxy-account-signup.component.html',
  styleUrls: ['./proxy-account-signup.component.css']
})

export class ProxyAccountSignupComponent implements OnInit 
{

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * サーバ通信Subscription
   *
   * @type {Subscription}
   * @memberof ProxyAccountSignupComponent
   */
  m_Busy: Subscription;

  /**
   * 見守り対象アカウントユーザ情報
   *
   * @type {ExUser}
   * @memberof ProxyAccountSignupComponent
   */
  m_ProxyUser: ExUser;

  /**
   * 性別
   *
   * @memberof ProxyAccountSignupComponent
   */
  SEX = [
    { value: 0, label: CONST.Common.SEX_NO_ANSWER },
    { value: 1, label: CONST.Common.SEX_MALE },
    { value: 2, label: CONST.Common.SEX_FEMALE }
  ];

  /**
   * 生年月日（年）
   *
   * @type {number}
   * @memberof ProxyAccountSignupComponent
   */
  userBirthYear: number;

  /**
   * 生年月日（月）
   *
   * @type {number}
   * @memberof ProxyAccountSignupComponent
   */
  userBirthMonth: number;

  /**
   * 生年月日（日）
   *
   * @type {number}
   * @memberof ProxyAccountSignupComponent
   */
  userBirthDay: number;

  /**
   * 年月日セレクト値（年）
   *
   * @memberof ProxyAccountSignupComponent
   */
  years = new Array;

  /**
   * 年月日セレクト値（月）
   *
   * @memberof ProxyAccountSignupComponent
   */
  months = new Array(12).fill(0).map((x, i) => i);
  
  /**
   * 年月日セレクト値（日）
   *
   * @memberof ProxyAccountSignupComponent
   */
  days = new Array(31).fill(0).map((x, i) => i + 1);
  
  /**
   * 日付が成立しているか
   *
   * @type {boolean}
   * @memberof ProxyAccountSignupComponent
   */
  isDateValid: boolean = true;

  /**
   * 生年月日チェック結果(未来の日付かどうか)
   *
   * @type {boolean}
   * @memberof ProfileSignupComponent
   */
  isFutureDate: boolean = false;

  /**
   * geocoder
   *
   * @private
   * @type {google.maps.Geocoder}
   * @memberof ProxyAccountSignupComponent
   */
  private m_Geocoder: google.maps.Geocoder;

  /**
   * 郵便番号エラーメッセージ
   *
   * @memberof ProxyAccountSignupComponent
   */
  m_ZipEmptyMessage: string = "";

  /**
   * 住所自動入力完了フラグ
   *
   * @memberof ProxyAccountSignupComponent
   */
  m_bAddressAutoCompleted: boolean = false;

  /**
   * 管理者と登録する見守り対象アカウントの住所を同一とする際の判定用
   *
   * @memberof ProxyAccountSignupComponent
   */
  m_bSameManagerAddress: boolean = false;

  /**
   * 住所必須有無
   *    観光目的でないユーザ：必須
   *    観光目的ユーザ　　　：必須
   *    管理者と同じ住所を登録チェック時：任意
   *
   * @type {boolean}
   * @memberof ProxyAccountSignupComponent
   */
  m_bAddressRequired: boolean = true;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of ProxyAccountSignupComponent.
   * @param {OnsNavigator} navigator
   * @param {ApplicationMessageService} appMsg
   * @param {Params} params
   * @param {ChangeDetectorRef} changeDetectorRef
   * @memberof ProxyAccountSignupComponent
   */
  constructor(
    private navigator: OnsNavigator, 
    private changeDetectorRef: ChangeDetectorRef,
    private UserWebApiService: UserWebApiService,
    private msg: MESSAGE,
  ) 
  {
    // 年月を初期化
    for (let year = 1900; year <= new Date().getFullYear(); year++) 
    {
      this.years.push(year);
    }
  }

  /**
   * 初期化処理。
   *
   * @memberof ProxyAccountSignupComponent
   */
  ngOnInit(): void 
  {
    // 見守り対象アカウント情報の初期化
    this.m_ProxyUser = 
    {
      profile: 
      {
        name: {
          family_name: "", 
          family_name_kana: "", 
          given_name: "", 
          given_name_kana: ""
        }, 
        birth: 
        {
          year: 1970, 
          month: 1, 
          day: 1
        }, 
        sex: 0,
        address: {
          zip: null,
          prefecture: "",
          city: "",
          address1: "",
          address2: "",
          address3: "", 
          location: undefined
        }
      }, 
      user_type: this.UserWebApiService.getUserInfo().user_type
    }

    // 生年月日表示用
    this.userBirthYear = this.m_ProxyUser.profile.birth.year;
    this.userBirthMonth = this.m_ProxyUser.profile.birth.month - 1;
    this.userBirthDay = this.m_ProxyUser.profile.birth.day;
    
    // 住所必須判定
    // this.m_bAddressRequired = this.m_ProxyUser.user_type == "citizen" ? true : false;
  }

  /**
   * ビュー初期化処理。
   *
   * @memberof ProxyAccountSignupComponent
   */
  ngAfterViewInit(): void 
  {
    this.m_Geocoder = new google.maps.Geocoder();
  }

  /**
   * 破棄処理。
   *
   * @memberof ProxyAccountSignupComponent
   */
  ngOnDestroy(): void 
  {
    this.m_Busy?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 生年月日の妥当性を確認する。
   *
   * @return {*}  {boolean}
   * @memberof ProxyAccountSignupComponent
   */
  canEdit(): boolean 
  {
    // 存在しない日付かチェックする
    this.isDateValid = moment({ year: this.userBirthYear, month: this.userBirthMonth, day: this.userBirthDay }).isValid();

    // 未来の日付かチェックする
    let birth = new Date(this.userBirthYear, this.userBirthMonth, this.userBirthDay);
    let now = new Date();

    if(now < birth)
    {
      // 生年月日が現在日付より未来の場合は、フラグON
      this.isFutureDate = true;
    }
    else
    {
      // 上記以外はフラグOFF
      this.isFutureDate = false;
    }

    return this.isDateValid || this.isFutureDate;
  }

  /**
   * 管理者と同じ住所を登録チェック時のイベントハンドラ
   *
   * @memberof ProxyAccountSignupComponent
   */
  changeCheckboxSameAddress(): void 
  {
    // 管理者と同じ住所の場合、住所は任意
    if (this.m_bSameManagerAddress) this.m_bAddressRequired = false;
    else this.m_bAddressRequired = true;
    // {
    //   // 観光目的でないユーザの場合、住所は必須
    //   if (this.m_ProxyUser.user_type === 'citizen') this.m_bAddressRequired = true;
    //   // 観光目的ユーザの場合、住所は任意
    //   else this.m_bAddressRequired = false;
    // }
  }

  /**
   * 見守り対象アカウント、緯度経度登録画面へ遷移する。
   *
   * @memberof ProxyAccountSignupComponent
   */
  pushLocationEdit(): void 
  {
    // 生年月日の設定
    var date = moment({ year: this.userBirthYear, month: this.userBirthMonth, day: this.userBirthDay });
    this.m_ProxyUser.profile.birth.year = parseInt(moment(date).format('YYYY'));
    this.m_ProxyUser.profile.birth.month = parseInt(moment(date).format('MM'));
    this.m_ProxyUser.profile.birth.day = parseInt(moment(date).format('DD'));

    // 見守り対象アカウント、緯度経度登録画面へ遷移
    this.navigator.element.pushPage(ProxyAccountSignupLocationEditComponent, { data: { proxyUser: this.m_ProxyUser, sameManagerAddress: this.m_bSameManagerAddress }});
  }

  /**
   * 住所自動入力を行う。
   *
   * @memberof ProxyAccountSignupComponent
   */
  addressAutocomplete(): void 
  {
    // 初期化
    this.zipEmptyMessageClear();

    this.m_Geocoder.geocode(
      {
        address: `${ this.m_ProxyUser.profile?.address?.zip ?? "" }`
      }, (results, status) => 
      {
        if ((status !== google.maps.GeocoderStatus.OK) || !results[0]) 
        {
          this.m_ZipEmptyMessage = this.msg.CLIENT.PROFILE.INVALID_ZIP.message();
          return;
        }
        
        const addressComponents = results[0].address_components;
        const getLongName = (type: string): string => addressComponents.find(e => e.types.some(x => x === type))?.long_name?? '';

        this.m_ProxyUser.profile.address.prefecture = getLongName('administrative_area_level_1');
        this.m_ProxyUser.profile.address.city = `${ getLongName('locality') }${ getLongName('sublocality_level_1') }`;
        this.m_ProxyUser.profile.address.address1 = `${ getLongName('sublocality_level_2') }${ getLongName('sublocality_level_3') }`;
        this.m_ProxyUser.profile.address.address2 = getLongName('sublocality_level_4');
        this.m_ProxyUser.profile.address.address3 = getLongName('premise');

        // 住所自動入力完了フラグON
        this.m_bAddressAutoCompleted = true;

        this.changeDetectorRef.detectChanges();
      }
    );
  }
  
  /**
   * 郵便番号エラーメッセージを初期化する。
   *
   * @memberof ProxyAccountSignupComponent
   */
  zipEmptyMessageClear(): void 
  {
    this.m_ZipEmptyMessage = "";
  }
}
