//=============================================================================================
// インポート
//=============================================================================================
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, InjectionToken, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subscription, firstValueFrom, interval } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as qs from 'qs';
import * as CONST from '../constants/constant';

// component:サービスからの参照はよくない
import { UserReservation } from '../components/reservation/user-reservation/user-reservation';

// service
import { MunicipalityWebApiService } from './municipality-web-api.service';

// interface
import { common } from '../interfaces/common';
import { request } from '../interfaces/request';
import { Reservation, Ticket, PassengerUsers, Section, Settings } from '../interfaces/response';
import * as moment from 'moment';

export const DISPATCH_WEB_API_RESERVATION_TRANS_MAP = new InjectionToken<Map<string, Map<string, string>>>('dispatchWebApiReservationTransMap');

//=============================================================================================
// Configクラス定義
//=============================================================================================

/**
 * Web API (予約関連) の構成設定。
 *
 * @export
 * @class DispatchWebApiServiceConfig
 */
export class DispatchWebApiServiceConfig {
  /**
   * Web API の基底 URL。
   *
   * @type {string}
   * @memberof DispatchWebApiServiceConfig
   */
  baseUrl: string;

  /**
   * HTTP リクエストと一緒に送信されるオプション。
   *
   * @type {({
   *     headers?: HttpHeaders | {
   *       [header: string]: string | string[];
   *     };
   *   })}
   * @memberof DispatchWebApiServiceConfig
   */
  httpOptions?: {
    /**
     * HTTP ヘッダー。
     *
     * @type {(HttpHeaders | {
     *       [header: string]: string | string[];
     *     })}
     */
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
  };
}

//=============================================================================================
// Serviceクラス定義
//=============================================================================================

/**
 * Web API (予約関連) の呼び出しを簡略化するためのユーティリティ。
 *
 * @export
 * @class DispatchWebApiService
 */
@Injectable({
  providedIn: 'root'
})
export class DispatchWebApiService implements OnDestroy {

  /**
   * 自治体ごとの設定情報
   *
   * @type {Settings}
   * @memberof DispatchWebApiService
   */
  setting: Settings;

  /**
   * OnDestroy時に破棄するSubscriptionオブジェクト
   *
   * @memberof DispatchWebApiService
   */
  subscription = new Subscription();

  /**
   * バス停
   *
   * @private
   * @memberof DispatchWebApiService
   */
  private busstops = [];

  /**
   * バス停を取得した時間
   *
   * @private
   * @type {number}
   * @memberof DispatchWebApiService
   */
  private gettedBusstopTime: number = 0;

  /**
   * Creates an instance of DispatchWebApiService.
   * @param {HttpClient} http HTTP リクエストを実行するためのサービス。
   * @param {DispatchWebApiServiceConfig} config Web API (予約関連) の構成設定。
   * @param {MunicipalityWebApiService} municipalityWebApiServ
   * @memberof DispatchWebApiService
   */
  constructor(
    private http: HttpClient,
    private config: DispatchWebApiServiceConfig,
    private municipalityWebApiServ: MunicipalityWebApiService,
  ) {
    const settingsChanged = this.municipalityWebApiServ.settingsChanged.subscribe({
      next: setting => {
        if (setting == null) return;
        this.setting = setting;
      }
    });
    this.subscription.add(settingsChanged);
  }

  /**
   * 廃棄処理
   *
   * @memberof DispatchWebApiService
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * 利用者ごとの予約情報を取得する。
   *
   * @param {Reservation} reserv
   * @return {*}  {common.customerBill}
   * @memberof DispatchWebApiService
   */
  getCustomersBill(reserv: Reservation, passengers: UserReservation.SearchConditionPassengers = undefined): common.customerBill {

    let customerBill: common.customerBill = {
      reservation_id: reserv.reservation_id, 
      o: reserv.o, 
      d: reserv.d, 
      sections: undefined, 
      user: undefined, 
      families: [], 
      // guests: [], 
      total: undefined
    };


    // section
    reserv.sections.forEach(s => {

      //  車両以外の場合は終了
      if (s.trans !== 'smartgoto') return;

      s.tickets.forEach(t => {

        // ユーザ情報を検索
        let property: PassengerUsers["property"];
        if (undefined !== reserv.passenger_users) {
          var user = reserv.passenger_users.find(u => u.id === t.user.user_id);
          if (undefined === user) return;   // ToDo：利用者に存在しないチケットがある。エラー表示するかどうか。
          property = user.property;
        }
        else if (undefined !== passengers) {
          if (passengers.self.user.user_id === t.user.user_id) property = 'self';
          else if  (undefined !== passengers.families.find(f => f.user.user_id === t.user.user_id)) property = 'family';
          else if  (undefined !== passengers.guests.find(g => g.user.user_id === t.user.user_id)) property = 'guest';
          else return;   // ToDo：利用者に存在しないチケットがある。エラー表示するかどうか。
        }
        else ;

        let bill: common.BillPassenger = {
          user_id: t.user.user_id, 
          user_name: t.user.name, 
          price: t.purchase_amount ?? 0, 
          refundPrice: t.purchase_amount ?? 0, 
          property: property, 
          tickets: new Array(), 
          canceled: user ? user?.canceled : false, 
          cancelStatus: false
        }

        switch (property) {

          // ログインユーザ
          case 'self': {

            let userTicket = customerBill?.user?.tickets.find(tk => tk.ticket.ticket_id === t.ticket_id);

            // 未登録の場合
            if (undefined === customerBill.user) {
              // 追加
              
              bill.tickets.push({ sections: new Array(s), ticket: t });
              customerBill.user = bill;
            }
            // セクション違いで同一ユーザかつ同一チケットがない場合
            else if (undefined === userTicket) {
              customerBill.user.tickets.push({ sections: new Array(s), ticket: t });
              customerBill.user.price += t.purchase_amount;
              customerBill.user.refundPrice += t.purchase_amount;
            }
            else { // 同一ticket_idが存在する場合はセクションのみ追加する
              userTicket.sections.push(s);
            }
            break;
          }

          // ファミリー
          case 'family': {
            // 未登録の場合
            if (customerBill.families.length === 0) {
              // 追加
              bill.tickets.push({ sections: new Array(s), ticket: t });
              customerBill.families.push(bill);
            }
            else {
              // 同一チケットがない場合
              if (undefined === this.checkDublication(customerBill.families, s, t))
              {
                // 追加
                bill.tickets.push({ sections: new Array(s), ticket: t });
                customerBill.families.push(bill);
              }
            }
            break;
          }

          // ゲスト
          // case 'guest': {
          //   // 未登録の場合
          //   if (customerBill.guests.length === 0) {
          //     // 追加
          //     bill.tickets.push({ sections: new Array(s), ticket: t });
          //     customerBill.guests.push(bill);
          //   }
          //   else {
          //     // 同一チケットがない場合
          //     if (undefined === this.checkDublication(customerBill.guests, s, t))
          //     {
          //       // 追加
          //       bill.tickets.push({ sections: new Array(s), ticket: t });
          //       customerBill.guests.push(bill);
          //     }
          //   }
          //   break;
          // }
          default: 
            break;
        }
      });
    });

    // 合計金額の計算
    this.priceCalculation(customerBill);

    return customerBill;
  }

  /**
   * 利用者が使用する同一チケットがあるか
   *
   * @private
   * @param {common.BillPassenger[]} passenger
   * @param {Ticket} ticket
   * @return {*}  {common.BillPassenger}
   * @memberof DispatchWebApiService
   */
  private checkDublication(passenger: common.BillPassenger[], section: Section, ticket: Ticket): common.BillPassenger {

    return passenger.find((p) => {
      // 登録済みのファミリーの場合
      if (p.user_id === ticket.user.user_id) {
        let tk = p.tickets.find((tk) => tk.ticket.ticket_id === ticket.ticket_id);

        // 同一のチケットが見つからない場合追加
        if (undefined === tk) {
          p.tickets.push({ sections: new Array(section), ticket: ticket });
          p.price += ticket.purchase_amount;
          p.refundPrice += ticket.purchase_amount;
        }
        else {
          tk.sections.push(section);
        };

        return true;
      }
    });
  }

  /**
   * 合計料金計算を行う。
   *    ・ログインユーザ＋ファミリー
   *    ・ゲスト
   *
   * @private
   * @param {common.customerBill} customerBill
   * @return {*}  {common.customerBill}
   * @memberof DispatchWebApiService
   */
  private priceCalculation(customerBill: common.customerBill): void  {
    // ログインユーザ
    if (customerBill.user) {
      customerBill.total = {
        price: customerBill.user.price, 
        refundPrice: customerBill.user.refundPrice, 
        payer_name: customerBill.user.tickets[0].ticket.payer.name
      }
    }
    
    // ファミリー
    if (customerBill.families.length > 0) {
      customerBill.families.forEach((f) => {
        if (undefined === customerBill.total) {
          customerBill.total = {
            price: f.price, 
            refundPrice: f.refundPrice, 
            payer_name: f.tickets[0].ticket.payer.name
          }
        }
        else {
          customerBill.total.price += f.price;
          customerBill.total.refundPrice += f.refundPrice;
        }
      });
    }
    
    // ゲスト
    // if (customerBill.guests.length > 0) 
    // {
    //   customerBill.guests.forEach((g) => 
    //   {
    //     let total: common.BillTotal = 
    //     {
    //       price: g.price, 
    //       refundPrice: g.refundPrice, 
    //       payer_name: g.tickets[0].ticket.payer.name
    //     }

    //     g.total_guest = total;
    //   });
    // }
  }

  /**
   * キャンセルルール取得
   *
   * @param {string} resesrve_time
   * @return {*}  {Settings["cancel"][number]}
   * @memberof DispatchWebApiService
   */
  getCancelRule(resesrve_time: string): Settings["cancel"][number] {

    // キャンセルルール(period.to)を昇順にソート
    const sortedCancel: Settings["cancel"] = this.setting.cancel.sort((a, b) => moment(a.period.to).diff(b.period.to));

    // 予約日時を含む期間のルールを取得
    return sortedCancel.find(rule => moment(resesrve_time).isSameOrBefore(rule.period.to));
  }

  /**
   * 利用者毎のキャンセル設定を行う。
   *    キャンセル状態の設定
   *    払い戻し料金、合計払い戻し料金の設定
   *
   * @param {common.customerBill} customerBill 配車単位の利用者情報
   * @param {PassengerUsers[]} passengers 払い戻しを行う利用者情報
   * @param {boolean} paidCancel 有料キャンセルか（true：有料、false：無料）
   * @return {*}  {void}
   * @memberof DispatchWebApiService
   */
  setRefundFee(customerBill: common.customerBill, passengers: PassengerUsers[], paidCancel: boolean, resesrve_time: string): void {
    // 払い戻しレート（%）から払い戻しの割合を算出
    const refundRate: number = (100 - this.getCancelRule(resesrve_time).ratio * 100) / 100;

    // 合計料金初期化
    customerBill.total.refundPrice = 0;

    // キャンセル対象者のみ画面に表示
    passengers.forEach((u) => {
      // すでにキャンセル済みの利用者なら次へ
      if (u.canceled) return;

      /**
       * 払い戻し金額取得
       * @param purchase_amount 
       * @returns 
       */
      const setRefundPrice = (amount: number) => {
        // 有料キャンセル：購入時支払い金額 * 払い戻し割合
        if (paidCancel) return amount * refundRate;
        // 無料キャンセル：購入時支払い金額
        else return amount;
      }

      // ログインユーザ
      if (customerBill.user) {
        if (u.id === customerBill.user.user_id) {
          // キャンセルするかどうかを設定
          customerBill.user.cancelStatus = u.cancel;
          
          // 有料キャンセル
          if (paidCancel) customerBill.user.refundPrice = customerBill.user.price * refundRate;

          if (customerBill.user.cancelStatus) {

            // チケットごとの払い戻し料金計算
            customerBill.user.tickets.forEach(ticket => {
              // 料金
              // NOTE: 定額乗車券での予約の場合、amount_orgに定額乗車券の料金がセットされる為分岐が必要。
              const amount: number = ticket.ticket.ticket_type === 1 ? ticket.ticket.amount_org : ticket.ticket.purchase_amount;

              ticket.refundPrice = setRefundPrice(amount);

              // 払い戻し金額合計
              customerBill.total.refundPrice += ticket.refundPrice;
            })
          }
        }
      }

      // ファミリー
      customerBill.families.forEach((f) => { 
        if (u.id === f.user_id) {
          f.cancelStatus = u.cancel;
            
          // 有料キャンセル
          if (paidCancel) f.refundPrice = f.price * refundRate;
          
          if (f.cancelStatus) {
            // チケットごとの払い戻し料金計算
            f.tickets.forEach(ticket => {
              // 料金
              // NOTE: 定額乗車券での予約の場合、amount_orgに定額乗車券の料金がセットされる為分岐が必要。
              const amount: number = ticket.ticket.ticket_type === 1 ? ticket.ticket.amount_org : ticket.ticket.purchase_amount;

              ticket.refundPrice = setRefundPrice(amount);

              // 払い戻し金額合計
              customerBill.total.refundPrice += ticket.refundPrice;
            })
          }  
        }
      });

      // ゲストは対象外
    });
  }

  /**
   * 料金種別（表示名）を取得する。
   *
   * @param {string} amountProperty
   * @return {*}  {string}
   * @memberof DispatchWebApiService
   */
  getAmountPropertyString(amountProperty: string): string {
    let property: string = "";

    switch(amountProperty) {
      case 'canceled':
        property = CONST.Dispatch.AMOUNT_PROPERTY.CANCEL;
        break;
      case 'normal':
        property = CONST.Dispatch.AMOUNT_PROPERTY.NORMAL;
        break;
      case 'baby':
        property = CONST.Dispatch.AMOUNT_PROPERTY.BABY;
        break;
      case 'toddler':
        property = CONST.Dispatch.AMOUNT_PROPERTY.TODDLER;
        break;
      case 'child':
        property = CONST.Dispatch.AMOUNT_PROPERTY.CHILD;
        break;
      case 'handicapped':
        property = CONST.Dispatch.AMOUNT_PROPERTY.HANDICAPPED;
        break;
      case 'assistant':
        property = CONST.Dispatch.AMOUNT_PROPERTY.ASSISTANT;
        break;
      case 'preschooler':
        property = CONST.Dispatch.AMOUNT_PROPERTY.PRESCHOOLER;
        break;
      case 'elementaryschool':
      property = CONST.Dispatch.AMOUNT_PROPERTY.ELEMENTARY_SCHOOL;
        break;
      case 'jrhighschool':
        property = CONST.Dispatch.AMOUNT_PROPERTY.JUNIOR_HIGH_SCHOOL;
        break;
      default:
        property = "";
    }
    
    return property;
  }

  /**
   * 取得した予約の中から目的の乗車券を取得する。
   *
   * @param {common.customerBill[]} bills 配車ごとのユーザが持つ乗車券情報
   * @param {number} user_id 取得するユーザID
   * @param {...Ticket["ticket_type"][]} targetTicketType 乗車券種別（1回券、定額乗車券、数日乗り放題券）
   * @return {*}  {Ticket[]}
   * @memberof DispatchWebApiService
   */
  getReservByUser(bills: common.customerBill[], user_id: string, ...targetTicketType: Ticket["ticket_type"][]): common.ReservTicket[] {
    let ticket: common.ReservTicket[] = [];
    let rt: common.ReservTicket;

    bills.forEach(b => {
      // キャンセルしていない予約
      if (undefined != b.user && user_id === b.user.user_id && false === b.user.canceled) {
        // 指定の乗車券種別の何れかと一致するか
        b.user.tickets.forEach(t => {
          rt = JSON.parse(JSON.stringify(t.ticket));
          rt.o = b.o;
          rt.d = b.d;
          if (true === targetTicketType.some((type) => type === t.ticket.ticket_type)) ticket.push(rt); 
        });
      }

      b.families.forEach(f => {
        // キャンセルしていない予約
        if (user_id === f.user_id && false === f.canceled) {
          // 指定の乗車券種別の何れかと一致するか
          f.tickets.forEach(t => {
            rt = JSON.parse(JSON.stringify(t.ticket));
            rt.o = b.o;
            rt.d = b.d;
            if (true === targetTicketType.some((type) => type === t.ticket.ticket_type))  ticket.push(rt); 
          });
        }
      });
    });

    return ticket;
  }

  /**
   *
   *
   * @param {DispatchWebApiSchemas.ResponseSchemas.Reservation[]} reservation
   * @param {number} user_id
   * @return {*}  {DispatchWebApiSchemas.ResponseSchemas.Reservation[]}
   * @memberof DispatchWebApiService
   */
  getValidReservation(reservation:Reservation[], user_id: string):Reservation[] {
    let res = [];
    reservation.forEach(element => { 
      // キャンセル済みの予約であるか判定
      let filter = element.passenger_users.filter(ele => {
        if (ele.id == user_id && ele.canceled == false) {
          return true;
        }
      })
      if (filter.length != 0) {
        res.push(element);
      }
    })
    
    return res;
  }

  /**
   * バス停取得
   *
   * @return {*}  {Promise<any>}
   * @memberof DispatchWebApiService
   */
  async getBusstop(): Promise<any> {

    const diff: number = moment().valueOf() - this.gettedBusstopTime;

    // 前回取得から一定時間以上立っていた場合、再取得
    if (diff > CONST.Busstop.UPDATE_BUSSTOP_TIME) {
      return (await firstValueFrom(this.getLatestBussstop())).body;
    }
    else return this.busstops;
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * 予約の一覧を取得する。
   *
   * @template T レスポンスの型。
   * @param {request.UserReservations} params 取得の対象となる予約を絞り込むための条件。
   * @return {*}  {Observable<HttpResponse<T>>} レスポンスの受信を監視するための `Observable`。
   * @memberof DispatchWebApiService
   */
  userReservations<T>(params: request.UserReservations): Observable<HttpResponse<T>> {
    return this.http
      .get<T>(`${this.config.baseUrl}/dispatch?${qs.stringify(params)}`, {
        ...this.config.httpOptions,
        observe: 'response',
        withCredentials: true
      });
  }

  /**
   * 移動プランを検索する。
   *
   * @template T レスポンスの型。
   * @param {request.InquiryReserve} reqBody 移動プランの検索条件。
   * @return {*}  {Observable<HttpResponse<T>>} レスポンスの受信を監視するための `Observable`。
   * @memberof DispatchWebApiService
   */
  inquiryReserve(reqBody: request.InquiryReserve): Observable<HttpResponse<any>> {
    return this.http
      .post<any>(`${this.config.baseUrl}/dispatch/fetch`, reqBody, {
        ...this.config.httpOptions,
        observe: 'response',
        withCredentials: true
      });
  }

  /**
   * 予約を確定する。
   *
   * @template T レスポンスの型。
   * @param {request.Reserve} reqBody 予約情報。
   * @return {*}  {Observable<HttpResponse<T>>} レスポンスの受信を監視するための `Observable`。
   * @memberof DispatchWebApiService
   */
  reserve(reqBody: request.Reserve): Observable<HttpResponse<any>> {

    return this.http.post<HttpResponse<any>>(`${this.config.baseUrl}/dispatch`, reqBody, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    })
  }

  /**
   * reservation_idで指定した配車予約情報を取得する
   *
   * @template T
   * @param {number} reservation_id
   * @return {*}  {Observable<HttpResponse<T>>}
   * @memberof DispatchWebApiService
   */
  getUserReservation<Reservation>(reservation_id: number): Observable<HttpResponse<Reservation>> {

    return this.http.get<Reservation>(`${this.config.baseUrl}/dispatch/reservation/${reservation_id}`, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }

  /**
   * 予約をキャンセルする。
   *
   * @template T レスポンスの型。
   * @param {request.DeleteReservation} reqBody キャンセルの対象となる予約。
   * @return {*}  {Observable<HttpResponse<T>>} レスポンスの受信を監視するための `Observable`。
   * @memberof DispatchWebApiService
   */
  deleteReservation(params: request.DeleteReservation): Observable<HttpResponse<any>> {
    const param: request.DeleteReservation = {
      pay_cancel: params.pay_cancel,
      passenger_user_id: params.passenger_user_id
    }

    return this.http.delete(`${this.config.baseUrl}/dispatch/${params.reservation_id}?${qs.stringify(param)}`, {
        ...this.config.httpOptions,
        observe: 'response',
        // params: param as any,
        withCredentials: true
      });
  }

  /**
   * バス停一覧取得
   *
   * @private
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof DispatchWebApiService
   */
  private getLatestBussstop(): Observable<HttpResponse<any>> {
    return this.http.get(`${this.config.baseUrl}/busstop`, {
      ...this.config.httpOptions, 
      observe: 'response', 
      withCredentials: true
    }).pipe(tap({
      next: (res: HttpResponse<any>) => {
        // 取得時点のミリ秒取得
        this.gettedBusstopTime = moment().valueOf();

        // バス停保存
        this.busstops.length = 0;
        this.busstops = res.body;
      }
    }));
  }
}