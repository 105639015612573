<ons-toolbar>
    <div class="left">
        <ons-back-button #onsBackButton></ons-back-button>
    </div>
    <div class="center">ファミリー招待</div>
</ons-toolbar>
<div class="content">
    <div [ngBusy]="m_Busy"></div>
    <div class="center segment-wrap">
        <ons-segment class="segment" active-index="1">
            <button disabled>STEP1</button>
            <button>STEP2</button>
            <button disabled>STEP3</button>
        </ons-segment>
        <div class="content--description">ファミリーの顔を撮影してください。</div>
    </div>
    <div class="button-wrap button-wrap--flex" *ngIf="isStarted">
        <ng-container *ngIf="paused">
            <ons-button id="family-auth-step2_001" class="button button--large--cta button--cancel" (click)="resume()">もう一度</ons-button>
            <ons-button id="family-auth-step2_002" class="button button--large--cta" (click)="auth()">写真を使用</ons-button>
        </ng-container>
        <ng-container *ngIf="!paused">
            <ons-button id="family-auth-step2_003" class="button button--large--cta button--shoot" (click)="shoot()">撮影</ons-button>
        </ng-container>
    </div>
    <div class="text--right content--description">
      撮影のコツは<ons-button id="family-auth-step2_004" modifier="quiet" (click)="alertDialog.show()"><span class="button-text">こちら</span></ons-button>
    </div>
    <div class="guest-face-camera-container">
        <video #video class="guest-face-camera" playsinline></video>
    </div>
    <div class="text--center content--description">
        端末のカメラ機能を利用する場合は<ons-button id="family-auth-step2_005" modifier="quiet" (click)="file.click()"><span class="button-text">こちら</span></ons-button>
    </div>
</div>
<div style="display:none">
    <canvas #canvas width="800" height="600"></canvas>
    <input #file type="file" accept="image/*" />
</div>
<ons-alert-dialog cancelable #alertDialog>
  <div class="alert-dialog-title">[撮影のコツ]</div>
  <div class="alert-dialog-content">
    <ul>
      <li>顔全体が映るようにしてください。</li>
      <li>正面を向いてください。</li>
      <li>顔とカメラが地面と水平になるようにしてください。</li>
      <li>なるべく明るい場所で撮ってください。</li>
      <li>顔の輪郭がはっきりわかるような角度で撮ってください。</li>
    </ul>
  </div>
  <div class="alert-dialog-footer">
    <ons-alert-dialog-button id="family-auth-step2_006" (click)="alertDialog.hide()">OK</ons-alert-dialog-button>
  </div>
</ons-alert-dialog>
