<ons-toolbar class="toolbar">
  <div class="right">
    <ons-toolbar-button class="edit-cancel-button" (click)="cancel()">登録中止</ons-toolbar-button>
  </div>
  <div class="center">新規会員登録</div>
</ons-toolbar>

<div class="content">
  <div [ngBusy]="busy"></div>
  <div class="center segment-wrap">
    <div class="segment" *ngIf="viewStep.valueOf() === true">
      <ul class="stepbar stepbar__four">
        <li class="stepbar__item">STEP1</li>
        <li class="stepbar__item">STEP2</li>
        <li class="stepbar__item stepbar__item--current">STEP3</li>
        <li class="stepbar__item">STEP4</li>
      </ul>
    </div>
    <div class="segment" *ngIf="viewStep.valueOf() === false">
      <ul class="stepbar stepbar__two">
        <li class="stepbar__item stepbar__item--current">STEP1</li>
        <li class="stepbar__item">STEP2</li>
      </ul>
    </div>

    <div class="content--description">ユーザー情報を登録してください。</div>
  </div>

  <form id="profileCreateForm" #formCheck="ngForm" class="formCheck">

    <ons-list class="profile-list">

      <ons-list-header modifier="color" class="list-item__title">観光目的の利用
        <span class="required-mark">※</span>
        <div class="text--right required-mark">※必須入力項目</div>
      </ons-list-header>
      <ons-list-item modifier="longdivider" class="list-item__text">
        <span>
          <ons-checkbox id="profile-signup_002" input-id="user_tourist" name="user_tourist" [checkd]="!citizen" (click)="toggle(false)" [disabled]="!canChangeUserType"></ons-checkbox>
          <label for="user_tourist" [class.checked]="!citizen">はい</label>
        </span>
        <span style="padding-left: 10px;">
          <ons-checkbox id="profile-signup_003" input-id="user_citizen" name="user_citizen" [checkd]="citizen" (click)="toggle(true)" [disabled]="!canChangeUserType"></ons-checkbox>
          <label for="user_citizen" [class.checked]="citizen">いいえ</label>
        </span>
      </ons-list-item>

      <ons-list-header modifier="color" class="list-item__title">氏名<span class="required-mark">※</span>
      </ons-list-header>
      <ons-list-item modifier="longdivider" class="list-item__text">
        <ons-input id="profile-signup_004" [(ngModel)]="userInfo.profile.name.family_name" name="family_name" #familyNameModel="ngModel"
          autocomplete="family-name" required placeholder="姓"></ons-input>
        <div class="valid-msg" *ngIf="familyNameModel.errors && (familyNameModel.dirty || familyNameModel.touched)">
          <span *ngIf="familyNameModel.errors.required">必須です。</span>
        </div>
      </ons-list-item>
      <ons-list-item modifier="longdivider" class="list-item__text">
        <ons-input id="profile-signup_005" [(ngModel)]="userInfo.profile.name.given_name" name="given_name" #givenNameModel="ngModel"
          autocomplete="given-name" required placeholder="名"></ons-input>
        <div class="valid-msg" *ngIf="givenNameModel.errors && (givenNameModel.dirty || givenNameModel.touched)">
          <span *ngIf="givenNameModel.errors.required">必須です。</span>
        </div>
      </ons-list-item>

      <ons-list-header modifier="color" class="list-item__title">
        氏名（かな）<span class="required-mark">※</span>
      </ons-list-header>
      <ons-list-item modifier="longdivider" class="list-item__text">
        <ons-input id="profile-signup_006" [(ngModel)]="userInfo.profile.name.family_name_kana" name="family_name_kana"
          #familyNameKanaModel="ngModel" required pattern="^[ぁ-んー]*$" placeholder="せい"></ons-input>
        <div class="valid-msg"
          *ngIf="familyNameKanaModel.errors && (familyNameKanaModel.dirty || familyNameKanaModel.touched)">
          <span *ngIf="familyNameKanaModel.errors.required">必須です。</span>
          <span *ngIf="familyNameKanaModel.errors.pattern">「ひらがな」以外の文字が含まれています。</span>
        </div>
      </ons-list-item>
      <ons-list-item modifier="longdivider" class="list-item__text">
        <ons-input id="profile-signup_007" [(ngModel)]="userInfo.profile.name.given_name_kana" name="given_name_kana" #givenNameKanaModel="ngModel"
          required pattern="^[ぁ-んー]*$" placeholder="めい"></ons-input>
        <div class="valid-msg"
          *ngIf="givenNameKanaModel.errors && (givenNameKanaModel.dirty || givenNameKanaModel.touched)">
          <span *ngIf="givenNameKanaModel.errors.required">必須です。</span>
          <span *ngIf="givenNameKanaModel.errors.pattern">「ひらがな」以外の文字が含まれています。</span>
        </div>
      </ons-list-item>

      <ons-list-header modifier="color" class="list-item__title">
        生年月日<span class="required-mark">※</span>
      </ons-list-header>
      <ons-list-item modifier="longdivider" class="list-item__text">
        <div class="form-select-box">
          <ons-select id="profile-signup_008" class="birthday-year dropdown-toggle" [(ngModel)]="userBirthYear" name="userBirthYear"
            #userBirthYearModel="ngModel" (change)="canEdit()" required>
            <option [value]="year" *ngFor="let year of years;">
              {{ year }}
            </option>
          </ons-select>
          <div class="mark--delimiter">/</div>
          <ons-select id="profile-signup_009" class="birthday-month dropdown-toggle" [(ngModel)]="userBirthMonth" name="userBirthMonth"
            #userBirthMonthModel="ngModel" (change)="canEdit()" required>
            <option [value]="month" *ngFor="let month of months;">{{ month + 1 }}</option>
          </ons-select>
          <div class="mark--delimiter">/</div>

          <ons-select id="profile-signup_010" class="birthday-day dropdown-toggle" [(ngModel)]="userBirthDay" name="userBirthDay"
            #userBirthDayModel="ngModel" (change)="canEdit()" required>
            <option [value]="day" *ngFor="let day of days;">{{ day }}</option>
          </ons-select>

        </div>
        <div class="valid-msg" *ngIf="isFutureDate">
          <span>未来の日付は指定できません。</span>
        </div>
        <div class="valid-msg" *ngIf="isDateValid == false && isFutureDate == false">
          <span>日付が不正です。</span>
        </div>
      </ons-list-item>

      <ons-list-header modifier="color" class="list-item__title">
        性別
      </ons-list-header>
      <ons-list-item modifier="longdivider" class="list-item__text">
        <div>
          <ons-select id="profile-signup_011" [(ngModel)]="userInfo.profile.sex" name="sex" #sexModel="ngModel" [attr.modifier]="userInfo.profile?.sex"
            required>
            <option *ngFor="let modifier of SEX" [value]="modifier.value">
              {{ modifier.label }}
            </option>
          </ons-select>
        </div>
        <div class="valid-msg" *ngIf="sexModel.errors && (sexModel.dirty || sexModel.touched)">
          <span *ngIf="sexModel.errors.required">「性別」は必須です。</span>
        </div>
      </ons-list-item>

      <ons-list-header modifier="color" class="list-item__title">
        電話番号<span class="required-mark">※</span>
      </ons-list-header>
      <ons-list-item modifier="longdivider" class="list-item__text">
        <ons-input id="profile-signup_012" [(ngModel)]="userInfo.profile.tel" name="tel" #telModel="ngModel" pattern="^\d{9,15}$" maxlength="15"
          autocomplete="tel" inputmode="numeric" required placeholder="08012345678(ハイフンなし)"></ons-input>
        <div class="valid-msg" *ngIf="telModel.errors && (telModel.dirty || telModel.touched)">
          <span *ngIf="telModel.errors.required">必須です。</span>
          <span *ngIf="telModel.errors.pattern">電話番号が不正です。9～15桁の半角数字を入力してください。</span>
        </div>
      </ons-list-item>

      <ons-list-header modifier="color" class="list-item__title">メールアドレス<span class="text--right required-mark" *ngIf="userInfo.auth === 'local'">※入力済み</span>
      </ons-list-header>
      <ons-list-item modifier="longdivider" class="list-item__text disable-editing">
        {{ userInfo.profile.mail }}
      </ons-list-item>
    </ons-list>

    <div class="address-list">
      <p class="address-list__title">
        住所<span class="required-mark">※</span>
      </p>
      <dl class="address-list__item">
        <dt class="address-title">郵便番号</dt>
        <dd class="address-data">
          <ons-input id="profile-signup_014" inputmode="numeric" [(ngModel)]="userInfo.profile.address.zip" name="zip" #zipModel="ngModel" required
            pattern="^\d{7}$" maxlength="7" autocomplete="postal-code" placeholder="1234567" class="input--short zip"
            (ngModelChange)="zipEmptyMessageClear()">
          </ons-input>
          <ons-button id="profile-signup_015" [disabled]="zipModel.errors" (click)="addressAutocomplete()" class="address-button">住所自動入力
          </ons-button>
          <div class="valid-msg" *ngIf="zipModel.errors && (zipModel.dirty || zipModel.touched)">
            <span *ngIf="zipModel.errors.required">必須です。</span>
            <span *ngIf="zipModel.errors.pattern">半角数字７桁で入力ください。</span>
            <span *ngIf="zipModel.errors.maxlength">最大７文字です。</span>
          </div>
          <div class="valid-msg" *ngIf="zipEmptyMessage.length > 0"><span>{{zipEmptyMessage}}</span></div>
        </dd>
      </dl>
      <dl class="address-list__item">
        <dt class="address-title">都道府県</dt>
        <dd class="address-data">
          <ons-input id="profile-signup_016" [(ngModel)]="userInfo.profile.address.prefecture" name="prefecture" #prefectureModel="ngModel" autocomplete="address-level1"
            placeholder="都道府県" required></ons-input>
          <div class="valid-msg" *ngIf="prefectureModel.errors && (prefectureModel.dirty || prefectureModel.touched)">
            <span *ngIf="prefectureModel.errors.required">必須です。</span>
          </div>
        </dd>
      </dl>
      <dl class="address-list__item">
        <dt class="address-title">市区町村</dt>
        <dd class="address-data">
          <ons-input id="profile-signup_017" [(ngModel)]="userInfo.profile.address.city" name="city" #cityModel="ngModel" autocomplete="address-level2"
            placeholder="市区町村" required></ons-input>
          <div class="valid-msg" *ngIf="cityModel.errors && (cityModel.dirty || cityModel.touched)">
            <span *ngIf="cityModel.errors.required">必須です。</span>
          </div>
        </dd>
      </dl>
      <dl class="address-list__item">
        <dt class="address-title">町域</dt>
        <dd class="address-data">
          <ons-input id="profile-signup_018" [(ngModel)]="userInfo.profile.address.address1" name="address1" #address1Model="ngModel" autocomplete="address-level3"
            placeholder="町域" required></ons-input>
          <div class="valid-msg" *ngIf="address1Model.errors && (address1Model.dirty || address1Model.touched)">
            <span *ngIf="address1Model.errors.required">必須です。</span>
          </div>
        </dd>
      </dl>
      <dl class="address-list__item">
        <dt class="address-title">番地以降</dt>
        <dd class="address-data">
          <ons-input id="profile-signup_019" [(ngModel)]="userInfo.profile.address.address2" name="address2" #address2Model="ngModel"
            placeholder="番地以降" required></ons-input>
          <div class="valid-msg" *ngIf="address2Model.errors && (address2Model.dirty || address2Model.touched || addressAutoCompleted)">
            <span *ngIf="address2Model.errors.required">必須です。</span>
          </div>
        </dd>
      </dl>
      <dl class="address-list__item">
        <dt class="address-title">建物名・<br />部屋番号</dt>
        <dd class="address-data">
          <ons-input id="profile-signup_020" [(ngModel)]="userInfo.profile.address.address3" name="address3" placeholder="建物名・部屋番号"></ons-input>
        </dd>
      </dl>
    </div>

    <div class="button-wrap">
      <div class="valid-msg" *ngIf="formCheck.invalid == true || isDateValid == false || isFutureDate == true">
        <span class="valid-msg__text">必須入力項目が正しく入力されていません。</span>
      </div>
      <ons-button id="profile-signup_021" modifier="large--cta noFixed" (click)="pushSignupLocation()"
        [disabled]="formCheck.invalid || !isDateValid || isFutureDate">次へ</ons-button>
    </div>
  </form>
</div>
