<div class="content">
    <div [ngBusy]="[busyPosition, busyUser, busyFamily, busyFavorite, busyAssert]"></div>
    <div>
        <div class="start-arrival-wrap">
            <ons-list class="spot-wrap">
                <ng-template #spotSelector let-od="od" let-title="title" let-imgSrc="imgSrc">
                    <ons-list-item tappable (click)="selectSpot(od)">
                        <img class="spot-icon" [src]="imgSrc" />
                        <ng-container [ngSwitch]="!!title">
                            <p *ngSwitchCase="true" class="list_text">{{title}}</p>
                            <p *ngSwitchDefault class="list_text empty-spot-title">未設定</p>
                        </ng-container>
                    </ons-list-item>
                </ng-template>
                <ng-container *ngTemplateOutlet="spotSelector; context: {od: 'o', title: searchCondition.spotService.getTitle(searchCondition.o), imgSrc: ASSETS.DEPARTURE}"></ng-container>
                <ng-container *ngTemplateOutlet="spotSelector; context: {od: 'd', title: searchCondition.spotService.getTitle(searchCondition.d), imgSrc: ASSETS.DESTINATION}"></ng-container>
            </ons-list>
            <ons-button id="user-reservation_002" class="button spot-change-button" tappable
                (click)="searchCondition.swapSpots()">
                <img [src]="ASSETS.CHANGE" />
            </ons-button>
        </div>
        <ons-list>
            <ons-list-item class="list-item-wrap">
                <img class="list-icon" [src]="ASSETS.CLOCK" />
                <div class="list-item--time-wrap">
                    <ons-segment #useCurrentTimeSegment class="segment" [activeIndex]="0"
                        (activeIndexChange)="toggleScheduleUseCurrentTime($event); assertScheduleDateTime()">
                        <button id="user-reservation_003">現在時刻</button>
                        <button id="user-reservation_004">時刻選択</button>
                    </ons-segment>
                    <ons-input type="datetime-local" id="set-time" placeholder="時間指定"
                        [value]="searchCondition.schedule.dateTime"
                        (click)="useCurrentTimeSegment.setActiveButton(1)"
                        (input)="searchCondition.schedule.dateTime = $event.target.value; assertScheduleDateTime()"
                        [class]="['list-item--time', ((useCurrentTimeSegment.getActiveButtonIndex() == 0) ? 'use-current-time' : '')].join(' ')">
                    </ons-input>
                    <ons-segment #OdSegment class="segment" [activeIndex]="0"
                        (activeIndexChange)="toggleScheduleOd($event)">
                        <button id="user-reservation_005">{{ searchConditionScheduleOdOptions.o.label }}</button>
                        <button id="user-reservation_006">{{ searchConditionScheduleOdOptions.d.label }}</button>
                    </ons-segment>
                    <div class="valid-msg">
                        <span *ngIf="validationErrorMessages.has(SCHEDULE_DATE_TIME_ERROR_MESSAGE_KEY)">{{validationErrorMessages.get(SCHEDULE_DATE_TIME_ERROR_MESSAGE_KEY)}}</span>
                    </div>
                </div>
            </ons-list-item>
        </ons-list>
        <ons-list>
            <ons-list-item id="user-reservation_007" tappable (click)="pushRide()">
                <img class="list-icon" [src]="ASSETS.NUMBER" />
                <p class="list_text">{{searchCondition.getPassengersCount()}}人<span *ngIf="searchCondition.getPassengersCount() > 0">({{searchCondition.passengers.self.checked ? 'あなた' : searchCondition.formatPassengerName(searchCondition.getPassengersUsers()[0])}}{{(searchCondition.getPassengersCount() > 1) ? '、...' : ''}})</span></p>
            </ons-list-item>
        </ons-list>
        <ons-list hidden>
            <ons-list-item id="user-reservation_008" (click)="pushBaggage()">
                <img class="list-icon" [src]="ASSETS.BAGGAGE" />
                <p class="list_text">荷物積載情報</p>
            </ons-list-item>
        </ons-list>
    </div>
    <div class="button-wrap">
        <ons-button id="user-reservation_009" class="button button--large--cta" [disabled]="!searchCondition.canSearch" (click)="search()">検　索
        </ons-button>
    </div>
</div>