import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Observable, Subscription } from 'rxjs';

// service
import { HistoryWebApiService } from '../../../http-services/history-web-api.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';

@Component({
  selector: 'parts-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent implements OnInit, AfterViewInit {

  /**
   * 通信用Subscription
   *
   * @type {Subscription}
   * @memberof ExpComponent
   */
  busy: Subscription;

  /**
   * 検索履歴
   *
   * @type {string[]}
   * @memberof ShoppingComponent
   */
  searchHistory: string[] = [];

  /**
   * 検索履歴のフィルタリング
   *
   * @type {Observable<string[]>}
   * @memberof ShoppingComponent
   */
  filteredOptions: Observable<string[]>;

  /**
   * 検索フォームにフォーカスがある場合履歴を再取得しない
   *
   * @type {boolean}
   * @memberof ExpComponent
   */
  oneFocus: boolean = false;

  /**
   * 検索履歴のオートコンプリート
   *
   * @type {MatAutocompleteTrigger}
   * @memberof ShoppingComponent
   */
  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger }) 
  autoComplete: MatAutocompleteTrigger;

  /**
   * 検索フォーム要素
   *
   * @private
   * @type {*}
   * @memberof SearchFormComponent
   */
  @ViewChild('autoCompleteInput') private searchInput: ElementRef<HTMLInputElement>;

  /**
   * 検索ワード
   *
   * @type {string}
   * @memberof SearchFormComponent
   */
  @Input() freeword: string;

  /**
   * 履歴対象
   *
   * @type {string}
   * @memberof SearchFormComponent
   */
  @Input() target: string;

  /**
   * 履歴最大数
   *
   * @type {number}
   * @memberof SearchFormComponent
   */
  @Input() limit: number;

  /**
   * 検索イベント
   *
   * @memberof SearchFormComponent
   */
  @Output() searchService = new EventEmitter<SearchFormData>();


  /**
   * 前回検索したフリーワード
   *
   * @type {string}
   * @memberof SearchFormComponent
   */
  beforeFreeWord: string = "";

  constructor(
    private historyServ: HistoryWebApiService,
    private errResServ: HttpErrorResponseParserService, 
  ) { }

  ngOnInit(): void {
    // freewordの初期値が指定されている場合、前回検索したフリーワードとして保存
    this.beforeFreeWord = this.freeword;
  }

  ngAfterViewInit(): void {

    // NOTE: changeイベントのみだと、同じフリーワードの時、親に通知されないため追加
    this.searchInput.nativeElement.addEventListener("keydown", (event: KeyboardEvent) => {
      // 押しっぱなし制御
      if (event.repeat) return;
      
      // エンターキーとして認識されるイベント & 前回検索したフリーワードと現在入力されているフリーワードが同じ場合、親に通知
      if (event.key.includes("Enter") && this.freeword == this.beforeFreeWord) {
        this.onSearch(false);
      }
      else return;
    });
  }

  /**
   * 検索フォームフォーカス時のイベントハンドラ。
   *    検索履歴を表示する。
   *
   * @memberof NewsArticleListComponent
   */
  onSearchFocus(): void {

    // 検索履歴取得、表示
    if (this.oneFocus === false) {
      this.searchHistory = [];
      this.getSearchHistory();
    }

    // フォーカスがある状態でのclickイベント無効化
    this.oneFocus = true;
  }

  /**
   * 検索フォームからフォーカスが外れた際のイベントハンドラ。
   *
   * @memberof NewsArticleListComponent
   */
  onSearchBlur(): void {

    // clickイベントを有効化
    this.oneFocus = false;
  }

  /**
   * 検索履歴選択時のイベントハンドラ。
   *
   * @memberof NewsArticleListComponent
   */
  onSearchHistorySelect(): void {
    
    // 検索履歴パネルを閉じる
    this.autoComplete.closePanel();
    
    // 選択項目でフリーワード検索
    this.onSearch(true);
  }

  /**
   * 検索履歴削除ボタン押下時のイベントハンドラ。
   *
   * @param {string} word
   * @param {boolean} [all]
   * @memberof NewsArticleListComponent
   */
  onDeleteSearchHistory(event: any, index: number, word: string, all?: boolean): void {
    
    // clickイベントの伝播を防止
    event.stopPropagation();
    event.preventDefault();

    const query = { word: word, all: all };
    if (all === true) delete query.word;
    if (all === undefined) delete query.all;
    
    // 検索履歴削除
    this.deleteSearchHistory(query, index);
  }

  /**
   * フリーワード検索実施時のイベントハンドラ。
   *
   * @return {*}  {void}
   * @memberof SearchFormComponent
   */

  /**
   * フリーワード検索実施時のイベントハンドラ。
   *
   * @param {boolean} is_change true: 変更あり false: 変更なし
   * @memberof SearchFormComponent
   */
  onSearch(is_change: boolean): void {

    if (this.freeword === "" || this.freeword === undefined) this.freeword = undefined;
    else {
      // 全角スペースを半角に置換、前後のスペース除去
      let word = this.freeword.replace(/　/g, " ");
      this.freeword = word.trim();
    }

    // 検索履歴パネルを閉じる
    this.autoComplete.closePanel();

    // INPUTタグからフォーカスを外す
    document.getElementById("HistoryInput").blur();

    // 前回検索したfreewordを保存
    this.beforeFreeWord = this.freeword;

    // 親へイベントを通知（フリーワード、変更があるかどうか）
    this.searchService.emit({freeword: this.freeword, is_change: is_change});
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * 検索履歴を取得する。
   *
   * @private
   * @memberof SearchFormComponent
   */
  private getSearchHistory(): void {

    this.busy = this.historyServ.getSearchHistory(this.target, this.limit).subscribe({
      next: response => { this.searchHistory = response.body; }, 
      error: this.errResServ.doParse((_err, errContent) => { this.errResServ.viewErrDialog(errContent); })
    });
  }

  /**
   * 検索履歴を削除する。
   *
   * @private
   * @param {{ word?: string, all?: boolean }} queryi
   * @param {number} index
   * @memberof ExpComponent
   */
  private deleteSearchHistory(query: { word?: string, all?: boolean }, index: number): void {

    this.busy = this.historyServ.deleteSearchHistory(this.target, query).subscribe({
      next: () => { index === -1 ?  this.searchHistory = [] : this.searchHistory.splice(index, 1); }, 
      error: this.errResServ.doParse((_err, errContent) => { this.errResServ.viewErrDialog(errContent); })
    });
  }

}

/**
 * 検索フォームのデータ
 *
 * @export
 * @interface SearchFormData
 */
export interface SearchFormData {

  /**
   * フリーワード
   *
   * @type {string}
   * @memberof SearchFormData
   */
  freeword: string;

  /**
   * フリーワードに変更があるかどうか
   *
   * @type {boolean}
   * @memberof SearchFormData
   */
  is_change: boolean;
}