//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { OnsNavigator } from 'ngx-onsenui';

// component
import { FamilyDetailComponent } from './family-detail/family-detail.component';
import { FamilyInviteApprovalComponent } from './family-invite-approval/family-invite-approval.component';
import { FamilyAddComponent } from './family-add/family-add.component';
import { FamilyDescriptionComponent } from './family-description/family-description.component';

// service
import { ApplicationMessageService } from '../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../lib-services/http-error-response-parser.service';
import { FamilyWebApiService } from '../../http-services/family-web-api.service';
import { PageKey, PagerService } from 'src/app/lib-services/pager.service';

// interface
import { Relation } from '../../interfaces/response';

// module
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * ファミリー管理画面
 *
 * @export
 * @class FamilyComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @author （株）オブジェクトデータ wada takimoto yoshida
 */
@Component({
  selector: 'ons-page[family]',
  templateUrl: './family.component.html',
  styleUrls: ['./family.component.css']
})
export class FamilyComponent implements OnInit, OnDestroy {

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * ファミリー情報のSubscriptionオブジェクト
   *
   * @type {Subscription}
   * @memberof FamilyComponent
   */
  m_Busy: Subscription;

  /**
   * ファミリー情報変更監視subscription
   *
   * @private
   * @type {Subscription}
   * @memberof MyqrComponent
   */
  private onFamilyChanged: Subscription;

  /**
   * ファミリー情報
   *
   * @type {Relation[]}
   * @memberof FamilyComponent
   */
  m_FamilyRelations: Relation[] = [];

  /**
   * プロフィールタブに出すバッジ
   *
   * @type {number}
   * @memberof FamilyComponent
   */
  m_StatusBadge: number = null;

  /**
   * OnsNavigatorによる、このComponentのページ番号(数え方:0,1,2,...)
   *
   * @type {number}
   * @memberof FamilyComponent
   */
  m_nPageIndex: number;

  /**
   * Family.componentのページ番号（初期化用）
   *
   * @type {number}
   * @memberof FamilyComponent
   */
  m_nFmilyTopIndex: number;

  /**
   * FamilyComponentのrefresh()メソッドを遷移先で使うためのオブジェクト変数
   *
   * @type {*}
   * @memberof FamilyComponent
   */
  m_FamilyReturnClass: any;

  /**
   * ファミリーメンバー情報
   *    [0]：正式メンバー
   *    [1]：招待承認待ち
   *
   * @type {Relation[][]}
   * @memberof FamilyComponent
   */
  m_MenberStatusList: Relation[][] = [];
  
  /**
   * ログインユーザのファミリーロール
   *
   * @type {string}
   * @memberof FamilyComponent
   */
  m_LoginUserRole: string;

  /**
   * assetsファイルへのパス(定数)
   *
   * @type {string}
   * @memberof FamilyComponent
   */
  readonly ASSETS_NUMBER: string = CommonFunctionModule.getAssetsUrl('/image/common/18-Number.png');

  /**
   * OnDestroy時に破棄するSubscriptionオブジェクト
   *
   * @memberof FamilyComponent
   */
  subscription = new Subscription();

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of FamilyComponent.
   * @param {OnsNavigator} navigator
   * @param {ApplicationMessageService} appMsg
   * @param {FamilyWebApiService} familyWebApiService
   * @param {HttpErrorResponseParserService} httpErrorResponseParserService
   * @param {PagerService} pagerServ
   * @memberof FamilyComponent
   */
  constructor(
    private navigator: OnsNavigator,
    private appMsg: ApplicationMessageService, 
    private familyWebApiService: FamilyWebApiService, 
    private httpErrorResponseParserService: HttpErrorResponseParserService,
    private pagerServ: PagerService,
    private msg: MESSAGE,
  ) { }

  /**
   * 初期化処理。
   *
   * @memberof FamilyComponent
   */
  ngOnInit(): void {
    // FamilyComponentのページ番号を記録
    this.m_nPageIndex = this.navigator.element.pages.length - 1;
    this.m_nFmilyTopIndex = this.m_nPageIndex;
    this.m_MenberStatusList.push(new Array());
    this.m_MenberStatusList.push(new Array());

    /**
     * 画面再描画クラス。
     *    他画面から本画面へ遷移し、再描画を行う。
     *
     * @class FamilyParamImpl
     */
    class FamilyParamImpl {
      /**
       * Creates an instance of FamilyParamImpl.
       * @param {FamilyComponent} component
       * @memberof FamilyParamImpl
       */
      constructor(private component: FamilyComponent) { }

      /**
       * 再描画を行う。
       *
       * @memberof FamilyParamImpl
       */
      refresh(backPageIndex?: number, update: boolean = true): void {
        this.component.refresh(backPageIndex, update);
      }

      /**
       * ファミリー情報を取得する。
       *
       * @memberof FamilyParamImpl
       */
      getFamily(): void {
        this.component.getFamily();
      }
    }
    
    // クラス構築(引数はFamilyParamImplのコンストラクタ引用)
    this.m_FamilyReturnClass = new FamilyParamImpl(this);

    // ログインユーザのファミリーロールを取得
    this.m_LoginUserRole = this.familyWebApiService.getLoginUserRole();

    // ファミリー情報取得
    this.getFamily();
  }

  /**
   * HTMLのデータ処理
   *
   * @memberof FamilyComponent
   */
  ngAfterViewInit(): void {
    setTimeout(() => { 
      this.m_FamilyRelations = this.familyWebApiService.getFamilyInfo();

      // ログインユーザが管理者の場合
      if (this.m_LoginUserRole == 'parent') {
        this.m_MenberStatusList = [];
        
        // メンバーのステータス毎に振り分け
        this.m_MenberStatusList.push(this.m_FamilyRelations.filter(r => r.status !== 'waiting' && r.role == 'child'));
        this.m_MenberStatusList.push(this.m_FamilyRelations.filter(r => r.status === 'waiting' && r.role == 'child'));
      }
    });

    // ファミリー情報の変更を監視
    this.onFamilyChanged = this.familyWebApiService.familyInfoChenged.subscribe({
      next: familyInfo => {
        // ファミリー情報を更新
        setTimeout(() => { this.m_FamilyRelations = familyInfo; });
      }
    });

    // ファミリートップ画面のindexを戻り先のデータとして設定。
    this.pagerServ.setReturnPage({index: this.navigator.element.pages.length - 1, key: PageKey.FamilyComponent});

    // 遷移先画面からファミリートップ画面に戻った際に、ファミリーの再取得を行う。
    const pagerSubject = this.pagerServ.subject.subscribe({
      next: (key: number) => {
        if (key == PageKey.FamilyComponent) {
          this.getFamily();
        }
      },
    });

    this.subscription.add(pagerSubject);
  }

  /**
   * 破棄処理。
   *
   * @memberof FamilyComponent
   */
  ngOnDestroy(): void {
    // Observablesのstreamを止める
    this.m_Busy?.unsubscribe();
    this.onFamilyChanged?.unsubscribe();

    // pager.serviceで使用するindexを削除
    this.pagerServ.deleteReturnPage(PageKey.FamilyComponent);
    this.subscription?.unsubscribe();
  }
  
//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 管理者・メンバー詳細画面への遷移する。
   * 
   * @memberof FamilyComponent　
   * @param {Relation} relation 表示するユーザー詳細情報
   */
  pushDetail(relation: Relation): void {
    this.navigator.element.pushPage(FamilyDetailComponent, { data: { relation: relation , class: this.m_FamilyReturnClass }});
  }

  /**
   * 管理者承認画面への遷移する。
   *
   * @memberof FamilyComponent
   * @param {Relation} relation 承認するユーザー情報
   */
  pushConfirm(relation: Relation): void {
    this.navigator.element.pushPage(FamilyInviteApprovalComponent, { data: { relation: relation, class: this.m_FamilyReturnClass, confirmMode: "family" }});
  }

  /**
   * ファミリー追加画面への遷移する。
   *
   * @memberof FamilyComponent
   */
  pushAdd(): void {
    this.navigator.element.pushPage(FamilyAddComponent, {data: { class: this.m_FamilyReturnClass }});
  }

  /**
   * ファミリー機能説明を表示する。
   *
   * @memberof FamilyComponent
   */
  viewDescription(): void {
    this.navigator.element.pushPage(FamilyDescriptionComponent);
  }

  /**
   * ファミリー登録の際の注意事項をダイアログ表示
   *
   * @memberof FamilyComponent
   */
  viewFamilyCautions(): void {
    this.appMsg.viewDialogMessage(this.msg.CLIENT.FAMILY.CAUTIONS.message());
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * 再描画を行う。
   *    HTTP通信によってファミリー情報が変更された時、
   *    FamilyComponentに戻り、再描画を行う。
   *
   * @private
   * @memberof FamilyComponent
   */
  private refresh(backPageIndex?: number, update: boolean = true): void {
    // 現在のページ番号
    const nCurrentIndex = this.navigator.element.pages.length - 1;

    // 戻り先が定義されているなら設定
    if (undefined !== backPageIndex) this.m_nPageIndex = backPageIndex;

    if (this.m_nPageIndex < nCurrentIndex) {
      // FamilyComponentの次のページを取り除く→ページ先頭がFamilyComponentになるまで繰り返し
      this.navigator.element.removePage(this.m_nPageIndex + 1)
      .then(() => {
        setTimeout(() => {
          this.refresh(backPageIndex, update);
        }, 0);
      });
    } 
    else if (this.m_nPageIndex === nCurrentIndex) {
      this.m_nPageIndex = this.m_nFmilyTopIndex;

      // HTTP通信により情報の最新化
      if (update) this.getFamily();
    }
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * ファミリー情報の取得(HTTP通信)する。
   *
   * @private
   * @memberof FamilyComponent
   */
  private getFamily(): void {
    this.m_Busy = this.familyWebApiService.getFamily().subscribe({
      next: response => {
        this.m_FamilyRelations = response.body;

        // ログインユーザが管理者の場合
        this.m_LoginUserRole = this.familyWebApiService.getLoginUserRole();
        if (this.m_LoginUserRole == 'parent') {
          this.m_MenberStatusList = [];
          
          // メンバーのステータス毎に振り分け
          this.m_MenberStatusList.push(this.m_FamilyRelations.filter(r => r.status !== 'waiting' && r.role == 'child'));
          this.m_MenberStatusList.push(this.m_FamilyRelations.filter(r => r.status === 'waiting' && r.role == 'child'));
        }
      },
      error: this.httpErrorResponseParserService.doParse((_error, customErrorContent) => {
        // エラー発生時はメッセージ表示
        this.appMsg.viewDialogErrorMessage(this.msg.CLIENT.FAMILY.E_GET_FAMILY.message(), customErrorContent.smartGotoErrMessage)
      })
    });
  }
}