//=============================================================================================
// インポート
//=============================================================================================
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Component, Injectable, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import * as qs from 'qs';
import * as dayjs from 'dayjs';
import * as CONST from '../constants/constant';
import * as moment from 'moment';

// interface
import { common } from '../interfaces/common';
import { parameter } from '../interfaces/parameter';
import { request } from '../interfaces/request';
import { ExpService, ExpResourceAvailable, Shop, ExpReservation, ExpRecalc, ShopCalendar, SgServiceOption, ExpFetchReservation, Settings } from '../interfaces/response';

// component
import { ExpDetailOptionDialog } from '../components/exp/exp-dialog/exp-detail-option-dialog.component';

// service
import { MunicipalityWebApiService } from './municipality-web-api.service';

// module
import { CommonFunctionModule } from '../lib-modules/common-function.module';

// parts
import { ListParts } from '../components/parts/ons-list/ons-list.component';
import { MatDialog } from '@angular/material/dialog';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * ServiceConfig
 *
 * @export
 * @class ExpWebApiServiceConfig
 */
export class ExpWebApiServiceConfig {
  /**
   * Web API の基底 URL。
   *
   * @type {string}
   * @memberof ExpWebApiServiceConfig
   */
   baseUrl: string;

   /**
    * HTTP リクエストと一緒に送信されるオプション。
    *
    * @type {({
    *     headers?: HttpHeaders | {
    *       [header: string]: string | string[];
    *     };
    *   })}
    * @memberof ExpWebApiServiceConfig
    */
   httpOptions?: {
     /**
      * HTTP ヘッダー。
      *
      * @type {(HttpHeaders | {
      *       [header: string]: string | string[];
      *     })}
      */
     headers?: HttpHeaders | 
     {
       [header: string]: string | string[];
     };
   };
}

/**
 * 宅配サービス
 *
 * @export
 * @class ExpWebApiService
 */
@Injectable({
  providedIn: 'root'
})
export class ExpWebApiService {

  methodGetShop: () => void;

  /**
   * private
   * モビリティサービス共通 利用開始日時
   * 
   * @type {string}
   * @memberof ExpWebApiService
   */
  private _mobilityStartDate: string = "";

  /**
   * モビリティ共通 利用開始日時
   *
   * @type {string}
   * @memberof ExpWebApiService
   */
  get mobilityStartDate(): string {
    return this._mobilityStartDate;
  }

  /**
   * Creates an instance of ExpWebApiService.
   * @param {HttpClient} http
   * @param {ExpWebApiServiceConfig} config
   * @memberof ExpWebApiService
   */
  constructor(
    private http: HttpClient, 
    private config: ExpWebApiServiceConfig,
    private commonFunc: CommonFunctionModule,
    private dialog: MatDialog,
  ) { }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * 予定・履歴用料金テーブルテンプレートを作成する。
   *
   * @param {ExpReservation} reserv
   * @param {ExpRecalc} recalc
   * @return {*}  {ListParts['payment_amount']}
   * @memberof ExpWebApiService
   */
  createTemplatePrice(reserv: ExpReservation, recalc: ExpRecalc): ListParts['payment_amount'] {

    // 料金計算
    let paymentRow: ListParts['payment_amount']['row'] = [];
    let totale_amount: number = 0;

    // parts:ons-listに渡すためのデータ作成
    // if (reserv.service.service_type === 'MOBILITY') {

    recalc.tickets.forEach(r => {
      // 種別名を取得
      const typeName = reserv.numbers.find(n => r.ticket_type === n.type)?.name;

      totale_amount += r.amount;

      const result = paymentRow.find(f => {
        if ((f.type + f.amount_org) == (reserv.numbers.length >= 2 ? r.ticket_name + r.amount : r.ticket_name + r.amount)) {
          // 料金、個数を加算
          f.amount += r.amount;
          f.count++;
          return true;
        }
        return false;
      });
      if (result) return;

      paymentRow.push({
        item: r.service_name, 
        type: reserv.numbers.length >= 2 ? r.ticket_name : r.ticket_name, 
        count: 1, 
        amount: r.amount, 
        amount_org: r.amount, 
        unit: r.unit
      });
    });
    // }
    // else if (reserv.service.service_type === 'ACTIVITY') {
    // }
    // else ;

    // 成形
    paymentRow.forEach(p => p.type = p.type.replace("\xA5", "￥"));
    return { row: paymentRow, total_amount: totale_amount, payer_name: reserv.payer.name };
  }

  /**
   * GoogleMapへのリンクを作成する。
   *
   * @param {ExpPlace} place
   * @return {*}  {string}
   * @memberof ExpWebApiService
   */
  // getGoogleUrl(location?: common.Location | string): string {

  //   // googleMap遷移用URL
  //   let googleMapUrl: string;

  //   if ((<common.Location>location)?.lat !== undefined) googleMapUrl = "https://www.google.com/maps/search/?api=1&query=" + (<common.Location>location).lat + "," + (<common.Location>location).lng + "&z=20";
  //   else googleMapUrl = "https://www.google.com/maps/search/?api=1&query=" + encodeURI(location as string) + "&z=20";

  //   return googleMapUrl;
  // }

  /**
   * 基準料金などの計算に使用する料金タイプ取得
   *
   * @param {ExpService["user_params"]["numbers"]} numbers
   * @return {*}  {number}
   * @memberof ExpWebApiService
   */
  getDefaultType(numbers: ExpService["user_params"]["numbers"]): number {
    return numbers.find(number => number.default === true).type;
  }

  /**
   * 料金計算を行う。
   *
   * @param {GetPrice} priceInfo
   * @return {*}  {number}
   * @memberof ExpWebApiService
   */
  getPrice(priceInfo: GetPrice): number {
    const func: string = priceInfo.price_rules.find(rule => rule.type === priceInfo.type).func;

    return this.commonFunc.evalNum(func, priceInfo.params);
  }

  /**
   * キャンセルルールを表示する形式に変換し取得。
   *
   * @param {ExpService['prices']['cancel_rules']} cancelRules
   * @return {*}  {string}
   * @memberof ExpWebApiService
   */
  getDispCancelRules(cancelRules: ExpService['prices'][number]['cancel_rules']): string {
    if (cancelRules) {
      if (!cancelRules[1]&&cancelRules[0]?.price.percent==0) {
        // キャンセルルールが1つ&&percent==0 → 固定メッセージ
        return "～当日：無料";
      }

      return cancelRules.sort((r1, r2) => {  // 念の為、time昇順で並び替え(予約日直近のキャンセルルールから始まる)
        if (r1.time.unit == r2.time.unit) return r1.time.number - r2.time.number;
        else {
          const rate = (unit: 'd'|'h'|'m'): number => {
            switch (unit) {
              case 'd': return 24*60;
              case 'h': return 24;
              case 'm': return 1;
            }
          };
          return r1.time.number*rate(r1.time.unit) - r2.time.number*rate(r2.time.unit);
        }
      }).map((x, i, self) => {
        /** mapの返り値 */
        let str = "";
        // 期間
        if (i==0) {
          str += `${x.time.number}${x.time.unit=='d'?"日":x.time.unit=='h'?"時間":"分"}前${x.time.number==0&&x.time.unit=='d'?"":"～当日"}`;
        }
        else {
          str += `${x.time.number}${x.time.unit=='d'?"日":x.time.unit=='h'?"時間":"分"}前`;

          const prev = self[i-1];
          if ((x.time.number!=prev.time.number+1 && prev.time.unit=='d' && x.time.unit=='d')||prev.time.unit!='d') {
            str += `～${prev.time.number+1}${prev.time.unit=='d'?"日":prev.time.unit=='h'?"時間":"分"}前`;
          }
        }

        str += "：";

        // キャンセル料
        if (x.price.amount!=void 0) {
          str += `${this.commonFunc.numericalForming(x.price.amount)}`;
        }
        else if (x.price.percent!=void 0) {
          str += `料金の${x.price.percent}%`;
        }

        return str;
      }).join('\n').replace(/0日前/g, "当日").replace(/1日前/g, "前日");
    }
    else return "ー";
  }

  /**
   * 料金の再計算を行い、再計算結果を返却する。
   *
   * @param {ExpReservation} reserv
   * @return {*}  {ListParts['payment_amount']['row']}
   * @memberof ExpWebApiService
   */
  recalc(reserv: ExpReservation): ExpRecalc {
    
    const user_params = {
      numbers: reserv.numbers, 
      rental_place: reserv.start.place ? reserv.start.place : undefined, 
      return_place: reserv.end.place ? reserv.end.place : undefined, 
      place: undefined, //reserv.end.place ? undefined : reserv.start.place, 
      end_time: reserv.end.schd_time, 
      as: reserv.cancel_by
    };

    // 料金計算
    const result: ExpRecalc = this.commonFunc.evalFunc(this.commonFunc.deepcopy(reserv).recalc_func, { user_params: user_params });
    result.tickets.forEach(t => t.ticket_name = t.ticket_name.replace("￥", "\xA5"));

    return result;
  }

  /**
   * 料金詳細説明ダイアログ用、文字列取得
   *
   * @param {ExpService} service
   * @param {number} utilTime
   * @return {*}  {string}
   * @memberof ExpWebApiService
   */
  getPriceDescription(numbers: ExpService["user_params"]["numbers"], priceRule: ExpService["prices"][number], utilTime: number): string {
    // ダイアログ内に表示するtemplate
    let dialog: string = '<div style="font-size: var(--font-size-medium);">料金詳細説明<br><table border="1"><thead><tr><th class="table__diagonal-line"></th><th>対象</th><th>料金</th></tr></thead><tbody>';
    
    for (let num of numbers) {

      // 料金計算
      const price: number = this.getPrice({ 
        price_rules: priceRule.price_rules, 
        params: { number: 1, util_time: utilTime }, 
        type: num.type
      });

      // データが空の場合、ダイアログ内表のセルを空表示に
      let name: string = num.name;
      let description: string = num.description;
      let unit: string = num.unit;
      if (!name) name = "";
      if (!description) description = "";
      if (!unit) unit = "";

      dialog = dialog + '<tr><td>' + name + '</td><td>' + description + '</td><td>￥' + price + '/' + unit + '</td></tr>';
    }
    dialog = dialog + '</tbody></table></div>';

    return dialog;
  }

  /**
   * 利用数データを表示する形に変換
   *
   * @param {{name: string, value: number, unit: string}[]} numbers 利用数が0の料金種別も含める
   * @return {*}  {string}
   * @memberof ExpWebApiService
   */
  getDispNumber(numbers: {name: string, value: number, unit: string}[]): string {

    // 利用数合計
    const totalNumber = numbers.reduce((sum, element) => {
      return sum + element.value;
    }, 0);
    
    // TODO: サービス内料金種別に、単位が異なるものが存在する場合対応が必要
    // 表示する利用数
    let dispNumber: string =  totalNumber + numbers[0].unit;

    // サービス料金種別が2種類以上の場合、人数の内訳を表示
    if (numbers.length > 1) {
      numbers.forEach((number, index) => {

        if (index == 0) dispNumber = dispNumber + ' (';

        // 利用数が1以上の場合のみ人数の内訳に対象の料金種別を表示
        if (number.value >= 1) dispNumber = dispNumber + number.name + '：' + number.value + number.unit + '、';

        if (index == numbers.length - 1) {
          dispNumber = dispNumber.slice(0, -1) + ')';
        }
      })
    }

    return dispNumber;
  }

  /**
   * サービスの最低料金計算に必要な、選択可能な最小時間を取得
   * 店舗管理体験サービス一覧画面、検索体験サービス一覧画面で使用
   *
   * @param {ExpService} service
   * @return {*}  {number}
   * @memberof ExpWebApiService
   */
  getMinimumTime(service: ExpService): number {

    // サービスの最低時間
    let minimumTime: number;
    // util_timeがないサービスの場合、price.unitを最低時間とする
    if (service.schedule_type == 1) minimumTime = service.time_step;
    // util_timeがあるサービスの場合、user_params.util_time.selectの最小値を最低時間とする
    else if (service.schedule_type == 3) minimumTime = service.user_params.util_time.select.reduce((a, b) => Math.min(a, b));
    // 対応外
    else {}
    
    return minimumTime;
  }

  /**
   * 指定した日付に合う料金情報を取得
   *
   * @param {ExpService["prices"]} prices
   * @param {moment.Moment} date
   * @return {*}  {ExpService["prices"][number]}
   * @memberof ExpWebApiService
   */
  getTargetPrice(prices: ExpService["prices"], date: moment.Moment): ExpService["prices"][number] {
    return prices.find(price => date.isSameOrAfter(price.start_date) && date.isSameOrBefore(price.end_date));
  }

  /**
   * 指定した日付に合うオプション料金情報を取得
   *
   * @param {SgServiceOption["prices"]} prices
   * @param {moment.Moment} date
   * @return {*}  {SgServiceOption["prices"][number]}
   * @memberof ExpWebApiService
   */
  getTargetOptionPrice(prices: SgServiceOption["prices"], date: moment.Moment): SgServiceOption["prices"][number] {
    return prices.find(price => date.isSameOrAfter(price.start_date) && date.isSameOrBefore(price.end_date));
  }

  /**
   * 予約キャンセルが可能かどうか
   *
   * @param {ExpReservation} reserv
   * @param {boolean} time_check キャンセル期限をチェックするか
   * @return {*}  {boolean}
   * @memberof ExpWebApiService
   */
  isServiceCancel(reserv: ExpReservation, time_check: boolean): boolean {

    // 利用前まではユーザキャンセル可能
    if (reserv.status === 'INIT') {

      // キャンセル期限もチェック
      if (time_check) {
        // 作成時点ではキャンセル期限はチケットで一律同一
        if (new Date().getTime() < new Date(reserv.start.schd_time).getTime()) return true;
        else ;
      }
      else return true;
    }

    return false;
  }

  /**
   * 制約を判定する。
   *
   * @param {ExpService["constraints"]} constraints
   * @param {number} num
   * @return {*}  {boolean}
   * @memberof ExpWebApiService
   */
  isMinNumberCheck(constraints: ExpService["constraints"], num: number): boolean {

    // 人数の制約チェック
    let result: boolean[] = [];
    constraints.forEach(c => {
      const formula = c.if.replace("sum", num.toString());
      result.push(Function(`return (${formula})`)());
    });

    // 判定結果を返却
    return !(result.every(r => r === true));
  }

  /**
   * モビリティ共通 利用開始日時を設定
   *
   * @memberof ExpWebApiService
   */
  set mobilityStartDate(date: string) {
    // 日付でない場合、処理を抜ける
    if (moment(date).isValid() === false) {
      console.error(`無効な日付: ${date}`);
      return;
    }
  
    // 整形
    const formatted_date: string = moment(date).format(moment.HTML5_FMT.DATETIME_LOCAL);
    // 利用開始日時変更
    this._mobilityStartDate = formatted_date;
  }

  /**
   * オプション詳細ダイアログ
   *
   * @param {ExpService["option_groups"][number]["options"][number]} option
   * @memberof ExpWebApiService
   */
  openOptionDialog(option: ExpService["option_groups"][number]["options"][number]): void {
    this.dialog.open(ExpDetailOptionDialog, {
      data: {
        images: option.images,
        optionName: option.name,
        description: this.commonFunc.replaceUrl(this.commonFunc.replaceEscapeSequence(option.description))
      }
    });
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /*
    店舗
  */

  /**
   * 店舗を検索する。
   *
   * @param {number} shop_id
   * @param {boolean} [have_exp_service=true]
   * @return {*}  {Observable<HttpResponse<Shop[]>>}
   * @memberof ExpWebApiService
   */
  getShop(shop_id: number, have_exp_service: boolean = true): Observable<HttpResponse<Shop[]>> {
    
    const param = {shop_id: shop_id, have_exp_service: have_exp_service};
    
    return this.http.get<Shop[]>(`${ this.config.baseUrl }/shop?${ qs.stringify(param) }`, {
      ...this.config.httpOptions, 
      observe: 'response', 
      withCredentials: true
    });
  }

  /**
   * 提供中体験サービスがある店舗を検索する。
   *
   * @param {boolean} [have_exp_service=true]
   * @return {*}  {Observable<HttpResponse<Shop[]>>}
   * @memberof ExpWebApiService
   */
  getShopList(have_exp_service: boolean = true): Observable<HttpResponse<Shop[]>> {
    
    const param = {have_exp_service: have_exp_service};
    
    return this.http.get<Shop[]>(`${ this.config.baseUrl }/shop?${ qs.stringify(param) }`, {
      ...this.config.httpOptions, 
      observe: 'response', 
      withCredentials: true
    });
  }

  /**
   * 店舗の営業時間一覧を取得する。
   *
   * @param {number} shop_id
   * @param {string} [from]
   * @param {string} [to]
   * @return {*}  {Observable<HttpResponse<ShopCalendar[]>>}
   * @memberof ExpWebApiService
   */
  getShopCalendar(shop_id: number, from?: string, to?: string): Observable<HttpResponse<ShopCalendar[]>> {
    
    const param = { from: from, to: to };

    // todo calenderの綴り　最後がarが正
    return this.http.get<ShopCalendar[]>(`${ this.config.baseUrl }/shop/${ shop_id }/calender?${ qs.stringify(param) }`, {
      ...this.config.httpOptions, 
      observe: 'response', 
      withCredentials: true
    });
  }

  /*
    体験サービス
  */

  /**
   * 体験サービス一覧を取得する。
   *
   * @param {parameter.ExpService} param
   * @return {*}  {Observable<HttpResponse<ExpService[]>>}
   * @memberof ExpWebApiService
   */
  getServiceList(param: parameter.ExpService): Observable<HttpResponse<ExpService[]>> {

    return this.http.get<ExpService[]>(`${ this.config.baseUrl }/exp/service?${ qs.stringify(param) }`, {
      ...this.config.httpOptions, 
      observe: 'response', 
      withCredentials: true
    });
  }

  /**
   * 体験サービスを取得する。
   *
   * @param {{ sg_service_id: number, reserved_date?: string }} params
   * @return {*}  {Observable<HttpResponse<ExpService>>}
   * @memberof ExpWebApiService
   */
  getService(params: { sg_service_id: number, reserved_date?: string } ): Observable<HttpResponse<ExpService>> {
    
    return this.http.get<ExpService>(`${ this.config.baseUrl }/exp/service/${ params.sg_service_id }?${ qs.stringify(params.reserved_date) }`, {
      ...this.config.httpOptions, 
      observe: 'response', 
      withCredentials: true
    });
  }

  /**
   * 体験サービスの予約可能数を取得する。
   *
   * @param {parameter.ExpAvailable} param
   * @return {*}  {Observable<HttpResponse<ExpAvailable[]>>}
   * @memberof ExpWebApiService
   */
  getAvailable(param: parameter.ExpAvailable): Observable<HttpResponse<ExpResourceAvailable>> {
    
    return this.http.get<ExpResourceAvailable>(`${ this.config.baseUrl }/exp/service/${ param.sg_service_id }/available?${ qs.stringify(param) }`, {
      ...this.config.httpOptions, 
      observe: 'response', 
      withCredentials: true
    });
  }

  /**
   * 体験サービス（アクティビティ）の予約可能数を取得する。
   *
   * @param {parameter.ExpAvailable} param
   * @return {*}  {Observable<HttpResponse<ExpAvailable[]>>}
   * @memberof ExpWebApiService
   */
  getResourceAvailable(param: parameter.ExpAvailable): Observable<HttpResponse<ExpResourceAvailable>> {
    
    return this.http.get<ExpResourceAvailable>(`${ this.config.baseUrl }/exp/service/${ param.sg_service_id }/resource-available?${ qs.stringify(param) }`, {
      ...this.config.httpOptions, 
      observe: 'response', 
      withCredentials: true
    });
  }

  /**
   * 体験サービスの予約一覧を取得する。
   *
   * @param {parameter.ExpReservation} param
   * @return {*}  {Observable<HttpResponse<ExpReservation[]>>}
   * @memberof ExpWebApiService
   */
  getReservation(param: parameter.ExpReservation): Observable<HttpResponse<ExpReservation[]>> {

    return this.http.get<ExpReservation[]>(`${ this.config.baseUrl }/exp/reservation?${ qs.stringify(param) }`, {
      ...this.config.httpOptions, 
      observe: 'response', 
      withCredentials: true
    });
  }

  /**
   * sg_reservation_idで指定した体験サービス予約情報を取得する
   *
   * @param {number} sg_reservation_id
   * @return {*}  {Observable<HttpResponse<ExpReservation>>}
   * @memberof ExpWebApiService
   */
  getTargetReservation(sg_reservation_id: number): Observable<HttpResponse<ExpReservation>> {

    return this.http.get<ExpReservation>(`${ this.config.baseUrl }/exp/reservation/${ sg_reservation_id }`, {
      ...this.config.httpOptions, 
      observe: 'response', 
      withCredentials: true
    })
  }

  /**
   * 体験サービスの新規予約fetch
   *
   * @param {request.ExpReservation} reqBody
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof ExpWebApiService
   */
  postFetchReservation(reqBody: request.ExpReservation): Observable<HttpResponse<any>> {
    
    return this.http.post(`${ this.config.baseUrl }/exp/reservation/fetch`, reqBody, {
      ...this.config.httpOptions, 
      observe: 'response', 
      withCredentials: true
    })
  }

  /**
   * 体験サービスを予約する。
   *
   * @param {request.ExpReservation} reqBody
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof ExpWebApiService
   */
  postReservation(sg_reservation_plan_id: string): Observable<HttpResponse<any>> {

    return this.http.post(`${ this.config.baseUrl }/exp/reservation`, {sg_reservation_plan_id: sg_reservation_plan_id}, {
      ...this.config.httpOptions, 
      observe: 'response', 
      withCredentials: true
    });
  }

  /**
   * 体験サービスの予約変更fetch
   *
   * @param {number} sg_reservation_id
   * @param {request.ExpReservation} reqBody
   * @return {*} 
   * @memberof ExpWebApiService
   */
  putFetchReservation(sg_reservation_id : number, reqBody: request.ExpReservation): Observable<HttpResponse<any>> {
    
    return this.http.put(`${this.config.baseUrl}/exp/reservation/${ sg_reservation_id }/fetch`, reqBody, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }

  /**
   * 体験サービスの予約を変更する。
   *
   * @param {number} sg_reservation_id 予約ID
   * @param {string} sg_reservation_plan_id 予約プランID
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof ExpWebApiService
   */
  putReservation(sg_reservation_id: number, sg_reservation_plan_id: string): Observable<HttpResponse<any>> {

    return this.http.put(`${this.config.baseUrl}/exp/reservation/${ sg_reservation_id }`, {sg_reservation_plan_id: sg_reservation_plan_id}, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }

  /**
   * 体験サービスの予約キャンセルfetch
   *
   * @template T
   * @param {number} sg_reservation_id
   * @return {*} 
   * @memberof ExpWebApiService
   */
  deleteFetchReservation(sg_reservation_id: number): Observable<HttpResponse<ExpFetchReservation>> {
    return this.http.delete<ExpFetchReservation>(`${ this.config.baseUrl }/exp/reservation/${ sg_reservation_id }/fetch?${ qs.stringify({as: 'user'}) }`, {
      ...this.config.httpOptions, 
      observe: 'response',
      withCredentials: true
    });
  }

  /**
   * 体験サービスの予約をキャンセルする。
   *
   * @template T
   * @param {parameter.ExpReservation} param
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof ExpWebApiService
   */
  deleteReservation<T>(sg_reservation_id: number, sg_reservation_plan_id: string): Observable<HttpResponse<any>> {
    return this.http.delete<T>(`${ this.config.baseUrl }/exp/reservation/${ sg_reservation_id }?${ qs.stringify({sg_reservation_plan_id: sg_reservation_plan_id}) }`, {
      ...this.config.httpOptions, 
      observe: 'response',
      withCredentials: true
    });
  }
}

/**
 * 料金計算に必要なデータ
 *
 * @export
 * @interface GetPrice
 */
export interface GetPrice {

  /**
   * サービスのprice_rulesプロパティ
   *
   * @type {ExpPrice[]}
   * @memberof GetPrice
   */
  price_rules: ExpService['prices'][number]['price_rules'];
  
  /**
   * 料金タイプ
   *
   * @type {number}
   * @memberof GetPrice
   */
  type: number;
  
  /**
   * 料金計算の変数
   * 現状は、util_timeとnumberのみ
   *
   * @type {{[param: string]: any}}
   * @memberof GetPrice
   */
  params: {[param: string]: any};
}


/**
 * 予約変更時、オプション情報管理
 *
 * @export
 * @class ReservationOption
 */
export class ReservationOption {

  private reservation: ExpReservation;

  optionGroups: ExpService["option_groups"];

  selectedOption: {[option_id: string]: UserSelectOption} = {};

  private commonFunc: CommonFunctionModule;

  /**
   * 設定情報(体験サービス)
   *
   * @type {Settings["exp"]}
   * @memberof ReservationOption
   */
  settingExp: Settings["exp"];


  constructor(
    _reservation: ExpReservation,
    _settingExp: Settings["exp"]
  ){
    this.reservation = _reservation
    this.settingExp = _settingExp;

    this.sortOption();
    this.createselectedOption();

    console.log(this.selectedOption);
  }

  /**
   * オプションを表示順にソート
   *
   * @return {*}  {ExpService["option_groups"]}
   * @memberof ReservationOption
   */
  private sortOption(): void {
    // オプショングループ順ソート
    this.optionGroups = this.reservation.service.option_groups.sort((a, b) => {
      return a.index - b.index;
    });

    // グループ一つ目からさらにグループ内ソート
    this.optionGroups.forEach(group => {
      group.options.sort((a, b) => {
        return a.index - b.index;
      });
    });

    // return this.optionGroups;
  }

  /**
   * ユーザー選択情報を保存する変数作成
   *
   * @private
   * @memberof ReservationOption
   */
  private createselectedOption(): void {

    // オプションテンプレート作成
    // 新規予約時に受付期間外のオプションは除外
    this.optionGroups = this.optionGroups.filter(group => {
      group.options = group.options.filter(option => {
        
        let userSelect: UserSelectOption = {
          sg_option_price_id: this.reservation.service_options.find(item => item.sg_option_id === option.sg_option_id).sg_option_price_id,
        }

        const selectedOption: ExpReservation["service_options"][number] = this.reservation.service_options.find(item => item.sg_option_id === option.sg_option_id && item.type == 'usage');
        if (selectedOption === void 0) return false;

        switch (option.user_option) {
          case 'yesno':
            userSelect.yesno_param = option.yesno_param.items.find(item => item.value === selectedOption.user_option.yesno);
            break;
          case 'select':
            userSelect.select_param = option.select_param.items.find(item => item.value === selectedOption.user_option.select);
            break;
          case 'number':
            let number: UserSelectOption["number_param"] = JSON.parse(JSON.stringify(option.number_param));
            number.selected = selectedOption.user_option.number;
            // セレクトボックス内容を指定されている場合
            if (!number.select) {
              number.select = [];
              // from, to, stepを元にセレクトボックス配列作成
              for (let num = number.from; num <= number.to; num += number.step) {
                number.select.push(num);
              }
            }
            // 初期値
            userSelect.number_param = number;

            break;
          case 'comment':
            userSelect.comment = selectedOption.user_option.comment;
            break;
        }

        // 個数決定タイプを選択する必要ありの場合、利用日数or時間のセレクトボックス配列作成
        if (option.time_rule === 'days' || option.time_rule === 'mins') {

          userSelect.time_param = option.time_param;
          userSelect.time_param.select = [];

          // NOTE: 個数決定タイプごとの上限までセレクトボックスを作成。
          //        チェック関数でサービスとしての最大値チェックは実施(設定されている場合のみ)。
          // from, to, stepを元にセレクトボックス配列作成
          const to: number = Math.min(Math.floor(this.settingExp.service.max_reserve_hour/24)+1, userSelect.time_param.to);
          for (let num = userSelect.time_param.from; num <= to; num += userSelect.time_param.step) {
            if (num === 0) continue;
            userSelect.time_param.select.push(num);
          }
          const timeValue: number = option.time_rule==='days' ? selectedOption.time_option.days : selectedOption.time_option.mins;
          // 初期値
          userSelect.time_param.selected = timeValue===0 ? userSelect.time_param.select[0] : timeValue;
        }
        // エラー
        userSelect.err = {
          message: "",
          check: false,
        }
        userSelect.time_err = {
          message: "",
          check: false,
        }

        this.selectedOption[option.sg_option_id] = JSON.parse(JSON.stringify(userSelect));
        return true;
      })
      console.log(this.selectedOption);
      return group.options.length === 0 ? false : true;
    })
    // return this.selectedOption;

    console.log(this.selectedOption);
  }

  /**
   * 指定した日付に合うオプション料金情報を取得
   *
   * @param {SgServiceOption["prices"]} prices
   * @param {moment.Moment} date
   * @return {*}  {SgServiceOption["prices"][number]}
   * @memberof ExpWebApiService
   */
  getTargetOptionPrice(prices: SgServiceOption["prices"], date: moment.Moment): SgServiceOption["prices"][number] {
    return prices.find(price => date.isSameOrAfter(price.start_date) && date.isSameOrBefore(price.end_date));
  }

  /**
   * オプション変更(user_option: select)
   *
   * @param {SgServiceOption} option
   * @param {SgServiceOption["yesno_param"]["items"][number]} item
   * @memberof ReservationOption
   */
  changeOptionYesno(option: SgServiceOption, item: SgServiceOption["yesno_param"]["items"][number]): void {
    this.selectedOption[option.sg_option_id+""].yesno_param = JSON.parse(JSON.stringify(item));
    this.checkTimeOption(option);
    console.log(this.selectedOption);
  }

  /**
   * オプション変更(user_option: select)
   *
   * @param {SgServiceOption} option
   * @param {SgServiceOption["select_param"]["items"][number]} item
   * @memberof ReservationOption
   */
  changeOptionSelect(option: SgServiceOption, item: SgServiceOption["select_param"]["items"][number]): void {
    this.selectedOption[option.sg_option_id+""].select_param = JSON.parse(JSON.stringify(item));
  }

  /**
   * オプション変更(user_option: number)
   *
   * @param {SgServiceOption} option
   * @param {number} value
   * @memberof ReservationOption
   */
  changeOptionNumber(option: SgServiceOption, value: number): void {
    this.selectedOption[option.sg_option_id+""].number_param.selected = JSON.parse(JSON.stringify(value));
  }

  /**
   * 日数選択型オプション 変更
   *
   * @param {SgServiceOption} option
   * @param {number} item
   * @memberof ReservationOption
   */
  changeOptionTime(option: SgServiceOption, item: number) {

    this.selectedOption[option.sg_option_id+""].time_param.selected = JSON.parse(JSON.stringify(item));
    this.checkTimeOption(option);
  }

  /**
   * 日数選択型オプション、チェック
   *
   * @private
   * @param {SgServiceOption} option
   * @return {*}  {void}
   * @memberof ReservationOption
   */
  private checkTimeOption(option: SgServiceOption): void {
  
    this.selectedOption[option.sg_option_id].err = {
      message: "",
      check: false
    }

    const targetOption: UserSelectOption = this.selectedOption[option.sg_option_id];

    // 時間指定なし(none)の場合、処理を抜ける
    if (option.time_rule === 'none') return;
    // 日数選択型オプション、未選択の場合、処理を抜ける
    if (!targetOption.time_param.selected) return;
    // 開始終了時間が未選択の場合、処理を抜ける
    // if (!this.expBill.start.date || !this.expBill.start.time.view
    //     || !this.expBill.end.date || !this.expBill.end.time.view) return;

    // 日数選択型オプションが表示されていない場合、処理を抜ける
    switch (option.user_option) {
      case 'yesno':
        if (targetOption.yesno_param.value === false) return;
        break;
      case 'select':
        if (targetOption.select_param.value === void 0) return;
        break;
      case 'number':
      case 'comment':
        break;
    }

    /**
     * 日数選択型オプション、選択中時間
     */
    const selected: number = targetOption.time_param.selected;

    if (option.time_rule === 'days') {
      // 開始終了日時をmoment型に設定
      const start_time = moment(this.reservation.start.schd_time);
      const end_time = moment(this.reservation.end.schd_time);
      
      // NOTE: 利用日数は、含まれている日数(例：3日22時～4日2時⇒2日)
      // 開始終了日時、所要時間から利用日数取得
      const start = start_time.startOf('day');
      const end = end_time.subtract(1, 'seconds').add(1,'day').startOf('day');
      let dateLimit: number = Math.min(end.diff(start, 'days'), Math.min(Math.floor(this.settingExp.service.max_reserve_hour/24)+1));

      if (option.time_param.to) dateLimit = Math.min(dateLimit, option.time_param.to);
    
      // テーブル型の場合、料金テーブル(value)最大値と比較し、小さい方を上限
      const targetPriceRule: SgServiceOption["prices"][number] = option.prices.find(price => price.sg_option_price_id === this.selectedOption[option.sg_option_id].sg_option_price_id);
      if (targetPriceRule.func === 'table') {
        if (option.user_option === 'select' && targetOption.select_param.value === void 0) return;
        
        if (option.user_option === 'select' && targetOption.select_param.value !== void 0) {
          dateLimit = Math.min(dateLimit, targetPriceRule.select.find(item => item.value === targetOption.select_param.value).rule.table.slice(-1)[0].value);
        }
        else dateLimit = Math.min(dateLimit, targetPriceRule.rule.table.slice(-1)[0].value);
      }

      if (selected > dateLimit) {
        this.selectedOption[option.sg_option_id].err = {
          message: "選択可能な最大日数を超えています。選択しなおしてください。",
          check: true
        }
      }
    }
  }
  
  /**
   * オプションに変更があるかチェック
   *
   * @return {*}  {boolean}
   * @memberof ReservationOption
   */
  checkChangeOption(): boolean {
    const isChangeOption: boolean = this.reservation.service.option_groups.some(group => {
      const result = group.options.some(option => {
        const target: ExpReservation["service_options"][number] = this.reservation.service_options.find(reservationOption => reservationOption.sg_option_id === option.sg_option_id);

        switch(option.user_option) {
          case 'yesno':
            if (target.user_option.yesno !== this.selectedOption[option.sg_option_id].yesno_param.value) return true;
            break;
          case 'select':
            if (target.user_option.select !== this.selectedOption[option.sg_option_id].select_param.value) return true;
            break;
          case 'number':
            if (target.user_option.number !== this.selectedOption[option.sg_option_id].number_param.selected) return true;
            break;
          case 'comment':
            if (target.user_option.comment !== this.selectedOption[option.sg_option_id].comment) return true;
            break;
        }

        if (option.user_option !== 'yesno' || (option.user_option === 'yesno' && this.selectedOption[option.sg_option_id].yesno_param.value === false)) return false;

        switch (option.time_rule) {
          case  'days':
            if (target.time_option.days !== this.selectedOption[option.sg_option_id].time_param.selected) return true;
        }
        return false;
      })

      return result; 
    })

    return isChangeOption;
  }

  /**
   * fetch用オプション情報作成
   *
   * @return {*}  {request.ExpReservation["service_options"]}
   * @memberof ReservationOption
   */
  createFetchOption(): request.ExpReservation["service_options"] {

    let optionBody: request.ExpReservation["service_options"] = [];
    
    // 開始終了日時をmoment型に設定
    this.reservation.service.option_groups.forEach(group => {
      group.options.forEach(option => {
        const targetBill: UserSelectOption = this.selectedOption[option.sg_option_id];
        let optionItem: request.ExpReservation["service_options"][number] = {
          sg_option_id: option.sg_option_id,
          sg_option_price_id: targetBill.sg_option_price_id
        }

        if (option.user_option) optionItem.user_option = {};
        switch(option.user_option) {
          case 'comment':
            optionItem.user_option.comment = targetBill.comment;
            break;
          case 'yesno':
            optionItem.user_option.yesno = targetBill.yesno_param.value;
            break;
          case 'select':
            optionItem.user_option.select = targetBill.select_param.value;
            break;
          case 'number':
            optionItem.user_option.number = targetBill.number_param.selected;
            break;
          default:
            console.log("想定外");
        }

        // 時間選択
        if (option.time_rule !== 'none') optionItem.time_option = {};
        switch(option.time_rule) {
          case 'days':
            // タイプ選択型オプション&「希望しない」を選択時、daysを0に
            if (option.user_option === 'select' && targetBill.select_param.value === void 0) optionItem.time_option.days = 0;
            else optionItem.time_option.days = targetBill.time_param.selected;
            break;
          // case 'mins':
          //   targetOption.time_option.min = target.time_param.selected;
          //   break;
        }

        optionBody.push(optionItem);
      })
    })
    return optionBody;
  }
}


/**
 * ユーザ選択情報
 *
 * @export
 * @interface UserSelectOption
 */
export interface UserSelectOption {
  sg_option_price_id?: number;
  comment?: string;
  yesno_param?: SgServiceOption["yesno_param"]["items"][number];
  select_param?: SgServiceOption["select_param"]["items"][number];
  number_param?: SgServiceOption["number_param"];
  time_param?: SgServiceOption["time_param"];
  err?: {
    check: boolean;
    message: string;
  };
  time_err?: {
    check: boolean;
    message: string;
  };
}