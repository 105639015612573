<ons-toolbar>
  <div class="left">
    <ons-back-button></ons-back-button>
  </div>
  <div class="center">メニュー一覧</div>
</ons-toolbar>

<div class="content">
  <div [ngBusy]="busy"></div>

  <!-- 店舗名 -->
  <ons-list class="list-item__title list-header__title--sticky shop-info-wrap">
    <ons-list-item id="deliveryMenu-header_">
      <!-- 店舗アイコン/店舗名 -->
      <ons-row vertical-align="center">
        <ons-col width="120px" class="list-icon-col"><span *ngIf="shop.icon !== undefined && shop.icon !== ''; else noIcon"><img class="shop-info-wrap__img" [src]="shop.icon" /></span></ons-col>
        <ons-col>{{ shop.name }}</ons-col>
      </ons-row>
    </ons-list-item>
  </ons-list>
 
  <!-- メニュー一覧 -->
  <div class="list-item__caption">メニュー一覧</div>
  <ons-list>
    <ons-list-item id="shopping-menu-list_001" class="list-item__menu--height" *ngFor="let menu of shopMenus; index as i" modifier="chevron longdivider" tappable (click)="onMenuSelect(i)">  
      <ons-row vertical-align="center">
        <ons-col class="list-icon-col" width="120px">
          <span *ngIf="menu.images?.length > 0; else noImage"><img class="list-icon-col__img" [src]="menu.images[0]" /></span>
        </ons-col>
        <ons-col class="menu--detail">
          <ons-row vertical-align="top" class="menu--detail--name">
            <ons-col id="shopping-menu-list_002"><p>{{ menu.name }}</p></ons-col>
          </ons-row>
          <ons-row vertical-align="bottom" class="menu--detail--info">
            <ons-col><p>￥{{ menu.price | number:'.0-0' }}</p></ons-col>
          </ons-row>
        </ons-col>
      </ons-row>
    </ons-list-item>
  </ons-list>
  
  <div *ngIf="getCartItemCount() > 0" class="button-wrap button-sticky">
    <ons-button id="shopping-menu-list_003" modifier="large--cta" (click)="onViewCart()">カートを見る（{{ getCartItemCount() }}個）</ons-button>
  </div>
  
  <!-- template -->
  <!-- 店舗アイコン、メニュー画像がない場合、汎用アイコンを表示 -->
  <ng-template #noIcon><mat-icon>store</mat-icon></ng-template>
  <ng-template #noImage><mat-icon>menu_book</mat-icon></ng-template>
</div>
