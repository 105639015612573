<div [ngBusy]="busy"></div>
<div class="content">
  <!-- 固定ヘッダー -->
  <header class="search-area">
    <!-- お届け先 -->
    <div class="order-area">
      <div class="order-logo">
        <img class="spot-icon" [src]="ASSETS_ORDER_DESTINATION">
      </div>
      <div class="order-address">
        <div class="text-overflow">
          <ng-container *ngIf="getDeliveryAddress() !== '未設定'; else noAddress">
            {{ getDeliveryAddress() }}
          </ng-container>
        </div>
      </div>
      <div class="order-logo">
        <ons-button modifier="cta" (click)="changeDeliveryAddress()">変更</ons-button>
      </div>
    </div>

    <!-- 検索フォーム -->
    <div class="search-form">
      <mat-icon id="shopping_003">search</mat-icon>
      <input matInput #autoCompleteInput id="shoppingHistoryInput" type="text" placeholder="スペース区切りで複数検索" 
            [(ngModel)]="freeword" 
            (change)="onSearch()" 
            (click)="onSearchFocus()" 
            (blur)="onSearchBlur()" 
            [matAutocomplete]="auto">
      
      <!-- 検索履歴 -->
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSearchHistorySelect()">
        <div class="history__right">
          <button id="shopping_004" class="history__delete" mat-flat-button (click)="onDeleteSearchHistory($event, -1, '', true)">
            すべて削除
          </button>
        </div>
        <mat-option *ngFor="let history of searchHistory; index as i;" [value]="history">
          <div class="history__left">
            <mat-icon class="search-icon">search</mat-icon><span id="shopping-search-history_00{{i+1}}">{{ history }}</span>
          </div>
          <div class="history__right">
            <button id="shopping-search-history-delete_00{{i+1}}" mat-icon-button (click)="onDeleteSearchHistory($event, i, history)">
              <mat-icon class="search-icon">clear</mat-icon>
            </button>
          </div>
        </mat-option>
      </mat-autocomplete>
    </div>
  </header>

  <!-- 店舗一覧 -->
  <ons-list>
    <ng-container *ngFor="let shop of shops; index as i">
      <ons-list-item id="shopping-shop-wrap_00{{i+1}}" modifier="chevron longdivider" class="shop--list--height" tappable (click)="onShopSelect(shop)">
        <!-- 店舗アイコン/店舗名 -->
        <ons-row vertical-align="center">
          <ons-col class="list-icon-col" width="120px"><span *ngIf="shop.icon !== undefined && shop.icon !== ''; else noIcon"><img class="list-icon-col__img" [src]="shop.icon" /></span></ons-col>
          <ons-col><p id="shopping-shop-name_00{{i+1}}">{{ shop.name }}</p></ons-col>
        </ons-row>
      </ons-list-item>
    </ng-container>
    <ng-container *ngIf="shops?.length === 0">
      <div class="text--center" *ngIf="freeword !== undefined; else noShop">
        <p>検索条件にヒットするお店はありませんでした。</p>
        <parts-image class="complete-wrap__img" url="/image/character/notfound.png"></parts-image>
      </div>
    </ng-container>
  </ons-list>

  <!-- template -->
  <ng-template #noAddress>
    {{ getDeliveryAddress() }}
  </ng-template>
  <!-- 商品販売中の店舗がない -->
  <ng-template #noShop>
    <div class="text--center" *ngIf="shops !== undefined">
      <p>現在、商品販売中のお店はありません。<br>販売開始までしばらくお待ちください。</p>
      <parts-image class="complete-wrap__img" url="/image/character/out-of-service.png"></parts-image>
    </div>
  </ng-template>
  <!-- 店舗アイコンがない場合、汎用アイコンを表示 -->
  <ng-template #noIcon><mat-icon>store</mat-icon></ng-template>
</div>
