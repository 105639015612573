<div [ngBusy]="busy"></div>
<div class="content">

  <!-- 検索フォーム -->
  <parts-search-form [freeword]="freeword" target="exp" [limit]="limit" (searchService)="onSearch($event)"></parts-search-form>

  <!-- body -->
  <div class="list-item__caption">
    {{areaName}}で利用できる観光サービスの詳細情報を、<a (click)="onClickMobipaLink()" class="bold">こちらのHP</a>よりご覧になられます。
  </div>
  <div class="text--center">
    <a (click)="onClickMobipaLink()"><parts-image url="/image/exp/exp_top.png"></parts-image></a>
  </div>
  <span class="br"></span>

  <!-- モビリティ -->
  <ng-container *ngIf="hasMobilityService.elements.length > 0; else noMobility">
    <parts-ons-list [listInfo]="listHeader[0]"></parts-ons-list>
    <parts-swiper-images [inputData]="hasMobilityService" (imageClick)="onClickShop($event)"></parts-swiper-images>
  </ng-container>

  <!-- アクティビティ -->
  <ng-container *ngIf="hasActivityService.elements.length > 0">
    <parts-ons-list [listInfo]="listHeader[1]"></parts-ons-list>
    <parts-swiper-images [inputData]="hasActivityService" (imageClick)="onClickShop($event)"></parts-swiper-images>
  </ng-container>

  <span class="br"></span>
</div>

<!-- template -->
<ng-template #noMobility>
  <parts-ons-list [listInfo]="listHeader[0]"></parts-ons-list>
  <div class="text--center service--area">
    <p>現在、提供中のサービスはありません。<br>提供開始までしばらくお待ちください。</p>
    <parts-image class="complete-wrap__img" url="/image/character/out-of-service.png"></parts-image>
  </div>
</ng-template>