//=============================================================================================
// インポート
//=============================================================================================
import { AfterViewInit, Component, OnInit, OnDestroy, ViewChild, Directive } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { OnsNavigator, onsPlatform } from 'ngx-onsenui';
import { BehaviorSubject, firstValueFrom, forkJoin, Observable, Subject, Subscription } from 'rxjs';

import { environment } from '../environments/environment';

// service
import { ApplicationMessageService } from './lib-services/application-message.service';
import { HttpErrorResponseParserService } from './lib-services/http-error-response-parser.service';
import { PurchaseWebApiService } from './http-services/purchase-web-api.service';
import { UserWebApiService } from './http-services/user-web-api.service';
import { UserAgentService } from './http-services/user-agent.service';
import { DispatchWebApiService } from './http-services/dispatch-web-api.service';
import { ShoppingWebApiService } from './http-services/shopping-web-api.service';
import { SelectHistoryWebApiService } from './http-services/select-history-web-api.service';
import { ExpWebApiService } from './http-services/exp-web-api.service';
import { PageKey, PagerService } from './lib-services/pager.service';
import { MunicipalityWebApiService } from './http-services/municipality-web-api.service';

// component
import { SplitterComponent } from './components/splitter/splitter.component';
import { LoginComponent } from './components/user/login/login.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { MyqrComponent } from './components/myqr/myqr.component';
import { HistoryDetailComponent } from './components/history/dispatch/history-detail/history-detail.component';
import { PurchaseDetailsComponent } from './components/purchase/purchase-details/purchase-details.component';
import { PurchaseTicketOwnedComponent } from './components/purchase/purchase-ticket-owned/purchase-ticket-owned.component';
import { HistoryOrderDetailComponent } from './components/history/order/history-order-detail/history-order-detail.component';
import { HistoryExpDetailComponent } from './components/history/exp/history-exp-detail/history-exp-detail.component';
import { TermAgreeComponent } from './components/term-agree/term-agree.component';
import { AllMunicipalitiesComponent } from './components/all-municipalities/all-municipalities.component';

// interface
import { parameter } from './interfaces/parameter';
import { common } from './interfaces/common';
import { PossessionsTicket, Reservation, Ticket, Settings, ExpService, UserInfo } from './interfaces/response';
import { ExpSubjectParams, UseListTabTag } from './components/service/use-list/use-list.component';
import * as CONST from './constants/constant';

// module
import { CommonFunctionModule } from './lib-modules/common-function.module';
import * as moment from 'moment';
import { MESSAGE } from './constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * rootコンポーネント
   *
   * @memberof AppComponent
   */
  // root = SplitterComponent;

  /**
   * 通信用subscription
   *
   * @type {Subscription}
   * @memberof AppComponent
   */
  busy: Subscription;

  /**
   * ngBusyのPromiseオブジェクト
   *
   * @type {Promise<any>}
   * @memberof AppComponent
   */
  busy_promise: Promise<any>;

  /**
   * ユーザー情報変更監視
   *
   * @type {Subscription}
   * @memberof AppComponent
   */
  initFinished: Subscription;

  /**
   * ユーザー情報監視
   *
   * @type {Subscription}
   * @memberof AppComponent
   */
  userInfoChanged: Subscription;

  /**
   * クライアント設定情報
   *
   * @type {Settings}
   * @memberof AppComponent
   */
  settings: Settings = null;

  /**
   * OnsNavigator
   *
   * @memberof AppComponent
   */
  @ViewChild(OnsNavigator) navigator: any;

  /**
   * ページ番号（初期化用）
   *
   * @type {number}
   * @memberof PurchaseTicketComponent
   */
  readonly pageTopIndex: number = 0;

  /**
   * ページ番号
   *    トップ以外に戻る場合に書き換えられる。
   *
   * @type {number}
   * @memberof PurchaseTicketComponent
   */
  pageIndex: number = 0;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of AppComponent.
   * @param {ApplicationMessageService} appMsgServ
   * @param {HttpErrorResponseParserService} errResServ
   * @param {UserWebApiService} userServ
   * @param {UserAgentService} userAgentServ
   * @param {DispatchWebApiService} dispatchServ
   * @param {PurchaseWebApiService} purchaseServ
   * @param {ShoppingWebApiService} shoppingServ
   * @memberof AppComponent
   */
  constructor(
    private appMsgServ: ApplicationMessageService,
    private msg: MESSAGE,
    private errResServ: HttpErrorResponseParserService,
    private userServ: UserWebApiService,
    private userAgentServ: UserAgentService,
    private dispatchServ: DispatchWebApiService,
    private purchaseServ: PurchaseWebApiService,
    private shoppingServ: ShoppingWebApiService,
    private selectHistoryServ: SelectHistoryWebApiService,
    private expServ: ExpWebApiService,
    private pagerServ: PagerService,
    private commonFunctionMdl: CommonFunctionModule,
    private municipalityWebApiServ: MunicipalityWebApiService,
  ) { }

  /**
   * 初期化処理。
   *
   * @memberof AppComponent
   */
  ngOnInit() {
    const html = document.documentElement;

    if (onsPlatform.isIPhoneX()) {
      html.setAttribute('onsflag-iphonex-portrait', '');
      html.setAttribute('onsflag-iphonex-landscape', '');
    }

    this.userAgentServ.initialize(window.navigator.userAgent);

    // [(Android)推奨環境] 以外は「ホームへ追加（PWAインストール）」を行えないようにする。
    if (!(this.userAgentServ.isRecommendedEnvironment && this.userAgentServ.isAndroidPhone))
      document.querySelector('link[rel="manifest"]').remove();

    // --------------------------------------------------
    // ページアンロード時に操作履歴データをサーバへ送信
    // --------------------------------------------------

    // 非表示になった時に送信
    addEventListener('visibilitychange', async () => {
      if (document.hidden) {
        await this.selectHistoryServ.postStrage(true, true);
      }
    });

    // ページが非表示になった時に送信
    addEventListener('hide', async (event) => {
      const target: HTMLElement = event.target as HTMLElement;
      // 記事一覧ページ
      if (target.matches('ons-page[news-article-list]')) {
        await this.selectHistoryServ.postStrage(true, true);
      }
    });

    // NOTE: 現状、blurが必要なケースが確認できないが、
    // 　　　　ＯＳのバージョンが古い場合、このイベントでしかアンロード時イベントが取れない可能性がある。
    // フォーカスが外れた時に送信
    // addEventListener('blur', async () => {
    //   await this.selectHistoryServ.postStrage(true, true);
    // });
  }

  /**
   * 描画後の初期化処理。
   *
   * @memberof AppComponent
   */
  ngAfterViewInit(): void {

    // 全体版(URLにエリアコードが存在しない場合)
    if (environment.areacode == "") {
      // 初期表示ページは、全体版ページ
      this.navigator.element.page = AllMunicipalitiesComponent;
    }
    // 各自治体版
    else {
      // 初期表示ページは、各自治体版
      this.navigator.element.page = SplitterComponent;

      // 初回処理--------------------------------------------------------------------------
      setTimeout(() => this.busy_promise = this.init());
    }
  }

  /**
   * 破棄処理。
   *
   * @memberof AppComponent
   */
  ngOnDestroy(): void {

    this.busy?.unsubscribe();
    this.userInfoChanged?.unsubscribe();
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * 各自治体版、初期処理
   *
   * @private
   * @memberof AppComponent
   */
  async init(): Promise<void> {
    // ユーザー情報変更監視
    this.userInfoChanged = this.userServ.userInfoChanged.subscribe({
      next: result => {
        let user: UserInfo = result;
        // ユーザー情報未取得の場合、処理を抜ける
        if (user === void 0) return;

        // appComponentのnavigatorをサービスに保存
        this.pagerServ.setAppNavigator = this.navigator;

        const url = window.location.href;
        // メールリンクかどうか
        const isMail: boolean = url.includes('?');

        // ログイン済みかつ規約合意をしていない場合、
        // 規約合意画面を表示しメール遷移を行わない(メール遷移の場合、規約合意完了後リロード)。
        if (this.userServ.isLoggedIn() && user.term_agreed===false) {
          this.navigator.element.pushPage(TermAgreeComponent, {
            animation: 'fade-ios',
            data: {isMail: isMail}
          });
        }
        else {
          // メール遷移
          if (isMail) this.link();
        }
        // ユーザー情報変更監視、停止
        this.userInfoChanged?.unsubscribe();
      }
    })

    // 自治体版の場合
    try {
      // 自治体ごと設定を取得
      await firstValueFrom(this.municipalityWebApiServ.getLatestSettings());
    }
    catch (err) {
      (this.errResServ.doParse((_err, errContent) => { this.errResServ.viewErrDialog(errContent); }))(err);
    }

    try {
      // ユーザ情報取得
      await firstValueFrom(this.userServ.getLatestUser());
    }
    catch (err) {
      // 未ログイン
    }
  }

  /**
   * メールリンクからの遷移処理。
   *
   * @private
   * @return {*}
   * @memberof AppComponent
   */
  private link(): void {

    const url = window.location.href;
    if (!url.includes('?')) { return; }

    // パラメータ取得
    const httpParams = new HttpParams({ fromString: url.split('?')[1] });

    // 「page」パラメータによって分岐
    switch(httpParams.get("page")) {

      // 予定・履歴
      case "history-detail":
        this.viewHistoryDetail(httpParams.get("reservation_id"));
        this.linkReset();
        break;

      // 注文履歴詳細
      case "order-detail":
        this.viewOrderDetail(httpParams.get("order_id"));
        this.linkReset();
        break;

      // 体験サービス 予約詳細
      case "exp-detail":
        // 体験サービス 予約詳細を表示する。
        this.viewExpReservationDetail(httpParams.get("reservation_id"));
        this.linkReset();
        break;

      // 請求明細
      case "purchase-details":
        this.viewPurchaseDetail(httpParams.get("payment_month"));
        this.linkReset();
        break;

      // 所有乗車券
      case "purchase-ticket":
        this.viewPurchaseTicket(httpParams.get("ticket_id"));
        this.linkReset();
        break;

      // QRコード
      case "my-qr":
        this.viewMyQR();
        this.linkReset();
        break;

      // パスワード再設定
      case "change-password":
        this.viewChangePassword(httpParams.get("token"));
        this.linkReset();
        break;

      case "exp":
      case "news":
      case "shopping":
        break;

      // パラメータエラー
      default:
        this.appMsgServ.viewDialogMessage(this.msg.CLIENT.COMMON.E_INVALID_URL.message());
    }
  }

  /**
   * 初期リンク(?page以降)URL削除
   *
   * @private
   * @memberof AppComponent
   */
  private linkReset(): void {
    // 初期リンク(?page以降)URL削除
    history.replaceState({}, "", location.href.split('?')[0]);
  }

  /**
   * ページ遷移を行う。
   *    任意のページから各タブのトップまたは、
   *    指定のページまで遷移する。
   *
   * @private
   * @param {number} [backPageIndex] 戻りたいページ番号
   * @param {boolean} [execution=true] 遷移後に処理を行うか
    * @memberof AppComponent
   */
  private returnPage(backPageIndex?: number, execution: boolean = true): void {

    // 現在のページ番号
    const nCurrentIndex = this.navigator.element.pages.length - 1;

    // 戻り先が定義されているなら設定
    if (undefined !== backPageIndex) this.pageIndex = backPageIndex;

    // 目的のページまで遷移するまで再帰呼び出し
    if (this.pageIndex < nCurrentIndex) {
      this.navigator.element.removePage(this.pageIndex + 1)
      .then(() => { setTimeout(() => { this.returnPage(backPageIndex, execution); }, 0); });
    }
    else if (this.pageIndex === nCurrentIndex) {
      this.pageIndex = this.pageTopIndex;
    }
    else ;
  }

  /**
   * 予定・履歴を表示する。
   *
   * @private
   * @param {string} reservation_id
   * @memberof AppComponent
   */
  private viewHistoryDetail(reservation_id: string): void {

    // パラメータ異常
    if (!reservation_id) {
      this.appMsgServ.viewDialogMessage(this.msg.CLIENT.COMMON.E_INVALID_URL.message());
      return;
    }

    // string⇒number
    const reservationIdNum = Number(reservation_id);

    // reservation_idが数字に変換できる値かどうか
    if (isNaN(reservationIdNum)) {
      this.appMsgServ.viewDialogMessage(this.msg.CLIENT.COMMON.E_INVALID_URL.message());
      return;
    }

    // ユーザ情報取得できるか？
    this.busy = this.userServ.autoLogin().subscribe({
      next: () => {

        // ユーザ予定・履歴取得　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　
        this.dispatchServ.getUserReservation<Reservation>(reservationIdNum).subscribe({
          next: response => {
            const reservation: Reservation = response.body;

            // 予定・履歴がなければ終了
            if (!reservation) {
              this.appMsgServ.viewDialogMessage(this.msg.CLIENT.DISPATCH.E_NO_RESERVATION.message());
              return;
            }

            // 画面表示用のユーザ別配車情報を作成
            let bill = this.dispatchServ.getCustomersBill(reservation);

            // 詳細画面へ遷移
            this.navigator.element.pushPage(HistoryDetailComponent, {
              animation: 'fade-ios',
              data: { reservation: reservation, customerBill: bill, transition: () => { this.returnPage(); } }
            });
          },
          error: this.errResServ.doParse((_err, errContent) => {
            this.appMsgServ.viewDialogMessage(this.msg.CLIENT.DISPATCH.E_GET_RESERVATION.message());
          })
        });
      },
      error: this.errResServ.doParse((_err, errContent) => {
        this.appMsgServ.viewDialogMessage(this.msg.CLIENT.COMMON.E_UNLOGIN_STATE.message());
      })
    });
  }

  /**
   * 注文履歴を表示する。
   *
   * @private
   * @param {string} order_id
   * @memberof AppComponent
   */
  private viewOrderDetail(order_id: string): void {

    // ログイン状態なら
    this.busy = this.userServ.autoLogin().subscribe({
      next: ()=> {
        // パラメータ異常
        if (!order_id ?? false) {
          this.appMsgServ.viewDialogMessage(this.msg.CLIENT.COMMON.E_INVALID_URL.message());
          return;
        }

        // 指定したorder_idの注文情報を取得
        this.shoppingServ.getOrder(order_id).subscribe({
          next: res => {
            let order = res.body;

            // 該当する注文情報がない場合終了
            if (!order) {
              this.appMsgServ.viewDialogMessage(this.msg.CLIENT.SHOPPING.ERR_NOT_TARGET_ORDER.message());
              return;
            }

            // googleMapURL設定
            order.destination.google_map_url = order.destination.location !== undefined ? this.commonFunctionMdl.getGoogleMapUrl(order.destination.location) : this.commonFunctionMdl.getGoogleMapUrl(order.destination.address);

            this.navigator.element.pushPage(HistoryOrderDetailComponent, {
              animation: 'fade-ios',
              data: {
                order: order,
                transition: () => { this.returnPage(); }
              }
            });
          },
          error: this.errResServ.doParse((_err, errContent) => {
            this.errResServ.viewErrDialog(errContent);
          })
        });
      },
      error: this.errResServ.doParse((_err, errContent) => {
        this.appMsgServ.viewDialogMessage(this.msg.CLIENT.COMMON.E_UNLOGIN_STATE.message());
      })
    });
  }

  /**
   * 請求明細を表示する。
   *
   * @private
   * @param {string} payment_month
   * @memberof AppComponent
   */
  private viewPurchaseDetail(payment_month: string): void {

    const returnPurchaseTrns: LoginComponent.Transition = {
      method: "popPage"
    }

    // ログイン状態なら
    this.busy = this.userServ.autoLogin().subscribe({
      next: user => {
        // パラメータ作成
        const params: parameter.PossessionsTicket = {
          im: 'payer',
          payment_month: payment_month
        }

        // // ログイン中のユーザが観光目的ユーザだったら
        // if (user.body.user_type != "citizen") {
        //   this.appMsgServ.viewClientMsg(this.appMsgServ.CLIENT_CODE.PURCHASE.E_TOURIST);
        //   return;
        // }

        // パラメータ異常
        if (!params.payment_month ?? false) {
          this.appMsgServ.viewDialogMessage(this.msg.CLIENT.COMMON.E_INVALID_URL.message());
          return;
        }

        // 無効な照会期間だったら
        switch (this.purchaseServ.isValidRange(params.payment_month)) {
          case false: {
            this.appMsgServ.viewDialogMessage(this.msg.CLIENT.PURCHASE.E_DETAIL_RANGE.message());

            return;
          }
          case "Invalid Date": {
            this.appMsgServ.viewDialogMessage(this.msg.CLIENT.PURCHASE.E_INVALID_RANGE.message());
            return;
          }
          default: ;
        }

        // 配車、注文、配送料情報を取得
        this.busy = this.purchaseServ.getPurchaseDetail(params).subscribe({
          next: result => {

            // 表示用データの整形
            let purchase: {[method: string]: common.DispPurchase} = this.purchaseServ.createPurchaseDetailData(result);

            // 請求明細画面を表示
            this.navigator.element.pushPage(PurchaseDetailsComponent, {
              animation: 'fade-ios',
              data: {
                purchase: purchase,
                transition: returnPurchaseTrns,
                payment_month: moment(params.payment_month).format('YYYY-MM')
              }
            });
          },
          error: this.errResServ.doParse((_err, errContent) => {
            this.appMsgServ.viewDialogMessage(this.msg.CLIENT.PURCHASE.E_GET_DETAILS.message());
          })
        });
      },
      error: this.errResServ.doParse((_err, errContent) => {
        this.appMsgServ.viewDialogMessage(this.msg.CLIENT.COMMON.E_UNLOGIN_STATE.message());
      })
    });
  }

  /**
   * 所有乗車券を表示する。
   *
   * @private
   * @param {string} ticket_id
   * @memberof AppComponent
   */
  private viewPurchaseTicket(ticket_id: string): void {

    // パラメータ
    const params: parameter.PossessionsTicket = {
      im: "user",
      valid: "in-before"
    }

    // ログイン状態なら
    this.busy = this.userServ.autoLogin().subscribe({
      next: user => {
        // パラメータ異常
        if (!ticket_id ?? false) {
          this.appMsgServ.viewDialogMessage(this.msg.CLIENT.COMMON.E_INVALID_URL.message());
          return;
        }

        // 所有乗車券を取得
        this.purchaseServ.getPurchaseDataList<PossessionsTicket>(params).subscribe({
          next: response => {
            // 所有している乗車券がない
            if (response.body.tickets?.length == 0 ?? 0) {
              // 通常あり得ないためエラー扱いとする
              this.appMsgServ.viewDialogMessage(this.msg.CLIENT.PURCHASE.MAIL_NOT_OWNED.message());
              return;
            }

            // ticket_idに該当する乗車券を検索
            const ticket = response.body.tickets.find((t) => t.ticket_id.toString() == ticket_id);
            if (undefined == ticket) {
              // 該当する乗車券がない
              this.appMsgServ.viewDialogMessage(this.msg.CLIENT.PURCHASE.E_NOT_TARGET_TICKET.message());
              return;
            }

            // 所有乗車券画面を表示
            this.navigator.element.pushPage(PurchaseTicketOwnedComponent, {
              animation: 'fade-ios',
              data: {
                userProfile: user.body,
                ticket: ticket
              }
            });
          },
          error: this.errResServ.doParse((_err, errContent) => {
            this.appMsgServ.viewDialogMessage(this.msg.CLIENT.PURCHASE.E_GET_POSSESSIONS.message());
          })
        });
      },
      error: this.errResServ.doParse((_err, errContent) => {
        this.appMsgServ.viewDialogMessage(this.msg.CLIENT.COMMON.E_UNLOGIN_STATE.message());
      })
    });
  }

  /**
   * QRコードを表示する。
   *
   * @private
   * @memberof AppComponent
   */
  private viewMyQR(): void {

    this.busy = this.userServ.autoLogin().subscribe({
      next: () => { this.navigator.element.pushPage(MyqrComponent, {animation: 'fade-ios'}); }
    });
  }

  /**
   * パスワード再設定を表示する。
   *
   * @private
   * @param {string} token
   * @return {*}  {void}
   * @memberof AppComponent
   */
  private viewChangePassword(token: string): void {

    // パラメータ異常
    if (!token ?? false) {
      this.appMsgServ.viewDialogMessage(this.msg.CLIENT.COMMON.E_INVALID_URL.message());
      return;
    }

    // パスワード再設定画面へ遷移
    this.navigator.element.pushPage(ChangePasswordComponent, {
      animation: 'fade-ios',
      data: { token: token }
    });
  }

  /**
   * 体験サービス 予約詳細を表示する。
   *
   * @private
   * @param {string} service_id
   * @memberof AppComponent
   */
  private viewExpReservationDetail(reservation_id: string): void {

    // パラメータ異常
    if (!reservation_id) {
      this.appMsgServ.viewDialogMessage(this.msg.CLIENT.COMMON.E_INVALID_URL.message());
      return;
    }

    // string⇒number
    const reservationIdNum = Number(reservation_id);

    // reservation_idが数字に変換できる値かどうか
    if (isNaN(reservationIdNum)) {
      this.appMsgServ.viewDialogMessage(this.msg.CLIENT.COMMON.E_INVALID_URL.message());
      return;
    }

    // ログイン状態チェック
    this.busy = this.userServ.autoLogin().subscribe({
      next: () => {
        // 予約GET
        this.busy = this.expServ.getTargetReservation(reservationIdNum).subscribe({
          next: res => {
            const expReserv = res.body;

            // 予定・履歴詳細画面に遷移
            this.navigator.element.pushPage(HistoryExpDetailComponent, {
              animation: 'fade-ios',
              data: {
                reserv: expReserv,
                transition: () => { this.returnPage(); }
              }
            });
          },
          error: this.errResServ.doParse((_err, errContent) => {
            this.appMsgServ.viewDialogMessage(this.msg.CLIENT.EXP.E_GET_RESERVATION.message());
          })
        });
      },
      error: this.errResServ.doParse((_err, errContent) => {
        this.appMsgServ.viewDialogMessage(this.msg.CLIENT.COMMON.E_UNLOGIN_STATE.message());
      })
    });
  }
}
