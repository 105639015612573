//=============================================================================================
// インポート
//=============================================================================================
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, InjectionToken } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

// service
import { ApplicationMessageService } from '../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../lib-services/http-error-response-parser.service';
import { FamilyWebApiService } from './family-web-api.service';
import { OrderRequestService } from '../lib-services/order-request.service';

// interface
import { request } from '../interfaces/request';
import { Settings, UserInfo } from '../interfaces/response';

//=============================================================================================
// 定数定義
//=============================================================================================

// カードブランド、支払い方法及び、アイコンMAP
export const USER_WEB_API_CARD_BRAND_MAP = new InjectionToken<Map<string, Map<string, string>>>('userWebApiCardBrandMap');
export const USER_WEB_API_PAYMENT_METHOD_MAP = new InjectionToken<Map<string, Map<string, string>>>('userWebApiPaymentMethodMap');

//=============================================================================================
// Configクラス定義
//=============================================================================================

/**
 * Web API (ユーザー関連) の構成設定。
 *
 * @export
 * @class UserWebApiServiceConfig
 */
export class UserWebApiServiceConfig {
  /**
   * Web API の基底 URL。
   *
   * @type {string}
   * @memberof UserWebApiServiceConfig
   */
  baseUrl: string;

  /**
   * エリアコード
   *
   * @type {string}
   * @memberof UserWebApiServiceConfig
   */
  areacode: string;

  /**
   * HTTP リクエストと一緒に送信されるオプション。
   *
   * @type {({
   *     headers?: HttpHeaders | {
   *       [header: string]: string | string[];
   *     };
   *   })}
   * @memberof UserWebApiServiceConfig
   */
  httpOptions?: {
    /**
     * HTTP ヘッダー。
     *
     * @type {(HttpHeaders | {
     *       [header: string]: string | string[];
     *     })}
     */
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
  };
}

//=============================================================================================
// Serviceクラス定義
//=============================================================================================

/**
 * Web API (ユーザー関連) の呼び出しを簡略化するためのユーティリティ。
 *
 * @export
 * @class UserWebApiService
 */
@Injectable({
  providedIn: 'root'
})
export class UserWebApiService {

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * SNS認証のコールバックからのイベントに使うlocalStorageのkey
   *
   * @private
   * @type {string}
   * @memberof UserWebApiService
   */
  private static STORAGE_KEY: string = 'smart-goto-auth';

  /**
   * ユーザ情報用BehaviorSubject
   *
   * @private
   * @memberof UserWebApiService
   */
  private userInfoSubject = new BehaviorSubject<UserInfo>(undefined);
  
//=============================================================================================
// コンストラクタ
//=============================================================================================

  /**
   * Creates an instance of UserWebApiService.
   * @param {HttpClient} http HTTP リクエストを実行するためのサービス。
   * @param {UserWebApiServiceConfig} config Web API (ユーザー関連) の構成設定。
   * @param {ApplicationMessageService} appMsgServ
   * @param {HttpErrorResponseParserService} errResServ
   * @param {OrderRequestService} orderServ
   * @param {FamilyWebApiService} familyServ
   * @memberof UserWebApiService
   */
  constructor(
    private http: HttpClient, 
    private config: UserWebApiServiceConfig, 
    private appMsgServ: ApplicationMessageService, 
    private errResServ: HttpErrorResponseParserService, 
    private orderServ: OrderRequestService, 
    private familyServ: FamilyWebApiService, 
  ) { }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * ユーザー情報を取得する。
   *
   * @return {*}  {UserInfo} 最後にサーバーから取得したユーザー情報 (ユーザーが未認証の場合は `null`)。
   * @memberof UserWebApiService
   */
  getUserInfo(): UserInfo {

    return this.userInfoSubject.getValue();
  }
  
  /**
   * ユーザーの認証状態を取得する。
   *
   * @return {*}  {boolean} ユーザーが認証済みの場合は true。それ以外の場合は false。
   * @memberof UserWebApiService
   */
  isLoggedIn(): boolean {

    // ユーザー情報がnull(ログアウト状態) or undefiend(ユーザー情報未取得)の場合、false
    return this.getUserInfo()===null || this.getUserInfo()===undefined ? false : true;;
  }

  /**
   * ユーザーの認証状態の変更を監視するための `Observable` を取得する。
   *
   * @readonly
   * @type {Observable<UserInfo>}
   * @memberof UserWebApiService
   */
  get userInfoChanged(): Observable<UserInfo> {
    
    return this.userInfoSubject.asObservable();
  }
  
  /**
   * 住所に変更があるか判定。
   *
   * @param {UserInfo["profile"]["address"]} beforeAddress
   * @param {UserInfo["profile"]["address"]} afterAddress
   * @return {*}  {boolean}
   * @memberof UserWebApiService
   */
  checkChangeAddress(beforeAddress: UserInfo["profile"]["address"], afterAddress: UserInfo["profile"]["address"]): boolean {

    // 住所に変更があるか
    let addressEditted: boolean = false;

    // 変更あり
    if (
      beforeAddress.zip !== afterAddress.zip ||
      beforeAddress.prefecture !== afterAddress.prefecture ||
      beforeAddress.city !== afterAddress.city ||
      beforeAddress.address1 !== afterAddress.address1 ||
      beforeAddress.address2 !== afterAddress.address2 ||
      beforeAddress.address3 !== afterAddress.address3 
    ) addressEditted = true;
    // 変更なし
    else addressEditted = false;
      
    return addressEditted;
  }

  /**
   * ユーザー情報、選択情報を削除
   *
   * @memberof UserWebApiService
   */
  refreshUserData() {
    // ログアウトと同じ処理
    this.userInfoSubject.next(null);
    this.familyServ.returnFamilyInfo();
    this.orderServ.refresh(true);
  }
   
//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * ログイン成功（セッション確立）後、ユーザ情報を取得する。
   *
   * @return {*}  {Observable<HttpResponse<UserInfo>>}
   * @memberof UserWebApiService
   */
  autoLogin(): Observable<HttpResponse<UserInfo>> {

    return this.http.get<UserInfo>(`${this.config.baseUrl}/${this.config.areacode}/user`, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    }).pipe(tap({
      next: (response: HttpResponse<UserInfo>) => {
        this.userInfoSubject.next(response.body);

        // ファミリー情報を取得し、こちらもpipeに登録
        this.familyServ.getFamily().subscribe({
          next: () => {},
          error: this.errResServ.doParse((_err, errContent) => {
            // エラー発生時はメッセージ表示
            this.errResServ.viewErrDialog(errContent);
          })
        });
      },
      // ユーザー情報取得失敗時、保持するユーザー情報をnull
      error: () => {this.userInfoSubject.next(null)}
    }));
  }

  /**
   * 最新のユーザ情報を取得する。
   *
   * @return {*}  {Observable<HttpResponse<UserInfo>>}
   * @memberof UserWebApiService
   */
  getLatestUser() :Observable<HttpResponse<UserInfo>> {
    
    return this.http.get<UserInfo>(`${this.config.baseUrl}/${this.config.areacode}/user`, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    }).pipe(tap({
      next: (response: HttpResponse<UserInfo>) => {
        this.userInfoSubject.next(response.body);
      },
      // ユーザー情報取得失敗時、保持するユーザー情報をnull
      error: () => {this.userInfoSubject.next(null)}
    }));
  }

  /**
   * 最後に取得したユーザ情報を取得する。
   *
   * @return {*}  {Observable<HttpResponse<UserInfo>>}
   * @memberof UserWebApiService
   */
  getLatestUserInfo(): Observable<HttpResponse<UserInfo>> {

    return this.autoLogin();
  }

  /**
   * メールアドレスによるログイン認証を行う。
   *
   * @param {string} username
   * @param {string} password
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof UserWebApiService
   */
  signin(username: string, password: string): Observable<HttpResponse<any>> {

    return this.http.post(`${this.config.baseUrl}/${this.config.areacode}/signin-local`, {
      username: username,
      password: password
    }, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }

  /**
   * 新規アカウントを作成する。
   *
   * @param {string} username
   * @param {string} password
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof UserWebApiService
   */
  signup(username: string, password: string): Observable<HttpResponse<any>> {

    return this.http.post(`${this.config.baseUrl}/pincode-send`, {
      username: username,
      password: password
    }, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }

  /**
   * PINCODE認証を行う。
   * signup時に使用。
   * 
   * @param {string} username
   * @param {string} pincode
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof UserWebApiService
   */
  signupPincode(username: string, pincode: string): Observable<HttpResponse<any>> {

    return this.http.post(`${this.config.baseUrl}/pincode-check`, {
      username: username,
      pincode: pincode,
    }, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }

  /**
   * メールアドレス以外（SNS）によるアカウント認証を行う。
   *
   * @param {string} post
   * @param {() => void} callback
   * @memberof UserWebApiService
   */
  auth(post: string, callback: () => void): void {

    const snslogin_window = window.open(`${this.config.baseUrl}/auth/${post}`);

    localStorage.removeItem(UserWebApiService.STORAGE_KEY);
    
    window.addEventListener('storage', function listener(event) {
      if (event.key == UserWebApiService.STORAGE_KEY) {
        // console.log("UserWebApiService] storage event:" + event.newValue);
        if (event.newValue == "succeed") {
          if (!snslogin_window.closed) {
            // Safari + LINEアプリログイン だとOAuthのコールバックは別ウィンドウになる
            snslogin_window.close();
          }
          event.currentTarget.removeEventListener(event.type, listener);
          callback();
        }
      }
    });
  }

  /**
   * ログアウトを行う。
   *
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof UserWebApiService
   */
  logout(): Observable<HttpResponse<any>> {
    
    return this.http.get(`${this.config.baseUrl}/signout`, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    }).pipe(tap({
      next: response => {
        this.refreshUserData();
      }
    }));
  }

  /**
   * 登録中止。
   *
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof UserWebApiService
   */
  signinCancel(): Observable<HttpResponse<any>> {
    
    return this.http.get(`${this.config.baseUrl}/signin-cancel`, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    }).pipe(tap({
      next: response => {
        // ログアウトと同じ処理
        this.refreshUserData();
      }
    }));
  }

  /**
   * PINコードでの二段階認証。
   *
   * @param {string} pincode
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof UserWebApiService
   */
  signinPincode(pincode: string): Observable<HttpResponse<any>> {

    return this.http.post(`${this.config.baseUrl}/signin-local-pincode`, { pincode: pincode }, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    })
  }

  /**
   * 任意のユーザーの情報 (簡略化されたもの) を取得する。
   *
   * @template T レスポンスの型。
   * @param {*} [query] 取得の対象となるユーザーの識別情報。
   * @return {*}  {Observable<HttpResponse<T>>} レスポンスの受信を監視するための `Observable`。
   * @memberof UserWebApiService
   */
  getSomeUser<T>(query?: any): Observable<HttpResponse<T>> {

    return this.http.get<T>(`${this.config.baseUrl}/${this.config.areacode}/user`, {
      ...this.config.httpOptions,
      observe: 'response',
      params: query,
      withCredentials: true
    });
  }

  ////////////////////////////////////////////////////////////////////////////
  // プロフィール
  ////////////////////////////////////////////////////////////////////////////

  /**
   * プロフィールを更新する。
   *
   * @param {(UserInfo["profile"] | {[editKey: string]: any })} profile
   * @param {number} [relation_id]
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof UserWebApiService
   */
  updateProfile(profile: UserInfo["profile"] | {[editKey: string]: any } , relation_id?: number): Observable<HttpResponse<any>> {

    return this.http.put(`${this.config.baseUrl}/user/profile`, profile, {
      ...this.config.httpOptions,
      observe: 'response',
      params: relation_id ? { relation_id: `${ relation_id }` } : null,
      withCredentials: true
    });
  }

  ////////////////////////////////////////////////////////////////////////////
  // 通知方法
  ////////////////////////////////////////////////////////////////////////////
  
  /**
   * 通知方法を変更する。
   *
   * @param {request.Notify} method
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof UserWebApiService
   */
  changeNotify(method: request.Notify): Observable<HttpResponse<any>> {

    return this.http.put(`${this.config.baseUrl}/user/notify`, method, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }

  ////////////////////////////////////////////////////////////////////////////
  // 支払い方法
  ////////////////////////////////////////////////////////////////////////////

  /**
   * 支払い方法を追加する。
   *
   * @param {request.Payment} payment
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof UserWebApiService
   */
  addPayment(payment: request.Payment): Observable<HttpResponse<any>> {

    return this.commitPayment(payment);
  }

  /**
   * 支払い方法を更新する。
   *    デフォルト支払い方法の変更
   *
   * @param {request.Payment} Payment
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof UserWebApiService
   */
  updatePayment(payment: request.Payment): Observable<HttpResponse<any>> {

    return this.commitPayment(payment);
  }

  /**
   * 支払い方法を削除する。
   *
   * @param {request.Payment} payment
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof UserWebApiService
   */
  deletePayment(payment: request.Payment): Observable<HttpResponse<any>> {

    return this.commitPayment(payment);
  }
  
  /**
   * 支払い方法をサーバへコミット。
   *
   * @private
   * @param {request.Payment} payment
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof UserWebApiService
   */
  private commitPayment(payment: request.Payment): Observable<HttpResponse<any>> {

    return this.http.put(`${this.config.baseUrl}/${this.config.areacode}/user/payment`, payment, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }
  

  /**
   * クレジットカード情報を取得する。
   *
   * @template T
   * @return {*}  {Observable<HttpResponse<T>>}
   * @memberof UserWebApiService
   */
  allCards<T>(): Observable<HttpResponse<T>> {

    return this.http.get<T>(`${this.config.baseUrl}/${this.config.areacode}/user/card`, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }

  /**
   * クレジットカード情報を作成する。
   *
   * @param {request.Card} card
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof UserWebApiService
   */
  createCard(card: request.Card): Observable<HttpResponse<any>> {

    return this.http.post(`${this.config.baseUrl}/${this.config.areacode}/user/card`, card, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }

  /**
   * クレジットカード情報を削除する。
   *
   * @param {number} card_seq
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof UserWebApiService
   */
  deleteCard(card_seq: number): Observable<HttpResponse<any>> {

    return this.http.delete(`${this.config.baseUrl}/${this.config.areacode}/user/card/${card_seq}`, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }

  ////////////////////////////////////////////////////////////////////////////
  // 二段階認証方法(PINコード送付先)変更
  ////////////////////////////////////////////////////////////////////////////

  /**
   * PINコード送付先変更
   *
   * @param {('mail' | 'sms')} method
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof UserWebApiService
   */
  updatePincodeMethod(method: 'mail' | 'sms'): Observable<HttpResponse<any>> {
    
    return this.http.put(`${this.config.baseUrl}/user/pincode`, { method: method }, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }

  ////////////////////////////////////////////////////////////////////////////
  // パスワード変更
  ////////////////////////////////////////////////////////////////////////////

  /**
   * パスワード再設定メール送信する。
   *
   * @param {string} mailAddress
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof UserWebApiService
   */
  sendChangePasswordMail(mailAddress: string): Observable<HttpResponse<any>> {

    return this.http.post(`${this.config.baseUrl}/${this.config.areacode}/change-password-request`, { username: mailAddress }, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }

  /**
   * パスワード再設定実行する。
   *
   * @param {string} token
   * @param {string} password
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof UserWebApiService
   */
  changePassword(token: string, password: string): Observable<HttpResponse<any>> {

    return this.http.post(`${this.config.baseUrl}/change-password`, { token: token, password: password }, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }

  ////////////////////////////////////////////////////////////////////////////
  // アカウント削除
  ////////////////////////////////////////////////////////////////////////////
  
  /**
   * アカウント削除手続きを行う。
   *
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof UserWebApiService
   */
  withdrawal(): Observable<HttpResponse<any>> {
    return this.http.post(`${this.config.baseUrl}/withdrawal`, {}, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }

}
