import { Component, OnInit } from '@angular/core';
import { OnsNavigator } from 'ngx-onsenui';
import { NotificationMethodComponent } from '../../notification-method/notification-method.component';
import { Subscription } from 'rxjs';
import { WithdrawalComponent } from '../../user/withdrawal/withdrawal.component';
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';

@Component({
  selector: 'ons-page[app-account-list-other-menu]',
  templateUrl: './account-list-other-menu.component.html',
  styleUrls: ['./account-list-other-menu.component.scss']
})
export class AccountListOtherMenuComponent implements OnInit {

  /**
   * 非同期処理監視Subscription
   *
   * @type {Subscription}s
   * @memberof AccountListComponent
   */
  busy: Subscription;

  readonly ASSETS = {
    NOTIFY: CommonFunctionModule.getAssetsUrl('/image/common/31-Alert.png'),
    WITHDRAWAL: CommonFunctionModule.getAssetsUrl('/image/common/withdrawal.png'),
  } as const;

  constructor(
    private _navigator: OnsNavigator,
  ) { }

  ngOnInit(): void {
  }

  /**
   * 通知方法画面へ
   *
   * @memberof AccountListComponent
   */
  pushNotificationMethod(): void {

    this._navigator.element.pushPage(NotificationMethodComponent);
  }

  /**
   * アカウント削除画面へ
   *
   * @memberof AccountListOtherMenuComponent
   */
  pushWithdrawal(): void {
    this._navigator.element.pushPage(WithdrawalComponent);
  }
}
