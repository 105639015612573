<ng-container *ngIf="listInfo.header_ons; else noOns">
  <ons-list>
    <ons-list-header modifier="color" class="list-item__title header--height">{{ listInfo.header }}</ons-list-header>
  </ons-list>
</ng-container>

<!--  通常 -->
<ng-container *ngIf="listInfo.mode=='common'">
  <ons-list>

    <ons-list-item class="list-item__text" modifier="longdivider">
      <ng-container *ngIf="listInfo.common.item; else noItem">
        <ng-container *ngIf="!(listInfo.common.link)">
          <ng-container *ngIf="commonType == 'string'">
            <div class="common--text">{{ listInfo.common.item }}</div>
          </ng-container>
          <ng-container *ngIf="commonType == 'number' || commonType == 'customNumber'">
            <div class="common--text">￥{{ listInfo.common.item | number:'.0-0' }}<ng-container *ngIf="commonType == 'customNumber'">{{ listInfo.common.number_option_text }}</ng-container></div>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="listInfo.common.link">
          <p>
            <a class="list-item__link" target="_blank" [attr.href]="listInfo.common.link">
              <div>{{ listInfo.common.item }}</div>
            </a>
          </p>
        </ng-container>
      </ng-container>
    </ons-list-item>
  </ons-list>
</ng-container>

<!-- 支払い料金 -->
<ng-container *ngIf="listInfo.mode=='paymentAmount'">
  <ons-list>
    <ons-list-item modifier="nodivider">
      <ons-row vertical-align="center" class="list-item__menu" *ngFor="let text of listInfo.payment_amount.row">
          <ons-col>
            {{ text.item }}
          </ons-col>
          <ons-col *ngIf="text.type">
            {{ text.type }}
          </ons-col>
          <ons-col width="10%" *ngIf="text.count">
            {{ text.count }}{{ text.unit }}
          </ons-col>
          <ons-col width="15%" class="amount">￥{{ text.amount | number:'.0-0' }}</ons-col>
      </ons-row>
    </ons-list-item>
    <ons-list-item modifier="nodivider">
      <div class="total">
        <div class="total-item__amount">お支払い料金(合計)：
          <span class="amount">￥{{ listInfo.payment_amount.total_amount | number:'.0-0' }}</span>
        </div>
        <div id="shopping-determine_004">請求先：{{ listInfo.payment_amount.payer_name }} 様</div>
      </div>
    </ons-list-item>
  </ons-list>
</ng-container>

<!-- リンク付きリスト -->
<ng-container *ngIf="listInfo.mode=='tappable'">
  <ons-list>
      <ons-list-item class="list-item__menu--height" modifier="chevron longdivider" tappable (click)="onClickList(listInfo.tappable.key)">  
        <ons-row class="list-row" vertical-align="center">

          <ons-col class="list-icon-col" width="120px">
            <span *ngIf="listInfo.tappable.icon; else noImage"><img class="list-icon-col__img" [src]="listInfo.tappable.icon"/></span>
          </ons-col>
          <ons-col class="menu--detail">
            <!-- <ons-col class="menu--detail" *ngFor="let item of listInfo.tappable.item; index as i"> -->
            <ng-container *ngFor="let item of listInfo.tappable.item; index as i">
              <ons-row vertical-align="top" class="menu--detail--name list-row" *ngIf="i==0">
                <ons-col *ngIf="item.text_string; else text_number"><p>{{ item.text_string }}</p></ons-col>
              </ons-row>
              <ons-row vertical-align="middle" class="menu--detail--name list-row" *ngIf="listInfo.tappable.item.length==3 && i==1">
                <ons-col *ngIf="item.text_string; else text_number"><p>{{ item.text_string }}</p></ons-col>
              </ons-row>
              <ons-row vertical-align="bottom" class="menu--detail--name list-row" *ngIf="listInfo.tappable.item.length==2 && i==1 || i==2">
                <ons-col *ngIf="item.text_string; else text_number"><p>{{ item.text_string }}</p></ons-col>
              </ons-row>

              <!-- <ng-template #itemString><p>{{ item }}</p></ng-template> -->
              <ng-template #text_number><p>{{item.option_text_before}}{{ item.text_number | number:'.0-0' }}{{item.option_text_after}}</p></ng-template>
            </ng-container>
          </ons-col>

            <!-- <ons-row vertical-align="bottom">
              <ons-col><p>￥{{ menu.payment_amount | number:'.0-0' }}</p></ons-col>
            </ons-row> -->
        </ons-row>
      </ons-list-item>
  </ons-list>
</ng-container>

<!-- 大ヘッダー -->
<ng-cotainer *ngIf="listInfo.mode=='header'">
  <ons-list class="list-item__title list-header__title--sticky shop-info-wrap">
    <ons-list-item>
      <!-- 店舗アイコン/店舗名 -->
      <ons-row vertical-align="center">
        <ons-col width="120px" class="list-icon-col"><span *ngIf="listInfo.header_list.icon !== undefined && listInfo.header_list.icon !== ''; else noIcon">
          <img class="shop-info-wrap__img" [src]="listInfo.header_list.icon" /></span>
        </ons-col>
        <ons-col>
          <ons-row *ngFor="let item of listInfo.header_list.item">{{ item }}</ons-row>
        </ons-col>
      </ons-row>
    </ons-list-item>
  </ons-list>
</ng-cotainer>

<!-- セレクトボックス -->
<ng-container *ngIf="listInfo.mode=='selectBox'">
  <ons-list>
    <ons-list-item modifier="longdivider" [matMenuTriggerFor]="menu">
      <!-- <ng-container *ngIf="!listInfo.select_box.is_link">
        <p class="address-select-wrap">
          <ons-select modifier="underbar" [id]="listInfo.select_box.item[0].select_id" (change)="onChangeSelectBox($event)" [(ngModel)]="selected">
            <option *ngFor="let item of listInfo.select_box.item" [value]="item.key">{{ item.name }}</option>
          </ons-select>
        </p>
      </ng-container> -->
      <!-- <ng-container *ngIf="listInfo.select_box.is_link"> -->
        
        <p class="link_select-box">
          <ng-container *ngIf="listInfo.select_box.type_name !== undefined">
            <!-- 種別名を表示 -->
            {{ listInfo.select_box.type_name }}：
          </ng-container>

          <!-- リンク付き -->
          <ng-container *ngIf="listInfo.select_box.is_link">
            <a target="_blank" [href]="listInfo.select_box.item[selectedIndex].link" (click)="onClickSelectBoxLink($event)">{{listInfo.select_box.item[selectedIndex].name}}</a>
          </ng-container>

          <!--  リンクなし -->
          <ng-container *ngIf="!listInfo.select_box.is_link">
            <span>{{listInfo.select_box.item[selectedIndex].name}}</span>
          </ng-container>
          
          <button class="link_select-box__drop-down-menu" mat-icon-button>
            <span class="material-icons">
              arrow_drop_down
            </span>
          </button>
          <mat-menu #menu="matMenu" xPosition="before">
            <ng-container *ngFor="let item of listInfo.select_box.item; index as i">
              <button class="mat-menu-item__height" mat-menu-item (click)="onChangeLinkSelectBox(item.key, i, $event)" [id]="listInfo.select_box.select_id">
                {{item.name}}
              </button>
            </ng-container>
          </mat-menu>
        </p>
        <ng-container *ngIf="listInfo.valid_msg !== '' && listInfo.id !== ''">
          <div class="valid-msg" id="valid-msg_{{listInfo.id}}" >
            <span>{{listInfo.valid_msg}}</span>
          </div>
        </ng-container>
      <!-- </ng-container> -->
    </ons-list-item>
  </ons-list>
</ng-container>

<!-- template -->
<!-- 店舗アイコン、メニュー画像がない場合、汎用アイコンを表示 -->
<ng-template #noIcon><mat-icon class="part-list-icon">store</mat-icon></ng-template>
<ng-template #noImage><mat-icon class="part-list-icon">menu_book</mat-icon></ng-template>
<ng-template #noOns><div class="list-item__caption" *ngIf="listInfo.header">{{ listInfo.header }}
  <!-- ヘッダーのinfoボタン -->
  <button *ngIf="listInfo.header_dialog_info" class="info_button" style="float: right;" (click)="onClickPopUpDialog()"><img [src]="ASSETS.INFORMATION"></button>
</div></ng-template>
<ng-template #noItem><mat-icon >remove</mat-icon></ng-template>
<ng-template #mainColorHeader><ons-list-header class="list-item__title header--height list-header--color">{{ listInfo.header }}</ons-list-header></ng-template>