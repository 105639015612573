import { Component, OnDestroy, OnInit } from '@angular/core';
import { AfterViewInit, Params } from 'ngx-onsenui';
import { Subscription } from 'rxjs';

// component
import { AppComponent } from 'src/app/app.component';

// service
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';
import { HttpErrorResponseParserService } from 'src/app/lib-services/http-error-response-parser.service';

// interface
import { municipalities } from 'src/app/interfaces/response';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ons-page[app-all-municipalities]',
  templateUrl: './all-municipalities.component.html',
  styleUrls: ['./all-municipalities.component.scss']
})
export class AllMunicipalitiesComponent implements OnInit, AfterViewInit, OnDestroy {

  /**
   * 通信用subscription
   *
   * @type {Subscription}
   * @memberof AllMunicipalitiesComponent
   */
  busy: Subscription;

  /**
   * 表示用、都道府県ごとの自治体配列
   *
   * @type {_Prefecture[]}
   * @memberof AllMunicipalitiesComponent
   */
  prefectures: _Prefecture[];

  /**
   * 戻るボタンを表示するかどうか
   *
   * @type {boolean}
   * @memberof AllMunicipalitiesComponent
   */
  isSplitter: boolean = false;

  /**
   * ツールバータイトル
   *
   * @type {string}
   * @memberof AllMunicipalitiesComponent
   */
  title: string;

  /**
   * Creates an instance of AllMunicipalitiesComponent.
   * @param {MunicipalityWebApiService} municipalityWebApiServ
   * @param {HttpErrorResponseParserService} errResServ
   * @memberof AllMunicipalitiesComponent
   */
  constructor(
    private municipalityWebApiServ: MunicipalityWebApiService,
    private errResServ: HttpErrorResponseParserService,
    private params: Params
  ) { }

  ngOnInit(): void {
    // ハンバーガーメニューからの遷移かどうか
    this.isSplitter = this.params.data.isSplitter ?? false;

    // ツールバータイトル
    this.title = this.isSplitter ? "利用自治体選択" : "Smart GOTO";
  }

  ngAfterViewInit(): void {
    setTimeout(() => {

      // 展開自治体一覧を取得
      this.busy = this.municipalityWebApiServ.getMunicipalities().subscribe({
        next: res => {
          let _municipalities: municipalities[] = res.body;

          // 表示用、都道府県配列取得
          this.getPrefecture(_municipalities);
        },
        error: this.errResServ.doParse((_err, errContent) => this.errResServ.viewErrDialog(errContent))
      });
    })
  }

  /**
   * 廃棄処理
   *
   * @memberof AllMunicipalitiesComponent
   */
  ngOnDestroy(): void {
    this.busy?.unsubscribe();
  }

  //=============================================================================================
  // メソッド
  //=============================================================================================

  /**
   * 表示用、都道府県配列取得
   *
   * @param {municipalities[]} _municipalities
   * @memberof AllMunicipalitiesComponent
   */
  getPrefecture(_municipalities: municipalities[]) {
    // 初期化
    this.prefectures = [];

    /** 追加済みの都道府県オブジェクトを記録するための都道府県コード配列 */
    const _prefetctures: string[] = [];

    for (const mun of _municipalities) {
      for (const add of mun.address) {
        if (!_prefetctures.includes(add.prefecture_code)) {
          // 都道府県オブジェクトを配列要素に追加
          this.prefectures.push({
            name: add.prefecture,
            kana: add.prefecture_kana,
            code: add.prefecture_code,
            cities: []
          });
          _prefetctures.push(add.prefecture_code);
        }
        // 自治体情報を市区町村配列に追加
        /** 自治体情報を追加する都道府県オブジェクト */
        const _prefecture = this.prefectures.find(pre => pre.code===add.prefecture_code);
        _prefecture.cities.push({
          name: add.city,
          kana: add.city_kana,
          municipal_id: mun.municipal_id,
          municipal_name: mun.name
        });
      }
    }

    // sort
    // 市区町村: 五十音順
    this.prefectures.forEach(pre => pre.cities.sort((a,b) => a.kana.localeCompare(b.kana)));
    // 都道府県: コード順(およそ北から南へ)
    this.prefectures.sort((a,b) => a.code.localeCompare(b.code));
  }

  /**
   * 選択した各自治体版サイトへ遷移
   *
   * @param {string} municipal_id
   * @memberof AllMunicipalitiesComponent
   */
  pushMunicipalVer(municipal_id: string): void {
    /** 操作代行アプリに含まれるURL */
    const PROXY_URL = "/operator/operation/proxy-app";
    if (location.href.includes(PROXY_URL)) {
      // 操作代行アプリの切替
      location.href = `/${municipal_id}${PROXY_URL}`;
    }
    else {
      // URLに自治体IDし、ページ遷移
      location.href = `/${municipal_id}`;
    }
  }
}

/**
 * 都道府県情報
 *
 * @interface _Prefecture
 */
interface _Prefecture {
  name: string;
  kana: string;
  /**
   * 都道府県コード
   */
  code: string;
  cities: _City[];
};

/**
 * 市区町村情報
 *
 * @interface _City
 */
interface _City {
  name: string;
  kana: string;
  municipal_id: string;
  /**
   * SmartGOTOの提供エリア名
   */
  municipal_name: string;
};