import { AfterViewInit, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { PLACE_MAP_MAP_OPTIONS, PLACE_MAP_SELECTED_MARKER_ICON } from '../../reservation/place-map/place-map';
import { Observable, Subscription } from 'rxjs';
import { SimpleChanges } from 'ngx-onsenui';

// module
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';

// service
import { SpotGeolocationService } from 'src/app/http-services/spot-geolocation.service'
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';

// interface
import { UserInfo } from 'src/app/interfaces/response';

@Component({
  selector: 'parts-location-update',
  templateUrl: './location-update.component.html',
  styleUrls: ['./location-update.component.scss']
})
export class LocationUpdateComponent implements OnInit, AfterViewInit, OnDestroy {

  /**
   * マップクリック、Subscription
   *
   * @private
   * @type {Subscription}
   * @memberof LocationUpdateComponent
   */
  private googleMapOnMapClick: Subscription;

  /**
   * 住所から緯度経度取得、Subscription
   *
   * @type {Subscription}
   * @memberof LocationUpdateComponent
   */
  getLocationSubscription: Subscription;

  /**
   * ズーム
   *
   * @type {number}
   * @memberof LocationUpdateComponent
   */
  zoom: number = 16;

  /**
   * エリアの代表地点
   *
   * @private
   * @type {google.maps.LatLngLiteral}
   * @memberof LocationUpdateComponent
   */
  private defaultSpotPosition: google.maps.LatLngLiteral;

  /**
   * マップの中心
   *
   * @type {google.maps.LatLngLiteral}
   * @memberof LocationUpdateComponent
   */
  center: google.maps.LatLngLiteral;

  /**
   * マーカー設定値
   * (center: マーカー設置位置, options: オプション)
   *
   * @type {{
   *     center: google.maps.LatLngLiteral,
   *   }}
   * @memberof LocationUpdateComponent
   */
  marker: {
    /**
     * マーカー設置位置
     *
     * @type {google.maps.LatLngLiteral}
     */
    center: google.maps.LatLngLiteral,
    
  } = {
    // 初期値null
    center: undefined,
  };

  /**
   * 住所(Input)
   *
   * @type {UserInfo["profile"]["address"]}
   * @memberof LocationUpdateComponent
   */
  @Input('address') readonly address: UserInfo["profile"]["address"];

  /**
   * 地点情報(緯度経度)登録の有無関係なく、初期ロードで住所⇒郵便番号の検索を行うかどうか
   *  note: 地点情報(緯度経度)が登録されていない場合、住所から郵便番号検索を行う
   * 
   * @type {boolean}
   * @memberof LocationUpdateComponent
   */
  @Input('isGetLocation') readonly isGetLocation: boolean;

  /**
   * 地点が変更された際に呼び出すoutput
   *
   * @type {(EventEmitter<google.maps.LatLngLiteral>)}
   * @memberof LocationUpdateComponent
   */
  @Output('locationEvent') locationEvent: EventEmitter<google.maps.LatLngLiteral> = new EventEmitter<google.maps.LatLngLiteral>();
  
  /**
   * googleMap
   *
   * @private
   * @type {GoogleMap}
   * @memberof LocationUpdateComponent
   */
  @ViewChild(GoogleMap) private googleMap: GoogleMap;

  /**
   * Creates an instance of LocationUpdateComponent.
   * @param {google.maps.MapOptions} mapOptions
   * @param {google.maps.Icon} markerIcon
   * @param {google.maps.LatLngLiteral} defaultSpotPosition
   * @param {SpotGeolocationService} spotGeolocationServ
   * @param {CommonFunctionModule} commonFunctionMdl
   * @memberof LocationUpdateComponent
   */
  constructor(
    @Inject(PLACE_MAP_MAP_OPTIONS) public mapOptions: google.maps.MapOptions,
    @Inject(PLACE_MAP_SELECTED_MARKER_ICON) private markerIcon: google.maps.Icon,
    private spotGeolocationServ: SpotGeolocationService,
    private commonFunctionMdl: CommonFunctionModule,
    private municipalityWebApiServ: MunicipalityWebApiService,
  ) {
    // エリアの代表地点
    this.defaultSpotPosition = this.municipalityWebApiServ.setting.map.representive_loc;
    // マップの中心
    this.center = this.defaultSpotPosition;
  }

  /**
   * Inputプロパティの変更検知
   *
   * @param {SimpleChanges} changes
   * @return {*} 
   * @memberof LocationUpdateComponent
   */
  ngOnChanges(changes: SimpleChanges): void {
  
    // 住所が取得できていない場合処理を行わない
    if (changes.address === void 0) return;

    // マーカーを設定
    this.setMarker(changes.address.currentValue);
  }

  /**
   * 初期処理
   *
   * @memberof LocationUpdateComponent
   */
  ngOnInit(): void {
  }

  /**
   *
   *
   * @memberof LocationUpdateComponent
   */
  ngAfterViewInit(): void {

    this.drawMarker();

    // マップクリック時の処理-------------------------------------------------------------------------
    setTimeout(() => {
      this.googleMapOnMapClick = this.googleMap.mapClick.subscribe(event => {
        // InfoWindow が表示されるのを防ぐ。
        event.stop();
  
        // クリックされた地点にマーカーを設定
        this.setMarkerCenter(event.latLng.lat(), event.latLng.lng());
  
        // 緯度経度を親にemit()
        this.updateLocation();
      })
    })
  }

  /**
   * 廃棄処理
   *
   * @memberof LocationUpdateComponent
   */
  ngOnDestroy(): void {
    this.getLocationSubscription?.unsubscribe();
    this.googleMapOnMapClick?.unsubscribe();
  }

//=============================================================================================
// メソッド
//=============================================================================================
  
  /**
   * markerを設定
   * 地図の中心にマーカーが来るように変更
   *
   * @param {UserInfo["profile"]["address"]} address
   * @memberof LocationUpdateComponent
   */
  setMarker(address: UserInfo["profile"]["address"]): void {

    /**
     * マーカーが設置されている場合、地図の初期表示をマーカーの位置に変更
     * 緯度経度を親にemit()
     */
    const updateMap = () => {

      // マーカーが設置されている場合
      if (this.marker.center) {
        this.center = this.commonFunctionMdl.deepcopy(this.marker.center);
      
        this.updateLocation();
      }
    }

    // 位置情報検索フラグがtrueかつ、addressに緯度経度情報が含まれていない場合、住所から緯度経度を取得しマーカー設置
    if (this.isGetLocation || !this.address.location) {
      // 住所から緯度経度を取得
      this.getLocationSubscription = this.spotGeolocationServ.getLocationByAddress(
        address.zip + address.prefecture + address.city + address.address1 + address.address2 + address.address3
        ).subscribe({
        next: location => {

          // 緯度経度
          this.setMarkerCenter(location.lat(), location.lng());

          updateMap();
        },

        // 入力した住所⇒該当する緯度経度がない
        error: () => {
          this.updateLocation(false);
        },
        complete: () => {}
      });
    }
    // 緯度経度がaddressに含まれている場合、その値を使用しマーカー設置
    else {
      this.setMarkerCenter(address.location.lat, address.location.lng);

      updateMap();
    }
  }

  private drawn_marker : google.maps.marker.AdvancedMarkerElement;

  /**
   * 位置を更新
   * @param lat 
   * @param lng 
   */
  private setMarkerCenter(lat: number, lng: number) {
    this.marker.center = {lat: lat, lng: lng};

    // note: google map 描画前に呼ばれることがある
    this.drawMarker();
  }

  /**
   * マーカー描画
   */
  private drawMarker() {
    // 描画済みのものを削除
    if (this.drawn_marker) {
      this.drawn_marker.map = null;
      this.drawn_marker.remove();
    }

    if (this.googleMap && this.marker.center) {
      const options: google.maps.marker.AdvancedMarkerElementOptions = {
        map: this.googleMap.googleMap,      
        position: new google.maps.LatLng(this.marker.center),
        content: CommonFunctionModule.convertIconToContent(this.markerIcon),
      }
      
      this.drawn_marker = new google.maps.marker.AdvancedMarkerElement(options);
    }
  }


  /**
   * (緯度経度を取得出来た場合)
   * 親コンポーネントに選択中の地点(緯度経度)をemit()
   *
   * @param {boolean} [success=true]
   * @memberof LocationUpdateComponent
   */
  updateLocation(success: boolean = true): void {

    setTimeout(() => {
      // 緯度経度取得成功
      if (success) this.locationEvent.emit(this.marker.center);
      // 失敗
      else this.locationEvent.emit(undefined);
    }) 
  }
}
