//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Subscription, Observable, fromEvent } from 'rxjs';
import { OnsNavigator } from 'ngx-onsenui';
import * as moment from 'moment-timezone';
import * as CONST from '../../constants/constant';
import * as uuid from 'uuid';

// service
import { ApplicationMessageService } from '../..//lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../..//lib-services/http-error-response-parser.service';
import { UserWebApiService } from '../../http-services/user-web-api.service';
import { FamilyWebApiService } from '../../http-services/family-web-api.service';
import { ShoppingWebApiService } from "../../http-services/shopping-web-api.service";
import { HistoryWebApiService } from '../../http-services/history-web-api.service';
import { OrderRequestService } from '../../lib-services/order-request.service';
import { AllocationConditionService } from '../../lib-services/allocation-condition-service';
import { CommonFunctionModule } from '../../lib-modules/common-function.module';
import { SelectHistoryWebApiService } from 'src/app/http-services/select-history-web-api.service';
import { PagerService } from 'src/app/lib-services/pager.service';

// component
import { AppComponent } from 'src/app/app.component';
import { TabbarComponent } from '../tabbar/tabbar.component';
import { UseListComponent, UseListTabTag } from '../service/use-list/use-list.component';
import { SigninComponent } from '../signin/signin.component';
import { ShoppingMenuListComponent } from './shopping-menu-list/shopping-menu-list.component';
import { SearchMethodListComponent } from '../reservation/search-method-list/search-method-list.component';
import { UserReservation } from '../reservation/user-reservation/user-reservation';

// interface
import { Shop, UserInfo } from '../../interfaces/response';
import { ShopSelectHistory } from 'src/app/http-services/select-history-web-api.service';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 店舗一覧。
 *
 * @export
 * @class ShoppingComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'ons-page[shopping]',
  templateUrl: './shopping.component.html',
  styleUrls: ['./shopping.component.scss']
})
export class ShoppingComponent implements OnInit, OnDestroy {

//=============================================================================================
// メンバ変数
//=============================================================================================

  /**
   * 通信用Subscription
   *
   * @type {Subscription}
   * @memberof NewsArticleListComponent
   */
  busy: Subscription;

  /**
   * ユーザ変更Subscription
   *
   * @type {Subscription}
   * @memberof ShoppingComponent
   */
  onUserChanged: Subscription;

  /**
   * 店舗一覧
   *
   * @type {Shop[]}
   * @memberof ShoppingComponent
   */
  shops: Shop[] = undefined;

  /**
   * 検索ワード
   *
   * @type {string}
   * @memberof ShoppingComponent
   */
  freeword: string;

  /**
   * 検索履歴
   *
   * @type {string[]}
   * @memberof ShoppingComponent
   */
  searchHistory: string[] = [];

  /**
   * 検索履歴のフィルタリング
   *
   * @type {Observable<string[]>}
   * @memberof ShoppingComponent
   */
  filteredOptions: Observable<string[]>;

  /**
   * moment
   *
   * @memberof ShoppingComponent
   */
  readonly moment = moment;

  /**
   * 検索フォームにフォーカスがある場合履歴を再取得しない
   *
   * @type {boolean}
   * @memberof ShoppingComponent
   */
  oneFocus: boolean = false;

  /**
   * onsNavigatorのページIndex
   *
   * @type {number}
   * @memberof ShoppingComponent
   */
  pageIndex: number;

  /**
   * 買い物機能TOPページ(useListComponentのindex番号)
   *
   * @type {number}
   * @memberof ShoppingComponent
   */
  shoppingTopPageIndex: number;

  /**
   * 遷移先でページスタックのリフレッシュメソッドを使うためのオブジェクト変数
   *
   * @type {*}
   * @memberof ShoppingComponent
   */
  shoppingReturnClass: any;

  /**
   * 買い物タブタップ時の再読み込み用subscription
   *
   * @type {Subscription}
   * @memberof ShoppingComponent
   */
  shoppingTabClickSubscription: Subscription

  /**
   * 検索履歴のオートコンプリート
   *
   * @type {MatAutocompleteTrigger}
   * @memberof ShoppingComponent
   */
  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger }) 
  autoComplete: MatAutocompleteTrigger;

  /**
   * 検索条件クラス
   *
   * @type {UserReservation.SearchCondition}
   * @memberof ShoppingComponent
   */
  searchCondition: UserReservation.SearchCondition;

  /**
   * assetsファイルへのパス(定数)
   *
   * @type {string}
   * @memberof ShoppingComponent
   */
  readonly ASSETS_ORDER_DESTINATION: string = CommonFunctionModule.getAssetsUrl('/image/common/Order_Destination.png');
//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of ShoppingComponent.
   * @param {ApplicationMessageService} appMsgServ
   * @param {HttpErrorResponseParserService} errResServ
   * @param {UserWebApiService} userServ
   * @param {FamilyWebApiService} familyServ
   * @param {ShoppingWebApiService} shoppingServ
   * @param {HistoryWebApiService} historyServ
   * @param {OnsNavigator} navigator
   * @param {UseListComponent} useListComp
   * @param {TabbarComponent} tabbarComp
   * @param {SelectHistoryWebApiService} selectHistoryServ
   * @memberof ShoppingComponent
   */
  constructor(
    private appComp: AppComponent, 
    private appMsgServ: ApplicationMessageService, 
    private errResServ: HttpErrorResponseParserService, 
    private userServ: UserWebApiService, 
    private familyServ: FamilyWebApiService, 
    private shoppingServ: ShoppingWebApiService, 
    private historyServ: HistoryWebApiService, 
    private allocationServ: AllocationConditionService, 
    private orderServ: OrderRequestService, 
    private commonFunction: CommonFunctionModule, 
    private navigator: OnsNavigator, 
    private useListComp: UseListComponent, 
    private tabbarComp: TabbarComponent,
    private pagerServ: PagerService, 
    private selectHistoryServ: SelectHistoryWebApiService,
    private msg: MESSAGE,
  ) { }

  /**
   * 初期処理。
   *
   * @memberof ShoppingComponent
   */
  ngOnInit(): void {

    // Componentのページ番号を記録
    this.pageIndex = this.navigator.element.pages.length - 1;
    this.shoppingTopPageIndex = this.pageIndex;

    /**
     * ページスタックをリフレッシュするクラス
     *
     * @class PageStackRefresh
     */
    class PageStackRefresh {
      constructor(private component: ShoppingComponent) { }

      /**
       * ページスタックをリフレッシュ
       *
       * @param {number} [backPageIndex]
       * @memberof PageStackRefresh
       */
      refresh(backPageIndex?: number) {

        this.component.refreshPageStack(backPageIndex);
      }

      /**
       * 画面再描画
       *
       * @memberof PageStackRefresh
       */
      redraw(): void {
        
        // 店舗一覧を取得
        this.component.getShopList();
      }
    }

    // クラス構築 
    this.shoppingReturnClass = new PageStackRefresh(this);

    // タブクリックで新規情報読み込み
    this.shoppingTabClickSubscription = this.useListComp.tabClickSubject.subscribe({
      next: (tag: UseListTabTag) => {
        if (tag === 'shopping') {
          if (this.navigator.element.pages.length === 1) {

            // 未ログインの場合は、ログインまたはアカウント作成を促すメッセージを表示し、サインイン画面へ遷移
            if (false === this.userServ.isLoggedIn()) {
              this.appMsgServ.viewDialogMessage(this.msg.CLIENT.COMMON.E_UNLOGIN_USE_FUNCTION.message(), () => {
                this.pagerServ.setUserAppNavigator = true;
                this.pagerServ.getAppNavigator.element.pushPage(SigninComponent, { data: { getShopList: () => this.getShopList(), checkDeliverable: () => this.checkDeliverable() }});
              });
    
              return;
            }
            
            // ファミリー管理者から制限されている場合は遷移不可
            if (this.familyServ.isLoginUserUtilization() === true) {
              this.appMsgServ.viewDialogMessage(this.msg.CLIENT.FAMILY.RESTRICTED_UTILIZATION.message());
    
              // おでかけタブ選択
              this.useListComp.firstTabClick();
              return;
            }
    
            // 買い物タブ以外から遷移してきた場合のみ
            if (this.useListComp.transitionTabHistory.before !== this.useListComp.transitionTabHistory.active) {
              // お届け可能かチェック
              this.checkDeliverable(true);
            }
    
            // 店舗一覧を取得
            this.getShopList();
          }
        }
      }
    });
  }

  /**
   * afterViewInit
   *
   * @memberof ShoppingComponent
   */
  ngAfterViewInit(): void {

    // ユーザ情報がnull（ログアウトした）なら店舗一覧を初期化
    // HACK: 必要のないタイミングでお届け可能チェックがされる
    this.onUserChanged = this.userServ.userInfoChanged.subscribe({
      next: user => {
        if (!this.userServ.isLoggedIn()) setTimeout(() => {
          this.shops = undefined;
        });
        else {

          // ユーザ情報変更時、表示中のタブが「利用中」「買い物」で店舗情報が取得できていない場合、店舗情報を取得
          // note: 未ログイン状態で買い物タブをタップしたときを想定
          if (this.tabbarComp.getActiveTab()===this.tabbarComp.tabComponent.reservation && this.useListComp.getActiveTabTag()===CONST.Common.SERVICE_TAG.SHOPPING && this.shops===undefined) {
            this.getShopList();
          }

          setTimeout(() => {
            // 住所登録がある場合は初期値を住所とする
            // if (this.orderServ.deliveryPlace.address === undefined) return;

            // 住人ユーザは住所必須のため登録住所を設定
            if (user.user_type === 'citizen') {
              this.orderServ.setDeliveryRegisteredAddress(user.profile.address);
              this.allocationServ.setSpot('order', { registerAddress: true });

              // お届け可能かチェック
              this.checkDeliverable(false);
            }
            // 観光目的ユーザの住所が都道府県～番地まで設定されている場合、登録住所を設定
            else if (user.user_type === 'tourist' && true === this.commonFunction.checkAddressValidity(user.profile.address)) {
              this.orderServ.setDeliveryRegisteredAddress(user.profile.address);
              this.allocationServ.setSpot('order', { registerAddress: true });

              // お届け可能かチェック
              this.checkDeliverable(false);
            }
            else this.orderServ.deliveryPlace = { name: "未設定" };
          });
        }
      }
    });
  }

  /**
   * 破棄処理。
   *
   * @memberof ShoppingComponent
   */
  ngOnDestroy(): void {

    this.busy?.unsubscribe();
    this.onUserChanged?.unsubscribe();
    this.shoppingTabClickSubscription?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================
  
  changeDeliveryAddress(): void {

    this.navigator.element.pushPage(SearchMethodListComponent, { data: { kind: "order", callMethod: () => this.checkDeliverable() }});
  }

  /**
   * お届け先（表示名）を取得する。
   *
   * @return {*}  {string}
   * @memberof ShoppingComponent
   */
  getDeliveryAddress(): string {

    return this.orderServ.deliveryPlace.name;
  }
  
  /**
   * 店舗選択時のイベントハンドラ。
   *
   * @param {Shop} shop
   * @memberof ShoppingComponent
   */
  onShopSelect(shop: Shop): void {
    
    // メニュー一覧へ遷移
    this.navigator.element.pushPage(ShoppingMenuListComponent, { data: { shop: shop, class: this.shoppingReturnClass }});

    // --------------------------------------------------
    // 操作履歴の送信
    // --------------------------------------------------
    
    // ログインユーザー情報
    const loginUserInfo: UserInfo = this.userServ.getUserInfo();

    // 店舗の操作履歴送信用データを作成
    const selectHistory: ShopSelectHistory = {
      id: uuid.v4(),
      user_id: loginUserInfo.user_id,
      date: moment().toISOString(),
      type: "shop",
      service: "shopping",
      shop: {
        shop_id: Number(shop.shop_id)
      }
    }

    // 記事の操作履歴をサービスに追加
    this.selectHistoryServ.addHistory(selectHistory);

  }

  /**
   * 検索フォームフォーカス時のイベントハンドラ。
   *    検索履歴を表示する。
   *
   * @memberof NewsArticleListComponent
   */
  onSearchFocus(): void {

    // 検索履歴取得、表示
    if (this.oneFocus === false) {
      this.searchHistory = [];
      this.getSearchHistory(CONST.Search.HISTORY);
    }

    // フォーカスがある状態でのclickイベント無効化
    this.oneFocus = true;
  }

  /**
   * 検索フォームからフォーカスが外れた際のイベントハンドラ。
   *
   * @memberof NewsArticleListComponent
   */
  onSearchBlur(): void {

    // clickイベントを有効化
    this.oneFocus = false;
  }

  /**
   * 検索履歴選択時のイベントハンドラ。
   *
   * @memberof NewsArticleListComponent
   */
  onSearchHistorySelect(): void {
    
    // 検索履歴パネルを閉じる
    this.autoComplete.closePanel();
    
    // 選択項目でフリーワード検索
    this.onSearch();
  }

  /**
   * 検索履歴削除ボタン押下時のイベントハンドラ。
   *
   * @param {string} word
   * @param {boolean} [all]
   * @memberof NewsArticleListComponent
   */
  onDeleteSearchHistory(event: any, index: number, word: string, all?: boolean): void {
    
    // clickイベントの伝播を防止
    event.stopPropagation();
    event.preventDefault();

    const query = { word: word, all: all };
    if (all === true) delete query.word;
    if (all === undefined) delete query.all;
    
    // 検索履歴削除
    this.deleteSearchHistory(query, index);
  }

  /**
   * フリーワード検索実施時のイベントハンドラ。
   *
   * @memberof NewsArticleListComponent
   */
  onSearch(): void {

    // 全角スペースを半角に置換、前後のスペース除去
    let word = this.freeword.replace(/　/g, " ");
    this.freeword = word.trim();

    if (this.freeword === "") this.freeword = undefined; 
    
    // 店舗一覧を取得
    this.getShopList();
    
    // 検索履歴パネルを閉じる
    this.autoComplete.closePanel();

    // INPUTタグからフォーカスを外す
    document.getElementById("shoppingHistoryInput").blur();
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * 買い物機能トップページに戻る
   *
   * @private
   * @param {number} [backPageIndex]
   * @memberof ShoppingComponent
   */
  private refreshPageStack(backPageIndex?: number): void {
    
    // 現在のページ番号
    const currentIndex = this.navigator.element.pages.length - 1;

    // 戻り先が定義されているなら設定
    if (undefined !== backPageIndex) this.pageIndex = backPageIndex;

    if (this.pageIndex < currentIndex) {

      // タブバー表示
      this.tabbarComp.setTabbarVisibility(true);
      
      // ページスタックの先頭がpageIndexになるまで繰り返し
      this.navigator.element.removePage(this.pageIndex + 1, { animation: "lift" })
      .then(() => {
        setTimeout(() => {
          this.refreshPageStack(backPageIndex);
        }, 0);
      });
    }
    else if (this.pageIndex === currentIndex) {
    
      this.pageIndex = this.shoppingTopPageIndex;
    }
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * 店舗一覧を取得する。
   *
   * @private
   * @memberof ShoppingComponent
   */
  private getShopList(): void {

    this.busy = this.shoppingServ.getShopList(this.freeword).subscribe({ 
      next: res => this.shops = res.body,
      error: this.errResServ.doParse((_err, errContent) => this.errResServ.viewErrDialog(errContent))
    });
  }

  /**
   * 配達可能住所かチェックする。
   *
   * @private
   * @memberof ShoppingComponent
   */
  private checkDeliverable(toast: boolean = true): void {
    
    // 未設定なら終了
    if (!this.orderServ.deliveryPlace.address && !this.orderServ.deliveryPlace.location) return;
    // 緯度経度が無ければエラー(登録住所を選択時にありうる)
    const location = this.orderServ.deliveryPlace.location;
    if (!location || !location.lat || !location.lng) {
      if (toast === true) this.appMsgServ.viewToastMessage(this.msg.CLIENT.SHOPPING.NO_LATLNG.message());
      return;
    }

    this.busy = this.shoppingServ.checkDeliverable(this.orderServ.deliveryPlace.address, this.orderServ.deliveryPlace.location).subscribe({ 
      next: res => {
        if (res.body.length === 0 && toast === true) {
          this.appMsgServ.viewToastMessage(this.msg.CLIENT.SHOPPING.ERR_OUT_RANGE_DELIVERY.message());
        }
      },
      error: this.errResServ.doParse((_err, errContent) => {

        // 緯度経度が無ければエラー(基本サーバー通信前に除外している)
        if (this.appMsgServ.SERV_CONST_CODE.COMMON.LOCATION_NOT_EXIST) {
          this.appMsgServ.viewDialogMessage(this.msg.CLIENT.SHOPPING.NO_LATLNG.message());
        }
        else this.errResServ.viewErrDialog(errContent);
      })
    });
  }

  /**
   * 検索履歴を取得する。
   *
   * @private
   * @param {number} limit
   * @memberof NewsArticleListComponent
   */
  private getSearchHistory(limit: number): void {

    this.busy = this.historyServ.getSearchHistory("shopping", limit).subscribe({
      next: res => this.searchHistory = res.body, 
      error: this.errResServ.doParse((_err, errContent) => this.errResServ.viewErrDialog(errContent))
    });
  }

  /**
   * 検索履歴を削除する。
   *
   * @private
   * @param {{ word?: string, all?: boolean }} query
   * @param {number} index
   * @memberof NewsArticleListComponent
   */
  private deleteSearchHistory(query: { word?: string, all?: boolean }, index: number): void {

    this.busy = this.historyServ.deleteSearchHistory("shopping", query).subscribe({
      next: () => index === -1 ? this.searchHistory = [] : this.searchHistory.splice(index, 1), 
      error: this.errResServ.doParse((_err, errContent) => this.errResServ.viewErrDialog(errContent))
    });
  }
}
