import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { OnsNavigator } from 'ngx-onsenui';

// service
import { UserWebApiService } from 'src/app/http-services/user-web-api.service';
import { HttpErrorResponseParserService } from 'src/app/lib-services/http-error-response-parser.service';
import { ApplicationMessageService } from 'src/app/lib-services/application-message.service';
import { MESSAGE } from 'src/app/constants/message';

@Component({
  selector: 'ons-page[app-pincode-auth-method]',
  templateUrl: './pincode-auth-method.component.html',
  styleUrls: ['./pincode-auth-method.component.scss']
})
export class PincodeAuthMethodComponent implements OnInit, OnDestroy {

  /**
   * 通信監視用Subscription
   *
   * @type {Subscription}
   * @memberof PincodeAuthMethodComponent
   */
  busy: Subscription;

  /**
   * 認証方法
   *
   * @type {AuthMethod}
   * @memberof PincodeAuthMethodComponent
   */
  method: AuthMethod;

  /**
   * メールアドレス認証
   * checkboxの選択状態
   *
   * @type {boolean}
   * @memberof PincodeAuthMethodComponent
   */
  authMailAddress: boolean = false;

  /**
   * SMS認証
   * checkboxの選択状態
   *
   * @type {boolean}
   * @memberof PincodeAuthMethodComponent
   */
  authSms: boolean = false;

  /**
   * Creates an instance of PincodeAuthMethodComponent.
   * @param {UserWebApiService} userWebApiServ
   * @param {HttpErrorResponseParserService} errResServ
   * @param {ApplicationMessageService} appMsgServ
   * @param {OnsNavigator} navigator
   * @memberof PincodeAuthMethodComponent
   */
  constructor(
    private userWebApiServ: UserWebApiService,
    private errResServ: HttpErrorResponseParserService,
    private appMsgServ: ApplicationMessageService,
    private navigator: OnsNavigator,
    private msg: MESSAGE,
  ) { }

  ngOnInit(): void {
    // 初期表示
    this.getLatestUser();
  }

  ngOnDestroy(): void {
    this.busy?.unsubscribe();
  }

// ========================================================================================================================
// イベントハンドラ
// ========================================================================================================================

  /**
   * チェックボックスの状態変更
   *
   * @param {AuthMethod} auth
   * @memberof PincodeAuthMethodComponent
   */
  onChangeCheckBox(auth: AuthMethod): void {

    this.method = auth;

    // 二段階認証方法は一つのみ設定可能なため、
    // 選択したもの以外のチェックボックスを非選択に
    if (this.method == AUTH_METHOD.mail) {
      this.authMailAddress = true;
      this.authSms = false;
    }
    else if (this.method == AUTH_METHOD.sms) {
      this.authMailAddress = false;
      this.authSms = true;
    }
    // 想定外
    else {
      this.appMsgServ.viewDialogMessage(this.msg.CLIENT.COMMON.E_UNEXPECTED.message(), () => {
        // マイページメニュー画面に戻る
        return this.navigator.element.popPage();
      });
    }

    // 二段階認証方法変更
    this.updatePincodeMethod();
  }

// ========================================================================================================================
// サーバー通信
// ========================================================================================================================

  /**
   * 最新のユーザ情報を取得。
   * 　初期表示する二段階認証方法を設定。
   *
   * @memberof PincodeAuthMethodComponent
   */
  private getLatestUser(): void {

    // 最新のユーザ情報から、現在選択されている二段階認証方法を取得。
    this.busy = this.userWebApiServ.getLatestUser().subscribe({
      next: res => {
        // 二段階認証方法
        this.method = res.body.pincode;

        // 初期表示(ユーザ情報に登録されている二段階認証方法をチェック)
        if (this.method == AUTH_METHOD.mail) this.authMailAddress = true;
        else if (this.method == AUTH_METHOD.sms) this.authSms = true;
        else {
          this.appMsgServ.viewDialogMessage(this.msg.CLIENT.COMMON.E_UNEXPECTED.message(), () => {
            // マイページメニュー画面に戻る
            this.navigator.element.popPage();
          });
        }
      },
      error: this.errResServ.doParse((_err, errContent) => this.errResServ.viewErrDialog(errContent))
    })
  }

  /**
   * PINコード送付先変更。
   * 変更できなかった場合レスポンスに基づいて、チェックボックスの状態を更新。
   *
   * @private
   * @memberof PincodeAuthMethodComponent
   */
  private updatePincodeMethod(): void {

    // PINコード送付先変更
    this.busy = this.userWebApiServ.updatePincodeMethod(this.method).subscribe({
      next: () => {},
      error: this.errResServ.doParse((_err, errContent) => {
        // パラメータ不正(電話番号 or メールアドレス未登録)
        if (errContent.smartGotoErrCode == this.appMsgServ.SERV_CONST_CODE.USER.ILLEGAL_PINCODE) {
          // 電話番号未登録
          if (errContent.smartGotoErrOpt?.pincode == AUTH_METHOD.sms) {
            this.appMsgServ.viewDialogMessage(this.msg.CLIENT.PINCODE.UNREGISTERED_TEL.message(), () => {
              // メールアドレス選択状態に戻す
              this.authSms = false;
              this.authMailAddress = true;
            });
          }
          // note: 現状、メールアドレス未登録は想定外であるため、マイページメニュー画面に戻る
          else {
            this.appMsgServ.viewDialogMessage(this.msg.CLIENT.COMMON.E_UNEXPECTED.message(), () => {
              // マイページメニュー画面に戻る
              this.navigator.element.popPage();
            });
          }
        }
        // エラーコードに基づくメッセージ
        else this.errResServ.viewErrDialog(errContent).then(() => {
          // マイページメニュー画面に戻る
          this.navigator.element.popPage();
        });
      })
    })
  }
}

/**
 * 二段階認証方法
 */
const AUTH_METHOD = {
  mail: 'mail',
  sms: 'sms'
} as const;

/**
 * 二段階認証方法
 */
type AuthMethod = typeof AUTH_METHOD[keyof typeof AUTH_METHOD];