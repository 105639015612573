<ons-toolbar>
    <div class="left ">
        <ons-back-button></ons-back-button>
    </div>
    <div class="center ">{{targetPlaceKindName}}の検索</div>
</ons-toolbar>
<div class="content">
    <div [ngBusy]="[busyPosition, busyMySpots, busySearchHistory]"></div>

    <ons-list class="list-wrap">
        <ons-list-item id="search-method-list_001" *ngIf="targetPlaceKind !== 'order'; else modeShopping" tappable class="single-list-item" (click)="selectCurrentPosition()">
            <div class="list-item__title">
                <img class="list-icon" [src]="ASSETS.MARKER" />
                <p>現在地</p>
            </div>
        </ons-list-item>
        <ng-template #modeShopping>
          <ons-list-item id="search-method-list_003" *ngIf="viewRegistAddress" tappable class="single-list-item" (click)="selectRegistedAddress()">
              <div class="list-item__title">
                  <img class="list-icon" [src]="ASSETS.NUMBER" />
                  <p>登録住所</p>
              </div>
          </ons-list-item>
        </ng-template>
    </ons-list>

    <ons-list class="list-wrap">
        <ons-list-item id="search-method-list_002" tappable modifier="chevron" class="single-list-item single-list-item--chevron"
            (click)="selectByMap()">
            <div class="list-item__title">
                <img class="list-icon" [src]="ASSETS.MAP_POINT" />
                <p>地図から指定する</p>
            </div>
        </ons-list-item>
    </ons-list>

    <ons-list class="list-wrap">
        <ons-list-item id="search-method-list_003" expandable modifier="parent">
            <div class="list-item__title">
                <img class="list-icon" [src]="ASSETS.INACTIVE" />
                <p>マイスポットから探す</p>
            </div>
            <ons-list [ngSwitch]="mySpots.length" modifier="noIconList" class="expandable-content">
                <ng-container *ngSwitchCase="0">
                    <ons-list-item class="expandable-content__item">
                        <p>(なし)</p>
                    </ons-list-item>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <ons-list-item class="expandable-content__item" tappable modifier="longdivider"
                        *ngFor="let item of mySpots" (click)="selectMySpot(item)">
                        <p>{{item.name}}</p>
                    </ons-list-item>
                </ng-container>
            </ons-list>
        </ons-list-item>
    </ons-list>
</div>