import { Pipe, PipeTransform } from '@angular/core';
import { CommonFunctionModule } from "./common-function.module";
import * as CONST from "../constants/constant";
import * as moment from 'moment';

/**
 * 日時修飾パイプ。
 *
 * @export
 * @class ChatDatePipe
 * @implements {PipeTransform}
 */
@Pipe({
  name: 'chatDate'
})
export class ChatDatePipe implements PipeTransform {

  constructor(
    private commonFunct: CommonFunctionModule,
  ) { }

  /**
   * 日時を指定のフォーマットに変換する。
   *
   * @param {string} value
   * @param {number} [type=1]
   * @return {*}  {string}
   * @memberof ChatDatePipe
   */
  transform(value: string, format: string, zero_padding: boolean = true): string {

    switch (format) {
      case "DATE":
        return this.commonFunct.transformDateTime(value, CONST.Common.MOMENT_FORMAT.DATE);
      case "DATE_MIN":
        return this.commonFunct.transformDateTime(value, CONST.Common.MOMENT_FORMAT.DATE_MIN);
      case "TIME":
        if (zero_padding) return this.commonFunct.transformDateTime(value, CONST.Common.MOMENT_FORMAT.TIME_ZERO);
        else return this.commonFunct.transformDateTime(value, CONST.Common.MOMENT_FORMAT.TIME);
      case "DATETIME":
        if (zero_padding) return this.commonFunct.transformDateTime(value, CONST.Common.MOMENT_FORMAT.DATE_TIME_ZERO);
        else return this.commonFunct.transformDateTime(value, CONST.Common.MOMENT_FORMAT.DATETIME);
      default:
        return this.commonFunct.transformDateTime(value, format);
    }
  }
}
