//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { OnsNavigator, Params } from 'ngx-onsenui';
import * as _ from 'lodash';

// service
import { PagerService } from '../../../lib-services/pager.service';
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { UserWebApiService } from '../../../http-services/user-web-api.service';

// component
import { ProfileListComponent } from '../profile-list/profile-list.component';
import { EDIT_MODE, ProfileLocationEditComponent } from 'src/app/components/location/profile-location-edit/profile-location-edit.component';

// module
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';

// interface
import { UserInfo } from '../../../interfaces/response';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

@Component({
  selector: 'ons-page[profile-edit]',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.css']
})
export class ProfileEditComponent implements OnInit, OnDestroy 
{

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * HTTP通信購読用
   *
   * @type {Subscription}
   * @memberof ProfileEditComponent
   */
  busy: Subscription;

  /**
   * ユーザ情報
   *
   * @type {UserInfo}
   * @memberof ProfileEditComponent
   */
  item: UserInfo;

  /**
   * 郵便番号空白時のメッセージ
   *
   * @memberof ProfileEditComponent
   */
  zipEmptyMessage = "";

  /**
   * 住所自動入力完了フラグ
   *
   * @memberof ProfileEditComponent
   */
  m_AddressAutoCompleted = false;

  /**
   * geocoder
   *
   * @private
   * @type {google.maps.Geocoder}
   * @memberof ProfileEditComponent
   */
  private geocoder: google.maps.Geocoder;

  /**
   * ボタン種別
   *
   * @type {('tel' | 'mail' | 'address')}
   * @memberof ProfileEditComponent
   */
  editKey: 'tel' | 'mail' | 'address';

  /**
   * 住所を編集したかどうか
   *
   * @type {boolean}
   * @memberof ProfileEditComponent
   */
  addressEditted: boolean = false;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of ProfileEditComponent.
   * @param {OnsNavigator} _navigator
   * @param {ApplicationMessageService} appMsg
   * @param {HttpErrorResponseParserService} httpErrorResponseParserService
   * @param {ChangeDetectorRef} changeDetectorRef
   * @param {UserWebApiService} userWebApiService
   * @param {Params} _params
   * @memberof ProfileEditComponent
   */
  constructor(
    private _navigator: OnsNavigator, 
    private pager: PagerService, 
    private appMsg: ApplicationMessageService, 
    private httpErrorResponseParserService: HttpErrorResponseParserService, 
    private changeDetectorRef: ChangeDetectorRef, 
    private userWebApiService: UserWebApiService, 
    private _params: Params,
    private commonFunctionMdl: CommonFunctionModule,
    private msg: MESSAGE,
  ) { }

  /**
   * 初期化処理
   *
   * @memberof ProfileEditComponent
   */
  ngOnInit(): void 
  {
    // this.item=_.cloneDeep(this._params.data.user);
    this.item = this.commonFunctionMdl.deepcopy(this._params.data.user);
    this.editKey=this._params.data.editKey;
  }

  /**
   * 描画後の初期化処理。
   *
   * @memberof ProfileEditComponent
   */
  ngAfterViewInit(): void 
  {
    this.geocoder=new google.maps.Geocoder()
  }

  /**
   * 破棄処理。
   *
   * @memberof ProfileEditComponent
   */
  ngOnDestroy(): void 
  {
    this.busy?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * プロフィールを更新する。
   *
   * @memberof ProfileEditComponent
   */
  edit(): void {

    // 編集したデータ
    let reqBody: {[editKey: string]: any };

    // 電話番号
    if (this.editKey == "tel") reqBody = {tel: this.item.profile.tel};
    // メールアドレス
    else if (this.editKey == "mail") reqBody = {mail: this.item.profile.mail};
    // // 住所(address)
    // else if (this.editKey == "address") reqBody = {address: this.item.profile.address};
    // それ以外
    else reqBody = this.item.profile;

    this.busy = this.userWebApiService.updateProfile(reqBody).subscribe({
      next: () => {
        this.userWebApiService.getLatestUserInfo().subscribe({
          next: () => {
            this.appMsg.viewDialogMessage(this.msg.CLIENT.COMMON.COMPLETE_REGIST.message(), () => {
              this.pager.transitionToTopPage(this._navigator, ProfileListComponent);
            });
          }
        });
      },
      error: this.httpErrorResponseParserService.doParse((_err, errContent) => {
        this.httpErrorResponseParserService.viewErrDialog(errContent);
      })
    });
  }

  /**
   * 住所地点(緯度経度)編集画面へ遷移する。
   *
   * @memberof ProfileEditComponent
   */
  pushAddressEdit(): void {

    // 登録されている住所と入力中の住所に変更があるかチェック
    this.addressEditted = this.userWebApiService.checkChangeAddress(this.userWebApiService.getUserInfo().profile.address, this.item.profile.address);

    // 住所地点(緯度経度)編集画面へ遷移
    this._navigator.element.pushPage(ProfileLocationEditComponent, {data: {
      address: this.item.profile.address,
      addressEditted: this.addressEditted,
      mode: EDIT_MODE.user
    }});
  }

  /**
   * 郵便番号から住所を検索する。
   *
   * @memberof ProfileEditComponent
   */
  addressAutocomplete(): void 
  {
    this.zipEmptyMessageClear();
    this.geocoder.geocode({
      address: `${this.item.profile?.address?.zip??""}`
    }, (results, status) => {
      if ((status!==google.maps.GeocoderStatus.OK)||!results[0]) 
      {
        this.zipEmptyMessage = this.msg.CLIENT.PROFILE.INVALID_ZIP.message();
        return;
      }
      const addressComponents=results[0].address_components;
      const getLongName=(type: string): string => addressComponents.find(e => e.types.some(x => x===type))?.long_name?? '';

      this.item.profile.address.prefecture=getLongName('administrative_area_level_1');
      this.item.profile.address.city=`${getLongName('locality')}${getLongName('sublocality_level_1')}`;
      this.item.profile.address.address1=`${getLongName('sublocality_level_2')}${getLongName('sublocality_level_3')}`;
      this.item.profile.address.address2=getLongName('sublocality_level_4');
      this.item.profile.address.address3=getLongName('premise');

      // 住所自動入力完了フラグON
      this.m_AddressAutoCompleted = true;

      this.changeDetectorRef.detectChanges();
    });
  }

  /**
   * 郵便番号エラーメッセージを初期化する。
   *
   * @memberof ProfileEditComponent
   */
  zipEmptyMessageClear(): void 
  {
    this.zipEmptyMessage = "";
  }

//=============================================================================================
// メソッド
//=============================================================================================
}
