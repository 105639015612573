import { Component, OnInit } from '@angular/core';
import { Params } from 'ngx-onsenui';
import { UserReservation } from '../user-reservation/user-reservation';

/**
 * 荷物積載情報指定画面。
 *
 * @export
 * @class BaggageComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'ons-page[baggage]',
  templateUrl: './baggage.component.html',
  styleUrls: ['./baggage.component.css']
})
export class BaggageComponent implements OnInit {
  /**
   * 移動プランの検索条件。
   *
   * @type {UserReservation.SearchCondition}
   * @memberof BaggageComponent
   */
  searchCondition: UserReservation.SearchCondition;

  /**
   * Creates an instance of BaggageComponent.
   * @param {Params} params 遷移元のページから渡されるパラメーター。
   * @memberof BaggageComponent
   */
  constructor(
    private params: Params
  ) { }

  /**
   * コンポーネントが初期化される際に呼び出されるメソッド。
   *
   * @memberof BaggageComponent
   */
  ngOnInit(): void {
    [this.searchCondition] = this.params.data;
  }
}
