//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit } from '@angular/core';
import { Params } from 'ngx-onsenui';

// service
import { OrderRequestService } from "../../../lib-services/order-request.service";

// component
import { TabbarComponent } from "../../../components/tabbar/tabbar.component";
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 注文完了画面。
 *
 * @export
 * @class ShoppingCompleteComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'ons-page[shopping-complete]',
  templateUrl: './shopping-complete.component.html',
  styleUrls: ['./shopping-complete.component.css']
})
export class ShoppingCompleteComponent implements OnInit {

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of ShoppingCompleteComponent.
   * @param {OrderRequestService} orderServ
   * @param {TabbarComponent} tabComp
   * @param {Params} params
   * @memberof ShoppingCompleteComponent
   */
  constructor(
    private orderServ: OrderRequestService, 
    private tabComp: TabbarComponent, 
    private params: Params
  ) { }

  /**
   * 初期処理。
   *
   * @memberof ShoppingCompleteComponent
   */
  ngOnInit(): void {
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * ホームへ戻るボタンのイベントハンドラ。
   *
   * @memberof ShoppingCompleteComponent
   */
  onBackHome(): void {

    // カート内容リフレッシュ
    this.orderServ.refresh();

    // タブバー再表示
    this.tabComp.setTabbarVisibility(true);

    // ページスタックをリフレッシュし、買い物機能初期画面に遷移
    this.params.data.class.refresh();
  }
}
