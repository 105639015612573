//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit,　OnDestroy, ChangeDetectorRef } from '@angular/core';
import { OnsNavigator, Params } from 'ngx-onsenui';
import { Subscription } from 'rxjs';
import * as CONST from '../../../constants/constant';

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { FamilyWebApiService } from '../../../http-services/family-web-api.service';
import { UserWebApiService } from '../../../http-services/user-web-api.service';
import { PageKey, PagerService } from 'src/app/lib-services/pager.service';

// interface
import { ExUser } from '../../../interfaces/response';
import { request } from '../../../interfaces/request';

// module
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 見守り対象アカウントプロフィール確認画面。
 *
 * @export
 * @class ProxyAccountSignupDetermineComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @author （株）オブジェクトデータ wada
 */
@Component({
  selector: 'ons-page[proxy-account-signup-determine]',
  templateUrl: './proxy-account-signup-determine.component.html',
  styleUrls: ['./proxy-account-signup-determine.component.css']
})
export class ProxyAccountSignupDetermineComponent implements OnInit, OnDestroy {

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * サーバ通信Subscription
   *
   * @type {Subscription}
   * @memberof ProxyAccountSignupDetermineComponent
   */
  m_Busy: Subscription;

  /**
   * 見守り対象アカウントユーザ情報
   *
   * @type {ExUser}
   * @memberof ProxyAccountSignupDetermineComponent
   */
  m_ProxyUser: ExUser;

  /**
   * 性別
   *
   * @memberof ProxyAccountSignupDetermineComponent
   */
  SEX = [
    { value: 0, label: CONST.Common.SEX_NO_ANSWER },
    { value: 1, label: CONST.Common.SEX_MALE },
    { value: 2, label: CONST.Common.SEX_FEMALE }
  ];

  /**
   * 選択中の性別
   *
   * @type {string}
   * @memberof ProxyAccountSignupDetermineComponent
   */
  m_strSelectedSex: string;

  /**
   * 同意条件
   *
   * @type {string[]}
   * @memberof ProxyAccountSignupDetermineComponent
   */
  terms: string[] = ['term'];

  /**
   * 同意条件チェック有無
   *
   * @type {string[]}
   * @memberof ProxyAccountSignupDetermineComponent
   */
  checkedTerms: string[] = [];

  /**
   * 住所緯度経度登録画面で指定した地点のgoogleMapUrl
   *
   * @type {string}
   * @memberof ProxyAccountSignupDetermineComponent
   */
  googleMapUrl: string;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of ProxyAccountSignupDetermineComponent.
   * @param {OnsNavigator} navigator
   * @param {ApplicationMessageService} appMsg
   * @param {HttpErrorResponseParserService} httpErrorResponseParserService
   * @param {FamilyWebApiService} familyWebApiService
   * @param {Params} params
   * @param {UserWebApiService} UserWebApiService
   * @param {PagerService} pagerServ
   * @memberof ProxyAccountSignupDetermineComponent
   */
  constructor(
    private navigator: OnsNavigator, 
    private appMsg: ApplicationMessageService, 
    private httpErrorResponseParserService: HttpErrorResponseParserService, 
    private familyWebApiService: FamilyWebApiService, 
    private params: Params,
    private UserWebApiService: UserWebApiService,
    private pagerServ: PagerService,
    private commonFunctionMdl: CommonFunctionModule,
    private municipalityWebApiServ: MunicipalityWebApiService,
    private msg: MESSAGE,
  ) {}

  /**
   * 初期化処理。
   *
   * @memberof ProxyAccountSignupDetermineComponent
   */
  ngOnInit(): void {
    // ユーザ情報
    this.m_ProxyUser = this.commonFunctionMdl.deepcopy(this.params.data.proxyUser);

    // 指定した(住所緯度経度登録画面)緯度経度を表示するgoogleMapのリンク作成
    this.googleMapUrl = this.commonFunctionMdl.getGoogleMapUrl(this.m_ProxyUser.profile.address.location);

    // 性別
    this.m_strSelectedSex = this.SEX.find(e => e.value === Number(this.m_ProxyUser.profile.sex)).label;
  }

  /**
   * 破棄処理。
   *
   * @memberof ProxyAccountSignupDetermineComponent
   */
  ngOnDestroy(): void {
    this.m_Busy?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 見守り対象アカウントを作成する。
   *
   * @memberof ProxyAccountSignupDetermineComponent
   */
  createProxyUser(): void {
    //見守り対象アカウント作成メソッド
    this.create();
  }

  /**
   * プライバシーポリシーを表示する。
   *
   * @memberof ProfileSignupDetermineComponent
   */
  viewPrivacyPolicy(): void {
    // プライバシーポリシーのサイトを表示
    window.open(`/${this.municipalityWebApiServ.setting.municipal_id}/assets/pdf/privacy-policy.pdf`,'_blank');
  }
 
  /**
  * 利用規約を表示する。
  *
  * @memberof ProfileSignupDetermineComponent
  */
  viewUsePolicy(): void {
    // 利用規約ページへ遷移
    window.open(this.municipalityWebApiServ.setting.term, '_blank');
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * 見守り対象アカウントを追加する。
   *
   * @memberof ProxyAccountSignupDetermineComponent
   */
  private create(): void {
    //見守り対象アカウント作成の際にサーバーに送る情報
    const body: request.createFamilyAlt = {
      name: this.m_ProxyUser.profile.name, 
      birth: this.m_ProxyUser.profile.birth, 
      sex: this.m_ProxyUser.profile.sex,
      address: this.m_ProxyUser.profile.address
    };

    // 見守り対象アカウント作成
    this.m_Busy = this.familyWebApiService.createProxy(body).subscribe({
      next: () => {
        // ダイアログを表示後、ファミリー一覧画面へ戻る
        this.appMsg.viewDialogMessage(this.msg.CLIENT.FAMILY.COMPLETE_CREATE.message(), _ => {
          this.pagerServ.transitionToPage(this.navigator, PageKey.FamilyComponent);
        });
      },
      error: this.httpErrorResponseParserService.doParse((error, customErrorContent) => {
        // エラー発生時はメッセージ表示
        this.appMsg.viewDialogErrorMessage(this.msg.CLIENT.COMMON.E_UNEXPECTED.message(), customErrorContent.smartGotoErrMessage);
      })
    });
  }
}
