<ons-toolbar>
  <div class="left ">
    <ons-back-button #onsBackButton></ons-back-button>
  </div>
  <div class="center">予約キャンセル</div>
</ons-toolbar>

<div class="content">
  <div [ngBusy]="m_Busy"></div>

  <!-------------
    Template
  -------------->

  <!-- キャンセル対象ユーザ -->
  <ng-container *ngIf="m_bShowCheckbox">
    <div class="list-item__caption">
      以下よりキャンセル対象ユーザーを選んでください
    </div>
    <ons-list>
      <ons-list-item *ngFor="let passenger of m_Passengers; let i = index" modifier="longdivider" tappable>
        <div *ngIf="passenger.cancelability; else Unable" (click)="passenger.cancel = !passenger.cancel">
          <label [attr.for]="'check-passenger-' + i" (click)="$event.stopPropagation()" style="float: left;">
            {{passenger.name.family_name}} {{passenger.name.given_name}}
          </label>
          <input type="checkbox" class="checkbox__input" [attr.input-id]="'check-passenger-' + i" [(ngModel)]="passenger.cancel"
              (change)="changeCancel()" (click)="$event.stopPropagation()">
          <div class="checkbox__checkmark" style="position: absolute; right: 8px;"></div>
        </div>
        <ng-template #Unable>
          <label [attr.for]="'check-passenger-' + i" (click)="$event.stopPropagation()" style="float: left;">
            {{passenger.name.family_name}} {{passenger.name.given_name}}
          </label>
          <input type="checkbox" class="checkbox__input" [attr.input-id]="'check-passenger-' + i" [(ngModel)]="passenger.cancel"
              (change)="changeCancel()" (click)="$event.stopPropagation()" disabled>
          <div class="checkbox__checkmark" style="position: absolute; right: 8px;"></div>
        </ng-template>
      </ons-list-item>
    </ons-list>
  </ng-container>

  <!-- OD -->
  <ng-template #sectionPoint let-sectionPoint="sectionPoint" let-odText="odText">
    <div class="section-point__time">{{ sectionPoint.schd_time | chatDate: "HH:mm" }}{{ odText }}</div>
    <a *ngIf="sectionPoint.location; else sectionPointNoLink"
        [href]="'https://www.google.com/maps/search/?api=1&query=' + sectionPoint.location.lat + ',' + sectionPoint.location.lng + '&z=20'"
        class="list-item__link" target="_blank">
      <div>{{ sectionPoint.name }}</div>
    </a>
    <ng-template #sectionPointNoLink>
      <div>{{ sectionPoint.name }}</div>
    </ng-template>
  </ng-template>

  <!-- 区間 -->
  <ng-template #section let-section="section" let-carNo="carNo">
    <div class="section">
      <div class="section-item__data">
        <ng-container *ngTemplateOutlet="sectionPoint; context: { sectionPoint: section.o, odText: '発' }"></ng-container>
        <div class="arrow">→</div>
        <ng-container *ngTemplateOutlet="sectionPoint; context: { sectionPoint: section.d, odText: '着' }"></ng-container>
      </div>
    </div>
  </ng-template>

  <!-- 同乗者 -->
  <ng-template #passenger let-passenger="passenger">
    <div class="passenger">
      <div class="passenger-item__name">{{ passenger.ticket.user.name }} 様</div>
      <div class="passenger-item__amount">
        <ons-row>
          <ons-col class="passenger-item__ticket-name">{{ passenger.ticket.ticket_name }}</ons-col>
          <ons-col class="amount">￥{{ passenger.refundPrice | number:'.0-0' }}</ons-col>
          <ons-col class="amount--child">{{ getAmoutProperty(passenger.ticket.amount_property) }}</ons-col>
        </ons-row>
      </div>
    </div>
  </ng-template>

  <!-- 合計料金 -->
  <ng-template #total let-total="total">
    <div class="total">
      <div class="total-item__amount">払い戻し料金(合計)：
        <span class="amount">￥{{ total.refundPrice | number:'.0-0' }}</span>
      </div>
      <div *ngIf="total.refundPrice > 0">払い戻し先：{{ total.payer_name }} 様</div>
    </div>
  </ng-template>

  <!-------------
    Body
  -------------->

  <ons-list *ngIf="m_bCancelAbility" class="reservation-list">
    <div class="list-item__caption">
      キャンセルによる料金払い戻し
      <ng-container *ngIf="customerBill.families?.length > 0">(ファミリー)</ng-container>
    </div>

    <!-- ログインユーザが乗車 -->
    <ons-list-item *ngFor="let ticket of customerBill?.user?.tickets; index as count" class="passenger-line">
      <!-- O → D -->
      <ng-container *ngFor="let sec of ticket.sections">
        <ng-container *ngTemplateOutlet="section; context: { section: sec }"></ng-container>
      </ng-container>

      <!-- ログインユーザ -->
      <ng-container *ngIf="customerBill.user.cancelStatus === true">
        <ng-container *ngTemplateOutlet="passenger; context: { passenger: ticket }"></ng-container>
      </ng-container>

      <!-- ファミリー -->
      <ng-container *ngFor="let family of customerBill.families">
        <ng-container *ngIf="family.cancelStatus === true">
          <ng-container *ngTemplateOutlet="passenger; context: { passenger: family.tickets[count] }"></ng-container>
        </ng-container>
      </ng-container>
    </ons-list-item>

    <!-- ログインユーザの乗車なし、ファミリーの乗車あり -->
    <ng-container *ngIf="customerBill.user === undefined">
      <ons-list-item *ngFor="let ticket of customerBill?.families[0]?.tickets; index as count" class="passenger-line">
        <!-- O → D -->
        <ng-container *ngFor="let sec of ticket.sections">
          <ng-container *ngTemplateOutlet="section; context: { section: sec }"></ng-container>
        </ng-container>

        <!-- ファミリー -->
        <ng-container *ngFor="let family of customerBill?.families">
          <ng-container *ngIf="family.cancelStatus === true">
            <ng-container *ngTemplateOutlet="passenger; context: { passenger: family.tickets[count] }"></ng-container>
          </ng-container>
        </ng-container>
      </ons-list-item>
    </ng-container>

    <!-- 合計料金 -->
    <ons-list-item>
      <ng-container *ngTemplateOutlet="total; context: {total: customerBill.total}"></ng-container>
    </ons-list-item>
  </ons-list>

  <br>

  <ons-list>
    <ons-list-item modifier="longdivider" expandable>
      <div class="list-item__title">キャンセルポリシー</div>
      <div class="expandable-content content-border--top">
        <p>キャンセル手数料は、1円未満切り捨てとなります。</p>
        <br>
        <div class="content-itemization"><b>{{ cancelDescription.ticket_name.one }}の場合</b></div>
        <div class="content--description">
          <p>以下のルールに基づき払い戻しを行います。</p>
          <table>
            <tr>
              <th>キャンセル時刻</th>
              <th>手数料</th>
              <th>払い戻し額</th>
            </tr>
            <tr>
              <td>乗車予定{{ cancelDescription.limit }}分前まで</td>
              <td>無料</td>
              <td>全額</td>
            </tr>
            <tr>
              <td>乗車予定{{ cancelDescription.limit }}分前～<br>乗車予定時刻まで</td>
              <td>料金の{{ cancelDescription.fee }}%</td>
              <td>料金－手数料</td>
            </tr>
            <tr>
              <td>乗車予定時刻後</td>
              <td>キャンセル不可</td>
              <td>0円</td>
            </tr>
          </table>
        </div>
        <div class="content-itemization"><b>{{ cancelDescription.ticket_name.season }}の場合</b></div>
        <div class="content--description">
          <p>
            乗車予定時刻までのキャンセルにおける手数料・払い戻し金は一切発生しません｡<br>
            また、乗車予定時刻後のキャンセルは不可となります。
          </p>
        </div>
      </div>
    </ons-list-item>
  </ons-list>
  <br>
  <ng-container *ngIf="m_bCancelAbility">
    <div class="content--text--around">以上の内容でキャンセルを行う場合、確定ボタンを押してください。</div>
  </ng-container>

  <div class="button-wrap">
    <ons-button id="reservation-cancel_001" (click)="cancelPlan()" [disabled]="!m_bCancelAbility" modifier="large--cta">キャンセル確定</ons-button>
  </div>
</div>