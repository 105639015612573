// ========================================================================================================================
// 各種インポート
// ========================================================================================================================

import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { timeout } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';
import * as moment from 'moment';

import * as CONST from '../constants/constant';

import { HttpErrorResponseParserService } from '../lib-services/http-error-response-parser.service';
import { HttpCustomErrorContent } from '../lib-services/http-error-response-parser.service';

import { AsyncLockWrap } from '../lib-modules/async-lock-wrap';

// ========================================================================================================================
// Configクラス定義
// ========================================================================================================================

export class SelectHistoryWebApiServiceConfig {

   baseUrl: string;

   httpOptions?: {

     headers?: HttpHeaders | 
     {
       [header: string]: string | string[];
     };
   };
}

// ========================================================================================================================
// クラス定義
// ========================================================================================================================

/**
 * 操作履歴管理サービス
 *
 * @export
 * @class SelectHistoryWebApiService
 */
@Injectable({
  providedIn: 'root'
})
export class SelectHistoryWebApiService {

  // ================================================================================
  // 変数定義
  // ================================================================================

  /**
   * 各コンポーネントから追加される操作履歴データ。
   * 一定件数貯まるまでサーバには送信せずサービスでストレージ。
   * @private
   * @type {SelectHistoryList}
   * @memberof SelectHistoryWebApiService
   */
  private storageHistories: SelectHistoryList;

  /**
   * サーバ送信用操作履歴データ。
   * 送信直前にストレージからデータを移動し送信。
   * [MEMO]
   * ストレージデータしかないと、呼ばれる回数の多いaddHistory()の方で、サーバ送信が完了するまでの
   * 間データ追加を待たないといけなくなり、スタックする排他制御数が多くなる可能性あり。
   * そのため、addHistory()ではサーバ送信完了まで待たなくていいように、保持データを2種類に分けた。
   * @private
   * @type {SelectHistoryList}
   * @memberof SelectHistoryWebApiService
   */
  private sendHistories: SelectHistoryList;

  /**
   * npmのAsyncLock(排他制御)をラップしたライブラリ。
   * ラップ内容はAsyncLockWrapクラス参照。
   * @private
   * @type {AsyncLockWrap}
   * @memberof SelectHistoryWebApiService
   */
  private asyncLock: AsyncLockWrap;

  // ================================================================================
  // 定数定義
  // ================================================================================

  /**
   * サービスにストレージする操作履歴データの最大数。
   * この数に達したら、データをサーバに一括POST。
   * @private
   * @type {number}
   * @memberof SelectHistoryWebApiService
   */
  private readonly MAX_STORAGE_COUNT: number = 5;

  /**
   * ストレージにデータを保管する最大日数。
   * 操作履歴保存からこの日数以上経ってpostStrageメソッドが呼び出された場合、必ずデータを送信。
   * @private
   * @type {number}
   * @memberof SelectHistoryWebApiService
   */
  private readonly MAX_STORAGE_DAY: number = 7;

  /**
   * ストレージ操作時の排他制御のタイムアウトミリ秒。
   * この秒数を超えてロックが続いた場合、待ちプロセスはキャンセル。
   * @type {number}
   * @memberof SelectHistoryWebApiService
   */
  private readonly STORAGE_LOCK_TIMEOUT_MS: number = 5000;

  /**
   * 送信用データ操作時の排他制御のタイムアウトミリ秒。
   * この秒数を超えてロックが続いた場合、待ちプロセスはキャンセル。
   * @private
   * @type {number}
   * @memberof SelectHistoryWebApiService
   */
  private readonly SEND_LOCK_TIMEOUT_MS: number = 15000;
  
  /**
   * ストレージ操作時の排他制御のロックキー。
   * @private
   * @type {string}
   * @memberof SelectHistoryWebApiService
   */
  private readonly STORAGE_LOCK_KEY: string = "STORAGE_LOCK_KEY";

  /**
   * 送信用データ操作時の排他制御のロックキー。
   *
   * @private
   * @type {string}
   * @memberof SelectHistoryWebApiService
   */
  private readonly SEND_LOCK_KEY: string = "SEND_LOCK_KEY";

  // ================================================================================
  // ライフサイクルメソッド定義
  // ================================================================================

  constructor(
    private http: HttpClient, 
    private config: SelectHistoryWebApiServiceConfig,
    private httpErrorResponseParserServ: HttpErrorResponseParserService,
  ) {
    this.storageHistories = new SelectHistoryList();
    this.sendHistories = new SelectHistoryList();
    this.asyncLock = new AsyncLockWrap();
  }

  // ================================================================================
  // 関数定義
  // ================================================================================

  /**
   * 各種操作履歴をストレージに追加。
   *
   * @param {(ArticleTextSelectHistory | ArticleImageSelectHistory | ShopSelectHistory | MenuSelectHistory)} history
   * @return {*}  {Promise<void>} asyncLock.acquireをawaitするためにasync関数化しているが、呼び出し元はawait不要。
   * @memberof SelectHistoryWebApiService
   */
  async addHistory(history: ArticleTextSelectHistory | ArticleImageSelectHistory | ShopSelectHistory | MenuSelectHistory): Promise<void> {

    // console.log("ストレージ/追加/ロック前");

    // ストレージデータに操作履歴を追加(排他処理)
    const result: string = await this.asyncLock.acquire(async () => {

      // console.log("ストレージ/追加/ロック中/データ数(追加前)：" + this.storageHistories.getCount() + "個");
      this.storageHistories.add(history);
      // console.log("ストレージ/追加/ロック中/データ数(追加後)：" + this.storageHistories.getCount() + "個");

    }, { resolveTimeout: this.STORAGE_LOCK_TIMEOUT_MS, key: this.STORAGE_LOCK_KEY });

    // 排他制御タイムアウトエラー時
    if (result != this.asyncLock.SUCCESS_RES) {
      // console.log("ストレージ/追加/ロックタイムアウトエラー");
      return;
    }

    // console.log("ストレージ/追加/ロック後");

    // ストレージデータ数が上限を超えたら、ストレージデータを送信用データに移行しサーバにPOST
    if (this.storageHistories.getCount() >= this.MAX_STORAGE_COUNT) {
      // postStrageはasync関数だが、ここで完了を待っても意味がないのでawait化しない
      this.postStrage(false, true);
    }
    else {
      // console.log("ストレージデータ数" + this.storageHistories.getCount() + "個だったため送信せず");
    }

  }

  /**
   * ストレージデータを送信用メモリに移行しサーバにPOST
   *
   * @param {boolean} beacon beaconAPIを使って送信するかどうか。ページアンロード時にはtrueを指定し実行すること。
   * @param {boolean} forced 強制的に送信するかどうか。ページアンロード時にはtrueを指定し実行すること。falseの場合、現在時刻と一番古い履歴データを比較し、一定期間経っていたら送信。
   * @return {*}  {Promise<void>}
   * @memberof SelectHistoryWebApiService
   */
  async postStrage(beacon: boolean, forced: boolean): Promise<void> {

    // --------------------------------------------------
    // 強制送信ではない場合の履歴データ時刻チェック
    // --------------------------------------------------
    
    // 強制送信ではない場合
    if (!forced) {

      // 最も古い操作履歴データが、保管最大日数以上ストレージされていない場合、データ送信しない
      let threshold: moment.Moment = moment();
      threshold.subtract(this.MAX_STORAGE_DAY, 'days');

      const storageOldestTime: moment.Moment = this.storageHistories.getOldestTime();
      const sendOldestTime: moment.Moment = this.sendHistories.getOldestTime();
      
      if (storageOldestTime==null && sendOldestTime==null) {
        // console.log("ストレージデータ・送信用データ共に0個だったので送信せず");
        return;
      }
      else if ((storageOldestTime!=null && storageOldestTime.isAfter(threshold)) || 
               (sendOldestTime!=null && sendOldestTime.isAfter(threshold))) {
        // console.log("ストレージデータ・送信用データの最古操作履歴データがしきい値より後だったため送信せず");
        return;
      }
    }

    // --------------------------------------------------
    // ストレージデータを一時メモリに取り出しストレージ初期化
    // --------------------------------------------------

    // console.log("ストレージ/取り出し/ロック前");

    let migrationHistories: SelectHistoryList;

    const result1: string = await this.asyncLock.acquire(async () => {

      migrationHistories = this.storageHistories.getCopyInstance();
      this.storageHistories.init();
      // console.log("ストレージ/取り出し/ロック中/一時メモリへ移行後、データ数：" + migrationHistories.getCount() + "個");

    }, { resolveTimeout: this.STORAGE_LOCK_TIMEOUT_MS, key: this.STORAGE_LOCK_KEY });

    // 排他制御タイムアウトエラー時
    if (result1 != this.asyncLock.SUCCESS_RES) {
      // console.log("ストレージ/取り出し/ロックタイムアウトエラー");
      return;
    }

    // console.log("ストレージ/取り出し/ロック後");

    // --------------------------------------------------
    // 一時メモリデータを送信用データにマージ
    // --------------------------------------------------

    // console.log("送信用データ/追加/ロック前");

    const result2: string = await this.asyncLock.acquire(async () => {

      this.sendHistories.merge(migrationHistories);
      // console.log("送信用データ/追加/ロック中/送信データへ追加後、データ数：" + this.sendHistories.getCount() + "個");

    }, { resolveTimeout: this.SEND_LOCK_TIMEOUT_MS, key: this.SEND_LOCK_KEY });
    
    // 排他制御タイムアウトエラー時
    if (result2 != this.asyncLock.SUCCESS_RES) {
      // console.log("送信用データ/追加/ロックタイムアウトエラー");
      return;
    }

    // console.log("送信用データ/追加/ロック後");

    // --------------------------------------------------
    // 送信用データをサーバ送信
    // --------------------------------------------------

    if (beacon) {
      await this.beaconPostExclusive();
    }
    else {
      await this.httpPostExclusive();
    }
    
  }

  /**
   * 送信用データを排他制御でサーバ送信(HTTP)
   *
   * @private
   * @return {*}  {Promise<void>}
   * @memberof SelectHistoryWebApiService
   */
  private async httpPostExclusive(): Promise<void> {

    // console.log("送信用データ/送信/ロック前");

    const result: string = await this.asyncLock.acquire(async () => {
      try {

        // console.log("送信用データ/送信/ロック中/データ数：" + this.sendHistories.getCount() + "個");

        // データ0件だったら送信しない
        if (this.sendHistories.getCount() == 0) {
          // console.log("送信用データ/送信/ロック中/0個だったので送信せず");
          return;
        }

        // サーバ送信
        await firstValueFrom(this.post(this.sendHistories));
        // console.log("送信用データ/送信/ロック中/サーバへ" + this.sendHistories.getCount() + "件のデータ送信完了");

        // メモリ初期化
        this.sendHistories.init();

      }
      catch (err) {
        // サーバエラーの場合
        if (err instanceof HttpErrorResponse) {

          const errName: string = err?.name;
          let errContent: HttpCustomErrorContent = {
            statusCode: (err.error as HttpCustomErrorContent)?.statusCode, 
            smartGotoErrMessage: (err.error as HttpCustomErrorContent)?.smartGotoErrMessage, 
            smartGotoErrCode: (err.error as HttpCustomErrorContent)?.smartGotoErrCode,
            occurredTimeout: errName=="TimeoutError",
          }

          // ユーザー操作の裏で履歴を送っているだけなのでメッセージ表示しないが、解析用にログ出力
          // console.log(errContent);
        }
        // それ以外の場合(通常あり得ない)
        else {
          // console.log(err);
        }
      }
    }, { resolveTimeout: this.SEND_LOCK_TIMEOUT_MS, key: this.SEND_LOCK_KEY });

    if (result != this.asyncLock.SUCCESS_RES) {
      // console.log("送信用データ/送信/ロックタイムアウトエラー");
    }
  }

  /**
   * 送信用データを排他制御でサーバ送信(Beacon)
   *
   * @return {*}  {Promise<void>}
   * @memberof SelectHistoryWebApiService
   */
  private async beaconPostExclusive(): Promise<void> {

    // BeaconPOSTの場合、呼び出し元(この関数の呼び出し元であるpostStrageのさらに呼び出し元)に、
    // 送信完了まで待ってもらいたいので、acquireをawait化
    const result: string = await this.asyncLock.acquire(async () => {

      // console.log("送信用データ/送信/ロック中/データ数：" + this.sendHistories.getCount() + "個");
      
      // データ0件だったら送信しない
      if (this.sendHistories.getCount() == 0) {
        // console.log("送信用データ/送信/ロック中/0個だったので送信せず");
        return;
      }

      // BeaconAPIで送信
      const blob: Blob = new Blob([JSON.stringify(this.sendHistories, null, 2)], {
        type: "application/json",
      });
      navigator.sendBeacon(this.config.baseUrl, blob);

      // console.log("送信用データ/送信/ロック中/サーバへ" + this.sendHistories.getCount() + "件のデータ送信完了");

      // メモリ初期化
      this.sendHistories.init();

    }, { resolveTimeout: this.SEND_LOCK_TIMEOUT_MS, key: this.SEND_LOCK_KEY });

    if (result != this.asyncLock.SUCCESS_RES) {
      // console.log("送信用データ/送信/ロックタイムアウトエラー");
    }
  }

  // ================================================================================
  // サーバ通信メソッド定義
  // ================================================================================

  /**
   * PostAPIの実行。
   * @private
   * @template T
   * @param {SelectHistoryList} histories
   * @return {*}  {Observable<HttpResponse<T>>}
   * @memberof SelectHistoryWebApiService
   */
  private post<T>(histories: SelectHistoryList): Observable<HttpResponse<T>> {
    return this.http.post<T>(`${this.config.baseUrl}`, histories, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true,
    // }).pipe(timeout(CONST.HTTP_TIMEAOUT_MS)); // 高齢者アプリのみ時間制限
    });
  }

  // get():void {}

}

// ========================================================================================================================
// サービスで利用するクラス定義
// ========================================================================================================================

/**
 * 各種操作履歴の管理用クラス。
 * データの管理だけでなく、データ操作メソッドも搭載。
 * @export
 * @class SelectHistoryList
 */
class SelectHistoryList {

  // ================================================================================
  // 変数定義
  // ================================================================================

  /**
   * 記事の操作履歴リスト
   *
   * @type {ArticleSelectHistory[]}
   * @memberof SelectHistoryList
   */
  articles: ArticleSelectHistory[];

  /**
   * 店舗の操作履歴リスト
   *
   * @type {ShopSelectHistory[]}
   * @memberof SelectHistoryList
   */
  shops: ShopSelectHistory[];

  /**
   * 商品の操作履歴リスト
   *
   * @type {MenuSelectHistory[]}
   * @memberof SelectHistoryList
   */
  menus: MenuSelectHistory[];

  // ================================================================================
  // ライフサイクルメソッド定義
  // ================================================================================

  constructor() {
    this.init();
  }
  
  // ================================================================================
  // 関数定義
  // ================================================================================

  /**
   * クラス変数の初期化
   *
   * @memberof SelectHistoryList
   */
  init(): void {

    this.articles = [];
    this.shops = [];
    this.menus = []; 
  }

  /**
   * クラス変数への要素追加
   *
   * @param {(ArticleTextSelectHistory | ArticleImageSelectHistory | ShopSelectHistory | MenuSelectHistory)} history
   * @memberof SelectHistoryList
   */
  add(history: ArticleTextSelectHistory | ArticleImageSelectHistory | ShopSelectHistory | MenuSelectHistory): void {

    // 選択コンテンツに応じて追加プロパティを決定
    switch (history.type) {
      case "article":
        this.articles.push(history);
        break;
    
      case "shop":
        this.shops.push(history);
        break;
    
      case "menu":
        this.menus.push(history);
        break;
    
      default:
    }
  }

  /**
   * 別インスタンスのデータを自身にマージ
   *
   * @param {SelectHistoryList} another
   * @memberof SelectHistoryList
   */
  merge(another: SelectHistoryList): void {

    // [MEMO] Tomoya Ishizone
    // 本当はconcat等でシンプル&スマートにマージしたかったが、
    // 何故か出来なかったのでforEach⇒pushの形に。
    // 他に良いやり方があれば改善したい。

    // 記事
    another.articles.forEach((article: ArticleSelectHistory) => {
      this.articles.push(article);
    });

    // 店舗
    another.shops.forEach((shop: ShopSelectHistory) => {
      this.shops.push(shop);
    });

    // 商品
    another.menus.forEach((menu: MenuSelectHistory) => {
      this.menus.push(menu);
    });
  }

  /**
   * クラス変数の総件数取得
   *
   * @return {*}  {number}
   * @memberof SelectHistoryList
   */
  getCount(): number {

    return this.articles.length + this.shops.length + this.menus.length;
  }

  /**
   * 最も古い操作履歴データの日時を取得
   *
   * @return {*}  {moment.Moment}
   * @memberof SelectHistoryList
   */
  getOldestTime(): moment.Moment {

    let res: moment.Moment = null;

    this.articles.forEach((article: ArticleSelectHistory) => {

      const articleDate: moment.Moment = moment(article.date);

      if (res==null) {
        res = articleDate;
      }
      else {
        if (articleDate.isBefore(res)) {
          res = articleDate;
        }
      }
    });

    this.shops.forEach((shop: ShopSelectHistory) => {

      const shopDate: moment.Moment = moment(shop.date);

      if (res==null) {
        res = shopDate;
      }
      else {
        if (shopDate.isBefore(res)) {
          res = shopDate;
        }
      }
    });

    this.menus.forEach((menu: MenuSelectHistory) => {

      const menuDate: moment.Moment = moment(menu.date);

      if (res==null) {
        res = menuDate;
      }
      else {
        if (menuDate.isBefore(res)) {
          res = menuDate;
        }
      }
    });

    return res;
  }

  /**
   * 自身のコピーインスタンスを取得
   *
   * @return {*}  {SelectHistoryList}
   * @memberof SelectHistoryList
   */
  getCopyInstance(): SelectHistoryList {

    let res: SelectHistoryList = new SelectHistoryList();

    // 記事
    res.articles = this.articles.map((base: ArticleTextSelectHistory | ArticleImageSelectHistory) => {

      let tmp: ArticleTextSelectHistory | ArticleImageSelectHistory;

      if(base.article.type=="text") {
        tmp = {
          id: base.id,
          user_id: base?.user_id,
          date: base.date,
          service: base.service,
          type: base.type,
          article: {
            article_id: base.article.article_id, 
            shop_id: base.article.shop_id,
            type: base.article.type,
          }
        };
      }
      else {
        tmp = {
          id: base.id,
          user_id: base?.user_id,
          date: base.date,
          service: base.service,
          type: base.type,
          article: {
            article_id: base.article.article_id, 
            shop_id: base.article.shop_id,
            type: base.article.type,
            url: base.article.url,
          }
        }
      }

      return tmp;
    });

    // 店舗
    res.shops = this.shops.map((base: ShopSelectHistory) => {
      return {
        id: base.id,
        user_id: base?.user_id,
        date: base.date,
        service: base.service,
        type: base.type,
        shop: {
          shop_id: base.shop.shop_id,
        }
      };
    });

    // 商品
    res.menus = this.menus.map((base: MenuSelectHistory) => {
      return {
        id: base.id,
        user_id: base?.user_id,
        date: base.date,
        service: base.service,
        type: base.type,
        menu: {
          shop_id: base.menu.shop_id,
          menu_id: base.menu.menu_id,
        }
      };
    });

    return res;
  }

}

// ========================================================================================================================
// インターフェイス定義
// ========================================================================================================================

/**
 * 記事本文の操作履歴
 *
 * @export
 * @interface ArticleTextSelectHistory
 * @extends {ArticleSelectHistory}
 */
export interface ArticleTextSelectHistory extends ArticleSelectHistory {

  /**
   * 選択記事情報
   *
   * @type {{
   *     shop_id: number;
   *     article_id: number;
   *   }}
   * @memberof ArticleTextSelectHistory
   */
  article: {

    /**
     * 選択記事の投稿店舗ID
     *
     * @type {number}
     */
    shop_id: number;

    /**
     * 選択記事ID
     *
     * @type {number}
     */
    article_id: number;

    /**
     * 本文と画像どちらを選択したか
     *
     * @type {"text"}
     */
    type: "text";
  }

}

/**
 * 記事画像の操作履歴
 *
 * @export
 * @interface ArticleImageSelectHistory
 * @extends {ArticleSelectHistory}
 */
export interface ArticleImageSelectHistory extends ArticleSelectHistory {

  /**
   * 選択記事情報
   *
   * @type {{
   *     shop_id: number;
   *     article_id: number;
   *   }}
   * @memberof ArticleTextSelectHistory
   */
  article: {

    /**
     * 選択記事の投稿店舗ID
     *
     * @type {number}
     */
    shop_id: number;

    /**
     * 選択記事ID
     *
     * @type {number}
     */
    article_id: number;

    /**
     * 本文と画像どちらを選択したか
     *
     * @type {"text"}
     */
    type: "image";

    /**
     * 選択画像URL
     *
     * @type {string}
     */
    url: string;
  }

}

/**
 * 記事の操作履歴
 *
 * @interface ArticleSelectHistory
 * @extends {SelectHistory}
 */
interface ArticleSelectHistory extends SelectHistory {

  /**
   * 操作対象コンテンツ
   *
   * @type {"article"}
   * @memberof ArticleSelectHistory
   */
  type: "article";

  /**
   * 選択記事情報
   *
   * @type {{
   *     shop_id: number;
   *     article_id: number;
   *   }}
   * @memberof ArticleSelectHistory
   */
  article: {

    /**
     * 選択記事の投稿店舗ID
     *
     * @type {number}
     */
    shop_id: number;

    /**
     * 選択記事ID
     *
     * @type {number}
     */
    article_id: number;

    /**
     * 本文と画像どちらを選択したか
     *
     * @type {("text" | "image")}
     */
    type: "text" | "image";
  }

}

/**
 * 店舗の操作履歴
 *
 * @export
 * @interface ShopSelectHistory
 * @extends {SelectHistory}
 */
export interface ShopSelectHistory extends SelectHistory {

  /**
   * 操作対象コンテンツ
   *
   * @type {"shop"}
   * @memberof ShopSelectHistory
   */
  type: "shop";

  /**
   * 選択店舗情報
   *
   * @type {{
   *     shop_id: number;
   *   }}
   * @memberof ShopSelectHistory
   */
  shop: {

    /**
     * 選択店舗ID
     *
     * @type {number}
     */
    shop_id: number;

  }
}

/**
 * 商品の操作履歴
 *
 * @export
 * @interface MenuSelectHistory
 * @extends {SelectHistory}
 */
export interface MenuSelectHistory extends SelectHistory {

  /**
   * 操作対象コンテンツ
   *
   * @type {"menu"}
   * @memberof MenuSelectHistory
   */
  type: "menu";

  /**
   * 選択商品情報
   *
   * @type {{
   *     shop_id: number;
   *     menu_id: number;
   *   }}
   * @memberof MenuSelectHistory
   */
  menu: {

    /**
     * 選択商品の出品店舗ID
     *
     * @type {number}
     */
    shop_id: number;

    /**
     * 選択商品ID
     *
     * @type {number}
     */
    menu_id: number;

  }

}

/**
 * 操作履歴の抽象型。
 * この型のデータをサーバに履歴としてPOSTしてもエラーになるため、
 * この型をextendsした型を履歴データとして扱うこと。
 * @export
 * @interface SelectHistory
 */
interface SelectHistory {

  /**
   * 操作履歴ID。
   * 念のためサーバで重複チェックを行うために、クライアントでuuidをセット。
   * @type {string}
   * @memberof SelectHistory
   */
  id: string;

  /**
   * 操作者のユーザーID。
   * スマホアプリのニュース閲覧など、未ログイン状態で操作可能な場合
   * (操作者特定不可)もあるので、任意プロパティとする。
   * @type {number}
   * @memberof SelectHistory
   */
  user_id?: string;

  /**
   * 操作日時
   *
   * @type {string}
   * @memberof SelectHistory
   */
  date: string;

  /**
   * 操作対象サービス
   *
   * @type {("news" | "shopping" | "tourism")}
   * @memberof SelectHistory
   */
  service: "news" | "shopping" | "tourism";
  
  /**
   * 操作対象コンテンツ
   *
   * @type {("article" | "shop" | "menu")}
   * @memberof SelectHistory
   */
  type: "article" | "shop" | "menu";

}