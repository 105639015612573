// ========================================================================================================================
// 各種インポート
// ========================================================================================================================

import { AfterViewInit, Component, Injectable, OnDestroy, OnInit } from '@angular/core';
import { OnsNavigator } from 'ngx-onsenui';
import { fromEvent, Observable, Subject, Subscription } from 'rxjs';
import * as CONST from '../../../constants/constant'

// service
import { UserWebApiService } from '../../../http-services/user-web-api.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { FamilyWebApiService } from '../../../http-services/family-web-api.service';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';

// component
import { AppComponent } from '../../../app.component';
import { TabbarComponent } from '../../tabbar/tabbar.component';
import { SigninComponent } from '../../signin/signin.component';
import { MyspotComponent } from '../../myspot/myspot.component';
import { FamilyComponent } from '../../family/family.component';
import { PagerService } from '../../../lib-services/pager.service';
import { ProfileListComponent } from '../../user/profile-list/profile-list.component';
import { ProfileSignupComponent } from '../../user/profile-signup/profile-signup.component';
import { NotificationMethodComponent } from '../../notification-method/notification-method.component';
import { PurchaseTicketComponent } from '../../purchase/purchase-ticket/purchase-ticket.component';
import { PurchaseDetailsComponent } from '../../purchase/purchase-details/purchase-details.component';
import { PaymentMethodListComponent } from '../../payment-method/payment-method-list/payment-method-list.component';
import { PincodeAuthMethodComponent } from '../../pincode-auth-method/pincode-auth-method.component';

import { Relation } from '../../../interfaces/response';

import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';
import { TermAgreeComponent } from '../../term-agree/term-agree.component';
import { AccountListOtherMenuComponent } from '../account-list-other-menu/account-list-other-menu.component';

// ========================================================================================================================
// クラス定義
// ========================================================================================================================

@Component({
  selector: 'ons-page[account-list]',
  templateUrl: './account-list.component.html',
  styleUrls: ['./account-list.component.css']
})
export class AccountListComponent implements OnInit, AfterViewInit, OnDestroy {

  // ================================================================================
  // 変数定義
  // ================================================================================

  /**
   * 非同期処理監視Subscription
   *
   * @type {Subscription}
   * @memberof AccountListComponent
   */
  busy: Subscription;

  /**
   * ユーザー情報変更監視Subscription
   *
   * @private
   * @type {Subscription}
   * @memberof AccountListComponent
   */
  private onUserInfoChanged: Subscription;

  /**
   * ファミリー情報変更監視Subscription
   *
   * @private
   * @type {Subscription}
   * @memberof AccountListComponent
   */
  private onFamilyInfoChanged: Subscription;

  /**
   * アカウント情報登録完了監視Subscription
   *
   * @private
   * @type {Subscription}
   * @memberof AccountListComponent
   */
  private onNextLinkChanged: Subscription;

  /**
   * マイページタブクリック監視Subscription
   *
   * @private
   * @type {Subscription}
   * @memberof AccountListComponent
   */
  private accountTabClickSubscription : Subscription;

  /**
   *　このコンポーネントのons-page番号
   *
   * @private
   * @type {number}
   * @memberof AccountListComponent
   */
  private currentPageIndex: number;

  /**
   * ログインユーザが観光か住民か
   *
   * @type {boolean}
   * @memberof AccountListComponent
   */
  // userTypeCitizen: boolean = false;

  /**
   * 「通知方法」メニューに表示するバッジの通知数
   *
   * @type {number}
   * @memberof AccountListComponent
   */
  notifyBadge: number = null;

  /**
   * 「支払方法」メニューに表示するバッジの通知数
   *
   * @type {number}
   * @memberof AccountListComponent
   */
  paymentBadge: number = null;

  /**
   * 「ファミリー」メニューに表示するバッジの通知数
   *
   * @type {number}
   * @memberof AccountListComponent
   */
  familyBadge: number = null;

  /**
   * 定額乗車券購入メニューの表示名
   *
   * @type {string}
   * @memberof AccountListComponent
   */
  purchaseName: string = CONST.Purchase.TICKET_TITLE_CITIZEN + "購入";

  /**
   * 未ログイン状態かどうか
   *
   * @type {boolean}
   * @memberof AccountListComponent
   */
  isNotLogin: boolean = false;

  /**
   * エリア名
   *
   * @type {string}
   * @memberof AccountListComponent
   */
  areaName: string;

  /**
   * OnDestroy時に破棄するSubscriptionオブジェクト
   *
   * @memberof AccountListComponent
   */
  subscription = new Subscription();

  /**
   * returnHere, returnAppの終了を監視
   *
   * @private
   * @type {Subject<any>}
   * @memberof AccountListComponent
   */
  private finishedRerurn: Subject<any> = new Subject();

  /**
   * returnHere()のページスタック削除が終了しているかどうか
   *
   * @private
   * @type {boolean}
   * @memberof AccountListComponent
   */
  private isFinishedRerurnHere: boolean = false;

  /**
   * returnApp()のページスタック削除が終了しているかどうか
   *
   * @private
   * @type {boolean}
   * @memberof AccountListComponent
   */
  private isFinishedReturnApp: boolean = false;

  /**
   * (アクセス自治体が)配車サービスを提供しているかどうか
   *
   * @type {boolean}
   * @memberof AccountListComponent
   */
  isProvideDispatch: boolean = false;

  /**
   * 新規登録処理かどうか
   *
   * @type {boolean}
   * @memberof AccountListComponent
   */
  isSigned: boolean = false;

  /**
   * ファミリー欄を表示するかどうか
   * 提供サービスが観光サービスのみの場合に非表示にする
   *
   * @type {boolean}
   * @memberof AccountListComponent
   */
  isVisibleFamily: boolean = true;

  /**
   * マイスポット欄を表示するかどうか
   * 提供サービスが観光サービスのみの場合に非表示にする
   *
   * @type {boolean}
   * @memberof AccountListComponent
   */
  isVisibleMyspot: boolean = true;

  /**
   * assetsファイルへのパス(定数)
   *
   * @memberof AccountListComponent
   */
  readonly ASSETS = {
    PROFILE: CommonFunctionModule.getAssetsUrl('/image/common/28-Profile.png'),
    MYSPOT: CommonFunctionModule.getAssetsUrl('/image/common/46-PinC_Inactive.png'),
    TICKET: CommonFunctionModule.getAssetsUrl('/image/common/60-Ticket.png'),
    PURCHASE: CommonFunctionModule.getAssetsUrl('/image/common/59-receipt_D.png'),
    FAMILY: CommonFunctionModule.getAssetsUrl('/image/common/22-Family.png'),
    PAYMENT: CommonFunctionModule.getAssetsUrl('/image/common/30-Money.png'),
    NOTIFY: CommonFunctionModule.getAssetsUrl('/image/common/31-Alert.png'),
    AUTH_METHOD: CommonFunctionModule.getAssetsUrl('/image/common/two-factor-auth-method.png'),
    OTHER: CommonFunctionModule.getAssetsUrl('/image/common/other-menu.png'),
  } as const;

  // ================================================================================
  // ライフサイクルメソッド定義
  // ================================================================================

  constructor(
    private _navigator: OnsNavigator,
    private _appComponent: AppComponent,
    private _tabbarComponent: TabbarComponent,
    private userWebApiService: UserWebApiService,
    private familyWebApiService: FamilyWebApiService,
    private accountService: AccountService,
    private pagerServ: PagerService,
    private municipalityWebApiServ: MunicipalityWebApiService,
  ) {
    const settingsChanged = this.municipalityWebApiServ.settingsChanged.subscribe({
      next: setting => {
        if (setting == null) return;
        // 提供サービスが観光サービスのみの場合、ファミリー欄とマイスポット欄を非表示にする
        if(setting.isProvidedExpServiceOnly){
            this.isVisibleFamily = false;
            this.isVisibleMyspot = false;
        }
        this.areaName = setting.name;
        // 配車サービスを提供しているかどうか
        this.isProvideDispatch = setting.services.includes(CONST.Common.SERVICE_TAG.RESERVATION);
      }
    });
    this.subscription.add(settingsChanged);
  }

  ngOnInit(): void {
    // 各ページ番号を記録
    this.currentPageIndex = this._navigator.element.pages.length - 1;

    // ログイン状態かつトップ画面の場合、最新のファミリー情報を取得する
    this.accountTabClickSubscription = fromEvent(this._tabbarComponent.accountTab.nativeElement, 'click').subscribe(() => {
      // ログイン状態
      if(this.userWebApiService.isLoggedIn()) {
        // this.returnHere();

        // note: タブクリックで画面を戻さなくなった場合、この条件が必要
        if (this._navigator.element.pages.length == 1) {
          // ファミリー情報取得
          this.familyWebApiService.getFamily().subscribe();
        }
      }
    });
  }

  ngAfterViewInit(): void {

    // ユーザー情報の変更を監視
    this.onUserInfoChanged = this.userWebApiService.userInfoChanged.subscribe({ 
      next: userInfo => {
        // ユーザー情報取得が行われていない場合、処理を抜ける
        if (userInfo === undefined) return;

        // 未ログインの場合
        if (userInfo === null) {
          this.isNotLogin = true;
          const lastIndex: number = this._navigator.element.pages.length - 1;

          // サインイン画面を表示
          setTimeout(() => {
            this._navigator.element.pushPage(SigninComponent).then(() => {
              if (this.pagerServ.getUserAppNavigator === false) {
                // 新規登録中止後、マイページメニュー画面へ
                if (this.isSigned === true) {
                  this.isSigned = false;
                  this.returnApp();
                }
              }
            });
          });

          // AccountListComponent、SigninComponentの順になるようページスタックを削除
          if (this.currentPageIndex < lastIndex) {
            for (let i = this.currentPageIndex; i<lastIndex; i++) {
              setTimeout(() => this._navigator.element.removePage(this.currentPageIndex + 1));
            }
          }
        }
        
        // ログイン済の場合
        else {
          // 情報未登録の場合、新規登録画面へ
          if (userInfo.status === 'signed') {

            this.isSigned = true;
            this._appComponent.navigator.element.pushPage(ProfileSignupComponent, { data: [] });
          }
          // ユーザー情報登録済み
          else {
            this.isSigned = false;
            // マイページタブ以外で、未ログイン状態⇒ログイン状態に変わった場合のみ
            if (this.isNotLogin == true && this._tabbarComponent.getActiveTab() != this._tabbarComponent.tabComponent.account) {
              this.isNotLogin = false;
              // サインイン画面を取り除く
              // this.returnHere();
              this._navigator.element.popPage();
            }
          }

          // note: ビュー描写直後に描写物が更新されているエラーを回避するためのsetTimeout
          setTimeout(() => {
            // List - Badge
            this.notifyBadge = this.paymentBadge = null;
            if((userInfo.notify?.method ?? '') === '') this.notifyBadge += 1;
            if(userInfo.notify?.method === 'line' && userInfo.notify?.line === null) this.notifyBadge += 1;
            if((userInfo.payment?.method ?? 'NONE') === 'NONE') this.paymentBadge += 1;
          });
        }
      }
    });

    // ファミリー情報の変更を監視
    this.onFamilyInfoChanged = this.familyWebApiService.familyInfoChenged.subscribe({
      next: (relations: Relation[]) => { 

        // ファミリーリンク申請が来ているリレーション数を画面上バッジに反映
        setTimeout(() => {
          this.familyBadge = null;
          if(relations) {
            for(let relation of relations) {
              if(relation.status == 'request') {
                this.familyBadge += 1;
              }
            }
          }
        });
      }

    });

    // returnHere()、returnApp()の終了を監視
    const finishedRerurnSub: Subscription = this.finishedRerurn.subscribe({
      next: () => {
        // returnHere()、returnApp()どちらかの処理が終了していない場合、処理を抜ける
        if(!this.isFinishedRerurnHere || !this.isFinishedReturnApp) return;

        this.isFinishedRerurnHere = false;
        this.isFinishedReturnApp = false;
        
        // 未ログインの場合処理を抜ける
        if (!this.userWebApiService.isLoggedIn()) return;

        // 規約合意していないならば、規約合意画面を表示
        if (this.userWebApiService.getUserInfo().term_agreed === false) {
          this._appComponent.navigator.element.pushPage(TermAgreeComponent, {animation: 'fade-ios'});
        }
      }
    });

    this.subscription.add(finishedRerurnSub);

    // アカウント情報登録完了を監視
    this.onNextLinkChanged = this.accountService.changed.subscribe({
      next: (component: "signin" | "payment" | "signin_noTrans") => {

        if (component === "signin_noTrans") return;

        this.returnHere();
        this.returnApp();

        switch(component) {
          case 'payment':
            this._tabbarComponent.setTabbarVisibility(true);
            this._tabbarComponent.accountTabClick();
            break;
          case 'signin':
            break;
        }
      }
    });
  }

  ngOnDestroy(): void {
    [this.busy, this.onUserInfoChanged,this.onFamilyInfoChanged, this.onNextLinkChanged, this.accountTabClickSubscription, this.subscription].forEach((s: Subscription) => {
      s?.unsubscribe();
    });
  }

  //=============================================================================================
  // イベントハンドラ
  //=============================================================================================

  /**
   * プロフィール画面へ
   *
   * @memberof AccountListComponent
   */
  pushProfile(): void {

    this.busy = this._navigator.element.pushPage(ProfileListComponent);
  }

  /**
   * マイスポット画面へ
   *
   * @memberof AccountListComponent
   */
  pushMyspotComponent(): void {

    this.busy = this._navigator.element.pushPage(MyspotComponent);
  }

  /**
   * 乗車券購入画面へ
   *
   * @memberof AccountListComponent
   */
  pushPurchaseTicket(): void {

    this.busy = this._navigator.element.pushPage(PurchaseTicketComponent);
  }

  /**
   * 請求明細詳細画面へ
   *
   * @memberof AccountListComponent
   */
  pushPurchaseDetails(): void {

    this.busy = this._navigator.element.pushPage(PurchaseDetailsComponent);
  }

  /**
   * ファミリー画面へ
   *
   * @memberof AccountListComponent
   */
  pushFamily(): void {

    this.busy = this._navigator.element.pushPage(FamilyComponent);
  }

  /**
   * 支払い方法画面へ
   *
   * @memberof AccountListComponent
   */
  pushPaymentMethod(): void {

    this.busy = this._navigator.element.pushPage(PaymentMethodListComponent);
  }

  /**
   * 二段階認証方法設定画面へ
   *
   * @memberof AccountListComponent
   */
  pushPincodeAuthMethod(): void {

    this._navigator.element.pushPage(PincodeAuthMethodComponent);
  }

  /**
   * その他メニュー画面へ
   *
   * @memberof AccountListComponent
   */
  pushOtherMenus(): void {
    this._navigator.element.pushPage(AccountListOtherMenuComponent);
  }

  /**
   * ログアウト処理
   *
   * @memberof AccountListComponent
   */
  logout(): void {

    this.busy = this.userWebApiService.logout().subscribe();
  }

  //=============================================================================================
  // メソッド
  //=============================================================================================

  /**
   * AccountListComponentを表示しているons-pageまでpopPage
   *
   * @private
   * @memberof AccountListComponent
   */
  private returnHere(): void {

    // ページスタック数
    const lastIndex = this._navigator.element.pages.length - 1;

    if (this.currentPageIndex < lastIndex) {
      this._navigator.element.removePage(this.currentPageIndex + 1).then(() => {
        setTimeout(() => this.returnHere(), 0);
      });
    }
    // ページスタック削除終了を通知
    else if (this.currentPageIndex == lastIndex) {
      this.isFinishedRerurnHere = true;
      this.finishedRerurn.next(null);
    }
  }
  /**
   * appComponentのons-navigatorから見て、AccountListComponentを表示しているons-pageまでpopPage
   *
   * @private
   * @memberof AccountListComponent
   */
  private returnApp(): void {

    const lastIndex = this._appComponent.navigator.element.pages.length - 1;

    // AccountListComponentを表示しているindex
    const currentAppPageIndex: number = 0;

    if (currentAppPageIndex < lastIndex) {
      this._appComponent.navigator.element.removePage(currentAppPageIndex + 1).then(() => {
        setTimeout(() => this.returnApp(), 0);
      });
    }
    // ページスタック削除終了を通知
    else if (currentAppPageIndex == lastIndex) {
      this.isFinishedReturnApp = true;
      this.finishedRerurn.next(null);
    }
  }
}

// ========================================================================================================================
// 関連サービス
// ========================================================================================================================

/**
 * アカウント状態を検知するサービス
 *
 * @export
 * @class AccountService
 */
@Injectable({
  providedIn: 'root'
})
export class AccountService {

  private linkSubject = new Subject<'signin' | 'payment' | 'signin_noTrans'>();

  link(type: 'signin' | 'payment' | 'signin_noTrans') {
    this.linkSubject.next(type);
  }

  get changed(): Observable<'signin' | 'payment' | 'signin_noTrans'> {
    return this.linkSubject.asObservable();
  }
}
