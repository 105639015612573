<ons-toolbar>
  <div class="left">
    <ons-back-button></ons-back-button>
  </div>
  <div class="center">請求明細</div>
  <div class="right" *ngIf="transition == null">
    <ons-toolbar-button id="purchase-details_001" (click)="latestMonthView();" onClick="helpMsg.hide();">今月</ons-toolbar-button>
  </div>
</ons-toolbar>

<div class="content">
  <div [ngBusy]="busy"></div>
  
  <!-- 年月 -->
  <div>
    <p class="content--text">照会年月
      <ons-select id="purchase-details_002" *ngIf="transition == null; else mailAccess" modifier="underbar" [(ngModel)]="selectedValue" [attr.modifier]="selectedValue" (change)="changePurchase();" onClick="helpMsg.hide();">
        <option *ngFor="let list of dateList" [value]="list.value">{{ list.label }}</option>
      </ons-select>　分
    </p>
  </div>
  
  <!-- サマリ -->
  <div class="purcase-wrap">
    <ons-row class="row-summary--header">
      <ons-col class="list-header--color col-summary--header" width="35%">請求金額</ons-col>
      <ons-col vertical-align="center" class="col-summary--value">
        <span>{{ getDispAmount(dispPurchase?.total) }}</span>
      </ons-col>
    </ons-row>
    <ons-row class="row-summary--header">
      <ons-col class="list-header--color col-summary--header" width="35%">期間</ons-col>
      <ons-col vertical-align="center" class="col-summary--value">
        <ng-container>{{ getDispDate(dispPurchasePeriod?.from) }}～{{ getDispDate(dispPurchasePeriod?.to) }}</ng-container>
      </ons-col>
    </ons-row>
    <ons-row class="row-summary--header">
      <ons-col class="list-header--color col-summary--header" width="35%">請求先 <button *ngIf="dispPaymentMethod == PAYMENT_NETHOD.FAIL" class="info_button" (click)="onClickPopUpDialog()">
        <img [src]="ASSETS.INFORMATION">
      </button></ons-col>
      <ons-col vertical-align="center" class="col-summary--value">
        <ng-container *ngIf="paymentMethod.length !== 0">
          <div [matMenuTriggerFor]="menu">
            {{getDispMethod(dispPaymentMethod)}}
            <button class="link_select-box__drop-down-menu" mat-icon-button><span class="material-icons">arrow_drop_down</span></button>
            <mat-menu #menu="matMenu" xPosition="before">
              <ng-container *ngFor="let method of paymentMethod">
                <button mat-menu-item (click)="changePurchaseMethod(method)">
                  {{getDispMethod(method)}}
                </button>
              </ng-container>
            </mat-menu>
          </div>
        </ng-container>
      </ons-col>
    </ons-row>
  </div>
  
  <br>

  <!-- 明細 -->
  <div class="purcase-wrap">
    <ons-row class="row-details--header">
      <ons-col width="25%" class="list-header--color"><span>ご利用日</span></ons-col>
      <ons-col width="50%" class="list-header--color col-details--header"><span>項目</span></ons-col>
      <ons-col width="25%" class="list-header--color"><span>支払金額</span></ons-col>
    </ons-row>
    <div *ngIf="dispPurchase?.detail.length > 0; else noTicket">
      <ons-row *ngFor="let item of dispPurchase?.detail">
        <ons-col class="text--right padding" width="25%">{{ moment(item.date).format('M月D日') }}</ons-col>
        <ons-col class="col-details--item padding" width="50%" vertical-align="center">{{ item.name }}</ons-col>
        <ons-col class="text--right padding" width="25%">{{ getDispAmount(item.amount) }}</ons-col>
      </ons-row>
    </div>
  </div>
  <ons-toast id="purchase-details_004" id="helpMsg" animation="default" onClick="helpMsg.hide();">
    <div class="toast">
      <div id="toastMsgDiv"></div>
      <button id="purchase-details_005" onClick="helpMsg.hide();">OK</button>
    </div>
  </ons-toast>
  <ng-container *ngIf="dispAlcoholPurchaseNotice">
    ※お酒を買われた方へのご注意<br>　20歳未満の飲酒は法律で禁止されています。
  </ng-container>
  <!-- template -->
  <ng-template #mailAccess>{{ payment_month }}</ng-template>
  
  <ng-template #noTicket>
    <ons-row style="border-top: 1px solid gray;">
      <ons-col class="text--center">照会年月の請求はありません。</ons-col>
    </ons-row>
  </ng-template>
</div>