<ons-toolbar class="toolbar">
  <div class="left">
    <ons-back-button></ons-back-button>
  </div>
  <div class="center">見守り対象アカウント作成</div>
</ons-toolbar>
<div class="content">
  <div [ngBusy]="m_Busy"></div>
  <div class="center segment-wrap">
    <div class="segment">
      <ul class="stepbar stepbar__three">
        <li class="stepbar__item stepbar__item--current">STEP1</li>
        <li class="stepbar__item">STEP2</li>
        <li class="stepbar__item">STEP3</li>
      </ul>
    </div>
    <div class="content--description">ご利用になるご家族の情報を入力してください。</div>
  </div>

  <form id="profileCreateForm" #formCheck="ngForm" class="formCheck">
    <ons-list class="profile-list">

      <ons-list-header modifier="color" class="list-item__title">氏名
        <span class="required-mark">※</span>
        <div class="text--right required-mark">※必須入力項目</div>
      </ons-list-header>
      <ons-list-item modifier="longdivider" class="list-item__text">
        <ons-input id="proxy-account-signup_001" [(ngModel)]="m_ProxyUser.profile.name.family_name" name="family_name" #familyNameModel="ngModel" 
                    autocomplete="family-name" required placeholder="姓"></ons-input>
        <div class="valid-msg" *ngIf="familyNameModel.errors && (familyNameModel.dirty || familyNameModel.touched)">
          <span *ngIf="familyNameModel.errors.required">必須です。</span>
        </div>
      </ons-list-item>

      <ons-list-item modifier="longdivider" class="list-item__text">
        <ons-input id="proxy-account-signup_002" [(ngModel)]="m_ProxyUser.profile.name.given_name" name="given_name" #givenNameModel="ngModel"
                    autocomplete="given-name" required placeholder="名"></ons-input>
        <div class="valid-msg" *ngIf="givenNameModel.errors && (givenNameModel.dirty || givenNameModel.touched)">
          <span *ngIf="givenNameModel.errors.required">必須です。</span>
        </div>
      </ons-list-item>
      <ons-list-header modifier="color" class="list-item__title">氏名（かな）<span class="required-mark">※</span></ons-list-header>
      <ons-list-item modifier="longdivider" class="list-item__text">
        <ons-input id="proxy-account-signup_003" [(ngModel)]="m_ProxyUser.profile.name.family_name_kana" name="family_name_kana"
                    #familyNameKanaModel="ngModel" required pattern="^[ぁ-んー]*$" placeholder="せい"></ons-input>
        <div class="valid-msg"
          *ngIf="familyNameKanaModel.errors && (familyNameKanaModel.dirty || familyNameKanaModel.touched)">
          <span *ngIf="familyNameKanaModel.errors.required">必須です。</span>
          <span *ngIf="familyNameKanaModel.errors.pattern">「ひらがな」以外の文字が含まれています。</span>
        </div>
      </ons-list-item>
      <ons-list-item modifier="longdivider" class="list-item__text">
        <ons-input id="proxy-account-signup_004" [(ngModel)]="m_ProxyUser.profile.name.given_name_kana" name="given_name_kana" #givenNameKanaModel="ngModel"
                    required pattern="^[ぁ-んー]*$" placeholder="めい"></ons-input>
        <div class="valid-msg"
          *ngIf="givenNameKanaModel.errors && (givenNameKanaModel.dirty || givenNameKanaModel.touched)">
          <span *ngIf="givenNameKanaModel.errors.required">必須です。</span>
          <span *ngIf="givenNameKanaModel.errors.pattern">「ひらがな」以外の文字が含まれています。</span>
        </div>
      </ons-list-item>

      <ons-list-header modifier="color" class="list-item__title">生年月日<span class="required-mark">※</span></ons-list-header>
      <ons-list-item modifier="longdivider" class="list-item__text">
        <div class="form-select-box">
          <ons-select class="birthday-year dropdown-toggle" [(ngModel)]="userBirthYear" name="userBirthYear"
                      #userBirthYearModel="ngModel" (change)="canEdit()" required>
            <option [value]="year" *ngFor="let year of years;">{{ year }}</option>
          </ons-select>
          <div class="mark--delimiter">/</div>
          <ons-select class="birthday-month dropdown-toggle" [(ngModel)]="userBirthMonth" name="userBirthMonth"
                      #userBirthMonthModel="ngModel" (change)="canEdit()" required>
            <option [value]="month" *ngFor="let month of months;">{{ month + 1 }}</option>
          </ons-select>
          <div class="mark--delimiter">/</div>

          <ons-select class="birthday-day dropdown-toggle" [(ngModel)]="userBirthDay" name="userBirthDay"
                      #userBirthDayModel="ngModel" (change)="canEdit()" required>
            <option [value]="day" *ngFor="let day of days;">{{ day }}</option>
          </ons-select>

        </div>
        <div class="valid-msg" *ngIf="isFutureDate">
          <span>未来の日付は指定できません。</span>
        </div>
        <div class="valid-msg" *ngIf="isDateValid == false && isFutureDate == false">
          <span>日付が不正です。</span>
        </div>
      </ons-list-item>

      <ons-list-header modifier="color" class="list-item__title">性別</ons-list-header>
      <ons-list-item modifier="longdivider" class="list-item__text">
        <div>
          <ons-select [(ngModel)]="m_ProxyUser.profile.sex" name="sex" #sexModel="ngModel" [attr.modifier]="m_ProxyUser.profile.sex" required>
            <option *ngFor="let modifier of SEX" [value]="modifier.value">
              {{ modifier.label }}
            </option>
          </ons-select>
        </div>
        <div class="valid-msg" *ngIf="sexModel.errors && (sexModel.dirty || sexModel.touched)">
          <span *ngIf="sexModel.errors.required">「性別」は必須です。</span>
        </div>
      </ons-list-item>
    </ons-list>

    <div class="address-list">
      <p class="address-list__title">
        住所<span class="required-mark">※</span>
      </p>
      <div class="content--text--check-wrap">
        <ons-checkbox id="proxy-account-signup_005" name="sameParent" [(ngModel)]="m_bSameManagerAddress" class="left" (change)="changeCheckboxSameAddress()"></ons-checkbox>
        <p class="content--text--small">
          管理者と同じ住所を登録
        </p>
      </div>
      <fieldset id="addressArea" [disabled]="m_bSameManagerAddress">
        <dl class="address-list__item">
          <dt class="address-title">郵便番号</dt>
          <dd class="address-data">
            <ons-input id="proxy-account-signup_006" inputmode="numeric" [(ngModel)]="m_ProxyUser.profile.address.zip" name="zip" #zipModel="ngModel" [required]="m_bAddressRequired"
              pattern="^\d{7}$" maxlength="7" autocomplete="postal-code" placeholder="1234567" class="input--short zip"
              (ngModelChange)="zipEmptyMessageClear()">
            </ons-input>
            <ons-button id="proxy-account-signup_007" [disabled]="zipModel.errors" (click)="addressAutocomplete()" class="address-button">住所自動入力</ons-button>
            <div class="valid-msg" *ngIf="zipModel.errors && (zipModel.dirty || zipModel.touched)">
              <span *ngIf="zipModel.errors.required">必須です。</span>
              <span *ngIf="zipModel.errors.pattern">半角数字７桁で入力ください。</span>
              <span *ngIf="zipModel.errors.maxlength">最大７文字です。</span>
            </div>
            <div class="valid-msg" *ngIf="m_ZipEmptyMessage.length > 0"><span>{{ m_ZipEmptyMessage }}</span></div>
          </dd>
        </dl>
        <dl class="address-list__item">
          <dt class="address-title">都道府県</dt>
          <dd class="address-data">
            <ons-input id="proxy-account-signup_008" [(ngModel)]="m_ProxyUser.profile.address.prefecture" name="prefecture" #prefectureModel="ngModel" autocomplete="address-level1"
              placeholder="都道府県" [required]="m_bAddressRequired"></ons-input>
            <div class="valid-msg" *ngIf="prefectureModel.errors && (prefectureModel.dirty || prefectureModel.touched)">
              <span *ngIf="prefectureModel.errors.required">必須です。</span>
            </div>
          </dd>
        </dl>
        <dl class="address-list__item">
          <dt class="address-title">市区町村</dt>
          <dd class="address-data">
            <ons-input id="proxy-account-signup_009" [(ngModel)]="m_ProxyUser.profile.address.city" name="city" #cityModel="ngModel" autocomplete="address-level2"
              placeholder="市区町村" [required]="m_bAddressRequired"></ons-input>
            <div class="valid-msg" *ngIf="cityModel.errors && (cityModel.dirty || cityModel.touched)">
              <span *ngIf="cityModel.errors.required">必須です。</span>
            </div>
          </dd>
        </dl>
        <dl class="address-list__item">
          <dt class="address-title">町域</dt>
          <dd class="address-data">
            <ons-input id="proxy-account-signup_010" [(ngModel)]="m_ProxyUser.profile.address.address1" name="address1" #address1Model="ngModel" autocomplete="address-level3"
              placeholder="町域" [required]="m_bAddressRequired"></ons-input>
            <div class="valid-msg" *ngIf="address1Model.errors && (address1Model.dirty || address1Model.touched)">
              <span *ngIf="address1Model.errors.required">必須です。</span>
            </div>
          </dd>
        </dl>
        <dl class="address-list__item">
          <dt class="address-title">番地以降</dt>
          <dd class="address-data">
            <ons-input id="proxy-account-signup_011" [(ngModel)]="m_ProxyUser.profile.address.address2" name="address2" #address2Model="ngModel"
              placeholder="番地以降" [required]="m_bAddressRequired"></ons-input>
            <div class="valid-msg" *ngIf="address2Model.errors && (address2Model.dirty || address2Model.touched || m_bAddressAutoCompleted)">
              <span *ngIf="address2Model.errors.required">必須です。</span>
            </div>
          </dd>
        </dl>
        <dl class="address-list__item">
          <dt class="address-title">建物名・<br />部屋番号</dt>
          <dd class="address-data">
            <ons-input id="proxy-account-signup_012" [(ngModel)]="m_ProxyUser.profile.address.address3" name="address3" placeholder="建物名・部屋番号"></ons-input>
          </dd>
        </dl>
      </fieldset>
    </div>
    
    <div class="button-wrap">
      <div class="valid-msg" *ngIf="formCheck.invalid == true || isDateValid == false || isFutureDate == true">
        <span class="valid-msg__text">必須入力項目が正しく入力されていません。</span>
      </div>
      <ons-button id="proxy-account-signup_013" modifier="large--cta noFixed" (click)="pushLocationEdit()"
        [disabled]="formCheck.invalid || !isDateValid || isFutureDate">次へ</ons-button>
    </div>
  </form>
</div>
