//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { OnsNavigator } from 'ngx-onsenui';
import * as moment from 'moment';
import * as CONST from '../../../constants/constant';

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service'
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { UserWebApiService } from '../../../http-services/user-web-api.service';
import { PurchaseWebApiService } from '../../../http-services/purchase-web-api.service';
import { FamilyWebApiService } from '../../../http-services/family-web-api.service';

// component
import { PurchaseTicketOwnedComponent } from '../purchase-ticket-owned/purchase-ticket-owned.component';
import { PurchaseTicketNewComponent } from '../purchase-ticket-new/purchase-ticket-new.component';

// interface
import { common } from '../../../interfaces/common';
import { parameter } from '../../../interfaces/parameter';
import { ExUser, PossessionsTicket, Ticket, Relation, UserName } from '../../../interfaces/response';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 定期券購入画面
 *
 * @export
 * @class PurchaseTicketComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @author （株）オブジェクトデータ wada
 */
@Component({
  selector: 'ons-page[purchase-ticket]',
  templateUrl: './purchase-ticket.component.html',
  styleUrls: ['./purchase-ticket.component.css']
})
export class PurchaseTicketComponent implements OnInit, OnDestroy 
{

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * ユーザー情報
   *
   * @type {ExUser}
   * @memberof PurchaseTicketComponent
   */
  userInfo: ExUser;

  /**
   * 利用者として選択中のユーザ情報
   *
   * @type {ExUser}
   * @memberof PurchaseTicketComponent
   */
  selectedUser: ExUser;

  /**
   * 選択中のユーザ情報
   *    selectBoxバインディング文字列
   *
   * @type {string}
   * @memberof PurchaseTicketComponent
   */
  selectedUserJSON: string;
  
  /**
   * 所有/購入乗車券情報
   *
   * @type {PossessionsTicket}
   * @memberof PurchaseTicketComponent
   */
  ownedTicket: PossessionsTicket;

  /**
   * 利用者（自身＋ファミリーメンバー）別の所有乗車券情報
   *    m_PossessionsTicket.ticket情報
   *
   * @type {object}
   * @memberof PurchaseTicketComponent
   */
  customersTicket: object = {};

  /**
   * ファミリー情報
   *
   * @type {Relation}
   * @memberof FamilyComponent
   */
  familiesInfo: Relation[] = [];

  /**
   * 利用者（自身＋ファミリメンバー）リスト
   *
   * @type {common.selectBox[]}
   * @memberof PurchaseTicketComponent
   */
  customerList: common.selectBox[] = [];

  /**
   * 乗車券の名称
   *    定額乗車券
   *
   * @type {string}
   * @memberof PurchaseTicketComponent
   */
  ticketTypeName: string = CONST.Purchase.TICKET_TITLE_CITIZEN;

  /**
   * 新規購入可能か否か
   *
   * @type {boolean}
   * @memberof PurchaseTicketComponent
   */
  purchasable: boolean = true;

  /**
   * 所有乗車券を閲覧可能か否か
   *
   * @type {boolean}
   * @memberof PurchaseTicketComponent
   */
  accessible: boolean = true;

  /**
   * テンプレートに直接描画するメッセージ
   *
   * @memberof PurchaseTicketComponent
   */
  templateMsg: string[] = [];

  /**
   * 日付フォーマット用
   *
   * @memberof PurchaseTicketComponent
   */
  moment = moment;

  /**
   * 購読用サブスクリプション
   *
   * @type {Subscription}
   * @memberof PurchaseDetailsComponent
   */
  busy: Subscription;

  /**
   * ページ番号（初期化用）
   *
   * @type {number}
   * @memberof PurchaseTicketComponent
   */
  readonly pageTopIndex: number = 1;

  /**
   * ページ番号
   *    トップ以外に戻る場合に書き換えられる。
   *
   * @type {number}
   * @memberof PurchaseTicketComponent
   */
  pageIndex: number = 1;
  
//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of PurchaseTicketComponent.
   * @param {ApplicationMessageService} appMsg
   * @param {UserWebApiService} userWebApiService
   * @param {PurchaseWebApiService} purchaseWebApiService
   * @param {OnsNavigator} navigator
   * @memberof PurchaseTicketComponent
   */
  constructor(
    private appMsg: ApplicationMessageService, 
    private errResServ: HttpErrorResponseParserService, 
    private userWebApiService: UserWebApiService, 
    private purchaseWebApiService: PurchaseWebApiService, 
    private familyWebApiService: FamilyWebApiService, 
    private navigator: OnsNavigator,
    private msg: MESSAGE,
  ) { }

  /**
   * 初期化処理。
   *
   * @memberof PurchaseTicketComponent
   */
  ngOnInit(): void 
  {
    // ユーザー情報を取得
    this.userInfo = this.userWebApiService.getUserInfo();

    // ログインユーザを選択中の利用者として登録（interfaceのコピー）
    this.selectedUser = JSON.parse(JSON.stringify(this.userInfo));
    
    // 最新のファミリー情報を取得
    this.busy = this.familyWebApiService.getFamily().subscribe({
      next: res => {
        // ファミリー情報を更新
        this.setFamilyInfo(res.body);
    
        // 利用者リストを作成
        this.createCustomerList();
    
        // 表示用エラーメッセージを初期化
        this.templateMsg = [];
    
        // 小学校入学前のユーザは利用不可
        // if (true === this.isPreschooler())
        // {
          // ファミリー管理者から制限されている場合は利用不可
          // this.isFamilyLimit();
        // }
    
        // 利用者ごとの所有乗車券配列を初期化
        this.customersTicket = {};
    
        // 所有乗車券取得
        this.getTicketInfo();
      },
      error: this.errResServ.doParse((_err, errContent) => this.errResServ.viewErrDialog(errContent))
    })
  }

  /**
   * 破棄処理。
   *
   * @memberof PurchaseTicketComponent
   */
  ngOnDestroy(): void 
  {
    // 破棄
    this.busy?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 利用者変更時のイベントハンドラ。
   *
   * @memberof PurchaseTicketComponent
   */
  changeCustomer(): void 
  {
    // 初期化
    this.purchasable = true;
    this.accessible = true;

    this.selectedUser = JSON.parse(this.selectedUserJSON);

    this.templateMsg = [];
    // // 小学校入学前のユーザは利用不可
    // if (true === this.isPreschooler())
    // {
    //   // ファミリー管理者から制限されている場合は利用不可
    //   this.isFamilyLimit();
    // }
  }

  /**
   * 所有乗車券選択時のイベントハンドラ。
   *
   * @param {Ticket} argTicket 表示する所有乗車券情報
   * @memberof PurchaseTicketComponent
   */
  selectTicket(argTicket: Ticket): void 
  {
    // 所有乗車券画面を表示
    this.navigator.element.pushPage(PurchaseTicketOwnedComponent,
      {
        data: { 
                userProfile: this.selectedUser, 
                ticket: argTicket
              }
      }
    );
  }

  /**
   * 新規購入ボタン押下時のイベントハンドラ。
   *
   * @memberof PurchaseTicketComponent
   */
  buyNewTicket(): void 
  {
    let posTicket = this.ownedTicket;
    
    // 選択した利用者の所有乗車券を抽出（参照先を変えるためコピー）
    posTicket.tickets = [].concat(<Ticket[]>this.customersTicket[this.selectedUser.user_id]);

    // 規約未合意(user_typeがundefined)の場合、遷移せずダイアログ表示
    if (this.selectedUser.user_type === void 0) {
      const name: UserName = this.selectedUser.profile.name;
      this.appMsg.viewDialogMessage(this.msg.CLIENT.PURCHASE.E_NOT_AGREE_BUY_TICKET.message(name.family_name + name.given_name));
      return;
    }
    
    // 乗車券新規購入画面を表示
    this.navigator.element.pushPage(PurchaseTicketNewComponent,
      {
        data: { 
                userProfile: this.selectedUser,
                returnPage: (backPageIndex?: number, update: boolean = true) => { this.returnPage(backPageIndex, update); }
                // refresh: (backPageIndex?: number, update: boolean = true) => { this.pagerService.movePage(this, this.pageIndex, this.navigator.element.pages.length - 1, this.navigator.element.removePage, backPageIndex, update); }
              }
      }
    );
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * 利用者リスト（自身＋ファミリメンバー（自身が管理者の場合のみ）を作成する。
   *
   * @memberof PurchaseTicketComponent
   */
  private createCustomerList(): void 
  {
    this.customerList = [];

    // 先頭は自分自身
    this.selectedUser = {
      user_id: this.userInfo.user_id, 
      user_type: this.userInfo.user_type, 
      suspend_date: this.userInfo.suspend_date, 
      profile: 
      {
        name: this.userInfo.profile.name, 
        birth: this.userInfo.profile.birth
      }, 
      identity: this.userInfo.identity
    };
    
    // ログインユーザを初期選択
    this.selectedUserJSON = JSON.stringify(this.selectedUser);

    this.customerList.push(
      {
        value: this.selectedUserJSON, 
        label: this.selectedUser.profile.name.family_name + ' ' + this.selectedUser.profile.name.given_name
      }
    );

    // ファミリーメンバーを追加
    this.familiesInfo.forEach(r => 
      {
        let family: ExUser = {
          user_id: r.user_id, 
          user_type: r.user_type, 
          suspend_date: undefined, 
          profile: 
          {
            name: r.name, 
            birth: r.birth
          }, 
          // identity: r.ide
        };

        this.customerList.push(
          {
            value: JSON.stringify(family), 
            label: r.name.family_name + " " + r.name.given_name
          }
        );
      }
    );
  }

  /**
   * ファミリー情報を格納する。
   *
   * @private
   * @param {Relation[]} family
   * @memberof PurchaseTicketComponent
   */
  private setFamilyInfo(family: Relation[]): void 
  {
    // ファミリー情報を取得（メンバーかつ招待承認待ち以外）
    // this.m_Busy = this.familyWebApiService.getFamily().subscribe();
    // let family = this.familyWebApiService.getFamilyInfo();

    // ファミリーがある場合
    if (undefined !== family) 
    {
      this.familiesInfo = family.filter(r => r.status !== 'waiting' && r.role === 'child');
    }
    // ない場合は m_FamilyRelations が初期化状態のため、length:0となる（そのためArrayメソッドにアクセスしても落ちない）
  }

  /**
   * 利用者が購入可能学年（小学校入学後）か判断する。
   *    小学校入学前の場合、
   *    エラーを表示して購入不可とする。
   *
   * @memberof PurchaseTicketComponent
   */
  // private isPreschooler(): boolean 
  // { 
  //   // 購入可能ユーザか
  //   let ret = this.purchaseWebApiService.isPreschooler(this.selectedUser);

  //   // 購入不可
  //   if (false === ret) 
  //   {
  //     // メッセージ生成
  //     let title: string = CONST.Purchase.TICKET_TITLE_CITIZEN;
  //     if (this.selectedUser.user_type === "tourist") title = CONST.Purchase.TICKET_TITLE_TOURIST;
  //     this.templateMsg.push(this.appMsg.getCLientMsg(this.appMsg.CLIENT_CODE.PURCHASE.NOT_TARGET_AGE6, ["$TICKET_TYPE$", title]));

  //     // 新規購入、所有乗車券閲覧不可
  //     this.purchasable = false;
  //     this.accessible = false;
  //   }

  //   return ret;
  // }

  /**
   * 利用制限中か判断する。
   *    ファミリー管理者から利用制限を設定されている場合、
   *    エラーを表示して購入不可とする。
   *
   * @private
   * @return {*}  {boolean}
   * @memberof PurchaseTicketComponent
   */
  // private isFamilyLimit(): boolean 
  // {
  //   // 制限状態を取得
  //   let ret = this.familyWebApiService.isLoginUserUtilization();

  //   // ファミリー管理者から制限されている場合は遷移不可
  //   if (true === ret)
  //   {
  //     let msg = this.appMsg.getCLientMsg(this.appMsg.CLIENT_CODE.FAMILY.RESTRICTED_UTILIZATION);

  //     let index = msg.indexOf("<br>");
  //     this.templateMsg.push(msg.substring(0, index));
  //     this.templateMsg.push(msg.slice(index + 4));

  //     // 新規購入不可、所有乗車券閲覧可
  //     this.purchasable = false;
  //     this.accessible = true;
  //   }

  //   return ret;
  // }
  
  /**
   * ページ遷移を行う。
   *    任意のページから各タブのトップまたは、
   *    指定のページまで遷移する。
   *
   * @private
   * @param {number} [backPageIndex] 戻りたいページ番号
   * @param {boolean} [execution=true] 遷移後に処理を行うか
   * @memberof PurchaseTicketComponent
   */
  private returnPage(backPageIndex?: number, execution: boolean = true): void 
  {
    // 現在のページ番号
    const nCurrentIndex = this.navigator.element.pages.length - 1;

    // 戻り先が定義されているなら設定
    if (undefined !== backPageIndex) this.pageIndex = backPageIndex;

    // 目的のページまで遷移するまで再帰呼び出し
    if (this.pageIndex < nCurrentIndex) 
    {
      this.navigator.element.removePage(this.pageIndex + 1)
      .then(() => { setTimeout(() => { this.returnPage(backPageIndex, execution); }, 0); });
    } 
    else if (this.pageIndex === nCurrentIndex) 
    {
      this.pageIndex = this.pageTopIndex;

      // 所有乗車券取得
      if (execution) this.getTicketInfo();
    }
    else ;
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * 所有乗車券取得処理
   *
   * @memberof PurchaseTicketComponent
   */
  private getTicketInfo(): void 
  {
    // パラメータ作成
    let params: parameter.PossessionsTicket;

    // ログインユーザが管理者　かつ　ファミリーが一人以上いる
    if (this.familiesInfo.length > 0 && this.familiesInfo[0].role === "child") 
    {
      // 購入者がログインユーザの乗車券を取得（利用期間は乗車券取得後にフィルタリング）
      params = { im: "payer", valid: "in-before" };
    }
    else // ログインユーザのみ
    {
      // 利用者がログインユーザ　かつ　仕様前/使用中の乗車券を取得
      params = { im: "user", valid: "in-before" };
    }

    // 所有/購入したチケットの一覧を取得
    this.busy = this.purchaseWebApiService.getPurchaseDataList<PossessionsTicket>(params) 
    .subscribe(
      {
        // 所有乗車券取得
        next: response => 
        {
          this.ownedTicket = response.body;
          
          // im: "payer", valid: "in-before"が実装されたため下記を削除
          // if (params.im === "payer") 
          // {
          //   // ログインユーザが購入者となっている仕様前/使用中乗車券を抽出
          //   this.ownedTicket.tickets = response.body.tickets?.filter(t => 
          //     t.ticket_type !==  1 && 
          //     new Date(t.end_date).getTime() >= new Date(new Date().setHours(0, 0, 0, 0)).getTime()
          //   );
          // }

          // 自動継続前のチケットが存在する場合、自動継続後チケットを表示対象から除く
          this.ownedTicket.tickets = this.ownedTicket.tickets.filter((ticket, _, self) => {
            return !self.some(_ticket => _ticket.next_ticket_id===ticket.ticket_id);
          });

          // ログインユーザの所有乗車券を保存
          this.customersTicket[this.userInfo.user_id] = this.ownedTicket.tickets.filter(t => t.user.user_id == this.userInfo.user_id);

          // ファミリーの所有乗車券を保存
          this.familiesInfo.forEach(r => this.customersTicket[r.user_id] = this.ownedTicket.tickets.filter(t => t.user.user_id == r.user_id));
        }, 
        error: this.errResServ.doParse((_error, customErrorContent) => 
        {
          // アカウント停止時はサーバからエラーが返ってくる
          // ToDo：メッセージをどう出すか
          this.appMsg.viewDialogMessage(this.msg.CLIENT.PURCHASE.E_GET_POSSESSIONS.message());
          
          // 新規購入不可
          this.purchasable = false;
        })
      }
    );
  }
}