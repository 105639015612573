<ons-toolbar>
  <div class="left">
    <ons-back-button></ons-back-button>
  </div>
  <div *ngIf="deleteMode === 'family'" class="center">ファミリーリンク解除</div>
  <div *ngIf="deleteMode === 'account'" class="center">アカウント削除</div>
</ons-toolbar>
<div class="content">
  <div [ngBusy]="busy"></div>

  <!-- ファミリーリンク解除 -->
  <div *ngIf="deleteMode === 'family'">
    <ons-list class="content--list--name">
      <ons-list-header *ngIf="loginUserRole === 'child'" modifier="color" class="list-item__title">あなたの管理者</ons-list-header>
      <ons-list-item modifier="longdivider" class="list-item__text">{{ relation.name.family_name }} {{ relation.name.given_name }}</ons-list-item>
    </ons-list>
    <br>

    <div *ngIf="loginUserRole === 'parent' && relation.status === 'request'" class="content--description--top">
      「{{ relation.name.family_name }} {{ relation.name.given_name }}」さんがファミリーリンク解除の申請を行いました。<br>
      申請を承認しますか？
    </div>
    <div class="content--text">
      ファミリーリンクを削除すると、<br>以下の項目が利用できなくなります。
    </div>
    <div class="content--list">
      <ul>
        <li>管理者支払いでの各種有料サービス</li>
      </ul>
      <div *ngIf="loginUserRole === 'parent'">
        <ul>
          <li>当アカウントがこれまで利用したサービスの利用履歴確認</li>
        </ul>
      </div>
    </div>
    <div class="content--text">
      利用前の予約が残っている場合、<br>リンクを削除することができません。<br>
      予約を全てキャンセルしてから<br>リンク削除を行ってください。
    </div>

    <div class="button-wrap">
      <div *ngIf="loginUserRole === 'parent'">
        <div *ngIf="relation.status !== 'request'" class="button-wrap content--description">
          <ons-button id="family-delete_001" class="button button--large--cta" (click)="deleteFamily(true)">ファミリーリンク解除</ons-button>
        </div>
        <div *ngIf="relation.status === 'request'" class="button-wrap--harf button-wrap--flex">
          <ons-button id="family-delete_002" class="button button--large--cta button--cancel" (click)="deleteFamily(false)">却下</ons-button>
          <ons-button id="family-delete_003" class="button button--large--cta" (click)="deleteFamily(true)">承認</ons-button>
        </div>
      </div>
      <div *ngIf="loginUserRole === 'child'">
        <div class="content--description">管理者にファミリーリンク解除の申請を行う場合は下記をタップしてください。</div>
        <ons-button id="family-delete_004" class="button button--large--cta" (click)="deleteRequest()">解除申請</ons-button>
      </div>
    </div>
  </div>
  
  <!-- アカウント削除 -->
  <div *ngIf="deleteMode === 'account'">

    <ons-list class="content--list--name">
      <ons-list-item modifier="longdivider" class="list-item__text">{{ relation.name.family_name }} {{ relation.name.given_name }}</ons-list-item>
    </ons-list>

    <div class="list-item__title content-text-wrap__title" style="padding: 10px 5px;">削除前に、以下の内容をご確認ください。</div>
    <ons-list>
      <ons-list-item class="list-item_text">
        <div class="content-text-wrap">
          <div class="content-text-wrap__indentback1 content-text-wrap__padding-top">
            <p>
              ・アカウント削除を行うと、上記アカウントに関連付けられたSmartGOTOのデータは削除されます。
            </p>
          </div>
          <div class="content-text-wrap__indentback1 content-text-wrap__padding-top">
            <p>
              ・他の自治体でもご利用中の場合、他の自治体でも同様にアカウント削除となります。
            </p>
          </div>
          <div class="content-text-wrap__indentback1 content-text-wrap__padding-top">
            <p>
              ・以下のいずれかに該当する場合、アカウント削除できません。
            </p>
          </div>
          <div class="content-text-wrap__indentback2">
            <p>
              ①上記アカウントが利用する、当日以降の各種サービス予約がある。
            </p>
          </div>
          <div class="content-text-wrap__indentback2">
            <p>
              ②上記アカウントが利用する、当日以降の定期配車予約設定がある。
            </p>
          </div>
          <div class="content-text-wrap__indentback2">
            <p>
              ③ご自身が、上記アカウントが利用する、継続購入設定ONの定額乗車券を保有している。
            </p>
          </div>
          <div class="content-text-wrap__indentback2">
            <p>
              ④上記アカウントが利用する、当日以降に利用終了日を迎える定額乗車券を保有している。
            </p>
          </div>
          <div class="content-text-wrap__indentback1 content-text-wrap__padding-top content-text-wrap__padding-bottom">
            <p>
              ・削除手続きを行っても削除できず、削除できない理由が不明な場合、{{office.office_name}}({{office.tel}})にお問い合わせください。
            </p>
          </div>
        </div>
      </ons-list-item>
    </ons-list>
    <div class="content-text-wrap__check">
      <ons-checkbox [(ngModel)]="checked" class="left" input-id="check"></ons-checkbox>
      <label class="content--text--small" for="check">上記の内容に同意する</label>
    </div>
    <div class="button-wrap">
      <ons-button modifier="large--cta noFixed" (click)="deleteAccount()"
        [disabled]="!checked">アカウント削除する</ons-button>
    </div>
  </div> 
</div>