<ons-toolbar class="toolbar">
  <div class="left">
      <ons-back-button></ons-back-button>
  </div>
  <div class="center">二段階認証方法</div>
</ons-toolbar>
<div class="content">
  <div [ngBusy]="busy"></div>
  <div class="content--description">
    <p class="content--description-text">
      ログイン時のパスワード認証後、2つ目の手順によって、ご自身のログインであることを確認させて頂いております。<br />
      以下の中から確認に用いる認証方法を選択してください。
    </p>
  </div>

  <ons-list>
    <ons-list-header modifier="color" class="list-item__title">認証方法</ons-list-header>
    <ons-list-item tappable modifier="longdivider" class="list-item__text">
      <div class="right">
        <ons-checkbox input-id="authMailAddress" [(ngModel)]="authMailAddress" (change)="onChangeCheckBox('mail')"></ons-checkbox>
      </div>
      <label for="authMailAddress" class="center">
        メールアドレス認証
      </label>
    </ons-list-item>
    <ons-list-item tappable modifier="longdivider" class="list-item__text">
      <div class="right">
        <ons-checkbox input-id="authSms" [(ngModel)]="authSms" (change)="onChangeCheckBox('sms')"></ons-checkbox>
      </div>
      <label for="authSms" class="center">
        SMS認証
      </label>
    </ons-list-item>

  </ons-list>
</div>