<ons-toolbar>
  <div class="left">
      <ons-back-button></ons-back-button>
  </div>
  <div class="center">所有乗車券</div>
</ons-toolbar>
<div class="content">
  <div [ngBusy]="busy"></div>
  <ons-list>
    <ons-list-header modifier="color" class="list-item__title">利用者</ons-list-header>
    <ons-list-item id="purchase-ticket-owned_001" modifier="longdivider" class="list-item__text">
      {{ purchaseUser.profile.name.family_name + ' ' + purchaseUser.profile.name.given_name }}
    </ons-list-item>

    <ons-list-header modifier="color" class="list-item__title">利用開始日</ons-list-header>
    <ons-list-item id="purchase-ticket-owned_002" modifier="longdivider" class="list-item__text">
      {{ moment(ownTicket.start_date).format('YYYY/MM/DD') }}
    </ons-list-item>
    
    <ons-list-header modifier="color" class="list-item__title">乗車券種類</ons-list-header>
    <ons-list-item id="purchase-ticket-owned_003" modifier="longdivider" class="list-item__text">
      {{ ownTicket.ticket_name }}
      <ng-template #tourist>{{ ownTicket.plan }}</ng-template>
    </ons-list-item>
  </ons-list>
  <!-- <div class="content--text--map" *ngIf="purchaseUser.user_type == 'tourist'">
    <ons-button modifier="quiet" (click)="viewBusStopMap()"><span class="button-text">主要な乗降場を地図で確認</span></ons-button>
  </div> -->
  <ons-list>
    <ons-list-header modifier="color" class="list-item__title">利用終了日</ons-list-header>
    <ons-list-item id="purchase-ticket-owned_004" modifier="longdivider" class="list-item__text">
      {{ moment(ownTicket.end_date).format('YYYY/MM/DD') }}
      <span class="content--text--emphasis" *ngIf="ownTicket.invalid.from !== undefined">
        （有効期限：{{ moment(ownTicket.invalid.from).add(-1, 'days').format('YYYY/MM/DD') }}）
      </span>
    </ons-list-item>
    
    <div *ngIf="isSelectAreaTicket === true && ownTicket.terms.special_type !== 'give_up_license'">
      <ons-list-header modifier="color" class="list-item__title">利用地区
        <!-- <ons-button modifier="quiet" (click)="viewBusStopMap()"><span class="button-text">主要な乗降場を確認</span></ons-button> -->
      </ons-list-header>
      <ons-list-item id="purchase-ticket-owned_005" modifier="longdivider" class="list-item__text">
        <div class="left">{{ ownTicket.area.name }}</div>
      </ons-list-item>
    </div>
    
    <ons-list-header modifier="color" class="list-item__title">料金</ons-list-header>
    <ons-list-item id="purchase-ticket-owned_006" modifier="longdivider" class="list-item__text">
      ￥{{ ownTicket.amount | number:'.0-0'  }}<span>（{{ ownTicket.plan }}）</span>
    </ons-list-item>
    
    <div *ngIf="ownTicket.continuable === true && ownTicket.terms.special_type !== 'give_up_license'">
      <ons-list-header modifier="color" class="list-item__title">自動更新</ons-list-header>
      <ons-list-item id="purchase-ticket-owned_007" modifier="longdivider" class="list-item__text">
        <div class="center">
          自動継続購入を有効にする
        </div>
        <div class="right">
          <ons-switch id="purchase-ticket-owned_008" [disabled]="exclusion" [(value)]="continueSwitchState" (change)="changeContinuous()" modifier="mainColor"></ons-switch>
        </div>
      </ons-list-item>
    </div>
  </ons-list>
  <div *ngIf="ownTicket.continuable === true && ownTicket.terms.special_type !== 'give_up_license'" class="content-text--left">
    <span class="content--text--emphasis">
      自動更新を有効にすると、期限終了時に同じ内容で自動継続購入されます。<br>
      設定の変更は利用終了日の{{ changeLimit }}時まで可能です。
    </span>
  </div>
  <div class="content--text-wrap">
    <p class="content--text">期間途中で払い戻しを行う場合は</p>
    <ons-button id="purchase-ticket-owned_009" modifier="quiet" (click)="viewPayBackMessage()"><span class="button-text">こちら</span></ons-button>
  </div>
</div>