<div class="content">
  <div [ngBusy]="busy"></div>

  <!-- カレンダー -->
  <div id="calendar">
    <full-calendar #calendar [options]="calendarOptions"></full-calendar>
  </div>

  <!-- 選択年月日 -->
  <div class="selected-date" id="history-list_001">
    {{ selectedDate | chatDate: "DATE" }}
  </div>

  <!-- 予定・履歴一覧 -->
  <ul class="plan-list">
    <li *ngFor="let item of scheduleHistory" tappable class="plan-item" (click)="pushDetail(item)">
      <div class="list__item--icon">
        <!-- ステータスアイコン（配車） -->
        <ng-container *ngIf="item.type === 'reservation'">
          <ng-container [ngSwitch]="item.status">
            <ng-container *ngSwitchCase="['reserved', 'working'].includes(item.status) ? item.status : !item.status">
              <img [src]="ASSETS.PLAN" />
            </ng-container>
            <ng-container *ngSwitchCase="['done', 'unboarded'].includes(item.status) ? item.status : !item.status">
              <img [src]="ASSETS.HISTORY" />
            </ng-container>
            <ng-container *ngSwitchCase="'canceled'">
              <img [src]="ASSETS.CANCEL1" />
            </ng-container>
            <ng-container *ngSwitchCase="'aborted'">
              <img [src]="ASSETS.CANCEL2" />
            </ng-container>
          </ng-container>
        </ng-container>

        <!-- ステータスアイコン（注文） -->
        <ng-container *ngIf="item.type === 'order'">
          <ng-container [ngSwitch]="item.status">
            <ng-container *ngSwitchCase="['INIT', 'READY', 'PICKED'].includes(item.status) ? item.status : !item.status">
              <img [src]="ASSETS.PLAN" />
            </ng-container>
            <ng-container *ngSwitchCase="'DELIVERED'">
              <img [src]="ASSETS.HISTORY" />
            </ng-container>
            <ng-container *ngSwitchCase="'CANCELED_USER'">
              <img [src]="ASSETS.CANCEL1" />
            </ng-container>
            <ng-container *ngSwitchCase="['CANCELED_SHOP', 'CANCELED_TRANS', 'CANCELED_OPERATOR', 'CANCELED_SYSTEM'].includes(item.status) ? item.status : !item.status">
              <img [src]="ASSETS.CANCEL2" />
            </ng-container>
          </ng-container>
        </ng-container>

        <!-- ステータスアイコン（観光） -->
        <ng-container *ngIf="item.type === 'exp'">
          <ng-container [ngSwitch]="item.status">
            <ng-container *ngSwitchCase="['INIT', 'USING'].includes(item.status) ? item.status : !item.status">
              <img [src]="ASSETS.PLAN" />
            </ng-container>
            <ng-container *ngSwitchCase="'DONE'">
              <img [src]="ASSETS.HISTORY" />
            </ng-container>
            <ng-container *ngSwitchCase="'CANCELED'">
              <ng-container *ngIf="item.cancel_by === 'user'; else notUser">
                <img [src]="ASSETS.CANCEL1" />
              </ng-container>
              <ng-template #notUser>
                <img [src]="ASSETS.CANCEL2" />
              </ng-template>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>

      <!-- 予定時刻 -->
      <div class="list__item--time">
        <ng-container *ngIf="item.type === 'reservation' || item.type === 'order'">
          <p>{{ item.from.schd_time | chatDate: "TIME" }}</p>
          <p>
            <ng-container *ngIf="item.type === 'reservation'">↓</ng-container>
            <ng-container *ngIf="item.type === 'order'">～</ng-container>
          </p>
          <p>{{ item.to.schd_time | chatDate: "TIME" }}</p>
        </ng-container>
        <!-- 開始日と終了日が異なる場合は時刻ではなく、日付を表示 -->
        <ng-container *ngIf="item.type === 'exp'">
          <p>{{ item.from.schd_time | chatDate: item.viewDate_start }}</p>
          <p>↓</p>
          <p>{{ item.to.schd_time | chatDate: item.viewDate_end }}</p>
        </ng-container>
      </div>

      <!-- 目的地 -->
      <div class="list__item--text">
        <ng-container *ngIf="item.type === 'reservation'">
          <p>{{ item.from.name }}</p>
          <p>↓</p>
          <p>{{ item.to.name }}</p>
        </ng-container>
        <ng-container *ngIf="item.type === 'order'">
          <p>{{ item.from.name }} へ配達</p>
        </ng-container>
        <ng-container *ngIf="item.type === 'exp'">
          <ng-container [ngSwitch]="item.service_type">
            <ng-container *ngSwitchCase="'MOBILITY'">
              <p>{{ item.from.name }}</p>
              <p>↓</p>
              <p>{{ item.to.name }}</p>
            </ng-container>
            <ng-container *ngSwitchCase="'ACTIVITY'">
              <p>{{ item.from.name }}</p>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
      
      <!-- バッジ、合計金額 -->
      <div class="list__item--price">
        <ng-container *ngIf="item.type === 'reservation'">
          <p class="reservation_badge">おでかけ</p>
        </ng-container>
        <ng-container *ngIf="item.type === 'order'">
          <p class="order_badge">買い物</p>
        </ng-container>
        <ng-container *ngIf="item.type === 'exp'">
          <p class="exp_badge">観光</p>
        </ng-container>
        <ng-container *ngIf="checkFamily(item) === true">
          <p class="family_badge">ファミリー</p>
        </ng-container>
        <p>&yen;{{ item.totalPrice | number:'.0-0' }}</p>
      </div>
    </li>
  </ul>

  <div *ngIf="scheduleHistory.length == 0" class="content-description">
    予定はありません
  </div>
</div>