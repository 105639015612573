import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { OnsNavigator, Params } from 'ngx-onsenui';

// service
import { UserWebApiService } from 'src/app/http-services/user-web-api.service';
import { ApplicationMessageService } from 'src/app/lib-services/application-message.service';

// module
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';

// interface
import { ExUser, UserInfo } from 'src/app/interfaces/response';
import { ProxyAccountSignupDetermineComponent } from '../proxy-account-signup-determine/proxy-account-signup-determine.component';
import { MESSAGE } from 'src/app/constants/message';

@Component({
  selector: 'ons-page[app-proxy-account-signup-location-edit]',
  templateUrl: './proxy-account-signup-location-edit.component.html',
  styleUrls: ['./proxy-account-signup-location-edit.component.scss']
})
export class ProxyAccountSignupLocationEditComponent implements OnInit, OnDestroy {

  /**
   * 見守り対象ユーザ入力情報
   *
   * @type {ExUser}
   * @memberof ProxyAccountSignupLocationEditComponent
   */
  proxyUser: ExUser;

  /**
   * 管理者と同じ住所かどうか
   *
   * @type {boolean}
   * @memberof ProxyAccountSignupLocationEditComponent
   */
  isSameManagerAddress: boolean;

  /**
   * 地図から選択した緯度経度
   *
   * @type {google.maps.LatLngLiteral}
   * @memberof ProxyAccountSignupLocationEditComponent
   */
  // location: google.maps.LatLngLiteral;

  /**
   * 登録する住所情報
   *
   * @type {UserInfo["profile"]["address"]}
   * @memberof ProxyAccountSignupLocationEditComponent
   */
  address: UserInfo["profile"]["address"];

  /**
   * 緯度経度の登録有無関係なく、住所から緯度経度を取得するかどうか
   *
   * @type {boolean}
   * @memberof ProxyAccountSignupLocationEditComponent
   */
  isGetLocation: boolean = false;

  constructor(
    private commonFunctionMdl: CommonFunctionModule,
    private params: Params,
    private userWebApiServ: UserWebApiService,
    private navigator: OnsNavigator,
    private appMsg: ApplicationMessageService,
    private msg: MESSAGE,
  ) { }

  /**
   * 初期処理
   *
   * @memberof ProxyAccountSignupLocationEditComponent
   */
  ngOnInit(): void {
    // 見守り対象アカウント
    this.proxyUser = this.commonFunctionMdl.deepcopy(this.params.data.proxyUser);
    // 管理者と同じ住所かどうか
    this.isSameManagerAddress = this.params.data.sameManagerAddress;

    // 「管理者と同じ住所を登録」チェックをいれた場合、ログインユーザ(管理者)の住所を代入
    if (this.isSameManagerAddress) this.address = this.commonFunctionMdl.deepcopy(this.userWebApiServ.getUserInfo().profile.address);
    // 「管理者と同じ住所を登録」チェックをいれていない場合、情報新規登録画面で入力した住所を代入
    else this.address = this.proxyUser.profile.address;

    // 「管理者と同じ住所を登録」チェックをいれた and 管理者の地点情報(緯度経度)が登録されている
    if (this.address.location) this.isGetLocation = false;
    
    //「管理者と同じ住所を登録」チェックをいれたが、管理者の地点情報(緯度経度)が登録されていない
    // or 「管理者と同じ住所を登録」をいれていない
    else this.isGetLocation = true;
  }

  ngOnDestroy(): void {
  }

  // ================================================================================
  // イベントハンドラ
  // ================================================================================

  /**
   * 選択した緯度経度を代入し、見守り対象アカウントプロフィール確認画面へ遷移
   *
   * @memberof ProxyAccountSignupLocationEditComponent
   */
  pushProxyDetermine(): void {

    // 住所情報(選択した緯度経度を含む)を登録するユーザ情報に代入
    this.proxyUser.profile.address = this.commonFunctionMdl.deepcopy(this.address);

    // 見守り対象アカウントプロフィール確認画面へ遷移
    this.navigator.element.pushPage(ProxyAccountSignupDetermineComponent, { data: { proxyUser: this.proxyUser }});
  }

  /**
   * 選択中の緯度経度の更新
   *
   * @param {google.maps.LatLngLiteral} location
   * @memberof ProxyAccountSignupLocationEditComponent
   */
  updateLocation(location: google.maps.LatLngLiteral): void {

    // 入力した住所に該当する地点(緯度経度)が見つからない場合
    if (!location) {
      this.appMsg.viewDialogMessage(this.msg.CLIENT.PROFILE.NOT_EXIST_ADDRESS.message(), () => {
        this.navigator.element.popPage();
      });
      return;
    }

    // 選択した地点情報(緯度経度)を登録する住所に代入
    this.address.location = this.commonFunctionMdl.deepcopy(location);
  }
}
