//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

// service
import { MenuService } from 'src/app/lib-services/menu.service';

// component
import { SplitterSideMenuComponent } from './splitter-side-menu/splitter-side-menu.component';
import { TabbarComponent } from '../tabbar/tabbar.component';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * ハンバーガーメニュー管理。
 *
 * @export
 * @class SplitterComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @author （株）オブジェクトデータ wada
 */
@Component({
  selector: 'ons-page[splitter]',
  templateUrl: './splitter.component.html',
  styleUrls: ['./splitter.component.css']
})
export class SplitterComponent implements OnInit, OnDestroy 
{

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * メニューページ
   *
   * @memberof SplitterComponent
   */
  sidePage = SplitterSideMenuComponent;
  
  /**
   * コンテンツページ
   *
   * @memberof SplitterComponent
   */
  contentPage = TabbarComponent;
  
  /**
   * ons-splitterのコントロールID
   *
   * @memberof SplitterComponent
   */
  @ViewChild('splitter') splitter: any;

  /**
   * 購読用
   *
   * @type {Subscription}
   * @memberof SplitterComponent
   */
  busy: Subscription;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of SplitterComponent.
   * @param {MenuService} menuService
   * @memberof SplitterComponent
   */
  constructor(private menuService: MenuService) 
  {
    this.busy = this.menuService.menu$.subscribe(() => this.splitter.nativeElement.side.open());
  }

  /**
   * 初期化処理。
   *
   * @memberof SplitterComponent
   */
  ngOnInit(): void 
  { }

  /**
   * 破棄処理。
   *
   * @memberof SplitterComponent
   */
  ngOnDestroy(): void 
  {
    this.busy?.unsubscribe();
  }
}
