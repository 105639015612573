<ons-toolbar>
  <div class="left">
    <ons-back-button></ons-back-button>
  </div>
  <div class="center">推奨環境</div>
</ons-toolbar>

<div class="content">
  <div class="content--list--description">
    SmartGOTOを快適にご利用いただくために、以下の環境でのご利用を推奨いたします。<br>
    また、JavaScriptが使用できる状態でご利用ください。
  </div>

  <div class="content--position">
    <ons-row>
      <ons-col class="content--top--left" width="20%"></ons-col>
      <ons-col class="list-header--color content--top">iPhone</ons-col>
      <ons-col class="list-header--color content--top">Android</ons-col>
    </ons-row>
    <ons-row>
      <ons-col class="list-header--color" width="20%">OS</ons-col>
      <ons-col class="content--col">iOS（最新版）</ons-col>
      <ons-col class="content--col">Android（最新版）</ons-col>
    </ons-row>
    <ons-row>
      <ons-col class="list-header--color" width="20%">ブラウザ</ons-col>
      <ons-col class="content--col">Safari</ons-col>
      <ons-col class="content--col">Chrome（最新版）</ons-col>
    </ons-row>
  </div>
</div>
