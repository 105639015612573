<ons-toolbar>
  <div class="left">
      <ons-back-button></ons-back-button>
  </div>
  <div class="center">{{ ticketTypeName }}購入</div>
</ons-toolbar>
<div class="content">
  <div [ngBusy]="busy"></div>
  <ons-list>
    <ons-list-header modifier="color" class="list-item__title">利用者</ons-list-header>
    <ons-list-item id="purchase-ticket_001" modifier="longdivider" class="list-item__text">
      <div class="form-select-box">
        <ons-select [(ngModel)]="selectedUserJSON" [attr.modifier]="selectedUserJSON" (change)="changeCustomer()" required>
          <option *ngFor="let list of customerList" [value]="list.value">{{ list.label }}</option>
        </ons-select>
      </div>
    </ons-list-item>
    <ons-list-header modifier="color" class="list-item__title" *ngIf="accessible === true">所有乗車券</ons-list-header>
    <div id="purchase-ticket_002" *ngIf="customersTicket[selectedUser.user_id] !== undefined && customersTicket[selectedUser.user_id].length > 0 && accessible === true; else noTicket">
      <ons-list-item *ngFor="let item of customersTicket[selectedUser.user_id]" tappable modifier="chevron nodivider" class="list-item__text" (click)="selectTicket(item)">
        <div>
          {{ item.area.name }}
          <dl class="plan-item">
            <dt>{{ item.ticket_name }}<ng-container *ngIf="item.ticket_name !== item.plan">（{{ item.plan }}）</ng-container></dt>
          </dl>
        </div>
        <dl class="plan-item">
          <dt>
            <span class="list-item__subtitle">
              {{ '（' + moment(item.start_date).format('YYYY/MM/DD') + '～' + moment(item.end_date).format('YYYY/MM/DD')  + '）' }}
              <span *ngIf="item.continuable === true && item.terms.special_type !== 'give_up_license'">{{ item.continue == true ? "自動更新有効" : "自動更新無効" }}</span>
            </span></dt>
        </dl>
      </ons-list-item>
    </div>
    <ng-template #noTicket>
      <ons-list-item modifier="longdivider" class="list-item__text" *ngIf="purchasable === true">
        現在所有している{{ ticketTypeName }}はありません。
      </ons-list-item>
    </ng-template>
  </ons-list>
  <div class="button-wrap">
    <p *ngIf="purchasable === false" class="content--text--emphasis"><span *ngFor="let msg of templateMsg">{{ msg }}<br></span></p>
    <ons-button id="purchase-ticket_003" [disabled]="!purchasable" class="button button--large--cta" (click)="buyNewTicket()">
      新規購入
    </ons-button>
  </div>
 </div>