//=============================================================================================
// インポート
//=============================================================================================
import { Component, ElementRef, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { OnsNavigator, Params } from 'ngx-onsenui';
import * as dayjs from 'dayjs';
import { Subscription } from 'rxjs';

// service
import { ExpWebApiService } from 'src/app/http-services/exp-web-api.service';
import { HttpErrorResponseParserService } from 'src/app/lib-services/http-error-response-parser.service';
import { ApplicationMessageService } from 'src/app/lib-services/application-message.service';

// module
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';

// interface
import { request } from 'src/app/interfaces/request';
import { ExpFetchReservation, ExpService, SgServiceOption } from 'src/app/interfaces/response';
import { ExpBill } from '../exp-detail/exp-detail.component';

// parts
import { ListParts } from 'src/app/components/parts/ons-list/ons-list.component';
import { ExpCompleteComponent } from '../exp-complete/exp-complete.component';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 観光サービス予約確認画面。
 *
 * @export
 * @class ExpConfirmComponent
 * @implements {OnInit}
 * @implements {AfterViewInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ons-page[exp-confirm]',
  templateUrl: './exp-confirm.component.html',
  styleUrls: ['./exp-confirm.component.scss']
})
export class ExpConfirmComponent implements OnInit, AfterViewInit, OnDestroy {

  readonly ASSETS = {
    INFORMATION: CommonFunctionModule.getAssetsUrl('/image/button/info.png')
  } as const;

  /**
   * 通信監視用Subscription
   *
   * @type {Subscription}
   * @memberof ExpConfirmComponent
   */
  busy: Subscription

  /**
   * 画面リスト用データ
   *
   * @type {{
   *     header: ListParts[];
   *     body1: ListParts[];
   *     body2: ListParts[];
   *   }}
   * @memberof ExpConfirmComponent
   */
  template: {
    header: ListParts[];
    body1: ListParts[];
    body2: ListParts[];
    optionGroup: ExpService["option_groups"];
  };

  /**
   * 選択したサービス
   *
   * @type {ExpService}
   * @memberof ExpConfirmComponent
   */
  service: ExpService;

  /**
   * 詳細画面で選択されたデータ
   *
   * @type {ExpBill}
   * @memberof ExpConfirmComponent
   */
  expBill: ExpBill;

  /**
   * ons-back-button
   *
   * @private
   * @type {ElementRef}
   * @memberof ExpConfirmComponent
   */
  @ViewChild('onsBackButton') private onsBackButton: ElementRef;

  /**
   * サーバに注文確定リクエスト実行中かどうか
   *
   * @type {boolean}
   * @memberof ExpConfirmComponent
   */
  isRequesting: boolean = false;

  /**
   * fetchレスポンス
   * 
   * @type {ExpFetchReservation}
   * @memberof ExpConfirmComponent
   */
  fetch: ExpFetchReservation;

  // /**
  //  * 表示用オプション配列
  //  *
  //  * @type {ExpBill["options"][string][]}
  //  * @memberof ExpConfirmComponent
  //  */
  // dispOption: ExpBill["options"][string][];

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of ExpConfirmComponent.
   * @param {Params} params
   * @param {OnsNavigator} navigator
   * @param {ExpWebApiService} expServ
   * @param {HttpErrorResponseParserService} errResServ
   * @param {ApplicationMessageService} appMsg
   * @param {CommonFunctionModule} commonFunc
   * @memberof ExpConfirmComponent
   */
  constructor(
    private params: Params,
    private navigator: OnsNavigator,
    private expServ: ExpWebApiService,
    private errResServ: HttpErrorResponseParserService, 
    private appMsg: ApplicationMessageService,
    private commonFunc: CommonFunctionModule,
    private municipalityWebApiServ: MunicipalityWebApiService,
    private msg: MESSAGE,
  ) { }
  
  /**
   * 初期処理
   *
   * @memberof ExpConfirmComponent
   */
  ngOnInit(): void {

    // サービス
    this.service = this.commonFunc.deepcopy(this.params.data.service);
    // fetchレスポンス
    this.fetch = this.commonFunc.deepcopy(this.params.data.fetch);
    // ユーザの選択データ
    this.expBill = this.commonFunc.deepcopy(this.params.data.expBill);

    this.template = {
      header: [],
      body1: [],
      body2: [],
      optionGroup: []
    };

    if (this.service.option_groups) {
      this.template.optionGroup = this.service.option_groups.filter(group => {
        group.options.forEach(option => {
          let result: boolean = true;
          switch(option.user_option) {
            case 'yesno':
              if (this.expBill.options[option.sg_option_id].yesno_param.value === false) result = false; 
              break;
            case 'select':
              if (this.expBill.options[option.sg_option_id].select_param.value === void 0) result = false;
              break;
            case 'number':
              if (this.expBill.options[option.sg_option_id].number_param.selected === 0) result = false;
              break;
          }
          option.isDisplay = result;
        });

        return group.options.some(option => option.isDisplay === true) ? true : false;
      })
    }

    // お支払い料金欄のデータ
    let paymentRow: ListParts['payment_amount']['row'] = [];

    this.fetch.tickets.forEach(ticket => {
      paymentRow.push({
        item: ticket.subject,
        type: ticket.title,
        count: ticket.number,
        amount: ticket.amount,
        unit: ticket.unit
      });
    });

    // 画面上部リスト表示用データ
    this.template.header = [
      {
        mode: 'header',
        header_list: {
          item: [
            this.service.title,
            "提供：" + this.service.shop.name
          ],
          icon: this.service.images[0] ?? ""
        }
      },
      {
        mode: 'paymentAmount',
        header: 'お支払い料金',
        payment_amount: {
          row: paymentRow,
          payer_name: this.fetch.payer.name,
        }
      },
    ];

    // 利用数データを表示用文字列に変換するために必要なデータ
    let numberParams: {name: string, value: number, unit: string}[] = [];

    // 料金種別ごとの名前、利用数、単位
    this.service.user_params.numbers.forEach(n => {
      numberParams.push({name: n.name, value: this.expBill.numbers.find(e => e.type == n.type).value, unit: n.unit});
    })
    
    // 利用数データを表示する形に変換
    const dispNumber: string = this.expServ.getDispNumber(numberParams);

    // 画面下部リスト用データ
    const list: ListParts[] = [
      {
        // target: this.service.service_type == 'MOBILITY' ? true: false,
        id: 'rental',
        header: '貸出場所',
        common: {
          item: this.expBill.rental_place?.name,
          link: this.commonFunc.getGoogleMapUrl(this.expBill.rental_place?.location)
        }
      },
      {
        // target: this.service.service_type == 'ACTIVITY' ? true: false,
        id: 'place',
        header: '開催場所',
        common: {
          item: this.expBill.place?.name,
          link: this.commonFunc.getGoogleMapUrl(this.expBill.place?.location)
        }
      },
      {
        header: '利用開始時刻',
        common: {
          item: dayjs(this.expBill.start_time).format("M月D日 H:mm")
        }
      },
      {
        // target: this.service.service_type == 'MOBILITY' ? true: false,
        id: 'return',
        header: '返却場所',
        common: {
          item: this.expBill.return_place?.name,
          link: this.commonFunc.getGoogleMapUrl(this.expBill.return_place?.location)
        }
      },
      {
        header: '利用終了時刻',
        common: {
          item: dayjs(this.expBill.end_time).format("M月D日 H:mm")
        }
      },
      {
        header: '予約数',
        common: {
          item: dispNumber
        }
      },
    ];

    // モビリティサービスの場合、開催場所は不要
    if (this.service.service_type == 'MOBILITY') {
      this.template.body1 = list.filter(list => list.id != 'place');
    }
    // アクティビティサービスの場合、貸出、返却場所は不要
    else if (this.service.service_type == 'ACTIVITY') {
      this.template.body1 = list.filter(list => list.id != 'rental' && list.id != 'return');
    }

    this.template.body2 = [
      {
        header: 'キャンセル料ルール',
        common: {
          item: this.expServ.getDispCancelRules(this.service.prices[0].cancel_rules)
        }
      },
      {
        header: '店舗連絡先',
        common: {
          item: this.service.shop.tel
        }
      }
    ];
  }

  /**
   *
   *
   * @memberof ExpConfirmComponent
   */
  ngAfterViewInit(): void {
    
    // バックボタン
    this.onsBackButton.nativeElement.onClick = () => {

      // popPage
      this.navigator.element.popPage().then(() => {
        // 詳細画面に戻った際の処理
        this.params.data.updateSchedule(false);
      });
    };
  }

  /**
   * 廃棄処理
   *
   * @memberof ExpConfirmComponent
   */
  ngOnDestroy(): void {

    this.busy?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 予約確定ボタンClick
   *
   * @memberof ExpConfirmComponent
   */
  onReserveButton(): void {

    this.reserve();
  }

  /**
   * 利用規約を表示
   *
   * @memberof ExpConfirmComponent
   */
  viewTermsOfService(): void {
    window.open(this.municipalityWebApiServ.setting.term);
  }

  /**
   * オプション詳細ダイアログ
   *
   * @param {ExpService["option_groups"][number]["options"][number]} option
   * @memberof ExpConfirmComponent
   */
  onClickOptionInfo(option: ExpService["option_groups"][number]["options"][number]): void {
    this.expServ.openOptionDialog(option);
  }

//=============================================================================================
// メソッド
//=============================================================================================

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * 予約確定
   *
   * @memberof ExpConfirmComponent
   */
  reserve(): void {

    // 2重送信対策
    if (this.isRequesting) {
      // console.log("2重クリックのためリクエストロック中");
      return;
    }
    this.isRequesting = true;

    // 予約確定
    this.busy = this.expServ.postReservation(this.fetch.sg_reservation_plan_id).subscribe({
      next: _ => {
        
        this.isRequesting = false;

        // 完了画面へ遷移
        this.navigator.element.pushPage(ExpCompleteComponent);
      },
      error: this.errResServ.doParse((_err, errContent) => {

        this.isRequesting = false;
        // 詳細画面に戻り、空き時間の更新を行う。
        const popPage = (updateService: boolean = false) => {
          // popPage
          this.navigator.element.popPage().then(() => {
            // 詳細画面に戻った際の処理
            this.params.data.updateSchedule(true, updateService);
          });
        }

        // 規約未合意エラー
        if (errContent.smartGotoErrCode == this.appMsg.SERV_CONST_CODE.COMMON.USER_TERM_NEVER_AGREED) {
          // 規約合意していないユーザーとしてメッセージに表示する文字列を取得
          const neverAgreeUser: string = this.municipalityWebApiServ.getTermNeverAgreedUser(errContent);
          // サーバーレスポンスが想定外
          if (neverAgreeUser === "") return this.errResServ.viewErrDialog(errContent);
          // 規約合意していないエラー
          else this.appMsg.viewDialogMessage(this.msg.CLIENT.EXP.E_NOT_TERM_AGREE.message(neverAgreeUser));
        }
        // 利用開始日時を過ぎた
        else if (errContent.smartGotoErrCode == this.appMsg.SERV_CONST_CODE.EXP.RESERVE_LIMIT) {
          this.appMsg.viewDialogMessage(this.msg.CLIENT.EXP.RESERVE_LIMIT_CONFIRM.message(), () => popPage());
        }
        // 予約プラン有効期限(fetchセッション)が切れた場合、個別メッセージ
        else if (errContent.smartGotoErrCode == this.appMsg.SERV_CONST_CODE.SG.NOT_EXIST_PLAN_ID) {
          this.appMsg.viewDialogMessage(this.msg.CLIENT.EXP.NOT_EXIST_PLAN_ID_RESERVE.message(), () => popPage());
        }
        // 運営債権支払い方法、購入不可エラー
        else if (errContent.smartGotoErrCode === this.appMsg.SERV_CONST_CODE.PURCHASE.METHOD_NO_PERMISSION) {
          this.appMsg.viewDialogMessage(this.msg.CLIENT.PURCHASE.METHOD_NO_PERMISSION_RESERVE.message(), () => popPage());
        }
        // GMOエラー
        else if (errContent.smartGotoErrCode === this.appMsg.SERV_CONST_CODE.PURCHASE.GMO_TRAN_AUTH) {
          this.errResServ.viewErrDialog(errContent, "RESERVE");
        }
        else if (errContent.smartGotoErrCode === this.appMsg.SERV_CONST_CODE.SG_OPTION.UNMATCH_REQUEST) {
          this.appMsg.viewDialogMessage(this.msg.CLIENT.EXP.OPTION_UNMATCH_REQUEST.message(), () => popPage(true))
        }
        else {
          this.errResServ.viewErrDialog(errContent).then(() => {
            // リクエストされた時間帯にリソースがなかった
            if (errContent.smartGotoErrCode == this.appMsg.SERV_CONST_CODE.EXP.NOT_AVAILABLE) {
              popPage();
            }
          });
        }
      })
    });
  }
}