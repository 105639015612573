import { Component, ViewChildren, OnInit } from '@angular/core';
import { OnsNavigator } from 'ngx-onsenui';
import * as ons from 'onsenui';

import { UserReservationComponent } from './user-reservation/user-reservation.component';

/**
 * 配車予約トップメニューコンポーネント(現在未使用)
 *
 * @export
 * @class ReservationComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'ons-page[reservation]',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.css']
})
export class ReservationComponent implements OnInit {

//=============================================================================================
// メンバー変数
//=============================================================================================
  /**
   * Pages
   */
  buttons=[
    {
      component: UserReservationComponent,
      label: 'SmartGOTOで行く',
    },
    {
      component: null,
      label: 'すぐにタクシーを呼ぶ',
    },
    {
      component: null,
      label: '他人の予約を代行する',
    }

  ];

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Constructor
   * @param navi
   */
  constructor(private navi: OnsNavigator) { }

  ngOnInit(): void {
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * Go to page
   * @param button
   */
  push(button) {
    if (button.component!=null) {
      this.navi.nativeElement.pushPage(button.component);
    } 
    else {
      ons.notification.alert('coming soon...', { title: null });
    }
  }

}
