//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { SwiperComponent } from "swiper/angular";
import SwiperCore , {
  Navigation,
  Pagination,
  Controller,
} from 'swiper';
import { EnlargeImage } from 'src/app/lib-modules/viewer';
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';
SwiperCore.use([
  Navigation,
  Pagination,
  Controller
]);

/**
 * 部品：swiper
 *
 * @export
 * @class SwiperComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'parts-swiper',
  templateUrl: './swiper-viewer.component.html',
  styleUrls: ['./swiper-viewer.component.scss']
})
export class SwiperViewerComponent implements OnInit {
  
  viewer: any;

  /**
   * 画像一覧
   *
   * @type {string[]}
   * @memberof SwiperViewerComponent
   */
  @Input() images: string[] = [];

  /**
   * 表示中のサムネイルインデックス
   *
   * @type {number}
   * @memberof SwiperViewerComponent
   */
  viewImageIndex: number = 0;

  @ViewChild('swiperRef', { static: false }) swiperRef?: SwiperComponent;
  controlledSwiper: any;
  setControlledSwiper(swiper) {
    this.controlledSwiper = swiper;
  }

  /**
   * 商品画像がない場合、表示する画像
   *
   * @type {string}
   * @memberof SwiperViewerComponent
   */
  noImage: string = CommonFunctionModule.getAssetsUrl('/image/common/49-Noimage.png');
  
//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  constructor() { }

  /**
   * 初期化処理。
   *
   * @memberof SwiperViewerComponent
   */
  ngOnInit(): void {
    this.viewer = new EnlargeImage(this.images);
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 画像クリック。拡大表示。
   *
   * @memberof SwiperViewerComponent
   */
  onEnlargeImage() {
    // image取得
    const imgV: HTMLElement = document.getElementById("swiperViewerImages");
    this.viewer.enlargeImage(imgV);
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * イメージをダウンロードする。
   *
   * @private
   * @memberof SwiperViewerComponent
   */
  // private imageDownload(): void {
    
  //   const a = document.createElement('a');
  //   a.href = this.inputData.images[this.viewImageIndex].image;
  //   // a.download = viewer.image.alt;
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  // }
}
