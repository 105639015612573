import { NgModule } from '@angular/core';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from "@angular/material/dialog";
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { DateAdapter, MatDateFormats, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgxMatDateAdapter, NgxMatDatetimePickerModule, NgxMatNativeDateAdapter, NgxMatNativeDateModule, NgxMatTimepickerModule, NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';

// NativeDateAdapterの一部を上書きしたクラス
class CustomDateAdapter extends NativeDateAdapter {
  getYearName(date: Date): string {
    return `${date.getFullYear()}`;
  }
  getDateNames(): string[] {
    return Array.from(Array(31), (v, k) => `${k + 1}`);
  }
  // format()メソッドは上書き不要
}

// NgxMatNativeDateAdapterの一部を上書きしたクラス
class CustomNgxMatDateAdapter extends NgxMatNativeDateAdapter {
  getYearName(date: Date): string {
    return `${date.getFullYear()}`;
  }
  getDateNames(): string[] {
    return Array.from(Array(31), (v, k) => `${k + 1}`);
  }
  format(date: Date, displayFormat: CustomDisplayFormat): string {
    // console.log(displayFormat);
    // 秒数は不要
    if (displayFormat.minute) {
      // YYYY/M/D H:mm
      return `${date.getFullYear()}/${date.getMonth()+1}/${date.getDate()} ${date.getHours()}:${('00'+date.getMinutes()).slice(-2)}`;
    }
    else if (displayFormat.day) {
      // YYYY/M/D
      return `${date.getFullYear()}/${date.getMonth()+1}/${date.getDate()}`;
    }
    else {
      // YYYY年M月
      return `${date.getFullYear()}年${date.getMonth()+1}月`;
    }
  };
}
// note: 必要に応じてフォーマットをカスタム
const CUSTOM_DATE_FORMAT: MatDateFormats = {
  parse: {
    dateInput: undefined as CustomDisplayFormat
  },
  display: {
    dateInput: undefined as CustomDisplayFormat,
    monthYearLabel: undefined as CustomDisplayFormat,
    dateA11yLabel: undefined as CustomDisplayFormat,
    monthYearA11yLabel: undefined as CustomDisplayFormat
  }
}

@NgModule({
  declarations: [],
  imports: [
  ], 
  exports: [
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatSelectModule,
    MatSidenavModule,
    MatSortModule,
    MatTableModule,
    MatToolbarModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    MatFormFieldModule,
  ],
  providers: [

    // DatePicker を日本語にする. 
    { provide: MAT_DATE_LOCALE, useValue: 'ja-JP' },
    { provide: DateAdapter, useClass: CustomDateAdapter },  // 年と日の表記を変更
    { provide: NgxMatDateAdapter, useClass: CustomNgxMatDateAdapter },
    // { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    // { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },    
    // { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMAT },      // note: 必要に応じてカスタムしたフォーマットを使用
    // { provide: NGX_MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMAT },  // note: 必要に応じてカスタムしたフォーマットを使用
  ],
})
export class MaterialModule { }

// note: あくまで既定のフォーマットの各値なので、自身でフォーマットをカスタムする場合は型定義を自由に変更可能
interface CustomDisplayFormat {
  year?: "numeric",
  month?: "numeric"|"long"|"short",
  day?: "numeric",
  hour12?: boolean, // 12Hか24Hか
  hour?: "2-digit",
  minute?: "2-digit",
  second?: "2-digit",
}
