<ons-toolbar>
  <div class="left">
    <ons-back-button></ons-back-button>
  </div>
  <div class="center">{{ticketTypeName}}購入</div>
</ons-toolbar>

<div [ngBusy]="busy"></div>
<div class="content">
  <ons-list>
    <ons-list-header modifier="color" class="list-item__title">利用者</ons-list-header>
    <ons-list-item id="purchase-ticket-new_001" modifier="longdivider" class="list-item__text">
      {{ purchaseUser.profile.name.family_name + ' ' + purchaseUser.profile.name.given_name }}
    </ons-list-item>

    <ons-list-header modifier="color" class="list-item__title">利用開始日</ons-list-header>
    <ons-list-item modifier="longdivider" class="list-item__text">
      <div class="form-select-box">
        <ons-select id="purchase-ticket-new_002" [(ngModel)]="selectedStartDate" [attr.modifier]="selectedStartDate" (change)="changeStartDate();">
          <option *ngFor="let list of startDateList" [value]="list.value">{{ list.label }}</option>
        </ons-select>
      </div>
    </ons-list-item>
  </ons-list>

  <ng-container *ngIf="buyableTicketList.length > 0; else noTicket">
    <ons-list>
      <ons-list-header modifier="color" class="list-item__title">乗車券種類</ons-list-header>
      <ons-list-item modifier="longdivider" class="list-item__text">
        <ng-container *ngIf="category.length >= 2; else oneCategory" class="form-select-box">
          <ons-select id="purchase-ticket-new_003" [(ngModel)]="selectedTicketType" [attr.modifier]="selectedTicketType" (change)="changePlan();">
            <option *ngFor="let cate of category" [value]="cate">{{ cate }}</option>
          </ons-select>
        </ng-container>
        <ng-template #oneCategory>{{ selectedTicketType }}</ng-template>
      </ons-list-item>
    </ons-list>

    <!-- <div class="content--text--map" *ngIf="!userCitizen">
        <ons-button modifier="quiet" (click)="viewBusStopMap()"><span class="button-text">主要な乗降場を地図で確認</span></ons-button>
    </div> -->

    <ons-list>
      <ons-list-header modifier="color" class="list-item__title">利用終了日</ons-list-header>
      <ons-list-item id="purchase-ticket-new_004" modifier="longdivider" class="list-item__text">
        {{ moment(ticketByCategory[selectedTicketType].ticket.end_date).format('YYYY/MM/DD') }}
      </ons-list-item>
    </ons-list>

    <div class="content-text--left content--text--error" *ngIf="userPeriod === true">
        <p>{{ periodShortMsg }}</p>
    </div>

    <ons-list>
      <div id="purchase-ticket-new_005" *ngIf="ticketByCategory[selectedTicketType].areas && ticketByCategory[selectedTicketType].ticket.terms.special_type !== 'give_up_license'">
        <ons-list-header modifier="color" class="list-item__title">利用地区
          <!-- <ons-button modifier="quiet" (click)="viewBusStopMap()"><span class="button-text">主要な乗降場を確認</span></ons-button> -->
        </ons-list-header>
        <ons-list-item *ngFor="let area of ticketByCategory[selectedTicketType].areas" modifier="longdivider" class="list-item__text" tappable>
          <ons-radio class="left" [attr.input-id]="area.ticket_catalog_id" [attr.value]="area.ticket_catalog_id" [(ngModel)]="purchaseTargetId" (change)="changeArea()"></ons-radio>
          <label class="center" [attr.for]="area.ticket_catalog_id">{{ area.name }}</label>
        </ons-list-item>
      </div>

      <ons-list-header modifier="color" class="list-item__title">料金</ons-list-header>
      <ons-list-item id="purchase-ticket-new_006" modifier="longdivider" class="list-item__text content--text--emphasis">
        ￥{{ ticketByCategory[selectedTicketType].ticket.price | number:'.0-0'  }}<span>（{{ ticketByCategory[selectedTicketType].ticket.name }}）</span>
      </ons-list-item>

      <div *ngIf="ticketByCategory[selectedTicketType].ticket.continuable === true && ticketByCategory[selectedTicketType].ticket.terms.special_type !== 'give_up_license'">
        <ons-list-header modifier="color" class="list-item__title">自動更新</ons-list-header>
        <ons-list-item id="purchase-ticket-new_007" modifier="longdivider" class="list-item__text">
          自動継続購入を有効にする
          <div class="right">
            <ons-switch id="purchase-ticket-new_008" [disabled]="exclusion" [(value)]="continueSwitchState" (change)="changeContinuous()" modifier="mainColor"></ons-switch>
          </div>
        </ons-list-item>
      </div>
    </ons-list>

    <div *ngIf="ticketByCategory[selectedTicketType].ticket.continuable === true && ticketByCategory[selectedTicketType].ticket.terms.special_type !== 'give_up_license'" class="content-text--left">
      <span class="content--text--error">
        自動更新を有効にすると、期限終了時に同じ内容で自動継続購入されます。<br>
        この設定は、購入後に変更することも可能です。
      </span>
    </div>
  </ng-container>

  <ng-template #noTicket>
    <div class="text--center">
      <p class="content--text--error">購入可能な定額乗車券はありません。</p>
    </div>
  </ng-template>

  <div class="button-wrap">
    <ons-button id="purchase-ticket-new_009" [disabled]="!purchasable" class="button button--large--cta" (click)="moveBuyNewTicket()">
      購入に進む
    </ons-button>
  </div>
</div>