import { Component, OnInit } from '@angular/core';

import { OperationMapComponent } from './operation-map/operation-map.component';


@Component({
  selector: 'ons-page[operation]',
  templateUrl: './operation.component.html',
  styleUrls: ['./operation.component.css']
})
export class OperationComponent implements OnInit {
  root = OperationMapComponent;

  constructor() { }

  ngOnInit(): void {
  }

}
