<ons-toolbar class="toolbar">
  <div class="left">
    <ons-back-button></ons-back-button>
  </div>
  <div class="center">支払い方法</div>
</ons-toolbar>
<div class="content">
  <div [ngBusy]="busy"></div>
  <ons-list class="payment-list">
    <ons-list-header modifier="color" class="list-item__title">支払い方法一覧</ons-list-header>

    <!-- クレジットカード -->
    <ng-container *ngFor="let item of paymentRegistration.card">
      <ons-list-item tappable modifier="chevron" class="list-item__text" (click)="selectCredit(item.card_seq)">
        <div class="list-item__icon payment-list-icon-wrap">
          <img class="payment-list-icon" [src]="cardBrandMap.get(item.brand)?.get('icon')" />
        </div>
        <span class="list-item__number">
          {{ item.card_no }}
        </span>
        <div class="list-item__status" *ngIf="item.is_default || item.is_expired || item.is_near_expired">
          <span class="status--default" *ngIf="item.is_default">
            {{ VIEW_TEXT.METHOD.DEFAULT }}
          </span>
          <span class="status--attention" *ngIf="item.is_expired">
            ※有効期限切れ
          </span>
          <span class="status--attention" *ngIf="item.is_near_expired">
            ※有効期限切れ間近
          </span>
        </div>
      </ons-list-item>
    </ng-container>

    <!-- ゆうちょ -->
    <ons-list-item id="payment-method-list_001" *ngIf="paymentRegistration.yucho" tappable modifier="chevron" class="list-item__text" (click)="selectMethod('YUCHO')">
      <div class="list-item__icon payment-list-icon-wrap">
        <img class="payment-list-icon" [src]="paymentMethodMap.get('YUCHO')?.get('icon')" />
      </div>
      <div style="flex: 1">
        <span class="list-item__number">
          {{ VIEW_TEXT.METHOD.YUCHO }}
        </span>
        <span class="list-item__status" *ngIf="userInfo?.payment.method === 'YUCHO'">
          {{ VIEW_TEXT.METHOD.DEFAULT }}
        </span>
      </div>
    </ons-list-item>

    <!-- JABANK -->
    <ons-list-item id="payment-method-list_002" *ngIf="paymentRegistration.jabank" tappable modifier="chevron" class="list-item__text" (click)="selectMethod('JABANK')">
      <div class="list-item__icon payment-list-icon-wrap">
        <img class="payment-list-icon" [src]="paymentMethodMap.get('JABANK')?.get('icon')" />
      </div>
      <div style="flex: 1">
        <span class="list-item__number">
          {{ VIEW_TEXT.METHOD.JABANK }}
        </span>
        <span class="list-item__status" *ngIf="userInfo?.payment.method === 'JABANK'">
          {{ VIEW_TEXT.METHOD.DEFAULT }}
        </span>
      </div>
    </ons-list-item>

    <!-- 振替払込書 -->
    <ons-list-item id="payment-method-list_003" *ngIf="paymentRegistration.slip" tappable modifier="chevron" class="list-item__text" (click)="selectMethod('SLIP')">
      <div class="list-item__icon payment-list-icon-wrap">
        <img class="payment-list-icon" [src]="paymentMethodMap.get('SLIP')?.get('icon')" />
      </div>
      <div style="flex: 1">
        <span class="list-item__number">
          {{ VIEW_TEXT.METHOD.SLIP }}
        </span>
        <span class="list-item__status" *ngIf="userInfo?.payment.method === 'SLIP'">
          {{ VIEW_TEXT.METHOD.DEFAULT }}
        </span>
      </div>
    </ons-list-item>

    <!-- 七十七銀行 -->
    <ons-list-item id="payment-method-list_002" *ngIf="paymentRegistration.bank77" tappable modifier="chevron" class="list-item__text" (click)="selectMethod('BANK77')">
      <div class="list-item__icon payment-list-icon-wrap">
        <img class="payment-list-icon-multi" [src]="paymentMethodMap.get('BANK77')?.get('icon')" />
        <img class="payment-list-icon-multi" [src]="paymentMethodMap.get('FREE_BANK')?.get('icon')" />
      </div>
      <div style="flex: 1">
        <span class="list-item__number">
          {{ VIEW_TEXT.METHOD.BANK77 }}
        </span>
        <span class="list-item__status" *ngIf="userInfo?.payment.method === 'BANK77'">
          {{ VIEW_TEXT.METHOD.DEFAULT }}
        </span>
      </div>
    </ons-list-item>

    <!-- 未登録 -->
    <ons-list-item *ngIf="paymentMethodUnregisteredMessage" class="list-item__text content--text">
      {{ paymentMethodUnregisteredMessage }}
    </ons-list-item>

  </ons-list>

  <div class="button-wrap">
    <ons-button id="payment-method-list_004" class="button button--large--cta" (click)="addPaymentMethod()">支払い方法を追加</ons-button>
  </div>

  <ng-container *ngIf="isExistConfigurablePayment">
    <div class="credit-type-wrap">
      <p class="content-text">以下の支払い方法に対応しております</p>
      <div class="credit-type">
        <ng-container *ngIf="isDisplayPaymentIcon.card">
          <span class="credit-type-item__title">{{ VIEW_TEXT.METHOD.CREDIT }}</span>
          <div class="credit-icon-wrap">
            <img class="credit-icon" [src]="cardBrandMap.get('MASTER')?.get('icon')" />
            <img class="credit-icon" [src]="cardBrandMap.get('VISA')?.get('icon')" />
            <!--
            <img class="credit-icon" [src]="cardBrandMap.get('JCB')?.get('icon')" />
            <img class="credit-icon" [src]="cardBrandMap.get('AMEX')?.get('icon')" />
            <img class="credit-icon" [src]="cardBrandMap.get('DINERS')?.get('icon')" />
            -->
          </div>
        </ng-container>
        <ng-container *ngIf="isDisplayPaymentIcon.yucho">
          <span class="credit-type-item__title">{{ VIEW_TEXT.METHOD.YUCHO }}</span>
          <div class="credit-icon-wrap">
            <img class="credit-icon-yucho" [src]="paymentMethodMap.get('YUCHO_FULL')?.get('icon')" />
          </div>
        </ng-container>
        <ng-container *ngIf="isDisplayPaymentIcon.jabank">
          <span class="credit-type-item__title">{{ VIEW_TEXT.METHOD.JABANK }}</span>
          <div class="credit-icon-wrap">
            <img class="credit-icon" [src]="paymentMethodMap.get('JABANK')?.get('icon')" />
          </div>
        </ng-container>
        <!-- <ng-container *ngIf="isDisplayPaymentIcon.slip">
          <span class="credit-type-item__title">{{ VIEW_TEXT.METHOD.SLIP }}の送付</span>
          <div class="credit-icon-wrap">
            <img class="credit-icon" [src]="paymentMethodMap.get('SLIP')?.get('icon')" />
          </div>
        </ng-container> -->
        <ng-container *ngIf="isDisplayPaymentIcon.bank77">
          <span class="credit-type-item__title">{{ VIEW_TEXT.METHOD.BANK77 }}</span>
          <div class="credit-icon-wrap">
            <img class="credit-icon-yucho" [src]="paymentMethodMap.get('BANK77_FULL')?.get('icon')" />
            <img class="payment-list-icon-multi" [src]="paymentMethodMap.get('FREE_BANK')?.get('icon')" />
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</div>