import { NgModule, ModuleWithProviders } from '@angular/core';
import { ExpWebApiServiceConfig } from '../http-services/exp-web-api.service';

@NgModule()
export class ExpWebApiModule {
  static withConfig(config: ExpWebApiServiceConfig): ModuleWithProviders<ExpWebApiModule> {
    return {
      ngModule: ExpWebApiModule,
      providers: [
        { provide: ExpWebApiServiceConfig, useValue: config }
      ]
    };
  }
}
