// ========================================================================================================================
// 各種インポート
// ========================================================================================================================

import * as AsyncLock from 'async-lock'

// ========================================================================================================================
// クラス定義
// ========================================================================================================================

/**
 * npmのAsyncLockモジュールをラップしたライブラリ。
 * npmのAsyncLockはTimeOutになってもエラーを投げるだけでロックを解除してくれないので、
 * TimeOut時にロックを解除するようラップ。
 * [注意]
 * TimeOut時時にもロック解除してはいけないような処理の場合はこのライブラリを使わないこと。
 * （例：ロックの中で重要データを送信する）
 * このライブラリは「処理がスタックして落ちるくらいならロックを解除した方がベター」な処理に用いる。
 * @export
 * @class AsyncLockWrap
 */
export class AsyncLockWrap {

  // ================================================================================
  // 変数定義
  // ================================================================================

  /**
   * NPMのAsyncLockライブラリ
   *
   * @private
   * @type {AsyncLock}
   * @memberof AsyncLockWrap
   */
  private asyncLock: AsyncLock;

  // ================================================================================
  // 定数定義
  // ================================================================================

  /**
   * ロック実行時にキーが設定されなかった場合のデフォルト値
   *
   * @private
   * @type {string}
   * @memberof AsyncLockWrap
   */
  private readonly DEFAULT_KEY: string = "__DEFAULT_KEY__";

  /**
   * ロック処理成功時のレスポンス
   *
   * @type {string}
   * @memberof AsyncLockWrap
   */
  readonly SUCCESS_RES: string = "Success";

   /**
    * ロックタイムアウト時のレスポンス
    *
    * @type {string}
    * @memberof AsyncLockWrap
    */
  readonly ERROR_RES: string = "Error";
  
  // ================================================================================
  // ライフサイクルメソッド定義
  // ================================================================================

  constructor(options?: AsyncLockOptions) {
    this.asyncLock = new AsyncLock(options);
  }

  // ================================================================================
  // 関数定義
  // ================================================================================

  /**
   * 第一引数に指定した関数の実行が終わるまでロック
   *
   * @param {Function} func 排他制御される関数
   * @param {AquireOptions} [options={}] 排他制御オプション
   * @return {*}  {Promise<unknown>}
   * @memberof AsyncLockWrap
   */
  acquire(func: Function, options: AquireOptions = {}): Promise<any> {

    let { resolveTimeout, key } = options;

    // キーが設定されてない場合はデフォルトキーを使う
    if (!key) {
      key = this.DEFAULT_KEY;
    }

    return this.asyncLock.acquire(key, () => {
      
      return new Promise(async resolve => {

        let isResolved: boolean = false;

        // タイムアウト秒数後にロック解除されてなかったら、コンソールエラーを出力しロック解除
        if (resolveTimeout) {
          setTimeout(() => {
            if (!isResolved) {
              console.error(`${resolveTimeout} ms passed. over resolveTimeout. unlock promiseLock.`);
              resolve(this.ERROR_RES);
            }
          }, resolveTimeout)
        }

        await func()
          resolve(this.SUCCESS_RES);
          isResolved = true;
      })
    })

  }
  
  /**
   * 引数のキーの処理がロック中かどうか
   *
   * @param {*} [key=this.DEFAULT_KEY]
   * @return {*} 
   * @memberof AsyncLockWrap
   */
  isBusy(key = this.DEFAULT_KEY) {

    return this.asyncLock.isBusy(key);
  }

}

// ========================================================================================================================
// ライブラリ用インターフェース
// ========================================================================================================================

/**
 * AsyncLockインスタンス生成時オプション
 *
 * @interface AsyncLockOptions
 */
interface AsyncLockOptions {

  /**
   * デフォルトのタイムアウトMS
   *
   * @type {number}
   * @memberof AsyncLockOptions
   */
  timeout?: number;

  /**
   *
   *
   * @type {number}
   * @memberof AsyncLockOptions
   */
  maxPending?: number;

  /**
   *
   *
   * @type {boolean}
   * @memberof AsyncLockOptions
   */
  domainReentrant?: boolean;

  /**
   *
   *
   * @type {*}
   * @memberof AsyncLockOptions
   */
  Promise?: any;

}

/**
 * 排他制御オプション
 *
 * @interface AquireOptions
 */
interface AquireOptions {

  /**
   * タイムアウトMS
   *
   * @type {number}
   * @memberof AquireOptions
   */
  resolveTimeout?: number

  /**
   * 排他制御のキー
   *
   * @type {(string | string[])}
   * @memberof AquireOptions
   */
  key?: string | string[]

}