<div [ngBusy]="busy"></div>
<ons-toolbar>
  <div class="left">
    <ons-back-button></ons-back-button>
  </div>
  <div class="center" *ngIf="mode === issueMode.user_card">利用者カード発行</div>
  <div class="center" *ngIf="mode === issueMode.reissue">QRコード再発行</div>
</ons-toolbar>
<div class="content">
  <div *ngIf="mode === issueMode.user_card" class="content--text-wrap">
    見守り対象アカウントの方は、ご自身で端末を持っておらず、
    そのままではサービス利用時にQRコードを表示できないため、
    <span class="content--emphasis">利用者カードの発行</span>をオススメしております。
    <br>
    <br>
    <parts-image url="/image/qr/user-card.jpeg"></parts-image>
    <br>
    <br>
    <span class="content--emphasis">利用者カードの発行を行いますか？</span>
    <br>
    ※「カード発行」ボタンを選択すると、近日中に{{officeName}}よりファミリー管理者（あなた）にお問い合わせ後、利用者カードが発送されます。
  </div>

  <div *ngIf="mode === issueMode.reissue" class="content--text-wrap">
    スマホや利用者カードの紛失あるいは、QRコードを他人に知られてしまった場合に、
    新たにQRコードを再発行することができます。これにより、以前のQRコードは無効となります。
  </div>
  <div>
    <div class="button-wrap">
      <!-- <ng-container> -->
        <ons-button *ngIf="mode === issueMode.user_card" class="button button--large--cta" (click)="onClickIssueUserCard()">カード発行</ons-button>
        <ons-button *ngIf="mode === issueMode.reissue" class="button button--large--cta" (click)="onClickReissueQrCode()">再発行</ons-button>
      <!-- </ng-container> -->
    </div>
  </div>
</div>