<ons-toolbar>
  <div class="left">
    <ons-back-button></ons-back-button>
  </div>
  <div class="center">ファミリー</div>
</ons-toolbar>
<div class="content">
  <div [ngBusy]="m_Busy"></div>
  <ons-list>
    <ons-list-header modifier="color" class="list-item__title">氏名</ons-list-header>
    <ons-list-item id="family-detail_007" modifier="longdivider" class="list-item__text">
      {{ m_Relation.name.family_name }} {{ m_Relation.name.given_name }}
      <span *ngIf="m_Relation.is_alt === true" class="content--text--alt">（見守り対象）</span>
      <div *ngIf="m_Relation.status === 'request' || m_Relation.status === 'waiting'">
        <div class="badge--request">リンク解除<br>申請中</div>
      </div>
    </ons-list-item>
    <ons-list-header modifier="color" class="list-item__title">氏名(かな)</ons-list-header>
    <ons-list-item id="family-detail_008" modifier="longdivider" class="list-item__text">
      {{ m_Relation.name.family_name_kana }} {{m_Relation.name.given_name_kana}}
    </ons-list-item>
  </ons-list>

  <!-- 管理者用 -->
  <div *ngIf="m_LoginUserRole === 'parent'">
    <ons-list>
      <ons-list-header modifier="color" class="list-item__title">生年月日</ons-list-header>
      <ons-list-item id="family-detail_009" modifier="longdivider" class="list-item__text">
        {{ m_Relation.birth.year }}/{{ m_Relation.birth.month }}/{{ m_Relation.birth.day }}
      </ons-list-item>

      <ons-list-header modifier="color" class="list-item__title">性別</ons-list-header>
      <ons-list-item id="family-detail_010" modifier="longdivider" class="list-item__text">{{ getFamilySexStr(m_Relation.sex) }}</ons-list-item>

      <div *ngIf="m_Relation.is_alt === true">
        <ons-list-header modifier="color" class="list-item__title">
          <div class="left">住所</div>
          <div id="family-detail_001" class="right list-item__link" (click)="pushEdit()">編集する</div>
        </ons-list-header>
        <ons-list-item id="family-detail_011" modifier="longdivider" class="list-item__text">
          {{m_Relation.address.zip}}
          {{m_Relation.address.prefecture + m_Relation.address.city + m_Relation.address.address1 + m_Relation.address.address2 + m_Relation.address.address3}}
        </ons-list-item>
      </div>

      <!-- <ons-list-header modifier="color" class="list-item__title">利用制限</ons-list-header>
      <ons-list-item modifier="longdivider" class="list-item__text">
        <div *ngIf="m_Relation.limits?.utilization === true; else noLimits">
          <div class="content--list--description">現在、以下の利用制限がかけられています。</div>
          <div class="content--list">
            <ul>
              <li>本人の操作による配車予約</li>
              <li>本人の操作による定期券の購入</li>
              <li>管理者以外による代理予約</li>
            </ul>
          </div>
        </div>
        <ng-template #noLimits>
          <div class="content--list--description">現在、利用制限はかけられていません。</div>
        </ng-template>
      </ons-list-item> -->

    </ons-list>
    <ons-list>
      <ons-list-header modifier="color" class="list-item__title">各種設定</ons-list-header>
      <!-- <ons-list-item id="family-detail_003" modifier="chevron longdivider" class="list-item__text" tappable (click)="pushLimit()">サービス利用制限の設定</ons-list-item> -->
      <ons-list-item id="family-detail_004" *ngIf="m_Relation.is_alt === false" modifier="chevron longdivider" class="list-item__text" tappable (click)="deleteFamily()">ファミリーリンクの解除</ons-list-item>
      <ons-list-item *ngIf="m_Relation.is_alt === true" modifier="chevron longdivider" class="list-item__text" tappable (click)="deleteAccount()">アカウントの削除</ons-list-item>
    </ons-list>
  </div>

    <!-- メンバー用 -->
  <div *ngIf="m_LoginUserRole === 'child'">
    <ons-list>
      <!-- <ons-list-header modifier="color" class="list-item__title">管理者からあなたへの利用制限</ons-list-header>
      <ons-list-item modifier="longdivider" class="list-item__text">
        <div *ngIf="m_Relation.limits?.utilization === true; else noLimits">
          <div class="content--list--description">現在、以下の利用制限がかけられています。</div>
          <div class="content--list">
            <ul>
              <li>本人の操作による配車予約</li>
              <li>本人の操作による定期券の購入</li>
              <li>管理者以外による代理予約</li>
            </ul>
          </div>
          <div class="content--list--description">利用制限を解除してもらいたい場合、管理者に依頼してください。</div>
        </div>
        <ng-template #noLimits>
          <div class="content--list--description">現在、利用制限はかけられていません。</div>
        </ng-template>
      </ons-list-item> -->
      <ons-list-header modifier="color" class="list-item__title">各種設定</ons-list-header>
      <ons-list-item id="family-detail_006" modifier="chevron longdivider" class="list-item__text" tappable (click)="deleteFamily()">ファミリーリンクの解除</ons-list-item>
    </ons-list>
  </div>
</div>