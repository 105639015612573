<ons-toolbar>
  <div class="left">
    <ons-back-button></ons-back-button>
  </div>
  <div class="center">乗車料金</div>
</ons-toolbar>

<div class="content">
  <ons-list *ngFor="let customer of customerBill; index as i">
    <div class="list-item__count">ユーザー{{i + 1}}</div>

    <ons-list-header modifier="color" class="list-item__title">氏名</ons-list-header>
    <ons-list-item modifier="longdivider" class="list-item__text">{{ customer.user_name }} 様</ons-list-item>

    <ons-list-header modifier="color" class="list-item__title">利用チケット</ons-list-header>
    <ons-list-item modifier="longdivider" class="list-item__text">
      {{ customer.tickets[0].ticket_name }}
      &nbsp;<span *ngIf="customer.tickets[0].ticket_type != 1">{{ customer.tickets[0].area.name }}&nbsp;</span>
      (￥{{ customer.tickets[0].purchase_amount | number:'.0-0' }})
      {{ getAmoutProperty(customer.tickets[0].amount_property) }}
    </ons-list-item>

    <ons-list-header modifier="color" class="list-item__title">料金請求先</ons-list-header>
    <ons-list-item modifier="longdivider" class="list-item__text">{{ customer.tickets[0].payer.name }} 様</ons-list-item>
  </ons-list>
</div>