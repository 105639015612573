//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { OnsNavigator, Params } from 'ngx-onsenui';
import { Subscription } from 'rxjs';

//service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { FamilyWebApiService } from '../../../http-services/family-web-api.service';

// interface
import { Relation } from '../../../interfaces/response';
import { request } from '../../../interfaces/request';
import { EDIT_MODE, ProfileLocationEditComponent } from '../../location/profile-location-edit/profile-location-edit.component';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 見守り対象アカウントプロフィール入力画面。
 *
 * @export
 * @class ProxyAccountProfileEditComponent
 * @implements {OnInit}
 * @author （株）オブジェクトデータ takimoto wada
 */
@Component({
  selector: 'ons-page[app-proxy-account-profile-edit]',
  templateUrl: './proxy-account-profile-edit.component.html',
  styleUrls: ['./proxy-account-profile-edit.component.css']
})
export class ProxyAccountProfileEditComponent implements OnInit, OnDestroy 
{

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * Subscriptionオブジェクト
   *
   * @type {Subscription}
   * @memberof FamilyComponent
   */
  m_Busy: Subscription;

  /**
   * 見守り対象アカウント情報
   *
   * @type {Relation}
   * @memberof ProxyAccountProfileEditComponent
   */
  m_Relation: Relation;

  /**
   * geocoder
   *
   * @private
   * @type {google.maps.Geocoder}
   * @memberof ProxyAccountProfileEditComponent
   */
  private m_Geocoder: google.maps.Geocoder;

  /**
   * 郵便番号エラーメッセージ
   *
   * @type {string}
   * @memberof ProxyAccountProfileEditComponent
   */
  m_ZipEmptyMessage: string = "";

  /**
   * 住所自動入力完了フラグ
   *
   * @type {boolean}
   * @memberof ProxyAccountProfileEditComponent
   */
  m_bAddressAutoCompleted: boolean = false;

  /**
   * 住所を編集したかどうか
   *
   * @type {boolean}
   * @memberof ProxyAccountProfileEditComponent
   */
  addressEditted: boolean = false;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of ProxyAccountProfileEditComponent.
   * @param {ApplicationMessageService} appMsg
   * @param {Params} params
   * @param {ChangeDetectorRef} changeDetectorRef
   * @param {FamilyWebApiService} familyWebApiService
   * @param {HttpErrorResponseParserService} httpErrorResponseParserService
   * @memberof ProxyAccountProfileEditComponent
   */
  constructor(
    private appMsg: ApplicationMessageService, 
    private params: Params, 
    private changeDetectorRef: ChangeDetectorRef, 
    private familyWebApiService: FamilyWebApiService, 
    private httpErrorResponseParserService: HttpErrorResponseParserService,
    private navigator: OnsNavigator,
    private msg: MESSAGE,
  ) { }

  /**
   * 初期化処理。
   *
   * @memberof ProxyAccountProfileEditComponent
   */
  ngOnInit(): void 
  {
    // ファミリー情報をコピー
    this.m_Relation = JSON.parse(JSON.stringify(this.params.data.relation));
  }

  /**
   * ビュー初期化処理。
   *
   * @memberof ProxyAccountProfileEditComponent
   */
  ngAfterViewInit(): void 
  {
    this.m_Geocoder = new google.maps.Geocoder();
  }

  /**
   * 破棄処理。
   *
   * @memberof ProxyAccountProfileEditComponent
   */
  ngOnDestroy(): void 
  {
    this.m_Busy?.unsubscribe();
  }

  //=============================================================================================
  // イベントハンドラ
  //=============================================================================================  

  /**
   * 住所、地点情報編集画面へ遷移
   *
   * @memberof ProxyAccountProfileEditComponent
   */
  pushLocationEdit(): void {

    // 登録されている住所と入力中の住所に変更があるかチェック
    this.checkAddress();

    // 住所地点(緯度経度)編集画面へ遷移
    this.navigator.element.pushPage(ProfileLocationEditComponent, {data: {
      addressEditted: this.addressEditted,
      mode: EDIT_MODE.proxy_user,
      relation_id: this.m_Relation.relation_id, 
      address: this.m_Relation.address
    }});
  }

  /**
   * 住所自動入力を行う。
   *
   * @memberof ProxyAccountProfileEditComponent
   */
  addressAutocomplete(): void 
  {
    //住所が見つからなかった場合表示するメッセージの初期化
    this.zipEmptyMessageClear();

    this.m_Geocoder.geocode(
      {
        address: `${ this.m_Relation.address?.zip ?? "" }`
      }, (results, status) => 
      {
        if ((status !== google.maps.GeocoderStatus.OK) || !results[0]) 
        {
          this.m_ZipEmptyMessage = this.msg.CLIENT.PROFILE.INVALID_ZIP.message();
          return;
        }
        
        const addressComponents = results[0].address_components;
        const getLongName = (type: string): string => addressComponents.find(e => e.types.some(x => x === type))?.long_name?? '';

        this.m_Relation.address.prefecture = getLongName('administrative_area_level_1');
        this.m_Relation.address.city = `${ getLongName('locality') }${ getLongName('sublocality_level_1') }`;
        this.m_Relation.address.address1 = `${ getLongName('sublocality_level_2') }${ getLongName('sublocality_level_3') }`;
        this.m_Relation.address.address2 = getLongName('sublocality_level_4');
        this.m_Relation.address.address3 = getLongName('premise');

        // 住所自動入力完了フラグON
        this.m_bAddressAutoCompleted = true;

        this.changeDetectorRef.detectChanges();
      }
    );
  }
 
  /**
   * 郵便番号エラーメッセージを初期化する。
   *
   * @memberof ProxyAccountProfileEditComponent
   */
  zipEmptyMessageClear(): void 
  {
    this.m_ZipEmptyMessage = "";
  }

  //=============================================================================================
  // メソッド
  //=============================================================================================

  /**
   * 
   *
   * @memberof ProxyAccountProfileEditComponent
   */
  checkAddress(): void {

    // DBに登録されているユーザ情報
    const registerdAddress = this.familyWebApiService.getFamilyInfo().find(ele => ele.relation_id == this.m_Relation.relation_id).address;

    // 住所
    const address = this.m_Relation.address;

    // 登録されている住所と入力した住所が異なっている(変更あり)
    if (
      registerdAddress.zip !== address.zip ||
      registerdAddress.prefecture !== address.prefecture ||
      registerdAddress.city !== address.city ||
      registerdAddress.address1 !== address.address1 ||
      registerdAddress.address2 !== address.address2 ||
      registerdAddress.address3 !== address.address3 
    ) this.addressEditted = true;
    // 変更なし
    else this.addressEditted = false;
  }
}
