//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { SwiperComponent } from "swiper/angular";
import SwiperCore , {
  Navigation,
  Pagination,
  Controller,
} from 'swiper';
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';
SwiperCore.use([
  Navigation,
  Pagination,
  Controller
]);

/**
 * 部品：swiper
 *
 * @export
 * @class SwiperComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'parts-swiper-images',
  templateUrl: './swiper-images.component.html',
  styleUrls: ['./swiper-images.component.scss']
})
export class SwiperImagesComponent implements OnInit {

  /**
   * 画像一覧
   *
   * @type {string[]}
   * @memberof SwiperImagesComponent
   */
  @Input()
  get inputData(): SwiperImages { return this._inputData};
  set inputData(swiperImages: SwiperImages) {
    this._inputData = swiperImages || {elements: []};

    if (swiperImages) {
      // slides_per_viewが指定されていなかった場合１に
      if (!this.inputData.slides_per_view) {
        this.inputData.slides_per_view = 1;
      }
    }
  }
  /**
   * @Inputのget用
   *
   * @private
   * @type {SwiperImages}
   * @memberof SwiperImagesComponent
   */
  private _inputData: SwiperImages;

  /**
   * 画像clickイベント
   *
   * @memberof SwiperImagesComponent
   */
  @Output() imageClick = new EventEmitter<string|number>()

  /**
   * 表示中のサムネイルインデックス
   *
   * @type {number}
   * @memberof SwiperImagesComponent
   */
  viewImageIndex: number = 0;

  @ViewChild('swiperRef', { static: false }) swiperRef?: SwiperComponent;
  controlledSwiper: any;
  setControlledSwiper(swiper) {
    this.controlledSwiper = swiper;
  }

  /**
   * swiper要素
   *
   * @type {SwiperComponent}
   * @memberof SwiperImagesComponent
   */
  @ViewChild('swiper', { static: false }) swiper: SwiperComponent;

  /**
   * 商品画像がない場合、表示する画像
   *
   * @type {string}
   * @memberof SwiperImagesComponent
   */
  noImage: string = CommonFunctionModule.getAssetsUrl('/image/common/49-Noimage.png');

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  constructor() { }

  /**
   * 初期化処理。
   *
   * @memberof SwiperImagesComponent
   */
  ngOnInit(): void {
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 画像クリック。
   *
   * @memberof SwiperImagesComponent
   */
   onClickImage(key?: string | number) {
    
    this.imageClick.emit(key);
  }

  /**
   * swiperのスライダーの初期化を行う。
   *
   * @memberof SwiperImagesComponent
   */
  swiperInit() {
    
    // コンテナ要素があれば
    if(this.swiper){
      // スライダーの初期化
      this.swiper.swiperRef.update();
    }
  }

//=============================================================================================
// メソッド
//=============================================================================================

}

/**
 * swiperを使った
 * カルーセルデザイン部品に必要なデータ
 *
 * @export
 * @interface SwiperImages
 */
export interface SwiperImages {

  /**
   * swiperに表示する全要素
   *
   * @type {Image[]}
   * @memberof SwiperImages
   */
  elements: Element[];

  /**
   * 一度に表示する要素数
   *
   * @type {number}
   * @memberof SwiperImages
   */
  slides_per_view?: number;
}

/**
 * swiper1要素
 *
 * @interface Image
 */
interface Element {

  /**
   * 画像
   *
   * @type {string}
   * @memberof Image
   */
  image: string,

  /**
   * クリックされた画像用識別子
   *
   * @type {(string | number)}
   * @memberof Element
   */
  key?: string | number,

  /**
   * 要素のタイトル
   *
   * @type {string}
   * @memberof Element
   */
  title?: string
}
