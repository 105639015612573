import { Component, OnInit } from '@angular/core';
import { OnDestroy, Params } from 'ngx-onsenui';
import { Subscription } from 'rxjs';
import { UserReservation } from '../user-reservation/user-reservation';

// service
import { FamilyWebApiService } from 'src/app/http-services/family-web-api.service';
import { UserWebApiService } from 'src/app/http-services/user-web-api.service';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';

// module
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';

/**
 * 乗車人数指定画面。
 *
 * @export
 * @class RideCountComponent
 * @implements {OnInit}
 * @implements {RideCountContext}
 */
@Component({
  selector: 'ons-page[ride-count]',
  templateUrl: './ride-count.component.html',
  styleUrls: ['./ride-count.component.css']
})
export class RideCountComponent implements OnInit, OnDestroy {

  /**
   * 通信用Subscription
   *
   * @type {Subscription}
   * @memberof RideCountComponent
   */
  busy: Subscription;

  /**
   * 移動プランの検索条件。
   *
   * @type {UserReservation.SearchCondition}
   * @memberof RideCountComponent
   */
  searchCondition: UserReservation.SearchCondition;

  // private currentPageIndex: number;

  /**
   * assetsファイルへのパス(定数)
   *
   * @type {string}
   * @memberof RideCountComponent
   */
  readonly ASSETS_FAMILY: string = CommonFunctionModule.getAssetsUrl('/image/common/22-Family.png');

  /**
   * エリア名
   *
   * @type {string}
   * @memberof RideCountComponent
   */
  areaName: string;

  /**
   * OnDestroy時に破棄するSubscriptionオブジェクト
   *
   * @memberof RideCountComponent
   */
  subscription = new Subscription();

  /**
   * Creates an instance of RideCountComponent.
   * @param {Params} params 遷移元のページから渡されるパラメーター。
   * @param {FamilyWebApiService} familyWebApiServ
   * @param {UserWebApiService} userWebApiServ
   * @param {MunicipalityWebApiService} municipalityWebApiServ
   * @memberof RideCountComponent
   */
  constructor(
    // private _navigator: OnsNavigator,
    private params: Params,
    private familyWebApiServ: FamilyWebApiService,
    private userWebApiServ: UserWebApiService,
    private municipalityWebApiServ: MunicipalityWebApiService,
  ) {
    const settingsChanged = this.municipalityWebApiServ.settingsChanged.subscribe({
      next: setting => {
        if (setting == null) return;
        this.areaName = setting.name;
      }
    });
    this.subscription.add(settingsChanged);
  }

  /**
   * コンポーネントが初期化される際に呼び出されるメソッド。
   *
   * @memberof RideCountComponent
   */
  ngOnInit(): void {
    // this.currentPageIndex = this._navigator.element.pages.length - 1;

    [this.searchCondition] = this.params.data;

    // ログイン状態の場合、最新のファミリー情報を取得
    if (this.userWebApiServ.isLoggedIn()) {
      this.busy = this.familyWebApiServ.getFamily().subscribe();
    }
  }

  /**
   * 廃棄処理
   *
   * @memberof RideCountComponent
   */
  ngOnDestroy(): void {

    this.busy?.unsubscribe();
    this.subscription?.unsubscribe();
  }

  /**
   * 顔認証を用いてゲストを追加する。
   *
   * @memberof RideCountComponent
   */
  // faceAuth(): void 
  // {
  //   this._navigator.element.pushPage(FaceAuthStep1Component, {
  //     data: [this.searchCondition, this]
  //   });
  // }

  /**
   * 〈乗車人数指定画面〉に戻る。
   *
   * @memberof RideCountComponent
   */
  // returnHere(): void {
  //   const lastIndex = this._navigator.element.pages.length - 1;

  //   if (this.currentPageIndex < lastIndex) {
  //     this._navigator.element.removePage(this.currentPageIndex + 1).then(() => {
  //       setTimeout(() => {
  //         this.returnHere();
  //       }, 0);
  //     });
  //   }
  // }
}
