//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { OnsNavigator, Params } from 'ngx-onsenui';
import Viewer from 'viewerjs';
import SwiperCore , {
  Navigation,
  Pagination,
  Controller,
} from 'swiper';
SwiperCore.use([
  Navigation,
  Pagination,
  Controller
]);
import * as CONST from '../../../constants/constant';

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { UserWebApiService } from '../../../http-services/user-web-api.service';
import { ShoppingWebApiService } from "../../../http-services/shopping-web-api.service";
import { OrderRequestService } from '../../../lib-services/order-request.service';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';

// interface
import { ShoppingMenu, Shop } from '../../../interfaces/response';
import { CartAddedItems, orderRequestResult } from '../../../lib-services/order-request.service';
import { SwiperComponent } from "swiper/angular";

// module
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';
import { MESSAGE } from 'src/app/constants/message';
@Component({
  selector: 'ons-page[shopping-menu-detail]',
  templateUrl: './shopping-menu-detail.component.html',
  styleUrls: ['./shopping-menu-detail.component.css']
})
export class ShoppingMenuDetailComponent implements OnInit, AfterViewInit {

//=============================================================================================
// メンバー変数
//=============================================================================================

  /**
   * 表示している写真を管理するための番号
   *
   * @type {number}
   * @memberof ShoppingMenuDetailComponent
   */
  num: number = 0;

  /**
   * 写真の枚数
   *
   * @type {number}
   * @memberof ShoppingMenuDetailComponent
   */
  photosLength: number = 1;

  /**
   * 選択しているメニューの情報
   *
   * @type {CartAddedItems}
   * @memberof ShoppingMenuDetailComponent
   */
  selectMenu: CartAddedItems;

  /**
   * 個数を増やすことができるかどうか
   *
   * @type {boolean}
   * @memberof ShoppingMenuDetailComponent
   */
  isDisabledCountUp: boolean = false;

  /**
   * 個数を減らすことができるかどうか
   *
   * @type {boolean}
   * @memberof ShoppingMenuDetailComponent
   */
  isDisabledCountDown: boolean = false;

  /**
   * メニューの合計料金
   *
   * @type {number}
   * @memberof ShoppingMenuDetailComponent
   */
  totalPrice: number;

  /**
   * 店舗情報
   *
   * @type {Shop}
   * @memberof ShoppingMenuDetailComponent
   */
  shop: Shop;

  /**
   * 表示用アレルギー情報
   *
   * @type {common.Object[]}
   * @memberof ShoppingMenuDetailComponent
   */
  displayAllergens: string;

  /**
   * 表示用カテゴリー情報
   *
   * @type {string}
   * @memberof ShoppingMenuDetailComponent
   */
  displayCategories: string;

  /**
   * 現状カート内に追加することができる最大個数
   *
   * @type {number}
   * @memberof ShoppingMenuDetailComponent
   */
  selectItemsMaxCount: number = 0;

  /**
   * 詳細画面の役割を判断する
   *
   * @type {menuProcMode}
   * @memberof ShoppingMenuDetailComponent
   */
  menuProcMode: menuProcMode;

  /**
   * カートに追加することができる最大個数
   *
   * @type {number}
   * @memberof ShoppingMenuDetailComponent
   */
  maxOrderItemCount: number;

  /**
   * 商品画像がない場合、表示する画像
   *
   * @type {string}
   * @memberof ShoppingMenuDetailComponent
   */
  noImages: string = CommonFunctionModule.getAssetsUrl('/image/common/49-Noimage.png');

  /**
   * 更新ボタンを活性化するかどうか
   *
   * @type {boolean}
   * @memberof ShoppingMenuDetailComponent
   */
  isDisabledUpdateButton: boolean = true;

  /**
   * 詳細画面に来た際の初期個数
   *
   * @type {number}
   * @memberof ShoppingMenuDetailComponent
   */
  selectedMenuCount: number = 0;

  /**
   * 表示中のサムネイルインデックス
   *
   * @type {number}
   * @memberof ShoppingMenuDetailComponent
   */
  viewImageIndex: number = 0;

  @ViewChild('swiperRef', { static: false }) swiperRef?: SwiperComponent;
  controlledSwiper: any;
  setControlledSwiper(swiper) {
    this.controlledSwiper = swiper;
  }

  /**
   * HTMLで表示するクライアントメッセージ
   *
   * @type {{[code: string]: string}}
   * @memberof ShoppingMenuDetailComponent
   */
  cliantErrMsg: {[code: string]: string} = {
    MAX_ORDER_ITEM_COUNT: "",
    ERR_ALREADY_MAX_COUNT: ""
  }

  /**
   * 表示用メニュー説明(リンクを含む)
   *
   * @type {string}
   * @memberof ShoppingMenuDetailComponent
   */
  dispDescription: string = "";

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of ShoppingMenuDetailComponent.
   * @param {ApplicationMessageService} appMsgServ
   * @param {HttpErrorResponseParserService} errResServ
   * @param {ShoppingWebApiService} shoppingServ
   * @param {OrderRequestService} orderRequestServ
   * @param {OnsNavigator} navigator
   * @param {Params} params
   * @memberof ShoppingMenuDetailComponent
   */
  constructor(
    private appMsgServ: ApplicationMessageService, 
    private errResServ: HttpErrorResponseParserService, 
    private shoppingServ: ShoppingWebApiService, 
    private orderRequestServ: OrderRequestService,
    private navigator: OnsNavigator, 
    private params: Params,
    private municipalityWebApiServ: MunicipalityWebApiService,
    private commonFuncMdl: CommonFunctionModule,
    private msg: MESSAGE,
  ) {
    this.maxOrderItemCount = this.municipalityWebApiServ.setting.shopping.max_order_item_count;
  }

  /**
   * 初期処理。
   *
   * @memberof ShoppingMenuDetailComponent
   */
  ngOnInit(): void {

    // HTMLで表示するクライアントメッセージ
    this.cliantErrMsg = {
      MAX_ORDER_ITEM_COUNT: this.msg.CLIENT.SHOPPING.ERR_MAX_COUNT.message(String(this.maxOrderItemCount)),
      ERR_ALREADY_MAX_COUNT: this.msg.CLIENT.SHOPPING.ERR_ALREADY_MAX_COUNT.message(String(this.maxOrderItemCount)) 
    }

    // メニュー追加処理か、更新処理か
    this.menuProcMode = this.params.data.detailMode;

    // このメニューで選択できる最大個数
    this.selectItemsMaxCount = this.maxOrderItemCount - this.orderRequestServ.getCartItemCount;

    // メニュー一覧画面から遷移してきた場合、表示する個数の初期値は1
    if (this.menuProcMode.mode == 'add') {
      this.selectedMenuCount = 1;
    }

    // カート一覧画面から遷移してきた場合、既に追加しているメニュー個数を表示する
    else if (this.menuProcMode.mode  == 'update') {
      
      this.selectedMenuCount = this.orderRequestServ.getItemCount(this.params.data.menu.menu_id);
      this.selectItemsMaxCount = this.selectItemsMaxCount + this.selectedMenuCount;
    }

    // 選択したメニューの個数が最大個数の場合
    if (this.selectItemsMaxCount == this.selectedMenuCount) {
      this.isDisabledCountUp = true;
    }

    // 0個に変更できないように
    if (this.selectedMenuCount == 1) {
      this.isDisabledCountDown = true;
    }
    const menu = this.commonFuncMdl.deepcopy(this.params.data.menu);

    // 説明、リンク変換
    this.dispDescription = this.commonFuncMdl.replaceUrl(this.commonFuncMdl.replaceEscapeSequence(menu.description));

    // 選択中のメニュー情報
    this.selectMenu = { menu: menu, count: this.selectedMenuCount };

    // 選択した注文先店舗情報を登録
    this.shop = this.params.data.shop;

    // 初めに表示する合計金額を計算
    this.totalPrice = this.selectMenu.menu.price * this.selectMenu.count;
    
    // 表示用データを作成
    this.createDisplayData();

    // 画像数
    this.photosLength = this.selectMenu.menu.images !== undefined ? this.selectMenu.menu.images.length : 0;
  }

  /**
   * コンポーネントのビューが初期化された後に呼び出されるメソッド。
   *
   * @memberof ShoppingMenuDetailComponent
   */
  ngAfterViewInit(): void {}

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * カートに商品を追加する。
   *
   * @memberof ShoppingMenuDetailComponent
   */
  addItem(): void {
  
    // カートにメニューを追加
    let addResult: orderRequestResult = this.orderRequestServ.addCart(this.shop, this.selectMenu);
    
    // エラー：カートに追加失敗
    // resultの値によってメッセージ切り替え
    if (addResult == orderRequestResult.ERR_DIFFERENT_SHOP) {
      this.appMsgServ.confirmMessage(this.msg.CLIENT.SHOPPING.CONFIRM_DIFFERENT_SHOP_ITEM.message(), value => {
        // OKの場合、注文情報を初期化
        if (Number(value) == CONST.Common.CONFIRM_YES) {
          this.orderRequestServ.refresh();
          addResult = this.orderRequestServ.addCart(this.shop, this.selectMenu);

          // 正常処理
          if (addResult == orderRequestResult.SUCCESS) {
            // メニュー一覧画面に戻る
            this.navigator.element.popPage().then(() => {
      
              // 画面再描画
              this.params.data.method();
            });
          }
          else {
            // 想定外
            this.appMsgServ.viewDialogMessage(this.msg.CLIENT.SHOPPING.ERR_ADD_ITEM.message());
            return;
          }
        }
        // キャンセルの場合、追加処理を抜ける
        else {
          return;
        }
      });
    }
    // 正常処理
    else {
      // メニュー一覧画面に戻る
      this.navigator.element.popPage().then(() => {

        // 画面再描画
        this.params.data.method();
      });
    }
  }

  /**
   * カート内の商品情報を更新する。
   *
   * @memberof ShoppingMenuDetailComponent
   */
  updateItem():void {

    // メニューの数量変更
    const updateResult = this.orderRequestServ.changeMenuCount(this.selectMenu.menu.menu_id, this.selectMenu.count);

    // 指定したメニューIDが存在しなかった場合
    if (updateResult == orderRequestResult.ERR_NOT_EXISTS_MENU_ID) {
      // 想定外
      this.appMsgServ.viewDialogMessage(this.msg.CLIENT.SHOPPING.ERR_UPDATE_ITEM.message());
      return;
    }
    // 正常処理
    else {
      // メニュー一覧画面に戻る
      this.navigator.element.popPage().then(() => {

        // 画面再描画
        this.params.data.method();
      });
    }
  }

  /**
   * 選択したカート内商品を削除する。
   *
   * @memberof ShoppingMenuDetailComponent
   */
  deleteItem(): void {

    // 選択した商品をカートから削除
    const deleteResult = this.orderRequestServ.deleteMenu(this.selectMenu.menu.menu_id);
    
    // 指定したメニューが存在しない場合
    if (deleteResult == orderRequestResult.ERR_NOT_EXISTS_MENU_ID) {
      // 想定外
      this.appMsgServ.viewDialogMessage(this.msg.CLIENT.SHOPPING.ERR_DELETE_ITEM.message());
      return;
    }
    // 正常処理
    else {

      // カート一覧画面に戻る
      this.navigator.element.popPage().then(() => {

        // 画面再描画
        this.params.data.method();

        // カートに商品が存在しない場合
        if (this.orderRequestServ.getCartItemCount == 0) {

          // ユーザ通知の上、メニュー一覧画面に戻る
          this.params.data.returnMenuList();

          return;
        }
      });
    }
  }

  /**
   * 画像クリック時、ライトボックス表示を行う。
   *
   * @memberof ShoppingMenuDetailComponent
   */
  onEnlargeImage() {
    
    // image取得
    const imgV: HTMLElement = document.getElementById(this.selectMenu.menu.menu_id);
    const options: Viewer.Options = {
      // タイトル
      title: false,
      // ツールバー
      toolbar: {
        prev: { show: this.selectMenu.menu.images.length > 1 ? true: false, size: "large" },
        zoomOut: { size: "large" },
        download: { show: true, size: "large", click: () => this.imageDownload(this.selectMenu.menu) }, 
        zoomIn: { size: "large" },
        next: { show: this.selectMenu.menu.images.length > 1 ? true: false, size: "large" },
        oneToOne: false,
        reset: false,
        play: false,
        rotateLeft: false,
        rotateRight: false,
        flipHorizontal: false,
        flipVertical: false,
      },
      // 画像描画後のイベント
      viewed: (event: CustomEvent) => {
        this.viewImageIndex = event.detail.index;
      }
    };

    // viewer生成
    const viewer = new Viewer(imgV, options);
  }

  /**
   * 商品個数を一つ増やす。
   *
   * @memberof ShoppingMenuDetailComponent
   */
  countUp(): void {

    // click時、表示中個数が最大数未満の時
    if (this.selectMenu.count < this.selectItemsMaxCount) {
      // 表示を＋１
      this.selectMenu.count++;
      // －ボタンを活性化
      this.isDisabledCountDown = false;
      // ボタンに表示する合計料金を計算
      this.calculateMenuPrice();
      // 増やした後の個数が最大数と同じ場合、＋ボタンを非活性化
      if (this.selectMenu.count == this.selectItemsMaxCount) this.isDisabledCountUp = true;
    }
    // click時、表示中個数が一つの場合
    else if (this.selectMenu.count == 1) {
      // －ボタンを活性化
      this.isDisabledCountDown = false;
    }

    // 変更がない（詳細画面に遷移した際の個数と同じ）ならば、更新ボタンを非表示
    if (this.selectedMenuCount == this.selectMenu.count) {
      this.isDisabledUpdateButton = true;
    }
    // 更新ボタン表示
    else {
      this.isDisabledUpdateButton = false;
    }
  }

  /**
   * 商品個数を一つ減らす。
   *
   * @memberof ShoppingMenuDetailComponent
   */
  countDown(): void {

    // click時、表示中個数が１個より多い時
    if (this.selectMenu.count > 1) {
      // 表示を－１
      this.selectMenu.count--;
      // ＋ボタンを活性化
      this.isDisabledCountUp = false;
      // ボタンに表示する合計料金を計算
      this.calculateMenuPrice();

      // 減らした後の個数が一つの場合
      if (this.selectMenu.count == 1) {
        // －ボタンを非活性化、＋ボタンを活性化
        this.isDisabledCountDown = true;
        this.isDisabledCountUp = false;
      }
    }

    // 変更がない（詳細画面に遷移した際の個数と同じ）ならば、更新ボタンを非表示
    if (this.selectedMenuCount == this.selectMenu.count) {
      this.isDisabledUpdateButton = true;
    }
    // 更新ボタン表示
    else {
      this.isDisabledUpdateButton = false;
    }
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * 表示用データを作成する。
   *
   * @private
   * @memberof ShoppingMenuDetailComponent
   */
  private createDisplayData() {

    // 表示用アレルギー情報を作成
    if (this.selectMenu.menu.allergens && this.selectMenu.menu.allergens.length != 0) {
      let allergensName: string[] = [];

      this.selectMenu.menu.allergens.forEach((element) => {
        allergensName.push(element.name)
      });
      this.displayAllergens = allergensName.join('、');
    }

    // 表示用カテゴリー情報を作成
    if (this.selectMenu.menu.categories && this.selectMenu.menu.categories.length != 0) {

      this.displayCategories = this.selectMenu.menu.categories.join('、');
    }
  }

  /**
   * メニュー料金を計算する。
   *
   * @memberof ShoppingMenuDetailComponent
   */
  private calculateMenuPrice(): void {

    this.totalPrice = this.selectMenu.menu.price * this.selectMenu.count; 
  }

  /**
   * イメージをダウンロードする。
   *
   * @private
   * @param {NewsArticle} article
   * @memberof NewsArticleListComponent
   */
  private imageDownload(menu: ShoppingMenu): void {
    
    const a = document.createElement('a');
    a.href = menu.images[this.viewImageIndex];
    // a.download = viewer.image.alt;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}

/**
 * 詳細画面の処理状態。
 *
 * @export
 * @interface menuProcMode
 */
export interface menuProcMode {

  /**
   * 詳細画面の処理モード
   *
   * @type {("add" | "update")} 追加 | 更新
   * @memberof menuProcMode
   */
  mode: "add" | "update";
  
  /**
   * カートから削除が可能か
   *
   * @type {boolean}
   * @memberof menuProcMode
   */
  delete_permit: boolean;
}