import { Component, OnInit } from '@angular/core';

import { UserWebApiService } from '../../http-services/user-web-api.service';
import { HttpErrorResponseParserService } from '../../lib-services/http-error-response-parser.service';
import { AccountListComponent } from './account-list/account-list.component';

/**
 * マイページメニューのons-navigatorコンポーネント
 *
 * @export
 * @class AccountComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'ons-page[account]',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {
  myPage = AccountListComponent;

  constructor(
    private userWebApiService: UserWebApiService,
    private httpErrorResponseParserService: HttpErrorResponseParserService
    ) { }

  ngOnInit(): void { }
}
