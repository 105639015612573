<ons-toolbar>
  <div class="left"><ons-back-button #onsBackButton></ons-back-button></div>
  <div class="center">観光サービス一覧</div>
</ons-toolbar>

<div [ngBusy]="busy"></div>
<div class="content">

  <parts-ons-list [listInfo]="header"></parts-ons-list>

  <!-- モビリティ -->
  <ng-container *ngIf="mobility.length > 1">
    <ng-container *ngFor="let list of mobility">
      <parts-ons-list [listInfo]="list" (partsEvent)="onClickList($event)"></parts-ons-list>
    </ng-container>
  </ng-container>

  <!-- アクティビティ -->
  <ng-container *ngIf="activity.length > 1">
    <ng-container *ngFor="let list of activity">
      <parts-ons-list [listInfo]="list" (partsEvent)="onClickList($event)"></parts-ons-list>
    </ng-container>
  </ng-container>
</div>