import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import jsQR from 'jsqr';
import { Subscription } from 'rxjs';
import { MESSAGE } from 'src/app/constants/message';
import { ApplicationMessageService } from 'src/app/lib-services/application-message.service';

/**
 * QRコード読み取りコンポーネント
 *
 * @export
 * @class QrReadComponent
 * @implements {OnInit}
 * @implements {AfterViewInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-qr-read',
  templateUrl: './qr-read.component.html',
  styleUrls: ['./qr-read.component.scss']
})
export class QrReadComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
//=============================================================================================
// プロパティ定義
//=============================================================================================
  
  /**
   * ngBusyに利用するSubscriptionオブジェクト
   *
   * @type {Subscription}
   * @memberof QrReadComponent
   */
  busy: Subscription;

  /**
   * videoタグにバインドするMediaStreamオブジェクト
   *
   * @type {MediaStream}
   * @memberof QrReadComponent
   */
  mediaStream: MediaStream;

  /**
   * QR発行したかどうか、QR発行が複数回行われないための値
   *
   * @type {boolean}
   * @memberof QrReadComponent
   */
  emitted: boolean = false;
 
  /**
   * 撮影中かどうか
   *
   * @type {boolean}
   * @memberof QrReadComponent
   */
  @Input('filming') readonly filming: boolean;

  /**
   * QRコード情報を読み取った際のイベント
   * note: filmingをtrueにするinput1回につき、1回までイベント発行される
   *
   * @type {EventEmitter<QrObject>}
   * @memberof QrReadComponent
   */
  @Output('qr') qr: EventEmitter<QrObject> = new EventEmitter<QrObject>();

  /**
   * QRコード画像タグ
   *
   * @private
   * @type {ElementRef<HTMLCanvasElement>}
   * @memberof QrReadComponent
   */
  @ViewChild('canvas') private canvas: ElementRef<HTMLCanvasElement>;

  /**
   * video映像タグ
   *
   * @private
   * @type {ElementRef<HTMLVideoElement>}
   * @memberof QrReadComponent
   */
  @ViewChild('video') private video: ElementRef<HTMLVideoElement>;

  /* 
    note: mediaStreamが存在するかで、撮影中かどうかを判断することはできるが、
          撮影開始処理呼び出し(撮影中ではない)してから、実際に撮影開始するまで、
          に別の撮影開始処理を実行しないための対策。
  */
  /**
   * 撮影開始処理呼び出し(撮影中ではない)から撮影終了まで
   *  別で撮影開始処理を行わないためのフラグ(排他制御)
   *
   * @type {boolean}
   * @memberof QrReadComponent
   */
  isStarted: boolean = false;

  /*
    note: ngOnDestroyの中で撮影停止処理を実行しており、
          実行された後、撮影開始処理が呼びされれてしまうと、このコンポーネントが破棄されても
          撮影状態が残ってしまうことを防ぐため。
  */
  /**
   * ngOnDestroyが呼び出されているかどうか
   * (呼び出された時点でtrue)
   *
   * @type {boolean}
   * @memberof QrReadComponent
   */
  isOnDestroy: boolean = false;

  /**
   * 撮影開始開始処理時Promise
   *
   * @type {Promise<any>}
   * @memberof QrReadComponent
   */
  startFirmBusy: Promise<any>

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  constructor(
    private _appMsgServ: ApplicationMessageService,
    private msg: MESSAGE,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {

    // filmingの変更を検知
    if (changes.filming.currentValue) {
      // 値の初期化
      this.emitted = false;
      // 撮影開始
      this.startFilm();
    }
    else {
      // 撮影停止
      this.stopFilm();
    }
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {

    this.video.nativeElement.onloadedmetadata = () => {
      // mediaStreamが読み込まれた時、videoタグ要素再生開始
      this.video.nativeElement.play().then(()=> {
        // 念の為、canvasをリセット
        const canvasElem = this.canvas.nativeElement;
        const context = canvasElem.getContext('2d');
        context.clearRect(0, 0, canvasElem.width, canvasElem.height);
        // QRコードスキャン
        this.scan();
      });
    }
  }

  ngOnDestroy(): void {
    this.isOnDestroy = true;
    this.busy?.unsubscribe();
    this.stopFilm();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 撮影開始
   *
   * @private
   * @memberof QrReadComponent
   */
  private startFilm(): void {
    // 撮影開始処理中 or 撮影中の場合、処理を抜ける
    // onDestroy(ディレクティブまたはコンポーネントを破棄する直前)が既に実行されている場合、処理を抜ける
    if (this.isStarted || this.isOnDestroy) return;

    // 撮影処理開始をフラグに保存
    this.isStarted = true;

    /** デバイスカメラ起動 */
    // ユーザの許可に基づいてビデオ撮影
    this.startFirmBusy = navigator.mediaDevices.getUserMedia({
      // 音声なし
      audio: false,
      video: {
        // リアカメラ
        facingMode: "environment"
      }
    })
    .then(mediaStream => {
      this.mediaStream = mediaStream;

      // note: デバイスカメラ起動中にisDestroyがtrueになった場合の対策
      // ngOnDestroy()(ディレクティブまたはコンポーネントを破棄する直前)が実行された後に撮影開始した場合、撮影停止処理実行
      if (this.isOnDestroy == true) this.stopFilm();
    })
    .catch(_ => {
      // デバイスカメラ起動失敗
      this._appMsgServ.viewDialogMessage(this.msg.CLIENT.SQRC.INACTIVE_CAMERA.message(), () => this.isStarted = false);
    });
  }

  /**
   * 画像スキャン
   *
   * @private
   * @return {*}  {void}
   * @memberof QrReadComponent
   */
  private scan(): void {
    /** videoタグ要素 */
    const videoElem = this.video.nativeElement;
    /** canvasタグ要素 */
    const canvasElem = this.canvas.nativeElement;
    
    // デバイスカメラ映像とつながっていない場合はスキャン停止
    if (!this.mediaStream) return;

    /** ソースの座標・サイズ計測結果 */
    const rectangle = (() => {
      const ratio = canvasElem.height / canvasElem.width;
      const sh = videoElem.videoWidth * ratio;

      if (videoElem.videoHeight < sh) {
        const sw = videoElem.videoWidth * (1 / ratio);

        return { sx: (videoElem.videoWidth - sw) / 2, sy: 0, sw, sh: videoElem.videoHeight };
      } 
      else {
        return { sx: 0, sy: (videoElem.videoHeight - sh) / 2, sw: videoElem.videoWidth, sh };
      }
    })();

    /** canvasタグ要素の描画内容 */
    const context = canvasElem.getContext('2d');  // note: TSのバージョンを上げると、willFrequentlyオプションが追加される

    // canvasに描画
    context.drawImage(videoElem, rectangle.sx, rectangle.sy, rectangle.sw, rectangle.sh, 0, 0, canvasElem.width, canvasElem.height);

    /** canvasのピクセルデータ */
    const imageData = context.getImageData(0, 0, canvasElem.width, canvasElem.height);

    // jsQRについて(https://github.com/cozmo/jsQR)
    /** imageDataから検出したQRコード */
    const code = jsQR(imageData.data, canvasElem.width, canvasElem.height, {
      // 色反転していないQRコードを探す
      inversionAttempts: "dontInvert"
    });

    setTimeout(() => {
      if (!code || !code.data || code.data =="<empty string>") {
        // QRコード未発見の場合、スキャン続行
        return this.scan();
      }

      if (this.emitted == false) {
        // QRコード情報をoutput
        this.qr.emit({
          code: code.data,
          image: this.canvas.nativeElement.toDataURL('image/jpeg')
        });
        this.emitted = true;
      }
      
    }, 1000/60);  // 60fps
  }

  /**
   * 撮影終了
   *
   * @private
   * @memberof QrReadComponent
   */
  private stopFilm(): void {
    this.mediaStream?.getVideoTracks().forEach(track => {
      // メディアトラックを無効化
      track.enabled = false;
      // トラックに関連付けられたソースの再生を停止し、ソースとトラックの関連付けを解除
      track.stop();
    });

    // デバイスカメラ映像ストリームを遮断
    this.mediaStream = null;
    
    // 撮影終了をフラグに保存
    this.isStarted = false;
  }
}

/**
 * 読み取られたQR情報
 *
 * @export
 * @interface QrObject
 */
export interface QrObject {
  /**
   * QRコード情報
   *
   * @type {string}
   * @memberof QrObject
   */
  code: string;

  /**
   * HTMLCanvasElement.toDataURL('image/jpeg')で変換されたQR画像情報
   *
   * @type {string}
   * @memberof QrObject
   */
  image: string;
}