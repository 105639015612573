<ons-toolbar modifier="header" class="toolbar">
  <div class="left">
    <ons-toolbar-button id="user-reservation_001" (click)="openMenu()">
      <ons-icon icon="ion-ios-menu, material: md-menu"></ons-icon>
    </ons-toolbar-button>
  </div>
  <div class="center toolbar__title-smartgoto-wrap">
    <div class="toolbar__title-smartgoto">Smart GOTO</div>
    <div *ngIf="areaName" class="toolbar__title-smartgoto--area-name">({{areaName}}版)</div>
  </div>
</ons-toolbar>

<!--　何のための処理なのか現時点で不明であるため、要調査
  ※おそらくタブを使わない場合のリストメニューと思われる。
     <ons-list class="useListMenu-list" *ngIf="useListMenuList">
    <ons-list-item modifier="longdivider" *ngFor="let item of tabs;"
        (click)="tabbar.setActiveTab(item.id-1);useListMenuList=!useListMenuList;onSetScroll(item.id);"
        class="useListMenuList__item">
        {{ item.label }}
    </ons-list-item>
</ons-list> -->
<ons-tabbar #tabbar modifier="top" class="useListMenu-tabbar" position="top">
  <ng-container *ngFor="let tab of displayTabs; let i = index;">
    <!-- 一枚目タブ -->
    <ons-tab *ngIf="i == 0" [id]="tab.id" [attr.label]="tab.label" [page]="tab.component"
    (click)="onSetScroll(tab.id, tab.tag);" active></ons-tab>
    <!-- 二枚目以降タブ -->
    <ons-tab *ngIf="i !== 0" [id]="tab.id" [attr.label]="tab.label" [page]="tab.component"
    (click)="onSetScroll(tab.id, tab.tag);"></ons-tab>
  </ng-container>
</ons-tabbar>