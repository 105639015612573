//=============================================================================================
// インポート
//=============================================================================================
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { OnsNavigator, Params } from 'ngx-onsenui';

// component
import { ExpDetailComponent } from '../exp-detail/exp-detail.component';
import { ExpDetailType1Component } from '../exp-detail-type1/exp-detail-type1.component';

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { ExpWebApiService, GetPrice } from '../../../http-services/exp-web-api.service';
import { PageKey, PagerService } from 'src/app/lib-services/pager.service';
import { GoogleTagManagerService } from '../../../lib-services/google-tag-manager.service';

// parts
import { ListParts } from '../../parts/ons-list/ons-list.component';

// interface
import { ExpService, Shop } from '../../../interfaces/response';
import { parameter } from '../../../interfaces/parameter';

// module
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';
import * as moment from 'moment';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 店舗サービス画面。
 *
 * @export
 * @class ExpShopServiceListComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ons-page[exp-shop-service-list]',
  templateUrl: './exp-shop-service-list.component.html',
  styleUrls: ['./exp-shop-service-list.component.scss']
})
export class ExpShopServiceListComponent implements OnInit, OnDestroy, AfterViewInit {

  /**
   * 通信用Subscription
   *
   * @type {Subscription}
   * @memberof ExpShopServiceListComponent
   */
  busy: Subscription;

  /**
   * 対象店舗のサービス一覧
   *
   * @type {ExpService}
   * @memberof ExpShopServiceListComponent
   */
  serviceList: ExpService[];

  /**
   * モビリティ リスト表示用データ
   *
   * @type {ListParts[]}
   * @memberof ExpShopServiceListComponent
   */
  mobility: ListParts[] = [];

  /**
   * アクティビティ リスト表示用データ
   *
   * @type {ListParts[]}
   * @memberof ExpShopServiceListComponent
   */
  activity: ListParts[] = [];

  /**
   * ヘッダー 表示
   *
   * @type {ListParts}
   * @memberof ExpShopServiceListComponent
   */
  header: ListParts;

  /**
   * 戻るボタン
   *
   * @private
   * @type {ElementRef}
   * @memberof ExpShopServiceListComponent
   */
  @ViewChild('onsBackButton') private onsBackButton: ElementRef;

  /**
   * 選択した店舗
   *
   * @type {Shop}
   * @memberof ExpShopServiceListComponent
   */
  shop: Shop;

  /**
   * OnDestroy時に破棄するSubscriptionオブジェクト
   *
   * @memberof ExpShopServiceListComponent
   */
  subscription = new Subscription();

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of ExpShopServiceListComponent.
   * @param {ApplicationMessageService} appMsgServ
   * @param {HttpErrorResponseParserService} errResServ
   * @param {ExpWebApiService} expServ
   * @param {OnsNavigator} navigator
   * @param {Params} params
   * @memberof ExpShopServiceListComponent
   */
  constructor(
    private appMsgServ: ApplicationMessageService,
    private errResServ: HttpErrorResponseParserService,
    private expServ: ExpWebApiService,
    private navigator: OnsNavigator,
    private params: Params,
    private pagerServ: PagerService,
    private msg: MESSAGE,
    private gtmServ: GoogleTagManagerService,
  ) { }

  /**
   * 初期処理
   *
   * @memberof ExpShopServiceListComponent
   */
  ngOnInit(): void {
    // 店舗
    this.shop = this.params.data.shop;

    // 対象店舗のサービス取得
    this.getServiceList(this.shop);

    this.gtmServ.pushPageview(`/exp/shop/${this.shop.shop_id}/`, this.shop.name);
  }

  /**
   * 描画後の初期化処理
   *
   * @memberof ExpShopServiceListComponent
   */
  ngAfterViewInit(): void {

    // バックボタン
    this.onsBackButton.nativeElement.onClick = () => {

      // popPage
      this.navigator.element.popPage().then(() => {
        // 観光トップ画面のswiper.jsを初期化
        this.params.data.swiperInit();
      });
    };

    // 観光サービストップ画面に戻る為のページ情報保存
    this.pagerServ.setReturnPage({ index: this.navigator.element.pages.length - 1, key: PageKey.ExpServiceListComponent });

    // ページスタックが削除されて、この画面に戻ってきたことを監視
    const pagerSubject = this.pagerServ.subject.subscribe({
      next: (key: number) => {
        if (key == PageKey.ExpServiceListComponent) {
          this.updateService();
        }
      },
    });
    this.subscription.add(pagerSubject);
  }

  /**
   * 破棄処理。
   *
   * @memberof ExpShopServiceListComponent
   */
  ngOnDestroy(): void {

    this.busy?.unsubscribe();
    this.subscription?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 部品リストのクリックイベント
   *
   * @param {(string|number)} key
   * @memberof ExpShopServiceListComponent
   */
  onClickList(key: string|number): void {

    // 選択したサービス
    const selectedService = this.serviceList.find(service => service.sg_service_id == key);

    if (selectedService.schedule_type === 1) {
      // 体験サービス詳細画面へ遷移
      this.navigator.element.pushPage(ExpDetailType1Component, {
        data: {
          service: selectedService,
        }
      });
    }
    else if (selectedService.schedule_type === 3) {
      // 体験サービス詳細画面へ遷移
      this.navigator.element.pushPage(ExpDetailComponent, {
        data: {
          service: selectedService,
        }
      });
    }
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * リスト表示用データ作成
   *
   * @param {Shop} shop
   * @memberof ExpShopServiceListComponent
   */
  private createDispList(shop: Shop) {

    // ヘッダー(店舗情報)
    this.header = {
      mode: 'header',
      header_list: {
        item: [shop.name],
        icon: shop.icon ?? ""
      }
    }

    // リストヘッダー
    this.mobility.push({header: 'モビリティ'});
    this.activity.push({header: 'アクティビティ'});

    this.serviceList.forEach((service: ExpService) => {

      // 最低料金を計算する為に必要なデータ
      const priceInfo: GetPrice = {
        price_rules: this.expServ.getTargetPrice(service.prices, moment()).price_rules,
        params: {
          number: 1,
          // 選択可能な最小時間
          util_time: this.expServ.getMinimumTime(service)
        },
        type: this.expServ.getDefaultType(service.user_params.numbers)
      }

      // 表示用データ
      const pushList: ListParts = {
        mode: 'tappable',
        tappable: {
          item: [
            {
              text_string: service.title
            },
            {
              option_text_before: '￥',
              // 最低料金
              text_number: this.expServ.getPrice(priceInfo),
              option_text_after: '～'
            }
          ],
          key: service.sg_service_id,
          icon: service.images.length > 0 ? service.images[0] : CommonFunctionModule.getAssetsUrl("/image/common/49-Noimage.png")
        }
      };

      // モビリティ
      if (service.service_type == 'MOBILITY') {
        this.mobility.push(pushList);
      }
      // アクティビティ
      else if (service.service_type == 'ACTIVITY') {
        this.activity.push(pushList);
      }
    });
  }

  /**
   * サービス一覧更新
   *
   * @private
   * @memberof ExpSearchServiceListComponent
   */
  private updateService(): void {
    this.getServiceList(this.shop, true);
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * 対象店舗のサービス一覧取得
   *
   * @memberof ExpShopServiceListComponent
   */
  getServiceList(shop: Shop, isUpdate: boolean=false): void {

    if (isUpdate === true) {
      this.serviceList = [];
      this.header = {};
      this.mobility = [];
      this.activity = [];
    }

    // クエリ作成
    const param: parameter.ExpService = {shop_id: Number(shop.shop_id)};

    // サービス一覧取得
    this.busy = this.expServ.getServiceList(param).subscribe({
      next: res => {
        // サービス一覧
        this.serviceList = res.body;

        // 該当するサービスが0件の場合
        if (this.serviceList.length == 0) {
          // popPage
          this.appMsgServ.viewDialogMessage(this.msg.CLIENT.EXP.NOT_EXIST_SERVICE.message(), () => {
            this.navigator.element.popPage().then(() => {
              // 観光トップ画面のswiper.jsを初期化
              this.params.data.swiperInit();
            });
          });
        }
        // 表示用データ作成
        else this.createDispList(shop);
      },
      error: this.errResServ.doParse((_err, errContent) => this.errResServ.viewErrDialog(errContent))
    });
  }
}


