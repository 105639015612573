<ons-toolbar>
    <div class="left ">
        <ons-back-button></ons-back-button>
    </div>
    <div class="center">荷物積載</div>
</ons-toolbar>
<ons-list>
    <ons-list-item modifier="nodivider">
        <div class="left">
            <ons-icon icon="fa-suitcase" class="list-item__icon"></ons-icon>
        </div>
        <p class="center">
            手持ち以外の荷物
        </p>
        <div class="right">
            <ons-switch [(value)]="searchCondition.baggage.checked"></ons-switch>
        </div>
    </ons-list-item>
    <!-- TODO: デザインは任せた。 -->
    <ons-list-item *ngIf="searchCondition.baggage.checked">
        <div>
            <textarea [(ngModel)]="searchCondition.baggage.detail"></textarea>
        </div>
    </ons-list-item>
</ons-list>