//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, ElementRef} from '@angular/core';
import { Subscription } from 'rxjs';
import { OnsNavigator, Params } from 'ngx-onsenui';
import * as CONST from '../../../constants/constant';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';

// component
import { ExpDetailComponent } from '../exp-detail/exp-detail.component';

// service
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { ExpWebApiService, GetPrice} from '../../../http-services/exp-web-api.service';
import { PageKey, PagerService } from 'src/app/lib-services/pager.service';
import { GoogleTagManagerService } from '../../../lib-services/google-tag-manager.service';
import { ApplicationMessageService } from '../../../lib-services/application-message.service';

// parts
import { ListParts } from '../../parts/ons-list/ons-list.component';
import { SearchFormData } from '../../parts/search-form/search-form.component';

// interface
import { parameter } from '../../../interfaces/parameter';
import { ExpService, Settings} from '../../../interfaces/response';

// module
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';
import * as moment from 'moment';
import { ExpDetailType1Component } from '../exp-detail-type1/exp-detail-type1.component';
import { MESSAGE } from '../../../constants/message';
import * as _ from 'lodash';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 観光サービス検索画面。
 *
 * @export
 * @class ExpSearchServiceListComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ons-page[exp-search-service-list]',
  templateUrl: './exp-search-service-list.component.html',
  styleUrls: ['./exp-search-service-list.component.scss']
})
export class ExpSearchServiceListComponent implements OnInit, OnDestroy, AfterViewInit {

  /**
   * 通信用Subscription
   *
   * @type {Subscription}
   * @memberof ExpSearchServiceListComponent
   */
  busy: Subscription;

  /**
   * HTML用
   *
   * @memberof ExpSearchServiceListComponent
   */
  moment = moment;

  /**
   * フリーワード
   *
   * @type {string}
   * @memberof ExpSearchServiceListComponent
   */
  freeword: string;

  /**
   * フリーワードに該当する
   *
   * @type {ExpService[]}
   * @memberof ExpSearchServiceListComponent
   */
  serviceList: ExpService[] = undefined;

  /**
   * 検索窓表示・非表示フラグ
   * 
   * @type {boolean}
   * @memberof ExpSearchServiceListComponent
   */
  showSearchForm: boolean = false;

  /**
   * 期間検索窓表示・非表示フラグ
   * 
   * @type {boolean}
   * @memberof ExpSearchServiceListComponent
   */
  showPeriodSearchForm: boolean = false;

  /**
   * 日付検索窓表示・非表示フラグ
   *
   * @type {boolean}
   * @memberof ExpSearchServiceListComponent
   */
  showUsageDateSearchForm: boolean = false;

  /**
   * mobipaからの遷移の場合検索結果が０件の場合検索窓・日付検索窓を非表示
   * 
   * @type {boolean}
   * @memberof ExpSearchServiceListComponent
   */
  isSearchResultEmpty: boolean = false;

  /**
   * 戻るボタン表示・非表示フラグ
   * 
   * @type {boolean}
   * @memberof ExpSearchServiceListComponent
   */
  showBackButton: boolean = false;

  /**
   * 検索方法種別
   * 
   * @type {string}
   * @memberof ExpSearchServiceListComponent
   */
  searchType: string = '';

  /**
   * 使用日検索履歴
   * 
   * @type {string[]}
   * @memberof ExpSearchServiceListComponent
   */
  usageDateSearchHistory: string[] = [];

  /**
   * 期間検索履歴スタック
   *
   * @type {PeriodSearchCondition[]}
   * @memberof ExpSearchServiceListComponent
   */
  periodSearchHistory: PeriodSearchCondition[] = [];

  /**
   * 外部リンクからの遷移の場合
  */
  fromExternalLink: boolean = false;

  /**
   * リスト表示用データ
   *
   * @type {ListParts[]}
   * @memberof ExpSearchServiceListComponent
   */
  listParts: ListParts[] = undefined;

  /**
   * 検索履歴最大数
   *
   * @type {number}
   * @memberof ExpComponent
   */
  limit: number = CONST.Search.HISTORY;

  /**
   * 設定情報(体験サービス)
   *
   * @type {Settings["exp"]}
   * @memberof ExpDetailComponent
   */
  settingExp: Settings["exp"];

  /**
   * datePickerの表示範囲
   *  start: 現在日時
   *  end: 現在日時 + 6か月
   *
   * @type {{ start: Date; end: Date }}
   * @memberof ExpSearchServiceListComponent
   */
  datePickerRange = {
    start: new Date(),
    end: this.addMonths(new Date(), 6)
  };

  addMonths(date: Date, months: number): Date {
    const result = new Date(date);
    result.setMonth(result.getMonth() + months);
    return result;
  }

  /**
   * 利用日検索用日付
   *
   * @type {string}
   * @memberof ExpReserveListComponent
   */
  usageDate: string;

  /**
   * 検索ボタンの活性・非活性
   *
   * @type {boolean}
   * @memberof ExpSearchServiceListComponent
   */
  isSearchButtonDisabled: boolean = true;

  /**
   * 画面初期化判定フラグ
   * 
   * @type {boolean}
   * @memberof ExpSearchServiceListComponent
   */
  initialFlag: boolean;

  /**
   * 時間選択リスト
   *
   * @type {any[]}
   * @memberof ExpSearchServiceListComponent
   */
  hourMinuteList: {
    view: string;
    hour: number;
    minute: number;
  }[] = [];

  /**
   * 期間検索条件
   * 
   * @type {PeriodSearchCondition}
   * @memberof ExpSearchServiceListComponent
   */
  periodSearchCondition: PeriodSearchCondition = {
    start: {
      date: "",
      time: { view: "", hour: 0, minute: 0 }
    },
    end: {
      date: "",
      time: { view: "", hour: 0, minute: 0 }
    }
  };

  /**
   * matDatePicker
   * 利用開始日時
   *
   * @type {(MatDatepicker<Date | null> | undefined)}
   * @memberof ExpDetailType1Component
   */
  @ViewChild('startTime') matStart: MatDatepicker<Date | null> | undefined;

  /**
   * matDatePicker
   * 利用終了日時
   *
   * @type {(MatDatepicker<Date | null> | undefined)}
   * @memberof ExpDetailType1Component
   */
  @ViewChild('endTime') matEnd: MatDatepicker<Date | null> | undefined;

  /**
   * matDatePicker
   * 利用開始日
   * 
   * @type {MatDatepicker<Date>}
   * @memberof ExpDetailType1Component
   */ 
  @ViewChild('startDate') startDate: MatDatepicker<Date>;

  /**
   * matDatePicker
   * 利用終了日
   * 
   * @type {MatDatepicker<any>}
   * @memberof ExpDetailType1Component
   */
  @ViewChild('endDate') endDate: MatDatepicker<any>;

  /**
   * 戻るボタン
   *
   * @private
   * @type {ElementRef}
   * @memberof ExpSearchServiceListComponent
   */
  @ViewChild('onsBackButton') private onsBackButton: ElementRef;

  /**
   * OnDestroy時に破棄するSubscriptionオブジェクト
   *
   * @memberof ExpSearchServiceListComponent
   */
  subscription = new Subscription();

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of ExpSearchServiceListComponent.
   * @param {HttpErrorResponseParserService} errResServ
   * @param {ExpWebApiService} expServ
   * @param {OnsNavigator} navigator
   * @param {Params} params
   * @memberof ExpSearchServiceListComponent
   */
  constructor(
    private errResServ: HttpErrorResponseParserService,
    private expServ: ExpWebApiService,
    private navigator: OnsNavigator,
    private params: Params,
    private pagerServ: PagerService,
    private gtmServ: GoogleTagManagerService,
    private appMsgServ: ApplicationMessageService, 
    private msg: MESSAGE,
  ) { }

  /**
   * 初期処理
   *
   * @memberof ExpSearchServiceListComponent
   */
  ngOnInit(): void {

    this.freeword = this.params.data.freeword;
    this.searchType = this.params.data.dateSearchType;
    this.fromExternalLink = this.params.data.fromExternalLink;

    this.isSearchResultEmpty = false;
    this.initialFlag = true;
    this.initial();
    // フォーム選択
    this.handleDateSearchType();
    
    this.gtmServ.pushPageview(`/exp/search_list/${this.freeword}/`, 'Search List');

    // 時刻リスト作成
    const startOfDay = this.moment().startOf('date');
    this.hourMinuteList = Array.from({length: 48}, (_, i) => ({
      view: this.moment(startOfDay).add(i * 30, 'minutes').format('H:mm'),
      hour: this.moment(startOfDay).add(i * 30, 'minutes').hour(),
      minute: this.moment(startOfDay).add(i * 30, 'minutes').minute(),
    }));
  }

  /**
   * 描画後の初期化処理
   *
   * @memberof ExpSearchServiceListComponent
   */
  ngAfterViewInit(): void {
    // バックボタン
    if(this.onsBackButton){
      this.onsBackButton.nativeElement.onClick = () => {
        // popPage
        this.navigator.element.popPage().then(() => {
          // 観光トップ画面のswiper.jsを初期化
          this.params.data.swiperInit();
        });
      };
    }

    // 観光サービストップ画面に戻る為のページ情報保存
    this.pagerServ.setReturnPage({ index: this.navigator.element.pages.length - 1, key: PageKey.ExpServiceListComponent });

    // ページスタックが削除されて、この画面に戻ってきたことを監視
    const pagerSubject = this.pagerServ.subject.subscribe({
      next: (key: number) => {
        if (key == PageKey.ExpServiceListComponent) {
          this.updateService();
        }
      },
    });
    this.subscription.add(pagerSubject);
  }

  /**
   * 破棄処理。
   *
   * @memberof ExpSearchServiceListComponent
   */
  ngOnDestroy(): void {

    this.busy?.unsubscribe();
    this.subscription?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * リストのクリックイベント
   *
   * @param {number} key
   * @memberof ExpSearchServiceListComponent
   */
  onClickList(key: number): void {
    const selectedService: ExpService | undefined = this.serviceList.find(e => e.sg_service_id === key);
    if (selectedService) {
      const data: any = { service: selectedService };
      if (selectedService.schedule_type === 1) {
        if (this.searchType === 'period' && this.checkDateTimeInput()) {
          data.from_period_search = true;
          data.start_date = {
            date: this.periodSearchCondition.start.date,
            time: {
              view: this.periodSearchCondition.start.time.view,
              hour: this.periodSearchCondition.start.time.hour,
              minute: this.periodSearchCondition.start.time.minute
            }
          };
          data.end_date = {
            date: this.periodSearchCondition.end.date,
            time: {
              view: this.periodSearchCondition.end.time.view,
              hour: this.periodSearchCondition.end.time.hour,
              minute: this.periodSearchCondition.end.time.minute
            }
          };
        }
      } else if (selectedService.schedule_type === 3) {
        if (this.searchType === 'usage_date') {
          data.usage_date = this.usageDate;
          data.from_usage_date_search = true;
        }
      }
      this.navigator.element.pushPage(
        selectedService.schedule_type === 1 ? ExpDetailType1Component : ExpDetailComponent,
        { data }
      );
    }
  }

  onBack(): void {
    // 期間検索の場合
    if (this.searchType === 'period') {
      this.handlePeriodSearchBack();
    } 
    // 利用日検索の場合
    else if (this.searchType === 'usage_date') {
      this.handleUsageDateSearchBack();
    }
  }

  /**
   * 期間検索の戻る処理
   */
  private handlePeriodSearchBack(): void {
    if (this.periodSearchHistory.length > 0) {
      this.periodSearchHistory.pop(); // 前回の検索条件を削除

      if (this.periodSearchHistory.length > 0) {
        // 最新の検索条件を取得
        this.periodSearchCondition = _.cloneDeep(this.periodSearchHistory[this.periodSearchHistory.length - 1]);

        this.updateDatePickerValues();

        const startDate = this.createDateTime(
          this.periodSearchCondition.start.date,
          this.periodSearchCondition.start.time.hour,
          this.periodSearchCondition.start.time.minute
        );
        const endDate = this.createDateTime(
          this.periodSearchCondition.end.date,
          this.periodSearchCondition.end.time.hour,
          this.periodSearchCondition.end.time.minute
        );

        const searchParams = this.createPeriodSearchParams(startDate, endDate);
        this.getServiceListByPeriod(searchParams);
      } else {
        // 期間検索履歴がなくなった場合は初期状態に戻す
        this.reloadPage();
      }
    } else {
      // 期間検索履歴がなくなった場合は初期状態に戻す
      this.reloadPage();
    }
  }

  /**
   * 利用日検索の戻る処理
   */
  private handleUsageDateSearchBack(): void {
    if (this.usageDateSearchHistory.length > 0) {
      this.usageDateSearchHistory.pop(); // 前回の利用日検索条件を削除

      if (this.usageDateSearchHistory.length > 0) {
        // 最新の利用日検索条件を取得
        this.usageDate = this.usageDateSearchHistory[this.usageDateSearchHistory.length - 1];

        const searchParams = this.createUsageDateSearchParams(this.usageDate);
        this.getServiceListByPeriod(searchParams);

        // 選択した利用日を日付ピッカーに反映
        this.startDate.select(new Date(this.usageDate));
      } else {
        // 利用日検索履歴がなくなった場合は初期状態に戻す
        this.reloadPage();
      }
    }
  }

  /**
   * 検索履歴が存在しないとき初期状態のページにする
   */
  reloadPage(): void {
    this.navigator.element.pushPage(ExpSearchServiceListComponent, { 
      animation: 'fade-ios',
      data: { 
        freeword: this.freeword,
        dateSearchType: this.searchType,
        fromExternalLink: this.fromExternalLink
      }
    });
  }

  /**
   * 日付が変更されたときのイベントハンドラ
   */
  updateDatePickerValues(): void {
    if (this.periodSearchCondition.start.date) {
        this.startDate.select(new Date(this.periodSearchCondition.start.date));
    }
    if (this.periodSearchCondition.end.date) {
        this.endDate.select(new Date(this.periodSearchCondition.end.date));
    }
  }

  /**
   * フリーワード検索実施時のイベントハンドラ。
   *
   * @param {SearchFormData} searchFormData
   * @memberof ExpSearchServiceListComponent
   */
  onSearch(searchFormData: SearchFormData): void {

    // 変更があった場合のみ、検索を行う
    if (searchFormData.is_change) {
      // 入力したフリーワード
      this.freeword = searchFormData.freeword;

      // 入力中のフリーワードでリスト更新
      this.getServiceList(this.freeword);
    }
  }

  /**
   * 期間検索型
   * datePickerで(開始, 終了日時)日付選択時のイベントハンドラ
   *
   * @param {MatDatepickerInputEvent<Date>} event
   * @memberof ExpDetailType1Component
   */
  onChangePeriodDate(event: MatDatepickerInputEvent<Date>, type: 'start' | 'end'): void {
    const targetPeriod = this.getTargetPeriod(type);
    const formattedDate = this.formatDate(event.value);

    if (formattedDate) {
      targetPeriod.date = formattedDate;
      this.updateSearchButtonState();
      this.checkStartDateTime();
      this.checkEndDateTime();
    } else {
      console.error('無効な日付');
    }
  }
  
  /**
   * 指定されたタイプに応じた期間オブジェクトを取得
   */
  private getTargetPeriod(type: 'start' | 'end'): any {
    return type === 'start' ? this.periodSearchCondition.start : this.periodSearchCondition.end;
  }
  
  /**
   * 日付をフォーマットする
   */
  private formatDate(date: Date | null): string | null {
    return date ? moment(date).format('YYYY-MM-DD') : null;
  }
  
  /**
   * 期間検索型
   * 時間選択時のイベントハンドラ
   *
   * @param {MatDatepickerInputEvent<Date>} event
   * @memberof ExpDetailType1Component
   */
  onChangePeriodTime(time: { view: string; hour: number; minute: number }, type: 'start' | 'end'): void {
    type === 'start' ? this.updateStartTime(time) : this.updateEndTime(time);

    // 検索ボタンの状態を更新
    this.updateSearchButtonState();

    // 日付と時間の妥当性を確認
    this.checkStartDateTime();
    this.checkEndDateTime();
  }
  
  /**
   * 選択した開始時間を更新
   * @param time  
   */
  private updateStartTime(time: { view: string; hour: number; minute: number }): void {
    this.periodSearchCondition.start.time.view = time.view;
    this.periodSearchCondition.start.time.hour = time.hour;
    this.periodSearchCondition.start.time.minute = time.minute;
  }
  
  /**
   * 選択した終了時間を更新
   * 
   * @param time
   */
  private updateEndTime(time: { view: string; hour: number; minute: number }): void {
    this.periodSearchCondition.end.time.view = time.view;
    this.periodSearchCondition.end.time.hour = time.hour;
    this.periodSearchCondition.end.time.minute = time.minute;
  }
  

  /**
   * 期間検索型
   * 検索ボタン押下時のイベントハンドラ
   */
  onClickPeriodSearch(): void {
    const { start, end } = this.periodSearchCondition;
  
    // 開始日・開始日時が入力されていない場合、現在日付を設定（終了日・終了日時が入力されている状態）
    // 開始終了すべて入力されていない場合は検索ボタンを押下できない
    if (!(start.date && start.time.view)) {
      this.setStartDateTime();  // 開始日時を設定
    }
  
    const startDateTime = this.createDateTime(start.date, start.time.hour, start.time.minute);
    const endDateTime = this.createDateTime(end.date, end.time.hour, end.time.minute);
  
    // 期間の妥当性を確認
    if (this.isValidPeriod(startDateTime, endDateTime)) {
      this.initialFlag = false;
      this.saveCurrentPeriodSearchCondition();
  
      const searchParams = this.createPeriodSearchParams(startDateTime, endDateTime);
      this.getServiceListByPeriod(searchParams);
      this.enableBackButton();
    }
  }
  
  /**
   * 現在の期間検索条件を履歴に保存
   */
  private saveCurrentPeriodSearchCondition(): void {
    const newSearchCondition = _.cloneDeep(this.periodSearchCondition);
    this.periodSearchHistory.push(newSearchCondition);
  }
  
  /**
   * 戻るボタンを有効化
   */
  private enableBackButton(): void {
    this.showBackButton = true;
    this.isSearchButtonDisabled = true;
  }
  
  /**
   * 利用日検索型
   * datePickerで（利用日）日付選択時のイベントハンドラ
   *
   * @param {MatDatepickerInputEvent<Date>} event
   * @memberof ExpSearchServiceListComponent
   */
  onChangeUsageDate(event: MatDatepickerInputEvent<Date>, type: 'start'): void {
    this.isSearchButtonDisabled = false;
    this.usageDate = event.value.toISOString();
  }

  /**
   * 利用日選択状態を確認
   * @returns boolean
   */
  isUsageDateSelected(){
    return !this.usageDate;
  }

  /**
   * 利用日検索型
   * 検索ボタン押下時のイベントハンドラ
   */
  onClickUsageDateSearch(){
    this.initialFlag = false;
    // 検索履歴に追加
    this.usageDateSearchHistory.push(this.usageDate);

    const searchParams = this.createUsageDateSearchParams(this.usageDate);
    this.getServiceListByPeriod(searchParams);
    this.showBackButton = true;
    this.isSearchButtonDisabled = true;
  }

  /**
   * 利用日検索パラメーターを作成
   */
  createUsageDateSearchParams(targetDate: string): any {
    let start: Date | moment.Moment;
    let end: Date | moment.Moment;

    start = moment(targetDate).startOf('day');
    end = moment(targetDate).endOf('day');

    return{
      freeword: this.freeword,
      date_from: start.toISOString(),
      date_to: end.toISOString(),
      schedule_type: 3
    }
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
  * 初期化 
  */
  initial(): void {
    this.periodSearchCondition = {
      start: {date: "", time: {view: "", hour: 0, minute: 0}},
      end: {date: "", time: {view: "", hour: 0, minute: 0}},
    };
    this.usageDate = "";
  }

  /**
   * 期間検索検索パラメータを作成
   * @param startDateTime 
   * @param endDateTime 
   * @returns 
   */
  createPeriodSearchParams(startDateTime: moment.Moment, endDateTime: moment.Moment): any {
    return{
      freeword: this.freeword,
      date_from: startDateTime.toISOString(),
      date_to: endDateTime.toISOString(),
      schedule_type: 1
    }
  }
  
  /**
   * デフォルトの検索フォームを表示
   */
  private showDefaultSearchForm(): void {
    this.showSearchForm = true;
  }
  
  /** 
   * searchTypeに応じた検索フォームを表示
   */
  private handleDateSearchType(): void {
    switch (this.searchType) {
      // 期間検索窓表示
      case 'period':
        this.showPeriodSearchForm = true;
        const periodParam: parameter.PeriodSearchExpService = {
            freeword: this.freeword,
            date_from: null,
            date_to: null,
            schedule_type: 1,
          };
        this.getServiceListByPeriod(periodParam);
        break;
      // 利用日窓検索表示
      case 'usage_date':
        this.showUsageDateSearchForm = true;
        const usageDateParam: parameter.PeriodSearchExpService = {
          freeword: this.freeword,
          date_from: null,
          date_to: null,
          schedule_type: 3,
        };
        this.getServiceListByPeriod(usageDateParam);
        break;
      // smartGOTO内の遷移の場合undefinedを受け取る
      // 外部リンクからの遷移の場合nullを受け取る
      case undefined:
      case null:
        this.showDefaultSearchForm();
        this.getServiceList(this.freeword);
        break;
      // date_search_typeが''とperiodとusage_date以外の場合
      default:
        this.showSearchForm = false;
        this.showUsageDateSearchForm = false;
        this.showPeriodSearchForm = false;
        this.showBackButton = false;
        break;
    }
  }

  /**
   * 日付と時間のチェック
   * 出発日OR時間OR返却日OR時間のみ選択し、リソースクリックしたケースを排除する
   */
  checkDateTimeInput(): boolean {
    if (this.periodSearchCondition.start.date == '' || this.periodSearchCondition.end.date == '' || this.periodSearchCondition.start.time.view == '' || this.periodSearchCondition.end.time.view == '') {
      return false;
    }
    return true;
  }

  /**
   * 開始日と時間のチェック
   */
  checkStartDateTime(){
    return this.isNotSelectedPeriodDateTime('start');
  }

  /**
   * 終了日と時間のチェック
   */
  checkEndDateTime(){
    return this.isNotSelectedPeriodDateTime('end');
  }

  /**
   * 選択状態を確認 
   * @param period 'start' or 'end'
   * @returns Boolean
   */
  isNotSelectedPeriodDateTime(period: 'start' | 'end'): boolean {
    return this.periodSearchCondition[period].date && this.periodSearchCondition[period].time.view ? false : true;
  }

  /**
   * 返却日時のみ指定されている場合の開始日時のセット
   */
  setStartDateTime(): void {
    let today = moment();
    this.periodSearchCondition.start.date = today.toISOString();
    this.periodSearchCondition.start.time = {
      view: today.format('HH:mm'),
      hour: today.hour(),
      minute: today.minute()
    }
  }

    /**
   * Moment.jsの日時オブジェクトを作成
   * @param date 日付
   * @param hour 時
   * @param minute 分
   * @returns Moment.jsの日時オブジェクト
   */
  private createDateTime(date: string, hour: number, minute: number): moment.Moment {
    return moment(date).set({'hour': hour, 'minute': minute});
  }

  /**
   * 出発日時と返却日時のバリデーション
   * 
   * @param {moment.Moment} startDateTime - 出発日時
   * @param {moment.Moment} endDateTime - 返却日時
   * @returns {boolean} - バリデーション結果（有効な期間の場合はtrue）
   */
  private isValidPeriod(startDateTime: moment.Moment, endDateTime: moment.Moment): boolean {
    const startTime = startDateTime.valueOf();
    const endTime = endDateTime.valueOf();

    // 出発日時と返却日時が同じ場合
    if (this.isSameDateTime(startTime, endTime)) {
      this.displayErrorMessage(this.msg.CLIENT.SEARCH.E_DATE_SAME_ERROR.message());
      return false;
    }

    // 出発日時が返却日時より後の場合
    if (this.isEndTimeBeforeStartTime(startTime, endTime)) {
      this.displayErrorMessage(this.msg.CLIENT.SEARCH.E_RETURN_DATE_ERROR.message());
      return false;
    }

    // 期間が7日を超える場合
    if (this.isPeriodExceedingLimit(startTime, endTime, 7)) {
      this.displayErrorMessage(this.msg.CLIENT.SEARCH.E_PERIOD_ERROR.message());
      return false;
    }

    return true;
  }

  /**
   * 出発日時と返却日時が同じか確認
   */
  private isSameDateTime(startTime: number, endTime: number): boolean {
    return endTime === startTime;
  }

  /**
   * 出発日時が返却日時より後か確認
   */
  private isEndTimeBeforeStartTime(startTime: number, endTime: number): boolean {
    return endTime < startTime;
  }

  /**
   * 期間が指定日数を超えているか確認
   */
  private isPeriodExceedingLimit(startTime: number, endTime: number, dayLimit: number): boolean {
    const daysDifference = (endTime - startTime) / (1000 * 60 * 60 * 24);
    return daysDifference > dayLimit;
  }

  /**
   * エラーメッセージを表示
   * 
   * @param {string} message - エラーメッセージ
   */
  private displayErrorMessage(message: string): void {
    this.appMsgServ.viewDialogMessage(message);
  }

  /**
   * リスト表示用データ作成
   *
   * @memberof ExpSearchServiceListComponent
   */
  private createDispList(): void {
    this.serviceList.forEach(service => {

      const priceInfo: GetPrice = {
        price_rules: this.expServ.getTargetPrice(service.prices, moment()).price_rules,
        params: {
          number: 1,
          // 選択可能な最小時間
          util_time: this.expServ.getMinimumTime(service)
        },
        type: this.expServ.getDefaultType(service.user_params.numbers)
      };

      this.listParts.push(
        {
          mode: 'tappable',
          tappable: {
            item: [
              { text_string: service.title },
              {
                text_number: this.expServ.getPrice(priceInfo),
                option_text_before: '￥',
                option_text_after: '～'
              },
              { text_string: '提供：' + service.shop.name }
            ],
            key: service.sg_service_id,
            icon: service.images.length > 0 ? service.images[0] : CommonFunctionModule.getAssetsUrl("/image/common/49-Noimage.png")
          },
        }
      );
    })
  }

  /**
   * サービス一覧更新
   *
   * @private
   * @memberof ExpSearchServiceListComponent
   */
  private updateService(): void {
    this.getServiceList(this.freeword);
  }

  /**
   * 条件に基づいて検索ボタンの活性・非活性状態を更新
   */
  private updateSearchButtonState(): void {
    const isStartEmpty = !this.periodSearchCondition.start.date || !this.periodSearchCondition.start.time.view;
    const isEndEmpty = !this.periodSearchCondition.end.date || !this.periodSearchCondition.end.time.view;

    if (isStartEmpty && isEndEmpty) {
      this.isSearchButtonDisabled = true;
    } else if (isStartEmpty && !isEndEmpty) {
      this.isSearchButtonDisabled = false; // 返却日時のみ指定がある場合
    } else if (!isStartEmpty && isEndEmpty) {
      this.isSearchButtonDisabled = true;
    } else {
      this.isSearchButtonDisabled = false;
    }
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * 入力したフリーワードに該当するサービス一覧取得
   *
   * @param {string} freeword
   * @memberof ExpSearchServiceListComponent
   */
  getServiceList(freeword: string): void {
    // クエリ作成
    const param: parameter.ExpService = { freeword: freeword };

    // フリーワードに該当するサービス取得
    this.busy = this.expServ.getServiceList(param).subscribe({
      next: res => {
        // 表示リストを初期化
        this.listParts = [];
        this.serviceList = [];

        this.serviceList = res.body;

        // 該当するサービスが0件の場合
        if (this.serviceList.length == 0) {
          this.listParts = undefined;
          this.isSearchResultEmpty = true;
          this.showBackButton = false;
          return;
        }
        else {
          // リスト表示用データ作成
          this.createDispList();
        }
      },
      error: this.errResServ.doParse((_err, errContent) => this.errResServ.viewErrDialog(errContent))
    })
  }

  /**
   * 期間指定検索 
   */
  getServiceListByPeriod(params: any): void {
    //表示リストを初期化
    this.listParts = [];
    this.serviceList = [];

    // クエリ作成
    const param: parameter.PeriodSearchExpService = {
      freeword: params.freeword,
      date_from: params.date_from,
      date_to: params.date_to,
      schedule_type: params.schedule_type,
    };

    // フリーワードに該当するサービス取得
    this.busy = this.expServ.getServiceList(param).subscribe({
      next: res => {
        this.serviceList = res.body;
        // 該当するサービスが0件の場合
        if (this.serviceList.length == 0) {
          this.listParts = undefined;
          if(this.initialFlag){
            this.isSearchResultEmpty = true;
            this.showBackButton = false;
          }
          return;
        }
        else {
          // リスト表示用データ作成
          this.createDispList();
        }
      },
      error: this.errResServ.doParse((_err, errContent) => this.errResServ.viewErrDialog(errContent))
    })
  }
}

export interface PeriodSearchCondition{
  start?: {
    date: string;
    time: {
      view: string;
      hour: number | null;
      minute: number | null;
    };
  };

  end?: {
    date: string;
    time: {
      view: string;
      hour: number | null;
      minute: number | null;
    };
  }
}