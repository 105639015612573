<style>
  button {
    color: var(--color-white);
    background-color: var(--color-main);
  }
</style>

<div mat-dialog-content>
  <div class="button-wrap">
    <button class="button button--large--cta" (click)="onReservChange()">予約変更</button>
    <span class="br"></span>
    <button class="button button--large--cta" (click)="onReservCancel()">予約キャンセル</button>
  </div>
</div>