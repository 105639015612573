//=============================================================================================
// import
//=============================================================================================
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Params, OnsNavigator } from 'ngx-onsenui';
import * as dayjs from 'dayjs';

// service
import { ApplicationMessageService } from '../../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../../lib-services/http-error-response-parser.service';
import { CommonFunctionModule } from "../../../../lib-modules/common-function.module";
import { ExpWebApiService, ReservationOption } from '../../../../http-services/exp-web-api.service';

// component
import { HistoryListComponent } from '../../history-list/history-list.component';

// parts
import { ListParts } from "../../../parts/ons-list/ons-list.component";

// interface
import { parameter } from '../../../../interfaces/parameter';
import { ExpReservation, ExpFetchReservation } from '../../../../interfaces/response';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 観光サービスキャンセル画面。
 *
 * @export
 * @class ExpCancelComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ons-page[exp-cancel]',
  templateUrl: './exp-cancel.component.html',
  styleUrls: ['./exp-cancel.component.scss']
})
export class ExpCancelComponent implements OnInit, OnDestroy {

  /**
   * 通信監視用Subscription
   *
   * @type {Subscription}
   * @memberof ExpCancelComponent
   */
  busy: Subscription;

  /**
   * onslist parts
   *
   * @type {{
   *     header: ListParts;
   *     body: ListParts[];
   *   }}
   * @memberof ExpCancelComponent
   */
  template: {

    /**
     * ヘッダー
     *
     * @type {ListParts}
     */
    header: ListParts;

    /**
     * 本文
     *
     * @type {ListParts[]}
     */
    body: ListParts[];
  };

  /**
   * 観光履歴・予定
   *
   * @type {ExpReservation}
   * @memberof ExpCancelComponent
   */
  reserv: ExpReservation;

  /**
   * サーバに注文確定リクエスト実行中かどうか
   *
   * @type {boolean}
   * @memberof ExpCancelComponent
   */
  isRequesting: boolean = false;

  /**
   * 体験サービス予約変更
   * 管理クラス
   *
   * @type {ReservationOption}
   * @memberof ExpCancelComponent
   */
  reservationOption: ReservationOption;

  /**
   * 
   *
   * @type {ExpFetchReservation}
   * @memberof ExpCancelComponent
   */
  fetch: ExpFetchReservation;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of ExpCancelComponent.
   * @param {ApplicationMessageService} appMsgServ
   * @param {HttpErrorResponseParserService} errResServ
   * @param {CommonFunctionModule} commonFunc
   * @param {ExpWebApiService} expServ
   * @param {OnsNavigator} navigator
   * @param {Params} params
   * @memberof ExpCancelComponent
   */
  constructor(
    private appMsgServ: ApplicationMessageService, 
    private errResServ: HttpErrorResponseParserService, 
    private commonFunc: CommonFunctionModule, 
    private expServ: ExpWebApiService, 
    private navigator: OnsNavigator, 
    private params: Params,
    private msg: MESSAGE,
  ) { }

  /**
   * 初期処理。
   *
   * @memberof ExpCancelComponent
   */
  ngOnInit(): void {

    this.reserv = this.commonFunc.deepcopy(this.params.data.reserv);

    this.fetch = this.commonFunc.deepcopy(this.params.data.fetch);

    this.reserv.numbers.forEach(n => n.value = 0);
    
    // テンプレート情報作成
    this.createTemplateDate();
  }

  /**
   * 破棄処理。
   *
   * @memberof ExpCancelComponent
   */
  ngOnDestroy(): void {
    
    this.busy?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * キャンセル実行ボタンタップ時のイベントハンドラ。
   *
   * @memberof ExpCancelComponent
   */
  onCancel(): void {

    this.deleteExpRserv();
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * テンプレートに表示する情報を作成する。
   *
   * @private
   * @memberof ExpCancelComponent
   */
  private createTemplateDate(): void {
    // お支払い料金欄のデータ
    let paymentRow: ListParts['payment_amount']['row'] = [];

    this.fetch.tickets.forEach(ticket => {
      paymentRow.push({
        item: ticket.subject,
        type: ticket.title,
        count: ticket.number,
        amount: ticket.amount,
        unit: ticket.unit
      });
    });
    
    this.template = {
      header: {
        mode: "header", 
        header_list: {
          item: [this.reserv.service.title, "提供：" + this.reserv.service.shop.name],
          icon: this.reserv.service.images[0] ?? ""
        }
      }, 
      body: [{
        id: "amount", 
        mode: 'paymentAmount',
        header: 'お支払い料金',
        payment_amount: {
          row: paymentRow,
          payer_name: this.reserv.payer.name
        }
      }, {
        id: "cancelRule", 
        header: 'キャンセル料ルール',
        common: { item: this.expServ.getDispCancelRules(this.reserv.service.prices[0].cancel_rules) }
      }, {
        header: '店舗連絡先',
        common: { item: this.reserv.service.shop.tel }
      }]
    };
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * 予約をキャンセルする。
   *
   * @private
   * @memberof ExpCancelComponent
   */
  private deleteExpRserv(): void {

    // 2重送信対策
    if (this.isRequesting) {
      // console.log("2重クリックのためリクエストロック中");
      return;
    }
    this.isRequesting = true;

    // parameter
    const params: parameter.ExpReservation = {
      sg_reservation_id : this.reserv.sg_reservation_id, 
    };

    /**
     * 予定・履歴トップへ遷移する。
     *
     */
    const transHistoryTop = () => {
      this.navigator.element.resetToPage(HistoryListComponent, {
        animation: 'fade-ios',
        data: { selectedDate: dayjs(this.reserv.start.schd_time).format('YYYY-MM-DD') }
      });
    };

    // request
    this.busy = this.expServ.deleteReservation(this.reserv.sg_reservation_id, this.fetch.sg_reservation_plan_id).subscribe({ 
      next: () => {
        this.isRequesting = false;
        this.appMsgServ.viewDialogMessage(this.msg.CLIENT.EXP.COMPLATE_CANCEL.message(), () => {
          // メール遷移の場合
          if (this.params.data.transition != null) this.params.data.transition();
          else transHistoryTop();
        });
      }, 
      error: this.errResServ.doParse((_err, errContent) => {
  
        this.isRequesting = false;

        switch (errContent.smartGotoErrCode) {
          // 予約プラン有効期限(fetchセッション)が切れた場合、個別メッセージ
          //  予定履歴詳細画面に戻る
          case this.appMsgServ.SERV_CONST_CODE.SG.NOT_EXIST_PLAN_ID:
            this.appMsgServ.viewDialogMessage(this.msg.CLIENT.EXP.NOT_EXIST_PLAN_ID_CANCEL.message(), () => {
              this.navigator.element.popPage();
            });
            break;
          // GMOエラー
          case this.appMsgServ.SERV_CONST_CODE.PURCHASE.GMO_TRAN_VOID:
            this.errResServ.viewErrDialog(errContent, "CANCEL");
            break;
          // キャンセル不可または、何かしらのエラーが発生
          // 一覧へ
          default:
            this.errResServ.viewErrDialog(errContent).then(() => {
              if (errContent.smartGotoErrCode == this.appMsgServ.SERV_CONST_CODE.EXP.DIFFERENT_CANCEL_RULE) {
                this.navigator.element.popPage();
              }
              else transHistoryTop();
            });
        }
      })
    });
  }
}
