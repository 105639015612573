//=============================================================================================
// import
//=============================================================================================
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Params, OnsNavigator } from 'ngx-onsenui';
import * as dayjs from 'dayjs';

// service
import { ApplicationMessageService } from '../../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../../lib-services/http-error-response-parser.service';
import { CommonFunctionModule } from "../../../../lib-modules/common-function.module";
import { ExpWebApiService, UserSelectOption } from '../../../../http-services/exp-web-api.service';

// component
import { HistoryListComponent } from '../../history-list/history-list.component';

// parts
import { ListParts } from "../../../parts/ons-list/ons-list.component";

// interface
import { request } from '../../../../interfaces/request';
import { ExpFetchReservation, ExpReservation } from '../../../../interfaces/response';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 観光サービス予約変更確認画面。
 *
 * @export
 * @class ExpChangeDatermineComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ons-page[exp-change-datermine]',
  templateUrl: './exp-change-datermine.component.html',
  styleUrls: ['./exp-change-datermine.component.scss']
})
export class ExpChangeDatermineComponent implements OnInit, OnDestroy {

  readonly ASSETS = {
    INFORMATION: CommonFunctionModule.getAssetsUrl('/image/button/info.png')
  } as const;

  /**
   * 通信監視用Subscription
   *
   * @type {Subscription}
   * @memberof ExpChangeDatermineComponent
   */
  busy: Subscription;

  /**
   * onslist parts
   *
   * @type {{
   *     header: ListParts;
   *     body: ListParts[];
   *   }}
   * @memberof ExpChangeDatermineComponent
   */
  template: {

    /**
     * ヘッダー
     *
     * @type {ListParts}
     */
    header: ListParts;

    /**
     * 本文1
     *
     * @type {ListParts[]}
     */
    body1: ListParts[];

    /**
     * 本文2
     *
     * @type {ListParts[]}
     */
    body2: ListParts[];

    /**
     * オプション
     *
     * @type {ExpReservation["service"]["option_groups"]}
     */
    optionGroup: ExpReservation["service"]["option_groups"];
  };

  /**
   * 観光履歴・予定
   *
   * @type {ExpReservation}
   * @memberof ExpChangeDatermineComponent
   */
  reservation: ExpReservation;

  /**
   * 料金計算日時
   *
   * @type {Date}
   * @memberof ExpChangeDatermineComponent
   */
  // recalc_date: Date;

  /**
   * サーバに注文確定リクエスト実行中かどうか
   *
   * @type {boolean}
   * @memberof ExpChangeDatermineComponent
   */
  isRequesting: boolean = false;

  /**
   * fetchレスポンス
   * 
   * @type {ExpFetchReservation}
   * @memberof ExpChangeDatermineComponent
   */
  fetch: ExpFetchReservation;

  /**
   * ユーザー選択状態
   *
   * @type {{ number: { type: number, value:number }[], option: {[option_id: string]: UserSelectOption} }}
   * @memberof ExpChangeDatermineComponent
   */
  selected: { number: { type: number, value:number }[], option: {[option_id: string]: UserSelectOption} };

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of ExpChangeDatermineComponent.
   * @param {ApplicationMessageService} appMsgServ
   * @param {HttpErrorResponseParserService} errResServ
   * @param {CommonFunctionModule} commonFunc
   * @param {ExpWebApiService} expServ
   * @param {OnsNavigator} navigator
   * @param {Params} params
   * @memberof ExpChangeDatermineComponent
   */
  constructor(
    private appMsgServ: ApplicationMessageService, 
    private errResServ: HttpErrorResponseParserService, 
    private commonFunc: CommonFunctionModule, 
    private expServ: ExpWebApiService, 
    private navigator: OnsNavigator, 
    private params: Params,
    private msg: MESSAGE,
  ) { }

  /**
   * 初期処理。
   *
   * @memberof ExpChangeDatermineComponent
   */
  ngOnInit(): void {

    this.reservation = this.commonFunc.deepcopy(this.params.data.reservation);

    this.fetch = this.commonFunc.deepcopy(this.params.data.fetch);
    
    this.selected = this.params.data.selected;

    // テンプレート情報作成
    this.createTemplateDate();
  }

  /**
   * 破棄処理。
   *
   * @memberof ExpChangeDatermineComponent
   */
  ngOnDestroy(): void {
    
    this.busy?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 変更ボタン押下時のイベントハンドラ。
   *
   * @memberof ExpChangeDatermineComponent
   */
  onChange(): void {

    this.putReservChange();
  }

  /**
   * オプション詳細ダイアログ
   *
   * @param {ExpService["option_groups"][number]["options"][number]} option
   * @memberof ExpChangeDatermineComponent
   */
  onClickOptionInfo(option: ExpReservation["service"]["option_groups"][number]["options"][number]): void {
    this.expServ.openOptionDialog(option);
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * テンプレートに表示する情報を作成する。
   *
   * @private
   * @memberof ExpChangeDatermineComponent
   */
  private createTemplateDate(): void {

    // お支払い料金欄のデータ
    let paymentRow: ListParts['payment_amount']['row'] = [];

    this.fetch.tickets.forEach(ticket => {
      paymentRow.push({
        item: ticket.subject,
        type: ticket.title,
        count: ticket.number,
        amount: ticket.amount,
        unit: ticket.unit
      });
    });

    this.template = {
      header: {
        mode: 'header',
        header_list: {
          item: [
            this.reservation.service.title,
            "提供：" + this.reservation.service.shop.name
          ],
          icon: this.reservation.service.images[0] ?? ""
        }
      }, 
      body1: [{
        displayOrder: 1, 
        id: "amount", 
        mode: 'paymentAmount',
        header: 'お支払い料金',
        payment_amount: {
          row: paymentRow,
          payer_name: this.reservation.payer.name
        }
      }, {
        displayOrder: 4, 
        header: '利用開始時刻',
        common: { item: dayjs(this.reservation.start.schd_time).format("M月D日 H:mm") }
      }, {
        displayOrder: 6, 
        header: '利用終了時刻',
        common: { item: dayjs(this.reservation.end.schd_time).format("M月D日 H:mm") }
      }],
      body2: [{
        id: "cancelRule", 
        header: 'キャンセル料ルール',
        common: { item: this.expServ.getDispCancelRules(this.reservation.service.prices[0].cancel_rules) }
      }, { 
        header: '店舗連絡先',
        common: { item: this.reservation.service.shop.tel }
      }],
      optionGroup: []
    }

    // 予約数
    let view: string = "";

    // view = this.reservation.numbers.map(n => n.value).reduce((a, b) => a + b) + this.reservation.numbers[0].unit;
    view = this.selected.number.map(n => n.value).reduce((a, b) => a + b) + this.reservation.numbers[0].unit;

    // 利用者の種別が複数あるなら
    if (this.reservation.numbers.length >= 2) {
      view += "（";

      // 内訳を作成（0人は表示しない）
      this.reservation.numbers.forEach(n => {
        const value: number = this.selected.number.find(num => num.type === n.type).value;

        if (value !== 0) {
          view += n.name + "：" + value + n.unit + "、";
        }
      });
      
      // 末尾のカンマを削除
      view = view.slice(0 , -1);
      view += "）";
    }

    this.template.body1.push({
      displayOrder: 2, 
      header: '予約数',
      common: { item: view }
    });

    // サービス種別によって表示内容を切り替え
    if (this.reservation.service.service_type === "MOBILITY") {
      this.template.body1.push({
        displayOrder: 3, 
        header: '貸出場所',
        common: {
          item: this.reservation.start.place.name,
          link: this.commonFunc.getGoogleMapUrl(this.reservation.start.place.location)
        }
      }, {
        displayOrder: 5, 
        header: '返却場所',
        common: {
          item: this.reservation.end.place.name,
          link: this.commonFunc.getGoogleMapUrl(this.reservation.end.place.location)
        }
      });
     }
     else if (this.reservation.service.service_type === "ACTIVITY") {
      this.template.body1.push({
        displayOrder: 3, 
        header: '開催場所',
        common: {
          item: this.reservation.start.place.name,
          link: this.commonFunc.getGoogleMapUrl(this.reservation.start.place.location)
        }
      });
    }
    else ;
    
    // 表示順にソート
    this.template.body1.sort((a, b) => a.displayOrder - b.displayOrder);
  
    if (this.reservation.service.option_groups) {
      this.template.optionGroup = this.reservation.service.option_groups.filter(group => {
        group.options.forEach(option => {
          let result: boolean = true;
          switch(option.user_option) {
            case 'yesno':
              if (this.selected.option[option.sg_option_id].yesno_param.value === false) result = false; 
              break;
            case 'select':
              if (this.selected.option[option.sg_option_id].select_param.value === void 0) result = false;
              break;
            case 'number':
              if (this.selected.option[option.sg_option_id].number_param.selected === 0) result = false;
              break;
          }
          option.isDisplay = result;
        });
        return group.options.some(option => option.isDisplay === true) ? true : false;
      })
    }
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * 予約を変更する。
   *
   * @private
   * @memberof ExpChangeDatermineComponent
   */
  private putReservChange(): void {

    // 2重送信対策
    if (this.isRequesting) {
      // console.log("2重クリックのためリクエストロック中");
      return;
    }
    this.isRequesting = true;
    
    let numbers: request.ExpReservation["numbers"] = [];
    this.reservation.numbers.forEach(n => {
      numbers.push({ type: n.type, value: n.value });
    });

    // if (this.reservation.service.service_type === "MOBILITY") {
    //   numbers.push({ type: 0, value: this.params.data.reserv_number[0] });
    // }
    // else if (this.reservation.service.service_type === "ACTIVITY") {
    //   // 予約数(mobilityの場合、配列のlengthが1のみ)
    //   const reserv_number: number = this.params.data.reserv_number;
    //   // activityの場合は、reserv_numberが配列でくるため別途処理が必要
    //   // typeも利用者の種別ごとに設定が必要
    // }

    // request body
    const params: request.ExpReservation = {
      sg_reservation_id: this.reservation.sg_reservation_id, 
      numbers: numbers, 
      as: 'user', 
      // recalc_date: this.recalc_date.toISOString()
    };

    /**
     * 予定・履歴トップへ遷移する。
     *
     */
    const transHistoryTop = () => {
      this.navigator.element.resetToPage(HistoryListComponent, {
          animation: 'fade-ios',
          data: { selectedDate: dayjs(this.reservation.start.schd_time).format('YYYY-MM-DD') }
        });
    };

    // request
    this.busy = this.expServ.putReservation(this.reservation.sg_reservation_id, this.fetch.sg_reservation_plan_id).subscribe({ 
      next: () => {
        
        this.isRequesting = false;
        this.appMsgServ.viewDialogMessage(this.msg.CLIENT.EXP.COMPLATE_CHANGE.message(), () => {
          // メール遷移の場合
          if (this.params.data.transition != null) this.params.data.transition();
          else transHistoryTop();
        });
      }, 
      error: this.errResServ.doParse((_err, errContent) => {
        
        this.isRequesting = false;
        switch (errContent.smartGotoErrCode) {
          // 予約プラン有効期限(fetchセッション)が切れた場合、個別メッセージ
          //  予定履歴詳細画面に戻る
          case this.appMsgServ.SERV_CONST_CODE.SG.NOT_EXIST_PLAN_ID:
            this.appMsgServ.viewDialogMessage(this.msg.CLIENT.EXP.NOT_EXIST_PLAN_ID_CHANGE.message(), () => {
              this.navigator.element.popPage();
            });
            break;

          // を過ぎた
          case this.appMsgServ.SERV_CONST_CODE.EXP.RESERVE_LIMIT:
            this.appMsgServ.viewDialogMessage(this.msg.CLIENT.EXP.RESERVE_LIMIT_CHANGE.message(), () => {
              transHistoryTop();
            });
            break;
          // GMOエラー
          // NOTE: サーバーエラー(オプション含め)から判別することができないため、クライアントメッセージとしている
          case this.appMsgServ.SERV_CONST_CODE.PURCHASE.GMO_TRAN_CHANGE:
            this.errResServ.viewErrDialog(errContent, "CHANGE");
            break;
          // 予約変更不可または、何かしらのエラーが発生
          // 一覧へ
          default:
            this.errResServ.viewErrDialog(errContent).then(() => {

              if (errContent.smartGotoErrCode == this.appMsgServ.SERV_CONST_CODE.EXP.DIFFERENT_CANCEL_RULE) {
                this.navigator.element.popPage();
              }
              else {
                transHistoryTop();
              }
            });
        }
      })
    });
  }
}
