/**
 * 基本的に、諸元値は、サーバー(/settings)から取得
 * 
 * 初期ロードに必要なデータのみ、このファイルに定義
 * 通信を待機した後、すべての処理を開始することになるため、初期ロードが遅くなる。
 * 
 * また、onsenUi,ons-tabタグは、生成時点で数、内容を決めておかないと、正常に表示されない。
 */

const settings: Settings = {
  
  shinkamigoto: {
    service: ['dispatch', 'news', 'shopping', 'exp-service'],
    municipal_id: "shinkamigoto"  
  },
  minamisanriku: {
    service: ['dispatch', 'news'],
    municipal_id: "minamisanriku"
  },
  miyakojima: {
    service: ['exp-service'],
    municipal_id: 'miyakojima'
  }
}

// エリアコード、自治体IDに対するuseListタブの並び順
// NOTE: エリアが追加された際にはここに追加。
export const municipalities: Municipalities = {
  'shinkamigoto': settings.shinkamigoto,
  '42411': settings.shinkamigoto,
  'minamisanriku': settings.minamisanriku,
  '046060': settings.minamisanriku,
  'miyakojima': settings.miyakojima,
  '472140': settings.miyakojima
}

interface Settings {
  [area: string]: {
    service: ('dispatch'|'news'|'shopping'|'exp-service')[],
    municipal_id: string
  }
};

interface Municipalities {
  [areacode: string]: Settings['param']
}
