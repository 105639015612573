<div [ngBusy]="busy"></div>
<div class="content">

  <!-- 検索フォーム -->
  <div class="search-area">
    <div class="search-form">
      <mat-icon>search</mat-icon>
      <input matInput #autoCompleteInput id="newsHistoryInput" type="text" placeholder="スペース区切りで複数検索"
            [(ngModel)]="freeword"
            (change)="onSearch()"
            (click)="onSearchFocus()"
            (blur)="onSearchBlur()"
            [matAutocomplete]="auto">

      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSearchHistorySelect($event)">
        <div class="history__right">
          <button class="history__delete" mat-flat-button (click)="onDeleteSearchHistory($event, -1, '', true)">
            すべて削除
          </button>
        </div>
        <mat-option *ngFor="let history of searchHistory; index as i;" [value]="history">
          <div class="history__left">
            <mat-icon class="search-icon">search</mat-icon>{{ history }}
          </div>
          <div class="history__right">
            <button mat-icon-button (click)="onDeleteSearchHistory($event, i, history)">
              <mat-icon class="search-icon">clear</mat-icon>
            </button>
          </div>
        </mat-option>
      </mat-autocomplete>
    </div>
  </div>

  <!-- カテゴリ一覧 -->
  <div class="list-item__caption">ニュースカテゴリ一覧</div>
  <section class="category-area">
    <ng-container *ngFor="let cate of categories">
      <!-- 記事があるカテゴリのみ表示 -->
      <!-- <ng-container *ngIf="cate.count > 0"> -->
        <!-- カテゴリ単体 -->
        <article (click)="onSelected(cate)" class="badge-container">
          <!-- アイコン -->
        <span *ngIf="cate.unread_count > 0" class="notification">{{ cate.unread_count }}</span>
          <div class="image-area">
            <div class="image-box">
              <img src="{{ cate.image ? cate.image : ASSETS_NO_IMAGE }}">
            </div>
        </div>
          <!-- カテゴリ名 -->
          <p>{{ cate.name }}</p>
        </article>
      <!-- </ng-container> -->
    </ng-container>
  </section>

  <div class="br"></div>

  <!-- template -->
  <!-- 記事がない -->
  <ng-container *ngIf="categories === undefined">
    <div class="text--center">
      <p>現在、配信されたニュースはありません。<br>配信までしばらくお待ちください。</p>
      <parts-image class="complete-wrap__img" url="/image/character/out-of-service.png"></parts-image>
    </div>
  </ng-container>

  <!-- 気象・防災関連情報 -->
  <ng-container *ngIf="disasterSiteList && disasterSiteList.length">
    <div class="centered-container">
      <button class="disaster-info-button" (click)="viewDisasterList()">気象・防災関連情報</button>
    </div>
  </ng-container>

  <!-- 免責事項 -->
  <div class="content--description">
    免責事項は、<ons-button modifier="quiet" (click)="viewTermsOfService()" class="button button-quiet"><span class="button-text">利用規約</span></ons-button>をご確認ください。
  </div>

</div>
