//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnDestroy, OnInit } from '@angular/core';
import { OnsNavigator, Params } from 'ngx-onsenui';
import { Subscription } from 'rxjs';
import * as CONST from '../../../constants/constant'

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { FamilyWebApiService } from '../../../http-services/family-web-api.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';

// component
import { FamilyDescriptionComponent } from '../family-description/family-description.component';

// interface
import { Relation } from '../../../interfaces/response';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 管理者承認画面
 *
 * @export
 * @class FamilyInviteApprovalComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @author （株）オブジェクトデータ yoshida wada
 */
@Component({
  selector: 'ons-page[family-invite-approval]',
  templateUrl: './family-invite-approval.component.html',
  styleUrls: ['./family-invite-approval.component.css']
})
export class FamilyInviteApprovalComponent implements OnInit, OnDestroy
{

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * 管理者承認のSubscriptionオブジェクト
   *
   * @type {Subscription}
   * @memberof FamilyDetailComponent
   */
  m_Busy: Subscription;

  /**
   * 管理者として承認するユーザー
   *
   * @type {Relation}
   * @memberof FamilyInviteApprovalComponent
   */
  m_Relation: Relation;

  /**
   * 削除モード
   *    family　：ファミリーリンク
   *    account ：初回ログイン（代理アカウント機能）
   *
   * @type {('family' | 'login')}
   * @memberof FamilyDeleteComponent
   */
  m_ConfirmMode: 'family' | 'login';

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of FamilyInviteApprovalComponent.
   * @param {OnsNavigator} navigator
   * @param {ApplicationMessageService} appMsg
   * @param {HttpErrorResponseParserService} httpErrorResponseParserService
   * @param {Params} params
   * @memberof FamilyInviteApprovalComponent
   */
  constructor(
    private navigator: OnsNavigator,
    private appMsg: ApplicationMessageService, 
    private httpErrorResponseParserService: HttpErrorResponseParserService,
    private familyWebApiService: FamilyWebApiService, 
    private params: Params,
    private msg: MESSAGE,
  ) 
  {}

  /**
   * 初期化処理
   *
   * @memberof FamilyInviteApprovalComponent
   */
  ngOnInit(): void 
  {
    //FamilyComponentからのデータ受け取り
    this.m_Relation = this.params.data.relation;
    this.m_ConfirmMode = this.params.data.confirmMode;
  }

  /**
   * HTMLのデータ処理
   *
   * @memberof FamilyInviteApprovalComponent
   */
  ngAfterViewInit(): void
  {}

  /**
   * 破棄処理
   *
   * @memberof FamilyDetailComponent
   */
  ngOnDestroy(): void 
  {
    // 廃棄
    this.m_Busy?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 許可ボタン押下時のイベントハンドラ。
   *
   * @memberof FamilyInviteApprovalComponent
   */
  approval(): void
  {
    // 許可処理
    // ファミリー招待の承認
    if (this.m_ConfirmMode === 'family') this.relationReply();
    // 代理アカウント初回ログインの承認
    else if (this.m_ConfirmMode === 'login') this.loginApproval();
  }

  /**
   * 却下/中止ボタン押下時のイベントハンドラ。
   *
   * @memberof FamilyInviteApprovalComponent
   */
  reject(): void
  {
    // ファミリー招待の却下
    if (this.m_ConfirmMode === 'family') 
    {
      this.appMsg.confirmMessage(this.msg.CLIENT.FAMILY.CONFIRM_AGAIN.message(), value => 
      {
        if (Number(value) == CONST.Common.CONFIRM_YES) 
        {
          // 却下/中止処理
          if (this.m_ConfirmMode === 'family') this.relationReject();
        }
      });
    }
    // 代理アカウント初回ログインの却下
    else if (this.m_ConfirmMode === 'login') 
    {
      this.navigator.element.popPage();
    }
  }

  /**
   * ファミリー機能説明を表示する。
   *
   * @memberof FamilyComponent
   */
  viewDescription(): void 
  {
    this.navigator.element.pushPage(FamilyDescriptionComponent);
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * ボタンのラベルを返却する。
   *
   * @param {boolean} accept
   * @return {*}  {string}
   * @memberof FamilyInviteApprovalComponent
   */
  getBtnLabel(accept: boolean): string 
  {
    const btnLabel = [['却下', '許可'], ['中止', '許可']];

    if (accept) // 許可
    {
      if (this.m_ConfirmMode === 'family') return btnLabel[0][1];
      else if (this.m_ConfirmMode === 'login') return btnLabel[1][1];
    }
    else // 却下/中止
    {
      if (this.m_ConfirmMode === 'family') return btnLabel[0][0];
      else if (this.m_ConfirmMode === 'login') return btnLabel[1][0];
    }
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * ファミリーリンクの招待申請を許可する。
   *    ファミリーメンバー機能
   *
   * @private
   * @memberof FamilyInviteApprovalComponent
   */
  private relationReply(): void 
  {
    this.m_Busy = this.familyWebApiService.replyRequest(this.m_Relation.relation_id).subscribe({
        next: () => {
          this.appMsg.viewDialogMessage(this.msg.CLIENT.FAMILY.CONFIRM_OK.message(this.m_Relation.name.family_name + " " + this.m_Relation.name.given_name), () => this.params.data.class.refresh());
        },
        error: this.httpErrorResponseParserService.doParse((_error, customErrorContent) => {
          // note: 許可できなかったということは、想定外 or 別端末などでファミリー情報が更新された為。
          // 許可できなかった場合、一覧画面に戻りファミリー情報更新
          this.httpErrorResponseParserService.viewErrDialog(customErrorContent).then(() => this.params.data.class.refresh());
        })
      }
    );
  }

  /**
   * ファミリーリンクの招待申請を拒否する。
   *    ファミリーメンバー機能
   *
   * @private
   * @memberof FamilyInviteApprovalComponent
   */
  private relationReject(): void {
    this.m_Busy = this.familyWebApiService.deleteRelation(this.m_Relation.relation_id).subscribe({
      next: () => {
        this.appMsg.viewDialogMessage(this.msg.CLIENT.FAMILY.CONFIRM_NG.message(), () => {
          this.params.data.class.refresh();
        });
      },
      error: this.httpErrorResponseParserService.doParse((_error, customErrorContent) => {
        this.httpErrorResponseParserService.viewErrDialog(customErrorContent);
      })
    });
  }

  /**
   * 初回ログインを許可する。
   *
   * @private
   * @memberof FamilyInviteApprovalComponent
   */
  private loginApproval(): void {
    this.m_Busy = this.familyWebApiService.getProxyToken(this.m_Relation.user_id).subscribe({
        next: () => {
          this.appMsg.viewDialogMessage(this.msg.CLIENT.FAMILY.LOGIN_APPROVAL.message(), () => {
            this.params.data.class.getFamily();
            this.navigator.element.popPage();
          });
        },
        error: this.httpErrorResponseParserService.doParse((_error, customErrorContent) => {
          this.httpErrorResponseParserService.viewErrDialog(customErrorContent);
        })
      }
    );
  }
}
