//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit, OnDestroy } from '@angular/core';
import { OnsNavigator, Params } from 'ngx-onsenui';
import { forkJoin, Observable, Subscription } from 'rxjs';
import * as CONST from '../../../constants/constant';

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { UserWebApiService } from '../../../http-services/user-web-api.service';
import { PagerService } from 'src/app/lib-services/pager.service';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';

// component
import { ProfileSignupCompleteComponent } from '../profile-signup-complete/profile-signup-complete.component';

// interface
import { Settings, Term, UserInfo } from '../../../interfaces/response';
import { SigninService } from '../../signin/signin.component';

// module
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * ユーザ登録確認画面。
 *
 * @export
 * @class ProfileSignupDetermineComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ons-page[account-list]',
  templateUrl: './profile-signup-determine.component.html',
  styleUrls: ['./profile-signup-determine.component.css']
})
export class ProfileSignupDetermineComponent implements OnInit, OnDestroy {
  /**
   * HTTP通信購読用
   *
   * @type {Subscription}
   * @memberof ProfileSignupDetermineComponent
   */
  busy: Subscription;

  /**
   * ユーザ情報
   *
   * @type {UserInfo}
   * @memberof ProfileSignupDetermineComponent
   */
  userInfo: UserInfo;
  
  /**
   * 性別
   *
   * @memberof ProfileSignupDetermineComponent
   */
  SEX = [
    { value: 0, label: CONST.Common.SEX_NO_ANSWER },
    { value: 1, label: CONST.Common.SEX_MALE },
    { value: 2, label: CONST.Common.SEX_FEMALE }
  ];

  /**
   * 選択中の性別
   *
   * @type {string}
   * @memberof ProfileSignupDetermineComponent
   */
  selectedSex: string;

  /**
   * STEP状態
   *
   * @type {Boolean}
   * @memberof ProfileSignupDetermineComponent
   */
  viewStep?: Boolean;

  /**
   * 利用規約、セキュリティポリシーチェックコントロールID
   *
   * @type {string[]}
   * @memberof ProfileSignupDetermineComponent
   */
  public terms: string[] = ['term'];
  
  /**
   * 利用規約、セキュリティポリシーチェックコントロール状態
   *
   * @type {string[]}
   * @memberof ProfileSignupDetermineComponent
   */
  public checkedTerms: string[] = [];

  /**
   * 住所緯度経度登録画面で指定した地点のgoogleMapUrl
   *
   * @type {string}
   * @memberof ProfileSignupDetermineComponent
   */
  googleMapUrl: string;

  /**
   * 住民ユーザーかどうか
   *
   * @type {boolean}
   * @memberof ProfileSignupDetermineComponent
   */
  isCitizen: boolean;

  /**
   * 利用規約
   *
   * @type {Term}
   * @memberof ProfileSignupDetermineComponent
   */
  term: Term;

  /**
   * 自治体ごと設定情報
   *
   * @type {Settings}
   * @memberof ProfileSignupDetermineComponent
   */
  setting: Settings;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of ProfileSignupDetermineComponent.
   * @param {OnsNavigator} _navigator
   * @param {ApplicationMessageService} appMsg
   * @param {HttpErrorResponseParserService} httpErrorResponseParserService
   * @param {UserWebApiService} userWebApiService
   * @param {Params} params
   * @memberof ProfileSignupDetermineComponent
   */
  constructor(
    private _navigator: OnsNavigator, 
    private appMsg: ApplicationMessageService, 
    private httpErrorResponseParserService: HttpErrorResponseParserService, 
    private userWebApiService: UserWebApiService, 
    private params: Params,
    private pagerServ: PagerService, 
    private signinServ: SigninService,
    private commonFunctionMdl: CommonFunctionModule,
    private municipalityWebApiServ: MunicipalityWebApiService,
    private msg: MESSAGE,
  ) {
    this.setting = this.municipalityWebApiServ.setting;
  }

  /**
   * 初期化処理。
   *
   * @memberof ProfileSignupDetermineComponent
   */
  ngOnInit(): void {

    this.viewStep = this.params.data.viewStep;
    this.userInfo = this.commonFunctionMdl.deepcopy(this.params.data.userInfo);
    this.isCitizen = this.params.data.citizen;

    // 規約取得
    this.getTerm();

    this.selectedSex = this.SEX.find(e => e.value === Number(this.userInfo.profile.sex)).label;
  
    // 指定した(住所緯度経度登録画面)緯度経度を表示するgoogleMapのリンク作成
    this.googleMapUrl = this.commonFunctionMdl.getGoogleMapUrl(this.userInfo.profile.address.location);
  }

  /**
   * 破棄処理。
   *
   * @memberof ProfileSignupDetermineComponent
   */
  ngOnDestroy(): void {
    this.busy?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 新規会員登録を中止。
   *
   * @memberof ProfileSignupDetermineComponent
   */
  cancel(): void {

    this.appMsg.confirmMessage(this.msg.CLIENT.PROFILE.COMFIRM_CANCEL_REGISTRATION.message(), (value) => {
      // Yes回答だったら登録中止
      if (Number(value) === CONST.Common.CONFIRM_YES) {
        this.busy = this.userWebApiService.signinCancel().subscribe({
          next: () => {},
          error: () => { location.reload(); }
        });

        // 利用するタブから遷移している場合はサインイン画面へ戻る
        if (this.pagerServ.getUserAppNavigator) this.signinServ.trans(true);
      }
    });
  }

  /**
   * ユーザ情報を更新する。
   *
   * @memberof ProfileSignupDetermineComponent
   */
  edit(): void {
    // ユーザー情報更新
    this.userWebApiService.updateProfile(this.userInfo.profile, this.userInfo.relation_id).subscribe({
      next: () => {

        // 規約合意
        this.municipalityWebApiServ.postTermAgreement({term_id: this.term.term_id, user_id: this.userInfo.user_id, is_citizen: this.isCitizen}).subscribe({
          next: () => {
            // 新規登録完了画面へ遷移
            this._navigator.element.pushPage(ProfileSignupCompleteComponent);
          },
          error: this.httpErrorResponseParserService.doParse((_err, errContent) => {
            this.httpErrorResponseParserService.viewErrDialog(errContent);
          })
        });

      },
      error: this.httpErrorResponseParserService.doParse((_err, errContent) => {
        this.httpErrorResponseParserService.viewErrDialog(errContent);
      })
    });
  }

  /**
   * プライバシーポリシーを表示する
   *
   * @memberof ProfileSignupDetermineComponent
   */
  viewPrivacyPolicy(): void {
    // プライバシーポリシーのサイトを表示
    window.open(`/${this.municipalityWebApiServ.setting.municipal_id}/assets/pdf/privacy-policy.pdf`,'_blank');
  }

  /**
   * 利用規約を表示する
   *
   * @memberof ProfileSignupDetermineComponent
   */
  viewUsePolicy(): void {
    // 利用規約ページへ遷移
    window.open(this.term.url, '_blank');
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * 規約取得
   *
   * @memberof ProfileSignupDetermineComponent
   */
  getTerm(): void {
    this.busy = this.municipalityWebApiServ.getTerm().subscribe({
      next: res => {this.term = res.body}
    });
  }
}
