//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit } from '@angular/core';
import { OnsNavigator } from 'ngx-onsenui';

// service
import { AccountService } from '../../account/account-list/account-list.component';
import { UserWebApiService } from '../../../http-services/user-web-api.service';
import { PagerService } from '../../../lib-services/pager.service';
import { SigninService } from '../../signin/signin.component';
import { PaymentMethodListComponent } from '../../payment-method/payment-method-list/payment-method-list.component';
import { GoogleTagManagerService } from '../../../lib-services/google-tag-manager.service';

// module
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * ユーザ登録完了画面。
 *
 * @export
 * @class ProfileSignupCompleteComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'ons-page[account-list]',
  templateUrl: './profile-signup-complete.component.html',
  styleUrls: ['./profile-signup-complete.component.css']
})
export class ProfileSignupCompleteComponent implements OnInit {

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
  * Creates an instance of ProfileSignupCompleteComponent.
  * @param {OnsNavigator} _navigator ページスタックの管理とナビゲーション機能を提供するコンポーネント。
  * @memberof ProfileSignupCompleteComponent
  */
  constructor(
    private _navigator: OnsNavigator,
    private userWebApiServ: UserWebApiService,
    private accountServ: AccountService,
    private pagerServ: PagerService,
    private signinServ: SigninService,
    private gtmServ: GoogleTagManagerService,
  ) { }

  /**
  * コンポーネントが初期化される際に呼び出されるメソッド。
  *
  * @memberof ProfileSignupCompleteComponent
  */
  ngOnInit(): void
  {
    this.gtmServ.pushPageview('/user/regist_complete/', 'ユーザー登録完了');
    this.gtmServ.pushFbqTrack('CompleteRegistration');
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * ホームに戻る。
   *
   * @memberof ProfileSignupCompleteComponent
   */
  returnHome(): void {

    this.userWebApiServ.getLatestUserInfo().subscribe({
      next: () => {
        // 利用するタブから遷移している場合は呼び出し元の画面へ戻る
        if (this.pagerServ.getUserAppNavigator) this.signinServ.trans();
        else this.accountServ.link('signin');
      }
    });
  }

  /**
   * 支払い方法画面へ
   *
   * @memberof ProfileSignupCompleteComponent
   */
  pushPaymentMethod(): void {

    this._navigator.element.pushPage(PaymentMethodListComponent);
  }
}
