//=============================================================================================
// インポート
//=============================================================================================
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import * as qs from 'qs';

import { request } from '../interfaces/request';
import { Relation, UserInfo } from '../interfaces/response';

//=============================================================================================
// Configクラス定義
//=============================================================================================

/**
 * HTTP接続情報
 *
 * @export
 * @class FamilyWebApiServiceConfig
 * @author （株）オブジェクトデータ wada
 */
export class FamilyWebApiServiceConfig 
{
  /**
   * サーバのベースURL
   *
   * @type {string}
   * @memberof FamilyWebApiServiceConfig
   */
  baseUrl: string;

  /**
   * エリアコード
   *
   * @type {string}
   * @memberof FamilyWebApiServiceConfig
   */
  areacode: string;

  /**
   * httpOptions
   *
   * @type {({
   *     headers?: HttpHeaders | {
   *       [header: string]: string | string[];
   *     };
   *   })}
   * @memberof FamilyWebApiServiceConfig
   */
  httpOptions?: 
  {
    headers?: HttpHeaders | 
    {
      [header: string]: string | string[];
    };
  };
}

//=============================================================================================
// Serviceクラス定義
//=============================================================================================

/**
 * WebApiサービス
 *
 * @export
 * @class FamilyWebApiService
 * @author （株）オブジェクトデータ wada
 */
@Injectable({
  providedIn: 'root'
})
export class FamilyWebApiService 
{

  /**
   *最新のファミリー情報を持つサブジェクト
   *
   * @private
   * @memberof FamilyWebApiService
   */
  private FamilyInfoSubject = new BehaviorSubject<Relation[]>(null);

  
  
//=============================================================================================
// コンストラクタ
//=============================================================================================

  /**
   * Creates an instance of FamilyWebApiService.
   * @param {HttpClient} http
   * @param {FamilyWebApiServiceConfig} config
   * @memberof FamilyWebApiService
   */
  constructor(
    private http: HttpClient, 
    private config: FamilyWebApiServiceConfig
  ) {}

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * ファミリー情報が更新された際に流すObservable(tabbarやマイページメニューに表示する通知用)
   *
   * @readonly
   * @type {Observable<Relation[]>}
   * @memberof FamilyWebApiService
   */
  get familyInfoChenged(): Observable<Relation[]>
  {
    return this.FamilyInfoSubject.asObservable();
  }

  /**
   * 最後にサーバーから取得したファミリーユーザー情報を渡す。(配車予約などでメンバー情報を使う場合)
   *
   * @return {Relation[]}
   * @memberof FamilyWebApiService
   */
  getFamilyInfo(): Relation[]
  {
    return this.FamilyInfoSubject.getValue();
  }

  /**
   * 空のファミリー情報を流すObservable(ログアウト時に使用)
   *
   * @return {void}
   * @memberof FamilyWebApiService
   */
  returnFamilyInfo(): void
  {
    return this.FamilyInfoSubject.next(null);
  }

  /**
   * ログインユーザのファミリーロールを取得する。
   *
   * @return {*}  {('child' | 'parent' | undefined)}
   * @memberof FamilyWebApiService
   */
  getLoginUserRole(): 'child' | 'parent' | undefined 
  {
    let ret: 'child' | 'parent' | undefined = undefined;
    
    // ファミリーを取得
    let family = this.getFamilyInfo();
    if (family)
    {
      // ファミリーに管理者/メンバーが混在しない前提
      if (undefined != family.find(f => f.role === 'child')) ret = 'parent';
      else if (undefined != family.find(f => f.role === 'parent')) ret = 'child';
    }

    return ret;
  }

  /**
   * ログインユーザの利用制限有無を取得する。
   *
   * @return {*}  {(boolean | undefined)} true：制限中
   * @memberof FamilyWebApiService
   */
  isLoginUserUtilization(): boolean | undefined 
  {
    if (this.getLoginUserRole() === 'parent') return false;
    if (this.getLoginUserRole() === 'child') 
    {
      if (this.getFamilyInfo()[0]?.limits?.utilization === true) return true;

      return false;
    }

    return undefined;
  }

  /**
   * ファミリー、ユーザーIDから氏名を取得
   *
   * @param {string} user_id
   * @return {*}  {string}
   * @memberof FamilyWebApiService
   */
  getFamilyName(user_id: string): string {
  
    const name = this.getFamilyInfo().find(user => user.user_id == user_id).name;

    if (name !== void 0) return name.family_name + name.given_name;
    else return "";
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * ファミリーユーザーをHTTP通信により取得する。
   *
   * @return {Observable<HttpResponse<Relation[]>>}
   * @memberof FamilyWebApiService
   */
  getFamily(): Observable<HttpResponse<Relation[]>> 
  {
    return this.http.get<Relation[]>(`${ this.config.baseUrl }/family`, 
    {
      ...this.config.httpOptions,
      observe: 'response',
      params: { areacode: this.config.areacode },
      withCredentials: true
    })
    .pipe( // オペレーター
      tap( // 本流から分岐
      {
        next: (response: HttpResponse<Relation[]>) => 
        {
          this.FamilyInfoSubject.next(response.body);
        }
      })
    );
  }

  /**
   * ファミリーメンバーを新規登録（招待申請を送信）する。
   *  ファミリー管理者機能
   *
   * @param {number} user_id
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof FamilyWebApiService
   */
  sendLinkRequest(user_id: string): Observable<HttpResponse<any>> {

    return this.http.post(`${ this.config.baseUrl }/family`, { user_id: user_id , role: 'child' }, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }

  /**
   * 管理者からの招待申請の承認・却下を行う。
   *    ファミリーメンバー機能
   *
   * @param {number} relation_id
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof FamilyWebApiService
   */
  replyRequest(relation_id: number): Observable<HttpResponse<any>>
  {
    return this.http.post(`${this.config.baseUrl}/family/accept`, { relation_id: relation_id }, 
    {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }

  /**
   * 管理者に認証用PINコードを送信(ログインユーザの認証)。
   *  ファミリー招待時。
   *
   * @param {{qr_image?: string, qr_code?: string}} qr
   * @return {*}  {Observable<HttpResponse<number>>}
   * @memberof FamilyWebApiService
   */
  authParent(qrcode: request.familyPincodeSend): Observable<HttpResponse<{user_id: string, dst: string}>> {

    return this.http.post<{user_id: string, dst: string}>(`${this.config.baseUrl}/family/pincode-send`, qrcode,
    {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }

  /**
   * PINCODE認証を行う。
   * ログインユーザ、二段階認証時に使用。
   *
   * @param {string} pincode
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof UserWebApiService
   */
   twoFactorAuthPincode(pincode: string): Observable<HttpResponse<any>> {

    return this.http.post(`${this.config.baseUrl}/family/pincode-check`, { pincode: pincode }, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    })
  }

  /**
   * ファミリーの情報を変更する
   *    ファミリー管理者機能
   *
   * @param {request.familyInfoUpdate} reqBody
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof FamilyWebApiService
   */
  familyInfoUpdate(reqBody: request.familyInfoUpdate): Observable<HttpResponse<any>>
  {
    return this.http.put(`${this.config.baseUrl}/family`, reqBody, 
    {
      ...this.config.httpOptions, 
      observe: 'response',
      withCredentials: true
    });
  }
  
  // =========================== リンク削除関連

  /**
   * ファミリーリンクを削除する。
   *    ・招待申請の拒否：メンバー
   *    ・リンクの削除：管理者
   *    ・リンクの削除申請の許可：管理者
   *
   * @param {number} relation_id
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof FamilyWebApiService
   */
  deleteRelation(relation_id: number): Observable<HttpResponse<any>>
  {
    return this.http.delete(`${this.config.baseUrl}/family?${qs.stringify({ relation_id: relation_id })}`, 
    {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }

  /**
   * ファミリーリレーションの削除申請を行う。
   *    ファミリーメンバー機能
   *
   * @param {*} reqBody
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof FamilyWebApiService
   */
  reqDeleteRelation(relation_id: number): Observable<HttpResponse<any>>
  {
    return this.http.post(`${this.config.baseUrl}/family/request`,  { relation_id: relation_id }, 
    {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }

  /**
   * ファミリーリレーションの削除申請の却下及び、申請の取り消しを行う。
   *    管理者　：削除申請の却下
   *    メンバー：削除申請の取り消し
   *
   * @param {number} relation_id
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof FamilyWebApiService
   */
  rejectDeleteRelation(relation_id: number): Observable<HttpResponse<any>> 
  {
    return this.http.post(`${this.config.baseUrl}/family/revert`, { relation_id: relation_id }, 
    {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }
  
  // =========================== 引継ぎ対象アカウント関連

  /**
   * 引継ぎ対象アカウントを作成する。
   *
   * @param {request.createFamilyAlt} reqBody 氏名/生年月日/性別
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof FamilyWebApiService
   */
  createProxy(reqBody: request.createFamilyAlt): Observable<HttpResponse<any>>
  {
    return this.http.post(`${this.config.baseUrl}/family/alt`, reqBody, 
    {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }

  /**
   * 引継ぎ対象アカウントを削除する。
   *
   * @param {number} user_id
   * @return {Observable<HttpResponse<any>>}
   * @memberof FamilyWebApiService
   */
  deleteProxy(user_id: string): Observable<HttpResponse<any>>
  {
    return this.http.delete(`${this.config.baseUrl}/family/alt?${qs.stringify({ user_id: user_id })}`, 
    {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }

  /**
   * 引継ぎ対象アカウント独立のためのトークンを取得する。
   *
   * @param {*} user_id
   * @return {Observable<HttpResponse<object>>} object: {token: string}
   * @memberof FamilyWebApiService
   */
  getProxyToken(user_id: string): Observable<HttpResponse<{ token: string }>>
  {
    return this.http.get<{ token: string }>(`${this.config.baseUrl}/family/alt/token?${qs.stringify({ user_id: user_id })}`, 
    {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }

  /**
   * 引継ぎ対象アカウント独立のためのトークンを入力する。
   *
   * @param {string} token
   * @return {Observable<HttpResponse<any>>}
   * @memberof FamilyWebApiService
   */
  handOverProxy(token: string): Observable<HttpResponse<UserInfo>>
  {
    return this.http.post<UserInfo>(`${this.config.baseUrl}/family/alt/token`,{ token: token, areacode: this.config.areacode }, 
    {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }
}
