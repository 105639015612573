// ========================================================================================================================
// 各種インポート
// ========================================================================================================================

import { Component, OnInit } from '@angular/core';
import { OnsNavigator, Params } from 'ngx-onsenui';
import * as ons from 'onsenui';
import { Subscription } from 'rxjs';

import { UserWebApiService } from '../../../http-services/user-web-api.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { MESSAGE } from 'src/app/constants/message';

// ========================================================================================================================
// クラス定義
// ========================================================================================================================

@Component({
  selector: 'ons-page[login]',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {

  // ================================================================================
  // 変数定義
  // ================================================================================

  /**
   * 非同期処理監視Subscription
   *
   * @type {Subscription}
   * @memberof LoginComponent
   */
  busy: Subscription;

  /**
   * PINコード送付先メールアドレス
   *
   * @type {string}
   * @memberof LoginComponent
   */
  public username: string = null;
  
  /**
   *　フォームに入力されたPINコード
   *
   * @type {string}
   * @memberof LoginComponent
   */
   public pincode: string = null;
  
  /**
   *　フォームに入力されたパスワード
   *
   * @type {string}
   * @memberof LoginComponent
   */
  public password: string = null;

  // ================================================================================
  // ライフサイクルメソッド定義
  // ================================================================================

  constructor(
    private _navigator: OnsNavigator,
    private userWebApiService: UserWebApiService,
    private httpErrorResponseParserService: HttpErrorResponseParserService,
    private _params: Params,
    private appMsg: ApplicationMessageService,
    private msg: MESSAGE,
  ) { }

  ngOnInit(): void {

    this.username = this._params.data.username;
  }

  ngOnDestroy(): void {

    this.busy?.unsubscribe();
  }

  // ================================================================================
  // 関数定義
  // ================================================================================

  /**
   * フォーム入力内容を元にPINコード認証
   *
   * @memberof LoginComponent
   */
  signup(): void {

    this.busy = this.userWebApiService.signupPincode(this.username, this.pincode).subscribe({
      next: () => this.userWebApiService.autoLogin().subscribe(),

      error: this.httpErrorResponseParserService.doParse((_error, customErrorContent) => {
        
        if (customErrorContent.smartGotoErrCode == this.appMsg.SERV_CONST_CODE.LOGIN.PIN_EXPIRED) {
          this.appMsg.viewDialogMessage(this.msg.CLIENT.SIGNUP.PIN_EXPIRED.message(), () => {
            this._navigator.element.popPage();
          });
        }
        else this.httpErrorResponseParserService.viewErrDialog(customErrorContent);

        // ons.notification.alert(customErrorContent.smartGotoErrMessage || this.appMsg.getCLientMsg(this.appMsg.CLIENT_CODE.COMMON.E_UNEXPECTED), { title: null });
      })
    });
  }

  /**
   * 遷移元画面に戻る
   *
   * @memberof LoginComponent
   */
  backPage(): void {

    this._navigator.element.popPage();
  }

}

// ========================================================================================================================
// 
// ========================================================================================================================

export namespace LoginComponent {

  export interface Transition {
    method?: 'popPage' | 'pushPage' | 'resetToPage' | 'replacePage';
    page?: any;
    options?: any;
  }

}
