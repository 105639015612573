import { InjectionToken } from '@angular/core';

// 値は全てapp.moduleで決定している
export const PLACE_MAP_MAP_OPTIONS=new InjectionToken<google.maps.MapOptions>('placeMapMapOptions');
export const PLACE_MAP_MARKER_ICON=new InjectionToken<google.maps.Icon>('placeMapMarkerIcon');
export const PLACE_MAP_CURRENT_POSITION_MARKER_ICON=new InjectionToken<google.maps.Icon>('placeMapCurrentPositionMarkerIcon');
export const PLACE_MAP_SELECTED_MARKER_ICON=new InjectionToken<google.maps.Icon>('placeMapSelectedMarkerIcon');
export const PLACE_MAP_SELECTED_FAVORITE_MARKER_ICON=new InjectionToken<google.maps.Icon>('placeMapSelectedFavoriteMarkerIcon');
export const PLACE_MAP_BUSSTOP_MARKER_ICON=new InjectionToken<google.maps.Icon>('placeMapBusstopMarkerIcon');
export const PLACE_MAP_SELECTED_BUSSTOP_MARKER_ICON=new InjectionToken<google.maps.Icon>('placeMapSelectedBusstopMarkerIcon');
export const PLACE_MAP_RELATED_MARKER_ICON=new InjectionToken<google.maps.Icon>('placeMapRelatedMarkerIcon');
/**
 * @deprecated
 */
export const PLACE_MAP_SEARCH_BOX_OPTIONS = new InjectionToken<google.maps.places.SearchBoxOptions>('placeMapSearchBoxOptions');