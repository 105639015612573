<ons-toolbar>
  <div class="left">
    <ons-back-button></ons-back-button>
  </div>
  <div class="center">お届け先</div>
</ons-toolbar>

<div class="content">
  <div [ngBusy]="busy"></div>
  
  <div class="list-item__caption">お届け先</div>
  <ons-list>
    <ons-list-item id="shopping-address_001" modifier="chevron longdivider" (click)="onSearchMethod()">
      <p *ngIf="getDeliveryAddress() !== ''; else noAddress">{{ getDeliveryAddress() }}</p>
    </ons-list-item>
  </ons-list>
  
  <div class="list-item__caption" *ngIf="selected.date !== ''">配達希望日時</div>
  <ons-list *ngIf="selected.date !== ''">
    <ons-list-item id="shopping-address_" modifier="longdivider" class="deliveryMenu">
      <p class="address-select-wrap">
        <span>日付</span>
        <ons-select id="shopping-address_002" class="address-select-wrap__select-box" modifier="underbar" [(ngModel)]="selected.date" [attr.modifier]="selected.date" (change)="changeDeliveryDate()">
          <option *ngFor="let date of selectDate" [value]="date.value">{{ date.label }}</option>
        </ons-select>
      </p>
    </ons-list-item>
    <ons-list-item modifier="longdivider" class="deliveryMenu" *ngIf="selected.time !== ''">
      <p class="address-select-wrap">
        <span>時間帯</span>
        <ons-select id="shopping-address_003" class="address-select-wrap__select-box" style="margin-left: auto;" modifier="underbar" [(ngModel)]="selected.time" [attr.modifier]="selected.time" (change)="changeDeliveryTime()">
          <option *ngFor="let time of selectTime[selected.date]" [value]="time.value">{{ time.label }}</option>
        </ons-select>
      </p>
    </ons-list-item>
  </ons-list>
  <div class="text--right" *ngIf="selected.date !== ''"><span class="amount">配送料￥{{ selected.amount | number:'.0-0' }}</span></div>
  <div class="text--right" *ngIf="selected.date !== ''"><span class="icon--warning">！</span> 注文の際には、商品代が別途かかります。</div>
  
  <div class="button-wrap button-sticky">
    <ons-button id="shopping-address_004" modifier="large--cta" [disabled]="selected.date === '' ? true : false" (click)="onCheckOrder()">注文内容確認へ</ons-button>
  </div>
  
  <!-- template -->
  <!-- 登録住所がない -->
  <ng-template #noAddress><p>未選択</p></ng-template>
</div>