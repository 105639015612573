import { ModuleWithProviders, NgModule } from '@angular/core';
import { UserWebApiServiceConfig } from '../http-services/user-web-api.service';

/**
 * ユーティリティサービス (ユーザー関連) に依存性を注入するためのインジェクターを構成するモジュール。
 *
 * @export
 * @class UserWebApiModule
 */
@NgModule()
export class UserWebApiModule {
  /**
   * DI トークンと依存性の関連付けを行う。
   *
   * @static
   * @param {UserWebApiServiceConfig} config Web API (ユーザー関連) の構成設定。
   * @return {*}  {ModuleWithProviders<UserWebApiModule>} `ModuleWithProviders<UserWebApiModule>`
   * @memberof UserWebApiModule
   */
  static withConfig(config: UserWebApiServiceConfig): ModuleWithProviders<UserWebApiModule> {
    return {
      ngModule: UserWebApiModule,
      providers: [
        { provide: UserWebApiServiceConfig, useValue: config }
      ]
    };
  }
}
