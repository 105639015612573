import { NgModule, ModuleWithProviders } from '@angular/core';
import { ShoppingWebApiServiceConfig } from '../http-services/shopping-web-api.service';

@NgModule()
export class ShoppingWebApiModule {
  static withConfig(config: ShoppingWebApiServiceConfig): ModuleWithProviders<ShoppingWebApiModule> {
    return {
      ngModule: ShoppingWebApiModule,
      providers: [
        { provide: ShoppingWebApiServiceConfig, useValue: config }
      ]
    };
  }
}
