//=============================================================================================
// インポート
//=============================================================================================
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { OnsNavigator, Params } from 'ngx-onsenui';
import { Moment } from 'moment';
import * as moment from 'moment-timezone';

import { environment } from '../../../../environments/environment';

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { DispatchWebApiService, DISPATCH_WEB_API_RESERVATION_TRANS_MAP } from '../../../http-services/dispatch-web-api.service';
import { SpotGeolocationService } from '../../../http-services/spot-geolocation.service';
import { UserReservation } from '../user-reservation/user-reservation';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';

// component
import { SearchResultDetailComponent } from '../search-result-detail/search-result-detail.component';
import { SearchResultList } from './search-result-list';

// interface
import { common } from '../../../interfaces/common';
import { request } from '../../../interfaces/request';
import { Reservation, InquiryReserve } from '../../../interfaces/response';
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 移動プラン選択画面。
 *
 * @export
 * @class SearchResultListComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ons-page[search-result-list]',
  templateUrl: './search-result-list.component.html',
  styleUrls: ['./search-result-list.component.css']
})
export class SearchResultListComponent implements OnInit, OnDestroy 
{

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * 日付と時刻を扱うためのユーティリティ (Moment オブジェクト)。
   *
   * @memberof SearchResultListComponent
   */
  readonly moment = moment;

  /**
   * 移動プランの検索条件。
   *
   * @type {UserReservation.SearchCondition}
   * @memberof SearchResultListComponent
   */
  searchCondition: UserReservation.SearchCondition;

  /**
   * 〈出発時刻/到着時刻〉を Moment オブジェクトに変換したもの。
   *
   * @type {Moment}
   * @memberof SearchResultListComponent
   */
  scheduleDateTime: Moment;

  /**
   * 移動プラン。
   *
   * @type {SearchResultList.Planner}
   * @memberof SearchResultListComponent
   */
  planner: SearchResultList.Planner;

  /**
   * 非同期処理の実行状態を表す。
   *
   * @type {Subscription}
   * @memberof SearchResultListComponent
   */
  busy: Subscription;

  private inquiryReserveRequestParams: request.InquiryReserve;

  /**
   * 利用者ごとの料金、利用チケット情報
   *
   * @type {common.customerBill}
   * @memberof SearchResultDetailComponent
   */
  customerBills: common.customerBill[] = [];

  /**
   * assetsファイルへのパス(定数)
   *
   * @memberof SearchResultListComponent
   */
  readonly ASSETS = {
    DEPARTURE: CommonFunctionModule.getAssetsUrl('/image/common/04-Departure.png'),
    DESTINATION: CommonFunctionModule.getAssetsUrl('/image/common/05-Destination.png'),
  } as const;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of SearchResultListComponent.
   * @param {Map<string, Map<string, string>>} reservationTransMap 交通手段に応じて異なる設定値が格納された `Map` オブジェクト。
   * @param {OnsNavigator} _navigator ページスタックの管理とナビゲーション機能を提供するコンポーネント。
   * @param {DispatchWebApiService} dispatchWebApiService Web API (予約関連) の呼び出しを簡略化するためのユーティリティ。
   * @param {SpotGeolocationService} spotGeolocationService デバイスやスポットの位置情報の扱いを簡略化するためのユーティリティ。
   * @param {HttpErrorResponseParserService} httpErrorResponseParserService `HttpErrorResponse` オブジェクトを SmartGOTO の形式にカスタマイズするためのユーティリティ。
   * @param {Params} params 遷移元のページから渡されるパラメーター。
   * @param {ApplicationMessageService} appMsg
   * @memberof SearchResultListComponent
   */
  constructor(
    @Inject(DISPATCH_WEB_API_RESERVATION_TRANS_MAP) public readonly reservationTransMap: Map<string, Map<string, string>>,
    private _navigator: OnsNavigator,
    private dispatchWebApiService: DispatchWebApiService,
    private spotGeolocationService: SpotGeolocationService,
    private httpErrorResponseParserService: HttpErrorResponseParserService,
    private params: Params,
    private appMsg: ApplicationMessageService,
    private municipalityWebApiServ: MunicipalityWebApiService,
    private msg: MESSAGE,
  ) { }

  /**
   * コンポーネントが初期化される際に呼び出されるメソッド。
   *
   * @memberof SearchResultListComponent
   */
  async ngOnInit(): Promise<void> {

    [this.searchCondition] = this.params.data;

    this.scheduleDateTime = this.searchCondition.scheduleDateTimeService.asMoment();

    const chooseSpotProperties = async (spot: UserReservation.SearchConditionSpot): Promise<request.ReservationPlace> => {
      // 現在地
      if (spot.useCurrentPosition) {
        return {
          location: spot.geocoderResult.geometry.location.toJSON(),
          name: this.spotGeolocationService.formatGeocoderResultAddress(spot.geocoderResult)
        };
      } 
      // マイスポット
      else if (spot.favorite) {
        return { favorite_id: spot.favorite.favorite_id };
      } 
      // 公共スポット
      else if (spot.place) {
        if (spot.place.place_id) return { place_id: spot.place.place_id };
        // place_idがなかった(バス停を選択)場合、地点名と緯度経度
        else return { name: spot.place.name, location: spot.place.location };
      }
      // 履歴
      else if (spot.history) {
        return {
          location: spot.history.location,
          name: spot.history.name
        };
      } 
      // POI(point of interest)
      else if (spot.placeResult) {
        return this.spotGeolocationService.getGeocoderPlaceResultAddress(spot);
        // let result: request.ReservationPlace = {
        //   place_id: spot.placeResult.place_id,
        //   location: spot.placeResult.geometry.location.toJSON(),
        //   name: spot.placeResult.name,
        //   search_text: spot.searchText ? spot.searchText : undefined
        // };

        // // 住所を成形して取得
        // const formatAddress: string = (spot.placeResult?.address_components && this.spotGeolocationService.formatPlaceResultAddress(spot.placeResult)) ??
        // (spot.geocoderResult?.address_components && this.spotGeolocationService.formatGeocoderResultAddress(spot.geocoderResult));
  
        // // 住所が取得できなかったら
        // if (!formatAddress) {

        //   const geocoderParam = (() => {
        //     // ポイントジオメトリまたは、マイスポットの位置情報があるなら、その情報を使用する
        //     const isSearchResultOrFavoriteSpot = spot.placeResult?.geometry || spot.favorite?.location;
        //     if (isSearchResultOrFavoriteSpot) return { location: spot.placeResult?.geometry.location ?? spot.favorite.location };
        //   })();
    
        //   if (!geocoderParam) return result;

        //   // google maps APIを使って地理情報から住所を取得し、サーバ通信に用いるため、
        //   // 全体をPromise化し、処理完了まで待機する
        //   return new Promise((res, rej) => {

        //     // 地理情報から住所取得
        //     this.geocoder.geocode(geocoderParam, (results, status) => {
              
        //       switch (status) {
        //         case google.maps.GeocoderStatus.OK:
        //           if (results[0].geometry) result.address = this.spotGeolocationService.formatGeocoderResultAddress(results[0]);

        //           // promise完了
        //           // 住所取得成功のため、addressあり
        //           res(result as request.ReservationPlace);
        //           break;
        //         default: 
        //           // 住所取得失敗のため、addressなし
        //           rej(result as request.ReservationPlace);
        //           break;
        //       }
        //     });
        //   }) as request.ReservationPlace;
        // }
        // else {
        //   // 住所を設定
        //   result.address = formatAddress;
        //   return result;
        // }
      } 
      // 任意地点
      else if (spot.geocoderResult) {
        const address: string = this.spotGeolocationService.formatGeocoderResultAddress(spot.geocoderResult);
        return {
          place_id: spot.geocoderResult.place_id,
          location: spot.arbitraryLocation.toJSON(),
          name: address,
          address: address
        };
      } 
      else {
        throw new Error();
      }
    };

    this.inquiryReserveRequestParams = {
      date: this.scheduleDateTime.format('YYYY-MM-DD'),
      time: this.scheduleDateTime.format('HH:mm'),
      o: await chooseSpotProperties(this.searchCondition.o),
      d: await chooseSpotProperties(this.searchCondition.d),
      user_ids: this.searchCondition.getPassengersUserIds(),
      od: this.searchCondition.schedule.odOptions[this.searchCondition.schedule.od].value,
      dir: this.searchCondition.schedule.dir
    };

    this.busy = this.dispatchWebApiService.inquiryReserve/* <InquiryReserve> */(this.inquiryReserveRequestParams).subscribe
    ({
      next: response => 
      {
        this.planner = new PlannerImpl(response.body);

        if (this.planner.reservations.length == 0) 
        {
          this.appMsg.viewDialogMessage(this.msg.CLIENT.DISPATCH.NOT_EXIST_DISPATCH_PLAN.message(), () => { this._navigator.element.popPage(); });
          return;
        }

        // 到着が早い順でソート
        this.planner.sort('time');

        // 配車に対する利用者毎の利用料金、チケット情報を取得
        this.planner.reservations.forEach((r) => 
        {
          let bill = this.dispatchWebApiService.getCustomersBill(r, this.searchCondition.passengers);
          this.customerBills.push(bill);

          // 配車の合計料金を計算（ログインユーザ＋ファミリー＋ゲスト）
          bill.total !== undefined ? r.price = bill.total.price : r.price = 0;
          // bill.guests.forEach((g) => r.price += g.total_guest.price);
        });
      },
      error: this.httpErrorResponseParserService.doParse((_err, errContent) => 
      {
        if (errContent.smartGotoErrCode == this.appMsg.SERV_CONST_CODE.COMMON.USER_TERM_NEVER_AGREED) {
          // 規約合意していないユーザーとしてメッセージに表示する文字列を取得
          const neverAgreeUser: string = this.municipalityWebApiServ.getTermNeverAgreedUser(errContent);
          // サーバーレスポンスが想定外
          if (neverAgreeUser === "") this.httpErrorResponseParserService.viewErrDialog(errContent).then(() => { this.searchCondition.returnHere(); });
          // 規約合意していないエラー
          else this.appMsg.viewDialogMessage(this.msg.CLIENT.DISPATCH.E_NOT_TERM_AGREE.message(neverAgreeUser), () => { this.searchCondition.returnHere(); });
        }
        else this.httpErrorResponseParserService.viewErrDialog(errContent).then(() => { this.searchCondition.returnHere(); });
      })
    });
  }

  /**
   * コンポーネントが破棄される際に呼び出されるメソッド。
   *
   * @memberof SearchResultListComponent
   */
  ngOnDestroy(): void 
  {
    this.busy?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 移動プランを選択する。
   *
   * @param {Reservation} reservation 予約情報。
   * @memberof SearchResultListComponent
   */
  selectPlan(reservation: Reservation, index: number): void 
  {
    this._navigator.element.pushPage(SearchResultDetailComponent, {
      animation: 'left',
      data: [this.searchCondition, this.inquiryReserveRequestParams, this.planner, reservation, this.customerBills[index]], 
    });
  }
}


//=============================================================================================
// Plannerクラス定義
//=============================================================================================

/**
 * `SearchResultList.Planner` の実装。
 *
 * @class PlannerImpl
 * @implements {SearchResultList.Planner}
 */
class PlannerImpl implements SearchResultList.Planner 
{
  /**
   * Creates an instance of PlannerImpl.
   * @param {InquiryReserve} inquiryReserveResponse サーバーからのレスポンス。
   * @memberof PlannerImpl
   */
  constructor
  (
    private inquiryReserveResponse: InquiryReserve
  ) { }

  /**
   * 日付。
   *
   * @readonly
   * @memberof PlannerImpl
   */
  get date() 
  {
    return this.inquiryReserveResponse.date;
  }

  /**
   * リクエスト ID。
   *
   * @readonly
   * @memberof PlannerImpl
   */
  get requestId() 
  {
    return this.inquiryReserveResponse.request_id;
  }

  /**
   * 予約情報のリスト。
   *
   * @readonly
   * @memberof PlannerImpl
   */
  get reservations() 
  {
    return this.inquiryReserveResponse.reservations;
  }

  /**
   * 指定された条件で移動プランを並び替える。
   *
   * @param {('time' | 'fee' | 'transfer')} order 並び替えの条件 (「到着が早い」、「料金が安い」、あるいは「乗換が少ない」)。
   * @memberof PlannerImpl
   */
  sort(order: 'time' | 'fee' | 'transfer') 
  {
    switch (order) {
      case 'time':
        this.reservations.sort((a, b) => moment(a.d.schd_time).valueOf() - moment(b.d.schd_time).valueOf());

        break;
      case 'fee':
        this.reservations.sort((a, b) => a.price - b.price);

        break;
      case 'transfer':
        this.reservations.sort((a, b) => a.trans_count - b.trans_count);

        break;
      default:
        break;
    }
  }
}