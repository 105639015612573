//=============================================================================================
// インポート
//=============================================================================================
import { Component } from '@angular/core';

// component
import { UseListComponent } from './use-list/use-list.component';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 利用するタブ。
 *
 * @export
 * @class ServiceComponent
 */
@Component({
  selector: 'ons-page[service]',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.css']
})

export class ServiceComponent {

//=============================================================================================
// メンバ変数
//=============================================================================================

  /**
   * ルートページ
   *
   * @memberof ServiceComponent
   */
  root = UseListComponent;

//=============================================================================================
// コンストラクタ
//=============================================================================================

  /**
   * Creates an instance of ServiceComponent.
   * @memberof ServiceComponent
   */
  constructor() { }
}
