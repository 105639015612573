<ons-toolbar>
  <div class="left">
    <ons-back-button #onsBackButton></ons-back-button>
  </div>
  <div class="center">カート</div>
</ons-toolbar>

<div class="content">
  <div [ngBusy]="busy"></div>

  <!-- 店舗名 -->
  <ons-list class="list-item__title list-header__title--sticky shop-info-wrap">
    <ons-list-item id="deliveryMenu-header_">
      <!-- 店舗アイコン/店舗名 -->
      <ons-row vertical-align="center">
        <ons-col width="120px" class="list-icon-col"><span *ngIf="shop.icon !== undefined && shop.icon !== ''; else noIcon"><img class="shop-info-wrap__img" [src]="shop.icon" /></span></ons-col>
        <ons-col>{{ shop.name }}</ons-col>
      </ons-row>
    </ons-list-item>
  </ons-list>

  <!-- 注文内容 -->
  <div class="list-item__caption">注文内容</div>
  <ons-list>
    <ons-list-item class="list-item__menu--height deliveryMenu" *ngFor="let item of items; index as i" 
      id="shopping-menu-cart_001" modifier="chevron longdivider" tappable (click)="onItemSelect(i)">
      <!-- メニュー -->
      <ons-row class="menu-detail-wrap" vertical-align="center">
        <ons-col class="list-icon-col" width="120px">
          <span *ngIf="item.menu.images?.length > 0; else noImage">
            <img class="list-icon-col__img" [src]="item.menu.images[0]" />
          </span>
        </ons-col>
        <ons-col class="menu-detail">
          <ons-row vertical-align="top" class="menu-detail-name">
            <ons-col><p class="menu-detail-name__line-clamp" id="shopping-menu-cart_002">{{ item.menu.name }}</p></ons-col>
          </ons-row>
          <ons-row vertical-align="bottom" class="menu-detail-price">
            <ons-col><p>￥{{ item.menu.price | number:'.0-0' }}/個</p></ons-col>
          </ons-row>
          <ons-row class="menu-detail-edit">
            <ons-col class="menu-detail-select">
              <ons-select class="menu-detail-select__select-box" (click)="$event.stopPropagation()" [(ngModel)]="selectedCount[i].count" (change)="changeCount(item.menu.menu_id)">
                <option *ngFor="let count of countArray" [value]="count">{{count}}個
                </option>
              </ons-select>
            </ons-col>
            <ons-col class="menu-detail-delete">
              <!-- <button mat-raised-button class="menu-detail-delete__button" (click)="deleteMenu($event, item.menu.menu_id)">削除</button> -->
              <!-- <p><span class="delete-link__text">削除</span></p> -->
              <button mat-raised-button class="menu-detail-delete__button" (click)="deleteMenu($event, item.menu.menu_id)">削除</button>
            </ons-col>
          </ons-row>
        </ons-col>
      </ons-row>
    </ons-list-item>
  </ons-list>
  <div class="text--right"><span class="amount">商品小計￥{{ getTotalPrice() | number:'.0-0' }}</span></div>
  <div class="text--right"><span class="icon--warning">！</span> 注文の際には、配送料が別途かかります。</div>
  <!-- <div class="text--right"><span class="description" (click)="onDescription()">個数変更・削除を行いたい場合はこちら</span></div> -->
  
  <div class="button-wrap button-sticky">
    <ons-button id="shopping-menu-cart_004" modifier="large--cta" (click)="onAddressInput()">お届け先情報入力へ</ons-button>
  </div>

  <!-- template -->
  <!-- 店舗アイコンがない場合、汎用アイコンを表示 -->
  <ng-template #noIcon><mat-icon class="shop-info-wrap__mat-icon">store</mat-icon></ng-template>
  <ng-template #noImage><mat-icon class="list-icon-col__mat-icon">menu_book</mat-icon></ng-template>
</div>
