//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { OnsNavigator, Params } from 'ngx-onsenui';

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { PagerService } from '../../../lib-services/pager.service';
import { UserWebApiService } from '../../../http-services/user-web-api.service';

// component
import { PaymentMethod, DELETE_IMPOSSIBLE } from '../payment-method';
import { PaymentMethodListComponent } from '../payment-method-list/payment-method-list.component';

// interface
import { request } from '../../../interfaces/request';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * クレジットカード操作画面。
 *
 * @export
 * @class PaymentMethodCreditDetaileComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ons-page[payment-method-credit-detaile]',
  templateUrl: './payment-method-credit-detaile.component.html',
  styleUrls: ['./payment-method-credit-detaile.component.css']
})

export class PaymentMethodCreditDetaileComponent implements OnInit, OnDestroy 
{

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * HTTP通信購読用
   *
   * @type {Subscription}
   * @memberof PaymentMethodDetaileCreditComponent
   */
  busy: Subscription;

  /**
   * 支払い方法の登録状況
   *
   * @type {PaymentMethod.PaymentRegistration}
   * @memberof PaymentMethodDetaileCreditComponent
   */
  paymentRegistration: PaymentMethod.PaymentRegistration;

  /**
   * クレジットカードの状況
   *
   * @type {PaymentMethod.PaymentCard}
   * @memberof PaymentMethodDetaileCreditComponent
   */
  card: PaymentMethod.PaymentCard;

  /**
   * デフォルト支払い方法のチェックボックス表示可否
   *
   * @type {boolean}
   * @memberof PaymentMethodDetaileCreditComponent
   */
  isDefaultOrExpiredCard: boolean;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of PaymentMethodDetaileCreditComponent.
   * @param {OnsNavigator} navigator
   * @param {ApplicationMessageService} appMsg
   * @param {HttpErrorResponseParserService} httpErrorResponseParserService
   * @param {PagerService} pager
   * @param {UserWebApiService} userWebApiService
   * @param {Params} params
   * @memberof PaymentMethodDetaileCreditComponent
   */
  constructor
  (
    private navigator: OnsNavigator, 
    private appMsg: ApplicationMessageService, 
    private httpErrorResponseParserService: HttpErrorResponseParserService, 
    private pager: PagerService, 
    private userWebApiService: UserWebApiService, 
    private params: Params,
    private msg: MESSAGE,
  ) { }

  /**
   * 初期化処理。
   *
   * @memberof PaymentMethodDetaileCreditComponent
   */
  ngOnInit(): void 
  {
    [this.card, this.paymentRegistration] = this.params.data;
    this.isDefaultOrExpiredCard = this.card.is_default || this.card.is_expired;
  }

  /**
   * 破棄処理。
   *
   * @memberof PaymentMethodDetaileCreditComponent
   */
  ngOnDestroy(): void 
  {
    this.busy?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 有効期限の表示成形を行う。
   *
   * @param {string} expire
   * @return {*}  {string}
   * @memberof PaymentMethodDetaileCreditComponent
   */
  toExpireFormat(expire: string): string 
  {
    const MM = expire.substring(2, 4);
    const YY = expire.substring(0, 2);
    return MM + '/' + YY;
  }

  /**
   * 支払い方法を削除する。
   *
   * @return {*}  {void}
   * @memberof PaymentMethodDetaileCreditComponent
   */
  delete(): void 
  {
    // 支払い方法が1件のみの場合削除不可
    if (this.paymentRegistration.count === 1) 
    {
      this.appMsg.viewDialogMessage(DELETE_IMPOSSIBLE, () => this.pager.transitionToTopPage(this.navigator, PaymentMethodListComponent));
      return;
    }
    
    this.busy = this.userWebApiService.deleteCard(this.card.card_seq).subscribe
    ({
      next: _ => 
      {
        this.appMsg.viewDialogMessage(this.msg.CLIENT.PAYMENT.DELETE.message(), _ => this.pager.transitionToTopPage(this.navigator, PaymentMethodListComponent));
      },
      error: this.httpErrorResponseParserService.doParse((_err, errContent) => 
      {
        this.httpErrorResponseParserService.viewErrDialog(errContent);
      })
    });
  }

  /**
   * デフォルト支払処理の変更を行う。
   *
   * @return {*}  {void}
   * @memberof PaymentMethodDetaileCreditComponent
   */
   changeDefaultPayment(): void 
  {
    if (this.isDefaultOrExpiredCard)
      return;

    const payment: request.Payment = 
    {
      method: 'CARD',
      card_seq: this.card.card_seq
    };
    
    this.busy = this.userWebApiService.updatePayment(payment).subscribe
    ({
      next: _ => 
      {
        this.appMsg.viewDialogMessage(this.msg.CLIENT.PAYMENT.UPDATE_DEFAULT.message(), _ => this.pager.transitionToTopPage(this.navigator, PaymentMethodListComponent));
      },
      error: this.httpErrorResponseParserService.doParse((_err, errContent) => 
      {
        this.httpErrorResponseParserService.viewErrDialog(errContent);
      })
    });
  }
}