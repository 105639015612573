import { NgModule, ModuleWithProviders } from '@angular/core';
import { NewsWebApiServiceConfig } from '../http-services/news-web-api.service';

@NgModule()
export class NewsWebApiModule {
  static withConfig(config: NewsWebApiServiceConfig): ModuleWithProviders<NewsWebApiModule> {
    return {
      ngModule: NewsWebApiModule,
      providers: [
        { provide: NewsWebApiServiceConfig, useValue: config }
      ]
    };
  }
}