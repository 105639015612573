//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Subscription, fromEvent } from 'rxjs';
import { OnsNavigator } from 'ngx-onsenui';
import * as CONST from '../../../constants/constant';

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { NewsWebApiService } from '../../../http-services/news-web-api.service';
import { HistoryWebApiService } from '../../../http-services/history-web-api.service';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';

// component
import { UseListComponent, UseListTabTag } from '../../service/use-list/use-list.component';
import { NewsArticleListComponent } from '../news-article-list/news-article-list.component';
import { DisasterPreventionRelateSiteListComponent } from '../disaster-prevention-relate-site-list/disaster-prevention-relate-site-list.component';

// interface
import { NewsCategory } from '../../../interfaces/response';
import { WeatherDisasterPreventionRelateSite } from "../../../interfaces/response/disaster-prevention-sites";

// module
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';

// parts
import { SwiperImagesComponent } from '../../parts/swiper-images/swiper-images.component';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 記事カテゴリ一覧。
 *
 * @export
 * @class NewsCategoryListComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ons-page[news-category-list]',
  templateUrl: './news-category-list.component.html',
  styleUrls: ['./news-category-list.component.scss']
})
export class NewsCategoryListComponent implements OnInit, OnDestroy, AfterViewInit {

  /**
   * 通信用Subscription
   *
   * @type {Subscription}
   * @memberof NewsCategoryListComponent
   */
  busy: Subscription;

  /**
   * まちニュースタブクリック用Subscription
   *
   * @type {Subscription}
   * @memberof NewsCategoryListComponent
   */
  tabClickBusy: Subscription;

  /**
   * URL連動検索遷移監視Subscription
   *
   * @type {Subscription}
   * @memberof ExpComponent
   */
  pushNewsSubscription: Subscription;

  /**
   * カテゴリ情報
   *
   * @type {NewsCategory[]}
   * @memberof NewsCategoryListComponent
   */
  categories: NewsCategory[] = [];

  /**
     * 気象・防災関連サイト情報
     *
     * @type {WeatherDisasterPreventionRelateSite}
     * @memberof NewsCategoryListComponent
     */
  disasterSiteList: WeatherDisasterPreventionRelateSite[] = [];

  /**
   * 検索ワード
   *
   * @type {string}
   * @memberof NewsCategoryListComponent
   */
  freeword: string = "";

  /**
   * 検索履歴
   *
   * @type {string[]}
   * @memberof NewsCategoryListComponent
   */
  searchHistory: string[] = [];


  /**
   * 検索フォームにフォーカスがある場合履歴を再取得しない
   *
   * @type {boolean}
   * @memberof NewsArticleListComponent
   */
  oneFocus: boolean = false;

  /**
   * 検索履歴のオートコンプリート
   *
   * @type {MatAutocompleteTrigger}
   * @memberof NewsCategoryListComponent
   */
  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger })
  autoComplete: MatAutocompleteTrigger;

  /**
   * noImage画像
   *
   * @type {string}
   * @memberof NewsCategoryListComponent
   */
  noImage: string;

  /**
   * assetsファイルへのパス(定数)
   *
   * @type {string}
   * @memberof NewsCategoryListComponent
   */
  readonly ASSETS_NO_IMAGE: string = CommonFunctionModule.getAssetsUrl('/image/common/no-image.png');

  /**
   * 配下のswiper-images.componentすべて
   *
   * @type {QueryList<SwiperImagesComponent>}
   * @memberof ExpComponent
   */
  @ViewChildren(SwiperImagesComponent) swiperImages: QueryList<SwiperImagesComponent>;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of NewsCategoryListComponent.
   * @param {ApplicationMessageService} appMsgServ
   * @param {HttpErrorResponseParserService} errResServ
   * @param {NewsWebApiService} newsServ
   * @param {HistoryWebApiService} historyServ
   * @param {OnsNavigator} navigator
   * @param {UseListComponent} useListComp
   * @memberof NewsCategoryListComponent
   */
  constructor(
    private appMsgServ: ApplicationMessageService,
    private errResServ: HttpErrorResponseParserService,
    private newsServ: NewsWebApiService,
    private historyServ: HistoryWebApiService,
    private navigator: OnsNavigator,
    private useListComp: UseListComponent,
    private municipalityWebApiServ: MunicipalityWebApiService,
  ) { }

  /**
   * 初期化処理。
   *
   * @memberof NewsCategoryListComponent
   */
  ngOnInit(): void {

    // noImage画像
    this.noImage = this.ASSETS_NO_IMAGE;

    // ページ表示時にカテゴリ情報読み込み
    const page = document.querySelector('ons-page[news-category-list]');
    page.addEventListener('show', () => {
      // カテゴリ一覧を取得
      this.getCategories();
    });

    // タブクリックで気象・防災関連サイトを読み込み
    this.tabClickBusy = this.useListComp.tabClickSubject.subscribe({
      next: (tag: UseListTabTag) => {
        if (tag === 'news') {
          if (this.navigator.element.pages.length === 1) {
            // 気象・防災関連サイトを取得
            this.disasterSiteList = this.municipalityWebApiServ.getDisasterPreventionSites();
          }
        }
      }
    });
  }

  /**
   * 描画後の初期化処理。
   *
   * @memberof NewsCategoryListComponent
   */
  ngAfterViewInit(): void {
    // リンクからの遷移処理-----------------------------------------------
    this.pushNewsSubscription = this.useListComp.pushNews.subscribe({
      next: (value: {freeword?: string}) => {
        if (value) {
          // まちニュース一覧画面へ遷移
          this.navigator.element.pushPage(NewsArticleListComponent, {
            animation: 'fade-ios',
            data: {
              freeword: value.freeword,
              swiperInit: () => {this.swiperInit()}
            }
          });
        }
      }
    })
  }

  /**
   * 破棄処理。
   *
   * @memberof NewsCategoryListComponent
   */
  ngOnDestroy(): void {

    this.busy?.unsubscribe();
    this.tabClickBusy?.unsubscribe();
    this.pushNewsSubscription?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * カテゴリ選択時のイベントハンドラ。
   *
   * @param {NewsCategory} category
   * @memberof NewsCategoryListComponent
   */
  onSelected(category: NewsCategory): void {

    // 選択したカテゴリを検索フォームに設定
    this.freeword = "#" + category.name;

    // ニュース一覧へ
    this.navigator.element.pushPage(NewsArticleListComponent, { data: { freeword: this.freeword }});
  }

  /**
   * 検索フォームフォーカス時のイベントハンドラ。
   *    検索履歴を表示する。
   *
   * @memberof NewsCategoryListComponent
   */
   onSearchFocus(): void {

    // 検索履歴取得、表示
    if (this.oneFocus === false) {
      this.searchHistory = [];
      this.getSearchHistory(CONST.Search.HISTORY);
    }

    // フォーカスがある状態でのclickイベント無効化
    this.oneFocus = true;
  }

  /**
   * 検索フォームからフォーカスが外れた際のイベントハンドラ。
   *
   * @memberof NewsCategoryListComponent
   */
  onSearchBlur(): void {

    // clickイベントを有効化
    this.oneFocus = false;
  }

  /**
   * 検索履歴選択時のイベントハンドラ。
   *
   * @param {MatAutocompleteSelectedEvent} event
   * @memberof NewsCategoryListComponent
   */
  onSearchHistorySelect(event: MatAutocompleteSelectedEvent): void {

    // 検索履歴パネルを閉じる
    this.autoComplete.closePanel();

    // 選択項目でフリーワード検索
    this.onSearch();
  }

  /**
   * 検索履歴削除ボタン押下時のイベントハンドラ。
   *
   * @param {string} word
   * @param {boolean} [all]
   * @memberof NewsCategoryListComponent
   */
  onDeleteSearchHistory(event: any, index: number, word: string, all?: boolean): void {

    // clickイベントの伝播を防止
    event.stopPropagation();
    event.preventDefault();

    const query = { word: word, all: all };
    if (all === true) delete query.word;
    if (all === undefined) delete query.all;

    // 検索履歴削除
    this.deleteSearchHistory(query, index);
  }

  /**
   * フリーワード検索実施時のイベントハンドラ。
   *
   * @memberof NewsCategoryListComponent
   */
  onSearch(): void {

    // 全角スペースを半角に置換、前後のスペース除去
    let word = this.freeword.replace(/　/g, " ");
    this.freeword = word.trim();

    if (this.freeword === "") this.freeword = undefined;

    if (this.categories.length > 0) {
      // ニュース一覧へ
      this.navigator.element.pushPage(NewsArticleListComponent, { data: { freeword: this.freeword }});
    }

    // 検索履歴パネルを閉じる
    this.autoComplete.closePanel();

    // INPUTタグからフォーカスを外す
    document.getElementById("newsHistoryInput").blur();
  }

  /**
   * 気象・防災関連情報を表示
   *
   * @memberof NewsCategoryListComponent
   */
  viewDisasterList(): void {
    this.navigator.element.pushPage(DisasterPreventionRelateSiteListComponent, { data: { site: this.disasterSiteList }});
  }

  /**
   * 利用規約を表示
   *
   * @memberof NewsCategoryListComponent
   */
  viewTermsOfService(): void {
    window.open(this.municipalityWebApiServ.setting.term);
  }

  //=============================================================================================
  // メソッド
  //=============================================================================================

  /**
   * トップ画面にons-back-buttonで戻ってきた際に
   *  swiperスライダーの初期化を行う。
   *
   * @memberof ExpComponent
   */
  swiperInit() {
    // 子のswiperをすべて初期化
    this.swiperImages.toArray().forEach(e => {
      e.swiperInit();
    })
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * カテゴリ情報を取得する。
   *
   * @private
   * @memberof NewsCategoryListComponent
   */
  private getCategories(): void {

    this.busy = this.newsServ.getCategories().subscribe({
      next: (res: { body: NewsCategory[]; }) => {

        // カテゴリがない＝ニュースが1件もない
        if (res.body.length === 0) {
          this.categories = undefined;
          return;
        }

        this.categories = res.body;

        // 記事数が1件以上あるカテゴリのみ表示対象
        this.categories = this.categories.filter(e => e.count>=1);

        // IDで昇順ソート
        this.categories.sort((a, b) => a.index - b.index);
      },
      error: this.errResServ.doParse((_err, errContent) => { this.errResServ.viewErrDialog(errContent); })
    });
  }

  /**
   * 検索履歴を取得する。
   *
   * @private
   * @param {number} limit
   * @memberof NewsCategoryListComponent
   */
  private getSearchHistory(limit: number): void {

    this.busy = this.historyServ.getSearchHistory("article", limit).subscribe({
      next: (res: { body: string[]; }) => { this.searchHistory = res.body; },
      error: this.errResServ.doParse((_err, errContent) => { this.errResServ.viewErrDialog(errContent); })
    });
  }

  /**
   * 検索履歴を削除する。
   *
   * @private
   * @param {{ word?: string, all?: boolean }} query
   * @param {number} index
   * @memberof NewsCategoryListComponent
   */
  private deleteSearchHistory(query: { word?: string, all?: boolean }, index: number): void {

    this.busy = this.historyServ.deleteSearchHistory("article", query).subscribe({
      next: () => { index === -1 ?  this.searchHistory = [] : this.searchHistory.splice(index, 1); },
      error: this.errResServ.doParse((_err, errContent) => { this.errResServ.viewErrDialog(errContent); })
    });
  }
}
