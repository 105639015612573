<ons-toolbar>
  <div class="left ">
    <ons-back-button></ons-back-button>
  </div>
  <div class="center">移動プラン詳細</div>
</ons-toolbar>

<div class="content">
  <div [ngBusy] = "m_Busy"></div>

  <!-- サマリ -->
  <div class="condition">
    <dl class="condition-item__text">
      <dt>日　時：</dt>
      <dd>{{ planner.date | chatDate: "YYYY/M/D" }}
          {{ reservation.o.schd_time | chatDate: "HH:mm" }} -
          {{ reservation.d.schd_time | chatDate: "HH:mm" }}({{ moment.duration(moment(reservation.d.schd_time).startOf('minutes').diff(moment(reservation.o.schd_time).startOf('minutes'))).format('h[時間]m[分]') }})
      </dd>
    </dl>
    <dl class="condition-item__text">
      <dt>利用者：</dt>
      <dd>{{ passengersFullNames.join(' 様、') }} 様</dd>
    </dl>
    <!-- TODO: 『荷物』に関する然るべき内容を表示する。 -->
    <!-- <p class="condition__text">荷物　：{{reservation.baggages}}</p> -->
    <dl class="condition-item__text">
      <dt>乗　換：</dt>
      <dd>{{ reservation.trans_count - 1 }}回</dd>
    </dl>
    <dl class="condition-item__text">
      <dt>料　金：</dt>
      <dd>
        {{ passengersPrice }}
        <!-- <span id="search-result-detail_001" (click)="showPassengerFare()" class="button-text">こちら</span>より詳細を確認できます。 -->
      </dd>
    </dl>
  </div>

  <div class="plan-wrap">
    <ons-list *ngFor="let section of reservation.sections; index as i; first as isFirst; last as isLast">

      <!--本文-->
      <ons-list-item *ngIf="isFirst" tappable modifier="longdivider chevron noPadding" class="plan-item">
        <ng-container *ngTemplateOutlet="sectionPoint; context: {od: 'o'}"></ng-container>
      </ons-list-item>

      <!--本文-->
      <ons-list-item *ngIf="section.tickets" modifier="longdivider" [attr.class]="'plan-item' + ' ' + 'plan-item__trans-' + section.trans">
        <ng-container *ngTemplateOutlet="sectionTickets; context: {section: section}"></ng-container>
      </ons-list-item>
      <ons-list-item *ngIf="!section.tickets" modifier="longdivider" [attr.class]="'plan-item' + ' ' + 'plan-item__trans-' + section.trans">
        <ng-container *ngTemplateOutlet="sectionTickets; context: {section: section}"></ng-container>
      </ons-list-item>
      <ons-list-item tappable modifier="longdivider chevron noPadding" class="plan-item">
        <ng-container *ngTemplateOutlet="sectionPoint; context: {od: 'd'}"></ng-container>
      </ons-list-item>

      <!-- template -->

      <!--地点テンプレート-->
      <ng-template #sectionPointDetail let-od="od">
        <ng-container [ngSwitch]="od">

          <ng-container *ngSwitchCase="'o'">
            <ons-row vertical-align="center">
              <ons-col width="64px" class="plan-item__section-schd-time">
                <ons-row>
                  <ons-col>{{ section.o.schd_time | chatDate: "HH:mm" }}発</ons-col>
                </ons-row>
              </ons-col>
              <ons-col width="16px">
                <ons-icon icon="fa-circle"></ons-icon>
              </ons-col>
              <ons-col class="plan-item__section-name">{{ section.o.name }}</ons-col>
            </ons-row>
          </ng-container>

          <ng-container *ngSwitchCase="'d'">
            <ons-row vertical-align="center">
              <ons-col width="64px" class="plan-item__section-schd-time">
                <ons-row>
                  <ons-col>{{ section.d.schd_time | chatDate: "HH:mm" }}着</ons-col>
                </ons-row>
                <ons-row *ngIf="!isLast">
                  <ons-col>{{ reservation.sections[i + 1].o.schd_time | chatDate: "HH:mm" }}発</ons-col>
                </ons-row>
              </ons-col>
              <ons-col width="16px">
                <ons-icon icon="fa-circle"></ons-icon>
              </ons-col>
              <ons-col class="plan-item__section-name">{{ section.d.name }}</ons-col>
            </ons-row>
          </ng-container>
        </ng-container>
      </ng-template>

      <ng-template #sectionPoint let-od="od">
        <a *ngIf="section[od].location; else noLink"
            [href]="'https://www.google.com/maps/search/?api=1&query=' + section[od].location.lat + ',' + section[od].location.lng + '&z=20'"
            class="list-item__link" target="_blank">
            <ng-container *ngTemplateOutlet="sectionPointDetail; context: {od: od}"></ng-container>
        </a>
        <ng-template #noLink>
          <div class="list-item__link">
            <ng-container *ngTemplateOutlet="sectionPointDetail; context: {od: od}"></ng-container>
          </div>
        </ng-template>
      </ng-template>

      <!-- 区間テンプレート -->
      <ng-template #sectionTickets let-section="section">
        <ons-row vertical-align="center">
          <ons-col width="64px" class="plan-item__section-trans">
            <img [src]="reservationTransMap.get(section.trans).get('icon')" />
          </ons-col>
          <ons-col class="plan-item__text">
            <div class="plan-item__section-description">
              <div>
                <span>{{ moment.duration(moment(section.d.schd_time).startOf('minutes').diff(moment(section.o.schd_time).startOf('minutes'))).format('h[時間]m[分]', { minValue: 1 }) }}</span>
                <span *ngIf="(section.distance | typeof) == 'number'">({{ section.distance | number:'.0-0' }}m)</span>
              </div>
              <div *ngIf="(section.car_no | typeof) == 'number'">車両番号：{{ section.car_no }}</div>
              <div *ngIf="!!section.comment" class="plan-item__section-comment">{{ section.comment }}</div>
            </div>
          </ons-col>
        </ons-row>
      </ng-template>


    </ons-list>
  </div>

  <!-- 補足 -->
  <div *ngIf="isWalkingSection === true" class="reservation-comment">
    <ons-icon class="reservation-comment-item__icon" icon="fa-exclamation-circle"></ons-icon>
    <p class="reservation-comment-item__text">徒歩移動の時間・距離は、実際の状況を反映していない場合があるのでご注意ください。</p>
  </div>

  <!-- 予約へ -->
  <div class="button-wrap">
    <ons-button id="search-result-detail_002" [disabled]="(reservation.index | typeof) != 'number'" (click)="decide()" modifier="large--cta">予約に進む</ons-button>
  </div>


</div>
