//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { OnsNavigator, Params } from 'ngx-onsenui';
import * as CONST from '../../../constants/constant';
import * as moment from 'moment';
import * as uuid from 'uuid';

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { UserWebApiService } from '../../../http-services/user-web-api.service';
import { ShoppingWebApiService } from "../../../http-services/shopping-web-api.service";
import { OrderRequestService, CartAddedItems } from "../../../lib-services/order-request.service";
import { SelectHistoryWebApiService } from '../../../http-services/select-history-web-api.service';

// component
import { TabbarComponent } from "../../../components/tabbar/tabbar.component";
import { ShoppingMenuDetailComponent, menuProcMode } from '../shopping-menu-detail/shopping-menu-detail.component';
import { ShoppingCartComponent } from '../shopping-cart/shopping-cart.component';

// interface
import { Shop, ShoppingMenu, UserInfo } from '../../../interfaces/response';
import { MenuSelectHistory } from 'src/app/http-services/select-history-web-api.service';
import { MESSAGE } from 'src/app/constants/message';


//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * メニュー一覧。
 *
 * @export
 * @class ShoppingMenuListComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ons-page[shopping-menu-list]',
  templateUrl: './shopping-menu-list.component.html',
  styleUrls: ['./shopping-menu-list.component.css']
})
export class ShoppingMenuListComponent implements OnInit, OnDestroy {

//=============================================================================================
// メンバ変数
//=============================================================================================

  /**
   * 通信用Subscription
   *
   * @type {Subscription}
   * @memberof NewsArticleListComponent
   */
  busy: Subscription;

  /**
   * 注文先店舗
   *
   * @type {Shop}
   * @memberof ShoppingMenuListComponent
   */
  shop: Shop;

  /**
   * 商品一覧
   *
   * @type {ShoppingMenu[]}
   * @memberof ShoppingMenuListComponent
   */
  shopMenus: ShoppingMenu[] = [];

  /**
   * カート内商品一覧
   *
   * @type {CartAddedItems[]}
   * @memberof ShoppingMenuListComponent
   */
  cartItem: CartAddedItems[] = [];

  /**
   * メニュー一覧画面のpageIndex
   *
   * @type {number}
   * @memberof ShoppingMenuListComponent
   */
  menuListPageIndex: number;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of ShoppingMenuListComponent.
   * @param {ApplicationMessageService} appMsgServ
   * @param {HttpErrorResponseParserService} errResServ
   * @param {UserWebApiService} userServ
   * @param {ShoppingWebApiService} shoppingServ
   * @param {OrderRequestService} orderServ
   * @param {TabbarComponent} tabComp
   * @param {ChangeDetectorRef} cd
   * @param {OnsNavigator} navigator
   * @param {Params} params
   * @memberof ShoppingMenuListComponent
   */
  constructor(
    private appMsgServ: ApplicationMessageService, 
    private errResServ: HttpErrorResponseParserService, 
    private userServ: UserWebApiService, 
    private shoppingServ: ShoppingWebApiService, 
    private orderServ: OrderRequestService, 
    private tabComp: TabbarComponent, 
    private cd: ChangeDetectorRef, 
    private navigator: OnsNavigator, 
    private params: Params,
    private selectHistoryServ: SelectHistoryWebApiService,
    private msg: MESSAGE,
  ) { }

  /**
   * 初期処理。
   *
   * @memberof ShoppingComponent
   */
  ngOnInit(): void {

    this.shop = this.params.data.shop;
    this.cartItem = this.orderServ.getCartItems;

    // 商品一覧を取得
    this.getShopItem();

    // メニュー一覧画面のpageIndexを保存
    this.menuListPageIndex = this.navigator.element.pages.length - 1;
  }

  /**
   * 破棄処理。
   *
   * @memberof ShoppingComponent
   */
  ngOnDestroy(): void {

    this.busy?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * メニュー選択時のイベントハンドラ。
   *
   * @param {number} index
   * @memberof ShoppingMenuListComponent
   */
  onMenuSelect(index: number): void {

    // 処理状態
    const procMode: menuProcMode = {
      mode: this.orderServ.getItemCount(this.shopMenus[index].menu_id) > 0 ? "update" : "add",
      delete_permit: false
    };

    // メニュー詳細へ遷移
    this.navigator.element.pushPage(ShoppingMenuDetailComponent, {
      data: { 
        shop: this.shop,
        menu: this.shopMenus[index],
        method: () => this.redraw(),
        detailMode: procMode
      }
    });

    // --------------------------------------------------
    // 操作履歴の送信
    // --------------------------------------------------

    // ログインユーザー情報
    const loginUserInfo: UserInfo = this.userServ.getUserInfo();

    // メニュー選択の操作履歴送信用データを作成
    const selectHistory: MenuSelectHistory = {
      id: uuid.v4(),
      user_id: loginUserInfo?.user_id,
      date: moment().toISOString(),
      type: "menu",
      service: "shopping",
      menu: {
        shop_id: Number(this.shop.shop_id),
        menu_id: Number(this.shopMenus[index].menu_id)
      }
    }

    // メニュー選択の操作履歴をサービスに追加
    this.selectHistoryServ.addHistory(selectHistory);
  }

  /**
   * カート表示ボタンのイベントハンドラ。
   *
   * @memberof ShoppingMenuListComponent
   */
  onViewCart(): void {
    
    // タブバー非表示
    this.tabComp.setTabbarVisibility(false);

    // カートへ遷移
    this.navigator.element.pushPage(ShoppingCartComponent, {
      animation: "lift", 
      data: { 
        class: this.params.data.class,
        menuListPageIndex: this.navigator.element.pages.length - 1,
        redraw: () => { this.redraw(); },
        returnMenuList: () => { this.returnMenuList() }
      }
    });
  }

  /**
   * カート内の商品個数を取得する。
   *
   * @return {*}  {number}
   * @memberof ShoppingMenuListComponent
   */
  getCartItemCount(): number {

    return this.orderServ.getCartItemCount;
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * 画面の再描画を行う。
   *
   * @memberof ShoppingCartComponent
   */
  redraw(): void {

    this.cartItem = this.orderServ.getCartItems;
    this.cd.detectChanges();
  }

  /**
   * ダイアログを表示し、メニュー一覧画面に戻る。
   *
   * @memberof ShoppingMenuListComponent
   */
  returnMenuList() : void {
    this.appMsgServ.viewDialogMessage(this.msg.CLIENT.SHOPPING.CART_ALL_DELETE.message(), () => this.params.data.class.refresh(this.menuListPageIndex));
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * 店舗の商品一覧を取得する。
   *
   * @private
   * @memberof ShoppingMenuListComponent
   */
  private getShopItem(): void {

    this.busy = this.shoppingServ.getShopMenu(this.shop.shop_id).subscribe({ 
      next: res => { this.shopMenus = res.body; },
      error: this.errResServ.doParse((_err, errContent) => { this.errResServ.viewErrDialog(errContent); })
    });
  }
}
