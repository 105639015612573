//=============================================================================================
// インポート
//=============================================================================================
import { Component, Input, OnInit } from '@angular/core';
import { OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { nextTick } from 'process';
import { EnlargeImage } from 'src/app/lib-modules/viewer';
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * CarouselParts
 *
 * @export
 * @class CarouselComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'parts-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {

  /**
   * 最大幅
   *
   * @private
   * @type {number}
   * @memberof CarouselComponent
   */
  readonly MAX_WIDTH: number = 640;

  /**
   * 高さ比率
   *
   * @private
   * @type {number}
   * @memberof CarouselComponent
   */
  readonly HEIGHT_RATIO: number = 0.75; // 4:3

  /**
   * 画像一覧
   *
   * @type {string[]}
   * @memberof CarouselComponent
   */
  @Input() images: string[] = [];

  /**
   * 選択している画像
   *
   * @type {string}
   * @memberof CarouselComponent
   */
  selectedCarouselImage: string;

  /**
   * 最大髙
   *
   * @type {number}
   * @memberof CarouselComponent
   */
  carouselMaxHeight: number;

  /**
   * カルーセルオプション
   *
   * @type {OwlOptions}
   * @memberof CarouselComponent
   */
  carouselOwlOptions: OwlOptions = {
    items: 1,
    nav:true,
    navText:["<div class='nav-btn prev-slide'></div>","<div class='nav-btn next-slide'></div>"],
    dots:false,
    margin:8,
    autoWidth:true,
    responsive: {
      0: {
        items: 1
      },
    }
  }

  /**
   * サムネイルオプション
   *
   * @type {OwlOptions}
   * @memberof CarouselComponent
   */
  thumbsOwlOptions: OwlOptions = {
    items: 3,
    dots:false,
    margin:5,
    autoWidth:true,
  }

  /**
   * 画像ビューワ
   *
   * @private
   * @type {any}
   * @memberof CarouselComponent
   */
  viewer: any;

  /**
   * 商品画像がない場合、表示する画像
   *
   * @type {string}
   * @memberof CarouselComponent
   */
  noImage: string = CommonFunctionModule.getAssetsUrl('/image/common/49-Noimage.png');

  //=============================================================================================
  // ライフサイクルメソッド
  //=============================================================================================

  /**
   * 初期化処理。
   *
   * @memberof CarouselComponent
   */
  ngOnInit(): void {
    this.viewer = new EnlargeImage(this.images);

    // カルーセル画像高さ調整
    let carouselWidth = window.innerWidth > this.MAX_WIDTH ? this.MAX_WIDTH : window.innerWidth;
    this.carouselMaxHeight = carouselWidth * this.HEIGHT_RATIO;
  }

  //=============================================================================================
  // メソッド
  //=============================================================================================

  /**
   * 画像選択。
   *
   * @memberof CarouselComponent
   */
  changeCarouselImage(image: string) {
    this.selectedCarouselImage = image;
  }


  /**
   * 画像クリック。拡大表示。
   *
   * @memberof CarouselComponent
   */
  onEnlargeImage() {
    const imgV: HTMLElement = document.getElementById("carouselViewerImages");
    this.viewer.enlargeImage(imgV);
  }
}
