<ons-toolbar>
  <div class="left">
    <ons-back-button></ons-back-button>
  </div>
  <div class="center">ファミリー</div>
  <div class="right content--text--around"><span id="family_001" *ngIf="m_FamilyRelations?.length > 0" class="help" (click)="viewDescription()">？</span></div>
</ons-toolbar>
<div class="content">
  <div [ngBusy]="m_Busy"></div>
  <div *ngIf="m_FamilyRelations?.length > 0; else noFamily">
    <!-- 管理者用 -->
    <div *ngIf="m_LoginUserRole == 'parent'">
      <ons-list>
        <!-- 正式メンバー -->
        <ons-list-header modifier="color" class="list-item__title">あなたのファミリーメンバー</ons-list-header>
        <div *ngIf="m_MenberStatusList[0].length == 0; else onMenber">
          <ons-list-item modifier="longdivider" class="list-item__text">現在メンバーはいません</ons-list-item>
        </div>
        <ng-template #onMenber>
          <ng-container *ngFor="let item of m_MenberStatusList[0]">
            <ons-list-item modifier="chevron longdivider" class="list-item__text" tappable (click)=pushDetail(item)>
              {{ item.name.family_name }} {{item.name.given_name}}
              <span *ngIf="item.is_alt === true" class="content--text--alt">（見守り対象）</span>
              <div *ngIf="item.status === 'request' || item.status === 'waiting'">
                <div class="badge--request">リンク解除<br>申請中</div>
              </div>
            </ons-list-item>
          </ng-container>
        </ng-template>
        <!-- 招待承認待ち -->
        <div *ngIf="m_MenberStatusList[1].length > 0">
          <ons-list-header modifier="color" class="list-item__title">招待承認待ち</ons-list-header>
          <ng-container *ngFor="let item of m_MenberStatusList[1]">
            <ons-list-item  modifier="longdivider" class="list-item__text">ID：{{ item.user_id }}</ons-list-item>
          </ng-container>
        </div>
      </ons-list>
      <div class="button-wrap">
        <ons-button id="family_002" class="button button--large--cta" (click)="pushAdd()">ファミリーを追加</ons-button>
      </div>
    </div>

    <!-- メンバー用 -->
    <div *ngIf="m_LoginUserRole == 'child'">
      <ons-list>
        <ons-list-header modifier="color" class="list-item__title">あなたの管理者</ons-list-header>
        <!-- 管理者 -->
        <ons-list-item id="family_003" modifier="chevron longdivider" class="list-item__text" *ngIf="m_FamilyRelations[0].status !== 'request'" tappable (click)="pushDetail(m_FamilyRelations[0])">
          <img hidden class="list-icon" [src]="ASSETS_NUMBER" />
          {{ m_FamilyRelations[0].name.family_name }} {{m_FamilyRelations[0].name.given_name}}
          <div *ngIf="m_FamilyRelations[0].status === 'waiting'">
            <div class="badge--request">リンク解除<br>申請中</div>
          </div>
        </ons-list-item>
        <!-- 管理者承認 -->
        <ng-container *ngIf="m_FamilyRelations[0].status === 'request'">
          <ons-list-item modifier="chevron longdivider" class="list-item-notification" *ngFor="let family of m_FamilyRelations" tappable (click)="pushConfirm(family)">
            <div class="list-item__text divided">「{{ family.name.family_name }} {{family.name.given_name}}」さんよりファミリーリンク申請が届いています</div>
          </ons-list-item>
        </ng-container>
      </ons-list>
    </div>
  </div>
  
  <!-- 初期表示画面 -->
  <ng-template #noFamily>
    <div class="content--list--description content--text--around">
      ファミリー機能とは？　詳しくは
      <ons-button modifier="quiet" (click)="viewDescription()"><span class="button-text">こちら</span></ons-button>をご覧ください。
      <br>
      ファミリー登録された情報は、他地域のSmartGOTOの利用開始時に引き継がれます。注意事項は
      <ons-button modifier="quiet" (click)="viewFamilyCautions()"><span class="button-text">こちら</span></ons-button>に記載しておりますので、必ずご確認ください。
    </div>
    <ons-list>
      <ons-list-header modifier="color" class="list-item__title">ファミリー管理者として繋がりたい場合</ons-list-header>
      <ons-list-item modifier="longdivider">
        <div class="button-wrap">
          <ons-button class="button button--large--cta" (click)="pushAdd()">ファミリーを追加</ons-button>
        </div>
      </ons-list-item>
      <ons-list-header modifier="color" class="list-item__title">ファミリーメンバーとして繋がりたい場合</ons-list-header>
      <ons-list-item modifier="longdivider">
        <div class="content--list--description">
          QRコード表示タブより自身のQRコードを表示し、管理者アカウントより読み取り・リンク申請を行ってもらってください。<br>
          申請後、本画面に申請通知が表示されます。
        </div>
      </ons-list-item>
    </ons-list>
  </ng-template>
</div>