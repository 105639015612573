import { ModuleWithProviders, NgModule } from '@angular/core';
import { SqrcWebApiServiceConfig } from '../http-services/sqrc-web-api.service';

/**
 * ユーティリティサービス (顔認証 QR コード関連) に依存性を注入するためのインジェクターを構成するモジュール。
 *
 * @export
 * @class SqrcWebApiModule
 */
@NgModule()
export class SqrcWebApiModule {
  /**
   * DI トークンと依存性の関連付けを行う。
   *
   * @static
   * @param {SqrcWebApiServiceConfig} config Web API (顔認証 QR コード関連) の構成設定。
   * @return {*}  {ModuleWithProviders<SqrcWebApiModule>} `ModuleWithProviders<SqrcWebApiModule>`
   * @memberof SqrcWebApiModule
   */
  static withConfig(config: SqrcWebApiServiceConfig): ModuleWithProviders<SqrcWebApiModule> {
    return {
      ngModule: SqrcWebApiModule,
      providers: [
        { provide: SqrcWebApiServiceConfig, useValue: config }
      ]
    };
  }
}
