import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

/**
 * Web API (マイスポット関連) の構成設定。
 *
 * @export
 * @class FavoriteWebApiServiceConfig
 */
export class FavoriteWebApiServiceConfig {
  /**
   * Web API の基底 URL。
   *
   * @type {string}
   * @memberof FavoriteWebApiServiceConfig
   */
  baseUrl: string;

  /**
   * HTTP リクエストと一緒に送信されるオプション。
   *
   * @type {({
   *     headers?: HttpHeaders | {
   *       [header: string]: string | string[];
   *     };
   *   })}
   * @memberof FavoriteWebApiServiceConfig
   */
  httpOptions?: {
    /**
     * HTTP ヘッダー。
     *
     * @type {(HttpHeaders | {
     *       [header: string]: string | string[];
     *     })}
     */
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
  };
}

/**
 * Web API (マイスポット関連) の呼び出しを簡略化するためのユーティリティ。
 *
 * @export
 * @class FavoriteWebApiService
 */
@Injectable({
  providedIn: 'root'
})
export class FavoriteWebApiService {
  private favoriteSubject = new Subject<FavoriteWebApiService.FavoriteChangedEvent>();

  /**
   * Creates an instance of FavoriteWebApiService.
   * @param {HttpClient} http HTTP リクエストを実行するためのサービス。
   * @param {FavoriteWebApiServiceConfig} config Web API (マイスポット関連) の構成設定。
   * @memberof FavoriteWebApiService
   */
  constructor(
    private http: HttpClient,
    private config: FavoriteWebApiServiceConfig
  ) { }

  /**
   * マイスポットの一覧を取得する。
   *
   * @template T レスポンスの型。
   * @return {*}  {Observable<HttpResponse<T[]>>} レスポンスの受信を監視するための `Observable`。
   * @memberof FavoriteWebApiService
   */
  allFavorites<T>(): Observable<HttpResponse<T[]>> {
    return this.http.get<T[]>(`${this.config.baseUrl}`, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }

  /**
   * マイスポットを取得する。
   *
   * @template T
   * @param {number} favorite_id 取得するマイスポットの ID。
   * @return {*}  {Observable<HttpResponse<T>>} レスポンスの受信を監視するための `Observable`。
   * @memberof FavoriteWebApiService
   */
  findFavorite<T>(favorite_id: number): Observable<HttpResponse<T>> {
    return this.http.get<T>(`${this.config.baseUrl}/${favorite_id}`, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }

  /**
   * マイスポットを新規登録する。
   *
   * @param {*} favorite 登録するマイスポットの内容。
   * @return {*}  {Observable<HttpResponse<any>>} レスポンスの受信を監視するための `Observable`。
   * @memberof FavoriteWebApiService
   */
  createFavorite(favorite: any): Observable<HttpResponse<any>> {
    return this.http.post(`${this.config.baseUrl}`, favorite, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    }).pipe(tap({
      next: _response => {
        this.favoriteSubject.next({ type: 'created' });
      }
    }));
  }

  /**
   * マイスポットを更新する。
   *
   * @param {number} favorite_id 更新するマイスポットの ID。
   * @param {*} favorite 更新するマイスポットの内容。
   * @return {*}  {Observable<HttpResponse<any>>} レスポンスの受信を監視するための `Observable`。
   * @memberof FavoriteWebApiService
   */
  updateFavorite(favorite_id: number, favorite: any): Observable<HttpResponse<any>> {
    return this.http.put(`${this.config.baseUrl}/${favorite_id}`, favorite, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    }).pipe(tap({
      next: _response => {
        this.favoriteSubject.next({
          type: 'updated',
          favoriteId: favorite_id
        });
      }
    }));
  }

  /**
   * マイスポットを削除する。
   *
   * @param {number} favorite_id 削除するマイスポットの ID。
   * @return {*}  {Observable<HttpResponse<any>>} レスポンスの受信を監視するための `Observable`。
   * @memberof FavoriteWebApiService
   */
  deleteFavorites(favorite_id: number): Observable<HttpResponse<any>> {
    return this.http.delete(`${this.config.baseUrl}/${favorite_id}`, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    }).pipe(tap({
      next: _response => {
        this.favoriteSubject.next({
          type: 'deleted',
          favoriteId: favorite_id
        });
      }
    }));
  }

  /**
   * マイスポットの表示順を変更する。
   *
   * @param {number[]} favorite_ids 順序付けられたマイスポットの ID のリスト。
   * @return {*}  {Observable<HttpResponse<any>>} レスポンスの受信を監視するための `Observable`。
   * @memberof FavoriteWebApiService
   */
  sortFavorites(favorite_ids: number[]): Observable<HttpResponse<any>> {
    return this.http.put(`${this.config.baseUrl}/sort`, favorite_ids, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    }).pipe(tap({
      next: _response => {
        this.favoriteSubject.next({ type: 'sorted' });
      }
    }));
  }

  /**
   *
   * @param favorites
   * @param places
   * @param transformFunc
   *
   * @deprecated
   */
  transform<TFavorite extends { place_id: string; }, TPlace extends { place_id: string; }, TResult>(
    favorites: TFavorite[],
    places: TPlace[],
    transformFunc: (originalFavorite: TFavorite, originalPlace: TPlace) => TResult
  ): TResult[] {
    return favorites
      .map(favorite => {
        return {
          favorite: favorite,
          place: places.find(place => favorite.place_id == place.place_id)
        }
      })
      .filter(a => !!a.place)
      .map(a => transformFunc(a.favorite, a.place));
  }

  /**
   * マイスポットの変更を監視するための `Observable` を取得する。
   *
   * @readonly
   * @type {Observable<FavoriteWebApiService.FavoriteChangedEvent>}
   * @memberof FavoriteWebApiService
   */
  get favoriteChanged(): Observable<FavoriteWebApiService.FavoriteChangedEvent> {
    return this.favoriteSubject.asObservable();
  }
}

export namespace FavoriteWebApiService {
  /**
   * マイスポットに変更が発生した際に受け取るイベント。
   *
   * @export
   * @interface FavoriteChangedEvent
   */
  export interface FavoriteChangedEvent {
    /**
     * 発生した変更の種類。
     *
     * @type {('created' | 'updated' | 'deleted' | 'sorted')}
     * @memberof FavoriteChangedEvent
     */
    type: 'created' | 'updated' | 'deleted' | 'sorted';

    /**
     * 変更が発生したマイスポットの ID。
     *
     * @type {number}
     * @memberof FavoriteChangedEvent
     */
    favoriteId?: number;
  }
}

export namespace FavoriteWebApiSchemas.ResponseSchemas {
  /**
   * マイスポット。
   *
   * @export
   * @interface Favorite
   */
  export interface Favorite {
    /**
     * マイスポットの ID。
     *
     * @type {number}
     * @memberof Favorite
     */
    favorite_id?: number;

    /**
     * スポットの ID。
     *
     * @type {string}
     * @memberof Favorite
     */
    place_id?: string;

    /**
     * 乗降場 ID。
     *
     * @type {number}
     * @memberof Favorite
     */
    bussotp_id?: number;

    /**
     * 表示名 (重複時はサフィクス "- N" を付ける)。
     *
     * @type {string}
     * @memberof Favorite
     */
    name?: string;

    /**
     * 位置。
     *
     * @type {{
     *       lat: number;
     *       lng: number;
     *     }}
     * @memberof Favorite
     */
    location?: {
      /**
       * 緯度。
       *
       * @type {number}
       */
      lat: number;

      /**
       * 経度。
       *
       * @type {number}
       */
      lng: number;
    };

    /**
     * 
     *
     * @type {number}
     * @memberof Favorite
     */
    user_id?: string;

    /**
     *
     *
     * @type {number}
     * @memberof Favorite
     * @deprecated 「不要になったので省いています (https://nu-soft.slack.com/archives/C01GCM6M04E/p1614662413057200?thread_ts=1614662251.054900&cid=C01GCM6M04E)」とのこと。
     */
    index?: number;

    /**
     *「自宅」などのフラグ
     *
     * @type {('home' | null)}
     * @memberof Favorite
     */
    type?: 'home' | null;
  }
}
