<ons-toolbar class="toolbar">
  <div class="left">
    <ons-back-button #onsBackButton></ons-back-button>
  </div>
  <div *ngIf="mode == 'family'" class="center">管理者本人確認</div>
  <div *ngIf="mode == 'login'" class="center">認証コード入力</div>
</ons-toolbar>
<div class="content">
  <div [ngBusy]="busy"></div>
  <div *ngIf="mode == 'family'" class="center segment-wrap">
    <ons-segment class="segment" active-index="1">
      <button disabled>STEP1</button>
      <button>STEP2</button>
      <button disabled>STEP3</button>
    </ons-segment>
  </div>
  <!-- <ons-input id="login_001" [(value)]="username" hidden></ons-input> -->

  <p class="content--text"><ng-container *ngIf="mode == 'family'">管理者の</ng-container>ご本人確認のために、ご登録されている連絡先（{{twoFactorMethod}}）に認証コードを送信しました。</p>
  <p class="content--text--attention">
    ※Yahoo!メールなど一部のメールサービスでは、送信されたメールが迷惑メールに振り分けられる可能性があります。<br />
    メールが届かない場合は、迷惑メールをご確認ください。
  </p>
  <p class="content--text">届いた認証コードを入力してください。<br />※認証コードは30分間有効です。</p>

  <dl class="login__item">
    <dt class="login-title">認証コード：</dt>
    <dd class="login-data">
      <ons-input [(value)]="pincode" inputmode="numeric"></ons-input>
    </dd>
  </dl>
  <div class="button-wrap">
    <ons-button modifier="large--cta" (click)="onClickAuthButton()" [disabled]="!this.pincode">認証</ons-button>
  </div>
</div>