//=============================================================================================
// インポート
//=============================================================================================
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as qs from 'qs';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * ServiceConfig
 *
 * @export
 * @class HistoryWebApiServiceConfig
 */
export class HistoryWebApiServiceConfig {
  baseUrl: string;
  httpOptions?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
  };
}

/**
 * 履歴サービス。
 *
 * @export
 * @class HistoryWebApiService
 */
@Injectable({
  providedIn: 'root'
})
export class HistoryWebApiService {

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of HistoryWebApiService.
   * @param {HttpClient} http
   * @param {HistoryWebApiServiceConfig} config
   * @memberof HistoryWebApiService
   */
  constructor(
    private http: HttpClient, 
    private config: HistoryWebApiServiceConfig
  ) { }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * 検索履歴を取得する。
   *
   * @param {number} limit
   * @return {*}  {Observable<HttpResponse<string[]>>}
   * @memberof HistoryWebApiService
   */
  getSearchHistory(target: string, limit: number): Observable<HttpResponse<string[]>> {
    return this.http.get<string[]>(`${ this.config.baseUrl }/${ target }?${ qs.stringify(limit) }`, {
      ...this.config.httpOptions, 
      observe: 'response',  
      withCredentials: true
    });
  }

  /**
   * 検索履歴を削除する。
   *
   * @template T
   * @param {{word?: string, all?: boolean }} query
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof HistoryWebApiService
   */
  deleteSearchHistory<T>(target: string, query: { word?: string, all?: boolean }): Observable<HttpResponse<any>> {
    return this.http.delete<T>(`${ this.config.baseUrl }/${ target }?${ qs.stringify(query) }`, {
      ...this.config.httpOptions, 
      observe: 'response',
      withCredentials: true
    });
  }
}
