<p mat-dialog-title>[カレンダーの見方]</p>
<div mat-dialog-content>

  <table border="1" style="min-height: 12vh; min-width: 30vh;">
    <thead>
      <tr>
        <th style="text-align: left;">&nbsp;分類&nbsp;</th><th style="text-align: left;">&nbsp;意味</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td style="text-align: center; font-size: large; background-color: white;">〇</td>
        <td style="font-size: large;">&nbsp;貸出可</td>
      </tr>
      <tr>
        <td style="text-align: center; font-size: large; background-color: lightgray;">×</td>
        <td>
          <span style="font-size: large;">&nbsp;貸出不可 </span>
          <span style="font-size: smaller;">(空き無しor営業時間外)&nbsp;</span>
        </td>
      </tr>
    </tbody>
  </table>
  <div *ngIf="data.displayText">
    <p mat-dialog-footer>
      <a style="color: #009CD6; font-size: 8pt; font-weight: bold;" (click)="onAvailableDialogCautionLinkClick()">※画面上段で予約日時を選択してください。</a><br>
      <span style="font-size: 8pt;">カレンダーからは予約日時の選択はできません。</span>
    </p>
  </div>
</div>