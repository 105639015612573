<ons-toolbar>
  <div class="left ">
    <ons-back-button #onsBackButton></ons-back-button>
  </div>
  <div class="center">{{ getViewStatus() }}</div>
</ons-toolbar>

<div class="content">
  <div [ngBusy]="busy"></div>
  <ng-container *ngIf="getViewDeliveryResult() !== ''"><div class="content--message">{{ getViewDeliveryResult() }}​</div></ng-container>

  <!-- 店舗名 -->
  <ons-list class="list-item__title shop-info-wrap">
    <ons-list-item>
      <!-- 店舗アイコン/店舗名 -->
      <ons-row vertical-align="center">
        <ons-col width="120px" class="list-icon-col"><span *ngIf="order.shops[0].icon !== undefined && order.shops[0].icon !== ''; else noIcon"><img class="shop-info-wrap__img" [src]="order.shops[0].icon" /></span></ons-col>
        <ons-col id="history-order-detail_001">{{ order.shops[0].name }}</ons-col>
      </ons-row>
    </ons-list-item>
  </ons-list>

  <!--区間ごと、ユーザーごとの支払小計テンプレート-->
  <ng-template #detail let-item="item">
    <!-- 商品一覧 -->
    <ons-row vertical-align="center" class="list-item__menu" *ngFor="let item of order.items; index as i">
      <ons-col id="history-order-detail-menu-name_00{{i+1}}" width="50%">{{ item.menu.name }}
        <!-- オプションを追加する場合はここでitem.optionsを出力 -->
      </ons-col>
      <ons-col id="history-order-detail-menu-count_00{{i+1}}" width="10%" class="text--right">{{ item.count }}個</ons-col>
      <ons-col id="history-order-detail-menu-subtotal_00{{i+1}}" class="amount">￥{{ item.amount | number:'.0-0' }}</ons-col>
    </ons-row>
    <ons-row vertical-align="center" class="list-item__menu">
      <ons-col width="60%">配送料</ons-col>
      <ons-col id="history-order-detail_002" class="amount">￥{{ getOrderCharge() }}</ons-col>
    </ons-row>
  </ng-template>

  <!--支払人ごとの支払合計テンプレート-->
  <ng-template #total let-order="order">
    <div class="total">
      <div class="total-item__amount">お支払い料金(合計)：
        <span id="history-order-detail_003" class="amount">￥{{ order.amount | number:'.0-0' }}</span>
      </div>
      <div id="history-order-detail_004" *ngIf="order.amount > 0">請求先：{{ order.payer.name }} 様</div>
    </div>
  </ng-template>

  <!--本文-->
  <ons-list>
    <div class="list-item__caption">
      お支払い料金
      <!-- <ng-container *ngIf="customerBill.families.length > 0">(ファミリー)</ng-container> -->
    </div>
    <ons-list-item modifier="nodivider">
      <!-- <ng-container *ngFor="let item of order.items"> -->
        <ng-container *ngTemplateOutlet="detail"></ng-container>
      <!-- </ng-container> -->
      <!-- <ng-container *ngTemplateOutlet="passenger; context: {passenger: customerBill.user}"></ng-container> -->
    </ons-list-item>
    <ons-list-item modifier="nodivider">
      <ng-container *ngTemplateOutlet="total; context: { order: order }"></ng-container>
    </ons-list-item>
  </ons-list>

  <div class="list-item__caption">お届け先</div>
  <ons-list>
    <ons-list-item modifier="longdivider" class="deliveryMenu">
      <p>
        <ng-container *ngIf="order.destination.google_map_url; else notExistGoogleMap">
          <a class="list-item__link" target="_blank" [href]="order.destination.google_map_url">
            <div id="history-order-detail_005">{{ order.destination.address_components ? getDestinationName(order.destination.address_components.address) : order.destination.name }}</div>
          </a>
        </ng-container>
        <ng-template #notExistGoogleMap>
          <div id="history-order-detail_005">{{ order.destination.name }}</div>
        </ng-template>
      </p>
    </ons-list-item>
  </ons-list>

  <div class="list-item__caption">配達希望日時</div>
  <ons-list>
    <ons-list-item modifier="longdivider" class="deliveryMenu">
      <p id="history-order-detail_006">{{ moment(order.d.schd_time.from).format('M月D日 HH:mm') }}～{{ moment(order.d.schd_time.to).format('HH:mm') }}</p>
    </ons-list-item>
  </ons-list>

  <!--【UI改善対応】テキストからボタンに変更-->
  <!--<div *ngIf="isOrderCancel()" class="attention">-->
  <!--  注文をキャンセルする場合は<ons-button modifier="quiet" (click)="pushCancel()"><span id="history-order-detail_007" class="button-text">こちら</span></ons-button>-->
  <!--</div>-->
  <div *ngIf="isOrderCancel()" class="button-wrap">
    <ons-button id="history-order-detail_007" (click)="pushCancel()" modifier="cta">予約のキャンセル</ons-button>
  </div>
  
  <!-- template -->
  <!-- 店舗アイコンがない場合、汎用アイコンを表示 -->
  <ng-template #noIcon><mat-icon class="shop-info-wrap__mat-icon">store</mat-icon></ng-template>
</div>
