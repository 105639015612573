<ons-tabbar modifier="default">
  <div class="tabbar__content"></div>
    <div #tabbar class="tabbar"
      [style.--account-inactive]="ASSETS.ACCOUNT_INACTIVE" [style.--account-active]="ASSETS.ACCOUNT_ACTIVE"
    >
        <ons-tab id="tabbar_001" #serviceTab class="service" modifier="IconBgImg" label="利用する" data-tab="service" [page]="tab1" active></ons-tab>
        <ons-tab id="tabbar_002" #historyTab class="history" modifier="IconBgImg" label="予定・履歴" data-tab="history" [page]="tab2"></ons-tab>
        <ons-tab id="tabbar_003" #myqrTab class="myqr" modifier="IconBgImg" label="QRコード" data-tab="myqr" [page]="tab3" badge=""></ons-tab>
        <ons-tab [hidden]="isDisplayOperationTab==false" id="tabbar_004" class="operation" modifier="IconBgImg" label="運行状況" data-tab="operation" [page]="tab4"></ons-tab>
        <ons-tab id="tabbar_005" #accountTab class="setting guest" modifier="IconBgImg" label="マイページ" data-tab="account" [page]="tab5" badge=""></ons-tab>
        <!-- <ons-tab class="setting" modifier="IconBgImg" label="マイページ" data-tab="setting"
            [ngClass]="{'user' : isLoggedInUser, 'guest' : !isLoggedInUser}"
            [style.background-image]="isLoggedInUser ? 'url(' + imgSrc + ')':''" [page]="tab5">
        </ons-tab> -->
    </div>
</ons-tabbar>
