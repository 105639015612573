//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Params } from 'ngx-onsenui';
import * as moment from 'moment';
import * as CONST from '../../../constants/constant';
import * as PURCHASE from '../purchase';

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { PurchaseWebApiService } from '../../../http-services/purchase-web-api.service';
import { DispatchWebApiService } from '../../../http-services/dispatch-web-api.service';
import { FamilyWebApiService } from '../../../http-services/family-web-api.service';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';

// interface
import { common } from '../../../interfaces/common';
import { request } from '../../../interfaces/request';
import { ExUser, Ticket, Reservation } from '../../../interfaces/response';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 所有乗車券の確認画面
 *
 * @export
 * @class PurchaseTicketOwnedComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @author （株）オブジェクトデータ wada
 */
@Component({
  selector: 'ons-page[purchase-ticket-owned]',
  templateUrl: './purchase-ticket-owned.component.html',
  styleUrls: ['./purchase-ticket-owned.component.css']
})
export class PurchaseTicketOwnedComponent implements OnInit, OnDestroy 
{

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * 乗車券を購入した利用者情報
   *
   * @type {ExUser}
   * @memberof PurchaseTicketNewComponent
   */
  purchaseUser: ExUser;

  /**
   * 乗車券の名称（ページタイトル用）
   *    定額乗車券/数日乗り放題券
   *
   * @type {string}
   * @memberof PurchaseTicketComponent
   */
  ticketTypeName: string = CONST.Purchase.TICKET_TITLE_CITIZEN;
  
  /**
   * 予約情報
   *
   * @type {common.ReservTicket[]}
   * @memberof PurchaseTicketNewComponent
   */
  reservOneTicket: common.ReservTicket[] = [];
  
  /**
   * 予約（定額乗車券）情報
   *
   * @type {Ticket[]}
   * @memberof PurchaseTicketOwnedComponent
   */
  reservFixedTicket: Ticket[] = [];

  /**
   * 所有乗車券情報
   *
   * @type {Ticket}
   * @memberof PurchaseTicketOwnedComponent
   */
  ownTicket: Ticket;

  /**
   * 所有乗車券の自動更新後の情報
   *
   * @type {Ticket}
   * @memberof PurchaseTicketOwnedComponent
   */
  ownNextTicket: Ticket;

  /**
   * 自動更新切り替えスイッチの排他フラグ
   *
   * @type {boolean}
   * @memberof PurchaseTicketConfirmComponent
   */
  exclusion: boolean = false;

  /**
   * 住人（観光目的でない）ユーザか
   *
   * @type {boolean}
   * @memberof PurchaseTicketNewComponent
   */
  userCitizen: boolean = true;

  /**
   * 自動更新切り替えスイッチの状態
   *
   * @type {boolean}
   * @memberof PurchaseTicketNewComponent
   */
  continueSwitchState: boolean;

  /**
   * 変更期限
   *
   * @type {number}
   * @memberof PurchaseTicketOwnedComponent
   */
  changeLimit: number;

  /**
   * 新規購入可能か否か
   *
   * @type {boolean}
   * @memberof PurchaseTicketComponent
   */
  purchasable: boolean = true;

  /**
   * 利用できない期間があるか
   *
   * @type {boolean}
   * @memberof PurchaseTicketNewComponent
   */
  userPeriod: boolean = false;

  /**
   * 利用期間短縮時にHTMLに表示するメッセージ
   *
   * @type {string}
   * @memberof PurchaseTicketNewComponent
   */
  periodShortMsg: string;
  
  /**
   * 購読用サブスクリプション
   *
   * @type {Subscription}
   * @memberof PurchaseTicketNewComponent
   */
  busy: Subscription;

  /**
   * 日付フォーマット用
   *
   * @memberof PurchaseTicketOwnedComponent
   */
  moment = moment;

  /**
   * エリアを選択する乗車券かどうか
   *
   * @type {boolean}
   * @memberof PurchaseTicketOwnedComponent
   */
  isSelectAreaTicket: boolean;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of PurchaseTicketOwnedComponent.
   * @param {ApplicationMessageService} appMsg
   * @param {PurchaseWebApiService} _purchaseWebApiService
   * @param {DispatchWebApiService} _dispatchWebApiService
   * @param {HttpErrorResponseParserService} httpErrorResponseParserService
   * @param {Params} _params
   * @memberof PurchaseTicketOwnedComponent
   */
  constructor(
    private appMsg: ApplicationMessageService, 
    private purchaseWebApiService: PurchaseWebApiService,
    private dispatchWebApiService: DispatchWebApiService,
    private httpErrorResponseParserService: HttpErrorResponseParserService,
    private familyWebApiService: FamilyWebApiService,
    private params: Params,
    private municipalityWebApiServ: MunicipalityWebApiService,
    private msg: MESSAGE,
  ) { }

  /**
   * 初期化処理。
   *
   * @memberof PurchaseTicketOwnedComponent
   */
  ngOnInit(): void 
  {
    // 初期化
    this.initContorol();
    this.initMessage();
    
    // パラメータ取得
    this.purchaseUser = this.params.data.userProfile;
    this.ownTicket = this.params.data.ticket;
    this.continueSwitchState = this.ownTicket.continue;
    this.userCitizen = this.purchaseUser.user_type === "citizen" ? true : false;  // 住人（観光目的でない）ユーザか
    this.changeLimit = CONST.Purchase.TICKET_CONTINUE_LIMIT;

    // エリアを選択する乗車券かどうか
    this.isSelectAreaTicket = this.ownTicket.area && this.ownTicket.area.area_ids && this.ownTicket.area.area_ids.length !== 0;

    // ページタイトルに使用する乗車券の種別名称を取得
    // this.ticketTypeName = this.purchaseWebApiService.getTicketTypeName(this.purchaseUser.user_type);
    
    // 自動更新後の乗車券を作成
    this.ownNextTicket = <Ticket>this.purchaseWebApiService.createNextTicket(this.ownTicket);

    // 予約一覧を取得
    this.getReservations(this.ownNextTicket.start_date, this.ownNextTicket.end_date);
  }

  /**
   * 破棄処理。
   *
   * @memberof PurchaseTicketOwnedComponent
   */
  ngOnDestroy(): void 
  {
    // 破棄
    this.busy?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * バス停一覧のPDFファイルを表示する
   *
   * @memberof PurchaseTicketOwnedComponent
   */
  viewBusStopMap(): void 
  {
    // 乗降可能なバス停一覧を表示
    // window.open('../../assets/pdf/' + CONST.Purcase.BUSSTOP_MAP,'_blank');
    // TODO:結合テスト向けにcoming soon表示に変更
    this.appMsg.viewDialogMessage(CONST.Common.COMING_SOON);
  }

  /**
   * 払い戻し案内画面を表示する
   *
   * @memberof PurchaseTicketOwnedComponent
   */
  viewPayBackMessage(): void 
  { 
    // 払い戻し案内のHTMLを表示
    this.appMsg.userNotification(this.msg.CLIENT.USER_NOTIFICATION.PURCHASE_REFUND.message(this.municipalityWebApiServ.setting.office.office_name));
  }

  /**
   * 自動更新設定変更処理
   *    ons-switch：changeイベントハンドラ
   *
   * @return {*} 
   * @memberof PurchaseTicketOwnedComponent
   */
  changeContinuous(): void 
  {
    this.exclusion = true;

    // ファミリー管理者から制限中
    if (true === this.familyWebApiService.isLoginUserUtilization()) 
    {
      this.appMsg.viewDialogMessage(this.msg.CLIENT.FAMILY.RESTRICTED_UTILIZATION.message(), () => setTimeout(() => 
      {
        this.continueSwitchState = !this.continueSwitchState;
        this.exclusion = false;
      }, 0));
      
      return;
    }

    // 変更期限を超えていたら変更不可
    if (true === this.purchaseWebApiService.checkContinueChangeLimit(this.ownTicket)) 
    {
      // 自動更新反転
      this.appMsg.viewDialogMessage(this.msg.CLIENT.PURCHASE.CONTINUE_OVER_LIMIT.message(), () => setTimeout(() => 
      {
        this.continueSwitchState = !this.continueSwitchState;
        this.exclusion = false;
      }, 0));
      
      return;
    }
    
    /**
     * 初期化処理
     *
     */
    const initial = () =>
    {
      // 初期化
      this.initMessage(); 
      this.continueSwitchState = this.ownTicket.continue;
      this.exclusion = false;
    };

    /**
     * テンプレート上にメッセージを表示
     *
     * @param {string[]} msg
     */
    const viewTmpMsgAndSetContinuous = (msg: string[]) => 
    {
      if (msg.length > 0) this.userPeriod = true;
      this.periodShortMsg = msg.join();
      this.setContinuousServer();
    };

    // 初期化
    this.initMessage();

    // OFF → ON
    if (true === this.continueSwitchState) 
    {
      // 自動更新不可乗車券
      // 自動更新後の全日が利用不可、免許返納者乗車券
      if (false === this.ownTicket.continuable) 
      {
        let msg: string = "";

        // 免許返納者は自動更新SW非表示のため対応不要
        if (this.ownTicket.terms.special_type === 'handicapped') 
          msg = this.msg.CLIENT.PURCHASE.TERM_EXPIRATION.message();
        else 
        {
          // if (new Date(this.ownTicket.start_date).getMonth() === 2)
          //   msg = this.appMsg.getCLientMsg(this.appMsg.CLIENT_CODE.PURCHASE.AFTER_GRADUATION);
          // else 
          msg = this.msg.CLIENT.PURCHASE.CONTINUE_DISABLE.message();
        }
        
        // 自動更新OFF
        this.appMsg.viewDialogMessage(msg, () => setTimeout(() => { this.continueSwitchState = false; }, 0));
  
        return;
      }

      // 自動更新後の乗車券の変更可否をチェック
      let checkResult = this.purchaseWebApiService.checkInvalidPeriodAndReserv(this.ownNextTicket, this.reservOneTicket);

      // ユーザ通知メッセージ
      let result = this.getPurchasableMessage(this.ownNextTicket, checkResult);

      if (false !== result.confirm) 
      {
        // 確認ダイアログ表示
        this.appMsg.confirmMessage(result.dlgMsg[0], value => 
          { 
            // キャンセルなら自動更新OFF
            if (Number(value) !== CONST.Common.CONFIRM_YES) initial();
            else 
            {
              if (undefined !== result.dlgMsg[1]) 
              {
                this.appMsg.viewDialogMessage(result.dlgMsg[1], () => viewTmpMsgAndSetContinuous(result.tempMsg));
              }
              else viewTmpMsgAndSetContinuous(result.tempMsg);
            }
          });
      }
      else 
      {
        if (result.dlgMsg.length > 0) 
        {
          this.appMsg.viewDialogMessage(result.dlgMsg[0], () => this.setContinuousServer());
        }
        else this.setContinuousServer();
      }
    }
    // ON→OFF
    else 
    {
      // 本定期券を使った予約があるため、自動更新OFF不可
      if (this.reservFixedTicket.length > 0) 
      {
        // 自動更新反転
        this.appMsg.viewDialogMessage(this.msg.CLIENT.PURCHASE.CONTINUE_NO_DISCONTINUOUS.message(), () => { initial(); });
        return;
      }

      // 自動更新切り替え（ON → OFF）
      this.setContinuousServer();
    }
  }

//=============================================================================================
// メソッド
//=============================================================================================
  
  /**
   * コントロールの状態を初期化する。
   *
   * @memberof PurchaseTicketNewComponent
   */
  private initContorol(): void 
  {
    // コントロールの状態を初期化
    this.continueSwitchState = false;
    this.purchasable = true;
  }

  /**
   * メッセージを初期化する。
   *
   * @memberof PurchaseTicketNewComponent
   */
  private initMessage(): void 
  {
    this.periodShortMsg = "";
    this.userPeriod = false;
  }

  /**
   * 自動更新SWを元に戻す。
   *
   * @private
   * @memberof PurchaseTicketOwnedComponent
   */
  private initContinueSwitch(): void 
  {
    this.continueSwitchState = this.ownTicket.continue;
    this.exclusion = false;
  }

  /**
   * 購入可否メッセージを取得する。
   *
   * @private
   * @param {TicketCatalog} target
   * @param {number[]} checkResult
   * @return {*}  {{ tempMsg: string[], dlgMsg: string[], confirm: boolean }}
   * @memberof PurchaseTicketNewComponent
   */
  private getPurchasableMessage(target: Ticket, checkResult: number[]): { tempMsg: string[], dlgMsg: string[], confirm: boolean } 
  { 
    let templatViewMsg: string[] = [];
    let dialogViewMsg: string[] = [];
    let confirm: boolean = false;

    // メッセージコードを取得
    checkResult.forEach(result => 
      {
        let tmpMsg: string = "";
        let dlgMsg: string = "";
        switch (result) 
        {
          // --------------------------------------------
          // 正常
          // --------------------------------------------
          case PURCHASE.PURCHASE_STATUS.OK: return;
          // --------------------------------------------
          // 切り替え不可
          // --------------------------------------------
          case PURCHASE.PURCHASE_STATUS.NG: 
          {
            if (this.ownTicket.terms.special_type === "handicapped") 
              dlgMsg = this.msg.CLIENT.PURCHASE.TERM_EXPIRATION.message();
            else 
              dlgMsg = this.msg.CLIENT.PURCHASE.CONTINUE_DISABLE.message();
            break;
          }
          // --------------------------------------------
          // 一部利用できない期間あり。ユーザ意識確認
          // --------------------------------------------
          case PURCHASE.PURCHASE_STATUS.OK_CAUTION: 
          {
            let from = moment(target.invalid.from).format("M/D");

            // ～から利用できないことを通知
            tmpMsg = this.msg.CLIENT.PURCHASE.CONTINUE_IMPOSSIBLE.message(`${ from }以降`, this.ownTicket.ticket_name);                                          
            dlgMsg = this.msg.CLIENT.PURCHASE.CONTINUE_IMPOSSIBLE_CONFIRM.message(`${ from }以降`, this.ownTicket.ticket_name);            
            confirm = true;
            break;
          }
          // --------------------------------------------
          // 自動更新後の有効期間に予約（1回券）あり
          // --------------------------------------------
          case PURCHASE.PURCHASE_STATUS.OK_RESERV_CANCEL: 
          {
            // キャンセル促し
            dlgMsg = this.msg.CLIENT.PURCHASE.CONTINUE_CANCEL_RESERVE.message();
            
            break;
          }
          case PURCHASE.PURCHASE_STATUS.E_UNEXPECTED: 
          default: 
          {
            dlgMsg = this.msg.CLIENT.COMMON.E_UNEXPECTED.message();
            return;
          }
        }
        
        if (tmpMsg !== "") templatViewMsg.push(tmpMsg);
        if (dlgMsg !== "") dialogViewMsg.push(dlgMsg);
      }
    );

    return { tempMsg: templatViewMsg, dlgMsg: dialogViewMsg, confirm: confirm };
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * 予約一覧を取得する。
   *
   * @private
   * @param {string} start_date
   * @param {string} end_date
   * @memberof PurchaseTicketOwnedComponent
   */
  private getReservations(start_date: string, end_date: string): void 
  {
    // パラメータ作成
    const param: request.UserReservations = 
    {
      passenger_user_id: this.purchaseUser.user_id, 
      begin_date: moment(start_date).format("YYYY-MM-DD"), 
      end_date: moment(end_date).format("YYYY-MM-DD"), 
      status: ['reserved']
    };
    
    // 予約一覧を取得
    this.busy = this.dispatchWebApiService.userReservations<Reservation[]>(param)
    .subscribe(
      {
        next: response => 
        {
          let customerBills: common.customerBill[] = [];
          this.reservOneTicket = [];
          this.reservFixedTicket = [];

          for (let reserv of response.body) 
          {
            customerBills.push(this.dispatchWebApiService.getCustomersBill(reserv));
          }

          // 購入ユーザの予約から1回券を取得
          this.reservOneTicket = this.dispatchWebApiService.getReservByUser(customerBills, this.purchaseUser.user_id, 1);
          
          // 購入ユーザの予約から定額乗車券を取得
          let termTicket = this.dispatchWebApiService.getReservByUser(customerBills, this.purchaseUser.user_id, 3);
          this.reservFixedTicket = termTicket.filter(t => t.ticket_id === this.ownTicket.next_ticket_id);
        }, 
        error: this.httpErrorResponseParserService.doParse((_error, customErrorContent) => 
        {
          this.httpErrorResponseParserService.viewErrDialog(customErrorContent);
        })
      }
    );
  }
  
  /**
   * 自動更新の設定変更を行う。
   *
   * @private
   * @memberof PurchaseTicketOwnedComponent
   */
  private setContinuousServer(): void 
  {
    // 更新実行
    this.busy = this.purchaseWebApiService.updateContinuous(this.ownTicket.ticket_id, this.continueSwitchState)
    .subscribe(
      {
        next: () => 
        {
          // 更新完了通知
          this.appMsg.viewDialogMessage(this.msg.CLIENT.PURCHASE.COMPLETE_AUTOUPDATE.message());

          // ローカルデータ更新（マイページメニューまで戻ればサーバから再取得される）
          this.ownTicket.continue = this.continueSwitchState;
          this.exclusion = false;
        },
        error: this.httpErrorResponseParserService.doParse((_err, errContent) => 
        {
          this.httpErrorResponseParserService.viewErrDialog(errContent)
          .then(() => {
            // GMOエラー
            // NOTE: サーバーエラー(オプション含め)から判別することができないため、クライアントメッセージとしている
            if (errContent.smartGotoErrCode === this.appMsg.SERV_CONST_CODE.PURCHASE.GMO_TRAN_AUTH ||
              errContent.smartGotoErrCode === this.appMsg.SERV_CONST_CODE.PURCHASE.GMO_TRAN_VOID) {
                this.httpErrorResponseParserService.viewErrDialog(errContent, "TICKET_CONTINUE").then(() => this.initContinueSwitch());
            }
            // 運営債権支払い方法、購入不可エラー
            else if (errContent.smartGotoErrCode === this.appMsg.SERV_CONST_CODE.PURCHASE.METHOD_NO_PERMISSION) {
              this.appMsg.viewDialogMessage(this.msg.CLIENT.PURCHASE.METHOD_NO_PERMISSION_TICKET_CONTINUE.message(), () => this.initContinueSwitch());
            }
            else this.initContinueSwitch();
          });
        })
      }
    );
  }
}
