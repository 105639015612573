// ========================================================================================================================
// 各種インポート(モジュール、コンポーネント、サービス等)
// ========================================================================================================================

// npm
import { Component, OnInit } from '@angular/core';

// コンポーネント
import { HistoryListComponent } from './history-list/history-list.component';

// ========================================================================================================================
// メタデータ定義
// ========================================================================================================================

@Component
({
  selector: 'ons-page[history]',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})

// ========================================================================================================================
// クラス定義
// ========================================================================================================================

export class HistoryComponent implements OnInit 
{
  
  root = HistoryListComponent;

  constructor() { }

  ngOnInit(): void { }

}
