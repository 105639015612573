<ons-toolbar>
    <div class="left">
      <ons-back-button></ons-back-button>
    </div>
    <div class="center">まちニュース</div>
</ons-toolbar>

<div class="content">

    <!-- 気象・防災関連情報一覧 -->
    <div class="list-item__caption">気象・防災関連情報</div>
    <section class="site-list-area">
    <ng-container *ngFor="let site of disasterSiteList">
        <!-- アイコン -->
        <article (click)="viewDisasterSite(site.url)">
            <div class="site-button-area">
                <div class="site-button-box">
                    <!-- ボタンの表示内容 -->
                    <span [innerHTML]="site.content" ></span>
                </div>
            </div>
            <!-- 補足説明 -->
            <p class="explanation-text" [innerHTML]="site.explanation"></p>
        </article>
    </ng-container>
    </section>

</div>
