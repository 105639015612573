//=============================================================================================
// インポート
//=============================================================================================
import { Component, Inject, OnInit } from '@angular/core';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { OnsNavigator, Params } from 'ngx-onsenui';
import 'moment-duration-format';
import * as moment from 'moment-timezone';
import * as CONST from '../../../constants/constant';

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { DispatchWebApiService, DISPATCH_WEB_API_RESERVATION_TRANS_MAP } from '../../../http-services/dispatch-web-api.service';

// component
// import { PassengerFareComponent } from '../_passenger-fare/passenger-fare.component';
import { SearchResultList } from '../search-result-list/search-result-list';
import { UserReservationDetermineComponent } from '../user-reservation-determine/user-reservation-determine.component';
import { UserReservation } from '../user-reservation/user-reservation';

// interface
import { common } from '../../../interfaces/common';
import { request } from '../../../interfaces/request';
import { Reservation } from '../../../interfaces/response';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 移動プラン詳細画面。
 *
 * @export
 * @class SearchResultDetailComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'ons-page[search-result-detail]',
  templateUrl: './search-result-detail.component.html',
  styleUrls: ['./search-result-detail.component.css']
})
export class SearchResultDetailComponent implements OnInit 
{

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * 日付と時刻を扱うためのユーティリティ (Moment オブジェクト)。
   *
   * @memberof SearchResultDetailComponent
   */
  readonly moment = moment;

  /**
   * 移動プランの検索条件。
   *
   * @type {UserReservation.SearchCondition}
   * @memberof SearchResultDetailComponent
   */
  searchCondition: UserReservation.SearchCondition;

  /**
   * 移動プラン。
   *
   * @type {SearchResultList.Planner}
   * @memberof SearchResultDetailComponent
   */
  planner: SearchResultList.Planner;

  /**
   * 予約情報。
   *
   * @type {Reservation}
   * @memberof SearchResultDetailComponent
   */
  reservation: Reservation;

  // 確定対象予約の各種情報
  strOriginName: string;         // 出発地スポット名
  strDestinationName: string;    // 目的地スポット名
  strOriginBusStop: string;      // 出発バス停名
  strDestinationBusStop: string; // 到着バス停名
  nOBusstopId: number;           // 出発バス停ID
  nDBusstopId: number;           // 到着バス停ID
  dOriginTime: Date;             // 出発時刻
  dDestinationTime: Date;        // 到着時刻
  nPrice: number;                // 金額
  strPrice: string;              // 金額
  nPassengerCount: number;       // 利用者数

  /**
   *ビジー状態用
   *
   * @type {Subscription}
   * @memberof SearchResultDetailComponent
   */
  m_Busy:Subscription;
  
  private inquiryReserveRequestParams: request.InquiryReserve;

  /**
   * 利用者ごとの料金、利用チケット情報
   *
   * @type {common.customerBill}
   * @memberof SearchResultDetailComponent
   */
  customerBill: common.customerBill;

  /**
   * 徒歩区間の有無
   *
   * @type {boolean}
   * @memberof SearchResultListComponent
   */
  isWalkingSection: boolean = false;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of SearchResultDetailComponent.
   * @param {Map<string, Map<string, string>>} reservationTransMap 交通手段に応じて異なる設定値が格納された `Map` オブジェクト。
   * @param {OnsNavigator} _navigator ページスタックの管理とナビゲーション機能を提供するコンポーネント。
   * @param {Params} params 遷移元のページから渡されるパラメーター。
   * @memberof SearchResultDetailComponent
   */
  constructor(
    @Inject(DISPATCH_WEB_API_RESERVATION_TRANS_MAP) public readonly reservationTransMap: Map<string, Map<string, string>>,
    private _navigator: OnsNavigator,
    private params: Params,
    private dispatchWebApiService: DispatchWebApiService,
    private httpErrorResponseParserService: HttpErrorResponseParserService,
    private appMsg: ApplicationMessageService,
    private msg: MESSAGE,
  ) { }

  /**
   * コンポーネントが初期化される際に呼び出されるメソッド。
   *
   * @memberof SearchResultDetailComponent
   */
  ngOnInit(): void 
  {
    [this.searchCondition, this.inquiryReserveRequestParams, this.planner, this.reservation, this.customerBill] = this.params.data;

    // 予約の各区間を検索
    let bFirstSection = true;
    this.reservation.sections.forEach(section => 
      {
        // SmartGOTOの配車区間だけ検索対象
        if (section.trans === 'smartgoto')
        {
          // 一番最初の配車区間の場合(乗り継ぎ便ができた場合を考慮)
          if (bFirstSection === true)
          {
            // 出発時刻＆出発バス停
            this.dOriginTime = new Date(section.o.schd_time);
            this.strOriginBusStop = section.o.name;
            this.nOBusstopId = section.o.busstop_id;
            bFirstSection = false;
          }

          // 到着時刻＆到着バス停
          this.dDestinationTime = new Date(section.d.schd_time);
          this.strDestinationBusStop = section.d.name;
          this.nDBusstopId = section.d.busstop_id;
        }
        // 徒歩区間有無
        else if (section.trans === 'walk') this.isWalkingSection = true;
      });
  }

  /**
   * 廃棄処理
   *
   * @memberof SearchResultDetailComponent
   */
  ngOnDestroy(): void 
  {
    this.m_Busy?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 搭乗者のフルネームのリスト。
   *
   * @readonly
   * @type {string[]}
   * @memberof SearchResultDetailComponent
   */
  get passengersFullNames(): string[] 
  {
    return this.reservation.passenger_names.map(name => name.family_name + " " + name.given_name);
  }

  /**
   * 利用者氏名、料金を取得する。
   *
   * @readonly
   * @type {string}
   * @memberof SearchResultDetailComponent
   */
  get passengersPrice(): string {
    
    let user: string = "";
    let family: string[] = [];
    let guest: string[] = [];

    // 同乗者と料金を取得
    if (this.customerBill.user) user = this.customerBill.user.user_name + " 様 " + this.customerBill.user.price + "円";
    family = this.customerBill.families.map(f => f.user_name + " 様 " + f.price + "円");
    // guest = this.customerBill.guests.map(g => g.user_name + " 様 " + g.price + "円");

    let ret: string = "";
    if (user !== "") ret = user;
    if (family.length > 0) {
      if (user !== "") ret += "、" ;
      ret += family.join("、");
    }
    if (guest.length > 0) {
      if (user !== "" || family.length > 0) ret += "、" ;
      ret += guest.join("、");
    }

    return ret;
  }

  /**
   * 配車単位での料金を確認する。
   *
   * @memberof SearchResultDetailComponent
   */
  // showPassengerFare(): void 
  // {
  //   this._navigator.element.pushPage(PassengerFareComponent, {
  //     data: [this.searchCondition, this.customerBill]
  //   });
  // }

  /**
   * 予約に進む。
   *
   * @memberof SearchResultDetailComponent
   */
  decide(): void 
  {
    // 利用者のID一覧
    const PassangersId = this.searchCondition.getPassengersUserIds();

    // 利用者ごとの予約重複チェック処理定義
    const Sources = PassangersId.map((id) => this.checkDuplicateReservation(id));
    
    // 利用者ごとの予約重複チェック実施
    this.m_Busy = forkJoin(Sources).subscribe(
      {
        next: ResultList => 
        {
          // ユーザー毎の重複チェック結果を予約単位にまとめ(⇒誰か1人でも不可なら不可、不可0人で誰か1人でも要確認なら確認)
          let nDuplicateEnum: number = 1;
          let nDuplicateCount: number = 0;
          let nComfirmCount: number = 0;
          let strDuplicateUser: string = '';
          let nComfirmUserId: number[] = [];
          for (var Result of ResultList)
          {
            if(Result.duplicateType == 3)
            {
              strDuplicateUser = strDuplicateUser + this.searchCondition.getPassengerName(Result.user_id) + ' 様、';
              nDuplicateEnum = 3;
              nDuplicateCount = nDuplicateCount + 1;
            }
            else if(Result.duplicateType == 2)
            {
              strDuplicateUser = strDuplicateUser + this.searchCondition.getPassengerName(Result.user_id) + ' 様、';
              nComfirmUserId.push(Result.user_id);
              nComfirmCount = nComfirmCount + 1;
              if(nDuplicateEnum == 1)
              {
                nDuplicateEnum = 2;
              }
            }
          }
    
          // ユーザー氏名文字列の整形
          // if(ResultList.length == 1)
          // {
          //   strDuplicateUser = '';
          //   console.log(strDuplicateUser);
          // }
          // else if(ResultList.length > 1)
          // {
            // 最後の「、」を削除
            strDuplicateUser = strDuplicateUser.slice(0, -1);
          // }
          
          // 予約不可の場合
          if(nDuplicateEnum == 3)
          {
            this.appMsg.viewDialogMessage(this.msg.CLIENT.DISPATCH.DEPLICATE_RESERVE.message(strDuplicateUser));
          }
          // 予約要確認の場合
          else if(nDuplicateEnum == 2)
          {
            // 重複確認
            this.appMsg.confirmMessage(this.msg.CLIENT.DISPATCH.DEPLICATE_RESERVE_CONFIRM.message(strDuplicateUser), (value) => 
            {
              if (Number(value) == CONST.Common.CONFIRM_YES) 
              {
                // 予約確定画面へ遷移
                this.pushReservationDetermine();
              }
            });
          }
          // 予約OKの場合
          else
          {
            //予約確定画面へ遷移
            this.pushReservationDetermine()
          }
        },
        error: () => {
          this.appMsg.viewDialogMessage(this.msg.CLIENT.COMMON.E_UNEXPECTED.message());
        }
      });
  }

  /**
   * 予約重複チェック
   *
   * @param {number} user_id
   * @return {*}  {Observable<any>}
   * @memberof SearchResultDetailComponent
   */
  checkDuplicateReservation(user_id: string): Observable<any> 
  {
    // 戻り値用データ定義
    const ReturnSubject: Subject<any> = new Subject();
    const ReturnData = new DuplicateReservation;
    ReturnData.user_id = user_id;
    ReturnData.duplicateType = 1;

    // 予約重複の識別子(1:重複なし、2:重複あり＆確認の必要あり、3:重複あり＆予約不可)
    let nDuplicateEnum: number = 1;

    // 操作中予約の乗降バス停発着時刻
    const dOriginNew: Date = this.dOriginTime;
    const dDestinationNew: Date = this.dDestinationTime;

    // 操作中予約の乗降バス停ID
    const nOBusstopIdNew: number = this.nOBusstopId;
    const nDBusstopIdNew: number = this.nDBusstopId;

    // 予約一覧取得用リクエストパラメータ
    const userReservationsRequestParams: request.UserReservations = 
    {
      passenger_user_id: user_id, 
      begin_date: moment(new Date()).format("YYYY-MM-DD"), 
      status: ['reserved', 'working']

      // passenger_user_id: user_id,
      // ステータス＝利用前or利用中or利用後のみ
      // ⇒キャンセルと運行中止はユーザーが利用しないため＆未乗車は予約の次の日にしか遷移しないため判定対象外
      // status: ['reserved', 'working', 'done'],
      // // 出発時刻の昇順でソート
      // sort: (() => 
      // {
      //   let sort = {};
      //   sort[`o.schd_time`] = 1;
      //   return sort;
      // })()
    };

    // 予約一覧を取得
    this.m_Busy = this.dispatchWebApiService.userReservations<Reservation[]>(userReservationsRequestParams).subscribe(
    {
      // 正常系レスポンス
      next: Response => 
      {
        if (Response.body.length > 0)
        {
          const validReservation = this.dispatchWebApiService.getValidReservation(Response.body, user_id)

          // 予約毎に重複チェック
          for (var Reservation of validReservation) 
          {
            // 既に予約不可の場合は判定なし
            if (nDuplicateEnum == 3) 
            {
              break;
            }

            /////////////////////////////////////
            // 判定対象予約の各種時刻＆バス停ID取得
            /////////////////////////////////////

            // 判定対象予約の乗降バス停発着時刻と予約不可しきい時刻
            let dOriginComp: Date;
            let dDestinationComp: Date;
            let dNotAcceptThreshold: Date;

            // 判定対象予約の乗降バス停ID
            let nOBusstopIdComp: number;
            let nDBusstopIdComp: number;

            // 検索中区間が一番最初の配車区間かどうかの判定変数
            let bFirstSection: boolean = true;

            // 発着時刻を取得
            Reservation.sections.forEach(Section => 
            {
              // SmartGOTOの配車区間のみが検索対象
              if (Section.trans=='smartgoto')
              {
                // 一番最初の配車区間の出発時刻をプランの出発時刻とする(乗り継ぎ便ができた場合を考慮)
                if (bFirstSection==true)
                {
                  dOriginComp = new Date(Section.o.schd_time);
                  nOBusstopIdComp = Section.o.busstop_id;
                  bFirstSection = false;
                }

                // 一番最後の配車区間の到着時刻をプランの到着時刻とする
                dDestinationComp = new Date(Section.d.schd_time); 
                nDBusstopIdComp = Section.d.busstop_id;    
              }
            });

            // 予約不可しきい時刻
            dNotAcceptThreshold = new Date(dDestinationComp);
            dNotAcceptThreshold.setMinutes( dNotAcceptThreshold.getMinutes() - CONST.Dispatch.DUPLICATE_RESERBATION_THRESHOLD);
            /////////////////////////////////////
            // 既存予約と新規予約の時刻被りチェック
            /////////////////////////////////////

            // 新規の出発 < 既存の出発
            if(dOriginNew < dOriginComp)
            {
              // 新規の到着 < 既存の出発
              if(dDestinationNew < dOriginComp)
              {
                // 時間重複なし
              }
              // 既存の出発 ≦ 新規の到着
              else
              {
                // 時間重複あり＆予約不可
                nDuplicateEnum = 3;
              }
            }
            // 既存の到着 < 新規の出発
            else if(dDestinationComp < dOriginNew)
            {
              // 時間重複なし
            }
            // 既存の出発 ≦ 新規の出発 ≦ 既存の到着
            else
            {
              // 既存の出発 < 予約不可しきい時刻
              if(dOriginComp < dNotAcceptThreshold)
              {
                // 既存の出発 ≦ 新規の出発 < 予約不可しきい時刻
                if (dOriginNew < dNotAcceptThreshold)
                {
                  // 時間重複あり＆予約不可
                  nDuplicateEnum = 3;
                }
                // 予約不可しきい時刻 ≦ 新規の出発 ≦ 既存の到着
                else
                {
                  // 予約不可しきい時刻 ≦ 新規の到着 ≦ 既存の到着
                  if(dDestinationNew <= dDestinationComp)
                  {
                    // 時間重複あり＆予約不可
                    nDuplicateEnum = 3;
                  }
                  // 既存の到着 < 新規の到着
                  else
                  {
                    // 新規予約と既存予約の乗車バス停が同一 or 新規予約と既存予約の降車バス停が同一の場合
                    if(nOBusstopIdNew == nOBusstopIdComp || nDBusstopIdNew == nDBusstopIdComp)
                    {
                      // 時間重複あり＆予約不可
                      nDuplicateEnum = 3;
                    }
                    else
                    {
                      // 時間重複あり＆確認の必要あり
                      if (nDuplicateEnum == 1)
                      {
                        nDuplicateEnum = 2;
                      }
                    }
                  }
                }
              }
              // 予約不可しきい時刻 ≦ 既存の出発
              else
              {
                // 予約不可しきい時刻 ≦ 新規の到着 ≦ 既存の到着
                if(dDestinationNew <= dDestinationComp)
                {
                  // 時間重複あり＆予約不可
                  nDuplicateEnum = 3;
                }
                // 既存の到着 < 新規の到着
                else
                {
                  // 新規予約と既存予約の乗車バス停が同一 or 新規予約と既存予約の降車バス停が同一の場合
                  if(nOBusstopIdNew == nOBusstopIdComp || nDBusstopIdNew == nDBusstopIdComp)
                  {
                    // 時間重複あり＆予約不可
                    nDuplicateEnum = 3;
                  }
                  else
                  {
                    // 時間重複あり＆確認の必要あり
                    if (nDuplicateEnum == 1)
                    {
                      nDuplicateEnum = 2;
                    }
                  }
                }
              }
            }
          }
        }

        /////////////////////////////////////
        // 重複チェック結果を戻り値に反映
        /////////////////////////////////////
        ReturnData.duplicateType = nDuplicateEnum;
        ReturnSubject.next(ReturnData);
        ReturnSubject.complete();

      },
      // 異常系レスポンス
      error: this.httpErrorResponseParserService.doParse((_err, errContent) => 
      {
        // エラーメッセージ
        this.httpErrorResponseParserService.viewErrDialog(errContent);
        // .then(() => 
        // {
        //   // ゲスト認証切れ時、ゲストを初期化
        //   if (errContent.smartGotoErrCode === this.appMsg.SERV_CONST_CODE.DISPATCH.QR_UNAUTH) 
        //   {
        //     this.searchCondition.deleteGuestPassenger();
        //     this.searchCondition.updateCanSearchValue();
        //   }
        //   this.searchCondition.returnHere();
        // });
        
        return;
      })
    });

    return ReturnSubject;
  }

  /**
   *予約確定画面に遷移
   *
   * @memberof SearchResultDetailComponent
   */
  pushReservationDetermine(): void 
  {
    this._navigator.element.pushPage(UserReservationDetermineComponent, {
      data: [this.searchCondition, this.inquiryReserveRequestParams, this.planner, this.reservation, this.customerBill]
    });
  }

}
// 重複チェックメソッドの戻り値の型
class DuplicateReservation
{
  user_id: string;
  duplicateType: number;
}