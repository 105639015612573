//=============================================================================================
// インポート
//=============================================================================================
import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { OnsNavigator, Params } from 'ngx-onsenui';
import { Observable, Subscription } from 'rxjs';

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { UserWebApiService } from '../../../http-services/user-web-api.service';
import { SqrcWebApiSchemas, SqrcWebApiService } from '../../../http-services/sqrc-web-api.service';
import { FamilyWebApiService } from '../../../http-services/family-web-api.service';

// component
import { FamilyAuthStep1Context } from '../family-invite-qr/family-invite-qr';
import { FamilyAuthStep2Context } from './family-auth-step2';
import { FamilyInviteConfirmComponent } from '../family-invite-confirm/family-invite-confirm.component';

// interface
import { ExUser, Relation } from '../../../interfaces/response';
import { request } from '../../../interfaces/request';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 *ファミリー招待画面 (STEP2)
 *
 * @export
 * @class FamilyAuthStep2Component
 * @implements {OnInit}
 * @implements {AfterViewInit}
 * @implements {OnDestroy}
 * @implements {FaceAuthStep2Context}
 */
@Component({
  selector: 'ons-page[family-auth-step2]',
  templateUrl: './family-auth-step2.component.html',
  styleUrls: ['./family-auth-step2.component.css']
})
export class FamilyAuthStep2Component implements OnInit, AfterViewInit, OnDestroy, FamilyAuthStep2Context 
{
  @ViewChild('onsBackButton') private onsBackButton: ElementRef;
  @ViewChild('canvas') private canvas: ElementRef<HTMLCanvasElement>;
  @ViewChild('video') private video: ElementRef<HTMLVideoElement>;
  @ViewChild('file') private file: ElementRef<HTMLInputElement>;

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * 非同期処理の実行状態を表す。
   *
   * @type {Subscription}
   * @memberof FamilyAuthStep2Component
   */
  m_Busy: Subscription;
  
  /**
   *画面間で共有されるコンテキスト
   *
   * @private
   * @type {FaceAuthStep1Context}
   * @memberof FamilyAuthStep2Component
   */
  private _faceAuthStep1Context: FamilyAuthStep1Context;

  /**
   * ファイルの読み取り
   *
   * @private
   * @memberof FamilyAuthStep2Component
   */
  private fileReader = new FileReader();

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of FamilyAuthStep2Component.
   * @param {OnsNavigator} _navigator ページスタックの管理とナビゲーション機能を提供するコンポーネント。
   * @param {UserWebApiService} userWebApiService Web API (ユーザー関連) の呼び出しを簡略化するためのユーティリティ。
   * @param {SqrcWebApiService} sqrcWebApiService Web API (顔認証 QR コード関連) の呼び出しを簡略化するためのユーティリティ。
   * @param {Params} params 遷移元のページから渡されるパラメーター。
   * @memberof FamilyAuthStep2Component
   */
  constructor(
    private _navigator: OnsNavigator,
    private userWebApiService: UserWebApiService,
    private sqrcWebApiService: SqrcWebApiService,
    private params: Params,
    private appMsg: ApplicationMessageService, 
    private httpErrorResponseParserService: HttpErrorResponseParserService, 
    private FamilyWebApiService: FamilyWebApiService,
    private msg: MESSAGE,
  ) { }

  /**
   * コンポーネントが初期化される際に呼び出されるメソッド。
   *
   * @memberof FaceAuthStep2Component
   */
  ngOnInit(): void 
  {
    this._faceAuthStep1Context = this.params.data.step1;

    // ファイル読み込み時のイベントリスナ
    this.fileReader.onload = ({ target: { result } }) => 
    {
      const img = new Image();

      // 画像読み込み時のイベントリスナ
      img.onload = _ => {
        const canvasElem = this.canvas.nativeElement;
        const context = canvasElem.getContext('2d');
        const size = this.adjustImageDestinationSize(img.width, img.height);

        // canvasの範囲を指定してクリア
        context.clearRect(0, 0, canvasElem.width, canvasElem.height);
        // canvasに画像を描写
        context.drawImage(img, 0, 0, size.width, size.height);

        this.auth();
      };

      // 画像を読み込み
      img.src = result as string;
    };
  }

  /**
   * コンポーネントのビューが初期化された後に呼び出されるメソッド。
   *
   * @memberof FamilyAuthStep2Component
   */
  ngAfterViewInit(): void 
  {
    // 「＜」ボタンタップ時の挙動
    this.onsBackButton.nativeElement.onClick = () => 
    {
      this._navigator.element.popPage({
        callback: () => {
          this._faceAuthStep1Context.detectQRCode();
        }
      });
    };

    const fileElem = this.file.nativeElement;

    // ローカルからファイル読み込み時の処理
    fileElem.onchange = _ => 
    {
      const file = fileElem.files.item(0);

      if (!file) {
        return;
      }

      this.fileReader.readAsDataURL(file);
    };

    setTimeout(() => {
      this.detectFace();
    }, 0);
  }

  /**
   * コンポーネントが破棄される際に呼び出されるメソッド。
   *
   * @memberof FamilyAuthStep2Component
   */
  ngOnDestroy(): void 
  {
    this.m_Busy?.unsubscribe();
    this.stop();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 顔写真を撮影する。
   *
   * @memberof FamilyAuthStep2Component
   */
  shoot(): void 
  {
    const videoElem = this.video.nativeElement;
    const canvasElem = this.canvas.nativeElement;

    // 映像一時停止
    videoElem.pause();

    const context = canvasElem.getContext('2d');
    const size = this.adjustImageDestinationSize(videoElem.videoWidth, videoElem.videoHeight);

    // canvasの範囲を指定してクリア
    context.clearRect(0, 0, canvasElem.width, canvasElem.height);
    // canvasに描写
    context.drawImage(videoElem, 0, 0, size.width, size.height);
  }

  /**
   * 顔写真の撮影を再開する。
   *
   * @return {*}  {void}
   * @memberof FamilyAuthStep2Component
   */
  resume(): void 
  {
    if (!this.paused) {
      return;
    }

    this.video.nativeElement.play();
  }

  /**
   * 顔認証を行う。
   *
   * @memberof FamilyAuthStep2Component
   */
  auth(): void 
  {
    /**
     * エラー処理。
     *
     * @param {HttpErrorResponse} _err
     */
    // const errorHandler = (_err: HttpErrorResponse) => 
    // {
    //   const errContent = _err.error as SqrcWebApiSchemas.ResponseSchemas.AuthHttpCustomErrorContent;
    //   this.httpErrorResponseParserService.viewErrDialog(errContent).then(() => 
    //   {
    //     // QR読み取り失敗
    //     if (errContent.smartGotoErrCode === this.appMsg.SERV_CONST_CODE.SQRC.DECODE)
    //     {
    //       this.appMsg.viewDialogMessage(this.msg.CLIENT.SQRC.E_INVALID_QR.message(), () => 
    //       {
    //         // QR読み取りやり直し
    //         this._navigator.element.popPage().then(() => { this._faceAuthStep1Context.detectQRCode(); });
    //       });
    //     }
    //     // 顔認証失敗
    //     else if (errContent.smartGotoErrCode === this.appMsg.SERV_CONST_CODE.SQRC.FACE_AUTH) 
    //     {
    //       this.appMsg.viewDialogMessage(this.msg.CLIENT.SQRC.E_FAIL_AUTH.message(), () => 
    //       {
    //         // 顔読み取りやり直し
    //         this.detectFace();
    //       });
    //     }
    //     else 
    //     {
    //       // その他エラーの場合、追加画面へ遷移
    //       this.params.data.class.refresh(this.params.data.addPageIndex, false);
    //     }
    //   });
    // };

    this.stop();

    let reqBody: request.Auth = 
    {
      qr_image_list: [this._faceAuthStep1Context.getQRDataURL()], 
      face_image_list: [this.canvas.nativeElement.toDataURL('image/jpeg')]
    }

    // QR/顔認証
    // this.m_Busy = this.sqrcWebApiService.auth(reqBody)
    // .subscribe(
    //   {
    //     next: authResult => 
    //     {
    //       this.m_Busy = this.userWebApiService.getSomeUser<ExUser>({ user_id_encrypted: authResult.body.qrcode })
    //       .subscribe(
    //         {
    //           next: user => 
    //           {
    //             const MemberUser = user.body;
    //             let errCode: number;

    //             // 重複チェック
    //             switch(this.existMember(MemberUser.user_id)) 
    //             {
    //               //招待するメンバーがログインユーザーの場合
    //               case 0: 
    //               {
    //                 errCode = this.appMsg.CLIENT_CODE.FAMILY.DUPLOCATE_OWN_USER;
    //                 break;
    //               }
    //               //招待するメンバーがすでにメンバーに追加されている or 招待している場合
    //               case 1: 
    //               {
    //                 errCode = this.appMsg.CLIENT_CODE.FAMILY.DUPLOCATE_USER;
    //                 break;
    //               }
    //               default: 
    //               {
    //                 // ファミリー招待（STEP3）へ遷移
    //                 this._navigator.element.pushPage(FamilyInviteConfirmComponent, 
    //                   {
    //                     data: { 
    //                       qrcode: authResult.body.qrcode, 
    //                       menberName: user.body.profile.name, 
    //                       class: this.params.data.class, 
    //                       addPageIndex: this.params.data.addPageIndex, 
    //                       step2: this
    //                     }
    //                   });
    //                 return;
    //               }
    //             }
                
    //             // エラーメッセージを表示して、前画面に戻る
    //             this.appMsg.viewClientMsg(errCode).then(() => 
    //             {
    //               this._navigator.element.popPage().then(() => { this._faceAuthStep1Context.detectQRCode(); });
    //             });
    //           },
    //           error: errorHandler
    //         }
    //       );
    //     },
    //     error: errorHandler
    //   }
    // );
  }

  /**
   * カメラが一時停止中である場合は true。それ以外の場合は false。
   *
   * @readonly
   * @type {boolean}
   * @memberof FamilyAuthStep2Component
   */
  get paused(): boolean 
  {
    return !!this.video?.nativeElement.paused;
  }

  /**
   * カメラが利用可能である場合は true。それ以外の場合は false。
   *
   * @readonly
   * @type {boolean}
   * @memberof FamilyAuthStep2Component
   */
  get isStarted(): boolean 
  {
    return !!this.video?.nativeElement.srcObject;
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * 顔写真の撮影を開始する。
   *
   * @memberof FamilyAuthStep2Component
   */
  detectFace(): void 
  {
    this.m_Busy = this.start().subscribe(
      {
        next: mediaStream => 
        {
          const videoElem = this.video.nativeElement;

          videoElem.srcObject = mediaStream;

          videoElem.onloadedmetadata = _ => {
            videoElem.play();
          };
        },
        error: _error => 
        {
          this.appMsg.viewDialogMessage(this.msg.CLIENT.COMMON.E_INACTIVE_CAMERA.message(), _ => {
            this.params.data.class.refresh(this.params.data.addPageIndex, false);
          });
        }
    });
  }

  /**
   * 撮影を開始する。
   *
   * @private
   * @return {*}  {Observable<MediaStream>}
   * @memberof FamilyAuthStep2Component
   */
  private start(): Observable<MediaStream> 
  {
    return new Observable<MediaStream>(subscriber => 
      {
        navigator.mediaDevices
          .getUserMedia({
            audio: false,
            video: {
              facingMode: "environment"  // リアカメラ(背面カメラ)
            }
          })
          .then(mediaStream => {
            // カメラ映像をobservableとして流す
            subscriber.next(mediaStream);
            subscriber.complete();
          })
          .catch((error: MediaStreamError) => {
            subscriber.error(error);
            subscriber.complete();
          });
      });
  }

  /**
   * 撮影を終了する。
   *
   * @private
   * @return {*}  {void}
   * @memberof FamilyAuthStep2Component
   */
  private stop(): void 
  {
    if (!this.isStarted) {
      return;
    }

    const videoElem = this.video.nativeElement;

    (videoElem.srcObject as MediaStream).getVideoTracks().forEach(track => {
      track.enabled = false;
      track.stop();
    });

    videoElem.srcObject = null;
  }

  /**
   * 撮影サイズを取得する。
   *
   * @private
   * @param {number} width
   * @param {number} height
   * @return {*}  {{ width: number; height: number; }}
   * @memberof FamilyAuthStep2Component
   */
  private adjustImageDestinationSize(width: number, height: number): { width: number; height: number; } 
  {
    const canvasElem = this.canvas.nativeElement;
    const ratio = height / width;

    if (ratio < (canvasElem.height / canvasElem.width)) {
      return { width: canvasElem.width, height: canvasElem.width * ratio };
    } else {
      return { width: canvasElem.height / ratio, height: canvasElem.height };
    }
  }

  /**
   * メンバーの重複チェック
   *
   * @param {number} user_id
   * @return {*}  {boolean}
   * @memberof FamilyAuthStep2Component
   */
  existMember(user_id: string) : number 
  {
    this.m_Busy = this.FamilyWebApiService.getFamily().subscribe();
    //最後にgetFamilyが呼び出されたときの情報
    let relations = this.FamilyWebApiService.getFamilyInfo();
    let user = this.userWebApiService.getUserInfo();

    if (!user.user_id)
    {
      throw new Error();
    }

    const includesUserId = (source: Relation[]) => source
    .map(e => e.user_id)
    .includes(user_id);

    if (user.user_id === user_id)//招待するメンバーが自分でないか
    {
      return 0;
    }
    if (includesUserId(relations))//すでにメンバーとして招待されているか
    {
      return 1;
    }
  }
}