//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Params, OnsNavigator } from 'ngx-onsenui';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { FullCalendarComponent, CalendarOptions, DayCellMountArg, EventClickArg, EventInput } from '@fullcalendar/angular';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import * as ons from 'onsenui';

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { UserWebApiService } from '../../../http-services/user-web-api.service';
import { ExpWebApiService, UserSelectOption } from '../../../http-services/exp-web-api.service';
import { CommonFunctionModule } from "../../../lib-modules/common-function.module";
import { PagerService, PageKey } from 'src/app/lib-services/pager.service';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';
import { GoogleTagManagerService } from '../../../lib-services/google-tag-manager.service';
import { DatePickerService } from 'src/app/lib-services/date-picker.service';

// component
import { ListParts } from "../../parts/ons-list/ons-list.component";
import { ExpConfirmComponent } from '../exp-confirm/exp-confirm.component';
import { SigninComponent, SigninService } from '../../signin/signin.component';
import { TabbarComponent } from '../../tabbar/tabbar.component';

// interface
import { parameter } from '../../../interfaces/parameter';
import { ExpOptionPlace, ExpOptionNumber, ExpService, ExpOptionTime, ExpTimeRange, ExpPlace, ExpAvailable, ExpResourceAvailable, ShopCalendar, SgServiceOption, ExpFetchReservation, Settings } from '../../../interfaces/response';
import { request } from 'src/app/interfaces/request';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 観光サービス詳細画面(所要時間固定型)
 *
 * @export
 * @class ExpDetailComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'ons-page[exp-detail]',
  templateUrl: './exp-detail.component.html',
  styleUrls: ['./exp-detail.component.scss']
})
export class ExpDetailComponent implements OnInit, OnDestroy {

  readonly ASSETS = {
    INFORMATION: CommonFunctionModule.getAssetsUrl('/image/button/info.png')
  } as const;

  /**
   * http-busy
   *
   * @type {Subscription}
   * @memberof ExpDetailComponent
   */
  busy: Subscription;

  /**
   * サインイン監視用Subscription
   *
   * @private
   * @type {Subscription}
   * @memberof ExpDetailComponent
   */
  private onNextLinkChanged: Subscription;

  /**
   * template
   *  ons-list部品用
   *
   * @memberof ExpDetailComponent
   */
  template: {
    /**
     * parts-header
     *
     * @type {ListParts}
     * @memberof ExpDetailComponent
     */
    header: ListParts;

    /**
     * parts-body
     *
     * @type {ListParts[]}
     * @memberof ExpDetailComponent
     */
    body: ListParts[];

    /**
     * parts-footer
     *
     * @type {ListParts}
     * @memberof ExpDetailComponent
     */
    footer: ListParts;
  };

  /**
   * オプショングループ
   *
   * @type {ExpService["option_groups"]}
   * @memberof ExpDetailComponent
   */
  optionGroups: ExpService["option_groups"] = [];

  /**
   * サービス情報
   *
   * @type {ExpService}
   * @memberof ExpDetailComponent
   */
  service: ExpService;

  /**
   * ユーザ選択情報
   *
   * @type {common.ExpBill}
   * @memberof ExpDetailComponent
   */
  expBill: ExpBill;

  /**
   * モビリティ共通の利用開始日時が設定済みかどうか
   * true: 設定の必要あり & 未設定
   * false: 設定の必要なし or 設定済み
   *
   * @type {boolean}
   * @memberof ExpDetailComponent
   */
  isSettedMobilityStartDate: boolean = false;

  /**
   * 予約数の初期リスト
   *    料金種別数の合計値が最大予約数以下になるように
   *    可変となるため元となる初期リストを保存しておく
   *
   * @type {ListParts["select_box"]["item"][]}
   * @memberof ExpDetailComponent
   */
  orgReservNumber: ListParts["select_box"]["item"][];

  /**
   * 取得した最新の空き時間情報
   *
   * @type {ExpAvailable['available']}
   * @memberof ExpDetailComponent
   */
  targetAvailable: ExpResourceAvailable['available'] = [];

  /**
   * 表示中料金
   * 利用開始日時によって変わる
   *
   * @type {ExpService["prices"][number]}
   * @memberof ExpDetailComponent
   */
  targetServicePrice: ExpService["prices"][number];

  /**
   * mat-date-picker
   *
   * @type {MatDatepicker<undefined>}
   * @memberof ExpReserveListComponent
   */
  @ViewChild('matDatePicker') matDatePicker: MatDatepicker<undefined>;

  /**
   * datePickerの表示範囲
   *
   * @memberof ExpDetailComponent
   */
  datePickerRange = {
    start: new Date(),
    end: new Date()
  };

  /**
   * カレンダーの表示範囲
   * 
   * @memberof ExpDetailComponent
   */
  validRange = {
    start: moment().format(moment.HTML5_FMT.DATE),
    end: moment().add(6, 'months').add(1, 'days').format(moment.HTML5_FMT.DATE)
  } 

  /**
   * calendar
   *
   * @type {FullCalendarComponent}
   * @memberof ExpDetailComponent
   */
  @ViewChild('calendar') calendar: FullCalendarComponent;

  /**
   * 次画面への遷移ボタン
   *
   * @type {ElementRef}
   * @memberof ExpDetailComponent
   */
  @ViewChild('btn_trans') btn_trans: ElementRef;

  /**
   * ons-back-button
   *
   * @private
   * @type {ElementRef}
   * @memberof ExpDetailComponent
   */
  @ViewChild('onsBackButton') private onsBackButton: ElementRef;

  /**
   * calendar plugins
   *
   * @memberof ExpDetailComponent
   */
  calendarPlugins = [timeGridPlugin, interactionPlugin];

  /**
   * 選択中の日付
   * note: selectedDateのsetter以外での参照は禁止
   *
   * @private
   * @type {string}
   * @memberof ExpReserveListComponent
   */
  private _selectedDate: string;

  /**
   * 選択中の日付
   *
   * @type {string}
   * @memberof ExpReserveListComponent
   */
  get selectedDate(): string {
    return this._selectedDate;
  }

  /**
   * カレンダーに表示する空き情報を示した文字列
   *
   * @memberof ExpDetailComponent
   */
  readonly available = {
    OK: "〇",
    NG: "×"
  }

  /**
   * カレンダーの次週・翌週判定に使用
   *
   * @memberof ExpDetailType1Component
   */
  readonly calendarWeekType = {
    next: "翌週",
    prev: "前週"
  } 

  /**
   * オプション、インフォメーションマーク要素
   *
   * @type {QueryList<ElementRef>}
   * @memberof ExpDetailComponent
   */
  @ViewChildren('optionDialog') optionDialog: QueryList<ElementRef>;

  /**
   * カレンダー
   *
   * @type {CalendarOptions}
   * @memberof ExpDetailComponent
   */
  readonly calendarOptions: CalendarOptions = {

    // 種類
    initialView: "todayAsFirstDayOfWeek",
    plugins: [
      timeGridPlugin,      // Display events on Month view or DayGrid view
      interactionPlugin   // Provides functionality for event drag-n-drop, resizing, dateClick, and selectable actions
    ],
    views:{
      todayAsFirstDayOfWeek: {
        type: 'timeGridWeek',
        duration: {days: 7}
      }
    },
    validRange: this.validRange,    
    // タイトル
    titleFormat: {
      month: "long",
      day: "numeric"
    },
    // 終日OFF
    allDaySlot: false,
    // ツールバー
    headerToolbar: {left: 'today,prev,next', center: 'title', right: 'selectDate'},
    // ボタン
    buttonText: { today: "今日" },
    // ボタンヒント
    buttonHints: {
      prev: '前の週を表示',
      next: '次の週を表示',
      today: '今日の日付に戻る'
    },
    // 言語
    locale: 'ja',
    // リンクオフ
    navLinks: false,
    // 高さ(height)
    height: 650,
    // データセル
    dayCellContent: (date: DayCellMountArg) => {
      date.dayNumberText = date.dayNumberText.replace('日', '');  // カレンダーセルの「日」を除去
    },
    // データセルクリック　※空き情報はイベントで表示するため処理なし
    dateClick: (date: DateClickArg): void => {
      // this.selectedDate = moment(date.dateStr).format(moment.HTML5_FMT.DATE);  // 選択日の設定
      // this.selectedStartTime = moment(date.dateStr).format(moment.HTML5_FMT.TIME);
      // console.log(this.selectedStartTime);
    },
    // 日付ヘッダー
    dayHeaderFormat: function (date) {
      const day = date.date.day;
      const weekNum = date.date.marker.getDay();
      const week = ['(日)', '(月)', '(火)', '(水)', '(木)', '(金)', '(土)'][weekNum];
      return day + '\n' + week;
    },
    // カスタムボタン
    customButtons: {
      // date-picker
      selectDate: {
        hint: '日付を選択',
        click: () => {
          this.openDatePicker();
        }
      },
      // prev
      prev: {
        text: '<',
        hint: '前の週を表示',
        click: () => {
          this.setCalendarWeekChange(this.calendarWeekType.prev);
        }
      },
      // next
      next: {
        text: '>',
        hint: '次の週を表示',
        click: () => {
          this.setCalendarWeekChange(this.calendarWeekType.next);
        }
      },
      // today
      today: {
        text: '今日',
        hint: '今日の日付に戻る',
        click: () => {
          const date_today = moment().format(moment.HTML5_FMT.DATE);
          this.selectedDate = date_today;  // 今日の日付に設定
        }
      }
    },
    // NOTE: slotmaxtime slotmintimeで指定した表示するスクロール領域
    //     の一番上からスクロールする距離を指定。
    //     slotmaxtime slotmintimeを指定した際にscrollToTimeが効かなかった為、初期表示時のoptionで指定。
    // 初期表示を一番上に
    scrollTime: {hour: 0},

    // slot
    slotLabelFormat: { hour: 'numeric', minute: 'numeric' },
    slotLabelInterval: "00:30",

    // event
    eventShortHeight: 100,
    eventMinHeight: 10,
    displayEventTime: false,
    eventBackgroundColor: "#ffffff",
    eventBorderColor: "#000000",
    eventTextColor: "#000000",

    // event click
    eventClick: (arg: EventClickArg): void => {

      // 空き以外は処理しない
      if (arg.el.textContent !== this.available.OK) return;

      // 色初期化
      const eve = document.getElementsByClassName("fc-event");
      for (let i = 0; i < eve.length; i++) {
        if (eve.item(i).textContent === this.available.OK) eve.item(i).setAttribute("style", "background-color: #ffffff; border-color: #000000");
      }

      // 利用開始時間、終了時間
      this.expBill.start_time = arg.event.start.toISOString();
      const endtime = new Date(arg.event.start);
      endtime.setMinutes(endtime.getMinutes() + this.expBill.util_time);
      this.expBill.end_time = endtime.toISOString();

      const price_id_before: number = this.targetServicePrice.sg_price_id;
      // 利用開始日時に該当する料金設定を取得
      this.targetServicePrice = this.expServ.getTargetPrice(this.service.prices, moment(this.expBill.start_time));

      if (price_id_before !== this.targetServicePrice.sg_price_id) {
        // 料金再計算
        this.calcuPrice();

        // 料金を再設定
        let price = this.template.body.filter(b => b.id === "price");

        price.forEach((p, index: number) => {

          let viewPrice: string = "";
          if (this.expBill.price.length >= 2) viewPrice += this.service.user_params.numbers.find(n => n.type === this.expBill.numbers[index].type).name + "：";
          viewPrice += this.commonFunc.numericalForming(this.expBill.price[index].value) + "/" + this.expBill.unit;

          p.common.item = viewPrice;
        });

        // 料金詳細説明を利用時間に応じて更新
        if (this.service.user_params.numbers.length >= 2 && this.service.service_type == "ACTIVITY") {
          this.template.body.find(b => b.id === "price").header_dialog_info = this.expServ.getPriceDescription(this.service.user_params.numbers, this.targetServicePrice, this.expBill.util_time);
        }
      }

      // 選択した時間をモビリティ共通日時として設定
      if (this.service.service_type === 'MOBILITY') this.expServ.mobilityStartDate = this.expBill.start_time;

      // 選択イベントの色変更
      arg.el.style.backgroundColor = "skyblue";

      this.checkAllTimeOption();

      // 遷移ボタン表示切替
      this.switchOverTransitionBtn();
    },
  };

  /**
   * 設定情報(体験サービス)
   *
   * @type {Settings["exp"]}
   * @memberof ExpDetailComponent
   */
  settingExp: Settings["exp"];

  /**
   * OnDestroy時に破棄するSubscriptionオブジェクト
   *
   * @memberof ExpDetailComponent
   */
  subscription = new Subscription();

  /**
   * 説明欄(表示用)
   *
   * @type {string}
   * @memberof ExpDetailComponent
   */
  dispDescription: string;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of ExpDetailComponent.
   * @param {ApplicationMessageService} appMsgServ
   * @param {HttpErrorResponseParserService} errResServ
   * @param {UserWebApiService} userServ
   * @param {ExpWebApiService} expServ
   * @param {CommonFunctionModule} commonFunc
   * @param {OnsNavigator} navigator
   * @param {Params} params
   * @param {PagerService} pagerServ
   * @param {TabbarComponent} tabbarComp
   * @memberof ExpDetailComponent
   */
  constructor(
    private appMsgServ: ApplicationMessageService,
    private msg: MESSAGE,
    private errResServ: HttpErrorResponseParserService,
    private userServ: UserWebApiService,
    private expServ: ExpWebApiService,
    private commonFunc: CommonFunctionModule,
    private navigator: OnsNavigator,
    private params: Params,
    private pagerServ: PagerService,
    private signinServ: SigninService,
    private tabbarComp: TabbarComponent,
    private municipalityWebApiServ: MunicipalityWebApiService,
    private gtmServ: GoogleTagManagerService,
    private datePickerService: DatePickerService,
  ) {
    const settingsChanged = this.municipalityWebApiServ.settingsChanged.subscribe({
      next: setting => {
        if (setting == null) return;
        this.settingExp = setting.exp;
      }
    });
    this.subscription.add(settingsChanged);
  }

  /**
   * 初期化処理。
   *
   * @memberof ExpDetailComponent
   */
  ngOnInit(): void {

    // カレンダーの最大表示日数（自治体毎の設定値Nヵ月後まで）
    this.datePickerRange.end.setMonth(this.datePickerRange.end.getMonth() + this.settingExp.reservation.max_month);

    // タブバー非表示
    this.tabbarComp.setTabbarVisibility(false);

    // サービス情報
    this.service = this.params.data.service;

    // 料金（初期値は当日）
    this.targetServicePrice = this.expServ.getTargetPrice(this.service.prices, moment());

    // オプションソート
    if (this.service.option_groups) {
      // オプショングループ順ソート
      this.optionGroups = this.service.option_groups.sort((a, b) => {
        return a.index - b.index;
      });

      // グループ一つ目からさらにグループ内ソート
      this.optionGroups.forEach(group => {
        group.options.sort((a, b) => {
          return a.index - b.index;
        });
      });
    }

    // 初期値
    this.initialExpBill();

    /**
     *
     * 画面表示list作成
     *
     */
    this.template = {
      header: {
        mode: "header",
        header_list: {
          item: [this.service.title, "提供：" + this.service.shop.name],
          icon: this.service.images[0] ?? ""
        }
      },
      body: [],
      footer: {
        header: 'キャンセル料ルール',
        common: { item: this.expServ.getDispCancelRules(this.targetServicePrice.cancel_rules) }
      }
    };

    // const viewDate = this.expServ.createViewDate(this.service, this.expBill.price);
    // this.template.body = viewDate.parts;
    // this.orgReservNumber = viewDate.orgReservNumber;

    // リストデータ生成
    const params = this.service.user_params;

    /** 貸出場所 @type {*} */
    let p_rental: ListParts["select_box"]["item"] = [];

    /** 返却場所 @type {*} */
    let p_return: ListParts["select_box"]["item"] = [];

    /** 開催場所 @type {*} */
    let p_place: ListParts["select_box"]["item"] = [];

    /** 利用時間 @type {*} */
    let util_time: ListParts["select_box"]["item"] = [];

    /** 予約数 @type {*} */
    let reserv_number: ListParts["select_box"]["item"][] = [];

    // 利用時間
    params.util_time.select.forEach(n => util_time.push({ key: n, name: this.commonFunc.TimeConvert(n) }));

    // モビリティ
    if (this.service.service_type === "MOBILITY") {
      // 貸出場所、返却場所
      params.rental_place.select.forEach(p => p_rental.push({ key: p.name, name: p.name, link: this.commonFunc.getGoogleMapUrl(p.location) }));
      params.return_place.select.forEach(p => p_return.push({ key: p.name, name: p.name, link: this.commonFunc.getGoogleMapUrl(p.location) }));
    }
    // アクティビティ
    else if (this.service.service_type === "ACTIVITY") {
      // 開催場所
      params.place.select.forEach(p => p_place.push({ key: p.name, name: p.name, link: this.commonFunc.getGoogleMapUrl(p.location) }));
    }
    else ;

    // 予約数、料金
    params.numbers.forEach((n, index: number) => {
      reserv_number.push([]);

      // 初期値
      this.expBill.numbers.push({type: n.type, value: n.select[0]});

      // for (let i = 1; i <= this.service.capacity; i++) {
      //   if (i === 0 && params.numbers.length >= 2) reserv_number[index].push({ key: "0", name: "0" + n.unit });
      //   reserv_number[index].push({ key: i, name: i.toString() + n.unit });
      // }
      n.select.forEach(p => reserv_number[index].push({ key: p, name: p.toString() + n.unit }));

      let price: string = "";
      if (params.numbers.length >= 2) price += n.name + "：";
      price += this.commonFunc.numericalForming(this.expBill.price[index].value) + "/" + this.expBill.unit;
    });
    // 料金計算
    this.calcuPrice();
    let viewPrice = params.numbers.map((n, index) => {
      let price: string = "";
      if (params.numbers.length >= 2) price += n.name + "：";
      price += this.commonFunc.numericalForming(this.expBill.price[index].value) + "/" + this.expBill.unit;
      return price;
    });

    // オリジナル情報を保存
    this.orgReservNumber = this.commonFunc.deepcopy(reserv_number);

    this.dispDescription = this.commonFunc.replaceUrl(this.commonFunc.replaceEscapeSequence(this.service.description));

    // 本文
    this.template.body = [
    {
      displayOrder: 2,
      header: "カテゴリ",
      common: { item: this.service.categories.join("、") }
    },
    {
      displayOrder: 5,
      mode: util_time.length > 1 ? "selectBox" : "common",
      header: "利用時間",
      select_box: {
        item: util_time,
        select_id: "util_time"
      },
      common: {
        item: util_time[0]?.name ?? "",
      }
    }];

    // 料金種別数
    const length = this.service.user_params.numbers.length;

    // 料金
    viewPrice.forEach((p, index: number) => {
      this.template.body.push({
        displayOrder: 6,
        id: "price",
        common: { item: p },
      });
      // ヘッダーは先頭のみ
      if (index === 0) {
        this.template.body[this.template.body.length - 1].header = "料金";
        // 料金種別が複数 & アクティビティサービスの場合、料金詳細説明(infoボタン)を表示
        if (length >= 2 && this.service.service_type == "ACTIVITY") {
          this.template.body[this.template.body.length - 1].header_dialog_info = this.expServ.getPriceDescription(this.service.user_params.numbers, this.targetServicePrice, this.expBill.util_time);
        }
      }
    });

    if (this.service.service_type === "MOBILITY") {
      this.template.body.push({
        displayOrder: 3,
        id: "rental",
        mode: p_rental.length > 1 ? "selectBox" : "common",
        header: "貸出場所",
        select_box: {
          item: p_rental,
          is_link: true,
          select_id: "rental"
        },
        common: {
          item: p_rental[0].name,
          link: p_rental[0].link
        },
      },
      {
        displayOrder: 4,
        id: "return",
        mode: p_return.length > 1 ? "selectBox" : "common",
        header: "返却場所",
        select_box: {
          item: p_return,
          is_link: true,
          select_id: "return"
        },
        common: {
          item: p_return[0].name,
          link: p_return[0].link
        },
        valid_msg: ""
      },
      {
        displayOrder: 7,
        id: "reserv_number",
        mode: reserv_number[0].length > 1 ? "selectBox" : "common",
        header: "予約数",
        select_box: {
          item: reserv_number[0],
          select_id: "reserv_number",
        },
        common: {
          item: reserv_number[0][0].name ?? "",
        }
      });
    }
    else if (this.service.service_type === "ACTIVITY") {
      this.template.body.push({
        displayOrder: 3,
        id: "place",
        mode: p_place.length > 1 ? "selectBox" : "common",
        header: "開催場所",
        select_box: {
          item: p_place,
          is_link: true,
          select_id: "place"
        },
        common: {
          item: p_place[0].name,
          link: p_place[0].link
        },
      });

      reserv_number.forEach((r, index: number) => {
        this.template.body.push({
          displayOrder: 7,
          id: "reserv_number" + index,
          mode: "selectBox",
          select_box: {
            item: r,
            select_id: "reserv_number" + index
          }
        });

        // 料金種別が複数ある場合のみ種別名を表示
        if (length >= 2) this.template.body[this.template.body.length - 1].select_box.type_name = this.service.user_params.numbers[index].name;

        // todo　capacityの単位をどうだすか（10「人」の部分）
        if (index === 0) {

          this.template.body[this.template.body.length - 1].header = this.service.capacity ? "予約数" + (length > 1 ? "（最大：" + this.service.capacity + this.service.user_params.numbers[index].unit + "）" : "") : "予約数";

          // this.template.body[this.template.body.length - 1].header = "予約数" + (length > 1 ? "（最大：" + this.service.capacity + this.service.user_params.numbers[index].unit + "）" : "");
        }
      });
    }

    // 表示順にソート
    this.template.body.sort((a, b) => a.displayOrder - b.displayOrder);

    this.gtmServ.pushExpDetailPageview(this.service);
  }

  /**
   * afterViewInit
   *
   * @memberof ExpDetailComponent
   */
  ngAfterViewInit(): void {

    // 詳細画面からサインインを求められた場合、サインイン後にほかのタブへ遷移する可能性がある。
    // 詳細画面は下部タブが非表示のため、タブを再表示させる。
    // ほかのタブから詳細画面（利用するタブをタップ）に戻ってきた際にはタブを再び非表示にする。
    // ◆実装手段
    // ①詳細画面遷移時ページスタックインデックスを保存しておく
    // ②ほかのタブへ遷移時に下部タブを再表示（signinComponent、AccountListComponent）
    // ③利用するタブタップ時、現画面が観光詳細の場合（①のインデックスから判断）、下部タブを非表示（TabbarCompoenntのngAfterViewInitに記述）
    // ④詳細画面から次の画面へ遷移する前に①のインデックスを破棄しておく
    this.pagerServ.setReturnPage({ index: this.navigator.element.pages.length - 1, key: PageKey.ExpDetailComponent });

    // オプション、インフォメーションマークclick時、二重イベント止める
    this.optionDialog.forEach(item => {
      item.nativeElement.addEventListener('click', (event) => {
        console.log('click');
        event.stopPropagation();
      });
    })

    // バックボタン
    this.onsBackButton.nativeElement.onClick = () => {

      // タブバー再表示
      this.tabbarComp.setTabbarVisibility(true);

      // 詳細画面のページインデックスが不要となるため、削除しておく
      this.pagerServ.deleteReturnPage(PageKey.ExpDetailComponent);

      const isUpdate: boolean = this.params.data.updated===true ? true : false;

      if (isUpdate) {
        if (this.params.data.isLink) {
          // popPage
          this.pagerServ.transitionToPage(this.navigator, PageKey.ExpComponent);
        }
        else this.pagerServ.transitionToPage(this.navigator, PageKey.ExpServiceListComponent);
      }
      else {
        // popPage
        this.navigator.element.popPage().then(() => {
          // NOTE: モビパからの遷移の場合、トップ画面に戻る
          // 観光トップ画面のswiper.jsを初期化
          if (this.params.data.isLink) this.params.data.swiperInit();
        });
      }
    };

    // サインイン処理を監視
    this.onNextLinkChanged = this.signinServ.changed.subscribe({
      next: (leaveSignin: boolean) => {
        if (leaveSignin === false) {
          // イベント、ボタン初期化
          this.calendar.getApi().removeAllEvents();
          this.initial();
          this.setInitAvailable();
        }
      }
    });

    this.initial();

    // カスタムボタンにカレンダーアイコンを挿入
    this.calendar.getApi().el.querySelector('button.fc-selectDate-button')?.insertAdjacentHTML('beforeend', '<span class="material-icons calendar-icon">calendar_month</span>');

    // 今日ボタンを非活性
    this.calendar.getApi().el.querySelector<HTMLButtonElement>('.fc-today-button').disabled = true;

    // カレンダー スワイプ処理の設定
    var detector = ons.GestureDetector(document.querySelector('#week-calendar'), {swipeVelocityX: 0.1});
    detector.on('swipeleft', ()=> {
      this.setCalendarWeekChange(this.calendarWeekType.next);
    });

    detector.on('swiperight', ()=> {
      this.setCalendarWeekChange(this.calendarWeekType.prev);
    });

    setTimeout(() => {
      if(this.params.data.from_usage_date_search && this.params.data.usage_date){
        this.selectedDate = moment(this.params.data.usage_date).format(moment.HTML5_FMT.DATE)
      }else{
        // 空き日程、初期処理
        this.setInitAvailable();
      }
      
      this.checkAllTimeOption();

      // 貸出、返却場所チェック
      this.checkIsDropOff();

      // 最少人数チェック
      if (this.service.service_type === "ACTIVITY") {

        const sumNum = this.expBill.numbers.map(n => n.value).reduce((a, b) => a + b);
        const index = this.template.body.findIndex(b => b.id === "reserv_number" + (this.service.user_params.numbers.length - 1));
        // console.log("index: ", index, "sum: ", sumNum,  "this.template.body: ", this.template.body);

        // いずれかの利用数が未選択
        if (this.expBill.numbers.filter(n => n.value < 0).length !== 0) this.template.body[index].valid_msg = "すべて選択してください。";
        else {
          if (this.expBill.err_min_number = this.expServ.isMinNumberCheck(this.service.constraints, sumNum))
            // todo 表示するメッセージは制約によって切り替わる。現状は配列[0]に最小人数制約が入っている前提
            this.template.body[index].valid_msg = this.service.constraints[0].message;
          else this.template.body[index].valid_msg = "";
        }
      }

      // 遷移ボタン表示切り替え
      this.switchOverTransitionBtn();
    })
  }

    /**
   * 日付選択ボタンをクリックしたときのイベントハンドラ
   */
    private openDatePicker() {
      this.matDatePicker.open();
  
      setTimeout(() => {
        this.datePickerService.positionDatePicker();
      }, 0);
    }

  /**
   * 破棄処理。
   *
   * @memberof ExpDetailComponent
   */
  ngOnDestroy(): void {

    this.busy?.unsubscribe();
    this.onNextLinkChanged?.unsubscribe();
    this.subscription?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * datePickerで日付選択時のイベントハンドラ
   *
   * @param {MatDatepickerInputEvent<Date>} event
   * @memberof ExpReserveListComponent
   */
  onChangeDate(event: MatDatepickerInputEvent<Date>): void {

    const selected = moment(event.value).format(moment.HTML5_FMT.DATE);
    this.selectedDate = selected;  // 選択日を設定

    // イベント、ボタン初期化
    // this.calendar.getApi().removeAllEvents();
    // this.initial();

    // // 取得済みの空き情報を使ってカレンダーを更新
    // this.addCustomEventSource();
  }

  /**
   *
   *
   * @param {ExpService["option_groups"][number]["options"][number]} option
   * @memberof ExpDetailComponent
   */
  onClickOptionInfo(option: ExpService["option_groups"][number]["options"][number]): void {
    this.expServ.openOptionDialog(option);
  }

  /**
   * オプション変更(user_option: yesno)
   *
   * @param {ExpService["option_groups"][number]["options"][number]} option
   * @param {(SgServiceOption["yesno_param"]["items"][number] | SgServiceOption["select_param"]["items"][number])} item
   * @memberof ExpDetailComponent
   */
  onChangeOptionYesno(option: ExpService["option_groups"][number]["options"][number], item: SgServiceOption["yesno_param"]["items"][number]): void {
    this.expBill.options[option.sg_option_id+""].yesno_param = item;
    this.checkTimeOption(option);
    this.switchOverTransitionBtn();
  }

  /**
   * オプション変更(user_option: select)
   *
   * @param {ExpService["option_groups"][number]["options"][number]} option
   * @param {SgServiceOption["select_param"]["items"][number]} item
   * @memberof ExpDetailComponent
   */
  onChangeOptionSelect(option: ExpService["option_groups"][number]["options"][number], item: SgServiceOption["select_param"]["items"][number]): void {
    this.expBill.options[option.sg_option_id+""].select_param = item;
  }

  /**
   *
   *
   * @param {ExpService["option_groups"][number]["options"][number]} option
   * @param {number} value
   * @memberof ExpDetailComponent
   */
  onChangeOptionNumber(option: ExpService["option_groups"][number]["options"][number], value: number): void {
    this.expBill.options[option.sg_option_id+""].number_param.selected = value;
  }

  /**
   * 日数選択型オプション 変更
   *
   * @param {ExpService["option_groups"][number]["options"][number]} option
   * @param {number} item
   * @memberof ExpDetailComponent
   */
  onChangeOptionTime(option: ExpService["option_groups"][number]["options"][number], item: number) {

    this.expBill.options[option.sg_option_id+""].time_param.selected = item;
    this.checkTimeOption(option);
    this.switchOverTransitionBtn();
  }

  // /**
  //  *
  //  *
  //  * @param {ExpService["option_groups"][number]["options"][number]} option
  //  * @param {number} value
  //  * @memberof ExpDetailComponent
  //  */
  // onChangeOptionTime(option: ExpService["option_groups"][number]["options"][number], value: number): void {
  //   this.expBill.options[option.sg_option_id+""].selected = value;
  // }

  /**
   * 次画面へ遷移する。
   *
   * @memberof ExpDetailComponent
   */
  onTransition(): void {

    // 請求先がない（未ログイン）場合、遷移せずログイン促し
    if (false === this.userServ.isLoggedIn()) {
      this.appMsgServ.viewDialogMessage(this.msg.CLIENT.COMMON.E_UNLOGIN_USE_FUNCTION.message(), () => {

        this.pagerServ.setUserAppNavigator = true;
        this.pagerServ.getAppNavigator.element.pushPage(SigninComponent, { data: { exp: true, redraw: () => {

          // // イベント、ボタン初期化
          // this.calendar.getApi().removeAllEvents();
          // this.initial();
          // this.communicationsCommand(true);
        }}});
      });

      return;
    }

    // 詳細画面のページインデックスが不要となるため、削除しておく
    this.pagerServ.deleteReturnPage(PageKey.ExpDetailComponent);


    const setOption = () => {
      let optionBody: request.ExpReservation["service_options"] = [];
      this.service.option_groups.forEach(group => {
        group.options.forEach(option => {
          const targetBill: UserSelectOption = this.expBill.options[option.sg_option_id];
          let optionItem: request.ExpReservation["service_options"][number] = {
            sg_option_id: option.sg_option_id,
            sg_option_price_id: this.expServ.getTargetOptionPrice(option.prices, moment(this.expBill.start_time)).sg_option_price_id,
          }

          if (option.user_option) optionItem.user_option = {};
          switch(option.user_option) {
            case 'comment':
              optionItem.user_option.comment = targetBill.comment;
              break;
            case 'yesno':
              optionItem.user_option.yesno = targetBill.yesno_param.value;
              break;
            case 'select':
              optionItem.user_option.select = targetBill.select_param.value;
              break;
            case 'number':
              optionItem.user_option.number = targetBill.number_param.selected;
              break;
            default:
              console.log("想定外");
          }

          // 時間選択
          if (option.time_rule !== 'none') optionItem.time_option = {};
          switch(option.time_rule) {
            case 'days':
              // yesno型オプション&「希望しない」を選択時、daysを0に
              if (option.user_option === 'yesno' && targetBill.yesno_param.value === false) optionItem.time_option.days = 0;
              else optionItem.time_option.days = targetBill.time_param.selected;
              break;
            // case 'mins':
            //   targetOption.time_option.min = target.time_param.selected;
            //   break;
          }

          optionBody.push(optionItem);
        })
      })
      return optionBody;
    }

    // fetch用リクエストボディ作成
    let reqBody: request.ExpReservation = {
      sg_service_id: this.service.sg_service_id,
      numbers: this.expBill.numbers,
      util_time: this.expBill.util_time,
      start_time: this.expBill.start_time,
      end_time: this.expBill.end_time,
      sg_price_id: this.targetServicePrice.sg_price_id,
      service_options: setOption()
    };

    // モビリティ 貸出、返却場所
    if (this.service.service_type === "MOBILITY") {
      reqBody.rental_place = this.expBill.rental_place;
      reqBody.return_place = this.expBill.return_place;
    }
    // アクティビティ 開催場所
    else if (this.service.service_type === "ACTIVITY") {
      reqBody.place = this.expBill.place;
    }

    // fetch
    this.busy = this.expServ.postFetchReservation(reqBody).subscribe({
      next: res => {

        const body: ExpFetchReservation = res.body;
        // 次画面へ
        this.navigator.element.pushPage(ExpConfirmComponent, {
          data: {
            fetch: body,
            service: this.service,
            expBill: this.expBill,
            updateSchedule: (updateSchedule: boolean, updateService: boolean=false) => {this.backToDetail(updateSchedule, updateService)}
          }
        });
      },
      error: this.errResServ.doParse((_err, errContent) => {

        switch(errContent.smartGotoErrCode) {
          case this.appMsgServ.SERV_CONST_CODE.SG_OPTION_PRICE.CALC_FAILED:
            const errOption: {sg_option_id: number, param: "number"|"days"|"mins", table_limit: number} = errContent.smartGotoErrOpt;
            let optionName: string = void 0;
            this.service.option_groups.some(group => {
              const result = group.options.find(option => option.sg_option_id === errOption.sg_option_id);
              if (result === void 0) return false;
              optionName = result.name;
              return true;
            });
            this.errResServ.viewErrDialog(errContent, optionName);
            break;
          // case this.appMsgServ.SERV_CONST_CODE.EXP.RESERVE_LIMIT:
          //   this.appMsgServ.viewClientMsg(this.appMsgServ.CLIENT_CODE.EXP.RESERVE_LIMIT_CONFIRM);
          //   break;
          case this.appMsgServ.SERV_CONST_CODE.SG_OPTION.UNMATCH_REQUEST:
            this.appMsgServ.viewDialogMessage(this.msg.CLIENT.EXP.OPTION_UNMATCH_REQUEST.message(), () => {
              this.updateService();
            });
            break;
          default:
            this.errResServ.viewErrDialog(errContent);
        }
      })
    });
  }

  /**
   * セレクトボックス変更時の値格納処理。
   *
   * @param {*} event
   * @memberof ExpDetailComponent
   */
  selectedEvent(event: any): void {

    switch (event.id) {
      // 貸出場所
      case "rental":
        this.expBill.rental_place = this.service.user_params.rental_place.select.find(f => f.name === event.key);
        this.checkIsDropOff();
        break;
      // 返却場所
      case "return":
        this.expBill.return_place = this.service.user_params.return_place.select.find(f => f.name === event.key);
        this.checkIsDropOff();
        break;
      // 開催場所
      case "place":
        this.expBill.place = this.service.user_params.place.select.find(f => f.name === event.key);
        break;
      // 利用時間
      case "util_time":
        this.expBill.util_time = Number(event.key);

        // 料金再計算
        this.calcuPrice();

        // 料金を再設定
        let price = this.template.body.filter(b => b.id === "price");

        price.forEach((p, index: number) => {

          let viewPrice: string = "";
          if (this.expBill.price.length >= 2) viewPrice += this.service.user_params.numbers.find(n => n.type === this.expBill.numbers[index].type).name + "：";
          viewPrice += this.commonFunc.numericalForming(this.expBill.price[index].value) + "/" + this.expBill.unit;

          p.common.item = viewPrice;
        });

        // 料金詳細説明を利用時間に応じて更新
        if (this.service.user_params.numbers.length >= 2 && this.service.service_type == "ACTIVITY") {
          this.template.body.find(b => b.id === "price").header_dialog_info = this.expServ.getPriceDescription(this.service.user_params.numbers, this.targetServicePrice, this.expBill.util_time);
        }
        break;
      // 利用数、人数
      case "reserv_number":
        const key = Number(event.key);

        // リストに変更がないなら終了
        if (this.expBill.numbers[0].value ===  key) return;

        // 選択値を保存
        this.expBill.numbers[0].value = key;
        break;
      default:
        if (!event.id.indexOf("reserv_number")){

          // 対象の要素番号（２桁（最大９９=length１００）まで対応）を取得
          const typeIndex: number = Number((<string>event.id).slice(this.expBill.numbers.length >= 10 ? -2 : -1));

          /** このイベントによって入力された数 */
          const key = Number(event.key);

          // リストに変更がないなら終了
          if (this.expBill.numbers[typeIndex].value === key) return;

          // 選択値を保存
          this.expBill.numbers[typeIndex].value = key;

          if (this.service.capacity !== void 0) {
            // 操作した料金種別以外の予約数の上限値を設定
            let reserv_numbers = this.template.body.filter(b => b?.id != undefined && !b?.id.indexOf("reserv_number"));
            /** 現在の全リソースの合計選択数 */
            const counts = this.expBill.numbers.reduce((pre,cur) => pre+cur.value,0);
            reserv_numbers.forEach((n, index: number) => {

              // (最大予約数 - 現在の合計選択数 + このリソースの選択数)を上限としたものに変更
              n.select_box.item = this.commonFunc.deepcopy(this.orgReservNumber[index].filter(item => (this.service.capacity - counts + this.expBill.numbers[index].value) >= Number(item.key)));
            });
          }

          // 最少人数チェック
          if (this.service.service_type === "ACTIVITY") {

            const sumNum = this.expBill.numbers.map(n => n.value).reduce((a, b) => a + b);
            const index = this.template.body.findIndex(b => b.id === "reserv_number" + (this.service.user_params.numbers.length - 1));
            // console.log("index: ", index, "sum: ", sumNum,  "this.template.body: ", this.template.body);

            // いずれかの利用数が未選択
            if (this.expBill.numbers.filter(n => n.value < 0).length !== 0) this.template.body[index].valid_msg = "すべて選択してください。";
            else {
              if (this.expBill.err_min_number = this.expServ.isMinNumberCheck(this.service.constraints, sumNum))
                // todo 表示するメッセージは制約によって切り替わる。現状は配列[0]に最小人数制約が入っている前提
                this.template.body[index].valid_msg = this.service.constraints[0].message;
              else this.template.body[index].valid_msg = "";
            }
          }
        }
        else return;
    }

    // イベント、ボタン初期化
    this.calendar.getApi().removeAllEvents();
    this.initial();

    // 利用時間変更時は空き情報を再取得し、カレンダーを更新
    if (event.id == "util_time") {

      // スケジュール期間を取得
      // const range = this.getScheduleRange();

      // 空き情報取得
      this.communicationsCommand();
    }
    // 取得済みの空き情報を使ってカレンダーを更新
    else this.addCustomEventSource();

    // 日数選択型オプションチェック
    this.checkAllTimeOption();

    // 遷移ボタン表示切り替え
    this.switchOverTransitionBtn();
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * 初期化。
   *
   * @memberof ExpDetailComponent
   */
  initial(): void {

    // 利用開始/終了時刻
    this.expBill.start_time = "";
    this.expBill.end_time = "";

    // 次画面遷移ボタン非表示
    this.btn_trans.nativeElement.style.display = "none";
  }

  /**
   * クライアント表示データ初期化
   *
   * @memberof ExpDetailComponent
   */
  initialExpBill(): void {

    const initNumber = (num: ExpOptionNumber): number => {

      if (num === undefined) return undefined;

      // let select: number[] = [];
      // num.select[0] === 0 ? select = num.select.splice(1) : select = num.select;
      return num.select ? Math.min(...num.select) : num.from
    };

    const initPlace = (place: ExpOptionPlace): ExpPlace => {

      if (place === undefined || place.select === undefined || place.select.length === 0) return undefined;
      return place.select[0];
    };

    const initTime = (time: ExpOptionTime): string => {

      if (time === undefined) return undefined;
      return time.select ? time.select[0] : time.from;
    };

    // オプション初期表示設定
    const initOption = (): void => {
      // オプション設定がない場合、処理をぬける
      if (!this.optionGroups) {
        this.expBill.options = null;
        return;
      }

      this.expBill.options = {};
      // サービスオプションをユーザー選択状態プロパティに変換
      this.optionGroups.forEach(group => {
        group.options.forEach(option => {
          let expBillOption: UserSelectOption = {};

          switch (option.user_option) {
            case 'yesno':
              // デフォルトプロパティが付与されている物を初期値に
              expBillOption.yesno_param = option.yesno_param.items.find(item => item.default === true);
              break;
            case 'select':
              // デフォルトプロパティが付与されている物を初期値に
              expBillOption.select_param = option.select_param.items.find(item => item.default === true);
              break;
            case 'number':
              let number: UserSelectOption["number_param"] = this.commonFunc.deepcopy(option.number_param);
              // セレクトボックス内容を指定されている場合
              if (number.select) {
                // 初期値
                number.selected = number.select[0];
                expBillOption.number_param = number;
              }
              else {
                number.select = [];
                // from, to, stepを元にセレクトボックス配列作成
                for (let num = number.from; num <= number.to; num += number.step) {
                  number.select.push(num);
                }
              }
              // 初期値
              number.selected = number.select[0];
              expBillOption.number_param = number;
              break;
            case 'comment':
              expBillOption.comment = "";
              break;
            default:
              console.log("想定外");
          }

          // 個数決定タイプを選択する必要ありの場合、利用日数or時間のセレクトボックス配列作成
          if (option.time_rule === 'days' || option.time_rule === 'mins') {

            expBillOption.time_param = option.time_param;
            expBillOption.time_param.select = [];

            // NOTE: 個数決定タイプごとの上限までセレクトボックスを作成。
            //        チェック関数で設定可能か確認　
            // from, to, stepを元にセレクトボックス配列作成
            const to: number = Math.min(Math.floor(this.settingExp.service.max_reserve_hour/24)+1, expBillOption.time_param.to);
            for (let num = expBillOption.time_param.from; num <= to; num += expBillOption.time_param.step) {
              if (num === 0) continue;
              expBillOption.time_param.select.push(num);
            }
            // 初期値
            expBillOption.time_param.selected = expBillOption.time_param.select[0];
          }
          // エラー初期値
          expBillOption.err = {
            message: "",
            check: false
          };
          expBillOption.time_err = {
            message: "",
            check: false
          };

          this.expBill.options[option.sg_option_id+""] = expBillOption;
        })
      })
    }

    const initRange = (range: ExpTimeRange): { from: string, to: string } => {

      if (range === undefined || !range.select.type || range.select?.type?.length === 0) return undefined;
      return range.select.type[0];
    };

    const params = this.service.user_params;
    this.expBill = {
      payer_name: "",

      // 場所
      rental_place: initPlace(params?.rental_place),
      return_place: initPlace(params?.return_place),
      place: initPlace(params?.place),

      // 乗り捨て不可エラー
      err_drop_off: false,

      // 最少人数エラー
      err_min_number: false,

      // 利用時間
      util_time: initNumber(params?.util_time),

      // 利用数
      // number: initNumber(params?.numbers[0]),
      numbers: [],

      // 利用数単位,
      unit: params?.numbers[0]?.unit,

      // 利用可能時間帯
      time_range: initRange(params?.time_range),

      // 料金
      price: [],
    };

    // オプション初期値設定
    initOption();

    if (this.service.service_type == 'ACTIVITY') {
      // 開始、終了時刻
      this.expBill.start_time = initTime(params?.start_time);
      this.expBill.end_time = initTime(params?.end_time);
    }

    this.service.user_params.numbers.forEach(p => {
      this.expBill.price.push({ name: p.name ?? "", value: 0 });
    });
    console.log(this.expBill.options);
  }

  /**
   * 空き日程 初期処理
   *
   * @private
   * @memberof ExpDetailComponent
   */
  private setInitAvailable(): void {
    const subject: Subject<boolean> = new Subject();

    // 営業時間、空き情報取得
    this.busy = subject.subscribe({
      next: isSetted => {
        if (isSetted === false) {
          setTimeout(() => {
            this.communicationsCommand(true);
          }, 0);
        }
      },
      error: this.errResServ.doParse((_err, errContent) => this.errResServ.viewErrDialog(errContent))
    })

    // モビリティ共通の利用開始日時が設定されていた場合
    if (this.service.service_type === 'MOBILITY' && this.expServ.mobilityStartDate !== "") {
      const date: string = this.expServ.mobilityStartDate;

      // モビリティ共通の利用開始日時から1週間の営業時間を取得
      this.busy = this.getShopCalendar(date).subscribe({
        next: res => {
          const businessHour = res.body;
          // 営業時間内かチェック
          if (this.checkIncludeBusinessHour(date, businessHour) == true) {
            // 開始日時を設定する必要あり
            this.isSettedMobilityStartDate = true;

            // 表示週切り替え
            this.selectedDate = date;

            subject.next(true);
            subject.complete();
          }
          else {
            subject.next(false);
            subject.complete();
          }
        },
        error: err => {
          subject.error(err);
          subject.complete();
        }
      });    
    }
    else {
      subject.next(false);
      subject.complete();
    }
  }

  /**
   * 選択中の日付を設定する。
   *
   * @memberof OrderListComponent
   */
  set selectedDate(date: string) {

    if (moment(date).isValid()) {

      // 選択前の日付
      const old_date = this._selectedDate;
      // 選択後の日付
      const formatted_date = moment(date).format(moment.HTML5_FMT.DATE);
      // 選択日を変更
      this._selectedDate = formatted_date;

      // 異なる日付を選択した場合
      if (old_date != formatted_date) {
        this.calendar.getApi().removeAllEvents();
        this.initial();

        // カレンダーの表示範囲を切り替え
        this.calendar.getApi().gotoDate(formatted_date);
        this.calendar.getApi().el.querySelector<HTMLButtonElement>('.fc-today-button').disabled = false;  // 今日ボタンの非活性を無効化

        // 情報を取得しカレンダーを更新
        this.communicationsCommand(true);

        // 今日ボタンの非活性処理
        this.setTodayButtonDisabled();
      }
    }
    else {
      console.error(`解釈不能な日付: ${date}`);
    }
  }

  /**
   * 空き情報を取得するスケジュール期間を取得する。
   *
   * @private
   * @return {*}  {{ start: Date, end: Date }}
   * @memberof ExpDetailComponent
   */
  private getScheduleRange(targetDate?: string): { start: Date | moment.Moment, end: Date | moment.Moment } {

    let start: Date | moment.Moment;
    let end: Date | moment.Moment;

    if (!targetDate) targetDate = moment().format(moment.HTML5_FMT.DATE);

    start = moment(targetDate).startOf('day');
    end = moment(targetDate).add(6, 'days').endOf('day');
    console.log("指定した日から一週間を設定：" + moment(start).format("YYYY-MM-DD HH:mm") +" ~ "+ moment(end).format("YYYY-MM-DD HH:mm"));
  
    return { start, end };
  }

  /**
   * 料金の計算を行う。
   *
   * @private
   * @return {*}  {void}
   * @memberof ExpDetailComponent
   */
  private calcuPrice(): void {

    this.expBill.numbers.forEach((n, index: number) => {
      let number: number = this.expBill.numbers[index].value;
      // todo
      if (number === 0) number = 1;

      this.expBill.price[index].value = this.expServ.getPrice({
                                          price_rules: this.targetServicePrice.price_rules,
                                          params: { number: 1, util_time: this.expBill.util_time },
                                          type: n.type
                                        });
    });

    // console.log("expBill.price: ", this.expBill.price);
  }

  /**
   * 詳細画面に戻った際の処理を行う。
   *
   * @private
   * @param {boolean} [update]
   * @memberof ExpDetailComponent
   */
  private backToDetail(updateSchedule: boolean, updateService: boolean = false): void {

    // このページを表示する時にカレンダーサイズを整える
    // note: このページが表示していないときにwindow.resizeが起こると、カレンダーサイズが不正となるため
    this.calendar.getApi()?.updateSize();

    // サービス情報更新
    if (updateService) return this.updateService();

    // 空き時間の更新を行うかどうか
    if (updateSchedule) {

      // イベント、ボタン初期化
      this.calendar.getApi().removeAllEvents();
      this.initial();

      setTimeout(() => {
        // 営業時間、空き情報を取得
        this.setInitAvailable();
      }, 0);
    }
    // 空き時間の更新を行わない場合でも、カレンダーの初期表示時間を指定
    // else this.calendar.getApi(updateService()).scrollToTime(this.startTime);
  }

  /**
   * サービス情報を更新
   *
   * @private
   * @memberof ExpDetailComponent
   */
  private updateService(): void {

    this.busy = this.expServ.getService({sg_service_id: this.service.sg_service_id}).subscribe({
      next: res => {
        const service: ExpService = res.body;
        const isLink: boolean = this.params.data.isLink ? true : false;

        this.navigator.element.replacePage(ExpDetailComponent, {
          animation: 'fade-ios',
          data: {
            service: service,
            updated: true,
            isLink: isLink,
          }
        });
      }
    })
  }

  /**
   * 空き時間
   * カレンダーイベント登録
   *
   * @private
   * @memberof ExpDetailComponent
   */
  private addCustomEventSource(): void {

    // イベントソース
    let events: EventInput[] = [];

    /**
     * イベントソース作成
     *
     * @param {string} status
     * @param {Date} start
     * @param {Date} end
     */
    const createEvents = (status: string, start: Date, end: Date) => {

      const bgColor = status === this.available.OK ? "white" : "lightgray";
      events.push({ title: status, start: start, end: end, backgroundColor: bgColor, className: moment(start).toISOString()});
    };

    this.targetAvailable.forEach(r => {

      const start = new Date(r.from);
      let end = new Date(r.from);
      end.setMinutes(end.getMinutes() + 30);

      // 選択している予約総数を取得
      const total_reservnum = this.expBill.numbers.map(n => n.value).reduce((a, b) => a + b);

      // const test = new Date(r.from);
      // if (test.getDate() === 9) console.log("date = 9 & r.available_number: ", r.available_number, "date: ", test);

      // 予約締切を過ぎている場合はNG
      const deadline_prop = this.service.reserve_deadline;
      if (deadline_prop.acpt_onday===true) {
        /** 締切時刻(これより後ろの時刻開始のサービスしか予約できない) */
        const deadline = moment().add(deadline_prop.acpt_time, 'm');
        if (deadline.isSameOrAfter(start)) {
          createEvents(this.available.NG, start, end);
          return;
        };
      }
      else if (deadline_prop.acpt_onday===false) {
        const deadline_time = deadline_prop.to.time.match(/(\d+):(\d+)/);
        /** 本日期限 */
        const today_deadline = moment().hour(Number(deadline_time[1])).minute(Number(deadline_time[2])).startOf('minute');
        /** 締切時刻(これより後ろの時刻開始のサービスしか予約できない) */
        let deadline: moment.Moment;
        if (moment().isBefore(today_deadline)) {
          deadline = moment().add(deadline_prop.to.day, 'd').startOf('D');  // N日後からの予約が出来る  (N=deadline_prop.to.day)
        }
        else {
          deadline = moment().add(deadline_prop.to.day+1, 'd').startOf('D');  // N日後の予約期限も過ぎているため、N+1日後からの予約が出来る
        }
        if (deadline.isSameOrAfter(start)) {
          createEvents(this.available.NG, start, end);
          return;
        }
      }

      // 予約可能な空きがある場合
      if (r.available_number > 0) {

        // 選択している利用総数が空き数を超えていない
        if (r.available_number >= total_reservnum) {

        // スケジュールにイベント「〇」を追加、ない場合は「×」を追加
          if (start.getTime() > new Date().getTime() && this.isMaxSchedule(start)) createEvents(this.available.OK, start, end);
          else createEvents(this.available.NG, start, end);
        }
        else createEvents(this.available.NG, start, end);
      }
      else createEvents(this.available.NG, start, end);
    });

    // イベント登録
    this.calendar.getApi().addEventSource(events);

    if (this.isSettedMobilityStartDate === true && this.expBill.start_time === "") {
      const date = moment(this.expServ.mobilityStartDate);

      // 利用開始日時スケジュール、共通利用開始日時を選択
      const targetElement = this.calendar.getApi().el.getElementsByClassName(date.toISOString())[0] as HTMLElement;
      targetElement.click();

      setTimeout(() => this.calendar.getApi()?.scrollToTime(date.format('H:mm')));

      this.isSettedMobilityStartDate = false;
    }
  }

  /**
   * カレンダーの表示範囲内か判定する。
   *
   * @private
   * @param {Date} start
   * @return {*}  {boolean}
   * @memberof ExpDetailComponent
   */
  private isMaxSchedule(start: Date): boolean {

    let result: boolean = false;

    // 最大予約可能日付
    let startMax = new Date(this.datePickerRange.start);
    const startMaxSchedule = new Date(startMax.getFullYear(), startMax.getMonth(), startMax.getDate(), 0, 0, 0);

    let endMax = new Date(this.datePickerRange.end);
    endMax.setDate(this.datePickerRange.end.getDate() + 1);
    const endMaxSchedule = new Date(endMax.getFullYear(), endMax.getMonth(), endMax.getDate(), 0, 0, 0);

    // 最大予約可能日付の範囲内か
    return (startMaxSchedule.getTime() <= start.getTime() && start.getTime() < endMaxSchedule.getTime()) ? true : false;
  }

  /**
   * 乗り捨て可能なサービスならば、
   * 貸出、返却場所のチェックを行い、異なる場合エラーメッセージを表示
   *
   * @private
   * @memberof ExpDetailComponent
   */
  private checkIsDropOff(): void {

    if (this.service.service_type === 'MOBILITY') {
      const index = this.template.body.findIndex(b => b.id === "return");

      if (this.service.is_drop_off === false &&
        (this.expBill.return_place.location.lat !== this.expBill.rental_place.location.lat ||
        this.expBill.return_place.location.lng !== this.expBill.rental_place.location.lng)
      ) {
        this.expBill.err_drop_off = true;
        this.template.body[index].valid_msg = "貸出・返却を同じ場所に指定してください";
      }
      else {
        this.expBill.err_drop_off = false;
        this.template.body[index].valid_msg = "";
      }
    }
  }

  /**
   * 予約確定画面への遷移可否を判定し、遷移ボタンをの表示を切り替える。
   *
   * @private
   * @return {*}
   * @memberof ExpDetailComponent
   */
  private switchOverTransitionBtn(): void {

    const switchBtn = (isDisplay: boolean): void => {
      if (isDisplay === true) this.btn_trans.nativeElement.style.display = "";
      else this.btn_trans.nativeElement.style.display = "none";
    }

    // カレンダー上の利用開始日時が未選択
    if (this.expBill.start_time == "" || this.expBill.end_time == "") return switchBtn(false);

    // すべての利用数が０以下
    if (this.expBill.numbers.filter(n => n.value === 0).length === this.expBill.numbers.length) return switchBtn(false);

    // いずれかの利用数が未選択
    if (this.expBill.numbers.filter(n => n.value < 0).length !== 0) return switchBtn(false);

    // モビリティ
    if (this.service.service_type === 'MOBILITY') {
      // 乗り捨て不可 & 貸出、返却場所で異なる場所を選択している
      if (this.expBill.err_drop_off === true) return switchBtn(false);
    }
    // アクティビティ
    else if (this.service.service_type === "ACTIVITY") {
      // 最少人数以下
      if (true === this.expBill.err_min_number) return switchBtn(false);
    }
    else ;

    const optionResult: boolean = Object.keys(this.expBill.options).some(id => {
      return this.expBill.options[id].time_err.check === true || this.expBill.options[id].err.check === true;
    })
    if (optionResult === true) return switchBtn(false);

    // 予約確定画面への遷移ボタン表示
    switchBtn(true);
  }

  /**
   * 指定した日時が営業時刻に含まれるかどうか
   *
   * @private
   * @param {(string | moment.Moment)} targetDate
   * @param {ShopCalendar[]} [businessHour] チェック対象の営業時間(指定しない場合、最後に取得した営業時間を使用)
   * @return {*}  {boolean}
   * @memberof ExpDetailType1Component
   */
  private checkIncludeBusinessHour(targetDate: string | moment.Moment, businessHours: ShopCalendar[]): boolean {

    const target: string = moment.isMoment(targetDate) ? moment(targetDate).format(moment.HTML5_FMT.DATETIME_LOCAL) : targetDate;

    // 指定した日の営業時間を取得
    const targetBusinessHour: ShopCalendar = businessHours.find(hour => moment(hour.date).isSame(target, 'day'));

    if (targetBusinessHour === void 0) return false;

    // 営業時間に含まれるかどうか
    return targetBusinessHour.opening_hours.some(opening => {
      const from = moment(opening.shipping.from, 'HH:mm');
      const to = moment(opening.shipping.to, 'HH:mm');

      return moment(targetBusinessHour.date).set({'hour': from.hour(), 'minute': from.minute()}).isSameOrBefore(target, 'hour')
      && moment(targetBusinessHour.date).set({'hour': to.hour(), 'minute': to.minute()}).isSameOrAfter(target, 'hour');
    });
  }

  /**
   * 全ての日数選択型オプションをチェック
   *
   * @private
   * @memberof ExpDetailComponent
   */
  private checkAllTimeOption(): void {
    this.optionGroups.forEach(group => {
      group.options.forEach(option => {
        if (option.time_rule !== 'none') this.checkTimeOption(option);
      });
    });
    this.switchOverTransitionBtn();
  }

  /**
   * 日数選択型オプション、上限利用日数を超えて選択していないかチェック
   *  上限利用日数：サービス利用日数と、オプション諸元の上限利用日数の小さい方
   *
   * @private
   * @param {ExpService["option_groups"][number]["options"][number]} option
   * @return {*}  {void}
   * @memberof ExpDetailComponent
   */
  private checkTimeOption(option: ExpService["option_groups"][number]["options"][number]): void {

    this.expBill.options[option.sg_option_id].time_err = {
      message: "",
      check: false
    }

    const targetOption: UserSelectOption = this.expBill.options[option.sg_option_id];

    // 時間指定なし(none)の場合、処理を抜ける
    if (option.time_rule === 'none') return;
    // 日数選択型オプション、未選択の場合、処理を抜ける
    if (!targetOption.time_param.selected) return;
    // 所要時間, 開始時間が未選択の場合、処理を抜ける
    if (!this.expBill.util_time || !this.expBill.start_time || !this.expBill.end_time) return;

    // 日数選択型オプションが表示されていない場合、処理を抜ける
    switch (option.user_option) {
      case 'yesno':
        if (targetOption.yesno_param.value === false) return;
        break;
      case 'select':
        if (targetOption.select_param.value === void 0) return;
        break;
      case 'number':
      case 'comment':
        break;
    }

    /**
     * 日数選択型オプション、選択中時間
     */
    const selected: number = targetOption.time_param.selected;

    if (option.time_rule === 'days') {
      // NOTE: 利用日数は、含まれている日数(例：3日22時～4日2時⇒2日)
      // 開始終了日時、所要時間から利用日数取得
      const start = moment(this.expBill.start_time).startOf('day');
      const end = moment(this.expBill.end_time).subtract(1, 'seconds').add(1,'day').startOf('day');
      let dateLimit: number = Math.min(end.diff(start, 'days'), Math.min(Math.floor(this.settingExp.service.max_reserve_hour/24)+1));

      if (option.time_param.to) {
        dateLimit = Math.min(dateLimit, option.time_param.to)
      }

      // テーブル型の場合、料金テーブル(value)最大値と比較し、小さい方を上限
      const targetPriceRule: SgServiceOption["prices"][number] = this.expServ.getTargetOptionPrice(option.prices, moment(this.expBill.start_time));
      if (targetPriceRule.func === 'table') {
        if (option.user_option === 'select' && targetOption.select_param.value === void 0) return;

        if (option.user_option === 'select' && targetOption.select_param.value !== void 0) {
          dateLimit = Math.min(dateLimit, targetPriceRule.select.find(item => item.value === targetOption.select_param.value).rule.table.slice(-1)[0].value);
        }
        else dateLimit = Math.min(dateLimit, targetPriceRule.rule.table.slice(-1)[0].value);
      }

      if (selected > dateLimit) {
          this.expBill.options[option.sg_option_id].time_err = {
            message: "選択可能な最大日数を超えています。選択しなおしてください。",
            check: true
          }
      }
    }
  }

  /**
   * カレンダーの週変更時の設定処理
   *
   * @private
   * @param {string} [weekType] next：翌週 prev：前週
   * @return {*}  {void}
   * @memberof ExpDetailType1Component
   */
    private setCalendarWeekChange(weekType: string){
    
      switch (weekType) {
        case this.calendarWeekType.next:
          this.calendar.getApi().next();
          break;
        case this.calendarWeekType.prev:
          this.calendar.getApi().prev();
          break;
        default:
          break;
      }
  
      const startDayWeek = this.calendar.getApi().view.activeStart;
      const date = moment(startDayWeek).format(moment.HTML5_FMT.DATE);
      this.selectedDate = date;
    }

  /**
   * 今日ボタン非活性判定処理
   * カレンダー表示の先頭（左列）が現在日である場合、今日ボタンを非活性に設定する
   *
   * @private
   * @return {*}  {void}
   * @memberof ExpDetailType1Component
   */ 
  private setTodayButtonDisabled() {
    const today = moment().format(moment.HTML5_FMT.DATE);
    if (moment(this.calendar.getApi().view.activeStart).format(moment.HTML5_FMT.DATE) == today) {
      this.calendar.getApi().el.querySelector<HTMLButtonElement>('.fc-today-button').disabled = true;
    }
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * サーバ通信を行う司令部。
   *
   * @private
   * @param {boolean} [initial] true：営業時間を取得
   * @memberof ExpDetailComponent
   */
  private communicationsCommand(initial?: boolean): void {

    let proc: Observable<any>[] = [];
    proc.push(this.getAvailable());
    if (initial === true) proc.push(this.getShopCalendar(this.selectedDate));

    this.busy = forkJoin(proc).subscribe({
      next: result => {

        // 営業時間
        if (result[1]) {
          // カレンダーの表示日数
          const maxday: { days: number } = this.calendar.getApi().view.getOption("duration") as { days: number };

          let input: EventInput[] = [];
          for (let i = 0; i < maxday.days; i++) {
            input.push({ daysOfWeek: [i], startTime: "08:00", endTime: "19:00" });
          }
          let minStartHours: string = input[0].startTime;
          let maxEndHours: string = input[0].endTime;

          (<ShopCalendar[]>result[1].body).forEach((shop, index: number) => {

            if (shop?.opening_hours?.length > 0) {

              let start: string = "";
              let end: string = "";

              shop.opening_hours.forEach((opening_hour, index: number) => {
                if (index === 0) {
                  start = opening_hour.shipping.from;
                  end = opening_hour.shipping.to;
                }
                // 同じ日に複数営業時間が設定されている場合、
                // start：開始時刻の中で一番早い時刻、end：終了時刻の中で一番遅い時刻を設定
                else {
                  start = start < opening_hour.shipping.from ? start : opening_hour.shipping.from;
                  end = end > opening_hour.shipping.to ? end : opening_hour.shipping.to;
                }
              });

              // 営業時間が一つでもあるならその値を最小、最大に設定
              if (index === 0) {
                minStartHours = start;
                maxEndHours = end;
              }

              //todo shipping > shopping
              const d = Number(moment(shop.date).format("d"));
              input[d] = {
                daysOfWeek: [d],
                startTime: start,
                endTime: end,
              };

              // カレンダーに表示する開始/終了時刻
              if (minStartHours === "") minStartHours = start;
              else {
                if (start < minStartHours) minStartHours = start;
              }
              if (maxEndHours === "") maxEndHours = end;
              else {
                if (end > maxEndHours) maxEndHours = end;
              }
            }
          });

          // 営業時間、カレンダー表示時間を設定
          this.calendar.options.businessHours = input;
          this.calendar.options.slotMinTime = minStartHours;
          this.calendar.options.slotMaxTime = maxEndHours;
        }

        // 空き情報
        const body = result[0].body as ExpResourceAvailable;

        this.targetAvailable = [];

        // 請求先(未ログイン時は空)
        if (body.payer) this.expBill.payer_name = body.payer.name;

        // サービス種別毎に空き数を取得
        if (this.service.service_type === "MOBILITY") {
          this.targetAvailable = body.available;
        }
        else if (this.service.service_type === "ACTIVITY") {
          const resources = body.resources;

          // リソースの内、空き数が最も多いものを選択
          if (resources.length > 1) {
            // 初期値として最初の空き数を設定
            this.targetAvailable = resources[0].availables;

            // const start = performance.now();
            // console.log("計測開始(for)");
            resources.forEach((r, index: number) => {
              if (index === 0) return;

              r.availables.forEach((a, i: number) => {
                if (resources[index - 1].availables[i].available_number < a.available_number) {
                  // console.log("より大きい空きリソースに差し替え：", this.targetAvailable[i], a);
                  this.targetAvailable[i] = this.commonFunc.deepcopy(a);
                }
              })
            });
          }
          else if (resources.length === 1) this.targetAvailable = resources[0].availables;
        }
        // 上記以外のサービス種別以外は未対応
        else ;

        // カレンダーイベント登録
        this.addCustomEventSource();

        // カレンダーの初期表示時間を指定
        // if (initial) this.calendar.getApi().scrollToTime(this.startTime);

        // console.log(this.targetAvailable.filter(f => f.available_number > 0));
      },
      error: this.errResServ.doParse((_err, errContent) => this.errResServ.viewErrDialog(errContent))
    });
  }

  /**
   * 店舗の営業時間を取得する。
   *
   * @private
   * @return {*}  {Observable<HttpResponse<ShopCalendar[]>>}
   * @memberof ExpDetailComponent
   */
  private getShopCalendar(targetDate?: string): Observable<HttpResponse<ShopCalendar[]>> {

    // スケジュール期間を取得
    const range = this.getScheduleRange(targetDate);

    // サーバ通信
    return this.expServ.getShopCalendar(this.service.shop.shop_id, range.start.toISOString(), range.end.toISOString());
  }

  /**
   * サービスの空き情報を取得する。
   *
   * @private
   * @return {*}  {Observable<HttpResponse<ExpResourceAvailable>>}
   * @memberof ExpDetailComponent
   */
  private getAvailable(): Observable<HttpResponse<ExpResourceAvailable>> {

    // スケジュール期間を取得
    const range = this.getScheduleRange(this.selectedDate);

    const param: parameter.ExpAvailable = {
      sg_service_id : this.service.sg_service_id,
      date_from: range.start.toISOString(),
      date_to: range.end.toISOString(),
      util_time: this.expBill.util_time
    };

    let method: Observable<HttpResponse<ExpResourceAvailable>> = undefined;
    if (this.service.service_type === "MOBILITY") method = this.expServ.getAvailable(param);
    else if (this.service.service_type === "ACTIVITY") method = this.expServ.getResourceAvailable(param);
    else ;

    return method;
  }
}


export interface ExpBill {

  /**
   * 貸出場所
   *
   * @type {ExpOptionPlace}
   * @memberof ExpReservationConfirm
   */
  rental_place?: ExpPlace;

  /**
   * 返却場所
   *
   * @type {ExpOptionPlace}
   * @memberof ExpReservationConfirm
   */
  return_place?: ExpPlace;

  /**
   * 開催場所
   *
   * @type {ExpOptionPlace}
   * @memberof ExpReservationConfirm
   */
  place?: ExpPlace;

  /**
   * 乗り捨て不可エラー
   *
   * @type {boolean}
   * @memberof ExpBill
   */
  err_drop_off: boolean;

  /**
   * 最少人数エラー
   *
   * @type {boolean}
   * @memberof ExpBill
   */
  err_min_number: boolean;

  /**
   * 料金
   *
   * @type {number}
   * @memberof ExpBill
   */
  price: {
    name: string;
    value: number;
  }[];

  /**
   * 利用数
   *
   * @type {number}
   * @memberof ExpReservationConfirm
   */
  // number?: number;

  /**
   * 利用数単位
   *
   * @type {string}
   * @memberof ExpReservationConfirm
   */
  unit?: string;

  /**
   * 利用数
   *
   * @memberof ExpBill
   */
  numbers? : {

    /**
     * 料金タイプ
     *
     * @type {number}
     */
    type: number;

    /**
     * 利用数
     *
     * @type {number}
     */
    value: number;
  }[];

  /**
   * 利用時間(分)
   *
   * @type {number}
   * @memberof ExpReservationConfirm
   */
  util_time?: number;

  /**
   * 利用開始日時
   *
   * @type {string}
   * @memberof ExpReservationConfirm
   */
  start_time?: string;

  /**
   * 利用終了日時
   *
   * @type {string}
   * @memberof ExpReservationConfirm
   */
  end_time?: string;

  /**
   * 利用可能時間帯
   *
   * @memberof ExpBill
   */
  time_range?: {

    /**
     * 利用開始時間
     *
     * @type {string}
     */
    from: string;

    /**
     * 利用終了時間
     *
     * @type {string}
     */
    to: string;
  }

  /**
   * 請求先
   *
   * @type {string}
   * @memberof ExpReservationConfirm
   */
  payer_name: string;

  options?: {[option_id: string]: UserSelectOption};
}

/**
 *
 *
 * @interface UserSelectOption
 */
// interface UserSelectOption {
//   sg_option_price_id?: number;
//   comment?: string;
//   err?: {
//     check: boolean;
//     message: string;
//   };
//   time_err?: {
//     check: boolean;
//     message: string;
//   };
//   yesno_param?: SgServiceOption["yesno_param"]["items"][number];
//   select_param?: SgServiceOption["select_param"]["items"][number];
//   number_param?: SgServiceOption["number_param"];
//   time_param?: SgServiceOption["time_param"];
// }

export interface View {
  name: string
  price: string;
  number: {
    value: number;
    label: string;
  }[];
}[];
