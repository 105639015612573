<ons-toolbar class="toolbar">
  <div class="left">
    <ons-back-button></ons-back-button>
  </div>
  <div class="center">支払い方法</div>
</ons-toolbar>

<div class="content">
  <div [ngBusy]="busy"></div>

  <ng-container *ngIf="postPayment.method === 'SLIP'">
    <p class="content--title">{{ VIEW_TEXT.METHOD.SLIP }}で料金を払う</p>
    <p class="content--text">
      毎月の請求金額の確定後、SmartGOTOよりお客様のご自宅に振替払込書が郵送されます。<br>
      振替払込書を持って対応店舗に支払いに行くだけで、月ごとのお支払いが完了します。
    </p>
  </ng-container>
  <ng-container *ngIf="postPayment.method === 'YUCHO'">
    <p class="content--title">{{ VIEW_TEXT.METHOD.YUCHO }}で料金を払う</p>
    <p class="content--text noPadding--bottom">
    <!--【UI改善対応】口座引き落とし時の強調表示-->
    <!--  毎月の請求金額の確定後、指定いただいたゆうちょ口座より自動で金額が引き落としされます。<br>-->
    <!--  引き落としの申込用紙は、以下の店舗にて受け取り可能です。-->
      毎月の請求金額確定後、指定口座からご利用料金を引き落とします。<br><br>
      <span class="bold">口座引き落としを開始するには、ご本人様による銀行窓口での申請手続きが必要です。<br><br></span>
      引き落としの申し込み用紙は、以下の店舗で受け取り可能です。<br><br>
      口座引き落としは事務手続きに時間をいただきます。事務手続きが完了次第、サービスのご利用ができます。あらかじめご了承ください。
    </p>
  </ng-container>
  <ng-container *ngIf="postPayment.method === 'BANK77'">
    <p class="content--title">銀行口座自動引き落としで料金を支払う</p>
    <p class="content--text noPadding--bottom">
      毎月の請求金額の確定後、指定頂いた銀行口座より自動で金額が引き落とされます。<br><br>
      <span class="bold">七十七銀行もしくはその他の銀行の口座引落を開始するには、以下店舗での申請手続きが必要です。<br><br></span>
      口座引き落としは事務手続きに時間をいただきます。事務手続きが完了次第、サービスのご利用ができます。あらかじめご了承ください。
    </p>
  </ng-container>
  <ng-container *ngIf="postPayment.method === 'JABANK'">
    <p class="content--title">{{ VIEW_TEXT.METHOD.JABANK }}で料金を払う</p>
    <p class="content--text noPadding--bottom">
      <!--【UI改善対応】口座引き落とし時の強調表示-->
      <!--毎月の請求金額の確定後、指定いただいたJAバンク口座より自動で金額が引き落としされます。<br>-->
      <!--引き落としの申込用紙は、以下の店舗にて受け取り可能です。-->
      毎月の請求金額確定後、指定口座からご利用料金を引き落とします。<br><br>
      <span class="bold">口座引き落としを開始するには、ご本人様による銀行窓口での申請手続きが必要です。<br><br></span>
      引き落としの申し込み用紙は、以下の店舗で受け取り可能です。<br><br>
      口座引き落としは事務手続きに時間をいただきます。事務手続きが完了次第、サービスのご利用ができます。あらかじめご了承ください。
    </p>
  </ng-container>

  <p class="content--text">
    対応店舗：<br>
    <ng-container *ngIf="postPayment.method === 'YUCHO' || postPayment.method === 'SLIP'">郵便局、ゆうちょ銀行、</ng-container>
    <ng-container *ngIf="postPayment.method === 'JABANK'">JAバンク各店舗、</ng-container>

    <a [href]="'https://www.google.com/maps/search/?api=1&query=' + office.position.lat + ',' + office.position.lng + '&z=20'" target="_blank">{{ office.name }}</a>
  </p>

  <div class="checkbox-wrap noPadding--bottom" *ngIf="isViewDefaultPayment === true">
    <ons-checkbox id="payment-method-post_001" input-id="default-payment-check" [(ngModel)]="defaultFlag" 
                  [attr.disabled]="paymentRegistration.count === 0 ? true : null" 
                  (ngModelChange)="postPayment.view === 'detail' ? changeDefaultPayment() : null">
    </ons-checkbox>
    <label for="default-payment-check">デフォルト支払い方法に設定する</label>
  </div>

  <div class="button-wrap">
    <ng-container [ngSwitch]="postPayment.view">
      <ons-button id="payment-method-post_002" *ngSwitchCase="'detail'" class="button button--large--cta" (click)="delete()">お支払情報を削除する</ons-button>
      <ons-button id="payment-method-post_003" *ngSwitchCase="'add'" class="button button--large--cta" (click)="add()">支払い方法に登録</ons-button>
    </ng-container>
  </div>
</div>