<ons-toolbar>
  <div class="left">
    <ons-back-button></ons-back-button>
  </div>
  <div class="center">ファミリー追加</div>
</ons-toolbar>
<div class="content">
  <ons-list>
    <ons-list-header modifier="color" class="list-item__title">既存アカウントをファミリーに入れる場合</ons-list-header>
    <ons-list-item modifier="longdivider">
      <div class="button-wrap">
        <ons-button id="family-add_001" class="button button--large--cta" (click)="inviteFamily()">ファミリーに招待</ons-button>
      </div>
    </ons-list-item>
    <ons-list-header modifier="color" class="list-item__title">アカウント作成できないご家族をファミリーに入れる場合</ons-list-header>
    <ons-list-item modifier="longdivider">
      <div class="button-wrap">
        <ons-button id="family-add_002" class="button button--large--cta" (click)="createProxy()">アカウント作成</ons-button>
      </div>
      <div class="content--list--description">
        作成したアカウントは見守り対象アカウントとなります。<br>
        見守り対象アカウントに関する詳しい情報は<ons-button id="family-add_003" modifier="quiet" (click)="viewProxyDescription()"><span class="button-text">こちら</span></ons-button>をご覧ください。
      </div>
    </ons-list-item>
  </ons-list>
</div>