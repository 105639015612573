import { InjectionToken } from '@angular/core';

export /** 現在位置アイコン @type {*} */
const OPERATION_MAP_CURRENT_POSITION_MARKER_ICON= new InjectionToken<google.maps.LatLngLiteral>('operationMapCurrentPositionMarkerIcon');

export /** 地図の表示オプション @type {*} */
const OPERATION_MAP_OPTIONS= new InjectionToken<google.maps.MapOptions>('operationMapOptions');

export /** 車両マーカアイコン @type {*} */
const OPERATION_MAP_VEHICLE_POSITION_MARKER_ICON=new InjectionToken<google.maps.Icon>('operationMapVehiclePositionMarkerIcon');
