//=============================================================================================
// インポート
//=============================================================================================
import { Injectable } from '@angular/core';
import * as CONST from '../constants/constant';
import { CommonFunctionModule } from '../lib-modules/common-function.module';
import { MunicipalityWebApiService } from '../http-services/municipality-web-api.service';

// interface
import { common } from '../interfaces/common';
import { ShoppingMenu, Shop, UserInfo, Settings } from '../interfaces/response';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 注文管理サービス。
 *
 * @export
 * @class OrderRequestService
 */
@Injectable({
  providedIn: 'root'
})
export class OrderRequestService {

//=============================================================================================
// クラス変数
//=============================================================================================

  /**
   * カート内の商品一覧
   *
   * @private
   * @type {CartAddedItems[]}
   * @memberof OrderRequestService
   */
  private cartItems: CartAddedItems[] = [];

  /**
   * 注文先の店舗
   *
   * @private
   * @type {shop}
   * @memberof OrderRequestService
   */
  private orderShop: Shop = null;

  /**
   * ユーザが選択した配達プラン
   *    クライアントが保持する形式
   *
   * @type {common.DeliveryPlan}
   * @memberof OrderRequestService
   */
  deliveryPlan: common.DeliveryPlan;

  /**
   * お届け先
   *
   * @type {common.Place}
   * @memberof OrderRequestService
   */
  deliveryPlace: common.Place = { name: "未設定", favorite_id: undefined, address: undefined, location: undefined };

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of OrderRequestService.
   * @memberof OrderRequestService
   */
  constructor(
    private commonFunction: CommonFunctionModule,
    private municipalityWebApiServ: MunicipalityWebApiService,
  ) {
    // 初期化
    // this.refresh();
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * カートに商品を追加する。
   *
   * @param {CartAddedItems} item
   * @return {*} 
   * @memberof OrderRequestService
   */
  addCart(shop: Shop, item: CartAddedItems): orderRequestResult {

    // 店舗情報設定
    if (orderRequestResult.SUCCESS !== this.setOrderShop(shop)) {

      // 店舗が異なる商品
      return orderRequestResult.ERR_DIFFERENT_SHOP;
    }

    // カート内商品をコピー
    let cart: CartAddedItems[] = JSON.parse(JSON.stringify(this.cartItems));
    let index: number = -1;

    // カート内に追加しようとしている商品がすでにあるか
    const result = cart.find((c, i: number) => {
      if (c.menu.menu_id === item.menu.menu_id) {
        // ある場合は個数を増加
        c.count += item.count;
        index = i;

        return c;
      }
    })

    // カート内にない商品の場合は追加
    if (undefined === result) {
      cart.push(item);
      index = cart.length - 1;
    }

    // チェック
    if (this.checkMultipleShop(cart[index]) == false) return orderRequestResult.ERR_DIFFERENT_SHOP;
    // if (this.checkMaxOrderItemCount(cart) == false) return orderRequestResult.ERR_MAX_ORDER_ITEM_COUNT;

    // 保存
    this.cartItems = JSON.parse(JSON.stringify(cart));
    
    // orderRequestResult.SUCCESSを返却
    return orderRequestResult.SUCCESS;
  }

  /**
   * カート内商品の数量を変更する。
   *
   * @param {string} menu_id
   * @param {number} count
   * @memberof OrderRequestService
   */
  changeMenuCount(menu_id: string, count: number): orderRequestResult {
    
    // 指定された menu_id のメニューがカート内に存在するかどうかを判定する。
    const isMenuIdValid = this.checkExistMenuId(menu_id);
    if (isMenuIdValid == false) {
      return orderRequestResult.ERR_NOT_EXISTS_MENU_ID;
    }

    // 数量変更後のメニューリストをチェックするため作成
    let changedSelectMenuList: CartAddedItems[] = this.cartItems.slice(0, this.cartItems.length);

    // カート内商品をコピー
    let cart: CartAddedItems[] = JSON.parse(JSON.stringify(this.cartItems));
    let index: number = -1;

    // 指定したカート内メニューの商品個数を変更
    changedSelectMenuList.forEach((element, i: number) => {
      if (element.menu.menu_id === menu_id) {
        element.count = count;
        cart[i] = element;
        index = i;
      }
    });

    // チェック
    // if (this.checkMaxItemCount(cart[index]) == false) return orderRequestResult.ERR_ITEM_MAX_COUNT;

    // 保存
    this.cartItems = JSON.parse(JSON.stringify(cart));
    
    // orderRequestResult.SUCCESSを返却
    return orderRequestResult.SUCCESS;
  }

//=============================================================================================
// getter/setter
//=============================================================================================

  /**
   * 注文先店舗を登録する。
   *
   * @param {shop} shop
   * @return {*} 
   * @memberof OrderRequestService
   */
  private setOrderShop(shop: Shop): orderRequestResult.SUCCESS | orderRequestResult.ERR_DIFFERENT_SHOP {

    // 注文先店舗が設定済みなら
    // if (this.orderShop !== null) {

    //   // 注文先店舗と異なる店舗
    //   if (this.orderShop.shop_id !== shop.shop_id) return orderRequestResult.ERR_DIFFERENT_SHOP;
    //   else return orderRequestResult.SUCCESS;
    // }
    
    // カートが空ではない場合
    if (this.cartItems.length > 0) {

      // カート内商品の店舗と異なる店舗を登録しようとした
      if (shop.shop_id != this.cartItems[0].menu.shop_id) {
        return orderRequestResult.ERR_DIFFERENT_SHOP;
      }
    }

    // 注文先店舗を登録
    this.orderShop = shop;
    
    return orderRequestResult.SUCCESS;
  }
  
  /**
   * お届け先（登録住所）を設定する。
   *
   * @param {UserInfo["profile"]["address"]} address
   * @memberof OrderRequestService
   */
  setDeliveryRegisteredAddress(address: UserInfo["profile"]["address"]): void {
    
    // 登録住所で初期化
    this.deliveryPlace = {
      name: this.commonFunction.conversionAddress(address, true), 
      address: this.commonFunction.conversionAddress(address), 
      location: address.location
    };
  }

  /**
   * お届け先（登録住所以外）を設定する。
   *
   * @param {common.Place} place
   * @memberof OrderRequestService
   */
  setDeliveryPlace(place: common.Place): void {

    this.deliveryPlace = {
      name: place.name, 
      favorite_id: place.favorite_id, 
      address: place.address, 
      location: place.location
    }
  }

  /**
   * カート内に指定の商品が存在するならその個数を取得する。
   *
   * @param {string} menu_id
   * @return {*}  {number}
   * @memberof OrderRequestService
   */
  getItemCount(menu_id: string): number {

    // カート内に同じ商品が存在するか（商品の重複はない前提）
    const result = this.cartItems.find(item => item.menu.menu_id === menu_id)

    if (result === undefined) return 0;
    else return result.count;
  }
  
  /**
   * カート内の商品一覧を取得する。
   *
   * @readonly
   * @type {CartAddedItems[]}
   * @memberof OrderRequestService
   */
  get getCartItems(): CartAddedItems[] {

    return this.cartItems;
  }

  /**
   * カート内商品の総数を取得する。
   *
   * @readonly
   * @type {number}
   * @memberof OrderRequestService
   */
  get getCartItemCount(): number {

    let count: number = 0;
    this.cartItems.forEach(c => count += c.count);

    return count;
  }

  /**
   * 注文先店舗を取得する。
   *
   * @readonly
   * @memberof OrderRequestService
   */
  get getOrderShop(): Shop {

    return this.orderShop;
  }

  /**
   * カート内商品の合計料金を取得する。
   *
   * @return {*}  {number}
   * @memberof OrderRequestService
   */
  getTotalPrice(): number {
    
    // 合計料金
    let total: number = 0;

    // 合計料金を計算
    this.cartItems.forEach(element => {
      total += (element.menu.price * element.count);
    });

    // 合計料金を返す
    return total;
  }

  /**
   * カートに商品を追加することができるかどうかチェックする。
   *
   * @param {CartAddedItems[]} item
   * @return {*}  {boolean}
   * @memberof OrderRequestService
   */
  checkItemAddedMaxCount(): boolean {
    
    const setting: Settings = this.municipalityWebApiServ.setting;

    if (this.getCartItemCount < setting.shopping.max_order_item_count) {
      return true;
    }

    else if (this.getCartItemCount >=setting.shopping.max_order_item_count) {
      return false;
    }
  }

  /**
   * 指定した商品をカートから削除する。
   *
   * @param {string} menu_id
   * @memberof OrderRequestService
   */
  deleteMenu(menu_id: string): orderRequestResult {

    // 指定された menu_id のメニューがカート内に存在するかどうかを判定する。
    const isMenuIdValid = this.checkExistMenuId(menu_id);
    if (isMenuIdValid == false) {
      return orderRequestResult.ERR_NOT_EXISTS_MENU_ID;
    }

    // 指定したメニューID以外の配列を作成
    let result = this.cartItems.filter(element => {
      return element.menu.menu_id != menu_id;
    });

    this.cartItems = result;

    // 削除後、カート内の商品がなくなった場合リフレッシュ
    if (this.cartItems.length == 0) {
      this.refresh();
    }

    // 正常
    return orderRequestResult.SUCCESS;
  }

  /**
   * カートを初期化する。
   *
   * @param {boolean} [noPlaceReset]
   * @memberof OrderRequestService
   */
  refresh(placeReset: boolean = false): void {

    this.cartItems = [];
    this.orderShop = null;
    // お届け先をリセットするかどうか
    if (placeReset == true) {
      this.deliveryPlace = { name: "未設定", favorite_id: undefined, address: undefined, location: undefined };
    }
  }

//=============================================================================================
// privateメソッド
//=============================================================================================


  /**
   * カート内の商品と異なる店舗の商品を追加しようとしていないかチェックする。
   *
   * @private
   * @param {CartAddedItems} item
   * @return {*}  {boolean}
   * @memberof OrderRequestService
   */
  private checkMultipleShop(item: CartAddedItems): boolean {

    if (this.orderShop.shop_id != item.menu.shop_id) return false;

    return true;
  }
  
  // /**
  //  * 商品毎の最大個数をチェックする。
  //  *
  //  * @private
  //  * @param {CartAddedItems} item
  //  * @return {*}  {boolean}
  //  * @memberof OrderRequestService
  //  */
  // private checkMaxItemCount(item: CartAddedItems): boolean {

  //   if (item.count > CONST.Shopping.MAX_ITEM_COUNT) return false;
    
  //   return true;
  // }
  
  // /**
  //  * カートに追加できる商品の最大個数をチェックする。
  //  *
  //  * @private
  //  * @param {CartAddedItems[]} item
  //  * @memberof OrderRequestService
  //  */
  // private checkMaxOrderItemCount(item: CartAddedItems[]): boolean {

  //   let menuCount: number = 0;
  //   item.forEach(element => {
  //     menuCount += element.count
  //   });

  //   if (menuCount > CONST.Shopping.MAX_ORDER_ITEM_COUNT) return false;

  //   return true;
  // }

  /**
   * 指定された menu_id のメニューがカート内に存在するかどうかを判定する。
   *
   * @private
   * @param {string} menu_id
   * @return {*}  {boolean}
   * @memberof OrderRequestService
   */
  private checkExistMenuId(menu_id: string): boolean {

    let isMenuIdValid = this.cartItems.some(element => {
      return element.menu.menu_id == menu_id;
    })
    if (isMenuIdValid == false) {
      return false;
    }
    return true;
  }
}

//=============================================================================================
// 外部公開定義
//=============================================================================================

/**
 * カート内の商品情報
 *
 * @export
 * @interface SelectMenu
 */
export interface CartAddedItems {
  
  /**
   * 商品
   *
   * @type {MenuInfo}
   * @memberof selectMenu
   */
  menu: ShoppingMenu;

  /**
   * 個数
   *
   * @type {number}
   * @memberof selectMenu
   */
  count: number;
}

/**
 * サービスが返却する処理結果。
 *
 * @export
 * @enum {number}
 */
export enum orderRequestResult {

  /**
   * 店舗情報がない
   */
  ERR_NOT_ORDER_SHOP = "ERR_NOT_ORDER_SHOP", 

  /**
   * 商品の最大数を超えている
   */
  ERR_ITEM_MAX_COUNT = "ERR_ITEM_MAX_COUNT", 
  
  /**
   * 異なる店舗の商品を追加しようとした
   */
  ERR_DIFFERENT_SHOP = "ERR_DIFFERENT_SHOP", 

  /**
   * 指定したメニューIDがカートに含まれていない
   */
  ERR_NOT_EXISTS_MENU_ID = "ERR_NOT_EXISTS_MENU_ID",

  /**
   * 正常
   */
   SUCCESS = "SUCCESS"
}
