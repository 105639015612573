//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { OnsNavigator, Params } from 'ngx-onsenui';
import * as CONST from '../../../constants/constant';

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { FamilyWebApiService } from '../../../http-services/family-web-api.service';
import { ShoppingWebApiService } from "../../../http-services/shopping-web-api.service";
import { OrderRequestService, CartAddedItems } from "../../../lib-services/order-request.service";

// component
import { ShoppingCompleteComponent } from '../shopping-complete/shopping-complete.component';

// interface
import { Shop } from '../../../interfaces/response';
import { common } from 'src/app/interfaces/common';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 注文確認画面。
 *
 * @export
 * @class ShoppingDatermineComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ons-page[shopping-datermine]',
  templateUrl: './shopping-datermine.component.html',
  styleUrls: ['./shopping-datermine.component.css']
})
export class ShoppingDatermineComponent implements OnInit, OnDestroy {

//=============================================================================================
// メンバ変数
//=============================================================================================

  /**
   * 通信用Subscription
   *
   * @type {Subscription}
   * @memberof NewsArticleListComponent
   */
  busy: Subscription;

  /**
   * 注文先店舗
   *
   * @type {Shop}
   * @memberof ShoppingCartComponent
   */
  shop: Shop;

  /**
   * カート内商品一覧
   *
   * @type {CartAddedItems[]}
   * @memberof ShoppingCartComponent
   */
  items: CartAddedItems[] = [];

  /**
   * 選択した配達プラン
   *
   * @type {common.DeliveryPlan}
   * @memberof ShoppingDatermineComponent
   */
  plan: common.DeliveryPlan;

  /**
   * お届け先
   *
   * @type {common.Place}
   * @memberof ShoppingDatermineComponent
   */
  place: common.Place;

  /**
   * サーバに注文確定リクエスト実行中かどうか
   *
   * @type {boolean}
   * @memberof ShoppingDatermineComponent
   */
  isRequesting: boolean = false;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of ShoppingDatermineComponent.
   * @param {ApplicationMessageService} appMsgServ
   * @param {HttpErrorResponseParserService} errResServ
   * @param {FamilyWebApiService} familyServ
   * @param {ShoppingWebApiService} shoppingServ
   * @param {OrderRequestService} orderServ
   * @param {OnsNavigator} navigator
   * @param {Params} params
   * @memberof ShoppingDatermineComponent
   */
  constructor(
    private appMsgServ: ApplicationMessageService, 
    private errResServ: HttpErrorResponseParserService, 
    private familyServ: FamilyWebApiService, 
    private shoppingServ: ShoppingWebApiService, 
    private orderServ: OrderRequestService, 
    private navigator: OnsNavigator, 
    private params: Params,
    private municipalityWebApiServ: MunicipalityWebApiService,
    private msg: MESSAGE,
  ) { }

  /**
   * 初期処理。
   *
   * @memberof ShoppingDatermineComponent
   */
  ngOnInit(): void {

    this.shop = this.orderServ.getOrderShop;
    this.items = this.orderServ.getCartItems;
    this.plan = this.orderServ.deliveryPlan;
    this.place = this.orderServ.deliveryPlace;

    // ファミリー制限チェックのため取得
    this.busy = this.familyServ.getFamily().subscribe();
  }

  /**
   * 破棄処理
   *
   * @memberof ShoppingDatermineComponent
   */
  ngOnDestroy(): void {

    this.busy?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  onCancelPolicy(): void {

    this.appMsgServ.shoppingCancelPolicy();
  }

  /**
   * 注文確定ボタンのイベントハンドラ。
   *
   * @memberof ShoppingDatermineComponent
   */
  onOrderConfirmation(): void {

    // 注文確定
    this.createOrder();
  }

  /**
   * 注文の合計料金（配送料込み）を取得する。
   *
   * @return {*}  {number}
   * @memberof ShoppingCartComponent
   */
  getTotalPrice(): number {
    
    return this.orderServ.getTotalPrice() + this.plan.total.price;
  }

  /**
   * ○月○日 HH:MM形式の日付文字列に変換する。
   *
   * @param {string} date
   * @return {*}  {string}
   * @memberof ShoppingDatermineComponent
   */
  conversionTimezone(date: string): string {

    const d = new Date(date);
    return (d.getMonth() + 1) + "月" + d.getDate() + "日" + " "  + d.getHours() + ":" + ('00' + d.getMinutes()).slice(-2);
  }


  /**
   * 利用規約を表示
   *
   * @memberof ShoppingDatermineComponent
   */
  viewTermsOfService(): void {
    window.open(this.municipalityWebApiServ.setting.term);
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * 注文を確定する。
   *
   * @private
   * @memberof ShoppingComponent
   */
   private createOrder(): void {

    // ファミリー管理者から制限されている場合は注文不可
    if (this.familyServ.isLoginUserUtilization() === true) {
      this.appMsgServ.viewDialogMessage(this.msg.CLIENT.FAMILY.RESTRICTED_UTILIZATION.message());
      return;
    }

    // 2重送信対策
    if (this.isRequesting) {
      // console.log("2重クリックのためリクエストロック中");
      return;
    }
    this.isRequesting = true;

    this.busy = this.shoppingServ.createOrder(this.orderServ.deliveryPlan.plan_id).subscribe({ 
      next: () => {

        this.isRequesting = false;

        this.navigator.element.pushPage(ShoppingCompleteComponent, { data: { class: this.params.data.class }});
      },
      error: this.errResServ.doParse((_err, errContent) => {

        this.isRequesting = false;

        // GMOエラー
        // NOTE: サーバーエラー(オプション含め)から判別することができないため、クライアントメッセージとしている
        if (errContent.smartGotoErrCode === this.appMsgServ.SERV_CONST_CODE.PURCHASE.GMO_TRAN_AUTH) {
          this.errResServ.viewErrDialog(errContent, "RESERVE");
        }
        // 運営債権支払い方法、購入不可エラー
        else if (errContent.smartGotoErrCode === this.appMsgServ.SERV_CONST_CODE.PURCHASE.METHOD_NO_PERMISSION) {
          this.appMsgServ.viewDialogMessage(this.msg.CLIENT.PURCHASE.METHOD_NO_PERMISSION_RESERVE.message());
        }
        else this.errResServ.viewErrDialog(errContent);
      })
    });
  }
}
