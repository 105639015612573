//=============================================================================================
// インポート
//=============================================================================================
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { OnsNavigator, Params } from 'ngx-onsenui';
import * as moment from 'moment';
import * as CONST from '../../../../constants/constant';

// service
import { ApplicationMessageService } from '../../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../../lib-services/http-error-response-parser.service';
import { UserWebApiService } from '../../../../http-services/user-web-api.service';
import { DispatchWebApiService } from '../../../../http-services/dispatch-web-api.service';
import { FamilyWebApiService } from '../../../../http-services/family-web-api.service';

// component
import { LoginComponent } from '../../../user/login/login.component';
import { HistoryListComponent } from '../../history-list/history-list.component';

// interface
import { common } from '../../../../interfaces/common';
import { request } from '../../../../interfaces/request';
import { Reservation, PassengerUsers } from '../../../../interfaces/response';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 配車予約キャンセル画面。
 *
 * @export
 * @class ReservationCancelComponent
 * @implements {OnInit}
 * @implements {AfterViewInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ons-page[reservation-cancel]',
  templateUrl: './reservation-cancel.component.html',
  styleUrls: ['./reservation-cancel.component.css']
})
export class ReservationCancelComponent implements OnInit, AfterViewInit, OnDestroy 
{

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * 予約情報
   *
   * @type {Reservation}
   * @memberof ReservationCancelComponent
   */
  m_Reservation: Reservation;

  /**
   *利用者のキャンセル情報
   *
   * @type {CancelPassenger[]}
   * @memberof ReservationCancelComponent
   */
  m_Passengers: PassengerUsers[];

  /**
   *キャンセルチェックボックスを出すかどうか
   *
   * @type {boolean}
   * @memberof ReservationCancelComponent
   */
  m_bShowCheckbox: boolean;

  /**
   * ページ操作transition
   *
   * @type {LoginComponent.Transition}
   * @memberof ReservationCancelComponent
   */
  m_Transition: LoginComponent.Transition;

  /**
   * 非同期通信の受信オブジェクト
   *
   * @type {Subscription}
   * @memberof ReservationCancelComponent
   */
  m_Busy: Subscription;

  /**
   * HTML用
   *
   * @memberof HistoryDetailComponent
   */
  moment = moment;

  /**
   *キャンセルボタンを押せる状態のチェック
   *
   * @type {boolean}
   * @memberof ReservationCancelComponent
   */
  m_bCancelAbility: boolean = false;

  /**
   * 利用者ごとの料金、利用チケット情報（ディープコピー）
   *    シャドーコピー：this._params.data.customerBill
   *
   * @type {common.customerBill}
   * @memberof ReservationCancelComponent
   */
  customerBill: common.customerBill;

  /**
   * キャンセルポリシーバインド値
   *
   * @memberof ReservationCancelComponent
   */
  cancelDescription = 
  {
    ticket_name: 
    {
      one: CONST.Purchase.TICKET_TYPE_NAME.NONE, 
      season: CONST.Purchase.TICKET_TITLE_CITIZEN
    }, 
    limit: null, 
    fee: null
  }

  /**
   * サーバに予約キャンセルリクエスト実行中かどうか
   *
   * @type {boolean}
   * @memberof ReservationCancelComponent
   */
  isRequesting: boolean = false;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of ReservationCancelComponent.
   * @param {Params} _params
   * @param {UserWebApiService} userWebApiService
   * @param {FamilyWebApiService} familyWebApiService
   * @param {DispatchWebApiService} dispatchWebApiService
   * @param {ApplicationMessageService} appMsg
   * @param {HttpErrorResponseParserService} httpErrorResponseParserService
   * @param {OnsNavigator} _navigator
   * @memberof ReservationCancelComponent
   */
  constructor(
    private _params: Params,
    private userWebApiService: UserWebApiService,
    private familyWebApiService: FamilyWebApiService,
    private dispatchWebApiService: DispatchWebApiService,
    private appMsg: ApplicationMessageService, 
    private httpErrorResponseParserService: HttpErrorResponseParserService,
    private _navigator: OnsNavigator,
    private msg: MESSAGE,
  ) {}

  /**
   * 初期化処理。
   *
   * @memberof ReservationCancelComponent
   */
  ngOnInit(): void 
  {
    this.m_Reservation = this._params.data.reservation;
    this.m_Transition = this._params.data.transition;

    const cancelRule = this.dispatchWebApiService.getCancelRule(this.m_Reservation.d.schd_time);
    // キャンセル割合
    this.cancelDescription.fee = cancelRule.ratio*100;
    // 無料キャンセル期限
    this.cancelDescription.limit = cancelRule.limit;
    
    // キャンセル用表示のためコピー
    this.customerBill = JSON.parse(JSON.stringify(this._params.data.customerBill));

    // if (this.userWebApiService.getUserInfo().user_type === "tourist") this.cancelDescription.ticket_name.season = CONST.Purchase.TICKET_TITLE_TOURIST;
  }

  /**
   * ビューの更新。
   *
   * @memberof ReservationCancelComponent
   */
  ngAfterViewInit(): void
  {
    this.m_Passengers = this.m_Reservation.passenger_users;
    this.m_Passengers.forEach(u => 
      {
        u.cancelability = 
        (
          !u.canceled  // 既にキャンセルされていないユーザーのみキャンセル可
          && 
          (
            u.property =='self' // 自身はキャンセル可
            || (this.familyWebApiService.getFamilyInfo().find(family => family.user_id == u.id && family.role == 'child' && family.status != 'waiting') != undefined)  // ファミリーメンバーはキャンセル可
          )
        );

        if (u.cancelability == true)
        {
          // キャンセル可のユーザーのキャンセルチェックボックス初期値はtrue
          u.cancel = true;
        }
        else
        {
          u.cancel = false;
        }
      });
    
    // ExpressionChangedAfterItHasBeenCheckedError回避のためのsetTimeout
    setTimeout(() => 
    {// キャンセル可能人数が一人の場合はチェックボックスを出さない
      if (this.m_Passengers.filter(u => u.cancelability == true).length == 1)
      {
        this.m_bShowCheckbox = false;
      }
      else
      {
        this.m_bShowCheckbox = true;
      }
      this.changeCancel();
    })
  }

  /**
   * 破棄処理。
   *
   * @memberof ReservationCancelComponent
   */
  ngOnDestroy(): void 
  {
    this.m_Busy?.unsubscribe();
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * ページ操作を行う。
   *
   * @param {LoginComponent.Transition} transition
   * @memberof ReservationCancelComponent
   */
  toTransition(transition: LoginComponent.Transition): void 
  {
    switch (transition.method) 
    {
      case 'popPage':
        this._navigator.element.popPage(transition.options);
        break;
      case 'pushPage':
        this._navigator.element.pushPage(transition.page, transition.options);
        break;
      case 'resetToPage':
        this._navigator.element.resetToPage(transition.page, transition.options);
        break;
    }
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 料金種別（文字列）を取得する。
   *
   * @param {string} property
   * @return {*}  {string}
   * @memberof ReservationCancelComponent
   */
   getAmoutProperty(property: string): string 
   {
     return this.dispatchWebApiService.getAmountPropertyString(property);
   }

  /**
   * キャンセルするユーザーの切り替えによる画面下方のビューの変更を行う。
   *
   * @return {*}  {void}
   * @memberof ReservationCancelComponent
   */
  changeCancel(): void 
  {
    // 現在時刻、有料キャンセル期限(＝乗車予定時刻)、無料キャンセル期限を取得
    const now = new Date();
    const paidLimit = new Date(this.m_Reservation.sections.filter(s => s.trans == "smartgoto")[0].o.schd_time);
    let freeLimit = new Date(paidLimit.getTime());
    freeLimit.setMinutes(freeLimit.getMinutes() - this.cancelDescription.limit);

    // 現在時刻が有料キャンセル期限以降の場合
    if(paidLimit <= now)
    {
      // キャンセル不可
      this.appMsg.viewDialogMessage(this.msg.CLIENT.DISPATCH.ELAPSED_RESERV_TIME.message(), () => this._navigator.element.popPage());
      return;
    }

    // 現在時刻が無料キャンセル期限以降＆有料キャンセル期限より前の場合
    let paid: boolean = false;
    if(freeLimit <= now && now < paidLimit) paid = true;

    // 払い戻し料金を取得
    this.dispatchWebApiService.setRefundFee(this.customerBill, this.m_Passengers, paid, this.m_Reservation.reserve_time);

    // キャンセル実行ボタンOFF
    this.m_bCancelAbility = !this.m_Passengers.every(u => u.cancel == false);
  }

  /**
   * 予約キャンセルを実行する。
   *
   * @return {*}  {void}
   * @memberof ReservationCancelComponent
   */
  cancelPlan(): void {

    // ファミリー管理者から制限されている場合はキャンセル不可
    this.m_Busy = this.familyWebApiService.getFamily().subscribe();
    if (this.familyWebApiService.isLoginUserUtilization()) {
      this.appMsg.viewDialogMessage(this.msg.CLIENT.FAMILY.RESTRICTED_UTILIZATION.message(), () => this._navigator.element.popPage());
      return;
    }
    
    /**キャンセルするユーザーのユーザーID*/
    const user_ids = this.m_Passengers.filter(u => u.cancel ==true).map(u => u.id);
    /**サーバーに送るパラメータ*/
    const params: request.DeleteReservation = {
      reservation_id: this.m_Reservation.reservation_id,
      passenger_user_id: user_ids,
      // pay_cancel: true
    }

    // 2重送信対策
    if (this.isRequesting) {
      // console.log("2重クリックのためリクエストロック中");
      return;
    }
    this.isRequesting = true;

    this.m_Busy = this.dispatchWebApiService.deleteReservation(params).subscribe({
      next: () => {

        this.isRequesting = false;
        this.appMsg.viewDialogMessage(this.msg.CLIENT.DISPATCH.CANCEL_COMPLETE.message(), () => {
          // メール遷移の場合
          if (this._params.data.transition != null) this._params.data.transition();
          else {
            this._navigator.element.resetToPage(HistoryListComponent, {
              animation: 'fade-ios',
              data: { selectedDate: moment(this.m_Reservation.o.schd_time).format('YYYY-MM-DD') }
            })
          }
        });
      },
      error: this.httpErrorResponseParserService.doParse((_error, customErrorContent) => {
        // 時間経過によりキャンセル料が発生した場合
        if(_error.status == 402) {

          this.isRequesting = false;
          this.appMsg.confirmMessage(this.msg.CLIENT.DISPATCH.COST_CAMCELLATION_FEE.message(), value => {
            if (Number(value) == CONST.Common.CONFIRM_YES) {

              // 2重送信対策
              if (this.isRequesting) {
                // console.log("2重クリックのためリクエストロック中");
                return;
              }
              this.isRequesting = true;

              this.m_Busy = this.dispatchWebApiService.deleteReservation({
                reservation_id: this.m_Reservation.reservation_id,
                passenger_user_id: user_ids,
                pay_cancel: true
              }).subscribe({
                next: _response => {

                  this.isRequesting = false;

                  this.appMsg.viewDialogMessage(this.msg.CLIENT.DISPATCH.CANCEL_COMPLETE.message(), () => {
                    // メール遷移の場合
                    if (this._params.data.transition != null) this._params.data.transition();
                    else {
                      this._navigator.element.resetToPage(HistoryListComponent, {
                        animation: 'fade-ios',
                        data: { selectedDate: moment(this.m_Reservation.o.schd_time).format('YYYY-MM-DD') }
                      })
                    }
                  });
                },
                error: this.httpErrorResponseParserService.doParse((_error, customErrorContent) => {

                  this.isRequesting = false;

                  this.httpErrorResponseParserService.viewErrDialog(customErrorContent).then(() => this._navigator.element.popPage());
                })
              });
            }
          });
        }
        else {
          this.isRequesting = false;
          // サーバからのエラーメッセージを表示
          this.httpErrorResponseParserService.viewErrDialog(customErrorContent).then(() => this._navigator.element.popPage());
        }
      })
    })
  }
}