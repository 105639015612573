// SmartGOTO特有のQRコードオブジェクトのインターフェース定義

import { Injectable } from "@angular/core";
import { Qr } from "src/app/constants/constant";


/** QRコードのtypeプロパティ種類 */
const QR_TYPE = {
  /** ユーザ */
  user: "US",
  /** 配送物 */
  delivery_package: "DP",
} as const;

type QrVer = typeof Qr.QR_VER[number];

export type QrType = typeof QR_TYPE[keyof typeof QR_TYPE];
type QrTypeObject = {
  [key in typeof QR_TYPE[keyof typeof QR_TYPE]]: any;
};

/**
 * QRコードのコンテンツ情報 
 * note: 型定義編集の際、constructorの編集も忘れず
 *
 * @class QrContent
 * @implements {QrTypeObject}
 */
@Injectable({
  providedIn: null,
})
export class QrContent implements QrTypeObject {
  /** ユーザQR */
  readonly "US": {
    /**
     * ユーザID
     *
     * @type {(number|string)}
     */
    readonly sg_id: number|string;
    /**
     * 発行回数
     *
     * @type {number}
     */
    readonly qr_ver: number;
  };
  /** 配送物QR */
  readonly "DP": {
    /**
     * 配送物ID
     *
     * @type {number}
     */
    readonly id: number;
  };

  // コンストラクタ
  constructor () {
    this.US = {sg_id: undefined, qr_ver: undefined};
    this.DP = {id: undefined};
  }
};

/**
 * SmartGOTO発行のQRコード情報
 * note: 型定義編集の際、constructorの編集も忘れず
 *
 * @export
 * @class SmartGotoQr
 */
@Injectable({
  providedIn: null,
})
export class SmartGotoQr {
  /**
   * このQRフォーマットの規格
   *
   * @type {QrVer}
   * @memberof SmartGotoQr
   */
  readonly ver: QrVer;
  /**
   * QRコードの分類
   *
   * @type {QrType}
   * @memberof SmartGotoQr
   */
  readonly type: QrType;
  /**
   * コンテンツ情報
   * note: 便宜上、any型としている
   *
   * @type {QrContent[keyof QrContent]}
   * @memberof SmartGotoQr
   */
  readonly content: any;
  /**
   * ハッシュ値
   *
   * @type {string}
   * @memberof SmartGotoQr
   */
  readonly hash: string;

  // コンストラクタ
  constructor() {
    this.ver = undefined;
    this.type = undefined;
    this.content = undefined;
    this.hash = undefined;
  }
};