<ons-toolbar>
  <div class="center">{{title}}</div>
  <ons-back-button *ngIf="isSplitter"></ons-back-button>
</ons-toolbar>

<div class="content">
  <div [ngBusy]="busy"></div>
  <div class="content--description">
    <p class="content--description-text" *ngIf="prefectures">
      Smart GOTOのサービスをご利用になる自治体を選択してください。
    </p>
  </div>

  <ons-list>
    <ng-container *ngIf="prefectures">
      <ons-list-item *ngFor="let prefectureContent of prefectures" expandable modifier="longdivider padding-left">
        <div class="list-item__wrap">{{prefectureContent.name}}</div>
        <ons-list class="expandable-content">
          <ons-list-item *ngFor="let city of prefectureContent.cities" modifier="longdivider" tappable (click)="pushMunicipalVer(city.municipal_id)">{{city.name}}</ons-list-item>
        </ons-list>
      </ons-list-item>
    </ng-container>
  </ons-list>
</div>