<ons-toolbar>
  <div class="left"><ons-back-button #onsBackButton></ons-back-button></div>
  <div class="center">観光サービス詳細</div>
</ons-toolbar>

<div [ngBusy]="busy"></div>
<div class="content">

  <!-- header -->
  <parts-ons-list [listInfo]="template.header"></parts-ons-list>

  <parts-carousel [images]="service.images"></parts-carousel>

  <div class="list-item__caption">説明</div>
  <ons-list>
    <ons-list-item class="list-item__text" modifier="longdivider">
      <ng-container *ngIf="dispDescription; else noItem">
        <div class="common--text" [innerHtml]="dispDescription"></div>
      </ng-container>
    </ons-list-item>
  </ons-list>

  <!-- body -->
  <ng-container *ngFor="let bo of template.body; index as i">
    <parts-ons-list [listInfo]="bo" (partsSelectEvent)="selectedEvent($event)"></parts-ons-list>
  </ng-container>

  <!-- オプション -->
  <ng-container  *ngIf="expBill.options">
    <ng-container *ngFor="let group of optionGroups">

      <!-- ヘッダー -->
      <div *ngIf="group.options" class="list-item__caption">
        <span *ngIf="group.options.length === 1" class="exp-option-wrap__item">
          <span>{{group.options[0].name}}</span>
          &nbsp;<button #optionDialog class="info_button" style="border: solid 1px !important;" (click)="onClickOptionInfo(group.options[0])"><img [src]="ASSETS.INFORMATION"></button>
        </span>
        <span *ngIf="group.options.length >= 2">{{group.name}}</span>
      </div>

      <ng-container *ngFor="let option of group.options">
        <ons-list>
          <ng-container *ngIf="option.user_option !== 'comment'">
            <ons-list-item *ngIf="group.options.length == 1" modifier="nodivider" [matMenuTriggerFor]="menu1">
              <span class="exp-option-wrap">
                <div *ngIf="expBill.options[option.sg_option_id]">
                  <span style="overflow-wrap: anywhere;" *ngIf="option.user_option === 'yesno'">{{expBill.options[option.sg_option_id].yesno_param.name}}</span>
                  <span style="overflow-wrap: anywhere;" *ngIf="option.user_option === 'select'">{{expBill.options[option.sg_option_id].select_param.name}}</span>
                  <span style="overflow-wrap: anywhere;" *ngIf="option.user_option === 'number'">
                    <span>{{expBill.options[option.sg_option_id].number_param.selected}}</span>
                    <span style="overflow-wrap: anywhere;">{{option.unit}}</span>
                  </span>
                </div>
                <div>
                  <button class="link_select-box__drop-down-menu" mat-icon-button>
                    <span class="material-icons">
                      arrow_drop_down
                    </span>
                  </button>
                  <mat-menu #menu1="matMenu" xPosition="before">
                    <ng-container *ngIf="option.user_option === 'yesno'">
                      <ng-container *ngFor="let item of option.yesno_param.items">
                        <button class="mat-menu-item__height" mat-menu-item (click)="onChangeOptionYesno(option, item)">{{item.name}}</button>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="option.user_option === 'select'">
                      <ng-container *ngFor="let item of option.select_param.items">
                        <button class="mat-menu-item__height" mat-menu-item (click)="onChangeOptionSelect(option, item)">{{item.name}}</button>
                      </ng-container>
                    </ng-container>
                    <ng-container *ngIf="option.user_option === 'number' && expBill.options[option.sg_option_id].number_param.select">
                      <ng-container *ngFor="let item of expBill.options[option.sg_option_id].number_param.select">
                        <button class="mat-menu-item__height" mat-menu-item (click)="onChangeOptionNumber(option, item)">{{item}}{{option.unit}}</button>
                      </ng-container>
                    </ng-container>
                  </mat-menu>
                </div>
              </span>
            </ons-list-item>
            <ons-list-item *ngIf="group.options.length >= 2" modifier="nodivider" [matMenuTriggerFor]="menu2">
              <p class="exp-option-wrap">
                <span class="exp-option-wrap__item">
                  <span style="overflow-wrap: anywhere;">{{ option.name }}</span>
                  &nbsp;<button #optionDialog class="info_button" style="border: solid 1px !important;" (click)="onClickOptionInfo(option)"><img [src]="ASSETS.INFORMATION"></button>
                </span>
                <span class="link_select-box">
                  <div *ngIf="expBill.options[option.sg_option_id]">
                    <span style="overflow-wrap: anywhere;" *ngIf="option.user_option === 'yesno'">{{expBill.options[option.sg_option_id].yesno_param.name}}</span>
                    <span style="overflow-wrap: anywhere;" *ngIf="option.user_option === 'select'">{{expBill.options[option.sg_option_id].select_param.name}}</span>
                    <span style="overflow-wrap: anywhere;" *ngIf="option.user_option === 'number'">
                      <span>{{expBill.options[option.sg_option_id].number_param.selected}}</span>
                      <span style="overflow-wrap: anywhere;">{{option.unit}}</span>
                    </span>
                  </div>
                  <div>
                    <button class="link_select-box__drop-down-menu" mat-icon-button>
                      <span class="material-icons">
                        arrow_drop_down
                      </span>
                    </button>
                    <mat-menu #menu2="matMenu" xPosition="before">
                      <ng-container *ngIf="option.user_option === 'yesno'">
                        <ng-container *ngFor="let item of option.yesno_param.items">
                          <button class="mat-menu-item__height" mat-menu-item (click)="onChangeOptionYesno(option, item)">{{item.name}}</button>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="option.user_option === 'select'">
                        <ng-container *ngFor="let item of option.select_param.items">
                          <button class="mat-menu-item__height" mat-menu-item (click)="onChangeOptionSelect(option, item)">{{item.name}}</button>
                        </ng-container>
                      </ng-container>
                      <ng-container *ngIf="option.user_option === 'number' && expBill.options[option.sg_option_id].number_param.select">
                        <ng-container *ngFor="let item of expBill.options[option.sg_option_id].number_param.select">
                          <button class="mat-menu-item__height" mat-menu-item (click)="onChangeOptionNumber(option, item)">{{item}}{{option.unit}}</button>
                        </ng-container>
                      </ng-container>
                    </mat-menu>
                  </div>
                </span>
              </p>
            </ons-list-item>
          </ng-container>

          <ons-list-item modifier="nodivider" *ngIf="option.user_option === 'comment'">
            <ons-input [(ngModel)]="expBill[option.sg_option_id].comment"></ons-input>
          </ons-list-item>

          <ng-container *ngIf="option.user_option !== 'yesno' || !(option.user_option === 'yesno' && expBill.options[option.sg_option_id].yesno_param.value === false)">
            <ons-list-item modifier="nodivider" *ngIf="(option.time_rule === 'days' || option.time_rule === 'mins') && expBill.options[option.sg_option_id].time_param" [matMenuTriggerFor]="menu">
              <p class="exp-option-wrap">
                <span class="exp-option-wrap__item" style="padding-left: 15px;">
                  <span *ngIf="option.time_rule === 'days'" style="overflow-wrap: anywhere;">利用日数</span>
                  <span *ngIf="option.time_rule === 'mins'" style="overflow-wrap: anywhere;">利用時間</span>
                </span>

                <span class="exp-option-wrap__item">
                  <div>
                    <span>{{expBill.options[option.sg_option_id].time_param.selected}}</span>
                    <span style="overflow-wrap: anywhere;" *ngIf="option.time_rule === 'days'">日</span>
                    <span style="overflow-wrap: anywhere;" *ngIf="option.time_rule === 'mins'">分</span>
                  </div>
                  <div>
                    <button class="link_select-box__drop-down-menu" mat-icon-button>
                      <span class="material-icons">
                        arrow_drop_down
                      </span>
                    </button>
                    <mat-menu #menu="matMenu" xPosition="before">
                      <ng-container *ngFor="let item of expBill.options[option.sg_option_id].time_param.select">
                        <button class="mat-menu-item__height" mat-menu-item (click)="onChangeOptionTime(option, item)">
                          {{item}}
                          <ng-container *ngIf="option.time_rule === 'days'">日</ng-container>
                          <ng-container *ngIf="option.time_rule === 'mins'">分</ng-container>
                        </button>
                      </ng-container>
                    </mat-menu>
                  </div>
                </span>
              </p>
              <div class="valid-msg" *ngIf="expBill.options[option.sg_option_id].time_err">
                <div *ngIf="expBill.options[option.sg_option_id].time_err.check ===true">
                  <span>{{expBill.options[option.sg_option_id].time_err.message}}</span>
                </div>
              </div>
            </ons-list-item>

          </ng-container>
        </ons-list>
      </ng-container>
    </ng-container>
  </ng-container>

  <!-- datepicker -->
  <mat-datepicker #matDatePicker hidden></mat-datepicker>
  <input matInput [matDatepicker]="matDatePicker" (dateChange)="onChangeDate($event)" [value]="selectedDate" [min]="datePickerRange.start" [max]="datePickerRange.end" hidden>

  <ons-list>
    <ons-list-header modifier="color" class="list-item__title header--height">利用開始時刻</ons-list-header>
  </ons-list>
  <!-- calendar -->
  <div class="calendar-wrap">
    <full-calendar id="week-calendar" #calendar [options]="calendarOptions"></full-calendar>
  </div>

  <!-- footer -->
  <parts-ons-list [listInfo]="template.footer"></parts-ons-list>
  <div #btn_trans class="button-wrap button-sticky">
    <ons-button modifier="large--cta" (click)="onTransition()">
      予約内容確認へ
    </ons-button>
  </div>
</div>

<ng-template #noItem><mat-icon >remove</mat-icon></ng-template>
