import { Component, OnInit } from '@angular/core';
import { OnsNavigator, Params } from 'ngx-onsenui';
import { CommonFunctionModule } from 'src/app/lib-modules/common-function.module';
import { ServiceComponent } from '../../service/service.component';
import { UserReservation } from '../user-reservation/user-reservation';

import { PageKey, PagerService } from 'src/app/lib-services/pager.service';

/**
 * 予約完了画面。
 *
 * @export
 * @class UserReservationCompleteComponent
 * @implements {OnInit}
 */
@Component({
  selector: 'ons-page[user-reservation-complete]',
  templateUrl: './user-reservation-complete.component.html',
  styleUrls: ['./user-reservation-complete.component.css']
})
export class UserReservationCompleteComponent implements OnInit {
  /**
   * 移動プランの検索条件。
   *
   * @type {UserReservation.SearchCondition}
   * @memberof UserReservationCompleteComponent
   */
  searchCondition: UserReservation.SearchCondition;

  /**
   * assetsファイルへのパス(定数)
   *
   * @type {string}
   * @memberof UserReservationCompleteComponent
   */
  readonly ASSETS_COMPELTE: string = CommonFunctionModule.getAssetsUrl('/image/character/complate.png', false)

  /**
   * Creates an instance of UserReservationCompleteComponent.
   * @param {OnsNavigator} _navigator ページスタックの管理とナビゲーション機能を提供するコンポーネント。
   * @param {Params} params 遷移元のページから渡されるパラメーター。
   * @memberof UserReservationCompleteComponent
   */
  constructor(
    private _navigator: OnsNavigator,
    private params: Params,
    private pagerServ: PagerService,
  ) { }

  /**
   * コンポーネントが初期化される際に呼び出されるメソッド。
   *
   * @memberof UserReservationCompleteComponent
   */
  ngOnInit(): void {
    [this.searchCondition] = this.params.data;
  }

  /**
   * ホームに戻る。
   *
   * @memberof UserReservationCompleteComponent
   */
  returnHome(): void {
    // 配車サービストップ画面に遷移
    this.pagerServ.transitionToPage(this._navigator, PageKey.UserReservationComponent);
  }

  /**
   * 続けて予約する。
   *
   * @memberof UserReservationCompleteComponent
   */
  continueReservation(): void {
    this.searchCondition.returnHere();
  }
}
