//=============================================================================================
// インポート
//=============================================================================================
import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import * as qs from 'qs';
import * as CONST from '../constants/constant';
import * as PURCHASE from '../components/purchase/purchase';
import * as moment from 'moment';

// service
import { MunicipalityWebApiService } from './municipality-web-api.service';
import { UserWebApiService } from './user-web-api.service';

// module
import { CommonFunctionModule } from "../lib-modules/common-function.module";

// interface
import { common } from '../interfaces/common';
import { parameter } from '../interfaces/parameter';
import { ExUser, TicketCatalog, Ticket, PossessionsOrderItem, PossessionsDeliveryTicket, PossessionsTicket, Settings, PossessionsExpTicket, UserInfo, Purchase, PaymentSummary } from '../interfaces/response';

//=============================================================================================
// Configクラス定義
//=============================================================================================

/**
 * HTTP接続情報
 *
 * @export
 * @class PurchaseWebApiServiceConfig
 * @author （株）オブジェクトデータ wada
 */
export class PurchaseWebApiServiceConfig {
  /**
   * サーバのベースURL
   *
   * @type {string}
   * @memberof PurchaseWebApiServiceConfig
   */
  baseUrl: string;

  /**
   * httpOptions
   *
   * @type {({
   *     headers?: HttpHeaders | {
   *       [header: string]: string | string[];
   *     };
   *   })}
   * @memberof PurchaseWebApiServiceConfig
   */
  httpOptions?: {
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
  };
}

//=============================================================================================
// Serviceクラス定義
//=============================================================================================

/**
 * WebApiサービス
 *
 * @export
 * @class PurchaseWebApiService
 * @author （株）オブジェクトデータ wada
 */
@Injectable({
  providedIn: 'root'
})
export class PurchaseWebApiService implements OnDestroy {
  
  /**
   * 自治体ごとの設定情報
   *
   * @type {Settings}
   * @memberof PurchaseWebApiService
   */
  setting: Settings;

  /**
   * OnDestroy時に破棄するSubscriptionオブジェクト
   *
   * @memberof PurchaseWebApiService
   */
  subscription = new Subscription();

//=============================================================================================
// コンストラクタ
//=============================================================================================

  /**
   *
   * @param {HttpClient} http
   * @param {CommonFunctionModule} commonFunction
   * @param {PurchaseWebApiServiceConfig} config
   * @param {MunicipalityWebApiService} municipalityWebApiServ
   * @memberof PurchaseWebApiService
   */
  constructor(
    private http: HttpClient, 
    private commonFunction: CommonFunctionModule, 
    private config: PurchaseWebApiServiceConfig, 
    private municipalityWebApiServ: MunicipalityWebApiService,
    private userWebApiServ: UserWebApiService,
  ) {
    const settingsChanged = this.municipalityWebApiServ.settingsChanged.subscribe({
      next: setting => {
        if (setting == null) return;
        this.setting = setting;
      }
    });
    this.subscription.add(settingsChanged);
  }

  /**
   * 廃棄処理
   *
   * @memberof PurchaseWebApiService
   */
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

//=============================================================================================
// メソッド
//=============================================================================================

  // --------------------------------------------
  //  請求明細
  // --------------------------------------------

  /**
   * 年月が請求明細の有効範囲内か？
   *
   * @param {string} date
   * @return {*}  {(boolean | string)}
   * @memberof PurchaseWebApiService
   */
  isValidRange(date: string): boolean | string { 

    // チェックする年月が日付か
    if (new Date(date).toString() === "Invalid Date") return "Invalid Date";

    // 選択可能な最新、最古の日付
    const oldest: string = this.setting.purchase.periods[this.setting.purchase.periods.length - 1].month;
    const newest: string = this.setting.purchase.periods[0].month;

    // 有効表示範囲内か
    if (moment(date).isSameOrAfter(oldest, 'month') && moment(date).isSameOrBefore(newest, 'month')) return true;
    return false;
  }
  
  // --------------------------------------------
  // 乗車券購入
  // --------------------------------------------
  
  /**
   * ユーザータイプに応じた乗車券名称を取得する。
   *    定額乗車券/数日乗り放題券
   *
   * @param {ExUser["user_type"]} user_type
   * @return {*}  {string}
   * @memberof PurchaseWebApiService
   */
  getTicketTypeName(user_type: ExUser["user_type"]): string {

    let title: string = "";

    // ユーザ種別によって分岐
    switch (user_type) {
      case "citizen": // 観光目的でないユーザ
        title = CONST.Purchase.TICKET_TITLE_CITIZEN;
        break;
      case "tourist": // 観光目的ユーザ
        title = CONST.Purchase.TICKET_TITLE_TOURIST;
        break;
      default: ;
    }

    return title;
  }
   
  /**
   * 購入可能学年に到達しているかを返却する。
   *
   * @param {ExUser} user
   * @return {*}  {boolean}
   * @memberof PurchaseWebApiService
   */
  isPreschooler(user: ExUser): boolean {

    const NEW_MONTH: number = 4;

    // 新学期（4/1）
    let newTerm = new Date(new Date().getFullYear(), NEW_MONTH - 1, 1);

    // 生年月日
    let birth = new Date(user.profile.birth.year, user.profile.birth.month - 1, user.profile.birth.day);

    // 新学期時点での年齢を取得
    let age = Math.floor((this.commonFunction.parseDate(newTerm) - this.commonFunction.parseDate(birth)) / 10000);

    // 7歳以上は購入可
    if (CONST.Purchase.TICKET_PLAN_AGE.FREE < age) return true;
    else if (CONST.Purchase.TICKET_PLAN_AGE.FREE === age) { // 6歳
      let useStart = new Date();

      // 設定可能な最大の利用開始日
      useStart.setDate(useStart.getDate() + this.setting.ticket.limit);

      // 利用開始日が新学期を超えている場合は購入可
      if (newTerm.getTime() <= useStart.getTime()) return true;
    }

    return false;
  }

  /**
   * 乗車券の名称を設定する。
   *
   * @param {TicketCatalog} catalog
   * @return {*}  {void}
   * @memberof PurchaseWebApiService
   */
  setTicketTypeName(catalog: TicketCatalog, user_type: "citizen" | "tourist"): void {

    // ticket_type_nameが設定されている場合は処理を抜ける
    // note: 今後、ticket_type_nameはすべてのカタログに対応するように
    if (catalog.ticket_type_name !== void 0) return;

    // 観光目的でない（住人）ユーザ
    if (user_type === "citizen") {
      switch (catalog.terms.age_type) {
        case "elementary_school": 
          catalog.ticket_type_name = CONST.Purchase.TICKET_TYPE_NAME.CHILD;
          return;
        case "junior_high_school": 
        case "high_school": 
          catalog.ticket_type_name = CONST.Purchase.TICKET_TYPE_NAME.STUDENT;
          return;
        case "adult": 
        case "senior": 
          catalog.ticket_type_name = CONST.Purchase.TICKET_TYPE_NAME.ADULT_SENIOR;
          return;
        default: {
          if (catalog.terms.special_type === 'give_up_license') {
            catalog.ticket_type_name = CONST.Purchase.TICKET_TYPE_NAME.ADULT_SENIOR;
            return;
          }
          else ; // 想定外
        }
      }
    }
    // 観光目的ユーザ
    else if (user_type === "tourist") {
      switch (catalog.terms.age_type) {
        case "elementary_school": 
          catalog.ticket_type_name = CONST.Purchase.TICKET_TYPE_NAME.TOURIST_CHILD;
          return;
        case undefined: 
          catalog.ticket_type_name = CONST.Purchase.TICKET_TYPE_NAME.TOURIST_ADULT;
          return;
        default: 
          catalog.ticket_type_name = "";
          return
      }
    }
    else ;
  }

  /**
   * 購入可能乗車券を料金で並び替える。
   *    sort=true ：昇順
   *    sort=false：降順
   *
   * @param {TicketCatalog[]} ticket
   * @param {boolean} [sort=true]
   * @memberof PurchaseWebApiService
   */
  sortTicketPrice(ticket: TicketCatalog[], sort: boolean = true): void {

    if (true === sort) {
      ticket.sort((a, b) => {
        if (a.price < b.price) return -1;
        else if (a.price > b.price) return 1;
        else return 0;
      });
    }
    else {
      ticket.sort((a, b) => {
        if (a.price > b.price) return -1;
        else if (a.price < b.price) return 1;
        else return 0;
      });
    }
  }
  
  /**
   * 予約（1回券/定額乗車券）と対象乗車券の重複チェック。
   *    対象乗車券の有効期間内に予約（1回券/定額乗車券）があるかどうか。ある場合、
   *    観光目的でないユーザ：1回券/定額乗車券の利用地区が購入する乗車券で賄えるか。
   *    観光目的ユーザ：1回券の利用期間が乗車券の利用期間に含まれるか。
   *
   * @private
   * @param {(TicketCatalog | Ticket)} target
   * @return {*}  {boolean} true：含まれる、false：含まれない
   * @memberof PurchaseTicketNewComponent
   */
  isReservDuplication(target: TicketCatalog | Ticket, reservOneTicket: common.ReservTicket[]): boolean 
  { 
    // 予約（1回券）がないなら含まれない
    if (reservOneTicket.length == 0) return false;
    let result: boolean[] = [];

    // コピー
    let oneTicket = reservOneTicket.slice();
    let delPos: number[] = [];

    oneTicket.forEach((one, index) => {
      // 対象乗車券の有効範囲以降の予約でないなら除外準備
      if (false === this.commonFunction.compareDateInclude(one.o.schd_time, one.d.schd_time, target.start_date, target.end_date)) 
      {
        delPos.unshift(index);
        return;
      }
    });

    // 利用不可の予約（1回券）を除外
    if (delPos.length != 0) delPos.forEach(pos => oneTicket.splice(pos, 1));

    // 除外の結果予約（1回券）がなくなれば含まれない
    if (oneTicket.length == 0) return false;
    
    // 全エリアで使用可能な乗車券の場合、含まれる
    if (target.available_zone["any"] === true) return true;

    // 観光目的でない（住人）ユーザかつ、免許返納者ではない場合
    if (target.terms.special_type !== 'give_up_license') {
      // console.log("購入対象の定期券のエリア（target.area.area_ids）: " + target.area.area_ids + " : " + target.area.name)
      // 予約（1回券）のエリアが購入しようとしている定期券のエリアにすべて含まれるか
      oneTicket.forEach(t => {

        const ret =  t.area.area_ids.every(ids => target.area.area_ids.includes(ids));
        // console.log("1回券のエリア（t.area.area_ids）: " + t.area.area_ids + " : " + t.area.name)
        // console.log("1回券がチケット範囲か: " + ret);
        
        result.push(ret);
      });
    }

    if (true === result.includes(true)) return true;
    return false;
  }

  /**
   * 対象の乗車券の購入可否をチェックする。
   *
   * @param {(TicketCatalog | Ticket)} ticket 購入対象/所有乗車券
   * @param {Ticket[]} reservTicket 予約情報
   * @param {boolean} user_citizen 観光目的でないユーザーか
   * @return {*}  {number[]}
   * @memberof PurchaseWebApiService
   */
  checkInvalidPeriodAndReserv(ticket: TicketCatalog | Ticket, reservTicket: Ticket[]): number[] {

    // 対象乗車券で賄える予約（1回券/定額乗車券）が有効期間内に存在するか
    let result = this.isReservDuplication(ticket, reservTicket);

    // 「有効期間のうち1日以上」が利用不可
    if (undefined !== ticket.invalid?.until) {    // この日まで利用できない（利用開始日からuntilまで利用できない）
      // チケット情報異常
      return [PURCHASE.PURCHASE_STATUS.E_UNEXPECTED];
    }
    // --------------------------------------------
    // 「有効期間の一部」が利用可
    // --------------------------------------------
    else if (undefined !== ticket.invalid?.from) { // この日から利用できない（fromから利用終了日まで利用できない）
      // 予約あり
      if (true === result) return [PURCHASE.PURCHASE_STATUS.OK_CAUTION, PURCHASE.PURCHASE_STATUS.OK_RESERV_CANCEL];
      else return [PURCHASE.PURCHASE_STATUS.OK_CAUTION];
    }
    // --------------------------------------------
    // 「有効期間のすべて」が利用可
    // --------------------------------------------
    else {
      // 予約あり
      if (true === result) return [PURCHASE.PURCHASE_STATUS.OK_RESERV_CANCEL];
      else return [PURCHASE.PURCHASE_STATUS.OK];
    }
  }

  /**
   * 自動更新変更期限を迎えているか判断する。
   *
   * @param {Ticket} ticket
   * @return {*}  {boolean}
   * @memberof PurchaseWebApiService
   */
  checkContinueChangeLimit(ticket: Ticket): boolean {

    let current = new Date();
    let result = this.commonFunction.compareDate(new Date(ticket.end_date), current);
    
    if (true === result && CONST.Purchase.TICKET_CONTINUE_LIMIT <= current.getHours()) return true;

    return false;
  }

  /**
   * 自動更新後の乗車券情報を作成する。
   *
   * @param {TicketCatalog} ticket
   * @return {*}  {TicketCatalog}
   * @memberof PurchaseWebApiService
   */
  createNextTicket(ticket: TicketCatalog | Ticket): TicketCatalog | Ticket {

    // 自動更新後の乗車券を作成
    let next:TicketCatalog | Ticket = JSON.parse(JSON.stringify(ticket));
    next.start_date = next.next_ticket?.start_date;
    next.end_date = next.next_ticket?.end_date;
    next.invalid = next.next_ticket?.invalid;

    return next;
  }

  /**
   * 配車、注文、送料の請求明細を取得する。
   *
   * @param {parameter.PossessionsTicket} params
   * @return {*}  {Observable<any>}
   * @memberof PurchaseWebApiService
   */
  getPurchaseDetail(params: parameter.PossessionsTicket): Observable<any> {

    const retSubject: Subject<any> = new Subject();    // 非同期処理を追加

    const taskList: Observable<any>[] = [
      this.getPurchaseData(params),
      this.getOrderItemData(params),
      this.getDeliveryTicketData(params),
      this.getExpTicketData(params)
    ];

    // 全非同期処理が完了するまで待機
    forkJoin(taskList).subscribe({
      next: res => {
        retSubject.next(res);
        retSubject.complete();
      },
      error: err => {
        retSubject.error(err);
        // completeしていないのはまずいのでは
      }
    });

    return retSubject;
  }

  /**
   * 請求明細表示データを作成する。
   *
   * @param {Object} result
   * @return {*}  {parameter.Purchase}
   * @memberof PurchaseWebApiService
   */
  createPurchaseDetailData(result: Object): {[string: string]: common.DispPurchase} {
    
    const tickets: PossessionsTicket = result[0];                   // 配車
    const order: PossessionsOrderItem = result[1];                  // 注文
    const charges: PossessionsDeliveryTicket = result[2];           // 送料
    const exp: PossessionsExpTicket = result[3];

    let purchase: {[method: string]: common.DispPurchase} = {};

    /**
     * 購入物のチェックを実施
     * 元々0円、キャンセル済を除く
     */
    const checkPurchase = (ticket: PossessionsTicket["tickets"][number] | PossessionsOrderItem["order_items"][number] | 
      PossessionsDeliveryTicket["delivery_tickets"][number] | PossessionsExpTicket["sg_tickets"][number] ): boolean => {
      // 元々0円の購入物を除く
      if (ticket.purchase.method === 'NONE') return false;

      // 0円購入物(無料キャンセル, 元々0円購入物)
      if (ticket.amount === 0) return false;

      // 支払い方法が存在しない購入物は除く(想定外)
      if (!ticket.purchase.method) return false;

      return true;
    }

    /**
     * keyとする請求先名を取得
     * 対象購入物の支払い方法がpurchaseのkeyに存在しなかった場合、作成
     * 
     * @param purchase 
     */
    const getPurchaseKey = (ticket: PossessionsTicket["tickets"][number] | PossessionsOrderItem["order_items"][number] | 
      PossessionsDeliveryTicket["delivery_tickets"][number] | PossessionsExpTicket["sg_tickets"][number], payment: PaymentSummary): string => {

      let method: string = "";

      // 請求先名(key)をセット
      // NOTE: 請求先がカードの場合、CARD + cardseqをkeyとしている
      if (ticket.purchase.method === 'CARD') {
        // カード識別子(card_seq)が存在しない場合、「CARD + カード番号(card_no)」をkeyとする
        method = ticket.purchase.card_seq  ? ticket.purchase.method + ticket.purchase.card_seq : ticket.purchase.method + ticket.purchase.card_no.slice(-4);
      }
      else {
        method = ticket.purchase.method === 'CARD' ? ticket.purchase.method + ticket.purchase.card_seq : ticket.purchase.method;
      }
      

      // 対象購入物の支払い方法がpurchaseのkeyに存在しなかった場合、作成
      if (!Object.keys(purchase).includes(method)) {
        purchase[method] = {purchase: ticket.purchase, payment: payment, total: 0, detail: []};
      }

      return method;
    }

    // 配車情報
    tickets.tickets.forEach(ticket => {

      if (checkPurchase(ticket) == false) return;
      const method = getPurchaseKey(ticket, tickets.payment);

      purchase[method].detail.push({
        date: tickets.payment.rule === 0 ? ticket.purchase.date : ticket.purchase.date_sales, 
        name: this.getDispTicketName(ticket), 
        amount: ticket.amount
      });

      purchase[method].total += ticket.amount;
    });

    /**
     * 注文、配送料マージ用
     */
    let array: common.DispPurchase["detail"] = [];

    // 注文情報
    order.order_items.forEach(item => {
      if (checkPurchase(item) == false) return;

      const method = getPurchaseKey(item, order.payment);

      array.push({
        date: order.payment.rule === 0 ? item.purchase.date : item.purchase.date_sales, 
        name: item.menu.name, 
        amount: item.amount, 
        order_id: item.order_id,
        method: method
      });
    });

    // 各注文の最後に送料を挿入するため反転
    array.reverse();

    // 送料
    charges.delivery_tickets.forEach(delivery_ticket => {

      if (checkPurchase(delivery_ticket) == false) return;

      const method = getPurchaseKey(delivery_ticket, charges.payment);

      const index = array.findIndex(d => d.order_id === delivery_ticket.order_id);
      const delivery = {
        date: charges.payment.rule === 0 ? delivery_ticket.purchase.date : delivery_ticket.purchase.date_sales,
        name: "配送料", 
        amount: delivery_ticket.amount, 
        order_id: delivery_ticket.order_id,
        method: method
      };

      array.splice(index, 0, delivery);
    })
    // 反転した配列を元に戻す
    array.reverse();

    // 注文, 送料情報
    array.forEach(item => {
      purchase[item.method].detail.push(item);
      purchase[item.method].total += item.amount;
    });

    // 観光サービス情報
    exp.sg_tickets.forEach(ticket => {
      
      if (checkPurchase(ticket) == false) return;

      const method = getPurchaseKey(ticket, exp.payment);

      purchase[method].detail.push({
        date: tickets.payment.rule === 0 ? ticket.purchase.date : ticket.purchase.date_sales, 
        name: (ticket.service ? ticket.service.name :  
               ticket.option ? ticket.option.name  : '') + ' ' + ticket.title.replace("\xA5", "￥"),
        amount: ticket.amount
      });

      purchase[method].total += ticket.amount;
    });

    // 各請求先、請求明細配列をソート
    Object.keys(purchase).forEach(item => {
      purchase[item].detail.sort((a, b) => {
        if (new Date(a.date).getTime() < new Date(b.date).getTime()) return -1;
        else if (new Date(a.date).getTime() > new Date(b.date).getTime()) return 1;
        else return 0;
      });
    })

    return purchase;
  };

  //=============================================================================================
  // private
  //=============================================================================================

  /**
   * 表示用のチケット名称を取得する
   * @param ticket 
   * @returns 
   */
  private getDispTicketName(ticket: Ticket): string {

    let ticketName: string = "";
    
    // 1回乗車券のキャンセル手数料
    if (ticket.deleted && ticket.ticket_type === 1) ticketName += "キャンセル手数料：";
    ticketName += ticket.ticket_name;

    // プラン名が設定されている場合は追加
    if (ticket.plan != undefined && ticket.plan != "") ticketName += "（" + ticket.plan + "）";

    // オペレータ操作明細
    if (ticket.ticket_type === 9) ticketName = "その他（" + ticket.note.trim() + "）";

    return ticketName;
  }

  /**
   * 照会年月の配車明細を取得する。
   *
   * @private
   * @param {parameter.PossessionsTicket} params
   * @return {*}  {Observable<any>}
   * @memberof PurchaseWebApiService
   */
  private getPurchaseData(params: parameter.PossessionsTicket): Observable<any> {  // 正確な返り値の型は Subject<PossessionsTicket>

    const retSubject: Subject<any> = new Subject();

    // 指定年月の請求額を取得
    this.getPurchaseDataList<PossessionsTicket>(params).subscribe({
      next: res => {
        let possTicket: PossessionsTicket = res.body;

        retSubject.next(possTicket);
        retSubject.complete();
      },
      error: (err) => {
        retSubject.error(err);
      }
    });

    return retSubject;
  }

  /**
   * 照会年月の注文明細を取得する。
   *
   * @private
   * @param {parameter.PossessionsTicket} params
   * @return {*}  {Observable<any>}
   * @memberof PurchaseWebApiService
   */
  private getOrderItemData(params: parameter.PossessionsTicket): Observable<any> {  // 正確な返り値の型はSubject<PossessionsOrderItem>

    const retSubject: Subject<any> = new Subject();

    // 指定年月の請求額を取得
    this.getOrderItem(params).subscribe({
      next: res => {
        let orderTicket: PossessionsOrderItem = res.body;

        retSubject.next(orderTicket);
        retSubject.complete();      
      },
      error: (err) => {
        retSubject.error(err);
      }
    });

    return retSubject;
  }

  /**
   * 照会年月の送料明細を取得する。
   *
   * @private
   * @param {parameter.PossessionsTicket} params
   * @return {*}  {Observable<any>}
   * @memberof PurchaseWebApiService
   */
  private getDeliveryTicketData(params: parameter.PossessionsTicket): Observable<any> {  // 正確な返り値の型はSubject<PossessionsDeliveryTicket>
    
    const retSubject: Subject<any> = new Subject();

    // 指定年月の請求額を取得
    this.getDeliveryTicket(params).subscribe({
      next: res => {
        let deliveryTicket: PossessionsDeliveryTicket = res.body;

        retSubject.next(deliveryTicket);
        retSubject.complete();
      },
      error: (err) => {
        retSubject.error(err);
      }
    });

    return retSubject;
  }

  /**
   * 照会年月の体験サービス明細を取得する。
   *
   * @private
   * @param {parameter.PossessionsTicket} params
   * @return {*}  {Observable<any>}
   * @memberof PurchaseWebApiService
   */
  private getExpTicketData(params: parameter.PossessionsTicket): Observable<any> {
    
    const retSubject: Subject<any> = new Subject();

    this.getExpTicket(params).subscribe({
      next: res => {
        let expTicket: PossessionsExpTicket = res.body;

        expTicket.sg_tickets.forEach(t => t.title)

        retSubject.next(expTicket);
        retSubject.complete();
      },
      error: (err) => {
        retSubject.error(err);
      }
    });

    return retSubject;
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * 所有乗車券一覧を取得する。
   *
   * @template T
   * @param {parameter.PossessionsTicket} params リクエストパラメータ
   * @return {*}  {Observable<HttpResponse<T>>}
   * @memberof PurchaseWebApiService
   */
  public getPurchaseDataList<T>(params: parameter.PossessionsTicket): Observable<HttpResponse<T>> {

    return this.http.get<T>(`${this.config.baseUrl}/possessions/ticket?${qs.stringify(params)}`, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }
  
  /**
   * 自動継続購入を有効/無効にする。
   *
   * @param {*} ticketInfo 自動継続購入の設定内容。
   * @return {*}  {Observable<HttpResponse<any>>} レスポンスの受信を監視するための `Observable`。
   * @memberof PurchaseWebApiService
   */
  public updateContinuous(ticket_id: number, continuous : boolean): Observable<HttpResponse<any>> {

    return this.http.put(`${this.config.baseUrl}/ticket`, { ticket_id: ticket_id, continue: continuous }, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }

  /**
   * 定期券/日数券の金額を取得する。
   *
   * @param {*} ticketInfo ユーザー情報、利用開始日。
   * @return {*}  {Observable<HttpResponse<TicketCatalog>>} レスポンスの受信を監視するための `Observable`。
   * @memberof PurchaseWebApiService
   */
  public getTicketList(user_id: string, start_date: string): Observable<HttpResponse<TicketCatalog[]>> {

    return this.http.post<TicketCatalog[]>(`${this.config.baseUrl}/ticket/list`, { user_id: user_id, start_date: start_date }, {
        ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }

  /**
   * チケット(定期券/N日券)を購入する。
   *
   * @param {*} ticketInfo ユーザーID、利用開始日、カタログID、自動更新設定。
   * @return {*}  {Observable<HttpResponse<any>>} レスポンスの受信を監視するための `Observable`。
   * @memberof PurchaseWebApiService
   */
  public buyTicket(ticketInfo: any): Observable<HttpResponse<any>> {

    return this.http.post(`${this.config.baseUrl}/ticket`, ticketInfo, {
        ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }
  
  /**
   * 注文明細を取得する。
   *
   * @param {parameter.PossessionsTicket} params
   * @return {*}  {Observable<HttpResponse<PossessionsOrderItem>>}
   * @memberof PurchaseWebApiService
   */
  public getOrderItem(params: parameter.PossessionsTicket): Observable<HttpResponse<PossessionsOrderItem>> {

    return this.http.get<PossessionsOrderItem>(`${this.config.baseUrl}/possessions/order-item?${qs.stringify(params)}`, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }
  
  /**
   * 注文送料の明細を取得する。
   *
   * @param {parameter.PossessionsTicket} params
   * @return {*}  {Observable<HttpResponse<PossessionsDeliveryTicket>>}
   * @memberof PurchaseWebApiService
   */
  public getDeliveryTicket(params: parameter.PossessionsTicket): Observable<HttpResponse<PossessionsDeliveryTicket>> {

    return this.http.get<PossessionsDeliveryTicket>(`${this.config.baseUrl}/possessions/delivery-ticket?${qs.stringify(params)}`, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }

  /**
   * 体験サービスの明細を取得する。
   *
   * @param {parameter.PossessionsTicket} params
   * @return {*}  {Observable<HttpResponse<PossessionsExpTicket>>}
   * @memberof PurchaseWebApiService
   */
  getExpTicket(params: parameter.PossessionsTicket): Observable<HttpResponse<PossessionsExpTicket>> {

    return this.http.get<PossessionsExpTicket>(`${this.config.baseUrl}/possessions/sg-ticket?${qs.stringify(params)}`, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }
}
