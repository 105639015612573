<div class="swiper-area">
  <swiper #swiper *ngIf="inputData.elements.length > 0"
  [slidesPerView]="inputData.slides_per_view" [spaceBetween]="10" [navigation]="true"
  [controller]="{ control: controlledSwiper }"
  >
  <!-- [pagination]="{ clickable: true }" -->
    <ng-container *ngFor="let image of inputData.elements">
      <ng-template swiperSlide>
        <div class="image--background image-center" (click)="onClickImage(image.key)">
          <ng-container *ngIf="image.image; else noPhoto">
            <img class="image--size" [src]="image.image">
          </ng-container>
          <!-- template -->
          <ng-template #noPhoto>
              <img class="image--size" [src]="noImage">
          </ng-template>
        </div>
        <div class="image-title">
          <span *ngIf="image.title">{{image.title}}</span>
        </div>
      </ng-template>
    </ng-container>
  </swiper>
</div>