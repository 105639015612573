<ons-toolbar class="toolbar">
  <div class="left"><ons-back-button></ons-back-button></div>
  <div class="center">アカウント削除</div>
</ons-toolbar>
<div class="content">
  <div [ngBusy]="busy"></div>
  <div class="list-item__title content-text-wrap__title" style="padding: 10px 5px;">削除前に、以下の内容をご確認ください。</div>
  <ons-list>
    <ons-list-item class="list-item_text">
      <div class="content-text-wrap">
        <div class="content-text-wrap__indentback1 content-text-wrap__padding-top">
          <p>
            ・一度アカウント削除したら再ログインはできません。
          </p>
        </div>
        <div class="content-text-wrap__indentback1 content-text-wrap__padding-top">
          <p>
            ・アカウント削除を行うと、本アカウントに関連付けられたSmartGOTOのデータは削除されます。
          </p>
        </div>
        <div class="content-text-wrap__indentback1 content-text-wrap__padding-top">
          <p>
            ・他の自治体でもご利用中の場合、他の自治体でも同様にアカウント削除となります。
          </p>
        </div>
        <div class="content-text-wrap__indentback1 content-text-wrap__padding-top">
          <p>
            ・以下のいずれかに該当する場合、アカウント削除できません。
          </p>
        </div>
        <div class="content-text-wrap__indentback2">
          <p>
            ①ご自身が利用または支払いを行う(※)、当日以降の各種サービス予約がある。
          </p>
        </div>
        <div class="content-text-wrap__indentback3">
          <p>
            ※ご自身がファミリー管理者の場合、ファミリーメンバー利用分もこれに含まれます。
          </p>
        </div>
        <div class="content-text-wrap__indentback2">
          <p>
            ②ご自身が利用または支払いを行う、当日以降の定期配車予約設定がある。
          </p>
        </div>
        <div class="content-text-wrap__indentback2">
          <p>
            ③ご自身が利用または支払いを行う、継続購入設定ONの定額乗車券を保有している。
          </p>
        </div>
        <div class="content-text-wrap__indentback2">
          <p>
            ④ご自身が利用または支払いを行う、当日以降に利用終了日を迎える定額乗車券を保有している。
          </p>
        </div>
        <div class="content-text-wrap__indentback2">
          <p>
            ⑤ファミリーリンクが残っている。
          </p>
        </div>
        <div class="content-text-wrap__indentback1 content-text-wrap__padding-top content-text-wrap__padding-bottom">
          <p>
            ・削除手続きを行っても削除できず、削除できない理由が不明な場合、{{office.office_name}}({{office.tel}})にお問い合わせください。
          </p>
        </div>
      </div>
    </ons-list-item>
  </ons-list>
  <div class="content-text-wrap__check">
    <ons-checkbox [(ngModel)]="checked" class="left" input-id="check"></ons-checkbox>
    <label class="content--text--small" for="check">上記の内容に同意する</label>
  </div>
  <div class="button-wrap">
    <ons-button modifier="large--cta noFixed" (click)="pushWithdrawalButton()"
      [disabled]="!checked">アカウント削除する</ons-button>
  </div>
</div>