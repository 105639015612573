<ons-toolbar class="toolbar">
    <div class="center">マイページ</div>
</ons-toolbar>
<div class="content">
    <div [ngBusy]="busy"></div>
    <ons-list class="accountMenu-list">
        <ons-list-header class="list-item__title">全自治体共通情報</ons-list-header>
        <ons-list-item id="account-list_001" modifier="chevron longdivider" class="accountMenu" tappable (click)="pushProfile()">
            <img class="list-icon" [src]="ASSETS.PROFILE" />
            <p class="list_text">プロフィール</p>
        </ons-list-item>
        <ons-list-item id="account-list_005" modifier="chevron longdivider" class="accountMenu" tappable (click)="pushFamily()" *ngIf="isVisibleFamily">
            <img class="list-icon" [src]="ASSETS.FAMILY" />
            <p class="list_text">ファミリー</p>
            <div class="tabbar__badge notification">{{ familyBadge }}</div>
        </ons-list-item>

        <ons-list-item modifier="chevron longdivider" class="accountMenu" tappable (click)="pushPincodeAuthMethod()">
            <img class="list-icon" [src]="ASSETS.AUTH_METHOD" />
            <p class="list_text">二段階認証方法</p>
        </ons-list-item>
        <ons-list-item modifier="chevron longdivider" class="accountMenu" tappable (click)="pushOtherMenus()">
            <img class="list-icon" [src]="ASSETS.OTHER" />
            <p class="list_text">その他</p>
        </ons-list-item>
    </ons-list>

    <ons-list class="accountMenu-list">
        <ons-list-header class="list-item__title" style="padding-top: 5px;">{{areaName}}用情報</ons-list-header>
        <ons-list-item id="account-list_002" modifier="chevron longdivider" class="accountMenu" tappable (click)="pushMyspotComponent()" *ngIf="isVisibleMyspot">
            <img class="list-icon" [src]="ASSETS.MYSPOT" />
            <p class="list_text">マイスポット</p>
        </ons-list-item>
        <ons-list-item *ngIf="isProvideDispatch" id="account-list_003" modifier="chevron longdivider" class="accountMenu" tappable (click)="pushPurchaseTicket()"> <!-- 数日乗車券非表示 -->
            <img class="list-icon" [src]="ASSETS.TICKET" />
            <p class="list_text">{{ purchaseName }}</p>
        </ons-list-item>
        <ons-list-item id="account-list_004" modifier="chevron longdivider" class="accountMenu" tappable (click)="pushPurchaseDetails()">
            <img class="list-icon" [src]="ASSETS.PURCHASE" />
            <p class="list_text">請求明細</p>
        </ons-list-item>
        <ons-list-item id="account-list_006" modifier="chevron longdivider" class="accountMenu" tappable (click)="pushPaymentMethod()">
            <img class="list-icon" [src]="ASSETS.PAYMENT" />
            <p class="list_text">支払い方法</p>
            <div class="tabbar__badge notification">{{ paymentBadge }}</div>
        </ons-list-item>
    </ons-list>
    <div class="button-wrap">
        <ons-button id="account-list_008" class="button button--large--cta" (click)="logout()">
            <div class="title">ログアウト</div>
        </ons-button>
    </div>
</div>
