<ons-toolbar>
  <div class="left">
    <ons-back-button></ons-back-button>
  </div>
  <div *ngIf="m_ConfirmMode === 'family'" class="center">ファミリー承認</div>
  <div *ngIf="m_ConfirmMode === 'login'" class="center">ログイン許可</div>
</ons-toolbar>
<div class="content">
  <div [ngBusy]="m_Busy"></div>

  <!-- ファミリーリンク -->
  <div *ngIf="m_ConfirmMode === 'family'" class="content--description">
    「{{ m_Relation.name.family_name }} {{ m_Relation.name.given_name }}」さんよりファミリーリンク申請が届いています。<br>
    申請を許可しますか？<br>
    <br>
    ファミリーリンクの設定により、できるようになることは
    <ons-button id="family-confirm_001" modifier="quiet" (click)="viewDescription()"><span class="button-text">こちら</span></ons-button>をご覧ください。

    <ons-list>
      <ons-list-item class="list-item_text">
        <div class="content-text-wrap">
          <p>
            申請を許可すると、管理者にあなたの以下の個人情報を開示します。
          </p>
          <p class="content-text-wrap__padding-top">
            ・氏名
          </p>
          <p>・生年月日</p>
          <p>・性別</p>
          <p>・各種サービス予約履歴</p>
          <p>・購入履歴</p>
          <p class="content-text-wrap__padding-top">
            <input id="invite-check" type="checkbox" #checkbox><label class="content-text-wrap__checkbox" for="invite-check">上記の個人情報開示に同意する</label>
          </p>
        </div>
      </ons-list-item>
    </ons-list>
    
    <div class="button-wrap--harf button-wrap--flex">
      <ons-button id="family-confirm_002" class="button button--large--cta button--cancel" (click)="reject()">却下</ons-button>
      <ons-button id="family-confirm_003" class="button button--large--cta" (click)="approval()" [disabled]="!checkbox.checked">許可</ons-button>
    </div>  
  </div>

  <!-- 初回ログイン -->
  <div *ngIf="m_ConfirmMode === 'login'">
    <ons-list class="content--list--name">
      <ons-list-item modifier="longdivider" class="list-item__text">{{ m_Relation.name.family_name }} {{ m_Relation.name.given_name }}</ons-list-item>
    </ons-list>
    <div class="content--description">
      見守り対象アカウントをログイン可能なユーザとする場合は、初回ログイン用にワンタイムパスワードを発行してください。<br>
      <br>
      なお、初回ログイン後はサービスに関する通知は本人に届くようになります。
      <div class="button-wrap">
        <ons-button id="family-confirm_004" class="button button--large--cta" (click)="approval()">パスワード発行</ons-button>
      </div>
    </div>
  </div>

</div>