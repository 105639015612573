import { ModuleWithProviders, NgModule } from '@angular/core';
import { FavoriteWebApiServiceConfig } from '../http-services/favorite-web-api.service';

/**
 * ユーティリティサービス (マイスポット関連) に依存性を注入するためのインジェクターを構成するモジュール。
 *
 * @export
 * @class FavoriteWebApiModule
 */
@NgModule()
export class FavoriteWebApiModule {
  /**
   * DI トークンと依存性の関連付けを行う。
   *
   * @static
   * @param {FavoriteWebApiServiceConfig} config Web API (マイスポット関連) の構成設定。
   * @return {*}  {ModuleWithProviders<FavoriteWebApiModule>} `ModuleWithProviders<FavoriteWebApiModule>`
   * @memberof FavoriteWebApiModule
   */
  static withConfig(config: FavoriteWebApiServiceConfig): ModuleWithProviders<FavoriteWebApiModule> {
    return {
      ngModule: FavoriteWebApiModule,
      providers: [
        { provide: FavoriteWebApiServiceConfig, useValue: config }
      ]
    };
  }
}