<ons-toolbar>
  <div class="left">
    <button *ngIf="fromExternalLink && showBackButton" (click)="onBack()" class="custom-back-button"></button>
    <ons-back-button *ngIf="!fromExternalLink && !showBackButton" #onsBackButton></ons-back-button>
  </div>
  <div class="center">観光サービス一覧</div>
</ons-toolbar>

<div [ngBusy]="busy"></div>
<div class="content">
  <!-- 検索フォーム -->
  <parts-search-form *ngIf="showSearchForm" [freeword]="freeword" target="exp" [limit]="limit" (searchService)="onSearch($event)"></parts-search-form>

  <!--期間検索-->
  <ng-container *ngIf="showPeriodSearchForm && !isSearchResultEmpty">
    <div class="search-container">
    <ng-template #periodSearch>
      <div class="search-box">
        <div class="datetime-wrapper">
          <div class="datetime-label">出発日時</div>
          <div class="datetime-picker" [ngClass]="{'null-value': checkStartDateTime()}">
            <div (click)="startDate.open()" class="date-picker__input">
                <span class="date" *ngIf="this.periodSearchCondition?.start?.date !== ''; else noStartDate">{{moment(this.periodSearchCondition.start.date).format('YYYY/MM/DD (ddd)') }}</span>
                <mat-datepicker #startDate hidden></mat-datepicker>
                <button class="mat-icon__size" mat-icon-button>
                  <span class="material-icons calendar-icon">calendar_month</span>
                </button>
                <input matInput [matDatepicker]="startDate" [min]="datePickerRange.start" [max]="datePickerRange.end" (dateChange)="onChangePeriodDate($event, 'start')"  hidden>
            </div>
            <div [matMenuTriggerFor]="startTimeMenu" class="time-picker__input">
              <span class="time" *ngIf="this.periodSearchCondition.start.time.view !== ''; else noTime">{{this.periodSearchCondition.start.time.view}}</span>
              <p>
                <button class="mat-icon__size" mat-icon-button>
                  <span class="material-icons">
                    arrow_drop_down
                  </span>
                </button>
                <mat-menu #startTimeMenu="matMenu" xPosition="before">
                  <ng-container *ngFor="let item of hourMinuteList; index as i">
                    <button class="mat-menu-item__height" mat-menu-item (click)="onChangePeriodTime(item, 'start')">
                      {{item.view}}
                    </button>
                  </ng-container>
                </mat-menu>
              </p>
            </div>
          </div>
        </div>
        
        <div class="datetime-wrapper">
          <div class="datetime-label">返却日時</div>
          <div class="datetime-picker" [ngClass]="{'null-value': checkEndDateTime()}">
              <div (click)="endDate.open()" class="date-picker__input">
                <span class="date" *ngIf="this.periodSearchCondition?.end?.date !== ''; else noEndDate">{{moment(this.periodSearchCondition.end.date).format('YYYY/MM/DD (ddd)')}}</span>
                <mat-datepicker #endDate [startAt]="periodSearchCondition.start.date" hidden></mat-datepicker>
                <button class="mat-icon__size" mat-icon-button>
                  <span class="material-icons calendar-icon">calendar_month</span>
                </button>
                <input matInput [matDatepicker]="endDate" [min]="datePickerRange.start" [max]="datePickerRange.end" (dateChange)="onChangePeriodDate($event, 'end')" hidden>
              </div>
              <div [matMenuTriggerFor]="endTimeMenu" class="time-picker__input">
                <span class="time" *ngIf="this.periodSearchCondition.end.time.view !== ''; else noTime">{{ this.periodSearchCondition.end.time.view }}</span>
                <p>
                  <button class="mat-icon__size" mat-icon-button>
                    <span class="material-icons">
                      arrow_drop_down
                    </span>
                  </button>
                  <mat-menu #endTimeMenu="matMenu" xPosition="before">
                    <ng-container *ngFor="let item of hourMinuteList; index as i">
                      <button class="mat-menu-item__height" mat-menu-item (click)="onChangePeriodTime(item, 'end')">
                        {{item.view}}
                      </button>
                    </ng-container>
                  </mat-menu>
                </p>
              </div>
          </div>
          <button class="search-button mat-icon__size" (click)="onClickPeriodSearch()" [disabled]="isSearchButtonDisabled">
            <span class="material-icons" style="color: white;">search</span>
          </button>
        </div>
      </div>
    </ng-template>
    <ng-container *ngTemplateOutlet="periodSearch"></ng-container>
    </div>
  </ng-container>

  <!--利用日検索-->
  <ng-container *ngIf="showUsageDateSearchForm && !isSearchResultEmpty">
    <div class="search-container">
    <ng-template #usageDateTemplate>
      <div class="search-box">
        <div class="datetime-wrapper">
          <div class="datetime-label">利用日</div>
          <div class="datetime-picker" [ngClass]="{'null-value': isUsageDateSelected()}">
            <div (click)="startDate.open()" class="date-picker__input">
                <span class="date" *ngIf="this.usageDate !== ''; else noStartDate">{{moment(this.usageDate).format('YYYY/MM/DD (ddd)')}}</span>
                <mat-datepicker #startDate hidden></mat-datepicker>
                <button class="mat-icon__size" mat-icon-button>
                  <span class="material-icons calendar-icon">calendar_month</span>
                </button>
                <input matInput [matDatepicker]="startDate" [min]="datePickerRange.start" [max]="datePickerRange.end" (dateChange)="onChangeUsageDate($event, 'start')"  hidden>
            </div>
          </div>
          <button class="search-button mat-icon__size" (click)="onClickUsageDateSearch()" [disabled]="isSearchButtonDisabled">
            <span class="material-icons" style="color: white;">search</span>
          </button>
        </div>
      </div>
    </ng-template>
    <ng-container *ngTemplateOutlet="usageDateTemplate"></ng-container>
    </div>
  </ng-container>

  <!-- サービスの検索結果一覧 -->
   <div class="search-result">
     <ng-container *ngFor="let list of listParts">
       <parts-ons-list [listInfo]="list" (partsEvent)="onClickList($event)"></parts-ons-list>
     </ng-container>
     <ng-container *ngIf="listParts === undefined">
       <div class="text--center" *ngIf="freeword !== undefined; else noService">
         <p *ngIf="showSearchForm; else notFoundChangeCondition">
           検索条件にヒットするサービスはありませんでした。
         </p>
         <ng-template #notFoundChangeCondition>
           <p>お探しの条件に一致するサービスはありませんでした。検索条件を変えて再度検索してください。</p>
         </ng-template>
         <parts-image class="complete-wrap__img" url="/image/character/notfound.png"></parts-image>
       </div>
     </ng-container>
   </div>

  <!-- template -->
  <!-- 提供中のサービスがない -->
  <ng-template #noService>
    <div class="text--center" *ngIf="listParts !== undefined">
      <p>現在、商品提供中のサービスはありません。<br>提供開始までしばらくお待ちください。</p>
      <parts-image class="complete-wrap__img" url="/image/character/out-of-service.png"></parts-image>
    </div>
  </ng-template>
</div>

<ng-template class="no-date" #noStartDate>年/月/日(曜日)</ng-template>

<ng-template class="no-date" #noEndDate>年/月/日(曜日)</ng-template>

<ng-template #noTime>--:--</ng-template>

<ng-template #onlyOne><span>&nbsp;a</span></ng-template>

<ng-template #noItem><mat-icon >remove</mat-icon></ng-template>