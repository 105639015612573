//=============================================================================================
// インポート
//=============================================================================================
import { Component } from '@angular/core';
import { Params } from 'ngx-onsenui';

// service
import { MunicipalityWebApiService } from "../../../http-services/municipality-web-api.service";

// interface
import { WeatherDisasterPreventionRelateSite } from "../../../interfaces/response/disaster-prevention-sites";

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 気象・防災関連情報一覧
 *
 * @export 
 * @class DisasterPreventionRelateSiteListComponent
 */
@Component({
    selector: 'ons-page[disaster-prevention-relate-site-list]',
    templateUrl: './disaster-prevention-relate-site-list.component.html',
    styleUrls: ['./disaster-prevention-relate-site-list.component.scss']
})
export class DisasterPreventionRelateSiteListComponent {

    /**
     * 各自治体の気象・防災関連情報
     *
     * @type {WeatherDisasterPreventionRelateSite}
     * @memberof DisasterPreventionRelateSiteListComponent
     */
    disasterSiteList: WeatherDisasterPreventionRelateSite[] = [];

    /**
     * Creates an instance of DisasterPreventionRelateSiteListComponent.
     * @param {Params} params
     * @memberof DisasterPreventionRelateSiteListComponent
     */
    constructor(
        private params: Params,
        private municipalityWebApiServ: MunicipalityWebApiService
    ) { }

    ngOnInit(): void {

        // 気象・防災関連情報を取得
        this.disasterSiteList = this.municipalityWebApiServ.getDisasterPreventionSites();
    }

    /**
     * 気象・防災関連情報を表示
     *
     * @memberof DisasterPreventionRelateSiteListComponent
     */
    viewDisasterSite(siteUrl): void {
        window.open(siteUrl, '_blank');
    }
}