//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit, OnDestroy } from '@angular/core';
import { OnsNavigator } from 'ngx-onsenui';
import { fromEvent, Subscription } from 'rxjs';

// service
import { ApplicationMessageService } from '../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../lib-services/http-error-response-parser.service';
import { SqrcWebApiService } from '../../http-services/sqrc-web-api.service';
import { UserWebApiService } from '../../http-services/user-web-api.service';
import { FamilyWebApiService } from '../../http-services/family-web-api.service';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';

// component
import { MyqrIssueComponent, ISSUE_MODE } from './myqr-issue/myqr-issue.component';
import { TabbarComponent } from '../tabbar/tabbar.component';

// interface
import { common } from '../../interfaces/common';
import { UserInfo, Relation } from '../../interfaces/response';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * QRコード画面
 *
 * @export
 * @class MyqrComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ons-page[myqr]',
  templateUrl: './myqr.component.html',
  styleUrls: ['./myqr.component.css']
})

export class MyqrComponent implements OnInit, OnDestroy {

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * ユーザ情報変更監視subscription
   *
   * @private
   * @type {Subscription}
   * @memberof MyqrComponent
   */
  private onUserInfoChanged: Subscription;

  /**
   * QR取得のsubscriptionオブジェクト
   *
   * @type {Subscription}
   * @memberof MyqrComponent
   */
  busy: Subscription;
  
  /**
   * ログイン有無
   *
   * @type {boolean}
   * @memberof MyqrComponent
   */
  isLoggedIn: boolean;
  
  /**
   * QRコードjson
   *
   * @type {string}
   * @memberof MyqrComponent
   */
  imgSrc: object = {};

  /**
   * 選択中の利用者（ユーザID)
   *
   * @type {string}
   * @memberof MyqrComponent
   */
  selectedUserId: string;
  
  /**
   * 利用者情報セレクトボックス
   *
   * @type {common.selectBox[]}
   * @memberof MyqrComponent
   */
  userInfos: common.selectBox[] = [];

  /**
   * 見守り対象アカウントであるかないか
   *
   * @type {boolean}
   * @memberof MyqrComponent
   */
  isAlt: boolean = false;

  /**
   * QRタブタブタップ時の再読み込み用subscription
   *
   * @type {Subscription}
   * @memberof MyqrComponent
   */
  myqrTabClickSubscription: Subscription;

  /**
   * QR画像(dataURL形式)
   *
   * @type {string}
   * @memberof MyqrComponent
   */
  qrImage: string;

  /**
   * 利用者カード発行機能を表示するかどうか
   *
   * @type {boolean}
   * @memberof MyqrComponent
   */
  isDisplayIssueCard: boolean;

  /**
   * OnDestroy時に破棄するSubscriptionオブジェクト
   *
   * @memberof MyqrComponent
   */
  subscription = new Subscription();

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of MyqrComponent.
   * @param {OnsNavigator} navigator
   * @param {ApplicationMessageService} appMsg
   * @param {HttpErrorResponseParserService} errResServ
   * @param {SqrcWebApiService} sqrcServ
   * @param {UserWebApiService} userServ
   * @param {FamilyWebApiService} familyServ
   * @memberof MyqrComponent
   */
  constructor(
    private navigator: OnsNavigator, 
    private appMsg: ApplicationMessageService, 
    private errResServ: HttpErrorResponseParserService, 
    private sqrcServ: SqrcWebApiService, 
    private userServ: UserWebApiService, 
    private familyServ: FamilyWebApiService,
    private tabbarComp: TabbarComponent,
    private municipalityWebApiServ: MunicipalityWebApiService,
  ) {
    const settingsChanged = this.municipalityWebApiServ.settingsChanged.subscribe({
      next: setting => {
        if (setting == null) return;
        this.isDisplayIssueCard = setting.qr_issue;
      }
    });
    this.subscription.add(settingsChanged);
  }

  /**
   * 初期化処理。
   *
   * @memberof MyqrComponent
   */
  ngOnInit(): void {

    // ログイン状態を保存
    this.isLoggedIn = this.userServ.isLoggedIn();
  }

  /**
   * ビュー初期化後処理。
   *
   * @memberof MyqrComponent
   */
  ngAfterViewInit(): void {

    // ログイン状態を監視しログアウト時、データを初期化
    this.onUserInfoChanged = this.userServ.userInfoChanged.subscribe({
      next: () => {
        // ログイン状態を保存
        this.isLoggedIn = this.userServ.isLoggedIn();

        // ログアウト時、データを初期化
        if (!this.isLoggedIn) {
          // 初期化
          this.initData();
        }
      },
      error: () => {
        // 初期化
        this.initData();
      }
    });

    // QRタブが選択されたイベントを監視し、表示用データの取得を行う
    this.myqrTabClickSubscription = fromEvent(this.tabbarComp.myqrTab.nativeElement, 'click').subscribe(() => {

      // ログイン状態を取得
      this.isLoggedIn = this.userServ.isLoggedIn();

      // ログイン状態
      if (this.isLoggedIn) {
        // QRトップ画面の場合、ファミリー情報再取得
        if (this.navigator.element.pages.length === 1) {
          // 未ログイン(セレクトボックス未作成)からこの画面に初めて来た場合
          if (this.userInfos?.length == 0) {
            // ユーザ情報を更新
            this.setUserInfo(this.userServ.getUserInfo());
          }

          // 最新ファミリー情報取得
          this.busy = this.familyServ.getFamily().subscribe({
            next: res => {
              // ファミリー情報を更新
              this.setFamilyInfo(res.body);

              // 選択中ユーザがファミリーに存在しない
              if (!this.userInfos.some(e => e.value == this.selectedUserId)) {
                // 選択中ユーザをログインユーザに変更
                this.selectedUserId = <string>this.userInfos[0].value;
                this.isAlt = false;
              }

              // QRデータを初期化
              this.initData(true);

              // 選択中ユーザ、最新のQRを取得
              this.setUserQrCode(this.selectedUserId);
            },
            error: this.errResServ.doParse((_err, errContent) => this.errResServ.viewErrDialog(errContent))
          });
        }
      }
      // 未ログイン状態
      else {
        // 初期化
        this.initData();
      }
    });
  }

  /**
   * 破棄処理。
   *
   * @memberof MyqrComponent
   */
  ngOnDestroy(): void 
  {
    [this.onUserInfoChanged, this.busy, this.myqrTabClickSubscription].forEach(s => 
      {
        s?.unsubscribe();
      });
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 利用者セレクトボックス変更時のイベントハンドラ。
   *
   * @memberof MyqrComponent
   */
  changeCustomer(): void {
    // 選択中ユーザのQRコードが未取得の場合、取得
    if (undefined == this.imgSrc[this.selectedUserId]) {
      this.setUserQrCode(this.selectedUserId);
    }

    // note: 選択中ユーザが見守り対象アカウントでない場合、「利用者カードを発行したい場合」を表示しない
    // 選択中ユーザが見守り対象アカウントかどうか判定
    // ログインユーザー選択中
    if(this.userInfos[0].value == this.selectedUserId) this.isAlt = false;
    // ファミリーメンバー選択中の場合、見守りアカウントかどうか判定
    else this.isAlt = this.familyServ.getFamilyInfo().find(u => u.user_id == this.selectedUserId).is_alt;
  }

  /**
   * QR関連発行画面(利用者カード発行)へ遷移
   *
   * @memberof MyqrComponent
   */
  onClickIssueUserCard(): void {
    this.navigator.element.pushPage(MyqrIssueComponent, {
      data: {
        user_id: this.selectedUserId,
        mode: ISSUE_MODE.user_card
      }
    });
  }

  /**
   * QR関連発行画面（QR再発行）へ遷移
   *
   * @memberof MyqrComponent
   */
  onClickReissueQr(): void {
    this.navigator.element.pushPage(MyqrIssueComponent, { 
      data: {
        user_id: this.selectedUserId,
        // QR再発行APIレスポンスのデータを、表示QRに上書き(再発行画面からQRトップ画面に戻ってきた際に呼ばれる想定)
        setQr: (reissueQrCode: string) => {
          this.imgSrc[this.selectedUserId] = reissueQrCode;
        },
        mode: ISSUE_MODE.reissue
      }
    });
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * ログインユーザ情報を格納する。
   *
   * @private
   * @param {UserInfo} userInfo
   * @memberof MyqrComponent
   */
  private setUserInfo(userInfo : UserInfo): void {
    
    // 引数のユーザ情報がnullの場合、未ログイン状態
    this.isLoggedIn = userInfo !== null;
    // QRの初期表示としてログインユーザIDを保存
    this.selectedUserId = userInfo?.user_id;

    // ユーザ情報が取得済み（ログイン済み）
    if (userInfo) {
      // ログインユーザをセレクトボックスの1番目とする
      this.userInfos[0] = {
          value: userInfo.user_id, 
          label: userInfo.profile.name.family_name + " " + userInfo.profile.name.given_name + " (ID：" + userInfo.user_id + ")",
      };
    }
  }

  /**
   * ファミリー情報を格納する。
   *  ログインユーザ以外を削除し、ファミリー情報再設定。
   *
   * @param {Relation[]} family
   * @memberof MyqrComponent
   */
  private setFamilyInfo(family: Relation[]): void {
    
    // ファミリー情報を更新するため、ログインユーザ以外を削除
    if (this.userInfos?.length > 1) this.userInfos.splice(1, this.userInfos.length - 1);
    
    if (family) {
      // 詰めなおし
      family.forEach(f => {
        if (f.role === 'child' && f.status !== 'waiting') {
          this.userInfos.push({
            value: Number(f.user_id), 
            label: f.name.family_name + " " + f.name.given_name + " (ID：" + f.user_id + ")",
          })
        }
      });
    }
  }

  /**
   * QRトップ画面のデータを初期化
   *
   * @private
   * @param {boolean} qrOnly QRデータのみ
   * @memberof MyqrComponent
   */
  private initData(qrOnly?: boolean) {

    // 初期化
    if (!qrOnly) {
      this.userInfos = [];
      this.isAlt = false;
    }
    this.imgSrc = {};
    this.qrImage = null;
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * QRコードを取得する。
   *
   * @private
   * @memberof MyqrComponent
   */
  private setUserQrCode(user_id?: string): void {
    //ログイン状態の場合、QRコードを要求する
    if (this.userServ.isLoggedIn()) {

      // QRコードを取得する。
      this.busy = this.sqrcServ.getCode(user_id).subscribe({
        next: res => {

          // 表示用データとしてQRコード（json）を保存
          this.imgSrc[this.selectedUserId] = res.body.qrcode;
        },
        error: this.errResServ.doParse((_error, customErrorContent) => {
          this.errResServ.viewErrDialog(customErrorContent);
        })
      }); 
    }
  }
}