import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  if (window)
  {
    window.console.log = () => {};
  }
}

if (environment.googleTagManagerId) {
  const script = document.createElement('script');
  script.text = `
window.dataLayer = window.dataLayer || [];
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${environment.googleTagManagerId}');`;
  document.head.appendChild(script);
}

async function prepare() {
  if (environment.env === 'mock') {
    const { worker } = await import('./mocks/browser');
    return await worker.start();
  }
}

prepare()
.then(() => platformBrowserDynamic().bootstrapModule(AppModule))
.catch(err => console.error(err));
