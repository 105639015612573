//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit, OnDestroy } from '@angular/core';
import { OnsNavigator, Params } from 'ngx-onsenui';
import { Subscription } from 'rxjs';

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { SqrcWebApiService } from '../../../http-services/sqrc-web-api.service';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';

// interface
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 利用者カード発行依頼画面（見守り対象アカウント用）
 *
 * @export
 * @class MyqrIssueComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @author （株）オブジェクトデータ takimoto wada
 */
@Component({
  selector: 'ons-page[myqr-issue]',
  templateUrl: './myqr-issue.component.html',
  styleUrls: ['./myqr-issue.component.css']
})
export class MyqrIssueComponent implements OnInit, OnDestroy {

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * subscriptionオブジェクト
   *
   * @type {Subscription}
   * @memberof MyqrIssueComponent
   */
  busy: Subscription;

  /**
   * 実行する処理
   *    user_card: 利用者カード発行依頼
   *    reissue: QRコード再発行
   *
   * @type {('user_card' | 'reissue')}
   * @memberof MyqrIssueComponent
   */
  mode: IssueMode;

  /**
   * 実行可能な処理
   *    user_card: 利用者カード発行依頼
   *    reissue: QRコード再発行
   *
   * @memberof MyqrIssueComponent
   */
  readonly issueMode = ISSUE_MODE;

  /**
   * オフィス名
   *
   * @type {string}
   * @memberof MyqrIssueComponent
   */
  officeName: string;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of MyqrIssueComponent.
   * @param {Params} params
   * @param {ApplicationMessageService} appMsg
   * @param {SqrcWebApiService} sqrcServ
   * @memberof MyqrIssueComponent
   */
  constructor
  (
    private params: Params,
    private appMsg: ApplicationMessageService,
    private errResServ: HttpErrorResponseParserService, 
    private sqrcServ: SqrcWebApiService,
    private navigator: OnsNavigator,
    private municipalityWebApiServ: MunicipalityWebApiService,
    private msg: MESSAGE,
  ) { }

  /**
   * 初期化処理。
   *
   * @memberof MyqrIssueComponent
   */
  ngOnInit(): void {
    this.mode = this.params.data.mode;
    
    // オフィス名
    this.officeName = this.municipalityWebApiServ.setting.office.office_name;
  }

  /**
   * 破棄処理。
   *
   * @memberof MyqrIssueComponent
   */
  ngOnDestroy(): void {
    this.busy?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================
  
  /**
   * 利用者カード発行ボタンClick
   *
   * @memberof MyqrIssueComponent
   */
  onClickIssueUserCard(): void {
    this.issueUserCard();
  }

  /**
   * QR再発行ボタンClick
   *
   * @memberof MyqrIssueComponent
   */
  onClickReissueQrCode(): void {
    this.reissueQrCode();
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * 利用者カード発行依頼を運営へ通知し、QRタブトップ画面に遷移する。
   *
   * @memberof MyqrIssueComponent
   */
  private issueUserCard(): void {
    // 発行を依頼する見守り対象アカウントのuser_id
    const alt_user_id: string = this.params.data.user_id;

    // 利用者カード発行通知を運営へ通知
    this.busy = this.sqrcServ.requestIssueUserCard(alt_user_id).subscribe({
      next: () => {
        // 完了ダイアログを表示後、QRタブトップ画面に遷移(myqrコンポーネント)
        this.appMsg.viewDialogMessage(this.msg.CLIENT.SQRC.ISSUE_USER_CARD.message(this.municipalityWebApiServ.setting.office.office_name), () => this.navigator.element.popPage());
      },
      error: this.errResServ.doParse((_error, customErrorContent) => {
        // エラー発生時はメッセージ表示
        this.appMsg.viewDialogErrorMessage(this.msg.CLIENT.COMMON.E_UNEXPECTED.message(), customErrorContent.smartGotoErrMessage);
      })
    });
  }

  /**
   * QRコード再発行を行い、QRタブトップ画面に遷移する。
   *
   * @private
   * @memberof MyqrIssueComponent
   */
  private reissueQrCode(): void {

    // QRコード再発行
    this.busy = this.sqrcServ.putCode(this.params.data.user_id).subscribe({
      next: res => {
        // 完了ダイアログを表示後、QRタブトップ画面に遷移
        this.appMsg.viewDialogMessage(this.msg.CLIENT.SQRC.REISSUE_QR.message(), _ => {
          this.navigator.element.popPage().then(_ => {
            // 表示QRに、再発行後のQRを上書き
            this.params.data.setQr(res.body.qrcode);
          })
        });
      },
      error: this.errResServ.doParse((_err, errContent) => {
        // QR再発行回数が上限を超えた場合、ダイアログを出すだけでなく、QRタブトップ画面に遷移
        if (errContent.smartGotoErrCode == this.appMsg.SERV_CONST_CODE.SQRC.UPDATE_LIMIT_RATE) {
          this.errResServ.viewErrDialog(errContent).then(() => {
            this.navigator.element.popPage();
          })
        }
        else this.errResServ.viewErrDialog(errContent);
      })
    })
  }
}

/**
 * QR関連発行mode
 */
export const ISSUE_MODE = {

  user_card: 'user_card',

  reissue: 'reissue'
} as const;

/**
 * 選択可能なmode
 */
type IssueMode = typeof ISSUE_MODE[keyof typeof ISSUE_MODE];