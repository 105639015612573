<ons-toolbar class="toolbar">
  <div class="left"><ons-back-button></ons-back-button></div>
  <div class="center">その他</div>
</ons-toolbar>
<div class="content">
  <div [ngBusy]="busy"></div>
  <ons-list class="accountMenu-list">
    <ons-list-item id="account-list_007" modifier="chevron longdivider" class="accountMenu" tappable (click)="pushNotificationMethod()">
      <img class="list-icon" [src]="ASSETS.NOTIFY" />
      <p class="list_text">通知方法</p>
    </ons-list-item>
    <ons-list-item modifier="chevron longdivider" class="accountMenu" tappable (click)="pushWithdrawal()">
      <img class="list-icon" [src]="ASSETS.WITHDRAWAL" />
      <p class="list_text">アカウント削除</p>
    </ons-list-item>
  </ons-list>
</div>  