//=============================================================================================
// インポート
//=============================================================================================
import { Component, ElementRef, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { OnsNavigator, Params } from 'ngx-onsenui';
import { Subscription } from 'rxjs';

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { FamilyWebApiService } from '../../../http-services/family-web-api.service';
import { PageKey, PagerService } from 'src/app/lib-services/pager.service';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 *ファミリー招待画面（STEP3）
 *
 * @export
 * @class FamilyInviteConfirmComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 * @implements {AfterViewInit}
 */
@Component({
  selector: 'ons-page[family-invite-confirm]',
  templateUrl: './family-invite-confirm.component.html',
  styleUrls: ['./family-invite-confirm.component.css']
})
export class FamilyInviteConfirmComponent implements OnInit, OnDestroy {

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * サーバ通信Subscription
   *
   * @type {Subscription}
   * @memberof FamilyInviteConfirmComponent
   */
  busy: Subscription;

  /**
   * 招待するメンバーのユーザID
   *
   * @type {number}
   * @memberof FamilyInviteConfirmComponent
   */
  memberUserId: string;
  
  /**
   * エリア名
   *
   * @type {string}
   * @memberof FamilyInviteConfirmComponent
   */
  areaName: string;

  /**
   * OnDestroy時に破棄するSubscriptionオブジェクト
   *
   * @memberof FamilyInviteConfirmComponent
   */
  subscription = new Subscription();

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of FamilyInviteConfirmComponent.
   * @param {OnsNavigator} _navigator
   * @param {Params} params
   * @param {FamilyWebApiService} FamilyWebApiService
   * @param {HttpErrorResponseParserService} httpErrorResponseParserService
   * @param {ApplicationMessageService} appMsg
   * @param {PagerService} pagerServ
   * @param {MunicipalityWebApiService} municipalityWebApiServ
   * @memberof FamilyInviteConfirmComponent
   */
  constructor(
    private _navigator: OnsNavigator,
    private params: Params,
    private FamilyWebApiService: FamilyWebApiService,
    private httpErrorResponseParserService: HttpErrorResponseParserService,
    private appMsg: ApplicationMessageService,
    private pagerServ: PagerService,
    private municipalityWebApiServ: MunicipalityWebApiService,
    private msg: MESSAGE,
  ) {
    const settingsChanged = this.municipalityWebApiServ.settingsChanged.subscribe({
      next: setting => {
        if (setting == null) return;
        this.areaName = setting.name;
      }
    });
    this.subscription.add(settingsChanged);
  }

  /**
   * 初期化処理。
   *
   * @memberof FamilyInviteConfirmComponent
   */
  ngOnInit(): void {
    // 読み取ったQRのユーザID
    this.memberUserId = this.params.data.userId;
  }

  /**
   * 破棄処理。
   *
   * @memberof FamilyInviteConfirmComponent
   */
  ngOnDestroy(): void {
    this.busy?.unsubscribe();
    this.subscription?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 中止ボタンClick
   * メンバーの招待を中止し、ファミリー一覧画面に戻る。
   *
   * @memberof FamilyInviteConfirmComponent
   */
  onClickAbortButton(): void {
    // ファミリー一覧画面に戻る
    this.pagerServ.transitionToPage(this._navigator, PageKey.FamilyComponent);
  }

  /**
   * 招待ボタンClick。
   *
   * @memberof FamilyInviteConfirmComponent
   */
  onClickInviteButton(): void {
    // 招待申請を送信
    this.execute();
  }

//=============================================================================================
// サーバー通信
//=============================================================================================

  /**
   * QRを読み取ったユーザにファミリー招待申請を送信。
   *
   * @memberof FamilyInviteConfirmComponent
   */
  execute(): void {

    this.busy = this.FamilyWebApiService.sendLinkRequest(this.memberUserId).subscribe({
      next:() => {
        // ファミリー一覧画面に戻る
        this.pagerServ.transitionToPage(this._navigator, PageKey.FamilyComponent);
      },
      error: this.httpErrorResponseParserService.doParse((_error, customErrorContent) => {
        // エラー発生時はメッセージ表示し、追加画面へ遷移
        this.appMsg.viewDialogErrorMessage(this.msg.CLIENT.FAMILY.E_REGISTER_FAMILY.message(), customErrorContent.smartGotoErrMessage, () => {
          this.pagerServ.transitionToPage(this._navigator, PageKey.FamilyAddComponent);
        });
      })
    }); 
  }
}
