import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { OnsNavigator, Params } from 'ngx-onsenui';
import { Subscription } from 'rxjs';

// service
import { UserWebApiService } from 'src/app/http-services/user-web-api.service';
import { FamilyWebApiService } from 'src/app/http-services/family-web-api.service';
import { HttpErrorResponseParserService } from 'src/app/lib-services/http-error-response-parser.service';
import { ApplicationMessageService } from 'src/app/lib-services/application-message.service';
import { PagerService } from 'src/app/lib-services/pager.service';
import { SigninService } from '../signin/signin.component';
import { AccountService } from '../account/account-list/account-list.component';
import { NewsWebApiService } from '../../http-services/news-web-api.service';

// component
import { FamilyInviteConfirmComponent } from '../family/family-invite-confirm/family-invite-confirm.component';
import { MESSAGE } from 'src/app/constants/message';

@Component({
  selector: 'ons-page[app-pincode-auth]',
  templateUrl: './pincode-auth.component.html',
  styleUrls: ['./pincode-auth.component.scss']
})
export class PincodeAuthComponent implements OnInit, AfterViewInit, OnDestroy {

  /**
   * 通信監視用Subscription
   *
   * @type {Subscription}
   * @memberof PincodeAuthComponent
   */
  busy: Subscription;

  /**
   * pincode認証の用途
   *
   * @type {QrMode}
   * @memberof PincodeAuthComponent
   */
  mode: QrMode;

  /**
   * PINコード認証を行うユーザの電話番号
   *
   * @type {string}
   * @memberof PincodeAuthComponent
   */
   twoFactorMethod: string;

  /**
   * PINコード
   * 　入力欄と紐付けられている
   *
   * @type {string}
   * @memberof PincodeAuthComponent
   */
  pincode: string = "";

  /**
   * 戻るボタン
   *
   * @private
   * @type {ElementRef}
   * @memberof PincodeAuthComponent
   */
  @ViewChild('onsBackButton') private onsBackButton: ElementRef;

  /**
   * Creates an instance of PincodeAuthComponent.
   * @param {Params} params
   * @param {UserWebApiService} userWebApiServ
   * @param {HttpErrorResponseParserService} errResServ
   * @param {OnsNavigator} navigator
   * @param {FamilyWebApiService} familyWebApiServ
   * @param {PagerService} pagerServ
   * @param {SigninService} signinServ
   * @param {AccountService} accountServ
   * @param {NewsWebApiService} newsServ
   * @memberof PincodeAuthComponent
   */
  constructor(
    private params: Params,
    private userWebApiServ: UserWebApiService,
    private errResServ: HttpErrorResponseParserService,
    private navigator: OnsNavigator,
    private familyWebApiServ: FamilyWebApiService,
    private pagerServ: PagerService,
    private signinServ: SigninService,
    private appMsgServ: ApplicationMessageService,
    private newsServ: NewsWebApiService,
    private msg: MESSAGE,
  ) { }

  ngOnInit(): void {
    // modeによって画面の表示、遷移先を変更
    this.mode = this.params.data.mode;

    // pincodeを送付した連絡先
    this.twoFactorMethod = this.params.data.twoFactorMethod;
  }

  ngAfterViewInit(): void {

    // ファミリーによる二段階認証
    if (this.mode == 'family') {
      // 戻るボタンClick
      this.onsBackButton.nativeElement.onClick = () => {
        // QR読み取り画面に戻った後、撮影再開
        this.navigator.element.popPage().then(() => {
          this.params.data.restartFilming();
        })
      }
    }
  }

  ngOnDestroy(): void {

    this.busy?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 認証ボタンClick
   *
   * @memberof PincodeAuthComponent
   */
  onClickAuthButton(): void {

    // ファミリー招待時認証
    if (this.mode == QR_MODE.family) this.authFamilyPincode();
    // ログイン時認証
    else if (this.mode == QR_MODE.login) this.authLoginPincode();
    // 想定外
    else {}
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * PINCODE認証を行う。
   * ファミリー招待時認証。
   *
   * @private
   * @param {string} pincode
   * @memberof PincodeAuthComponent
   */
  private authFamilyPincode(): void {

    this.busy = this.familyWebApiServ.twoFactorAuthPincode(this.pincode).subscribe({
      next: () => {

        // ファミリー招待確認画面へ遷移
        this.navigator.element.pushPage(FamilyInviteConfirmComponent, {
          data: { userId: this.params.data.userId }
        });
      },
      error: this.errResServ.doParse((_err, errContent) => {

        // ファミリー招待時、PINコードの有効期限が切れた場合、QR読み取り画面に戻る
        if (errContent.smartGotoErrCode == this.appMsgServ.SERV_CONST_CODE.LOGIN.PIN_EXPIRED) {
          this.appMsgServ.viewDialogMessage(this.msg.CLIENT.FAMILY.PIN_EXPIRED.message(), () => {
            this.navigator.element.popPage().then(() => {
              this.params.data.restartFilming();
            });
          });
        }
        else this.errResServ.viewErrDialog(errContent);
      })
    })
  }

  /**
   * PINCODE認証を行う。
   * ログイン時認証。
   *
   * @private
   * @memberof PincodeAuthComponent
   */
  private authLoginPincode(): void {

    // Pinコード認証
    this.busy = this.userWebApiServ.signinPincode(this.pincode).subscribe({
      next: () => {

        // ユーザ情報、ファミリー情報取得
        this.userWebApiServ.autoLogin().subscribe({
          next: () => {
            this.appMsgServ.viewDialogMessage(this.msg.CLIENT.PROFILE.FACE_AUTH_SUCCESS.message(), () => {
              // 利用するタブから遷移している場合は呼び出し元の画面へ戻る
              if (this.pagerServ.getUserAppNavigator) this.signinServ.trans();
              // マイページタブからのログイン
              // else this.accountServ.link('signin');
            });
            // 未ログイン時の既読記事情報を削除
            this.newsServ.clearLocalReadArticles();
          },
          error: this.errResServ.doParse((_err, errContent) => this.errResServ.viewErrDialog(errContent))
        })
      },
      error: this.errResServ.doParse((_err, errContent) => {

        // ログイン時、PINコードの有効期限が切れた場合、サインイン画面に戻る
        if (errContent.smartGotoErrCode == this.appMsgServ.SERV_CONST_CODE.LOGIN.PIN_EXPIRED) {
          this.appMsgServ.viewDialogMessage(this.msg.CLIENT.LOGIN.PIN_EXPIRED.message(), () => {
            this.navigator.element.popPage();
          });
        }
        else this.errResServ.viewErrDialog(errContent);
      })
    })
  }
}

/**
 * pinCode認証の用途
 */
export const QR_MODE = {

  // ファミリー認証
  family: 'family',

  // ログイン認証
  login: 'login'
} as const;

/**
 * pinCode認証の用途
 */
type QrMode = typeof QR_MODE[keyof typeof QR_MODE];
