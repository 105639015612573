import { Injectable } from '@angular/core';

export class UserAgentServiceConfig {
  recommendedEnvironment: {
    android: {
      os: string;
      chrome: string;
    },
    iPhone: {
      os: string;
    }
  }
}
@Injectable({
  providedIn: 'root'
})
export class UserAgentService {

  private _userAgent: string;

  private _isAndroidPhone = false;
  private _isiPhone = false;
  private _isOtherTerminal = false;


  private _isRecommendedEnvironment = false;

  constructor(private _config: UserAgentServiceConfig) { }

  public initialize(userAgent: string): void {
    this._userAgent = userAgent.toLowerCase();

    const isRecommendedEnvironment = function (baseNos: number[], terminalNos: number[]): boolean {
      const getNo = (index: number, nos: number[]) => nos.length - 1 >= index ? nos[index] : 0;
      for (let i = 0; i < Math.max(baseNos.length, terminalNos.length); i++) {
        const baseNo = getNo(i, baseNos);
        const terminalNo = getNo(i, terminalNos);
        // console.log(`baseNo: ${baseNo}, terminalNo: ${terminalNo}`);
        if (baseNo > terminalNo) {
          return false;
        } else if (baseNo < terminalNo) {
          return true;
        }
      }
      return true;
    }

    const recommendediOS = this._config.recommendedEnvironment.iPhone.os;
    const recommendedAndroidOS = this._config.recommendedEnvironment.android.os;
    const recommendedChrome = this._config.recommendedEnvironment.android.chrome;

    if (this._userAgent.indexOf('iphone') > 0) {
      if (this._userAgent.match(/version\/([0-9\._]+).*mobile.*safari.*/)) {
        this._isiPhone = true;

        const recommendediOSNos = recommendediOS.split('.').map(e => parseInt(e));
        const useriOSNos = this._userAgent.match(new RegExp(`iphone os [\\d_]+`))[0].match(/[\d_]+/)[0].split('_').map(e => parseInt(e));
        if (isRecommendedEnvironment(recommendediOSNos, useriOSNos))
          this._isRecommendedEnvironment = true;
      }
    } else if (this._userAgent.indexOf('android') > 0 && this._userAgent.indexOf('mobile') > 0) {
      this._isAndroidPhone = true;

      const androidRecommendedNos = recommendedAndroidOS.split('.').map(e => parseInt(e));
      const userAndroidNos = this._userAgent.match(new RegExp(`android [\\d.]+`))[0].match(/[\d.]+/)[0].split('.').map(e => parseInt(e));
      const recommendedChromeNos = recommendedChrome.split('.').map(e => parseInt(e));
      const userChromeNos = this._userAgent.match(new RegExp(`chrome/[\\d.]+`))[0].match(/[\d.]+/)[0].split('.').map(e => parseInt(e));
      if (isRecommendedEnvironment(androidRecommendedNos, userAndroidNos) && isRecommendedEnvironment(recommendedChromeNos, userChromeNos))
        this._isRecommendedEnvironment = true;
    } else {
      this._isOtherTerminal = true;
    }
  }

  get isAndroidPhone() {
    return this._isAndroidPhone;
  }

  get isiPhone() {
    return this._isiPhone;
  }

  get isOtherTerminal() {
    return this._isOtherTerminal;
  }

  get isRecommendedEnvironment() {
    return this._isRecommendedEnvironment;
  }

  get recommendedEnvironment() {
    return this._config.recommendedEnvironment;
  }
}
