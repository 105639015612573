<ons-toolbar class="toolbar">
  <div class="left">
    <ons-back-button></ons-back-button>
  </div>
  <div class="center">新規会員登録</div>
</ons-toolbar>

<div class="content">
  <div class="center segment-wrap">
    <div class="segment">
      <ul class="stepbar stepbar__three">
        <li class="stepbar__item">STEP1</li>
        <li class="stepbar__item stepbar__item--current">STEP2</li>
        <li class="stepbar__item">STEP3</li>
      </ul>
    </div>
  </div>

  <div class="content--message">お迎え・配達を迅速に行うために、地図上でご住所の入り口にピンを置いてください。</div>

  <div class="button-wrap">
    <ons-button modifier="large--cta" (click)="pushProxyDetermine()" [disabled]="!address.location">位置情報を確定し次へ</ons-button>
  </div>

  <parts-location-update style="height: 100%" [address]="address" [isGetLocation]="isGetLocation" (locationEvent)="updateLocation($event)"></parts-location-update>
</div>