//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit, OnDestroy, AfterViewInit, Injectable } from '@angular/core';
import { Params, OnsNavigator } from 'ngx-onsenui';
import { Observable, Subject, Subscription } from 'rxjs';
import * as CONST from '../../constants/constant';

// service
import { ApplicationMessageService } from '../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../lib-services/http-error-response-parser.service';
import { UserWebApiService } from '../../http-services/user-web-api.service';
import { AccountService } from '../../components/account/account-list/account-list.component';
import { CommonFunctionModule } from '../../lib-modules/common-function.module';
import { NewsWebApiService } from '../../http-services/news-web-api.service';

// component
import { AppComponent } from '../../app.component';
import { SignupComponent } from '../user/signup/signup.component';
import { ChangePasswordComponent } from '../../components/change-password/change-password.component';
import { ProfileSignupComponent } from '../user/profile-signup/profile-signup.component';
import { TabbarComponent } from '../tabbar/tabbar.component';
import { PageKey, PagerService } from 'src/app/lib-services/pager.service';
import { UserInfo } from 'src/app/interfaces/response';
import { PincodeAuthComponent, QR_MODE } from '../pincode-auth/pincode-auth.component';

import { TermAgreeComponent } from '../term-agree/term-agree.component';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * サインイン画面。
 *
 * @export
 * @class SigninComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ons-page[signin]',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit, AfterViewInit, OnDestroy {

//=============================================================================================
// プロパティ定義
//=============================================================================================


  /**
   * メールアドレスとパスワードでサインインしたか
   *
   * @memberof SigninComponent
   */
  public signupLocal = false;

  /**
   * メールアドレス
   *
   * @memberof SigninComponent
   */
  public username = null;

  /**
   * パスワード
   *
   * @memberof SigninComponent
   */
  public password = null;

  /**
   * サーバ通信Subscription
   *
   * @type {Subscription}
   * @memberof SigninComponent
   */
  busy: Subscription;

  /**
   * ユーザー情報更新時用Subscription
   *
   * @type {Subscription}
   * @memberof SigninComponent
   */
  onUserInfoChanged: Subscription;

  /**
   * サインイン監視用Subscription
   *
   * @private
   * @type {Subscription}
   * @memberof SigninComponent
   */
  private onNextLinkChanged: Subscription;

  /**
   * assetsファイルへのパス(定数)
   *
   * @type {string}
   * @memberof SigninComponent
   */
  readonly ASSETS_MAIL: string = CommonFunctionModule.getAssetsUrl('/image/common/39-Mail.png');

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of SigninComponent.
   * @param {AppComponent} _appComponent
   * @param {OnsNavigator} _navigator
   * @param {ApplicationMessageService} appMsgServ
   * @param {UserWebApiService} userServ
   * @param {SqrcWebApiService} sqrcServ
   * @param {AccountService} accountService
   * @param {HttpErrorResponseParserService} errResServ
   * @param {NewsWebApiService} newsServ
   * @param {Params} params
   * @memberof SigninComponent
   */
  constructor(
    private _appComponent: AppComponent,
    private _navigator: OnsNavigator,
    private appMsgServ: ApplicationMessageService,
    private userServ: UserWebApiService,
    private accountService: AccountService,
    private errResServ: HttpErrorResponseParserService,
    private commonFunction: CommonFunctionModule,
    private newsServ: NewsWebApiService,
    private params: Params,
    private pagerServ: PagerService,
    private signinServ: SigninService,
  ) { }

  /**
   * 初期化処理。
   *
   * @memberof SigninComponent
   */
  ngOnInit(): void {
  }

  /**
   * 初期化直後の処理
   *
   * @memberof SigninComponent
   */
  ngAfterViewInit(): void {

    this.pagerServ.setReturnPage({ index: this._navigator.element.pages.length - 2, key: PageKey.AllClear });
    this.pagerServ.setReturnPage({ index: this._navigator.element.pages.length - 1, key: PageKey.Signin });

    // ------------------------------------------------------------------------
    // [MEMO] 2021/10/6 Tomoya Ishizone
    // (電波状況が悪かったりサーバの処理が重いなどで)autoLoginのレスポンスが非常に遅い場合に、
    // ユーザーがautoLoginのレスポンスが返ってくるまでにマイページタブを押した場合、
    // まだuserInfo=nullとなりログイン画面に遷移してしまうが、
    // その後autoLoginが成立したら他のタブはログイン状態として操作できるのに、
    // マイページタブだけログイン画面のままとなってしまう。
    //
    // ⇒ログイン画面で、userServ.userInfoChangedの購読をしておいて、
    // 　ユーザーInfoが取れたらマイページメニュー(statusがsignedの場合はユーザー情報登録画面)
    // 　に遷移する処理を追加。
    // ------------------------------------------------------------------------
    this.onUserInfoChanged = this.userServ.userInfoChanged.subscribe({
      next: userInfo => {
        if (userInfo) {
          // ユーザー情報未登録
          if (userInfo.status === 'signed') {
            // this._appComponent.navigator.element.pushPage(ProfileSignupComponent, { data: [] });
            this.pagerServ.getAppNavigator.element.pushPage(ProfileSignupComponent, { data: [] });
          }
          // ユーザー情報登録済
          else if (userInfo.status === 'registed') {
            // 遷移元が機能の途中ページの場合、その画面に戻りたいが、
            // 現在の実装上ons-navigatorのページスタックを初期化してトップへ戻るようになっている。
            // 途中ページに戻る仕組みを上記に追加するにはテストを含めた工数が多く必要となるため、
            // 対応するまでの間はサインイン時はトップへ戻る仕組みのままとする。
            // if (undefined !== this.params.data.exp) this.accountService.link('signin_noTrans');
            // else
            // タブバーの表示非表示を観光タブトップ画面に戻った際に、変更
            // TODO: ページスタックを初期化してトップへ戻らなくなった場合、この処理の削除が必要
            if (undefined !== this.params.data.exp) {
              this.params.data.redraw();
            }

            // NOTE: shopping.componentで店舗一覧取得をしているにも関わらず、ここで取得している理由を調査必要
            // 買い物タブからサインインした場合は店舗一覧を取得しておく
            if (undefined !== this.params.data.checkDeliverable) {

              // 観光目的ユーザの住所が設定されているか
              if (this.userServ.getUserInfo().user_type === 'tourist') {
                if (true === this.commonFunction.checkAddressValidity(this.userServ.getUserInfo().profile.address))
                  this.params.data.checkDeliverable();
              }
              else this.params.data.checkDeliverable();
              this.params.data.getShopList();
            }

            if (this.pagerServ.getUserAppNavigator === false) this.accountService.link('signin');
          }
        }
      }
    });

    // サインイン処理を監視
    this.onNextLinkChanged = this.signinServ.changed.subscribe({
      next: (leaveSignin: boolean) => {
        if (this.pagerServ.getUserAppNavigator) {
          this.pagerServ.initAppNavigator(leaveSignin).then(() => {
            // 規約合意していない場合、規約合意画面を表示
            if (this.userServ.getUserInfo().term_agreed === false) this._navigator.element.pushPage(TermAgreeComponent, {animation: 'fade-ios'});
          });
        }
      }
    });
  }

  /**
   * 破棄処理。
   *
   * @memberof SigninComponent
   */
  ngOnDestroy(): void {

    this.busy?.unsubscribe();
    this.onUserInfoChanged?.unsubscribe();
    this.onNextLinkChanged?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * メールアドレスで新規登録を行う。
   *
   * @memberof SigninComponent
   */
  signup(): void {

    this._appComponent.navigator.element.pushPage(SignupComponent, {data: this.params.data});
  }

  /**
   * SNSアカウントでサインインを行う。
   *
   * @param {('line' | 'facebook' | 'twitter' | 'yj' | 'google')} provider
   * @return {*}  {void}
   * @memberof SigninComponent
   */
  signinSNS(provider: 'line' | 'facebook' | 'twitter' | 'yj' | 'google'): void {

    switch(provider) {
      case "facebook":
      case "twitter":
        this.appMsgServ.viewDialogMessage(CONST.Common.COMING_SOON);
        return;
    }

    this.userServ.auth(provider, () => {
      // console.log("[SignupComponent] login completed provider:" + provider);
      this.userServ.autoLogin().subscribe({
        next: () => this.accountService.link('signin'),
        error: this.errResServ.doParse((_err, errContent) => { this.errResServ.viewErrDialog(errContent); })
      });
    });
  }

  /**
   * メールアドレスとパスワードでサインインを行う。
   *
   * @memberof SigninComponent
   */
  signinLocal(): void {

    /**
     * ログイン処理。
     *
     * @param {boolean} login
     * true: succeed, false: required
     */
    const login = () => {
      this.userServ.autoLogin().subscribe({
        next: (res) => {
          // ユーザ情報
          const user: UserInfo = res.body;

          if (user.status == 'registed') {
            // 機能の途中でログインした場合ログイン用のnavigatorを初期化
            if (this.pagerServ.getUserAppNavigator) this.signinServ.trans();
          }

          // 買い物タブからサインインした場合は店舗一覧を取得しておく
          if (undefined !== this.params.data.checkDeliverable) {

            // 観光目的ユーザの住所が設定されているか
            if (user.user_type === 'tourist') {
              if (true === this.commonFunction.checkAddressValidity(user.profile.address))
                this.params.data.checkDeliverable();
            }
            else this.params.data.checkDeliverable();
            this.params.data.getShopList();
          }
          // 未ログイン時の既読記事情報を削除
          this.newsServ.clearLocalReadArticles();
        },
        error: this.errResServ.doParse((_err, errContent) => { this.errResServ.viewErrDialog(errContent); })
      });
    };

    // ログイン処理
    this.busy = this.userServ.signin(this.username, this.password).subscribe({
      next: response => {
        // ログイン時、二段階認証の有無
        var result: 'succeed' | 'required' = response.body.result;

        switch(result) {
          // 二段階認証あり
          case 'required':
            // Pinコード認証画面へ遷移
            this._appComponent.navigator.element.pushPage(PincodeAuthComponent, {
              data: {
                mode: QR_MODE.login,
                twoFactorMethod: response.body.dst
              }
            });
            break;
          // 二段階認証なし
          case 'succeed':
            // ログイン後処理
            login();
            break;
        }
      },
      error: this.errResServ.doParse((_err, errContent) => { this.errResServ.viewErrDialog(errContent); })
    });
  }

  /**
   * パスワード再設定
   *
   * @memberof SigninComponent
   */
  forgetPassword(): void {

    this._navigator.element.pushPage(ChangePasswordComponent);
  }

  // /**
  //  * SNS認証の注意書きを表示する。
  //  *
  //  * @memberof SigninComponent
  //  */
  // cannotSignin(): void {

  //   this.appMsgServ.viewDialogMessage(this.msg.CLIENT.COMMON.CANNOT_SIGNIN.message());
  // }
}

@Injectable({
  providedIn: 'root'
})
export class SigninService {

  private linkSubject = new Subject<boolean>();

  trans(leaveSignin: boolean = false) {
    this.linkSubject.next(leaveSignin);
  }

  get changed(): Observable<boolean> {
    return this.linkSubject.asObservable();
  }

}
