<ons-toolbar class="toolbar">
  <div class="left">
    <ons-back-button></ons-back-button>
  </div>
  <div class="center">ユーザー登録</div>
</ons-toolbar>

<div class="content">
  <div [ngBusy]="busy"></div>
  <div class="center segment-wrap">
    <div class="segment">
      <ul class="stepbar stepbar__four">
        <li class="stepbar__item stepbar__item--current">STEP1</li>
        <li class="stepbar__item">STEP2</li>
        <li class="stepbar__item">STEP3</li>
        <li class="stepbar__item">STEP4</li>
      </ul>
    </div>
    <div>登録用メールアドレスとパスワードを入力してください。</div>
  </div>

  <form id="mailSignUpForm" #formCheck="ngForm" class="formCheck mail-wrap">
    <ons-input id="signup_001" type="email" modifier="underbar" placeholder="メールアドレス" [(ngModel)]="username" name="user_name"
      #userNameModel="ngModel" required email></ons-input>
    <div class="valid-msg" *ngIf="userNameModel.errors && (userNameModel.dirty || userNameModel.touched)">
      <span *ngIf="userNameModel.errors.required">必須です。</span>
      <span *ngIf="userNameModel.errors.email">メールアドレスが不適切です。</span>
    </div>
    <ons-input id="signup_002" type="password" modifier="underbar" placeholder="パスワード" [(ngModel)]="password" name="password"
      #passwordModel="ngModel" required minlength="8" maxlength="16" pattern="^(?=.*?[a-zA-Z])(?=.*?\d)[a-zA-Z\d]*$">
    </ons-input>
    <div class="valid-msg" *ngIf="passwordModel.errors && (passwordModel.dirty || passwordModel.touched)">
      <span *ngIf="passwordModel.errors.required">必須です。</span>
      <span *ngIf="passwordModel.errors.minlength">８文字以上必要です。</span>
      <span *ngIf="passwordModel.errors.maxlength">最大１６文字です。</span>
      <span *ngIf="passwordModel.errors.pattern">「半角数字、英字」を各１字以上、「半角英数のみ」で入力してください。</span>
    </div>
    <ons-input id="signup_003" type="password" modifier="underbar" placeholder="パスワード（確認用）" [(ngModel)]="passwordConfirm"
      name="password_confirm" #passwordConfirmModel="ngModel" required></ons-input>
    <div class="valid-msg"
      *ngIf="passwordConfirmModel.errors && (passwordConfirmModel.dirty || passwordConfirmModel.touched)">
      <span *ngIf="passwordConfirmModel.errors.required">必須です。</span>
    </div>

    <ons-button id="signup_004" modifier="large--cta noFixed sns" (click)="signupLocal()"
      [attr.disabled]="!formCheck.invalid ? null : ''">
      <img class="button-icon" [src]="ASSETS_MAIL" />
      認証コード送信
    </ons-button>
  </form>
</div>
