import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { municipalities, Settings, Term } from '../interfaces/response';
import { request } from '../interfaces/request';

// service
import { FamilyWebApiService } from './family-web-api.service';

// interface
import { HttpCustomErrorContent } from '../lib-services/http-error-response-parser.service';
import { Location } from "../interfaces/response/location";
import { WeatherDisasterPreventionRelateSite } from "../interfaces/response/disaster-prevention-sites";
// ========================================================================================================================
// Configクラス定義
// ========================================================================================================================

/**
 * Web API (予約関連) の構成設定。
 *
 * @export
 * @class MunicipalityWebApiServiceConfig
 */
export class MunicipalityWebApiServiceConfig {
  /**
   * Web API の基底 URL。
   *
   * @type {string}
   * @memberof MunicipalityWebApiServiceConfig
   */
  baseUrl: string;

  /**
   * エリアコード
   *
   * @type {string}
   * @memberof MunicipalityWebApiServiceConfig
   */
  areacode: string;

  /**
   * HTTP リクエストと一緒に送信されるオプション。
   *
   * @type {({
   *     headers?: HttpHeaders | {
   *       [header: string]: string | string[];
   *     };
   *   })}
   * @memberof MunicipalityWebApiServiceConfig
   */
  httpOptions?: {
    /**
     * HTTP ヘッダー。
     *
     * @type {(HttpHeaders | {
     *       [header: string]: string | string[];
     *     })}
     */
    headers?: HttpHeaders | {
      [header: string]: string | string[];
    };
  };
}


/**
 * Web API (予約関連) の呼び出しを簡略化するためのユーティリティ。
 *
 * @export
 * @class MunicipalityWebApiService
 */
@Injectable({
  providedIn: 'root'
})
export class MunicipalityWebApiService {

  /**
   * 自治体ごとの設定情報
   *
   * @private
   * @type {Settings}
   * @memberof MunicipalityWebApiService
   */
  private _setting: Settings;

  /**
   * 設定情報用BehaviorSubject
   *
   * @private
   * @memberof MunicipalityWebApiService
   */
  private settingsSubject = new BehaviorSubject<Settings>(null);

  /**
   * 自治体ごとの設定情報
   *
   * @readonly
   * @type {Settings}
   * @memberof MunicipalityWebApiService
   */
  get setting(): Settings {
    return this._setting;
  };

  /**
   * 自治体ごとの設定情報
   *
   * @readonly
   * @type {Observable<Settings>}
   * @memberof MunicipalityWebApiService
   */
  get settingsChanged(): Observable<Settings> {
    return this.settingsSubject.asObservable();
  }

  /**
   * 
   * @param {HttpClient} http HTTP リクエストを実行するためのサービス。
   * @param {MunicipalityWebApiServiceConfig} config Web API (予約関連) の構成設定。
   * @memberof MunicipalityWebApiService
   */
  constructor(
    private http: HttpClient,
    private config: MunicipalityWebApiServiceConfig,
    private familyWebApiServ: FamilyWebApiService,
  ) { }


//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * クライアント設定情報を取得する。
   *
   * @return {*}  {Settings}
   * @memberof MunicipalityWebApiService
   */
  getClientSettings(): Settings {
    
    return this.settingsSubject.getValue();
  }

  /**
   * 緯度経度登録をスキップした際に設定する仮値を取得する
   * 各自治体の役場の緯度経度情報
   *
   * @return {Location}
   * @memberof MunicipalityWebApiService
   */
  getDummyLocation(): Location{

    return this.setting.dummy_location.location;
  }

  /**
   * 自治体ごとの気象・防災関連情報サイト
   *
   * @return {WeatherDisasterPreventionRelateSite}
   * @memberof MunicipalityWebApiService
   */
  getDisasterPreventionSites(): WeatherDisasterPreventionRelateSite[]{

    return this.setting.weather_disaster_prevention_relate_sites;
  }

  /**
   * 規約未合意エラーレスポンスから、
   * 規約合意していないユーザーとしてメッセージで表示する文字列を取得。
   *
   * @param {HttpCustomErrorContent} content
   * @return {*}  {string}
   * @memberof MunicipalityWebApiService
   */
  getTermNeverAgreedUser(content: HttpCustomErrorContent): string {
    const errOption = content.smartGotoErrOpt;

    let neverAgreeUser: string = "";
    switch (errOption.role) {
      case 'user': // 利用者
        neverAgreeUser = this.familyWebApiServ.getFamilyName(errOption.user_id) + "様";
        break;
      case 'login_user': // ログインユーザー
        neverAgreeUser = "ログインユーザー";
        break;
      case 'payer': // 決済者
        neverAgreeUser = "決済者";
        break;
      default: // 想定外
        neverAgreeUser = "";
    }

    return neverAgreeUser;
  }

//=============================================================================================
// サーバ通信
//=============================================================================================

  /**
   * 最新のクライアント設定情報を取得する。
   *
   * @return {*}  {Observable<HttpResponse<Settings>>}
   * @memberof MunicipalityWebApiService
   */
  getLatestSettings(): Observable<HttpResponse<Settings>> {

    return this.http.get<Settings>(`${this.config.baseUrl}/${this.config.areacode}/settings`, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    }).pipe(tap({
      next: (res: HttpResponse<Settings>) => {
        this._setting = res.body;
        this._setting.isProvidedExpServiceOnly = (this.setting.services.length === 1 && this.setting.services[0] === 'exp-service');
        this.settingsSubject.next(res.body);
      }
    }));
  }

  /**
   * 展開自治体一覧取得
   *
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof MunicipalityWebApiService
   */
  getMunicipalities(): Observable<HttpResponse<municipalities[]>> {

    return this.http.get<municipalities[]>(`${this.config.baseUrl}/municipalities`, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }

  ////////////////////////////////////////////////////////////////////////////
  // 規約
  ////////////////////////////////////////////////////////////////////////////

  /**
   * 規約取得
   *
   * @param {string} [term_id]
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof MunicipalityWebApiService
   */
  getTerm(term_id?: string): Observable<HttpResponse<Term>> {

    return this.http.get<Term>(`${this.config.baseUrl}/${this.config.areacode}/term`, {
      ...this.config.httpOptions,
      observe: 'response',
      params: term_id ? {term_id: `${term_id}`} : null,
      withCredentials: true
    });
  }

  /**
   * ユーザーor店舗と自治体との規約合意
   *
   * @param {request.PostTermAgreement} reqBody
   * @return {*}  {Observable<HttpResponse<any>>}
   * @memberof MunicipalityWebApiService
   */
  postTermAgreement(reqBody: request.PostTermAgreement): Observable<HttpResponse<any>> {

    return this.http.post<request.PostTermAgreement>(`${this.config.baseUrl}/${this.config.areacode}/term-agreement`, reqBody, {
      ...this.config.httpOptions,
      observe: 'response',
      withCredentials: true
    });
  }
}