<ons-toolbar>
  <div class="center">アプリ情報・規約</div>
</ons-toolbar>

<div class="content">
  <ons-list>
    <ons-list-item modifier="chevron longdivider" tappable (click)="pushChangeMunicipality()">
      <p>他自治体版サイトへ</p>
    </ons-list-item>

    <ng-container *ngIf="areaDocuments">
      <ons-list-item expandable modifier="longdivider padding-left">
        <p>SmartGOTO説明資料</p>
        <ons-list class="expandable-content">
          <ons-list-item *ngFor="let document of areaDocuments" modifier="longdivider" tappable (click)="pushPDF(document.url)">{{document.area}}版（PDF）</ons-list-item>
        </ons-list>
      </ons-list-item>
    </ng-container>

    <ons-list-item id="splitter-side-menu_001" modifier="longdivider" tappable (click)="pushTerms()">
      <p>利用規約</p>
    </ons-list-item>

    <ons-list-item id="splitter-side-menu_002" modifier="longdivider" tappable (click)="pushPrivacyPolicy()">
      <p>プライバシーポリシー</p>
    </ons-list-item>

    <ons-list-item id="splitter-side-menu_003" modifier="chevron longdivider" tappable (click)="pushRecommendedEnvironment()">
      <p>推奨環境</p>
    </ons-list-item>
  </ons-list>
  <p class="center">最新リリース日：{{ releaseDate }}</p>
</div>