//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit } from '@angular/core';
import { OnsNavigator } from 'ngx-onsenui';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 見守り対象アカウント説明画面。
 *
 * @export
 * @class ProxyAccountDescriptionComponent
 * @implements {OnInit}
 * @author （株）オブジェクトデータ wada
 */
@Component({
  selector: 'ons-page[proxy-account-description]',
  templateUrl: './proxy-account-description.component.html',
  styleUrls: ['./proxy-account-description.component.css']
})
export class ProxyAccountDescriptionComponent implements OnInit 
{

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of ProxyAccountDescriptionComponent.
   * @param {OnsNavigator} navigator
   * @memberof ProxyAccountDescriptionComponent
   */
  constructor(
    private navigator: OnsNavigator,
    private municipalityWebApiServ: MunicipalityWebApiService,
  ) { }

  /**
   * 初期化処理。
   *
   * @memberof ProxyAccountDescriptionComponent
   */
  ngOnInit(): void 
  {
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * プライバシーポリシーを表示する。
   *
   * @memberof ProxyAccountDescriptionComponent
   */
  viewPrivacyPolicy(): void 
  {
    // プライバシーポリシーのサイトを表示
    window.open(`/${this.municipalityWebApiServ.setting.municipal_id}/assets/pdf/privacy-policy.pdf`,'_blank');
  }
  
  /**
   * 利用規約へ遷移する。
   *
   * @memberof ProxyAccountDescriptionComponent
   */
  viewUsePolicy(): void 
  {
    // 利用規約ページへ遷移
    window.open(this.municipalityWebApiServ.setting.term, '_blank');
  }
}
