//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit } from '@angular/core';
import { OnsNavigator } from 'ngx-onsenui';

// component
import { FamilyInviteQrComponent } from '../family-invite-qr/family-invite-qr.component';
import { ProxyAccountSignupComponent } from '../../proxy-account/proxy-account-signup/proxy-account-signup.component';
import { ProxyAccountDescriptionComponent } from '../../proxy-account/proxy-account-description/proxy-account-description.component';

// service
import { PageKey, PagerService } from 'src/app/lib-services/pager.service';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * ファミリー追加画面。
 *    本画面はファミリー管理者向けとなる。
 *
 * @export
 * @class FamilyAddComponent
 * @implements {OnInit}
 * @author （株）オブジェクトデータ wada
 */
@Component({
  selector: 'ons-page[app-family-add]',
  templateUrl: './family-add.component.html',
  styleUrls: ['./family-add.component.css']
})

export class FamilyAddComponent implements OnInit {

//=============================================================================================
// プロパティ定義
//=============================================================================================


//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of FamilyAddComponent.
   * @param {OnsNavigator} navigator
   * @param {Params} params
   * @memberof FamilyAddComponent
   */
  constructor(
    private navigator: OnsNavigator,
    private pagerServ: PagerService,
  ) { }

  /**
   * 初期化処理。
   *
   * @memberof FamilyAddComponent
   */
  ngOnInit(): void {
    // 戻り先としてスタックのindexを登録
    this.pagerServ.setReturnPage({key: PageKey.FamilyAddComponent, index: this.navigator.element.pages.length - 1})
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * ファミリーメンバーとして招待する。
   *
   * @memberof FamilyAddComponent
   */
  inviteFamily(): void {
    this.navigator.element.pushPage(FamilyInviteQrComponent);
  }

  /**
   * 代理アカウントを作成する。
   *
   * @memberof FamilyAddComponent
   */
  createProxy(): void {
    this.navigator.element.pushPage(ProxyAccountSignupComponent);
  }

  /**
   * 代理アカウント機能説明を表示する。
   *
   * @memberof FamilyComponent
   */
  viewProxyDescription(): void {
    this.navigator.element.pushPage(ProxyAccountDescriptionComponent);
  }
}
