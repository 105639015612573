<ons-toolbar class="toolbar">
    <div class="left">
        <ons-back-button *ngIf="!isEditList"></ons-back-button>
        <div id="myspot_001" class="button--text" *ngIf="isEditList" (click)="cancel()">
            キャンセル
        </div>
    </div>
    <div class="center">マイスポット</div>
    <div class="right">
        <div id="myspot_002" class="button--text" *ngIf="!isEditList && mySpots.length > 0" (click)="edit()">
            編集
        </div>
        <div *ngIf="isEditList">
            <ons-button id="myspot_003" class="toolbar-button" (click)="register()">完了</ons-button>
        </div>
    </div>
</ons-toolbar>
<form method="post">
    <div [ngBusy]="busy"></div>
    <ul cdkDropList [cdkDropListData]="mySpots" (cdkDropListDropped)="drop($event)" class="myspot-list">
        <li [cdkDragData]="item" *ngFor="let item of mySpots;" cdkDrag [cdkDragStartDelay]="300"
            [cdkDragDisabled]="!isEditList" (click)="isEditList ? false : transitionToEdit(item)"
            class="myspot-list__item">
            <div class="myspot-list__item--name" [class.edit]="isEditList">
                <div *ngIf="isEditList" (click)="delete(item)" class="myspot-list__item--deleteButton">
                    <img class="list-icon noPadding" [src]="ASSETS.TRASH" />
                </div>
                <img class="list-icon" [src]="ASSETS.INACTIVE" />
                <p class="item-list" [class.item-edit]="isEditList">{{item.name}}</p>
            </div>
            <div *ngIf="isEditList" cdkDragHandle class="list-icon noPadding IconBgImg" [style.--spot-edit]="ASSETS.BAR"></div>
            <div *ngIf="!isEditList" class="myspot-list__item--arrow"></div>
        </li>
    </ul>
    <p *ngIf="!isEditList && mySpots.length == 0" class="myspot-empty">
        現在マイスポットは登録されていません。
    </p>
    <br>
    <ons-list *ngIf="!isEditList && mySpotsUndefined.length > 0" class="myspot-list">
        <ons-list-header class="list-item__title">未設定のスポット</ons-list-header>
    </ons-list>
    <ul *ngIf="!isEditList" class="myspot-list">
        <li *ngFor="let item of mySpotsUndefined;" (click)="isEditList ? false : transitionToEdit(item)" class="myspot-list__item">
            <div class="myspot-list__item--name" [class.edit]="isEditList">
                <img class="list-icon" [src]="ASSETS.INACTIVE" />
                <p class="item-list" [class.item-edit]="isEditList">{{item.name}}</p>
            </div>
            <div *ngIf="!isEditList" class="myspot-list__item--arrow"></div>
        </li>
    </ul>
    
    <div *ngIf="!isEditList" class="button-wrap button-sticky">
        <ons-button id="myspot_004" modifier="large--cta large--cta--icon" (click)="transitionToEdit()">
            <img class="button-icon" [src]="ASSETS.PLUS" />
            マイスポットの追加
        </ons-button>
    </div>
    <input type="hidden" id="result" name="result" />

</form>