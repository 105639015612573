//=============================================================================================
// インポート
//=============================================================================================
import { Inject, Injectable, OnDestroy } from '@angular/core';
import * as ons from 'onsenui';
import { Subscription } from 'rxjs';
import * as CONST from '../constants/constant';
import { MunicipalityWebApiService } from '../http-services/municipality-web-api.service';
import { Settings } from '../interfaces/response';
import { MESSAGE } from '../constants/message';

/**
 * ApplicaitonMessageサービス
 *
 * @export
 * @class ApplicationMessageService
 * @author （株）オブジェクトデータ wada
 */
@Injectable({
  providedIn: 'root'
})
export class ApplicationMessageService implements OnDestroy 
{
  readonly FATAL_MSG: string = "<br>一度時間を空けて操作いただくか、それでもエラーが発生する場合は、利用規約に記載の窓口連絡先までお問い合わせください。";

  readonly DISPATCH_ERROR: string = "配車情報を取得することができませんでした。";

  readonly UNEXPECTED_MSG: string = "想定外エラーが起こりました。";

  readonly CONTACT_MSG: string = "<br>詳細については、利用規約に記載の窓口連絡先までお問い合わせください。";

  /**
   * クライアントで個別に表示が必要なサーバコード群
   *
   * @memberof ApplicationMessageService
   */
  readonly SERV_CONST_CODE = 
  {
    COMMON: 
    {
      SUSPENDED_USER: "COMMON.SUSPENDED_USER",
      LOCATION_NOT_EXIST: "COMMON.LOCATION_NOT_EXIST",
      USER_TERM_NEVER_AGREED: "COMMON.USER_TERM_NEVER_AGREED",
    }, 
    DISPATCH: 
    {
      SUSPENDED_USER: "DISPATCH.SUSPENDED_USER", 
      QR_UNAUTH: "DISPATCH.QR_UNAUTH",
      OVER_DATE: "DISPATCH.OVER_DATE",
    }, 
    PURCHASE: 
    {
      SUSPENDED_USER: "PURCHASE.SUSPENDED_USER",
      GMO_TRAN_AUTH: "PURCHASE.GMO_TRAN_AUTH",
      GMO_TRAN_VOID: "PURCHASE.GMO_TRAN_VOID",
      GMO_TRAN_CHANGE: "PURCHASE.GMO_TRAN_CHANGE",
      METHOD_NO_PERMISSION: "PURCHASE.METHOD_NO_PERMISSION",
    }, 
    SQRC: 
    {
      DECODE: "SQRC.DECODE", 
      FACE_AUTH: "SQRC.FACE_AUTH",
      UPDATE_LIMIT_RATE: "SQRC.UPDATE_LIMIT_RATE"
    },
    EXP: {
      TIME_LIMIT: "SG.TIME_LIMIT", 
      ALREADY_DONE: "SG.ALREADY_DONE", 
      UPDATE_LIMIT: "SG.UPDATE_LIMIT", 
      CANCEL_LIMIT: "SG.CANCEL_LIMIT", 
      RESERVE_LIMIT: "SG.RESERVE_LIMIT",
      RESERVATION_NOT_FOUND: "SG.RESERVATION_NOT_FOUND", 
      ALREADY_CANCELED: "SG.ALREADY_CANCELED", 
      CANCEL_AS: "SG.CANCEL_AS", 
      DIFFERENT_CANCEL_RULE: "SG.DIFFERENT_CANCEL_RULE",
      NOT_AVAILABLE: "SG.NOT_AVAILABLE"
    },
    USER: {
      ILLEGAL_PINCODE: "USER.ILLEGAL_PINCODE"
    },
    LOGIN: {
      PIN_EXPIRED: "LOGIN.PIN_EXPIRED"
    },
    SG: {
      NOT_EXIST_PLAN_ID: "SG.NOT_EXIST_PLAN_ID", //
    },
    SG_OPTION: {
      UNMATCH_REQUEST: "SG_OPTION.UNMATCH_REQUEST"//
    },
    SG_OPTION_PRICE: {
      CALC_FAILED: "SG_OPTION_PRICE.CALC_FAILED"
    }
  }

  /**
   * 自治体毎設定情報
   *
   * @type {Settings}
   * @memberof ApplicationMessageService
   */
  setting: Settings;

  /**
   * OnDestroy時に破棄するSubscriptionオブジェクト
   *
   * @type {Subscription}
   * @memberof ApplicationMessageService
   */
  subscription = new Subscription();

  /**
   * Creates an instance of Message.
   * @memberof Message
   */
  constructor(
    private msg: MESSAGE,
    private municipalityWebApiServ: MunicipalityWebApiService,
  )
  {
    const settingsChanged = this.municipalityWebApiServ.settingsChanged.subscribe({
      next: setting => {
        if (setting == null) return;
        this.setting = setting;
      }
    });
    this.subscription.add(settingsChanged);
  }

  /**
   * 廃棄処理
   *
   * @memberof ApplicationMessageService
   */
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }

  public userNotification(msg: string, title: string = "ご案内"): Promise<HTMLElement> {
    // 運営事務所
    const office = this.municipalityWebApiServ.setting.office;

    const notificationMsg = `
<!DOCTYPE html>
<html>
  <head>
    <style>
      pre {
        font-size: 15px;
        width: 100%;
        border-top: solid 1px;
        white-space: pre-wrap;
        font-family: "Helvetica Neue", Helvetica, "Hiragino Sans", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
      }
    </style>
  </head>
  <body>
    <pre>

${ msg }

［住所］
${ office.zip ?? ""  }
${ office.address ?? ""  }
${ office.building_name ?? "" } ${ office.office_name }

［電話番号］
<a href="tel:${ office.tel.replace(/-/g, "") }">${ office.tel }</a>

［営業時間］
${ office.start_time }～${ office.end_time }（${ office.day_text }）</pre>
  </body>
</html>`;

    // ダイアログを表示
    return ons.notification.alert(notificationMsg, { title: title });
  }


  public shoppingCancelPolicy(): Promise<HTMLElement> {
    // 運営事務所
    const office = this.municipalityWebApiServ.setting.office;

    const notificationMsg = `
<!DOCTYPE html>
<html>
  <head>
    <style>
      pre {
        font-size: 15px;
        width: 100%;
        border-top: solid 1px;
        white-space: pre-wrap;
        font-family: "Helvetica Neue", Helvetica, "Hiragino Sans", "Hiragino Kaku Gothic ProN", Meiryo, sans-serif;
      }
    </style>
  </head>
  <body>
    <pre>

<b>期限までにキャンセルを行った場合</b>、請求料金を全額払い戻します。

<b>期限を過ぎてキャンセルを行いたい場合</b>、アプリからは操作できません。${office.office_name}までお問合せください。
また、その場合は料金の払い戻しはありません。

［電話番号］
<a href="tel:${ office.tel.replace(/-/g, "") }">${ office.tel }</a>

［営業時間］
${ office.start_time }～${ office.end_time }（${ office.day_text }）</pre>
  </body>
</html>`;

    // ダイアログを表示
    return ons.notification.alert(notificationMsg, { title: "キャンセルポリシー" });
  }

  /**
   * toastメッセージ(画面上部)を表示する。
   *
   * @param {string} msg
   * @return {*}  {Promise<HTMLElement>}
   * @memberof ApplicationMessageService
   */
  public viewToastMessage(msg: string, animation: string = "fall"): Promise<HTMLElement> {

    return ons.notification.toast(msg, { animation: animation, timeout: 4000 });
  }

  /**
   * アラートダイアログを表示
   *
   * @param {string} [message] ダイアログメッセージ
   * @param {(value: number)=>void} [callback] ダイアログclose後の処理
   * @return {*}  {Promise<HTMLElement>}
   * @memberof ApplicationMessageService
   */
  public viewDialogMessage(message?: string, callback?: (value: number)=>void): any {
    // 汎用メッセージ
    if (undefined === message) message = this.msg.UNEXPECTED_MSG;

    let options: AlertOptions = {title: null};
    if (callback) {
      options.callback = callback;
    }

    return ons.notification.alert(message, options);
  }

  /**
   * サーバーレスポンスを含めたクライアントメッセージ
   *
   * @param {string} [message]
   * @param {string} [responseMsg]
   * @param {(value: number)=>void} [callback]
   * @return {*} 
   * @memberof ApplicationMessageService
   */
  viewDialogErrorMessage(message?: string, responseMsg?: string, callback?: (value: number)=>void) {
    let resMsg: string = "";
    let clientMsg: string = this.UNEXPECTED_MSG;
    let options: AlertOptions = {title: null};

    // サーバからのレスポンスメッセージがある場合追記
    if (responseMsg != undefined && responseMsg != "") resMsg = "<br><br>詳細：<br>" + responseMsg;
    
    // エラーコードに紐づくメッセージを取得
    if (message != undefined) clientMsg = message;

    if (callback) options.callback = callback;

    return ons.notification.alert(clientMsg + resMsg, options);
  }

  /**
   * 確認ダイアログを表示
   * note: callbackのみを待ちたい時はasync,await不要
   *       このメソッドを同期処理としたい時はasync,awaitを付けて、ons.confirmをreturn
   * 
   * @param {string} msg メッセージ内容
   * @param {((value: typeof CONST.Common.CONFIRM_YES|typeof CONST.Common.CONFIRM_NO)=>void)} [callback] ダイアログclose後の処理
   *       Common.CONFIRM_YES: 「はい」を選択したときの返り値, Common.CONFIRM_NO: 「いいえ」を選択したときの返り値
   * @return {*}  {Promise<HTMLElement>}
   * @memberof ApplicationMessageService
   */
  public confirmMessage(msg: string, callback?: (value: typeof CONST.Common.CONFIRM_YES|typeof CONST.Common.CONFIRM_NO)=>void): Promise<HTMLElement> 
  {
    let options: AlertOptions = {
      title: CONST.Common.CONFIRM,
      buttonLabels: [CONST.Common.YES, CONST.Common.NO],
      primaryButtonIndex: 0,
      cancelable: true,
    }
    if (callback) {
      options.callback = callback;  // callbackがany型のため、valueをnumber型として扱うことが可能
    }
    // 確認ダイアログを表示
    return ons.notification.confirm(msg, options);
  }
}

/**
 * OnsenUIダイアログのオプション
 *
 * @interface AlertOptions
 */
interface AlertOptions {
  message?: string;
  messageHTML?: string;
  buttonLabel?: string;
  buttonLabels?: string[];
  primaryButtonIndex?: number;
  cancelable?: boolean;
  animation?: string;
  title?: string;
  modifier?: string;
  callback?: any;
  id?: string;
}