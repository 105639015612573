<ons-toolbar>
  <div class="left">
      <ons-back-button></ons-back-button>
  </div>
  <div class="center">{{ ticketTypeName }}購入</div>
</ons-toolbar>
<div class="content">
  <div [ngBusy]="busy"></div>
  <p class="content-text--left">下記の内容で{{ ticketTypeName }}を購入します。<br>よろしいですか？</p>

  <ons-list>
    <ons-list-header modifier="color" class="list-item__title">利用者</ons-list-header>
    <ons-list-item id="purchase-ticket-confirm_001" modifier="longdivider" class="list-item__text">
      {{ purchaseUser.profile.name.family_name + ' ' + purchaseUser.profile.name.given_name }} 様
    </ons-list-item>

    <ons-list-header modifier="color" class="list-item__title">利用開始日</ons-list-header>
    <ons-list-item id="purchase-ticket-confirm_002" modifier="longdivider" class="list-item__text">
        {{ moment(purchaseTargetTicket.start_date).format('YYYY/MM/DD') }}
    </ons-list-item>
    
    <ons-list-header modifier="color" class="list-item__title">乗車券種類</ons-list-header>
    <ons-list-item id="purchase-ticket-confirm_003" modifier="longdivider" class="list-item__text">
      <span>{{ purchaseTargetTicket.ticket_type_name }}</span>
    </ons-list-item>
    
    <ons-list-header modifier="color" class="list-item__title">利用終了日</ons-list-header>
    <ons-list-item id="purchase-ticket-confirm_004" modifier="longdivider" class="list-item__text">
      {{ moment(purchaseTargetTicket.end_date).format('YYYY/MM/DD') }}
      <span class="content--text--emphasis" *ngIf="purchaseTargetTicket.invalid !== undefined">
        （有効期限：{{ moment(purchaseTargetTicket.invalid.from).add(-1, 'days').format('YYYY/MM/DD') }}）
      </span>
    </ons-list-item>
    
    <div *ngIf="true === isSelectAreaTicket && purchaseTargetTicket.terms.special_type !== 'give_up_license'">
      <ons-list-header modifier="color" class="list-item__title">利用地区</ons-list-header>
      <ons-list-item id="purchase-ticket-confirm_005" modifier="longdivider" class="list-item__text">
        {{ purchaseTargetTicket.area.name }}
      </ons-list-item>
    </div>
    
    <ons-list-header modifier="color" class="list-item__title">料金</ons-list-header>
    <ons-list-item id="purchase-ticket-confirm_006" modifier="longdivider" class="list-item__text content--text--emphasis">
      ￥{{ purchaseTargetTicket.price | number:'.0-0'  }}<span>（{{ purchaseTargetTicket.name }}）</span>
    </ons-list-item>
    
    <div *ngIf="purchaseTargetTicket.continuable === true && purchaseTargetTicket.terms.special_type !== 'give_up_license'">
      <ons-list-header modifier="color" class="list-item__title">自動更新</ons-list-header>
      <ons-list-item id="purchase-ticket-confirm_007" modifier="longdivider" class="list-item__text">
        {{ m_bContinueStatus == true ? "自動継続購入する" : "自動継続購入しない"}}
      </ons-list-item>
    </div>
  </ons-list>
  
  <div class="button-wrap">
    <ons-button id="purchase-ticket-confirm_008" [disabled]="isRequesting" class="button button--large--cta" (click)="buyNewTicket()">
      購入を確定する
    </ons-button>
  </div>
</div>
