<ons-toolbar>
  <div class="left">
    <ons-back-button></ons-back-button>
  </div>
  <div class="center">サービス利用制限</div>
</ons-toolbar>
<div class="content">
  <div [ngBusy]="m_Busy"></div>

  <ons-list class="content--list--name">
    <ons-list-item modifier="longdivider" class="list-item__text">{{ m_Relation.name.family_name }} {{ m_Relation.name.given_name }}</ons-list-item>
  </ons-list>
  <br>
  <ons-list>
    <ons-list-header modifier="color" class="list-item__title">サービス利用制限</ons-list-header>
    <ons-list-item tappable modifier="longdivider" class="list-item__text">
      <div class="right">
        <ons-checkbox id="family-limit_001" input-id="checkUtilization" [(ngModel)]="m_bUtilization" (change)="changeUtilization()"></ons-checkbox>
      </div>
      <label for="checkUtilization" class="center">
        このアカウントのサービス利用を制限する
      </label>
    </ons-list-item>
    <ons-list-item modifier="longdivider" expandable>
      <div class="list-item__title">［サービス利用制限とは］</div>
      <div class="expandable-content content--list">
        <ul>
          <li>利用制限がかかったメンバーは、メンバー自身の操作によってサービスの予約や各種チケットの購入を行うことができません。また、管理者以外に代理予約を行ってもらうこともできません。</li>
          <li>利用制限中も、ログインや無料サービス利用は可能です。</li>
        </ul>
      </div>
    </ons-list-item>
  </ons-list>
  <div class="button-wrap">
    <ons-button id="family-limit_002" [disabled]="m_bDisabled" class="button button--large--cta" (click)="saveUtilization()">設定を保存</ons-button>
  </div>
</div>