// angular
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FullCalendarModule } from '@fullcalendar/angular'; // for FullCalendar!
import { BusyConfig, NgBusyModule } from 'ng-busy';
import { SwiperModule } from 'swiper/angular';
import localeJa from '@angular/common/locales/ja';
import * as moment from 'moment-timezone';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { CarouselModule } from 'ngx-owl-carousel-o';

// onsenui
import { OnsenModule } from 'ngx-onsenui';
import * as ons from 'onsenui';

// module
import { MaterialModule } from './lib-modules/material.module';
import { CommonFunctionModule } from "./lib-modules/common-function.module";
import { DispatchWebApiModule } from './http-modules/dispatch-web-api.module';
import { FavoriteWebApiModule } from './http-modules/favorite-web-api.module';
import { NewsWebApiModule } from './http-modules/news-web-api.module';
import { OperationWebApiModule } from './http-modules/operation-web-api.module';
import { SpotGeolocationModule } from './http-modules/spot-geolocation.module';
import { SqrcWebApiModule } from './http-modules/sqrc-web-api.module';
import { UserAgentModule } from './http-modules/user-agent.module';
import { UserWebApiModule } from './http-modules/user-web-api.module';
import { PurchaseWebApiModule} from './http-modules/purchase-web-api.module';
import { FamilyWebApiModule} from './http-modules/family-web-api.module';
import { ShoppingWebApiModule } from './http-modules/shopping-web-api.module';
import { HistoryWebApiModule } from "./http-modules/history-web-api.module";
import { SelectHistoryWebApiModule } from "./http-modules/select-history-web-api.module";
import { ExpWebApiModule } from './http-modules/exp-web-api.module';
import { ApplicationMessageService } from './lib-services/application-message.service';
import { MunicipalityWebApiModule } from './http-modules/municipality-web-api.module';

// pipe
import { TypeofPipe } from './lib-modules/typeof.pipe';
import { OmissionPipe } from './lib-modules/omission.pipe';
import { ChatDatePipe } from './lib-modules/chat-date.pipe';

// component
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { AllMunicipalitiesComponent } from './components/all-municipalities/all-municipalities.component';
import { TabbarComponent } from './components/tabbar/tabbar.component';
import { SigninComponent } from './components/signin/signin.component';
import { SplitterComponent } from './components/splitter/splitter.component';
import { SplitterSideMenuComponent } from './components/splitter/splitter-side-menu/splitter-side-menu.component';
import { RecommendedEnvironmentComponent } from './components/splitter/splitter-side-menu/recommended-environment/recommended-environment.component';
import { PincodeAuthComponent } from './components/pincode-auth/pincode-auth.component';
import { TermAgreeComponent } from './components/term-agree/term-agree.component';

// ---------------------
// User
// ---------------------
import { LoginComponent } from './components/user/login/login.component';
import { SignupComponent } from './components/user/signup/signup.component';
import { ProfileSignupComponent } from './components/user/profile-signup/profile-signup.component';
import { ProfileSignupDetermineComponent } from './components/user/profile-signup-determine/profile-signup-determine.component';
import { ProfileSignupCompleteComponent } from './components/user/profile-signup-complete/profile-signup-complete.component';
import { ProfileListComponent } from './components/user/profile-list/profile-list.component';
import { ProfileEditComponent } from './components/user/profile-edit/profile-edit.component';
import { ProfileLocationEditComponent } from './components/location/profile-location-edit/profile-location-edit.component';
import { ProfileSignupLocationEditComponent } from './components/user/profile-signup-location-edit/profile-signup-location-edit.component';

// ---------------------
// ChangePassword
// ---------------------
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ChangePasswordCompleteComponent } from './components/change-password/change-password-complete/change-password-complete.component';

// ---------------------
// Service
// ---------------------
import { ServiceComponent } from './components/service/service.component';
import { UseListComponent } from './components/service/use-list/use-list.component';

// ---------------------
// Reservation(Serviceから分岐)
// ---------------------
import { ReservationComponent } from './components/reservation/reservation.component';
import { UserReservationComponent } from './components/reservation/user-reservation/user-reservation.component';
import { BaggageComponent } from './components/reservation/_baggage/baggage.component';
import { RideCountComponent } from './components/reservation/ride-count/ride-count.component';
import { SearchMethodListComponent } from './components/reservation//search-method-list/search-method-list.component';
import { PlaceMapComponent } from './components/reservation//place-map/place-map.component';
import { SearchResultListComponent } from './components/reservation//search-result-list/search-result-list.component';
import { SearchResultDetailComponent } from './components/reservation//search-result-detail/search-result-detail.component';
import { PassengerFareComponent } from './components/reservation/_passenger-fare/passenger-fare.component';
import { UserReservationDetermineComponent } from './components/reservation//user-reservation-determine/user-reservation-determine.component';
import { UserReservationCompleteComponent } from './components/reservation//user-reservation-complete/user-reservation-complete.component';

// ---------------------
// CityNews(Serviceから分岐)
// ---------------------
import { NewsCategoryListComponent } from './components/news/news-category-list/news-category-list.component';
import { NewsArticleListComponent } from './components/news/news-article-list/news-article-list.component';
import { DisasterPreventionRelateSiteListComponent } from "./components/news/disaster-prevention-relate-site-list/disaster-prevention-relate-site-list.component";

// ---------------------
// Shopping(Serviceから分岐)
// ---------------------
import { ShoppingComponent } from './components/shopping/shopping.component';
import { ShoppingMenuListComponent } from './components/shopping/shopping-menu-list/shopping-menu-list.component';
import { ShoppingMenuDetailComponent } from './components/shopping/shopping-menu-detail/shopping-menu-detail.component';
import { ShoppingCartComponent } from './components/shopping/shopping-cart/shopping-cart.component';
import { ShoppingAddressComponent } from './components/shopping/shopping-address/shopping-address.component';
import { ShoppingDatermineComponent } from './components/shopping/shopping-datermine/shopping-datermine.component';
import { ShoppingCompleteComponent } from './components/shopping/shopping-complete/shopping-complete.component';

// ---------------------
// Exp(Serviceから分岐)
// ---------------------
import { ExpComponent } from './components/exp/exp/exp.component';
import { ExpDetailComponent } from './components/exp/exp-detail/exp-detail.component';
import { ExpShopServiceListComponent } from './components/exp/exp-shop-service-list/exp-shop-service-list.component';
import { ExpConfirmComponent } from './components/exp/exp-confirm/exp-confirm.component';
import { ExpSearchServiceListComponent } from './components/exp/exp-search-service-list/exp-search-service-list.component';
import { ExpCompleteComponent } from './components/exp/exp-complete/exp-complete.component';
import { ExpDetailAvailableDescriptionDialog, ExpDetailType1Component } from './components/exp/exp-detail-type1/exp-detail-type1.component';
import { ExpDetailOptionDialog } from './components/exp/exp-dialog/exp-detail-option-dialog.component';

// ---------------------
// History
// ---------------------
import { HistoryComponent } from './components/history/history.component';
import { HistoryListComponent } from './components/history/history-list/history-list.component';
import { HistoryDetailComponent } from './components/history/dispatch/history-detail/history-detail.component';
import { MovingPlanDetailComponent } from './components/history/dispatch/moving-plan-detail/moving-plan-detail.component';
import { ReservationCancelComponent } from './components/history/dispatch/reservation-cancel/reservation-cancel.component';
import { HistoryOrderDetailComponent } from './components/history/order/history-order-detail/history-order-detail.component';
import { OrderCancelComponent } from './components/history/order/order-cancel/order-cancel.component';
import { HistoryExpDetailComponent } from './components/history/exp/history-exp-detail/history-exp-detail.component';
import { ExpChangeComponent } from './components/history/exp/exp-change/exp-change.component';
import { ExpChangeDatermineComponent } from './components/history/exp/exp-change-datermine/exp-change-datermine.component';
import { ExpCancelComponent } from './components/history/exp/exp-cancel/exp-cancel.component';
import { ExpChangeType1Component } from './components/history/exp/exp-change-type1/exp-change-type1.component';

// ---------------------
// Myqr
// ---------------------
import { MyqrComponent } from './components/myqr/myqr.component';
import { MyqrIssueComponent } from './components/myqr/myqr-issue/myqr-issue.component';
import { QRCodeModule } from 'angularx-qrcode';

// ---------------------
// Operation
// ---------------------
import { OperationComponent } from './components/operation/operation.component';
import { OperationMapComponent } from './components/operation/operation-map/operation-map.component';

// ---------------------
// Account
// ---------------------
import { AccountComponent } from './components/account/account.component';
import { AccountListComponent } from './components/account/account-list/account-list.component';
import { AccountListOtherMenuComponent } from './components/account/account-list-other-menu/account-list-other-menu.component';

// ---------------- 以下、マイページタブから遷移

// ---------------------
// Profile(Userに包括)
// ---------------------

// ---------------------
// MySpot
// ---------------------
import { MyspotComponent } from './components/myspot/myspot.component';
import { MyspotEditComponent } from './components/myspot/myspot-edit/myspot-edit.component';

// ---------------------
// Purchase
// ---------------------
import { PurchaseTicketComponent } from './components/purchase/purchase-ticket/purchase-ticket.component';
import { PurchaseTicketOwnedComponent } from './components/purchase/purchase-ticket-owned/purchase-ticket-owned.component';
import { PurchaseTicketNewComponent } from './components/purchase/purchase-ticket-new/purchase-ticket-new.component';
import { PurchaseTicketConfirmComponent } from './components/purchase/purchase-ticket-confirm/purchase-ticket-confirm.component';
import { PurchaseDetailsComponent } from './components/purchase/purchase-details/purchase-details.component';

// ---------------------
// Family
// ---------------------
import { FamilyComponent } from './components/family/family.component';
import { FamilyDetailComponent } from './components/family/family-detail/family-detail.component';
import { FamilyInviteApprovalComponent } from './components/family/family-invite-approval/family-invite-approval.component';
import { FamilyLimitComponent } from './components/family/_family-limit/family-limit.component';
import { FamilyAddComponent } from './components/family/family-add/family-add.component';
import { FamilyDeleteComponent } from './components/family/family-delete/family-delete.component';
import { FamilyInviteQrComponent } from './components/family/family-invite-qr/family-invite-qr.component';
import { FamilyAuthStep2Component } from './components/family/_family-auth-step2/family-auth-step2.component';
import { FamilyInviteConfirmComponent } from './components/family/family-invite-confirm/family-invite-confirm.component';
import { FamilyDescriptionComponent } from './components/family/family-description/family-description.component';

// ---------------------
// ProxyAccount
// ---------------------
import { ProxyAccountSignupComponent } from './components/proxy-account/proxy-account-signup/proxy-account-signup.component';
import { ProxyAccountSignupDetermineComponent } from './components/proxy-account/proxy-account-signup-determine/proxy-account-signup-determine.component';
import { ProxyHandOverComponent } from './components/proxy-account/proxy-hand-over/proxy-hand-over.component';
import { ProxyAccountDescriptionComponent } from './components/proxy-account/proxy-account-description/proxy-account-description.component';
import { ProxyAccountProfileEditComponent } from './components/proxy-account/proxy-account-profile-edit/proxy-account-profile-edit.component';
import { ProxyAccountSignupLocationEditComponent } from './components/proxy-account/proxy-account-signup-location-edit/proxy-account-signup-location-edit.component';

// ---------------------
// PaymentMethod
// ---------------------
import { PaymentMethodListComponent } from './components/payment-method/payment-method-list/payment-method-list.component';
import { PaymentMethodAddComponent } from './components/payment-method/payment-method-add/payment-method-add.component';
import { PaymentMethodCreditAddComponent } from './components/payment-method/payment-method-credit-add/payment-method-credit-add.component';
import { PaymentMethodCreditDetaileComponent } from './components/payment-method/payment-method-credit-detaile/payment-method-credit-detaile.component';
import { PaymentMethodPostComponent } from './components/payment-method/payment-method-post/payment-method-post.component';

// ---------------------
// Notifiation
// ---------------------
import { NotificationMethodComponent } from './components/notification-method/notification-method.component';

// ---------------------
// Pincode
// ---------------------
import { PincodeAuthMethodComponent } from './components/pincode-auth-method/pincode-auth-method.component';

// ---------------------
// Rules(Accountに内包)
// ---------------------

// ---------------------
// Parts
// ---------------------
import { SwiperViewerComponent } from './components/parts/swiper-viewer/swiper-viewer.component';
import { OnsListComponent } from './components/parts/ons-list/ons-list.component';
import { SearchFormComponent } from './components/parts/search-form/search-form.component';
import { SwiperImagesComponent } from './components/parts/swiper-images/swiper-images.component';
import { QrReadComponent } from './components/parts/qr-read/qr-read.component';
import { LocationUpdateComponent } from './components/parts/location-update/location-update.component';
import { ImageComponent } from './components/parts/image/image.component';
import { CarouselComponent } from './components/parts/carousel/carousel.component';

// ---------------------
// constant
// ---------------------
import { DISPATCH_WEB_API_RESERVATION_TRANS_MAP } from './http-services/dispatch-web-api.service';
import { GEOLOCATION_DEFAULT_MAX_DISTANCE, GEOLOCATION_DEFAULT_POSITION } from './lib-services/geolocation.service';
import { USER_WEB_API_CARD_BRAND_MAP, USER_WEB_API_PAYMENT_METHOD_MAP } from './http-services/user-web-api.service';
// import { SMARTGOTO_OFFICE_POSITION } from './components/payment-method/payment-method';
import {
  OPERATION_MAP_CURRENT_POSITION_MARKER_ICON,
  // OPERATION_MAP_DEFAULT_POSITION,
  OPERATION_MAP_OPTIONS,
  OPERATION_MAP_VEHICLE_POSITION_MARKER_ICON
} from './components/operation/operation-map/operation-map';
import {
  PLACE_MAP_BUSSTOP_MARKER_ICON,
  PLACE_MAP_CURRENT_POSITION_MARKER_ICON,
  PLACE_MAP_MAP_OPTIONS,
  PLACE_MAP_MARKER_ICON,
  PLACE_MAP_RELATED_MARKER_ICON,
  PLACE_MAP_SEARCH_BOX_OPTIONS,
  PLACE_MAP_SELECTED_BUSSTOP_MARKER_ICON,
  PLACE_MAP_SELECTED_FAVORITE_MARKER_ICON,
  PLACE_MAP_SELECTED_MARKER_ICON
} from './components/reservation//place-map/place-map';
import {
  UserReservation,
  USER_RESERVATION_CONFIG
} from './components/reservation//user-reservation/user-reservation';
import { MESSAGE } from './constants/message';
import { WithdrawalComponent } from './components/user/withdrawal/withdrawal.component';

registerLocaleData(localeJa);

moment.locale('ja');
moment.tz.setDefault('Asia/Tokyo');

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    OperationComponent,
    ReservationComponent,
    ServiceComponent,
    AccountComponent,
    ShoppingComponent,
    TabbarComponent,
    UserReservationComponent,
    HistoryListComponent,
    HistoryDetailComponent,
    UseListComponent,
    RideCountComponent,
    BaggageComponent,
    SearchResultListComponent,
    SearchResultDetailComponent,
    SearchMethodListComponent,
    HistoryComponent,
    OperationMapComponent,
    AccountListComponent,
    ProfileListComponent,
    NotificationMethodComponent,
    MyspotComponent,
    UserReservationCompleteComponent,
    PlaceMapComponent,
    MyspotEditComponent,
    ProfileEditComponent,
    SigninComponent,
    SignupComponent,
    ProfileSignupComponent,
    MyqrComponent,
    MyqrIssueComponent,
    TypeofPipe,
    OmissionPipe,
    PassengerFareComponent,
    UserReservationDetermineComponent,
    PaymentMethodListComponent,
    PaymentMethodAddComponent,
    PaymentMethodCreditAddComponent,
    PaymentMethodCreditDetaileComponent,
    PaymentMethodPostComponent,
    PurchaseDetailsComponent,
    ProfileSignupDetermineComponent,
    ProfileSignupCompleteComponent,
    ChangePasswordComponent,
    ChangePasswordCompleteComponent,
    PurchaseTicketComponent,
    PurchaseTicketOwnedComponent,
    PurchaseTicketNewComponent,
    PurchaseTicketConfirmComponent,
    SplitterSideMenuComponent,
    RecommendedEnvironmentComponent,
    FamilyComponent,
    FamilyDetailComponent,
    FamilyInviteApprovalComponent,
    FamilyLimitComponent,
    FamilyAddComponent,
    FamilyInviteQrComponent,
    FamilyAuthStep2Component,
    FamilyInviteConfirmComponent,
    FamilyDeleteComponent,
    FamilyDescriptionComponent,
    ReservationCancelComponent,
    MovingPlanDetailComponent,
    ProxyAccountSignupComponent,
    ProxyAccountSignupDetermineComponent,
    ProxyHandOverComponent,
    ProxyAccountDescriptionComponent,
    ProxyAccountProfileEditComponent,
    MyqrIssueComponent,
    SplitterComponent,
    ShoppingComponent,
    NewsArticleListComponent,
    ShoppingMenuListComponent,
    ShoppingCartComponent,
    ShoppingAddressComponent,
    ShoppingMenuDetailComponent,
    ShoppingDatermineComponent,
    ShoppingCompleteComponent,
    HistoryOrderDetailComponent,
    OrderCancelComponent,
    ChatDatePipe,
    NewsCategoryListComponent,
    DisasterPreventionRelateSiteListComponent,
    ExpComponent,
    ExpDetailComponent,
    SwiperViewerComponent,
    ExpShopServiceListComponent,
    ExpSearchServiceListComponent,
    ExpConfirmComponent,
    OnsListComponent,
    SearchFormComponent,
    ExpCompleteComponent,
    HistoryExpDetailComponent,
    ExpChangeComponent,
    ExpChangeDatermineComponent,
    ExpCancelComponent,
    SwiperImagesComponent,
    PincodeAuthComponent,
    QrReadComponent,
    PincodeAuthMethodComponent,
    LocationUpdateComponent,
    ProfileLocationEditComponent,
    ProfileSignupLocationEditComponent,
    ProxyAccountSignupLocationEditComponent,
    ImageComponent,
    TermAgreeComponent,
    AllMunicipalitiesComponent,
    ExpDetailType1Component,
    ExpDetailOptionDialog,
    ExpChangeType1Component,
    AccountListOtherMenuComponent,
    WithdrawalComponent,
    ExpDetailAvailableDescriptionDialog,
    CarouselComponent,
  ],
  entryComponents: [
    LoginComponent,
    OperationComponent,
    ReservationComponent,
    ServiceComponent,
    AccountComponent,
    ShoppingComponent,
    TabbarComponent,
    UserReservationComponent,
    HistoryListComponent,
    HistoryDetailComponent,
    UseListComponent,
    RideCountComponent,
    BaggageComponent,
    SearchResultListComponent,
    SearchResultDetailComponent,
    SearchMethodListComponent,
    HistoryComponent,
    OperationMapComponent,
    AccountListComponent,
    ProfileListComponent,
    NotificationMethodComponent,
    MyspotComponent,
    MyspotEditComponent,
    MyqrComponent
  ],
  imports: [
    CommonFunctionModule,
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OnsenModule,
    FullCalendarModule,
    HttpClientModule,
    GoogleMapsModule,
    DragDropModule,
    MaterialModule,
    FlexLayoutModule,
    SwiperModule,
    InfiniteScrollModule,
    QRCodeModule,
    UserWebApiModule.withConfig({
      baseUrl: environment.base_url,
      areacode: environment.areacode,
      httpOptions: {
        headers: new HttpHeaders({
          // TODO: Basic 認証
          // Authorization: 'Basic ' + btoa('simplize:simplize-dev')
        })
      }
    }),
    DispatchWebApiModule.withConfig({
      baseUrl: environment.base_url + "/" + environment.areacode,
      httpOptions: {
        headers: new HttpHeaders({
          // TODO: Basic 認証
          // Authorization: 'Basic ' + btoa('simplize:simplize-dev')
        })
      }
    }),
    FavoriteWebApiModule.withConfig({
      baseUrl: environment.base_url + "/" + environment.areacode +'/favorite',
      httpOptions: {
        headers: new HttpHeaders({
          // TODO: Basic 認証
          // Authorization: 'Basic ' + btoa('simplize:simplize-dev')
        })
      }
    }),
    NewsWebApiModule.withConfig({
      baseUrl: environment.base_url + "/" + environment.areacode +'/news',
      httpOptions: {
        headers: new HttpHeaders({
          // TODO: Basic 認証
          // Authorization: 'Basic ' + btoa('simplize:simplize-dev')
        })
      }
    }),
    PurchaseWebApiModule.withConfig({
      baseUrl: environment.base_url + "/" + environment.areacode + '/purchase',
      httpOptions: {
        headers: new HttpHeaders({
          // TODO: Basic 認証
          // Authorization: 'Basic ' + btoa('simplize:simplize-dev')
        })
      }
    }),
    OperationWebApiModule.withConfig({
      baseUrl: environment.base_url + "/" + environment.areacode + '/operation',
      httpOptions: {
        headers: new HttpHeaders({
          // TODO: Basic 認証
          // Authorization: 'Basic ' + btoa('simplize:simplize-dev')
        })
      }
    }),
    FamilyWebApiModule.withConfig({
      baseUrl: environment.base_url + '/relation',
      areacode: environment.areacode,
      httpOptions: { headers: new HttpHeaders({}) }
    }),
    UserAgentModule.withConfig({
      recommendedEnvironment: environment.recommendedEnvironment
    }),
    SqrcWebApiModule.withConfig({
      baseUrl: environment.base_url + '/qr',
      areacode: environment.areacode,
      httpOptions: {
        headers: new HttpHeaders({
          // TODO: Basic 認証
          // Authorization: 'Basic ' + btoa('simplize:simplize-dev')
        })
      }
    }),
    ShoppingWebApiModule.withConfig({
      baseUrl: environment.base_url + '/' + environment.areacode,
      httpOptions: {
        headers: new HttpHeaders({
          // TODO: Basic 認証
          // Authorization: 'Basic ' + btoa('simplize:simplize-dev')
        })
      }
    }),
    HistoryWebApiModule.withConfig({
      baseUrl: environment.base_url + '/' + environment.areacode + '/search-history',
      httpOptions: {
        headers: new HttpHeaders({})
      }
    }),
    SelectHistoryWebApiModule.withConfig({
      baseUrl: environment.base_url + "/" + environment.areacode + '/select-history',
      httpOptions: {
        headers: new HttpHeaders({})
      }
    }),
    ExpWebApiModule.withConfig({
      baseUrl: environment.base_url + '/' + environment.areacode,
      httpOptions: {
        headers: new HttpHeaders({
          // TODO: Basic 認証
          // Authorization: 'Basic ' + btoa('simplize:simplize-dev')
        })
      }
    }),
    MunicipalityWebApiModule.withConfig({
      baseUrl: environment.base_url,
      areacode: environment.areacode,
      httpOptions: {
        headers: new HttpHeaders({
          // TODO: Basic 認証
          // Authorization: 'Basic ' + btoa('smart-goto:smart-goto-dev')
        })
      }
    }),

    SpotGeolocationModule.withConfig({
      // TODO: 必要に応じて調整。
      options: {
        positionOptions: {
          enableHighAccuracy: true,
          maximumAge: 0,
          timeout: 10000
        },
        timerOptions: {
          delay: 1000
        }
      },
      addressFormat: {
        orders: [
          { type: "locality", name: "long_name" },
          { type: "sublocality_level_1", name: "long_name" },
          { type: "sublocality_level_2", name: "long_name" },
          { type: "sublocality_level_3", name: "long_name" },
          { type: "sublocality_level_4", name: "long_name" },
          { type: "premise", name: "long_name" }
        ],
        hyphens: [
          {
            left: "sublocality_level_4",
            right: "premise",
            char: "－"
          }
        ]
      }
    }),
    ServiceWorkerModule.register('custom-service-worker.js', { enabled: true }),
    BrowserAnimationsModule,
    NgBusyModule.forRoot(new BusyConfig({
      // TODO: 必要に応じて既定値を設定する。
      message: 'しばらくお待ちください...'
    })),
    CarouselModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: "ja-JP" },
    {
      provide: GEOLOCATION_DEFAULT_MAX_DISTANCE,
      // TODO: 必要に応じて調整。
      useValue: 50000
    },
    {
      provide: PLACE_MAP_MAP_OPTIONS,
      useValue: {
        disableDefaultUI: true,
        mapId: environment.googleMapId,
      } as google.maps.MapOptions
    },
    {
      provide: PLACE_MAP_MARKER_ICON,
      useValue: {
        url: CommonFunctionModule.getAssetsUrl("/image/common/13-Spot_Inactive.png"),
        scaledSize: new google.maps.Size(32, 32)
      } as google.maps.Icon
    },
    {
      provide: PLACE_MAP_CURRENT_POSITION_MARKER_ICON,
      useValue: {
        url: CommonFunctionModule.getAssetsUrl("/image/common/53-Currentplace_A.png"),
        scaledSize: new google.maps.Size(32, 32)
      } as google.maps.Icon
    },
    {
      provide: PLACE_MAP_SELECTED_MARKER_ICON,
      useValue: {
        url: CommonFunctionModule.getAssetsUrl("/image/common/13-Spot_Active.png"),
        scaledSize: new google.maps.Size(32, 32)
      } as google.maps.Icon
    },
    {
      provide: PLACE_MAP_SELECTED_FAVORITE_MARKER_ICON,
      useValue: {
        url: CommonFunctionModule.getAssetsUrl("/image/common/46-PinC_Active_Pink.png"),
        scaledSize: new google.maps.Size(32, 32)
      } as google.maps.Icon
    },
    {
      provide: PLACE_MAP_BUSSTOP_MARKER_ICON,
      useValue: {
        // TODO: バス停のアイコンを指定すること。
        url: CommonFunctionModule.getAssetsUrl("/image/common/Busstop.png"),
        // 画像サイズに合わせ調整
        scaledSize: new google.maps.Size(20, 34.2),
      } as google.maps.Icon
    },
    {
      provide: PLACE_MAP_SELECTED_BUSSTOP_MARKER_ICON,
      useValue: {
        // TODO: バス停のアイコンを指定すること。
        url: CommonFunctionModule.getAssetsUrl("/image/common/06-Busstop_Active.png"),
        scaledSize: new google.maps.Size(64, 64)
      } as google.maps.Icon
    },
    {
      provide: PLACE_MAP_RELATED_MARKER_ICON,
      useValue: {
        url: CommonFunctionModule.getAssetsUrl("/image/common/13-Spot_Inactive.png"),
        scaledSize: new google.maps.Size(32, 32)
      } as google.maps.Icon
    },
    {
      // 運行状況画面：現在位置アイコン
      provide: OPERATION_MAP_CURRENT_POSITION_MARKER_ICON,
      useValue: {
        url: CommonFunctionModule.getAssetsUrl("/image/common/53-Currentplace_A.png"),
        scaledSize: new google.maps.Size(32, 32)
      } as google.maps.Icon
    },
    {
      // 地図表示オプション（ズームの+-、人形アイコンOFF）
      provide: OPERATION_MAP_OPTIONS,
      useValue: {
        disableDefaultUI: true,
        mapId: environment.googleMapId,
      } as google.maps.MapOptions
    },
    {
      // 車両マーカアイコン
      provide: OPERATION_MAP_VEHICLE_POSITION_MARKER_ICON,
      useValue: {
        url: CommonFunctionModule.getAssetsUrl("/image/common/56-Car_D.png"),
        scaledSize: new google.maps.Size(32, 32)
      } as google.maps.Icon
    },
    {
      provide: USER_RESERVATION_CONFIG,
      // TODO: 必要に応じて調整。
      useValue: {
        reservableDays: 7,
        guest: {
          validTime: 20*60,
          slideEachExpirations: true,
          reenableExpiration: true
        }
      } as UserReservation.Config
    },
    {
      provide: DISPATCH_WEB_API_RESERVATION_TRANS_MAP,
      // TODO: 必要に応じて調整。
      useValue: new Map<string, Map<string, string>>([
        ["walk", new Map<string, string>([
          ["icon", CommonFunctionModule.getAssetsUrl("/image/common/44-Walk_Box_B.png")]
        ])],
        ["smartgoto", new Map<string, string>([
          ["icon", CommonFunctionModule.getAssetsUrl("/image/common/09-SmartGOTO_Box_B.png")]
        ])],
        ["bus", new Map<string, string>([
          ["icon", CommonFunctionModule.getAssetsUrl("/image/common/10-Bus_Box_B.png")]
        ])]
      ])
    },
    {
      provide: USER_WEB_API_CARD_BRAND_MAP,
      // TODO: 必要に応じて調整。
      // key - 'AMEX', 'DINERS', 'VISA', 'JCB', 'MASTER'
      useValue: new Map<string, Map<string, string>>([
        ["AMEX", new Map<string, string>([
          ["icon", CommonFunctionModule.getAssetsUrl("/image/logo/logo_AXP.jpg")]
        ])],
        ["DINERS", new Map<string, string>([
          ["icon", CommonFunctionModule.getAssetsUrl("/image/logo/logo_Diners.jpg")]
        ])],
        ["VISA", new Map<string, string>([
          ["icon", CommonFunctionModule.getAssetsUrl("/image/logo/logo_visa.jpg")]
        ])],
        ["JCB", new Map<string, string>([
          ["icon", CommonFunctionModule.getAssetsUrl("/image/logo/logo_JCB.gif")]
        ])],
        ["MASTER", new Map<string, string>([
          ["icon", CommonFunctionModule.getAssetsUrl("/image/logo/logo_master.png")]
        ])]
      ])
    },
    {
      provide: USER_WEB_API_PAYMENT_METHOD_MAP,
      useValue: new Map<string, Map<string, string>>([
        ["SLIP", new Map<string, string>([
          ["icon", CommonFunctionModule.getAssetsUrl("/image/logo/logo_slip.png")]
        ])],
        ["YUCHO", new Map<string, string>([
          ["icon", CommonFunctionModule.getAssetsUrl("/image/logo/logo_yucho.jpg")]
        ])],
        ["YUCHO_FULL", new Map<string, string>([
          ["icon", CommonFunctionModule.getAssetsUrl("/image/logo/logo_yucho_full.png")]
        ])],
        ["JABANK", new Map<string, string>([
          ["icon", CommonFunctionModule.getAssetsUrl("/image/logo/logo_ja.png")]
        ])],
        ["BANK77", new Map<string, string>([
          ["icon", CommonFunctionModule.getAssetsUrl("/image/logo/logo_77.png")]
        ])],
        ["BANK77_FULL", new Map<string, string>([
          ["icon", CommonFunctionModule.getAssetsUrl("/image/logo/logo_77_full.png")]
        ])],
        ["FREE_BANK", new Map<string, string>([
          ["icon", CommonFunctionModule.getAssetsUrl("/image/logo/logo_free_bank.png")]
        ])],
      ])
    },
    // {
    //   provide: SMARTGOTO_OFFICE_POSITION,
    //   // TODO: 必要に応じて調整。
    //   useValue: {
    //     lat: 32.9853614,
    //     lng: 129.0847464
    //   }
    // },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor(
    updates: SwUpdate,
    appMsgServ: ApplicationMessageService,
    msg: MESSAGE,) {
    // console.log('build date:', environment.releaseDate);
    // console.log(`environment: ${environment.env}`);

    ons.disableAutoStyling();

    // 定周期でバージョン取得
    // const versionUpdateSubscription = interval(20 * 1000).subscribe(async () => {
    //   try {
    //     console.log('check for update');
    //     await updates.checkForUpdate();
    //     await updates.activateUpdate();
    //   }
    //   catch (err) {
    //     console.error('Failed to check for updates:', err);
    //   }
    // });
    // 新バージョン検知イベントハンドラ
    updates.available.subscribe(available => {
      console.log('new version available', available);
      appMsgServ.viewDialogMessage(msg.CLIENT.COMMON.NEW_VERSION_AVAILABLE.message(), () => location.reload());
      // versionUpdateSubscription.unsubscribe();
    });
  }
}
