//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnDestroy, OnInit } from '@angular/core';
import { OnsNavigator, Params } from 'ngx-onsenui';
import { Subscription } from 'rxjs';
import * as moment from 'moment-timezone';
import * as CONST from '../../../constants/constant';

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service'
import { DispatchWebApiService } from '../../../http-services/dispatch-web-api.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { FamilyWebApiService } from '../../../http-services/family-web-api.service';

// compoent
import { UserReservationCompleteComponent } from '../user-reservation-complete/user-reservation-complete.component';
import { UserReservation } from '../user-reservation/user-reservation';
import { SearchResultList } from '../search-result-list/search-result-list';

// interface
import { common } from '../../../interfaces/common';
import { request } from '../../../interfaces/request';
import { Reservation } from '../../../interfaces/response';
import { MunicipalityWebApiService } from 'src/app/http-services/municipality-web-api.service';
import { MESSAGE } from 'src/app/constants/message';

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 予約画面。
 *
 * @export
 * @class UserReservationDetermineComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'ons-page[user-reservation-determine]',
  templateUrl: './user-reservation-determine.component.html',
  styleUrls: ['./user-reservation-determine.component.css']
})
export class UserReservationDetermineComponent implements OnInit, OnDestroy 
{

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * 日付と時刻を扱うためのユーティリティ (Moment オブジェクト)。
   *
   * @memberof UserReservationDetermineComponent
   */
  readonly moment = moment;

  /**
   * 移動プランの検索条件。
   *
   * @type {UserReservation.SearchCondition}
   * @memberof UserReservationDetermineComponent
   */
  searchCondition: UserReservation.SearchCondition;

  /**
   * 移動プラン。
   *
   * @type {SearchResultList.Planner}
   * @memberof UserReservationDetermineComponent
   */
  planner: SearchResultList.Planner;

  /**
   * 予約情報。
   *
   * @type {Reservation}
   * @memberof UserReservationDetermineComponent
   */
  reservation: Reservation;

  /**
   * 非同期処理の実行状態を表す。
   *
   * @type {Subscription}
   * @memberof UserReservationDetermineComponent
   */
  busy: Subscription;

  /**
   * 予約パラメータ
   *
   * @private
   * @type {request.InquiryReserve}
   * @memberof UserReservationDetermineComponent
   */
  private inquiryReserveRequestParams: request.InquiryReserve;

  /**
   * 利用者ごとの料金、利用チケット情報
   *
   * @type {common.customerBill}
   * @memberof UserReservationDetermineComponent
   */
  customerBill: common.customerBill;

  /**
   * サーバに予約確定リクエスト実行中かどうか
   *
   * @type {boolean}
   * @memberof UserReservationDetermineComponent
   */
  isRequesting: boolean = false;

  /**
   * キャンセルポリシーバインド値
   *
   * @memberof ReservationCancelComponent
   */
  cancelDescription =
  {
    ticket_name:
    {
      one: CONST.Purchase.TICKET_TYPE_NAME.NONE,
      season: CONST.Purchase.TICKET_TITLE_CITIZEN
    },
    limit: 0,
    fee: 0
  }

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of UserReservationDetermineComponent.
   * @param {OnsNavigator} _navigator ページスタックの管理とナビゲーション機能を提供するコンポーネント。
   * @param {ApplicationMessageService} appMsg
   * @param {DispatchWebApiService} dispatchWebApiService Web API (予約関連) の呼び出しを簡略化するためのユーティリティ。
   * @param {HttpErrorResponseParserService} httpErrorResponseParserService `HttpErrorResponse` オブジェクトを SmartGOTO の形式にカスタマイズするためのユーティリティ。
   * @param {UserWebApiService} userWebApiService
   * @param {Params} params 遷移元のページから渡されるパラメーター。
   * @memberof UserReservationDetermineComponent
   */
  constructor(
    private _navigator: OnsNavigator,
    private appMsg: ApplicationMessageService, 
    private familyWebApiService: FamilyWebApiService,
    private dispatchWebApiService: DispatchWebApiService,
    private httpErrorResponseParserService: HttpErrorResponseParserService,
    private params: Params,
    private municipalityWebApiServ: MunicipalityWebApiService,
    private msg: MESSAGE,
  ) { }

  /**
   * コンポーネントが初期化される際に呼び出されるメソッド。
   *
   * @memberof UserReservationDetermineComponent
   */
  ngOnInit(): void 
  {
    [this.searchCondition, this.inquiryReserveRequestParams, this.planner, this.reservation, this.customerBill] = this.params.data;

    // 予約日時に合ったキャンセルルールを取得
    const cancelRule = this.dispatchWebApiService.getCancelRule(this.reservation.d.schd_time);
    this.cancelDescription.limit = cancelRule.limit;
    this.cancelDescription.fee = cancelRule.ratio*100;
    // if (this.userWebApiService.getUserInfo().user_type === "tourist") this.cancelDescription.ticket_name.season = CONST.Purchase.TICKET_TITLE_TOURIST;
  }

  /**
   * コンポーネントが破棄される際に呼び出されるメソッド。
   *
   * @memberof UserReservationDetermineComponent
   */
  ngOnDestroy(): void 
  {
    this.busy?.unsubscribe();
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * 料金種別（文字列）を取得する。
   *
   * @param {string} property
   * @return {*}  {string}
   * @memberof UserReservationDetermineComponent
   */
  getAmoutProperty(property: string): string 
  {
    return this.dispatchWebApiService.getAmountPropertyString(property);
  }
  
  /**
   * 予約を確定する。
   *
   * @memberof UserReservationDetermineComponent
   */
  determine(): void 
  {
    // ファミリー管理者から制限されている場合は予約不可
    this.busy = this.familyWebApiService.getFamily().subscribe();
    if (this.familyWebApiService.isLoginUserUtilization())
    {
      this.appMsg.viewDialogMessage(this.msg.CLIENT.FAMILY.RESTRICTED_UTILIZATION.message());
      return;
    }

    // 予約の確定
    this.determineReserv();
  }

  /**
   * 予約の確定
   *
   * @memberof UserReservationDetermineComponent
   */
  determineReserv(): void {

    // 2重送信対策
    if (this.isRequesting) {
      // console.log("2重クリックのためリクエストロック中");
      return;
    }
    this.isRequesting = true;

    // 予約を確定
    this.busy = this.dispatchWebApiService.reserve({
        date: this.planner.date,
        request_id: this.planner.requestId,
        index: this.reservation.index,
        o: this.inquiryReserveRequestParams.o,
        d: this.inquiryReserveRequestParams.d,
        user_ids: this.searchCondition.getPassengersUserIds()
      }).subscribe({
        next: _response => {

          this.isRequesting = false;

          this._navigator.element.pushPage(UserReservationCompleteComponent, { data: [this.searchCondition] });
        },
        error: this.httpErrorResponseParserService.doParse((_err, errContent) => {

          this.isRequesting = false;
          
          // 予約可能日数以降の時刻を選択している場合
          if (errContent.smartGotoErrCode === this.appMsg.SERV_CONST_CODE.DISPATCH.OVER_DATE) {
            this.appMsg.viewDialogMessage(this.msg.CLIENT.DISPATCH.E_OVER_DATE.message((
              this.municipalityWebApiServ.setting.dispatch.limit + 1) + '',
              this.municipalityWebApiServ.setting.dispatch.limit + ''
            ), () => this.searchCondition.returnHere());
          }
          // GMOエラー
          // NOTE: サーバーエラー(オプション含め)から判別することができないため、クライアントメッセージとしている
          else if (errContent.smartGotoErrCode === this.appMsg.SERV_CONST_CODE.PURCHASE.GMO_TRAN_AUTH) {
            this.httpErrorResponseParserService.viewErrDialog(errContent, "RESERVE").then(() => {this.searchCondition.returnHere();});
          }
          // 運営債権支払い方法、購入不可エラー
          else if (errContent.smartGotoErrCode === this.appMsg.SERV_CONST_CODE.PURCHASE.METHOD_NO_PERMISSION) {
            this.appMsg.viewDialogMessage(this.msg.CLIENT.PURCHASE.METHOD_NO_PERMISSION_RESERVE.message(), () => {
              this.searchCondition.returnHere();
            });
          }
          else {
            this.httpErrorResponseParserService.viewErrDialog(errContent).then(() => {
              this.searchCondition.returnHere();
            });
          }
        })
      });
  }

  /**
   * セキュリティポリシーを表示する。
   *
   * @memberof UserReservationDetermineComponent
   */
  onCancelPolicy(): void {

    this.appMsg.shoppingCancelPolicy();
  }

  /**
   * 利用規約を表示
   *
   * @memberof NewsCategoryListComponent
   */
  viewTermsOfService(): void {
    window.open(this.municipalityWebApiServ.setting.term);
  }

}