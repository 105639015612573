import { Injectable, InjectionToken, OnDestroy } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { UserReservation } from '../components/reservation/user-reservation/user-reservation';
import { request } from '../interfaces/request';
import { Settings } from '../interfaces/response';

import { MunicipalityWebApiService } from './municipality-web-api.service';

/**
 * 構成設定。
 *
 * @export
 * @class SpotGeolocationServiceConfig
 */
export class SpotGeolocationServiceConfig {
  /**
   * 運用の対象となる地域の境界。
   *
   * @type {google.maps.LatLngBounds}
   * @memberof SpotGeolocationServiceConfig
   */
  maxValidBounds?: google.maps.LatLngBounds;

  /**
   * ラップされたメソッドへの引数。
   *
   * @type {SpotGeolocationService.Options}
   * @memberof SpotGeolocationServiceConfig
   */
  options?: SpotGeolocationService.Options;

  /**
   * 住所 (`GeocoderAddressComponent[]`) の書式化に関する設定。
   *
   * @type {{
   *     orders: {
   *       type: string;
   *       name: string;
   *     }[],
   *     hyphens: {
   *       left: string;
   *       right: string;
   *       char: string;
   *     }[]
   *   }}
   * @memberof SpotGeolocationServiceConfig
   */
  addressFormat?: {
    /**
     * 書式化する項目を表示順に並べたリスト。
     *
     * @type {{
     *       type: string;
     *       name: string;
     *     }[]}
     */
    orders: {
      /**
       * 書式化の対象となる種別 (`types` プロパティに含まれる値)。
       *
       * @type {string}
       */
      type: string;

      /**
       * 書式化の対象となるプロパティ名 (`long_name`、または `short_name`)。
       *
       * @type {string}
       */
      name: string;
    }[],
    /**
     * ハイフンを挿入する位置。
     *
     * @type {{
     *       left: string;
     *       right: string;
     *       char: string;
     *     }[]}
     */
    hyphens: {
      /**
       * ハイフンの左側の項目の種別 (`types` プロパティに含まれる値)。
       *
       * @type {string}
       */
      left: string;

      /**
       * ハイフンの右側の項目の種別 (`types` プロパティに含まれる値)。
       *
       * @type {string}
       */
      right: string;

      /**
       * ハイフンとして挿入される文字。
       *
       * @type {string}
       */
      char: string;
    }[]
  };
}

/**
 * デバイスやスポットの位置情報の扱いを簡略化するためのユーティリティ。
 *
 * @export
 * @class SpotGeolocationService
 */
@Injectable({
  providedIn: 'root'
})
export class SpotGeolocationService implements OnDestroy {

  /**
   * geogoder
   *
   * @private
   * @memberof SearchResultListComponent
   */
  private geocoder = new google.maps.Geocoder();

  /**
   * OnDestroy時に破棄するSubscriptionオブジェクト
   *
   * @private
   * @memberof SpotGeolocationService
   */
  private subscription = new Subscription();

  /**
   * Creates an instance of SpotGeolocationService.
   * @param {SpotGeolocationServiceConfig} config
   * @param {MunicipalityWebApiService} municipalityWebApiServ
   * @memberof SpotGeolocationService
   */
  constructor(
    private config: SpotGeolocationServiceConfig,
    private municipalityWebApiServ: MunicipalityWebApiService,
  ) {
    const settingsChanged = this.municipalityWebApiServ.settingsChanged.subscribe({
      next: setting => {
        if (setting == null) return;
        // 運用の対象となる地域の境界
        this.config.maxValidBounds = new google.maps.LatLngBounds(
          setting.map.region.sw, setting.map.region.ne
        );
      }
    });
    this.subscription.add(settingsChanged);
  }

  /**
   * 廃棄処理
   *
   * @memberof SpotGeolocationService
   */
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();    
  }
  
  /**
   * `Coordinates` オブジェクトに含まれる『緯度/経度』を元にして `google.maps.LatLng` オブジェクトを生成する。
   *
   * @param {Coordinates} coords
   * @return {*}  {google.maps.LatLng}
   * @memberof SpotGeolocationService
   */
  createLatLng(coords: Coordinates): google.maps.LatLng {
    return new google.maps.LatLng(coords.latitude, coords.longitude);
  }

  /**
   * デバイスの現在位置を取得する。
   *
   * @param {SpotGeolocationService.Options} [options] オプション。
   * @return {*}  {Observable<{
   *     position?: Position;
   *     positionError?: PositionError;
   *   }>} 位置情報 (`Position` オブジェクト)、あるいは位置情報の取得時に発生したエラーの理由 (`PositionError` オブジェクト) を受け取るための `Observable`。
   * 位置情報にアクセスできない場合は `position`、および `positionError` のいずれも未定義。
   * @memberof SpotGeolocationService
   */
  getCurrentPosition(options?: SpotGeolocationService.Options): Observable<{
    position?: Position;
    positionError?: PositionError;
  }> {
    return new Observable(subscriber => {
      if (!navigator.geolocation) {
        subscriber.next({});
        subscriber.complete();
      } else {
        const getCurrentPositionInternal = () => {
          setTimeout(() => {
            navigator.geolocation.getCurrentPosition(
              position => {
                if (!subscriber.closed) {
                  subscriber.next({
                    position: position
                  });
                  subscriber.complete();
                }
              },
              positionError => {
                if (!subscriber.closed) {
                  subscriber.next({
                    positionError: positionError
                  });
                  subscriber.complete();
                }
              },
              (options || this.config?.options)?.positionOptions
            );
          }, 0);
        };

        const intervalId = setInterval(
          () => {
            if (subscriber.closed) {
              clearInterval(intervalId);
            } else {
              getCurrentPositionInternal();
            }
          },
          (options || this.config?.options)?.timerOptions?.delay || 1000
        );

        getCurrentPositionInternal();
      }
    });
  }

  /**
   * スポット情報から住所を成形して取得する。
   *    住所が取れない場合、ジオメトリまたは、位置情報から
   *    GoogleAPIを使用して住所を取得し、成形して返却する。
   *
   * @param {UserReservation.SearchConditionSpot} spot
   * @return {*}  {request.ReservationPlace}
   * @memberof SpotGeolocationService
   */
  getGeocoderPlaceResultAddress(spot: UserReservation.SearchConditionSpot): request.ReservationPlace {
    
    let result: request.ReservationPlace = {
      place_id: spot.placeResult.place_id,
      location: spot.placeResult.geometry.location.toJSON(),
      name: spot.placeResult.name,
      search_text: spot.searchText ? spot.searchText : undefined
    };

    // 住所を成形して取得
    const formatAddress: string = (spot.placeResult?.address_components && this.formatPlaceResultAddress(spot.placeResult)) ??
    (spot.geocoderResult?.address_components && this.formatGeocoderResultAddress(spot.geocoderResult));

    // 住所が取得できなかったら
    if (!formatAddress) {

      const geocoderParam = (() => {
        // ポイントジオメトリまたは、マイスポットの位置情報があるなら、その情報を使用する
        const isSearchResultOrFavoriteSpot = spot.placeResult?.geometry || spot.favorite?.location;
        if (isSearchResultOrFavoriteSpot) return { location: spot.placeResult?.geometry.location ?? spot.favorite.location };
      })();

      if (!geocoderParam) return result;

      // google maps APIを使って地理情報から住所を取得し、サーバ通信に用いるため、
      // 全体をPromise化し、処理完了まで待機する
      return new Promise((res, rej) => {

        // 地理情報から住所取得
        this.geocoder.geocode(geocoderParam, (results, status) => {
          
          switch (status) {
            case google.maps.GeocoderStatus.OK:
              if (results[0].geometry) result.address = this.formatGeocoderResultAddress(results[0]);

              // promise完了
              // 住所取得成功のため、addressあり
              res(result as request.ReservationPlace);
              break;
            default: 
              // 住所取得失敗のため、addressなし
              rej(result as request.ReservationPlace);
              break;
          }
        });
      }) as request.ReservationPlace;
    }
    else {
      // 住所を設定
      result.address = formatAddress;
      return result;
    }
  }

  /**
   * 住所から緯度経度を取得。
   *
   * @param {string} address 住所
   * @return {*}  {Observable<google.maps.LatLng>}
   * @memberof SpotGeolocationService
   */
  getLocationByAddress(address: string): Observable<google.maps.LatLng> {
    return new Observable(subscriber => { 
      new google.maps.Geocoder().geocode({address}, (result, status) => {
        // 正常系(google.maps.GeocoderStatus.OK)以外はエラー
        if (status != google.maps.GeocoderStatus.OK) {
          subscriber.error(status);
          subscriber.complete();
          return;
        }

        subscriber.next(result[0].geometry.location);
        subscriber.complete();
      });
    });
  }

  /**
   * 住所を書式化する。
   *
   * @param {google.maps.GeocoderResult | google.maps.places.PlaceResult} geocoderResult 書式化の対象となる `google.maps.GeocoderResult` オブジェクト。
   * @return {*}  {string} 書式化された住所。
   * @memberof SpotGeolocationService
   */
  formatGeocoderResultAddress(geocoderResult: google.maps.GeocoderResult | google.maps.places.PlaceResult): string {
    return this.formatGeocoderOrPlaceResultAddress(geocoderResult);
  }

  /**
   * 住所を書式化する。
   *
   * @param {google.maps.places.PlaceResult} placeResult 書式化の対象となる `google.maps.places.PlaceResult` オブジェクト。
   * @return {*}  {string} 書式化された住所。
   * @memberof SpotGeolocationService
   */
  formatPlaceResultAddress(placeResult: google.maps.places.PlaceResult): string {
    return this.formatGeocoderOrPlaceResultAddress(placeResult);
  }

  private formatGeocoderOrPlaceResultAddress(geocoderOrPlaceResul: google.maps.GeocoderResult | google.maps.places.PlaceResult): string {
    if (!this.config.addressFormat) {
      return geocoderOrPlaceResul.formatted_address;
    }

    if (!geocoderOrPlaceResul.address_components || !Array.isArray(geocoderOrPlaceResul.address_components)) {
      geocoderOrPlaceResul.address_components = [];
    }

    const ordered = this.config.addressFormat.orders.reduce<{ type: string; name: string; }[]>((previousValue, currentValue) => {
      let value = previousValue;

      const component = geocoderOrPlaceResul.address_components.find(component => component.types.includes(currentValue.type));

      if (component) {
        value.push({
          type: currentValue.type,
          name: component[currentValue.name]
        });
      }

      return value;
    }, []);

    return ordered.reduce((previousValue, currentValue, currentIndex, array) => {
      let value = `${previousValue}${currentValue.name}`;

      const nextValue = array[currentIndex + 1];

      if (nextValue) {
        this.config.addressFormat.hyphens.filter(hyphen => (hyphen.left == currentValue.type) && (hyphen.right == nextValue.type)).forEach(hyphen => {
          value = `${value}${hyphen.char}`;
        });
      }

      return value;
    }, "");
  }

  /**
   * 運用の対象となる地域の境界。
   *
   * @readonly
   * @type {google.maps.LatLngBounds}
   * @memberof SpotGeolocationService
   */
  get maxValidBounds(): google.maps.LatLngBounds {
    return new google.maps.LatLngBounds(this.config?.maxValidBounds?.getSouthWest(), this.config?.maxValidBounds?.getNorthEast());
  }
}

export namespace SpotGeolocationService {
  /**
   * タイマーに関するオプション。
   *
   * @export
   * @interface TimerOptions
   */
  export interface TimerOptions {
    /**
     * インターバルタイマーの遅延時間 (ミリ秒単位)。
     *
     * @type {number}
     * @memberof TimerOptions
     */
    delay?: number;
  }

  /**
   * ラップされたメソッドへの引数。
   *
   * @export
   * @interface Options
   */
  export interface Options {
    /**
     * 位置情報の取得に関するオプション。
     *
     * @type {PositionOptions}
     * @memberof Options
     */
    positionOptions?: PositionOptions;

    /**
     * タイマーに関するオプション。
     *
     * @type {TimerOptions}
     * @memberof Options
     */
    timerOptions?: TimerOptions;
  }
}