//=============================================================================================
// インポート
//=============================================================================================
import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';
import { OnsNavigator, Params } from 'ngx-onsenui';
import { forkJoin, fromEvent, Observable, Subject, Subscription } from 'rxjs';
import { HTML5_FMT } from 'moment';
import * as moment from 'moment-timezone';

// calendar
import { FullCalendarComponent, CalendarOptions, DayCellMountArg } from '@fullcalendar/angular';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';

// service
import { ApplicationMessageService } from '../../../lib-services/application-message.service';
import { HttpErrorResponseParserService } from '../../../lib-services/http-error-response-parser.service';
import { DispatchWebApiService } from '../../../http-services/dispatch-web-api.service';
import { UserWebApiService } from '../../../http-services/user-web-api.service';
import { FamilyWebApiService } from '../../../http-services/family-web-api.service';
import { ShoppingWebApiService } from '../../../http-services/shopping-web-api.service';
import { ExpWebApiService } from '../../../http-services/exp-web-api.service';
import { CommonFunctionModule } from "../../../lib-modules/common-function.module";
import { PagerService, PageKey } from "../../../lib-services/pager.service";

// component
import { TabbarComponent } from '../../tabbar/tabbar.component';
import { HistoryDetailComponent } from '../dispatch/history-detail/history-detail.component';
import { HistoryOrderDetailComponent } from '../order/history-order-detail/history-order-detail.component';
import { HistoryExpDetailComponent } from '../exp/history-exp-detail/history-exp-detail.component';

// interface
import { common } from '../../../interfaces/common';
import { request } from '../../../interfaces/request';
import { Reservation, Relation, Order, ExpReservation, ExpRecalc } from '../../../interfaces/response';
import { parameter } from '../../../interfaces/parameter';

/**
 * 最後に詳細を表示した予約ID群のinterface定義
 *
 * @export
 * @interface LastDisplayId
 */
export interface LastDisplayId {
  /**
   * 配車予約ID
   *
   * @type {string}
   * @memberof LastDisplayId
   */
  userReservation: string, 

  /**
   * 注文ID
   *
   * @type {string}
   * @memberof LastDisplayId
   */
  order: string, 

  /**
   * 観光予約ID
   *
   * @type {string}
   * @memberof LastDisplayId
   */
  exp: string
}

//=============================================================================================
// クラス定義
//=============================================================================================

/**
 * 予定・履歴（予約情報）画面
 *
 * @export
 * @class HistoryListComponent
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component
({
  selector: 'ons-page[history-list]',
  templateUrl: './history-list.component.html',
  styleUrls: ['./history-list.component.scss']
})

export class HistoryListComponent implements OnInit, OnDestroy {

//=============================================================================================
// プロパティ定義
//=============================================================================================

  /**
   * カレンダーモジュール
   *
   * @type {FullCalendarComponent}
   * @memberof HistoryListComponent
   */
  @ViewChild('calendar') calendar: FullCalendarComponent;
  readonly calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    plugins: [
      dayGridPlugin,      // Display events on Month view or DayGrid view
      interactionPlugin   // Provides functionality for event drag-n-drop, resizing, dateClick, and selectable actions
    ],
    titleFormat: {
      year: 'numeric', 
      month: 'long'
    },
    headerToolbar: { left: 'prev,next', center: 'title', right: 'today' }, 
    // カスタムボタン
    customButtons: {
      prev: {
        hint: '前月を表示', 
        click: () => this.onClickCalendarToolbarBtn("prev")
      },
      next: {
        hint: '翌月を表示', 
        click: () => this.onClickCalendarToolbarBtn("next")
      },
      today: { 
        text: '今日', 
        hint: '今日の日付に戻る', 
        click: () => this.onClickCalendarToolbarBtn("today")
      }
    }, 
    locale: 'ja',
    dayCellContent: (date: DayCellMountArg) => {
      date.dayNumberText = date.dayNumberText.replace('日', '');  // カレンダーセルの「日」を除去
    },
    dateClick: (date: DateClickArg): void => {
      this.selectedDate = moment(date.dateStr).format(moment.HTML5_FMT.DATE);  // 選択日の設定

      this.handleDateClick(date);
    }
  };

  /**
   * ファミリーメンバー情報
   *
   * @type {Relation[]}
   * @memberof HistoryListComponent
   */
  familyInfo: Relation[] = [];

  /**
   * 選択中日付
   *
   * @type {string}
   * @memberof HistoryListComponent
   */
  selectedDate: string = null;

  /**
   * 最後に詳細を表示した予約ID群
   *
   * @type {LastDisplayId}
   * @memberof HistoryListComponent
   */
  lastDisplayId: LastDisplayId;

  /**
   * 配車予約情報
   *
   * @type {Reservation[]}
   * @memberof HistoryListComponent
   */
  reservations: Reservation[];

  /**
   * 注文情報
   *
   * @type {Order[]}
   * @memberof HistoryListComponent
   */
  orders: Order[];

  /**
   * 観光予約情報
   *
   * @type {ExpReservation[]}
   * @memberof HistoryListComponent
   */
  expReserv: ExpReservation[];
  
  /**
   * 選択中の日付クラス(note: 当クラスを付与すると、スタイルが適用)
   *
   * @memberof HistoryListComponent
   */
  selectedDateClass = 'history-selected-day';

  /**
   * 選択中の日付の文字列クラス(note: 当クラスを付与すると、スタイルが適用)
   *
   * @memberof HistoryListComponent
   */
  selectedDateTextClass = 'selected-day-number';

  /**
   * 非同期通信の受信オブジェクト
   *
   * @type {Subscription}
   * @memberof HistoryListComponent
   */
  busy: Subscription;

  /**
   * ユーザ情報変更監視subscription
   *
   * @type {Subscription}
   * @memberof HistoryListComponent
   */
  onUserInfoChanged: Subscription;

  /**
   * ファミリー情報変更監視subscription
   *
   * @type {Subscription}
   * @memberof HistoryListComponent
   */
  // onFamilyChanged: Subscription;

  /**
   * 予定・履歴タブタップ時の再読み込み用subscription
   *
   * @type {Subscription}
   * @memberof HistoryListComponent
   */
  historyTabClickSubscription: Subscription;

  /**
   * 日付操作moment
   *
   * @memberof HistoryListComponent
   */
  moment = moment;

  /**
   * 配車ごとの利用者情報
   *
   * @type {common.customerBill[]}
   * @memberof HistoryListComponent
   */
  customerBills: common.customerBill[] = [];

  /**
   * 画面表示用の予定・履歴
   *
   * @type {common.ScheduleHistory[]}
   * @memberof HistoryListComponent
   */
  scheduleHistory: common.ScheduleHistory[] = [];

  /**
   * assetsファイルへのパス(定数)
   *
   * @memberof HistoryListComponent
   */
  readonly ASSETS = {
    PLAN: CommonFunctionModule.getAssetsUrl('/image/common/11-Plan.png'),
    HISTORY: CommonFunctionModule.getAssetsUrl('/image/common/12-History.png'),
    CANCEL1: CommonFunctionModule.getAssetsUrl('/image/common/60-Cancel_F_5.png'),
    CANCEL2: CommonFunctionModule.getAssetsUrl('/image/common/60-Cancel_B_2.png'),
  } as const;

//=============================================================================================
// ライフサイクルメソッド
//=============================================================================================

  /**
   * Creates an instance of HistoryListComponent.
   * @param {OnsNavigator} _navigator
   * @param {ElementRef} _element
   * @param {Params} _params
   * @param {TabbarComponent} tabbarComponent
   * @param {HttpErrorResponseParserService} errResServ
   * @param {UserWebApiService} userServ
   * @param {DispatchWebApiService} dispatchServ
   * @param {FamilyWebApiService} familyServ
   * @param {ShoppingWebApiService} shopServ
   * @param {ExpWebApiService} expServ
   * @param {CommonFunctionModule} commonFunc
   * @param {PagerService} pagerServ
   * @memberof HistoryListComponent
   */
  constructor(
    private _navigator: OnsNavigator, 
    private _element: ElementRef, 
    private _params: Params, 
    private tabbarComponent: TabbarComponent, 
    private errResServ: HttpErrorResponseParserService, 
    private userServ: UserWebApiService, 
    private dispatchServ: DispatchWebApiService, 
    private familyServ: FamilyWebApiService, 
    private shopServ: ShoppingWebApiService, 
    private expServ: ExpWebApiService, 
    private commonFunc: CommonFunctionModule, 
    private pagerServ: PagerService, 
  ) { }

  /**
   * 初期化処理。
   *
   * @memberof HistoryListComponent
   */
  ngOnInit(): void {

    // 初期選択日付(入力パラメータがあればそれを、なければ当日が初期値)
    this.selectedDate = this._params.data.selectedDate?? moment().format(HTML5_FMT.DATE);

    // 初期化
    this.lastDisplayId = {
      userReservation: "", 
      order: "", 
      exp: ""
    };

    // タブクリック時、予定履歴取得。
    this.historyTabClickSubscription = fromEvent(this.tabbarComponent.historyTab.nativeElement, 'click').subscribe(() => {
      // ログイン済みの場合、履歴タブを押した際に最新のファミリー情報を取得
      if (this.userServ.isLoggedIn() === true) {
        // 最新のファミリー情報取得
        this.familyServ.getFamily().subscribe({
          next: res => {
            // ファミリー情報更新
            this.setFamilyInfo(res.body);

            // 「・」マーク(予約がある)の初期化
            this.initMarkedElements();

            // 履歴の再取得
            setTimeout(() => {
              // NOTE: history-list.componentが表示されていない時に、window.resizeが呼ばれた場合
              //        カレンダーのサイズが0になるため、updateSizeメソッドが必要。
              this.calendar.getApi().updateSize();
              
              // 予定・履歴の再描画
              this.reload();
            }, 0);
          },
          error: this.errResServ.doParse((_err, errContent) => this.errResServ.viewErrDialog(errContent))
        });
      }
    });

    // ファミリー情報を取得
    this.setFamilyInfo(this.familyServ.getFamilyInfo());
  }

  /**
   * ビュー描画後処理。
   *
   * @memberof HistoryListComponent
   */
  ngAfterViewInit(): void {
    
    // 戻り先のページキーを設定
    this.pagerServ.setReturnPage({ index: this._navigator.element.pages.length - 1, key: PageKey.HistoryList });

    // 現在の選択日付に遷移
    this.calendar.getApi().gotoDate(new Date(this.selectedDate));

    // 日付の背景色、文字色変更、「今日」ボタン有効化
    this.addBackgounrdColorToDate(this.selectedDate);
    this.addTextColorToDate(this.selectedDate);
    this.setTodayBtnValidation();

    // 予定履歴タブを表示している場合のみ、履歴の再取得を行う。
    // note: スマホアプリの初期ロードでは呼ばれず、
    //  予定履歴タブのスタックがたまった画面(配車キャンセル確定画面など)からresetToPage()で戻る場合のみで呼ばれる。
    if (this.tabbarComponent.getActiveTab() == this.tabbarComponent.tabComponent.history) {
      setTimeout(() => {
        // 履歴の再取得
        this.getScheduleDay(this.selectedDate);
        this.getScheduleMonth();
      }, 0);
    }
    
    // ユーザー情報変更を検知したら
    this.onUserInfoChanged = this.userServ.userInfoChanged.subscribe({
      next: user => {
        // ユーザー情報未取得の場合、処理を抜ける
        if (user === undefined) return;
        
        // 未ログインの場合、予定履歴を初期化
        if (this.userServ.isLoggedIn() === false) this.initAll();
      }
    });

    // ログインユーザがファミリー管理者の場合、ファミリー情報の変更を監視
    // this.onFamilyChanged = this.familyServ.familyInfoChenged.subscribe({
    //   next: familyInfo => {
    //     // ファミリー情報を更新
    //     this.setFamilyInfo(familyInfo);

    //     // 未ログインかつ、ファミリーが1人(メンバーが存在しない)
    //     if (!this.userServ.isLoggedIn() && this.familyInfo.length > 1) {
    //       // 履歴の取り直し
    //       setTimeout(() => {
    //         this.getScheduleDay(this.selectedDate);
    //         this.getScheduleMonth();
    //       }, 0);
    //     }
    //   }
    // });
  }
  
  /**
   * 破棄処理。
   *
   * @memberof HistoryListComponent
   */
  ngOnDestroy(): void {

    [this.busy, this.onUserInfoChanged, /* this.onFamilyChanged, */ this.historyTabClickSubscription].forEach(s => {
      s?.unsubscribe();
    });
  }

//=============================================================================================
// イベントハンドラ
//=============================================================================================

  /**
   * カレンダーツールバータップ時のイベントハンドラ。
   *
   * @param {("prev" | "next" | "today")} action
   * @memberof HistoryListComponent
   */
  onClickCalendarToolbarBtn(action: "prev" | "next" | "today"): void {

    // ツールバーボタンに応じてカレンダーを操作
    if (action === "prev") this.calendar.getApi().prev();
    else if (action === "next") this.calendar.getApi().next();
    else if (action === "today") {
      this.calendar.getApi().today();
 
      // 本日を選択日付として保存
      this.selectedDate = moment().format(moment.HTML5_FMT.DATE);

      // 本日の予定を取得
      this.getScheduleDay(this.selectedDate);
    }
    else ;

    // 日付の背景色、文字色変更、「今日」ボタン有効化
    this.addBackgounrdColorToDate(this.selectedDate);
    this.addTextColorToDate(this.selectedDate);
    this.setTodayBtnValidation();

    // 表示月全体の予約情報を取得
    this.busy = this.getScheduleMonth();
  }

  /**
   * カレンダーの日付選択時のイベントハンドラ。
   *
   * @param {{ dateStr: string }} arg
   * @memberof HistoryListComponent
   */
  handleDateClick(arg: { dateStr: string }): void {

    // 日付の背景色、文字色変更、「今日」ボタン有効化
    this.addBackgounrdColorToDate(arg.dateStr);
    this.addTextColorToDate(arg.dateStr);
    this.setTodayBtnValidation();

    // ログイン状態なら予定履歴を取得
    if (this.userServ.isLoggedIn()) this.getScheduleDay(arg.dateStr);
  }

  /**
   * 予定・履歴タップ時のイベントハンドラ。
   *
   * @param {common.ScheduleHistory} item
   * @memberof HistoryListComponent
   */
  pushDetail(item: common.ScheduleHistory): void {

    // 予定・履歴詳細へ遷移
    switch (item.type) {
      // 配車
      case "reservation":
        this.lastDisplayId.userReservation = item.id;
        const reservation = this.reservations.find(r => r.reservation_id.toString() === item.id);
        const bill = this.customerBills.find(bill => bill.reservation_id.toString() === item.id);
    
        this._navigator.element.pushPage(HistoryDetailComponent, { data: { reservation: reservation, customerBill: bill, reload: () => { this.reload(); } }});

        break;
      // 注文
      case "order":
        this.lastDisplayId.order = item.id;
        const order = this.orders.find(o => o.order_id === item.id);
        this._navigator.element.pushPage(HistoryOrderDetailComponent, { data: { order: order, reload: () => { this.reload(); } }});

        break;
      // 観光
      case "exp":
        this.lastDisplayId.exp = item.id;
        const reserv = this.expReserv.find(e => e.sg_reservation_id.toString() === item.id);
        this._navigator.element.pushPage(HistoryExpDetailComponent, { data: { reserv: reserv, reload: () => { this.reload(); } }});

        break;
      default: ;
    }
  }

  /**
   * 予約・履歴中にファミリーが含まれるかチェックする。
   *
   * @param {common.ScheduleHistory} schedule
   * @return {*}  {boolean}
   * @memberof HistoryListComponent
   */
  checkFamily(schedule: common.ScheduleHistory): boolean {
      
    // ログインユーザ
    const user = this.userServ.getUserInfo();
    
    //　配車
    if (schedule.type === 'reservation') {
    
      const reservation = this.reservations.find(r => r.reservation_id.toString() === schedule.id);
      if (reservation === undefined) return false;

      let family_exist: boolean = false;

      for (let section of reservation.sections) {
        if (section.tickets == undefined) {
          // チケット情報が無い場合はスキップ
          continue;
        }

        for (let ticket of section.tickets) {
          // 搭乗者がログインユーザ以外かつ、ファミリーメンバー
          if (user) {
            if (ticket.user.user_id === user.user_id) return false;
            const family = this.familyInfo.find(u => u.user_id == ticket.user.user_id);
            if (family) {
              family_exist = true;
            }
          }
        }

        // ログインユーザが搭乗者に含まれず、ファミリーが搭乗している
        if (family_exist) return true;
        return false;  //ticketがあるsectionを回ってもファミリーが見つからなかったらfalse
      }
    }
    // 注文
    else if (schedule.type === 'order') {

      const order = this.orders.find(o => o.order_id === schedule.id);
      if (order === undefined) return false;

      // 注文者がログインユーザ以外かつ、ファミリーメンバー
      if (user) {
        if (order.user.user_id !== user.user_id.toString()) {
          if (undefined !== this.familyInfo.find(f => f.user_id.toString() === order.user.user_id)) return true;
        }
      }
    }
    // 観光
    else if (schedule.type === 'exp') {
      
      const exp = this.expReserv.find(e => e.sg_reservation_id.toString() === schedule.id);
      if (exp === undefined) return false;

      // 予約者がログインユーザ以外かつ、ファミリーメンバー
      if (user) {
        if (exp.user.user_id !== user.user_id.toString()) {
          if (undefined !== this.familyInfo.find(f => f.user_id.toString() === exp.user.user_id)) return true;
        }
      }
    }
    else ;

    return false;
  }

//=============================================================================================
// メソッド
//=============================================================================================

  /**
   * 予定履歴の全情報を初期化する。
   *
   * @private
   * @memberof HistoryListComponent
   */
  private initAll(): void {

    // 選択日付を当日で初期化
    this.selectedDate = moment().format(HTML5_FMT.DATE);

    // 前回選択したID初期化
    this.lastDisplayId = {
      userReservation: "", 
      order: "", 
      exp: ""
    };

    // 画面情報初期化
    this.scheduleHistory = [];
    
    // 日付の背景色、文字色変更、「今日」ボタン有効化
    this.addBackgounrdColorToDate(this.selectedDate);
    this.addTextColorToDate(this.selectedDate);
    this.setTodayBtnValidation();

    // 詳細画面以降を開いていたら閉じる
    if (this._navigator.element.pages.length > 1) {
      this.pagerServ.transitionToPage(this._navigator, PageKey.HistoryList);
    }
    
    // カレンダーの予定あり「・」の初期化
    this.initMarkedElements();
  }

  /**
   * カレンダーの予定あり「・」の初期化
   *
   * @private
   * @memberof HistoryListComponent
   */
  private initMarkedElements() {

    // カレンダーの予定あり「・」を全削除
    const regex = new RegExp(/user-reservation[-]*[a-z]*/g);
    const markedElements = Array.from(this.calendar.getApi().el.querySelectorAll(`[class*='user-reservation']`));
    markedElements.map(e => e.className = e.className.replace(regex, ''));
  }

  /**
   * 「今日」ボタンを常に有効状態にする。
   *
   * @private
   * @memberof HistoryListComponent
   */
  private setTodayBtnValidation(): void {

    // 「今日」ボタンを活性化
    let btn_today = <HTMLInputElement>document.getElementsByClassName("fc-today-button fc-button fc-button-primary")[0];
    if (btn_today) btn_today.disabled = false;
  }

  /**
   * 日付の背景色を設定する。
   *
   * @private
   * @param {string} [date=null]
   * @memberof HistoryListComponent
   */
  private addBackgounrdColorToDate(date: string = null): void {

    // 背景色を初期化
    this._element.nativeElement.querySelector(`.${this.selectedDateClass}`)?.classList.remove(this.selectedDateClass);

    // 選択した日付がある場合は背景色を設定
    if (date)
      this._element.nativeElement.querySelector(`[data-date="${date}"]`)?.classList.add(this.selectedDateClass);
  }

  /**
   * 日付の文字色を設定する。
   *
   * @private
   * @param {string} [date=null]
   * @memberof HistoryListComponent
   */
  private addTextColorToDate(date: string = null): void {

    // 文字色を初期化
    this._element.nativeElement.querySelector(`.${this.selectedDateTextClass}`)?.classList.remove(this.selectedDateTextClass);

    // 選択した日付がある場合は文字色を設定
    if (date)
      this._element.nativeElement.querySelector(`[data-date="${date}"]`)?.classList.add(this.selectedDateTextClass);
  }

  /**
   * ファミリーメンバー情報を格納する。
   *
   * @private
   * @param {Relation[]} family
   * @memberof HistoryListComponent
   */
  private setFamilyInfo(family: Relation[]): void {

    if (family) {
      this.familyInfo = [];
      this.familyInfo = family.filter(f => f.role === 'child' && f.status != 'waiting');
    }
  }

  /**
   * ユーザーごとの合計金額を算出する。
   *
   * @private
   * @param {Reservation} reservation
   * @return {*}  {number}
   * @memberof HistoryListComponent
   */
  private getTotalPriceUser(reservation: Reservation): number {

    // まだユーザー情報の取得が完了していなかったら
    if (!this.userServ.isLoggedIn()) return 0;

    // 合計料金を返却（ログインユーザ＋ファミリー）
    return this.customerBills.find(bill => bill.reservation_id === reservation.reservation_id).total?.price ?? 0;
  }

  /**
   * 予定・履歴の再描画を行う。
   *
   * @memberof HistoryListComponent
   */
  reload(): void {

    // NOTE: history-list.componentが表示されていない時に、window.resizeが呼ばれた場合
    //        カレンダーのサイズが0になるため、updateSizeメソッドが必要。
    this.calendar.getApi().updateSize();
    
    this.getScheduleDay(this.selectedDate);
    this.getScheduleMonth();
  }

//=============================================================================================
// サーバ通信（非同期監視部）
//=============================================================================================

  /**
   * 選択した日付の配車/注文/観光の予定・履歴を取得する。
   *
   * @private
   * @param {string} targetDate
   * @return {*}  {void}
   * @memberof HistoryListComponent
   */
  private getScheduleDay(targetDate: string): void {
    
    // 選択した日付
    this.selectedDate = targetDate;

    // 初期化
    // this.reservations = [];
    // this.customerBills = [];
    // this.orders = [];
    // this.scheduleHistory = [];

    // ログインしていなければ
    if (this.userServ.isLoggedIn() === false) {

      // 詳細ページ以降が表示されているなら閉じる
      // if (1 < this._navigator.element.pages.length) 
        // this.pagerServ.transitionToPage(this._navigator, PageKey.HistoryList);

      this.initAll();
      return;
    }

    const taskList: Observable<any>[] = [];

    // 非同期処理を追加
    taskList.push(this.getReservationSchedulingHistory());  // 配車
    taskList.push(this.getOrderSchedulingHistory());        // 注文
    taskList.push(this.getExpSchedulingHistory());          // 観光

    // 処理実行
    this.busy = forkJoin(taskList).subscribe({
      next: result => {
      
        const reservations: Reservation[] = result[0];
        const orders: Order[] = result[1];
        const exps: ExpReservation[] = result[2];
        let viewDate: common.ScheduleHistory[] = [];

        // 配車
        reservations.map(r => {
          // 表示データ生成
          viewDate.push({
            type: 'reservation', 
            id: r.reservation_id.toString(), 
            status: r.status, 
            from: {
              name: r.o.name, 
              schd_time: r.o.schd_time
            }, 
            to: {
              name: r.d.name, 
              schd_time: r.d.schd_time
            }, 
            totalPrice: this.getTotalPriceUser(r)
          });
        });

        // 注文
        orders.map(o => {
          // 表示データ生成
          viewDate.push({
            type: 'order', 
            id: o.order_id, 
            status: o.status, 
            from: {
              name: o.destination.address_components ? this.commonFunc.getAddressAfterCity(o.destination.address_components.address) : o.destination.name, 
              schd_time: o.d.schd_time.from
            }, 
            to: {
              name: o.destination.name, 
              schd_time: o.d.schd_time.to
            }, 
            totalPrice: o.amount
          });
        });

        // 観光
        exps.map(e => {
          
          // 日付跨ぎチェック
          const start = moment(new Date(e.start.schd_time)).format("L");
          const end = moment(new Date(e.end.schd_time)).format("L");
          const selectDay = moment(new Date(this.selectedDate)).format("L");
          let startFormat = "TIME";
          let endFormat = "TIME";
          if (e.multiday) {
            if (start !== selectDay) startFormat = "DATE_MIN";
            if (end !== selectDay) endFormat = "DATE_MIN";
          }

          // 合計料金
          const total: number = e.service_options.length===0 ? e.amount : e.amount + e.service_options.map(option => option.ticket.amount).reduce((a, b) => a + b);;
          // 表示データ生成
          viewDate.push({
            type: 'exp', 
            service_type: e.service.service_type, 
            id: e.sg_reservation_id.toString(), 
            status: e.status, 
            cancel_by: e.cancel_by, 
            from: {
              name: e.start.place.name, 
              schd_time: e.start.schd_time
            }, 
            to: {
              name: e.end.place.name, 
              schd_time: e.end.schd_time
            }, 
            totalPrice: total,
            viewDate_start: startFormat, 
            viewDate_end: endFormat
          });
        });

        // from順にソート
        viewDate.sort((a, b) => {
          if (new Date(a.from.schd_time).getTime() < new Date(b.from.schd_time).getTime()) return -1;

          return 1;
        });

        this.scheduleHistory = viewDate;
      }, 
      error: this.errResServ.doParse((_err, errContent) => { this.errResServ.viewErrDialog(errContent); })
    });
  }

  /**
   * 表示月の配車/注文/観光の予定・履歴を取得する。
   *
   * @private
   * @return {*}  {void}
   * @memberof HistoryListComponent
   */
  private getScheduleMonth(): Subscription {

    // [未認証] 日付下マーク class を削除
    if (!this.userServ.isLoggedIn()) {
      // const regex = new RegExp(/user-reservation[-]*[a-z]*/g);
      // const markedElements = Array.from(this.calendar.getApi().el.querySelectorAll(`[class*='user-reservation']`));
      // markedElements.map(e => e.className = e.className.replace(regex, ''));
      
      this.initAll();
      return;
    }

    // カレンダーに表示されている日付を全て取得。表示月以外も含む。
    /** 表示月の年月日配列 */
    const dates = Array.from(this.calendar.getApi().el.querySelectorAll<HTMLElement>('[data-date]')).map(e => e.dataset.date).sort();

    /**
     * 配車情報取得
     *
     * @param {string[]} dates
     * @return {*}  {*}
     */
    const getUserReserv = (dates: string[]): any => {
      const userReservationsUserInfo: request.UserReservations = {
        begin_date: moment(dates[0]).format(HTML5_FMT.DATE), 
        end_date: moment(dates[dates.length - 1]).format(HTML5_FMT.DATE), 
        date_only: true
      };

      return this.dispatchServ.userReservations(userReservationsUserInfo);
    };
    
    /**
     * 注文情報取得
     *
     * @param {string[]} dates
     * @return {*}  {*}
     */
    const getOrderReserv = (dates: string[]): any => {
      // const userIds = this.createParamUserId();
      const orderParam: parameter.Order = {
        // user_id: userIds !== undefined ? (<string[]>userIds) : undefined, 
        delivery_date_from: moment(dates[0]).format(HTML5_FMT.DATE), 
        delivery_date_to: moment(dates[dates.length - 1]).format(HTML5_FMT.DATE), 
        as: 'user', 
        date_only: true, 
      }
  
      return this.shopServ.getOrders(orderParam);
    };
    
    /**
     * 観光情報取得
     *
     * @param {string[]} dates
     * @return {*}  {*}
     */
    const getExpReserv = (dates: string[]): any => {
      const expParam: parameter.ExpReservation = {
        date_from: moment(dates[0]).format(HTML5_FMT.DATE), 
        date_to: moment(dates[dates.length - 1]).format(HTML5_FMT.DATE), 
        date_only: true, 
      };
      return this.expServ.getReservation(expParam);
    };

    const taskList: Observable<any>[] = [];

    // 非同期処理を追加
    taskList.push(getUserReserv(dates));  // 配車
    taskList.push(getOrderReserv(dates)); // 注文
    taskList.push(getExpReserv(dates));   // 観光

    // 処理実行
    return forkJoin(taskList).subscribe({
      next: result => {
      
        const reservations: string[] = result[0].body;
        const orders: Order[] = result[1].body;
        const exps: ExpReservation[] = result[2].body;
        let schedule = new Set();
        
        reservations.map(r => schedule.add(moment(new Date(r)).format("YYYY-MM-DD")));
        orders.map(o => schedule.add(moment(new Date(o.o.schd_time.from)).format("YYYY-MM-DD")));
        exps.map(e => {
          let start = moment(e.start.schd_time);
          let end = moment(e.end.schd_time);

          // 利用開始日と終了日の間の日程をすべて予定ありに設定する
          for (let d = start; d.isSameOrBefore(end, 'day'); d.add(1, 'd')) {
            schedule.add(d.format("YYYY-MM-DD"));
          }
        });
      
        // taskList内の処理がすべて完了した後の処理
        let selected = [];

        // 既に適用済みの日付でない場合
        schedule.forEach(s => {
          const element = this.calendar.getApi().el.querySelector(`.fc-daygrid-body [data-date="${s}"]`);
          if (element) {
            // TODO: 適切なクラス、CSSを。
            // const target = this.calendar.getApi().el.querySelector(`.fc-daygrid-body [data-date="${s}"] .fc-daygrid-day-frame .fc-daygrid-day-events`);
            const target = this.calendar.getApi().el.querySelector(`.fc-daygrid-body [data-date="${s}"] .fc-daygrid-day-frame`);
            target.classList.add(`user-reservation`);
            target.classList.add(`user-reservation--${s}`);
          }
          selected.push(s);
        });
      }, 
      error: this.errResServ.doParse((_err, errContent) => { this.errResServ.viewErrDialog(errContent); })
    });
  }

//=============================================================================================
// サーバ通信（通信部）
//=============================================================================================

  //*********************************** */
  // 選択日の予約情報を取得
  //*********************************** */

  /**
   * 選択した日付の予約情報を取得する。
   *
   * @param {string} targetDate
   * @return {*} 
   * @memberof HistoryListComponent
   */
  private getReservationSchedulingHistory(targetDate?: string): Observable<any> {

    const retSubject: Subject<any> = new Subject();

    // パラメータ作成
    // ファミリーがいる場合パラメータにファミリーメンバーIDを追加
    const userReservationsUserInfo: request.UserReservations = {
      begin_date: this.selectedDate,
      end_date: this.selectedDate,
      sort: (() => {
        let sort = {};
        sort[`o.schd_time`] = 1;

        return sort;
      })()
    };

    // 予約情報を取得
    this.dispatchServ.userReservations<Reservation[]>(userReservationsUserInfo).subscribe({
        next: response => {
          this.reservations = response.body;
          this.customerBills = [];

          for (let reservation of this.reservations) {

            // 自身とファミリーメンバーが全員キャンセル済みの場合、予約自体のステータスをキャンセルと表示する
            if (reservation.passenger_users.filter(u => this.userServ.getUserInfo().user_id == u.id 
                                                      || this.familyInfo.find(f => f.user_id == u.id))
              .every(u => u.canceled == true)) {
              
              reservation.status = 'canceled';
            }

            // 利用者の配車情報を抽出
            this.customerBills.push(this.dispatchServ.getCustomersBill(reservation));
          }

          // 履歴詳細などpushPageが複数ある（history-list以外のページ表示中）
          // if (1 < this._navigator.element.pages.length) {
          //   const reservation = this.reservations.find(r => r.reservation_id.toString() === this.lastDisplayId.userReservation);
          //   if (undefined !== reservation) {
          //     const bill = this.customerBills.find(bill => bill.reservation_id.toString() === this.lastDisplayId.userReservation);
          //     this._navigator.element.replacePage(HistoryDetailComponent, {
          //       animation: 'fade-ios',
          //       data: { reservation: reservation, customerBill: bill, reload: () => { this.reload(); }}
          //     });
          //   }
          //   else this._navigator.element.popPage({ animation: 'fade-ios' });
          // }

          retSubject.next(this.reservations);
        }, 
        error: (_err) => retSubject.error(_err), 
        complete: () => retSubject.complete()
      });

      return retSubject;
  }

  /**
   * 選択した日付の注文情報を取得する。
   *
   * @private
   * @return {*}  {Observable<any>}
   * @memberof HistoryListComponent
   */
  private getOrderSchedulingHistory(): Observable<any> {
    
    const retSubject: Subject<any> = new Subject();

    const orderParam: parameter.Order = {
      delivery_date_from: this.selectedDate + " 00:00", 
      delivery_date_to: this.selectedDate + " 23:59", 
      as: 'user', 
    }

    this.shopServ.getOrders(orderParam).subscribe({
      next: res => {
        this.orders = res.body;

        // 注文の合計料金を計算
        this.orders.forEach(o => {
          
          let amount: number = 0;
          
          o.items.forEach(i => amount += i.amount);
          o.packages.forEach(p => p.sections.forEach(s => amount += s.ticket.amount));
          o.amount = amount;

          if (o.destination.location || o.destination.address) o.destination.google_map_url = o.destination.location !== undefined ? this.commonFunc.getGoogleMapUrl(o.destination.location) : this.commonFunc.getGoogleMapUrl(o.destination.address);
        });

        retSubject.next(this.orders);
      }, 
      error: (_err) => retSubject.error(_err), 
      complete: () => retSubject.complete()
    });

    return retSubject;
  }

  /**
   * 選択した日付のサービス予約情報を取得する。
   *
   * @private
   * @return {*}  {Observable<any>}
   * @memberof HistoryListComponent
   */
  private getExpSchedulingHistory(): Observable<any> {
    
    const retSubject: Subject<any> = new Subject();

    const expParam: parameter.ExpReservation = {
      date_from: this.selectedDate + " 00:00", 
      date_to: this.selectedDate + " 23:59", 
    }

    this.expServ.getReservation(expParam).subscribe({
      next: res => {
        this.expReserv = res.body;

        this.expReserv.forEach(exp => {

          // const user_params = {
          //   numbers: exp.numbers, 
          //   rental_place: exp.start.place ? exp.start.place : undefined, 
          //   return_place: exp.end.place ? exp.end.place : undefined, 
          //   place: exp.end.place ? undefined : exp.start.place, 
          //   end_time: exp.end.schd_time, 
          //   as: exp.cancel_by
          // };

          const reserv = this.commonFunc.deepcopy(exp);
          // 料金計算
          // NOTE: recalc_funcは使わない
          const exp_result: ExpRecalc = {
            amount: 0,
            recalc_date: new Date(),
            tickets: reserv.usages.map(usage => {
              const tickets = usage.person_usages.map(pu => pu.ticket);
              tickets.forEach((ticket, i) => {
                ticket.ticket_type = 1;
                ticket.service_name = reserv.service.title;
                ticket.ticket_name = ticket.title;
                ticket.unit = reserv.service.user_params.numbers.find(number => number.type == usage.person_usages[i].price_property).unit;
              });
              const extension_tickets = usage.person_usages.map(pu => pu.extension_ticket).filter(ticket => !!ticket);  // 存在する延長チケットをフィルター
              extension_tickets.forEach((ticket, i) => {
                ticket.ticket_type = 2;
                ticket.service_name = reserv.service.title;
                ticket.ticket_name = ticket.title;
                ticket.unit = reserv.service.user_params.numbers.find(number => number.type == usage.person_usages[i].price_property).unit;
              });
              return (tickets.concat(extension_tickets) as ExpRecalc["tickets"]).sort((ti1, ti2) => ti1.sg_ticket_id-ti2.sg_ticket_id);;
            }).reduce((pre, cur) => pre.concat(cur),[])
          };
          exp_result.amount = exp_result.tickets.reduce((pre, cur) => pre+cur.amount, 0);
          
          // exp.tickets = [].concat(exp_result.tickets);
          exp.amount = exp_result.amount;
          exp.recalc_date = exp_result.recalc_date;

          exp.multiday = false;
          if (new Date(exp.start.schd_time).getDate() !== new Date(exp.end.schd_time).getDate()) exp.multiday = true;
        });

        retSubject.next(this.expReserv);
      }, 
      error: (_err) => retSubject.error(_err), 
      complete: () => retSubject.complete()
    });

    return retSubject;
  }
}
