import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectHistoryWebApiServiceConfig } from '../http-services/select-history-web-api.service';




@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ]
})
export class SelectHistoryWebApiModule {
  static withConfig(config: SelectHistoryWebApiServiceConfig): ModuleWithProviders<SelectHistoryWebApiModule> {
    return {
      ngModule: SelectHistoryWebApiModule,
      providers: [
        { provide: SelectHistoryWebApiServiceConfig, useValue: config }
      ]
    };
  }
}
