<ons-toolbar>
    <div class="center">パスワード再設定</div>
</ons-toolbar>
<div class="content">
    <div *ngIf="m_bMailTransition == false; else defaultTransition">
        <p class="content--text">
            パスワード再設定用のメールを送信しました。
        </p>
        <div class="pre-wrap content--text content--text--small">
            {{ m_strMailAddress }}にメールを送信しました。<br>
            メールのリンクからパスワードをリセットしてください。<br>
            ※再設定の有効期限は30分間となります。有効期限を過ぎた場合、再度リンク送信を行ってください。
        </div>
    </div>
    <ng-template #defaultTransition>
        <p class="content--text">
            新しいパスワードが設定されました。<br>
            下記ボタンよりSmartGOTOのホームに移り、ログインを行ってください。
        </p>
    </ng-template>
    <br>
    <ons-button id="change-password-complete_001" class="button button--large--cta" (click)="transition()">ホームへ</ons-button>
</div>