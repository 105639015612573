<ons-toolbar class="toolbar">
  <div class="left">
    <ons-back-button></ons-back-button>
  </div>
  <div class="center">支払い方法</div>
</ons-toolbar>
<form class="content" *ngIf="creditForm" [formGroup]="creditForm">
  <div [ngBusy]="busy"></div>
  <p class="content--title">カード情報の登録</p>
  <ons-list class="list-wrap">
    <ons-list-header modifier="color" class="list-item__title">カード番号</ons-list-header>
    <ons-list-item>
      <div formArrayName="card_nos">
        <ons-row>
          <ng-container
            *ngFor="let item of creditForm.get('card_nos')['controls']; let i = index; last as isLast; trackBy: myTrackBy">
            <ons-col>
              <ons-input id="payment-method-credit-add_00{{i + 1}}" modifier="underbar" #cardNos formControlName="{{i}}" (ngModelChange)="changeCardNo(i, $event)"
                inputmode="numeric" maxlength="4" placeholder="0000"></ons-input>
            </ons-col>
          </ng-container>
        </ons-row>
      </div>
      <div class="valid-msg" *ngIf="creditForm.get('card_nos')['invalid'] && creditForm.get('card_nos')['dirty']">
        <span *ngIf="creditForm.get('card_nos')['errors']?.cardNosPattern">半角数字で入力してください。</span>
        <span
          *ngIf="!creditForm.get('card_nos')['errors']?.cardNosPattern && creditForm.get('card_nos')['errors']?.Rquired">必須です。</span>
      </div>
    </ons-list-item>
    <ons-list-header modifier="color" class="list-item__title">名義人名</ons-list-header>
    <ons-list-item>
      <ons-input id="payment-method-credit-add_005" formControlName="holder_name" placeholder="TARO GOTO"></ons-input>
      <div class="valid-msg"
        *ngIf="creditForm.get('holder_name')['errors'] && (creditForm.get('holder_name')['dirty'] || creditForm.get('holder_name')['touched'])">
        <span *ngIf="creditForm.get('holder_name')['errors']?.required">必須です。</span>
        <span
          *ngIf="!creditForm.get('holder_name')['errors']?.required && creditForm.get('holder_name')['errors']?.pattern">入力例)
          TARO GOTO<br>・半角大英字のみ。<br>・姓名間に半角スペース必要。</span>
      </div>
    </ons-list-item>
    <ons-list-header modifier="color" class="list-item__title">有効期限</ons-list-header>
    <ons-list-item>
      <div>
        <ons-select id="payment-method-credit-add_006" class="dropdown-toggle" formControlName="expire_month">
          <option *ngFor="let month of expireMonths;"
            [value]="month"
            [attr.selected]="month === creditForm.get('expire_month').value ? true : null">{{ month }}</option>
        </ons-select>
        <ons-select id="payment-method-credit-add_007" class="dropdown-toggle" formControlName="expire_year">
          <option *ngFor="let year of expireYears;"
            [value]="year"
            [attr.selected]="year === creditForm.get('expire_year').value ? true : null">{{ year }}</option>
        </ons-select>
      </div>
      <div class="valid-msg"
        *ngIf="(creditForm.get('expire_month')['errors'] && creditForm.get('expire_month')['dirty']) || (creditForm.get('expire_year')['errors'] && creditForm.get('expire_year')['dirty'])">
        <span
          *ngIf="creditForm.get('expire_month')['errors']?.required || creditForm.get('expire_year')['errors']?.required">必須です。</span>
      </div>
    </ons-list-item>
    <ons-list-header modifier="color" class="list-item__title">セキュリティコード
      <a id="payment-method-credit-add_008" href="javascript: void(0);" (click)="cvvInfoAlert()" class="list-item__link">セキュリティコードCVVとは？</a>
    </ons-list-header>
    <ons-list-item>
      <input id="payment-method-credit-add_009" type="text" id="dummy-username" name="username" autocomplete="username" style="top: -100px; left: -100px; position: fixed;" />
      <ons-input id="payment-method-credit-add_010" type="password" formControlName="security_code" inputmode="numeric" maxlength="4" placeholder="123"
        autocomplete='new-password'></ons-input>
      <div class="valid-msg"
        *ngIf="creditForm.get('security_code')['errors'] && (creditForm.get('security_code')['dirty'] || creditForm.get('security_code')['touched'])">
        <span *ngIf="creditForm.get('security_code')['errors']?.pattern">半角数字で入力してください。</span>
        <span
          *ngIf="!creditForm.get('security_code')['errors']?.pattern && (creditForm.get('security_code')['errors']?.required || creditForm.get('security_code')['errors']?.minlength)">必須です。</span>
      </div>
    </ons-list-item>
  </ons-list>
  <div class="checkbox-wrap">
    <ons-checkbox id="payment-method-credit-add_011" input-id="default-flag" [attr.disabled]="isDefaultFlagDisabled ? true : null" formControlName="default_flag"></ons-checkbox>
    <label [attr.for]="isDefaultFlagDisabled ? null : 'default-flag'">デフォルト支払い方法に設定する</label>
  </div>
  <div class="button-wrap">
    <ons-button id="payment-method-credit-add_012" class="button button--large--cta" [disabled]="creditForm.invalid" (click)="add()">
      <div class="title">登録</div>
    </ons-button>
  </div>
</form>